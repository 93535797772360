import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class TemplatesController extends Controller {
  @service router;
  @service permissions;
  @service current;
  @service flipper;

  @action
  modalClosedAction() {
    return this.router.transitionTo('jobs.index');
  }

  loadTemplates = task(async () => {
    await get(this.current.company, 'templates');
  });
}
