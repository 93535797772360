/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import Component from '@glimmer/component';
import { action, get, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { timeout, task, keepLatestTask } from 'ember-concurrency';
import { DECORATIVE_COLORS } from 'teamtailor/constants/core/color-dot';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import FlashMessages from 'teamtailor/services/flash-message';
import Router from '@ember/routing/router-service';
import DashboardWidgetModel from 'teamtailor/models/dashboard-widget';
import { isPresent } from '@ember/utils';

type DashboardWidgetVideoModalArgs = {
  widget: DashboardWidgetModel;
  route: string;
  onSave?: () => void;
  onClose?: () => void;
};

type WidgetColor = {
  name: string;
  displayName: string;
};

export default class DashboardWidgetsVideoModalComponent extends Component<DashboardWidgetVideoModalArgs> {
  @service declare flashMessages: FlashMessages;
  @service declare router: Router;
  @service declare store: Store;
  @service declare current: Current;

  @tracked modalOpen = true;
  @tracked type: string | undefined;
  @tracked showNextStep = false;
  @tracked isRecordingStepDone = false;

  get showTypeSelect(): boolean {
    return get(this.args.widget, 'isNew') && !this.type;
  }

  get isFormStep(): boolean {
    return (
      this.type === 'embed' ||
      (this.type === 'record' && this.isRecordingStepDone) ||
      isPresent(this.args.widget.id)
    );
  }

  get isRecordingStep(): boolean {
    return this.type === 'record' && !this.isRecordingStepDone;
  }

  get widgetColors(): WidgetColor[] {
    return DECORATIVE_COLORS.map((color) => ({
      name: color,
      displayName: capitalize(color),
    }));
  }

  get widgetColor(): string {
    return this.args.widget.color;
  }

  get selectedWidgetColor(): WidgetColor | undefined {
    return this.widgetColors.findBy('name', this.widgetColor);
  }

  get allFieldsNotPresent(): boolean {
    // add this.selectedStyle when supported
    return !(this.args.widget.title && this.widgetColor);
  }

  get isRecord(): boolean {
    const { widget } = this.args;
    return (
      this.type === 'record' ||
      get(widget.options, 'videoProvider') === 'ziggeo'
    );
  }

  delayedCloseTask = task(async () => {
    await timeout(800);
    this.handleModalClose();
  });

  saveTask = keepLatestTask(async () => {
    await this.args.widget.save();
    if (this.args.onSave) {
      await this.args.onSave();
    }

    await this.delayedCloseTask.perform();
    return true;
  });

  destroyTask = keepLatestTask(async () => {
    const dashboardWidgetId = parseInt(this.args.widget.id, 10);
    await this.args.widget.destroyRecord();

    this.store
      .peekAll('picked-dashboard-widget')
      .forEach((pickedDashboardWidget) => {
        if (pickedDashboardWidget.dashboardWidgetId === dashboardWidgetId) {
          pickedDashboardWidget.unloadRecord();
        }
      });

    return true;
  });

  @action
  handleTypeSelection(type: string) {
    this.type = type;
  }

  @action
  handleRecording(videoToken: string) {
    this.showNextStep = true;
    const { widget } = this.args;
    setProperties(widget.options, {
      videoProvider: 'ziggeo',
      externalVideoId: videoToken,
    });
  }

  @action
  handleRerecord() {
    this.showNextStep = false;
  }

  @action
  handleModalClose() {
    if (this.args.onClose) {
      this.args.onClose();
    }

    if (this.args.route) {
      this.router.transitionTo(this.args.route);
    }
  }
}
