/* import __COLOCATED_TEMPLATE__ from './range-select.hbs'; */
import DropdownMenu from 'teamtailor/components/core/dropdown-menu';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { SelfScheduleClass } from 'teamtailor/classes/meetings/self-schedule';
import TimeFormatService from 'teamtailor/services/time-format';

type MeetingRangeSelectArgs = {
  value: SelfScheduleClass;
  onChange?: () => void;
};

export default class MeetingRangeSelect extends DropdownMenu<MeetingRangeSelectArgs> {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  get rangeLabel(): string {
    if (this.args.value.slotRules?.dateRanges) {
      return this.intl.t('components.meetings.event_scheduler.number_of_days', {
        days: this.days,
      });
    }

    return '';
  }

  get days() {
    const from = this.args.value.slotRules?.dateRanges?.firstObject?.from;
    const to = this.args.value.slotRules?.dateRanges?.firstObject?.to;
    const days = Math.round(moment(to).diff(from, 'days', true)) + 1;
    return days;
  }

  get minDate(): Date {
    return moment().add(1, 'days').startOf('day').toDate();
  }

  get toDate(): Date {
    return moment(
      this.args.value.slotRules?.dateRanges?.firstObject?.to
    ).toDate();
  }

  @action
  setDates(period: Date[]): void {
    if (period.length === 2 && period[0] && period[1]) {
      if (this.args.value.slotRules) {
        this.args.value.slotRules = {
          ...this.args.value.slotRules,
          dateRanges: [
            {
              from: moment(period[0]).format('YYYY-MM-DD'),
              to: moment(period[1]).format('YYYY-MM-DD'),
            },
          ],
        };
      }

      this.api.close();

      if (this.args.onChange) {
        this.args.onChange();
      }
    }
  }
}
