/* import __COLOCATED_TEMPLATE__ from './sortable-table-header.hbs'; */
import Component from '@ember/component';
import { setProperties, get, computed } from '@ember/object';

export default Component.extend({
  tagName: 'th',
  classNameBindings: [':cursor-pointer', 'isActive:sorted', 'directionClass'],

  attributeBindings: ['colspan'],

  sortDirection: null,
  sortBy: null,
  onSort: null,

  isDescending: computed('sortDirection', 'model.sortDirection', function () {
    const sortDirection =
      get(this, 'model.sortDirection') || this.sortDirection;
    return sortDirection === 'desc';
  }),

  isActive: computed('column', 'model.sortBy', 'sortBy', function () {
    const sortBy = get(this, 'model.sortBy') || this.sortBy;
    return get(this, 'column') === sortBy;
  }),

  directionClass: computed(
    'isActive',
    'model.sortDirection',
    'sortDirection',
    function () {
      if (get(this, 'isActive')) {
        return get(this, 'model.sortDirection') || this.sortDirection;
      } else {
        return 'desc';
      }
    }
  ),

  click() {
    if (this.onSort) {
      this.onSort();
    } else {
      let model = get(this, 'model');
      setProperties(model, {
        sortBy: get(this, 'column'),
        sortDirection: get(model, 'sortDirection') === 'desc' ? 'asc' : 'desc',
      });
    }
  },
});
