/* import __COLOCATED_TEMPLATE__ from './candidate-preview-popover.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import CandidateModel from 'teamtailor/models/candidate';
import TodoModel from 'teamtailor/models/todo';
import ActivityModel from 'teamtailor/models/activity';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import CandidatePreviewService from 'teamtailor/services/candidate-preview';
import MeetingEventModel from 'teamtailor/models/meeting-event';
import { guidFor } from '@ember/object/internals';
import {
  POPPER_OPTIONS,
  DEFAULT_POPOVER_DELAY,
  HEIGHT_DIFF_LIMIT_BETWEEN_CONTENT,
  CURSOR_OUT_OF_BOUND_DELAY,
} from 'teamtailor/constants/candidate-preview';
import { JobApplicationModel } from 'teamtailor/models';

type CandidatePreviewPopoverArgs = {
  candidate: CandidateModel;
  candidateRoute?: string;
  customTargetId?: string;
  anonymous?: boolean;
  isHidden?: boolean;
  jobApplication?: JobApplicationModel;
};

type TodoDueDateDropdown = {
  actions?: {
    close?: () => void;
  };
};

type Content = 'meetings' | 'todos' | 'actions' | 'upcoming-triggers';

export default class CandidatePreviewPopoverComponent extends Component<CandidatePreviewPopoverArgs> {
  @service declare apollo: TeamtailorApolloService;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare candidatePreview: CandidatePreviewService;
  @tracked meetings!: MeetingEventModel[];
  @tracked activities!: ActivityModel[];
  @tracked todos!: TodoModel[];
  @tracked expandedContent: Content | null = null;
  @tracked hasFetched = false;
  @tracked popoverHideDelay = DEFAULT_POPOVER_DELAY;
  @tracked _isShown = false;
  todoDueDateDropdown?: TodoDueDateDropdown;
  todosDivHeight!: number | undefined;

  popperOptions = POPPER_OPTIONS;

  constructor(owner: unknown, args: CandidatePreviewPopoverArgs) {
    super(owner, args);
    this.meetings = [];
    this.activities = [];
    this.todos = [];
  }

  get isRenderingPopover() {
    return !this.args.isHidden && (!this.hasFetched || this.hasCandidateData);
  }

  get hasCandidateData() {
    return (
      !!this.meetings.length || !!this.todos.length || !!this.activities.length
    );
  }

  get delay() {
    return this.candidatePreview.hasSlowerDelay ? '700' : '150';
  }

  get isShown() {
    if (this.candidatePreview.popoverDisplayable) {
      return this._isShown;
    } else {
      return false;
    }
  }

  set isShown(value) {
    this._isShown = value;
  }

  get hasNoData() {
    return (
      !this.todos.length && !this.meetings.length && !this.activities.length
    );
  }

  get internalClassname() {
    return `candidate-preview-${guidFor(this)}`;
  }

  get candidateId() {
    return get(this.args.candidate, 'id');
  }

  get targetId() {
    return this.args.customTargetId || `candidate-id-${this.candidateId}`;
  }

  get hasOnlyActivities() {
    return (
      [
        this.meetings,
        this.todos,
        this.args.jobApplication?.jobApplicationTriggers,
      ].flat().length === 0
    );
  }

  handlePopover(show: boolean) {
    this._isShown = show;
    const arrowElement = document.querySelector(
      `.arrow-${this.internalClassname}`
    );
    const popoverElement = document.querySelector(
      `.popover-${this.internalClassname}`
    );
    arrowElement?.classList.toggle('!opacity-0', !show);
    popoverElement?.classList.toggle('!opacity-0', !show);
  }

  fetchDataTask = restartableTask(async () => {
    if (!this.hasFetched) {
      const [meetings, activites, todos] = await Promise.all([
        this.store.query('meeting-event', {
          upcoming_and_ongoing: true,
          filter: {
            candidate_ids: [this.candidateId],
          },
        }),

        this.store.query('activity', {
          candidate_id: this.candidateId,
          per_page: 3,
          filter_automatic_emails: true,
          ...(this.args.jobApplication
            ? { job_application_id: this.args.jobApplication.id }
            : {}),
        }),

        this.store.query('todo', {
          candidate_id: this.candidateId,
          only_incomplete: true,
        }),
      ]);

      this.meetings = meetings;
      this.activities = activites;
      this.todos = todos;

      this.expandedContent = this.meetings.length
        ? 'meetings'
        : this.todos.length
          ? 'todos'
          : 'actions';
    }

    this.handlePopover(true);
    this.hasFetched = true;
  });

  @action
  toggleExpanded(componentName: Content) {
    this.expandedContent =
      this.expandedContent === componentName ? null : componentName;
  }

  @action
  onToggleDueDateDropdown(dropdown: TodoDueDateDropdown) {
    this.todoDueDateDropdown = dropdown;
  }

  @action
  onQuickScheduleHover(isHovering: boolean) {
    if (!isHovering) {
      this.popoverHideDelay = DEFAULT_POPOVER_DELAY;
    }
  }

  @action
  onHide() {
    if (this.hasFetched) {
      this.todoDueDateDropdown?.actions?.close?.();
      this.hasFetched = false;
      this.handlePopover(false);

      if (this.candidatePreview.hasSlowerDelay) {
        this.candidatePreview.hasSlowerDelay = false;
      }
    }
  }

  @action
  onShow() {
    if (!this.hasFetched) {
      this.fetchDataTask.perform();
    }
  }

  @action
  onDestroy() {
    this.fetchDataTask.cancelAll();
  }

  @action
  onMouseEnter() {
    this.popoverHideDelay = DEFAULT_POPOVER_DELAY;
  }

  @action
  didInsertContent(componentName: Content, content: HTMLElement) {
    if (componentName === 'todos') {
      this.todosDivHeight = content.offsetHeight;
    } else if (
      componentName === 'actions' &&
      this.todosDivHeight &&
      this.todos.length
    ) {
      if (
        this.todosDivHeight - content.offsetHeight >
        HEIGHT_DIFF_LIMIT_BETWEEN_CONTENT
      ) {
        this.popoverHideDelay = CURSOR_OUT_OF_BOUND_DELAY;
      }
    }
  }
}
