/* import __COLOCATED_TEMPLATE__ from './stepstone-de.hbs'; */
import PromotionBaseClass from './promotion-base';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class extends PromotionBaseClass {
  @service current;

  @tracked checkoutData;

  get stepstoneDeDescriptionWithDefault() {
    return (
      get(this, 'promotion.stepstoneDeDescription') ||
      get(this.current.company, 'defaultCareerSite.about')
    );
  }

  get isExistingAgreement() {
    return get(this, 'promotion.channel.channelActivation.existingAgreement');
  }
}
