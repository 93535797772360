/* import __COLOCATED_TEMPLATE__ from './cookie-table.hbs'; */
import Component from '@glimmer/component';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';

const TABLE_COLUMNS = [
  {
    label: 'common.name',
    width: 200,
  },
  {
    label: 'common.vendor',
    width: 150,
  },
  {
    label: 'common.expires',
    width: 150,
  },
  {
    label: 'common.description',
    width: 350,
  },
  {
    label: '',
    width: 80,
  },
];

export default class extends Component {
  @service flipper;

  @filterBy('args.cookies', 'editable', false) defaultCookies;
  @filterBy('args.cookies', 'editable', true) editableCookies;

  columns = TABLE_COLUMNS;
}
