/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import JobModel from 'teamtailor/models/job';
import JobCommentStatus from 'teamtailor/models/job-comment-status';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Current from 'teamtailor/services/current';
import { restartableTask } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import NotificationsService from 'teamtailor/services/notifications';
import { modifier } from 'ember-modifier';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';
import { UserModel } from 'teamtailor/models';
import FlipperService from 'teamtailor/services/flipper';

type Args = {
  selectedJob: JobModel | null | string;
};

interface JobsResponse {
  jobs: JobModel[];
  meta: {
    has_jobs: boolean;
    page: number;
    total_count: number;
    total_pages: number;
  };
}

export type MetaJobsData = {
  has_jobs: boolean;
  page: number;
  total_count: number;
  total_pages: number;
};

export default class GlobalCommentsSidebar extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare notifications: NotificationsService;
  @service declare server: Server;
  @service declare flipper: FlipperService;

  @tracked showAddTeamMemberModal = false;
  @tracked currentJobs: JobModel[] = [];
  @tracked currentMyJobs: JobModel[] = [];
  @tracked jobsPage = 1;
  @tracked myJobsPage = 1;
  @tracked mentionedJobIds: string[] = [];
  @tracked pinnedActivities: Record<string, string[]> | null = null;
  @tracked generalChatUsers: UserModel[] = [];

  fetchedJobCommentsForJobIds: string[] = [];
  _jobComments: JobCommentStatus[] = [];

  loadAllJobsTask = restartableTask(async () => {
    const scope = await this.store.query('job', {
      status: 'open',
      sort: 'title-asc',
      page: this.jobsPage,
    });

    this.currentJobs = [...this.currentJobs, ...scope];
    return scope;
  });

  loadMyJobsTask = restartableTask(async () => {
    const scope = await this.store.query('job', {
      status: 'open',
      sort: 'title-asc',
      recruiter_or_team_member: 'true',
      page: this.myJobsPage,
    });

    this.currentMyJobs = [...this.currentMyJobs, ...scope];
    return scope;
  });

  loadJobCommentStatusesTask = restartableTask(async () => {
    const myJobIds = this.myJobIds.filter(
      (id) => !this.fetchedJobCommentsForJobIds.includes(id)
    );

    if (!myJobIds.length) {
      return null;
    }

    this.fetchedJobCommentsForJobIds = [
      ...this.fetchedJobCommentsForJobIds,
      ...myJobIds,
    ];

    const jobComments = await this.store.query('job-comment-status', {
      job_ids: myJobIds,
      sort: 'title-asc',
    });

    this._jobComments = [...this._jobComments, ...jobComments];

    return this._jobComments;
  });

  allJobsTask = trackedTask(this, this.loadAllJobsTask, () => [this.jobsPage]);
  myJobsTask = trackedTask(this, this.loadMyJobsTask, () => [this.myJobsPage]);

  jobCommentStatusesTask = trackedTask(
    this,
    this.loadJobCommentStatusesTask,
    () => [this.myJobIds]
  );

  get jobCommentStatuses() {
    return this.jobCommentStatusesTask.value ?? [];
  }

  get myJobs() {
    return this.myJobsTask.value ?? [];
  }

  get myJobIds() {
    return [...this.currentMyJobs.map((job) => job.id)];
  }

  get allJobs() {
    return this.allJobsTask.value ?? [];
  }

  get myJobsMeta() {
    const data = this.myJobsTask.value as JobsResponse;
    return data.meta;
  }

  get allJobsMeta() {
    const data = this.allJobsTask.value as JobsResponse;
    return data.meta;
  }

  get jobs() {
    if (this.current.user.adminOrRecruitmentAdmin) {
      const myJobIds = new Set(this.currentMyJobs.map((job) => job.id));
      return this.currentJobs.filter((job) => !myJobIds.has(job.id));
    }

    return [];
  }

  get firstJob() {
    if (this.currentMyJobs.length > 0) {
      return this.currentMyJobs[0];
    }

    if (this.jobs.length > 0) {
      return this.jobs[0];
    }

    return null;
  }

  get currentJob() {
    return this.args.selectedJob || this.firstJob;
  }

  get currentChannel() {
    if (this.args.selectedJob === 'mentioned') {
      return this.args.selectedJob;
    }

    if (this.args.selectedJob === 'unread') {
      return this.args.selectedJob;
    }

    return this.args.selectedJob || this.firstJob;
  }

  receiveMentionedJobIds = modifier(() => {
    if (this.notifications.unreadMentions) {
      this.mentionedJobIds = Object.keys(this.notifications.unreadMentions);
    }
  });

  setChannelsWithPinned = modifier(async () => {
    const url = `/app/companies/${get(
      this.current.company,
      'uuid'
    )}/api/activities/pinned_activities`;

    this.pinnedActivities = (await this.server.request(
      url,
      'GET',
      {}
    )) as Record<string, string[]>;
  });

  setGeneralChatUsers = modifier(async () => {
    const params = {
      general_chat_access: true,
      per_page: 3,
    };

    this.generalChatUsers = await this.store.query('user', params);
  });

  @action
  closeHiringTeamModal() {
    this.showAddTeamMemberModal = false;
  }

  @action
  loadMoreJobs(isMyJobs: boolean) {
    isMyJobs ? (this.myJobsPage += 1) : (this.jobsPage += 1);
  }
}
