/* import __COLOCATED_TEMPLATE__ from './event.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import {
  IUser,
  IJob,
  IConnectedConference,
  IMeetingEventDateRange,
  ISelfSchedule,
  ICandidate,
  IJobApplication,
} from 'teamtailor/components/meeting/types';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

import {
  UserAttendeeClass,
  BookedSlotClass,
  MeetingEventClass,
  CandidateAttendeeClass,
} from 'teamtailor/classes/meetings';

import { SelfScheduleClass } from 'teamtailor/classes/meetings/self-schedule';
import PreparedMessageClass from 'teamtailor/classes/prepared-message';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';
import GET_CONNECTED_CONFERENCES from 'teamtailor/gql/connected-conferences-query.graphql';
import GET_USERS from 'teamtailor/gql/users-queries.graphql';
import { ScheduleMode } from '../calendar/viewer';
import AvailabilityService from 'teamtailor/services/availability';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { assert } from '@ember/debug';
import Current from 'teamtailor/services/current';

interface EventArgs {
  event: MeetingEventClass;
  currentUser: IUser;
  candidates: CandidateAttendeeClass[];
  job?: IJob;
  allEvents: MeetingEventClass[];
  preparedMessage: PreparedMessageClass;
  eventIndex: number;
  bookableMeetingRooms: MeetingRoomAttendeeClass[];
  isEditing?: boolean;
  jobApplications: IJobApplication[];
}

type ConnectedConferencesResult = {
  connectedConferences: IConnectedConference[];
};

export type FetchUserResult = {
  usersConnection: {
    nodes: IUser[];
  };
};

export default class MeetingEvent extends Component<EventArgs> {
  @service declare apollo: TeamtailorApolloService;
  @service declare availability: AvailabilityService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare current: Current;

  @tracked isOpen = false;
  @tracked connectedConferences: IConnectedConference[] = [];
  @tracked declare scheduleMode: ScheduleMode;
  @tracked selfScheduleAvailabilityPeriods: string[] = [];
  @tracked selfScheduleAvailabilityLoading = false;
  @tracked hiringTeam: (UserAttendeeClass | undefined)[] = [];

  initialVisibility = this.args.event.visibility;

  constructor(owner: unknown, args: EventArgs) {
    super(owner, args);
    this.requestConferenceData();
    if (args.event.selfSchedule) {
      this.requestAvailableSlotsData();
    }

    if (args.job) {
      this._fetchHiringTeam();
    }
  }

  requestConferenceData = async (): Promise<void> => {
    try {
      const conferences = await this._fetchConnectedConferences();
      this.connectedConferences = conferences;
    } catch (error) {
      this.flashMessages.error('Failed to fetch video services', {
        sticky: false,
        preventDuplicates: true,
      });
    }
  };

  _fetchConnectedConferences = async (): Promise<IConnectedConference[]> => {
    const data: ConnectedConferencesResult = await this.apollo.query({
      query: GET_CONNECTED_CONFERENCES,
      fetchPolicy: 'network-only',
      variables: {
        id: this.organizer?.user?.id,
      },
    });

    return data.connectedConferences;
  };

  requestAvailableSlotsData = async (): Promise<void> => {
    try {
      this.selfScheduleAvailabilityLoading = true;
      const availability =
        await this.availability.requestAvailableSlotsDataForEvent(this.event);
      this.selfScheduleAvailabilityPeriods = availability?.periods || [];
      this.selfScheduleAvailabilityLoading = false;
    } catch (error) {
      this.flashMessages.error('Failed to fetch available slots', {
        sticky: false,
        preventDuplicates: true,
      });
      this.selfScheduleAvailabilityLoading = false;
    }
  };

  _fetchHiringTeam = async () => {
    if (this.job) {
      const { usersConnection }: FetchUserResult = await this.apollo.query({
        query: GET_USERS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            ids: this.job.hiringTeam
              .filter((u) => u.role !== 'external_recruiter')
              .slice(0, 25)
              .map((u) => u.id),
          },
        },
      });

      if (usersConnection.nodes.length) {
        this.hiringTeam = this.handleAttendees(usersConnection.nodes);
      } else {
        this.hiringTeam = [];
      }
    } else {
      this.hiringTeam = [];
    }
  };

  get isEditing(): boolean {
    return this.args.isEditing ?? false;
  }

  get companyHasMeetingRooms(): boolean {
    return (
      Array.isArray(this.args.bookableMeetingRooms) &&
      this.args.bookableMeetingRooms.length > 0
    );
  }

  get readOnly(): boolean {
    return this.event.isReadOnly;
  }

  get candidates(): (ICandidate | undefined)[] {
    return this.args.candidates.map((c) => c.candidate);
  }

  get isPastEvent(): boolean {
    return this.bookedSlot?.isPast() ?? false;
  }

  get event(): MeetingEventClass {
    return this.args.event;
  }

  get bookableMeetingRooms(): MeetingRoomAttendeeClass[] {
    return this.args.bookableMeetingRooms;
  }

  get allEvents(): MeetingEventClass[] {
    return this.args.allEvents;
  }

  get eventIndex(): number {
    return this.allEvents.indexOf(this.event);
  }

  get userAttendees(): UserAttendeeClass[] {
    return this.event.meetingEventAttendees.userAttendees;
  }

  get job(): IJob | undefined {
    return this.args.job;
  }

  get firstCandidate(): ICandidate | undefined {
    assert('this.args.candidates[0] must exist', this.args.candidates[0]);

    return this.args.candidates[0].candidate;
  }

  get jobApplication(): IJobApplication | undefined {
    return this.args.jobApplications.find(
      (ja) => ja.job?.id === (this.args.job ? this.args.job.id : '')
    );
  }

  get userAttendeesIds(): string[] {
    return this.userAttendees.mapBy('user.id');
  }

  get selectedUserGroup() {
    return {
      groupName: this.intl.t('common.selected'),
      options: this.userAttendees,
    };
  }

  get suggestedUsers() {
    if (this.hiringTeam.length) {
      return [
        this.selectedUserGroup,
        {
          groupName: this.intl.t('components.hiring_team.hiring_team'),
          options: this.hiringTeam,
        },
      ];
    }

    return [this.selectedUserGroup];
  }

  get showVisibilitySetting() {
    return (
      this.organizerHasCronofy &&
      this.current.company.meetingVisibility !== this.initialVisibility
    );
  }

  get organizer(): UserAttendeeClass | undefined {
    return this.event.organizer;
  }

  get isSelfSchedule(): boolean {
    return this.selfSchedule !== undefined;
  }

  get eventDuration(): number {
    if (this.selfSchedule) {
      return this.selfSchedule.duration;
    }

    const start = moment(this.bookedSlot?.startsAt);
    const end = moment(this.bookedSlot?.endsAt);
    return moment.duration(end.diff(start)).asMinutes();
  }

  get bookedSlot(): BookedSlotClass | undefined {
    return this.event.bookedSlot;
  }

  get selfSchedule(): SelfScheduleClass | undefined {
    return this.event.selfSchedule;
  }

  get organizerHasCronofy(): boolean {
    return this.organizer?.hasConnectedCalendar ?? false;
  }

  get selfScheduleEnabled(): boolean {
    return this.isEditing ? this.isSelfSchedule : this.organizerHasCronofy;
  }

  get disableCandidateReminder() {
    return (
      this.readOnly ||
      (this.event.bookedSlot
        ? moment(this.event.bookedSlot.startsAt).isBefore(
            moment().add(1, 'days')
          )
        : false)
    );
  }

  handleAttendees(
    users: (IUser | undefined)[]
  ): (UserAttendeeClass | undefined)[] {
    const userAttendees = users.map((user: IUser | undefined) => {
      if (user) {
        return UserAttendeeClass.from(user);
      }

      return user;
    });

    const attendees = userAttendees.map((attendee) => {
      if (
        this.isEditing &&
        attendee &&
        attendee.user?.id === this.organizer?.user?.id
      ) {
        attendee.isDisabled = true;
      }

      return attendee;
    });

    return attendees.filter(
      (attendee) =>
        attendee && !this.userAttendeesIds.includes(attendee.user?.id || '')
    );
  }

  get organizerHealthy(): boolean {
    if (this.organizer?.cronofyAuthorization) {
      return this.organizer.cronofyAuthorization.isHealthy;
    }

    return true;
  }

  @action
  setScheduleMode(mode: ScheduleMode): void {
    this.scheduleMode = mode;

    if (mode === ScheduleMode.Manual && !this.event.bookedSlot) {
      this.event.bookedSlot = BookedSlotClass.from({
        startsAt: moment().startOf('hour').add(2, 'hour').toDate(),
        endsAt: moment().startOf('hour').add(3, 'hours').toDate(),
      });
    } else if (mode === ScheduleMode.SelfAuto && !this.event.selfSchedule) {
      this.event.selfSchedule = SelfScheduleClass.createWithDefaultSlotRules();
      this.requestAvailableSlotsData();
    }
  }

  @action
  openSelectTime(): void {
    this.isOpen = true;
  }

  @action
  closeSelectTime(): void {
    this.isOpen = false;
  }

  @action
  handleSetBookedSlot(dateRange: IMeetingEventDateRange | undefined): void {
    this.event.selfSchedule = undefined;
    if (dateRange) {
      this.event.bookedSlot = BookedSlotClass.from(dateRange);
    } else {
      this.event.bookedSlot = undefined;
    }
  }

  @action
  handleSetSelfSchedule(selfSchedule: ISelfSchedule | undefined): void {
    this.event.bookedSlot = undefined;
    if (selfSchedule) {
      this.event.selfSchedule = SelfScheduleClass.from(selfSchedule);
      this.requestAvailableSlotsData();
    } else {
      this.event.selfSchedule = undefined;
    }
  }

  @action
  handleTimeZoneChange(tzid: string): void {
    this.event.tzid = tzid;
  }

  @action
  selectOrganiser(attendee: UserAttendeeClass): void {
    this.event.reassignOrganizer(attendee);
    this.requestConferenceData();

    if (!this.organizerHasCronofy) {
      this.event.selfSchedule = undefined;
      this.event.meetingEventAttendees.clear(
        this.event.meetingEventAttendees.meetingRoomAttendees!
      );
    }
  }

  @action
  refreshAvailability(): void {
    if (this.event.selfSchedule) {
      this.requestAvailableSlotsData();
    }
  }

  @action
  fetchHiringTeam(): void {
    this._fetchHiringTeam();
  }
}
