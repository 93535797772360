import Controller from '@ember/controller';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { MeetingEvent } from 'teamtailor/classes/analytics/report-meetings';
import AnalyticsService from 'teamtailor/services/analytics';
import { MeetingStatsTypeResponse } from 'teamtailor/utils/insights/graphql-response-types';
import { IntlService } from 'ember-intl';
import { createFormatDurationInstance } from 'teamtailor/helpers/format-duration';
import { getOwner } from '@ember/application';
import { StatBarItem } from 'teamtailor/components/stat-bar';
import moment from 'moment-timezone';

export default class AnalyticsOverviewMeetingsController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;

  // Gets set in model's setupController hook:
  calendarEvents: MeetingEvent[] = [];

  get meetingStats(): MeetingStatsTypeResponse | undefined {
    return this.model.meetingStats.value;
  }

  get durationPerInterval() {
    if (!this.meetingStats) {
      return [];
    }

    return this.meetingStats.durationPerInterval.map((interval) => {
      return {
        ...interval,
        count: interval.count / 3600, // seconds to hours
      };
    });
  }

  get allStats() {
    return [
      this.scheduledMeetingsStats,
      this.candidatesInvitedStats,
      this.averageNumberOfParticipantsStats,
      this.totalMeetingTimeStats,
      this.averageMeetingTimeStats,
    ];
  }

  get scheduledMeetingsStats(): StatBarItem {
    return {
      value: this.meetingStats!.numberOfScheduledMeetings,
      title: this.intl.t('reports.meetings.scheduled'),
      icon: 'calendar',
    };
  }

  get candidatesInvitedStats(): StatBarItem {
    return {
      value: this.meetingStats!.numberOfCandidatesInvited,
      title: this.intl.t('reports.meetings.candidates_invited'),
      icon: 'user',
    };
  }

  get averageNumberOfParticipantsStats(): StatBarItem {
    return {
      value: this.meetingStats!.averageNumberOfParticipants,
      title: this.intl.t('reports.meetings.avg_participants'),
      icon: 'user',
    };
  }

  get totalMeetingTimeStats(): StatBarItem {
    return {
      value: this.formatDuration(this.meetingStats!.totalMeetingTime, {
        seconds: true,
        displayMinutes: false,
      }),

      title: this.intl.t('reports.meetings.in_total'),
      icon: 'stopwatch',
    };
  }

  get averageMeetingTimeStats(): StatBarItem {
    return {
      value: this.formatDuration(this.meetingStats!.averageMeetingTime, {
        seconds: true,
      }),

      title: this.intl.t('reports.meetings.avg_duration'),
      icon: 'stopwatch',
    };
  }

  formatDuration(duration: number, options?: any) {
    const owner = getOwner(this);
    const formatDurationHelper = createFormatDurationInstance(owner);

    return formatDurationHelper.compute([duration], options);
  }

  @action
  handleExport() {
    jsonToCsvFile(
      'team-activity',
      this.analytics.startDate,
      this.analytics.endDate,
      this.calendarEvents,
      (row: MeetingEvent) => ({
        starts_at: this.formatTimetoMeetingTimezone(
          row.bookedSlot.startsAt,
          row.tzid
        ),
        ends_at: this.formatTimetoMeetingTimezone(
          row.bookedSlot.endsAt,
          row.tzid
        ),
        summary: row.summary,
        candidate: row.candidateName,
        job: row.meeting.job?.title,
        location:
          row.meetingEventLocation?.location?.nameOrCity ||
          row.meetingEventLocation?.videoMeeting?.providerName,

        number_of_participants: row.numberOfUserParticipants,
        duration_in_mins: row.durationInMinutes,
      })
    );
  }

  formatTimetoMeetingTimezone(time: string, timezone: string): string {
    const timeWithTimezone = moment.parseZone(time);
    return timeWithTimezone.clone().tz(timezone).format();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.meetings': AnalyticsOverviewMeetingsController;
  }
}
