/* import __COLOCATED_TEMPLATE__ from './enable-desktop-notifications-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import DesktopNotificationsService from 'teamtailor/services/desktop-notifications';
import { inject as service } from '@ember/service';

export default class EnableDesktopNotificationsBanner extends Component {
  @service declare desktopNotifications: DesktopNotificationsService;

  get isNotificationsGranted() {
    return this.desktopNotifications.isNotificationsGranted;
  }

  get showBanner(): boolean {
    return this.desktopNotifications.notificationPermission === 'default';
  }

  @action
  enableDesktopNotifications() {
    this.desktopNotifications.enableDesktopNotifications();
  }
}
