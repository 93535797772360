/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';

type Args = {
  activity: ActivityModel;
};

export default class HireQualityResponseComponent extends Component<Args> {
  get activity(): ActivityModel {
    return this.args.activity;
  }

  get status() {
    return this.activity.actionData.status;
  }

  get isCreated() {
    return this.status === 'created';
  }

  get isSent() {
    return this.status === 'sent';
  }

  get isAnswered() {
    return this.status === 'answered';
  }

  get isCanceled() {
    return this.status === 'canceled';
  }
}
