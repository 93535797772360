/* import __COLOCATED_TEMPLATE__ from './hire-quality.hbs'; */
import Component from '@glimmer/component';
import {
  badgeColor,
  badgeText,
  showMultipleBadges,
} from 'teamtailor/utils/hires-report';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { HIRE_QUALITY_COLORS } from 'teamtailor/constants/hire-quality';

interface ResponseCounts {
  goodCount: number;
  poorCount: number;
  pendingCount: number;
}

type HireQualityCellData = {
  value: ResponseCounts;
};

type Args = {
  data: HireQualityCellData;
};

export default class DataTableHireQualityComponent extends Component<Args> {
  @service declare intl: IntlService;

  get responseCounts() {
    return this.args.data.value;
  }

  get badgeText() {
    return badgeText(this.responseCounts, this.intl);
  }

  get badgeColor() {
    return badgeColor(this.responseCounts);
  }

  get showMultipleBadges() {
    return showMultipleBadges(this.responseCounts);
  }

  get goodColor() {
    return HIRE_QUALITY_COLORS.good;
  }

  get poorColor() {
    return HIRE_QUALITY_COLORS.poor;
  }

  get pendingColor() {
    return HIRE_QUALITY_COLORS.pending;
  }
}
