/* import __COLOCATED_TEMPLATE__ from './add-payment-method-modal.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import CompanyModel from 'teamtailor/models/company';
import { action } from '@ember/object';
import { PaymentModalInterface } from 'teamtailor/controllers/settings/payments/in-app';
import config from 'teamtailor/config/environment';
import { tracked } from '@glimmer/tracking';
import {
  loadStripe,
  Stripe,
  StripeElementLocale,
  StripeElements,
  StripePaymentElement,
} from '@stripe/stripe-js';
import {
  STRIPE_PAYMENT_ELEMENT_ID,
  STRIPE_APPEARANCE,
} from 'teamtailor/constants/stripe';

export default class AddPaymentMethodModalComponent extends Component<PaymentModalInterface> {
  @service declare current: Current;

  get company(): CompanyModel {
    return this.current.company;
  }

  get currentLanguage() {
    return this.current.locale;
  }

  @tracked declare elements?: StripeElements;

  @tracked declare stripe?: Stripe | null;

  @tracked declare paymentElement?: StripePaymentElement;

  @tracked
  consentChecked = false;

  @tracked
  errorMessage = '';

  @tracked
  isFormLoaded = false;

  paymentElementId = STRIPE_PAYMENT_ELEMENT_ID;

  @action
  resetModal() {
    this.errorMessage = '';
    this.isFormLoaded = false;
  }

  @action
  async didInsertComponent() {
    this.resetModal();
    this.stripe = await loadStripe(config.stripe.publishableKey);
    this.elements = this.stripe?.elements({
      clientSecret: this.args.paymentProcessorSetting.stripeClientSecret,
      locale: this.currentLanguage as StripeElementLocale,
      appearance: STRIPE_APPEARANCE,
    });
    this.paymentElement = this.elements?.create('payment');
    this.paymentElement?.mount(`#${this.paymentElementId}`);

    this.paymentElement?.on('ready', () => {
      this.isFormLoaded = true;
    });
  }

  @action
  async handleSave() {
    const result = await this.stripe?.confirmSetup({
      elements: this.elements!,
      redirect: 'if_required',
    });

    if (result?.error) {
      this.errorMessage = result.error.message || '';
    } else {
      this.checkIfCardExists();
    }
  }

  checkIfCardExists() {
    this.stripe
      ?.retrieveSetupIntent(
        this.args.paymentProcessorSetting.stripeClientSecret
      )
      .then(({ setupIntent }) => {
        switch (setupIntent?.status) {
          case 'succeeded': {
            this.args.paymentProcessorSetting.status = 'pending';
            this.args.onCloseModal();
            break;
          }
        }
      });
  }
}
