/* import __COLOCATED_TEMPLATE__ from './field.hbs'; */
import Model from '@ember-data/model';
import { get, set, action } from '@ember/object';
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { isChangeset } from 'validated-changeset';
import { isEmpty, isNone } from '@ember/utils';

import {
  AllowedSizes,
  ALLOWED_SIZES,
  DEFAULT_SIZE,
} from 'teamtailor/constants/form';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { provide } from 'ember-provide-consume-context';

type Args = {
  model?: Model;
  modelField?: string;
  value?: unknown;
  errors?: Model['errors'];
  size?: AllowedSizes;
  label?: string;
  onChange?: (event: Event) => void;
  onValueDidChange?: (arg: {
    value: unknown;
    model?: Model;
    modelField: string;
  }) => void;
};

export class FieldContext {
  constructor(public fieldComponent: FormFieldComponent) {}

  get fieldElementId() {
    return `${this.fieldComponent.id}-element`;
  }

  get fieldLabelId() {
    return `${this.fieldComponent.id}-field-label`;
  }

  get ariaLabelledbyArray() {
    const out = [];

    if (this.fieldComponent.args.label) {
      out.push({
        name: 'fieldLabelId',
        id: this.fieldLabelId,
      });
    }

    return out;
  }

  get ariaLabelledby() {
    return this.ariaLabelledbyArray.map(({ id }) => id).join(' ');
  }
}

export default class FormFieldComponent extends Component<Args> {
  @tracked id = guidFor(this);

  @provide('core/form/field')
  context = new FieldContext(this);

  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Field @size', DEFAULT_SIZE);
    return this.args.size ?? DEFAULT_SIZE;
  }

  get isChangeset(): boolean {
    return !isEmpty(this.model) && isChangeset(this.model);
  }

  get changesetErrors(): string[] | null {
    if (
      !this.isChangeset ||
      !this.model?.errors ||
      isEmpty(this.args.modelField)
    ) {
      return null;
    }

    return this.model.errors.filter(({ key }) => key === this.args.modelField);
  }

  get errors() {
    return (
      this.args.errors ??
      (!this.isChangeset &&
      this.model &&
      this.args.modelField &&
      !isChangeset(this.model)
        ? get(this.model, 'errors').errorsFor(this.args.modelField)
        : null)
    );
  }

  @action
  handleInput(e: KeyboardEvent) {
    if (e.target instanceof HTMLInputElement) {
      this.value = e.target.value;
    }
  }

  get value() {
    return (
      this.args.value ??
      (this.model && this.args.modelField
        ? get(this.model, this.args.modelField as keyof typeof this.model)
        : null)
    );
  }

  set value(value: unknown) {
    if (this.model && this.args.modelField && !isNone(value)) {
      set(
        this.model,
        this.args.modelField as keyof typeof this.model,
        value as string // ts is not great att inferring types here so lets just say its a string..
      );
      this.args.onValueDidChange?.({
        modelField: this.args.modelField,
        model: this.args.model,
        value,
      });
    }
  }

  get model() {
    return this.args.model;
  }
}
