/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { EkEvent, keyResponder, onKey } from 'ember-keyboard';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';
import { CandidateModel, JobApplicationModel } from 'teamtailor/models';
import { SegmentsInfinityModel } from 'teamtailor/controllers/candidates/segment';
import IntlService from 'ember-intl/services/intl';
import ArrayProxy from '@ember/array/proxy';
import { KeyCodes } from 'teamtailor/constants/key-codes';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

interface CandidateModalNewMainBrowseCandidatesSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      browseModels:
        | CandidateModel[]
        | JobApplicationModel[]
        | SegmentsInfinityModel
        | null;
      browseModelsTotal: number;
      browsePath: string;
      candidate: CandidateModel;
      keyboardPriority: number;
      activeTab?: string;
      anonymous?: boolean;
      browseAction?: () => void;
    };
  };
}

@keyResponder
export default class CandidateModalNewMainBrowseCandidates extends Component<CandidateModalNewMainBrowseCandidatesSignature> {
  @service declare router: RouterService;
  @service declare current: Current;
  @service declare intl: IntlService;

  declare keyboardPriority: number;
  declare previousButton: HTMLButtonElement;
  declare nextButton: HTMLButtonElement;

  constructor(
    owner: unknown,
    args: CandidateModalNewMainBrowseCandidatesSignature['Args']['Named']
  ) {
    super(owner, args);
    this.keyboardPriority = this.args.keyboardPriority + 1;
  }

  get browseModels() {
    if (this.isBrowsingCandidates) {
      // Just say its an ArrayProxy, cause TS doesn't understand SegmentsInfinityModel implements/extends that.
      return this.args.browseModels as unknown as ArrayProxy<CandidateModel>;
    }

    return this.args.browseModels
      ? (this.args.browseModels as JobApplicationModel[])
      : undefined;
  }

  @onKey(KeyCodes.ARROW_RIGHT)
  goToNextCandidate(e: KeyboardEvent, ekEvent: EkEvent) {
    if (this.nextCandidate && this.allowKeyboardNavigation) {
      e.preventDefault();
      this.nextButton.click();
    }
    ekEvent.stopPropagation();
  }

  @onKey(KeyCodes.ARROW_LEFT)
  goToPreviousCandidate(e: KeyboardEvent, ekEvent: EkEvent) {
    if (this.previousCandidate && this.allowKeyboardNavigation) {
      e.preventDefault();
      this.previousButton.click();
    }
    ekEvent.stopPropagation();
  }

  @cached
  get currentCandidateIndex() {
    if (this.browseModels?.length) {
      const idAttribute = this.isBrowsingCandidates ? 'id' : 'candidateId';

      const candidateId = this.isBrowsingCandidates
        ? this.args.candidate.id
        : parseInt(this.args.candidate.id, 10);

      const index = this.browseModels.mapBy(idAttribute).indexOf(candidateId);

      return index;
    }

    return undefined;
  }

  get currentCandidateIndexAsPage() {
    return this.currentCandidateIndex !== undefined
      ? this.currentCandidateIndex + 1
      : undefined;
  }

  get nextCandidate() {
    if (this.currentCandidateIndex !== undefined && this.browseModels) {
      const model = this.browseModels.objectAt(this.currentCandidateIndex + 1);

      return this.isBrowsingCandidates
        ? model
        : get(model as JobApplicationModel, 'candidate');
    }
  }

  get nextCandidateName() {
    if (this.args.anonymous) {
      return this.intl.t('candidates.candidate.candidate_modal.next_candidate');
    } else {
      return get(this.nextCandidate as CandidateModel, 'nameOrEmail');
    }
  }

  get previousCandidate() {
    if (this.currentCandidateIndex !== undefined && this.browseModels) {
      const model = this.browseModels.objectAt(this.currentCandidateIndex - 1);

      return this.isBrowsingCandidates
        ? model
        : get(model as JobApplicationModel, 'candidate');
    }
  }

  get previousCandidateName() {
    if (this.args.anonymous) {
      return this.intl.t(
        'candidates.candidate.candidate_modal.previous_candidate'
      );
    } else {
      return get(this.previousCandidate as CandidateModel, 'nameOrEmail');
    }
  }

  get allowKeyboardNavigation() {
    const element = document.activeElement as HTMLElement;

    if (element.isContentEditable) {
      return false;
    }

    if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
      return (element as HTMLInputElement).disabled;
    }

    return true;
  }

  get isBrowsingCandidates() {
    if (!this.args.browseModels || this.args.browseModelsTotal < 2) {
      return false;
    } else {
      return (
        this.args.browsePath === 'video-meeting.candidate' ||
        this.args.browseModels.constructor.name === SegmentsInfinityModel.name
      );
    }
  }

  bindRouteChangeEvent = modifier(() => {
    if (this.args.browseAction) {
      this.router.on('routeDidChange', this.handleRouteChanged);
    }

    return () => {
      if (this.args.browseAction) {
        this.router.off('routeDidChange', this.handleRouteChanged);
      }
    };
  });

  maybeTriggerBrowseAction = () => {
    if (
      this.args.browseAction &&
      this.browseModels &&
      this.currentCandidateIndex !== undefined &&
      this.currentCandidateIndex >= this.browseModels.length - 5
    ) {
      this.args.browseAction();
    }
  };

  handleRouteChanged = (transition: Transition) => {
    if (transition.to.name === transition.from?.name) {
      next(this.maybeTriggerBrowseAction);
    }
  };

  @action
  handleInsertNavigationButton(
    direction: 'previous' | 'next',
    element: HTMLButtonElement
  ) {
    if (direction === 'previous') {
      this.previousButton = element;
    } else {
      this.nextButton = element;
    }
  }
}
