/* import __COLOCATED_TEMPLATE__ from './tags-column.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import CandidateModel from 'teamtailor/models/candidate';
import TagModel from 'teamtailor/models/tag';

interface TagsColumnArgs {
  candidate: CandidateModel;
}

export default class TagsColumn extends Component<TagsColumnArgs> {
  get candidate(): CandidateModel {
    return this.args.candidate;
  }

  get numberOfTagsToShow(): number {
    return get(this.candidate, 'tags').length === 4 ? 4 : 3;
  }

  get visibleTags(): TagModel[] {
    return get(this.candidate, 'sortedTags').slice(
      0,
      get(this, 'numberOfTagsToShow')
    );
  }

  get hiddenTags(): TagModel[] {
    return get(this.candidate, 'sortedTags').slice(
      get(this, 'numberOfTagsToShow'),
      get(this.candidate, 'sortedTags').length
    );
  }

  get tooltipText(): string {
    return this.hiddenTags.mapBy('name').join(', ');
  }

  get moreTagsCount(): number {
    return Math.max(
      0,
      get(this.candidate, 'tags').length - get(this, 'numberOfTagsToShow')
    );
  }
}
