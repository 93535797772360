/* import __COLOCATED_TEMPLATE__ from './message-mode-switch.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { MessageMode } from 'teamtailor/components/candidate-messages/composer';
import { CandidateModel } from 'teamtailor/models';

interface ComposerMessageModeSwitchSignature {
  Args: {
    candidate: CandidateModel;
    messageMode: MessageMode;
    onChangeMessageMode: (messageMode: MessageMode) => void;
  };
}

export default class ComposerMessageModeSwitch extends Component<ComposerMessageModeSwitchSignature> {
  @service declare current: Current;

  get activatedSms() {
    return !!this.current.company.belongsTo('phoneNumber').id();
  }

  get candidateHasValidPhoneNumber() {
    return this.args.candidate.isTwilioNumberValid;
  }

  get smsTooltip() {
    if (!this.args.candidate.phone) {
      return 'components.candidate_messages.no_number';
    } else if (!this.candidateHasValidPhoneNumber) {
      return 'components.candidate_messages.number_not_valid';
    }

    return 'components.candidate_messages.sms';
  }
}
