/* import __COLOCATED_TEMPLATE__ from './language-picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'teamtailor/config/environment';
import { get } from 'teamtailor/utils/get';

export default class LanguagePickerComponent extends Component {
  @service intl;
  @service flipper;

  get supportedLocales() {
    const locales = config.supportedLocales;
    if (
      get(this.flipper, 'translator') &&
      !locales.includes(config.translationLocale)
    ) {
      locales.unshiftObject(config.translationLocale);
    }

    return locales;
  }

  get options() {
    const locales = this.supportedLocales.map((locale) => ({
      ...locale,
      name: locale.intlKey ? this.intl.t(locale.intlKey) : locale.name,
    }));

    if (this.args.defaultLocale) {
      return [
        {
          value: null,
          name: this.args.defaultText,
        },
        ...locales,
      ];
    }

    return locales;
  }

  @action
  onSelect(locale) {
    this.args.onSelect(locale.value);
  }
}
