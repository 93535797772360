/* import __COLOCATED_TEMPLATE__ from './billing-contact-modal.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import CompanyModel from 'teamtailor/models/company';
import { action } from '@ember/object';
import { PaymentModalInterface } from 'teamtailor/controllers/settings/payments/in-app';
import { isEmpty } from '@ember/utils';
import PaymentProcessorSettingModel from 'teamtailor/models/payment-processor-setting';

const requiredSetupFields = [
  'email',
  'name',
  'address',
  'country',
  'state',
  'city',
  'postcode',
] as Array<keyof PaymentProcessorSettingModel>;

export default class BillingContactModalComponent extends Component<PaymentModalInterface> {
  @service declare current: Current;

  get company(): CompanyModel {
    return this.current.company;
  }

  get isValid(): boolean {
    return (
      requiredSetupFields.filter((fieldName) => {
        const pps = this.args.paymentProcessorSetting;
        return isEmpty(pps[fieldName]);
      }).length === 0
    );
  }

  @action
  async handleSave() {
    const paymentProcessorSetting =
      await this.args.paymentProcessorSetting.save();

    if (this.args.onSubmit !== undefined && paymentProcessorSetting.id) {
      this.args.onSubmit();
    }
  }
}
