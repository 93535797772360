/* import __COLOCATED_TEMPLATE__ from './partner-integration.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  CandidateModel,
  JobApplicationModel,
  PartnerActivationModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { later } from '@ember/runloop';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel | undefined;
  onClose: () => void;
}

export default class extends Component<Args> {
  get candidate() {
    return this.args.candidate;
  }

  @action
  handleSendWebhook(
    partnerActivation: PartnerActivationModel,
    webhookData: Record<string, unknown>,
    selectedjobApplication: JobApplicationModel
  ) {
    const job =
      get(this.args.jobApplication, 'job') ||
      get(selectedjobApplication, 'job');

    const partnerResult = get(this.candidate, 'partnerResults').createRecord({
      partnerActivation,
      webhookData,
      webhookConfig: get(partnerActivation, 'cachedConfig'),
      job,
    });

    const promise = partnerResult.save();
    promise.then(() => {
      later(() => {
        this.args.onClose();
      }, 1000);
    });
    return promise;
  }
}
