/* import __COLOCATED_TEMPLATE__ from './question-filter-boolean.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    filter: { boolean?: boolean };
    question: Record<string, unknown>;
    updateFilter: (arg: Record<string, boolean>) => void;
  };
}

type BooleanChoice = {
  text: string;
  value: boolean;
};

export default class QuestionFilterBoolean extends Component<Signature> {
  @service declare intl: IntlService;

  get booleanChoices() {
    return [
      { text: this.intl.t('common.yes'), value: true },
      { text: this.intl.t('common.no'), value: false },
    ];
  }

  @action
  handleRadioUpdate(choice: BooleanChoice) {
    this.args.updateFilter({ boolean: choice.value });
  }
}
