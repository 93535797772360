/* import __COLOCATED_TEMPLATE__ from './move-to-department.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  CandidateModel,
  DepartmentModel,
  DivisionModel,
  RoleModel,
} from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import { NO_DIVISION_ID } from 'teamtailor/models/division';

interface CandidateModalNewActionModalsAddToJobArgs {
  candidate: CandidateModel;
  onClose: () => void;
}

export default class CandidateModalNewActionModalsMoveToDepartment extends Component<CandidateModalNewActionModalsAddToJobArgs> {
  @service declare current: Current;

  @tracked selectedDivision: DivisionModel | null = null;
  @tracked selectedDepartment: DepartmentModel | null = null;
  @tracked selectedRole: RoleModel | null = null;

  get candidate() {
    return this.args.candidate;
  }

  get company() {
    return this.current.company;
  }

  get departments() {
    let divisionId = this.selectedDivision?.id ?? null;
    const departments = get(this.company, 'departments');

    if (!divisionId) {
      return departments;
    }

    if (divisionId === NO_DIVISION_ID) {
      divisionId = null;
    }

    return departments.filter((department) => {
      const division = get(department, 'division');
      return (
        get(division, 'id') === divisionId || department.id === NO_DEPARTMENT_ID
      );
    });
  }

  @action
  selectMoveToDivision(division: DivisionModel) {
    this.selectedDivision = division;
    this.selectedDepartment = null;
    this.selectedRole = null;
  }

  @action
  selectMoveToDepartment(department: DepartmentModel) {
    this.selectedDepartment = department;
    this.selectedRole = null;
  }

  @action
  async handleMove() {
    if (!this.selectedDivision && !this.selectedDepartment) {
      return;
    }

    set(this.candidate, 'division', this.selectedDivision);
    set(this.candidate, 'department', this.selectedDepartment);

    if (this.selectedRole) {
      set(this.candidate, 'role', this.selectedRole);
    }

    await this.candidate.save();

    this.selectedDivision = null;
    this.selectedDepartment = null;
    this.selectedRole = null;
    this.args.onClose();
  }
}
