import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';

export default class ContentEditorHeaderRoute extends Route {
  @service iframePreview;

  async model() {
    const page = await this.modelFor('content.editor');
    const careerSite = await get(page, 'pagePublication.careerSite');
    const careerSiteHeader = await get(careerSite, 'careerSiteHeader');
    const navigationItems = await get(careerSiteHeader, 'navigationItems');
    return { page, careerSiteHeader, navigationItems };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    get(this, 'iframePreview').scrollToSection({
      section: 'header',
    });
  }
}
