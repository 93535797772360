/* import __COLOCATED_TEMPLATE__ from './nurture-statuses.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface OptionsArgs {
  id: string | undefined;
  label: string | undefined;
}

export default class extends Component {
  @service declare intl: IntlService;

  get options(): OptionsArgs[] {
    return [
      { id: 'all', label: this.intl.t('nurture_campaign.status_options.all') },
      {
        id: 'available',
        label: this.intl.t('nurture_campaign.status_options.current'),
      },
      {
        id: 'active',
        label: this.intl.t('nurture_campaign.status_options.active'),
      },
      {
        id: 'draft',
        label: this.intl.t('nurture_campaign.status_options.draft'),
      },
      {
        id: 'paused',
        label: this.intl.t('nurture_campaign.status_options.paused'),
      },
      {
        id: 'archived',
        label: this.intl.t('common.archived'),
      },
    ];
  }
}
