/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import UploadModel from 'teamtailor/models/upload';
import Component from '@glimmer/component';
import Server from 'teamtailor/services/server';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import {
  DEFAULT_STYLE,
  ICON_FILE_TYPE,
} from 'teamtailor/constants/icon_file_type';
import { get } from 'teamtailor/utils/get';
import { tracked } from 'tracked-built-ins';
import { MessageModel } from 'teamtailor/models';

interface CandidateModalNewMainDocumentComponentArgs {
  document: UploadModel;
  onDelete: (document: UploadModel) => void;
}

export default class CandidateModalNewMainDocumentComponents extends Component<CandidateModalNewMainDocumentComponentArgs> {
  @service declare server: Server;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  @tracked virusModalOpen = false;

  get document() {
    return this.args.document;
  }

  get uploader() {
    return (
      get(this.document.user, 'nameOrEmail') ||
      get(this.document.message, 'user.nameOrEmail' as keyof MessageModel) ||
      this.intl.t('candidates.candidate.candidate_modal.the_candidate')
    );
  }

  get icon() {
    if (ICON_FILE_TYPE.hasOwnProperty(this.args.document.fileContentType)) {
      return ICON_FILE_TYPE[
        this.document.fileContentType as keyof typeof ICON_FILE_TYPE
      ];
    }

    return DEFAULT_STYLE;
  }

  @action
  download() {
    const newWindow = window.open();
    (
      this.server.request(this.document.fileUrl) as Promise<{ url: string }>
    ).then(({ url }) => {
      if (!newWindow) {
        return;
      }

      newWindow.location.href = url;
    });
  }

  get isVirusModalOpen() {
    return this.virusModalOpen;
  }

  @action
  handleVirusModalClose() {
    this.virusModalOpen = false;
  }

  @action
  click(event: Event) {
    event.preventDefault();

    if (this.document.isFileInfected) {
      this.virusModalOpen = true;
    } else {
      this.download();
    }
  }
}
