/* import __COLOCATED_TEMPLATE__ from './onboarding.hbs'; */
import WidgetClass from 'teamtailor/components/dashboard/widgets/base/widget-class';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import AboardApolloService from 'teamtailor/services/aboard-apollo';
import { trackedFunction } from 'ember-resources/util/function';
import Current from 'teamtailor/services/current';
import ENV from 'teamtailor/config/environment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import AboardService from 'teamtailor/services/aboard-service';

interface OnboardingData {
  id: string;
  fullName: string;
  avatarUrl: string | null;
  startDate: string;
  totalPercentage: number;
}

interface TeamOnboarding {
  id: string;
  onboardee: {
    avatar: {
      url: string | null;
    } | null;
    fullName: string;
  };
  position: {
    startDate: string;
  };
  summary: {
    totalPercentage: number;
  };
}

interface QueryResult {
  viewer?: {
    teamOnboardings?: TeamOnboarding[];
  };
}

export default class OnboardingWidget extends WidgetClass {
  @service declare aboardApollo: AboardApolloService;
  @service declare aboardService: AboardService;
  @service declare current: Current;

  @tracked aboardLiteOnboardingId: string | null = null;
  @tracked aboardLiteIsOpen: boolean = false;

  aboardData = trackedFunction(this, async (): Promise<OnboardingData[]> => {
    const result = await this.aboardApollo.query<QueryResult>({
      query: gql`
        query {
          viewer {
            ... on CurrentProfile {
              teamOnboardings {
                id
                onboardee {
                  avatar {
                    url
                  }
                  fullName
                }
                position {
                  startDate
                }
                summary {
                  totalPercentage
                }
              }
            }
          }
        }
      `,
    });

    if (result.viewer?.teamOnboardings) {
      const flatData: OnboardingData[] = result.viewer.teamOnboardings.map(
        (onboarding: TeamOnboarding) => ({
          id: onboarding.id,
          fullName: onboarding.onboardee.fullName,
          avatarUrl: onboarding.onboardee.avatar?.url || null,
          startDate: onboarding.position.startDate,
          totalPercentage: onboarding.summary.totalPercentage / 100,
        })
      );

      return flatData.sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );
    }

    return [];
  });

  onboardingUrl = trackedFunction(this, async (): Promise<string> => {
    const token = await this.aboardService.token();
    const { company } = this.current;
    const destinationUrl = encodeURI(
      `${ENV.httpOrHttps}://app.${ENV.ABOARD_HOST}/companies/${company.aboardId}/portal/onboardings`
    );

    return `${ENV.httpOrHttps}://app.${ENV.ABOARD_HOST}/companies/${company.aboardId}/teamtailor/redirects?token=${token}&destination=${destinationUrl}`;
  });

  @action
  openAboardLite(onboardingId: string) {
    this.aboardLiteOnboardingId = onboardingId;
    this.aboardLiteIsOpen = true;
  }

  @action
  closeAboardLite() {
    this.aboardLiteIsOpen = false;
  }
}
