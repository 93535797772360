import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import { task, enqueueTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

const DISABLED_REPLY_TIME = 'Do not show';
const OPTIMAL_TITLE_LENGTH = 80;

const remoteStatuses = ['none', 'temporary', 'hybrid', 'fully'];

const employmentTypes = [
  'none',
  'fully',
  'part',
  'contract',
  'temporary',
  'apprenticeship',
  'internship',
  'volunteer',
];

const employmentLevels = [
  'none',
  'senior',
  'mid',
  'professionals',
  'technicians',
  'sales',
  'administrative',
  'support',
  'craft',
  'operatives',
  'laborers',
  'service',
];

export default class JobsEditPostingController extends Controller {
  @service copilot;
  @service current;
  @service flipper;
  @service intl;
  @service store;
  @service user;

  @tracked showJobNameInput = false;
  @tracked embeddedVideoService = null;
  @tracked cookieDetails = null;

  remoteStatuses = remoteStatuses;
  employmentTypes = employmentTypes;
  employmentLevels = employmentLevels;

  @action async onJobNameInputFocusout() {
    if (this.showJobNameInput) {
      this.showJobNameInput = false;
    }
  }

  get selectedLocationsText() {
    return this.intl.t('jobs.edit.posting.selected_locations_tooltip', {
      locationsCount: this.model.locations.length,
      locationNames: this.model.locations.map((x) => x.nameOrCity).join(', '),
    });
  }

  get companyHasAnyLocations() {
    return this.store.query('location', { per_page: 1 });
  }

  get disabledReplyTime() {
    return this.intl.t('jobs.edit.posting.do_not_show');
  }

  get allCustomFields() {
    return this.current.company.visibleJobCustomFields;
  }

  get sortedCustomFields() {
    return this.allCustomFields.sort((a, b) => {
      const aSort =
        a.constructor.modelName === 'picked-custom-field'
          ? a.customField?.rowOrder || a.customField?.id
          : a.rowOrder || a.id;
      const bSort =
        b.constructor.modelName === 'picked-custom-field'
          ? b.customField?.rowOrder || b.customField?.id
          : b.rowOrder || b.id;
      return aSort < bSort ? -1 : 1;
    });
  }

  get internalCustomFields() {
    return this.sortedCustomFields.filter((cf) => !cf.isExternal);
  }

  get externalCustomFields() {
    return this.sortedCustomFields.filter((cf) => cf.isExternal);
  }

  get replyTimes() {
    let replyTimes = Object.values(
      get(this.jobDetail, 'responseTimeOptions.options')
    );
    replyTimes.unshift(this.disabledReplyTime);
    return replyTimes;
  }

  get selectedReplyTimeValue() {
    const values = get(this.jobDetail, 'responseTimeOptions.options');
    const value = get(this.jobDetail, 'replyTime');
    return value && value !== this.disabledReplyTime
      ? values[value]
      : this.disabledReplyTime;
  }

  get applyButtonText() {
    const text = get(this.jobDetail, 'applyButtonText');
    if (isEmpty(text)) {
      return get(this.current.company, 'applyButtonTextDefault');
    } else {
      return text;
    }
  }

  get divisions() {
    return this.current.company.divisionsWithoutNoDivision;
  }

  get departments() {
    if (get(this, 'model.division.id')) {
      return get(this, 'model.division.departments');
    }

    return this.current.company.departmentsWithoutNoDepartment.filter(
      (department) => department.division.id === null
    );
  }

  get companyButtonColor() {
    return this.current.company.design.color;
  }

  get companyButtonColorStyle() {
    return htmlSafe(`background-color: ${this.companyButtonColor}`);
  }

  get titleFormLabel() {
    const template = get(this, 'model.template');

    if (template) {
      return this.intl.t('models.job.job_ad_title');
    } else {
      return `${this.intl.t('models.job.job_ad_title')} *`;
    }
  }

  get selectedLanguageOption() {
    const languageCode = get(this.jobDetail, 'languageCode');
    return get(this, 'languageOptions').findBy('languageCode', languageCode);
  }

  get isTitleHintShown() {
    const title = get(this, 'model.externalTitle');
    return title.length >= OPTIMAL_TITLE_LENGTH;
  }

  get languageOptions() {
    return get(this.current.company, 'careerSites').map((careerSite) => {
      const { languageCode, isDefault, id } = careerSite;
      return {
        isDefault,
        languageCode,
        id,
      };
    });
  }

  get sortedLanguageOptions() {
    return this.languageOptions.sort((a, b) => {
      if (a.isDefault !== b.isDefault) {
        return a.isDefault ? -1 : 1;
      }

      return a.languageCode.localeCompare(b.languageCode);
    });
  }

  get missingVideoCookie() {
    if (!this.cookieDetails) {
      return false;
    }

    return !this.cookieDetails
      .filterBy('enabled')
      .findBy('id', this.embeddedVideoService);
  }

  get jobDetail() {
    return get(this.model, 'jobDetail');
  }

  get imageWithSetting() {
    return get(this.jobDetail, 'imageWithSetting');
  }

  removeError(attribute, value) {
    const hasError = get(this, `model.errors.${attribute}`);
    if (!hasError) return;

    if (value.length) {
      get(this, 'model.errors').remove(attribute);
    }
  }

  videoServiceFromBody(body) {
    if (!body) return false;
    if (body.includes('</lite-youtube>')) {
      return 'youtube';
    }

    if (body.includes('</lite-vimeo>')) {
      return 'vimeo';
    }
  }

  draftCopilotJobDescription = task(async () => {
    const skills = get(this.jobDetail, 'scorecardPicks')
      .filterBy('topic', 'skills')
      .mapBy('name');
    const traits = get(this.jobDetail, 'scorecardPicks')
      .filterBy('topic', 'personality')
      .mapBy('name');
    const language_code = get(this.jobDetail, 'languageCode');

    await this.copilot.draftJobDescription(
      get(this, 'actions.onChange').bind(this),
      get(this, 'model.externalTitle'),
      skills,
      traits,
      language_code
    );
  });

  fetchCookies = task(async () => {
    const cookieDetails = await this.store.findAll('cookie-detail');
    this.cookieDetails = cookieDetails;
  });

  @action
  onChange(value) {
    set(this.jobDetail, 'body', value);
    this.removeError('body', value);
    this.embeddedVideoService = this.videoServiceFromBody(value);
  }

  @action
  updateReplyTime(value) {
    const values = get(this.jobDetail, 'responseTimeOptions.options');
    let key = Object.keys(values).find((key) => values[key] === value);
    key = key ? key : DISABLED_REPLY_TIME;
    set(this.jobDetail, 'replyTime', key);
  }

  @action
  updateStaticField(fieldType, value) {
    set(this, `model.${fieldType}`, value.id);
  }

  @action
  handlePickImage(image) {
    const { imageWithSetting } = this;
    const pickedImage =
      get(imageWithSetting, 'pickedImage') ||
      this.store.createRecord('picked-image', { imageWithSetting });
    set(pickedImage, 'image', image);
  }

  @action
  handleRemoveImage() {
    const { imageWithSetting } = this;
    const pickedImage = get(imageWithSetting, 'pickedImage');

    if (pickedImage) {
      pickedImage.deleteRecord();
    }

    set(imageWithSetting, 'pickedImage', null);
  }

  @action
  handleAdTemplate(template) {
    set(this, 'model.adTemplate', template);
  }

  @action
  stopPropagationAction(_, event) {
    event && event.stopPropagation && event.stopPropagation();
  }

  @action
  handleLanguageCodeChange(selectedLanguage) {
    set(this.jobDetail, 'languageCode', selectedLanguage.languageCode);
  }

  @action
  handleDivisionChange(selectedDivision) {
    set(this.model, 'division', selectedDivision);
    set(this.model, 'department', null);
  }

  @action
  stopCopilot() {
    this.copilot.stop();
  }

  @action
  handleColleagues(user) {
    if (get(this.jobDetail, 'colleagues').findBy('id', get(user, 'id'))) {
      get(this.jobDetail, 'colleagues').removeObject(user);
    } else {
      get(this.jobDetail, 'colleagues').addObject(user);
    }
  }

  @action
  onInsertJobNameInput(element) {
    element.focus();
  }

  @action
  setUserAsRecruiter(user) {
    this.model.user = user; // This is the actual recruiter being saved to the server
    this.model.recruiter = user; // This one is whats shown in the form
  }
}
