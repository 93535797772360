/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Server from 'teamtailor/services/server';
import { modifier } from 'ember-modifier';
import { IntlService } from 'ember-intl';
import FlashMessagesService from 'teamtailor/services/flash-message';
interface Args {
  isOpen: boolean;
  onClose: () => void;
}

export default class extends Component<Args> {
  @service declare current: Current;
  @service declare server: Server;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessagesService;
  token: string | undefined;

  @tracked emailInput: HTMLInputElement | null = null;
  @tracked email: string = '';
  @tracked codeSent: boolean = false;
  @tracked confirmationCode: string = '';
  @tracked errorMessage: string | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.email = this.current.user.email;
  }

  get isDisabled() {
    return !this.mailHaveChanged || !this.email;
  }

  get mailHaveChanged() {
    return this.email !== this.current.user.email;
  }

  confirmCode() {
    const { user } = this.current;

    if (!this.confirmationCode || !this.token) {
      return;
    }

    this.server
      .memberAction(user, {
        action: 'update_email',
        queryParams: {
          token: this.token,
          code: this.confirmationCode,
        },
      })
      .then((response) => {
        const { success, error } = response as {
          success: boolean;
          error: string | undefined;
        };

        if (!success) {
          if (error) {
            this.flashMessages.error(
              this.intl.t(
                'employees.profile.account_settings.email.please_try_again'
              )
            );
            this.reset();
            return;
          }

          this.errorMessage = this.intl.t(
            'employees.profile.account_settings.email.invalid_code'
          );
          return;
        }

        this.flashMessages.success(
          this.intl.t(
            'employees.profile.account_settings.email.email_successfully_changed_to',
            { email: this.email }
          )
        );
        this.reset();
        user.reload();
      });
  }

  sendEmail() {
    const { user } = this.current;

    this.server
      .memberAction(user, {
        action: 'update_email',
        queryParams: {
          email: this.email,
        },
      })
      .then((response) => {
        const { token, error } = response as {
          token: string;
          error: string | undefined;
        };

        if (error) {
          this.errorMessage = this.intl.t(
            'employees.profile.account_settings.email.email_already_exists'
          );
          return;
        }

        this.token = token;
        this.errorMessage = undefined;
        this.codeSent = true;
      });
  }

  setEmailInputRef = modifier((element: HTMLInputElement) => {
    this.emailInput = element;
  });

  @action
  reset() {
    this.codeSent = false;
    this.email = this.current.user.email;
    this.confirmationCode = '';
    this.errorMessage = undefined;
    this.token = undefined;
    this.args.onClose();
  }

  @action
  changeEmail() {
    if (this.emailInput && !this.emailInput.checkValidity()) {
      this.emailInput.reportValidity();
      throw new Error('Invalid email');
    }

    if (!this.email) {
      return;
    }

    if (!this.codeSent) {
      this.sendEmail();
    } else {
      this.confirmCode();
    }
  }
}
