/* import __COLOCATED_TEMPLATE__ from './location.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import LocationModel from 'teamtailor/models/location';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { EmployeeBulkArgs } from 'teamtailor/constants/employee';
import CompanyModel from 'teamtailor/models/company';

export default class BulkActionsEmployeesLocationComponent extends Component<EmployeeBulkArgs> {
  @service declare current: Current;

  @tracked location: LocationModel | null = null;

  get company(): CompanyModel {
    return this.current.company;
  }

  @action
  onClose(): void {
    this.args.onClose();
    this.location = null;
  }

  @action
  handleBulkChange(): void {
    const data = {
      location_id: this.location?.id,
    };
    this.args.handleBulkChange(data, 'bulk_location');
    this.onClose();
  }
}
