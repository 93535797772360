/* import __COLOCATED_TEMPLATE__ from './map.hbs'; */
import Component from '@glimmer/component';
import { getProperties } from '@ember/object';

export default class FormLocationMap extends Component {
  get options() {
    const { lat, long: lng } = getProperties(this.args.location, 'lat', 'long');

    return {
      mapOptions: {
        center: {
          lat,
          lng,
        },

        zoom: 13,
        zoomControl: this.args.zoomControl,
        doubleClickZoom: this.args.doubleClickZoom,
        scrollWheelZoom: this.args.scrollWheelZoom,
      },

      markerOptions: [lat, lng],
    };
  }
}
