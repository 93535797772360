/* import __COLOCATED_TEMPLATE__ from './user-image-group.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import UserModel from 'teamtailor/models/user';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import {
  DEFAULT_SIZE,
  ALLOWED_SIZES,
  AllowedSizes,
} from 'teamtailor/constants/avatar';
import TimeFormatService from 'teamtailor/services/time-format';

interface Args {
  users: UserModel[];
  size?: AllowedSizes;
  limit?: number;
  tooltipSide?: string;
  totalUsers?: number;
  tooltipText?: string;
}

const DEFAULT_LIMIT = 3;
const MAX_LIMIT = 8;

export default class CoreUserImageGroup extends Component<Args> {
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;

  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'UserImage @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get limit(): number {
    const limitCount = this.args.limit ?? DEFAULT_LIMIT;

    const limit =
      limitCount > MAX_LIMIT
        ? MAX_LIMIT
        : limitCount < DEFAULT_LIMIT
          ? MAX_LIMIT
          : limitCount;

    if (limit + 1 === this.args.users.length) {
      return limit + 1;
    } else {
      return limit;
    }
  }

  get visibleUsers(): UserModel[] {
    if (this.limit) {
      return this.args.users.slice(0, this.limit);
    } else {
      return this.args.users;
    }
  }

  get nonVisibleUsers() {
    const firstIndexOfHiddenUsers = this.visibleUsers.length;
    const lastIndex = firstIndexOfHiddenUsers + DEFAULT_LIMIT;
    return this.args.users.slice(firstIndexOfHiddenUsers, lastIndex);
  }

  get hiddenNumberOfUsersInTooltip() {
    return this.limit < 5
      ? this.totalUsers - this.limit
      : this.totalUsers - this.limit - DEFAULT_LIMIT;
  }

  get hasMore(): boolean {
    return this.limit > 0 && this.totalUsers > this.limit;
  }

  get moreUserNames() {
    if (this.limit < 5) {
      return this.visibleUsers.mapBy('nameOrEmail');
    } else {
      return this.nonVisibleUsers.mapBy('nameOrEmail');
    }
  }

  get visibleUserNames(): string {
    return (
      new Intl.ListFormat(this.timeFormat.locale)
        .format(this.moreUserNames.compact())
        .replace(/,(?=[^,]+$)/, '') || ''
    );
  }

  get totalUsers(): number {
    return this.args.totalUsers ?? this.args.users.length;
  }
}
