import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ApolloService from 'ember-apollo-client/services/apollo';
import { IntlService } from 'ember-intl';
import {
  DashboardWidgetModel,
  DepartmentModel,
  DivisionModel,
  LocationModel,
} from 'teamtailor/models';
import { IJob } from 'teamtailor/components/meeting/types';
import { ModelFrom } from 'teamtailor/utils/type-utils';

import EmployeeRoute, {
  JobsConnection,
  QueryParams,
} from 'teamtailor/routes/employee';
import { TaskForAsyncTaskFunction } from 'ember-concurrency';

const emojis = {
  morning: '☀️',
  afternoon: '🍵',
  evening: '✨',
  default: '💞',
} as const;

export default class EmployeeController extends Controller {
  declare model: ModelFrom<EmployeeRoute>;

  @service declare apollo: ApolloService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  queryParams = [
    'page',
    'query',
    {
      divisionId: 'division_id',
    },
    {
      departmentId: 'department_id',
    },
    {
      locationId: 'location_id',
    },
  ];

  @tracked divisionId: DivisionModel['id'] | null = null;
  @tracked departmentId: DepartmentModel['id'] | null = null;
  @tracked locationId: LocationModel['id'] | null = null;
  @tracked page = 1;
  @tracked perPage = 5;
  @tracked query: string | null = null;
  @tracked timeSlot: keyof typeof emojis = 'morning';
  @tracked totalAmountOfJobs = 0;
  @tracked useCardView = false;
  @tracked declare fetchTask: TaskForAsyncTaskFunction<
    unknown,
    (params: QueryParams) => Promise<JobsConnection>
  >;

  get emoji() {
    return emojis[this.timeSlot];
  }

  get greeting() {
    return this.intl.t(`employee.greetings.${this.timeSlot}`);
  }

  get totalCount() {
    return this.model.jobsConnection.totalCount;
  }

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  @action
  handleWidgetSettingsClick(widget: DashboardWidgetModel) {
    this.router.transitionTo('employee.dashboard-widget.edit', widget);
  }

  @action
  handleQueryChange(query: string) {
    this.page = 1;
    this.query = query;
  }

  @action
  handlePaginationChange(page: number) {
    this.page = page;
  }

  @action
  handleSearchFilterChange(
    attribute: 'locationId' | 'departmentId' | 'divisionId',
    model?: LocationModel | DepartmentModel | DivisionModel | null
  ) {
    this.page = 1;
    const modelId = model?.id ?? null;

    if (attribute == 'divisionId' && this.divisionId != modelId) {
      this.departmentId = null;
    }

    this[attribute] = modelId;
  }

  @action
  handleShareClick(job: IJob) {
    this.router.transitionTo('employee.share-job', {
      queryParams: { job_id: job.id },
    });
  }

  @action
  handleReferClick(job: IJob) {
    this.router.transitionTo('employee.referral', {
      queryParams: { jobId: job.id },
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    employee: EmployeeController;
  }
}
