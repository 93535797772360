/* import __COLOCATED_TEMPLATE__ from './question-filter-date.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

type QuestionFilters = {
  date?: string;
};

interface QuestionFilterNumberArgs {
  updateFilter({ date }: { date: string }): void;
  filter: QuestionFilters;
}

export default class QuestionFilterNumber extends Component<QuestionFilterNumberArgs> {
  get value(): string {
    return this.args.filter.date ?? '';
  }

  set value(val: string) {
    this.args.filter.date = val;
  }

  @action
  updateFilter(): void {
    this.args.updateFilter({ date: this.value.toString() });
  }
}
