/* import __COLOCATED_TEMPLATE__ from './hotel-career.hbs'; */
import PromotionBaseClass from './promotion-base';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';

export default class extends PromotionBaseClass {
  @service declare current: Current;

  @tracked checkoutData?: Record<string, string>;

  get stepstoneDeDescriptionWithDefault() {
    const promotionDescription = get(this.promotion, 'stepstoneDeDescription');
    const carreerSiteAbout = get(
      this.current.company.defaultCareerSite,
      'about'
    );

    return promotionDescription || carreerSiteAbout;
  }

  get isExistingAgreement() {
    return get(this.promotion, 'channel.channelActivation.existingAgreement');
  }

  get hotelCareerEmployments() {
    return get(this, 'store').peekAll(
      'promotion-categories/hotel-career-employment'
    );
  }
}
