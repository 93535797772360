/* import __COLOCATED_TEMPLATE__ from './trigger-prediction-form.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import JobModel from 'teamtailor/models/job';
import StageModel from 'teamtailor/models/stage';
import TriggerPredictionModel from 'teamtailor/models/trigger/prediction';
import { get } from 'teamtailor/utils/get';

type TriggerPredictionFormArgs = {
  model: TriggerPredictionModel;
  job: JobModel;
};

export default class TriggerPredictionForm extends Component<TriggerPredictionFormArgs> {
  get trigger() {
    return this.args.model;
  }

  get job() {
    return this.args.job;
  }

  get stage() {
    return this.trigger.stage;
  }

  get stageOptions() {
    const jobStages = get(this.job, 'stages');

    return jobStages.filter((stage: StageModel) => {
      return get(stage, 'id') && get(stage, 'id') !== get(this.stage, 'id');
    });
  }

  @action
  handleSetProceedStage(stage: StageModel) {
    set(this.trigger, 'proceedStage', stage);
  }
}
