/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import { CandidateModel } from 'teamtailor/models';
import CookieConsentService from 'teamtailor/services/cookie-consent';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import { modifier } from 'ember-modifier';

interface Args {
  candidate: CandidateModel;
  anonymous: boolean;
}

export default class extends Component<Args> {
  @service declare current: Current;
  @service declare cookieConsent: CookieConsentService;

  @tracked showLinkedinWidget = false;

  get widgetId() {
    const integration = get(this.linkedinSetting, 'content.integrationSetup');
    const anonymousStage = this.args.anonymous;
    const integrationEnabled = get(this.linkedinSetting, 'rscEnabled');

    if (integration && !anonymousStage && integrationEnabled) {
      const findContractWithPlugin = Object.keys(integration).filter(
        (id) => integration[id].PROFILE_PLUGIN
      );
      const [contractWithPluginId] = findContractWithPlugin;

      if (
        contractWithPluginId !== undefined &&
        integration[contractWithPluginId]
      ) {
        return integration[contractWithPluginId].PROFILE_PLUGIN.context;
      }
    }

    return null;
  }

  get linkedinSetting() {
    return get(this.current, 'company.linkedinsetting');
  }

  showWidget = modifier((_element, [_candidate]) => {
    this.showLinkedinWidget = false;
    later(() => {
      this.showLinkedinWidget = true;
    }, 100);
  });
}
