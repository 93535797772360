/* import __COLOCATED_TEMPLATE__ from './edit-image.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class MediaLibraryEditImageComponent extends Component {
  @service flashMessages;
  @service intl;
  @service imageTags;

  @action
  save(changeset) {
    return changeset.save().then(() => {
      this.flashMessages.success(
        this.intl.t('components.media_library.image_saved')
      );
      this.imageTags.refresh();
      this.args.onModalClose();
    });
  }

  @action
  handleTagSelect(changeset, tag) {
    toggleInList(changeset.tags, tag);
  }
}
