/* import __COLOCATED_TEMPLATE__ from './trigger-todo-form.hbs'; */
import Component from '@glimmer/component';
import { action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class TriggerTodoForm extends Component {
  @service flashMessages;
  @service intl;
  @service store;

  @tracked editingTodo = null;
  @tracked editingTodoIndex = null;

  get todos() {
    const { todos } = this.args.model;

    return todos.map((todo) => {
      if (get(todo, 'assignee.id')) {
        return todo;
      }

      todo.assignee = todo.assigneeId
        ? this.store.findRecord('user', todo.assigneeId, {
            backgroundReload: false,
          })
        : null;

      return todo;
    });
  }

  get headerText() {
    if (this.args.model.onReject) {
      return this.intl.t('mixins.trigger_form.header_text_on_reject', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    } else {
      return this.intl.t('mixins.trigger_form.header_text_on_move', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    }
  }

  @action
  handleCreateTodo(todo) {
    if (isEmpty(todo.value.trim())) {
      return;
    }

    const assigneeId = get(todo.assignee, 'id') || null;
    const daysFromNow = todo.relativeDueDays;
    this.args.model.todos = [
      ...this.args.model.todos,
      { ...todo, assigneeId, daysFromNow },
    ];

    return true;
  }

  @action
  deleteTodo(index) {
    this.args.model.todos.removeAt(index);
  }

  @action
  editTodo(index, todo) {
    this.editingTodoIndex = index;
    this.editingTodo = todo;
  }

  @action
  saveTodo({ value, description, assignee, relativeDueDays }) {
    const daysFromNow = relativeDueDays;
    const assigneeId = assignee ? get(assignee, 'id') : null;
    setProperties(this.editingTodo, {
      assignee,
      assigneeId,
      daysFromNow,
      description,
      relativeDueDays,
      value,
    });
  }
}
