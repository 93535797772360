/* import __COLOCATED_TEMPLATE__ from './search-filters.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { isArray } from '@ember/array';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';

export default class SearchFilterComponent extends Component {
  @service current;
  @service store;
  @service intl;
  @service router;
  @service permissions;

  fetchedTags = [];

  nullValue = null;
  ratings = [
    { name: '*', id: '1' },
    { name: '**', id: '2' },
    { name: '***', id: '3' },
    { name: '****', id: '4' },
    { name: '*****', id: '5' },
  ];

  get controller() {
    return this.args.stagesController;
  }

  get job() {
    return this.args.job;
  }

  get jobDetail() {
    return this.job.jobDetail;
  }

  get partners() {
    return get(this.jobDetail, 'partners');
  }

  get questions() {
    if (this.permissions.has('job/update')) {
      return get(this.jobDetail, 'questions');
    } else {
      return get(this.jobDetail, 'questions')
        .slice()
        .filter((question) => !get(question, 'isPrivate'));
    }
  }

  get pickedQuestions() {
    return get(this.jobDetail, 'pickedQuestions');
  }

  get sortedQuestionFilters() {
    return this.controller.questionFilters.sortBy('question_id');
  }

  get locations() {
    return get(this.job, 'locations');
  }

  get dateToday() {
    return new Date();
  }

  get nonSelectedQuestions() {
    if (isArray(this.controller.questionFilters)) {
      let selectedIds = this.controller.questionFilters.mapBy('question_id');
      return this.questions.filter(function (question) {
        return !selectedIds.includes(get(question, 'id'));
      });
    } else {
      return this.controller.questions;
    }
  }

  get selectedPartner() {
    return this.partners.findBy('id', this.controller.partner_id);
  }

  get routeLink() {
    if (this.args.isOnTriggersPage) {
      return 'jobs.job.stages.stage-triggers.index';
    } else {
      return 'jobs.job.stages.index';
    }
  }

  selectedTags = trackedFunction(this, async () => {
    if (isEmpty(this.tagNames)) {
      return this.controller.tags;
    }

    if (isEmpty(this.fetchedTags)) {
      await this.fetchMissingTags();
    }

    return this.controller.tags.map((selected) => {
      if (this.tagNames.includes(selected)) {
        return this.fetchedTags.findBy('name', selected);
      } else {
        return selected;
      }
    });
  });

  get tagNames() {
    return this.controller.tags.filter((tag) => {
      return typeof tag === 'string';
    });
  }

  get filterByOptions() {
    return [
      {
        value: this.nullValue,
        text: this.intl.t('components.search_filters.all'),
      },
      {
        value: 'me',
        text: this.intl.t('components.search_filters.rated_by_me'),
      },
      {
        value: 'not-me',
        text: this.intl.t('components.search_filters.not_rated_by_me'),
      },
      {
        value: 'not-rated',
        text: this.intl.t('components.search_filters.not_yet_rated'),
      },
    ];
  }

  get filterSwitchValue() {
    if (this.args.isOnTriggersPage) {
      return this.router.currentRoute.queryParams.onReject === 'true'
        ? 'rejected'
        : 'active';
    } else {
      return this.controller.showRejected ? 'rejected' : 'active';
    }
  }

  async fetchMissingTags() {
    const response = await this.store.query('tag', {
      name: this.tagNames,
      taggableType: 'Candidate',
    });
    this.fetchedTags = response.toArray();
  }

  @action
  async selectPartner(partner) {
    this.controller.partner_results_status = null;
    this.controller.partner_results_min_score = null;
    this.controller.partner_results_max_score = null;
    this.controller.partner_id = partner?.id;
  }

  @action
  selectQuestion(question) {
    if (question) {
      let questionFilterObject = {
        question_id: get(question, 'id'),
        job_id: get(this.job, 'id'),
      };

      if (!isEmpty(this.controller.questions)) {
        let pickedQuestion = this.controller.questions.findBy(
          'questionId',
          get(question, 'id')
        );
        if (pickedQuestion) {
          questionFilterObject.picked_question_id = get(pickedQuestion, 'id');
        }
      }

      this.controller.questionFilters.pushObject(questionFilterObject);
    }
  }

  @action
  selectLocation(location) {
    this.controller.location_id = location?.id;
  }

  @action
  toggleRating(rating) {
    toggleInList(this.controller.ratings, rating);
  }

  @action
  selectTags(tag) {
    toggleInList(this.fetchedTags, tag);
    toggleInList(this.controller.tags, tag.name);
  }

  @action
  onPartnerFilterChange(params) {
    this.controller.partner_results_status = params.partner_results_status;
    this.controller.partner_results_min_score =
      params.partner_results_min_score;
    this.controller.partner_results_max_score =
      params.partner_results_max_score;
  }

  @action
  changeFilterBy(option) {
    this.controller.reviewed_by = option.value;
  }

  @action
  toggleFilter() {
    const active = this.filterSwitchValue === 'active';
    const queryParams = this.args.isOnTriggersPage
      ? { onReject: `${active}` }
      : { showRejected: `${active}` };

    this.router.transitionTo(this.routeLink, {
      queryParams,
    });
  }
}
