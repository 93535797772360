/* import __COLOCATED_TEMPLATE__ from './dashboard.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, setProperties } from '@ember/object';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { onKey } from 'ember-keyboard';
import { underscore } from '@ember/string';
import recordRemover from 'teamtailor/utils/record-remover';
import { CUSTOM_WIDGET_SETTINGS } from 'teamtailor/constants/dashboard';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { modifier } from 'ember-modifier';

const COLUMNS = 3;
export default class DashboardComponent extends Component {
  @service current;
  @service dragSort;
  @service intl;
  @service pusher;
  @service router;
  @service store;
  @service intercom;
  @service flashMessages;
  @service permissions;
  @service flipper;

  @tracked channel = null;
  @tracked isEditing = false;
  @tracked isNotDraggingFromLibrary = false;
  @tracked newWidget = null;

  @tracked isGifSettingsOpen = false;

  get libraryIsDroppable() {
    return this.isNotDraggingFromLibrary && this.dragSort.isDragging;
  }

  get company() {
    return this.current.company;
  }

  get user() {
    return this.current.user;
  }

  get libraryWidgets() {
    return this.availableLibraryWidgets.filter(
      (widget) => !widget.hasPickedDashboardWidgets
    );
  }

  disableDrag(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  get sortedAvailableWidgets() {
    return this.company.dashboardWidgets
      .slice()
      .toSorted((widget1, widget2) => widget1.title - widget2.title);
  }

  get companyPickedDashboardWidgetIds() {
    return this.companyPickedDashboardWidgets.map(
      (pickedWidget) => pickedWidget.dashboardWidgetId
    );
  }

  get userPickedDashboardWidgets() {
    return this.filterByTarget(get(this.user, 'pickedDashboardWidgets'));
  }

  get companyPickedDashboardWidgets() {
    return this.filterByTarget(get(this.company, 'pickedDashboardWidgets'));
  }

  get canAccessLibrary() {
    return this.args.canAccessLibrary !== undefined
      ? this.args.canAccessLibrary
      : true;
  }

  get disableUserArea() {
    return this.args.disableUserArea || false;
  }

  get isSaving() {
    return (
      this.removePickedDashboardWidgetTask.isRunning ||
      this.savePickedDashboardWidgetTask.isRunning
    );
  }

  get notSaving() {
    return !this.isSaving;
  }

  get availableLibraryWidgets() {
    return this.filterByTarget(this.sortedAvailableWidgets);
  }

  get columns() {
    return this.columnsArray().map((i) => this.getColumnWidgets(i));
  }

  get companyColumns() {
    return this.columnsArray().map((i) =>
      this.getOwnerPickedDashboardWidgets(i, 'companyPickedDashboardWidgets')
    );
  }

  get userColumns() {
    const excludeIds = this.companyPickedDashboardWidgetIds;

    return this.columnsArray().map((i) => {
      return this.getOwnerPickedDashboardWidgets(
        i,
        'userPickedDashboardWidgets',
        excludeIds
      );
    });
  }

  get target() {
    return underscore(this.args.target || 'dashboard');
  }

  get infinityWidgets() {
    if (!this.permissions.has('settings/update_company')) {
      return [];
    }

    return CUSTOM_WIDGET_SETTINGS.filter(({ target }) => target === this.target)
      .map((options) => {
        if (this.args.isEmployeeDashboard && options.name === 'segment') {
          return null;
        }

        return {
          ...options,
          defaultOptions: options,
          libraryComponent: 'dashboard/library-item',
          createOnDragEnd: true,
          title: this.intl.t(
            [
              'dashboard.widgets.',
              options.name.replace('employee-', ''),
              '.title',
            ].join('')
          ),
        };
      })
      .filter(Boolean);
  }

  columnsArray() {
    return Array.from({ length: COLUMNS }, (_, i) => i + 1);
  }

  filterByTarget(collection) {
    return collection.filter((widget) => widget.target === this.target);
  }

  getOwnerPickedDashboardWidgets(
    column,
    pickedDashboardWidgetsAttribute,
    excludeWidgetIds
  ) {
    excludeWidgetIds = excludeWidgetIds || [];
    let widgets =
      pickedDashboardWidgetsAttribute === 'companyPickedDashboardWidgets'
        ? this.companyPickedDashboardWidgets
        : this.userPickedDashboardWidgets;
    widgets = widgets
      .filter((widget) => widget.column === column)
      .filter(
        (pickedDashboardWidget) =>
          !excludeWidgetIds.includes(pickedDashboardWidget.dashboardWidgetId)
      )
      .toSorted((widget1, widget2) => widget1.rowOrder - widget2.rowOrder);

    return this.removeComponentlessWidgets(widgets);
  }

  getColumnWidgets(column) {
    let companyColumnWidgets = this.getOwnerPickedDashboardWidgets(
      column,
      'companyPickedDashboardWidgets'
    );

    let userColumnWidgets = this.getOwnerPickedDashboardWidgets(
      column,
      'userPickedDashboardWidgets',
      this.companyPickedDashboardWidgetIds
    );

    return [...companyColumnWidgets, ...userColumnWidgets];
  }

  removeComponentlessWidgets(pickedDashboardWidgets) {
    return pickedDashboardWidgets.filter((pickedDashboardWidget) => {
      let componentName = `dashboard/widgets/${get(
        pickedDashboardWidget,
        'dashboardWidget.name'
      )}`;
      return getOwner(this).hasRegistration(`component:${componentName}`);
    });
  }

  handleDragStart({ sourceList }) {
    this.isNotDraggingFromLibrary = sourceList !== this.libraryWidgets;
  }

  handleDragEnd() {
    if (this.isNotDraggingFromLibrary) {
      this.isNotDraggingFromLibrary = false;
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.dragSort.off('start', this, this.handleDragStart);
    this.dragSort.off('end', this, this.handleDragEnd);

    if (this.channel) {
      this.channel.unbind('picked-dashboard-widget-updated');
      this.channel.unbind('picked-dashboard-widget-destroyed');
      this.channel = null;
    }

    recordRemover(this.store, 'picked-dashboard-widget');
  }

  isDroppingPersonalSegmentCompanyWide(draggedItem, targetList) {
    const isWidget = draggedItem.constructor.modelName === 'dashboard-widget';

    const widget = isWidget ? draggedItem : get(draggedItem, 'dashboardWidget');
    if (get(widget, 'name') !== 'segment') {
      return false;
    }

    return get(widget, 'segment.companyWide')
      ? false
      : get(this, 'companyColumns').includes(targetList);
  }

  @onKey('Escape')
  closeLibrary() {
    if (this.isEditing) {
      this.isEditing = false;
    }
  }

  removePickedDashboardWidgetTask = dropTask(async (pickedDashboardWidget) => {
    if (get(pickedDashboardWidget, 'company') !== null) {
      let dashboardWidget = get(pickedDashboardWidget, 'dashboardWidget');
      const pickedDashboardWidgets = get(
        dashboardWidget,
        'pickedDashboardWidgets'
      );
      await pickedDashboardWidgets.forEach((widget) => {
        widget.destroyRecord();
      });
    } else {
      await pickedDashboardWidget.destroyRecord();
    }
  });

  savePickedDashboardWidgetTask = dropTask(
    async (pickedDashboardWidget, openGifSettings) => {
      await pickedDashboardWidget.save();

      if (openGifSettings) {
        this.isGifSettingsOpen = true;
      }
    }
  );

  loadWidgets = dropTask(async () => {
    const { target } = this;
    await this.store.query('picked-dashboard-widget', { target });
  });

  handleAfterSaveNewWidget = dropTask(async () => {
    if (this.newWidget) {
      await Promise.all(
        this.newWidget.pickedDashboardWidgets.map((picked) => picked.save())
      );
      this.newWidget = null;
      this.isEditing = false;
    }
  });

  handleInsertElement = modifier(() => {
    this.loadWidgets.perform();

    this.keyboardActivated = true;

    this.dragSort.on('start', this, this.handleDragStart);
    this.dragSort.on('end', this, this.handleDragEnd);

    const channelName = get(this, 'company.mainChannel');
    this.pusher.subscribe(channelName).then((channel) => {
      this.channel = channel;

      channel.bind('picked-dashboard-widget-updated', (data) => {
        if (get(this, 'user.id') !== data.user_id) {
          let { store } = this;
          this.company.reload().then(function () {
            store.pushPayload(data.model);
          });
        }
      });

      channel.bind('picked-dashboard-widget-destroyed', (data) => {
        if (get(this, 'user.id') !== data.user_id) {
          const pickedDashboardWidget = this.store.peekRecord(
            'picked-dashboard-widget',
            data.model.picked_dashboard_widget.id
          );
          if (pickedDashboardWidget) {
            this.store.unloadRecord(pickedDashboardWidget);
          }
        }
      });
    });
  });

  @action
  dragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
    targetArgs,
  }) {
    if (sourceList === targetList && sourceIndex === targetIndex) {
      return undefined;
    }

    if (
      !draggedItem.createOnDragEnd &&
      this.isDroppingPersonalSegmentCompanyWide(draggedItem, targetList)
    ) {
      this.flashMessages.error(
        this.intl.t(
          'dashboard.widgets.segment.cant_drop_personal_segment_in_global_space'
        )
      );
      return undefined;
    }

    let sameList = sourceList === targetList;
    let upwards = targetIndex < sourceIndex;
    let last = targetIndex === targetList.length;
    let notEmpty = targetList.length > 0;
    let rowOrder = 0;
    let rowOrderPosition =
      typeof targetIndex === String ? targetIndex : targetIndex.toString();
    let widgetSibling = targetList[targetIndex];

    if (sameList) {
      if (widgetSibling) {
        rowOrder = widgetSibling.rowOrder + (upwards ? -1 : 1);
      }
    } else {
      if (widgetSibling) {
        rowOrder = widgetSibling.rowOrder - 1;
      } else if (last && notEmpty) {
        rowOrder = get(targetList[targetList.length - 1], 'rowOrder') + 1;
      }
    }

    if (draggedItem.createOnDragEnd) {
      this.newWidget = this.store.createRecord('dashboard-widget', {
        ...draggedItem.defaultOptions,
        options: { ...draggedItem.defaultOptions.options },
      });
      draggedItem = this.newWidget;
    }

    if (draggedItem.constructor.modelName === 'dashboard-widget') {
      draggedItem = this.store.createRecord('picked-dashboard-widget', {
        dashboardWidget: draggedItem,
        column: null,
        rowOrderPosition,
        rowOrder,
      });
    }

    if (isEmpty(this.newWidget)) {
      sourceList = [
        ...sourceList.slice(0, sourceIndex),
        ...sourceList.slice(sourceIndex + 1),
      ];
    }

    targetList = [
      ...targetList.slice(0, targetIndex),
      draggedItem,
      ...targetList.slice(targetIndex),
    ];

    const companyOrUser = targetArgs.split(':')[0];
    const column = targetArgs.split(':')?.[1];

    if (companyOrUser === 'company') {
      setProperties(draggedItem, {
        column,
        company: this.company,
        rowOrderPosition,
        rowOrder,
        user: null,
      });

      const shouldOpenGifSettings =
        get(draggedItem, 'dashboardWidget.name') === 'gif' &&
        this.permissions.has('settings/update_company');

      if (isEmpty(this.newWidget)) {
        return this.savePickedDashboardWidgetTask.perform(
          draggedItem,
          shouldOpenGifSettings
        );
      }
    } else if (companyOrUser === 'user') {
      setProperties(draggedItem, {
        column,
        company: null,
        rowOrderPosition,
        rowOrder,
        user: get(this, 'user'),
      });
      if (isEmpty(this.newWidget)) {
        return this.savePickedDashboardWidgetTask.perform(draggedItem);
      }
    }

    if (isEmpty(this.newWidget)) {
      return this.removePickedDashboardWidgetTask.perform(draggedItem);
    } else {
      this.newWidget.pickedDashboardWidgets = [
        ...this.newWidget.pickedDashboardWidgets,
        draggedItem,
      ];
    }
  }

  @action
  toggleEditing() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.intercom.hideWidget();
    } else {
      this.intercom.showWidget();
    }
  }

  @action
  determineLibraryPosition({ draggedItem, items }) {
    const draggedItemTitle = get(draggedItem, 'dashboardWidget.title');
    const allItems = items.map((item) => item.title);
    allItems.push(draggedItemTitle);
    allItems.sort();
    return allItems.indexOf(draggedItemTitle);
  }

  @action
  handleRemoveWidget(item, items) {
    items.filter((widget) => widget !== item);
    this.removePickedDashboardWidgetTask.perform(item);
  }

  @action
  async handleCloseNewWidget() {
    if (this.newWidget) {
      this.newWidget = null;
      recordRemover(this.store, 'picked-dashboard-widget', 'dashboard-widget');
    }
  }
}
