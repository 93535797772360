import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class Requisition extends ApplicationAdapter {
  urlForUpdateRecord(id, modelName, snapshot) {
    const { adapterOptions } = snapshot;

    let url = `${super.urlForUpdateRecord()}/requisitions/${snapshot.id}`;

    if (adapterOptions && adapterOptions.updateFlow) {
      url += '/update_flow';
    }

    return url;
  }
}
