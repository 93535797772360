/* import __COLOCATED_TEMPLATE__ from './generate-data.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Server from 'teamtailor/services/server';
import Current from 'teamtailor/services/current';
import { dropTask } from 'ember-concurrency';

interface Args {
  model_name: string;
  parent_model_name: string;
  parent_model_id: number;
}

export default class GenerateData extends Component<Args> {
  @service declare current: Current;
  @service declare server: Server;

  maxAmount = 500;
  @tracked show = false;
  @tracked amountToGenerate = null;
  @tracked amountToDelete = null;

  @action toggleShow() {
    this.show = !this.show;
  }

  onClick = dropTask(async (generate: boolean) => {
    await this.server
      .request(
        `/app/companies/${this.current.company.uuid}/api/dev_tools/${
          generate ? 'generate' : 'delete'
        }_dev_data`,
        'POST',
        {
          data: {
            model_name: this.args.model_name,
            number_of_instances: generate
              ? this.amountToGenerate
              : this.amountToDelete,

            parent_model_name: this.args.parent_model_name,
            parent_model_id: this.args.parent_model_id,
          },
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        alert(error);
      });
  });
}
