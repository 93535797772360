/* import __COLOCATED_TEMPLATE__ from './partner.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, waitForProperty } from 'ember-concurrency';

export interface MarketplaceActivationsPartnerArgs {
  provider: string;
  fetchProvider: () => void;
}

export default class MarketplaceActivationsPartnerComponent extends Component<MarketplaceActivationsPartnerArgs> {
  @tracked authComponent = '';

  @action
  async fetchData() {
    this.args.fetchProvider();
    await this.runTask.perform();
  }

  runTask = task(async () => {
    await waitForProperty(this.args, 'provider', (value: string) => {
      return typeof value === 'string';
    });

    this.authComponent = ['deel'].includes(this.args.provider)
      ? 'pusher-auth'
      : 'auth-popup';
  });
}
