/* import __COLOCATED_TEMPLATE__ from './scorecard-criteria-picker.hbs'; */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  ttAlert: service(),
  intl: service(),

  classNames: ['tt-form-group', 'c-scorecard-picker'],

  label: null,
  options: null,
  placeholder: null,
  searchField: null,
  searchPlaceholder: null,
  criterias: null,
  emptyCriteriasText: null,

  groupName: computed(function () {
    // drag list needs a group to disable drag between drag lists
    return Math.random();
  }),

  onChange() {},
  onCreate() {},
  onRemove() {},
  onDragEnd() {},

  stopPropagation(e) {
    e.stopPropagation();
  },

  deleteCriterium: action(function (criterium) {
    this.ttAlert.confirm(
      this.intl.t(
        'components.scorecard_criteria_picker.delete_criterium_warning',
        {
          criterium: criterium.name,
        }
      ),
      async () => {
        await criterium.destroyRecord();
      },
      null,
      {
        confirmButtonText: this.intl.t('common.delete'),
      }
    );
  }),
});
