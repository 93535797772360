/* import __COLOCATED_TEMPLATE__ from './thumbnail-settings-form.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service store;

  @action
  async handlePickImage(image) {
    set(this, 'args.page.thumbnail', image);
    set(this, 'args.page.removeThumbnail', null);
  }

  @action
  handleRemoveImage() {
    set(this, 'args.page.removeThumbnail', true);
    set(this, 'args.page.thumbnail', null);
  }

  @action
  async handleSave() {
    return this.args.page.save();
  }
}
