/* import __COLOCATED_TEMPLATE__ from './viewer.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import IntlService from 'ember-intl/services/intl';
import {
  Calendar,
  DatesSetArg,
  EventApi,
  EventContentArg,
  EventInput,
  CalendarOptions,
  EventMountArg,
  DayHeaderContentArg,
  EventSourceInput,
} from '@fullcalendar/core';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin from '@fullcalendar/interaction';
import { restartableTask, timeout } from 'ember-concurrency';

import { v1 as uuid } from 'ember-uuid';

import TeamtailorApolloService from 'teamtailor/services/apollo';
import { IMeetingEventDateRange } from 'teamtailor/components/meeting/types';
import {
  BookedSlotClass,
  MeetingEventClass,
  UserAttendeeClass,
  CandidateAttendeeClass,
  MeetingRoomAttendeeClass,
  AttendeeType,
} from 'teamtailor/classes/meetings';
import { SelfScheduleClass } from 'teamtailor/classes/meetings/self-schedule';

import AVAILABILITY_QUERY from 'teamtailor/gql/meetings/availability.graphql';
import ATTENDEES_EVENTS_QUERY from 'teamtailor/gql/attendees-events-query.graphql';
import AvailabilityService from 'teamtailor/services/availability';
import TimeFormatService from 'teamtailor/services/time-format';
import TimezonesService from 'teamtailor/services/timezones';
import { SegHierarchy } from '@fullcalendar/core/internal';
import timeGridPlugin from '@fullcalendar/timegrid';

type DateRange = Omit<IMeetingEventDateRange, 'startsAt' | 'endsAt'> & {
  startsAt?: Date | null;
  endsAt?: Date | null;
};

interface IArgs {
  currentMeetingEvent?: MeetingEventClass;
  meetingEvents: MeetingEventClass[];
  onAddBookedSlot(dateRange: DateRange | undefined): void;
  onAddSelfSchedule(selfSchedule: SelfScheduleClass | undefined): void;
  onClose(): void;
  onTimeZoneChange(timeZone: string): void;
  setScheduleMode(scheduleMode: string): void;
  bookedSlot?: BookedSlotClass;
  selfSchedule?: SelfScheduleClass; // TODO: Handle these when initilizing. NOTE – self schedule can't be edited
  selfScheduleEnabled: boolean;
  teamMembers: UserAttendeeClass[];
  bookableMeetingRooms: MeetingRoomAttendeeClass[];
  scheduleMode?: ScheduleMode;
  isEditing?: boolean;
}
interface IAttendeeEvent {
  startsAt: Date;
  endsAt: Date;
  title: string;
}

export enum ScheduleMode {
  Self = 'scheduleSelf',
  SelfAuto = 'scheduleSelfAuto',
  Manual = 'scheduleManual',
}

enum CalendarView {
  Week = 'week',
  WorkWeek = 'work_week',
  Month = 'month',
}

type ViewModeOption = {
  name: string;
  value: CalendarView;
};

const getClosestMinuteStep = (date: Date | null, minuteStep = 5) => {
  return moment(date)
    .startOf('day')
    .add(
      -Math.floor(
        moment(date).startOf('day').diff(date, 'minutes') / minuteStep
      ) * minuteStep,
      'minutes'
    );
};

export default class CalendarViewerComponent extends Component<IArgs> {
  @service declare availability: AvailabilityService;
  @service declare apollo: TeamtailorApolloService;
  @service declare timeFormat: TimeFormatService;
  @service declare timezones: TimezonesService;
  @service declare intl: IntlService;

  @tracked declare calendar: Calendar;
  @tracked dateString = '';
  @tracked week = '';
  @tracked declare scheduleMode: ScheduleMode;
  @tracked declare isDragging: boolean;
  @tracked declare calendarView: ViewModeOption;
  @tracked declare calendarElement: HTMLDivElement;
  @tracked declare isResizing: boolean;
  @tracked declare hoverElement: HTMLDivElement;
  @tracked availableSlots? = 0;

  @tracked declare rawEvents: EventApi[];

  @tracked loadingEvents = false;

  @tracked disabledTimes: { start: Date; end: Date; bufferAware: boolean }[] =
    [];

  @tracked hoveredAttendeeIndex = -1;

  @tracked showInfo = false;

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this.rawEvents = [];

    if (this.args.scheduleMode) {
      this.scheduleMode = this.args.scheduleMode;
    } else {
      if (this.args.bookedSlot) {
        this.scheduleMode = ScheduleMode.Manual;
      }

      if (this.args.selfSchedule) {
        if (this.args.selfSchedule.slotRules) {
          this.scheduleMode = ScheduleMode.SelfAuto;
        } else {
          this.scheduleMode = ScheduleMode.Self;
        }
      }
    }

    this.calendarView = {
      value: CalendarView.Week,
      name: this.intl.t(
        `components.meetings.event_scheduler.${CalendarView.Week}`
      ),
    };
  }

  willDestroy(): void {
    super.willDestroy();
    this.calendar.destroy();
  }

  get disabledTimesWithBuffer() {
    return this.disabledTimes.map((e) => ({
      start: e.bufferAware
        ? moment(e.start)
            .subtract(
              this.currentEvent?.selfSchedule?.bufferBefore ?? 0,
              'minutes'
            )
            .toDate()
        : e.start,

      end: e.bufferAware
        ? moment(e.end)
            .add(this.currentEvent?.selfSchedule?.bufferAfter ?? 0, 'minutes')
            .toDate()
        : e.end,
    }));
  }

  get organizer() {
    return this.currentEvent?.organizer;
  }

  get organizerHasCronofy() {
    return this.organizer?.hasConnectedCalendar ?? false;
  }

  get currentEvent() {
    return this.args.currentMeetingEvent;
  }

  get _users() {
    return (
      this.args.currentMeetingEvent?.meetingEventAttendees.userAttendees || []
    );
  }

  get _candidates() {
    const candidateAttendees =
      this.args.currentMeetingEvent?.meetingEventAttendees.candidateAttendees;
    return (candidateAttendees?.length || 0) > 5
      ? []
      : candidateAttendees || [];
  }

  get _meetingRooms() {
    return (
      this.args.currentMeetingEvent?.meetingEventAttendees
        .meetingRoomAttendees || []
    );
  }

  get attendees() {
    return [...this._users, ...this._meetingRooms, ...this._candidates];
  }

  get teamMembersWithCalendars() {
    return this._users.filter((attendee) => attendee.hasConnectedCalendar);
  }

  get viewModes() {
    return [
      {
        value: CalendarView.Week,
        name: this.intl.t(
          `components.meetings.event_scheduler.${CalendarView.Week}`
        ),
      },
      {
        value: CalendarView.WorkWeek,
        name: this.intl.t(
          `components.meetings.event_scheduler.${CalendarView.WorkWeek}`
        ),
      },
    ];
  }

  get currentTzid() {
    return this.args.currentMeetingEvent?.tzid || '';
  }

  attendeesEvents = async (
    info: {
      start: Date;
      end: Date;
      startStr: string;
      endStr: string;
      timeZone: string;
    },
    successCallback: (events: EventInput[]) => void,
    failureCallback: (error: unknown) => void
  ) => {
    try {
      this.loadingEvents = true;

      const { end, start } = info;

      const mParsedStart = moment(start).subtract(7, 'days');
      const mParsedEnd = moment(end).add(7, 'days');

      const fetchedEvents = await this.fetchAttendeesEvents(
        mParsedStart.toISOString(),
        mParsedEnd.toISOString()
      );

      const fetchedAvailability = await this.fetchAvailability(
        mParsedStart.toISOString(),
        mParsedEnd.toISOString()
      );

      const ownerAvailabilities: Record<string, EventInput[]> = {};
      this._users
        .filter((a) => !a.excludeAvailability)
        .forEach((attendee) => {
          if (attendee.user?.id) {
            ownerAvailabilities[attendee.user.id] = fetchedAvailability.filter(
              (e) => e.ownerId === attendee.user?.id
            );
          }
        });

      const attendeeUnavailability: {
        start: Date;
        end: Date;
        groupId: string;
        display: string;
        classNames: string[];
      }[] = [];

      Object.values(ownerAvailabilities).forEach((ownerAvailability) => {
        const colorIndex = this.attendees.findIndex(
          (a) =>
            (a as UserAttendeeClass).user?.id === ownerAvailability[0]?.ownerId
        );
        this.invertRanges(ownerAvailability, {
          start: moment(start),
          end: moment(end),
        }).forEach((e) => {
          attendeeUnavailability.push({
            start: moment(e.start).toDate(),
            end: moment(e.end).toDate(),
            groupId: `${colorIndex}`,
            display: 'background',
            classNames: [
              'fc-non-business',
              `fc-non-business--attendee-${colorIndex}`,
            ],
          });
        });
      });

      attendeeUnavailability.sort((a, b) => {
        if (a.start < b.start) {
          return -1;
        }

        if (a.start > b.start) {
          return 1;
        }

        if (a.end < b.end) {
          return -1;
        }

        if (a.end > b.end) {
          return 1;
        }

        return 0;
      }); // not fully tested, but it kinda needs to sort this correctly for the next step to work

      const mergedUnavailability = [
        {
          start: new Date('2000-01-01T00:00Z'),
          end: moment().endOf('hour').toDate(),
          groupId: 'disabled',
          display: 'background',
          classNames: [
            'fc-non-business',
            'fc-non-business--all-unavailability',
          ],
        },
      ];

      attendeeUnavailability.forEach((e) => {
        if (mergedUnavailability.length > 0) {
          const prevEvent =
            mergedUnavailability[mergedUnavailability.length - 1];
          if (prevEvent) {
            if (e.start <= prevEvent.end) {
              if (e.end > prevEvent.end) {
                prevEvent.end = e.end;
              }

              return;
            }
          }
        }

        mergedUnavailability.push({
          start: e.start,
          end: e.end,
          groupId: 'disabled',
          display: 'background',
          classNames: [
            'fc-non-business',
            'fc-non-business--all-unavailability',
          ],
        });
      });

      const events = fetchedEvents.map((e) => {
        const colorIndex = this.attendees.findIndex((a) => {
          switch (e.ownerType) {
            case 'User':
              return (a as UserAttendeeClass).user?.id === e.ownerId;
            case 'MeetingRoom':
              return (
                (a as MeetingRoomAttendeeClass).meetingRoom?.id === e.ownerId
              );
            case 'Candidate':
              return (a as CandidateAttendeeClass).candidate?.id === e.ownerId;
          }

          return false;
        });

        return {
          id: uuid(),
          title: '',
          start: moment(e.startsAt).toISOString(),
          end: moment(e.endsAt).toISOString(),

          classNames: [
            'eventBox',
            `eventBox-${colorIndex}`,
            `eventBox-${e.ownerType.toLowerCase()}`,
          ],

          groupId: `${colorIndex}`,
          display: 'background',
          extendedProps: { isEvent: true },
        };
      });

      const segInputs = events.map((event, index) => ({
        index,
        thickness: 1,
        span: {
          start: moment(event.start).unix(),
          end: moment(event.end).unix(),
        },
      }));

      const hierarchy = new SegHierarchy();
      hierarchy.strictOrder = true;
      hierarchy.addSegs(segInputs);
      hierarchy.entriesByLevel.forEach((segsOnLevel, level) => {
        segsOnLevel.forEach((segs) => {
          events[segs.index]?.classNames.push(`eventBoxLevel-${level}`);
        });
      });

      this.disabledTimes = [
        ...events.map((e) => ({
          start: moment(e.start).toDate(),
          end: moment(e.end).toDate(),
          bufferAware: true,
        })),
        ...attendeeUnavailability.map((e) => ({
          start: e.start,
          end: e.end,
          bufferAware: false,
        })),
      ];

      const freeTime = mergedUnavailability.map((e) => ({
        ...e,
        groupId: 'free-time',
        classNames: ['fc-free-time-area'],
        display: 'inverse-background',
      }));

      successCallback([
        ...mergedUnavailability,
        ...attendeeUnavailability,
        ...freeTime,
        ...events,
      ]);
    } catch (err) {
      failureCallback(err);
    } finally {
      this.loadingEvents = false;
    }
  };

  get eventSources() {
    return [
      {
        events: this.attendeesEvents,
        editable: false,
        overlap: true,
        id: 'attendees',
      },
    ];
  }

  get selectStatus() {
    if (this.scheduleMode === ScheduleMode.Self) {
      return this.intl.t(
        'components.meetings.event_scheduler.schedule_info.picked_slots',
        { count: this.rawEvents.length }
      );
    }

    if (this.scheduleMode === ScheduleMode.SelfAuto) {
      return this.intl.t(
        'components.meetings.event_scheduler.schedule_info.all_available',
        {
          weeks: moment(
            this.args.currentMeetingEvent?.selfSchedule?.slotRules
              ?.dateRanges?.[0]?.to
          ).diff(
            this.args.currentMeetingEvent?.selfSchedule?.slotRules
              ?.dateRanges?.[0]?.from,
            'days'
          ),
        }
      );
    }
  }

  get defaultTimedEventDuration() {
    return moment
      .duration({ years: 0, hours: 1, minutes: 0, seconds: 0 })
      .toISOString();
  }

  compareRawEventWithEventClass = (
    event: EventInput,
    currentEvent: MeetingEventClass
  ) => {
    const { startsAt, endsAt } = currentEvent.bookedSlot ?? {
      startsAt: undefined,
      endsAt: undefined,
    };

    return (
      moment(event.startsAt).isSame(startsAt) &&
      moment(event.endsAt).isSame(endsAt)
    );
  };

  intersectsAnyEvent = (start: Date, end: Date, events = this.rawEvents) => {
    const intersections: {
      event: EventApi;
      before: boolean;
      after: boolean;
      date: Date;
    }[] = [];

    events.forEach((event) => {
      const eStart = new Date(event.start || '');
      const eEnd = new Date(event.end || '');

      if (start < eStart && end > eStart) {
        intersections.push({
          event,
          before: true,
          after: false,
          date: eStart,
        });
      } else if (start >= eStart && start < eEnd) {
        intersections.push({
          event,
          before: false,
          after: true,
          date: eEnd,
        });
      } else if (start <= eStart && end >= eEnd) {
        intersections.push({
          event,
          before: false,
          after: false,
          date: eEnd,
        });
      }
    });

    return intersections;
  };

  invertRanges = (
    ranges: EventInput[],
    constraintRange: { start: moment.Moment; end: moment.Moment }
  ) => {
    const invertedRanges = [];
    let { start } = constraintRange;
    let i: number | undefined, dateRange: DateRange | undefined;

    ranges.sort((a, b) => {
      if (moment(a.startsAt) < moment(b.startsAt)) {
        return -1;
      }

      if (moment(a.startsAt) > moment(b.startsAt)) {
        return 1;
      }

      if (moment(a.end) < moment(b.end)) {
        return -1;
      }

      if (moment(a.end) > moment(b.end)) {
        return 1;
      }

      return 0;
    });

    for (i = 0; i < ranges.length; i += 1) {
      dateRange = ranges[i];
      if (dateRange && moment(dateRange.startsAt).isAfter(start)) {
        invertedRanges.push({
          ...dateRange,
          start,
          end: dateRange.startsAt,
        });
      }

      if (dateRange && moment(dateRange.endsAt).isAfter(start)) {
        start = moment(dateRange.endsAt);
      }
    }

    if (start.isBefore(constraintRange.end)) {
      invertedRanges.push({
        ...dateRange,
        start,
        end: constraintRange.end,
      });
    }

    return invertedRanges;
  };

  mergeEvents = (eventsToMerge: EventApi[]) => {
    if (eventsToMerge.length <= 1) {
      return;
    }

    const firstEvent = eventsToMerge[0];
    if (!firstEvent) {
      return;
    }

    eventsToMerge.forEach((event, i) => {
      if (i === 0) {
        return;
      }

      if (event.start && moment(event.start).isBefore(firstEvent.start)) {
        firstEvent.setStart(event.start);
      }

      if (moment(event.end).isAfter(firstEvent.end)) {
        firstEvent.setEnd(event.end);
      }

      event.remove();
    });
  };

  addEvent = (data: EventInput) => {
    const eventData = {
      id: uuid(),
      title: data.title ?? this.args.currentMeetingEvent?.summary,
      editable: true,
      durationEditable: this.scheduleMode === ScheduleMode.Manual,
      classNames: [
        'fc-event-active-event',
        this.scheduleMode === ScheduleMode.Self ||
        this.scheduleMode === ScheduleMode.SelfAuto
          ? 'fc-event-self'
          : 'fc-event-specific',
      ],

      ...data,
      extendedProps: {
        editable: true,
        isRawEvent: true,
        ...data.extendedProps,
      },
    };
    const event = this.calendar.addEvent(eventData);

    if (event) {
      this.rawEvents = [...this.rawEvents, event];
    }

    this.getAvailabilitySlots.perform();
  };

  handleEventRemove = ({ event }: { event: EventApi }): void => {
    this.rawEvents = this.rawEvents.filter((e) => {
      return e.id !== event.id;
    });
    this.getAvailabilitySlots.perform();
  };

  handleEventChange = ({
    event,
    oldEvent,
  }: {
    event: EventApi;
    oldEvent: EventApi;
  }): void => {
    this.rawEvents = this.rawEvents.map((e) => {
      if (e.id === oldEvent.id) {
        return event;
      }

      return e;
    });
    this.getAvailabilitySlots.perform();
  };

  handleEventContent = (
    arg: EventContentArg
  ): { domNodes: HTMLElement[] } | void => {
    const arrayOfDomNodes = [];

    if (
      arg.event.extendedProps.editable ||
      arg.event.extendedProps.isRawEvent
    ) {
      const eventFrame = document.createElement('div');
      eventFrame.className = 'fc-event-main-frame';
      arrayOfDomNodes.push(eventFrame);
    }

    if (arg.event.extendedProps.isRawEvent) {
      const freeSlotsDiv = this.freeSlotContent(arg);

      arrayOfDomNodes.push(freeSlotsDiv);
    }

    if (arg.event.extendedProps.editable) {
      const titleFrame = document.createElement('div');
      titleFrame.className = 'fc-event-title-frame';

      const time = document.createElement('div');
      time.className = 'fc-event-time';
      time.innerText = `${moment(arg.event.start)
        .tz(this.currentTzid)
        .format(this.timeFormat.format)} - ${moment(arg.event.end)
        .tz(this.currentTzid)
        .format(this.timeFormat.format)}`;

      if (this.scheduleMode === ScheduleMode.Manual) {
        const title = document.createElement('div');
        title.className = 'fc-event-title';
        title.innerText = arg.event.title;
        titleFrame.appendChild(title);
        const dragIndicator = document.createElement('div');
        dragIndicator.className = 'fc-event-drag-handle';
        arrayOfDomNodes.push(dragIndicator);
      }

      titleFrame.appendChild(time);

      arrayOfDomNodes.push(titleFrame);

      if (this.scheduleMode === ScheduleMode.Self) {
        const removeButton = document.createElement('div');
        removeButton.onclick = (evt) => this.removeButtonClick(evt, arg.event);
        removeButton.className = 'fc-event-remove';

        removeButton.appendChild(this.getXSvgIcon());

        arrayOfDomNodes.push(removeButton);
      }
    }

    if (arg.event.extendedProps.isEvent) {
      return this.bufferEventContent(arg);
    }

    if (arrayOfDomNodes.length) {
      return { domNodes: arrayOfDomNodes };
    }

    return void 0;
  };

  bufferEventContent = (arg: EventContentArg): { domNodes: HTMLElement[] } => {
    const nodes = [];
    const bufferBeforeHeight =
      this.getPixelsPerMinute() *
      (this.currentEvent?.selfSchedule?.bufferBefore ?? 0);
    const bufferAfterHeight =
      this.getPixelsPerMinute() *
      (this.currentEvent?.selfSchedule?.bufferAfter ?? 0);

    const bufferBefore = document.createElement('div');
    bufferBefore.className = 'fc-event-buffer';
    bufferBefore.style.height = `${bufferBeforeHeight}px`;
    bufferBefore.style.top = `-${bufferBeforeHeight + 1}px`;
    const bufferAfter = document.createElement('div');
    bufferAfter.className = 'fc-event-buffer';
    bufferAfter.style.height = `${bufferAfterHeight}px`;
    bufferAfter.style.bottom = `-${bufferAfterHeight + 1}px`;
    const event = document.createElement('div');
    event.className = 'fc-event-busy-box';

    const time = document.createElement('div');
    time.className = 'fc-event-time';
    time.innerText = `${moment(arg.event.start)
      .tz(this.currentTzid)
      .format(this.timeFormat.format)} - ${moment(arg.event.end)
      .tz(this.currentTzid)
      .format(this.timeFormat.format)}`;
    event.appendChild(time);

    if (
      this.scheduleMode === ScheduleMode.Self ||
      this.scheduleMode === ScheduleMode.SelfAuto
    ) {
      if (this.timers[arg.event.id]) {
        clearTimeout(this.timers[arg.event.id]);
      }

      this.timers[arg.event.id] = setTimeout(() => {
        const parent = event.parentElement;

        if (!parent) {
          return;
        }

        const intersections =
          arg.event.start && arg.event.end
            ? this.intersectsAnyEvent(arg.event.start, arg.event.end)
            : [];

        if (intersections.length) {
          parent.style.marginLeft = '12px';
        } else {
          parent.style.marginLeft = '0px';
        }
      }, 150);
    }

    if (bufferBeforeHeight) {
      nodes.push(bufferBefore);
    }

    nodes.push(event);
    if (bufferAfterHeight) {
      nodes.push(bufferAfter);
    }

    return { domNodes: nodes };
  };

  freeSlotContent = (arg: EventContentArg) => {
    const freeSlotsDiv = document.createElement('div');
    freeSlotsDiv.className = 'fc-event-free-slots-container';

    if (
      (this.scheduleMode === ScheduleMode.Self ||
        this.scheduleMode === ScheduleMode.SelfAuto) &&
      arg.isStart
    ) {
      const eventTopPixel = arg.event.start
        ? this.dateToPixel(arg.event.start)
        : 0;

      const availableSlotsInPeriod = this.getSlotsForPeriod(arg.event);

      availableSlotsInPeriod.forEach((slot) => {
        const startPixel = this.dateToPixel(slot.start) - eventTopPixel;
        const endPixel = this.dateToPixel(slot.end) - eventTopPixel;

        const daysAway = -moment
          .tz(arg.event.start, this.currentTzid)
          .startOf('day')
          .diff(slot.start, 'days');

        const slotDiv = document.createElement('div');
        slotDiv.className = 'fc-event-free-slot';
        slotDiv.style.left = `calc(${daysAway * 5}px + ${daysAway * 100}%)`;
        slotDiv.style.top = `${startPixel + 4}px`;
        slotDiv.style.height = `${endPixel - startPixel - 6}px`;

        freeSlotsDiv.appendChild(slotDiv);
      });
    }

    return freeSlotsDiv;
  };

  timers: Record<string, ReturnType<typeof setTimeout>> = {};

  handleDayHeaderContent = (arg: DayHeaderContentArg) => {
    const dateString = arg.text.split(' ');
    if (!dateString[0] || !dateString[1]) {
      return void 0;
    }

    const numberBeforeText = isNaN(parseInt(dateString[0], 10)) ? false : true;

    const dayContainer = document.createElement('div');

    const day = document.createElement('div');
    day.innerText = numberBeforeText ? dateString[1] : dateString[0];
    const number = document.createElement('div');
    number.innerText = numberBeforeText ? dateString[0] : dateString[1];
    if (arg.isToday) {
      dayContainer.className = 'fc-day-today-day';
      number.className = 'fc-day-today-number';
    } else {
      dayContainer.className = 'flex items-center gap-4';
    }

    if (numberBeforeText) {
      dayContainer.appendChild(number);
      dayContainer.appendChild(day);
    } else {
      dayContainer.appendChild(day);
      dayContainer.appendChild(number);
    }

    return { domNodes: [dayContainer] };
  };

  getSlotsForPeriod = (
    queryPeriod: EventApi,
    {
      startInterval = this.currentEvent?.selfSchedule?.startInterval ?? 30,
    } = {}
  ) => {
    const eventDuration = moment(queryPeriod.end).diff(
      queryPeriod.start,
      'minutes'
    );
    const slotDuration = this.currentEvent?.selfSchedule?.duration ?? 60;

    const requiredMembers = this.requiredMembersForIntersect();

    const mStart = getClosestMinuteStep(queryPeriod.start, startInterval);
    const mEnd = moment(queryPeriod.end);

    const eStart = mStart.toDate();
    const eEnd = mEnd.toDate();

    const disabledTimesWithinPeriod = this.disabledTimesWithBuffer.filter(
      (dEvent) => {
        return dEvent.start < eEnd && dEvent.end > eStart;
      }
    );

    const slots = [];
    let currentMinute = 0;

    while (currentMinute < eventDuration) {
      const testSlot = {
        start: moment(eStart).add(currentMinute, 'minutes').toDate(),
        end: moment(eStart)
          .add(currentMinute + slotDuration, 'minutes')
          .toDate(),
      };

      if (moment(testSlot.end).isAfter(eEnd)) {
        // done no more testing can be done.
        break;
      }

      if (testSlot.start.getMinutes() % startInterval !== 0) {
        currentMinute += 5;
        continue;
      }

      const intersectsDisabled = this.intersectsAnyEvent(
        testSlot.start,
        testSlot.end,
        disabledTimesWithinPeriod as unknown as EventApi[] // lol ¯\_(ツ)_/¯, does not really need to be an eventApi in this use case...
      );

      if (intersectsDisabled.length > requiredMembers) {
        currentMinute += startInterval;
        continue;
      }

      slots.push(testSlot);
      currentMinute += slotDuration;
    }

    return slots;
  };

  requiredMembersForIntersect = (): number => {
    const connectedUsers = this.teamMembersWithCalendars.length;
    const requiredMembers =
      (this.currentEvent?.selfSchedule?.required === 'all'
        ? connectedUsers
        : this.currentEvent?.selfSchedule?.required) ?? connectedUsers;

    return connectedUsers - requiredMembers;
  };

  allowSelect = (): boolean => {
    if (
      this.scheduleMode === ScheduleMode.Manual &&
      this.rawEvents.length < 1
    ) {
      return false;
    }

    if (this.scheduleMode === ScheduleMode.Self) {
      return false;
    }

    if (this.scheduleMode === ScheduleMode.SelfAuto) {
      return false;
    }

    return false;
  };

  getXSvgIcon = (): SVGSVGElement => {
    const xSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    xSvg.setAttribute('width', '16px');
    xSvg.setAttribute('height', '16px');
    xSvg.setAttribute('fill', 'none');
    xSvg.setAttribute('viewBox', '0 0 24 24');
    const xPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    xPath.setAttribute(
      'd',
      'M5.15115 5.1515C5.37618 4.92654 5.68135 4.80016 5.99955 4.80016C6.31775 4.80016 6.62291 4.92654 ' +
        '6.84795 5.1515L11.9995 10.3031L17.1511 5.1515C17.2618 5.03689 17.3943 4.94547 17.5407 4.88258C17.6871 ' +
        '4.81969 17.8445 4.78659 18.0039 4.7852C18.1632 4.78382 18.3212 4.81418 18.4687 4.87452C18.6162 ' +
        '4.93485 18.7501 5.02396 18.8628 5.13663C18.9755 5.2493 19.0646 5.38328 19.1249 5.53076C19.1853 ' +
        '5.67823 19.2156 5.83625 19.2142 5.99558C19.2129 6.15492 19.1798 6.31238 19.1169 6.45879C19.054 ' +
        '6.60519 18.9626 6.73761 18.8479 6.8483L13.6963 11.9999L18.8479 17.1515C19.0665 17.3778 19.1875 ' +
        '17.6809 19.1848 17.9956C19.182 18.3102 19.0558 18.6112 18.8333 18.8337C18.6108 19.0562 18.3099 ' +
        '19.1824 17.9952 19.1851C17.6806 19.1878 17.3775 19.0669 17.1511 18.8483L11.9995 13.6967L6.84795 ' +
        '18.8483C6.62163 19.0669 6.3185 19.1878 6.00387 19.1851C5.68923 19.1824 5.38826 19.0562 5.16577 ' +
        '18.8337C4.94328 18.6112 4.81707 18.3102 4.81434 17.9956C4.81161 17.6809 4.93256 17.3778 5.15115 ' +
        '17.1515L10.3027 11.9999L5.15115 6.8483C4.92618 6.62327 4.7998 6.3181 4.7998 5.9999C4.7998 5.68171 ' +
        '4.92618 5.37654 5.15115 5.1515Z'
    );
    xPath.setAttribute('fill-rule', 'evenodd');
    xPath.setAttribute('clip-rule', 'evenodd');
    xPath.setAttribute('fill', 'currentColor');
    xSvg.appendChild(xPath);
    return xSvg;
  };

  removeButtonClick = (clickEvent?: MouseEvent, event?: EventApi): void => {
    if (clickEvent) {
      clickEvent.stopPropagation();
    }

    event?.remove();
  };

  initialFocusedEvent = (): Date | undefined => {
    if (this.args.bookedSlot) {
      return this.args.bookedSlot.startsAt;
    } else if (this.args.selfSchedule?.slots?.length) {
      return this.args.selfSchedule.slots.firstObject?.startsAt;
    }
  };

  pixelsPerMinute = 0;

  getPixelsPerMinute = () => {
    if (this.pixelsPerMinute) {
      return this.pixelsPerMinute;
    }

    const timeGridFrame = document.querySelector('.fc-timegrid-col') as
      | HTMLElement
      | undefined;

    if (!timeGridFrame) {
      return 1584 / 60 / 24;
    }

    this.pixelsPerMinute = timeGridFrame.offsetHeight / 60 / 24;
    return this.pixelsPerMinute;
  };

  pixelToTime = (pixel: number, dateStr: string) => {
    const startMinute = pixel / this.getPixelsPerMinute();
    return moment.tz(dateStr, this.currentTzid).add(startMinute, 'minutes');
  };

  dateToPixel = (date: Date) => {
    const mmt = moment.tz(date, this.currentTzid);
    const minutes = -mmt.startOf('day').diff(date, 'minutes');
    return this.getPixelsPerMinute() * minutes;
  };

  renderCalendar = (element: HTMLDivElement) => {
    this.calendarElement = element;

    this.calendar = new Calendar(element, {
      now: moment().tz(this.currentTzid).toDate(),
      plugins: [interactionPlugin, timeGridPlugin, momentTimezonePlugin],

      headerToolbar: {
        start: '', // will normally be on the left. if RTL, will be on the right
        center: '',
        end: '', // will normally be on the right. if RTL, will be on the left
      },

      initialView: 'timeGridWeek',
      initialDate: this.initialFocusedEvent(),

      datesSet: this.handleDateChange,
      contentHeight: element.getBoundingClientRect().height,
      displayEventEnd: false,
      displayEventTime: true,
      editable: true,

      eventDidMount: (info) => {
        if (info.event.groupId === 'free-time') {
          const minuteStep = 5;

          const getDuration = () => {
            if (
              this.scheduleMode === ScheduleMode.Manual &&
              this.rawEvents[0]
            ) {
              const duration = moment
                .duration(
                  moment(this.rawEvents[0].end ?? undefined).diff(
                    moment(this.rawEvents[0].start ?? undefined)
                  )
                )
                .asMinutes();
              return duration;
            }

            return this.currentEvent?.selfSchedule?.duration ?? 60;
          };

          const getHoverEventPosition = (
            info: EventMountArg,
            e: MouseEvent
          ) => {
            const duration = getDuration();
            const height = duration * this.getPixelsPerMinute();

            let top =
              Math.floor(
                (e.offsetY - height / 2.0) /
                  (minuteStep * this.getPixelsPerMinute())
              ) *
              (minuteStep * this.getPixelsPerMinute());

            top = top < 0 ? 0 : top;
            top =
              top > info.el.offsetHeight - height
                ? info.el.offsetHeight - height
                : top;

            return { top, height, mouseY: e.offsetY };
          };

          const getHoverEventInfo = (info: EventMountArg, e: MouseEvent) => {
            const {
              top: orgTop,
              height,
              mouseY,
            } = getHoverEventPosition(info, e);
            const duration = getDuration();
            let top = orgTop;

            const dateStr =
              info.el.parentElement?.parentElement?.parentElement?.parentElement
                ?.dataset.date || '2000-01-01';
            const parentTop = info.el.parentElement?.offsetTop || 0;

            let start = this.pixelToTime(top + parentTop, dateStr).toDate();
            let end = moment(start).add(duration, 'minutes').toDate();
            const mouseTime = this.pixelToTime(
              mouseY + parentTop,
              dateStr
            ).toDate();

            const getDisabledTimesWithinPeriod = (start: Date, end: Date) => {
              return [...this.rawEvents, ...this.disabledTimesWithBuffer]
                .filter((dEvent) => {
                  return (
                    dEvent.start &&
                    dEvent.start < end &&
                    dEvent.end &&
                    dEvent.end > start
                  );
                })
                .map((dEvent) => {
                  // makes sure so disabled does not end different than from timestep
                  return {
                    start: getClosestMinuteStep(dEvent.start, -minuteStep),
                    end: getClosestMinuteStep(dEvent.end, minuteStep),
                  };
                }) as unknown as EventApi[];
            };

            const intersectionInfo = this.intersectsAnyEvent(
              start,
              end,
              getDisabledTimesWithinPeriod(start, end)
            );

            const checkInvalidity = () => {
              if (this.scheduleMode === ScheduleMode.SelfAuto) {
                return true;
              }

              if (intersectionInfo.length > 1) {
                return true;
              } else if (intersectionInfo.length === 1 && intersectionInfo[0]) {
                const { date, before, after } = intersectionInfo[0];
                const parentTop = info.el.parentElement?.offsetTop || 0;
                const pixel = this.dateToPixel(date) - parentTop;

                if (!before && !after) {
                  return true;
                }

                if (before) {
                  if (date < mouseTime) {
                    return true;
                  }

                  top = pixel - height;
                }

                if (after) {
                  if (date > mouseTime) {
                    return true;
                  }

                  top = pixel;
                }

                start = this.pixelToTime(top + parentTop, dateStr).toDate();
                end = moment(start).add(duration, 'minutes').toDate();

                const intersects = this.intersectsAnyEvent(
                  start,
                  end,
                  getDisabledTimesWithinPeriod(start, end)
                );

                if (intersects.length > 0) {
                  return true;
                }
              }

              if (Math.round(top) < 0) {
                return true;
              }

              if (top + height > info.el.offsetHeight) {
                return true;
              }

              return false;
            };

            const invalid = checkInvalidity();

            return { top, height, dateStr, start, end, invalid };
          };

          let hasAppended = false;
          info.el.onmouseenter = () => {
            if (this.isDragging || this.isResizing) {
              return;
            }

            info.el.appendChild(this.hoverElement);
            hasAppended = true;
          };

          info.el.onmouseleave = () => {
            if (!hasAppended) {
              return;
            }

            info.el.removeChild(this.hoverElement);
            hasAppended = false;
          };

          info.el.onmousemove = (e) => {
            const { top, height, invalid } = getHoverEventInfo(info, e);

            if (invalid) {
              this.hoverElement.classList.add('invalid');
              info.el.classList.remove('clickable');
            } else {
              this.hoverElement.classList.remove('invalid');
              info.el.classList.add('clickable');
            }

            if (
              this.scheduleMode === ScheduleMode.Manual &&
              this.rawEvents.length
            ) {
              this.hoverElement.classList.add('moving');
            } else {
              this.hoverElement.classList.remove('moving');
            }

            this.hoverElement.style.top = `${top}px`;
            this.hoverElement.style.height = `${height}px`;
          };

          info.el.onclick = (e) => {
            const { start, end, invalid } = getHoverEventInfo(info, e);

            if (invalid) {
              return;
            }

            if (this.scheduleMode === ScheduleMode.Manual) {
              this.clearPickedDateRange();
            }

            this.addEvent({
              start,
              end,
            });
          };
        }
      },

      eventContent: this.handleEventContent,

      eventDragStart: this.handleDragStart,
      eventDrop: this.handleDragEnd,

      eventResizeStart: this.handleResizeStart,
      eventResize: this.handleResizeEnd,

      eventChange: this.handleEventChange,
      selectAllow: this.allowSelect,
      defaultTimedEventDuration: this.defaultTimedEventDuration,
      selectMinDistance: 5,
      selectMirror: true,
      selectable: true,
      snapDuration: '00:05:00',
      timeZone: this.currentTzid,
      weekNumberCalculation: 'ISO',
      weekNumbers: false,
      dragScroll: true,
      locale: this.timeFormat.locale,
      firstDay: this.timeFormat.firstDayOfWeek.id,
      eventRemove: this.handleEventRemove,

      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short',
        hour12: !this.timeFormat.militaryFormat,
      },

      dayHeaderContent: this.handleDayHeaderContent,

      views: {
        timeGrid: {
          nowIndicator: true,
          allDaySlot: false,
          slotDuration: '01:00:00',
          slotEventOverlap: false,
          weekends: true,

          dayHeaderFormat: {
            weekday: 'short',
            day: 'numeric',
            omitCommas: true,
          },

          slotLabelInterval: {
            hours: 1,
          },

          slotLabelFormat: {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short',
            hour12: !this.timeFormat.militaryFormat,
          },
        },
      },

      eventOrder: 'groupId',
      eventOrderStrict: true,
    });

    this.renderMeetingEvents();

    if (this.currentEvent?.bookedSlot) {
      this.calendar.setOption(
        'scrollTime',
        moment(this.currentEvent.bookedSlot.startsAt)
          .subtract(2, 'hour')
          .format('HH:mm')
      );
    }

    this.calendar.render();

    this.calendar.setOption(
      'eventSources',
      this.eventSources as EventSourceInput[]
    );
  };

  fetchAttendeesEvents = async (
    from: string,
    to: string
  ): Promise<EventInput[]> => {
    const useTimezone = this.currentTzid;

    const data: Record<'attendeesEvents', IAttendeeEvent[] | undefined> =
      await this.apollo.query({
        query: ATTENDEES_EVENTS_QUERY,
        variables: {
          userIds: this._users
            .filter((attendee) => !attendee.excludeAvailability)
            .map((attendee) => attendee.user?.id),

          meetingRoomIds: this._meetingRooms.map(
            (attendee) => attendee.meetingRoom?.id
          ),

          candidateIds: this._candidates.map(
            (attendee) => attendee.candidate?.id
          ),

          filter: {
            from,
            to,
            tzid: useTimezone,
            includeFree: false,
          },
        },
      });

    return data.attendeesEvents || [];
  };

  fetchAvailability = async (
    startWeek: string,
    endWeek: string
  ): Promise<EventInput[]> => {
    const availability: IAttendeeEvent[] | undefined = await this.apollo.query(
      {
        query: AVAILABILITY_QUERY,
        variables: {
          userIds: this._users
            .filter((attendee) => !attendee.excludeAvailability)
            .map((attendee) => attendee.user?.id),

          startWeek,
          endWeek,
          tzid: this.args.currentMeetingEvent?.tzid,
        },
      },
      'cronofyAvailabilityRule'
    );

    return availability || [];
  };

  handleDateChange = (args: DatesSetArg): void => {
    const startMonth = moment(args.start).format('MMMM');
    const endMonth = moment(args.end).format('MMMM');
    const startYear = moment(args.start).format('YYYY');
    const endYear = moment(args.end).format('YYYY');

    let year = startYear;
    if (startYear !== endYear) {
      year = `${startYear} - ${endYear}`;
    }

    if (startMonth !== endMonth) {
      this.dateString = `${startMonth} - ${endMonth} ${year}`;
    } else {
      this.dateString = `${startMonth} ${year}`;
    }

    this.week = `${this.intl.t(
      `components.meetings.event_scheduler.week`
    )} ${moment(args.start).add(1, 'day').isoWeek()}`;
  };

  toDateRange = (event: EventApi | undefined) => {
    if (event?.start && event.end) {
      return {
        startsAt: event.start,
        endsAt: event.end,
      };
    }
  };

  toSelfschedule = (): SelfScheduleClass | undefined => {
    if (this.scheduleMode === ScheduleMode.Self) {
      return SelfScheduleClass.from({
        duration: this.currentEvent?.selfSchedule?.duration ?? 60,
        bufferBefore: this.currentEvent?.selfSchedule?.bufferBefore ?? 0,
        bufferAfter: this.currentEvent?.selfSchedule?.bufferAfter ?? 0,
        startInterval: this.currentEvent?.selfSchedule?.startInterval ?? 5,
        slotRules: undefined,
        slots: this.rawEvents.map(
          (event) => this.toDateRange(event) as IMeetingEventDateRange
        ),

        required: this.currentEvent?.selfSchedule?.required,
      });
    }

    if (this.scheduleMode === ScheduleMode.SelfAuto) {
      return SelfScheduleClass.from({
        duration: this.currentEvent?.selfSchedule?.duration ?? 60,
        bufferBefore: this.currentEvent?.selfSchedule?.bufferBefore ?? 0,
        bufferAfter: this.currentEvent?.selfSchedule?.bufferAfter ?? 0,
        startInterval: this.currentEvent?.selfSchedule?.startInterval ?? 30,
        slotRules: this.currentEvent?.selfSchedule?.slotRules,
        slots: [],
        required: this.currentEvent?.selfSchedule?.required,
      });
    }
  };

  setOptionWithRerender = <OptionName extends keyof CalendarOptions>(
    key: OptionName,
    value: CalendarOptions[OptionName]
  ): void => {
    this.calendar.setOption(key, value);
    this.rerender();
  };

  rerender = () => {
    this.calendar.destroy();
    this.calendar.render();
  };

  renderMeetingEvents = (): void => {
    const { currentMeetingEvent } = this.args;

    if (currentMeetingEvent?.selfSchedule?.slots?.length !== 0) {
      currentMeetingEvent?.selfSchedule?.slots?.map((slot) => {
        this.addEvent({
          start: slot.startsAt,
          end: slot.endsAt,
          title: currentMeetingEvent.summary,
        });
      });
    } else if (currentMeetingEvent.selfSchedule.slotRules) {
      const periods = currentMeetingEvent.selfSchedule.slotRulesToPeriods(
        currentMeetingEvent.tzid
      );

      periods.forEach((period) => {
        this.addEvent({
          start: period.start,
          end: period.end,
          title: currentMeetingEvent.summary,
          editable: false,
          extendedProps: {
            editable: false,
          },
        });
      });
    }

    const { startsAt, endsAt } = currentMeetingEvent?.bookedSlot ?? {};

    if (!startsAt || !endsAt) {
      return;
    }

    this.addEvent({
      start: startsAt,
      end: endsAt,
      title: currentMeetingEvent?.summary,
    });
  };

  getAvailabilitySlots = restartableTask(async () => {
    await timeout(300);
    if (this.scheduleMode === ScheduleMode.Self) {
      if (
        this.rawEvents.length === 0 ||
        !this.args.currentMeetingEvent?.selfSchedule
      ) {
        this.availableSlots = 0;
        return;
      }

      this.args.currentMeetingEvent.selfSchedule.slots = this.rawEvents.map(
        (e) =>
          new BookedSlotClass({
            startsAt: moment(e.start).toDate(),
            endsAt: moment(e.end).toDate(),
          })
      );
    }

    const availability =
      await this.availability.requestAvailableSlotsDataForEvent(
        this.args.currentMeetingEvent
      );
    this.availableSlots = availability?.periods.length;
  });

  @action
  handleDragStart(): void {
    this.isDragging = true;
  }

  @action
  handleDragEnd(): void {
    this.isDragging = false;
  }

  @action
  handleResizeStart(): void {
    this.isResizing = true;
  }

  @action
  handleResizeEnd(): void {
    this.isResizing = false;
  }

  @action
  prevWeek(): void {
    this.calendar.prev();
  }

  @action
  nextWeek(): void {
    this.calendar.next();
  }

  @action
  clearPickedDateRange(): void {
    this.rawEvents.forEach((event) => event.remove());
    this.rawEvents = [];
    this.getAvailabilitySlots.perform();
  }

  @action
  selectTimeZone(timeZone: string): void {
    this.calendar.setOption('timeZone', timeZone);
    this.setOptionWithRerender('now', moment().tz(timeZone).toDate());
    this.args.onTimeZoneChange(timeZone);
    this.clearPickedDateRange();
    this.renderMeetingEvents();
  }

  @action
  setManualScheduleMode(): void {
    this.scheduleMode = ScheduleMode.Manual;
    this.args.setScheduleMode(this.scheduleMode);
    if (this._meetingRooms.length > 0 && this._meetingRooms[0]) {
      this.toggleMeetingRoom(this._meetingRooms[0]);
    }

    if (this.currentEvent) {
      this.currentEvent.bookedSlot = BookedSlotClass.from({
        startsAt: moment().startOf('hour').add(2, 'hour').toDate(),
        endsAt: moment().startOf('hour').add(3, 'hours').toDate(),
      });
      this.currentEvent.selfSchedule = undefined;
    }

    this.clearPickedDateRange();
    this.renderMeetingEvents();
  }

  @action
  setSelfScheduleMode(): void {
    this.scheduleMode = ScheduleMode.Self;
    this.args.setScheduleMode(this.scheduleMode);

    if (this.currentEvent) {
      this.currentEvent.bookedSlot = undefined;
      this.currentEvent.selfSchedule = new SelfScheduleClass({
        startInterval: 5,
      });
    }

    this.clearPickedDateRange();
    this.renderMeetingEvents();
    this.handleSetDuration(60);
  }

  @action
  setSelfScheduleAutoMode(): void {
    this.scheduleMode = ScheduleMode.SelfAuto;
    this.args.setScheduleMode(this.scheduleMode);

    if (this.currentEvent) {
      this.currentEvent.bookedSlot = undefined;
      this.currentEvent.selfSchedule =
        SelfScheduleClass.createWithDefaultSlotRules();
    }

    this.clearPickedDateRange();
    this.renderMeetingEvents();
    this.handleSetDuration(60);
  }

  @action
  handleSetDuration(duration: number): void {
    if (this.currentEvent?.selfSchedule === undefined) {
      return;
    }

    const schedule = this.currentEvent.selfSchedule;
    schedule.duration = duration;

    if (this.scheduleMode === ScheduleMode.Self) {
      this.rawEvents.forEach((e) => {
        e.setProp('durationEditable', true);
        e.setEnd(moment(e.start).add(duration, 'minutes').toDate());
        e.setProp('durationEditable', false);
      });
      this.rawEvents = [...this.rawEvents];
    }

    this.calendar.render();
    this.getAvailabilitySlots.perform();
  }

  @action
  done(): void {
    if (this.currentEvent) {
      if (this.scheduleMode === ScheduleMode.Manual) {
        this.currentEvent.selfSchedule = undefined;
        this.args.onAddBookedSlot(this.toDateRange(this.rawEvents[0]));
      }

      if (this.scheduleMode === ScheduleMode.Self) {
        this.currentEvent.bookedSlot = undefined;
        this.args.onAddSelfSchedule(this.toSelfschedule());
      }

      if (this.scheduleMode === ScheduleMode.SelfAuto) {
        this.currentEvent.bookedSlot = undefined;
        this.args.onAddSelfSchedule(this.toSelfschedule());
      }
    }

    this.args.onClose();
  }

  @action
  toggleUser(attendee: UserAttendeeClass): void {
    if (!attendee.isOrganizer) {
      this.currentEvent?.meetingEventAttendees.toggle(attendee);
      const eventSource = this.calendar.getEventSourceById('attendees');
      eventSource?.refetch();
    }

    this.getAvailabilitySlots.perform();
  }

  @action
  toggleUserExclusion(attendee: UserAttendeeClass): void {
    this.currentEvent?.meetingEventAttendees.userAttendees.forEach((a) => {
      if (a.user?.id === attendee.user?.id) {
        a.excludeAvailability = !a.excludeAvailability;
      }
    });
    const eventSource = this.calendar.getEventSourceById('attendees');
    eventSource?.refetch();

    this.getAvailabilitySlots.perform();
  }

  @action
  toggleMeetingRoom(attendee: MeetingRoomAttendeeClass): void {
    if (
      this.scheduleMode === ScheduleMode.Manual &&
      !(
        this.currentEvent?.meetingEventAttendees.meetingRoomAttendees
          ?.length === 1 &&
        this.currentEvent.meetingEventAttendees.meetingRoomAttendees[0] ===
          attendee
      )
    ) {
      this.currentEvent?.meetingEventAttendees.clear(
        this.currentEvent.meetingEventAttendees
          .meetingRoomAttendees as AttendeeType[]
      );
    }

    this.currentEvent?.meetingEventAttendees.toggle(attendee);
    const eventSource = this.calendar.getEventSourceById('attendees');
    eventSource?.refetch();
    this.getAvailabilitySlots.perform();
  }

  @action
  setCalendarViewMode(mode: ViewModeOption): void {
    this.calendarView = mode;
  }

  @action
  registerHoverElement(element: HTMLDivElement): void {
    this.hoverElement = element;
  }

  @action
  handleSetBuffer(buffer: number): void {
    if (this.currentEvent?.selfSchedule !== undefined) {
      const schedule = this.currentEvent.selfSchedule;
      schedule.bufferBefore = buffer;
      schedule.bufferAfter = buffer;
    }

    this.calendar.render();
    this.getAvailabilitySlots.perform();
  }

  @action
  rerenderCalendar(): void {
    this.clearPickedDateRange();
    this.calendar.render();
    this.renderMeetingEvents();
  }

  @action
  hoverAttendee(index: number): void {
    this.hoveredAttendeeIndex = index;
  }

  @action
  handleSetStartInterval(startInterval: number): void {
    if (this.currentEvent?.selfSchedule !== undefined) {
      const schedule = this.currentEvent.selfSchedule;
      schedule.startInterval = startInterval;
    }

    this.calendar.render();
    this.getAvailabilitySlots.perform();
  }

  @action
  handleSetRequired(required: number | 'all'): void {
    if (this.currentEvent?.selfSchedule !== undefined) {
      const schedule = this.currentEvent.selfSchedule;
      schedule.required = required;
    }

    this.calendar.render();
    this.getAvailabilitySlots.perform();
  }
}
