/* import __COLOCATED_TEMPLATE__ from './scorecard-rows.hbs'; */
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';
import { SCORE_VALUE_ADJUSTMENT } from 'teamtailor/constants/scorecard-scores';
import ScorecardScore from 'teamtailor/models/scorecard-score';
import ScorecardPick from 'teamtailor/models/scorecard-pick';
import JobApplicationModel from 'teamtailor/models/job-application';
import JobDetail from 'teamtailor/models/job-detail';
import { tracked } from '@glimmer/tracking';

type ScoreResult = {
  scoreValue: number | null;
  adjustedScoreValue: number;
  jobApplicationId: string;
  candidateId: string;
};

type ComputedScore = {
  scorecardPick: ScorecardPick;
  topic: string;
  scores: ScoreResult[];
};

type ScorecardRowsArgs = {
  jobApplications: JobApplicationModel[];
  sortBy: () => void;
  pinnedCount: number;
  hoveredJobApplication: JobApplicationModel;
};

export default class CompareCandidatesScorecardRow extends Component<ScorecardRowsArgs> {
  @tracked sortBy = this.args.sortBy;
  @tracked pinnedCount = this.args.pinnedCount;

  get jobDetail(): JobDetail | undefined {
    if (!this.jobApplication) {
      return undefined;
    }

    return get(this.jobApplication.job, 'jobDetail') as unknown as JobDetail;
  }

  get jobApplication(): JobApplicationModel | undefined {
    if (!this.args.jobApplications.length) {
      return undefined;
    }

    return this.args.jobApplications[0];
  }

  get computedScores(): ComputedScore[] {
    if (!this.jobDetail) {
      return [];
    }

    const scorecardPicks = get(
      this.jobDetail,
      'scorecardPicks'
    ) as unknown as ScorecardPick[];

    const computed = scorecardPicks.map((scorecardPick) =>
      this.computeScoreForPick(scorecardPick)
    );

    return computed.sort(this.sortComputedScores);
  }

  computeScoreForPick(scorecardPick: ScorecardPick): ComputedScore {
    const scores = this.args.jobApplications.map(
      (application: JobApplicationModel) => {
        let scoreValue = this.getScore(scorecardPick, application);
        const adjustedScoreValue = this.adjustScore(scoreValue);
        const candidateId = get(application.candidate, 'id');
        if (scoreValue !== null) scoreValue = Math.floor(scoreValue);
        return {
          scoreValue,
          adjustedScoreValue,
          jobApplicationId: application.id,
          candidateId,
        };
      }
    ) as ScoreResult[];

    return {
      scorecardPick,
      topic: get(scorecardPick.scorecardCriterium, 'topic'),
      scores,
    };
  }

  adjustScore(score: number | null): number | null {
    if (score === null) {
      return null;
    }

    return Math.round(score * 10) / 10 + SCORE_VALUE_ADJUSTMENT;
  }

  sortComputedScores(a: ComputedScore, b: ComputedScore): number {
    if (a.topic < b.topic) return 1; // For descending order by topic
    if (a.topic > b.topic) return -1;

    if (a.scorecardPick.weight < b.scorecardPick.weight) return 1; // For ascending order by weight
    if (a.scorecardPick.weight > b.scorecardPick.weight) return -1;

    if (a.scorecardPick.name < b.scorecardPick.name) return -1; // For ascending order by name
    if (a.scorecardPick.name > b.scorecardPick.name) return 1;

    return 0;
  }

  scorecardSort(currentScorecardScores: ScoreResult[]) {
    return function (a: JobApplicationModel, b: JobApplicationModel) {
      const aScore = currentScorecardScores.find(
        (score: ScoreResult) => score.candidateId === get(a.candidate, 'id')
      );
      const bScore = currentScorecardScores.find(
        (score: ScoreResult) => score.candidateId === get(b.candidate, 'id')
      );
      if (!aScore || !bScore) return 0;

      return bScore.adjustedScoreValue - aScore.adjustedScoreValue;
    };
  }

  @action
  getScore(
    scorecardPick: ScorecardPick,
    application: JobApplicationModel
  ): number | null {
    const scorecardCriteriumId: string = get(
      scorecardPick.scorecardCriterium,
      'id'
    );

    const candidate = get(application, 'candidate');
    const scorecardScores: ScorecardScore[] = get(
      candidate,
      'scorecardScores'
    ).filter((scorecardScore: ScorecardScore) => {
      return (
        get(scorecardScore.scorecardPick, 'scorecardCriteriumId') ===
        scorecardCriteriumId
      );
    });

    if (scorecardScores.length) {
      return (
        scorecardScores.mapBy('score').reduce((a: number, b: number) => a + b) /
        scorecardScores.length
      );
    }

    return null;
  }
}
