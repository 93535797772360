/* import __COLOCATED_TEMPLATE__ from './survey-picker.hbs'; */
import Component from '@glimmer/component';
import SurveyModel from 'teamtailor/models/survey';
import { argDefault } from 'teamtailor/utils/arg-default';

type SurveyPickerArgs = {
  isFullWidth?: boolean;
  allowNull?: boolean;
  size?: string;
  selected?: SurveyModel;
  options: SurveyModel[];
  onSelect: (survey?: SurveyModel) => void;
};

export default class CoreSurveyPicker extends Component<SurveyPickerArgs> {
  @argDefault isFullWidth = true;
  @argDefault allowNull = false;
  @argDefault size = 'large';
}
