/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
import { inject as service } from '@ember/service';
import NotificationsService from 'teamtailor/services/notifications';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { gql } from '@apollo/client/core';
import { timeout, dropTask } from 'ember-concurrency';
import ApolloService from 'ember-apollo-client/services/apollo';
import RouterService from '@ember/routing/router-service';
import Current from 'teamtailor/services/current';
import BatchJobService from 'teamtailor/services/batch-job';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import { get } from 'teamtailor/utils/get';

// @ts-expect-error For some reason ts cannot find the following module.scss file
import styles from 'teamtailor/components/notifications/dropdown.module.scss';
import FlipperService from 'teamtailor/services/flipper';
import ModalService from 'teamtailor/services/modal';
import { helper } from '@ember/component/helper';
import BowserService from 'teamtailor/services/bowser';

interface NotificationsDropdownSignature {
  Element: HTMLDivElement;
}

export default class NotificationsDropdown extends Component<NotificationsDropdownSignature> {
  @service declare notifications: NotificationsService;
  @service declare apollo: ApolloService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare batchJob: BatchJobService;
  @service declare flipper: FlipperService;
  @service declare modal: ModalService;
  @service declare bowser: BowserService;

  @tracked pageInfo?: PageInfo;
  @tracked loadedNotifications: Notification[] = [];
  @tracked enableMarkAllAsReadButton = false;
  @tracked dialogElement?: HTMLDialogElement;
  @tracked buttonElement?: HTMLButtonElement;
  @tracked isOpen = false;

  handleIsOpenChange = helper(([isOpen]) => {
    if (isOpen) {
      this.modal.registerOpenModal(this.dialogElement!);
    } else {
      this.modal.unregisterOpenModal(this.dialogElement!);
    }
  });

  willDestroy() {
    this.modal.unregisterOpenModal(this.dialogElement!);
  }

  registerDialog = modifier((element: HTMLDialogElement) => {
    this.dialogElement = element;
  });

  registerButtonElement = modifier((element: HTMLButtonElement) => {
    this.buttonElement = element;
  });

  get iconClasses() {
    if (this.batchJob.hideBatchJobTriggered) return styles.wobble_bell;
    else return '';
  }

  @action
  goToNotificationSettings() {
    this.close();
    this.router.transitionTo(
      'employees.profile.notifications',
      this.current.user
    );
  }

  @action
  showNotifications() {
    this.dialogElement?.showModal();

    if (this.bowser.isSafari) {
      // Make safari focus the dialog on open (not the first focusable element) like the other browsers do
      this.dialogElement?.setAttribute('tabindex', '0');
      this.dialogElement?.focus();
    }

    this.fetchNotifications.perform();
    this.revealMarkAllAsReadButton.perform();
    this.isOpen = true;
  }

  @action
  close() {
    this.dialogElement?.close();
    this.pageInfo = undefined;
    this.enableMarkAllAsReadButton = false;
    this.loadedNotifications.clear();
    this.fetchNotifications.cancelAll({ resetState: true });
    this.isOpen = false;
  }

  @action
  async openNotification(notification: Notification) {
    await this.apollo.mutate({
      mutation: MARK_AS_READ,
      variables: {
        userId: this.current.user.id,
        companyUuid: this.current.company.uuid,
        id: notification.id,
      },
    });

    set(notification, 'isUnread', false);
  }

  revealMarkAllAsReadButton = dropTask(async () => {
    await timeout(800);
    this.enableMarkAllAsReadButton =
      this.notifications.unread > 0 ||
      this.notifications.unreadMentionsTotal > 0 ||
      this.notifications.unreadConversations > 0;
  });

  fetchNotifications = dropTask(async () => {
    if (this.pageInfo?.hasNextPage === false) {
      return;
    }

    const variables: NotificationQueryVariables = {
      userId: this.current.user.id,
      companyUuid: this.current.company.uuid,
      cursor: this.pageInfo?.endCursor,
    };

    if (get(this.flipper, 'global_comments')) {
      variables.filter = {
        excludeGlobalComments: true,
      };
    }

    const {
      notificationsConnection,
    }: {
      notificationsConnection: {
        pageInfo: PageInfo;
        edges: { node: Notification }[];
      };
    } = await this.apollo.query({
      query: QUERY,
      fetchPolicy: 'no-cache',
      variables,
    });

    const { pageInfo, edges } = notificationsConnection;

    this.pageInfo = pageInfo;
    this.loadedNotifications.pushObjects(edges.map((edge) => edge.node));
  });

  markAllAsRead = dropTask(async () => {
    await this.apollo.mutate({
      mutation: MARK_ALL_AS_READ,
      variables: {
        userId: this.current.user.id,
        companyUuid: this.current.company.uuid,
      },
    });

    await this.notifications.refreshUnread.perform();

    this.loadedNotifications
      .filterBy('isUnread')
      .map((a) => set(a, 'isUnread', false));

    this.enableMarkAllAsReadButton = false;
  });
}

interface NotificationQueryVariables {
  userId: string;
  companyUuid: string;
  cursor?: string;
  filter?: {
    excludeGlobalComments: boolean;
  };
}

interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
}

interface Notification {
  id: string;
  link: string;
  toHuman: string;
  isUnread: boolean;
  notifiable: {
    id: string;
    color: string;
    initials: string;
    interest: string;

    fromUser: {
      id: string;
      avatarUrl: string;
      nameOrEmail: string;
    };

    candidate: {
      id: string;
    };

    user: {
      nameOrEmail: string;
    };

    picture: {
      appUserAvatar: {
        url: string;
      };
    };
  };
  action: {
    code: string;
  };
}

const QUERY = gql`
  query NotificationList(
    $cursor: String
    $userId: ID
    $filter: NotificationsFilterInput
  ) {
    notificationsConnection(
      userScope: { userId: $userId }
      after: $cursor
      first: 20
      filter: $filter
    ) @connection(key: "NotificationList", filter: ["userScope", "filter"]) {
      totalCount
      totalUnread
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          link
          toHuman
          isUnread
          interest
          createdAt
          notifiable {
            id
            isAnonymousJobApplication
            fromUser {
              id
              avatarUrl
              nameOrEmail
            }
            picture {
              appUserAvatar {
                url
              }
            }
            color
            initials
            candidate {
              id
            }
          }
          action {
            code
          }
        }
      }
    }
  }
`;

const MARK_AS_READ = gql`
  mutation markNotificationAsRead($userId: ID!, $id: ID!) {
    updateNotification(
      input: {
        userScope: { userId: $userId }
        id: $id
        notificationAttributes: { checked: true }
      }
    ) {
      notification {
        id
        isUnread
      }
    }
  }
`;

const MARK_ALL_AS_READ = gql`
  mutation markAllNotificationsAsRead($userId: ID!) {
    updateFilteredNotifications(
      input: {
        userScope: { userId: $userId }
        filter: { scope: UNREAD }
        notificationAttributes: { checked: true }
      }
    ) {
      success
    }
  }
`;
