/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import { tracked } from '@glimmer/tracking';
import { dasherize } from '@ember/string';
import { setProperties, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import uniqBy from 'teamtailor/utils/uniq-by';

const REQUIRED_PROPS = ['name'];

export default class ModalComponent extends TranslatableFormBaseComponent {
  @service store;
  @service intl;

  constructor() {
    super(...arguments);

    next(() => {
      let { translatableModel, ownerType } = this.args;
      if (translatableModel) {
        this.showAdvanced =
          translatableModel.isHidden || translatableModel.isRequired;
        this.type = translatableModel.inputType;
        this.isSearchable = translatableModel.isSearchable;
        this.isFeatured = translatableModel.isFeatured;
        this.isPrivate = translatableModel.isPrivate;
        this.isHidden = translatableModel.isHidden;
        this.isRequired = translatableModel.isRequired;
        this.isExternal = translatableModel.isExternal;

        if (
          translatableModel.apiName !== this.dashedValue(translatableModel.name)
        ) {
          this.apiName = translatableModel.apiName;
        }
      } else {
        this.newCustomField = this.store.createRecord('custom-field', {
          languageCode: this.defaultStaticLanguage,
        }); // doesnt really matter which type we create, just need a model
      }

      this.selectedTab = ownerType;
    });
  }

  translationsProps = REQUIRED_PROPS;

  @tracked apiNameListening = true;
  @tracked showApiNameInput = false;
  @tracked showAdvanced = false;
  @tracked newCustomField = null;

  @tracked _apiName = '';
  @tracked type = null;
  @tracked isSearchable = true;
  @tracked isFeatured = false;
  @tracked isPrivate = false;
  @tracked isHidden = false;
  @tracked isRequired = false;
  @tracked isExternal = false;

  @tracked selectedTab = 'Candidate';

  get isEditing() {
    return !!this.customField?.id;
  }

  get customField() {
    return this.translatableModel;
  }

  get translatableModel() {
    return this.newCustomField || this.args.translatableModel;
  }

  get isNotSearchable() {
    return !this.isSearchable;
  }

  get resourceTabs() {
    return [
      {
        type: 'Candidate',
        translationKey: 'settings.custom_fields.tabs.candidates',
      },
      {
        type: 'Job',
        translationKey: 'settings.custom_fields.tabs.jobs',
      },
    ];
  }

  get canNotBeSearchable() {
    return (
      this.selectedTab === 'Job' &&
      ['url', 'text', 'email', 'phone'].includes(this.customField?.inputType)
    );
  }

  get defaultStaticLanguage() {
    return this.current.company.defaultCareerSite?.languageCode;
  }

  get apiName() {
    if (this.apiNameListening) {
      return this.dashedValue(this.customField.name);
    }

    return this._apiName;
  }

  set apiName(value) {
    this.apiNameListening = false;
    this._apiName = value;
  }

  get hasPickedCustomFields() {
    return !!this.customField?.hasPickedCustomFields;
  }

  get _options() {
    if (this.customField?.inputType === 'select') {
      return this.customField?.options.toArray();
    }

    return [];
  }

  get hasNonUniqueValue() {
    return this._options.length !== uniqBy(this._options, 'value').length;
  }

  get hasEmptyOptionValue() {
    return this._options.some((o) => !o.value);
  }

  get optionsErrors() {
    let errors = [];
    if (this.hasNonUniqueValue) {
      errors.push('settings.custom_fields.errors.options_non_unique');
    }

    return errors;
  }

  get isValid() {
    const hasName = !!this.translatableModel?.name;
    const hasType = !!this.type;
    return (
      hasName && hasType && !this.hasEmptyOptionValue && !this.hasNonUniqueValue
    );
  }

  get selectedType() {
    return this.args.fields.find(
      (f) =>
        this.newCustomField?.type === f.type ||
        this.args.translatableModel?.type === f.type
    );
  }

  dashedValue(value) {
    return dasherize(value)
      .replace(/-{1,}/gi, '-')
      .replace(/[^a-zA-Z0-9-]/g, '');
  }

  @action
  handleTypeTabClick(tab) {
    this.selectedTab = tab;
  }

  @action
  setApiName(value) {
    let apiNameValue = this.dashedValue(value);
    this.apiName = apiNameValue;
  }

  @action
  close() {
    this.error = null;
    this.customField?.tidyOptions?.();
    this.args.onClose();
  }

  @action
  async saveAndClose() {
    setProperties(this.customField, {
      apiName: this.apiName,
      isSearchable: this.isSearchable && !this.isHidden,
      isFeatured: this.isFeatured && !this.isHidden,
      isPrivate: this.isPrivate && !this.isHidden,
      isExternal: this.isExternal && !this.isHidden,
      isRequired: this.isRequired && !this.isHidden,
      isHidden: this.isHidden,
      type: this.selectedType.type,
      ownerType: this.selectedTab,
    });

    await this.handleSave();
    this.close();
  }

  @action
  handleSelectType(type) {
    if (type.inputType === this.type) {
      return;
    }

    this.type = type.inputType;

    const name = this.newCustomField ? this.newCustomField.name : '';

    if (this.newCustomField !== null) {
      this.newCustomField.options.toArray().forEach((o) => {
        o.unloadRecord();
      });

      this.newCustomField.unloadRecord();
    }

    this.newCustomField = this.store.createRecord('custom-field', {
      name,
      languageCode: this.defaultStaticLanguage,
      type: type.type,
    });

    if (type.inputType === 'select') {
      this.newCustomField.options.pushObject(
        this.store.createRecord('custom-field/option', { rowOrder: 0 })
      );
    }
  }
}
