import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Purchase from 'teamtailor/lib/purchase';

export default class EditRoute extends Route {
  @service store;
  templateName = 'jobs.job.promote.pick';

  afterModel(model) {
    if (!get(model, 'invoiceInfo.content')) {
      const invoiceInfo = this.store.createRecord('invoiceInfo');
      set(model, 'invoiceInfo', invoiceInfo);
    }

    switch (get(model, 'channel.internalName')) {
      case 'ams':
        return RSVP.all([
          this.store.findAll('occupation-field').then(() => {
            return get(model, 'occupationName.occupationGroup').then(
              (group) => {
                return this.store.query('occupation-group', {
                  occupation_field_id: get(group, 'occupationField.id'),
                });
              }
            );
          }),
          this.store.findAll('country'),
          this.store.findAll('municipality-code'),
        ]);
      case 'cv_library':
        return RSVP.all([
          this.store.findAll('promotion-categories/cv-library-industry'),
          this.store.findAll('promotion-categories/cv-library-job-type'),
          this.store.findAll('promotion-categories/cv-library-salary-period'),
        ]);
      case 'cv_online_ee':
      case 'cv_online_lt':
      case 'cv_online_lv':
        return RSVP.all([
          this.store.findAll('promotion-categories/cv-online-work-time'),
          this.store.findAll('promotion-categories/cv-online-search-category'),
          this.store.query('promotion-categories/cv-online-town', {
            channel_internal_name: get(model, 'channel.internalName'),
          }),
        ]);
      case 'blocket':
      case 'stepstone_sverige':
        return RSVP.all([
          this.store.findAll('blocket-category'),
          this.store.findAll('blocket-subcategory'),
          this.store.findAll('blocket-region'),
          this.store.findAll('blocket-municipality'),
        ]);
      case 'jobnet':
        return RSVP.all([
          this.store.findAll('promotion-categories/jobnet-main-profession'),
          this.store.findAll('promotion-categories/jobnet-municipality'),
        ]);
      case 'jobteaser':
        return RSVP.all([
          this.store.findAll('promotion-categories/jobteaser-education-level'),
          this.store.findAll('promotion-categories/jobteaser-work-experience'),
          this.store.findAll('promotion-categories/jobteaser-contract-type'),
          this.store.findAll('promotion-categories/jobteaser-remote-type'),
          this.store.findAll(
            'promotion-categories/jobteaser-contract-duration'
          ),
          this.store.findAll(
            'promotion-categories/jobteaser-position-category'
          ),
          this.store.findAll('promotion-categories/jobteaser-payment-type'),
        ]);
      case 'metro':
        return RSVP.all([
          this.store.findAll('metro-category'),
          this.store.findAll('metro-region'),
        ]);
      case 'monster':
      case 'monster_fi':
      case 'monster_de':
      case 'monster_fr':
      case 'monster_us':
      case 'monster_ca':
      case 'monster_ie':
      case 'monster_uk':
      case 'monster_lu':
      case 'monster_nl':
        return RSVP.all([
          this.store.query('monster-job-category', {
            channel_internal_name: get(model, 'channel.internalName'),
          }),
          this.store.query('monster-job-industry', {
            channel_internal_name: get(model, 'channel.internalName'),
          }),
          this.store.query('monster-job-occupation', {
            channel_internal_name: get(model, 'channel.internalName'),
          }),
        ]);
      case 'ejobs':
        return RSVP.all([
          this.store.findAll('promotion-categories/ejobs-city'),
          this.store.findAll('promotion-categories/ejobs-department'),
        ]);
      case 'finn':
        return RSVP.all([
          this.store.findAll('finn-occupation'),
          this.store.findAll('finn-occupation-specialization'),
          this.store.findAll('finn-industry'),
        ]);
      case 'hotel-career':
        return RSVP.all([
          this.store.findAll('promotion-categories/hotel-career-employment'),
        ]);
      case 'uptrail':
        return RSVP.all([
          this.store.findAll('uptrail-category'),
          this.store.findAll('uptrail-region'),
        ]);
      case 'oikotie':
        return RSVP.all([this.store.findAll('oikotie-line-of-business')]);
      case 'mol':
        return RSVP.all([
          this.store.findAll('mol/general-profession').then(() => {
            return get(model, 'molProfession').then((profession) => {
              return get(profession, 'generalProfession').then((general) => {
                set(model, 'molGeneralProfession', general);
                return this.store.query('mol/profession', {
                  general_profession_id: get(general, 'id'),
                });
              });
            });
          }),
          this.store.findAll('promotion-categories/mol-additional-location'),
        ]);
      case 'reed':
        return this.store.findAll('reed/parent-sector').then(() => {
          return get(model, 'reedSector').then((sector) => {
            return get(sector, 'parentSector').then((parent) => {
              set(model, 'reedParentSector', parent);
              return this.store.query('reed/sector', {
                parent_sector_id: get(parent, 'id'),
              });
            });
          });
        });
      case 'caterer_global':
        return RSVP.all([
          this.store.findAll('promotion-categories/caterer-global-region'),
          this.store.findAll('promotion-categories/totaljobs-minmax-salary'),
        ]);
      case 'totaljobs':
      case 'caterer':
      case 'jobsite':
      case 'cwjobs':
        return RSVP.all([
          this.store.findAll('promotion-categories/totaljobs-region'),
          this.store.findAll('promotion-categories/totaljobs-minmax-salary'),
        ]);
      default:
        return undefined;
    }
  }

  setupController(controller, model) {
    super.setupController(controller, get(model, 'channel'));
    set(controller, 'promotion', model);

    const selectedBudget =
      get(model, 'price') === 0
        ? 'custom'
        : get(model, `channel.plans.${get(model, 'currency')}`)?.find(
            (plan) => plan.amount === get(model, 'price')
          );

    set(
      controller,
      'purchase',
      new Purchase({
        channel: get(model, 'channel'),
        promotion: model,
        currency: get(model, 'currency'),
        selectedBudget,
      })
    );
  }
}
