import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class CandidateAdapter extends ApplicationAdapter {}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    candidate: CandidateAdapter;
  }
}
