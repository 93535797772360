/* import __COLOCATED_TEMPLATE__ from './report-type-modal.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import FlipperService from 'teamtailor/services/flipper';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import { action, get } from '@ember/object';

interface ReportTypeModalArgs {
  isOpen: boolean;
  onClose: () => void;
}
export default class extends Component<ReportTypeModalArgs> {
  @service declare flipper: FlipperService;
  @service declare intl: IntlService;

  @tracked selectedType = 'jobs';

  get types() {
    let types = [
      {
        value: 'jobs',
        title: this.intl.t('custom_report.types.job.title'),
        description: this.intl.t('custom_report.types.job.description'),
      },
      {
        value: 'candidates',
        title: this.intl.t('custom_report.types.candidate.title'),
        description: this.intl.t('custom_report.types.candidate.description'),
      },
      {
        value: 'job_applications',
        title: this.intl.t('custom_report.types.job_application.title'),
        description: this.intl.t(
          'custom_report.types.job_application.description'
        ),
      },
    ];

    if (!get(this.flipper, 'disable_employee_analytics')) {
      types = [
        ...types,
        {
          value: 'users',
          title: this.intl.t('custom_report.types.employee.title'),
          description: this.intl.t('custom_report.types.employee.description'),
        },
      ];
    }

    return types;
  }

  @action
  onChange(value: string) {
    this.selectedType = value;
  }
}
