/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
/* eslint-disable ember/use-ember-get-and-set */
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import QuestionModel from 'teamtailor/models/question';
import ScorecardCriterium from 'teamtailor/models/scorecard-criterium';
import { MappedTypes } from 'teamtailor/constants/question';
import TagModel from 'teamtailor/models/tag';
import TranslationQuestionModel from 'teamtailor/models/translation/question';
import Current from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';
import PermissionsService from 'teamtailor/services/permissions';
import ScorecardCriteriaSorter from 'teamtailor/utils/scorecard-criteria-sorter';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { argDefault } from 'teamtailor/utils/arg-default';
import { next } from '@ember/runloop';
import CopilotService from 'teamtailor/services/copilot';
import uniqBy from 'teamtailor/utils/uniq-by';
import { get } from 'teamtailor/utils/get';

interface Args {
  baseModel: QuestionModel;
  changeset: BufferedChangeset;
  questionType: string;
  translation?: TranslationQuestionModel;
  hideQuestionOptions?: boolean;
}

export default class SettingsQuestionsForm extends Component<Args> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare permissions: PermissionsService;
  @service declare intl: IntlService;
  @service declare copilot: CopilotService;
  @argDefault hideQuestionOptions = false;
  sorter: ScorecardCriteriaSorter;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.sorter = new ScorecardCriteriaSorter(this.intl.primaryLocale);
    this.maybeCreateStructuredAsset();
  }

  get showQuestionOptions(): boolean {
    return !this.hideQuestionOptions;
  }

  get question() {
    return this.args.baseModel;
  }

  get workingSet(): BufferedChangeset | TranslationQuestionModel {
    if (this.isTranslation && this.args.translation) {
      return this.args.translation;
    }

    return this.args.changeset;
  }

  get isTranslation(): boolean {
    return !isEmpty(this.args.translation);
  }

  get selectedTags(): TagModel[] {
    const tags = this.args.changeset.get('tags') as TagModel[];
    return uniqBy(tags, 'name');
  }

  get selectedScorecardCriterium(): ScorecardCriterium | undefined {
    let selectedScorecardCriterium = <ScorecardCriterium | undefined>(
      this.args.changeset.scorecardCriterium
    );

    if (!selectedScorecardCriterium) {
      return undefined;
    }

    const id = selectedScorecardCriterium.get('id');
    const name = selectedScorecardCriterium.get('name');

    if (id) {
      selectedScorecardCriterium = this.sortedScorecardCriteria.find(
        (scorecardCriterium: ScorecardCriterium) => scorecardCriterium.id === id
      );
    }

    if (!selectedScorecardCriterium) {
      selectedScorecardCriterium = this.sortedScorecardCriteria.find(
        (scorecardCriterium: ScorecardCriterium) =>
          scorecardCriterium.name === name
      );
    }

    return selectedScorecardCriterium;
  }

  get isText() {
    return this.args.changeset.get('type') === MappedTypes.text;
  }

  get isRange() {
    return this.args.changeset.get('type') === MappedTypes.range;
  }

  get isChoice() {
    return this.args.changeset.get('type') === MappedTypes.choice;
  }

  get sortedScorecardCriteria() {
    return get(this.current.company, 'scorecardCriteria')
      .slice()
      .sort(this.sorter.sort);
  }

  maybeCreateStructuredAsset() {
    next(() => {
      const question = get(this, 'question');
      if (isPresent(question) && isEmpty(get(question, 'assetStructure'))) {
        const options = {
          department: null,
          role: null,
          region: null,
          location: null,
        };

        this.store.createRecord('asset-structure', {
          options,
          organizable: question,
        });
      }
    });
  }

  @action
  handleChangeTags(tag: TagModel) {
    toggleInList(this.args.changeset.get('tags'), tag);
  }

  @action
  userCanCreateTags() {
    return this.permissions.has('settings/update_recruitment', 'full');
  }

  @action
  handleSelectScorecardCriterium(scorecardCriterium: ScorecardCriterium) {
    this.args.changeset.scorecardCriterium =
      get(this.args.changeset, 'scorecardCriterium.id') ===
      scorecardCriterium.id
        ? null
        : scorecardCriterium;
  }

  @action
  onCriteriumCreation(scorecardCriterium: ScorecardCriterium) {
    this.handleSelectScorecardCriterium(scorecardCriterium);
  }
}
