/* import __COLOCATED_TEMPLATE__ from './navigation.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { MenuItem, Routes } from 'teamtailor/constants/unicorn/navigation';
import BootDataService from 'teamtailor/services/boot-data';

interface UnicornNavigationComponentSignature {
  Args: object;
}

export default class UnicornNavigationComponent extends Component<UnicornNavigationComponentSignature> {
  @service declare bootData: BootDataService;
  @service declare router: RouterService;
  @tracked searchValue = '';

  constructor(owner: unknown, args: object) {
    super(owner, args);
    this.router.on('routeDidChange', () => this.clearSearch());
  }

  get id(): string {
    return guidFor(this);
  }

  get pages() {
    if (this.searchValue.length) {
      return this.search(Routes);
    }

    return Routes;
  }

  highlightSearchValue(path: string) {
    const regexPattern = new RegExp(`(${this.searchValue}(s)?)`, 'gi');
    return path.replace(
      regexPattern,
      '<mark class="bg-transparent font-bold text-white">$1</mark>'
    );
  }

  createSearchItem(item: MenuItem, path: string) {
    const fullPath = `${path}${item.text}`;
    const breadcrumb = this.highlightSearchValue(fullPath);
    return {
      ...item,
      text: this.highlightSearchValue(item.text),
      breadcrumb,
    };
  }

  itemMatchesSearch(item: MenuItem): boolean {
    return item.text.toLowerCase().includes(this.searchValue.toLowerCase());
  }

  search(menuItems: MenuItem[], path = ''): MenuItem[] {
    return menuItems.reduce<MenuItem[]>((searchHits, menuItem) => {
      if (this.itemMatchesSearch(menuItem)) {
        if (menuItem.children) {
          menuItem.children.map((menuChild) => {
            if (menuChild.children) {
              menuChild.children.map((menuGrandChild) => {
                const childPath = `${path}${menuItem.text}/${menuGrandChild.text}/`;
                searchHits.push(
                  this.createSearchItem(menuGrandChild, childPath)
                );
              });
            } else {
              const childPath = `${path}${menuItem.text}/`;
              searchHits.push(this.createSearchItem(menuChild, childPath));
            }
          });
        } else {
          searchHits.push(this.createSearchItem(menuItem, path));
        }
      } else if (menuItem.children) {
        const newPath = path ? `${path}${menuItem.text}/` : `${menuItem.text}/`;
        const childResults = this.search(menuItem.children, newPath);
        searchHits = searchHits.concat(childResults);
      }

      return searchHits;
    }, []);
  }

  clearSearch() {
    if (this.searchValue.length) {
      this.searchValue = '';
    }
  }

  willDestroy() {
    super.willDestroy();
    this.router.off('routeDidChange', () => this.clearSearch());
  }

  @action
  handleInput(e: KeyboardEvent) {
    this.searchValue = (e.target as HTMLInputElement).value;
  }

  @action
  currentRouteIncludes(routeName: string) {
    return this.router.currentRouteName.includes(routeName.toLowerCase());
  }
}
