/* import __COLOCATED_TEMPLATE__ from './people.hbs'; */
import FormBaseComponent from './base';

export default class PeopleAdjustmentsFormComponent extends FormBaseComponent {
  reload() {
    if (this.model.enabled) {
      this.iframePreview.reloadSection();
    } else {
      this.iframePreview.removeSection(this.model.id);
    }
  }
}
