/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import CurrentService from 'teamtailor/services/current';
import jobApplicationTriggerModel from 'teamtailor/models/job-application-trigger';
import moment, { Moment } from 'moment-timezone';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import TriggerWebhookModel from 'teamtailor/models/trigger/webhook';

interface TriggerItemArgs {
  jobApplicationTrigger: jobApplicationTriggerModel;
}

export default class CandidateModalNewActivityTriggerItemComponent extends Component<TriggerItemArgs> {
  @service declare intl: IntlService;
  @service declare current: CurrentService;

  @tracked currentTime: Moment = moment();
  intervalId: number | undefined;

  constructor(owner: unknown, args: TriggerItemArgs) {
    super(owner, args);
    this.setLiveTimer();
  }

  willDestroy() {
    super.willDestroy();
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }

  get locale() {
    return this.current.locale;
  }

  get trigger() {
    return this.args.jobApplicationTrigger.trigger;
  }

  get job() {
    return get(this.trigger, 'job');
  }

  get triggerType() {
    return get(this.trigger, 'type');
  }

  get isTagTrigger() {
    return this.triggerType === 'tag';
  }

  get isWebhookTrigger() {
    return this.triggerType === 'webhook';
  }

  get logotype() {
    return this.isWebhookTrigger
      ? (
          this.args.jobApplicationTrigger
            .trigger as unknown as TriggerWebhookModel
        ).logotypeSquare
      : null;
  }

  get triggerTranslationName() {
    return get(this.trigger, 'triggerTranslationName');
  }

  get body() {
    return this.intl.t(
      `components.activity_feed.triggers.body.${this.triggerTranslationName}`,
      {
        htmlSafe: true,
        triggerContent: get(this.trigger, 'triggerContent'),
      }
    );
  }

  get isTriggerEnqueued() {
    const { scheduledAt } = this.args.jobApplicationTrigger;
    return (
      (isPresent(scheduledAt) &&
        moment(scheduledAt).isBefore(this.currentTime)) ||
      isEmpty(scheduledAt)
    );
  }

  get remainingTime() {
    if (this.isTriggerEnqueued) {
      return this.intl.t('components.activity_feed.triggers.enqueued');
    } else {
      const { scheduledAt } = this.args.jobApplicationTrigger;
      const momentScheduledAt = moment(scheduledAt);
      const localCurrentTime = this.currentTime.locale(this.locale);

      return localCurrentTime.to(momentScheduledAt);
    }
  }

  get remainingTimeTooltip() {
    if (this.isTriggerEnqueued) {
      return this.intl.t('components.activity_feed.triggers.will_run_shortly');
    } else {
      return this.intl.t('components.trigger_item.scheduled_for', {
        date: this.scheduledAtDateString,
        time: this.scheduledAtTimeString,
      });
    }
  }

  get scheduledAtDateString() {
    moment.locale(this.locale);
    const { scheduledAt } = this.args.jobApplicationTrigger;
    return scheduledAt ? moment(scheduledAt).format('ll') : '';
  }

  get scheduledAtTimeString() {
    moment.locale(this.locale);
    const { scheduledAt } = this.args.jobApplicationTrigger;
    return scheduledAt ? moment(scheduledAt).format('LT') : '';
  }

  get stage() {
    return get(this.args.jobApplicationTrigger.trigger, 'stage');
  }

  setLiveTimer() {
    this.intervalId = window.setInterval(() => {
      this.currentTime = moment();
    }, 60000);
  }

  @action
  cancelTrigger() {
    this.args.jobApplicationTrigger.status = 'cancelled';
    this.args.jobApplicationTrigger.save();
  }

  @action
  async triggerNow() {
    this.args.jobApplicationTrigger.triggerNow();
  }
}
