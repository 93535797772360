/* import __COLOCATED_TEMPLATE__ from './heading-bar.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class HeadingBarComponent extends Component {
  @service timeFormat;

  @tracked
  _isStuck = false;

  get externallyControlledIsStuck() {
    return this.args.isStuck !== undefined;
  }

  get isStuck() {
    return this.externallyControlledIsStuck ? this.args.isStuck : this._isStuck;
  }

  get formattedCount() {
    let number = new Intl.NumberFormat(this.timeFormat.locale).format(
      this.args.count
    );
    return number;
  }

  @action
  async isStuckChange(element, [isStuck]) {
    if (this.args.sticky) {
      element.classList.toggle('is-stuck', isStuck);
    }
  }
}
