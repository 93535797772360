/* import __COLOCATED_TEMPLATE__ from './edit-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import HireQualityResponseModel from 'teamtailor/models/hire-quality-response';
import IntlService from 'ember-intl/services/intl';
import { isBlank } from '@ember/utils';
import moment from 'moment-timezone';
import { tomorrow } from 'teamtailor/utils/due-date';
import { inject as service } from '@ember/service';

type Args = {
  hireQualityResponse: HireQualityResponseModel;
  onClose: () => void;
};

export default class HireQualitySurveyEditModal extends Component<Args> {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  quickScheduleOptions = ['twoMonths', 'threeMonths', 'sixMonths', 'oneYear'];

  get hireQualityResponse(): HireQualityResponseModel {
    return this.args.hireQualityResponse;
  }

  get minDate(): Date {
    return tomorrow;
  }

  get jobId(): number {
    return get(this.hireQualityResponse.jobApplication, 'jobId');
  }

  get saveDisabled(): boolean {
    return (
      isBlank(this.hireQualityResponse.user) ||
      isBlank(this.hireQualityResponse.scheduledAt)
    );
  }

  get scheduledAt(): Date | null {
    const { scheduledAt } = this.hireQualityResponse;
    if (scheduledAt) {
      const utcOffset = moment(scheduledAt).utcOffset() * 60000;
      const adjustedDate = new Date(scheduledAt.getTime() - utcOffset);

      return adjustedDate;
    }

    return null;
  }

  @action
  async onSave(): Promise<void> {
    await this.hireQualityResponse.save();
    this.flashMessages.success(
      this.intl.t('candidates.hire_quality_survey.survey_updated')
    );
    this.args.onClose();
  }
}
