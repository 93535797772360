/* import __COLOCATED_TEMPLATE__ from './candidate-merger.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { localCopy } from 'tracked-toolbox';

export default class CandidateMergedComponent extends Component {
  @service intl;
  @service flashMessages;
  @service router;

  @localCopy('args.candidates') selectedCandidates;
  @localCopy('args.primaryCandidate') primaryCandidate;

  @tracked selectingCandidates = false;

  get candidateModalRoute() {
    if (this.router.currentRouteName.startsWith('jobs.job.stages')) {
      return 'jobs.job.stages.index.candidate';
    }

    return 'candidates.candidate.index';
  }

  get mergingToSourcedCandidate() {
    return this.primaryCandidate?.isSourced;
  }

  get primaryCandidateIsExternallySourced() {
    return this.primaryCandidate?.isExternallySourced;
  }

  get selectedWithoutPrimary() {
    if (!this.primaryCandidate) {
      return this.selectedCandidates;
    }

    return this.selectedCandidates.filter((candidate) => {
      return candidate.id !== this.primaryCandidate.id;
    });
  }

  get isLoading() {
    return this.args.candidates.some((candidate) => !candidate.isLoaded);
  }

  get externallySourcedMergeOk() {
    if (this.primaryCandidateIsExternallySourced) {
      return true;
    }

    const externalRecruiterIds = [
      ...new Set(
        this.selectedWithoutPrimary
          .map((candidate) => candidate.externalRecruiterId)
          .filter(Boolean)
      ),
    ];

    return externalRecruiterIds.length <= 1;
  }

  mergeTask = dropTask(async () => {
    try {
      await this.args.onMerge(
        this.selectedWithoutPrimary,
        this.primaryCandidate
      );
    } catch (error) {
      if (error.code && error.code === 'ConflictError') {
        get(this, 'flashMessages').error(
          this.intl.t('candidates.candidate.candidate_merger.conflict_merge')
        );
      }
    }
  });

  @action
  nextStep() {
    this.selectingCandidates = false;
    this.primaryCandidate = this.selectedCandidates.firstObject;
  }

  @action
  goBack() {
    this.selectingCandidates = true;
  }

  @action
  resetModal() {
    this.selectingCandidates = false;
    this.args.onClose();
  }

  @action
  handleSwitchChange(candidate) {
    const candidateFound = this.selectedCandidates.some(
      (c) => c.id === candidate.id.toString()
    );
    if (candidateFound) {
      this.selectedCandidates = this.selectedCandidates.filter((c) => {
        return c.id !== candidate.id;
      });
    } else {
      this.selectedCandidates = [...this.selectedCandidates, candidate];
    }
  }
}
