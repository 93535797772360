/* import __COLOCATED_TEMPLATE__ from './label-switch.hbs'; */
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

interface LabelSwitchComponentArgs {
  label?: string;
  disabled?: boolean;
  handleChange?: (checked: boolean) => void;
  theme?: string;
  alignment?: string;
  tabindex?: number;
  checked?: boolean;
}

export default class LabelSwitchComponent extends Component<LabelSwitchComponentArgs> {
  @argDefault theme = 'default';

  @argDefault alignment = 'right';

  get displayLabelLeft(): boolean {
    return this.args.label !== undefined && this.alignment === 'left';
  }

  get displayLabelRight(): boolean {
    return this.args.label !== undefined && this.alignment === 'right';
  }
}
