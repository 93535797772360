/* import __COLOCATED_TEMPLATE__ from './candidate-form.hbs'; */
import Store from '@ember-data/store';
import { action, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import DepartmentModel, {
  NO_DEPARTMENT_ID,
} from 'teamtailor/models/department';
import LocationModel from 'teamtailor/models/location';
import TagModel from 'teamtailor/models/tag';
import PermissionsService from 'teamtailor/services/permissions';
import { QueueItemClass } from './queue-item';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import { DivisionModel } from 'teamtailor/models';
import { NO_DIVISION_ID } from 'teamtailor/models/division';

interface Args {
  queueItem?: QueueItemClass;
  locations?: LocationModel[];
  lastParseError: Error | null;
}

export default class BulkCandidateCandidateForm extends Component<Args> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare permissions: PermissionsService;

  get availableDepartments() {
    if (get(this.current.user, 'admin')) {
      return get(this.current.company, 'sortedDepartments');
    } else {
      return get(this.current.user, 'sortedAvailableDepartments');
    }
  }

  get availableDivisions() {
    return get(this.current.company, 'divisions');
  }

  get filteredDepartments() {
    let divisionId = get(get(this.args.queueItem, 'division'), 'id') ?? null;

    if (!divisionId) {
      return this.availableDepartments;
    }

    divisionId = divisionId === NO_DIVISION_ID ? null : divisionId;

    return this.availableDepartments.filter((department: DepartmentModel) => {
      const departmentDivisionId = get(get(department, 'division'), 'id');

      return (
        departmentDivisionId === divisionId ||
        get(department, 'id') === NO_DEPARTMENT_ID
      );
    });
  }

  get selectedLocations(): LocationModel[] {
    return this.args.queueItem?.locations.toArray() ?? [];
  }

  get selectedTags(): TagModel[] {
    return this.args.queueItem?.tags?.toArray() ?? [];
  }

  get useCountryPhonePicker(): boolean {
    return (
      isEmpty(this.args.queueItem?.phone) ||
      !!this.args.queueItem?.phone?.includes('+') ||
      !!this.args.queueItem?.phone?.includes('00')
    );
  }

  get headquarterCountryCode() {
    return get(
      get(this.current.company, 'headquarters'),
      'countryCode'
    )?.toLowerCase();
  }

  get userCanCreateTags(): boolean {
    return this.permissions.has('settings/update_recruitment', 'full');
  }

  @action
  handleChangeTags(tag: TagModel): void {
    if (this.args.queueItem) {
      if (!this.args.queueItem.tags) {
        this.args.queueItem.tags = [];
      }

      toggleInList(this.args.queueItem.tags, tag);
    }
  }

  @action
  onDivisionChange(pickedDivision?: DivisionModel): void {
    if (this.args.queueItem) {
      set(this.args.queueItem, 'division', pickedDivision);

      if (pickedDivision) {
        setProperties(this.args.queueItem, {
          department: undefined,
          role: undefined,
        });
      }
    }
  }

  @action
  onDepartmentChange(pickedDepartment?: DepartmentModel): void {
    if (this.args.queueItem) {
      const { role } = this.args.queueItem;
      set(this.args.queueItem, 'department', pickedDepartment);

      if (
        (pickedDepartment?.id === NO_DEPARTMENT_ID || !pickedDepartment) &&
        role
      ) {
        set(this.args.queueItem, 'role', undefined);
      }
    }
  }

  @action
  handlePickLocation(location: LocationModel): void {
    const { queueItem } = this.args;
    if (queueItem) {
      toggleInList(queueItem.locations, location);
    }
  }

  @action
  handleSetRating(newRating: number): void {
    if (this.args.queueItem) {
      this.args.queueItem.reviewRating = newRating;
    }
  }

  @action
  setCandidatePicture(url: string): void {
    if (this.args.queueItem) {
      this.args.queueItem.picture = url;
    }
  }

  @action
  removeCandidatePicture(): void {
    if (this.args.queueItem) {
      this.args.queueItem.picture = null;
    }
  }
}
