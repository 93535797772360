/* import __COLOCATED_TEMPLATE__ from './icon.hbs'; */
import Component from '@glimmer/component';
import { animate } from 'teamtailor/ember-smooth';

export default class ButtonIcon extends Component {
  async in(element) {
    await animate.from(
      element,
      {
        width: 0,
        marginLeft: -4,
        opacity: 0,
      },
      { duration: 150 }
    );
  }

  async out(elementOrClone) {
    await animate.to(
      elementOrClone,
      {
        width: 0,
        marginLeft: -4,
        opacity: 0,
      },
      { duration: 150 }
    );
  }
}
