/* import __COLOCATED_TEMPLATE__ from './apply-with-seek.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ApplyWithSeek extends Component {
  @service current;

  get clientId() {
    return this.current.company.seekAdvertiserId;
  }

  get enabled() {
    return !!this.current.company.applyWithSeek;
  }

  get disabled() {
    return !this.enabled;
  }

  @action
  saveCompany() {
    this.current.company.save();
  }

  get applyUrl() {
    return `https://${this.current.company.subdomain}.teamtailor.com/applications/social_seek`;
  }

  get isButtonDisabled() {
    return this.enabled && !this.clientId;
  }
}
