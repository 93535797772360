/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { CandidateModel, JobApplicationModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

interface InterviewsSignature {
  Args: {
    candidate: CandidateModel;
    jobApplication?: JobApplicationModel;
  };
}

export default class CandidateModalNewMainSectionInterviews extends Component<InterviewsSignature> {
  get candidate() {
    return this.args.candidate;
  }

  get job() {
    return get(this.args.jobApplication, 'job');
  }

  get interviewKits() {
    if (!this.job) {
      return null;
    }

    const interviewKitIds = this.interviews.mapBy('interviewKitId');
    const interviewKits = get(this.job, 'interviewKits');

    return interviewKits
      ? interviewKits.filter((interviewKit) => {
          return interviewKitIds.includes(get(interviewKit, 'id'));
        })
      : null;
  }

  get interviews() {
    if (!this.job) {
      return [];
    }

    const jobId = get(this.job, 'id');
    return this.candidate.interviews.toArray().filter((interview) => {
      const interviewKitId = interview.belongsTo('interviewKit').id();
      const { isPublished } = interview;
      return isPublished && interviewKitId && interview.jobId === jobId;
    });
  }
}
