/* import __COLOCATED_TEMPLATE__ from './external.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout, task } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';

export default class PromotionFormsExternal extends Component {
  @service pusher;
  @service user;
  @service current;
  @service intl;
  @service store;
  @service router;
  @service flashMessages;
  @service ttAlert;

  @tracked configSuccess;
  @tracked promotionRequest;
  @tracked variant;
  @tracked waitingForResponse;
  @tracked switchingPage;
  @tracked configErrors;
  @tracked disabled;
  @tracked webhookData = this.promotionRequest.webhookData;
  @tracked shouldFetchDynamicFields = true;
  @tracked checkoutData;

  get showResult() {
    return this.configSuccess && this.promotionRequest?.id;
  }

  get isLoading() {
    return !this.showResult && !this.configErrors;
  }

  get currentPageWebhookConfig() {
    return this.promotionRequest.webhookConfig[
      this.promotionRequest.webhookConfig.page
    ];
  }

  get lastCreatedOrUpdatedPromotionRequest() {
    const sortedPromotionRequests =
      this.args.promotion.promotionRequests.sortBy('createdAt');
    const lastPromotionRequest = sortedPromotionRequests.rejectBy(
      'status',
      'config_sent'
    ).lastObject;

    return lastPromotionRequest;
  }

  get paymentProcessorSetting() {
    return get(this.current.company, 'paymentProcessorSetting');
  }

  get vatPercentage() {
    return get(this.paymentProcessorSetting, 'vatPercentage');
  }

  get price() {
    return (
      get(this.args.channel, 'discountedPromotionPrice') ||
      get(this.args.channel, 'originalPromotionPrice')
    );
  }

  get isCreateType() {
    return this.args.type === 'create';
  }

  get canUnpublish() {
    return this.args.type === 'update' && this.args.channel.canRemove;
  }

  reloadPromotionRequest = task(async (promotionRequestId) => {
    if (this.promotionRequest.id !== promotionRequestId) {
      return;
    }

    await this.promotionRequest.reload();

    if (this.args.type === 'update') {
      const sortedPromotionRequests =
        this.args.promotion.promotionRequests.sortBy('createdAt');
      const lastPromotionRequest = sortedPromotionRequests
        .rejectBy('status', 'config_sent')
        .rejectBy('status', 'delete_sent').lastObject;

      if (lastPromotionRequest) {
        Object.keys(lastPromotionRequest.webhookData).forEach((key) => {
          if (this.webhookData[key] === undefined) {
            this.webhookData[key] = lastPromotionRequest.webhookData[key];
          }
        });
      }
    }

    await timeout(500);
    this.configSuccess = true;
  });

  setButtonStatus = task(async (type) => {
    this.waitingForResponse = false;
    if (type === 'success') {
      this.variant = 'positive';
    } else {
      this.variant = 'negative';
    }

    await timeout(2000);
    this.variant = 'primary';
  });

  @action
  async createPromotionRequest() {
    this.configSuccess = false;

    const promotionRequest = await this.store.createRecord('promotion-request');
    promotionRequest.channelActivation = this.args.channel.channelActivation;
    promotionRequest.job = this.args.job;
    promotionRequest.promotion = this.args.promotion;
    await promotionRequest.save();
    this.promotionRequest = promotionRequest;
    this.args.promotion.promotionRequests.pushObject(promotionRequest);
  }

  @action
  async save() {
    this.disabled = true;

    const promotion = await this.args.promotion.save();
    this.cleanUpPickedLocations();
    this.promotionRequest.webhookData = this.webhookData;
    this.promotionRequest.promotion = promotion;
    this.waitingForResponse = true;
    this.shouldFetchDynamicFields = false;

    await this.promotionRequest.save();
  }

  @action
  async handleUnpublish() {
    this.ttAlert.confirm(
      this.intl.t('jobs.job.promote.unpublish.confirm_message'),
      async () => {
        await this.args.promotion.unpublish();
      },
      () => {},
      {
        title: this.intl.t('jobs.job.promote.unpublish_promotion'),
        confirmButtonText: this.intl.t(
          'jobs.job.promote.unpublish.confirm_unpublish'
        ),
      }
    );
  }

  @action async handleRedirectPromotion() {
    this.disabled = true;

    await this.args.promotion.save();
    set(this.promotionRequest, 'webhookData', this.webhookData);
    await this.promotionRequest.save();
  }

  @action
  async previousPage() {
    this.switchingPage = 'previous';
    this.promotionRequest.webhookData = this.webhookData;
    await this.promotionRequest.previousPageConfig();
  }

  @action
  async nextPage() {
    this.switchingPage = 'next';
    this.promotionRequest.webhookData = this.webhookData;
    await this.promotionRequest.nextPageConfig();
  }

  @action
  formErrors(_error) {
    const error = typeof _error === 'string' ? JSON.parse(_error) : _error;

    this.configSuccess = false;
    if (typeof error === 'string') {
      return [error];
    }

    if (Array.isArray(error)) {
      return error;
    }

    if (typeof error === 'object' && error?.errors) {
      return error.errors;
    }

    return [this.intl.t('jobs.job.promote.promotion_config_fail')];
  }

  @action
  async setupPusher() {
    try {
      const channel = await this.pusher.channel(
        get(this, 'user.notificationsChannel')
      );

      channel.bind('promotion-request-config-success', (promotionRequestId) => {
        this.switchingPage = false;
        this.reloadPromotionRequest.perform(promotionRequestId);
      });

      channel.bind('promotion-request-config-fail', (error) => {
        this.configErrors = this.formErrors(error);
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      });

      channel.bind('promotion-request-action', async (data) => {
        const { payload, type, request_type, redirect_link } = data;
        this.store.pushPayload(payload);
        this.setButtonStatus.perform(type);
        if (type === 'success') {
          await this.handlePromotionSuccess({
            payload,
            type,
            request_type,
            redirect_link,
          });
        } else {
          await this.args.promotion.reload();

          this.disabled = false;

          this.flashMessages.error(
            this.intl.t(`jobs.job.promote.promotion_${request_type}_${type}`)
          );
        }
      });
    } catch {
      return;
    }
  }

  @action
  async handlePromotionSuccess({ type, request_type, redirect_link }) {
    if (redirect_link) {
      await this.args.promotion.reload();
    } else {
      this.flashMessages.success(
        this.intl.t(`jobs.job.promote.promotion_${request_type}_${type}`)
      );
      await this.args.promotion.reload();
      await this.onSuccess.perform();
    }

    this.disabled = false;
  }

  @action
  isConfigValid() {
    this.disabled = this.currentPageWebhookConfig.some(
      (field) => !field.isValid
    );
  }

  @action
  async teardownPusher() {
    const channel = await this.pusher.channel(
      get(this, 'user.notificationsChannel')
    );

    if (channel) {
      channel.unbind('promotion-request-config-success');
      channel.unbind('promotion-request-config-fail');
      channel.unbind('promotion-request-action');
    }
  }

  onSuccess = task(async () => {
    await timeout(2000);
    this.router.transitionTo('jobs.job.promote');
  });

  @action
  onValueChanged(key, value) {
    this.isConfigValid();

    this.webhookData[key] = value;
  }

  cleanUpPickedLocations() {
    this.args.promotion.pickedLocations
      .filterBy('hasDirtyAttributes')
      .forEach((pl) => pl.rollbackAttributes());
  }
}
