/* import __COLOCATED_TEMPLATE__ from './page-meta-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import PageModel from 'teamtailor/models/page';

interface PageMetaFormComponentArgs {
  page: PageModel;
  onSave: (page: PageModel) => void;
}

export default class PageMetaFormComponent extends Component<PageMetaFormComponentArgs> {
  get urlPrefix() {
    const urlParts = this.args.page.publicUrl.split('/');
    urlParts.pop();
    return `/${urlParts.join('/')}`;
  }

  @action
  handleSave() {
    return this.args.onSave(this.args.page);
  }
}
