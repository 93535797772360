/* import __COLOCATED_TEMPLATE__ from './requisition-types.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { capitalize } from '@ember/string';
interface OptionsArgs {
  id: string | undefined;
  label: string | undefined;
}

export default class extends Component {
  @service declare intl: IntlService;

  get options(): OptionsArgs[] {
    const statuses = [
      'approved',
      'pending',
      'rejected',
      'completed',
      'draft',
      'archived',
    ];

    return statuses.map((status) => ({
      id: status,
      label: capitalize(this.intl.t(`job.requisitions_options.${status}`)),
    }));
  }
}
