/* import __COLOCATED_TEMPLATE__ from './new-label.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { parallel } from 'ember-animated';
import move from 'ember-animated/motions/move';
import { fadeIn } from 'ember-animated/motions/opacity';
import { boolArg } from 'teamtailor/utils/bool-arg';

export default class NewLabelComponent extends Component {
  // eslint-disable-next-line require-yield
  transition = function* ({ insertedSprites }) {
    if (boolArg(this.args.animateIn)) {
      for (let sprite of insertedSprites) {
        sprite.startTranslatedBy(0, 3);
        parallel(
          move(sprite, { duration: 200 }),
          fadeIn(sprite, { from: 0, duration: 200 })
        );
      }
    }
  }.bind(this);

  get showLabel() {
    return this.args.startDate
      ? moment(this.args.startDate).add(2, 'weeks').isAfter(new Date())
      : false;
  }
}
