import Model, { attr, belongsTo, AsyncBelongsTo } from '@ember-data/model';
import JobModel from 'teamtailor/models/job';

export default class JobCommentStatusModel extends Model {
  @belongsTo('job')
  declare job: AsyncBelongsTo<JobModel>;

  @attr('date') declare latestReadCommentAt: Date | null;
  @attr declare unread: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'job-comment-status': JobCommentStatusModel;
  }
}
