/* import __COLOCATED_TEMPLATE__ from './meta.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import Message from 'teamtailor/models/message';
import TimeFormatService from 'teamtailor/services/time-format';

interface MetaArgs {
  message: Message;
  isOnline: boolean;
}

export default class Meta extends Component<MetaArgs> {
  @service declare timeFormat: TimeFormatService;

  get maybeDeliveredInChat() {
    return this.args.isOnline;
  }
}
