/* import __COLOCATED_TEMPLATE__ from './preview-modal.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { QUESTION_TYPES } from 'teamtailor/constants/question';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import IntlService from 'ember-intl/services/intl';
import CurrentService from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import QuestionModel from 'teamtailor/models/question';
import ScorecardCriteriumModel from 'teamtailor/models/scorecard-criterium';
import {
  NESTED_TYPES,
  OrderedCompetence,
} from 'teamtailor/utils/competence/ordered-list-helpers';

type InterviewKitCriteraTagsArgs = {
  interviewKit: InterviewKitModel;
};

type Question = {
  type: string;
  icon: string;
  value: string;
};

export default class PreviewModalComponent extends Component<InterviewKitCriteraTagsArgs> {
  @service declare intl: IntlService;
  @service declare current: CurrentService;

  get questions() {
    return this.filterModelsByType(NESTED_TYPES.QUESTION);
  }

  get scorecardCriteria() {
    return this.filterModelsByType(NESTED_TYPES.COMPETENCE);
  }

  filterModelsByType(
    modelType: NESTED_TYPES
  ): (QuestionModel | ScorecardCriteriumModel)[] {
    return get(this.args.interviewKit, 'nestedInterviewItems').reduce(
      (
        acc: (QuestionModel | ScorecardCriteriumModel)[],
        item: OrderedCompetence
      ) => {
        if (item.type === modelType && item.model) {
          acc.push(item.model);
        }

        item.children?.forEach((child) => {
          if (child.type === modelType && child.model) {
            acc.push(child.model);
          }
        });

        return acc;
      },
      []
    );
  }

  get availableQuestionTypes(): Question[] {
    return Object.entries(QUESTION_TYPES)
      .map((question) => {
        return {
          type: question[0],
          icon: question[1].icon,
          value: this.intl.t(question[1].translationKey),
        };
      })
      .sortBy('value');
  }
}
