/* import __COLOCATED_TEMPLATE__ from './stage-types-column.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';
import Stage from 'teamtailor/models/stage-type';
import CandidateModel from 'teamtailor/models/candidate';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import JobApplicationModel from 'teamtailor/models/job-application';
import ArrayProxy from '@ember/array/proxy';
import Current from 'teamtailor/services/current';

type StageTypesColumnArgs = {
  showAmount?: number;
  candidate: CandidateModel;
};

export default class StageTypesColumn extends Component<StageTypesColumnArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;

  @tracked jobApplications: ArrayProxy<JobApplicationModel> | [] = [];

  get stageTypesCounts() {
    return get(this.candidate, 'stageTypesCounts');
  }

  get inboxCounts() {
    return get(this.candidate, 'inboxCount');
  }

  get hiredCounts() {
    return get(this.candidate, 'hiredCount');
  }

  get candidate(): CandidateModel {
    return this.args.candidate;
  }

  get showAmount(): number {
    return this.args.showAmount || 2;
  }

  get inboxCount(): number {
    return this.stageTypesCounts?.inbox_count || this.inboxCounts || 0;
  }

  get hiredCount(): number {
    return this.stageTypesCounts?.hired_count || this.hiredCounts || 0;
  }

  get badges() {
    const editableStageTypes = get(this.current.company, 'editableStageTypes');

    return [
      {
        category: 'inbox',
        count: this.inboxCount,
        jobTitles: this.getTooltip(
          this.jobApplications.filterBy('stage.inbox')
        ),
      },

      ...editableStageTypes.map((stage: Stage) => {
        return {
          category: stage.category,
          count: stage.category
            ? this.stageTypesCounts?.[`${stage.category}_count`]
            : 0,

          jobTitles: this.getTooltip(
            this.jobApplications.filterBy(
              'stage.stageType.category',
              stage.category
            )
          ),
        };
      }),

      {
        category: 'hired',
        count: this.hiredCount,
        jobTitles: this.getTooltip(
          this.jobApplications.filterBy('stage.hired')
        ),
      },

      {
        category: 'rejected',
        count: get(this.candidate, 'rejectedCount'),
        jobTitles: this.getTooltip(this.jobApplications.filterBy('isRejected')),
      },
    ];
  }

  getTooltip(jobApplications: JobApplicationModel[]) {
    return jobApplications.mapBy('jobTitle').join(', ');
  }

  get badgesWithCounts() {
    return this.badges.filter((badge) => (badge.count || 0) > 0);
  }

  get visibleBadges() {
    return this.badgesWithCounts.slice(0, this.showAmount);
  }

  get otherBadgesText(): string {
    const otherBadgesText: string[] = [];
    const otherBadges = this.badgesWithCounts.slice(
      this.showAmount,
      this.badgesWithCounts.length
    );

    otherBadges.forEach((badge) => {
      const badgeText = `${this.intl.t(`job.stage_types.${badge.category}`)}${
        badge.count && badge.count > 1 ? `(${badge.count})` : ''
      }`;
      otherBadgesText.push(badgeText);
    });

    return otherBadgesText.join(', ');
  }

  get numberOfAdditionalBadges() {
    return this.badgesWithCounts.length - this.visibleBadges.length;
  }

  @action
  async loadTooltips() {
    const jobApplications = await get(this.candidate, 'jobApplications');
    this.jobApplications = jobApplications;
  }
}
