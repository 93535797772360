/* import __COLOCATED_TEMPLATE__ from './message.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import { ChatMessage } from 'teamtailor/utils/video-meetings/utils';

type Args = {
  chatMessage: ChatMessage;
  multipleCandidateMeeting: boolean;
};

export default class VideoMeetingChatMessage extends Component<Args> {
  @service declare timeFormat: TimeFormatService;

  get defaultTimeFormat() {
    return 'HH:mm';
  }

  get getSenderInitials() {
    return this.args.chatMessage.attendee_name
      .split(' ')
      .slice(0, 2)
      .map((name) => name[0])
      .join('');
  }

  colors = [
    '#1abc9c',
    '#16a085',
    '#f1c40f',
    '#f39c12',
    '#2ecc71',
    '#27ae60',
    '#e67e22',
    '#d35400',
    '#3498db',
    '#2980b9',
    '#e74c3c',
    '#c0392b',
    '#9b59b6',
    '#8e44ad',
    '#bdc3c7',
    '#34495e',
    '#2c3e50',
    '#95a5a6',
    '#7f8c8d',
    '#ec87bf',
    '#d870ad',
    '#f69785',
    '#9ba37e',
    '#b49255',
    '#b49255',
    '#a94136',
  ];

  get backgroundColor() {
    if (!this.args.multipleCandidateMeeting) return '#71717a';

    const ateendeeNameHashCode = this.hashCode(
      this.args.chatMessage.attendee_name
    );
    const colorIndex = ateendeeNameHashCode % this.colors.length;
    return this.colors[colorIndex];
  }

  hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32-bit integer
    }

    return Math.abs(hash);
  }
}
