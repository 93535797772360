/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import InsightsService from 'teamtailor/services/insights';
import { gql } from '@apollo/client/core';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import FlipperService from 'teamtailor/services/flipper';
import { StageModel } from 'teamtailor/models';

const QUERY = gql`
  query OddsOfHiringQuery($jobId: ID!) {
    similarJobs: oddsOfHiringQuery(jobId: $jobId, similarJobs: true) {
      weeklyOdds: odds(frequency: WEEKLY) {
        numDays
        week: index
        frequency
        value
      }
      averageTimeToHire
    }
  }
`;

interface OddsOfHiringInterface {
  jobId: string;
  stage: StageModel;
}

export type WeeklyOddsType = {
  frequency: 'WEEKLY';
  week: number;
  numDays: number;
  value: number;
  month?: never;
};

interface OddsOfHiringQueryResult {
  similarJobs: {
    weeklyOdds: WeeklyOddsType[];
    averageTimeToHire: number;
  };
}

export default class OddsOfHiringComponent extends Component<OddsOfHiringInterface> {
  @service declare insights: InsightsService;
  @service declare flipper: FlipperService;

  @tracked weeklyOdds: WeeklyOddsType[] = [];
  @tracked averageTimeToHire = 0;

  get shouldShowOddsOfHiring() {
    return (
      (this.job.isPublished || this.job.isUnlisted) &&
      this.job.isPublic &&
      this.hasEnoughStages &&
      !this.fetchOddsOfHiringTask.isRunning
    );
  }

  get canShowForecast() {
    return this.hasRequiredNumberOfApplications && this.hasForecast;
  }

  get job() {
    return this.args.stage.job;
  }

  get hasRequiredNumberOfApplications() {
    const activeStages = this.job.stages.filter((stage) => !stage.hired);
    const totalActiveApplications = activeStages.reduce((acc, stage) => {
      const applicationCount = stage.activeJobApplications.length;
      return acc + applicationCount;
    }, 0);

    return totalActiveApplications >= 1;
  }

  get hasEnoughStages() {
    return this.job.stages.length >= 5;
  }

  get hasForecast() {
    return this.weeklyOdds.length > 0;
  }

  fetchOdds = task(async () => {
    const { jobId } = this.args;
    const result = await this.insights.query<OddsOfHiringQueryResult>({
      query: QUERY,
      variables: {
        jobId,
      },

      context: { jobId },
    });

    const { weeklyOdds, averageTimeToHire } = result.similarJobs;
    const smoothedWeeklyOdds = weeklyOdds.map((odd) => {
      const oddsInPercent = this.oddsForWeek(odd, weeklyOdds);

      return {
        ...odd,
        value: oddsInPercent,
      };
    }) satisfies WeeklyOddsType[];

    this.weeklyOdds = smoothedWeeklyOdds;
    this.averageTimeToHire = averageTimeToHire;
  });

  oddsForWeek(odds: WeeklyOddsType, weeklyOdds: WeeklyOddsType[]): number {
    if (odds.value < 1) {
      return Math.ceil(odds.value * 100);
    }

    const weekFadeoutAmount = 9 - odds.week;
    const oddsForWeekBeforeFadeoutStarts =
      ([...weeklyOdds].reverse().find((odd) => odd.value < 1.0)?.value || 1) *
      100;

    const weekNineValue =
      weeklyOdds.find((odd) => odd.week === 9)?.value || 1.0;

    const diffDistanceToWeekNine = Math.round(weekNineValue - odds.value);

    const finalOddsInPercent = 95 - weekFadeoutAmount - diffDistanceToWeekNine;

    // Adapt odds so we don't have a sudden drop when the last week before we reach 100% odds
    // has a value higher than the "final calculated odds".
    // Ex: 92% for last week, final calculated odds is 87%
    if (oddsForWeekBeforeFadeoutStarts > finalOddsInPercent) {
      const fadeoutAmount = 95 - weekFadeoutAmount;

      if (fadeoutAmount < oddsForWeekBeforeFadeoutStarts) {
        return Math.round(oddsForWeekBeforeFadeoutStarts);
      }

      return fadeoutAmount;
    }

    return finalOddsInPercent;
  }

  fetchOddsOfHiringTask = trackedTask(this, this.fetchOdds, () => [
    this.args.jobId,
  ]);
}
