/* import __COLOCATED_TEMPLATE__ from './select-report-type-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

type Args = {
  isOpen: boolean;
  onClose: () => void;
  onReportTypeSelected: (reportType: 'historic' | 'snapshot') => void;
};

export default class ReportReportTypeModal extends Component<Args> {
  @tracked selectedReportType?: 'historic' | 'snapshot';

  @action
  onClose() {
    this.selectedReportType = undefined;
    this.args.onClose();
  }

  @action
  next() {
    const { selectedReportType } = this;
    if (!selectedReportType) return;

    this.selectedReportType = undefined;
    this.args.onReportTypeSelected(selectedReportType);
  }
}
