/* import __COLOCATED_TEMPLATE__ from './job-title-tooltip.hbs'; */
import Component from '@glimmer/component';
import JobModel from 'teamtailor/models/job';
import { get } from 'teamtailor/utils/get';

interface JobTitleTooltipArgs {
  job: JobModel;
}

export default class JobTitleTooltip extends Component<JobTitleTooltipArgs> {
  get showTooltip(): boolean {
    return !!get(this.args.job, 'internalName');
  }
}
