/* import __COLOCATED_TEMPLATE__ from './editor-header.hbs'; */
import Component from '@glimmer/component';
import { set, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import ContentCareerSiteService from 'teamtailor/services/content-career-site';
import { get } from 'teamtailor/utils/get';
import {
  BlockLayoutModel,
  CareerSiteModel,
  SectionModel,
} from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import RouterService from '@ember/routing/router-service';
import IntlService from 'ember-intl/services/intl';
import { restartableTask } from 'ember-concurrency';
import FlashMessageService from 'teamtailor/services/flash-message';
import TtAlertService from 'teamtailor/services/tt-alert';
import { inject as controller } from '@ember/controller';
import ContentEditorController from 'teamtailor/controllers/content/editor';
import { SECTION_NAMES_WITH_ADJUSTMENTS } from './careersite-form';

type EditorHeaderComponentArgs = {
  backIcon?: string;
  displayEmoji?: boolean;
  section?: SectionModel;
  header?: string;
  saveCustomName: (name: string, section: SectionModel) => Promise<void>;
  name?: string;
  settingsPath?: string;
  careerSite?: CareerSiteModel;
};

export default class EditorHeaderComponent extends Component<EditorHeaderComponentArgs> {
  @service declare contentCareerSite: ContentCareerSiteService;
  @service declare iframePreview: IframePreviewService;
  @service declare intl: IntlService;
  @service declare ttAlert: TtAlertService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  defaultHeader = 'Careersite';

  @controller('content.editor')
  declare editorController: ContentEditorController;

  get backIcon() {
    return this.args.backIcon || 'chevron-left';
  }

  get displayEmoji() {
    if (this.args.displayEmoji === false) {
      return false;
    } else {
      return true;
    }
  }

  get hasAdjustments() {
    const name = get(this.args.section, 'name') || this.args.name;
    return SECTION_NAMES_WITH_ADJUSTMENTS.includes(name!);
  }

  get showDefaultHeader() {
    return isEmpty(this.args.header);
  }

  get showSectionControls() {
    return (
      this.args.section ||
      this.showFooterSettings ||
      this.showBlogSettings ||
      this.hasAdjustments
    );
  }

  get showFooterSettings() {
    return this.router.currentRouteName.includes('content.editor.footer');
  }

  get showHeaderSettings() {
    return this.router.currentRouteName.includes('content.editor.header');
  }

  get deleteBlockDisabled() {
    return this.showFooterSettings || this.showHeaderSettings;
  }

  get showBlogSettings() {
    return this.args.settingsPath;
  }

  get changeLayoutIsDisabled() {
    return this.blockLayouts.length < 2;
  }

  get blockLayouts() {
    const availableBlockLayouts = this.args.section
      ? this.allBlockLayouts
          .filter((blockLayout) => {
            return blockLayout.blockName === this.args.section?.name;
          })
          .toSorted((a, b) => a.displayName.localeCompare(b.displayName))
      : [];

    return availableBlockLayouts;
  }

  get allBlockLayouts() {
    return get(this.editorController, 'allBlockLayouts') || [];
  }

  get careerSite() {
    return get(this.args, 'careerSite');
  }

  @tracked isEditableMode = false;

  onCustomNameChange = restartableTask(async (name: string) => {
    await this.args.saveCustomName(name, this.args.section!);
    set(this, 'isEditableMode', false);
  });

  @action
  toggleEditableMode() {
    set(this, 'isEditableMode', true);
  }

  @action
  handleHeaderBlur(event: FocusEvent) {
    const { value } = event.target as HTMLInputElement;
    this.onCustomNameChange.perform(value);
  }

  @action
  handleEditRenameKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      set(this, 'isEditableMode', false);
    }

    if (event.key === 'Enter') {
      set(this, 'isEditableMode', false);
    }
  }

  @action
  async handleChangeLayout(blockLayout: BlockLayoutModel) {
    set(this.args.section!, 'layout', blockLayout.name);
    const { section } = this.args;
    // @ts-expect-error does the layout property get used by save?
    await section?.save({ layout: blockLayout.name });
    this.iframePreview.reloadIframe();
  }

  @action
  deleteSection() {
    const { section } = this.args;

    this.ttAlert.confirm(
      this.intl.t('content.editor.confirm_delete_section'),
      () => {
        this.performDeleteSection(section!);
        this.router.transitionTo('content.editor.index');
        this.flashMessages.success(
          this.intl.t('content.editor.section_deleted')
        );
      },
      () => {},
      {
        confirmButtonText: this.intl.t('content.editor.delete_section'),
      }
    );
  }

  @action
  handleChangeFooterLayout(layoutName: string) {
    if (this.careerSite) {
      set(this.careerSite, 'footerLayout', layoutName);
    }
  }

  async performDeleteSection(section: SectionModel) {
    await section.destroyRecord();
    this.iframePreview.removeSection(section.id);
  }
}
