/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import { CandidatePusherEvents } from 'teamtailor/utils/candidate-modal/pusher';
import { filteredTabs, TabName } from 'teamtailor/utils/candidate-modal/tabs';
import JobApplicationModel from 'teamtailor/models/job-application';
import CandidateModel from 'teamtailor/models/candidate';
import { get } from 'teamtailor/utils/get';
import { enqueueTask } from 'ember-concurrency';
import ConfettiService from 'teamtailor/services/confetti';
import StageModel from 'teamtailor/models/stage';
import CandidateModalFeedService from 'teamtailor/services/candidate-modal-feed';
import Current from 'teamtailor/services/current';
import { argDefault } from 'teamtailor/utils/arg-default';
import RouterService from '@ember/routing/router-service';
import MessageService from 'teamtailor/services/message-service';
import OnlineCandidatesService from 'teamtailor/services/online-candidates';

interface Args {
  candidate: CandidateModel;
  activeTab: TabName;
  onTabChange: (tab: TabName) => void;
  onClose: () => void;
  jobApplication?: JobApplicationModel;
  isAnonymousStage?: boolean;
  isVideoMeetingsContext?: boolean;
  jobApplicationId?: string;
}

const RIGHT_SIDEBAR_GLOBAL = 'sidebar-global';

export default class CandidateModalContent extends Component<Args> {
  @service declare current: Current;
  @service declare pusher: PusherService;
  @service declare store: Store;
  @service declare confetti: ConfettiService;
  @service declare candidateModalFeed: CandidateModalFeedService;
  @service declare router: RouterService;
  @service declare messageService: MessageService;
  @service declare onlineCandidates: OnlineCandidatesService;

  @tracked jobApplication?: JobApplicationModel;
  @tracked modalToDisplay?: string;
  @tracked connectedPusherChannels: PusherChannel[] = [];
  @tracked candidateNameVisible = true;
  @tracked sideBarVisible = true;
  @tracked autofocus = false;
  @tracked globalFilter;

  @argDefault isVideoMeetingsContext = false;

  declare candidatePusherEvents: CandidatePusherEvents;
  declare tabs;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.router.currentURL.includes('jobs')) {
      this.globalFilter =
        localStorage.getItem(`${RIGHT_SIDEBAR_GLOBAL}`) === 'true';
    } else {
      this.globalFilter = true;
    }

    this.preselectJobApplication();

    this.setupPusher();

    registerDestructor(this, () => {
      this.messageService.reset();
      this.teardownPusher();
    });

    this.tabs = filteredTabs(this.current.company);
  }

  get currentTab() {
    return this.tabs.findBy('name', this.args.activeTab);
  }

  get candidate() {
    return this.args.candidate;
  }

  get stage() {
    return get(this.jobApplication, 'stage');
  }

  get isAnonymous() {
    const { stage } = this;
    return get(stage, 'isFulfilled')
      ? get(stage, 'anonymous')
      : this.args.isAnonymousStage;
  }

  get candidateDisplayName() {
    return this.isAnonymous
      ? this.candidate.anonymousName
      : this.candidate.nameOrEmail;
  }

  get outerJobContext() {
    // is used for determing if candidate modal is opened inside a job, and will only return the job for passed @jobApplication
    // Not the same as any selected job application from the job context switcher
    return get(this.args.jobApplication, 'job');
  }

  get jobApplicationMatchesOuterJobContext() {
    return Boolean(
      this.args.jobApplication &&
        this.jobApplication?.job === this.outerJobContext
    );
  }

  setupPusher() {
    this.candidatePusherEvents = new CandidatePusherEvents(
      this.args.candidate,
      this.pusher,
      this.store,
      this.candidateModalFeed,
      this.current.company
    );

    this.candidatePusherEvents.bindEvents();
  }

  teardownPusher() {
    this.candidatePusherEvents.unbindEvents();
  }

  get isOnline() {
    return this.onlineCandidates.members.includes(this.candidate.uuid);
  }

  get showCloseButton() {
    return !this.modalToDisplay && !this.args.isVideoMeetingsContext;
  }

  async preselectJobApplication() {
    if (this.args.jobApplicationId) {
      await this.candidate.hasMany('jobApplications').load();

      const jobApplications = this.candidate.hasMany('jobApplications');
      const exists = jobApplications.ids().includes(this.args.jobApplicationId);

      if (exists) {
        this.jobApplication = jobApplications
          .value()
          ?.find(
            (jobApplication: JobApplicationModel) =>
              jobApplication.id === this.args.jobApplicationId
          );
      }
    } else {
      if (this.args.jobApplication) {
        this.jobApplication = this.args.jobApplication;
      } else {
        this.jobApplication = undefined;
      }
    }
  }

  changeStage = enqueueTask(async (stage: StageModel) => {
    if (!this.jobApplication) {
      return;
    }

    const previousStage = await this.jobApplication.activeStage;

    set(this.jobApplication, 'rowOrderPosition', 'first');
    set(this.jobApplication, 'activeStage', stage);

    await this.jobApplication.save();

    if (stage.hired) {
      this.confetti.show();
    }

    if (this.jobApplicationMatchesOuterJobContext) {
      if (get(stage, 'sortValue') !== 'rowOrder') {
        get(stage, 'fetchJobApplicationsTask').perform({
          rejected: false,
          reload: true,
        });
      }

      previousStage?.refreshCounts();
      stage.refreshCounts();

      const overdue = await get(this.outerJobContext, 'overdueJobApplication');

      if (overdue?.count) {
        overdue.reload();
      }
    }
  });

  @action
  toggleFilter(global: boolean) {
    this.teardownPusher();
    this.globalFilter = global;

    localStorage.setItem(
      `${RIGHT_SIDEBAR_GLOBAL}`,
      this.globalFilter.toString()
    );
    this.setupPusher();
  }

  @action
  handleJobApplicationChange(jobApplication: JobApplicationModel) {
    this.markAsRead(jobApplication);

    this.teardownPusher();
    this.jobApplication = jobApplication;

    this.router.transitionTo({
      queryParams: { jobApplicationId: jobApplication.id },
    });

    this.setupPusher();
  }

  @action
  handleOpenSubModal(modal: string) {
    this.modalToDisplay = modal;
  }

  @action
  handleCloseSubModal() {
    this.modalToDisplay = undefined;
    this.candidate.reload();
    get(this.candidate, 'jobApplications').every((jobApplication) =>
      jobApplication.reload()
    );
  }

  @action
  toggleSideBar() {
    this.sideBarVisible = !this.sideBarVisible;
  }

  @action
  handleTabChange(tab: TabName, autoFocus = false) {
    if (!this.sideBarVisible) {
      this.sideBarVisible = true;
    }

    if (this.autofocus !== autoFocus) {
      this.autofocus = autoFocus;
    }

    this.args.onTabChange(tab);
  }

  @action
  handleCandidateChange() {
    this.teardownPusher();
    this.setupPusher();
    this.preselectJobApplication();
  }

  markAsRead(jobApplication: JobApplicationModel | undefined) {
    if (!jobApplication) {
      return;
    }

    if (
      jobApplication.unread &&
      (!get(this.current.user, 'ttUser') ||
        get(get(this.current.user, 'company'), 'id') ===
          this.current.company.id)
    ) {
      jobApplication.markAsRead();
    }
  }
}
