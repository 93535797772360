/* import __COLOCATED_TEMPLATE__ from './rollup.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CustomReportProperty } from 'teamtailor/utils/custom-report-schema';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';

type RollupType = 'count' | 'distinct' | 'sum' | 'average' | 'min' | 'max';

interface CustomReportTableRollupArgs {
  values: any[];
  property: CustomReportProperty;
  rollupType?: RollupType;
  onChangeRollup: (_: RollupType | undefined) => void;
  hovering?: boolean;
}

type RollupObject = {
  name: RollupType;
  translatedName: string;
};

export default class CustomReportTableRollupComponent extends Component<CustomReportTableRollupArgs> {
  @service declare intl: IntlService;

  get currentRollupObject(): RollupObject | null {
    const { rollupType } = this.args;

    if (!rollupType) {
      return null;
    }

    return this.options.find((o) => o.name === rollupType) || null;
  }

  get calculatedValue(): string | undefined {
    const { values: vals, rollupType } = this.args;

    const values = vals.filter((v) => v || v === 0);

    if (!rollupType) {
      return undefined;
    }

    switch (rollupType) {
      case 'count':
        return values.filter((v) => v || v === 0).length.toString();

      case 'distinct':
        return new Set(values).size.toString();

      case 'sum':
        return values.reduce((acc, v) => acc + parseFloat(v), 0).toString();

      case 'average':
        return values.length > 0
          ? (
              values.reduce((acc, v) => acc + parseFloat(v), 0) / values.length
            ).toFixed(1)
          : undefined;

      case 'min':
        return Math.min(...values).toString();

      case 'max':
        return Math.max(...values).toString();

      case undefined:
        return undefined;
    }
  }

  get options() {
    const options: RollupObject[] = [
      {
        translatedName: this.intl.t('custom_report.rollups.count'),
        name: 'count',
      },
      {
        translatedName: this.intl.t('custom_report.rollups.distinct'),
        name: 'distinct',
      },
    ];

    if (
      this.args.property.type === 'integer' ||
      this.args.property.type === 'number' ||
      this.args.property.type === 'double'
    ) {
      options.push(
        {
          translatedName: this.intl.t('custom_report.rollups.sum'),
          name: 'sum',
        },
        {
          translatedName: this.intl.t('custom_report.rollups.average'),
          name: 'average',
        },
        {
          translatedName: this.intl.t('custom_report.rollups.min'),
          name: 'min',
        },
        {
          translatedName: this.intl.t('custom_report.rollups.max'),
          name: 'max',
        }
      );
    }

    return options;
  }

  @action
  handleUpdateType(rollup: RollupObject | null) {
    if (rollup === null) {
      this.args.onChangeRollup(undefined);
    } else {
      this.args.onChangeRollup(rollup.name);
    }
  }
}
