/* import __COLOCATED_TEMPLATE__ from './user-info-card.hbs'; */
import Component from '@glimmer/component';

export default class InsightsMoleculesUserInfoCard extends Component {
  get totalJobsCount() {
    const idsSet = new Set();
    const { activities } = this.args.user;
    activities.map((activity) => idsSet.add(activity.jobId));
    return idsSet.size;
  }

  get totalHiredCount() {
    const { activities } = this.args.user;
    return activities.filter((activity) => activity.eventType === 'HIRED')
      .length;
  }
}
