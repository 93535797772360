/* import __COLOCATED_TEMPLATE__ from './control-button.hbs'; */
import Component from '@glimmer/component';

interface VideoMeetingControlButtonArgs {
  icon: string;
  disabled: boolean;
  visible: boolean;
  style: string;
}

export default class VideoMeetingControlButton extends Component<VideoMeetingControlButtonArgs> {}
