/* import __COLOCATED_TEMPLATE__ from './upload-progress.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';

interface Args {
  fileLength: number;
  progress: number;
}

export default class UploadProgress extends Component<Args> {
  get fileLabel(): string {
    return this.args.fileLength === 1 ? 'file' : 'files';
  }

  get progressStyle(): SafeString | string {
    if (this.args.progress) {
      return htmlSafe(`width: ${this.args.progress}%`);
    } else {
      return '';
    }
  }
}
