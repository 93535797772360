/* import __COLOCATED_TEMPLATE__ from './page-publication-picker.hbs'; */
import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ContentCareerSiteService from 'teamtailor/services/content-career-site';
import { PagePublicationModel } from 'teamtailor/models';
import fuzzysort from 'fuzzysort';
import { task } from 'ember-concurrency';

type PagePublicationPickerComponentArgs = {
  onSelect: (pagePublication: PagePublicationModel) => void;
  selected: PagePublicationModel | null;
  icon?: string;
};

export default class PagePublicationPickerComponent extends Component<PagePublicationPickerComponentArgs> {
  @service declare contentCareerSite: ContentCareerSiteService;
  @service declare store: Store;
  @tracked searchTerm = '';
  @tracked allPagePublications? = [] as PagePublicationModel[];

  get filteredPagePublications() {
    if (!this.searchTerm) {
      return this.allPagePublications?.sortBy('navigationTitle');
    } else {
      return fuzzysort
        .go(this.searchTerm, this.allPagePublications!, {
          key: 'navigationTitle',
        })
        .map((val) => val.obj)
        .sortBy('navigationTitle');
    }
  }

  handleMouseenter = task(async () => {
    if (this.handleMouseenter.lastSuccessful) {
      return;
    }

    const careerSite = await this.contentCareerSite.current;

    this.allPagePublications = await this.store.query('page-publication', {
      not_template: true,
      published: true,
      career_site_id: careerSite?.id,
    });
  });
}
