/* import __COLOCATED_TEMPLATE__ from './latest-hire.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/application';
import TimeToHireReport from 'teamtailor/classes/analytics/report-time-to-hire';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { action } from '@ember/object';

export default class LatestHireWidget extends WidgetClass {
  @service reports;
  @service store;
  @service server;
  @service router;

  @tracked candidateId;
  @tracked jobTitle;
  @tracked name;
  @tracked imageData = Object.freeze({});

  constructor(...args) {
    super(...args);

    this.fetchData.perform();
  }

  fetchData = task(async () => {
    const container = getOwner(this);
    const timeToHireReport = new TimeToHireReport({ container });
    const { rows: hires } = await timeToHireReport.fetch.perform();

    if (hires.length <= 0) {
      return null;
    }

    const lastHire = hires.find(
      (hire) => hire.candidate !== undefined
    )?.candidate;

    if (!lastHire) return null;

    await this.fetchAvatarDetails(lastHire.id).then((avatarDetails) => {
      this.imageData = {
        picture: avatarDetails.picture_id,
        socialImageUrl: avatarDetails.social_image_url,
        color: avatarDetails.color,
        initials: avatarDetails.initials,
      };
      this.candidateId = avatarDetails.id;
      this.jobTitle = get(lastHire, 'role.name');
      this.name = lastHire.nameOrEmail;
    });
  });

  get hasCandidate() {
    return this.name;
  }

  @action
  navigateToCandidate() {
    if (this.candidateId) {
      return this.router.transitionTo('candidates.candidate', this.candidateId);
    }
  }

  fetchAvatarDetails(candidateId) {
    return this.server.request(this.avatarDetailsUrl(candidateId));
  }

  avatarDetailsUrl(candidateId) {
    const adapter = this.store.adapterFor('candidate');
    return `${adapter.buildURL('candidates')}/${candidateId}/avatar_details`;
  }
}
