/* import __COLOCATED_TEMPLATE__ from './pie-chart-settings.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import Settings from 'teamtailor/utils/insights/reports/settings';

const RECOMMENDED_MAX_PIE_CHART_ITEMS = 20;

type Args = {
  settings: Settings;
  rowCount?: number;
  onUpdate: (settings: Settings) => void;
  scrollContainerElement?: HTMLElement;
};

export default class ReportPieChartSettings extends Component<Args> {
  get scrollContainerElement() {
    return this.args.scrollContainerElement;
  }

  get currentSettings() {
    return this.args.settings;
  }

  get currentLimit() {
    return String(this.args.settings.limit);
  }

  get percentageOptions() {
    const numberArray = [];
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 10; j++) {
        numberArray.push({ value: Number(`${i}.${j}`) });
      }
    }

    for (let i = 5.0; i < 20; i += 0.5) {
      numberArray.push({ value: i });
    }

    for (let i = 20; i < 50; i++) {
      numberArray.push({ value: i });
    }

    return numberArray;
  }

  get showLimitAlert() {
    if (this.currentSettings.limit || this.currentSettings.groupSmallValues) {
      return false;
    }

    return (this.args.rowCount || 0) > RECOMMENDED_MAX_PIE_CHART_ITEMS;
  }

  get limitOptions() {
    const numberArray = [];
    for (let i = 0; i < 100; i++) {
      numberArray.push({ value: `${i}` });
    }

    return numberArray;
  }

  @action
  handleUpdateGroupPercentage(percentage: any) {
    const stringPercentage = percentage?.value || percentage;
    this.currentSettings.groupPercentage = stringPercentage
      ? parseFloat(stringPercentage)
      : undefined;
    this.args.onUpdate(this.currentSettings);
  }

  @action
  handleLimitTypeChanged() {
    this.currentSettings.sortDirection = 'desc';

    switch (this.currentSettings.limitType) {
      case 'groupSmallValues':
        this.currentSettings.limit = undefined;
        this.currentSettings.groupPercentage = 1;
        this.scrollDown();
        break;
      case 'normal':
        this.currentSettings.groupPercentage = undefined;
        this.currentSettings.limit = 20;
        this.scrollDown();
        break;
      default:
        this.currentSettings.groupPercentage = undefined;
        this.currentSettings.limit = undefined;
        break;
    }

    this.args.onUpdate(this.currentSettings);
  }

  scrollDown() {
    next(() => {
      if (this.scrollContainerElement) {
        this.scrollContainerElement.scrollTo(
          0,
          this.scrollContainerElement.scrollHeight
        );
      }
    });
  }
}
