/* import __COLOCATED_TEMPLATE__ from './view.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { timeout, restartableTask } from 'ember-concurrency';
import Current from 'teamtailor/services/current';
import { NO_DIVISION_ID } from 'teamtailor/models/division';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import { get } from 'teamtailor/utils/get';

interface EmployeeDashboardJobViewerListViewArgs {
  Args: {
    divisionId?: string;
    departmentId?: string;
    locationId?: number;
    onQueryChange(query: string): void;
    onPaginationChange(page: number): void;
    jobs: {
      id: number;
    }[];
  };
}

export default class EmployeeDashboardJobViewerListViewComponent extends Component<EmployeeDashboardJobViewerListViewArgs> {
  @service declare current: Current;

  @tracked searchProxy = '';

  get hasDivisionFilter() {
    return !!this.args.divisionId;
  }

  get hasDepartmentFilter() {
    return !!this.args.departmentId;
  }

  get hasLocationFilter() {
    return !!this.args.locationId;
  }

  get hasFilterSelected() {
    return (
      this.hasDivisionFilter ||
      this.hasDepartmentFilter ||
      this.hasLocationFilter
    );
  }

  get showDivisionFilter() {
    return !!this.current.company.hasMany('divisions').ids();
  }

  get showDepartmentFilter() {
    return !!this.current.company.hasMany('departments').ids();
  }

  get showLocationFilter() {
    return !!this.current.company.hasMany('locations').ids();
  }

  get sortedDepartments() {
    if (this.current.company.hasFeature('divisions') && !this.args.divisionId) {
      return [];
    }

    const filterDivisionId =
      this.args.divisionId === NO_DIVISION_ID ? null : this.args.divisionId;

    return this.current.company.sortedDepartments.filter((department) => {
      return (
        get(department.division, 'id') === filterDivisionId ||
        department.id === NO_DEPARTMENT_ID
      );
    });
  }

  get paddedJobs() {
    const { jobs } = this.args;
    if (jobs.length < 5) {
      return [...jobs, ...Array(5 - jobs.length).fill(null)];
    }

    return jobs;
  }

  handleSearchInput = restartableTask(async (query = null) => {
    if (query !== '') {
      await timeout(750);
    }

    if (typeof query === 'string' && query.length) {
      this.searchProxy = query;
    } else {
      this.searchProxy = '';
    }

    this.args.onQueryChange(this.searchProxy);
  });

  @action
  handleDidInsert(_: unknown, [query]: [string]) {
    this.searchProxy = query;
  }

  @action
  handlePaginationChange(page: number) {
    this.args.onPaginationChange(page);
  }
}
