/* import __COLOCATED_TEMPLATE__ from './wysiwyg-editor.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import { bind, next } from '@ember/runloop';
import { keyResponder, onKey, EkEvent } from 'ember-keyboard';

import EditorJS, { OutputData, API, BlockAPI } from '@editorjs/editorjs/types';

import { KeyCodes } from 'teamtailor/constants/key-codes';
import Toolbox, { ToolboxEvent } from 'teamtailor/utils/wysiwyg-editor/toolbox';
import Settings from 'teamtailor/utils/wysiwyg-editor/settings';
import ImageTool from 'teamtailor/utils/wysiwyg-editor/block-tools/image';
import VideoTool from 'teamtailor/utils/wysiwyg-editor/block-tools/video';
import DividerTool from 'teamtailor/utils/wysiwyg-editor/block-tools/divider';
import QuoteTool from 'teamtailor/utils/wysiwyg-editor/block-tools/quote';
import CodeTool from 'teamtailor/utils/wysiwyg-editor/block-tools/code';
import * as toolIcons from 'teamtailor/utils/wysiwyg-editor/icons';
import Editor from 'teamtailor/utils/wysiwyg-editor/editor';
import AiBlockTool, {
  AiBlockData,
} from 'teamtailor/utils/wysiwyg-editor/block-tools/ai';
import AiInlineTool from 'teamtailor/utils/wysiwyg-editor/inline-tools/ai-inline';
import IntlService from 'ember-intl/services/intl';
import ImageModel from 'teamtailor/models/image';
import GiphyImageModel from 'teamtailor/models/giphy-image';
import KeyboardService from 'ember-keyboard/services/keyboard';
import { assert } from '@ember/debug';
import { isNone } from '@ember/utils';
import Server from 'teamtailor/services/server';
import Pusher from 'teamtailor/services/pusher';
import { CustomInlineToolbar } from 'teamtailor/utils/wysiwyg-editor/module-overrides/inline-toolbar';
import { scrollToPromise } from 'teamtailor/utils/scroll-to-promise';
import { justAssert } from 'teamtailor/utils/justAssert';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { CoreDropdownMenuApi } from './core/dropdown-menu';

type Block = {
  wrapper: HTMLElement;
  data?: AiBlockData;
};

interface Args {
  content?: OutputData;
  initialHtml?: string;
  containerId?: string;
  scrollParent: HTMLElement;
  onChange: (data: OutputData) => void;
}

export interface Config {
  initializeEmberBlock: (
    blockId: string,
    args: { wrapper: HTMLElement; data?: AiBlockData }
  ) => void;
}

export type SelectionState = {
  currentBlockText: string;
  range: Range;
  blockApiBlock: BlockAPI;
  mark?: HTMLElement;
};

type ChatCompletionEvent = {
  content: string;
  timestamp: number;
  stop: boolean;
  error?: string;
};

function randomIntBetween(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

@keyResponder
export default class WysiwygEditor extends Component<Args> {
  holder?: HTMLElement;
  declare editor: EditorJS;

  @service declare intl: IntlService;
  @service declare keyboard: KeyboardService;
  @service declare server: Server;
  @service declare pusher: Pusher;
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;

  @tracked declare pickingImageBlockId?: string;
  @tracked declare pickingGifBlockId?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // has to be useCapture: true to trigger before editorjs event listener (that takes away '.ce-popover--opened' )
    document.addEventListener('keydown', this.handleKeydownUseCapture, true);
    document.addEventListener(
      'selectionchange',
      this.handleSelectionChangeUseCapture,
      true
    );
  }

  willDestroy(): void {
    super.willDestroy();
    document.removeEventListener('keydown', this.handleKeydownUseCapture, true);
    document.removeEventListener(
      'selectionchange',
      this.handleSelectionChangeUseCapture,
      true
    );
  }

  onEscPressToolboxIsOpen = false;
  escIsPressed = false;

  @action handleKeydownUseCapture(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.escIsPressed = true;
      if (this.aiInlineDropdownApi?.openSubMenus.size) {
        event.stopImmediatePropagation();
      }
    }

    this.onEscPressToolboxIsOpen = !!document.querySelector(
      '.ce-popover--opened'
    );
  }

  @action hideAiBlock(blockId: string): void {
    this.removeEmberBlock(blockId);
  }

  @action handleSelectionChangeUseCapture(event: Event) {
    if (this.aiResponseIsOpen || this.aiInlineDropdownApi?.isOpen) {
      event.stopImmediatePropagation(); // stop inline toolbar from being closed
    }
  }

  @action resetAiResponse() {
    this.newAiBlock = undefined;
    this.aiResponse = '';
    this.aiResponseType = '';
    this.aiResponseIsFinished = false;
    this.editableElem = undefined;
    this.currentPusherId = '';
    this.aiRequestSetTimeoutId = undefined;
  }

  @action handleDiscardClick() {
    this.resetAiResponse();
    next(() => {
      this.aiInlineDropdownApi?.open();
    });
  }

  declare keyboardPriority: number;

  @action
  handleInsert(element: HTMLElement) {
    this.initializeEditor.perform(element);

    const highestRegisteredPriority = this.keyboard.activeResponders.reduce(
      (acc, val) =>
        val !== this && val.keyboardPriority > acc ? val.keyboardPriority : acc,
      0
    );

    this.keyboardPriority = highestRegisteredPriority + 1;
  }

  @action
  handleOpenMediaLibrary(type: 'image' | 'gif', blockId: string) {
    if (type === 'image') {
      this.pickingImageBlockId = blockId;
    } else {
      this.pickingGifBlockId = blockId;
    }
  }

  @action
  async handlePickImage(image: ImageModel) {
    const url = image.s3Image;
    if (this.pickingImageBlockId) {
      await this.updateBlock(this.pickingImageBlockId, {
        imageId: image.id,
        url,
      });
      this.pickingImageBlockId = '';
    }
  }

  @action
  async handlePickGif(image: GiphyImageModel) {
    if (this.pickingGifBlockId) {
      await this.updateBlock(this.pickingGifBlockId, {
        imageId: image.id,
        url: image.url,
      });
      this.pickingGifBlockId = '';
    }
  }

  @onKey(KeyCodes.CMD_Z)
  @onKey(KeyCodes.CMD_SHIFT_Z)
  handleUndoRedo(event: Event) {
    if (this.args.containerId) {
      const outsideEditor = !(event.target as HTMLElement).closest(
        `#${this.args.containerId}`
      );

      if (outsideEditor && this.holder) {
        this.holder.dispatchEvent(new KeyboardEvent('keydown', event));
      }
    }
  }

  @onKey(KeyCodes.ESC)
  handleEsc(_event: KeyboardEvent, ekEvent: EkEvent) {
    if (this.onEscPressToolboxIsOpen) {
      ekEvent.stopPropagation();
    }

    this.onEscPressToolboxIsOpen = false;
  }

  async updateBlock(id: string, data: object = {}) {
    const block = await this.editor.blocks.getById(id)?.save();
    this.editor.blocks.update(id, {
      ...block?.data,
      ...data,
    });
  }

  @action removeEmberBlock(blockId: string) {
    const { type } = this.emberBlocks[blockId]!.block.data!;

    delete this.emberBlocks[blockId];
    this.emberBlocks = this.emberBlocks;
    this.editor.blocks.delete(this.editor.blocks.getBlockIndex(blockId));

    if (type === 'aiBlock') {
      (
        this.editor.configuration.holder as HTMLElement
      ).querySelector<HTMLElement>('.ce-toolbar')!.style.display = '';
    }
  }

  generateUniqueId() {
    return `post-${Math.random().toString(36).slice(2, 9)}`;
  }

  aiRequestSetTimeoutId?: number;

  @action aiChatRequest(
    messages: string[],
    onMessageReceived: (event: ChatCompletionEvent, pusherId: string) => void,
    onRequestTimeout: () => void
  ) {
    const pusherId = this.generateUniqueId();
    this.pusher.subscribe(pusherId).then(async (channel) => {
      channel.bind('new_message', (event: ChatCompletionEvent) => {
        if (event.error) {
          return this.flashMessages.error(event.error);
        }

        if (this.aiRequestSetTimeoutId) {
          clearTimeout(this.aiRequestSetTimeoutId);
          this.aiRequestSetTimeoutId = undefined;
        }

        onMessageReceived(event, pusherId);
      });

      const request = () => {
        const companyUuid = this.current.company.uuid;
        return this.server.fetch(
          `/app/companies/${companyUuid}/api/copilot/wysiwyg`,
          {
            method: 'POST',
            body: JSON.stringify({
              pusher_id: pusherId,
              messages,
            }),
          }
        );
      };

      this.aiRequestSetTimeoutId = window.setTimeout(() => {
        onRequestTimeout();
      }, 7000);

      await request();
    });

    return pusherId;
  }

  newAiBlock: BlockAPI | undefined;

  @action handleAskAiToWriteBlock(blockId: string, message: string) {
    type Params = Parameters<typeof this.handleAskAiToWriteBlock>;

    const aiBlockIndex = this.emberBlocks[blockId]?.block.data?.blockIndex;
    assert('aiBlockIndex has to exist', !isNone(aiBlockIndex));

    const currEmberBlock = this.emberBlocks[blockId];

    if (currEmberBlock) {
      this.emberBlocks[blockId] = {
        ...currEmberBlock,
        isLoading: true,
      };
      this.emberBlocks = this.emberBlocks;
    }

    this.currentPusherId = this.aiChatRequest(
      [message],
      (event: ChatCompletionEvent, pusherId: string) => {
        this.handleAiWriteBlockResponseReceived.perform(
          event,
          blockId,
          aiBlockIndex,
          pusherId
        );
      },
      () => {
        this.timedOutRetryFn = () => {
          this.timedOutRetryFn = undefined;
          const args: Params = [blockId, message];

          this.handleAskAiToWriteBlock(...args);
        };
      }
    );
  }

  aiResponseChunkTimeoutElapsed = true;
  editableElem: HTMLElement | undefined;

  handleAiWriteBlockResponseReceived = task(
    { enqueue: true },
    async (
      event: ChatCompletionEvent,
      blockId: string,
      aiBlockIndex: number,
      pusherId: string
    ) => {
      if (pusherId !== this.currentPusherId || this.timedOutRetryFn) {
        return;
      }

      if (!this.aiResponseChunkTimeoutElapsed) {
        await waitForProperty(this, 'aiResponseChunkTimeoutElapsed', true);
      }

      this.aiResponseChunkTimeoutElapsed = false;
      const data = event.content;

      if (data) {
        this.aiResponse += data;

        if (!this.newAiBlock) {
          this.removeEmberBlock(blockId);

          const insertBlockIndex = Math.min(
            this.editor.blocks.getBlocksCount(),
            aiBlockIndex + 1
          );

          this.newAiBlock = this.editor.blocks.insert(
            'paragraph',
            { text: this.aiResponse },
            null,
            insertBlockIndex,
            true
          );

          this.editableElem = this.newAiBlock.holder.querySelector<HTMLElement>(
            '[contenteditable="true"]'
          )!;

          this.deleteIfEmpty(insertBlockIndex - 1);
        } else {
          if (
            this.editableElem &&
            !['UL', 'OL'].includes(this.editableElem.tagName)
          ) {
            this.editableElem.spellcheck = false;

            const elemRect = this.editableElem.getBoundingClientRect();
            const scrollParentRect =
              this.args.scrollParent.getBoundingClientRect();

            if (elemRect.bottom >= scrollParentRect.bottom) {
              await scrollToPromise(
                this.args.scrollParent,
                this.args.scrollParent.scrollTop + 45
              );
            }

            this.editableElem.innerText = this.aiResponse;

            const range = window.getSelection();
            range?.selectAllChildren(this.editableElem);
            range?.collapseToEnd();
          }
        }
      }

      if (event.stop) {
        if (this.editableElem) {
          this.editableElem.spellcheck = true;
        }

        this.resetAiResponse();
        this.newAiBlock?.dispatchChange();
      }

      await timeout(randomIntBetween(10, 90));
      this.aiResponseChunkTimeoutElapsed = true;
    }
  );

  @tracked emberBlocks: Record<string, { block: Block; isLoading: boolean }> =
    {};

  @action async initializeEmberBlock(blockId: string, block: Block) {
    await this.editor.isReady;

    this.emberBlocks[blockId] = { block, isLoading: false };
    this.emberBlocks = this.emberBlocks;

    const nextBlockIndex = this.editor.blocks.getCurrentBlockIndex() + 1;
    if (this.editor.blocks.getBlockByIndex(nextBlockIndex)?.isEmpty) {
      this.editor.blocks.delete(nextBlockIndex);
    }

    if (block.data?.type === 'aiBlock') {
      (
        this.editor.configuration.holder as HTMLElement
      ).querySelector<HTMLElement>('.ce-toolbar')!.style.display = 'none';
    }
  }

  @action handleReplaceSelectionClick() {
    const { blockApiBlock, mark } = this.selectionState;
    const { aiResponse } = this;
    const childNodes = [...mark!.childNodes];
    mark?.replaceWith(...childNodes);
    const range = document.createRange();

    range.setStartBefore(childNodes[0]!);
    range.setEndAfter(childNodes.at(-1)!);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    range.deleteContents();
    range.insertNode(document.createTextNode(aiResponse));
    range.commonAncestorContainer.normalize();

    blockApiBlock.dispatchChange();

    this.resetAiResponse();
  }

  @action handleAiInlineOptionClick(
    queryName:
      | 'fix_spelling'
      | 'more_professional'
      | 'more_casual'
      | 'more_straightforward'
      | 'more_confident'
      | 'more_friendly'
      | 'shorter'
      | 'longer'
      | 'a_little_shorter'
      | 'a_little_longer'
  ) {
    this.aiResponseType = queryName;

    const { range, blockApiBlock, currentBlockText } = this.selectionState;

    const selectedText = range.toString();

    const instructionHumanized = queryName.replace('_', ' ');
    const instructions =
      queryName === 'fix_spelling'
        ? [`Fix spelling in this text: "${selectedText}"`]
        : [
            `Help me edit this text: "${currentBlockText}".`,
            `What can I replace "${selectedText}" with to make it be ${instructionHumanized}.`,
          ];

    this.aiResponseWidth = blockApiBlock.holder
      .querySelector<HTMLElement>('[contenteditable="true"]')
      ?.getBoundingClientRect().width;

    const conditionalRequirements = [];
    if (selectedText[0] === selectedText[0]?.toLowerCase()) {
      conditionalRequirements.push('Dont capitalize the first letter');
    }

    this.aiResponse = '';

    this.currentPusherId = this.aiChatRequest(
      [
        ...instructions,
        `Only respond with your edited version of "${selectedText}" without quotes around your response, nothing else.`,
        'Dont ever add quotes around your response.',
        ...conditionalRequirements,
      ],
      (event: ChatCompletionEvent, pusherId) => {
        this.handleAiInlineOptionResponseReceived.perform(
          event,
          selectedText,
          pusherId
        );
      },
      () => {
        this.timedOutRetryFn = () => {
          this.timedOutRetryFn = undefined;
          this.handleAiInlineOptionClick(queryName);
        };
      }
    );
  }

  @tracked timedOutRetryFn?: () => void;

  handleAiInlineOptionResponseReceived = task(
    { enqueue: true },
    async (
      event: ChatCompletionEvent,
      selectedText: string,
      pusherId: string
    ) => {
      if (pusherId !== this.currentPusherId || this.timedOutRetryFn) {
        return;
      }

      if (!this.aiResponseChunkTimeoutElapsed) {
        await waitForProperty(this, 'aiResponseChunkTimeoutElapsed', true);
      }

      this.aiResponseChunkTimeoutElapsed = false;
      if (event.stop) {
        this.aiResponseIsFinished = true;

        if (selectedText.at(-1) !== '.' && this.aiResponse.at(-1) === '.') {
          this.aiResponse = this.aiResponse.slice(0, -1);
        }
      }

      const data = event.content;

      if (data) {
        this.aiResponse += data;
      }

      await timeout(randomIntBetween(10, 90));
      this.aiResponseChunkTimeoutElapsed = true;
    }
  );

  @action deleteIfEmpty(blockIndex: number) {
    if (this.editor.blocks.getBlockByIndex(blockIndex)?.isEmpty) {
      this.editor.blocks.delete(blockIndex);
    }
  }

  @tracked declare selectionState: SelectionState;

  @action setSelectionState(selectionState: SelectionState) {
    this.selectionState = selectionState;
  }

  get aiResponseIsOpen() {
    return !!this.aiResponseType;
  }

  @tracked aiResponse = '';
  @tracked aiResponseIsFinished = false;
  @tracked aiResponseType = '';
  @tracked aiResponseWidth?: number;
  @tracked currentPusherId = '';

  @action handleAiResponseIsOpenChange([aiResponseIsOpen]: [boolean]) {
    this.inlineToolbarEl?.classList.toggle(
      'ce-inline-toolbar--showed',
      !aiResponseIsOpen
    );
  }

  initializeEditor = task(async (element: HTMLElement) => {
    const [{ default: EditorJS }, tools] = await Promise.all([
      import('@editorjs/editorjs'),
      this.tools(),
    ]);

    this.holder = element;
    this.editor = new EditorJS({
      holder: element,
      placeholder: this.intl.t('components.wysiwyg_editor.placeholder'),
      defaultBlock: 'paragraph',
      // @ts-expect-error incompatability between `@editorjs` & `@editorjs/header` types. Remove when upgrading?
      tools,
      data: this.args.content,
      onReady: bind(this, this.onReady),
      onChange: bind(this, this.onChange),
      moduleOverrides: {
        InlineToolbar: CustomInlineToolbar,
      },
    });
  });

  async onChange(
    api: API,
    event: CustomEvent<{ index: number; target: BlockAPI } | undefined>
  ) {
    if (event.detail?.target.name === 'aiBlock') {
      return;
    }

    const content = await api.saver.save();
    this.args.onChange(content);
  }

  async onReady() {
    const [{ default: DragDrop }, { default: Undo }] = await Promise.all([
      // @ts-expect-error No types available, need to write our own
      import('editorjs-drag-drop'),
      // @ts-expect-error No types available, need to write our own
      import('editorjs-undo'),
    ]);

    new DragDrop(this.editor);

    const undo = new Undo({
      editor: this.editor,
      config: {
        shortcuts: {
          undo: 'CMD+Z',
          redo: 'CMD+SHIFT+Z',
        },
      },
    });

    if (this.args.content) {
      undo.initialize(this.args.content);
    }

    const editor = new Editor(this.editor);
    new Toolbox(
      editor,
      {
        assistant: this.intl.t('components.wysiwyg_editor.toolbox.assistant'),
        basics: this.intl.t('components.wysiwyg_editor.toolbox.basics'),
        media: this.intl.t('components.wysiwyg_editor.toolbox.media'),
      },
      this.current.company.hasCopilotFeature('wysiwyg')
    );

    new Settings(editor, {
      up: this.intl.t('components.wysiwyg_editor.settings.up'),
      down: this.intl.t('components.wysiwyg_editor.settings.down'),
      duplicate: this.intl.t('components.wysiwyg_editor.settings.duplicate'),
      delete: this.intl.t('components.wysiwyg_editor.settings.delete'),
      size: this.intl.t('components.wysiwyg_editor.settings.size'),
      default: this.intl.t('components.wysiwyg_editor.settings.media.default'),
      large: this.intl.t('components.wysiwyg_editor.settings.media.large'),
      full: this.intl.t('components.wysiwyg_editor.settings.media.full'),
      quote: this.intl.t('components.wysiwyg_editor.block.quote'),
      'turn-into': this.intl.t('components.wysiwyg_editor.settings.turn_into'),
      'heading-2': this.intl.t('components.wysiwyg_editor.block.heading_2'),
      'heading-3': this.intl.t('components.wysiwyg_editor.block.heading_3'),
      text: this.intl.t('components.wysiwyg_editor.block.text'),
      'list-unordered': this.intl.t(
        'components.wysiwyg_editor.block.bulleted_list'
      ),

      'list-ordered': this.intl.t(
        'components.wysiwyg_editor.block.numbered_list'
      ),
    });

    if (this.args.initialHtml) {
      this.editor.blocks.renderFromHTML(this.args.initialHtml);
    }

    this.editor.on(ToolboxEvent.BlockAdded, ({ block, data }) => {
      if (block.name === 'image' && data.type === 'image') {
        this.handleOpenMediaLibrary('image', block.id);
      } else if (block.name === 'image' && data.type === 'gif') {
        this.handleOpenMediaLibrary('gif', block.id);
      }
    });

    justAssert(this.editor.configuration.holder instanceof HTMLElement);

    this.inlineToolbarEl =
      this.editor.configuration.holder.querySelector('.ce-inline-toolbar');
  }

  @tracked declare inlineToolbarEl: HTMLElement | null;

  @tracked declare aiInlineDropdownApi?: CoreDropdownMenuApi;

  @action registerAiInlineDropdownApi(api: CoreDropdownMenuApi) {
    this.aiInlineDropdownApi = api;
  }

  async tools() {
    const [
      { default: Header },
      { default: Paragraph },
      { default: List },
      { default: Strikethrough },
    ] = await Promise.all([
      import('@editorjs/header'),
      // @ts-expect-error No types available, need to write our own
      import('@editorjs/paragraph'),
      // @ts-expect-error No types available, need to write our own
      import('@editorjs/list'),
      // @ts-expect-error No types available, need to write our own
      import('editorjs-strikethrough'),
    ]);

    const formatting = ['bold', 'italic', 'link', 'strikethrough'];
    const nonFormattingNoAi = ['link', 'strikethrough'];
    const nonFormatting = [
      ...nonFormattingNoAi,
      ...(this.current.company.hasCopilotFeature('wysiwyg')
        ? ['aiInline']
        : []),
    ];

    return {
      strikethrough: {
        class: Strikethrough,
        shortcut: 'CMD+SHIFT+X',
      },

      ...(this.current.company.hasCopilotFeature('wysiwyg')
        ? {
            aiInline: {
              class: AiInlineTool,
              config: {
                initializeEmberBlock: this.initializeEmberBlock,
                setSelectionState: this.setSelectionState,
              },
            },

            aiBlock: {
              class: AiBlockTool,
              config: {
                initializeEmberBlock: this.initializeEmberBlock,
              },

              toolbox: [
                {
                  title: this.intl.t('components.wysiwyg_editor.block.ai'),
                  icon: toolIcons.sparkles({ class: 'text-decorative-purple' }),
                  data: {
                    type: 'aiBlock',
                  },
                },
              ],
            },
          }
        : {}),

      paragraph: {
        class: Paragraph,
        toolbox: {
          title: this.intl.t('components.wysiwyg_editor.block.text'),
          icon: toolIcons.text,
        },
      },

      heading: {
        class: Header,
        inlineToolbar: nonFormatting,
        toolbox: [
          {
            title: this.intl.t('components.wysiwyg_editor.block.heading_2'),
            icon: toolIcons.heading2,
            data: {
              level: 2,
            },
          },
          {
            title: this.intl.t('components.wysiwyg_editor.block.heading_3'),
            icon: toolIcons.heading3,
            data: {
              level: 3,
            },
          },
        ],

        config: {
          levels: [2, 3],
        },
      },

      list: {
        class: List,
        inlineToolbar: [...formatting, ...nonFormatting],

        toolbox: [
          {
            title: this.intl.t('components.wysiwyg_editor.block.bulleted_list'),

            icon: toolIcons.bulletedList,
            data: {
              style: 'unordered',
            },
          },
          {
            title: this.intl.t('components.wysiwyg_editor.block.numbered_list'),

            icon: toolIcons.numberedList,
            data: {
              style: 'ordered',
            },
          },
        ],
      },

      image: {
        class: ImageTool,
        config: {
          default: this.intl.t(
            'components.wysiwyg_editor.settings.media.default'
          ),

          large: this.intl.t('components.wysiwyg_editor.settings.media.large'),
          full: this.intl.t('components.wysiwyg_editor.settings.media.full'),
          imagePlaceholder: this.intl.t(
            'components.wysiwyg_editor.image_placeholder'
          ),

          gifPlaceholder: this.intl.t(
            'components.wysiwyg_editor.gif_placeholder'
          ),

          captionPlaceholder: this.intl.t(
            'components.wysiwyg_editor.caption_placeholder'
          ),

          openMediaLibrary: this.handleOpenMediaLibrary,
        },

        toolbox: [
          {
            title: this.intl.t('components.wysiwyg_editor.block.image'),
            icon: toolIcons.image,
            data: {
              type: 'image',
            },
          },
          {
            title: this.intl.t('components.wysiwyg_editor.block.gif'),
            icon: toolIcons.gif,
            data: {
              type: 'gif',
            },
          },
        ],
      },

      video: {
        class: VideoTool,
        config: {
          placeholder: this.intl.t(
            'components.wysiwyg_editor.video_placeholder'
          ),
        },

        toolbox: {
          title: this.intl.t('components.wysiwyg_editor.block.video'),
          icon: toolIcons.video,
        },
      },

      code: {
        class: CodeTool,
        config: {
          placeholder: this.intl.t(
            'components.wysiwyg_editor.code_placeholder'
          ),
        },

        toolbox: {
          title: this.intl.t('components.wysiwyg_editor.block.code'),
          icon: toolIcons.code,
        },
      },

      quote: {
        class: QuoteTool,
        inlineToolbar: nonFormatting,
        toolbox: {
          title: this.intl.t('components.wysiwyg_editor.block.quote'),
          icon: toolIcons.quote,
        },

        config: {
          quotePlaceholder: this.intl.t(
            'components.wysiwyg_editor.quote_placeholder'
          ),

          captionPlaceholder: this.intl.t(
            'components.wysiwyg_editor.caption_placeholder'
          ),
        },
      },

      delimiter: {
        class: DividerTool,
        toolbox: {
          title: this.intl.t('components.wysiwyg_editor.block.divider'),
          icon: toolIcons.delimiter,
        },
      },
    };
  }
}
