/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { actionBarTabs, TabName } from 'teamtailor/utils/candidate-modal/tabs';
import RouteHelper from 'teamtailor/services/route-helper';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { UploadFile } from 'ember-file-upload';
import {
  CandidateModel,
  InterviewKitModel,
  JobApplicationModel,
  JobModel,
  StageModel,
} from 'teamtailor/models';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';
import FlipperService from 'teamtailor/services/flipper';
import { later } from '@ember/runloop';
import FileUploadData from 'teamtailor/services/file-upload-data';

interface Args {
  activeTab?: TabName;
  candidate: CandidateModel;
  jobApplication?: JobApplicationModel;
  job?: JobModel;
}

const DEFAULT_TAB = 'activities';

export default class CandidateModalNewActionBar extends Component<Args> {
  @service declare router: RouterService;
  @service declare routeHelper: RouteHelper;
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare flipper: FlipperService;
  @service declare fileUploadData: FileUploadData;
  @tracked _activeTab: string | undefined;
  @tracked nextStageLoading = false;
  @tracked nextStageLoadingDone = false;

  declare tabs;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.tabs = actionBarTabs();
  }

  get activeTab() {
    return this._activeTab || this.args.activeTab || DEFAULT_TAB;
  }

  get user() {
    return this.current.user;
  }

  get company() {
    return this.current.company;
  }

  get newMeetingRoute() {
    return this.routeHelper.getNestedRoute('meetings.new');
  }

  get jobApplication() {
    return this.args.jobApplication;
  }

  get quickRejectSetting() {
    return get(this.jobApplication?.stage, 'quickRejectSetting');
  }

  get stage() {
    return this.jobApplication?.stage;
  }

  get job() {
    return this.args.job || this.jobApplication?.job;
  }

  get nextStage() {
    const stages = get(this.job, 'stages');

    const currentStage = get(this.jobApplication, 'stage');

    if (!currentStage || !stages) {
      return;
    }

    const currentStageIndex = stages.findIndex(
      (stage: StageModel) => stage.id === get(currentStage, 'id')
    );

    return stages[currentStageIndex + 1] || null;
  }

  get pickedInterviewKit() {
    const jobDetail = get(this.job, 'jobDetail');
    const pickedInterviewKits = get(jobDetail, 'pickedInterviewKits');
    const stageId = this.jobApplication?.belongsTo('stage').id();

    return pickedInterviewKits?.find(
      (pick) => pick.belongsTo('stage').id() === stageId
    );
  }

  @action
  setStageLoading() {
    this.nextStageLoading = true;

    later(() => {
      this.nextStageLoading = false;
      this.nextStageLoadingDone = true;
      later(() => {
        this.nextStageLoadingDone = false;
      }, 1200);
    }, 600);
  }

  @action
  handleJobOfferClick() {
    const route = this.routeHelper.getNestedRoute('job-offer.new');
    this.router.transitionTo(route);
  }

  @action
  createUploadRecord(_fileName: string, file: UploadFile) {
    this.fileUploadData.add(file.id, {
      candidate: this.args.candidate,
      job: this.args.jobApplication?.job,
    });
  }

  @action
  handleFileUploaded(
    temporaryFileUrl: string,
    fileFileName: string,
    file: UploadFile
  ) {
    const fileUploadData = this.fileUploadData.data[file.id];
    const { candidate, job } = fileUploadData ?? {};
    const upload = this.store.createRecord('upload', {
      temporaryFileUrl,
      fileFileName,
      candidate: candidate ?? this.args.candidate,
      job: job ?? this.args.jobApplication?.job,
      fileContentType: file.type,
    });
    this.fileUploadData.remove(file.id);

    return upload
      .save()
      .then(() => {
        this.flashMessages.success(
          this.intl.t(
            'candidates.candidate.candidate_modal.document_upload_success'
          )
        );
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      });
  }

  @action
  unreject() {
    if (!this.jobApplication) {
      return;
    }

    return this.jobApplication.unreject();
  }

  @action
  async quickReject() {
    if (!this.jobApplication) {
      return;
    }

    await this.jobApplication.reject({
      quickReject: true,
    });
  }

  @action
  leaveFeedback(interviewKit: InterviewKitModel) {
    const feedBackArgs = {
      interviewKitId: get(interviewKit, 'id'),
      jobId: get(this.job, 'id'),
    };

    this.router.transitionTo({
      queryParams: {
        tab: 'interview-feed',
        interviewFeedQuery: JSON.stringify(feedBackArgs),
      },
    });
  }
}
