/* import __COLOCATED_TEMPLATE__ from './offer-items.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';

export default class OfferItems extends Component {
  get hiringManager() {
    return get(this.args.jobOffer, 'recruiter');
  }

  get offerMessage() {
    return get(this.args.jobOffer, 'jobOfferForm.introText');
  }

  get offerItems() {
    return get(this.args.jobOffer, 'jobOfferForm.formQuestions')
      .filter(
        (item) =>
          !get(this.args.jobOffer, 'jobOfferForm.unmodifiableFields').includes(
            item.text
          )
      )
      .sortBy('sortIndex');
  }

  get salary() {
    return this.args.jobOffer.jobOfferForm.formQuestions
      .findBy('text', 'salary')
      ?.formAnswers.toArray()[0]?.formattedValue;
  }
}
