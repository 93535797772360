/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import elementOffset from 'teamtailor/utils/element-offset';

export default class QuickNavigationComponent extends Component {
  @service quickNavigation;

  element = null;
  labelElement = null;

  @action
  handleSelectedItem(selectedItem) {
    const container = this.element?.parentElement;

    if (!container?.clientHeight || !selectedItem?.clientHeight) {
      return;
    }

    const childOffsetFromTop =
      elementOffset(selectedItem).top - elementOffset(container).top;

    const itemHeight = selectedItem.clientHeight;
    const parentHeight = container.clientHeight;

    const isAboveParent = childOffsetFromTop < 0;
    const isBelowParent = childOffsetFromTop + itemHeight > parentHeight;

    if (isAboveParent) {
      container.scrollTop = container.scrollTop + childOffsetFromTop;
      return;
    }

    if (isBelowParent) {
      const labelHeight = this.labelElement
        ? this.labelElement.clientHeight
        : 0;

      const delta = parentHeight - (childOffsetFromTop + itemHeight);

      container.scrollTop = container.scrollTop - delta + labelHeight;
    }
  }

  @action
  setElement(element) {
    this.element = element;
  }

  @action
  setLabelElement(element) {
    this.labelElement = element;
  }
}
