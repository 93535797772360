/* import __COLOCATED_TEMPLATE__ from './team-members.hbs'; */
import { IUser } from 'teamtailor/components/meeting/types';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  MeetingEventClass,
  UserAttendeeClass,
} from 'teamtailor/classes/meetings';
import { enqueueTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import GET_USERS from 'teamtailor/gql/users-queries.graphql';
import { FetchUserResult } from '../event';

interface Args {
  event: MeetingEventClass;
  toggleOrganizerCallback: (attendee: UserAttendeeClass) => void;
  toggleUserWithCalendarCallback: () => void;
  candidateIds: string[];
}

type SearchQuery = {
  page: number;
  searchTerm: string | null;
};

export default class MeetingEventRowsTeamMembers extends Component<Args> {
  @service declare apollo: TeamtailorApolloService;

  get event(): MeetingEventClass {
    return this.args.event;
  }

  get userAttendees(): UserAttendeeClass[] {
    return this.event.meetingEventAttendees.userAttendees;
  }

  fetchUsersTask = enqueueTask(async (query: SearchQuery) => {
    if (!query.searchTerm) {
      return [];
    }

    const { usersConnection }: FetchUserResult = await this.apollo.query({
      query: GET_USERS,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          query: query.searchTerm,
          candidateIds: this.args.candidateIds,
        },

        first: 10,
      },
    });

    if (usersConnection.nodes.length) {
      const convertedUsers = usersConnection.nodes.map((user: IUser) => {
        return UserAttendeeClass.from(user);
      });
      return convertedUsers;
    }

    return [];
  });

  @action
  toggleUser(attendee: UserAttendeeClass): void {
    const attendees = this.event.meetingEventAttendees;
    attendees.toggle(attendee);

    const eventOrganizerExistInAttendeelist = this.userAttendees.some(
      (a) => a.user?.id === this.event.organizer?.user?.id
    );

    if (!eventOrganizerExistInAttendeelist) {
      const newOrganizer =
        attendees.userAttendeesWithCalendar[0] ?? attendees.userAttendees[0];
      if (newOrganizer) {
        this.args.toggleOrganizerCallback(newOrganizer);
      }
    }

    if (attendee.hasConnectedCalendar) {
      this.args.toggleUserWithCalendarCallback();
    }
  }
}
