/* import __COLOCATED_TEMPLATE__ from './detail-diff-value.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class RequisitionDetailDiffValueComponent extends Component {
  @service store;

  get readableValue() {
    const { type, value } = this.args;

    if (!value) {
      return '-';
    }

    const modelToFetch =
      type === 'department_id'
        ? 'department'
        : type === 'location_id'
          ? 'location'
          : type === 'role_id'
            ? 'role'
            : type === 'recruiter_id'
              ? 'user'
              : null;

    return modelToFetch ? this.store.findRecord(modelToFetch, value) : value;
  }

  get customField() {
    const { type, customForm } = this.args;

    return customForm.form.find((field) => field.uuid === type);
  }

  get promiseField() {
    switch (this.args.type) {
      case 'department_id':
      case 'role_id':
        return 'name';

      case 'location_id':
        return 'nameOrCity';

      case 'recruiter_id':
        return 'nameOrEmail';

      default:
        return null;
    }
  }
}
