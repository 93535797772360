import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ContentBlockGrid extends ApplicationSerializer {
  payloadKeyFromModelName() {
    return 'content_block_grid';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-grid': ContentBlockGrid;
  }
}
