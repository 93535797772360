/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NurtureCampaignsActionsComponent extends Component {
  @service intl;

  @action
  pauseNurtureCampaign(e) {
    e.stopPropagation();
    this.args.nurtureCampaign.pause();
  }

  @action
  archiveNurtureCampaign(e) {
    e.stopPropagation();
    this.args.nurtureCampaign.archive();
  }

  @action
  activateOrResumeNurtureCampaign(e) {
    e.stopPropagation();
    if (this.args.nurtureCampaign.isPaused) {
      this.args.nurtureCampaign.resume();
    } else {
      this.args.nurtureCampaign.activate();
    }
  }
}
