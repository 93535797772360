/* import __COLOCATED_TEMPLATE__ from './circular-progress.hbs'; */
import Component from '@glimmer/component';

interface CircularProgressArgs {
  estimatePercentage: number;
  estimateColor: 'green' | 'yellow' | 'red';
}

export default class CircularProgressComponent extends Component<CircularProgressArgs> {
  get circularProgressValue() {
    const svgLength = 251;
    const circularProgressValue =
      svgLength - (svgLength * this.args.estimatePercentage) / 100;

    return circularProgressValue;
  }

  get strokeColorClass() {
    switch (this.args.estimateColor) {
      case 'green':
        return 'stroke-[var(--color-background-decorative-green-medium)]';
      case 'yellow':
        return 'stroke-[var(--color-background-decorative-yellow-medium)]';
      case 'red':
        return 'stroke-[var(--color-background-decorative-red-medium)]';
      default:
        return 'stroke-[var(--color-background-decorative-zinc-medium)]';
    }
  }
}
