/* import __COLOCATED_TEMPLATE__ from './interview-feed-kits.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InterviewFeedKits extends Component {
  @service current;
  @tracked previewingInterviewKit;

  get job() {
    return get(this.args.jobApplication, 'job');
  }

  get pickedInterviewKits() {
    return get(this.job, 'jobDetail.pickedInterviewKits');
  }

  get availableInterviewKits() {
    return this.pickedInterviewKits?.filter((pickedInterviewKit) => {
      return (
        !pickedInterviewKit.isHidden ||
        this.args.findUserInterview(
          this.candidateInterviewsFor(pickedInterviewKit)
        )
      );
    });
  }

  get groupedAvailablePickedInterviewKits() {
    return this.availableInterviewKits
      ?.reduce((acc, pik) => {
        const stageId = get(pik, 'stage.id');
        const existing = acc.find((a) => get(a, 'stage.id') === stageId);
        if (existing) {
          existing.interviewKits.push(pik);
        } else {
          acc.push({
            stage: get(pik, 'stage'),
            interviewKits: [pik],
          });
        }

        return acc;
      }, [])
      .map((group) => {
        return {
          ...group,
          interviewKits: group.interviewKits.sortBy('interviewKit.name'),
        };
      })
      .sort((a, b) => {
        const rowOrderA = get(a, 'stage.rowOrder') || Number.MAX_SAFE_INTEGER;
        const rowOrderB = get(b, 'stage.rowOrder') || Number.MAX_SAFE_INTEGER;

        return rowOrderA - rowOrderB;
      });
  }

  get languageCode() {
    if (this.current.company.hasFeature('career_site_languages')) {
      return get(this.job, 'languageCode') || this.current.company.localeAts;
    } else {
      return null;
    }
  }

  candidateInterviewsFor(pickedInterviewKit) {
    if (this.args.candidate) {
      return get(this.args.candidate, 'interviews').filter((interview) => {
        return (
          get(interview, 'interviewKitId') ===
            get(pickedInterviewKit, 'interviewKit.id') &&
          get(interview, 'jobId') === get(this.job, 'id')
        );
      });
    } else {
      return [];
    }
  }

  @action
  showPreviewModal(pik) {
    this.previewingInterviewKit = pik;
  }

  @action
  hidePreviewModal() {
    this.previewingInterviewKit = null;
  }
}
