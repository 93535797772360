/* import __COLOCATED_TEMPLATE__ from './code.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface UnicornCodeArgs {
  code?: string;
}

export default class UnicornCode extends Component<UnicornCodeArgs> {
  @tracked inDarkMode = false;
  @tracked containerElement?: HTMLElement;

  @action
  renderContainer(el: HTMLElement) {
    this.containerElement = el;
  }

  @action
  toggleDarkMode() {
    const codeSection = this.containerElement;

    if (codeSection) {
      if (this.inDarkMode) {
        codeSection.classList.remove('theme-dark');
        codeSection.classList.add('theme-light');
      } else {
        codeSection.classList.remove('theme-light');
        codeSection.classList.add('theme-dark');
      }

      this.inDarkMode = !this.inDarkMode;
    }
  }
}
