/* import __COLOCATED_TEMPLATE__ from './nps-scores-by-date.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { primaryColor } from 'teamtailor/components/insights/charts/colors';

export default class extends Component {
  @service intl;

  get chartOptions() {
    return {
      chart: {
        type: 'bar',
        height: '300px',
      },

      xAxis: {
        lineWidth: 0,
        categories: this.args.data.mapBy('date'),
      },

      plotOptions: {
        bar: {
          minPointLength: 3,
        },
      },

      yAxis: {
        min: -100,
        max: 100,
        title: {
          enabled: false,
        },

        plotLines: [
          {
            zIndex: 9,
            color: '#78868E',
            width: 1,
            value: 0,
          },
        ],
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        pointFormat: `${this.intl.t(
          'models.report_nps_per_stage.score'
        )}: <b>{point.y}</b><br>${this.intl.t(
          'models.report_nps_per_stage.participants'
        )}: {point.responses}`,
      },
    };
  }

  get chartData() {
    return [
      {
        name: '',
        data: this.args.data.map(({ date, responses, value }) => ({
          name: date,
          responses,
          y: value,
          color: primaryColor,
        })),
      },
    ];
  }
}
