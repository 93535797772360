/* import __COLOCATED_TEMPLATE__ from './scorecard-result.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import uniqBy from 'teamtailor/utils/uniq-by';
import uniq from 'teamtailor/utils/uniq';

export default class ScorecardResultComponent extends Component {
  @service current;

  @tracked isExpanded = this.args.isExpanded;

  get scorecardPicks() {
    return get(this, 'args.job.jobDetail.scorecardPicks');
  }

  get scorecardScores() {
    return this.scorecardScoresForCandidate.filterBy('isNew', false);
  }

  get scorecardPicksWithScores() {
    return this.scorecardPicks.filter((scorecardPick) => {
      return this.scorecardScores.some((scorecardScore) => {
        return get(scorecardScore, 'scorecardPick.id') === scorecardPick.id;
      });
    });
  }

  get scorecardScoresForCandidate() {
    return get(this.args.candidate, 'scorecardScores').filter(
      (scorecardScore) => !scorecardScore.isNew
    );
  }

  get scoresWithoutUsers() {
    return this.scorecardScoresForCandidate.filter((score) => {
      return !score.belongsTo('user').id();
    });
  }

  get groupedScoresWithoutUsers() {
    const groups = [];
    this.scoresWithoutUsers.forEach((score) => {
      const value = score.criteriumId;
      let group = groups.find((g) => g.value === value);

      if (group) {
        group.items.push(score);
      } else {
        group = { value, items: [score] };
        groups.push(group);
      }
    });

    return groups;
  }

  get deletedUsersScore() {
    const { groupedScoresWithoutUsers } = this;
    const deletedUsersScore = [];

    if (groupedScoresWithoutUsers.length > 0) {
      const maxPerCriteria = groupedScoresWithoutUsers.reduce(
        (max, current) => {
          return Math.max(max, current.items.length);
        },
        0
      );

      for (let i = 0; i < maxPerCriteria; i++) {
        let scores = groupedScoresWithoutUsers.map((groupedScores) => {
          return groupedScores.items[i];
        });

        deletedUsersScore.push(EmberObject.create({ scores, tempId: i + 1 }));
      }
    }

    return deletedUsersScore;
  }

  get users() {
    const users = uniq(
      this.scorecardScoresForCandidate.mapBy('user.content')
    ).compact();

    if (users.includes(this.current.user)) {
      users.removeObject(this.current.user);
      users.pushObject(this.current.user);
    }

    const { deletedUsersScore } = this;
    if (deletedUsersScore.length) {
      users.pushObjects(deletedUsersScore);
    }

    return users;
  }

  get hasScored() {
    return this.users.mapBy('id').includes(this.current.user.id);
  }

  get comments() {
    return uniqBy(
      this.users
        .compact()
        .map((user) => {
          return get(this, 'args.candidate.scorecardComments').find(
            (comment) => {
              return (
                !comment.isNew &&
                !isEmpty(comment.comment) &&
                get(comment, 'user.id') === user.id &&
                get(comment, 'job.id') === get(this.args.job, 'id')
              );
            }
          );
        })
        .compact(),
      'id'
    );
  }

  @action
  addScore() {
    this.args.isScoring();
  }

  @action
  toggleExpanded() {
    if (!this.isExpanded) {
      this.isExpanded = true;
    }
  }
}
