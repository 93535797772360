/* import __COLOCATED_TEMPLATE__ from './filter-bar.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FilterBar extends Component {
  @tracked hasFiltersSelected;

  @action
  onVisible(element) {
    element.addEventListener('wheel', function (e) {
      if (e.deltaX === 0) {
        e.preventDefault();

        element.scrollLeft += e.deltaY;
      }
    });
  }
}
