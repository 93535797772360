/* import __COLOCATED_TEMPLATE__ from './missing-translations.hbs'; */
import { get, action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class CareerSitesMissingTranslationsComponent extends Component {
  @service current;
  @service intl;
  @service questionTranslations;

  @argDefault useQuestionTranslations = false;

  get careerSites() {
    return get(this.current.company, 'careerSites');
  }

  get translations() {
    return this.useQuestionTranslations
      ? this.questionTranslations.getQuestionTranslations(this.args.item)
      : this.args.item.translations;
  }

  get careerSitesLanguages() {
    return this.careerSites.mapBy('languageCode');
  }

  get translatedLanguages() {
    return this.translations
      .mapBy('languageCode')
      .without(this.defaultLanguageCode);
  }

  get defaultLanguageCode() {
    return this.current.company.defaultCareerSite?.languageCode;
  }

  get untranslatedLanguages() {
    return this.careerSitesLanguages.filter(
      (languageCode) =>
        !this.translatedLanguages.includes(languageCode) &&
        languageCode !== this.defaultLanguageCode
    );
  }

  @action
  fullLanguage(code) {
    return this.intl.t(`language_codes.${code}`);
  }
}
