/* import __COLOCATED_TEMPLATE__ from './meetings.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { MeetingStatsTypeResponse } from 'teamtailor/utils/insights/graphql-response-types';

type Args = {
  data?: MeetingStatsTypeResponse;
};

export default class MeetingsWidget extends Component<Args> {
  @tracked currentIndex = 0;

  dataTypes = ['meetingsPerInterval', 'durationPerInterval'];

  get dataType() {
    return this.dataTypes[this.currentIndex] as
      | 'meetingsPerInterval'
      | 'durationPerInterval';
  }

  get translationKey() {
    return this.dataType === 'meetingsPerInterval'
      ? 'candidate_meetings_frequency'
      : 'total_time_spent_in_meetings';
  }

  get dataValue() {
    const data = this.args.data?.[this.dataType];

    if (this.dataType === 'durationPerInterval') {
      return data?.map((interval) => {
        return {
          ...interval,
          count: interval.count / 3600, // seconds to hours
        };
      });
    } else {
      return data;
    }
  }
}
