/* import __COLOCATED_TEMPLATE__ from './reject.hbs'; */
import BaseComponent from './base';
import { action, get, setProperties } from '@ember/object';
import {
  CannedResponseModel,
  JobApplicationModel,
  RejectReasonModel,
} from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';

import rejectSettingsFormValid from 'teamtailor/utils/reject-settings-form-valid';

type BulkRejectOptions = {
  job_id: string;
  reject_reason_id: string | undefined;
  reject_template_id: string | undefined;
  reject_subject: string | null;
  reject_body: string | null;
  reject_delay_timestamp: string | null;
  reject_send_email: boolean;
};

export default class RejectComponent extends BaseComponent {
  @tracked rejectReason: RejectReasonModel | null = null;
  @tracked rejectSendEmail = true;
  @tracked rejectTemplate: CannedResponseModel | null = null;
  @tracked rejectSubject = null;
  @tracked rejectBody = null;
  @tracked rejectDelay = null;
  @tracked rejectDelayTimestamp = null;

  get rejectSettingsFormIsValid() {
    return rejectSettingsFormValid(
      'rejectReason',
      'rejectSendEmail',
      'rejectTemplate',
      'rejectSubject',
      'rejectBody'
    );
  }

  get selectedSourcedApplications() {
    return this.args.selectedJobApplications.filter(
      (ja: JobApplicationModel) => ja.sourcedAt
    );
  }

  get bulkRejectOptions(): BulkRejectOptions {
    return {
      job_id: get(this.args, 'job.id'),
      reject_reason_id: this.rejectReason?.id,
      reject_template_id: this.rejectTemplate?.id,
      reject_subject: this.rejectSubject,
      reject_body: this.rejectBody,
      reject_delay_timestamp: this.rejectDelayTimestamp,
      reject_send_email: this.rejectSendEmail,
    };
  }

  @action
  async rejectCandidates() {
    await this.postAction('bulk_reject', this.postData(this.bulkRejectOptions));
    const fromStages: string[] = [];
    this.args.selectedJobApplications.forEach(
      async (ja: JobApplicationModel) => {
        fromStages.push(ja.belongsTo('stage').id()!);
        await ja.reload();
        let stage = get(ja, 'stage');
        setProperties(ja, {
          rejectedAt: get(ja, 'rejectedAt') || new Date(),
          rejectedStage: stage,
        });
      }
    );

    const fromStagesCount = fromStages.reduce(
      (occurrences: { [key: string]: number }, item) => {
        occurrences[item] = (occurrences[item] || 0) + 1;
        return occurrences;
      },
      {}
    );

    Object.keys(fromStagesCount)
      .map((item) => {
        return { key: item, value: fromStagesCount[item] };
      })
      .forEach((stage) => {
        if (stage.key) {
          this.store
            .peekRecord('stage', stage.key)
            .changeCount(false, stage.value ? -stage.value : 0);
          this.store.findRecord('stage-job-application-count', stage.key);
        }
      });

    setProperties(this, {
      rejectReason: null,
      rejectTemplate: null,
      rejectSubject: null,
      rejectBody: null,
      rejectDelayTimestamp: null,
      rejectDelay: null,
      rejectSendEmail: false,
    });
    this.args.clearSelection();
    this.args.job.reload();
    this.args.onClose();
  }
}
