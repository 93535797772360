/* import __COLOCATED_TEMPLATE__ from './questions-rows.hbs'; */
import Component from '@glimmer/component';
import { compare, isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import AnswerModel from 'teamtailor/models/answer';
import JobModel from 'teamtailor/models/job';
import JobDetail from 'teamtailor/models/job-detail';
import JobApplicationModel from 'teamtailor/models/job-application';
import QuestionModel from 'teamtailor/models/question';
import PickedQuestionModel from 'teamtailor/models/picked-question';

type QuestionsRowsArgs = {
  jobApplications: JobApplicationModel[];
  pinnedCount: number;
  job: JobModel;
  hiddenCandidateIds: string[];
  hoveredJobApplication: JobApplicationModel;
  jobDetail: JobDetail;
};

type AnswerItem = {
  answer: AnswerModel | undefined;
  jobApplication: JobApplicationModel;
};

type QuestionItem = {
  question: QuestionModel;
  answers: AnswerItem[];
};

type Result = QuestionItem[];

const ALLOWED_QUESTION_TYPES = [
  'Question::Boolean',
  'Question::Choice',
  'Question::Date',
  'Question::Number',
  'Question::Text',
  'Question::Range',
];

export default class CompareCandidatesQuestionsRowsComponent extends Component<QuestionsRowsArgs> {
  @tracked questions?: QuestionModel[];

  constructor(owner: unknown, args: QuestionsRowsArgs) {
    super(owner, args);
    this.fetchQuestions.perform();
  }

  get computedResults(): Result {
    const questionsAnswers: Result = [];

    this.questions?.forEach((question) => {
      const questionItem: QuestionItem = { question, answers: [] };
      this.args.jobApplications.forEach((jobApplication) => {
        const answers = get(jobApplication.candidate, 'answers');
        const answer = answers.find((answer: AnswerModel) => {
          return (
            answer.jobId === jobApplication.jobIdAsString &&
            answer.questionId === get(question, 'id')
          );
        })!;
        questionItem.answers.push({ answer, jobApplication });
      });
      questionsAnswers.push(questionItem);
    });

    return questionsAnswers;
  }

  answerSort(question: QuestionModel, answers: AnswerItem[]) {
    const candidateAnswer = (
      jobApplication: JobApplicationModel
    ): AnswerModel | undefined => {
      const answerItem = answers.findBy(
        'jobApplication.id',
        get(jobApplication, 'id')
      );
      return answerItem?.answer;
    };

    const choiceValue = (answer: AnswerModel | undefined): string => {
      const choice = answer?.choices[0];
      return (
        get(question, 'alternativesArrayForChoices').findBy('id', choice)
          ?.title || ''
      );
    };

    return (a: JobApplicationModel, b: JobApplicationModel) => {
      const aAnswer = candidateAnswer(a);
      const bAnswer = candidateAnswer(b);
      let aValue = aAnswer?.answerValue;
      let bValue = bAnswer?.answerValue;

      if (get(question, 'isBoolean')) {
        aValue = isPresent(aAnswer?.boolean) ? Number(aAnswer?.boolean) : -1;
        bValue = isPresent(bAnswer?.boolean) ? Number(bAnswer?.boolean) : -1;
      }

      if (get(question, 'isSingleChoice')) {
        aValue = choiceValue(aAnswer);
        bValue = choiceValue(bAnswer);
      }

      if (get(question, 'isMultipleChoice')) {
        return compare(
          (bValue as number[] | undefined)?.length,
          (aValue as number[] | undefined)?.length
        );
      } else if (get(question, 'isText') || get(question, 'isSingleChoice')) {
        return aValue
          ? bValue
            ? (aValue as string).localeCompare(bValue as string)
            : -1
          : 1;
      } else {
        return compare(bValue, aValue);
      }
    };
  }

  fetchQuestions = task(async () => {
    const questions: QuestionModel[] = [];
    const { jobDetail } = this.args;

    const pickedQuestions = (
      get(
        jobDetail,
        'pickedQuestions' as keyof JobDetail
      ) as PickedQuestionModel[]
    ).sortBy('position');

    for (const pickedQuestion of pickedQuestions) {
      // eslint-disable-next-line no-await-in-loop
      const question = await get(pickedQuestion, 'question');

      const type = get(question, 'type');

      if (type && question && ALLOWED_QUESTION_TYPES.includes(type)) {
        questions.push(question);
      }
    }

    this.questions = Object.values(questions);
  });
}
