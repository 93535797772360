/* import __COLOCATED_TEMPLATE__ from './partner-image.hbs'; */
import Component from '@glimmer/component';
import { PartnerModel } from 'teamtailor/models';
import {
  DEFAULT_SIZE,
  SIZE_CLASSES,
  ALLOWED_SIZES,
  AllowedSizes,
} from 'teamtailor/constants/avatar';
import { argDefault } from 'teamtailor/utils/arg-default';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { get } from 'teamtailor/utils/get';

interface PartnerImageArgs {
  partner: PartnerModel;
  size?: AllowedSizes;
  tooltipSide?: string;
}

export default class PartnerImage extends Component<PartnerImageArgs> {
  @argDefault tooltipSide = 'top';

  get sizeClasses() {
    return SIZE_CLASSES[this.size];
  }

  get size(): AllowedSizes {
    verifyArg(
      this.args.size,
      ALLOWED_SIZES,
      'PartnerImage @size',
      DEFAULT_SIZE
    );

    return this.args.size ?? DEFAULT_SIZE;
  }

  get title(): string {
    return get(this.args.partner, 'name');
  }

  get imageUrl() {
    return get(this.args.partner, 'logotypeSquare');
  }
}
