/* import __COLOCATED_TEMPLATE__ from './move-rejected-candidates.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ModalsRemoveRejectedCandidatesComponent extends Component {
  @tracked selectedStage = null;

  get stage() {
    return this.args.stage;
  }

  get job() {
    return this.stage.job;
  }

  get selectableStages() {
    return this.job.stages.filter((stage) => {
      return !stage.hired && stage.id !== this.stage.id;
    });
  }

  @action
  async handleMoveCandidates() {
    return this.stage
      .quickMoveRejectedCandidates({
        move_to_stage_id: this.selectedStage.id,
      })
      .finally(() => {
        this.stage.jobApplications.clear();
        this.stage.rejectedJobApplications.clear();
        this.stage._rejectedJobApplicationsCount = null;
        this?.args?.onComplete();
      });
  }

  @action
  handleClose() {
    this.selectedStage = null;
    this.args.onClose?.();
  }
}
