/* import __COLOCATED_TEMPLATE__ from './job-card.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';

const JOB_CARD_QUERY = gql`
  query JobCardQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    applied: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
    ) {
      aggregated(groupBy: [DATE]) {
        date
        count
      }
    }
    visits: pageviewQuery(dateRange: $dateRange, jobIds: $jobIds) {
      aggregated(groupBy: [DATE]) {
        date
        unique: distinctCount(field: SESSION_ID)
      }
    }
  }
`;

export default class InsightsWidgetsJobCard extends Component {
  @service insights;

  fetchData = dropTask(async () => {
    const { dateRange, job } = this.args;

    const result = await this.insights.query({
      query: JOB_CARD_QUERY,
      variables: {
        jobIds: [job.id],
        dateRange: dateRange.asObject,
      },

      context: { jobId: job.id },
    });

    if (!result) return { visitsData: [], appliedData: [], dateRange: [] };

    const {
      applied: { aggregated: applied },
      visits: { aggregated: visits },
    } = result;

    const visitsData = dateRange.dateSpan.map(
      (row) => visits.find((a) => moment(a.date).isSame(row))?.unique || 0
    );
    const appliedData = dateRange.dateSpan.map(
      (row) => applied.find((a) => moment(a.date).isSame(row))?.count || 0
    );

    return {
      visitsData,
      appliedData,
      dateRange: dateRange.dateSpan.map((date) => date.format('DD MMM')),
    };
  });
}
