/* import __COLOCATED_TEMPLATE__ from './job-list.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'teamtailor/config/environment';
import DepartmentModel from 'teamtailor/models/department';
import LocationModel from 'teamtailor/models/location';
import Current from 'teamtailor/services/current';
import CareerSiteModel from 'teamtailor/models/career-site';
import IntlService from 'ember-intl/services/intl';

type Args = {
  [key: string]: unknown;
};

export default class WidgetSnippetsJobList extends Component {
  @service declare store: Store;
  @service declare current: Current;
  @service declare intl: IntlService;

  @tracked declare jobListWidgetFeed: string;
  @tracked declare jobListWidgetLimit: number;
  @tracked declare jobListWidgetDepartment?: DepartmentModel;
  @tracked declare jobListWidgetLocation?: LocationModel;
  @tracked declare jobListWidgetCareerSite?: CareerSiteModel;
  @tracked declare apiKey?: string;

  @tracked groupJobsWidget = false;
  @tracked jobListWidgetPagination = false;
  @tracked jobListWidgetPopup = false;
  @tracked jobListWidgetDepartmentSelect = false;
  @tracked jobListWidgetRoleSelect = false;
  @tracked jobListWidgetRegionSelect = false;
  @tracked jobListWidgetLocationSelect = false;
  @tracked jobListWidgetCareerSiteSelect = false;
  @tracked jobListWidgetRemoteStatusSelect = false;
  @tracked jobListWidgetGroupCompanySelect = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.jobListWidgetFeed = 'public';
    this.jobListWidgetLimit = 20;
  }

  get sortedCareerSites() {
    const { company } = this.current;
    const careerSites = [
      {
        id: null,
        translatedLanguage: this.intl.t('search.language.all_languages'),
      },
      company.defaultCareerSite,
      ...company.careerSites.sortBy('translatedLanguage').rejectBy('isDefault'),
    ];

    return careerSites;
  }

  get sortedLocations() {
    const locations = get(this.current.company, 'locations').slice();
    return locations.length
      ? [
          {
            id: null,
            nameOrCity: this.intl.t('search.location.all_locations'),
          },
          ...locations,
        ]
      : [];
  }

  get sortedDepartments() {
    const departments = get(
      this.current.company,
      'departmentsWithoutNoDepartment'
    ).slice();

    return departments.length
      ? [
          {
            id: null,
            name: this.intl.t('search.department.all_departments'),
          },
          ...departments,
        ]
      : [];
  }

  get scriptTag(): string {
    return `<script src="${config.scripts_host}/jobs.js" async charset="utf-8"></script>`;
  }

  get divTag(): string {
    const html = [];

    html.push(
      `<div class="teamtailor-jobs-widget" data-teamtailor-limit="${this.jobListWidgetLimit}"`
    );

    if (this.jobListWidgetPagination) {
      html.push('data-teamtailor-pagination="true"');
    }

    if (this.jobListWidgetPopup) {
      html.push('data-teamtailor-popup="true"');
    }

    if (this.jobListWidgetDepartment?.id) {
      html.push(
        `data-teamtailor-department="${this.jobListWidgetDepartment.id}"`
      );
    }

    if (this.jobListWidgetLocation?.id) {
      html.push(`data-teamtailor-location="${this.jobListWidgetLocation.id}"`);
    }

    if (this.jobListWidgetCareerSite?.id) {
      html.push(
        `data-teamtailor-language="${this.jobListWidgetCareerSite.languageCode}"`
      );
    }

    if (this.jobListWidgetDepartmentSelect && !this.jobListWidgetDepartment) {
      html.push('data-teamtailor-department-select="true"');
    }

    if (this.jobListWidgetRoleSelect) {
      html.push('data-teamtailor-role-select="true"');
    }

    if (this.jobListWidgetRegionSelect) {
      html.push('data-teamtailor-region-select="true"');
    }

    if (this.jobListWidgetLocationSelect && !this.jobListWidgetDepartment) {
      html.push('data-teamtailor-location-select="true"');
    }

    if (this.jobListWidgetCareerSiteSelect) {
      html.push('data-teamtailor-language-select="true"');
    }

    if (this.jobListWidgetGroupCompanySelect) {
      html.push('data-teamtailor-group-company-select="true"');
    }

    if (this.jobListWidgetRemoteStatusSelect) {
      html.push('data-teamtailor-remote-status-select="true"');
    }

    if (this.jobListWidgetFeedOption) {
      html.push(`data-teamtailor-feed="${this.jobListWidgetFeedOption}"`);
    }

    html.push(`data-teamtailor-api-key="${this.apiKey}"></div>`);

    return html.join(' ');
  }

  get jobListWidgetFeedOption(): string | undefined {
    return this.jobListWidgetFeed === 'public'
      ? undefined
      : this.jobListWidgetFeed;
  }

  get groupOptions() {
    return [
      {
        text: this.intl.t(
          'settings.integrations.widget.this_company_description',
          { companyName: this.current.company.name }
        ),

        value: false,
      },
      {
        text: this.intl.t(
          'settings.integrations.widget.group_companies_description'
        ),

        value: true,
      },
    ];
  }

  get feedOptions() {
    return [
      {
        text: this.intl.t(
          'settings.integrations.widget.jobs_visible_on_careers_site'
        ),

        value: 'public',
      },
      {
        text: this.intl.t('settings.integrations.widget.internal_jobs'),
        value: 'internal',
      },
      {
        text: this.intl.t(
          'settings.integrations.widget.combination_of_public_and_internal'
        ),

        value: 'internal,public',
      },
    ];
  }

  @action
  async generateJobListWidgetCode(): Promise<void> {
    const apiKey = await this.store.queryRecord('api-key', {
      widget: this.jobListWidgetFeed,
      group_key: this.groupJobsWidget,
    });
    const { key } = apiKey;
    this.apiKey = key;
  }

  @action
  changedSettings(): void {
    this.apiKey = undefined;
  }

  @action
  clearDepartmentAndLocation(): void {
    this.jobListWidgetDepartment = undefined;
    this.jobListWidgetLocation = undefined;
    this.jobListWidgetCareerSite = undefined;
  }

  @action
  selectDepartment(department: DepartmentModel | undefined): void {
    this.jobListWidgetDepartment = department;
    if (department) {
      this.jobListWidgetDepartmentSelect = false;
    }
  }

  @action
  selectLocation(location: LocationModel | undefined): void {
    this.jobListWidgetLocation = location;
    if (location) {
      this.jobListWidgetRegionSelect = false;
      this.jobListWidgetLocationSelect = false;
    }
  }

  @action
  selectLanguage(careerSite: CareerSiteModel): void {
    this.jobListWidgetCareerSite = careerSite;
  }

  @action
  setGroupOption({ value }: { value: boolean }) {
    this.groupJobsWidget = value;

    if (value) {
      this.clearDepartmentAndLocation();
    }

    this.changedSettings();
  }

  @action
  setJobFeed({ value }: { value: string }) {
    this.jobListWidgetFeed = value;

    this.changedSettings();
  }
}
