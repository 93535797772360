/* import __COLOCATED_TEMPLATE__ from './section-layout-option.hbs'; */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import classnames from 'teamtailor/utils/classnames';

export default Component.extend({
  border: false,
  description: null,
  dynamicSize: false,
  groupValue: null,
  image: null,
  tagName: '',
  value: null,
  extraClasses: null,

  radioButtonClasses: computed(
    'border',
    'dynamicSize',
    'extraClasses',
    function () {
      return classnames(
        'c-section-layout-option',
        'tt-dark-theme',
        get(this, 'extraClasses'),
        {
          'c-section-layout-option--with-border': get(this, 'border'),
          'c-section-layout-option--dynamic-size': get(this, 'dynamicSize'),
        }
      );
    }
  ),
});
