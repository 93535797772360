/* import __COLOCATED_TEMPLATE__ from './calendar.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';

import TeamtailorApolloService from 'teamtailor/services/apollo';

import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import MeetingEventModel from 'teamtailor/models/meeting-event';
import TimeFormatService from 'teamtailor/services/time-format';
import Current from 'teamtailor/services/current';

type DateElements = {
  date: Date;
  element: HTMLElement;
};

type EventListByDate = {
  date: Date;
  events: MeetingEventModel[];
};

export default class CalendarWidgetComponent extends WidgetClass {
  @service declare apollo: TeamtailorApolloService;
  @service declare router: RouterService;
  @service declare current: Current;
  @service declare store: Store;
  @service declare timeFormat: TimeFormatService;

  @tracked eventList: MeetingEventModel[] = [];
  @tracked initialLoad = true;
  @tracked declare selectedDate: Date | undefined;
  @tracked dateElements: DateElements[] = [];
  @tracked eventListByDate: EventListByDate[] = [];
  @tracked currentEvent?: MeetingEventModel;
  @tracked flatpickerRef?: any;
  @tracked prevMonthDisabled = true;
  @tracked declare flatpickerDate: Date;

  constructor(owner: unknown, args: unknown) {
    super(owner, args);
    this.fetchEvents();
  }

  get today(): Date {
    return moment().startOf('day').toDate();
  }

  get fromDate(): string {
    if (this.selectedDate) {
      return moment(this.selectedDate).format('YYYY-MM-DD');
    }

    return moment().format('YYYY-MM-DD');
  }

  get toDate(): string | undefined {
    if (this.selectedDate) {
      return moment(this.selectedDate).add(1, 'day').format('YYYY-MM-DD');
    }
  }

  get daysWithEvents(): string[] {
    return this.eventList.map((event: MeetingEventModel) =>
      moment(event.bookedSlotStartsAt).format('L')
    );
  }

  findCurrentEvent(): MeetingEventModel | undefined {
    const earliestEvent = this.eventList.toArray().sort((a, b) => {
      if (moment(a.bookedSlotEndsAt).isBefore(moment(b.bookedSlotEndsAt))) {
        return -1;
      }

      if (moment(b.bookedSlotEndsAt).isBefore(moment(a.bookedSlotEndsAt))) {
        return 1;
      }

      return 0;
    }).firstObject;
    if (moment(earliestEvent?.bookedSlotStartsAt).isSame(moment(), 'day')) {
      return earliestEvent;
    }
  }

  get currentEventOngoing(): boolean | undefined {
    return (
      this.currentEvent &&
      moment(this.currentEvent.bookedSlotStartsAt).isBefore(moment())
    );
  }

  async fetchEvents(): Promise<void> {
    this.eventListByDate = [];
    this.eventList = await this.store.query('meeting-event', {
      upcoming_and_ongoing: true,
      filter: { user_ids: this.current.user.id },
    });
    this.currentEvent = this.findCurrentEvent();
    this.eventList.forEach((m) => {
      if (m !== this.currentEvent) {
        const d = moment(m.bookedSlotStartsAt);
        const exists = this.eventListByDate.find((l) =>
          moment(l.date).isSame(d, 'day')
        );
        if (exists) {
          exists.events.push(m);
        } else {
          this.eventListByDate.push({
            date: d.startOf('day').toDate(),
            events: [m],
          });
        }
      }
    });
    this.initialLoad = false;
  }

  get userLocaleData(): moment.Locale {
    return moment.localeData(this.current.company.localeAts);
  }

  get locale() {
    return {
      ...this.timeFormat.flatPickrLocaleData,
      ordinal: this.userLocaleData.ordinal,
      weekdays: {
        shorthand: this.userLocaleData.weekdaysMin().map((d) => d[0]),
        longhand: this.timeFormat.flatPickrLocaleData.weekdays.longhand,
      },
    };
  }

  @action
  openMeetingEvent(meetingId: string, eventId: string, e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.transitionTo('meetings.events/show', meetingId, eventId).data =
      {
        returnUrl: this.router.currentURL,
      };
  }

  @action
  selectDate(value: Date | [Date]): void {
    this.selectedDate = Array.isArray(value) ? value[0] : value;
    const dateElement = this.dateElements.find((d) =>
      moment(d.date).isSame(moment(this.selectedDate), 'day')
    );
    if (dateElement) {
      dateElement.element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }

  @action
  registerElement(date: string, element: HTMLElement): void {
    const dateStartOfDay = moment(date).startOf('day').toDate();
    const exists = this.dateElements.find((d) => d.date === dateStartOfDay);
    if (!exists) {
      this.dateElements.push({ date: dateStartOfDay, element });
    }
  }

  @action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  flatpickerOnReady(_date: any, _str: any, instance: any): void {
    this.flatpickerRef = instance;
  }

  @action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDayCreate(_dObj: any, _dStr: any, _fp: any, dayElem: HTMLElement) {
    if (this.daysWithEvents.length > 0) {
      const day = moment(dayElem.getAttribute('aria-label'))
        .format('L')
        .toString();
      if (this.daysWithEvents.includes(day)) {
        const dots = Math.min(
          this.daysWithEvents.filter((d) => d === day).length,
          3
        );
        dayElem.innerHTML += `<span class='event'>${'.'.repeat(dots)}</span>`;
      }
    }

    dayElem.innerHTML = `<span class="innerBox">${dayElem.innerHTML}</span>`;
  }

  @action
  onMonthChange(): void {
    this.prevMonthDisabled =
      this.flatpickerRef?.currentMonth === this.today.getMonth();
    this.flatpickerDate = new Date();
    this.flatpickerDate.setFullYear(
      this.flatpickerRef?.currentYear,
      this.flatpickerRef?.currentMonth
    );
  }

  @action
  nextMonth(): void {
    this.flatpickerRef?.changeMonth(1);
  }

  @action
  prevMonth(): void {
    this.flatpickerRef?.changeMonth(-1);
  }
}
