/* import __COLOCATED_TEMPLATE__ from './preloaded-model.hbs'; */
import Component from '@glimmer/component';

type PreloadedModelCellData = {
  type: string;
  value: string;
};

type PreloadedModelCellArgs = {
  data: PreloadedModelCellData;
};

export default class DataTablePreloadedModelComponent extends Component<PreloadedModelCellArgs> {
  get value() {
    const { value } = this.args.data;
    if (parseInt(value).toString() !== value) {
      return value;
    }

    return undefined;
  }

  get type() {
    return this.args.data.type;
  }
}
