/* import __COLOCATED_TEMPLATE__ from './jobs.hbs'; */
import WidgetClass from './base/widget-class';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

const JOBS_LIMIT = 10;

export default class JobsWidget extends WidgetClass {
  @service store;
  @service pusher;
  @service current;

  @tracked jobs;
  @tracked channel;

  get hasJobs() {
    return isPresent(this.jobs);
  }

  get totalJobs() {
    return this.jobs?.meta?.total_count;
  }

  get hasMoreJobs() {
    return this.totalJobs > JOBS_LIMIT;
  }

  fetchJobs = restartableTask(async () => {
    this.jobs = await this.store.query('job', {
      status: 'open',
      recruiter_or_team_member: true,
      sort: 'last_job_application_at-desc',
      per_page: JOBS_LIMIT,
    });
  });

  @action
  setupPusher() {
    this.pusher
      .subscribe(this.current.company.candidatesChannel)
      .then((channel) => {
        this.channel = channel;
        channel.bind('demo-data-updated', () => {
          this.fetchJobs.perform();
        });
      });
  }

  @action
  teardownPusher() {
    if (this.channel) {
      this.channel.unbind('demo-data-updated');
    }
  }
}
