/* import __COLOCATED_TEMPLATE__ from './template-form.hbs'; */
import FormBaseComponent from './form-base';
import { action, get } from '@ember/object';

export default class TemplateForm extends FormBaseComponent {
  get messageFields() {
    return this.fields.filter((field) =>
      get(this.args.form, 'messageFields').includes(field.label)
    );
  }

  get messagesExcludedFields() {
    return this.fields.filter(
      (field) => !get(this.args.form, 'messageFields').includes(field.label)
    );
  }

  @action
  onUpdateFields(fields) {
    this.fields = [...this.messageFields, ...fields];
    this.args.onUpdateFields(this.fields);
  }
}
