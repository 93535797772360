/* import __COLOCATED_TEMPLATE__ from './message-templates.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { ICannedResponse } from './types';
import GET_CANNED_RESPONSES from 'teamtailor/gql/canned-responses.graphql';
import PreparedMessageClass from 'teamtailor/classes/prepared-message';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import fuzzysort from 'fuzzysort';
import { isEmpty } from '@ember/utils';
import templateBodyToRedactorHTML from 'teamtailor/utils/template-body-to-redactor-html';

interface IArgs {
  preparedMessage: PreparedMessageClass;
  selectedCannedResponse?: ICannedResponse;
  languageCode: string;
  onChange?: (args: ICannedResponse) => void;
}

export default class MessageTemplateComponent extends Component<IArgs> {
  @service declare apollo: TeamtailorApolloService;
  @tracked declare cannedResponses: ICannedResponse[];
  @tracked searchTerm = '';

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this.fetchData();
  }

  get preparedMessage(): PreparedMessageClass {
    return this.args.preparedMessage;
  }

  @action
  async onLanguageCodeChange(): Promise<void> {
    await this.fetchData();
    const currentSelectedCannedResponse =
      this.args.selectedCannedResponse || this.preparedMessage;
    const newSelectedCannedResponse = this.filteredOptions.findBy(
      'id',
      currentSelectedCannedResponse.id
    );
    if (newSelectedCannedResponse) {
      this.selectMessageTemplate(newSelectedCannedResponse);
    }
  }

  @action
  selectMessageTemplate(cannedResponse: ICannedResponse): void {
    this.preparedMessage.body = templateBodyToRedactorHTML(cannedResponse.body);
    this.preparedMessage.subject = cannedResponse.subject;
    this.preparedMessage.questions = cannedResponse.questions;
    const uploads = cannedResponse.uploads.map((u) => {
      return {
        fileKey: u.file.path,
        fileFileName: u.fileFileName,
        fileFileSize: u.fileFileSize,
        fileContentType: u.fileContentType,
        fromTemplate: true,
      };
    });
    this.preparedMessage.uploads = uploads;

    if (this.args.onChange) {
      this.args.onChange(cannedResponse);
    }
  }

  async fetchData(): Promise<void> {
    const { cannedResponses }: { cannedResponses: ICannedResponse[] } =
      await this.apollo.query({
        query: GET_CANNED_RESPONSES,
        variables: {
          locale: this.args.languageCode || null,
          filter: {
            showDefaultReply: false,
            showReject: false,
            showWelcomeToConnect: false,
            showExternalRecruiterInvite: false,
            showConsentExpiredReminder: false,
            showConsentExtendReminder: false,
            showConsentMissingReminder: false,
          },
        },
      });

    this.cannedResponses = this.applyTagsForSearch(cannedResponses);
  }

  applyTagsForSearch(cannedResponses: ICannedResponse[]) {
    return cannedResponses.map((cr) => {
      return { ...cr, nameWithTags: `${cr.name} (${cr.tags.mapBy('name')})` };
    });
  }

  get filteredOptions() {
    if (isEmpty(this.searchTerm)) {
      return this.cannedResponses;
    } else {
      return fuzzysort
        .go(this.searchTerm, this.cannedResponses, {
          key: 'nameWithTags',
          allowTypo: false,
          threshold: -10000,
        })
        .map((r) => r.obj);
    }
  }

  @action
  handleSearch(event: InputEvent) {
    const { value } = event.target as HTMLInputElement;
    this.searchTerm = value;
  }
}
