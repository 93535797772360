/* import __COLOCATED_TEMPLATE__ from './daily-progress.hbs'; */
import Component from '@glimmer/component';

export default class extends Component {
  get isDone() {
    return !this.noTodos && this.completedCount === this.args.total;
  }

  get onlyOne() {
    return this.args.total - this.completedCount === 1;
  }

  get halfWay() {
    return this.completedCount === this.args.total / 2;
  }

  get completedCount() {
    return this.args.total - this.args.due;
  }
}
