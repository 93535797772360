/* import __COLOCATED_TEMPLATE__ from './aboard-lite.hbs'; */
import { trackedFunction } from 'ember-resources/util/function';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import AboardService from 'teamtailor/services/aboard-service';
import Current from 'teamtailor/services/current';
import ENV from 'teamtailor/config/environment';

interface Args {
  onboardingId: string;
  close: () => void;
}

export default class AboardLiteWidgetComponent extends Component<Args> {
  @service declare aboardService: AboardService;
  @service declare current: Current;

  aboardLiteUrl = trackedFunction(this, async (): Promise<string> => {
    const { onboardingId } = this.args;
    const token = await this.aboardService.token();

    const url = `${ENV.httpOrHttps}://app.${ENV.ABOARD_HOST}/companies/${this.current.company.aboardId}/teamtailor/onboardings/${onboardingId}?token=${token}&theme=${this.current.user.appearance}`;

    return url;
  });

  @action
  close() {
    this.args.close();
  }
}
