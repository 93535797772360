/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isArray } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import {
  DEFAULT_SCALE_MAX_VALUE,
  OTHER_OPTION,
  SCALE_LABEL_TYPES,
  SCALE_OPTIONS,
} from 'teamtailor/utils/form-builder';

export default class FormFieldEditable extends Component {
  OTHER_OPTION = OTHER_OPTION;
  SCALE_OPTIONS = SCALE_OPTIONS;
  SCALE_LABEL_TYPES = SCALE_LABEL_TYPES;

  lastAddedIndex = null;

  @tracked answer = this.args.answer;

  get answerAsArray() {
    return isArray(this.answer) ? this.answer : [this.answer];
  }

  @tracked scaleValue =
    this.SCALE_OPTIONS.find((s) => s.value === this.args.field.maximum_value) ||
    this.SCALE_OPTIONS.firstObject;

  get radioOptionHasOther() {
    const { options } = this.args.field;
    return options.includes(OTHER_OPTION);
  }

  get showAddOptionButton() {
    const { options, type } = this.args.field;
    const isSingleCheckbox = type === 'checkbox' && !options?.length;
    return !isSingleCheckbox;
  }

  get fieldHasOtherOptionSelected() {
    return this.args.field.options.some((option) => option.id === '__OTHER__');
  }

  get showAddOtherButton() {
    const { includeOtherOption, field } = this.args;
    return (
      field.type === 'radio' &&
      includeOtherOption &&
      !this.radioOptionHasOther &&
      !this.fieldHasOtherOptionSelected
    );
  }

  get labelNumber() {
    return this.scaleValue.value || DEFAULT_SCALE_MAX_VALUE;
  }

  @action
  addOption(value) {
    this.args.onAddOption(value);
    const { options } = this.args.field;
    this.lastAddedIndex = options ? options.length - 1 : null;
  }

  @action
  onUpdateOption(optionIndex, value) {
    this.args.onUpdateOption(optionIndex, value);
  }

  @action
  focusInput(index, el) {
    if (index === this.lastAddedIndex) {
      el.focus();
    }
  }

  @action
  toggleCheckboxChoice(choiceId) {
    if (this.args.field.type === 'radio') {
      this.updateRadioAnswer(choiceId);
    } else {
      this.updateMultipleChoiceAnswer(choiceId);
    }
  }

  @action
  handleScaleChange(value) {
    this.scaleValue = value;
    this.args.onScaleChange(value);
  }

  updateRadioAnswer(choiceId) {
    this.answer = [choiceId];
    this.args.onUpdateAnswer(choiceId);
  }

  updateMultipleChoiceAnswer(choiceId) {
    let arr = this.answer;
    if (!isArray(arr)) {
      arr = [];
    }

    if (arr.indexOf(choiceId) === -1) {
      arr.pushObject(choiceId);
    } else {
      arr.removeObject(choiceId);
    }

    this.answer = arr;
    this.args.onUpdateAnswer(this.answer);
  }
}
