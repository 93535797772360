/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import Component from '@glimmer/component';
import { action, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { trackedFunction } from 'ember-resources/util/function';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { v1 as uuid } from 'ember-uuid';
import {
  ActivityModel,
  CandidateModel,
  GlobalCollaborateMessageModel,
  JobModel,
  NoteModel,
  UploadModel,
  UserModel,
  JobApplicationModel,
} from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import { FileUploadStatus, FileUpload } from 'teamtailor/types/file-upload';
import { dropTask } from 'ember-concurrency';
import { relationsRecordRemover } from 'teamtailor/utils/record-remover';
import { modifier } from 'ember-modifier';
import uniqBy from 'teamtailor/utils/uniq-by';
import FlipperService from 'teamtailor/services/flipper';

export interface NoteFormSignature {
  Element: HTMLFormElement;
  Args: {
    candidate?: CandidateModel;
    job?: JobModel;
    jobApplication?: JobApplicationModel;
    note?: NoteModel;
    finishedSaving?: (
      a: NoteModel | ActivityModel | GlobalCollaborateMessageModel
    ) => void;
    saveDisabled?: boolean;
    selectedJob?: JobModel;
    channel: string | undefined;
  };
}

export default class NoteForm extends Component<NoteFormSignature> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare flipper: FlipperService;

  @tracked value = '';
  @tracked isPrivate = false;
  @tracked fileUpload: UploadModel | FileUpload | null = null;
  @tracked _selectedUsers: UserModel[] = [];
  @tracked mentionContainer: HTMLElement | null = null;
  @tracked useJobContext;

  noteUploadId: string | null = null;
  declare mentionTrigger: (element: HTMLElement) => void;
  declare mentionTextarea: HTMLTextAreaElement;
  declare fileQueueName: string;

  constructor(owner: unknown, args: NoteFormSignature['Args']) {
    super(owner, args);
    this.setInitialValues();
    this.fileQueueName = uuid();
    this.useJobContext = !!get(args.job, 'id');
  }

  resolvedMentionableUsers = trackedFunction(this, async () => {
    const candidate = await get(this.args, 'candidate');
    const job = await get(this.args, 'job');

    const users = candidate
      ? await candidate.getUsersWithAccessThroughJob(job)
      : await Promise.all(get(job, 'usersWhoCanBeMentioned')!);

    return users.without(this.current.user);
  });

  get mentionableUsers() {
    return this.resolvedMentionableUsers.value || [];
  }

  get mentionedUsers() {
    const mentions: string[] = this.value.match(/@[\w-]+/gi) || [];
    return this.mentionableUsers.filter((u) =>
      mentions.includes(`@${get(u, 'username')}`)
    );
  }

  get adminUsers() {
    return this.mentionableUsers.filterBy('admin');
  }

  get selectedUsers() {
    return uniqBy(
      [...this.mentionedUsers, ...this._selectedUsers, ...this.adminUsers],
      'id'
    );
  }

  get isValid() {
    return !!this.value && !this.args.saveDisabled;
  }

  get mentionJob() {
    return this.useJobContext ? get(this.args.job, 'id') : null;
  }

  setInitialValues() {
    const { note } = this.args;

    if (note) {
      this.value = note.note;
      this.isPrivate = note.privateNote;
      this.noteUploadId = note.belongsTo('upload').id();

      this.resolvePrivateNoteUsers(note);

      if (this.noteUploadId) {
        this.resolveUpload(note);
      }
    }
  }

  reset() {
    setProperties(this, {
      value: '',
      isPrivate: false,
      fileUpload: null,
      _selectedUsers: [],
      noteUploadId: null,
    });
  }

  getUploadRecord(candidate?: CandidateModel, job?: JobModel) {
    if (!this.fileUpload || this.noteUploadId) {
      return this.fileUpload;
    }

    const { temporaryUrl: temporaryFileUrl, fileName: fileFileName } = this
      .fileUpload as FileUpload;

    return this.store.createRecord('upload', {
      temporaryFileUrl,
      fileFileName,
      candidate,
      job,
    });
  }

  async resolveUpload(note: NoteModel) {
    this.fileUpload = await get(note, 'upload');
  }

  async resolvePrivateNoteUsers(note: NoteModel) {
    const privateNoteUsers = (await get(note, 'privateNoteUsers')).toArray();
    this._selectedUsers.pushObjects(privateNoteUsers);
  }

  async handleGlobalCommentsMessageSubmit(channel: string) {
    const params = {
      channel,
      message: this.value,
    };

    const model = this.store.createRecord('global-collaborate-message', params);

    await model.save();

    if (this.args.finishedSaving) {
      this.args.finishedSaving(model);
    }
  }

  handleSubmit = dropTask(async () => {
    const candidate = await get(this.args, 'candidate');
    const job = this.useJobContext
      ? await get(this.args, 'selectedJob')
      : undefined;
    const note = await get(this.args, 'note');
    const upload = this.getUploadRecord(candidate, job);
    const channel = get(this.args, 'channel');
    const jobApplication = this.useJobContext
      ? await get(this.args, 'jobApplication')
      : undefined;

    if (get(this.flipper, 'global_comments') && channel !== undefined) {
      this.handleGlobalCommentsMessageSubmit(channel);
    } else {
      const params = {
        code: 'note',
        actionData: { note: this.value },
        updatedAt: new Date(),
        privateNote: this.isPrivate,
        privateNoteUsers: this.isPrivate ? this.selectedUsers : [],
        upload,
        candidate,
        job,
        jobApplication,
      };

      if (note) {
        setProperties(note, params as never);
      }

      const model = note || this.store.createRecord('activity', params);

      model.save().then(() => {
        if (!upload && this.noteUploadId) {
          this.store.peekRecord('upload', this.noteUploadId)?.unloadRecord();
        }

        relationsRecordRemover(candidate, 'uploads');

        if (this.args.finishedSaving) {
          this.args.finishedSaving(model);
        }
      });
    }

    this.reset();
  });

  @action
  handleCmdEnter() {
    if (this.isValid) {
      this.handleSubmit.perform();
    }
  }

  @action
  handleRegisterMentionTrigger(mentionTrigger: (element: HTMLElement) => void) {
    this.mentionTrigger = mentionTrigger;
  }

  handleMentionsTextareaInsert = modifier(
    (mentionTextarea: HTMLTextAreaElement) => {
      this.mentionTextarea = mentionTextarea;
      this.mentionContainer = document.getElementById('candidate-modal');
    }
  );

  @action
  handleMentionPickerClick() {
    this.mentionTextarea.focus();

    next(() => {
      this.mentionTrigger(this.mentionTextarea);
    });
  }

  @action
  handlePermissionUserClicked(user: UserModel) {
    toggleInList(this._selectedUsers, user);
    this.isPrivate = !!this._selectedUsers.length;
  }

  @action
  makePublic() {
    this.isPrivate = false;
    this._selectedUsers = [];
  }

  @action
  makePrivate() {
    this.isPrivate = true;
  }

  @action
  removeFile() {
    this.fileUpload = null;
  }

  @action
  handleFileUploadStarted(fileName: string) {
    this.fileUpload = {
      fileName,
      status: FileUploadStatus.UPLOADING,
    };
  }

  @action
  handleFileUploaded(temporaryUrl: string) {
    this.fileUpload = {
      ...(this.fileUpload as FileUpload),
      status: FileUploadStatus.DONE,
      temporaryUrl,
    };
  }

  @action
  handleValueChange({ target }: { target: HTMLTextAreaElement }) {
    this.value = target.value;
  }
}
