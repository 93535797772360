/* import __COLOCATED_TEMPLATE__ from './copilot-button.hbs'; */
import StatusButtonComponent from 'teamtailor/components/status-button';
import { action } from '@ember/object';
import { later, cancel } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

export default class CopilotButtonComponent extends StatusButtonComponent {
  @service intl;
  @tracked startTime;
  @tracked currentTime;

  get text() {
    const diff = moment(this.currentTime).diff(this.startTime, 'seconds');

    if (diff > 10 && this.inFlight) {
      return this.intl.t('recruiter_copilot.still_loading');
    }

    return this.args.text;
  }

  @action
  stop() {
    this.actionTask.cancelAll();
    if (this.args.onStop) {
      this.args.onStop();
    }
  }

  @action
  async handleClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.startTimer();

    await this.actionTask.perform();

    this.stopTimer();
  }

  startTimer() {
    this.startTime = new Date();
    this.currentTime = new Date();
    this.updateTimeTask = later(this, this.updateCurrentTime, 1000);
  }

  stopTimer() {
    cancel(this.updateTimeTask);
  }

  updateCurrentTime() {
    this.currentTime = new Date();
    this.updateTimeTask = later(this, this.updateCurrentTime, 1000);
  }

  willDestroy() {
    super.willDestroy();
    this.stopTimer();
    this.stop();
  }
}
