/* import __COLOCATED_TEMPLATE__ from './section-cta-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { setProperties, set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

const ctaTypes = [
  'Job',
  'Connect',
  'Department',
  'People',
  'Blog',
  'Page',
  'Custom',
];

export default class SectionCtaForm extends Component {
  @service current;
  @service intl;
  @service store;
  @service filthyContent;

  @tracked hasLoadedPagePublications = false;

  get showTypeSelection() {
    return !this.section.ctaType;
  }

  get section() {
    return this.args.model;
  }

  get types() {
    return ctaTypes;
  }

  get company() {
    return this.current.company;
  }

  get jobs() {
    return this.company.jobs;
  }

  get departments() {
    return this.company.departmentsWithoutNoDepartment;
  }

  get pages() {
    return this.company.pages;
  }

  get isHeroForm() {
    return this.section.name === 'hero';
  }

  get buttonPlaceholder() {
    switch (this.section.ctaType) {
      case 'Job':
        return this.intl.t('components.section_cta_form.apply_now');
      case 'Connect':
        return this.intl.t('components.section_cta_form.connect_with_us');
      case 'Department':
      case 'People':
        return this.intl.t('components.section_cta_form.get_to_know_us');
      case 'Blog':
        return this.intl.t('components.section_cta_form.read_our_blog');
      case 'Custom':
      case 'Page':
        return this.intl.t('components.section_cta_form.visit_page');
      default:
        return '';
    }
  }

  get _publishedPages() {
    return this.pages.filterBy('isPublished');
  }

  get _pageOptions() {
    return this._publishedPages.map((page) => {
      return { title: page.displayTitle, ctaPageId: page.id };
    });
  }

  get filteredPages() {
    return this._pageOptions.filter((page) => {
      return page.ctaPageId !== get(this, 'model.page.id');
    });
  }

  get selectedPage() {
    const pages = this.filteredPages;
    const { ctaPageId } = this.section;
    return pages.find((page) => Number(page.ctaPageId) === ctaPageId);
  }

  makeDirty() {
    if (
      !this.section.isDirty &&
      !this.filthyContent.isFilthy(this.section.id)
    ) {
      this.filthyContent.setFilthy(this.section.id);
    }
  }

  @action
  async didInsertForm() {
    if (this.section.ctaType === 'Page') {
      await this.store.findAll('page-publication', { reload: true });
      set(this, 'hasLoadedPagePublications', true);
    }
  }

  @action
  pickCtaType(type) {
    this.section.ctaType = type;
    this.didInsertForm();
  }

  @action
  handlePickDepartment(department) {
    const departmentId = department ? department.id : null;
    this.section.pickedDepartmentId = departmentId;
  }

  @action
  pickPage(page) {
    this.section.ctaPageId = Number(page.ctaPageId);
  }

  @action
  pickJob(job) {
    setProperties(this.section, {
      job,
      jobPageType: 'Job page',
    });
    this.makeDirty();
  }

  @action
  changeJobPageType() {
    this.section.toggleProperty('linkToApplication');
  }

  @action
  changeWindowTargetBlank() {
    this.section.toggleProperty('ctaWindowTargetBlank');
  }
}
