/* import __COLOCATED_TEMPLATE__ from './empty-result.hbs'; */
import Component from '@glimmer/component';

const emojis = {
  all: '🐳',
  archived: '🚀',
  search: '👀',
  unanswered: '👏',
  unread: '🎉',
  todos: '🧘',
  meetings: '🥝',
};
export default class extends Component {
  get copyKey() {
    const translationPath = `components.toolbox.${this.args.toolbox}.empty_state.`;
    const key = this.args.hasSearched ? 'search' : this.args.filter;
    return translationPath + key;
  }

  get emoji() {
    if (this.args.hasSearched) {
      return emojis.search;
    }

    return emojis[this.args.filter];
  }
}
