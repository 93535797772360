/* import __COLOCATED_TEMPLATE__ from './email.hbs'; */
import NurtureCampaignStepBase from './base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import NurtureCampaignStepEmailValidations from 'teamtailor/validations/nurture-campaign-step/email';
import { dropTask } from 'ember-concurrency';
import GET_CANNED_RESPONSES from 'teamtailor/gql/canned-responses.graphql';

export default class NurtureCampaignStepComponent extends NurtureCampaignStepBase {
  @service apollo;
  @service intl;

  validator = NurtureCampaignStepEmailValidations;

  @tracked toolbarContainer = null;
  @tracked cannedResponses = [];

  fetchCannedResponses = dropTask(async () => {
    const { cannedResponses } = await this.apollo.query({
      query: GET_CANNED_RESPONSES,
    });

    this.cannedResponses = this.applyTagsForSearch(cannedResponses);
  });

  applyTagsForSearch(cannedResponses) {
    return cannedResponses.map((cr) => {
      return { ...cr, nameWithTags: `${cr.name} (${cr.tags.mapBy('name')})` };
    });
  }

  setToolbarButtonTabIndex(buttons) {
    Array.from(buttons).forEach((button) => (button.tabIndex = 0));
  }

  @action
  registerToolbarContainer(_, e) {
    this.toolbarContainer = e;
  }

  @action
  selectMessageTemplate(cannedResponse) {
    this.changeset.body = cannedResponse.body;
    this.changeset.subject = cannedResponse.subject;
  }

  @action
  getRedactorInstance(redactor) {
    const { nodes } = redactor.toolbar.$toolbar;
    this.setToolbarButtonTabIndex(nodes[0].children);
  }
}
