/* import __COLOCATED_TEMPLATE__ from './trigger-webhook-form.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @service intl;
  @service pusher;
  @service current;
  @service store;
  @service flipper;
  @service router;

  @tracked proceedSettingsEnabled = false;
  @tracked partnerConfigError;
  @tracked loadingConfig = false;
  @tracked configSuccess = false;
  @tracked webhookConfigChunks = [];
  webhookData = {};

  @tracked mappings = [];

  get user() {
    return this.current.user;
  }

  get stage() {
    return this.args.model.stage;
  }

  get job() {
    return this.args.model.job;
  }

  get model() {
    return this.args.model;
  }

  get stageOptions() {
    const currentStageId = get(this.stage, 'id');
    const options = get(this.job, 'stages').map((stage) => {
      const stageId = get(stage, 'id');
      const isDisabled = stageId ? stageId === currentStageId : true;
      return {
        id: stageId,
        name: get(stage, 'name'),
        isDisabled,
        stage,
      };
    });
    return options;
  }

  get selectedModels() {
    return get(this.args.model, 'mappedPartnerCriteria')
      .toArray()
      .rejectBy('isDeleted');
  }

  get scorecardCheckboxChecked() {
    return this.selectedModels.length > 0;
  }

  set scorecardCheckboxChecked(value) {
    if (value && !this.selectedModels.length) {
      this.addModel();
    } else if (!value && this.selectedModels.length) {
      this.selectedModels.forEach((model) => {
        this.deleteMapping(model);
      });
    }
  }

  get canAddMapping() {
    const scorecardPicks = this.selectedModels
      .mapBy('scorecardPick.id')
      .compact();

    return (
      this.args.model.mappedPartnerCriteria.length <
        this.availableAssessmentCriteria.length &&
      scorecardPicks.length === this.selectedModels.length &&
      scorecardPicks.length < this.availableScorecardPicks.length
    );
  }

  get availableAssessmentCriteria() {
    return get(this.args.model, 'webhookPartnerCriteria');
  }

  get canAddCriteria() {
    return (
      !this.hasUnpersistedScorecardPicks &&
      this.availableAssessmentCriteria &&
      this.availableAssessmentCriteria.length > 0
    );
  }

  get hasUnpersistedScorecardPicks() {
    return this.availableScorecardPicks.isAny('isNew');
  }

  get availableScorecardPicks() {
    return get(this.args.model, 'job.jobDetail.scorecardPicks');
  }

  get headerText() {
    if (get(this, 'args.model.onReject')) {
      return this.intl.t('mixins.trigger_form.header_text_on_reject', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    } else {
      return this.intl.t('mixins.trigger_form.header_text_on_move', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    }
  }

  get rangeValue() {
    return this.model &&
      this.model.proceedValue &&
      !isNaN(this.model.proceedValue)
      ? parseInt(this.model.proceedValue, 10)
      : 0;
  }

  partnerConfigFieldsTask = task(async (key) => {
    this.loadingConfig = true;
    const webhook_data_params = this.prepareWebhoookDataParams(key);
    try {
      const partnerActivation = await get(this, 'args.model.partnerActivation');

      const { config } = await partnerActivation.getConfig({
        job_id: get(this, 'args.model.job.id'),
        stage_id: get(this, 'args.model.stage.id'),
        webhook_data: JSON.stringify(webhook_data_params),
      });

      if (config?.fields) {
        this.setConfig(config);
      }

      return null;
    } catch (e) {
      this.loadingConfig = false;
      this.configSuccess = false;
      this.args.setConfigValid(false);
      this.partnerConfigError = e;
      return e;
    }
  });

  @action
  handleSetProceedStage({ stage }) {
    set(this.args.model, 'proceedStage', stage);
  }

  @action
  async navigateToSetUpSkillsAndTraits(event) {
    event.preventDefault();
    await this.args.onCloseModal();
    this.router.transitionTo('jobs.edit.evaluation.index', this.args.model.job);
  }

  get filteredWebhookData() {
    if (!this.args.model.webhookData) return {};
    return Object.entries(this.args.model.webhookData).reduce(
      (acc, [key, value]) => {
        if (![null, undefined, '', false, 'false'].includes(value)) {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );
  }

  @action
  updateWebhookData(config = []) {
    return config.reduce((acc, field) => {
      const exists = Object.keys(this.filteredWebhookData).includes(field?.id);
      if (exists) {
        return { ...acc, [field.id]: this.filteredWebhookData[field.id] };
      }

      return acc;
    }, {});
  }

  @action
  prepareWebhoookDataParams(key = null) {
    const config = this.args.model.webhookConfig;

    if (!Array.isArray(config)) return this.filteredWebhookData;
    const fields = config.reduce((acc, field) => {
      if (field.parentFieldId !== key) {
        return { ...acc, [field.id]: this.filteredWebhookData[field.id] };
      }

      return acc;
    }, {});

    return fields;
  }

  @action
  async onInit() {
    await this.setupPusher();
    await this.partnerConfigFieldsTask.perform();
    this.proceedSettingsEnabled =
      !isEmpty(get(this, 'args.model.proceedStage.id')) ||
      !isEmpty(get(this, 'args.model.proceedValue'));
    this.args.setMoveEnabled(this.proceedSettingsEnabled);
    this.proceedConditionTypeOptions = ['above', 'below', 'equal'];
  }

  @action
  handleChange() {
    this.proceedSettingsEnabled = !this.proceedSettingsEnabled;
    this.args.setMoveEnabled(this.proceedSettingsEnabled);
  }

  @action
  async maybeRefetchConfig(key, value) {
    const { webhookConfig } = this.args.model;

    const refetchableField = webhookConfig.find(
      (field) => field.refetch && field.id === key
    );

    if (refetchableField && this.webhookData[key] !== value) {
      await this.partnerConfigFieldsTask.perform(key);

      this.webhookData[key] = value;
    }
  }

  @action
  onValueChanged(key, value) {
    this.args.model.webhookData[key] = value;
    this.maybeRefetchConfig(key, value);
    let formValid = this.args.model.webhookConfig.isEvery('isValid');
    this.args.setConfigValid(formValid);
  }

  @action
  setProceedCondition(condition) {
    this.args.model.proceedConditionType = condition;
  }

  @action
  async getUserChannel() {
    const channel = await this.pusher.channel(
      get(this, 'user.notificationsChannel')
    );
    if (!channel.subscribed) {
      return this.pusher.subscribe(get(this, 'user.notificationsChannel'));
    }

    return channel;
  }

  @action
  async setupPusher() {
    try {
      const channel = await this.getUserChannel();

      channel.bind('partner-request-config-chunk', (data) => {
        const { chunk, final } = data;

        this.webhookConfigChunks.push(chunk);

        if (final) {
          try {
            const parsedConfig = JSON.parse(this.webhookConfigChunks.join(''));

            const fields = parsedConfig ? parsedConfig?.config?.fields : [];

            const assessmentCriteria =
              parsedConfig?.config?.assessment_criteria || [];

            this.setConfig({ fields, assessmentCriteria });
          } catch {
            this.partnerConfigError = this.intl.t(
              'components.partner.webhooks_modal.config_fetch_failed'
            );
            this.configSuccess = false;
            this.loadingConfig = false;
          }
        }
      });

      channel.bind('partner-request-config-fail', (e) => {
        this.configSuccess = false;
        this.loadingConfig = false;
        this.args.setConfigValid(false);

        this.partnerConfigError = e;
      });
    } catch {
      return;
    }
  }

  @action setConfig(config) {
    setProperties(this.args.model, {
      webhookConfig: config.fields,
      webhookPartnerCriteria: config.assessmentCriteria?.map((criteria) => {
        return {
          id: criteria.id,
          label: criteria.label,
        };
      }),

      webhookData: this.updateWebhookData(config.fields),
    });
    this.configSuccess = true;
    this.loadingConfig = false;
    this.webhookConfigChunks = [];
    this.args.setConfigValid(true);
  }

  @action
  async teardownPusher() {
    const channel = await this.pusher.channel(
      get(this, 'user.notificationsChannel')
    );

    if (channel) {
      channel.unbind('partner-request-config-chunk');
      channel.unbind('partner-request-config-fail');
    }
  }

  @action
  addModel() {
    this.args.model.mappedPartnerCriteria.createRecord();
  }

  @action
  deleteMapping(model) {
    this.store.deleteRecord(model);
  }
}
