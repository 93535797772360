import RESTAdapter from '@ember-data/adapter/rest';
import { isEmpty } from '@ember/utils';
import config from 'teamtailor/config/environment';
import classic from 'ember-classic-decorator';

interface ReponseHeaders {
  'x-total': string;
  'x-per-page': string;
}

interface ResponsePayload {
  results?: unknown[];
}

interface RequestData {
  query: string;
}

@classic
export default class UnsplashPhotoAdapter extends RESTAdapter {
  host = 'https://api.unsplash.com';

  urlForQuery(params: { query: string }) {
    if (!isEmpty(params.query)) {
      return `${this.host}/search/photos`;
    } else {
      return `${this.host}/photos`;
    }
  }

  handleResponse(
    status: number,
    headers: ReponseHeaders,
    payload: ResponsePayload,
    requestData: RequestData
  ) {
    const count = Number(headers['x-total']);
    const perPage = Number(headers['x-per-page']);

    return super.handleResponse(
      status,
      headers,
      {
        unsplash_photos: payload.results ? payload.results : payload,
        per_page: perPage,
        meta: {
          count,
          total_pages: Math.ceil(count / perPage),
        },
      },
      requestData
    );
  }

  async download(url: string) {
    return (
      this.ajax(url, 'GET') as Promise<{ unsplash_photos: { url: string } }>
    ).then((response) => {
      return response.unsplash_photos.url;
    });
  }

  headers = {
    Authorization: `Client-ID ${config.unsplash.application_id}`,
  };
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'unsplash-photo': UnsplashPhotoAdapter;
  }
}
