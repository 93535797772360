/* import __COLOCATED_TEMPLATE__ from './candidate-avatar.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { later } from '@ember/runloop';
import { dropTask } from 'ember-concurrency';
import imageUrl from 'teamtailor/utils/image-url';
import { argDefault } from 'teamtailor/utils/arg-default';
import { tracked } from '@glimmer/tracking';
import { computePosition } from '@floating-ui/dom';

export default class CandidateAvatarComponent extends Component {
  @service anonymals;
  @service imageAverageColor;
  @service stageListHelper;

  @argDefault size = 'default';

  @argDefault hasUnreadBorder = false;

  get candidateId() {
    return get(this.args, 'model.candidateId') || get(this.args, 'model.id'); // Is candidateId in inbox where model is a conversation
  }

  async createAnimationInstance(element) {
    if (this.animation || !this.showAnonymal) {
      return;
    }

    const [revealAnonymousAvatarJson, lottie] = await Promise.all([
      fetch('/assets/lottie-animations/reveal-anonymous-avatar.json').then(
        (response) => response.json()
      ),
      import('lottie-web'),
    ]);

    this.animation = lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      animationData: revealAnonymousAvatarJson.animationData,

      loop: false,
      autoplay: false,
    });
  }

  doAvatarAnimation = dropTask(
    async (lottieWrapperPopperRef, avatarAnimationQueueItem, popperEl) => {
      if (!this.showAnonymal) {
        return;
      }

      const {
        data: { fromAnonymous, toAnonymous },
      } = avatarAnimationQueueItem;

      this.element.classList.toggle('show-anonymous');

      if (fromAnonymous === true && toAnonymous === false) {
        const lottieWrapper = popperEl.children[0];

        await this.createAnimationInstance(lottieWrapper);

        const { x, y } = await computePosition(
          lottieWrapperPopperRef,
          popperEl,
          {
            placement: 'bottom',
          }
        );

        this.popperStyle = {
          left: `${x}px`,
          top: `${y}px`,
        };

        lottieWrapper.classList.add(
          'c-candidate-avatar__lottieWrapper--playing'
        );

        this.animation.play();

        this.animation.onComplete = () => {
          this.animation.stop(); // reset animation
          lottieWrapper.classList.remove(
            'c-candidate-avatar__lottieWrapper--playing'
          );
        };
      }

      later(() => {
        this.element.classList.add('c-candidate-avatar--animate');
        this.element.classList.toggle('show-anonymous');

        later(() => {
          this.element.classList.remove('c-candidate-avatar--animate');
        }, 500);
      }, 10);
    }
  );

  @action
  handleDidInsert(el) {
    this.element = el;
  }

  @tracked popperEl;

  @action
  didInsertUpdatePopperEl(element) {
    this.popperEl = element;
  }

  @action
  didUpdateLottieWrapper(element, [avatarAnimationQueueItem, popperEl]) {
    if (
      this.showAnonymal &&
      avatarAnimationQueueItem &&
      popperEl &&
      element.isConnected
    ) {
      this.doAvatarAnimation.perform(
        element,
        avatarAnimationQueueItem,
        popperEl
      );
      this.stageListHelper.animationQueue.removeObject(
        avatarAnimationQueueItem
      );
    }
  }

  get avatarAnimationQueueItem() {
    return this.stageListHelper.getMatchingAnimationQueueItem({
      type: 'avatar',
      data: {
        stageId: get(this.args, 'jobApplication.stage.id'),
        jobApplicationId: get(this.args, 'jobApplication.id'),
        targetIndex: this.args.collectionIndex,
      },
    });
  }

  get sizeClass() {
    return `c-avatar-image--${this.size}`;
  }

  get splashColor() {
    const averageColor = this.imageAverageColor.urlToColorMap[this.imageUrl];
    const splashColor = averageColor
      ? averageColor.hex
      : get(this, 'args.model.color');

    return splashColor;
  }

  @tracked
  popperStyle = {};

  get style() {
    return {
      '--splashColor': this.splashColor,
      ...this.popperStyle,
    };
  }

  get bgColorStyle() {
    return htmlSafe(`background-color: ${this.anonymalColor}`);
  }

  get type() {
    if (get(this.args, 'model.picture')) {
      return 'picture';
    } else {
      return 'initials';
    }
  }

  get anonymalColor() {
    return get(this.args, 'model.anonymal.colorHex');
  }

  get anonymalIconUrl() {
    return get(this.args, 'model.anonymal.iconUrl');
  }

  @action
  didInsertUpdateAvatarImage(element, [imageUrl, anonymous]) {
    if (imageUrl && anonymous && element.isConnected) {
      this.imageAverageColor.getAverageColor.perform(imageUrl);
    }
  }

  get imageUrl() {
    return imageUrl(get(this.args, 'model.picture'), 'employee_picture');
  }

  get showAnonymal() {
    let jobApplicationId = get(this.args, 'jobApplication.id');
    let wasMoved =
      jobApplicationId &&
      jobApplicationId === this.stageListHelper.lastMovedJobApplicationId;

    return this.candidateId && (this.args.anonymous || wasMoved);
  }
}
