/* import __COLOCATED_TEMPLATE__ from './activity-item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import toggleReaction from 'teamtailor/utils/toggle-reaction';
import { get } from 'teamtailor/utils/get';

const EMOJIS = ['👍', '❤️', '🎉', '😁', '✅'];

export default class EmployeeDashboardActivityItemComponent extends Component {
  @service current;

  static CODE_ACTIVITY_MAP = {
    referred: 'employee-dashboard/activity-item/referred',
    referral_hired: 'employee-dashboard/activity-item/referral-hired',
  };

  get activity() {
    return this.args.activity;
  }

  get reactions() {
    return get(this.activity, 'reactions');
  }

  get contentComponentName() {
    return EmployeeDashboardActivityItemComponent.CODE_ACTIVITY_MAP[
      this.activity.code
    ];
  }

  get emojis() {
    return EMOJIS;
  }

  @action
  handleReaction(emoji) {
    toggleReaction(this.activity, this.current.user, emoji);
  }
}
