/* import __COLOCATED_TEMPLATE__ from './select-scroll-wrapper.hbs'; */
import ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  timeout,
  enqueueTask,
  restartableTask,
  TaskForAsyncTaskFunction,
  Task,
} from 'ember-concurrency';

const SCROLL_OFFSET = 200;

type FetchPageReturn = ArrayProxy<unknown> & {
  meta?: { total_pages: number; page: string };
};

type SelectScrollWrapperComponentSignature = {
  Args: {
    presetValues?: unknown[];
    fetchPageTask: TaskForAsyncTaskFunction<
      Record<string, unknown>,
      (arg: { page: number; searchTerm: string }) => Promise<FetchPageReturn>
    >;
  };
};

export type SelectScrollWrapperCtx = {
  page: number;
  totalPages: number | null;
  searchTerm: string | null;
  options: unknown[];
  searchTask: Task<void, unknown[]>;
  fetchPageTask: Task<void, unknown[]>;
  handleDropdownContainerScrollDebounced: (event: Event) => void;
  runningSearchTaskPromise?: Promise<void>;
};

export default class SelectScrollWrapperComponent extends Component<SelectScrollWrapperComponentSignature> {
  page = 1;
  totalPages: number | null = null;
  @tracked searchTerm = null;
  @tracked options = [] as unknown[];

  searchTask = restartableTask(async (searchTerm) => {
    const { promise, resolve } = Promise.withResolvers<void>();

    this.runningSearchTaskPromise = promise;

    searchTerm = typeof searchTerm === 'string' ? searchTerm : null;
    if (searchTerm !== this.searchTerm) {
      this.page = 1;
      this.searchTerm = searchTerm;
      if (searchTerm) await timeout(500);
    }

    return this.fetchPageTask.perform(searchTerm).then(() => {
      resolve();
      this.runningSearchTaskPromise = undefined;
    });
  });

  @tracked runningSearchTaskPromise?: Promise<void>;

  fetchPageTask = enqueueTask(async (searchTerm) => {
    searchTerm = typeof searchTerm === 'string' ? searchTerm : null;

    if (this.args.presetValues && !searchTerm) {
      this.options = this.args.presetValues;
      return this.options;
    }

    const result = await this.args.fetchPageTask.perform({
      page: this.page,
      searchTerm,
    });

    this.totalPages = result.meta?.total_pages || 1;
    const page = result.meta?.page || 1;

    if (page === 1) {
      this.options = result.toArray();
    } else {
      this.options.addObjects(result.toArray());
    }

    return this.options;
  });

  handleDropdownContainerScrollDebounced = (event: Event) =>
    debounce(this, 'handleDropdownContainerScroll', event, 150);

  @action
  handleDropdownContainerScroll(event: Event) {
    const listEl = event.target as HTMLElement;

    const elHeight = listEl.scrollHeight - listEl.clientHeight;
    const { scrollTop } = listEl;

    if (scrollTop > elHeight - SCROLL_OFFSET) {
      if (this.page >= (this.totalPages || 0)) return;
      this.page = this.page + 1;

      this.fetchPageTask.perform(this.searchTerm);
    }
  }
}
