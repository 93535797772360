/* import __COLOCATED_TEMPLATE__ from './jobs.hbs'; */
import BulkActionsBase from './base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import CurrentService from 'teamtailor/services/current';

type BulkData = {
  [key: string]: unknown;
};
export default class BulkActionsJobsComponent extends BulkActionsBase {
  @service declare store: Store;
  @service declare current: CurrentService;

  get modals(): string[] {
    const modals = [
      'audience',
      'move',
      'locations',
      'new-hire-share-page-status',
      'recruiter',
      'remote-status',
      'status',
    ];

    return modals;
  }

  get jobBulkOptions() {
    const { deselectedIds, selectAll, selectedIds, searchParams } = this.args;
    if (selectAll) {
      return {
        query: searchParams,
        deselected_ids: deselectedIds,
      };
    } else {
      return {
        selected_ids: selectedIds,
      };
    }
  }

  @action
  handleBulkChange(bulkData: BulkData, action: string): void {
    const promise = this.postAction(
      action,
      { ...this.jobBulkOptions, ...bulkData },
      'jobs'
    );

    this.batchJob.batchJobStartedTask
      .perform(promise, this.args.selectedCount)
      .then(() => {
        const reloadStages = action === 'bulk_new_hire_share_page_status';
        this.args.onAfterBulkChange(reloadStages);
      });
  }

  @action
  closeModal(): void {
    this.currentModal = null;
  }
}
