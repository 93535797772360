/* import __COLOCATED_TEMPLATE__ from './intro-message.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DashboardIntroMessageComponent extends Component {
  @service current;
  @service router;

  get user() {
    return this.current.user;
  }

  @action
  handleSettingsClick() {
    this.router.transitionTo('employee.dashboard-widget.intro-message');
  }
}
