/* import __COLOCATED_TEMPLATE__ from './password-restricted-option.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { v4 as uuid } from 'ember-uuid';

type Args = {
  onRestrictedChange: () => void;
  onPasswordChange: (password: string) => void;
  setPassword: (password: string) => void;
  restricted: boolean;
  password: string;
  errors?: { message: string };
};

export default class PasswordRestrictedOption extends Component<Args> {
  get restricted() {
    return this.args.restricted;
  }

  @action
  toggleOption() {
    this.args.onRestrictedChange();

    if (this.restricted && !this.args.password) {
      const password = uuid().replace('-', '').substring(0, 12);
      this.args.setPassword(password);
    }
  }
}
