/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

interface CoreToggleSwitchComponentSignature {
  Args: {
    onChange?: (event: unknown) => void;
    value: unknown;
    isDisabled?: boolean;
  };
}

export default class CoreToggleSwitchComponent extends Component<CoreToggleSwitchComponentSignature> {
  get groupName() {
    return guidFor(this);
  }

  @action
  onChange(value: unknown) {
    this.args.onChange?.(value);
  }
}
