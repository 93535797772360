/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { JobModel } from 'teamtailor/models';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import StageDrag from 'teamtailor/services/stage-drag';
import AboardService from 'teamtailor/services/aboard-service';

interface Args {
  job: JobModel;
  showRejected: boolean;
}

export default class StageAboard extends Component<Args> {
  @service declare dragSort: any;
  @service declare stageDrag: StageDrag;
  @service private declare aboardService: AboardService;

  virtualCollection: HTMLElement | null = null;
  @tracked stageDropListEl = null;

  get filteredJobApplications() {
    return get(this.stage, 'jobApplications')
      .filter((jobApplication) => jobApplication.aboardOnboardingId)
      .filter((jobApplication) => {
        return this.args.showRejected
          ? jobApplication.rejectedAt
          : jobApplication;
      });
  }

  get stage() {
    return get(this.args.job, 'hiredStage');
  }

  get isCompetenceBased() {
    return get(get(this.args.job, 'jobDetail'), 'competenceBased');
  }

  @action
  handleRegisterVerticalCollection(virtualCollection: any) {
    this.virtualCollection = virtualCollection;
    this.stageDropListEl = virtualCollection.element;
  }
}
