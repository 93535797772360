/* import __COLOCATED_TEMPLATE__ from './visibility.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { MeetingEventClass } from 'teamtailor/classes/meetings';
import { IMeetingEventVisibility } from 'teamtailor/components/meeting/types';
import { action } from '@ember/object';

type Value = {
  id: IMeetingEventVisibility;
  label: string;
};

interface Args {
  event: MeetingEventClass;
}

export default class VisibilityComponent extends Component<Args> {
  @service declare intl: IntlService;

  get options(): [Value, Value, Value] {
    return [
      {
        id: 'default',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.default'
        ),
      },
      {
        id: 'no_candidate_names_in_invite',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.no_candidate_names_in_invite'
        ),
      },
      {
        id: 'private',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.private'
        ),
      },
    ];
  }

  @action
  onSelect(value: Value) {
    this.args.event.visibility = value.id;
  }
}
