/* import __COLOCATED_TEMPLATE__ from './visitors.hbs'; */
import Component from '@glimmer/component';
import { primaryColor } from 'teamtailor/components/insights/charts/colors';

export default class InsightsWidgetsVisitors extends Component {
  graphColor = primaryColor;

  get startDate() {
    return this.args.dates.at(0);
  }

  get endDate() {
    return this.args.dates.at(-1);
  }
}
