/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import { easeOut, easeIn } from 'ember-animated/easings/cosine';

export default class extends Component {
  *transition({ insertedSprites, removedSprites }) {
    for (let sprite of removedSprites) {
      sprite.applyStyles({ 'z-index': '8' });
      sprite.endAtPixel({
        x: sprite.absoluteInitialBounds.x,
        y: sprite.absoluteInitialBounds.y + window.outerHeight,
      });
      yield move(sprite, { easing: easeIn });
    }

    for (let sprite of insertedSprites) {
      sprite.applyStyles({ 'z-index': '10' });
      sprite.startAtPixel({
        x: sprite.absoluteFinalBounds.x,
        y: sprite.absoluteFinalBounds.y + window.outerHeight,
      });
      yield move(sprite, { easing: easeOut });
    }
  }
}
