/* import __COLOCATED_TEMPLATE__ from './hire-quality-response.hbs'; */
import Component from '@glimmer/component';

export default class HireQualityResponseComponent extends Component {
  get activity() {
    return this.args.activity;
  }

  get status() {
    return this.activity?.actionData?.status;
  }

  get isCreated() {
    return this.status === 'created';
  }

  get isSent() {
    return this.status === 'sent';
  }

  get isAnswered() {
    return this.status === 'answered';
  }

  get isCanceled() {
    return this.status === 'canceled';
  }
}
