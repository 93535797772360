/* import __COLOCATED_TEMPLATE__ from './permissions-table.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service flipper;

  get roles() {
    return [
      'user',
      'hiring_manager',
      'recruiter',
      'recruitment_admin',
      'admin',
      'analytics',
      'designer',
    ].compact();
  }

  get permissionTypes() {
    return this.args.permissions.map((p) => p.type);
  }

  get categories() {
    const categories = this.permissionTypes
      .map((p) => p.substring(0, p.indexOf('/')))
      .filter((p) => !!p);
    return [...new Set(categories)];
  }

  get permissionsByCategory() {
    return Object.assign(
      {},
      ...this.categories.map((category) => {
        const types = this.permissionTypes.filter((t) => {
          return t.substring(0, t.indexOf('/')) === category;
        });

        return {
          [category]: types.map((t) => t.substring(t.indexOf('/') + 1)),
        };
      })
    );
  }

  get permissionRoles() {
    const { permissions } = this.args;

    return Object.assign(
      {},
      ...this.permissionTypes.map((type) => {
        const { roles: roleScopes } = permissions.findBy('type', type);
        const orderedScopes = this.roles.map((role) => roleScopes[role]);

        return {
          [type]: orderedScopes,
        };
      })
    );
  }
}
