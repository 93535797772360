import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { get, set, setProperties } from '@ember/object';
import * as Sentry from '@sentry/ember';
import config from 'teamtailor/config/environment';

export default class CompanyRoute extends Route {
  @service appRefresher;
  @service batchJob;
  @service bootData;
  @service cookieConsent;
  @service companyPage;
  @service current;
  @service errorMessage;
  @service flipper;
  @service intercom;
  @service pusher;
  @service router;
  @service session;
  @service store;
  @service translations;
  @service user;
  @service userOnlineStatus;
  @service theme;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  async model({ company_id }, transition) {
    set(this, 'bootData.companyUuid', company_id);
    try {
      const bootData = await this.bootData.fetch();
      return RSVP.hash({
        bootData,
        company: this.store.queryRecord('company', { current: true }),
        user: this.user,
        translations: this.translations.setLocale(bootData.locale_ats),
      });
    } catch (error) {
      if (error.message === 'NoAccessError') {
        await this.translations.setLocale('en');
        this.errorMessage.noAccess();
      } else {
        this.session.attemptedTransitionPath = window.location.href;
        this.errorMessage.generic();
      }

      transition.abort();
    }
  }

  afterModel({ company, user, bootData: { impersonating } }) {
    this.current.company = company;
    this.current.user = user;

    get(this, 'pusher').start();
    get(this, 'theme').setup();
    get(this, 'batchJob');

    if (get(this.flipper, 'user_online_status')) {
      this.userOnlineStatus.impersonating = impersonating;
      if (!impersonating) {
        this.userOnlineStatus.setup();
      }
    }

    if (this.current.company.isLocked) {
      this.companyPage.hideHeader = true;
      this.router.replaceWith('wrap-up');
    }
  }

  setupController(controller, { bootData: { impersonating } }) {
    super.setupController(...arguments);

    controller.impersonating = impersonating;

    this.setupDatadog();
    this.setupIntercom(impersonating);
    this.setupSentry();
    this.appRefresher.setupDeployNotifications();
  }

  async setupDatadog() {
    if (!get(this.flipper, 'datadog_rum')) return;
    if (!config.datadog?.applicationId || !config.datadog?.clientToken) return;

    try {
      const { datadogRum } = await import('@datadog/browser-rum');
      datadogRum.init({
        applicationId: config.datadog.applicationId,
        clientToken: config.datadog.clientToken,
        site: 'datadoghq.eu',
        service: 'teamtailor-ember-app',
        env: config.APP_NAME,
        version: config['ember-cli-app-version']?.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackViewsManually: true,
        defaultPrivacyLevel: 'mask',
      });

      datadogRum.setUser({
        id: this.current.user.id,
        email: this.current.user.email,
        company_id: this.current.company.id,
        company_name: this.current.company.name,
      });

      // eslint-disable-next-line tt/ember-router-events
      this.router.on('routeWillChange', (transition) => {
        // skip intermediate (loading) routes (For example: grow.loading)
        if (!transition.isIntermediate) {
          const routeName = transition.targetName;
          datadogRum.startView({ name: routeName });
        }
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  setupIntercom(impersonating) {
    let { user, company } = this.current;
    if (!impersonating) {
      setProperties(this.intercom, {
        user: {
          id: get(user, 'intercomId'),
          name: get(user, 'name'),
          email: get(user, 'email'),
          hash: get(user, 'intercomUserHash'),
          http_host: config.HTTP_HOST,

          browserWidth: document.documentElement.clientWidth,
          browserHeight: document.documentElement.clientHeight,
          browserResolution: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          screenResolution: `${window.screen.width}x${window.screen.height}`,

          isInPwa: window.matchMedia(
            '(display-mode: standalone), (display-mode: minimal-ui)'
          ).matches,
        },
      });
    }

    if (!impersonating && get(user, 'companyId') === get(company, 'id')) {
      setProperties(this.intercom, {
        company: {
          id: get(company, 'intercomId'),
          name: get(company, 'name'),
          hash: get(user, 'intercomUserHash'),
        },
      });
    }

    if (this.cookieConsent.functional) {
      this.intercom.start();
    }
  }

  setupSentry() {
    Sentry.setUser({
      id: this.current.user.id,
      email: this.current.user.email,
    });
  }

  activate() {
    this.router.on('routeDidChange', this.setupTrackr);
  }

  deactivate() {
    this.router.off('routeDidChange', this.setupTrackr);
  }

  setupTrackr = (transition) => {
    if (!config.TRACKR_URL) {
      return;
    }

    fetch(`${config.TRACKR_URL}/t`, {
      method: 'POST',
      body: JSON.stringify({
        uid: this.current.user.intercomId,
        path: transition.to.name,
        stack: config.APP_NAME,
      }),
    });
  };

  resetController(controller, isExiting) {
    if (
      isExiting &&
      get(this.flipper, 'user_online_status') &&
      !controller.impersonating
    ) {
      this.userOnlineStatus.teardown();
    }
  }
}
