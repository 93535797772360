/* import __COLOCATED_TEMPLATE__ from './oikotie.hbs'; */
import PromotionBaseClass from './promotion-base';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class extends PromotionBaseClass {
  @service current;

  @tracked checkoutData;

  get oikotieLineOfBusiness() {
    return this.store.peekAll('oikotie-line-of-business');
  }

  get oikotieContractTypes() {
    return [
      { value: 'Vakinainen virka', name: 'Regular post' },
      { value: 'Määräaikainen virka', name: 'Temporary post' },
      { value: 'Vakinainen työsuhde', name: 'Permanent employment' },
      { value: 'Määräaikainen työsuhde', name: 'Temporary employment' },
      { value: 'Kesätyö', name: 'Summer work' },
      { value: 'Harjoittelu', name: 'Internship' },
      { value: 'Sijaisuus', name: 'Substitute' },
      { value: 'Freelancer', name: 'Freelancer' },
      { value: 'Muu', name: 'Other' },
    ];
  }

  get oikotieJobCategory() {
    return [
      { value: 'kokopäiväinen', name: 'Full-time' },
      { value: 'osa-aikainen', name: 'Part-time' },
      { value: 'tuntityö', name: 'Hourly basis / timework' },
      { value: 'keikkatyö', name: 'Casual work' },
      { value: 'iltatyö', name: 'Evening work' },
      { value: 'yötyö', name: 'Night work' },
      { value: 'viikonlopputyö', name: 'Weekend work' },
      { value: 'oppisopimus', name: 'Apprenticeship' },
      { value: 'muu', name: 'Other' },
    ];
  }

  get oikotieExperienceLevels() {
    return [
      { value: 'harjoittelija', name: 'Trainee' },
      { value: 'työntekijä', name: 'Employee' },
      { value: 'asiantuntija', name: 'Specialist' },
      { value: 'esihenkilö', name: 'Superior' },
      { value: 'johtaja', name: 'Manager' },
    ];
  }

  @action
  selectOikotieJobCategory(selectedCategory) {
    set(this, 'promotion.oikotieJobCategory', get(selectedCategory, 'value'));
  }

  @action
  selectOikotieContractType(contractType) {
    set(this, 'promotion.oikotieContractType', get(contractType, 'value'));
  }

  @action
  selectOikotieExperienceLevel(experienceLevel) {
    set(
      this,
      'promotion.oikotieExperienceLevel',
      get(experienceLevel, 'value')
    );
  }

  @action
  setOikotieLineOfBusiness(lineOfBusiness) {
    const value = (get(this.promotion, 'oikotieLineOfBusiness') || []).slice();
    toggleInList(value, lineOfBusiness);
    if (value.length > 3) {
      return;
    }

    set(this, 'promotion.oikotieLineOfBusiness', value);
  }
}
