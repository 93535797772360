/* import __COLOCATED_TEMPLATE__ from './api-key-field.hbs'; */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
// TODO: Fix this the next time the file is edited.

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';

export default class PartnerApiKeyFieldComponent extends Component {
  @service store;

  @tracked apiKeys = [];

  fetchApiKeys = task(async () => {
    const keys = await this.store.findAll('api-key');
    this.apiKeys = keys;
    return keys;
  });

  get partnerActivation() {
    return this.args.partnerActivation;
  }

  get partner() {
    return get(this.partnerActivation, 'partner');
  }

  get apiKeyScope() {
    return get(this.partner, 'apiKeyScope');
  }

  get filteredApiKeys() {
    return this.apiKeys.filter(
      ({ parameterName }) => parameterName === this.apiKeyScope
    );
  }

  get apiKey() {
    return get(this.partnerActivation, 'apiKey');
  }

  get apiKeyName() {
    return get(this.apiKey, 'name');
  }

  get humanScope() {
    return get(this.apiKey, 'humanScope');
  }

  get readWrite() {
    return get(this.apiKey, 'readWrite');
  }

  get selectedLabel() {
    if (!this.apiKeyName) return;
    const permission = `${this.humanScope} - ${this.readWrite}`;

    return this.apiKeyName ? `${this.apiKeyName}: ${permission}` : permission;
  }

  @action
  async onChange(key) {
    this.args.onSelectApiKey(key);
  }
}
