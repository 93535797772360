/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { PickedUploadFields } from 'teamtailor/classes/prepared-message';
import { assert } from '@ember/debug';

interface IPreparedMessageAttachmentArgs {
  attachment: PickedUploadFields;
  removeAttachment?(attachment: PickedUploadFields): void;
}

export default class PreparedMessageAttachmentComponent extends Component<IPreparedMessageAttachmentArgs> {
  get canDestroy(): boolean {
    return 'removeAttachment' in this.args;
  }

  get truncatedNameIfNeeded(): string {
    const { fileFileName } = this.args.attachment;

    if (fileFileName.length > 20) {
      return `${fileFileName.substring(0, 10)}...${fileFileName.substring(
        fileFileName.length - 7
      )}`;
    }

    return fileFileName;
  }

  get fileType(): string {
    const { fileFileName } = this.args.attachment;

    const match = fileFileName.match(/\.([0-9a-z]{1,5})(?:[?#]|$)/i);
    if (match) {
      assert('match[1] must exist', match[1]);
      return match[1];
    }

    return 'unknown';
  }

  get isUploading(): boolean {
    return !!this.args.attachment.isUploading;
  }

  @action
  handleDestroy(): void {
    if (this.args.removeAttachment) {
      this.args.removeAttachment(this.args.attachment);
    }
  }
}
