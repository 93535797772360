/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { isObjectWithKey } from 'teamtailor/utils/type-utils';
import BlockAdjustmentsService from 'teamtailor/services/block-adjustments';
import { SectionModel } from 'teamtailor/models';

type Item = { id: string; label: string } | string;

type AdjustmentInputSelectSignature = {
  Args: {
    selectedId: string;
    defaultId: string;
    items: Array<Item>;
    attribute?: keyof SectionModel;
    reloadIframeAfterChange?: boolean;
    onChange: (
      id: string,
      options?: { attribute?: string; reloadIframeAfterChange?: boolean }
    ) => void;
  };
};

export default class AdjustmentInputSelectComponent extends Component<AdjustmentInputSelectSignature> {
  @service declare blockAdjustments: BlockAdjustmentsService;

  @tracked
  dropdownId: string | null = null;

  get isDisabled() {
    return (
      this.blockAdjustments.editingAttribute &&
      this.blockAdjustments.editingAttribute !== this.dropdownId
    );
  }

  get value() {
    return (
      this.getLabel(this.args.selectedId) ??
      this.getLabel(this.args.defaultId) ??
      '-'
    );
  }

  getLabel(id: string) {
    const item = this.args.items.find((item) => {
      const itemId = isObjectWithKey(item, 'id') ? item.id : item;
      return itemId === id;
    });

    return isObjectWithKey(item, 'label') ? item.label : item;
  }

  @action
  setDropdownId(id: string) {
    this.dropdownId = id;
  }

  @action
  attachEvents(closeDropdown: () => void) {
    this.closeDropdown = closeDropdown;
    document.addEventListener(
      this.blockAdjustments.closeAllEvent,
      this.closeDropdown
    );
  }

  @action
  destroyEvents() {
    document.removeEventListener(
      this.blockAdjustments.closeAllEvent,
      this.closeDropdown
    );
  }

  @action
  dropdownToggled(isOpen: boolean) {
    next(() => {
      this.blockAdjustments.editingAttribute = isOpen
        ? this.dropdownId
        : undefined;
    });
  }

  @action handleChange(item: Item) {
    const id = isObjectWithKey(item, 'id') ? item.id : item;
    this.args.onChange(id, {
      attribute: this.args.attribute,
      reloadIframeAfterChange: this.args.reloadIframeAfterChange,
    });
  }

  closeDropdown() {
    this.closeDropdown();
  }
}
