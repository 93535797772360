/* import __COLOCATED_TEMPLATE__ from './progress-circle.hbs'; */
import partialCircle from 'svg-partial-circle';
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class extends Component {
  @argDefault size = 'large';

  largeStrokeWidth = 6;
  smallStrokeWidth = 4;
  largeSize = 48;
  smallSize = 16;

  get svgSize() {
    return this.size === 'large' ? this.largeSize : this.smallSize;
  }

  get strokeWidth() {
    return this.size === 'large'
      ? this.largeStrokeWidth
      : this.smallStrokeWidth;
  }

  get noTodos() {
    return !this.args.total;
  }

  get done() {
    return !this.noTodos && this.args.completed === this.args.total;
  }

  get todosLeft() {
    return (this.args.total || 0) - (this.args.completed || 0);
  }

  get progressPath() {
    const halfSize = this.svgSize / 2;
    const halfStrokeWidth = this.strokeWidth / 2;
    const startAngle = -Math.PI / 2;
    const progress = this.args.total
      ? this.args.completed / this.args.total
      : 0;
    return partialCircle(
      halfSize, // center X
      halfSize, // center Y
      halfSize - halfStrokeWidth, // radius
      startAngle, // start angle
      startAngle + 2 * Math.PI * progress // end angle
    )
      .map((command) => command.join(' '))
      .join(' ');
  }
}
