/* import __COLOCATED_TEMPLATE__ from './company-fonts.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class CompanyFonts extends Component {
  @service current;

  get googleFontUrl() {
    return get(this.current.company, 'design.googleFontUrl');
  }

  get googleFontUrlValid() {
    return Boolean(this.googleFontUrl.includes('https://fonts.googleapis.com'));
  }

  get typekitId() {
    return get(this.current.company, 'design.typekitId');
  }
}
