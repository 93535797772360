/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FormFieldReadCheckbox extends Component {
  @tracked selectedOptions = this.args.value || [];

  get isSingleCheckbox() {
    return !this.args.field.options?.length;
  }

  @action
  onChange(optionId, checked) {
    if (this.isSingleCheckbox) {
      this.args.onChange(checked);
      return;
    }

    if (checked) {
      this.selectedOptions = [...this.selectedOptions, optionId];
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        (_optionId) => _optionId !== optionId
      );
    }

    this.args.onChange(this.selectedOptions);
  }
}
