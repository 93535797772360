/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { stripHtml } from 'teamtailor/utils/string-sanitizer';
import { UserModel } from 'teamtailor/models';
import { SafeString } from '@ember/template/-private/handlebars';
import { get } from 'teamtailor/utils/get';

type CoreDropdownItemSelectComponentArgs = {
  user?: UserModel;
  text?: string;
  searchTerm?: string;
};

function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default class CoreDropdownItemSelectComponent extends Component<CoreDropdownItemSelectComponentArgs> {
  @tracked declare guid: string;

  @action onInsert(el: HTMLElement) {
    this.guid = guidFor(el);
  }

  get text() {
    let text: string | SafeString =
      this.args.text ??
      (this.args.user ? get(this.args.user, 'nameOrEmail') : '');

    if (this.args.user) {
      text = stripHtml(text);
    } else {
      text = text
        .toString()
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    }

    if (this.args.searchTerm && text) {
      text = text.replace(
        new RegExp(`(${escapeRegExp(this.args.searchTerm)})`, 'gi'),
        '<b>$1</b>'
      );
    }

    return text;
  }
}
