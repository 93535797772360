/* import __COLOCATED_TEMPLATE__ from './scale-answers.hbs'; */
import Component from '@glimmer/component';
import {
  defaultFontOptions,
  stackLabelFontOptions,
} from 'teamtailor/utils/survey-charts';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { stageTypeColors } from 'teamtailor/components/insights/charts/colors';

type StageRatings = {
  [key: number]: number;
  average_score: number;
  total: number;
};

type ChartPoint = {
  y: number;
  x: number;
  series: {
    name: string;
  };
};

type StageBreakdown = {
  inbox: StageRatings;
  in_process: StageRatings;
  screening: StageRatings;
  interview: StageRatings;
  offer: StageRatings;
  hired: StageRatings;
};

type responseCountByStageType = {
  [key: string]: number;
  inbox: number;
  in_process: number;
  screening: number;
  interview: number;
  offer: number;
  hired: number;
};

type RatingBreakdown = {
  [key: number]: responseCountByStageType;
};

type Answers = {
  values: StageBreakdown;
  rating_breakdown: RatingBreakdown;
  number_of_picks: {
    by_total: number;
    by_rejected: number;
    by_hired: number;
  };
};

type Question = {
  answers: Answers;
  title: string;
  type: string;
  maximum_value: number;
  average_score: number;
};

type ChartData =
  | {
      name: string;
      data: (number | undefined)[];
      color: string | undefined;
      index: number;
    }[]
  | {
      data: (number | undefined)[];
    }[];

type SurveyScaleAnswersArgs = {
  question: Question;
  questionIndex: number;
  showStageBreakdown: boolean;
  isAnonymous: boolean;
};

export default class SurveyTextAnswersComponent extends Component<SurveyScaleAnswersArgs> {
  @service declare intl: IntlService;
  @tracked visibleStageTypes = Object.keys(this.args.question.answers.values);

  colors = stageTypeColors;

  get question(): Question {
    return this.args.question;
  }

  get answers(): Answers {
    return this.question.answers;
  }

  get numOfAnswers(): number {
    return this.answers.number_of_picks.by_total;
  }

  get avgRating(): string {
    const average = this.question.average_score;
    return (Math.round(average * 100) / 100).toFixed(2);
  }

  get showAnswers(): boolean {
    return !this.args.isAnonymous || this.numOfAnswers >= 10;
  }

  get stageTypes(): string[] {
    return Object.keys(this.answers.values);
  }

  get ratings(): number[] {
    return Array.from({ length: this.question.maximum_value }, (_, i) => i + 1);
  }

  get chartData(): ChartData {
    const data: ChartData = [];
    const { stageTypes } = this;

    if (this.args.showStageBreakdown) {
      this.visibleStageTypes.forEach((stageType) => {
        const counts = Object.values(this.answers.rating_breakdown).map(
          (responseCounts) => {
            return responseCounts[stageType];
          }
        );
        const color: string | undefined = stageTypeColors[stageType];
        data.push({
          name: this.intl.t(`job.stage_types.${stageType}`),
          data: counts,
          color,
          index: stageTypes.indexOf(stageType) || 0,
        });
      });

      return data;
    } else {
      const data = Object.values(this.answers.rating_breakdown).map(
        (responseCounts) => {
          return responseCounts.total;
        }
      );
      return [{ data }];
    }
  }

  get chartOptions(): { [key: string]: any } {
    const { intl, numOfAnswers, args } = this;
    const { showStageBreakdown } = args;

    return {
      chart: {
        type: 'column',
        height: '300px',
        spacingLeft: 5,
        spacingRight: 0,
        spacingTop: 15,
      },

      title: {
        text: undefined,
      },

      xAxis: {
        categories: this.ratings,
        labels: {
          style: defaultFontOptions,
        },

        lineColor: '#EEEFF2',
      },

      yAxis: {
        min: 0,
        reversedStacks: false,
        offset: 30,
        tickLength: 30,
        title: {
          text: undefined,
        },

        labels: {
          enabled: true,
          padding: 0,
          x: 15,
          y: -5,
          style: defaultFontOptions,
        },

        stackLabels: {
          enabled: true,
          style: stackLabelFontOptions,
          formatter(this: { total: number }): string | null {
            const responseCount = this.total;
            const percentage = responseCount
              ? Math.round((responseCount / numOfAnswers) * 100)
              : null;
            return responseCount ? `${responseCount} / ${percentage}%` : null;
          },
        },
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        formatter(this: { point: ChartPoint }): string | false {
          return showStageBreakdown
            ? `${this.point.series.name} <br/>${intl.t('surveys.votes')}: ${
                this.point.y
              }`
            : false;
        },
      },

      plotOptions: {
        column: {
          animation: {
            duration: 200,
          },

          stacking: 'normal',
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
  }

  @action
  handleCheck(stageType: string): void {
    const visibleStageTypes = [...this.visibleStageTypes];
    if (visibleStageTypes.includes(stageType)) {
      this.visibleStageTypes = visibleStageTypes.filter(
        (type: string) => type !== stageType
      );
    } else {
      visibleStageTypes.push(stageType);
      this.visibleStageTypes = visibleStageTypes;
    }

    this.visibleStageTypes = this.stageTypes.reduce(
      (acc: string[], type: string) => {
        if (this.visibleStageTypes.includes(type)) {
          acc.push(type);
        }

        return acc;
      },
      []
    );
  }
}
