/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import Component from '@glimmer/component';

type TabsLinkArgs = {
  icon?: string;
  iconStyle?: string;
  isDisabled?: boolean;
  isGrowable?: boolean;
  route?: string;
  text?: string;
};

export default class TabsLink extends Component<TabsLinkArgs> {}
