/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { JobApplicationModel, JobModel, StageModel } from 'teamtailor/models';

interface Args {
  stage: StageModel;
  showRejected: boolean;
  filteredCandidateIds: string[] | null;
  filteredJobApplications: JobApplicationModel[];
  selectJobApplication: (
    jobApplication: JobApplicationModel,
    value: boolean
  ) => void;
  deselectJobApplication: (jobApplication: JobApplicationModel) => void;
  params: Record<string, string>;
  job: JobModel;
}

export default class StageListHeader extends Component<Args> {
  @tracked isEditingName = false;
  @tracked bulkSelectingAll = false;

  get allCandidatesSelected() {
    return get(this.args.stage, 'allCandidatesSelected');
  }

  get triggers() {
    const { triggers } = this.args.stage;
    const filterRejected = this.args.showRejected || false;
    return triggers.filter((trigger) => trigger.onReject === filterRejected);
  }

  get triggerCount() {
    return this.triggers.length;
  }

  get hasTriggers() {
    return this.triggers.length > 0;
  }

  get triggerTooltip() {
    const total = get(this, 'triggerCount');
    const triggerLabel = total > 1 ? 'triggers' : 'trigger';
    return `${total} ${triggerLabel}`;
  }

  get showFilteredTitle() {
    return this.args.filteredCandidateIds !== null;
  }

  get requisitionNumberOfOpenings() {
    const { job } = this.args;

    const requisition = get(job, 'requisition');

    return get(requisition, 'numberOfOpenings');
  }

  @action
  toggleEditingName() {
    this.isEditingName = true;
  }

  @action
  handleEditNameFocusOut() {
    this.args.stage.rollbackAttributes();
    this.isEditingName = false;
  }

  @action
  handleEditNameKeyUp(_value: string, event: KeyboardEvent) {
    if (event.key === 'Escape') {
      (event.target as HTMLElement).blur();
    }
  }

  @action
  saveName() {
    if (isEmpty(get(this.args.stage, 'name'))) {
      this.handleEditNameFocusOut();
    }

    return this.args.stage.save().then(() => {
      this.isEditingName = false;
    });
  }

  @action
  async selectAll() {
    set(this.args.stage, 'allCandidatesSelected', !this.allCandidatesSelected);
    this.bulkSelectingAll = true;
    await this.args.stage.fetchJobApplicationsTask.perform({
      rejected: this.args.showRejected,
      reload: false,
      filter: this.args.params,
      loadAll: true,
    });
    this.bulkSelectingAll = false;
    this.args.filteredJobApplications.forEach(
      (jobApplication: JobApplicationModel) => {
        if (this.allCandidatesSelected) {
          this.args.selectJobApplication(
            jobApplication,
            this.allCandidatesSelected
          );
        } else {
          this.args.deselectJobApplication(jobApplication);
        }
      }
    );
  }
}
