/* import __COLOCATED_TEMPLATE__ from './form-code-editor.hbs'; */
import FormInput from 'teamtailor/components/form-input';
import Prism from 'prismjs';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';

const TAB_LENGTH = 2;

export default class FormCodeEditorComponent extends FormInput {
  @tracked codeTextArea = null;
  @tracked outputContext = null;

  get editorLanguageClass() {
    return `code-output--${this.args.language}`;
  }

  get id() {
    return guidFor(this);
  }

  renderOutput(value) {
    value = value ? `${value} ${'\n'}` : '';
    const html = Prism.highlight(
      value,
      Prism.languages[this.args.language],
      this.args.language
    );

    this.outputContext.innerHTML = html;
    this.outputContext.scrollTop = this.codeTextArea.scrollTop;
  }

  reformatCode(event) {
    const selStartPos = this.codeTextArea.selectionStart;
    const inputVal = this.codeTextArea.value;

    if (event.key === 'Tab') {
      this.codeTextArea.value = `${inputVal.substring(
        0,
        selStartPos
      )}${'  '}${inputVal.substring(selStartPos, inputVal.length)}`;
      this.codeTextArea.selectionStart = selStartPos + TAB_LENGTH;
      this.codeTextArea.selectionEnd = selStartPos + TAB_LENGTH;
      event.preventDefault();
    }

    this.renderOutput(this.codeTextArea.value);
  }

  @action
  onInsert(e) {
    this.codeTextArea = e.querySelector('textarea');
    this.outputContext = e.querySelector('.code-output');
    this.renderOutput(this.args.value);
  }

  @action
  handleKeydown(e) {
    this.reformatCode(e);
  }

  @action
  handleScroll() {
    this.outputContext.scrollTop = this.codeTextArea.scrollTop;
  }

  @action
  handleOnInput(event) {
    this.reformatCode(event);
    if (this.args.onChange) {
      debounce(this.args.onChange, 750);
    }
  }
}
