/* import __COLOCATED_TEMPLATE__ from './question-groups.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Intl from 'ember-intl/services/intl';
import { action } from '@ember/object';
import QuestionModel from 'teamtailor/models/question';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import OrderedListHelper, {
  NESTED_TYPES,
  OrderedCompetence,
  OrderedCompetenceParent,
} from 'teamtailor/utils/competence/ordered-list-helpers';
import { isEmpty } from '@ember/utils';

interface CompetenceSelectArgs {
  interviewKit: InterviewKitModel;
  onSelectQuestion: (
    question: QuestionModel,
    parentItem: OrderedCompetence
  ) => void;
  selectedQuestions?: QuestionModel[];
}

export default class CompetenceSelectComponent extends Component<CompetenceSelectArgs> {
  @service declare intl: Intl;
  @service declare current: Current;

  @action
  handleDragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
  }: {
    draggedItem: OrderedCompetence;
    sourceList: OrderedCompetence[];
    sourceIndex: number;
    targetList: OrderedCompetence[];
    targetIndex: number;
  }): void {
    if (sourceList === targetList && sourceIndex === targetIndex) {
      return;
    }

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, draggedItem);

    this.args.interviewKit.updateRowOrderPositions(
      draggedItem.type === NESTED_TYPES.COMPETENCE
    );
  }

  @action
  setupOrderedItems() {
    const { interviewKit } = this.args;
    const { competenceOrder } = interviewKit;

    if (isEmpty(competenceOrder)) {
      competenceOrder.pushObjects(
        OrderedListHelper.getFirstTimeNestedItems(interviewKit)
      );
    }
  }

  @action
  handleSelectQuestion(
    parentItem: OrderedCompetenceParent,
    question: QuestionModel
  ): void {
    this.args.onSelectQuestion(question, parentItem);
  }
}
