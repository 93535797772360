/* import __COLOCATED_TEMPLATE__ from './record-meeting-button.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';

interface VideoMeetingRecordMeetingButtonArgs {
  recordingSecondsElapsed: number;
}

export default class RecordMeetingButtonComponent extends Component<VideoMeetingRecordMeetingButtonArgs> {
  get recordingTime() {
    return moment()
      .startOf('day')
      .seconds(this.args.recordingSecondsElapsed)
      .format(this.format());
  }

  format() {
    return this.args.recordingSecondsElapsed >= 3600 ? 'HH:mm:ss' : 'mm:ss';
  }
}
