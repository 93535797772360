/* import __COLOCATED_TEMPLATE__ from './pusher-auth.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import authPopup from 'teamtailor/utils/auth-promise-window';
import ENV from 'teamtailor/config/environment';
import { v1 as uuid } from 'ember-uuid';
import { inject as service } from '@ember/service';
import { task, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import PusherService from 'teamtailor/services/pusher';
import Current from 'teamtailor/services/current';

const BASE_URL = `${ENV.httpOrHttps}://${ENV.RAILS_HOST}`;

interface handleSuccessArg {
  authorization: { id: number; type: string; pusherAuth: boolean };
  pusherAuth: boolean;
}

type PusherAuthArgs = {
  provider: string;
  handleSuccess: (auth: handleSuccessArg) => void;
  handleFailure: (e: string) => void;
};

export default class PusherAuthComponent extends Component<PusherAuthArgs> {
  @tracked state = '';
  authWindow: Window | null = null;
  @service declare current: Current;
  @service declare pusher: PusherService;
  eventId = uuid();

  get url() {
    const userId = get(this.current.user, 'id');
    const companyUuid = get(this.current.company, 'uuid');

    return `${BASE_URL}/auth/${this.args.provider}?event_id=${this.eventId}&user_id=${userId}&company_uuid=${companyUuid}`;
  }

  openAsyncTask = task(async (key: string) => {
    this.state = 'loading';
    this.authWindow = authPopup('', `Authorize ${this.args.provider}`);
    await waitForProperty(this, key, (value) => {
      return Boolean(value);
    });

    if (this.authWindow) this.authWindow.location = this.url;
  });

  @action
  openAsync(key: string) {
    this.openAsyncTask.perform(key);
  }

  @action
  async setupPusher() {
    try {
      const channel = await this.pusher.channel(
        get(this.current.user, 'notificationsChannel')
      );

      channel.bind(`auth-success-${this.eventId}`, (data) => {
        this.args.handleSuccess({
          authorization: { ...data, pusherAuth: true },
          pusherAuth: true,
        });
        this.state = 'default';
      });

      channel.bind(`auth-fail-${this.eventId}`, (e) => {
        this.args.handleFailure(e);
      });
    } catch {
      return;
    }
  }
}
