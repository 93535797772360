/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import JobModel from 'teamtailor/models/job';

interface Args {
  job: JobModel;
}

export default class extends Component<Args> {
  get anonymous() {
    return this.args.job.stages.find(
      (stage) => stage.name === 'Inbox' && stage.anonymous
    );
  }
}
