/* import __COLOCATED_TEMPLATE__ from './recruiting-firm-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RecruitingFirmValidations from 'teamtailor/validations/recruiting-firm';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';
import RecruitingFirmModel from 'teamtailor/models/recruiting-firm';

interface RecruitingFirmFormArgs {
  recruitingFirm: RecruitingFirmModel;
}

export default class RecruitingFirmFormComponent extends Component<RecruitingFirmFormArgs> {
  RecruitingFirmValidations = RecruitingFirmValidations;
  @service declare current: Current;
  @service declare intl: IntlService;

  get isNew() {
    return get(this.args.recruitingFirm, 'isNew');
  }

  get header() {
    const action = this.isNew ? 'add_title' : 'edit_title';
    return this.intl.t(
      `settings.external_recruitment.recruiting_firms.recruiting_firm_form.${action}`
    );
  }

  get saveButtonTranslationKey() {
    return this.isNew ? 'common.add' : 'common.update';
  }
}
