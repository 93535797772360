/* import __COLOCATED_TEMPLATE__ from './section-connect-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class SectionConnectForm extends Component {
  @service current;
  @service store;

  get section() {
    return this.args.model;
  }

  get isSelectDisabled() {
    return get(this, 'section.department.id');
  }

  get selectedFilterDepartment() {
    if (!this.section.pickedDepartmentId) return undefined;
    return this.store.findRecord('department', this.section.pickedDepartmentId);
  }

  @action
  handleSetDepartment(department) {
    const departmentId = department ? department.id : null;
    set(this, 'section.pickedDepartmentId', departmentId);
  }

  @action
  didInsertForm() {
    if (
      !this.section.pickedDepartmentId &&
      get(this, 'section.department.id')
    ) {
      set(
        this,
        'section.pickedDepartmentId',
        get(this, 'section.department.id')
      );
    }
  }
}
