/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import Component from '@glimmer/component';
import groupBy from 'teamtailor/utils/group-by';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';

export default class ReactionsListComponent extends Component {
  _sorting = ['createdAt'];
  @sort('args.reactions', '_sorting') sortedReactions;
  @groupBy('sortedReactions', 'emoji') reactionsByType;
}
