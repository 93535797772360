/* import __COLOCATED_TEMPLATE__ from './table-with-actions.hbs'; */
import Component from '@glimmer/component';

const LOADING_COLUMNS = [
  {
    width: '2xl',
  },
  {
    width: 'xl',
  },
  {
    width: 'm',
    contentType: 'core/badge',
  },
  {
    width: 'm',
  },
];

export default class UnicornTableWithActions extends Component {
  loadingColumns = LOADING_COLUMNS;

  get columns() {
    return [
      {
        label: 'Id',
      },
      {
        label: 'Job title',
      },
      {
        label: 'Location',
      },
    ];
  }
}
