/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

interface Args {
  onToggle: () => void;
  checked: boolean;
}

export default class extends Component<Args> {
  @tracked animation = false;

  @action
  onClick() {
    if (!this.args.checked) {
      this.animation = true;
      later(() => {
        this.animation = false;
        this.args.onToggle();
      }, 400);
    } else {
      this.args.onToggle();
    }
  }
}
