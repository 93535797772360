/* import __COLOCATED_TEMPLATE__ from './edit-avatar-modal.hbs'; */
import { action, setProperties } from '@ember/object';
import AvatarModel from 'teamtailor/models/avatar';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset as changeset } from 'ember-changeset';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import { TempAvatarData } from 'teamtailor/constants/job-avatar';

interface EditAvatarModalArgs {
  avatar: AvatarModel;
  saveAvatar?: () => void;
  colorOptions: { color: string; variant: string; isDecorative: boolean }[];
  defaultAvatarData: TempAvatarData;
}

export default class EditAvatarModalComponent extends Component<EditAvatarModalArgs> {
  @tracked changeset: BufferedChangeset | null = null;

  buildChangeset = modifier(() => {
    this.changeset = changeset(this.args.avatar);
  });

  get avatarType() {
    return this.changeset?.avatarType;
  }

  get isTypeEmoji() {
    return this.avatarType === 'emoji';
  }

  get isTypeIcon() {
    return this.avatarType === 'icon';
  }

  get hasChanges() {
    return this.changeset?.isDirty;
  }

  get saveButtonDisabled() {
    return (
      !this.hasChanges ||
      (this.isTypeEmoji && !this.changeset?.chosenEmoji) ||
      (this.isTypeIcon && !this.changeset?.chosenIcon)
    );
  }

  @action
  resetAvatar(): void {
    setProperties(this.changeset, this.args.defaultAvatarData);
  }

  @action
  onSave() {
    if (this.hasChanges) {
      this.changeset?.execute();
      this.args.avatar.resetBasedOnType();
    }

    this.args.saveAvatar?.();
  }
}
