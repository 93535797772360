/* import __COLOCATED_TEMPLATE__ from './jobnet.hbs'; */
import PromotionBaseClass from './promotion-base';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import moment from 'moment-timezone';
import { A as array } from '@ember/array';
import { tracked } from '@glimmer/tracking';

import {
  jobnetDrivingLicenses,
  jobnetJobTypes,
} from 'teamtailor/constants/promote';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class extends PromotionBaseClass {
  @service current;
  @service intl;

  @tracked checkoutData;
  jobnetDrivingLicenses = jobnetDrivingLicenses;
  jobnetJobTypes = jobnetJobTypes;

  get jobnetMunicipalities() {
    return this.store.peekAll('promotion-categories/jobnet-municipality');
  }

  get jobnetMainProfessions() {
    return this.store.peekAll('promotion-categories/jobnet-main-profession');
  }

  get jobnetHiringDateHint() {
    let deadlineMoment = moment().add(
      get(this, 'promotion.channel.duration') - 1,
      'days'
    );
    if (get(this, 'promotion.job.endDate')) {
      deadlineMoment = moment(get(this, 'promotion.job.endDate'));
    }

    const employmentDate = moment(get(this, 'promotion.jobnetEmploymentDate'));
    if (get(this, 'promotion.jobnetEmploymentDate')) {
      return deadlineMoment.isSameOrAfter(employmentDate);
    }

    return false;
  }

  get jobnetHintErrorMessage() {
    let message = this.intl.t(
      'components.job.promotion.jobnet.employment_date_error'
    );
    const endDate = get(this, 'promotion.job.endDate');
    let deadlineMoment = moment()
      .add(get(this, 'promotion.channel.duration'), 'days')
      .format('YYYY-MM-DD');
    if (endDate) {
      return `${message} ${moment(endDate).format('YYYY-MM-DD')}`;
    }

    return `${message} ${deadlineMoment}`;
  }

  get jobnetSelectedDrivingLicenses() {
    const drivingLicense = get(this, 'promotion.jobnetDrivingLicense');
    if (drivingLicense && drivingLicense !== 'Nothing') {
      return drivingLicense.split(',');
    } else {
      return array();
    }
  }

  get jobnetMinMaxWorkHoursValidation() {
    const max = parseInt(get(this, 'promotion.jobnetMaxWorkHours')) || 0;
    const min = parseInt(get(this, 'promotion.jobnetMinWorkHours')) || 0;
    const maxWorkHours = 36;
    if (min > max || max > maxWorkHours) {
      return true;
    }

    return false;
  }

  get isValidJobnetHiringDate() {
    if (get(this, 'promotion.jobnetEmploymentDate')) {
      return get(this, 'jobnetHiringDateHint');
    }

    return false;
  }

  @action
  selectJobnetMainProfession(object) {
    if (get(object, 'id') !== get(this, 'promotion.jobnetMainCategory.id')) {
      this.store.unloadAll('promotion-categories/jobnet-secondary-category');
      set(this, 'promotion.jobnetSecondaryCategory', null);
    }

    set(this, 'promotion.jobnetMainCategory', object);
    this.store
      .query('promotion-categories/jobnet-secondary-category', {
        general_profession_id: get(object, 'id'),
      })
      .then((secondaryProfessions) => {
        set(this, 'jobnetSecondaryProfessions', secondaryProfessions);
      });
  }

  @action
  selectJobnetSecondaryProfession(object) {
    set(this, 'promotion.jobnetSecondaryCategory', object);
    set(this, 'promotion.jobnetOccupationId', object.id);
  }

  @action
  selectJobnetJobType(object) {
    set(this, 'promotion.jobnetJobType', object);
  }

  @action
  selectJobnetDrivingLicense(object) {
    const values = this.jobnetSelectedDrivingLicenses.slice();
    toggleInList(values, object);
    if (values.length) {
      set(this, 'promotion.jobnetDrivingLicense', values.join());
    } else {
      set(this, 'promotion.jobnetDrivingLicense', 'Nothing');
    }
  }
}
