/* import __COLOCATED_TEMPLATE__ from './actions-row.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ConfettiService from 'teamtailor/services/confetti';
import JobApplicationModel from 'teamtailor/models/job-application';
import StageModel from 'teamtailor/models/stage';

type ActionsRowArgs = {
  jobApplications: JobApplicationModel[];
  pinnedCount: number;
  hoveredJobApplication: JobApplicationModel;
};

export default class CompareCandidatesActionsRowComponent extends Component<ActionsRowArgs> {
  @service declare confetti: ConfettiService;
  @tracked showRejectModal = false;
  @tracked activeJobApplication?: JobApplicationModel;

  @action
  openRejectModal(jobApplication: JobApplicationModel): void {
    this.activeJobApplication = jobApplication;
    this.showRejectModal = true;
  }

  updateStage = task(
    async (application: JobApplicationModel, stage: StageModel) => {
      this.activeJobApplication = application;
      set(application, 'activeStage', stage);
      await application.save();

      if (stage.hired) {
        this.confetti.show();
      }

      const job = await get(stage, 'job');
      const odj = await get(job, 'overdueJobApplication');
      if (odj) {
        odj.reload();
      }
    }
  );
}
