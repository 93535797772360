/* import __COLOCATED_TEMPLATE__ from './multi-select.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class PartnerConfigFieldsMultiSelectComponent extends Component {
  get selectedValues() {
    if (this.args.value === undefined) {
      return [];
    }

    return this.args.options.filter((option) =>
      this.args.value.includes(option.id)
    );
  }

  get options() {
    return this.args.field.optgroups ? this.groupOptions : this.args.options;
  }

  @action
  changeSelectValues(value) {
    const values = (this.selectedValues || []).slice();
    toggleInList(values, value);
    if (this.args.field.max !== undefined) {
      if (values.length > this.args.field.max) {
        return;
      }
    }

    const newValues = values.map((value) => value.id);
    this.args.onChange(newValues);
  }

  get selectedValue() {
    return this.args.options?.find(
      (option) => option.id?.toString() === this.args.value?.toString()
    );
  }

  get selectedOptgroups() {
    if (this.args.value === undefined) return [];
    return this.plainGroupOptions.filter((option) =>
      this.args.value.includes(option.id)
    );
  }

  get plainGroupOptions() {
    const { optgroups } = this.args;

    const reduceGroups = (result, group) => {
      return [
        ...result,
        ...group.options.map((option) => {
          return { id: `${option.id}`, label: option.label };
        }),
      ];
    };

    if (optgroups) {
      return optgroups.reduce(reduceGroups, []);
    } else {
      return null;
    }
  }

  get groupOptions() {
    const { optgroups } = this.args;
    if (optgroups) {
      return optgroups.map((optgroup) => {
        return {
          groupName: optgroup.label,
          options: optgroup.options.map((option) => {
            return { id: `${option.id}`, label: option.label };
          }),
        };
      });
    } else {
      return null;
    }
  }
}
