/* import __COLOCATED_TEMPLATE__ from './section-heading.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class InsightsJobSectionHeading extends Component {
  @tracked startDate = null;
  @tracked endDate = null;

  @action
  setDefaultDates() {
    this.startDate = moment().subtract(29, 'day');
    this.endDate = moment().subtract(1, 'day');
  }

  @action
  handleUpdateDates(startDate, endDate) {
    this.startDate = moment(startDate);
    this.endDate = moment(endDate);
  }
}
