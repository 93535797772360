/* import __COLOCATED_TEMPLATE__ from './audio-meter-bar.hbs'; */
import { tracked } from '@glimmer/tracking';
import VideoMeetingAudioMeter from 'teamtailor/components/video-meeting/audio-meter';

export default class VideoMeetingAudioMeterBar extends VideoMeetingAudioMeter {
  @tracked barCount = 0;
  @tracked maxBars = 10;
  barWidth = 7;

  updateView(element: HTMLElement, inputData: Float32Array) {
    const { clientWidth } = element;

    this.maxBars = Math.floor(clientWidth / this.barWidth);

    const percent = this.percentFromInputData(inputData);

    this.barCount = Math.floor(this.maxBars * percent);
  }
}
