/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';
import { tracked } from '@glimmer/tracking';

const UNSPLASH = 'Unsplash';
const GIPHY = 'Giphy';

export default class MediaLibraryModalComponent extends Component {
  @argDefault multiple = false;
  @argDefault selectable = true;
  @tracked activeTabIndex = 0;
  @tracked selectedTag = null;
  @tracked query = '';

  unsplashName = UNSPLASH;
  giphyName = GIPHY;

  @action
  handlePickImage(image) {
    if (this.args.onPickImage) {
      this.args.onPickImage(image);
    }
  }

  @action
  onToggleTab(index) {
    this.selectedTag = null;
    this.activeTabIndex = index;
    this.query = '';
  }

  @action
  handleCloseModel() {
    this.activeTabIndex = 0;
    if (this.args.onClose) {
      this.args.onClose();
    }
  }

  @action
  handleTagClick(tag) {
    this.selectedTag = this.selectedTag === tag ? null : tag;
  }

  @action
  onFileUploadClick() {
    window.dispatchEvent(new CustomEvent('media-library-upload'));
  }
}
