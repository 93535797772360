/* import __COLOCATED_TEMPLATE__ from './off-boarding.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import moment from 'moment-timezone';

export default class ImageWidget extends WidgetClass {
  @service declare current: CurrentService;

  get user() {
    return this.current.user;
  }

  get company() {
    return this.current.company;
  }

  get daysLeft() {
    if (!this.company.churnedAt) {
      return null;
    }

    const today = moment().format('YYYY-MM-DD');
    const lockDay = moment(this.company.churnedAt).format('YYYY-MM-DD');

    return moment(lockDay).diff(today, 'days');
  }
}
