/* import __COLOCATED_TEMPLATE__ from './custom.hbs'; */
import Component from '@glimmer/component';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, set, computed } from '@ember/object';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout, task, keepLatestTask } from 'ember-concurrency';
import { reject } from 'rsvp';
import { EMPLOYEE_CUSTOM_WIDGET_COLORS } from 'teamtailor/constants/dashboard';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import DashboardWidgetValidations from 'teamtailor/validations/dashboard/widget';
import { themeColors } from '@teamtailor/design-tokens';

const DECORATIVE_COLORS = Object.keys(themeColors.background.decorative);

const KEY_CODE_ESCAPE = 27;

const WIDGET_COLOR_OPTIONS = Object.freeze({
  custom: DECORATIVE_COLORS,
  'employee-custom': EMPLOYEE_CUSTOM_WIDGET_COLORS,
});

const DEPRECATED_COLORS_MAP = {
  turquoise: 'teal',
  gray: 'zinc',
  gold: 'amber',
  coral: 'rose',
};

export default class DashboardWidgetsFormCustomComponent extends Component {
  @service flashMessages;
  @service router;
  @service store;
  @service current;

  @alias('args.widget') widget;
  @alias('args.route') route;
  @alias('args.isEmployeeDashboard') isEmployeeDashboard;

  @tracked modalOpen = true;

  widgetColors = WIDGET_COLOR_OPTIONS[this.widget.name];

  @computed('widget')
  get changeset() {
    return new Changeset(
      this.widget,
      lookupValidator(DashboardWidgetValidations),
      DashboardWidgetValidations
    );
  }

  delayedCloseTask = task(async () => {
    await timeout(800);
    this.modalOpen = false;

    if (this.route) {
      this.router.transitionTo(this.route);
    }
  });

  destroyTask = keepLatestTask(async () => {
    const dashboardWidgetId = parseInt(this.widget.id, 10);
    await this.widget.destroyRecord();

    this.store
      .peekAll('picked-dashboard-widget')
      .forEach((pickedDashboardWidget) => {
        if (pickedDashboardWidget.dashboardWidgetId === dashboardWidgetId) {
          pickedDashboardWidget.unloadRecord();
        }
      });

    this.modalOpen = false;
    return true;
  });

  saveTask = keepLatestTask(async () => {
    await this.changeset.validate();
    if (this.changeset.isValid) {
      await this.changeset.save();
      if (this.args.onSave) {
        await this.args.onSave();
      }

      this.delayedCloseTask.perform();
      return true;
    } else {
      return reject();
    }
  });

  @action
  handleEditNameKeyUp(value, event) {
    if (event.keyCode === KEY_CODE_ESCAPE) {
      this.modalOpen = false;
    }
  }

  @action
  handleModalClose() {
    this.modalOpen = false;

    if (this.args.onClose) {
      this.args.onClose();
    }

    if (this.route) {
      this.router.transitionTo(this.route);
    }
  }

  @action
  handleSaveWidget() {
    return this.saveTask.perform();
  }

  @action
  handleDestroyWidget() {
    this.destroyTask.perform();
  }

  @action
  handelInsertElement() {
    if (DEPRECATED_COLORS_MAP[this.widget.color]) {
      set(this, 'widget.color', DEPRECATED_COLORS_MAP[this.widget.color]);
    }
  }
}
