/* import __COLOCATED_TEMPLATE__ from './section-item-content.hbs'; */
import Component from '@glimmer/component';

import resize from 'teamtailor/utils/motions/resize';

export default class SectionItemComponent extends Component {
  constructor() {
    super(...arguments);

    this.motion = resize(() => {
      if (this.args.onResizeComplete) {
        this.args.onResizeComplete();
      }
    });
  }
}
