/* import __COLOCATED_TEMPLATE__ from './restricted-modal.hbs'; */
import Component from '@glimmer/component';

export default class RestrictedModalComponent extends Component {
  get candidateDisplayName() {
    const { nameOrEmail, anonymousName } = this.args.candidate;

    return this.args.anonymous ? anonymousName : nameOrEmail;
  }
}
