import Service, { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';

import CurrentService from 'teamtailor/services/current';

const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export default class TimeFormatService extends Service {
  @service declare current: CurrentService;
  @service declare intl: IntlService;

  defaultTimeFormat: string = 'HH:mm';

  get format() {
    return (
      this.current.user.timeFormat ||
      this.current.company.timeFormat ||
      this.defaultTimeFormat
    );
  }

  get locale() {
    return this.current.locale;
  }

  get formatForEmberFlatpickr() {
    return this.militaryFormat ? 'H:i' : 'h:i K';
  }

  get formatWithSeconds() {
    return this.addLongForm(this.format);
  }

  get militaryFormat() {
    return this.format === 'HH:mm';
  }

  get shortLocale() {
    const locale = this.locale === 'no' ? 'nb' : this.locale;
    return `${locale}-short`;
  }

  get allWeekdays() {
    return DAYS_OF_WEEK.map((day, index) => {
      return {
        id: index + 1,
        value: day,
        name: this.intl.t(`common.weekdays.${day}`),
      };
    });
  }

  get defaultWeekday() {
    return this.weekdayForLocale(this.locale);
  }

  get firstDayOfWeek() {
    return (
      this.allWeekdays.find(
        (day) => day.value === this.current.user.startOfWeekDay
      ) || this.defaultWeekday
    );
  }

  get flatPickrLocaleData() {
    const localeData = moment.localeData(this.locale);

    return {
      locale: this.locale,
      firstDayOfWeek: this.firstDayOfWeek.id,
      ordinal: localeData.ordinal,
      weekdays: {
        longhand: localeData.weekdays(),
        shorthand: localeData.weekdaysShort(),
      },

      months: {
        longhand: localeData.months(),
        shorthand: localeData.monthsShort(),
      },
    };
  }

  get orderedDaysOfWeek() {
    const firstDayOfWeekIndex = this.firstDayOfWeek.id - 1;
    return [
      ...DAYS_OF_WEEK.slice(firstDayOfWeekIndex),
      ...DAYS_OF_WEEK.slice(0, firstDayOfWeekIndex),
    ];
  }

  weekdayForLocale(locale: string) {
    const localeData = moment.localeData(locale);
    const firstWeekDayId = localeData.firstDayOfWeek();
    return (
      this.allWeekdays.find((day) => day.id === firstWeekDayId) ||
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.allWeekdays.at(-1)!
    );
  }

  addLongForm(format: string) {
    return format.replace(':mm', ':mm:ss');
  }
}

declare module '@ember/service' {
  interface Registry {
    'time-format': TimeFormatService;
  }
}
