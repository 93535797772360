/* import __COLOCATED_TEMPLATE__ from './select-locations.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { trackedFunction } from 'ember-resources/util/function';
import { get } from 'teamtailor/utils/get';

export default class extends Component {
  @service store;

  locationsFunction = trackedFunction(this, async () => {
    const pickedLocations = await get(this.args.promotion, 'pickedLocations');
    const nonDeletedPickedLocations = pickedLocations.filter(
      (pl) => !pl._destroy
    );
    if (nonDeletedPickedLocations.length) {
      const locations = await Promise.all(
        nonDeletedPickedLocations.map((pl) => get(pl, 'location'))
      );
      return locations;
    }

    return [];
  });

  get locations() {
    return this.locationsFunction.value;
  }

  get showLocationPicker() {
    if (this.args.isLocationPickerAlwaysVisible) {
      return true;
    }

    return get(this.args.promotion, 'job.locations.length') > 1;
  }

  processNewAndExistingPickedLocations(locations) {
    locations.forEach((location) => {
      const existingPickedLocation = this.args.promotion.pickedLocations.findBy(
        'location.id',
        get(location, 'id')
      );

      if (existingPickedLocation) {
        if (existingPickedLocation._destroy) {
          existingPickedLocation._destroy = false;
        }
      } else {
        this.store.createRecord('picked-location', {
          ownerType: 'Promotion',
          promotion: this.args.promotion,
          location,
        });
      }
    });
  }

  processPickedLocationsToDestroy(locations) {
    const pickedLocationsToDestroy = this.args.promotion.pickedLocations.filter(
      (pickedLocation) =>
        !locations.mapBy('id').includes(get(pickedLocation, 'location.id'))
    );

    pickedLocationsToDestroy.forEach((pickedLocation) => {
      if (pickedLocation.isNew) {
        pickedLocation.rollbackAttributes();
      } else {
        pickedLocation._destroy = true;
      }
    });

    return pickedLocationsToDestroy;
  }

  @action
  onChange(location) {
    const locations = (this.locations || []).slice();
    toggleInList(locations, location);
    this.processNewAndExistingPickedLocations(locations);
    this.processPickedLocationsToDestroy(locations);
  }
}
