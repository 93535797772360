/* import __COLOCATED_TEMPLATE__ from './salary-criteria-selection.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { data, CurrencyCodeRecord } from 'currency-codes';
import Intl from 'ember-intl/services/intl';
import { RequisitionFlowModel } from 'teamtailor/models';

type SalaryCriteriaSelectionArgs = {
  model?: RequisitionFlowModel;
};

type SalaryTimeUnit = {
  value: string;
  label: string;
};

export default class CriteriaSelection extends Component<SalaryCriteriaSelectionArgs> {
  currencyList: CurrencyCodeRecord[] = data;
  @service declare intl: Intl;

  get salaryTimeUnitOptions(): SalaryTimeUnit[] {
    return [
      {
        value: 'hourly',
        label: this.intl.t('jobs.job.salary.options.hourly'),
      },
      {
        value: 'daily',
        label: this.intl.t('jobs.job.salary.options.daily'),
      },
      {
        value: 'monthly',
        label: this.intl.t('jobs.job.salary.options.monthly'),
      },
      {
        value: 'yearly',
        label: this.intl.t('jobs.job.salary.options.yearly'),
      },
    ];
  }

  get selectedCurrencyObj(): CurrencyCodeRecord | undefined {
    return this.currencyList.find((c) => c.code === this.args.model?.currency);
  }

  @action
  updateCurrency(currency: CurrencyCodeRecord): void {
    if (this.args.model) {
      this.args.model.currency = currency.code;
    }
  }

  @action
  handleSalaryTimeUnitChange(timeUnit: SalaryTimeUnit): void {
    if (this.args.model) {
      this.args.model.salaryTimeUnit = timeUnit.value;
    }
  }
}
