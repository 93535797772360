/* import __COLOCATED_TEMPLATE__ from './video-picker.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { argDefault } from 'teamtailor/utils/arg-default';
import DirectUploadService from 'teamtailor/services/direct-upload';
import Store from '@ember-data/store';
import VideoModel from 'teamtailor/models/video';

interface VideoPickerComponentArgs {
  name: string;
  theme: string;
  ratioNumerator: number;
  ratioDenominator: number;
  overlayOpacity: number;
  overlayColor: string;
  type: string;
  video?: VideoModel;
  onPickVideo: (video: VideoModel, type: string) => void;
}

export default class VideoPicker extends Component<VideoPickerComponentArgs> {
  @service declare directUpload: DirectUploadService;
  @service declare store: Store;

  @tracked declare file: File;

  @argDefault name = 'video-picker';
  @argDefault theme = 'default';

  uploadToS3 = task(async (file) => {
    const fileUrl = await this.directUpload.s3(file, { model: 'videos' });
    const video = await this.store
      .createRecord('video', { file: fileUrl, transcodeType: this.args.type })
      .save();
    this.args.onPickVideo(video, this.args.type);
  });

  @action
  upload(file: File) {
    this.uploadToS3.perform(file);
  }
}
