/* import __COLOCATED_TEMPLATE__ from './interviewer-image.hbs'; */
import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';
import UserModel from 'teamtailor/models/user';
import PartnerModel from 'teamtailor/models/partner';

type InterviewerImageArgs = {
  user?: UserModel;
  partner?: PartnerModel;
  author?: {
    author: string;
  };
  interviewKitName?: string;
};

export default class InterviewerImage extends Component<InterviewerImageArgs> {
  get tooltipText(): string {
    const { user, author, partner, interviewKitName } = this.args;
    const interviewKitText = interviewKitName ? ` (${interviewKitName})` : '';

    if (user && get(user, 'nameOrEmail')) {
      return `${get(user, 'nameOrEmail')}${interviewKitText}`;
    } else if (partner && get(partner, 'name')) {
      return `${get(partner, 'name')}${interviewKitText}`;
    } else if (author) {
      return `${author.author}${interviewKitText}`;
    }

    return '';
  }
}
