/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask, timeout } from 'ember-concurrency';
import { action, get, set } from '@ember/object';
import PerfectScrollbar from 'perfect-scrollbar';
import { once, next } from '@ember/runloop';
import classnames from 'teamtailor/utils/classnames';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import { compare } from '@ember/utils';

const SCOPE_FILTERS = ['my', 'all'];

const FILTERS = [
  {
    value: 'all',
    translationKey: 'components.toolbox.messages.filters.all',
  },
  {
    value: 'unread',
    translationKey: 'components.toolbox.messages.filters.unread',
  },
  {
    value: 'unanswered',
    translationKey: 'components.toolbox.messages.filters.no_replies',
  },
  {
    value: 'archived',
    translationKey: 'components.toolbox.messages.filters.archived',
  },
];

export default class extends Component {
  @service pusher;
  @service store;
  @service user;
  @service intl;
  @service conversationQuery;

  perfectScrollbar = null;
  channel = null;
  scopeFilters = SCOPE_FILTERS;
  statusFilters = FILTERS;
  @tracked searchString = null;

  constructor(...args) {
    super(...args);
    this.setupLocaleMoment();
  }

  get conversations() {
    return this.conversationQuery.conversations;
  }

  get sortedConversations() {
    return this.conversations.sort((a, b) => {
      return compare(b.messageSentAt, a.messageSentAt);
    });
  }

  get hasSearched() {
    return !!this.searchString;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.perfectScrollbar) {
      this.perfectScrollbar.destroy();
      this.perfectScrollbar = null;
    }
  }

  setupLocaleMoment() {
    const userLocale = moment.locale();
    const inboxLocale = `${userLocale}-inboxLocale`;
    if (!moment.locales().includes(inboxLocale)) {
      moment.defineLocale(inboxLocale, {
        parentLocale: userLocale,
      });

      this.updateLocale(inboxLocale);
    }

    const localeMoment = moment();
    localeMoment.locale(inboxLocale);
  }

  updateLocale(inboxLocale) {
    moment.updateLocale(inboxLocale, {
      relativeTime: {
        s: this.intl.t('common.just_now'),
        ss: this.intl.t('common.just_now'),
        m: this.intl.t('common.just_now'),
        mm: this.intl.t('common.today'),
        h: this.intl.t('common.today'),
        hh: this.intl.t('common.today'),
        d: this.intl.t('common.yesterday'),
        dd: (number) => {
          if (number < 7) {
            return this.intl.t('common.x_days', { count: number });
          } else {
            return this.intl.t('common.x_weeks', {
              count: Math.round(number / 7),
            });
          }
        },

        M: this.intl.t('common.last_month'),
        MM: '%d months',
        y: this.intl.t('common.last_year'),
        yy: '%d years',
      },
    });
  }

  get searchInputClass() {
    return classnames('searchInput', {
      active: this.hasSearched,
    });
  }

  async setupPusher() {
    try {
      this.channel = await this.pusher.subscribe(
        get(this, 'user.notificationsChannel')
      );
    } catch {
      return;
    }

    this.channel.bind('updated-conversation', async (data) => {
      const {
        conversation: { id },
      } = data;

      const conversationItem = this.conversations?.find(
        (item) => get(item, 'id') === `${id}`
      );
      const scrollToTop =
        this.perfectScrollbar && this.perfectScrollbar.element.scrollTop === 0;
      if (conversationItem) {
        this.store.pushPayload(data);
      } else {
        const conversations = await this.store.query('conversation', {
          id: `${id}`,
          scope: this.args.selectedScopeFilter,
          status: this.args.selectedStatusFilter,
        });

        if (conversations[0] && this.conversationQuery.conversations) {
          this.conversationQuery.conversations = [
            ...this.conversationQuery.conversations,
            conversations[0],
          ];
        }
      }

      if (scrollToTop) {
        next(() => {
          this.perfectScrollbar.element.scrollTop = 0;
        });
      }
    });
  }

  teardownPusher() {
    if (this.channel) {
      this.channel.unbind('updated-conversation');
    }
  }

  refetchConversations() {
    this.scrollContainerElement.scrollTop = 0;
    this.conversationQuery.page = 1;
    this.queryConversations();
  }

  searchTask = restartableTask(async (value) => {
    await timeout(150);
    this.conversationQuery.page = 1;
    await this.queryConversations(value);
  });

  async queryConversations(value) {
    this.searchString = value;
    return this.conversationQuery.fetchConversationsTask.perform(
      this.args.selectedScopeFilter,
      this.args.selectedStatusFilter,
      value
    );
  }

  @action
  async onOpenChanged() {
    if (this.args.isOpen) {
      await this.queryConversations();
      once(this, 'setupPusher');
    } else {
      once(this, 'teardownPusher');
    }
  }

  @action
  bindScrollElement(element) {
    this.scrollContainerElement = element;
    const ps = new PerfectScrollbar(element, {
      wheelPropagation: true,
      suppressScrollX: true,
    });

    set(this, 'perfectScrollbar', ps);
  }

  @action
  loadNextPage() {
    if (!this.conversationQuery.fetchConversationsTask.isRunning) {
      this.queryConversations();
    }
  }

  @action
  filterChanged(e, props) {
    const [isStatusFilter, filter] = props;
    const fetchedFilter = isStatusFilter
      ? this.conversationQuery.fetchedStatusFilter
      : this.conversationQuery.fetchedScopeFilter;

    if (fetchedFilter !== filter) {
      this.refetchConversations();
    }
  }

  @action
  handleConversationAction(conversation, memberAction) {
    this.conversationQuery.handleConversationAction(conversation, memberAction);
  }

  @action
  handleConversationReassigned(conversation) {
    this.conversationQuery.handleConversationReassigned(conversation);
  }
}
