/* import __COLOCATED_TEMPLATE__ from './single-sign-on.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service declare intl: IntlService;

  get options() {
    return [
      {
        id: null,
        label: this.intl.t('search.single_sign_on.all_users'),
      },
      {
        id: 'true',
        label: this.intl.t('search.single_sign_on.single_sign_on_activated'),
      },
      {
        id: 'false',
        label: this.intl.t('search.single_sign_on.single_sign_on_inactive'),
      },
    ];
  }
}
