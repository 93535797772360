/* import __COLOCATED_TEMPLATE__ from './qr-code-viewer.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

let QRCodeStyling = null;
const DEFAULT_SIZE = 180;
const DEFAULT_DOT_COLOR = '#34353a'; // base-grey-78
export default class QrCodeCreatorComponent extends Component {
  element = null;
  qrCode = null;

  get styleSettings() {
    return {
      width: this.args.size || DEFAULT_SIZE,
      height: this.args.size || DEFAULT_SIZE,
      margin: 1,
      type: 'svg',
      data: this.args.value,
      image: this.args.useLogo && this.args.logo ? this.args.logo : null,

      dotsOptions: {
        color: DEFAULT_DOT_COLOR,
        type: this.args.isRound ? 'rounded' : 'square',
      },

      backgroundOptions: {
        color: '#ffffff',
      },

      cornersSquareOptions: {
        color: this.args.accentColor || DEFAULT_DOT_COLOR,
        type: this.args.isRound ? 'extra-rounded' : 'square',
      },

      cornersDotOptions: {
        color: DEFAULT_DOT_COLOR,
        type: this.args.isRound ? 'dot' : 'square',
      },

      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 2,
      },

      qrOptions: {
        errorCorrectionLevel: 'Q',
      },
    };
  }

  get displayCode() {
    return !this.args.hideCode;
  }

  createQrCode() {
    this.qrCode = new QRCodeStyling(this.styleSettings);

    if (this.displayCode) {
      this.qrCode.append(this.element);
    }

    if (this.args.onRegisteredQrCode) {
      this.args.onRegisteredQrCode(this.qrCode);
    }
  }

  updateQrCode() {
    if (this.args.refreshOnUpdate) {
      this.createQrCode();
    } else {
      this.qrCode.update(this.styleSettings);
    }
  }

  initialize = task(async () => {
    const module = await import('qr-code-styling');
    QRCodeStyling = module.default;
    this.createQrCode();
  });

  @action
  handleInsertElement(element) {
    this.element = element;
    this.initialize.perform();
  }

  @action
  handleUpdate() {
    this.updateQrCode();
  }
}
