/* import __COLOCATED_TEMPLATE__ from './share-activities.hbs'; */
import { gql } from '@apollo/client/core';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ApolloService from 'ember-apollo-client/services/apollo';
import { task, TaskGroup, taskGroup } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import PROMOTIONS from 'teamtailor/gql/promotions.graphql';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import InsightsService from 'teamtailor/services/insights';
import { tracked } from 'tracked-built-ins';
import WidgetClass from './base/widget-class';

const USER_PROMOTIONS = gql`
  query ($id: ID!, $userId: ID!) {
    user(userScope: { userId: $userId }, id: $id) {
      id

      promotions {
        id
        url

        job {
          title
          image {
            avatar
          }
        }
      }
    }
  }
`;

type PromotionWithStat = {
  model: Promotion;
  pageViews: number;
  applications: number;
  hired: number;
};

type Response = {
  applications: {
    aggregated: {
      promotionId: string;
      appliedCount: number;
      hiredCount: number;
    }[];
  };
  pageViews: {
    aggregated: {
      count: number;
      distinctCount: number;
      promotionId: string;
    }[];
  };
};

type Promotion = {
  id: string;
  job: {
    title: string;
    image?: {
      avatar: string;
    };
  };
};

type UserResponse = {
  user: {
    id: string;
    promotions: Promotion[];
  };
};

export default class DashboardWidgetsShareActivities extends WidgetClass {
  @service declare insights: InsightsService;
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare apollo: ApolloService;

  @tracked promotionsWithStats: PromotionWithStat[] = [];
  @taskGroup declare fetchGroup: TaskGroup<unknown>;
  @tracked showAll = false;

  slice = 5;
  showMoreButtonAt = 6;

  constructor(owner: unknown, args: unknown) {
    super(owner, args);

    this.fetchData.perform();
  }

  get promotionsWithStatsSegment() {
    if (!this.showAll) {
      if (this.promotionsWithStats.length > this.showMoreButtonAt) {
        return this.promotionsWithStats.slice(0, this.slice);
      }

      return this.promotionsWithStats.slice(0, this.showMoreButtonAt);
    }

    return this.promotionsWithStats;
  }

  get showFooter() {
    return (
      this.promotionsWithStats.length > this.showMoreButtonAt &&
      !this.showAll &&
      !this.fetchGroup.isRunning
    );
  }

  fetchData = task(async () => {
    const startDate = this.current.user.createdAt;
    const endDate = moment().add(1, 'day').toDate();

    const userPromotions: Promotion[] =
      await this.fetchUserPromotions.perform();

    if (userPromotions.length) {
      const data = await this.fetchAggregatedData.perform(
        userPromotions.mapBy('id'),
        startDate,
        endDate
      );

      this.promotionsWithStats = this.mapPromotionsWithStats(
        userPromotions,
        data
      )
        .sortBy('pageViews')
        .rejectBy('pageViews', 0)
        .reverse();
    }
  });

  fetchUserPromotions = task({ group: 'fetchGroup' }, async () => {
    const data: UserResponse = await this.apollo.query({
      query: USER_PROMOTIONS,
      variables: {
        id: this.current.user.id,
      },
    });

    return data.user.promotions;
  });

  fetchAggregatedData = task(
    { group: 'fetchGroup' },
    async (promotionIds: Promotion['id'][], startDate: Date, endDate: Date) => {
      return this.insights.query<Response>({
        query: PROMOTIONS,
        variables: {
          dateRange: {
            startDate,
            endDate,
          },

          promotionIds,
        },
      });
    }
  );

  mapPromotionsWithStats(
    promotions: Promotion[],
    data: Response
  ): PromotionWithStat[] {
    return promotions.map((promotion) => {
      const pageViews =
        data.pageViews.aggregated.find(
          (aggregated) => aggregated.promotionId === promotion.id
        )?.distinctCount || 0;

      const applications =
        data.applications.aggregated.find(
          (aggregated) => aggregated.promotionId === promotion.id
        )?.appliedCount || 0;

      const hired =
        data.applications.aggregated.find(
          (aggregated) => aggregated.promotionId === promotion.id
        )?.hiredCount || 0;

      return {
        model: promotion,
        pageViews,
        applications,
        hired,
      };
    });
  }

  @action
  toggleShowAll(event: MouseEvent) {
    event.preventDefault();
    this.showAll = !this.showAll;
  }

  @action
  copyToClipboard(url: string) {
    navigator.clipboard.writeText(url);
    this.flashMessages.success(
      this.intl.t('common.share_link_copied_to_clipboard')
    );
  }
}
