/* import __COLOCATED_TEMPLATE__ from './form-expandable-box.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class FormExpandableBoxComponent extends Component {
  @tracked isOpen = false;
  @argDefault canClose = true;
  @argDefault canOpen = true;
  @argDefault canMove = true;
  @argDefault showSaveButton = true;
  @argDefault showIcon = true;

  constructor() {
    super(...arguments);
    this.isOpen = this.args.openFromStart;
  }

  @action
  onDidUpdate() {
    this.isOpen = this.args.openFromStart;
  }

  @action
  toggleOpen(e) {
    e.stopPropagation();

    if (!this.canOpen) return;

    if (this.canClose) {
      this.isOpen = !this.isOpen;
      if (this.isOpen && this.args.onOpen) {
        this.args.onOpen();
      }
    }
  }

  @action
  close() {
    this.isOpen = false;
  }
}
