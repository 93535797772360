/* import __COLOCATED_TEMPLATE__ from './approval-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type ApprovalModalArgs = {
  isOpen: boolean;
  onClose: () => void;
  onProcessApproval: (
    comment: string | undefined,
    isApproved: boolean
  ) => Promise<void>;
  type: 'APPROVE' | 'DENY';
};

export default class ApprovalModal extends Component<ApprovalModalArgs> {
  @tracked
  comment: string = '';

  get title() {
    return this.args.type === 'APPROVE'
      ? 'requisitions.approval_modal_title'
      : 'requisitions.denial_modal_title';
  }

  get commentLabel() {
    return this.args.type === 'APPROVE'
      ? 'requisitions.add_comment_for_approval'
      : 'requisitions.why_you_deny';
  }

  get confirmButtonText() {
    if (!this.comment.length) {
      return this.args.type === 'APPROVE'
        ? 'requisitions.approve_without_comment'
        : 'requisitions.deny_without_feedback';
    }

    return this.args.type === 'APPROVE'
      ? 'requisitions.approve_with_comment'
      : 'requisitions.deny_with_feedback';
  }

  resetComment() {
    this.comment = '';
  }

  @action
  async onConfirm() {
    if (this.args.type === 'APPROVE') {
      await this.args.onProcessApproval(this.comment, true);
    } else {
      await this.args.onProcessApproval(this.comment, false);
    }

    this.resetComment();
  }
}
