/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { action } from '@ember/object';

export default class TextBaseComponent extends BaseComponent {
  @action
  handleRemoveImage() {
    this.args.model.pickedImages.lastObject.deleteRecord();
    this.args.model.pickedImages = [];
    this.filthyContent.setFilthy(this.args.model.id);
  }

  @action
  handlePickImage(image) {
    const { model } = this.args;
    const isNew = this.args.model.pickedImages.length === 0;
    let pickedImage =
      this.args.model.pickedImages.firstObject ||
      this.args.model.pickedImages.createRecord('picked-image');

    pickedImage.section_id = model.id;
    pickedImage.image = image;

    if (isNew) {
      pickedImage.save().then(() => {
        this.filthyContent.setFilthy(this.args.model.id);
      });
    } else {
      this.filthyContent.setFilthy(this.args.model.id);
    }
  }
}
