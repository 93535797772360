/* import __COLOCATED_TEMPLATE__ from './toggle-item.hbs'; */
import Component from '@glimmer/component';
import { action, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import AutomaticDeletionSettingModel from 'teamtailor/models/automatic-deletion-setting';

export type Args = {
  model: AutomaticDeletionSettingModel;
  toggleAttr: keyof AutomaticDeletionSettingModel;
  daysAttr: keyof AutomaticDeletionSettingModel;
  toggleLabel: string;
  descTextPart1: string;
  descTextPart2: string;
  helpText: string;
  isDisabled: boolean;
};

export default class CounterComponent extends Component<Args> {
  @service declare intl: IntlService;

  get toggleValue(): boolean {
    return get(this.args.model, this.args.toggleAttr) as boolean;
  }

  get daysValue(): number {
    return get(this.args.model, this.args.daysAttr) as number;
  }

  get daysText(): string {
    return this.convertDaysToText(this.daysValue);
  }

  get daysOptions() {
    const selectableDays = [1, 7, 14, 30, 180];
    return selectableDays.map((days) => {
      return {
        days,
        text: this.convertDaysToText(days),
      };
    });
  }

  convertDaysToText(days: number) {
    if (!days) return this.intl.t('settings.automatic_deletion.selectDays');
    else if (days === 7 || days === 14) {
      return this.intl.t('common.x_weeks', { count: days / 7 });
    } else if (days === 30 || days === 180) {
      return this.intl.t('common.x_months', { count: days / 30 });
    } else {
      return this.intl.t('common.x_days', { count: days });
    }
  }

  @action
  updateToggle(value: boolean): void {
    set(this.args.model, this.args.toggleAttr, !value);
    if (!value) this.updateDays(undefined);
  }

  @action
  updateDays(value: number | undefined): void {
    set(this.args.model, this.args.daysAttr, value);
  }
}
