/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import fuzzysort from 'fuzzysort';

export default class HeaderComponent extends Component {
  @service store;
  @service iframePreview;

  @tracked draggingEnabled = false;
  @tracked unfilteredAvailablePagePublications = [];
  @tracked pageSearchTerm = '';

  constructor() {
    super(...arguments);
    this.loadAvailablePagePublications();
  }

  get availablePagePublications() {
    if (isEmpty(this.pageSearchTerm)) {
      return this.unfilteredAvailablePagePublications.sortBy(
        'navigation_title'
      );
    } else {
      return fuzzysort
        .go(this.pageSearchTerm, this.unfilteredAvailablePagePublications, {
          key: 'navigation_title',
        })
        .mapBy('obj')
        .sortBy('navigation_title');
    }
  }

  @action
  setPageSearchTerm(event) {
    this.pageSearchTerm = event.target.value;
  }

  @action
  async loadAvailablePagePublications() {
    const { page_publications: pagePublications } =
      await this.args.model.availablePagePublications();
    this.unfilteredAvailablePagePublications = pagePublications;
  }

  @action
  async handleNewNavigationItem(pagePublicationId) {
    const pagePublication = await this.store.findRecord(
      'page-publication',
      pagePublicationId
    );
    const navigationItem = await this.store.createRecord('navigation-item', {
      pagePublication,
      careerSite: pagePublication.careerSite,
      careerSiteHeader: this.args.model,
    });
    await navigationItem.save();
    this.loadAvailablePagePublications();
    this.iframePreview.reloadIframe();
  }

  @action
  async handleSectionItemDragEnd({ draggedItem, sourceIndex, targetIndex }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    draggedItem.rowOrderPosition =
      targetIndex > sourceIndex ? targetIndex + 1 : targetIndex;

    await draggedItem.save();
    this.iframePreview.reloadIframe();
  }
}
