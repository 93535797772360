/* import __COLOCATED_TEMPLATE__ from './trigger-smart-schedule-form.hbs'; */
import TriggerFormBaseComponent from 'teamtailor/components/trigger-form-base';
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import { task } from 'ember-concurrency';
import {
  durations,
  intervalOptions,
  bufferOptions,
} from 'teamtailor/constants/calendar';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import moment from 'moment-timezone';

export default class TriggerSmartScheduleForm extends TriggerFormBaseComponent {
  @service intl;
  @service store;
  @service current;
  @service timeFormat;

  durations = durations;
  intervalOptions = intervalOptions;
  bufferOptions = bufferOptions;

  @tracked bookableMeetingRooms = null;
  @tracked _locations = null;
  @tracked _moveToStage = false;
  @tracked selectedLocation = null;
  @tracked connectedConferences = null;
  @tracked selectedResponse = null;

  get model() {
    return this.args.model;
  }

  get user() {
    return this.current.user;
  }

  get company() {
    return this.current.company;
  }

  get job() {
    return this.model.job;
  }

  get moveToStage() {
    return this._moveToStage || !!get(this.model, 'proceedStage.id');
  }

  get cannedResponses() {
    const company = get(this, 'company');
    const job = get(this, 'job');

    let context = null;
    if (job) {
      context = get(job, 'assetStructureContext');
    } else {
      const departmentId = get(this.user, 'departmentId');
      context = {
        department: departmentId,
        role: null,
        region: null,
        location: null,
      };
    }

    return company.fetchGroupedCannedResponsesByContext(context);
  }

  get attendeesError() {
    if (
      get(this, 'model.users.length') &&
      !get(this, 'model.haveCronofyUser')
    ) {
      return [
        {
          message: this.intl.t(
            'components.trigger_smart_schedule_form.user_must_be_selected'
          ),
        },
      ];
    }
  }

  get stageOptions() {
    const stages = get(this.args.job, 'stages');
    return stages.map((stage) => {
      const id = get(stage, 'id');
      const isDisabled = id ? id === get(this.model, 'stage.id') : true;

      return {
        id,
        name: get(stage, 'name'),
        isDisabled,
        stage,
      };
    });
  }

  get requiredAttendeeOptions() {
    return [
      { label: this.intl.t('common.all'), value: 'all' },
      { label: 1, value: 1 },
      { label: 2, value: 2 },
    ];
  }

  constructor() {
    super(...arguments);

    this.fetchMeetingRooms.perform();
    this.fetchLocations.perform();
    this.fetchConnectedConferences();
  }

  fetchConnectedConferences() {
    if (!get(this, 'model.organizer.id')) {
      return;
    }

    get(this, 'store')
      .query('connected-conference', {
        user_id: get(this, 'model.organizer.id'),
      })
      .then((connectedConferences) => {
        this.connectedConferences = connectedConferences;
        const providerName = get(this, 'model.providerName');

        if (providerName) {
          const selectedConnectedConference = connectedConferences.findBy(
            'providerName',
            providerName
          );
          set(this, 'model.videoProvider', selectedConnectedConference);
          this.selectedLocation = selectedConnectedConference;
        }
      });
  }

  fetchMeetingRooms = task(async () => {
    await get(this, 'store')
      .findAll('meeting-room')
      .then((meetingRooms) => {
        this.bookableMeetingRooms = meetingRooms.filterBy('bookable', true);
      });
  });

  fetchLocations = task(async () => {
    if (get(this, 'model.location')) {
      await get(this, 'store')
        .findAll('location')
        .then((locations) => {
          const location = locations.findBy(
            'fullAddress',
            get(this, 'model.location')
          );

          this.selectedLocation = location || get(this, 'model.location');
        });
    }
  });

  setUploadsFromCannedResponse() {
    const uploads = this.selectedResponse.uploads.filter((upload) => {
      return (
        !upload.languageCode ||
        upload.languageCode === get(this.job, 'languageCode')
      );
    });
    set(this, 'model.uploads', uploads);
  }

  @action
  toggleUser(user) {
    const users = get(this, 'model.users');

    if (users.includes(user)) {
      users.removeObject(user);
    } else {
      users.pushObject(user);
    }

    if (!users.includes(get(this, 'model.organizer'))) {
      set(this, 'model.organizer', get(this, 'model.cronofyUsers.firstObject'));
      this.fetchConnectedConferences();
    }
  }

  @action
  changeUsers(selectedUsers) {
    set(this, 'model.users', selectedUsers);
    if (!selectedUsers.includes(get(this, 'model.organizer'))) {
      set(this, 'model.organizer', get(this, 'model.cronofyUsers.firstObject'));
      this.fetchConnectedConferences();
    }
  }

  @action
  changeConnectedConference(selectedConnectedConference) {
    set(this, 'model.videoProvider', selectedConnectedConference);
    set(this, 'model.providerName', selectedConnectedConference.providerName);
  }

  @action
  didSelectCannedResponse(response) {
    set(this, 'selectedResponse', response);
    if (get(response, 'languageCode') === get(this, 'job.languageCode')) {
      set(this, 'model.summary', get(response, 'subject'));
      set(this, 'model.eventDescription', get(response, 'body'));
    } else {
      get(response, 'translations').then((translations) => {
        const translatedResponse =
          translations.findBy('languageCode', get(this, 'job.languageCode')) ||
          response;

        set(this, 'model.summary', get(translatedResponse, 'subject'));
        set(this, 'model.eventDescription', get(translatedResponse, 'body'));
      });
    }

    this.setUploadsFromCannedResponse();
  }

  @action
  changeOrganizer(user) {
    set(this, 'model.organizer', user);
  }

  @action
  handleChangeTime(type, date) {
    const time = moment(date).format(this.timeFormat.format);
    this.model[type] = time;
  }

  @action
  changeTimeZone(timeZone) {
    set(this.model, 'timeZone', timeZone);
  }

  @action
  toggleCandidateReminder() {
    set(this.model, 'candidateReminder', !get(this.model, 'candidateReminder'));
  }

  @action
  selectProceedStage({ stage }) {
    set(this.model, 'proceedStage', stage);
  }

  @action
  toggleMoveToStage(moveToStage) {
    if (!moveToStage) {
      set(this.model, 'proceedStage', undefined);
    }

    this._moveToStage = moveToStage;
  }

  @action
  setLocation(type, location) {
    if (this.selectedLocation === location) {
      set(this, 'model.location', null);
      set(this, 'model.providerName', null);
      this.selectedLocation = null;
    } else {
      this.selectedLocation = location;
      switch (type) {
        case 'location':
          set(this, 'model.location', get(location, 'fullAddress'));
          set(this, 'model.providerName', null);
          set(this, 'model.hasVideo', false);
          break;
        case 'video':
          set(this, 'model.hasVideo', true);
          set(this, 'model.videoProvider', location);
          set(this, 'model.providerName', location.providerName);
          set(this, 'model.location', null);
          break;
        case 'custom':
        default:
          set(this, 'model.location', location);
          set(this, 'model.providerName', null);
          set(this, 'model.hasVideo', false);
          break;
      }
    }
  }

  @action
  setRequiredAttendees(option) {
    this.model.requiredAttendees = option.value;
  }
}
