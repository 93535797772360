/* import __COLOCATED_TEMPLATE__ from './hidden-input.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { timeout } from 'ember-concurrency';
import moment from 'moment-timezone';
import { get } from 'teamtailor/utils/get';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import { isEmpty } from '@ember/utils';
import { EkEvent, KeyboardService, keyResponder } from 'ember-keyboard';
import { inject as service } from '@ember/service';

type Value = string | Record<string, unknown>;

type HiddenInputSignature = {
  Args: {
    inputClass?: string;
    optionName?: string;
    inputType?: Value;
    value?: string | Record<string, unknown>;
    onChange: (value: Value) => void;
  };
};

type IntlTelInputElement = HTMLInputElement & { open(): void };

@keyResponder
export default class HiddenInput extends Component<HiddenInputSignature> {
  @service declare keyboard: KeyboardService;

  @tracked showInput = false;
  @tracked inputElement?: IntlTelInputElement | HTMLElement;
  @tracked inputValue?: Value;
  @tracked invalid: string | boolean = false;

  get dropdownContainer() {
    return document.body;
  }

  onPhoneInputInsert = modifier((el: IntlTelInputElement, [inputType]) => {
    if (inputType !== 'phone') {
      return;
    }

    el.addEventListener(
      'open:countrydropdown',
      this.handleCountrydropdownToggle
    );
    el.addEventListener(
      'close:countrydropdown',
      this.handleCountrydropdownToggle
    );
    return () => {
      el.removeEventListener(
        'open:countrydropdown',
        this.handleCountrydropdownToggle
      );
      el.removeEventListener(
        'close:countrydropdown',
        this.handleCountrydropdownToggle
      );
    };
  });

  @tracked phoneEscapePriority?: number;

  phoneCountryInputIsOpen = false;

  @action handleCountrydropdownToggle(event: Event) {
    if (event.type === 'open:countrydropdown') {
      const highestPriorityResponder = this.keyboard.sortedResponders[0];

      if (highestPriorityResponder?.keyboardPriority !== undefined) {
        this.phoneEscapePriority =
          highestPriorityResponder.keyboardPriority + 1;
      }
    } else {
      this.phoneEscapePriority = undefined;
    }

    this.phoneCountryInputIsOpen = event.type === 'open:countrydropdown';
  }

  onPhoneEscape = (_event: KeyboardEvent, ekEvent: EkEvent) => {
    if (this.phoneCountryInputIsOpen) {
      ekEvent.stopPropagation();
    }
  };

  get inputType() {
    return this.args.inputType;
  }

  get inputClasses() {
    let classes =
      'body-text-s border border-transparent rounded-8 px-10 py-4 no-global-styles focus:outline-none';

    if (this.inputType === 'date') {
      classes += ' border-0';
    }

    if (!this.showInput) {
      classes += ' hidden';
    } else {
      classes.replace(' hidden', '');
    }

    if (this.args.inputClass) {
      classes += ` ${this.args.inputClass}`;
    }

    return classes;
  }

  get formattedValue() {
    if (this.inputType === 'date') {
      return moment(this.args.value).format('DD MMMM YYYY');
    } else if (this.inputType === 'select') {
      return get(
        this.args.value,
        this.args.optionName as keyof typeof this.args.value
      );
    } else if (this.inputType === 'formattedText') {
      return htmlSafe(this.args.value as string);
    } else {
      return this.args.value;
    }
  }

  @action
  registerInput(element: HTMLElement | IntlTelInputElement) {
    this.inputElement = element;
  }

  @action
  inputLostFocus() {
    this.showInput = false;
  }

  @action
  optionSelected(option: Value) {
    this.showInput = false;
    this.args.onChange(option);
  }

  @action
  pickDate(value: string) {
    const date = moment.utc(value).format('YYYY-MM-DD');
    this.showInput = false;
    this.args.onChange(date);
  }

  @action
  async focusInput() {
    this.showInput = true;
    await timeout(300);

    if (this.inputElement) {
      if (this.inputType === 'formattedText') {
        const element = this.inputElement.querySelector('.redactor-in');
        if (element instanceof HTMLElement) {
          element.focus();
        }
      } else if (this.inputType === 'date') {
        (this.inputElement as IntlTelInputElement).open();
      } else if (this.inputType === 'phone') {
        const element = this.inputElement.querySelector('input');
        if (element instanceof HTMLElement) {
          element.focus();
        }
      } else {
        this.inputElement.focus();
      }
    }
  }

  @action
  handlePhoneInputFocusOut(event: FocusEvent) {
    if (
      this.inputElement?.contains(event.relatedTarget as IntlTelInputElement)
    ) {
      return;
    }

    if (this.inputValue !== this.args.value) {
      this.args.onChange(this.inputValue!);
    }

    this.inputLostFocus();
  }

  @action
  handlePhoneInputChange(number: string) {
    if (this.inputValue !== number) {
      this.inputValue = number;
    }
  }

  handlePhoneInput = modifier((element, [value]: [Value]) => {
    const input = element.querySelector('.iti input') as
      | HTMLInputElement
      | undefined;
    if (input && isEmpty(value)) {
      input.value = '';
    }

    this.inputValue = value;
  });
}
