/* import __COLOCATED_TEMPLATE__ from './filter-form.hbs'; */
import Component from '@glimmer/component';
import {
  FilterOperator,
  FilterValue,
  IFilter,
  IOperator,
} from 'teamtailor/components/fancy-filters';
import classnames from 'teamtailor/utils/classnames';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce, next } from '@ember/runloop';
import { scrollToPromise } from 'teamtailor/utils/scroll-to-promise';

interface FilterFormArgs {
  headingBarIsStuck?: boolean;
  filter: IFilter;
  appearance: string;
  instantSearch: boolean;
  onDelete: () => void;
}

export default class extends Component<FilterFormArgs> {
  @tracked _value?: FilterValue;
  @tracked operator?: FilterOperator;
  @tracked showInvalidState = false;
  @tracked showTooltip = false;
  @tracked isInputOpen = false;

  get value(): FilterValue | undefined {
    return (
      (this.operator as IOperator | undefined)?.formatValue?.(this._value) ||
      this._value
    );
  }

  set value(value: FilterValue | undefined) {
    this._value = value;
  }

  get triggerClasses(): string {
    return classnames('trigger', {
      'trigger--valid': !this.showInvalidState,
      'trigger--invalid': this.showInvalidState,
      [`trigger--${this.args.appearance}`]: this.args.appearance,
    });
  }

  get hideInput(): boolean | undefined {
    return (this.operator as IOperator | undefined)?.hideInput;
  }

  get isSecondary(): boolean {
    return this.args.appearance === 'secondary';
  }

  get filterType(): string | undefined {
    return (
      (this.operator as IOperator | undefined)?.filterType ||
      this.args.filter.filterType
    );
  }

  updateSearchValue(): void {
    this.args.filter.value = this.value ?? '';
  }

  @action
  checkForOverflow(element: HTMLElement): void {
    if (element.offsetWidth < element.scrollWidth) {
      this.showTooltip = true;
      element.classList.add('text-ellipsis');
    }
  }

  @action
  async handleUpdateValue(value: FilterValue) {
    if (this.args.headingBarIsStuck) {
      await scrollToPromise(0);
    }

    this.value = value;

    if (this.args.instantSearch) {
      debounce(this, 'updateSearchValue', 300);
    }
  }

  @action
  handleUpdateOperator(operator: FilterOperator): void {
    this.operator = operator;
    if (this.args.instantSearch) {
      this.args.filter.selectedOperator = this.operator;
    }
  }

  @action
  setDefaultValues(): void {
    this.value = this.args.filter.value;
    this.operator = this.args.filter.selectedOperator;
  }

  @action
  handleDone(next: () => void): void {
    const newVal = this.value ?? '';
    if (this.args.filter.value !== newVal) {
      this.args.filter.value = this.value ?? '';
    }

    if (this.args.filter.selectedOperator !== this.operator) {
      this.args.filter.selectedOperator = this.operator;
    }

    this.args.filter.isOpen = false;

    next();
  }

  @action
  handleUpdateValidationState(isOpen: boolean): void {
    // We only want to validate once the dropdown is being closed.
    if (isOpen) return;

    this.showInvalidState = !this.args.filter.isValid;
  }

  @action
  didInsert(menu: { api: { isOpen: boolean; open(): void } }) {
    if (!menu.api.isOpen) {
      next(() => {
        menu.api.open();
      });
    }
  }
}
