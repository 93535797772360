/* import __COLOCATED_TEMPLATE__ from './meetings-chart.hbs'; */
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import AnalyticsService from 'teamtailor/services/analytics';
import {
  MeetingIntervalType,
  MeetingStatsTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import { getDays, getMonths, getYears } from 'teamtailor/utils/date';

type ValuePerInterval = MeetingStatsTypeResponse['meetingsPerInterval'][0];

type Args = {
  data: ValuePerInterval[];
  intervalType: MeetingIntervalType;
  dataType: 'meetingsPerInterval' | 'durationPerInterval';
};

export default class MeetingsChart extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare analytics: AnalyticsService;

  get startDate() {
    return moment(this.analytics.startDate);
  }

  get intervalType() {
    return this.args.intervalType;
  }

  get dataType() {
    return this.args.dataType;
  }

  get color() {
    return this.dataType === 'durationPerInterval' ? '#A855F7' : undefined;
  }

  get chartData() {
    let counts: number[] = [];

    if (this.intervalType === 'year') {
      counts = this.valueArrayPerYear;
    }

    if (this.intervalType === 'month') {
      counts = this.valueArrayPerMonth;
    }

    if (this.intervalType === 'day') {
      counts = this.valueArrayPerDay;
    }

    return [
      {
        color: this.color,
        data: counts,
      },
    ];
  }

  get valueArrayPerYear() {
    const { data } = this.args;
    const years = getYears(this.startDate, moment());

    const counts = years.map((year) => {
      const matchingObj = data.find((m) => m.year === parseInt(year));

      return matchingObj ? matchingObj.count : 0;
    });

    return counts;
  }

  get valueArrayPerDay() {
    const { data } = this.args;
    const days = getDays(this.startDate, moment());

    const counts = days.map((date) => {
      const day = parseInt(date.format('DD'));
      const month = parseInt(date.format('MM'));
      const year = parseInt(date.format('YYYY'));

      const matchingObj = data.find((m) => {
        return m.day === day && m.month === month && m.year === year;
      });

      return matchingObj ? matchingObj.count : 0;
    });

    return counts;
  }

  get valueArrayPerMonth() {
    const { data } = this.args;
    const months = getMonths(this.startDate, moment());

    const counts = months.map((date) => {
      const month = parseInt(date.format('MM'));
      const year = parseInt(date.format('YYYY'));

      const matchingObj = data.find((m) => {
        return m.month === month && m.year === year;
      });

      return matchingObj ? matchingObj.count : 0;
    });

    return counts;
  }

  get chartOptions() {
    let categories: string[] = [];

    const { intl, intervalType, dataType } = this;

    if (intervalType === 'year') {
      categories = getYears(this.startDate, moment());
    }

    if (intervalType === 'month') {
      const months = getMonths(this.startDate, moment());
      categories = months.map((month) => month.format('MMM'));
    }

    if (intervalType === 'day') {
      const dates = getDays(this.startDate, moment());
      categories = dates.map((date) => date.format('DD MMM'));
    }

    return {
      chart: {
        type: 'column',
        height: 280,
      },

      xAxis: {
        categories,
        tickPositioner(): number[] | undefined {
          if (intervalType === 'day') {
            return [0, categories.length - 1];
          } else {
            return undefined;
          }
        },
      },

      tooltip: {
        x: '',
        y: '',

        formatter() {
          if (dataType === 'meetingsPerInterval') {
            const translation = intl.t('common.x_meetings', {
              count: this.y,
            });

            return `${this.x}<br />${translation}`;
          }

          let numberValue = parseFloat(this.y);

          const i18nKey =
            numberValue < 1.0 ? 'common.x_minutes' : 'common.x_hours';
          numberValue = numberValue < 1.0 ? numberValue * 60 : numberValue;

          const translation = intl.t(i18nKey, {
            count: parseFloat(numberValue.toFixed(2)),
          });

          return `${this.x}<br />${translation}`;
        },
      },

      yAxis: {
        allowDecimals: false,
        title: {
          text: null,
        },
      },

      legend: {
        enabled: false,
      },
    };
  }
}
