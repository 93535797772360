/* import __COLOCATED_TEMPLATE__ from './totaljobs.hbs'; */
import PromotionBaseClass from './promotion-base';
import { action, get, set, setProperties } from '@ember/object';

export default class extends PromotionBaseClass {
  get catererGlobalRegions() {
    return this.store.peekAll('promotion-categories/caterer-global-region');
  }

  get totaljobsRegions() {
    return this.store.peekAll('promotion-categories/totaljobs-region');
  }

  get totaljobsMinmaxSalaries() {
    return this.store.peekAll('promotion-categories/totaljobs-minmax-salary');
  }

  get totaljobsMinmaxSalary() {
    return get(this, 'totaljobsMinmaxSalaries').find(
      (salary) =>
        get(salary, 'min') === get(this, 'promotion.totaljobsMinSalary') &&
        get(salary, 'max') === get(this, 'promotion.totaljobsMaxSalary')
    );
  }

  get totaljobsJobTypes() {
    if (get(this, 'promotion.channel.internalName') === 'cwjobs') {
      return ['Permanent', 'Contract'];
    } else {
      return ['Permanent', 'Contract', 'Temporary', 'Part Time'];
    }
  }

  @action
  selectTotaljobsMinmaxSalary(range) {
    setProperties(get(this, 'promotion'), {
      totaljobsMinSalary: get(range, 'min'),
      totaljobsMaxSalary: get(range, 'max'),
    });
  }

  @action
  setTotaljobsSalaryDescription(salary) {
    set(this, 'promotion.totaljobsSalaryDescription', salary.name);
  }
}
