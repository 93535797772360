/* import __COLOCATED_TEMPLATE__ from './ad-template-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import PageModel from 'teamtailor/models/page';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

type AdTemplateFormArgs = {
  selectedLanguageId?: string;
  handleAdTemplateChange: (adTemplate?: PageModel) => void;
  adTemplate?: PageModel;
  adTemplateId?: string;
  isJobTemp: boolean;
};

export default class JobsAdTemplateFormComponent extends Component<AdTemplateFormArgs> {
  @service declare current: Current;
  @service declare store: Store;

  @tracked adTemplates: PageModel[] = [];
  @tracked initialLanguage = true;

  get defaultTemplate(): PageModel | undefined {
    return this.adTemplates.findBy('isDefault', true);
  }

  setAdTemplate(): void {
    if (
      !this.initialLanguage ||
      (this.args.isJobTemp && !this.args.adTemplateId)
    ) {
      this.args.handleAdTemplateChange(this.defaultTemplate);
    }
  }

  fetchAdTemplates = restartableTask(async () => {
    const pages: PageModel[] = await this.store.query('page', {
      career_site_id: this.args.selectedLanguageId,
      view: 'templates',
    });

    this.adTemplates = pages.filterBy('isActive', true).sortBy('createdAt');

    this.setAdTemplate();
    this.initialLanguage = false;
  });

  @action
  didInsert(): void {
    this.fetchAdTemplates.perform();
  }

  @action
  handleLanguageChange(): void {
    this.fetchAdTemplates.perform();
  }
}
