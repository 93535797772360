/* import __COLOCATED_TEMPLATE__ from './created-at.hbs'; */
import Component from '@glimmer/component';

type JobsCreatedAtArgs = {
  createdAt: Date;
};

export default class JobsCreatedAtComponent extends Component<JobsCreatedAtArgs> {
  get createdThisYear(): boolean {
    return this.args.createdAt.getFullYear() === new Date().getFullYear();
  }
}
