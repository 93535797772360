/* import __COLOCATED_TEMPLATE__ from './field-error.hbs'; */
import Component from '@glimmer/component';

export default class extends Component {
  popperOptions = Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: true,
      },
    },
  });
}
