/* import __COLOCATED_TEMPLATE__ from './metro.hbs'; */
import { action, set } from '@ember/object';
import PromotionBaseClass from './promotion-base';
import { tracked } from '@glimmer/tracking';

export default class extends PromotionBaseClass {
  @tracked checkoutData;

  get metroCategories() {
    return this.store.peekAll('metro-category').filterBy('isParent', true);
  }

  get metroRegions() {
    return this.store
      .peekAll('metro-region')
      .filterBy('metroRegion.id', '10001');
  }

  get metroEmployeeTypeOptions() {
    return [
      { value: 1, name: 'Heltid' },
      { value: 2, name: 'Deltid' },
      { value: 3, name: 'Vikariat' },
      { value: 4, name: 'Extra / Säsong / Visstid' },
    ];
  }

  @action
  setMetroEmploymentType(option) {
    set(this, 'promotion.metroEmploymentType', option.value);
  }
}
