/* import __COLOCATED_TEMPLATE__ from './form-expandable-box-with-modal.hbs'; */
import FormExpandableBox from 'teamtailor/components/form-expandable-box';
import { action } from '@ember/object';
export default class FormExpandableBoxWithModalComponent extends FormExpandableBox {
  @action
  handleModalClose() {
    this.close();
    this.args.onModalClose?.();
  }
}
