/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import {
  CandidateModel,
  SurveyModel,
  FormQuestionModel,
  FormAnswerModel,
} from 'teamtailor/models';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { States as FormInviteStates } from 'teamtailor/models/form-invite';
import { inject as service } from '@ember/service';
import FlashMessageService from 'teamtailor/services/flash-message';

interface Signature {
  Args: {
    candidate: CandidateModel;
  };
}

type SurveysWithResponses = {
  survey: SurveyModel;
  questionsWithAnswers: QuestionsWithAnswers;
}[];

type QuestionsWithAnswers = QuestionWithAnswer[];

type QuestionWithAnswer = {
  question: FormQuestionModel;
  answer: string | string[] | undefined;
  isOtherSelected?: boolean;
};

export default class extends Component<Signature> {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked showSendSurvey = false;
  @tracked expandedSurveyId: string | null = null;

  get surveys(): SurveyModel[] {
    if (this.args.candidate.isDestroying) {
      return [];
    }

    const formInvites = get(this.args.candidate, 'formInvites');
    return formInvites
      .filterBy('state', FormInviteStates.ANSWERED)
      .mapBy('survey');
  }

  get iconName() {
    return this.expandedSurveyId ? 'caret-up' : 'caret-down';
  }

  get surveysWithResponses() {
    const surveys = [] as SurveysWithResponses;

    this.surveys.forEach((survey) => {
      const questionsWithAnswers = [] as QuestionsWithAnswers;

      get(survey, 'sortedFormQuestions').forEach((formQuestion) => {
        const formAnswer = formQuestion.formAnswers.find(
          (answer) => answer.candidateId.toString() === this.args.candidate.id
        );
        const questionWithAnswer = {
          question: formQuestion,
          answer: this.getQuestionAnswer(formQuestion, formAnswer),
        } as QuestionWithAnswer;

        if (formQuestion.isChoiceType) {
          questionWithAnswer.isOtherSelected = !formQuestion.schema.alternatives
            .mapBy('id')
            .includes(formAnswer?.textValue);
        }

        questionsWithAnswers.push(questionWithAnswer);
      });

      surveys.push({
        survey,
        questionsWithAnswers,
      });
    });

    return surveys;
  }

  getQuestionAnswer(
    formQuestion: FormQuestionModel,
    formAnswer: FormAnswerModel | undefined
  ) {
    if (formAnswer) {
      if (formQuestion.isTextType || formQuestion.isScaleType) {
        return formAnswer.formattedValue;
      } else if (formQuestion.isChoiceType) {
        return formAnswer.textValue;
      } else if (formQuestion.isCheckboxType) {
        return formAnswer.arrayValue;
      }
    }
  }

  @action
  expandSurvey(surveyId: string) {
    this.expandedSurveyId =
      this.expandedSurveyId === surveyId ? null : surveyId;
  }
}
