/* import __COLOCATED_TEMPLATE__ from './head-cell.hbs'; */
import CompareCandidatesCellComponent from './cell';
import { action } from '@ember/object';

export default class JobsCompareHeadCell extends CompareCandidatesCellComponent {
  @action
  didInsertSelectButton(selectableItem: HTMLElement) {
    // set dynamic width of button::after content to increase clickable area
    const width = selectableItem.closest('th')?.offsetWidth;
    const button = selectableItem.getElementsByClassName(
      'select-button'
    )[0] as HTMLElement;
    button.style.setProperty('--width', `${width}px`);
  }
}
