/* import __COLOCATED_TEMPLATE__ from './picture.hbs'; */
import AdjustmentsBaseComponent from './base';

export default class PictureAdjustmentsComponent extends AdjustmentsBaseComponent {
  DEFAULT_BORDER_RADIUS = 8;
  DEFAULT_MIN = 0;
  DEFAULT_MAX = 50;

  get borderRadius() {
    return this.args.model.borderRadius || '-';
  }

  get isNotFullLayout() {
    return this.args.model.layout !== 'full';
  }
}
