/* import __COLOCATED_TEMPLATE__ from './trigger-new-smart-move-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { scheduleOnce, next } from '@ember/runloop';
import { tracked } from 'tracked-built-ins';
import { AnimateArray } from 'teamtailor/ember-smooth';
import { scrollToPromise } from '../utils/scroll-to-promise';
import { inject as service } from '@ember/service';

export default class TriggerSmartMoveForm extends Component {
  @tracked moves;
  @service scroller;
  @service current;

  constructor() {
    super(...arguments);
    this.setMovesWithArray(get(this.args.trigger, 'moves'));
  }

  get company() {
    return this.current.company;
  }

  get stage() {
    return this.args.trigger.stage;
  }

  get job() {
    return this.args.trigger.job;
  }

  get showAddGroupButton() {
    const moveModels = this.moves.map((move) => move.object);
    return moveModels.some((element) => element.hasCriteriaQuestion);
  }

  setMovesWithArray(array) {
    this.moves = new AnimateArray(
      ...array.sortBy('rowOrderPosition', 'rowOrder').rejectBy('isDeleted')
    );
  }

  @action
  async handleRemoveMove(i) {
    const row = this.moves[i];

    const GAP_SIZE = 20;

    if (this.moves.length === i + 1) {
      const maxScroll =
        this.args.scrollElement.scrollHeight -
        this.args.scrollElement.clientHeight;
      const newScrollTop =
        maxScroll -
        ((row.beforeEl?.getBoundingClientRect().height || 0) + GAP_SIZE) -
        (row.el.getBoundingClientRect().height + GAP_SIZE);

      if (typeof newScrollTop === 'number') {
        await scrollToPromise(this.args.scrollElement, newScrollTop);
      }
    }

    await this.moves.asyncRemoveAt(i);
    row.object.deleteRecord();
  }

  @tracked skipIn = false;

  @action
  handleAddMove() {
    this.skipIn = true;
    this.moves.asyncPushObject(
      get(this.args.trigger, 'moves').createRecord({
        rowOrderPosition: this.moves.length,
      }),
      { animate: false }
    );

    scheduleOnce('afterRender', this, this.addMoveAfterRender);
  }

  addMoveAfterRender() {
    this.moves.lastObject.el.scrollIntoView({
      behavior: 'smooth',
    });
  }

  @action handleMoveArrowClick(change, move) {
    this.args.scrollElement.style.overflowAnchor = 'none';
    const currIndex = this.moves.findIndex(({ object }) => {
      return move === object;
    });

    const currMoveEl = this.moves[currIndex].el;
    const oldRectTop = currMoveEl.getBoundingClientRect().top;

    const oldScrollTop = this.args.scrollElement.scrollTop;
    const newIndex = currIndex + change;
    this.moves.asyncRemoveAt(currIndex, { animate: false });
    this.moves.asyncInsertAt(newIndex, move, { animate: false });
    this.moves.forEach(({ object }, i) => {
      object.rowOrderPosition = i;
    });

    scheduleOnce(
      'afterRender',
      this,
      this.moveUpDownAfterRender,
      oldScrollTop,
      oldRectTop,
      newIndex
    );
  }

  moveUpDownAfterRender(oldScrollTop, oldRectTop, newIndex) {
    const newRectTop = this.moves[newIndex].el.getBoundingClientRect().top;
    const rectTopDiff = oldRectTop - newRectTop;
    this.args.scrollElement.scrollTop = oldScrollTop - rectTopDiff;

    next(() => {
      this.args.scrollElement.style.overflowAnchor = '';
    });
  }
}
