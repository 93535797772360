import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class PickedVideo extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  serialize() {
    let json = super.serialize(...arguments);

    if (json.section_id) {
      json.owner_id = json.section_id;
      json.owner_type = 'ContentBlock::Section';
    } else if (json.section_item_id) {
      json.owner_id = json.section_item_id;
      json.owner_type = 'ContentBlock::SectionItem';
    } else if (json.message_widget_id) {
      json.owner_id = json.message_widget_id;
      json.owner_type = 'MessageWidget';
    } else if (json.connect_media_item_id) {
      json.owner_id = json.connect_media_item_id;
      json.owner_type = 'ConnectMediaItem';
    }

    delete json.section_id;
    delete json.section_item_id;
    delete json.message_widget_id;
    delete json.company_id;
    delete json.connect_media_item_id;

    return json;
  }
}
