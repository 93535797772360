/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ResizeObserverService from 'ember-resize-observer-service';
import { inject as service } from '@ember/service';

interface Args {
  hidden: boolean;
  id: string;
  title: string;
  sideScroll: boolean;
}

export default class BlockLibraryRow extends Component<Args> {
  @tracked leftScrollVisible = false;
  @tracked rightScrollVisible = false;

  @service declare resizeObserver: ResizeObserverService;

  @action
  setupScrollButtons(element: Element): void {
    this.resizeObserver.observe(element, () => {
      requestAnimationFrame(() => {
        this.recheckScrollButtons(element);
      });
    });

    element.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        this.leftScrollVisible = element.scrollLeft > 0;
        this.rightScrollVisible =
          element.scrollLeft < element.scrollWidth - element.clientWidth;
      });
    });
  }

  @action
  recheckScrollButtons(element: Element): void {
    const padding = parseInt(
      window.getComputedStyle(element, null).getPropertyValue('padding')
    );

    this.rightScrollVisible =
      (element.parentElement &&
        element.scrollWidth >
          element.parentElement.clientWidth + padding * 2) ||
      false;
  }

  @action
  scrollLayoutList(block: string, direction: string): void {
    const layoutList = document.getElementById(`${block}-list`);
    const buttonWidth = layoutList?.querySelector('button')?.clientWidth;

    if (layoutList && buttonWidth) {
      layoutList.scroll({
        behavior: 'smooth',
        left:
          direction === 'right'
            ? layoutList.scrollLeft + buttonWidth * 2
            : layoutList.scrollLeft - buttonWidth * 2,
      });
    }
  }
}
