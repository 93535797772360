/* import __COLOCATED_TEMPLATE__ from './referral-row.hbs'; */
import Component from '@glimmer/component';

import {
  UserModel,
  CandidateModel,
  JobApplicationModel,
  JobModel,
} from 'teamtailor/models';

export interface Referral {
  userId: number;
  jobId?: number;
  candidateId: number;
  timestamp: number;
}

type Args = {
  referral: Referral;
  candidates?: CandidateModel[];
  users?: UserModel[];
  jobs?: JobModel[];
};

export default class ReferralRow extends Component<Args> {
  get candidate(): CandidateModel | undefined {
    return this.args.candidates?.find((candidate) => {
      return candidate.id === this.args.referral.candidateId.toString();
    });
  }

  get job(): JobModel | undefined {
    return this.args.jobs?.find((job) => {
      return job.id === this.args.referral.jobId?.toString();
    });
  }

  get jobApplication(): JobApplicationModel | undefined {
    return this.candidate?.jobApplications.find((jobApplication) => {
      return String(jobApplication.jobId) === this.job?.id;
    });
  }

  get user(): UserModel | undefined {
    return this.args.users?.find((user) => {
      return user.id === this.args.referral.userId.toString();
    });
  }
}
