/* import __COLOCATED_TEMPLATE__ from './status-button.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';

const STATUS_TIMEOUT = 2000;

export default class ActionBarStatusButtonComponent extends Component {
  @service intl;

  @tracked optionButtonsVisible = false;
  @tracked isSuccess = false;
  @tracked isFailure = false;

  get isRunning() {
    return this.saveTask.isRunning;
  }

  get inFlight() {
    return this.isRunning && this.notSuccess && this.notFailure;
  }

  get hasOptions() {
    return this.args.options?.length > 0;
  }

  get saveButtonTitle() {
    return this.args.saveButtonTitle || this.intl.t('common.save');
  }

  doSave() {
    return this.args.doSave ? this.args.doSave() : null;
  }

  doSaveAsDraft() {
    return this.args.doSaveAsDraft ? this.args.doSaveAsDraft() : null;
  }

  doOptionSelect(action) {
    return this.args.doOptionSelect ? this.args.doOptionSelect(action) : null;
  }

  saveTask = dropTask(async (promise) => {
    let isNoPromise = typeof promise.then != 'function';
    if (!isNoPromise) {
      let result = await promise.catch(() => ({ isError: true }));

      if (result && result.isError) {
        this.isFailure = true;
        await timeout(STATUS_TIMEOUT);
        this.isFailure = false;
      } else {
        this.isSuccess = true;
        await timeout(STATUS_TIMEOUT);
        this.isSuccess = false;
      }
    }
  });

  @action
  handleSave() {
    return this.saveTask.perform(this.doSave());
  }

  @action
  handleSaveAsDraft() {
    return this.doSaveAsDraft();
  }

  @action
  handleOptionSelect(action) {
    this.optionButtonsVisible = false;
    return this.saveTask.perform(this.doOptionSelect(action));
  }

  @action
  toggleOptionButtons(e) {
    e.stopPropagation();
    this.optionButtonsVisible = !this.optionButtonsVisible;
  }
}
