/* import __COLOCATED_TEMPLATE__ from './image-picker-candidate.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  maxImageFileSize,
  ALLOWED_IMAGE_TYPES,
} from 'teamtailor/constants/uploads';

export default class ImagePickerCandidate extends Component {
  @service directUpload;
  @service('file-queue') fileQueue;
  @service intl;
  @service flashMessages;

  queueName = 'candidate-profile-image';

  @action
  async uploadToS3(file) {
    if (file.size > maxImageFileSize) {
      const queue = this.fileQueue.find(this.queueName);
      queue.files.removeObject(file);
      this.flashMessages.error(
        this.intl.t('components.media_library.file_too_big', {
          file: file.name,
        })
      );

      return;
    }

    let url = await this.directUpload.s3(file, {
      model: 'images',
      filename: `original.${file.extension}`,
    });

    this.args?.onImageUploaded(url);
  }

  @action
  validateFile(file) {
    const validType = ALLOWED_IMAGE_TYPES.includes(file.type);
    const validSize = file.size <= maxImageFileSize;
    return validType && validSize;
  }
}
