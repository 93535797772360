/* import __COLOCATED_TEMPLATE__ from './orb.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import BowserService from 'teamtailor/services/bowser';
import { ORB_PATHS } from './orb-data';

export default class BreathingAnimationOrb extends Component {
  @service declare bowser: BowserService;

  get filtersEnabled() {
    return !['Firefox', 'Safari'].includes(this.bowser.browser.name!);
  }

  get paths() {
    return ORB_PATHS;
  }
}
