/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { argDefault } from 'teamtailor/utils/arg-default';

const VALID_SIZES = ['32', '40'] as const;
type ValidSize = (typeof VALID_SIZES)[number];

interface ProgressIndicatorArgs {
  completionPercentage: number;
  size?: ValidSize;
}

export default class GradientProgressComponent extends Component<ProgressIndicatorArgs> {
  @argDefault completionPercentage = 0;
  @argDefault size: string = '40';

  get sizeClass(): string {
    verifyArg(this.size, VALID_SIZES, 'Gradient progress @size');
    return this.size === '40' ? 'size-48' : 'size-40';
  }

  get pixelSize(): number {
    verifyArg(this.size, VALID_SIZES, 'Gradient progress @size');
    return this.size === '40' ? 48 : 40;
  }

  get filledValue(): number {
    const radius = this.pixelSize * 0.37;
    return radius * 2 * Math.PI;
  }

  get percentageFillValue(): number {
    return this.filledValue - this.filledValue * this.completionPercentage;
  }
}
