/* import __COLOCATED_TEMPLATE__ from './top-users.hbs'; */
import Component from '@glimmer/component';

interface UserActivity {
  hires: number;
  interviews: number;
  messages: number;
  moves: number;
  notes: number;
  rejections: number;
  reviews: number;
  scores: number;
  sources: number;
  userId: string;
  name: string;
  total: number;
}

type Args = {
  rows?: UserActivity[];
};

export default class InsightsWidgetsTopUsers extends Component<Args> {
  get topUsers() {
    return this.args.rows
      ?.sort((a, b) => {
        return b.total - a.total;
      })
      .slice(0, 6);
  }
}
