/* import __COLOCATED_TEMPLATE__ from './todos.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import TodoModel from 'teamtailor/models/todo';
import CandidateModel from 'teamtailor/models/candidate';
import FlashMessageService from 'teamtailor/services/flash-message';

interface TodosArgs {
  todos: TodoModel[];
  isExpanded: boolean;
  candidate: CandidateModel;
  toggleExpanded(): void;
}

export default class CandidatePreviewPopoverTodosComponent extends Component<TodosArgs> {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked editingTodo!: TodoModel;

  onError(): void {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  toggleIsExpanded(): void {
    this.args.toggleExpanded();
  }

  @action
  handleToggle(todo: TodoModel) {
    return todo.toggleCompletion();
  }

  @action
  deleteTodo(todo: TodoModel): Promise<void | Error> {
    return todo
      .destroyRecord()
      .then((deletedTodo: TodoModel) => {
        set(
          this.args.candidate,
          'todos',
          this.args.todos.filter((todo) => todo.id !== deletedTodo.id)
        );
      })
      .catch((e: Error) => {
        this.onError();
        return e;
      });
  }

  @action
  editTodo(todo: TodoModel): void {
    this.editingTodo = todo;
  }

  @action saveTodo({
    value,
    due,
    assignee,
    candidate,
  }: TodoModel): Promise<TodoModel | Error> {
    this.editingTodo.value = value;
    this.editingTodo.due = due;
    this.editingTodo.assignee = assignee;
    this.editingTodo.candidate = candidate;
    return this.editingTodo.save().catch((e: Error) => {
      this.onError();
      return e;
    });
  }

  @action
  setDueDate(todo: TodoModel, dueDate: Date): Promise<TodoModel | Error> {
    return todo.setDueDate(dueDate).catch((e: Error) => {
      this.onError();
      return e;
    });
  }
}
