/* import __COLOCATED_TEMPLATE__ from './missing.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class CareerSitesMissingTranslationsComponent extends Component {
  @service current;
  @service questionTranslations;

  get careerSites() {
    return get(this.current.company, 'careerSites') || [];
  }

  get careerSitesLangCodes() {
    return this.careerSites.mapBy('languageCode');
  }

  get translationLangCodes() {
    return this.questionTranslations
      .getQuestionTranslations(this.translationObject)
      .mapBy('languageCode');
  }

  get hasTranslation() {
    return this.translationLangCodes.includes(this.currentLanguage);
  }

  get currentLanguage() {
    return this.args.currentLanguage;
  }

  get handleTranslation() {
    return this.args.handleTranslation;
  }

  get translationObject() {
    return this.args.translationObject;
  }

  get translationObjectId() {
    return get(this.translationObject, 'id');
  }

  get objectLanguage() {
    return get(this.translationObject, 'languageCode');
  }

  get checkAllLanguages() {
    return this.args.checkAllLanguages;
  }

  get hasLoadedTranslations() {
    return this.questionTranslations.questions.isLoaded;
  }

  get missingTranslation() {
    if (this.careerSites?.length <= 1 || !this.objectLanguage) {
      return false;
    }

    let isMissingTranslation = false;

    if (this.checkAllLanguages) {
      isMissingTranslation = this.isMissingAnyLanguage;
      if (isMissingTranslation) return true;
    }

    if (this.hasLoadedTranslations && this.currentLanguage) {
      if (this.currentLanguage !== this.objectLanguage) {
        isMissingTranslation = !this.hasTranslation;
      }

      if (
        this.translationObjectId &&
        this.handleTranslation &&
        !this.hasTranslation
      ) {
        this.handleTranslation(this.translationObjectId, isMissingTranslation);
      }
    }

    if (isMissingTranslation) return true;

    if (this.args.validateTranslationContent) {
      isMissingTranslation = this.questionTranslations
        .getQuestionTranslations(this.translationObject)
        .some((translation) => this.isMissingTranslationInContent(translation));
    }

    return isMissingTranslation;
  }

  get isMissingAnyLanguage() {
    const allLanguages = [...this.translationLangCodes, this.objectLanguage];

    let difference = this.careerSitesLangCodes.filter(
      (languageCode) => !allLanguages.includes(languageCode)
    );

    return difference.length > 0;
  }

  isMissingTranslationsInAlternatives(alternatives) {
    if (!alternatives) return false;

    return alternatives.some((alternative) => alternative.title.length === 0);
  }

  isMissingTranslationInContent(translation) {
    const questionDescription = get(this.translationObject, 'description');

    return (
      translation.title?.length === 0 ||
      (questionDescription &&
        questionDescription.length > 0 &&
        translation.description?.length === 0) ||
      this.isMissingTranslationsInAlternatives(translation.alternatives)
    );
  }
}
