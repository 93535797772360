/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty, isNone } from '@ember/utils';
import { KeyCodes } from 'teamtailor/constants/key-codes';
import { EkEvent, keyResponder, onKey } from 'ember-keyboard';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';
import KeyboardService from 'ember-keyboard/services/keyboard';
import { Options as FocusTrapOptions } from 'focus-trap';
import { CoreAnchoredElementApi } from '../anchored-element';
import { restartableTask, task, waitForProperty } from 'ember-concurrency';
import { consume } from 'ember-provide-consume-context';
import { SelectScrollWrapperCtx } from './select-scroll-wrapper';
import { afterRender } from 'teamtailor/ember-smooth';
import { debounce } from 'teamtailor/utils/debounce';
import { Placement } from '@floating-ui/dom';

type CoreDropdownContainerArgs = {
  focusTrap?: boolean;
  upDownNavigationEnabled?: boolean;
  focusTrapOptions?: FocusTrapOptions;
  searchTerm?: string | null;
  lockSizeWhenSearching?: boolean;
  initialPlacement?: Placement;
  isEmpty?: boolean;
  onSearch?: (event: KeyboardEvent) => void;
  scrollIntoViewOptions?: boolean | ScrollIntoViewOptions;
  onClose?: () => void;
  keyboardPriority?: number;
  onSearchInputInsert?: (el: HTMLInputElement) => void;
  activeItemEl?: HTMLElement;
  onActiveItemElChange?: (el?: HTMLElement) => void;
  onEnterKeydown?: (event: KeyboardEvent) => boolean;
  onKeydown?: (event: KeyboardEvent) => void;
  isOpenedByKeyboard?: boolean;
  onRegister?: (api: CoreDropdownContainerApi) => void;
  domContentIsReady?: boolean;
  scrollToFirstSelected?: () => Promise<void>;
  listWrapperElement?: string | false;
  triggerElement: HTMLHtmlElement;
};

export type CoreDropdownContainerApi = {
  updatePosition?: () => void;

  keyboardPriority: number;

  activateItem: (direction?: 'up' | 'down') => void;
};

@keyResponder
export default class CoreDropdownContainer extends Component<CoreDropdownContainerArgs> {
  declare element: HTMLElement;
  @service declare keyboard: KeyboardService;

  @argDefault focusTrap = false;
  @argDefault upDownNavigationEnabled = true;
  @argDefault listWrapperElement = 'ul';
  @argDefault lockSizeWhenSearching = true;

  @tracked domContentIsReady = this.args.domContentIsReady ?? true;
  @action domContentIsReadyUpdater([newVal]: [boolean]) {
    // Using @argDefault or getter with default value didnt work with `waitForProperty` below, hence this helper updating local tracked prop instead
    this.domContentIsReady = newVal;
  }

  get focusTrapOptions() {
    return {
      initialFocus: true,

      ...(this.args.focusTrapOptions || {}),
    };
  }

  @tracked declare lockSizeRect?: DOMRect | null;

  declare innerEl?: HTMLElement;
  declare innerElRect?: DOMRect;

  @consume('select-scroll-wrapper')
  declare selectScrollWrapperCtx?: SelectScrollWrapperCtx;

  get isRemoteSearch() {
    return !!this.selectScrollWrapperCtx;
  }

  get initialPlacement() {
    if (!isNone(this.args.initialPlacement)) {
      return this.args.initialPlacement;
    }

    return this.selectScrollWrapperCtx &&
      this.anchoredElementApi?.positionState?.placement.startsWith('top')
      ? this.anchoredElementApi.positionState.placement
      : undefined;
  }

  onSearch = restartableTask(async (event: KeyboardEvent) => {
    if (this.lockSizeWhenSearching) {
      const value =
        event.target instanceof HTMLInputElement ? event.target.value : null;

      const searchTermBeforeSearch = this.args.searchTerm;
      const beforeRect = this.innerElRect;

      this.args.onSearch?.(event);

      const isRemoteSearch =
        !!this.selectScrollWrapperCtx?.runningSearchTaskPromise;

      if (isRemoteSearch) {
        await this.selectScrollWrapperCtx?.runningSearchTaskPromise;
        await Promise.all([
          waitForProperty(this.args, 'domContentIsReady', (val) => !!val),
          afterRender(),
        ]);

        if (value) {
          this.innerEl!.style.minWidth = '';
          this.innerEl!.style.minHeight = '';

          const unlockedRect = this.innerEl?.getBoundingClientRect();

          if (!unlockedRect || !beforeRect) {
            return;
          }

          let lockSizeToSet = beforeRect;

          if (unlockedRect.height > beforeRect.height) {
            lockSizeToSet.height = unlockedRect.height;
          }

          if (unlockedRect.width > beforeRect.width) {
            lockSizeToSet.width = unlockedRect.width;
          }

          this.lockSizeRect = lockSizeToSet;
        } else if (this.args.searchTerm && !value) {
          // unlock size when stopping to search
          this.lockSizeRect = null;
        }
      } else {
        if (!searchTermBeforeSearch && value) {
          await afterRender();
          // lock size when starting to search
          this.lockSizeRect = this.innerEl?.getBoundingClientRect();
        } else if (this.args.searchTerm && !value) {
          // unlock size when stopping to search
          this.lockSizeRect = null;
        }
      }
    }
  });

  @action handleLockSizeRect([showLoading, lockSizeRect]: [
    boolean,
    DOMRect | undefined,
  ]) {
    if (!this.innerEl) return;

    if (lockSizeRect) {
      this.innerEl.style.minWidth = `${lockSizeRect.width}px`;
      this.innerEl.style.minHeight = `${lockSizeRect.height}px`;
    } else if (!showLoading) {
      this.innerEl.style.minWidth = '';
      this.innerEl.style.minHeight = '';
    }
  }

  get scrollIntoViewOptions() {
    return (
      this.args.scrollIntoViewOptions || {
        behavior: 'auto',
        inline: 'nearest',
        block: 'nearest',
      }
    );
  }

  get searchEnabled() {
    return !isEmpty(this.args.onSearch);
  }

  get itemEls() {
    const liElements = this.element.querySelectorAll(
      ':scope > div > ul > div > li, :scope > div > ul > li'
    );

    return [
      ...new Set(
        Array.from(liElements).map(
          (child) => child.firstElementChild as HTMLElement
        )
      ),
    ].filter((child) => !('disabled' in child) || !child.disabled);
  }

  async getSelectedItemEl() {
    await this.args.scrollToFirstSelected?.();

    return this.itemEls.find(
      (item) => item.getAttribute('aria-selected') === 'true'
    );
  }

  highlightFirstItem() {
    if (this.searchEnabled) {
      this.focusSearch();
    } else {
      this.element.focus();
    }
  }

  @onKey(KeyCodes.ESC)
  handleEsc(_e: KeyboardEvent, ekEvent: EkEvent) {
    ekEvent.stopPropagation();
    this.args.onClose?.();
  }

  @tracked declare _keyboardPriority: number;

  get keyboardPriority() {
    return this.args.keyboardPriority ?? this._keyboardPriority;
  }

  set keyboardPriority(val) {
    this._keyboardPriority = val;
  }

  declare searchInput: HTMLInputElement | null;

  @action
  handleSearchInputInsert(el: HTMLInputElement) {
    this.searchInput = el;
    this.args.onSearchInputInsert?.(el);
  }

  focusSearch() {
    this.searchInput?.focus();
  }

  focusFirstItem() {
    this.focusElement(
      ':scope > ul > li > *:not(:disabled), :scope > ul > div > li > *:not(:disabled)'
    );
  }

  focusElement(selector: string) {
    const focusElement = this.element.querySelector<HTMLElement>(selector);
    if (focusElement) {
      focusElement.focus();
    }
  }

  getActiveIndex() {
    return this.activeItemEl ? this.itemEls.indexOf(this.activeItemEl) : null;
  }

  getNextActiveItemEl(direction: -1 | 1, startIndex = this.getActiveIndex()) {
    return this.getFirstEnabledItem(direction, startIndex);
  }

  getFirstEnabledItem(direction = 1, startIndexArg?: number | null) {
    const { itemEls } = this;

    const startIndex = startIndexArg ?? itemEls.length - 1;

    if (itemEls.length === 0) {
      return null;
    }

    let index = startIndex;
    const maxIndex = itemEls.length - 1;

    do {
      index = this.wrapNumber(index + direction, 0, maxIndex);
      return itemEls[index];
    } while (index !== startIndex && startIndex !== -1);
  }

  wrapNumber(value: number, min: number, max: number) {
    if (value < min) {
      return max;
    } else if (value > max) {
      return min;
    }

    return value;
  }

  get activeItemEl() {
    return this.args.activeItemEl;
  }

  set activeItemEl(itemEl) {
    this.args.onActiveItemElChange?.(itemEl);
  }

  @action handleActiveItemElUpdate() {
    const itemEl = this.activeItemEl;

    if (itemEl) {
      this.itemEls.forEach((itemEl) => {
        itemEl.classList.remove('focused');
      });
      itemEl.classList.add('focused');
      itemEl.tabIndex = 0;
      itemEl.focus();
      itemEl.scrollIntoView(this.scrollIntoViewOptions);
    }

    if (this.searchEnabled) this.highlightFirstItem();
  }

  activateItem = (direction = 'down') => {
    const nextActiveItemEl = this.getNextActiveItemEl(
      direction === 'up' ? -1 : 1
    );
    if (nextActiveItemEl != null) {
      this.activeItemEl = nextActiveItemEl;
    }
  };

  @action
  handleKeydown(event: KeyboardEvent) {
    const { ARROW_UP, ARROW_DOWN, ENTER, ARROW_LEFT, ARROW_RIGHT, SPACE, TAB } =
      KeyCodes;

    // This is to make it work in tests. When upgrading ember-test-helpers to 3+ we might be able to remove event.key fallback
    const code = event.code || event.key;

    if (code === TAB) {
      this.args.onClose?.();
    }

    if (
      this.upDownNavigationEnabled &&
      (code === ARROW_UP || code === ARROW_DOWN)
    ) {
      event.preventDefault();
      event.stopPropagation();
      const direction = code === ARROW_UP ? 'up' : 'down';
      this.activateItem(direction);
    }

    if (code === ENTER) {
      const doClickActiveElement = this.args.onEnterKeydown?.(event) ?? true;
      if (this.activeItemEl) {
        event.stopPropagation();
        event.preventDefault();

        if (doClickActiveElement) this.activeItemEl.click();
      }
    }

    if (
      this.activeItemEl?.hasAttribute('aria-haspopup') &&
      [ARROW_LEFT, ARROW_RIGHT, SPACE].includes(code)
    ) {
      event.stopPropagation();
      event.preventDefault();
      this.activeItemEl.click();
    }

    if (this.args.onKeydown) {
      this.args.onKeydown(event);
    }
  }

  @tracked declare anchoredElementApi?: CoreAnchoredElementApi;

  get api(): CoreDropdownContainerApi {
    const parentThis = this;
    return {
      get updatePosition() {
        return parentThis.anchoredElementApi?.updatePosition;
      },

      get keyboardPriority() {
        return parentThis.keyboardPriority;
      },

      activateItem: this.activateItem,
    };
  }

  @action
  @debounce(150)
  async handleWindowResize() {
    if (this.lockSizeRect) {
      this.lockSizeRect = null;
      await afterRender();
      this.lockSizeRect = this.innerEl?.getBoundingClientRect();
    }
  }

  @action onDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  onInsert = task(async (element: HTMLElement) => {
    window.addEventListener('resize', this.handleWindowResize);

    this.element = element;

    if (!this.domContentIsReady) {
      await waitForProperty(this, 'domContentIsReady', Boolean);
    }

    this.highlightFirstItem();

    const highestPriorityResponder = this.keyboard.sortedResponders[0];

    if (highestPriorityResponder !== this) {
      const highestActivePriority =
        this.keyboard.sortedResponders[0]?.keyboardPriority ?? 0;

      this.keyboardPriority = highestActivePriority + 1;
    }

    const selectedItemEl = await this.getSelectedItemEl();
    if (selectedItemEl) {
      this.activeItemEl = selectedItemEl;
    } else if (this.args.isOpenedByKeyboard) {
      this.activateItem();
    }

    const highestRegisteredPriority = this.keyboard.activeResponders.reduce(
      (acc, val) =>
        val !== this && val.keyboardPriority && val.keyboardPriority > acc
          ? val.keyboardPriority
          : acc,
      0
    );

    this.keyboardPriority = highestRegisteredPriority + 1;

    this.args.onRegister?.(this.api);
  });
}
