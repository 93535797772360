/* import __COLOCATED_TEMPLATE__ from './tt-color-picker.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class TtColorPickerComponent extends Component {
  @tracked isOpen = false;

  pickerComponents = {
    interaction: {
      hex: true,
      rgba: false,
      hsva: false,
      clear: false,
      save: false,
    },
  };

  @action
  handleClose(e) {
    e.preventDefault();

    this.isOpen = false;
  }
}
