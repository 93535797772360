/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import imageUrl from 'teamtailor/utils/image-url';
import downloadFile from 'teamtailor/utils/download-file';

export default class MediaLibraryMasonryListImageToolsImageComponent extends Component {
  @action
  removeImage() {
    this.args.onRemoveImage(this.args.image);
  }

  @action
  downloadImage() {
    const { image } = this.args;
    const url = imageUrl(image, 'original');
    const imageName = image.filename ? image.filename : 'image';

    fetch(url)
      .then((response) => response.blob())
      .then((image) => {
        downloadFile(URL.createObjectURL(image), imageName);
      });
  }
}
