/* import __COLOCATED_TEMPLATE__ from './attendee-group.hbs'; */
import { camelize } from '@ember/string';
import Component from '@glimmer/component';
import { AttendeeListClass, AttendeeType } from 'teamtailor/classes/meetings';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import MeetingEventAttendeeModel from 'teamtailor/models/meeting-event-attendee';
import { IUser } from 'teamtailor/components/meeting/types';
import UserModel from 'teamtailor/models/user';
import CandidateModel from 'teamtailor/models/candidate';
import { AsyncBelongsTo } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';

interface Args {
  list?: AttendeeType[];
  showNames?: boolean;
  showStatus?: boolean;
  limit?: number;
  jobId?: string;
  modelList?: MeetingEventAttendeeModel[];
}

type ListType = {
  user?: IUser | UserModel | AsyncBelongsTo<UserModel>;
  src?: string;
  status: string;
  initials?: string;
  nameOrEmail?: string | null;
  color?: string;
  anonymous?: boolean;
  candidateId?: string;
};

export default class AttendeeGroup extends Component<Args> {
  @service declare router: RouterService;

  get showNames() {
    return this.args.showNames ?? false;
  }

  get showStatus() {
    return this.args.showStatus ?? true;
  }

  get collection() {
    return this.collectionFunction.value || [];
  }

  get numberOfAttendees() {
    if (this.args.list) {
      return this.args.list.length;
    }

    if (this.args.modelList) {
      return this.args.modelList.length;
    }

    return 0;
  }

  listAsAttendeeClass() {
    return (
      this.args.list?.map((a: AttendeeType): ListType | undefined => {
        if (AttendeeListClass.isAttendeeUser(a)) {
          return {
            user: a.user,
            src: a.user?.avatarUrl,
            status: this.showStatus
              ? camelize(a.status?.toLowerCase() ?? '')
              : '',

            initials: a.user?.initials,
            nameOrEmail: a.user?.nameOrEmail,
          };
        }

        if (AttendeeListClass.isAttendeeCandidate(a)) {
          return {
            src: a.candidate?.avatarImage?.appUserAvatar?.url,
            status: this.showStatus
              ? camelize(a.status?.toLowerCase() ?? '')
              : '',

            initials: a.candidate?.initials,
            nameOrEmail: a.candidate?.nameOrEmail,
            color: a.candidate?.color,
            candidateId: a.candidate?.id,
          };
        }

        return undefined;
      }) || []
    );
  }

  async listAsModel() {
    if (!Array.isArray(this.args.modelList)) {
      return [];
    }

    await Promise.all(
      this.args.modelList.map((attendee: MeetingEventAttendeeModel) => {
        if (attendee.userId) {
          return attendee.belongsTo('user').load();
        }

        if (attendee.candidateId) {
          return attendee.belongsTo('candidate').load();
        }
      })
    );

    return this.args.modelList.map(
      (a: MeetingEventAttendeeModel): ListType | undefined => {
        if (a.userId) {
          return {
            user: a.user,
            src: get(a.user, 'avatarUrl'),
            status:
              this.showStatus && a.status
                ? camelize(a.status.toLowerCase())
                : '',

            initials: get(a.user, 'initials'),
            nameOrEmail: get(a.user, 'nameOrEmail'),
          };
        }

        if (a.candidateId) {
          return {
            src: get(a.candidate, 'picture'),
            status:
              this.showStatus && a.status
                ? camelize(a.status.toLowerCase())
                : '',

            initials: get(a.candidate, 'initials'),
            nameOrEmail: get(a.candidate, 'nameOrEmail'),
            color: get(a.candidate, 'color'),
            candidateId: get(a.candidate, 'id'),
          };
        }

        return undefined;
      }
    );
  }

  anonymousOnJob(jobId?: string, candidate?: CandidateModel | UserModel) {
    if (candidate instanceof CandidateModel && jobId) {
      const jobApplications = get(candidate, 'jobApplications');
      const jobApplication = jobApplications.find(
        (ja) => ja.jobId.toString() === jobId
      );

      return !!(jobApplication && get(jobApplication.stage, 'anonymous'));
    }

    return false;
  }

  collectionFunction = trackedFunction(this, async () => {
    let list = [];

    if (Array.isArray(this.args.modelList)) {
      list = [...(await this.listAsModel())];
    } else {
      list = [...this.listAsAttendeeClass()];
    }

    return list.slice(0, this.args.limit);
  });

  @action
  goToCandidate(attendee: ListType) {
    if (attendee.candidateId) {
      if (this.args.jobId) {
        this.router.transitionTo(
          'jobs.job.stages.index.candidate',
          this.args.jobId,
          attendee.candidateId
        );
      } else {
        this.router.transitionTo(
          'candidates.segment.candidate',
          'all',
          attendee.candidateId
        );
      }
    }
  }
}
