/* import __COLOCATED_TEMPLATE__ from './job-nps-score.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import { action } from '@ember/object';
import {
  calculateScore,
  isPromoterScore,
  isPassiveScore,
  isDetractorScore,
} from 'teamtailor/utils/nps';

const QUERY = gql`
  query JobNpsScoreQuery(
    $dateRange: DateRangeAttributes!
    $compareDateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    job: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      npsResponses: rows {
        jobId
        score: npsResponseScore
      }
    }
    previousPeriod: eventQuery(
      dateRange: $compareDateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      npsResponses: rows {
        jobId
        score: npsResponseScore
      }
    }
  }
`;

const EXPORT_QUERY = gql`
  query JobNpsScoreExportQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    job: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      npsResponses: rows {
        score: npsResponseScore
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'type', headerKey: 'common.type' },
  { type: 'number', propertyName: 'count', headerKey: 'insights.common.count' },
];

export default class extends Component {
  @service intl;
  @service insights;
  @service insightsExport;

  get numberOfResponses() {
    return this.fetchData?.lastSuccessful?.value?.numberOfResponses;
  }

  get npsResponses() {
    return this.fetchData?.lastSuccessful?.value?.npsResponses;
  }

  get previousResponses() {
    return this.fetchData?.lastSuccessful?.value?.previousResponses;
  }

  get promoters() {
    return this.npsResponses?.filter(({ score }) => isPromoterScore(score));
  }

  get detractors() {
    return this.npsResponses?.filter(({ score }) => isDetractorScore(score));
  }

  get passives() {
    return this.npsResponses?.filter(({ score }) => isPassiveScore(score));
  }

  get currentScore() {
    const scores = this.npsResponses.mapBy('score');
    return calculateScore(scores);
  }

  get previousScore() {
    const scores = this.previousResponses.mapBy('score');
    if (!scores.length) {
      return null;
    }

    return calculateScore(scores);
  }

  get breakdown() {
    const { promoters, detractors, passives } = this;
    const legendNameAndCount = (key, count) => {
      return {
        name: this.intl.t(`insights.widgets.job_nps_score.${key}`),
        label: this.intl.t(`insights.widgets.job_nps_score.${key}_with_count`, {
          count,
        }),

        value: count,
      };
    };

    return [
      legendNameAndCount('promoters', promoters.length),
      legendNameAndCount('detractors', detractors.length),
      legendNameAndCount('passives', passives.length),
    ];
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.common.nps_score',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.loadReportData,
      this.args.dateRange,
      { createdAt: this.args.job.createdAt }
    );
  }

  @action
  async loadReportData(dateRange) {
    const { job } = this.args;

    const {
      job: { npsResponses },
    } = await this.insights.query({
      query: EXPORT_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });

    const scores = npsResponses.mapBy('score');
    const passives = scores.filter(isPassiveScore).length;
    const promoters = scores.filter(isPromoterScore).length;
    const detractors = scores.filter(isDetractorScore).length;

    return [
      { type: 'promoters', count: promoters },
      { type: 'detractors', count: detractors },
      { type: 'passives', count: passives },
    ];
  }

  fetchData = dropTask(async () => {
    const { job, dateRange } = this.args;

    const { previousPeriod, job: response } = await this.insights.query({
      query: QUERY,
      variables: {
        dateRange: dateRange.asObject,
        compareDateRange: dateRange.previousDateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });

    return {
      numberOfResponses: response.npsResponses.length,
      npsResponses: response.npsResponses,
      previousResponses: previousPeriod.npsResponses,
    };
  });
}
