/* import __COLOCATED_TEMPLATE__ from './sms-help.hbs'; */
import Component from '@glimmer/component';
import { MAX_LENGTH } from 'teamtailor/utils/sms';

export default class SmsHelp extends Component {
  get smsLength() {
    return this.args.value?.length || 0;
  }

  get limitReached() {
    return this.smsLength >= MAX_LENGTH;
  }

  get limitCount() {
    return `${this.smsLength}/${MAX_LENGTH}`;
  }
}
