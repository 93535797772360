/* import __COLOCATED_TEMPLATE__ from './nps-comparison.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import { plotLineFontOptions } from 'teamtailor/components/insights/charts/font-options';
import InsightsService from 'teamtailor/services/insights';
import AnalyticsService from 'teamtailor/services/analytics';
import {
  primaryColor,
  secondaryColor,
} from 'teamtailor/components/insights/charts/colors';
import { action } from '@ember/object';
import InsightsExportService from 'teamtailor/services/insights-export';
import DateRange from 'teamtailor/utils/date-range';
import {
  SCORE_SECTORS,
  fetch,
} from 'teamtailor/classes/analytics/report-nps-comparison';
import { get } from 'teamtailor/utils/get';

type Args = {
  showMoreLink?: string;
  onShowExportModal?: () => void;
  isEmpty?: boolean;
  loading?: boolean;

  companiesPerScoreSector?: number[];
  averageCompanyScores?: number;
  averageScore?: number;
  currentScoreSector: number;
};

const EXPORT_TABLE_COLUMNS = [
  ...SCORE_SECTORS.map((sector) => {
    return {
      type: 'number',
      propertyName: sector,
      headerKey: `insights.widgets.nps_comparison.${sector}`,
    };
  }),
  {
    type: 'number',
    propertyName: 'averageCompanyScores',
    headerKey: 'insights.widgets.nps_comparison.average_for_all_companies',
  },
  {
    type: 'number',
    propertyName: 'averageScore',
    headerKey: 'insights.widgets.nps_comparison.average_score',
  },
];

export default class InsightsWidgetsNpsComparison extends Component<Args> {
  @service declare intl: IntlService;
  @service declare insights: InsightsService;
  @service declare analytics: AnalyticsService;
  @service declare insightsExport: InsightsExportService;
  @service declare current: Current;

  get labels() {
    return [this.intl.t('insights.widgets.nps_comparison.you_are_here')];
  }

  get legendColors() {
    return ['bg-brand-primary'];
  }

  get chartData() {
    return [
      {
        name: '',
        data: this.args.companiesPerScoreSector?.map((row, index) => {
          return {
            name: this.intl.t(
              `insights.widgets.nps_comparison.score_sector_${index}`
            ),

            y: row,

            color:
              this.args.currentScoreSector === index
                ? primaryColor
                : secondaryColor,
          };
        }),
      },
    ];
  }

  get chartOptions() {
    return {
      chart: {
        type: 'column',
        height: '300px',
        marginTop: 35,
      },

      xAxis: {
        categories: SCORE_SECTORS.map((sector) =>
          this.intl.t(`insights.widgets.nps_comparison.${sector}`)
        ),

        plotLines: this.plotLines,
      },

      yAxis: {
        title: {
          enabled: false,
        },

        labels: {
          enabled: false,
        },
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      plotOptions: {
        bar: {
          pointWidth: 16,
        },

        series: {
          stacking: 'normal',
          borderColor: null,
          pointWidth: 20,
        },
      },
    };
  }

  get plotLines() {
    const { averageCompanyScores } = this.args;
    const text = this.intl.t(
      'insights.widgets.nps_comparison.average_for_all_companies'
    );

    return [
      {
        label: {
          text,
          rotation: 0,
          align: 'center',
          y: -10,
          style: {
            ...plotLineFontOptions,
          },
        },

        color: plotLineFontOptions.color,
        width: 1,
        dashStyle: 'dash',
        value: (7.0 * ((averageCompanyScores || 0) + 100)) / 200.0,
        zIndex: 3,
      },
    ];
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.widgets.nps_comparison.title',
      `nps`,
      EXPORT_TABLE_COLUMNS,
      this.fetchExportData,
      new DateRange(this.analytics.startDate, this.analytics.endDate),
      {
        sortKey: 'date',
        sortDirection: 'desc',
        createdAt: get(this.current.company, 'went_live_at'),
        size: 'large',
      }
    );
  }

  @action
  async fetchExportData(dateRange: DateRange) {
    const container = getOwner(this);
    const {
      averageCompanyScores,
      companiesPerScoreSector,
      averageScore,
    }: {
      companiesPerScoreSector?: number[];
      averageCompanyScores?: number;
      averageScore?: number;
    } = await fetch(container, { dateRange });
    return [
      {
        ...companiesPerScoreSector.reduce<{ [key: string]: number }>(
          (acc, row, index) => {
            acc[`score_sector_${index}`] = row;
            return acc;
          },
          {}
        ),

        averageCompanyScores:
          Math.round(100 * (averageCompanyScores || 0)) / 100.0,

        averageScore: Math.round(100 * (averageScore || 0)) / 100.0,
      },
    ];
  }
}
