/* import __COLOCATED_TEMPLATE__ from './candidate-status.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  STATUS_COLOR,
  STATUS_TEXT,
} from 'teamtailor/constants/candidate-status';
import CandidateModel, { CandidateStatus } from 'teamtailor/models/candidate';
import { get } from 'teamtailor/utils/get';

type Args = {
  candidate: CandidateModel;
};

export default class CandidateStatusComponent extends Component<Args> {
  @action
  color(status: CandidateStatus) {
    return STATUS_COLOR[status];
  }

  @action
  text(status: CandidateStatus) {
    return STATUS_TEXT[status];
  }

  @action
  date(status: CandidateStatus) {
    if (status === 'will_be_deleted') {
      return get(this.args.candidate, 'willBeDeletedAt');
    }

    if (status === 'application_will_be_deleted') {
      return get(this.args.candidate, 'jobApplicationWillBeDeletedAt');
    }
  }
}
