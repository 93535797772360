/* import __COLOCATED_TEMPLATE__ from './progress-bar.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface ProgressBarArgs {
  percentage: number;
  progressClass?: string;
}

export default class ProgressBar extends Component<ProgressBarArgs> {
  get progressStyle() {
    return htmlSafe(`width: ${this.args.percentage}%`);
  }
}
