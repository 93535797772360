/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { trackedFunction } from 'ember-resources/util/function';
import { JobModel, JobOfferModel } from 'teamtailor/models';
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import AboardApolloService from 'teamtailor/services/aboard-apollo';
import { gql } from '@apollo/client/core';
import { Changeset as changeset } from 'ember-changeset';
import StageDrag from 'teamtailor/services/stage-drag';
import { get } from 'teamtailor/utils/get';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { data, CurrencyCodeRecord } from 'currency-codes';
import { BufferedChangeset } from 'ember-changeset/types';

interface Args {
  job: JobModel;
  onClose: () => void;
}

interface AboardManager {
  avatar: { url: string };
  fullName: string;
  id: string;
}

interface AboardDepartment {
  id: string;
  name: string;
}

interface AboardLocation {
  id: string;
  name: string;
}

interface AboardTemplate {
  default: boolean;
  id: string;
  name: string;
  members: { fullName: string; avatar: { url: string } }[];
}

interface AboardData {
  company: {
    name: string;
    profiles: AboardManager[];
    departments: AboardDepartment[];
    locations: AboardLocation[];
    onboardingTemplates: AboardTemplate[];
  };
}

interface OnboardingResponse {
  createProfileOnboarding: {
    onboarding: {
      id: string;
    };
  };
}

const REQUIRED_FIELDS = ['jobTitle', 'startDate'];

export default class OnboardingModal extends Component<Args> {
  @service declare aboardApollo: AboardApolloService;
  @service declare stageDrag: StageDrag;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  currencyList: CurrencyCodeRecord[] = data;

  @tracked changeSet: BufferedChangeset = changeset({
    manager: null,
    department: null,
    location: null,
    startDate: null,
    jobTitle: null,
    amount: null,
    currency: null,
    period: null,
  });

  @tracked filteredTemplates: AboardTemplate[] = [];
  @tracked pickedTemplates: AboardTemplate[] = [];
  @tracked salaryFrom: string | null = null;
  @tracked startDateFrom: string | null = null;

  aboardData = trackedFunction(this, async () => {
    const query = gql`
      query {
        company {
          name
          profiles(roles: [ADMIN, MANAGER]) {
            avatar {
              url
            }
            fullName
            id
          }
          departments {
            id
            name
          }
          locations {
            id
            name
          }
          onboardingTemplates {
            default
            id
            name
            color
            emoji {
              raw
            }
            members {
              fullName
              avatar {
                url
              }
            }
          }
        }
      }
    `;

    let result: AboardData;
    try {
      result = await this.aboardApollo.query({ query });
    } catch (error) {
      this.args.onClose();
      return;
    }

    await this.preselectStartDateAndSalary();

    if (!this.startDateFrom) {
      await this.preselectFromRequisition();
    }

    this.preselectDepartment(result.company.departments);
    const sortedTemplates = [...result.company.onboardingTemplates].sort(
      (a, b) => a.name.localeCompare(b.name)
    );
    this.preselectRole();

    this.filteredTemplates = sortedTemplates;

    return {
      companyName: `${result.company.name} onboarding`,
      managerList: [...result.company.profiles].sort((a, b) =>
        a.fullName.localeCompare(b.fullName)
      ),

      departmentList: [...result.company.departments].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),

      locationList: [...result.company.locations].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),

      templatesList: sortedTemplates,
    };
  });

  get availableTemplates() {
    return this.filteredTemplates.filter(
      (template) =>
        !this.pickedTemplates.some(
          (pickedTemplate) => pickedTemplate.id === template.id
        )
    );
  }

  get formHasErrors() {
    if (this.changeSet.amount) {
      REQUIRED_FIELDS.push('amount', 'currency', 'period');
    }

    return REQUIRED_FIELDS.some((field) => !this.changeSet[field]);
  }

  get salaryTimeUnits() {
    return [
      { label: 'yearly' },
      { label: 'monthly' },
      { label: 'weekly' },
      { label: 'daily' },
      { label: 'hourly' },
    ];
  }

  @action
  addTemplate(template: AboardTemplate, event: Event) {
    event.preventDefault();
    this.pickedTemplates = [...this.pickedTemplates, template];
    this.filteredTemplates = this.filteredTemplates.filter(
      (pickedTemplate) => pickedTemplate.id !== template.id
    );
  }

  @action
  removeTemplate(template: AboardTemplate, event: Event) {
    event.preventDefault();
    this.pickedTemplates = this.pickedTemplates.filter(
      (pickedTemplate) => pickedTemplate.id !== template.id
    );
    this.filteredTemplates = [...this.filteredTemplates, template];
  }

  @action
  setChangeSet(key: string, value: any) {
    set(this.changeSet, key, value);
  }

  @action
  moveBack() {
    this.pickedTemplates = [];
    this.changeSet.rollback();

    if (this.stageDrag.jobApplication) {
      this.stageDrag.jobApplication.aboardOnboardingId = null;
    }

    this.stageDrag.jobApplication = null;

    this.args.onClose();
  }

  @action
  onSearch(input: InputEvent) {
    const searchTerm = (input.target as HTMLInputElement).value;

    if (!this.aboardData.value) {
      return;
    }

    this.filteredTemplates = this.aboardData.value.templatesList
      .filter((template) =>
        template.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter((template) =>
        this.pickedTemplates.every(
          (pickedTemplate) => pickedTemplate.id !== template.id
        )
      );
  }

  @action
  resetTemplateSearch() {
    this.filteredTemplates =
      this.aboardData.value?.templatesList.filter(
        (template) =>
          !this.pickedTemplates.some(
            (pickedTemplate) => pickedTemplate.id === template.id
          )
      ) || [];
  }

  @action
  async createOnboarding() {
    const mutation = gql`
      mutation CreateProfileOnboarding(
        $firstName: String!
        $lastName: String!
        $email: String!
        $phoneNumber: String
        $onboardingTemplateIds: [ID!]!
        $jobTitle: String!
        $startDate: ISO8601DateTime!
        $managerId: ID
        $departmentId: ID
        $locationId: ID
        ${
          this.changeSet.amount != null
            ? `
        $amount: Int
        $currency: String
        $payPeriod: PayPeriodEnum
        `
            : ''
        }
      ) {
        createProfileOnboarding(
          firstName: $firstName
          lastName: $lastName
          personalEmail: $email
          personalPhoneNumber: $phoneNumber
          onboardingTemplateIds: $onboardingTemplateIds
          position: {
            jobTitle: $jobTitle
            startDate: $startDate
            managerId: $managerId
            departmentId: $departmentId
            locationId: $locationId
          }
          ${
            this.changeSet.amount != null
              ? `
          salary: {
            amount: $amount
            currency: $currency
            payPeriod: $payPeriod
            startDate: $startDate
          }
          `
              : ''
          }
        ) {
          onboarding {
            id
          }
        }
      }
    `;

    if (!this.stageDrag.jobApplication) {
      return;
    }

    const candidate = await get(this.stageDrag.jobApplication, 'candidate');

    if (!candidate) {
      return;
    }

    const variables: any = {
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      email: candidate.email,
      phoneNumber: candidate.phone,
      onboardingTemplateIds: this.pickedTemplates.map(
        (template) => template.id
      ),
      jobTitle: this.changeSet.jobTitle,
      startDate: get(this.changeSet, 'startDate'),
      managerId: get(this.changeSet, 'manager.id'),
      departmentId: get(this.changeSet, 'department.id'),
      locationId: get(this.changeSet, 'location.id'),
    };

    const amount = get(this.changeSet, 'amount');
    if (amount != null) {
      variables.amount = amount;
      variables.currency = get(this.changeSet, 'currency');
      variables.payPeriod = (
        get(this.changeSet, 'period') as string | null
      )?.toUpperCase();
    }

    try {
      const response: OnboardingResponse = await this.aboardApollo.mutate({
        mutation,
        variables,
      });

      this.stageDrag.jobApplication.aboardOnboardingId =
        response.createProfileOnboarding.onboarding.id;
      await this.stageDrag.jobApplication.save();

      this.args.onClose();
    } catch (error) {
      this.flashMessages.error('Failed to start onboarding');
      this.moveBack();
    }
  }

  @action
  ttColor(color: string) {
    return color.replace('sky', 'light-blue');
  }

  setDefaultOnboarding(template: AboardTemplate[]) {
    template
      .filter((template) => template.default)
      .forEach((template) => {
        this.pickedTemplates = [...this.pickedTemplates, template];
      });
  }

  async preselectRole() {
    const jobRole = await get(this.args.job, 'role');

    if (jobRole) {
      this.changeSet.jobTitle = jobRole.name;
    } else {
      this.changeSet.jobTitle = this.args.job.title;
    }
  }

  async preselectDepartment(departments: AboardDepartment[]) {
    const jobDepartment = await get(this.args.job, 'department');

    if (!jobDepartment) {
      return;
    }

    departments.forEach((department) => {
      if (department.name.toLowerCase() === jobDepartment.name.toLowerCase()) {
        this.changeSet.department = department;
      }
    });
  }

  async preselectStartDateAndSalary() {
    const jobOffers = get(this.stageDrag.jobApplication, 'jobOffers') || [];

    if (jobOffers.length === 0) {
      return;
    }

    const jobOffer = jobOffers
      .slice()
      .sort(
        (a: JobOfferModel, b: JobOfferModel) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .find((jobOffer: JobOfferModel) => jobOffer.isAccepted);

    if (jobOffer) {
      if (jobOffer.startDate) {
        this.startDateFrom = 'job_offer';
        this.changeSet.startDate = jobOffer.startDate;
      }

      if (jobOffer.salary.amount) {
        this.salaryFrom = 'job_offer';
        this.changeSet.amount = jobOffer.salary.amount;
        this.changeSet.currency = jobOffer.salary.currency;
        this.changeSet.period = jobOffer.salary.period;
      }
    }
  }

  async preselectFromRequisition() {
    const requisition = await get(this.args.job, 'requisition');

    if (!requisition) {
      return;
    }

    if (requisition.minSalary) {
      this.changeSet.currency = requisition.currency;
      this.changeSet.period = requisition.salaryTimeUnit;
      this.salaryFrom = 'requisition';
    }
  }
}
