/* import __COLOCATED_TEMPLATE__ from './default-activation-config.hbs'; */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PartnerDefaultActivationConfigComponent extends Component {
  @service intl;

  @alias('args.partnerActivation.partner') partner;
  @alias('args.partnerActivation.providerKey') providerKey;
  @alias('partner.website') website;

  get partnerName() {
    return this.partner?.name || 'partner';
  }

  get initValue() {
    return this.providerKey?.default_key;
  }

  get providerKeyField() {
    return {
      type: 'text',
      required: 'true',
      id: 'default_key',
      value: this.providerKey,
      description: this.intl.t(
        'settings.integrations.partner_activations.default_activation.key_description',
        { partnerName: this.partnerName }
      ),

      label: 'Integration key',
    };
  }
}
