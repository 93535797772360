import TriggerAdapter from 'teamtailor/adapters/trigger';
import classic from 'ember-classic-decorator';

@classic
export default class HireQualitySurvey extends TriggerAdapter {}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'trigger/hire-quality-survey': HireQualitySurvey;
  }
}
