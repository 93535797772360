/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ToolboxMeetingsContentComponent extends Component {
  @service router;

  @action
  close(_meetingId, meetingEventId) {
    if (meetingEventId) {
      return this.router.transitionTo({
        queryParams: {
          toolbox: [this.args.query, 'show', meetingEventId].join(','),
        },
      });
    }

    return this.router.transitionTo({
      queryParams: { toolbox: this.args.query },
    });
  }

  @action
  openEdit() {
    return this.router.transitionTo({
      queryParams: {
        toolbox: [this.args.query, 'edit', this.args.meetingEventId].join(','),
      },
    });
  }

  @action
  refetchSidebar() {
    this.args.meetingEventQuery.refetchTask.perform();
  }
}
