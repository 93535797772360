/* import __COLOCATED_TEMPLATE__ from './guide-time-modal.hbs'; */
import { action, get, set } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class StageListSettingsGuideTimeModal extends Component {
  @service flashMessages;
  @service intl;
  @service intercom;

  @argDefault saveOnButtonClick = true;

  @tracked
  selectedGuideTime = this.args.stage.guideTime;

  @tracked
  dirty = false;

  guideTimeOptions = [
    14400, 28800, 86400, 172800, 259200, 345600, 432000, 691200, 864000,
    1296000, 1728000,
  ];

  @action
  guideTimeSelected(guideTime) {
    this.selectedGuideTime = guideTime;
    this.dirty = true;
  }

  @action
  async removeGuideTimeClicked() {
    set(this.args, 'stage.guideTime', null);

    if (this.saveOnButtonClick) {
      await this.save();
    }
  }

  @action
  async doneClicked() {
    set(this.args, 'stage.guideTime', this.selectedGuideTime);

    if (this.saveOnButtonClick) {
      await this.save();
    }
  }

  async save() {
    let changedAttributes = this.args.stage.changedAttributes();
    let addedNewGuideTime = !changedAttributes.guideTime[0];

    return this.args.stage
      .save()
      .then(() => {
        this.flashMessages.success(
          isEmpty(get(this.args, 'stage.guideTime'))
            ? this.intl.t('components.stage_list.guide_time_removed')
            : this.intl.t('components.stage_list.guide_time_saved')
        );
        const overdue = get(this.args, 'stage.job.overdueJobApplication');
        if (overdue.isFulfilled) {
          overdue.reload();
        }

        if (addedNewGuideTime) {
          this.intercom.trackEvent('added-stage-guidetime');
        }
      })
      .catch(() => {
        this.flashMessages.error(
          this.intl.t('components.stage_list.couldnt_save_guide_time')
        );
        this.args.stage.rollbackAttributes();
      });
  }
}
