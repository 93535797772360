/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export type Args = {
  checked: boolean;
  isFullWidth?: boolean;
  onChange?: (value: boolean) => void;
};

export default class CoreCheckboxComponent extends Component<Args> {
  @action
  onChange(event: InputEvent) {
    const { checked } = event.target as HTMLInputElement;
    this.args.onChange?.(checked);
  }
}
