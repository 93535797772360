/* import __COLOCATED_TEMPLATE__ from './map-partner-criterium.hbs'; */
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import ScorecardCriteriumModel, {
  TOPIC,
} from 'teamtailor/models/scorecard-criterium';
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';
import MappedPartnerCriteriumModel from 'teamtailor/models/mapped-partner-criterium';
import { AssessmentCriteria } from 'teamtailor/models/trigger/webhook';
import { get } from 'teamtailor/utils/get';
import ScorecardCriteriaSorter from 'teamtailor/utils/scorecard-criteria-sorter';

interface TriggersWebhookPartnerAssessmentCriteriumArgs {
  assessmentCriteria: AssessmentCriteria[];
  scorecardCriteria: ScorecardCriteriumModel[];
  selectedModels: MappedPartnerCriteriumModel[];
  model: MappedPartnerCriteriumModel;
  onDelete(record: MappedPartnerCriteriumModel): void;
}

export default class TriggersWebhookPartnerAssessmentCriterium extends Component<TriggersWebhookPartnerAssessmentCriteriumArgs> {
  @service declare intl: IntlService;

  get pickedScorecardIds() {
    return this.args.selectedModels.toArray().mapBy('scorecardPick.id');
  }

  get availableAssessmentCriteria() {
    return this.assessmentCriteria.filter((assessmentCriteria) => {
      return !this.args.selectedModels.isAny(
        'partnerIdentifier',
        assessmentCriteria.id
      );
    });
  }

  get skillsAndTraitsOptions() {
    return [
      {
        groupName: this.intl.t('common.skills'),
        options: this.scorecardCriteria
          .toArray()
          .filterBy('topic', TOPIC.SKILL)
          .sort(this.sorter.sort),
      },
      {
        groupName: this.intl.t('common.traits'),
        options: this.scorecardCriteria
          .toArray()
          .filterBy('topic', TOPIC.TRAIT)
          .sort(this.sorter.sort),
      },
    ];
  }

  get pickedSkillOrTrait() {
    const skillsAndTraits = this.skillsAndTraits
      .map((groupedOptions) => groupedOptions.options)
      .flat();

    return (
      skillsAndTraits.findBy('id', get(this.args.model.scorecardPick, 'id')) ??
      null
    );
  }

  get pickedAssessmentCriterium() {
    return this.args.assessmentCriteria.find((assessmentCriteria) => {
      return (
        assessmentCriteria.id === get(this.args.model, 'partnerIdentifier')
      );
    });
  }

  get noAssessmentCriteriaSelected() {
    return !get(this.args.model, 'partnerIdentifier');
  }

  private get assessmentCriteria() {
    return this.args.assessmentCriteria;
  }

  private get sorter() {
    return new ScorecardCriteriaSorter(this.intl.primaryLocale);
  }

  private get skillsAndTraits() {
    return [
      {
        groupName: this.intl.t('common.skills'),
        options: this.scorecardCriteria
          .toArray()
          .filterBy('topic', TOPIC.SKILL)
          .sort(this.sorter.sort),
      },
      {
        groupName: this.intl.t('common.traits'),
        options: this.scorecardCriteria
          .toArray()
          .filterBy('topic', TOPIC.TRAIT)
          .sort(this.sorter.sort),
      },
    ];
  }

  private get scorecardCriteria() {
    return this.args.scorecardCriteria;
  }

  @action
  selectAssessmentCriterium(assessmentCriteria: AssessmentCriteria) {
    set(this.args.model, 'partnerIdentifier', assessmentCriteria.id);
  }

  @action
  selectScorecardCriterium(scorecardPick: ScorecardPickModel) {
    set(this.args.model, 'scorecardPick', scorecardPick);
  }
}
