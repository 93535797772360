/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { get, action, set } from '@ember/object';
import moment from 'moment-timezone';
import {
  computedLocalStorage,
  booleanTransform,
} from 'teamtailor/utils/computed-local-storage';
import { getSortOptionLabelString } from 'teamtailor/utils/sort-option-label-string';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';
import sortByRowOrder from 'teamtailor/utils/sort-by-row-order';

export default class Stage extends Component {
  @service permissions;
  @service store;
  @service intl;

  @tracked _viewOverdue = false;
  @tracked sortObject = {
    value: this.sortValue,
    label: this.sortLabel,
  };

  @argDefault mode = 'content';

  get stage() {
    return this.args.stage || {};
  }

  get stageContentInstance() {
    return this.args.stageListInstances[get(this.stage, 'id')];
  }

  get sortValue() {
    return get(this.stage, 'sortValue');
  }

  get sortDirection() {
    return get(this.stage, 'sortDirection');
  }

  get sortLabel() {
    return this.intl.t(getSortOptionLabelString(this.sortValue));
  }

  get viewOverdue() {
    return this._viewOverdue && this.hasOverdueCandidates;
  }

  set viewOverdue(value) {
    this._viewOverdue = value;
  }

  get wideCardKey() {
    return `wideCards_${get(this.stage, 'id')}`;
  }

  @computedLocalStorage(booleanTransform, 'wideCardKey') wideCards;

  get isPastMilestone() {
    let milestone = get(this.stage, 'milestone');
    return milestone ? moment().isAfter(milestone, 'day') : false;
  }

  get hasOverdueCandidates() {
    return get(this.stage, 'overdueGuideTimeCount') > 0;
  }

  get jobApplications() {
    let jobApplications = get(this.stage, 'jobApplications').filter(
      (jobApplication) => {
        const rejectedAt = get(jobApplication, 'rejectedAt');
        return this.args.showRejected === !!rejectedAt;
      }
    );

    if (this.sortObject.value === 'rowOrder') {
      return sortByRowOrder(jobApplications);
    }

    return jobApplications;
  }

  get filteredJobApplications() {
    let applications = get(this, 'jobApplications');

    if (
      this.args.inBulkMode &&
      !this.permissions.has('candidate/access_restricted')
    ) {
      applications = applications.filter((jobApplication) => {
        return get(jobApplication, 'candidate.restricted') === false;
      });
    }

    if (this._viewOverdue) {
      applications = applications.filter(
        (jobApplication) => jobApplication.isGuideTimeOverDue
      );
    }

    return applications;
  }

  @action
  onShowWideCards(value) {
    this.wideCards = value;
  }

  @action
  toggleViewOverdue() {
    this.viewOverdue = !this.viewOverdue;
  }

  @action
  updateSortObject(sortOption) {
    this.sortObject = sortOption;

    const defaultSortDirection = sortOption?.defaultSortDirection;
    if (defaultSortDirection) {
      set(this.stage, 'sortDirection', defaultSortDirection);
    }

    set(this.stage, 'sortValue', sortOption.value);

    this.saveStage();
  }

  @action
  updateSortDirection(sortDirection) {
    set(this.stage, 'sortDirection', sortDirection);

    this.saveStage();
  }

  async saveStage() {
    try {
      if (get(this.stage, 'sortValue') !== 'rowOrder') {
        this.stageContentInstance.setProjectedItemsCount();
      }

      this.stageContentInstance.displayLoadingState = true;

      await this.stage.save();

      await this.stage.fetchJobApplicationsTask.perform({
        rejected: this.args.showRejected,
        reload: true,
        filter: this.args.params,
      });

      this.stageContentInstance.displayLoadingState = false;
    } catch {
      this.stage.rollbackAttributes();
    }
  }
}
