/* import __COLOCATED_TEMPLATE__ from './editor-actions.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import ContentCareerSiteService from 'teamtailor/services/content-career-site';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import TtAlertService from 'teamtailor/services/tt-alert';

type EditorActionsArgs = {
  hasUnpublishedDesignChanges: boolean;
  hasUnpublishedChanges: boolean;
  onPublish: () => Promise<void>;
  onDiscardChanges: () => Promise<void>;
  iframeSrc: string;
};

export default class EditorActionsComponent extends Component<EditorActionsArgs> {
  @service declare contentCareerSite: ContentCareerSiteService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare ttAlert: TtAlertService;

  get changesTooltipText(): string | null {
    if (this.args.hasUnpublishedDesignChanges) {
      return this.intl.t('components.editor_actions.changes.publish_design');
    }

    if (this.args.hasUnpublishedChanges) {
      return this.intl.t('components.editor_actions.changes.publish_page');
    }

    return null;
  }

  publishChangesTask = dropTask(async () => {
    await this.args.onPublish();
  });

  discardChangesTask = dropTask(async () => {
    await this.args.onDiscardChanges();
  });

  get connectPreviewUrl(): string {
    const url = new URL(this.args.iframeSrc);
    url.searchParams.delete('editor');
    return url.toString();
  }

  @action
  publishChanges() {
    return this.publishChangesTask.perform();
  }

  @action
  discardChanges() {
    this.ttAlert.customConfirm({
      title: this.intl.t('content.editor.confirm_discard_changes'),
      text: this.intl.t('content.editor.are_you_sure_discard_changes'),
      cancelButtonText: this.intl.t('common.cancel'),
      confirmButtonClass: 'btn-danger',
      confirmButtonText: this.intl.t('content.editor.yes_discard_changes'),
      confirmCallback: () => {
        try {
          this.discardChangesTask.perform().then(() => {
            this.flashMessages.success(
              this.intl.t('content.editor.changes_discarded')
            );
          });
        } catch (_error) {
          this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
        }
      },
    });
  }

  @action
  blurButton(event: { currentTarget?: { blur: () => void } }): void {
    if (event.currentTarget) {
      event.currentTarget.blur();
    }
  }

  @action
  setQueryParams(queryParams: object): void {
    this.router.transitionTo({ queryParams });
  }

  @action
  goToRoute(route: string): void {
    this.router.transitionTo(route);
  }
}
