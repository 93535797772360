/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

import Section from 'teamtailor/models/section';
import BlockLayout from 'teamtailor/models/block-layout';
import CareerSite from 'teamtailor/models/career-site';
import IntlService from 'ember-intl/services/intl';
import BlockAdjustmentsService from 'teamtailor/services/block-adjustments';
import ENV from 'teamtailor/config/environment';

import Base from './base';

interface Args {
  blockLayout: BlockLayout;
  mockSection: Section;
  careerSite: CareerSite;
  onClose(): void;
  newSection(params: {
    blockName?: string;
    layoutName?: string;
    mockSection?: Section;
    position?: string;
  }): Promise<Section>;
}

export default class BlockLibraryModalPreview extends Base<Args> {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare blockAdjustments: BlockAdjustmentsService;

  @tracked menusVisible = true;

  @action
  didInsert() {
    later(() => {
      this.menusVisible = false;
    }, 1400);
  }

  @action
  async addNewBlock(): Promise<void> {
    await this.args.newSection({
      blockName: this.args.blockLayout.blockName,
      layoutName: this.args.blockLayout.name,
      mockSection: this.args.mockSection,
      position: this.router.currentRoute.queryParams.position,
    });
  }

  @action
  routeBack() {
    this.router.transitionTo('content.editor.block-library');
  }

  @action
  onMouseLeave() {
    this.blockAdjustments.closeAll();
  }

  get careerSitePreviewUrl() {
    const base = this.args.careerSite.url;
    const path = this.args.blockLayout.careerSitePreviewPath;

    if (base && path) {
      const url = new URL(path, base);
      return url.href.replace(url.protocol, `${ENV.httpOrHttps}:`);
    }

    return null;
  }

  get availableBlockLayouts() {
    return this.availableBlockLayoutCategories.find(({ name }) => {
      return this.args.blockLayout.blockName === name;
    })?.blockLayouts;
  }
}
