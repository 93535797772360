/* import __COLOCATED_TEMPLATE__ from './meeting-event-candidate-status-changed.hbs'; */
import { inject as service } from '@ember/service';
import Base from './base';
import { get } from 'teamtailor/utils/get';

export default class CalendarComponent extends Base {
  @service timeFormat;

  get answerTranslationKey() {
    return `components.activity_item.meeting_answers.${get(
      this.activity,
      'actionData.meeting_event_invite_status'
    )}`;
  }
}
