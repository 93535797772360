/* import __COLOCATED_TEMPLATE__ from './meeting-room.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { MeetingEventClass } from 'teamtailor/classes/meetings';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';

interface Args {
  event: MeetingEventClass;
  bookableMeetingRooms: MeetingRoomAttendeeClass[];
  toogleMeetingRoomCallback: () => void;
}

export default class MeetingEventRowsOrganizer extends Component<Args> {
  @service declare intl: IntlService;

  get event(): MeetingEventClass {
    return this.args.event;
  }

  get pickedMeetingRooms(): MeetingRoomAttendeeClass[] | undefined {
    return this.event.meetingEventAttendees.meetingRoomAttendees ?? [];
  }

  get bookableMeetingRooms(): MeetingRoomAttendeeClass[] {
    return this.args.bookableMeetingRooms;
  }

  get meetingRoomLabel(): string {
    if (
      this.pickedMeetingRooms &&
      this.pickedMeetingRooms.length === 1 &&
      this.pickedMeetingRooms[0]?.meetingRoom
    ) {
      return this.pickedMeetingRooms[0]?.meetingRoom.resourceName;
    }

    if (this.pickedMeetingRooms && this.pickedMeetingRooms.length > 1) {
      return this.intl.t(
        'components.meetings.event_scheduler.meeting_rooms_selected',
        { count: this.pickedMeetingRooms.length }
      );
    }

    return this.intl.t('components.meetings.event_scheduler.select');
  }

  get companyHasMeetingRooms(): boolean {
    return (
      Array.isArray(this.args.bookableMeetingRooms) &&
      this.args.bookableMeetingRooms.length > 0
    );
  }

  get organizerHasCronofy(): boolean {
    return this.event.organizer?.hasConnectedCalendar ?? false;
  }

  get showMeetingRooms(): boolean {
    if (this.organizerHasCronofy && this.companyHasMeetingRooms) {
      return true;
    }

    return false;
  }

  get isSelfSchedule(): boolean {
    return this.event.selfSchedule !== undefined;
  }

  @action
  toggleMeetingRoom(attendee: MeetingRoomAttendeeClass | null): void {
    if (
      !this.isSelfSchedule &&
      this.event.meetingEventAttendees.meetingRoomAttendees
    ) {
      this.event.meetingEventAttendees.clear(
        this.event.meetingEventAttendees.meetingRoomAttendees
      );
    }

    if (attendee) this.event.meetingEventAttendees.toggle(attendee);

    this.args.toogleMeetingRoomCallback();
  }

  @action
  clearMeetingRoom(attendee: MeetingRoomAttendeeClass): void {
    this.event.meetingEventAttendees.toggle(attendee);
  }
}
