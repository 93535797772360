import Service, { inject as service } from '@ember/service';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, setProperties, action } from '@ember/object';
import { timeout, restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias, filterBy } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import fuzzysort from 'fuzzysort';
import { get } from 'teamtailor/utils/get';

const FILTER_OPTIONS = [
  { value: 'job', translation: 'components.quick_navigation.filter.jobs' },
  {
    value: 'candidate',
    translation: 'components.quick_navigation.filter.candidates',
  },
  {
    value: 'page',
    translation: 'components.quick_navigation.filter.pages',
  },
  {
    value: 'action',
    translation: 'components.quick_navigation.filter.actions',
  },
  {
    value: 'view',
    translation: 'components.quick_navigation.filter.views',
  },
];

export default class QuickNavigationService extends Service {
  @service store;
  @service router;
  @service appRefresher;
  @service intl;
  @service searchableRoutes;
  @service quickNavigationHistory;
  @service quickNavigationCache;
  @service recentVisits;
  @service user;

  @tracked query = '';
  @tracked filterByType = null;
  @tracked searchResult = null;
  @tracked localResult = null;
  @tracked selectedItem = null;
  @tracked isOpen = false;
  @tracked history = [];
  @tracked selectedFilter = null;
  @tracked showShortcuts = false;

  _filterOptions = FILTER_OPTIONS;
  inputElement = null;

  @alias('searchableRoutes.routes') routes;
  @filterBy('routes', 'createAction', true) createActions;
  @filterBy('createActions', 'default', true) defaultCreateActions;

  @computed('_filterOptions', 'user.role')
  get filterOptions() {
    const role = get(this, 'user.role');

    switch (role) {
      case 'user':
        return this.filtersWithout('page');
      case 'designer':
        return this.filtersWithout('candidate', 'job');
    }

    return this._filterOptions;
  }

  @computed(
    'defaultCreateActions.[]',
    'filterByType',
    'filterOptions',
    'history.[]',
    'query',
    'recentVisits.visits.[]',
    'result.[]',
    'localResult'
  )
  get indexArray() {
    if (!isEmpty(this.query)) {
      return this.result;
    } else {
      return this.localResult
        ? this.localResult
        : [
            ...this.recentVisits.visits,
            ...this.history,
            ...this.defaultCreateActions,
          ];
    }
  }

  @computed('searchResult.[]', 'localResult.[]')
  get result() {
    return [].concat(this.searchResult).concat(this.localResult).compact();
  }

  searchTask = restartableTask(async (query) => {
    query = query.trim();
    const { filterByType } = this;

    if (filterByType === 'action' || filterByType === 'view') {
      this.resetProperties();
      this.filterByRoutesByType(query, filterByType);
      this.selectedItem = this.indexArray[0];
      return undefined;
    }

    if (query === '') {
      this.resetProperties();
      this.selectedItem = this.indexArray[0];
      return undefined;
    }

    const queryObject = {
      query,
      filterByType,
    };
    const cachedResult = this.quickNavigationCache.getResult(queryObject);

    await timeout(cachedResult ? 0 : 300);

    if (cachedResult) {
      this.searchResult = cachedResult;
    } else {
      let record = await this.store.createRecord('search', queryObject).save();
      this.searchResult = record.searchResults.toArray();
      this.quickNavigationCache.add(queryObject, this.searchResult);
    }

    if (!filterByType) {
      this.localResult = this.performLocalSearch(query, this.routes);
    } else {
      this.localResult = [];
    }

    this.selectedItem = this.indexArray[0];

    if (this.searchResult.length) {
      this.quickNavigationHistory.addTask.perform(query, 3000);
    }

    return undefined;
  });

  filtersWithout() {
    const exclude = [...arguments];
    return this._filterOptions.filter((filter) => {
      return !exclude.includes(filter.value);
    });
  }

  resetProperties() {
    setProperties(this, {
      localResult: null,
      searchResult: null,
      selectedItem: null,
    });
  }

  filterByRoutesByType(query, type) {
    const filterType = type === 'action' ? 'createAction' : type;
    const routes = this.routes.filterBy(filterType);
    this.localResult =
      query !== '' ? this.performLocalSearch(query, routes) : routes;
  }

  performLocalSearch(query, pages) {
    return fuzzysort
      .go(query, pages, {
        key: 'searchable',
        allowTypo: false,
        threshold: -10000,
      })
      .mapBy('obj');
  }

  open() {
    this.isOpen = true;
    this.resetSearch();
    this.populateHistory();
    this.selectedItem = this.indexArray[0];
    this.searchableRoutes.fetchRoutes.perform();
  }

  @action close() {
    this.isOpen = false;
    this.resetSearch();
  }

  resetSearch() {
    this.unloadResults();

    this.localResult = null;
    this.searchResult = null;
    this.selectedItem = null;
    this.selectedFilter = null;
    this.filterByType = null;
    this.showShortcuts = false;
    this.query = '';
  }

  populateHistory() {
    this.history.setObjects(
      this.quickNavigationHistory.history.map((title) => {
        return { type: 'search', title };
      })
    );
  }

  unloadResults() {
    this.store.peekAll('search-result').forEach((result) => {
      if (result?.search != null) result.unloadRecord();
    });
    this.store.unloadAll('search');
  }

  @computed('selectedItem', 'indexArray.[]')
  get currentIndex() {
    return this.indexArray.indexOf(this.selectedItem);
  }

  @computed('filterOptions', 'selectedFilter')
  get currentFilterIndex() {
    return this.filterOptions.indexOf(this.selectedFilter);
  }

  @computed('filterOptions', 'selectedItem')
  get filterIsSelected() {
    return this.selectedItem === this.filterOptions[0];
  }

  filterNavigateLeft() {
    const nextIndex = Math.max(this.currentFilterIndex - 1, 0);
    this.selectedFilter = this.filterOptions[nextIndex];
    this.selectedItem = null;
  }

  filterNavigateRight() {
    const nextIndex = Math.min(
      this.filterOptions.length - 1,
      this.currentFilterIndex + 1
    );

    this.selectedFilter = this.filterOptions[nextIndex];
    this.selectedItem = null;
  }

  navigateUp() {
    if (!this.currentIndex || this.selectedFilter) {
      this.selectedItem = null;
      this.inputElement.focus();
    } else {
      const nextIndex = Math.max(this.currentIndex - 1, 0);
      this.selectedItem = this.indexArray[nextIndex];
    }

    this.selectedFilter = null;
  }

  navigateDown() {
    const numberOfResults = this.indexArray.length;
    const nextIndex = Math.min(numberOfResults - 1, this.currentIndex + 1);

    this.selectedItem = this.indexArray[nextIndex];
    this.selectedFilter = null;
  }

  transitionToNewTabSelectedItem() {
    if (this.selectedItem) {
      this.transitionToItem(this.selectedItem, false, true);
    }
  }

  transitionToSelectedItem(useCareersiteUrl = false) {
    if (this.selectedItem) {
      this.transitionToItem(this.selectedItem, useCareersiteUrl, false);
    }
  }

  transitionToItem(item, useCareersiteUrl = false, newTab = false) {
    const { resultId, type, route, query, draftPageId, title, careersiteUrl } =
      item;

    if (useCareersiteUrl) {
      if (careersiteUrl) {
        window.open(careersiteUrl, '_blank');
      } else {
        return;
      }
    }

    if (type === 'search') {
      this.query = title;
      this.searchTask.perform(title);
      return;
    }

    let routeParams;
    if (type === 'job') {
      routeParams = ['jobs.job.stages.index', resultId];
    } else if (type === 'candidate') {
      routeParams = ['candidates.candidate', resultId];
    } else if (type === 'page_publication') {
      routeParams = ['content.editor.index', draftPageId];
    } else if (type === 'page') {
      routeParams = [];
      route && routeParams.addObject(route);
      query && routeParams.addObject({ queryParams: query });
    }

    if (routeParams && routeParams.length) {
      if (newTab) {
        window.open(this.router.urlFor(...routeParams), '_blank');
      } else {
        this.appRefresher.transitionOrNavigateTo(...routeParams);
      }
    }

    if (!item.createAction) {
      this.quickNavigationHistory.addTask.perform(this.query);
    }

    if (!useCareersiteUrl && !newTab) this.close();
  }

  clearHistory() {
    this.history.setObjects([]);
    this.quickNavigationHistory.clear();
  }
}
