/* import __COLOCATED_TEMPLATE__ from './content-item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';

import TtAlertService from 'teamtailor/services/tt-alert';
import TimeFormatService from 'teamtailor/services/time-format';

type InterviewKitContentItemArgs = {
  onRemove?: () => void;
};

export default class InterviewKitContentItem extends Component<InterviewKitContentItemArgs> {
  @service declare timeFormat: TimeFormatService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  @action
  onRemove() {
    this.ttAlert.confirm(
      this.intl.t(
        'components.interview_kit_overview.copilot_answer_remove_sure'
      ),
      () => {
        if (this.args.onRemove) {
          this.args.onRemove();
        }
      }
    );
  }
}
