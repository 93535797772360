/* import __COLOCATED_TEMPLATE__ from './user-referrals.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';

const DATA_QUERY = gql`
  query EmployeeReferralsQuery($dateRange: DateRangeAttributes!, $userId: ID!) {
    user: eventQuery(
      dateRange: $dateRange
      filters: { userId: { equals: $userId } }
      eventTypes: [REFERRAL]
      orderBy: { field: DATE, desc: true }
    ) {
      referrals: rows {
        date
        jobId
        candidateId
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'candidateId',
    headerKey: 'insights.common.candidate_id',
  },
  {
    type: 'text',
    propertyName: 'jobTitle',
    headerKey: 'insights.common.job_title',
  },
  {
    type: 'date',
    propertyName: 'date',
    headerKey: 'common.date',
  },
];

export default class InsightsWidgetsUserReferrals extends Component {
  @service insights;
  @service insightsExport;
  @service intl;
  @service store;
  @service analytics;

  get referrals() {
    return this.fetchData.lastSuccessful?.value?.referrals;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.users.common.referrals',
      `employee-${this.args.user.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange
    );
  }

  @action
  async retrieveExportData(dateRange) {
    const { referrals } = await this.queryForData(dateRange);
    const jobIds = referrals.map((referral) => referral.jobId).filter(Boolean);
    const jobs =
      jobIds.length > 0 &&
      (await this.store.query('job', {
        ids: jobIds,
        groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
      }));

    return referrals.map((referral) => {
      const job = jobs.findBy('id', referral.jobId);
      return {
        date: referral.date,
        candidateId: referral.candidateId,
        jobTitle:
          job?.title ||
          (referral.jobId ? this.intl.t('insights.common.deleted_job') : ''),
      };
    });
  }

  @action
  queryForData(dateRange) {
    const { user } = this.args;
    const userId = get(user, 'id');

    return this.insights
      .query({
        query: DATA_QUERY,
        variables: {
          userId,
          dateRange: dateRange.asObject,
        },
      })
      .then((result) => result.user);
  }

  fetchData = dropTask(async () => {
    const result = await this.queryForData(this.args.dateRange);
    const { referrals } = result;
    const candidateIds = referrals.map((referral) => referral.candidateId);
    const jobIds = referrals.map((referral) => referral.jobId).filter(Boolean);
    const candidates =
      (await jobIds.length) > 0 &&
      this.store.query('candidate', {
        ids: candidateIds,
        groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
      });
    const jobs = await this.store.query('job', {
      ids: jobIds,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    return {
      referrals: referrals.map((referral) => {
        const candidate = candidates.findBy('id', referral.candidateId);
        const job = jobs.findBy('id', referral.jobId);
        return {
          user: this.args.user,
          candidate,
          jobTitle:
            job?.title ||
            (referral.jobId ? this.intl.t('insights.common.deleted_job') : ''),

          ...referral,
        };
      }),
    };
  });
}
