/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { CareerSiteModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';

type OnChangeType = {
  languageCode: string;
  index: number;
};

interface TranslationTabsComponentArgs {
  onChange?: (arg: OnChangeType) => void;
}

export default class TranslationTabsComponent extends Component<TranslationTabsComponentArgs> {
  @service declare current: CurrentService;

  sortTabs(a: CareerSiteModel, b: CareerSiteModel) {
    return a.isDefault
      ? -1
      : b.isDefault
        ? 1
        : a.translatedLanguage.localeCompare(b.translatedLanguage);
  }

  get tabs() {
    return get(this.current.company, 'careerSites')
      .toArray()
      .sort(this.sortTabs);
  }

  @action
  handleTab(index: number, languageCode: string, e: MouseEvent) {
    if (this.args.onChange) {
      (e.target as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
      this.args.onChange({ languageCode, index });
    }
  }
}
