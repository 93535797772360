/* import __COLOCATED_TEMPLATE__ from './choose-recording.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import VideoRoomModel from 'teamtailor/models/video-room';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { recordingTimeFormatter } from 'teamtailor/helpers/recording-time-formatter';
import moment from 'moment-timezone';
import { CompositionStatus } from 'teamtailor/constants/video-room';
import TimeFormatService from 'teamtailor/services/time-format';

export default class MeetingChooseRecording extends Component {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  formatRecordingDate(date: Date): string {
    const momentDate = moment(date);
    moment.locale(this.timeFormat.locale);
    const formattedDate = momentDate.format('HH:mm:ss · DD MMM YYYY ');
    return formattedDate;
  }

  getStatusText(videoRoom: VideoRoomModel): string {
    let status: string;

    switch (videoRoom.compositionStatus) {
      case CompositionStatus.AVAILABLE:
        status = recordingTimeFormatter([videoRoom.duration || 0]);
        break;

      case CompositionStatus.NA:
        status = this.intl.t('components.video_meeting.enqueued');
        break;

      case CompositionStatus.FAILED:
        status = this.intl.t('components.video_meeting.failed');
        break;

      default:
        status = `${videoRoom.compositionProgress || 0}%`;
        break;
    }

    return this.intl.t('components.video_meeting.recording_date_status', {
      status,
    });
  }

  @action
  getText(videoRoom: VideoRoomModel): string {
    return `${this.formatRecordingDate(
      videoRoom.updatedAt
    )} ${this.getStatusText(videoRoom)}`;
  }
}
