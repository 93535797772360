/* import __COLOCATED_TEMPLATE__ from './single-stacked-bar-chart.hbs'; */
import Component from '@glimmer/component';

export default class InsightsChartsStackedBarChart extends Component {
  get categories() {
    return this.args.data.map((data) => data.label);
  }

  get chartData() {
    return this.categories.map((name) => {
      return {
        name,
        data: [this.args.data.findBy('label', name).value],
      };
    });
  }

  get chartOptions() {
    const options = {
      chart: {
        type: 'bar',
        height: '60px',
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
      },

      title: {
        text: null,
      },

      yAxis: {
        visible: false,
        endOnTick: false,
        maxPadding: 0,

        labels: {
          enabled: false,
        },

        title: {
          text: '',
        },
      },

      xAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
      },

      tooltip: {
        followPointer: true,
        headerFormat: '',
      },

      legend: false,

      plotOptions: {
        series: {
          stacking: 'normal',
          borderColor: null,
          pointWidth: 40,
        },
      },
    };

    return options;
  }
}
