import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class InterviewKitSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    scorecardPicks: {
      key: 'scorecard_picks_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },

    pickedQuestions: {
      key: 'picked_questions_attributes',
      serialize: 'records',
      deserialize: 'records',
    },

    tags: { serialize: 'records', key: 'tag_list' },
    createdAt: false,
  };

  serialize() {
    const json = super.serialize(...arguments);
    if (json.tag_list.length) {
      json.tag_list = json.tag_list.map((tag) => tag.name);
    }

    this.removeCompetenceOrderModels(json.competence_order);

    return json;
  }

  removeCompetenceOrderModels(items) {
    if (!items) {
      return;
    }

    items.map((item) => {
      delete item.model;
      this.removeCompetenceOrderModels(item.children);
    });
  }
}
