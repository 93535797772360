import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import CurrentService from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';

export default class RecruiterCopilotController extends Controller {
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare current: CurrentService;
  @service declare store: Store;
  @service declare flipper: FlipperService;

  get copilotSettings() {
    return this.model;
  }

  get suggestExistingCandidatesDisabled(): boolean {
    if (this.current.company.hasCopilotFeature('suggest_existing_candidates')) {
      return false;
    }

    return (
      get(this.flipper, 'disable_enabling_of_suggest_existing_candidates') &&
      !get(
        this.flipper,
        'disable_enabling_of_suggest_existing_candidates_except_for'
      )
    );
  }

  get answerInterviewKitDisabledReason(): string | undefined {
    const interviewKitEnabled =
      this.current.company.hasFeature('interview_kit');
    const videoRecordingEnabled =
      this.current.company.hasFeature('video_recording');

    if (!interviewKitEnabled && !videoRecordingEnabled) {
      return this.intl.t(
        'settings.recruiter_copilot.interview_kit_question_answers.disabled_reason_ik_vr'
      );
    } else if (!interviewKitEnabled) {
      return this.intl.t(
        'settings.recruiter_copilot.interview_kit_question_answers.disabled_reason_ik'
      );
    } else if (!videoRecordingEnabled) {
      return this.intl.t(
        'settings.recruiter_copilot.interview_kit_question_answers.disabled_reason_vr'
      );
    }
  }

  @action toggleFeature(
    name: string,
    enabled: boolean,
    event: PointerEvent
  ): void {
    // Prevents double execution when clicking on the input in <LabelSwitch>
    event.preventDefault();
    this.setCopilotFeature(name, enabled);
  }

  @action
  async setCopilotFeature(name: string, enabled: boolean): Promise<void> {
    const setting = get(this.current.company, 'copilotSettings').find(
      (c) => c.name === name
    );

    if (setting) {
      setting.enabled = enabled;
      await setting.save();
    } else {
      const newSetting = await this.store
        .createRecord('copilot-setting', {
          name,
          enabled,
        })
        .save();
      this.current.company.copilotSettings.pushObject(newSetting);
    }

    this.flashMessages.success(
      this.intl.t('settings.recruiter_copilot.settings_updated')
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.recruiter-copilot': RecruiterCopilotController;
  }
}
