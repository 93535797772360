/* import __COLOCATED_TEMPLATE__ from './heading.hbs'; */
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { IntlService } from 'ember-intl';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class OrganizationHeadingController extends Component {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare current: Current;

  get divisionsCount() {
    return get(get(this.current.company, 'divisionCount'), 'count');
  }

  get departmentsCount() {
    return get(get(this.current.company, 'departmentCount'), 'count');
  }

  get rolesCount() {
    return get(get(this.current.company, 'roleCount'), 'count');
  }

  get tabs() {
    const tabsList = [
      {
        route: 'settings.organization.departments.index',
        text: this.intl.t('settings.departments.title'),
        count: this.departmentsCount,
      },
    ];

    if (this.departmentsCount > 0) {
      tabsList.push({
        route: 'settings.organization.roles.index',
        text: this.intl.t('settings.roles.title'),
        count: this.rolesCount,
      });
    }

    if (this.current.company.hasFeature('divisions')) {
      tabsList.unshift({
        route: 'settings.organization.divisions.index',
        text: this.intl.t('settings.divisions.title'),
        count: this.divisionsCount,
      });
    }

    return tabsList;
  }
}
