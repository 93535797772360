/* import __COLOCATED_TEMPLATE__ from './job-match.hbs'; */
import Component from '@glimmer/component';
import CandidateModel from 'teamtailor/models/candidate';
import JobApplicationModel from 'teamtailor/models/job-application';
import { get } from 'teamtailor/utils/get';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel;
}

export default class CandidateModalNewMainJobMatchComponent extends Component<Args> {
  get candidate() {
    return this.args.candidate;
  }

  get jobApplication() {
    return this.args.jobApplication;
  }

  get job() {
    return get(this.jobApplication, 'job');
  }

  get jobId() {
    return get(this.job, 'id');
  }

  get scorecardPicks() {
    const jobDetail = get(this.job, 'jobDetail');

    return get(jobDetail, 'scorecardPicks').filter((scorecardPick) => {
      return !!scorecardPick.weight;
    });
  }

  get interviews() {
    return get(this.candidate, 'interviews')
      .rejectBy('isDraft')
      .filterBy('jobId', this.jobId);
  }
}
