/* import __COLOCATED_TEMPLATE__ from './library-item.hbs'; */
import Component from '@glimmer/component';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias, notEmpty, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class DashboardLibraryItemComponent extends Component {
  @service current;
  @alias('args.widget') widget;
  @alias('widget.emoji') emoji;
  @or('widget.titleTranslated', 'widget.title') title;
  @notEmpty('widget.color') isColored;

  @computed('widget.name')
  get widgetNameClass() {
    return `c-dashboard-widget--${this.widget.name}`;
  }

  @computed('widget.color')
  get widgetColorClass() {
    return this.widget.color === ''
      ? ''
      : `c-dashboard-widget--color-${this.widget.color}`;
  }
}
