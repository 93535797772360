/* import __COLOCATED_TEMPLATE__ from './ziggeo-player.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import TtZiggeo from 'teamtailor/services/tt-ziggeo';

export default class ZiggeoPlayerComponent extends Component<{
  videoToken: string;
  answerId: string;
}> {
  @service declare timeFormat: TimeFormatService;
  @service declare ttZiggeo: TtZiggeo;

  @tracked
  videoIsLoaded = false;

  @action
  async handleVideoElDidInsert(el: HTMLElement) {
    ZiggeoApi.V2.Locale.setLocale(this.timeFormat.locale);

    let clientAuthToken = await this.ttZiggeo.clientAuthTokenAnswer(
      this.args.answerId
    );

    const player = new ZiggeoApi.V2.Player({
      element: el,
      attrs: {
        video: this.args.videoToken,
        'client-auth': clientAuthToken,
      },
    });
    player.on('loaded', () => {
      this.videoIsLoaded = true;
    });
    player.activate();
  }
}
