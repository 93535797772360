/* import __COLOCATED_TEMPLATE__ from './first-render.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce } from '@ember/runloop';

export default class FirstRenderComponent extends Component {
  @tracked isFirstRender = true;

  constructor() {
    super(...arguments);
    scheduleOnce('afterRender', this, this.setIsFirstRender);
  }

  setIsFirstRender() {
    this.isFirstRender = false;
  }
}
