import Service, { inject as service } from '@ember/service';
import RouteHelperService from 'teamtailor/services/route-helper';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';

export default class ModalService extends Service {
  @service declare routeHelper: RouteHelperService;
  @service declare router: RouterService;

  openModals: HTMLElement[] = [];

  @action
  registerOpenModal(el: HTMLElement) {
    this.openModals.push(el);

    return () => this.unregisterOpenModal(el);
  }

  @action
  unregisterOpenModal(thisModalBaseEl: HTMLElement) {
    this.openModals = this.openModals.filter(
      (modalBaseEl) => modalBaseEl !== thisModalBaseEl
    );
  }

  @action
  closeCandidateModal(candidateId: string) {
    if (this.routeHelper.parentRoute !== undefined) {
      return this.router.transitionTo(
        this.routeHelper.parentRoute,
        candidateId
      );
    }

    return window.history.back();
  }

  @action
  closeMeetingModal(meetingId?: string, meetingEventId?: string) {
    if (this.routeHelper.parentRoute !== undefined) {
      if (meetingId && meetingEventId) {
        return this.router.transitionTo(
          this.routeHelper.getNestedRoute('meetings.events/show'),
          meetingId,
          meetingEventId
        );
      }

      return this.router.transitionTo(this.routeHelper.parentRoute);
    }

    return window.history.back();
  }
}

declare module '@ember/service' {
  interface Registry {
    modal: ModalService;
  }
}
