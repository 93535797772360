/* import __COLOCATED_TEMPLATE__ from './dropdown-menu.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import { TrackedMap } from 'tracked-built-ins';
import { Options as FocusTrapOptions } from 'focus-trap';
import { CoreDropdownContainerApi } from './dropdown/container';
import { modifier } from 'ember-modifier';
import { consume } from 'ember-provide-consume-context';
import { FieldContext } from './form/field';
import { argDefault } from 'teamtailor/utils/arg-default';

type CoreDropdownMenuArgs = {
  isOpen?: boolean;
  onIsOpenChange?: (isOpen: boolean) => void;
  onOpen?: () => void;
  onClose?: () => void;
  focusTrapOptions?: FocusTrapOptions;
  isSubmenu?: boolean;
  destinationElement?: HTMLElement;
  onRegister?: (api: CoreDropdownMenuApi) => void;
  showOnHover?: boolean;
  useFieldContext?: boolean;
};

type OnInsertSubMenuContainerData = {
  button?: HTMLButtonElement;
};

type OnDestroySubMenuContainerData = {
  isClosedByKeyboard?: boolean;
};

export type CoreDropdownMenuApi = {
  close: () => void;
  open: () => void;
  addClickOutsideElement: (
    element: HTMLElement,
    { button }: OnInsertSubMenuContainerData
  ) => void;
  isOpen: boolean;
  openSubMenus: TrackedMap<HTMLElement, OnInsertSubMenuContainerData>;
  updatePosition?: () => void;
  containerElement?: HTMLElement;
  containerKeyboardPriority?: number;
};

export default class CoreDropdownMenu<Args> extends Component<
  CoreDropdownMenuArgs & Args
> {
  @consume('core/form/field') fieldContext?: FieldContext;

  @tracked _isOpen = false;

  @argDefault useFieldContext = true;

  declare buttonElement: HTMLButtonElement;

  get isOpen() {
    return this.args.isOpen ?? this._isOpen;
  }

  set isOpen(val) {
    if (!isEmpty(this.args.isOpen)) {
      this.args.onIsOpenChange?.(val);
    } else {
      this._isOpen = val;
    }
  }

  get hideMenu() {
    return this.args.showOnHover && !this.isOpen;
  }

  @tracked isOpenedByKeyboard = false;
  @tracked activeItemEl?: HTMLElement | null = null;

  triggerIdDefault = `trigger-${guidFor(this)}`;
  get triggerId() {
    return this.useFieldContext
      ? this.fieldContext?.fieldElementId || this.triggerIdDefault
      : this.triggerIdDefault;
  }

  listboxId = `listbox-${guidFor(this)}`;

  getElement(id: string) {
    return (
      document.getElementById(`${id}-candidate-modal`) ||
      document.getElementById(id)
    );
  }

  get destinationElement() {
    return this.args.isSubmenu
      ? this.getElement('ember-teamtailor-dropdown-submenu')
      : this.args.destinationElement ||
          this.getElement('ember-teamtailor-dropdown');
  }

  @tracked containerApi?: CoreDropdownContainerApi;

  openSubMenus = new TrackedMap<HTMLElement, OnInsertSubMenuContainerData>();

  get api(): CoreDropdownMenuApi {
    const parentThis = this;
    return {
      close: this.close,
      open: this.open,
      addClickOutsideElement: this.onInsertSubMenuContainer,
      get isOpen() {
        return parentThis.isOpen;
      },

      openSubMenus: this.openSubMenus,

      get containerKeyboardPriority() {
        return parentThis.containerApi?.keyboardPriority;
      },

      get updatePosition() {
        return parentThis.containerApi?.updatePosition;
      },

      get containerElement() {
        return parentThis.containerElement;
      },
    };
  }

  @action
  close() {
    if (this.isOpen) {
      this.isOpen = false;
      this.onClose();
    }
  }

  @action
  open({ isOpenedByKeyboard = false } = {}) {
    this.isOpenedByKeyboard = isOpenedByKeyboard;
    if (!this.isOpen) {
      this.isOpen = true;
      this.onOpen();
    }
  }

  onOpen() {
    next(() => {
      this.args.onOpen?.();
    });
  }

  onClose() {
    next(() => {
      this.args.onClose?.();
    });
  }

  @action
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as Element | null;
    if (
      targetElement &&
      typeof targetElement.closest === 'function' &&
      !targetElement.closest('.flatpickr-calendar') &&
      targetElement.getAttribute('type') !== 'file'
    ) {
      this.close();
    }
  }

  containerElement?: HTMLElement;

  @tracked
  extraClickOutsideElements: HTMLElement[] = [];

  @action
  onInsertSubMenuContainer(
    element: HTMLElement,
    { button }: OnInsertSubMenuContainerData = {}
  ) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    this.openSubMenus.set(element, { button });
    this.extraClickOutsideElements.pushObject(element);
    return this.onDestroySubMenuContainer;
  }

  @action
  onDestroySubMenuContainer(
    element: HTMLElement,
    { isClosedByKeyboard }: OnDestroySubMenuContainerData = {}
  ) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    const newActiveItemEl = this.openSubMenus.get(element)?.button;

    this.openSubMenus.delete(element);
    if (isClosedByKeyboard) {
      this.activeItemEl = newActiveItemEl;
    }

    this.extraClickOutsideElements.removeObject(element);
  }

  @action
  onToggleMenu(e?: UIEvent) {
    const options = { isOpenedByKeyboard: e?.detail === 0 };

    if (this.isOpen) {
      this.close();
    } else {
      this.open(options);
    }
  }

  @action handleTriggerKeydown(e: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
      e.preventDefault(); // prevent scrolling
      e.stopPropagation();
    }
  }

  @action handleTriggerKeyup(e: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
      this.open({ isOpenedByKeyboard: true });
    }
  }

  onInsertButton = modifier((element: HTMLButtonElement) => {
    this.buttonElement = element;
    this.extraClickOutsideElements.push(this.buttonElement);
    this.args.onRegister?.(this.api);
  });

  onInsert = modifier((element: HTMLElement) => {
    this.containerElement = element;
  });
}
