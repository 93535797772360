/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import { DepartmentModel, RoleModel } from 'teamtailor/models';
import Store from '@ember-data/store';
import FlipperService from 'teamtailor/services/flipper';

const REQUIRED_PROPS = ['name'];

export default class DepartmentsFormComponent extends TranslatableFormBaseComponent<DepartmentModel> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare flipper: FlipperService;

  translationsProps = REQUIRED_PROPS;

  get department() {
    return this.args.translatableModel as DepartmentModel;
  }

  get divisions() {
    return get(this.current.company, 'divisionsWithoutNoDivision');
  }

  get roles() {
    return get(this.department, 'roles').filter(
      (role: RoleModel) => !role.isDeleted
    );
  }

  @action
  async addRole() {
    this.store.createRecord('role', {
      department: this.department,
      languageCode: this.defaultLanguageCode,
    });
  }

  @action
  async deleteRole(role: RoleModel) {
    await role.deleteRecord();
  }
}
