/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import SectionItemSocialLinksValidations from 'teamtailor/validations/section-item/social-links';

export default class SocialLinksBaseComponent extends BaseComponent {
  @service directUpload;
  @service iframePreview;
  @service intl;
  @service store;
  @service ttAlert;

  validator = SectionItemSocialLinksValidations;

  get sectionItem() {
    return this.args.sectionItem;
  }

  reloadSection() {
    const sectionId = get(this, 'sectionItem.section.id');
    this.iframePreview.reloadSection(sectionId);
  }

  @action
  async handleSaveItem(changeset) {
    await changeset.validate();
    if (changeset.isValid) {
      await changeset.save();
      this.reloadSection();
    }
  }

  @action
  handleDelete() {
    this.ttAlert.confirm(
      this.intl.t('content.editor.section.items.delete_confirm'),
      () => {
        this.sectionItem.destroyRecord().then(() => {
          this.reloadSection();
        });
      },
      () => {},
      {
        confirmButtonText: this.intl.t('common.delete'),
      }
    );
  }
}
