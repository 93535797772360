/* import __COLOCATED_TEMPLATE__ from './move.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { JobBulkArgs } from 'teamtailor/constants/jobs';
import DepartmentModel from 'teamtailor/models/department';
import RoleModel from 'teamtailor/models/role';
import CurrentService from 'teamtailor/services/current';
import { DivisionModel } from 'teamtailor/models';

export default class BulkActionsJobsMoveComponent extends Component<JobBulkArgs> {
  @service declare current: CurrentService;

  @tracked division: DivisionModel | null = null;
  @tracked department: DepartmentModel | null = null;
  @tracked role: RoleModel | null = null;

  @action
  onClose(): void {
    this.args.onClose();
    this.department = null;
    this.role = null;
  }

  @action
  handleBulkChange(): void {
    const data = {
      division_id: this.division?.id ?? null,
      department_id: this.department?.id ?? null,
      role_id: this.role?.id ?? null,
    };
    this.args.handleBulkChange(data, 'bulk_move');
    this.onClose();
  }
}
