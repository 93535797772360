/* import __COLOCATED_TEMPLATE__ from './advanced-partner-result-filters.hbs'; */
import { action, get } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { STATUSES } from 'teamtailor/constants/partner-results';

export default class CandidatesAdvancedPartnerResultFiltersComponent extends Component {
  @service intl;
  @tracked partnerResultsMetadata;
  @tracked partnerResultMax;
  @tracked partnerResultMin;
  @tracked partnerResultStatus;

  min = 0;
  max = 100;

  get value() {
    return [this.min, this.max];
  }

  get params() {
    return {
      partner_results_min_score: this.partnerResultMin,
      partner_results_max_score: this.partnerResultMax,
      partner_results_status: this.partnerResultStatus,
    };
  }

  get statuses() {
    const translatedStatus = (status) => ({
      id: status,
      intl: this.intl.t(`components.search_filters.partner_result.${status}`),
    });

    return STATUSES.map(translatedStatus);
  }

  get useRange() {
    return this.min !== this.max;
  }

  get showAssessmentScoresRange() {
    return get(this, 'args.partner.capabilityAssessments');
  }

  @action onStatusChange(status) {
    this.partnerResultStatus = status?.id;
    this.args.onPartnerFilterChange(this.params);
  }

  @action
  onRangeChange(value) {
    const [min, max] = Array.isArray(value) ? value : [value, value];
    this.partnerResultMin = min;
    this.partnerResultMax = max;

    this.args.onPartnerFilterChange(this.params);
  }
}
