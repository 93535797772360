/* import __COLOCATED_TEMPLATE__ from './qr-code.hbs'; */
import PromotionBaseClass from './promotion-base';
import { action } from '@ember/object';

export default class extends PromotionBaseClass {
  get isGenerateStep() {
    return this.args.promotion.isNew;
  }

  @action
  saveQrCode() {
    return this.args.promotion.save();
  }

  @action
  handleQrCodeSettinsChange(settings) {
    this.args.promotion.qrCodeSettings = settings;
  }

  @action
  downloadPoster() {}
}
