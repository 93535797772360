/* import __COLOCATED_TEMPLATE__ from './text-answers.hbs'; */
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import SessionService from 'ember-simple-auth/services/session';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import TimeFormatService from 'teamtailor/services/time-format';
import Server from 'teamtailor/services/server';

type AnswerValue = {
  text: string;
  candidate_id: string;
  candidate: {
    nameOrEmail: string | null;
  } | null;
};

type Answer = {
  values: AnswerValue[];
  number_of_picks: {
    by_total: number;
  };
};

type Question = {
  id: string;
  answers: Answer;
  type: string;
};

type SurveyTextAnswersArgs = {
  question: Question;
  surveyId: string;
  isAnonymous: boolean;
};

const INITIAL_SHOWN_ANSWERS = 3;

export default class SurveyTextAnswersComponent extends Component<SurveyTextAnswersArgs> {
  @service declare store: Store;
  @service declare server: Server;
  @service declare session: SessionService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare timeFormat: TimeFormatService;

  @tracked hideInitialAnswers = true;
  @tracked page = 1;
  @tracked declare answersValues: AnswerValue[];

  initalShownAnswers = INITIAL_SHOWN_ANSWERS;

  constructor(owner: unknown, args: SurveyTextAnswersArgs) {
    super(owner, args);
    this.answersValues = this.addCandidates(
      this.answers.values.slice(0, this.initalShownAnswers)
    );
  }

  get answers(): Answer {
    return this.args.question.answers;
  }

  get numOfAnswers(): number {
    return this.answers.number_of_picks.by_total;
  }

  get showAnswers(): boolean {
    return !this.args.isAnonymous || this.numOfAnswers >= 10;
  }

  get isLongTextQuestion(): boolean {
    return this.args.question.type === 'FormQuestion::TextArea';
  }

  get showLoadMoreAnswersButton(): boolean {
    return this.answersValues.length < this.answers.number_of_picks.by_total;
  }

  updateAnswersValues(values: AnswerValue[]) {
    const currentSet = new Set([
      ...this.answersValues,
      ...this.addCandidates(values),
    ]);
    this.answersValues = Array.from(currentSet);
  }

  addCandidates(answers: AnswerValue[]): AnswerValue[] {
    if (this.args.isAnonymous) {
      return answers;
    }

    answers.forEach((answer) => {
      answer.candidate = answer.candidate_id
        ? this.store.peekRecord('candidate', answer.candidate_id)
        : null;
    });
    return answers;
  }

  fetchAnswersTask = restartableTask(async () => {
    try {
      const adapter = this.store.adapterFor('application');
      const url = `${adapter.buildURL(
        'survey',
        this.args.surveyId
      )}/questions/${this.args.question.id}/results`;

      const { values, page_number } = await this.server
        .request(url, 'GET', {
          data: {
            per_page: '50',
            page: (this.page + 1).toString(),
          },
        })
        .then(
          (json) =>
            (
              json as {
                form_question_result: {
                  answers: { values: AnswerValue[]; page_number: number };
                };
              }
            ).form_question_result.answers
        );

      this.page = page_number;
      this.updateAnswersValues(values);
    } catch (e) {
      this.flashMessages.error(
        this.intl.t(
          'settings.requisitions.custom_form.could_not_load_more_responses'
        )
      );
    }
  });

  @action
  async handleLoadMoreAnswers() {
    if (this.hideInitialAnswers && this.page === 1) {
      this.hideInitialAnswers = false;
      this.updateAnswersValues(this.answers.values);
    } else {
      await this.fetchAnswersTask.perform();
    }
  }
}
