/* import __COLOCATED_TEMPLATE__ from './unread-activities.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import ActivityModel from 'teamtailor/models/activity';

type Args = {
  jobId: string;
  latestJobCommentReadDateAt: Date;
};

export default class UnreadActivitiesComponent extends Component<Args> {
  @service declare store: Store;

  resolveUnreadActivityResponses = trackedFunction(this, async () => {
    const activities: ActivityModel[] = await this.store.query('activity', {
      job_id: this.args.jobId,
      collaborate: true,
      filter_mentioned_actions: true,
    });
    const jobActivities = activities.slice(0);

    const unreadComments = jobActivities.filter((activity) => {
      return activity.createdAt >= this.args.latestJobCommentReadDateAt;
    });
    return unreadComments;
  });

  get isLoading() {
    return (
      this.resolveUnreadActivityResponses.isLoading ||
      this.resolveUnreadActivityResponses.value === null
    );
  }

  get sortedUnreadActivities(): ActivityModel[] {
    return this.resolveUnreadActivityResponses.value || [];
  }
}
