import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class StagesRoute extends Route {
  @service pusherForJobs;

  async setupController(controller, model) {
    super.setupController(...arguments);
    (await this.pusherForJobs.setup(model)).bindStageEvents();
  }

  resetController() {
    this.pusherForJobs.unbindStageEvents();
  }
}
