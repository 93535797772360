/* import __COLOCATED_TEMPLATE__ from './stage-triggers-list.hbs'; */
import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';

export default class StageTriggersListComponent extends Component {
  get stageTriggers() {
    return this.args.stage.triggers;
  }

  get visibleTriggers() {
    const stageTriggers = get(this, 'stageTriggers');
    return stageTriggers.filter(
      (trigger) =>
        !get(trigger, 'isNew') &&
        !get(trigger, 'isDeleted') &&
        !get(trigger, 'hideOnStage')
    );
  }

  get sortedStageTriggers() {
    return this.visibleTriggers.sortBy(
      'visibleTriggers',
      'delayJobFor',
      'created_at'
    );
  }

  get filteredStageTriggers() {
    return get(this, 'sortedStageTriggers').filterBy(
      'onReject',
      this.args.onReject
    );
  }
}
