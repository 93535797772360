/* import __COLOCATED_TEMPLATE__ from './status-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Task, timeout, dropTask } from 'ember-concurrency';
import { statusButtonTimeout } from 'teamtailor/constants/timeout';
import ENV from 'teamtailor/config/environment';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'ember-resources/util/function';

interface StatusButtonSignature {
  Args: {
    disabled?: boolean;
    isDisabled?: boolean;
    icon?: string;
    actionTask?: Task<void, unknown[]>;
    action?: () => Promise<void>;
    afterAction?: () => Promise<void>;
  };
}

export default class StatusButtonComponent extends Component<StatusButtonSignature> {
  @tracked isSuccess = false;
  @tracked isFailure = false;

  get inFlight() {
    return this.taskIsRunning && !this.isSuccess && !this.isFailure;
  }

  get disabled() {
    return this.args.disabled || this.args.isDisabled || false;
  }

  get icon() {
    if (this.inFlight) {
      return 'spinner';
    } else if (this.isSuccess) {
      return 'check';
    } else if (this.isFailure) {
      return 'ban';
    } else if (this.args.icon) {
      return this.args.icon;
    }
  }

  get taskIsRunning() {
    return (
      this.outerActionTaskHandler.value?.isRunning || this.actionTask.isRunning
    );
  }

  outerActionTaskHandler = trackedFunction(this, async () => {
    const { actionTask } = this.args;

    if (!actionTask) {
      return;
    }

    const { last, lastSuccessful, lastErrored } = actionTask;

    if (last && last === lastSuccessful) {
      await this.success();
    } else if (last && last === lastErrored) {
      await this.failure();
    }

    return actionTask;
  });

  actionTask = dropTask(async () => {
    try {
      await this.args.action?.();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.cancel) {
        return;
      }

      if (ENV.environment === 'development' || ENV.environment === 'test') {
        // eslint-disable-next-line no-console
        console.error('StatusButton', e);
      }

      await this.failure();
      return;
    }

    await this.success();
  });

  async failure() {
    this.isFailure = true;
    await timeout(statusButtonTimeout);
    this.isFailure = false;
  }

  async success() {
    this.isSuccess = true;
    await timeout(statusButtonTimeout);

    if (this.args.afterAction) {
      await this.args.afterAction();
    }

    this.isSuccess = false;
  }

  @action
  handleClick(event: MouseEvent) {
    event.preventDefault();

    if (this.args.actionTask) {
      this.args.actionTask.perform();
    } else {
      this.actionTask.perform();
    }
  }
}
