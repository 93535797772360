/* import __COLOCATED_TEMPLATE__ from './wrapper.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PromotionCardsBaseInternal extends Component {
  @service current;
  @service router;

  get channel() {
    return this.args.channel;
  }

  get job() {
    return this.args.job;
  }

  @action
  handleClick() {
    if (!this.args.job.isPromotableForInternalChannels) {
      return;
    }

    if (this.args.onClick) {
      this.args.onClick(this.channel);
    } else {
      this.router.transitionTo('jobs.job.promote.pick', this.channel, {
        queryParams: { currency: this.channel.currency },
      });
    }
  }
}
