/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import UserModel from 'teamtailor/models/user';
import { SafeString } from '@ember/template/-private/handlebars';
import { isNone } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { stripHtml } from 'teamtailor/utils/string-sanitizer';

type CoreDropdownContentComponentArgs = {
  user?: UserModel;
  text?: string;
  searchTerm?: string;
  activeItemEl: HTMLElement;
  onActiveItemElChange: (el: HTMLElement) => void;
  as?: Component;
  activatableEl?: HTMLButtonElement | HTMLAnchorElement;
};

export default class CoreDropdownContentComponent extends Component<CoreDropdownContentComponentArgs> {
  @tracked declare activatableEl?: HTMLButtonElement | HTMLAnchorElement;

  get isActiveItemEl() {
    return this.activatableEl && this.args.activeItemEl === this.activatableEl;
  }

  get typeAttr() {
    return isNone(this.args.as) ? 'button' : undefined;
  }

  get text() {
    let text: string | SafeString =
      this.args.text ??
      (this.args.user ? get(this.args.user, 'nameOrEmail') : '');

    if (this.args.user) {
      text = stripHtml(text);
    }

    if (this.args.searchTerm && text) {
      text = text.replace(
        new RegExp(`(${this.args.searchTerm})`, 'gi'),
        '<b>$1</b>'
      );
    }

    return text;
  }
}
