import Controller from '@ember/controller';
import { setProperties, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import { get } from 'teamtailor/utils/get';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { compare } from '@ember/utils';

const stageBasedCompareFn = (a, b) => {
  if (a.stage.id === b.stage.id) {
    const direction = get(a.stage, 'sortDirection') === 'asc' ? 1 : -1;
    const sortKey = get(a.stage, 'sortValue');
    return direction * compare(get(a, sortKey), get(b, sortKey));
  }
  return compare(a.stage.id, b.stage.id);
};

export default class StagesIndexController extends Controller.extend(
  SearchQuery
) {
  @service confetti;
  @service dragSort;
  @service current;
  @service store;
  @service permissions;

  @tracked tags = [];
  @tracked custom_fields = [];
  @tracked ratings = [];
  @tracked reviewed_by = null;
  @tracked query = '';
  @tracked location_id = null;
  @tracked questionFilters = [];
  @tracked showRejected = false;
  @tracked expired_consent = null;
  @tracked missing_consent = null;
  @tracked removal_requested = null;
  @tracked consent_future_jobs = null;
  @tracked created_start_date = null;
  @tracked created_end_date = null;
  @tracked activity_start_date = null;
  @tracked activity_end_date = null;
  @tracked internal = null;
  @tracked sourced = null;
  @tracked sourced_external = null;
  @tracked partner_id = null;
  @tracked partner_results_max_score = null;
  @tracked partner_results_min_score = null;
  @tracked partner_results_status = null;
  @tracked inBulkMode = false;
  @tracked selectedJobApplications = [];
  @tracked sidebarToShow = null;

  @computedLocalStorage(Boolean, 'hideSuggestedCandidatesStage', false)
  hideSuggestedStage;

  stageListInstances = {};

  get questions() {
    return this.questionFilters;
  }

  set questions(questionFilters) {
    this.questionFilters = questionFilters;
  }

  get hasModifiedFilters() {
    return this.model && this.model.candidateIds !== null;
  }

  get selectedCandidateIds() {
    return this.selectedJobApplications
      .sort(stageBasedCompareFn)
      .map((jobApplication) => jobApplication.candidateId);
  }

  get suggestedStageAvailable() {
    const hasFeature = this.current.company.hasCopilotFeature(
      'suggest_existing_candidates'
    );

    return (
      hasFeature &&
      (this.model.job.suggestedStageEnabled ||
        this.permissions.has('job/update'))
    );
  }

  get showSuggestedStage() {
    const notHidden = !this.hideSuggestedStage;

    return this.suggestedStageAvailable && notHidden;
  }

  registerStageListInstance(stageListInstance) {
    const stageId = get(stageListInstance, 'args.stage.id');
    this.stageListInstances[stageId] = stageListInstance;

    return () => {
      delete this.stageListInstances[stageId];
    };
  }

  @action
  toggleBulk() {
    if (this.inBulkMode) {
      this.selectedJobApplications.clear();
      for (const stage of this.model.job.stages) {
        stage.allCandidatesSelected = false;
      }
    }

    this.inBulkMode = !this.inBulkMode;
  }

  @action
  clearSelection() {
    get(this, 'selectedJobApplications').clear();
  }

  @action
  clearFilters() {
    setProperties(this, {
      tags: [],
      custom_fields: [],
      ratings: [],
      questionFilters: [],
      reviewed_by: null,
      query: '',
      location_id: null,
      internal: null,
      sourced: null,
      sourced_external: null,
      showRejected: false,
      expired_consent: null,
      missing_consent: null,
      removal_requested: null,
      consent_future_jobs: null,
      created_start_date: null,
      created_end_date: null,
      activity_start_date: null,
      activity_end_date: null,
      partner_id: null,
      partner_results_max_score: null,
      partner_results_min_score: null,
      partner_results_status: null,
    });
  }

  @action
  toggleRejected() {
    get(this, 'selectedJobApplications').clear();
    this.showRejected = !this.showRejected;
  }

  @action
  selectJobApplication(jobApplication, forceAdd) {
    if (
      get(this, 'selectedJobApplications').includes(jobApplication) &&
      !forceAdd
    ) {
      get(this, 'selectedJobApplications').removeObject(jobApplication);
    } else {
      get(this, 'selectedJobApplications').addObject(jobApplication);
    }
  }

  @action
  deselectJobApplication(jobApplication) {
    get(this, 'selectedJobApplications').removeObject(jobApplication);
  }

  @action
  toggleSuggestedStage() {
    this.hideSuggestedStage = !this.hideSuggestedStage;
  }
}
