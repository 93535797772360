/* import __COLOCATED_TEMPLATE__ from './nps-per-stage-type.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import InsightsService from 'teamtailor/services/insights';
import AnalyticsService from 'teamtailor/services/analytics';
import { action } from '@ember/object';
import InsightsExportService from 'teamtailor/services/insights-export';
import DateRange from 'teamtailor/utils/date-range';
import { get } from 'teamtailor/utils/get';
import {
  NpsPerStageTypeRow,
  fetch as fetchNpsPerStageType,
} from 'teamtailor/classes/analytics/report-nps-per-stage-type';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import {
  NpsPerStageRow,
  fetch as fetchNpsPerStage,
} from 'teamtailor/classes/analytics/report-nps-per-stage-new';
import npsScore from 'teamtailor/components/nps-score';
import { capitalize } from '@ember/string';
import {
  HeatmapTable,
  HeatmapTableCell,
  HeatmapTableRow,
} from '../molecules/heatmap-table-types';

type Args = {
  showMoreLink?: string;
  isEmpty?: boolean;
  loading?: boolean;

  data?: NpsPerStageTypeRow[];
  perStageData?: NpsPerStageRow[];
};

const EXPORT_STAGE_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'stageName',
    headerKey: 'insights.widgets.nps_per_stage_type.stage_name',
  },
  {
    type: 'number',
    propertyName: 'totalNpsScore',
    headerKey: 'insights.widgets.nps_per_stage_type.total',
  },
  {
    type: 'number',
    propertyName: 'rejectedNpsScore',
    headerKey: 'insights.widgets.nps_per_stage_type.rejected',
  },
  {
    type: 'number',
    propertyName: 'nonRejectedNpsScore',
    headerKey: 'insights.widgets.nps_per_stage_type.non_rejected',
  },
];

const EXPORT_STAGE_TYPE_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'stageType',
    headerKey: 'insights.widgets.nps_per_stage_type.stage_type',
  },
  {
    type: 'number',
    propertyName: 'totalNpsScore',
    headerKey: 'insights.widgets.nps_per_stage_type.total',
  },
  {
    type: 'number',
    propertyName: 'rejectedNpsScore',
    headerKey: 'insights.widgets.nps_per_stage_type.rejected',
  },
  {
    type: 'number',
    propertyName: 'nonRejectedNpsScore',
    headerKey: 'insights.widgets.nps_per_stage_type.non_rejected',
  },
];

function combineAverages(
  avg1: number,
  avg2: number,
  count1: number,
  count2: number
) {
  if (count1 + count2 > 0) {
    return Math.round((avg1 * count1 + avg2 * count2) / (count1 + count2));
  }

  return 0;
}

export default class InsightsWidgetsNpsPerStageType extends Component<Args> {
  @service declare intl: IntlService;
  @service declare insights: InsightsService;
  @service declare analytics: AnalyticsService;
  @service declare insightsExport: InsightsExportService;
  @service declare current: Current;
  @service declare store: Store;

  @tracked showStages = false;

  get columns() {
    if (this.showStages) {
      return (this.rows as NpsPerStageRow[]).map((row) =>
        capitalize(row.stageName)
      );
    }

    return (this.rows as NpsPerStageTypeRow[]).map((row) =>
      this.intl.t(`job.stage_types.${row.stageType}`)
    );
  }

  get nonRejectedNPS() {
    return this.rows.map((row) => this.roundOrNull(row.nonRejectedNpsScore));
  }

  get rejectedNPS() {
    return this.rows.map((row) => this.roundOrNull(row.rejectedNpsScore));
  }

  get totalNPS() {
    return this.rows.map((row) => this.roundOrNull(row.totalNpsScore));
  }

  get rows() {
    if (this.showStages) {
      return this.stageRows(this.args.perStageData);
    } else {
      return this.dataRows(this.args.data);
    }
  }

  colorForScore(score: number | null) {
    if (score === null) {
      return 'zinc';
    }

    if (score < 0) {
      return 'red';
    }

    if (score >= 0 && score < 25) {
      return 'orange';
    }

    return 'green';
  }

  get heatmapTable(): HeatmapTable {
    return {
      headers: this.columns,
      rows: this.heatmapTableRows,
    };
  }

  get heatmapTableRows(): HeatmapTableRow[] {
    const totalNpsCells: HeatmapTableCell[] = this.totalNPS.map((row) => {
      return {
        value: row,
        color: this.colorForScore(row),
      };
    });

    const nonRejectedNpsCells: HeatmapTableCell[] = this.nonRejectedNPS.map(
      (row) => {
        return {
          value: row,
          color: this.colorForScore(row),
        };
      }
    );

    const rejectedNpsCells: HeatmapTableCell[] = this.rejectedNPS.map((row) => {
      return {
        value: row,
        color: this.colorForScore(row),
      };
    });

    const totalNpsHeatmapTableRow = {
      header: this.intl.t('insights.widgets.nps_per_stage_type.total'),
      cells: totalNpsCells,
    };

    const rejectedNpsHeatmapTableRow = {
      header: this.intl.t('insights.widgets.nps_per_stage_type.rejected'),
      cells: rejectedNpsCells,
    };

    const nonRejectedNpsHeatmapTableRow = {
      header: this.intl.t('insights.widgets.nps_per_stage_type.non_rejected'),
      cells: nonRejectedNpsCells,
    };

    return [
      totalNpsHeatmapTableRow,
      rejectedNpsHeatmapTableRow,
      nonRejectedNpsHeatmapTableRow,
    ];
  }

  roundOrNull(value: number | null) {
    if (value === null) {
      return null;
    }

    return Math.round(value);
  }

  stageRows(data: NpsPerStageRow[] = []) {
    return data
      .slice()
      .sortBy('stageIndex')
      .reverse()
      .reduce<NpsPerStageRow[]>((acc, row) => {
        const existing = acc.find(
          (r: NpsPerStageRow) => r.stageName === row.stageName
        );
        if (existing) {
          const updated = { ...existing };
          if (existing.totalResponses + row.totalResponses > 0) {
            updated.totalNpsScore = combineAverages(
              existing.totalNpsScore,
              row.totalNpsScore,
              existing.totalResponses,
              row.totalResponses
            );

            updated.totalResponses += row.totalResponses;
          }

          if (existing.rejectedResponses + row.rejectedResponses > 0) {
            updated.rejectedNpsScore = combineAverages(
              existing.rejectedNpsScore,
              row.rejectedNpsScore,
              existing.rejectedResponses,
              row.rejectedResponses
            );
            updated.rejectedResponses += row.rejectedResponses;
          }

          if (existing.nonRejectedNpsScore + row.nonRejectedNpsScore > 0) {
            updated.nonRejectedNpsScore = combineAverages(
              existing.nonRejectedNpsScore,
              row.nonRejectedNpsScore,
              existing.nonRejectedResponses,
              row.nonRejectedResponses
            );
            updated.nonRejectedResponses += row.nonRejectedResponses;
          }

          acc.removeObject(existing);
          acc.push(updated);
        } else {
          acc.push(row);
        }

        return acc;
      }, [])
      .map((row) => {
        return {
          ...row,
          npsScore,
        };
      })
      .sortBy('stageIndex');
  }

  dataRows(data: NpsPerStageTypeRow[] = []) {
    const stageTypes = this.store.peekAll('stage-type').toArray();
    return data.slice().sort((a, b) => {
      let aIndex: number | undefined = stageTypes.findBy(
        'category',
        a.stageType
      )?.orderIndex;
      let bIndex: number | undefined = stageTypes.findBy(
        'category',
        b.stageType
      )?.orderIndex;
      if (aIndex === undefined) {
        aIndex = 0.5; // In process doesn't have an orderIndex
      }

      if (bIndex === undefined) {
        bIndex = 0.5; // In process doesn't have an orderIndex
      }

      return aIndex - bIndex;
    });
  }

  @action
  toggleStages() {
    this.showStages = !this.showStages;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      `insights.widgets.nps_per_stage_type.${
        this.showStages ? 'title_by_stage' : 'title_by_stage_type'
      }`,
      `nps`,
      this.showStages
        ? EXPORT_STAGE_TABLE_COLUMNS
        : EXPORT_STAGE_TYPE_TABLE_COLUMNS,
      this.fetchExportData,
      new DateRange(this.analytics.startDate, this.analytics.endDate),
      {
        sortKey: 'date',
        sortDirection: 'desc',
        createdAt: get(this.current.company, 'went_live_at'),
      }
    );
  }

  @action
  async fetchExportData(dateRange: DateRange) {
    const container = getOwner(this);
    if (this.showStages) {
      const rows = await fetchNpsPerStage(container, { dateRange });
      return Promise.resolve(
        this.stageRows(rows).map((row) => ({
          ...row,
          totalNpsScore: Math.round(row.totalNpsScore),
          rejectedNpsScore: Math.round(row.rejectedNpsScore),
          nonRejectedNpsScore: Math.round(row.nonRejectedNpsScore),
        }))
      );
    } else {
      const rows = await fetchNpsPerStageType(container, { dateRange });
      return Promise.resolve(
        this.dataRows(rows).map((row) => ({
          ...row,
          stageType: this.intl.t(`job.stage_types.${row.stageType}`),
          totalNpsScore: Math.round(row.totalNpsScore),
          rejectedNpsScore: Math.round(row.rejectedNpsScore),
          nonRejectedNpsScore: Math.round(row.nonRejectedNpsScore),
        }))
      );
    }
  }
}
