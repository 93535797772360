/* import __COLOCATED_TEMPLATE__ from './calendar-selector.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class CalendarSelectorComponent extends Component {
  @service store;
  @service current;
  @service flipper;

  @tracked primaryCalendar = null;
  @tracked calendars = [];

  get isLoading() {
    return this.loadCalendarsTask.isRunning;
  }

  get editableCalendars() {
    return this.calendars.filterBy('isEditable', true);
  }

  get selectedCalendars() {
    return this.calendars.filterBy('isSelected', true);
  }

  loadCalendarsTask = restartableTask(async () => {
    this.calendars = await this.store.findAll('calendar-option');

    const primaryCalendar = this.calendars.findBy('isPrimary', true);
    if (!primaryCalendar) {
      return this.createDefaultCalendar(this.calendars);
    } else {
      this.primaryCalendar = primaryCalendar;
    }
  });

  createDefaultCalendar(calendars) {
    const firstPrimaryCalendar = calendars.findBy('calendarPrimary', true);
    if (firstPrimaryCalendar) {
      return this.store
        .createRecord('connected-calendar', {
          useForInserts: true,
          calendarId: firstPrimaryCalendar.id,
          email: firstPrimaryCalendar.profileName,
          calendarIntegratedConferencingAvailable:
            firstPrimaryCalendar.calendarIntegratedConferencingAvailable,
        })
        .save()
        .then(async () => {
          const calendars = await this.store.findAll('calendar-option');
          this.calendars = [...calendars];
          this.primaryCalendar = calendars.findBy('calendarPrimary', true);
        });
    }
  }

  @action
  handleSelectionChange(calendarOption) {
    if (!calendarOption.isDefault) {
      if (calendarOption.isSelected) {
        calendarOption.connectedCalendar.destroyRecord();
      } else {
        this.store
          .createRecord('connected-calendar', {
            calendarId: calendarOption.id,
            email: calendarOption.profileName,
            useForInserts: false,
            calendarIntegratedConferencingAvailable:
              calendarOption.calendarIntegratedConferencingAvailable,
          })
          .save()
          .then(this.loadCalendarsTask.perform);
      }
    }
  }

  @action
  async handleDefaultChange(calendarOption) {
    if (this.primaryCalendar) {
      const primary = await get(this.primaryCalendar, 'connectedCalendar');
      primary.useForInserts = false;
      await primary.save();
    }

    let connectedCalendar = await get(calendarOption, 'connectedCalendar');
    if (connectedCalendar) {
      connectedCalendar.useForInserts = true;
    } else {
      connectedCalendar = this.store.createRecord('connected-calendar', {
        calendarId: calendarOption.id,
        email: calendarOption.profileName,
        useForInserts: true,
        calendarIntegratedConferencingAvailable:
          calendarOption.calendarIntegratedConferencingAvailable,
      });
    }

    await connectedCalendar.save();
    this.loadCalendarsTask.perform();
  }
}
