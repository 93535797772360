/* import __COLOCATED_TEMPLATE__ from './selected-property.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';

type Args = {
  property: BaseProperty;
  onRemove?: (p: BaseProperty) => void;
};

export default class SelectedPropertyComponent extends Component<Args> {
  get color(): string {
    const hasEvent = this.args.property.queryTypes.includes('event');
    const hasPageview = this.args.property.queryTypes.includes('pageview');

    if (hasEvent && hasPageview) {
      return 'bg-green-300';
    } else if (hasEvent) {
      return 'bg-blue-300';
    }

    return 'bg-yellow-300';
  }

  get showRemoveButton(): boolean {
    return !!this.args.onRemove;
  }

  @action
  remove() {
    this.args.onRemove?.(this.args.property);
  }
}
