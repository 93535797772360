/* import __COLOCATED_TEMPLATE__ from './keyword-picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { isArray } from '@ember/array';

type KeywordPickerArgs = {
  searchPlaceholder?: string;
  options?: string[];
  selected?: string[];
  isMultiple?: boolean;
  onSelect: (keyword: string) => void;
  isSubmenu?: boolean;
  onClose?: () => void;
  parentApi: {
    addClickOutsideElement: (
      arg: HTMLElement | null
    ) => (arg: HTMLElement | null) => void;
  };
};

export default class KeywordPickerComponent extends Component<KeywordPickerArgs> {
  @service declare intl: IntlService;

  declare dropdownApi?: { close(): void };

  @tracked searchTerm: string | null = null;
  @tracked removeOutSideClickElement?: (arg: HTMLElement | null) => void;

  get destinationElement() {
    return this.args.isSubmenu
      ? document.getElementById('ember-teamtailor-dropdown-submenu')
      : null;
  }

  get displayCreate() {
    return this.searchTerm && this.searchTerm.length > 0;
  }

  get searchPlaceholder(): string {
    return (
      this.args.searchPlaceholder ??
      this.intl.t('components.keyword_picker.type_to_create')
    );
  }

  get createPlaceholder(): string {
    return this.hasKeyword
      ? 'components.keyword_picker.remove_keyword'
      : 'components.keyword_picker.add_keyword';
  }

  get hasKeyword() {
    return this.searchTerm
      ? this.selectedAsArray.includes(this.searchTerm)
      : false;
  }

  get hasSelected(): boolean {
    return this.selectedAsArray.length > 0;
  }

  get selected() {
    return this.args.selected;
  }

  get selectedAsArray() {
    let { selected } = this;
    if (selected && !isArray(selected)) {
      selected = [selected];
    }

    return selected || [];
  }

  get options(): string[] | undefined {
    return this.selectedAsArray;
  }

  @action
  handleSearch(term: string): void {
    if (this.searchTerm !== term) {
      this.searchTerm = term;
    }
  }

  @action
  handleRegisterApi(api: { close(): void }): void {
    this.dropdownApi = api;

    if (this.args.isSubmenu) {
      this.removeOutSideClickElement =
        this.args.parentApi.addClickOutsideElement(this.destinationElement);
    }
  }

  @action
  handleClose(): void {
    this.removeOutSideClickElement?.(this.destinationElement);
    this.args.onClose?.();
  }

  @action
  async handleCreate(value: string) {
    this.searchTerm = value;
    if (this.searchTerm) {
      this.args.onSelect((this.searchTerm || '').trim());
      this.searchTerm = null;
    }
  }
}
