/* import __COLOCATED_TEMPLATE__ from './job-picked-custom-fields-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';

export default class JobPickedCustomFieldsModal extends Component {
  get allPickedCustomFields() {
    return get(this.args.job, 'pickedCustomFields');
  }

  get pickedCustomFieldsToShow() {
    return this.allPickedCustomFields.filterBy('isHidden', false);
  }

  customFieldsToShow = trackedFunction(this, async () => {
    await Promise.all(
      this.pickedCustomFieldsToShow.map((pcf) => get(pcf, 'customField'))
    );
    return this.pickedCustomFieldsToShow;
  });

  get salaryValue() {
    if (this.args.job.maxSalary) {
      return `${this.args.job.minSalary} - ${this.args.job.maxSalary} ${this.args.job.currency}`;
    }

    if (this.args.job.minSalary) {
      return `${this.args.job.minSalary} ${this.args.job.currency}`;
    }

    return false;
  }

  @action
  openLink(url, newTab, type) {
    if (type === 'CustomField::Url') {
      if (!url.includes('http')) {
        url = `//${url}`;
      }
    }

    const link = document.createElement('a');
    link.href = url;
    if (newTab) {
      link.target = '_blank';
    }

    link.click();
  }
}
