/* import __COLOCATED_TEMPLATE__ from './sharing-image-form.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, get, getProperties } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default Component.extend({
  current: service(),

  companyButtonColorStyle: computed(
    'current.company.design.color',
    function () {
      const design = get(this, 'current.company.design');
      const { buttonBackgroundColor, buttonTextColor } = getProperties(
        design,
        'buttonBackgroundColor',
        'buttonTextColor'
      );
      return htmlSafe(
        `background-color: ${buttonBackgroundColor}; color: ${buttonTextColor}`
      );
    }
  ),
});
