/* import __COLOCATED_TEMPLATE__ from './marketplace-modal.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service intl;
  @service store;
  @service router;
  @service flashMessages;
  @service permissions;

  partner = null;
}
