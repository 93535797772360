/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import Component from '@glimmer/component';
import ScorecardPick from 'teamtailor/models/scorecard-pick';
import ScorecardCriteriumModel, {
  TOPIC,
} from 'teamtailor/models/scorecard-criterium';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Intl from 'ember-intl/services/intl';
import flatten from 'teamtailor/utils/flatten';
import { get } from 'teamtailor/utils/get';
import ScorecardCriteriaSorter from 'teamtailor/utils/scorecard-criteria-sorter';

interface CompetenceSelectArgs {
  selectedScorecardPicks: ScorecardPick[];
  onSelect: () => void;
}

type GroupedOptions = {
  options: ScorecardCriteriumModel[];
  groupName: string;
};

export default class CompetenceSelectComponent extends Component<CompetenceSelectArgs> {
  @service declare intl: Intl;
  @service declare current: Current;

  sorter: ScorecardCriteriaSorter;

  constructor(owner: unknown, args: CompetenceSelectArgs) {
    super(owner, args);
    this.sorter = new ScorecardCriteriaSorter(this.intl.primaryLocale);
  }

  get skillsAndTraits(): GroupedOptions[] {
    const scorecardCriteria = get(
      this.current.company,
      'scorecardCriteria'
    ).filter((option) => !option.addedByCopilot);

    return [
      {
        groupName: this.intl.t('common.skills'),
        options: scorecardCriteria
          .filterBy('topic', TOPIC.SKILL)
          .toArray()
          .sort(this.sorter.sort),
      },
      {
        groupName: this.intl.t('common.traits'),
        options: scorecardCriteria
          .filterBy('topic', TOPIC.TRAIT)
          .toArray()
          .sort(this.sorter.sort),
      },
    ];
  }

  get selectedSkillsAndTraits(): ScorecardCriteriumModel[] {
    const skillsAndTraits = flatten(
      this.skillsAndTraits.map((groupedOptions) => groupedOptions.options)
    );

    const scorecardCriteriumIds = this.args.selectedScorecardPicks.mapBy(
      'scorecardCriterium.id'
    );

    return skillsAndTraits.filter(
      (scorecardCriterium: ScorecardCriteriumModel) =>
        scorecardCriteriumIds.includes(scorecardCriterium.id)
    );
  }
}
