/* import __COLOCATED_TEMPLATE__ from './form-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export interface FormFieldArgs {
  onChange?: (value: string | undefined) => void;
}

export default class FormFieldComponent extends Component<FormFieldArgs> {
  @action onInput(event: { target?: HTMLInputElement }) {
    if (this.args.onChange) {
      this.args.onChange(event.target?.value);
    }
  }
}
