/* import __COLOCATED_TEMPLATE__ from './only-show-errors-after-first.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type Args = {
  errors?: string[];
};

export default class OnlyShowErrorsAfterFirstComponent extends Component<Args> {
  @tracked hasDoneAction = false;

  get showErrors() {
    return (
      this.hasDoneAction && this.args.errors && this.args.errors.length > 0
    );
  }

  @action
  onAction() {
    this.hasDoneAction = true;
  }
}
