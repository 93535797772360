import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ContentRoute extends Route {
  @service current;
  @service pusher;
  @service iframePreview;
  @service store;

  channel = null;

  setupController(controller, model) {
    super.setupController(controller, model);

    this.pusher.subscribe(this.current.company.mainChannel).then((channel) => {
      this.channel = channel;
      this.channel.bind('page-updated', this.handlePusherEvent);
      this.channel.bind('page-count-updated', this.handlePusherEvent);
      this.channel.bind('department-updated', this.handlePusherEvent);
      this.channel.bind('location-updated', this.handlePusherEvent);
      this.channel.bind('video-transcoded', this.handleVideoTranscoded);
    });
  }

  handlePusherEvent = (event) => {
    this.store.pushPayload(event);
  };

  handleVideoTranscoded = (video) => {
    this.handlePusherEvent(video);
    this.iframePreview.reloadIframe();
  };

  resetController() {
    if (this.channel) {
      this.channel.unbind('page-updated', this.handlePusherEvent);
      this.channel.unbind('page-count-updated', this.handlePusherEvent);
      this.channel.unbind('department-updated', this.handlePusherEvent);
      this.channel.unbind('location-updated', this.handlePusherEvent);
      this.channel.unbind('video-transcoded', this.handleVideoTranscoded);
    }
  }
}
