/* import __COLOCATED_TEMPLATE__ from './finn.hbs'; */
import PromotionBaseClass from './promotion-base';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class extends PromotionBaseClass {
  @tracked checkoutData;
  @service intl;

  finnGeneralOccupation = null;
  finnSpecialization = null;

  get jobTitlePlaceholder() {
    return get(this, 'promotion.job.title');
  }

  get finnOccupationsGeneral() {
    return this.store.peekAll('finn-occupation');
  }

  get finnIndustries() {
    return this.store.peekAll('finn-industry');
  }

  get finnOccupationsSpecialization() {
    return this.store.peekAll('finn-occupation-specialization');
  }

  get finnJobTypes() {
    return [
      {
        value: 'job',
        name: this.intl.t('components.job.promotion.finn.regular_job'),
      },
      {
        value: 'job_executive',
        name: this.intl.t('components.job.promotion.finn.executive_job'),
      },
      {
        value: 'job_parttime',
        name: this.intl.t('components.job.promotion.finn.parttime_job'),
      },
    ];
  }

  get finnDurations() {
    return [
      { value: 'permanent', name: 'Fast' },
      { value: 'temporary', name: 'Vikariat' },
      { value: 'fixedcontract', name: 'Engasjement' },
      { value: 'project', name: 'Prosjekt' },
      { value: 'trainee', name: 'Trainee' },
      { value: 'seasonal', name: 'Sommer/Sesong' },
      { value: 'selfemployed', name: 'Selvstendig næringsdrivende' },
      { value: 'apprentice', name: 'Lærling' },
    ];
  }

  get finnManagerRoles() {
    return [
      { value: 'senmanager', name: 'Direktør' },
      { value: 'midmanager', name: 'Leder' },
      { value: 'techmanager', name: 'Fagleder' },
    ];
  }

  get finnSectors() {
    return [
      { value: 'private', name: 'Privat' },
      { value: 'public', name: 'Offentlig' },
      { value: 'organisations', name: 'Organisasjoner' },
      { value: 'cooperative', name: 'Samvirke' },
      { value: 'selfemployed', name: 'Franchise/Selvstendig næringsdrivende' },
    ];
  }

  get finnTags() {
    return this.args.promotion.finnTags.map((tag) => {
      return { tag };
    });
  }

  specializationsForOccupation(occupation) {
    return get(this, 'finnOccupationsSpecialization').filter(
      (specialization) => {
        return get(specialization, 'id').indexOf(get(occupation, 'id')) === 0;
      }
    );
  }

  @action
  removeFinnOccupation(specialization) {
    let nrPickedSpecializations = get(
      this,
      'promotion.finnOccupationSpecializations'
    ).filter(function (specialization) {
      return (
        get(specialization, 'id').indexOf(get(specialization, 'parent.id')) ===
        0
      );
    }).length;
    if (nrPickedSpecializations === 1) {
      get(this, 'promotion.finnOccupationGenerals').removeObject(
        get(specialization, 'parent')
      );
    }

    get(this, 'promotion.finnOccupationSpecializations').removeObject(
      specialization
    );
  }

  @action
  addFinnOccupation(occupation) {
    if (!occupation) {
      return true;
    }

    return set(
      this,
      'finnAvailableSpecializations',
      this.specializationsForOccupation(occupation)
    );
  }

  @action
  addFinnSpecialization(specialization) {
    if (!specialization) {
      return true;
    }

    if (
      !get(this, 'promotion.finnOccupationGenerals').includes(
        get(specialization, 'parent')
      )
    ) {
      get(this, 'promotion.finnOccupationGenerals').pushObject(
        get(specialization, 'parent')
      );
    }

    get(this, 'promotion.finnOccupationSpecializations').pushObject(
      specialization
    );
    return undefined;
  }

  @action
  removeFinnIndustry(industry) {
    get(this, 'promotion.finnIndustries').removeObject(industry);
  }

  @action
  addFinnTag(tag) {
    toggleInList(get(this, 'promotion.finnTags'), tag.tag || tag);

    set(
      this,
      'promotion.finnTags',
      get(this, 'promotion.finnTags').slice(
        0,
        get(this, 'promotion.finnTagsLimit')
      )
    );
  }

  @action
  stopPropagationAction(_, event) {
    event.stopPropagation();
  }
}
