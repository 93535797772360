/* import __COLOCATED_TEMPLATE__ from './user-status.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import UserStatusModel from 'teamtailor/models/user-status';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';

interface ComponentArgs {
  status: UserStatusModel;
}

export default class UserStatusComponent extends Component<ComponentArgs> {
  @service declare current: Current;

  get user() {
    return this.current.user;
  }

  get activeStatus() {
    return this.resolveActiveStatus.value;
  }

  resolveActiveStatus = trackedFunction(this, async () => {
    const activeStatus = await get(this.user, 'activeStatus');
    return activeStatus;
  });

  get statusText() {
    let statusText = get(this.activeStatus, 'statusText') || '';
    return statusText.length > 25
      ? `${statusText.substring(0, 25)}...`
      : statusText;
  }

  @action
  removeStatus() {
    if (this.activeStatus) {
      this.activeStatus.destroyRecord();
    }
  }
}
