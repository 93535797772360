/* import __COLOCATED_TEMPLATE__ from './nps-response-score.hbs'; */
import Component from '@glimmer/component';
import {
  isDetractorScore,
  isPassiveScore,
  isPromoterScore,
} from 'teamtailor/utils/nps';

interface NpsResponseScoreSignature {
  score: number | null;
  size?: 'small' | 'medium';
}

export default class NpsResponseScore extends Component<NpsResponseScoreSignature> {
  get score(): number | null {
    return this.args.score;
  }

  get sizeClasses() {
    switch (this.args.size) {
      case 'small':
        return 'size-32';
      case 'medium':
        return 'size-40';
      default:
        return 'size-40';
    }
  }

  get isPending() {
    return this.score === null;
  }

  get isDetractor() {
    if (this.isPending) return false;

    return isDetractorScore(this.score!);
  }

  get isPromoter() {
    if (this.isPending) return false;

    return isPromoterScore(this.score!);
  }

  get isPassive() {
    if (this.isPending) return false;

    return isPassiveScore(this.score!);
  }
}
