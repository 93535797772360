/* import __COLOCATED_TEMPLATE__ from './competence.hbs'; */
import Component from '@glimmer/component';
import { Task, dropTask } from 'ember-concurrency';
import ObjectProxy from '@ember/object/proxy';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import InterviewKit from 'teamtailor/models/interview-kit';
import Job from 'teamtailor/models/job';
import {
  OrderedCompetenceParent,
  OrderedCompetence,
} from 'teamtailor/utils/competence/ordered-list-helpers';
import ScorecardCriteriumModel, {
  TOPIC as SCORECARD_CRITERIUM_TOPIC,
} from 'teamtailor/models/scorecard-criterium';
import { get } from 'teamtailor/utils/get';
import { later } from '@ember/runloop';
import QuestionModel from 'teamtailor/models/question';

interface CompetenceQuestionGroupsCompetenceArgs {
  interviewKit: InterviewKit;
  job?: Job;
  item: OrderedCompetenceParent | OrderedCompetence;
  suggestQuestions: Task<void, [string?, string?, string?, string?]>;
}

export default class CompetenceQuestionGroupsCompetenceComponent extends Component<CompetenceQuestionGroupsCompetenceArgs> {
  @tracked containerElement?: HTMLElement;

  @action
  handleInsert(element: HTMLElement) {
    this.containerElement = element;
  }

  suggestQuestionsForCompetence = dropTask(async () => {
    let skill: string | undefined = undefined;
    let trait: string | undefined = undefined;
    let model: ScorecardCriteriumModel | QuestionModel | undefined;

    if (this.args.item.model instanceof ObjectProxy) {
      model = get(this.args.item.model, 'content');
    } else if (this.args.item.model instanceof ScorecardCriteriumModel) {
      model = this.args.item.model;
    }

    if (model instanceof ScorecardCriteriumModel) {
      // If not saved, the suggested questions don't know where to go
      if (get(model, 'isNew')) {
        await model.save();
      }

      const topic = get(model, 'topic');
      const name = get(model, 'name');

      if (topic === SCORECARD_CRITERIUM_TOPIC.SKILL) {
        skill = name;
      }

      if (topic === SCORECARD_CRITERIUM_TOPIC.TRAIT) {
        trait = name;
      }

      later(() => {
        this.containerElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 50);

      await this.args.suggestQuestions.perform(
        this.args.job?.id,
        model.id,
        skill,
        trait
      );
    }
  });
}
