/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import AnalyticsService from 'teamtailor/services/analytics';
import Settings, {
  formatRows,
  sortRows,
} from 'teamtailor/utils/insights/reports/settings';
import InsightsReport from 'teamtailor/models/insights-report';
import {
  EventTypeResponse,
  PageviewTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import { action } from '@ember/object';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';
import FlipperService from 'teamtailor/services/flipper';

interface ComponentArgs {
  settings: Settings;
  report: InsightsReport;
  rows: (PageviewTypeResponse & EventTypeResponse)[];
  isEditing?: boolean;
  onSort: (column: string) => void;
  onChangePage?: (page: number) => void;
  onUpdate?: (settings: Settings) => void;
  isSharedReport?: boolean;
}

export default class ReportsTableComponent extends Component<ComponentArgs> {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  get report() {
    return this.args.report;
  }

  get currentSettings() {
    return this.args.settings;
  }

  get rows() {
    return this.args.rows;
  }

  get columns() {
    return this.currentSettings.allSelectedProperties;
  }

  get sortedRows() {
    let rows = this.rows.length ? this.rows : [];
    if (this.columns.length === 1 && !rows.length) {
      rows = new Array(10).fill({ [this.columns[0]!.columnName]: '' });
    }

    const formattedRows = formatRows(rows, this.columns);
    return sortRows(
      formattedRows,
      this.currentSettings.sortProperty,
      this.currentSettings.sortDirection
    );
  }

  get groupedAvailableProperties() {
    return this.currentSettings.groupedAvailableProperties.map((group) => ({
      ...group,
      groupName: this.intl.t(
        `insights.reports.builder.property_groups.${group.groupName}`
      ),
    }));
  }

  @action
  onPaginate(page: number) {
    this.args.onChangePage?.(page);
  }

  @action
  onAddAfter(column: any, property: BaseProperty) {
    const index = this.currentSettings.selectedProperties.findIndex(
      (p) => p.columnName === column.propertyName
    );

    const tempArray = [...this.currentSettings.selectedProperties];

    tempArray.splice(index + 1, 0, property);
    this.currentSettings.selectedProperties = [...tempArray];
  }

  @action
  onAddBefore(column: any, property: BaseProperty) {
    const index = this.currentSettings.selectedProperties.findIndex(
      (p) => p.columnName === column.propertyName
    );

    const tempArray = [...this.currentSettings.selectedProperties];

    tempArray.splice(index, 0, property);
    this.currentSettings.selectedProperties = [...tempArray];
  }

  @action
  onUpdate(column: any, property: BaseProperty) {
    let index = this.currentSettings.selectedProperties.findIndex(
      (p) => p.columnName === column.propertyName
    );
    if (index === -1) {
      if (
        this.currentSettings.baseProperty?.columnName === column.propertyName
      ) {
        this.currentSettings.baseProperty = property;
        return;
      }

      if (
        this.currentSettings.additionalBaseProperty?.columnName ===
        column.propertyName
      ) {
        this.currentSettings.additionalBaseProperty = property;
        return;
      }

      index = 0;
    }

    const tempArray = [...this.currentSettings.selectedProperties];

    tempArray.splice(index, 1, property);
    this.currentSettings.selectedProperties = [...tempArray];
  }

  @action
  onRemove(column: any) {
    const index = this.currentSettings.selectedProperties.findIndex(
      (p) => p.columnName === column.propertyName
    );
    if (index === -1) {
      throw new Error(`Could not find column ${column.propertyName}`);
    }

    const tempArray = [...this.currentSettings.selectedProperties];

    tempArray.splice(index, 1);
    this.currentSettings.selectedProperties = [...tempArray];
  }

  @action
  isBaseProperty(property: BaseProperty) {
    if (
      this.currentSettings.currentGroupBys.find((p) => p.id === property.id)
    ) {
      return true;
    }

    return false;
  }
}
