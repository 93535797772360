/* import __COLOCATED_TEMPLATE__ from './picked-questions-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

import { editQuestionProperties } from 'teamtailor/utils/jobs/edit/question';

export default class PickedQuestionsList extends Component {
  @service intl;
  @service permissions;
  @tracked editingQuestionGuid = null;

  guidFor = guidFor;
  editQuestionProperties = editQuestionProperties;

  get shouldDisplayMissingLanguages() {
    return this.args.languageCode || this.args.checkAllLanguages;
  }

  get sortedPickedQuestions() {
    const list = this.args.pickedQuestions;
    return this.args.isAlreadySorted ? list : list.sortBy('rowOrderPosition');
  }

  setRowOrderPosition(items) {
    items.forEach((item, index) => {
      set(item, 'rowOrderPosition', index);
    });
  }

  @action
  handleDragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, draggedItem);

    this.setRowOrderPosition(targetList);

    this.args.onChange?.(targetList);
  }

  @action
  handleRowOrderPosition() {
    this.setRowOrderPosition(this.args.pickedQuestions);
  }

  @action
  toggleQualifying(pickedQuestion) {
    this.args.qualifyingStateWillChange?.(pickedQuestion);
    set(pickedQuestion, 'isQualifying', !pickedQuestion.isQualifying);
  }
}
