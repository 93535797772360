/* import __COLOCATED_TEMPLATE__ from './bar.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import { SafeString } from '@ember/template/-private/handlebars';

type SurveysQuestionAnswersBarArgs = {
  value: number;
  numberOfAnswers: number;
};

export default class QuestionAnswersBar extends Component<SurveysQuestionAnswersBarArgs> {
  @service declare intl: IntlService;

  @tracked watchOthers = false;

  get cssStyle(): SafeString {
    const cssWidth = `${this.percentage || 0}%`;

    return htmlSafe(`width: ${cssWidth}`);
  }

  get percentage(): number {
    const { value, numberOfAnswers } = this.args;

    return Math.round((100 * value) / numberOfAnswers);
  }

  get tooltipText(): string {
    const { value } = this.args;

    return `${value} ${this.intl.t('common.candidate', { count: value })}`;
  }
}
