import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class JobOfferSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    jobOfferForm: { embedded: 'always', key: 'job_offer_form_attributes' },
    users: { serialize: 'ids' },
    approvalSteps: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'requisition_steps_attributes',
    },
  };
}
