/* import __COLOCATED_TEMPLATE__ from './rejected.hbs'; */
import Base from './base';
import { get } from 'teamtailor/utils/get';

export default class RejectedComponent extends Base {
  get stageStatus() {
    const stage = get(this, 'activity.stage');
    if (stage.inbox) {
      return 'inbox';
    } else if (stage.hired) {
      return 'hired';
    } else {
      return 'reviewing';
    }
  }
}
