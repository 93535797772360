/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { inject as service } from '@ember/service';
import SectionItemComponent from 'teamtailor/components/content-block/section-item-component';
import { get } from 'teamtailor/utils/get';
import { tracked } from 'tracked-built-ins';

export default class VideoItemComponent extends SectionItemComponent {
  @service store;

  @tracked hasNoMarketingCookies = false;
  @tracked cookieDetails;

  uploadLabel = null;

  constructor(...args) {
    super(...args);
    this._fetchCookieDetails();
  }

  async _fetchCookieDetails() {
    this.cookieDetails = await this.store.findAll('cookie-detail');
  }

  get showThumbnail() {
    return (
      get(this, 'sectionItem.section.layout') !== 'modal' &&
      !this.args.noThumbnail
    );
  }

  get itemName() {
    const { externalVideoId, itemType } = this.sectionItem;

    if (itemType === 'file') {
      return this.intl.t('content.editor.section.video.file');
    } else if (externalVideoId) {
      if (externalVideoId.toLowerCase().match('youtube')) {
        return 'Youtube';
      } else if (externalVideoId.toLowerCase().match('vimeo')) {
        return 'Vimeo';
      } else {
        return externalVideoId.match(/\D/) ? 'Youtube' : 'Vimeo';
      }
    } else {
      return this.intl.t('content.editor.section.video.embed');
    }
  }

  get missingVideoCookie() {
    if (!this.cookieDetails || !this.sectionItem.itemType === 'embed') {
      return false;
    }

    const videoProvider = this.itemName.toLowerCase();
    return !this.cookieDetails.filterBy('enabled').findBy('id', videoProvider);
  }
}
