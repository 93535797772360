import Route from '@ember/routing/route';
import { setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

export default class RequisitionsRoute extends Route {
  @service store;

  queryParams = {
    status: { refreshModel: true },
    include_archived: { refreshModel: true },
    query: { refreshModel: true },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    location_id: { refreshModel: true },
    user_id: { refreshModel: true },
    division_id: { refreshModel: true },
    department_id: { refreshModel: true },
    recruiter_id: { refreshModel: true },
    requisition_archive_reason_id: { refreshModel: true },
    assignee: { refreshModel: true },
  };

  model(params) {
    return this.store.query('requisition', params);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        showBulk: false,
        selectAll: false,
        deselectedRequisitionIds: [],
        selectedRequisitionIds: [],
      });
    }
  }
}
