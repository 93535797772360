import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class Reaction extends ApplicationSerializer {
  serialize() {
    let json = super.serialize(...arguments);

    if (json.action_id) {
      json.reactable_id = json.action_id;
    }

    if (json.note_id) {
      json.reactable_id = json.note_id;
    }

    if (json.action_id || json.note_id) {
      json.reactable_type = 'Action';
    }

    delete json.note_id;
    delete json.action_id;

    return json;
  }
}
