/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import type { AnimationItem } from 'lottie-web';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import TimeFormatService from 'teamtailor/services/time-format';
import CandidateModel from 'teamtailor/models/candidate';
import CustomizeTableColumn from 'teamtailor/utils/candidate-table/column';
import setColumnValues from 'teamtailor/utils/candidate-table/column-values';
import { KeyCodes } from 'teamtailor/constants/key-codes';

const LOOP_TIMES = 3;
const { ENTER } = KeyCodes;

interface CandidatesTableRowArgs {
  candidate: CandidateModel;
  candidateRoute?: string;
  selectAll?: boolean;
  selectedCandidateIds?: string[];
  deselectedCandidateIds?: string[];
  additionalCandidateIds?: string[];
  showBulk?: boolean;
}

export default class CandidatesTableRowComponent extends Component<CandidatesTableRowArgs> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare timeFormat: TimeFormatService;

  @tracked additionalCandidateIds: string[] = [];
  @tracked newCandidateAnimation: AnimationItem | null = null;

  get candidate(): CandidateModel {
    return this.args.candidate;
  }

  @tracked columnsWithValues: CustomizeTableColumn[] = [];

  @action
  setColumnsWithValues(
    _: HTMLElement,
    [columns, candidate]: [
      columns: CustomizeTableColumn[],
      candidate: CandidateModel,
    ]
  ) {
    this.columnsWithValues = setColumnValues(columns, candidate);
  }

  get candidateRoute(): string {
    return this.args.candidateRoute || 'candidates.candidate';
  }

  get isAdditionalCandidate(): boolean {
    return this.additionalCandidateIds.includes(this.candidate.id);
  }

  get createdTitle(): string {
    const formattedTimestamp = moment(this.candidate.createdAt).format(
      `YYYY-MM-DD ${get(this.timeFormat, 'format')}`
    );

    return this.intl.t('components.candidates_table.row.created_at', {
      time: formattedTimestamp,
    });
  }

  get createdAt(): string {
    return moment(get(this.candidate, 'createdAt'))
      .locale(get(this.timeFormat, 'shortLocale'))
      .fromNow();
  }

  get activityTitle(): string {
    return this.intl.t('components.candidates_table.row.last_activity_at', {
      time: moment(get(this.candidate, 'lastActivity')).format(
        `YYYY-MM-DD ${get(this.timeFormat, 'format')}`
      ),
    });
  }

  get lastActivityAt(): string {
    return moment(get(this.candidate, 'lastActivity'))
      .locale(get(this.timeFormat, 'shortLocale'))
      .fromNow();
  }

  @action
  handleRowClick(e: MouseEvent | KeyboardEvent): void {
    if (this.args.showBulk) {
      if (e.type === 'keyup' && (e as KeyboardEvent).key !== ENTER) {
        return;
      }

      e.preventDefault();

      const listProperty = this.args.selectAll
        ? 'deselectedCandidateIds'
        : 'selectedCandidateIds';

      const id = get(this.candidate, 'id');
      const list = this.args[listProperty];

      if (list?.includes(id)) {
        list.removeObject(id);
      } else {
        list?.addObject(id);
      }
    }
  }

  @action
  async didInsertLottieWrapper(el: HTMLElement) {
    let counter = 0;

    const lottie = (await import('lottie-web')).default;

    this.newCandidateAnimation = lottie.loadAnimation({
      container: el,
      renderer: 'svg',
      path: '/assets/lottie-animations/shining-stars.json',
      loop: LOOP_TIMES,
      autoplay: true,
    });

    this.newCandidateAnimation.addEventListener('loopComplete', () => {
      counter++;
      if (counter === LOOP_TIMES) {
        this.newCandidateAnimation?.destroy();
      }
    });

    this.newCandidateAnimation.addEventListener('DOMLoaded', () => {
      this.newCandidateAnimation?.play();
    });
  }
}
