/* import __COLOCATED_TEMPLATE__ from './merge.hbs'; */
import { get } from 'teamtailor/utils/get';
import BaseComponent from './base';
import { action } from '@ember/object';
import uniq from 'teamtailor/utils/uniq';

export default class MergeComponent extends BaseComponent {
  @action
  mergeCandidates(candidates, primaryCandidate) {
    let promise = this.postAction(
      'bulk_merge',
      this.postData({
        primary_candidate_id: get(primaryCandidate, 'id'),
        merge_candidates_id: candidates.mapBy('id'),
      })
    );

    promise.then(() => {
      if (this.hasJob) {
        let stageName, jobApplicationsName;
        if (this.args.showRejected) {
          stageName = 'rejectedStage';
          jobApplicationsName = 'rejectedJobApplications';
        } else {
          stageName = 'activeStage';
          jobApplicationsName = 'activeJobApplications';
        }

        uniq(this.args.selectedJobApplications.mapBy(stageName)).forEach(
          (stage) => {
            get(stage, jobApplicationsName).reload();
          }
        );
      } else {
        this.args.bulkCallbackAction();
      }

      this.args.clearSelection();
      this.args.onClose();
    });

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCandidates.length
    );

    return promise;
  }
}
