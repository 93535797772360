/* import __COLOCATED_TEMPLATE__ from './scene.hbs'; */
import { action } from '@ember/object';
import { keyResponder, onKey } from 'ember-keyboard';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { bind, cancel, later } from '@ember/runloop';
import Server from 'teamtailor/services/server';
import VideoMeetingService from 'teamtailor/services/video-meeting';

import {
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalVideoTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video';

import IntlService from 'ember-intl/services/intl';
import {
  DeviceOption,
  RecordingStatus,
} from 'teamtailor/controllers/video-meetings';
import {
  AudioVideoFacade,
  ContentShareObserver,
  DefaultVideoTransformDevice,
} from 'amazon-chime-sdk-js';
import {
  AteendeeIdsToTilesMap,
  DeviceFacingMode,
  MeetingAttendee,
  MeetingData,
  RemoteParticipantScreenShare,
} from 'teamtailor/utils/video-meetings/utils';
import { VideoMeetingTokenModel } from 'teamtailor/models';
import { EmberRunTimer } from '@ember/runloop/types';

interface VideoMeetingSceneArgs {
  audioTrack?: LocalAudioTrack;
  dominantParticipant?: RemoteParticipant | MeetingAttendee;
  isDesktop: boolean;
  isRecording: boolean;
  localParticipant: LocalParticipant | MeetingAttendee;
  minimized: boolean;
  onTriggerRecording: () => void;
  previousDominantParticipant?: RemoteParticipant | MeetingAttendee;
  remoteParticipants?: RemoteParticipant[];
  remoteParticipantScreenShare?: RemoteParticipantScreenShare;
  room: Room;
  videoTrack?: LocalVideoTrack | MediaStream;
  model: VideoMeetingTokenModel;
  // Chime
  isNewProvider: boolean;
  meetingData?: MeetingData;
  audioVideoFacade: AudioVideoFacade;
  tilesMap?: AteendeeIdsToTilesMap;
  currentVideoInputDevice?: DeviceOption;
  videoTransformDevice: DefaultVideoTransformDevice | null;
  onChangeVideoInputDevice: (
    deviceOption?: DeviceOption,
    facingMode?: DeviceFacingMode
  ) => void;
  isRecordingAddonEnabled: boolean;
  interviewLayout: boolean;
  toggleCandidateCard: () => void;
}

export enum Timeout {
  MOBILE_CONTROLS = 4000,
}

type ViewOption = {
  id: string;
  label: string;
  icon: string;
};

@keyResponder
export default class VideoMeetingScene extends Component<VideoMeetingSceneArgs> {
  @service declare intl: IntlService;
  @service declare server: Server;
  @service declare videoMeeting: VideoMeetingService;

  @tracked declare screenTrack?: LocalVideoTrack;
  @tracked declare remoteScreenTrack?: RemoteVideoTrack;
  @tracked audio = true;
  @tracked video = true;
  @tracked screen = false;
  @tracked controlsVisible = true;
  @tracked view = 'grid';
  @tracked controlsContainer: HTMLElement | null = null;
  @tracked
  recordingSecondsElapsed = 0;

  @tracked hasTriggeredRecording = false;

  lastUsedVideoDevice: DefaultVideoTransformDevice | string | null = null;
  laterCallback: EmberRunTimer | null = null;

  declare mobileCameraMode: string;

  constructor(owner: unknown, args: VideoMeetingSceneArgs) {
    super(owner, args);

    if (!this.args.isNewProvider) {
      (<LocalParticipant>this.localParticipant).on(
        'trackEnabled',
        bind(this, this.trackEnabled)
      );
      (<LocalParticipant>this.localParticipant).on(
        'trackDisabled',
        bind(this, this.trackDisabled)
      );
      this.audio = this.args.audioTrack?.isEnabled || false;

      this.room.on('trackSubscribed', bind(this, this.handleTrackSubscribed));
      this.room.on(
        'trackUnsubscribed',
        bind(this, this.handleTrackUnsubscribed)
      );
    }

    document.addEventListener('touchstart', this.showControls);
    document.addEventListener('mousemove', this.showControls);
    this.hideControls.perform();
  }

  get viewOptions(): ViewOption[] {
    return [
      {
        id: 'speaker',
        icon: 'image-user',
        label: this.intl.t('components.video_meeting.views.speaker'),
      },
      {
        id: 'grid',
        icon: 'grid',
        label: this.intl.t('components.video_meeting.views.grid'),
      },
    ];
  }

  get participantCount(): number {
    return this.args.remoteParticipants?.length || 0;
  }

  get singleView(): boolean {
    return (
      this.participantCount === 1 &&
      !this.shareView &&
      !this.args.interviewLayout
    );
  }

  get shareView(): boolean {
    return this.screen || this.isScreenShared;
  }

  get isScreenShared() {
    return (
      !isEmpty(this.remoteScreenTrack) ||
      !isEmpty(this.args.remoteParticipantScreenShare)
    );
  }

  get speakerView(): boolean {
    return this.view === 'speaker' || this.shareView;
  }

  get room(): Room {
    return this.args.room;
  }

  get localParticipant(): LocalParticipant | MeetingAttendee {
    return this.args.localParticipant;
  }

  get desktopLayout(): boolean {
    return this.args.isDesktop && !this.args.minimized;
  }

  get showMeetingControls(): boolean {
    return !this.args.interviewLayout && !this.singleView;
  }

  handleTrackSubscribed = (track: RemoteTrack) => {
    if (track.kind === 'data') {
      return;
    }

    if (track.kind === 'video' && track.name === 'screen-share') {
      this.handleStartShareScreen(track);
    }

    if (track.kind === 'audio') {
      document.body.appendChild(track.attach());
    }
  };

  handleTrackUnsubscribed = (track: RemoteTrack) => {
    if (track.kind === 'video' && track.name === 'screen-share') {
      this.handleStopShareScreen();
    }

    if (track.kind !== 'data') {
      track.detach().forEach((element: HTMLElement) => {
        element.remove();
      });
    }
  };

  handleStartShareScreen = (track: RemoteVideoTrack): void => {
    this.remoteScreenTrack = track;
  };

  handleStopShareScreen = (): void => {
    this.remoteScreenTrack = undefined;
  };

  showControls = (): void => {
    this.controlsVisible = true;
    this.hideControls.perform();
  };

  hideControls = restartableTask(async () => {
    if (!this.args.interviewLayout) {
      await timeout(Timeout.MOBILE_CONTROLS);
      this.controlsVisible = false;
    }
  });

  trackEnabled = (track: LocalTrack) => {
    if (track.kind === 'video' && track.name !== 'screen-share') {
      this.video = true;
    }

    if (track.kind === 'audio') {
      this.audio = true;
    }
  };

  trackDisabled = (track: LocalTrack) => {
    if (track.kind === 'video' && track.name !== 'screen-share') {
      this.video = false;
    }

    if (track.kind === 'audio') {
      this.audio = false;
    }
  };

  stopAudio = (): void => {
    if (this.args.isNewProvider) {
      this.args.audioVideoFacade.realtimeMuteLocalAudio();
      this.audio = !this.args.audioVideoFacade.realtimeIsLocalAudioMuted();
    } else {
      this.args.audioTrack?.disable();
    }
  };

  startAudio = (): void => {
    if (this.args.isNewProvider) {
      this.args.audioVideoFacade.realtimeUnmuteLocalAudio();
      this.audio = !this.args.audioVideoFacade.realtimeIsLocalAudioMuted();
    } else {
      this.args.audioTrack?.enable();
    }
  };

  stopVideo = async (): Promise<void> => {
    if (this.args.isNewProvider) {
      this.args.audioVideoFacade.stopLocalVideoTile();
      await this.args.audioVideoFacade.stopVideoInput();
      this.video = false;
      this.lastUsedVideoDevice =
        this.args.videoTransformDevice ||
        this.args.currentVideoInputDevice?.deviceId ||
        null;
    } else {
      (<LocalParticipant>this.localParticipant).videoTracks.forEach(
        (publication) => {
          if (publication.track.name !== 'screen-share') {
            publication.track.disable();
          }
        }
      );
    }
  };

  startVideo = async (): Promise<void> => {
    if (this.args.isNewProvider) {
      if (this.lastUsedVideoDevice) {
        await this.args.audioVideoFacade.startVideoInput(
          this.lastUsedVideoDevice
        );

        this.args.audioVideoFacade.startLocalVideoTile();
        this.video = true;
        this.lastUsedVideoDevice = null;
      }
    } else {
      (<LocalParticipant>this.localParticipant).videoTracks.forEach(
        (publication) => {
          if (publication.track.name !== 'screen-share') {
            publication.track.enable();
          }
        }
      );
    }
  };

  stopScreen = (): void => {
    if (this.args.isNewProvider) {
      this.args.toggleCandidateCard();

      this.args.audioVideoFacade.stopContentShare();
      this.args.audioVideoFacade.removeContentShareObserver(
        this.screenShareObserver
      );

      this.screen = false;
    } else {
      if (this.screenTrack) {
        (<LocalParticipant>this.localParticipant).unpublishTrack(
          this.screenTrack
        );
        this.screenTrack.stop();
        this.screenTrack = undefined;
        this.screen = false;
      }
    }
  };

  async startScreen(): Promise<void> {
    this.screen = true;

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          frameRate: 20,
        },
      });

      if (this.args.isNewProvider) {
        this.args.toggleCandidateCard();

        this.args.audioVideoFacade.addContentShareObserver(
          this.screenShareObserver
        );
        this.args.audioVideoFacade.startContentShare(stream);
      } else {
        const streamTrack = stream.getTracks()[0];
        if (streamTrack) {
          this.screenTrack = new LocalVideoTrack(streamTrack, {
            name: 'screen-share',
          });

          (<LocalParticipant>this.localParticipant).publishTrack(
            this.screenTrack,
            {
              priority: 'high',
            }
          );

          this.screenTrack.once('stopped', this.stopScreen);
        }
      }
    } catch {
      this.screen = false;
    }
  }

  get showRecordingButton() {
    return (
      this.args.isRecordingAddonEnabled &&
      this.args.model.userId &&
      (!this.args.isRecording || this.hasTriggeredRecording)
    );
  }

  async setRecordingStatus(status: string) {
    this.args.onTriggerRecording();
    await this.args.model.setRecordingStatus(this.args.model.id, {
      action_name: status,
      sid: this.recordingSid,
      meeting_arn: this.meetingArn,
    });
  }

  get recordingSid() {
    if (this.args.isNewProvider) {
      return this.args.meetingData?.meeting.Meeting.MeetingId;
    } else {
      return this.room.sid;
    }
  }

  get meetingArn() {
    if (this.args.isNewProvider) {
      return this.args.meetingData?.meeting.Meeting.MeetingArn;
    }
  }

  get nextMobileCameraMode(): 'user' | 'environment' {
    if (!this.mobileCameraMode || this.mobileCameraMode === 'user') {
      return 'environment';
    } else {
      return 'user';
    }
  }

  get controlButtonElements(): NodeListOf<HTMLButtonElement> | undefined {
    return this.controlsContainer?.querySelectorAll('button');
  }

  get controlButtonFocused(): boolean {
    return Array.from(this.controlButtonElements || []).some(
      (button: HTMLButtonElement) => button === document.activeElement
    );
  }

  get allowKeyboardNavigation() {
    const element = document.activeElement as HTMLElement;

    if (element.isContentEditable) {
      return false;
    }

    if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
      return (element as HTMLInputElement).disabled;
    }

    return true;
  }

  @onKey('Space')
  toggleControls(e: KeyboardEvent): void {
    if (!this.allowKeyboardNavigation || this.controlsVisible) return;

    this.showControls();

    if (this.controlButtonFocused && e.target instanceof HTMLButtonElement) {
      e.preventDefault();
    } else {
      this.controlButtonElements?.item(0).focus();
    }
  }

  @onKey('Escape')
  hideControlsOnEscape(): void {
    if (!this.allowKeyboardNavigation) return;

    if (this.controlsVisible) {
      this.controlsVisible = false;
    }
  }

  @onKey('Tab')
  handleOpenControls(): void {
    if (!this.allowKeyboardNavigation) return;

    if (this.controlsVisible) {
      this.showControls();
    }
  }

  @onKey('ArrowRight')
  handleArrowRight(): void {
    if (
      !this.allowKeyboardNavigation ||
      !this.controlsVisible ||
      !this.controlButtonFocused
    )
      return;

    this.showControls();
    const nextButton = document.activeElement?.nextElementSibling;
    if (nextButton) {
      (nextButton as HTMLButtonElement).focus();
    } else {
      this.controlButtonElements?.item(0).focus();
    }
  }

  @onKey('ArrowLeft')
  handleArrowLeft(): void {
    if (
      !this.allowKeyboardNavigation ||
      !this.controlsVisible ||
      !this.controlButtonFocused
    )
      return;

    this.showControls();
    const previousButton = document.activeElement?.previousElementSibling;
    if (previousButton) {
      (previousButton as HTMLButtonElement).focus();
    } else {
      this.controlButtonElements
        ?.item(this.controlButtonElements.length - 1)
        .focus();
    }
  }

  @onKey('shift+m')
  @action
  toggleAudio(): void {
    if (!this.allowKeyboardNavigation) return;
    if (this.audio) {
      this.stopAudio();
    } else {
      this.startAudio();
    }
  }

  @onKey('shift+v')
  @action
  toggleVideo(): void {
    if (!this.allowKeyboardNavigation) return;
    if (this.video) {
      this.stopVideo();
    } else {
      this.startVideo();
    }
  }

  @action
  async toggleRecording(): Promise<void> {
    if (this.args.isRecording) {
      await this.setRecordingStatus(RecordingStatus.STOP);
      this.hasTriggeredRecording = false;
      this.stopTimer();
    } else {
      await this.setRecordingStatus(RecordingStatus.START);
      this.hasTriggeredRecording = true;
      this.startTimer();
    }
  }

  startTimer() {
    this.laterCallback = later(
      this,
      () => {
        if (this.args.isRecording) {
          this.recordingSecondsElapsed++;
          this.startTimer();
        }
      },
      1000
    );
  }

  stopTimer() {
    if (this.laterCallback) cancel(this.laterCallback);
    later(
      this,
      () => {
        this.recordingSecondsElapsed = 0;
      },
      1000 // We wait 1 second to be better sure that the recording status gets updated before resetting the timer
    );
  }

  @action
  toggleScreen(): void {
    if (this.screen) {
      this.stopScreen();
    } else {
      this.startScreen();
    }
  }

  @action
  changeView(view: string): void {
    this.view = view;
  }

  @action
  handleLeave(): void {
    if (this.args.isNewProvider) {
      if (this.screen) {
        this.stopScreen();
      }

      this.args.audioVideoFacade.stop();
    } else {
      this.room.disconnect();
    }
  }

  @action
  flipCamera() {
    if (this.args.isNewProvider) {
      if (this.args.currentVideoInputDevice?.deviceId) {
        this.args.onChangeVideoInputDevice(
          undefined,
          this.nextMobileCameraMode
        );
        this.mobileCameraMode = this.nextMobileCameraMode;
      }
    } else {
      (<LocalParticipant>this.localParticipant).videoTracks.forEach(
        (publication) => {
          publication.track.restart({ facingMode: this.nextMobileCameraMode });
          this.mobileCameraMode = this.nextMobileCameraMode;
        }
      );
    }
  }

  @action
  handleInsertControls(element: HTMLElement): void {
    this.controlsContainer = element;
  }

  get screenShareObserver(): ContentShareObserver {
    return {
      contentShareDidStop: () => {
        if (this.screen) {
          this.stopScreen();
        }
      },
    };
  }

  get localParticipantName(): string {
    let name = '';

    if (this.args.isNewProvider) {
      name = (<MeetingAttendee>this.args.localParticipant).name || 'guest';
    } else {
      name =
        (<LocalParticipant>this.args.localParticipant).identity.split(
          '--'
        )[0] || 'guest';
    }

    if (name === 'guest') name = this.intl.t('components.video_meeting.guest');

    return name;
  }
}
