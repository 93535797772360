/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import { inject as service } from '@ember/service';
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { chartTypes } from './utils';

export default class InfoGraphicComponent extends BaseComponent {
  @service intl;

  get infographicOptions() {
    return chartTypes(this.intl);
  }
}
