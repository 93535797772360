import Model, { attr, belongsTo } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

export default class PartnerResult extends Model {
  @belongsTo('candidate') candidate;
  @belongsTo('partnerActivation') partnerActivation;
  @belongsTo('partner') partner;
  @belongsTo('job') job;

  @attr('string', { defaultValue: 'sending' }) status;
  @attr('date') updatedAt;
  @attr('date') createdAt;
  @attr('number') assessmentScore;
  @attr('string') assessmentGrade;
  @attr('number') assessmentDuration;
  @attr('string') summary;
  @attr('string') url;
  @attr('string') lastErrorResponse;
  @attr('string') partnerId;
  @attr('raw', { defaultValue: () => ({}) }) webhookData;
  @attr('raw', { defaultValue: () => ({}) }) webhookConfig;
  @attr('raw', { defaultValue: () => ({}) }) details;
  @attr('raw') attachments;

  get jobId() {
    return get(this, 'job.id');
  }

  get hasScore() {
    return !isEmpty(get(this, 'assessmentScore'));
  }

  get isFailed() {
    return get(this, 'status') === 'failed';
  }

  get hasEmptyDetails() {
    return isEmpty(get(this, 'details'));
  }

  get hasAttachments() {
    return !isEmpty(get(this, 'attachments'));
  }

  get progressValue() {
    const maxValue = 251.1;
    let progress = 251.1 * (get(this, 'assessmentScore') / 100);
    return `${progress},${maxValue}`;
  }

  get elipseProgressValue() {
    const score = get(this, 'assessmentScore') || 0;
    const num = 1 - score / 100;
    const dashArray = 226.226;
    let progress = dashArray * 2 - dashArray * num;
    return progress;
  }

  get lastErrorResponseArray() {
    return get(this, 'lastErrorResponse').split('\n');
  }
}
