import Transform from '@ember-data/serializer/transform';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { AvailableUserAttribute } from 'teamtailor/models/single-sign-on';
import classic from 'ember-classic-decorator';

@classic
export default class SsoUserAttributeTransform extends Transform {
  @service declare intl: IntlService;

  deserialize(serialized: AvailableUserAttribute[]) {
    return serialized.map((attribute) => ({
      ...attribute,
      values: attribute.values?.map((value) => ({
        ...value,
        translation: this.intl.t(value.translation_path),
      })),

      translation: this.intl.t(attribute.translation_path),
    }));
  }

  serialize(deserialized: AvailableUserAttribute[]) {
    return deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'sso-user-attribute': SsoUserAttributeTransform;
  }
}
