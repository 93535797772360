/* import __COLOCATED_TEMPLATE__ from './add-reference.hbs'; */
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class AddReference extends Component {
  @service store;
  @service router;

  @tracked email = null;
  @tracked errors = {};

  name = null;
  title = null;
  sendReferenceEmail = false;
  referenceTemplateId = null;

  get candidate() {
    return this.args.candidate;
  }

  get jobApplication() {
    return this.args.jobApplication;
  }

  get addButtonDisabled() {
    return !this.email;
  }

  reset() {
    set(this, 'email', null);
    set(this, 'name', null);
    set(this, 'title', null);
    set(this, 'sendReferenceEmail', false);
    set(this, 'referenceTemplateId', null);
  }

  @action
  addReference() {
    const reference = this.store.createRecord('reference', {
      candidate: get(this, 'candidate'),
      email: get(this, 'email'),
      title: get(this, 'title'),
      name: get(this, 'name'),
      jobApplication: get(this, 'jobApplication'),
      job: get(this, 'jobApplication.job'),
    });

    return reference
      .save()
      .then(() => {
        if (this.sendReferenceEmail && this.referenceTemplateId) {
          get(this, 'candidate')
            .askForFeedback({
              reference_id: get(reference, 'id'),
              template_id: this.referenceTemplateId,
            })
            .then(() => {
              reference.reload();
            });
        }
      })
      .then(() => {
        this.reset();
        setTimeout(() => {
          this.args.onSave();
        }, 500);
      })
      .catch(() => {
        set(this, 'errors', reference.errors);
        reference.rollbackAttributes();
      });
  }

  @action
  selectReferenceTemplate(template) {
    set(this, 'referenceTemplateId', template.id);
  }

  get referenceTemplates() {
    return this.store.findAll('reference-template');
  }

  get referenceTemplatesURL() {
    return this.router.urlFor('settings.reference-templates');
  }
}
