import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class PickedCustomField extends ApplicationSerializer {
  attrs = {
    isHidden: { serialize: false },
    isFeatured: { serialize: false },
    isPrivate: { serialize: false },
    isExternal: { serialize: false },
    isRequired: { serialize: false },
    type: { serialize: false },
    rowOrder: { serialize: false },
  };

  payloadKeyFromModelName() {
    return 'custom_field_picked_custom_field';
  }
}
