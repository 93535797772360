/* import __COLOCATED_TEMPLATE__ from './archive-modal.hbs'; */
import { get } from 'teamtailor/utils/get';
import { action } from '@ember/object';
import RejectComponent from 'teamtailor/components/bulk-actions/candidates/reject';
import JobModel from 'teamtailor/models/job';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { restartableTask } from 'ember-concurrency';

const HIRED_DISPLAY_LIMIT = 15;

export default class ArchiveModal extends RejectComponent {
  @service declare router: RouterService;

  @tracked rejectRemainingCandidates = false;

  get job(): JobModel {
    return this.args.job;
  }

  get remainingCandidatesCount() {
    return this.job.activeJobApplicationsCount - this.hiredCount;
  }

  get hiredStageJobApplicationCount() {
    return get(get(this.job, 'hiredStage'), 'jobApplicationCount');
  }

  get sourcedNotHiredCount() {
    return this.sourcedJobApplicationsCount - this.sourcedHiredCount;
  }

  get sourcedJobApplicationsCount() {
    return get(this.job.jobDetail, 'activeSourcedJobApplicationsCount');
  }

  get sourcedHiredCount() {
    return get(this.hiredStageJobApplicationCount, 'activeSourcedCount');
  }

  get hiredCount() {
    return get(this.hiredStageJobApplicationCount, 'activeCount');
  }

  get moreCount() {
    return Math.max(this.hiredCount - HIRED_DISPLAY_LIMIT, 0);
  }

  get statusButtonDisabled() {
    return this.hiredCandidatesTask.isRunning || this.isRejectFormInvalid;
  }

  get isRejectFormInvalid() {
    return this.rejectRemainingCandidates && !this.rejectSettingsFormIsValid;
  }

  get rejectOptions() {
    if (!this.rejectRemainingCandidates) {
      return;
    }

    return this.bulkRejectOptions;
  }

  fetchHiredCandidatesTask = restartableTask(async () => {
    const hiredStage = await get(this.job, 'hiredStage');

    if (hiredStage) {
      if (get(hiredStage, 'activeJobApplications').length === 0) {
        await get(hiredStage, 'fetchJobApplicationsTask').perform({
          rejected: false,
        });
      }

      return get(hiredStage, 'activeCandidates').slice(0, HIRED_DISPLAY_LIMIT);
    }
  });

  hiredCandidatesTask = trackedTask(this, this.fetchHiredCandidatesTask, () => [
    this.job,
  ]);

  @action
  openSourcedJobApplications() {
    this.args.onClose();
    this.router.transitionTo('jobs.job.stages.index', {
      job: this.job,
      queryParams: { sourced: 'yes', sidebarToShow: 'filters' },
    });
  }
}
