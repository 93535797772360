/* import __COLOCATED_TEMPLATE__ from './stage-list-wrapper.hbs'; */
import Component from '@glimmer/component';

export default class StageListWrapperComponent extends Component {
  declare stagesDropListWrapperEl: HTMLElement;

  focusStagesDropListEl = () => {
    this.stagesDropListWrapperEl.setAttribute('tabindex', '-1');
    this.stagesDropListWrapperEl.focus();

    const onElFocusout = () => {
      this.stagesDropListWrapperEl.removeAttribute('tabindex');
      this.stagesDropListWrapperEl.removeEventListener(
        'focusout',
        onElFocusout
      );
    };

    this.stagesDropListWrapperEl.addEventListener('focusout', onElFocusout);
  };
}
