/* import __COLOCATED_TEMPLATE__ from './activity-breakdown.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class InsightsMoleculesActivityBreakdown extends Component {
  @service intl;

  get chartCategories() {
    return [this.intl.t('insights.molecules.activity_breakdown.times')];
  }
}
