import { CandidateModel, ModelKey, CompanyModel } from 'teamtailor/models';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import Store from '@ember-data/store';
import CandidateModalFeedService from 'teamtailor/services/candidate-modal-feed';
import ModelRegistry from 'ember-data/types/registries/model';

type GenericPusherData = {
  candidate_id: number;
};

type CandidateUpdateData = {
  candidate_id?: number;
  candidate: { id: number };
};

type MeetingMessagePusherData = {
  message_id: number;
} & GenericPusherData;

type ActivityPusherData = {
  job_application_id?: number;
  activity_id: number;
  job_id?: number;
  id_to_destroy?: number;
} & GenericPusherData;

type PartnerResultPusherData = {
  partner_result_id: number;
} & GenericPusherData;

type InterviewPusherData = {
  interview_id: number;
} & GenericPusherData;

type Message = { id: number };

type MessagesUpdatePusherData = {
  messages: Message[];
};

type ReactionPusherData = {
  reaction: {
    action_id: number;
  };
};

type JobApplicationTriggerPusherData = {
  job_application_trigger_id: number;
  trigger_id: number;
  trigger_type: keyof ModelRegistry;
} & GenericPusherData;

const deconstructActivityPushArgs = (data: ActivityPusherData) => {
  return {
    candidateId: data.candidate_id.toString(),
    activityId: data.activity_id.toString(),
    jobApplicationId: data.job_application_id?.toString(),
  };
};

export const handleActivities = async (
  candidate: CandidateModel,
  store: Store,
  data: ActivityPusherData,
  candidateModalFeed: CandidateModalFeedService
) => {
  if (data.id_to_destroy) {
    const review = store.peekRecord('activity', data.id_to_destroy);

    if (review) {
      review.unloadRecord();
      candidateModalFeed.removeRecord(review, {
        modelKey: 'activity',
        candidate,
      });
    }

    return;
  }

  const { candidateId, activityId, jobApplicationId } =
    deconstructActivityPushArgs(data);

  if (candidateId.toString() !== candidate.id) {
    return;
  }

  const activity = await store.findRecord('activity', activityId, {
    reload: true,
  });

  const jobApplication = jobApplicationId
    ? store.peekRecord('job-application', jobApplicationId)
    : null;

  const pushToFeed = (modelKey: ModelKey, modelId: string | null) => {
    if (!modelId) {
      return;
    }

    const record = store.peekRecord(modelKey, modelId);

    if (record) {
      candidateModalFeed.addRecord(record, {
        candidate,
        modelKey,
        jobApplication,
      });
    }
  };

  pushToFeed('activity', activityId);

  if (activity.code === 'message') {
    pushToFeed('message', activity.belongsTo('message').id());
  }

  if (activity.code === 'note') {
    await store.findRecord('note', activityId);
    pushToFeed('note', activityId);
  }
};

export class CandidatePusherEvents {
  declare candidate: CandidateModel;
  declare pusher: PusherService;
  declare store: Store;
  declare candidateModalFeed: CandidateModalFeedService;
  declare company: CompanyModel;
  declare channel?: PusherChannel;

  constructor(
    candidate: CandidateModel,
    pusher: PusherService,
    store: Store,
    candidateModalFeed: CandidateModalFeedService,
    company: CompanyModel
  ) {
    this.candidate = candidate;
    this.pusher = pusher;
    this.store = store;
    this.candidateModalFeed = candidateModalFeed;
    this.company = company;
  }

  async bindEvents() {
    this.channel = await this.pusher.subscribe(this.company.candidatesChannel);

    this.channel.bind('new-activity', this.handleActivityPush);
    this.channel.bind('updated-activity', this.handleActivityPush);
    this.channel.bind('destroyed-activity', this.handleActivityPush);
    this.channel.bind('update-partner-result', this.handleUpdatePartnerResult);
    this.channel.bind('updated-candidate', this.handleUpdatedCandidate);
    this.channel.bind('updated-interview', this.handleUpdatedInterview);
    this.channel.bind(
      'meeting-invite-message',
      this.handleMeetingInviteMessage
    );
    this.channel.bind('updated-messages', this.handleUpdatedMessages);
    this.channel.bind('new-reaction', this.handleNewReaction);
    this.channel.bind(
      'created-job-application-trigger',
      this.handleCreatedJobApplicationTrigger
    );
    this.channel.bind(
      'updated-job-application-trigger',
      this.handleUpdatedJobApplicationTrigger
    );
    this.channel.bind('destroyed-reaction', this.handleDestroyedReaction);
  }

  unbindEvents() {
    if (this.channel) {
      this.channel.bind('new-activity', this.handleActivityPush);
      this.channel.bind('updated-activity', this.handleActivityPush);
      this.channel.bind('destroyed-activity', this.handleActivityPush);
      this.channel.bind(
        'update-partner-result',
        this.handleUpdatePartnerResult
      );
      this.channel.bind('updated-candidate', this.handleUpdatedCandidate);
      this.channel.bind('updated-interview', this.handleUpdatedInterview);
      this.channel.bind(
        'meeting-invite-message',
        this.handleMeetingInviteMessage
      );
      this.channel.bind('updated-messages', this.handleUpdatedMessages);
      this.channel.bind('new-reaction', this.handleNewReaction);
      this.channel.bind(
        'created-job-application-trigger',
        this.handleCreatedJobApplicationTrigger
      );
      this.channel.bind(
        'updated-job-application-trigger',
        this.handleUpdatedJobApplicationTrigger
      );
      this.channel.bind('destroyed-reaction', this.handleDestroyedReaction);
    }
  }

  private handleActivityPush = (data: ActivityPusherData) => {
    handleActivities(this.candidate, this.store, data, this.candidateModalFeed);
  };

  private handleUpdatePartnerResult = (data: PartnerResultPusherData) => {
    if (this.candidate.id === data.candidate_id.toString()) {
      this.store.findRecord('partner-result', data.partner_result_id);
    }
  };

  private handleUpdatedCandidate = (data: CandidateUpdateData) => {
    if (data.candidate_id) {
      if (this.candidate.id === data.candidate_id.toString()) {
        this.store.findRecord('candidate', data.candidate_id);
      }
    } else {
      if (this.store.peekRecord('candidate', data.candidate.id)) {
        this.store.pushPayload(data);
      }
    }
  };

  private handleUpdatedInterview = (data: InterviewPusherData) => {
    if (this.candidate.id === data.candidate_id.toString()) {
      this.store.findRecord('interview', data.interview_id, { reload: true });
    }
  };

  private handleMeetingInviteMessage = async (
    data: MeetingMessagePusherData
  ) => {
    if (this.candidate.id === data.candidate_id.toString()) {
      const messageId = data.message_id;
      const modelKey = 'message';
      const record = await this.store.findRecord(modelKey, messageId);

      this.candidateModalFeed.addRecord(record, {
        candidate: this.candidate,
        modelKey,
      });
    }
  };

  private handleUpdatedMessages = (data: MessagesUpdatePusherData) => {
    data.messages = data.messages.filter((message: { id: number }) =>
      this.store.peekRecord('message', message.id)
    );

    if (data.messages.length > 0) {
      this.store.pushPayload(data);
    }
  };

  private handleNewReaction = (data: ReactionPusherData) => {
    if (data.reaction.action_id) {
      if (this.store.peekRecord('activity', data.reaction.action_id)) {
        this.store.pushPayload(data);
      }
    }
  };

  private handleDestroyedReaction = (id: number) => {
    const reaction = this.store.peekRecord('reaction', id);
    if (reaction) {
      this.store.unloadRecord(reaction);
    }
  };

  private handleCreatedJobApplicationTrigger = async (
    data: JobApplicationTriggerPusherData
  ) => {
    if (this.candidate.id === data.candidate_id.toString()) {
      await this.store.findRecord(data.trigger_type, data.trigger_id);
      const jobApplicationTrigger = await this.store.findRecord(
        'job-application-trigger',
        data.job_application_trigger_id
      );
      const jobApplicationTriggers =
        await this.candidate.jobApplicationTriggers;
      jobApplicationTriggers.push(jobApplicationTrigger);
    }
  };

  private handleUpdatedJobApplicationTrigger = async (
    data: JobApplicationTriggerPusherData
  ) => {
    if (this.candidate.id === data.candidate_id.toString()) {
      await this.store.findRecord(data.trigger_type, data.trigger_id);

      const jobApplicationTrigger = this.store.peekRecord(
        'job-application-trigger',
        data.job_application_trigger_id
      );

      if (jobApplicationTrigger) {
        jobApplicationTrigger.reload();
      }
    }
  };
}
