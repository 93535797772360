/* import __COLOCATED_TEMPLATE__ from './delete-modal.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { CannedResponseModel } from 'teamtailor/models';
import Store from '@ember-data/store';
import Server from 'teamtailor/services/server';
import { tracked } from '@glimmer/tracking';

type Meta = {
  total_count: number;
};

type JobJson = {
  id: number;
};

interface Args {
  cannedResponse: CannedResponseModel;
}

export default class SettingsCannedResponseDeleteModal extends Component<Args> {
  @service declare store: Store;
  @service declare server: Server;

  @tracked totalJobsCount: number | null = null;

  fetchRelatedJobsTask = dropTask(async () => {
    const response = await this.server.memberAction<{
      jobs?: JobJson[];
      meta: Meta;
    }>(this.args.cannedResponse, {
      action: 'related_jobs',
      method: 'GET',
    });

    if (response.jobs) {
      this.totalJobsCount = response.meta.total_count;
      this.store.pushPayload(response);

      const displayCount = this.totalJobsCount === 6 ? 6 : 5;

      return response.jobs.slice(0, displayCount).map((job: JobJson) => {
        return this.store.peekRecord('job', job.id);
      });
    }
  });

  relatedJobsTask = trackedTask(this, this.fetchRelatedJobsTask, () => [
    this.args.cannedResponse,
  ]);
}
