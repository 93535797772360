/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class FormBaseComponent extends Component {
  @service current;
  @service confetti;
  @service intl;
  @service iframePreview;
  @service blockAdjustments;
  @service filthyContent;

  DEFAULT_BORDER_RADIUS = 8;
  DEFAULT_MIN = 0;
  DEFAULT_MAX = 40;
  DEFAULT_HEADER_FONT = 'Roboto';
  DEFAULT_BODY_FONT = 'Inter';

  @tracked showEnableCustomStylesModal = false;

  get design() {
    return get(this, 'args.design');
  }

  get buttonStyles() {
    return [
      {
        id: true,
        label: this.intl.t('content.editor.global_design.outlined'),
      },
      {
        id: false,
        label: this.intl.t('content.editor.global_design.filled'),
      },
    ];
  }

  get fontWeights() {
    return [
      { id: 300, label: 'Thin 300' },
      { id: 400, label: 'Normal 400' },
      { id: 500, label: 'Medium 500' },
      { id: 600, label: 'Semi bold 600' },
      { id: 700, label: 'Bold 700' },
      { id: 900, label: 'Extra Bold 900' },
    ];
  }

  get showCustomStylesForm() {
    return (
      get(this.current.company, 'isCustomStylesEnabled') ||
      !isEmpty(this.design.customJavascript) ||
      !isEmpty(this.design.customCss)
    );
  }

  get fontFamiliesString() {
    const fontWeights = this.fontWeights.map(({ id }) => id).join(',');
    return this.design.availableFonts
      ?.map((font) => `${font}:${fontWeights}`)
      .join('|');
  }

  get linkStyles() {
    return [
      {
        id: 'bold',
        label: this.intl.t('content.editor.global_design.link_styles.bold'),
      },
      {
        id: 'underline',
        label: this.intl.t(
          'content.editor.global_design.link_styles.underline'
        ),
      },
      {
        id: 'highlight',
        label: this.intl.t(
          'content.editor.global_design.link_styles.highlight'
        ),
      },
      {
        id: 'underline_highlight',
        label: this.intl.t(
          'content.editor.global_design.link_styles.underline_highlight'
        ),
      },
    ];
  }

  get employeesShapeOptions() {
    return [
      {
        id: 'circle',
        label: this.intl.t('content.editor.section.people.shapes.circle'),
      },
      {
        id: 'square',
        label: this.intl.t('content.editor.section.people.shapes.square'),
      },
    ];
  }

  resetColors() {
    this.design.primaryBackgroundColor = '#ffffff';
    this.design.secondaryBackgroundColor = this.design.color;
    this.design.primaryBackgroundColor = '#ffffff';
    this.design.secondaryTextColor = '#ffffff';
    this.design.primaryTextColor = '#252525';
    this.design.linkColor = this.design.color;
    this.design.buttonTextColor = '#ffffff';
    this.design.buttonBackgroundColor = this.design.color;
  }

  async saveAndReload() {
    if (
      this.design.hasDirtyAttributes ||
      this.filthyContent.isFilthy(get(this.design, 'id'))
    ) {
      await this.design.save();
      this.reload();
      this.filthyContent.setClean(get(this.design, 'id'));
    }
  }

  reload() {
    this.iframePreview.reloadIframe();
    this.iframePreview.reloadStyleguideIframe();
  }

  @action
  async handleAttributeChange() {
    await this.saveAndReload();
  }

  @action
  async handlePrimaryColorChange() {
    this.resetColors();
    await this.saveAndReload();
  }

  @action
  async handleButtonStyleChange(value) {
    this.design.buttonsOutlined = value;
    await this.saveAndReload();
  }

  @action
  async handleLinkStyleChange(value) {
    this.design.linkStyle = value;
    await this.saveAndReload();
  }

  @action
  async handleFontWeightChange(attribute, value) {
    this.design[attribute] = value;
    await this.saveAndReload();
  }

  @action
  async handleCustomFontsChange(value) {
    this.design.useCustomFonts = value;

    this.design.headerFont = value ? '' : this.DEFAULT_HEADER_FONT;
    this.design.bodyFont = value ? '' : this.DEFAULT_BODY_FONT;

    await this.saveAndReload();
  }

  @action
  async handleFaviconRemoved() {
    this.design.favicon = null;
    this.design.faviconCache = null;
    await this.saveAndReload();
  }

  @action
  async handleToggleEnableCustomStyles() {
    if (!get(this.current.company, 'isCustomStylesEnabled')) {
      this.showEnableCustomStylesModal = !this.showEnableCustomStylesModal;
    }
  }

  @action
  sendEnableCustomStylesForm(reason) {
    this.showEnableCustomStylesModal = false;
    set(this.current.company, 'isCustomStylesEnabled', true);
    return this.current.company.enableCustomStyles({ slack: { reason } });
  }

  @action
  async getWebsiteFavicon() {
    await this.design.getWebsiteFavicon();
    this.design.faviconCache = this.design.favicon;
    await this.saveAndReload();
  }

  @action
  async handleToggleConfetti() {
    if (this.design.confetti) {
      this.confetti.show();
    }
  }

  @action
  async handleShapeChange(shape) {
    this.design.employeesShape = shape;
    await this.saveAndReload();
  }
}
