/* import __COLOCATED_TEMPLATE__ from './custom-form-details.hbs'; */
import Component from '@ember/component';
import { requisitionFormToFormBuilderFields } from 'teamtailor/utils/form-builder';
import { computed, get } from '@ember/object';

export default Component.extend({
  showCustomFields: computed('customFields', 'customFieldAnswers', function () {
    const answers = get(this, 'customFieldAnswers');
    return answers && Object.keys(answers).length !== 0;
  }),

  customFields: computed('requisition.customForm.form', function () {
    const form = get(this, 'requisition.customForm.form');
    const fields = requisitionFormToFormBuilderFields(form?.slice());

    return fields;
  }),
});
