import Controller, { inject as controller } from '@ember/controller';
import {
  restartableTask,
  timeout,
  waitForProperty,
  enqueueTask,
} from 'ember-concurrency';
import { get, set, setProperties, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next, later } from '@ember/runloop';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import SegmentValidations from 'teamtailor/validations/segment';
import * as deepmerge from 'deepmerge';
import { resolve } from 'rsvp';
import ArrayProxy from '@ember/array/proxy';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { jsonToBase64 } from 'teamtailor/utils/base-64';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import InfinityModel from 'ember-infinity/lib/infinity-model';
import classic from 'ember-classic-decorator';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { tracked } from '@glimmer/tracking';
import { loadAvailableColumns } from 'teamtailor/constants/candidate-columns';
import { isUnfinishedAdvancedSearch } from 'teamtailor/utils/advanced-search';

const DEFAULT_LIST_DISPLAY_COUNT = 5;

const LOCAL_STORAGE_KEY = 'candidates-display-states';
const DEFAULT_EMOJIS = Object.freeze([
  '📈',
  '🚀',
  '👍',
  '💤',
  '⭐️',
  '🎉',
  '🥇',
]);

@classic
export class SegmentsInfinityModel extends InfinityModel {
  hasAdditionalCandidates = false;

  afterInfinityModel(data) {
    if (this.hasAdditionalCandidates) {
      const ids = this.content.mapBy('id');

      if (ids.length) {
        set(
          data,
          'content',
          data.content.filter((candidate) => !ids.includes(candidate.id))
        );
      }
    }

    return data;
  }
}

// Make sure this matches #fetch_candidates_from_db? in the candidates controller
export const DEFAULT_SEARCH_PARAMS = {
  query: '',
  root: [],
};

const DEFAULT_DISPLAY_LIST_ITEM_STATE = Object.freeze({
  showAll: false,
  forceClose: false,
});

const DEFAULT_DISPLAY_STATES = Object.freeze({
  mySegments: DEFAULT_DISPLAY_LIST_ITEM_STATE,
  companySegments: DEFAULT_DISPLAY_LIST_ITEM_STATE,
  departments: DEFAULT_DISPLAY_LIST_ITEM_STATE,
});

const getRandomEmoji = function () {
  return DEFAULT_EMOJIS[Math.floor(Math.random() * DEFAULT_EMOJIS.length)];
};

export default class CandidatesSegmentController extends Controller {
  @service appRefresher;
  @service flipper;
  @service flashMessages;
  @service candidateFilterParams;
  @service current;
  @service infinity;
  @service intl;
  @service router;
  @service store;
  @service ttAlert;

  @controller('candidates')
  candidatesController;

  queryParams = [
    {
      query: 'q',
      sortDirection: 'sort_direction',
      sortColumn: 'sort_column',
    },
  ];

  get company() {
    return this.current.company;
  }

  get user() {
    return this.current.user;
  }

  get visibleCandidateIds() {
    return this.candidates.map((candidate) => get(candidate, 'id'));
  }

  get candidateSegmentTitle() {
    return this.intl.t('candidates.segment.all_candidates');
  }

  get noCandidates() {
    return this.company.candidateCount === 0;
  }

  @tracked candidates = null;
  @tracked deselectedCandidateIds = null;
  @tracked _hasModifiedFilters = false;
  @tracked modalSegment = null;
  @tracked query = null;
  @tracked searchParams = {};
  @tracked _segment = null;
  @tracked availableColumnsAll;

  get availableColumns() {
    let _cols = this.availableColumnsAll ?? this.model?.availableColumns;

    if (get(this.flipper, 'disable_star_ratings')) {
      _cols = _cols.filter((col) => {
        return col.name !== 'rating' && col.name !== 'reviewed_by';
      });
    }

    return _cols;
  }

  get fixedColumns() {
    return this.availableColumns?.filter((column) => column.isDisabled);
  }

  get defaultColumns() {
    return this.availableColumns
      ?.filter((column) => column.isDefault)
      .filter(Boolean);
  }

  get segment() {
    return this._segment || this.model.segment;
  }

  set segment(val) {
    this._segment = val;
  }

  get segmentId() {
    return get(this, 'segment.id');
  }

  get segments() {
    return this.candidatesController.segments;
  }

  @tracked customizeTableDropdownInserted = false;

  loadAllColumns = restartableTask(async () => {
    if (!this.availableColumnsAll) {
      await waitForProperty(this, 'customizeTableDropdownInserted');
      this.availableColumnsAll = await loadAvailableColumns(this, 'all');
      this.model.availableColumns = [];
    }
  });

  @tracked showBulk = false;

  get selectedColumns() {
    const selectedColumnsByUser = get(
      this.user,
      'selectedCandidateDisplayColumns'
    );

    return selectedColumnsByUser?.length
      ? selectedColumnsByUser
          .map((columnRecord) => {
            return this.availableColumns?.find((col) =>
              col.isMatchingColumn(columnRecord)
            );
          })
          .filter(Boolean)
      : this.defaultColumns;
  }

  @tracked selectAll = false;
  @tracked selectedCandidateIds = null;
  @tracked sortColumn = 'created_at';
  @tracked sortDirection = 'desc';
  @tracked totalExtra = 0;
  get total() {
    return this.searchMetaTotal + this.totalExtra;
  }

  get searchMetaTotal() {
    return this.candidates?.meta?.total || 0;
  }

  get hasNoAccessToQueriedDepartment() {
    return !this.hasAccessToQueriedDepartment;
  }

  @tracked listDisplayStates = null;
  @tracked expandedDepartments = [];
  @tracked collapsedDepartments = [];
  @tracked additionalCandidateIds = null;
  @tracked availableFilters = null;
  get hasNotPreloaded() {
    return isEmpty(this.availableFilters);
  }

  get hasPreloaded() {
    return !this.hasNotPreloaded;
  }

  get teams() {
    return get(this, 'user.allTeams');
  }

  get isLoading() {
    return (
      this.fetchCandidates.isRunning || this.fetchCandidates.performCount === 0
    );
  }

  constructor() {
    super(...arguments);
    this.listDisplayStates = this.getInitialDisplayStates();
  }

  @computedLocalStorage(null, 'Segment._sidebarToShow', 'segments')
  _sidebarToShow;

  get sidebarToShow() {
    const sidebar = this._sidebarToShow;
    if (sidebar === 'filters') {
      return 'none';
    }

    return sidebar;
  }

  set sidebarToShow(val) {
    this.previousSidebarToShow = this._sidebarToShow;
    this._sidebarToShow = val;
  }

  @tracked previousSidebarToShow = null;

  get savedSegments() {
    return this.segments.filter((segment) => !segment.isNew);
  }

  get isSaved() {
    let searchParams = { root: this.searchParams.root };
    const segment = get(this, 'segment');

    if (isPresent(segment)) {
      let segmentFilters = get(segment, 'filters') || {};

      return (
        JSON.stringify(deepmerge(DEFAULT_SEARCH_PARAMS, searchParams)) ===
        JSON.stringify(deepmerge(DEFAULT_SEARCH_PARAMS, segmentFilters))
      );
    }

    return searchParams?.root?.length === 0;
  }

  get departmentChunkCount() {
    return this.getDisplayListChunkCount('departments');
  }

  @action
  getDisplayListChunkCount(list) {
    const count = get(this, `${list}.length`);
    return count > DEFAULT_LIST_DISPLAY_COUNT + 1
      ? DEFAULT_LIST_DISPLAY_COUNT
      : DEFAULT_LIST_DISPLAY_COUNT + 1;
  }

  get mySegments() {
    return this.savedSegments.filter(
      (segment) => segment.companyWide === false
    );
  }

  get companySegments() {
    return this.savedSegments.filter((segment) => segment.companyWide === true);
  }

  get segmentGroups() {
    let segmentGroups = [
      {
        key: 'mySegments',
        name: this.intl.t('candidates.segment.my_segments'),
        segments: this.mySegments,
        chunkCount: this.getDisplayListChunkCount('mySegments'),
      },
    ];
    if (!get(this, 'user.externalRecruiter')) {
      segmentGroups.unshift({
        key: 'companySegments',
        name: this.intl.t('candidates.segment.company_segments'),
        segments: this.companySegments,
        chunkCount: this.getDisplayListChunkCount('companySegments'),
      });
    }

    return segmentGroups;
  }

  get departments() {
    if (get(this, 'user.id') === undefined) return [];

    return get(this, 'user.adminOrRecruitmentAdmin')
      ? get(this, 'company.sortedDepartments').toArray()
      : get(this, 'user.sortedAvailableDepartments').toArray();
  }

  get department() {
    const department_id =
      this.searchParams.department_id ||
      (this.searchParams.root?.firstObject?.department_id?.any?.length === 1 &&
        this.searchParams.root?.firstObject?.department_id?.any?.firstObject);

    if (department_id) {
      return get(this, 'departments').find(
        (department) => department.id === department_id
      );
    } else if (
      this.searchParams.root?.firstObject?.department_id?.any?.length === 1
    ) {
      return get(this, 'departments').find(
        (department) => department.id === NO_DEPARTMENT_ID
      );
    }

    return undefined;
  }

  get showAllDepartments() {
    return this.shouldDisplayAll('departments');
  }

  get team() {
    const { team_id } = this.searchParams;
    if (team_id) {
      return this.teams.find((team) => team.id === team_id);
    }

    return undefined;
  }

  @action
  shouldDisplayAll(list) {
    const displayState = get(this, `listDisplayStates.${list}`);

    if (get(displayState, 'forceClose') || false) {
      return false;
    }

    if (get(displayState, 'showAll') || false) {
      return true;
    }

    const modelName = list === 'departments' ? 'department' : 'segment';
    return (
      get(this, list).indexOf(get(this, modelName)) >=
      DEFAULT_LIST_DISPLAY_COUNT
    );
  }

  get role() {
    const roleId =
      this.searchParams.role_id ||
      this.searchParams.root?.firstObject?.role_id?.any?.firstObject;

    if (roleId) {
      return get(this, 'company.roles').findBy('id', roleId);
    }

    return undefined;
  }

  get heading() {
    return (
      get(this, 'segment.name') ||
      get(this, 'role.name') ||
      get(this, 'department.name') ||
      get(this, 'team.name') ||
      get(this, 'candidateSegmentTitle')
    );
  }

  get selectedCount() {
    if (this.selectAll) {
      return this.total - this.visibleDeselectedCandidateIds.length;
    } else {
      return this.selectedCandidateIds?.length;
    }
  }

  get visibleDeselectedCandidateIds() {
    const visibleIds = this.visibleCandidateIds;
    const deselectedIds = this.deselectedCandidateIds;
    return visibleIds.filter((x) => deselectedIds.includes(x));
  }

  shouldUseCandidatesCache() {
    return (
      this.candidates !== null &&
      this.query === this.candidateFilterParams.lastQuery &&
      this.segmentId === this.candidateFilterParams.segmentId &&
      this.sortColumn === this.candidateFilterParams.sortColumn &&
      this.sortDirection === this.candidateFilterParams.sortDirection
    );
  }

  checkForNewCandidate = enqueueTask(async (id) => {
    const candidates = await this.store.query(
      'candidate',
      Object.assign({ selected_candidate_ids: [id] }, this.searchParams)
    );

    if (candidates.length > 0) {
      this.infinity.unshiftObjects(get(this, 'candidates'), candidates);
      set(
        this.infinity.infinityModels.lastObject,
        'hasAdditionalCandidates',
        true
      );
      const additionalCandidateIds = this.additionalCandidateIds?.length
        ? [...this.additionalCandidateIds, id.toString()]
        : [id.toString()];
      set(this, 'additionalCandidateIds', additionalCandidateIds);

      this.totalExtra = this.totalExtra + 1;
      this.refreshSegmentCounts();
      this.refreshDepartmentCounts();
    }
  });

  fetchCandidates = restartableTask(async () => {
    this.compareAndUpdateQueryParam.perform();

    if (this.shouldUseCandidatesCache()) {
      return;
    }

    if (
      this.searchParams.department_id &&
      this.searchParams.department_id !== NO_DEPARTMENT_ID
    ) {
      this.store.findRecord('department', this.searchParams.department_id, {
        backgroundReload: false,
      });
    }

    const params = Object.assign(
      {
        sort_column: this.sortColumn,
        sort_direction: this.sortDirection,
        per_page: 15,
      },
      this.searchParams
    );

    if (this.hasNoAccessToQueriedDepartment) {
      Object.assign(params, {
        store: {
          emptyResponse() {
            return resolve(
              ArrayProxy.create({ content: [], meta: { total: 0 } })
            );
          },
        },

        storeFindMethod: 'emptyResponse',
      });
    }

    this.additionalCandidateIds = [];

    this.candidates = await this.infinity
      .model('candidate', params, SegmentsInfinityModel)
      .catch(() => {
        this.flashMessages.error('Something went wrong');
      });
  });

  @action
  resetSearchParams(params = {}) {
    setProperties(this, {
      searchParams: deepmerge(DEFAULT_SEARCH_PARAMS, params),
      segment: null,
    });
  }

  compareAndUpdateQueryParam = restartableTask(
    async (wait = false, searchQuery = null) => {
      if (wait) {
        await timeout(700);
      }

      if (typeof searchQuery === 'string') {
        if (isUnfinishedAdvancedSearch(searchQuery)) {
          return;
        }

        this.searchParams.query = searchQuery;
      }

      await waitForProperty(this, 'hasPreloaded');
      this.compareAndUpdateNewQuery();
    }
  );

  getInitialDisplayStates() {
    const storedStates =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    return deepmerge(DEFAULT_DISPLAY_STATES, storedStates);
  }

  get hasAccessToQueriedDepartment() {
    const departmentId = get(this, 'searchParams.department_id');
    if (this.department?.isLoaded || isEmpty(departmentId)) {
      return true;
    }

    return departmentId === this.department?.id;
  }

  @action
  handleDepartmentRoleNavigate(params) {
    const searchParamsJSON = JSON.stringify(this.searchParams);
    const compareJSON = JSON.stringify(
      deepmerge(DEFAULT_SEARCH_PARAMS, params)
    );

    if (searchParamsJSON === compareJSON) {
      return;
    }

    this.resetSearchParams(params);
    this.appRefresher.transitionOrNavigateTo('candidates.segment', 'all', {
      queryParams: {
        q: jsonToBase64(this.searchParams),
      },
    });
  }

  get canSaveCurrentSegment() {
    return this.canSaveSegment(this.segment);
  }

  @action
  refreshSegmentCounts() {
    get(this, 'segments').forEach((segment) => {
      next(() => {
        segment.fetchCount();
      });
    });
  }

  @action
  refreshDepartmentCounts() {
    get(this, 'departments').forEach((department) => {
      department.candidateCounter.reload();
    });
  }

  @action
  handleTeamClick(team) {
    this.handleDepartmentRoleNavigate({
      team_id: team ? team.id : null,
    });
  }

  @action
  handleDepartmentClick(department) {
    const params = {
      root: [
        {
          department_id: {
            any: [department?.id === NO_DEPARTMENT_ID ? '' : department?.id],
          },
        },
      ],
    };

    this.handleDepartmentRoleNavigate(params);
  }

  @action
  handleRoleClick(role) {
    const params = {
      root: [{ role_id: { any: [role?.id] } }],
    };

    this.handleDepartmentRoleNavigate(params);
  }

  @action
  handleSegmentClick(segment) {
    const sameSegment = segment.id === this.segment?.id;
    if (sameSegment && !get(this, 'hasModifiedFilters')) {
      return;
    }

    this.appRefresher.transitionOrNavigateTo(
      'candidates.segment',
      this.company.uuid,
      segment.id,
      {
        queryParams: { q: null },
      }
    );
  }

  @action
  handleClickOnAllCandidates() {
    this.resetSearchParams();
    this.appRefresher.transitionOrNavigateTo('candidates.segment', 'all', {
      queryParams: { q: null },
    });
  }

  @action
  newSegment(name) {
    const segment = this.store.createRecord('segment', {
      filters: this.searchParams,
    });
    const changeset = new Changeset(
      segment,
      lookupValidator(SegmentValidations),
      SegmentValidations
    );

    if (typeof name === 'string') {
      set(changeset, 'name', name);
    }

    set(changeset, 'emoji', getRandomEmoji());
    set(this, 'modalSegment', changeset);
  }

  @action
  editSegment(segment) {
    segment.filters = this.searchParams;

    const changeset = new Changeset(
      segment,
      lookupValidator(SegmentValidations),
      SegmentValidations
    );
    set(this, 'modalSegment', changeset);
  }

  @action
  async saveModalSegment() {
    const segment = await get(this, 'modalSegment').save();
    later(() => {
      set(this, 'modalSegment', null);
      this.router.transitionTo('candidates.segment', segment.id, {
        queryParams: { q: null },
      });
    }, 400);
  }

  @action
  async saveSegment() {
    const { segment } = this;
    set(this, 'segment.filters', this.searchParams);
    await segment.save();
    later(() => {
      this.compareAndUpdateQueryParam.perform();
    }, 400);
  }

  @action
  destroyModalSegment() {
    this.ttAlert.confirm(
      this.intl.t('candidates.segment.delete_segment_confirm'),
      async () => {
        await get(this, 'modalSegment.data').destroyRecord();
        this.resetSearchParams();
        set(this, 'modalSegment', null);
        this.router.transitionTo('candidates.segment', 'all', {
          queryParams: { q: null },
        });
      }
    );
  }

  @action
  handleEditModalClose() {
    if (get(this, 'modalSegment.isNew')) {
      get(this, 'modalSegment.data').destroyRecord();
    }

    set(this, 'modalSegment', null);
  }

  @action
  handleToggleSelectAll() {
    setProperties(this, {
      selectAll: !this.selectAll,
      deselectedCandidateIds: [],
      selectedCandidateIds: [],
    });
  }

  @action
  bulkCallbackAction() {
    this.fetchCandidates.perform();
    this.refreshDepartmentCounts();
    this.refreshSegmentCounts();
  }

  @action
  handleBulkDelete(candidateIds) {
    if (candidateIds && candidateIds.length > 0) {
      const candidatesList = get(this, 'candidates');
      candidateIds.forEach((candidateId) => {
        const deletedCandidate = candidatesList.find(
          (candidate) => candidate.id === candidateId
        );
        candidatesList.removeObject(deletedCandidate);
      });
    } else {
      this.candidates = [];
    }
  }

  @action
  clearSelection() {
    setProperties(this, {
      selectAll: false,
      deselectedCandidateIds: [],
      selectedCandidateIds: [],
    });
  }

  @action
  toggleListDisplay(list) {
    const displayState = get(this, `listDisplayStates.${list}`);
    const isOpen = this.shouldDisplayAll(list);
    const storedStates =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};

    set(displayState, 'showAll', !isOpen);
    set(displayState, 'forceClose', isOpen);
    storedStates[list] = { showAll: !isOpen };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedStates));
  }

  @tracked candidatesTableEl = null;

  @action
  showAll(list) {
    return this.shouldDisplayAll(list);
  }

  @action
  canSaveSegment(segment) {
    const user = get(this, 'user');
    return get(user, 'admin') || !get(segment, 'companyWide');
  }

  @action
  handleDepartmentToggle({ id }, isOpen) {
    const expandedDepartments = get(this, 'expandedDepartments');
    const collapsedDepartments = get(this, 'collapsedDepartments');

    if (isOpen) {
      if (collapsedDepartments.indexOf(id) === -1) {
        collapsedDepartments.pushObject(id);
      }

      expandedDepartments.removeObject(id);
    } else {
      if (expandedDepartments.indexOf(id) === -1) {
        expandedDepartments.pushObject(id);
      }

      collapsedDepartments.removeObject(id);
    }
  }

  @tracked headingBarIsStuck = false;

  @action
  async handleWindowScroll() {
    const currScrollTop = document.documentElement.scrollTop;
    const isStuck = !(currScrollTop < get(this, 'headingBarIsStuckScrollTop'));
    get(this, 'headingBarEl')?.classList.toggle('border-neutral', isStuck);
    set(this, 'headingBarIsStuck', isStuck);
  }

  headingBarIsStuckScrollTop = this.headingBarHeight;

  @tracked candidateFiltersEl = null;
  @tracked filtersRect = null;
  @tracked headingBarHeight = 107;

  @action
  handleHeadingBarInnerRectChange(rect) {
    this.headingBarHeight = rect.height + 5; // until this is fixed https://github.com/PrecisionNutrition/ember-resize-kitchen-sink/issues/20 we have to specify padding + border width as a constant
  }

  @action
  setup() {
    get(this, 'company.sortedDepartments');

    window.addEventListener('scroll', this.handleWindowScroll);

    setProperties(this, {
      deselectedCandidateIds: [],
      selectedCandidateIds: [],
    });
  }

  get sortedColumns() {
    return [
      ...(this.selectedColumns.length > 0
        ? this.selectedColumns
        : this.defaultColumns),
    ].sort((a, b) => {
      return (
        this.availableColumns.findIndex((c) => c.name === a.name) -
        this.availableColumns.indexOf(b)
      );
    });
  }

  @action
  handleDestroy() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  @action
  filterQueryHasChanged(newQuery) {
    return (
      JSON.stringify(this.searchParams.root) !== JSON.stringify(newQuery.root)
    );
  }

  @action
  compareAndUpdateNewQuery() {
    setProperties(this.candidateFilterParams, {
      lastQuery: this.query,
      segmentId: this.segmentId,
      sortDirection: this.sortDirection,
      sortColumn: this.sortColumn,
    });

    if (this.isSaved && !isPresent(this.searchParams.query)) {
      if (this.query !== null && isBlank(this.searchParams.team_id)) {
        this.query = null;
      }
    } else {
      const params = deepmerge(DEFAULT_SEARCH_PARAMS, {
        root: this.searchParams.root,
      });
      if (isPresent(this.searchParams.query)) {
        params.query = this.searchParams.query;
      }

      const base64Params = jsonToBase64(params);
      if (this.query !== base64Params) {
        this.query = base64Params;
      }
    }
  }

  @action
  onUpdateQuery(query) {
    if (query && this.filterQueryHasChanged(query)) {
      set(this, 'searchParams.root', query.root);
      this.compareAndUpdateQueryParam.perform();
    }
  }

  @action
  clickAddCandidatesButton() {
    document.getElementById('add-candidates-button').click();
  }

  @action
  toggleBulk() {
    this.showBulk = !this.showBulk;
    if (!this.showBulk) {
      this.clearSelection();
    }
  }
}
