/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';
import { LOADING_COLUMNS } from 'teamtailor/constants/requisitions';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import RequisitionModel from 'teamtailor/models/requisition';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';

type RequisitionTableArgs = {
  isLoading?: boolean;
  requisitions?: RequisitionModel[];
};

export default class RequisitionTableComponent extends Component<RequisitionTableArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;

  loadingColumns = LOADING_COLUMNS;

  get columns() {
    return [
      {
        label: 'common.title',
        width: 300,
        selectable: true,
      },

      {
        label: 'requisitions.list.headers.next_approver',
        width: 175,
      },
      {
        label: 'common.location',
        width: 125,
      },
      {
        label: 'common.department',
        width: 125,
      },
      {
        label: 'requisitions.list.headers.recruiter',
        width: 175,
      },
      {
        label: 'requisitions.list.headers.creator',
        width: 175,
      },
      {
        label: 'common.created',
        width: 125,
      },
    ];
  }

  @action
  async handleCopyRequisition(requisitionId: string) {
    const requisition = await this.store.findRecord(
      'requisition',
      requisitionId
    );

    await requisition.copy();

    this.router.transitionTo('requisitions.new');
  }
}
