/* import __COLOCATED_TEMPLATE__ from './pipeline-overview.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import colors from 'teamtailor/components/insights/charts/colors';
import fontOptions from 'teamtailor/components/insights/charts/font-options';
import moment from 'moment-timezone';

export default class InsightsMoleculesPipelineOverview extends Component {
  @tracked hiddenStages = [];

  get visibleStages() {
    return this.args.stages.filter(
      (stage) => !this.hiddenStages.includes(stage.name)
    );
  }

  get labelData() {
    return this.args.stages.map((stage) =>
      this.visibleStages.includes(stage) ? stage.name : null
    );
  }

  get showYear() {
    const startYear = this.args.dates.firstObject.year();
    const endYear = this.args.dates.lastObject.year();
    const thisYear = moment().year();

    return startYear !== endYear || startYear !== thisYear;
  }

  @action
  handleCheck(stageName) {
    if (this.hiddenStages.includes(stageName)) {
      this.hiddenStages.removeObject(stageName);
    } else {
      this.hiddenStages.addObject(stageName);
    }
  }

  @action
  resetHiddenStages() {
    set(this, 'hiddenStages', []);
  }

  get formattedDates() {
    if (this.showYear) {
      return this.args.dates.map((date) => date.format('MMM D, YYYY'));
    }

    return this.args.dates.map((date) => date.format('MMM D'));
  }

  get chartData() {
    const stages = this.args.stages || [];
    const data = stages.map((stage) =>
      this.visibleStages.includes(stage) ? stage.data : []
    );

    return stages.mapBy('name').map((series, index) => {
      return {
        name: series,
        data: data[index],
        color: colors[index % colors.length],
      };
    });
  }

  get chartOptions() {
    return {
      chart: {
        type: 'column',
        height: '280px',
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 15,
      },

      title: {
        text: undefined,
      },

      xAxis: {
        labels: { enabled: false },
        categories: this.formattedDates,
      },

      yAxis: {
        min: 0,
        allowDecimals: false,
        reversedStacks: false,
        offset: 30,
        tickLength: 30,

        title: {
          text: undefined,
        },

        labels: {
          enabled: true,
          padding: 0,
          x: 15,
          y: -5,
          style: fontOptions,
        },

        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color: '#57595f',
          },
        },
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        headerFormat: '<span>{point.x}</span><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },

      plotOptions: {
        column: {
          animation: {
            duration: 200,
          },

          stacking: 'normal',
          borderWidth: 0,
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
  }
}
