/* import __COLOCATED_TEMPLATE__ from './download-link.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DownloadLink extends Component {
  @service server;
  @service ttAlert;
  @service intl;

  get href() {
    return this.args.href || '#';
  }

  get target() {
    return this.args.target || '_blank';
  }

  get icon() {
    if (this.args.isFileInfected !== undefined) {
      return this.args.isFileInfected
        ? 'triangle-exclamation'
        : this.args.icon || 'paperclip';
    } else {
      return this.args.icon === false ? false : this.args.icon || 'paperclip';
    }
  }

  get title() {
    return this.args.title || 'Download';
  }

  get url() {
    return this.args.url || null;
  }

  get isFileInfected() {
    return this.args.isFileInfected || null;
  }

  download() {
    let newWindow = window.open();
    this.server.request(this.url).then(({ url }) => {
      newWindow.location.href = url;
    });
  }

  @action
  click(event) {
    event.preventDefault();

    if (this.isFileInfected) {
      this.ttAlert.confirm(
        this.intl.t('components.download_link.virus_detected_description'),
        () => {
          this.download();
        },
        () => {},
        {
          title: this.intl.t('components.download_link.virus_detected_title'),
          cancelButtonText: this.intl.t(
            'components.download_link.virus_cancel_download'
          ),

          confirmButtonText: this.intl.t(
            'components.download_link.virus_confirm_download'
          ),
        }
      );
    } else {
      this.download();
    }
  }
}
