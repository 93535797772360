/* import __COLOCATED_TEMPLATE__ from './startupjoblist.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import {
  ALLOWED_CURRENCIES,
  DEFAULT_CURRENCY,
} from 'teamtailor/utils/currency-for-country';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @service intl;
  @service router;
  @service flashMessages;

  @tracked checkoutData;

  constructor() {
    super(...arguments);
    this.args.promotion.currency = this.args.currency;
    this.args.promotion.price = this.seletedBudget.amount;
  }

  get currencyWithFallback() {
    if (ALLOWED_CURRENCIES.includes(this.args.currency)) {
      return this.args.currency;
    }

    return DEFAULT_CURRENCY;
  }

  get seletedBudget() {
    const plansWithFallback = get(this, 'args.promotion.channel.plans')[
      this.currencyWithFallback
    ];
    if (this.args.promotion.price) {
      return plansWithFallback.find(
        (plan) => plan.amount === this.args.promotion.price
      );
    }

    return plansWithFallback[0];
  }

  get packageName() {
    return this.seletedBudget?.name;
  }

  get price() {
    return this.seletedBudget?.amount;
  }

  @action
  setBudget(budget) {
    this.args.promotion.plan = budget.name;
    this.args.promotion.price = budget.amount;
  }

  @action
  pick() {
    return this.args.promotion.save().then(() => {
      get(this, 'flashMessages').success(
        this.intl.t('components.promotion.created_message')
      );
      this.router.transitionTo('jobs.job.promote');
    });
  }
}
