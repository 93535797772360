/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import SectionItemComponent from 'teamtailor/components/content-block/section-item-component';
import {
  OnSelectArg,
  SelectedColor,
} from 'teamtailor/components/figure-picker';
import { inject as controller } from '@ember/controller';
import ContentEditorController from 'teamtailor/controllers/content/editor';
import { restartableTask, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ListItemBaseComponent extends SectionItemComponent {
  @action
  handleUploadButtonClick() {
    this.showImageLibrary = true;
  }

  @controller('content.editor')
  declare editorController: ContentEditorController;

  @action onFigurePickerOpen() {
    this.editorController.aFigurePickerisOpen = true;
  }

  @action onFigurePickerClose() {
    this.editorController.aFigurePickerisOpen = false;
  }

  @action async handleFigureSelectColor(color: SelectedColor) {
    this.sectionItem!.color = color.cssColor;
    await this.sectionItem?.save();
    this.reloadSection();
  }

  @action async handleFigureSelect(arg: OnSelectArg) {
    this.sectionItem!.icon = arg.iconName;
    this.sectionItem!.iconPrefix = arg.prefix;
    await this.sectionItem?.save();
    this.reloadSection();
  }

  onTitleInput = restartableTask(async (event: KeyboardEvent) => {
    this.sectionItem!.title = (event.target as HTMLInputElement).value;

    await timeout(50);
    await (await this.sectionItem)?.save();
    this.reloadSection();
  });

  @tracked _localBody = '';

  // local body copy to avoid value changing to older value when a request other than last one finishes before last one
  get localBody() {
    return this._localBody || this.sectionItem?.body || '';
  }

  onBodyInput = restartableTask(
    async (value: string, { isFirstChange }: { isFirstChange: boolean }) => {
      if (isFirstChange) return;

      this.sectionItem!.body = value;
      this._localBody = value;
      await timeout(50);
      await this.sectionItem!.save();
      this.reloadSection();
    }
  );
}
