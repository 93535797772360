/* import __COLOCATED_TEMPLATE__ from './splash.hbs'; */
import Component from '@glimmer/component';
import { get, set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { animate, afterRender } from 'teamtailor/ember-smooth';

const sizes = [
  { value: 'xs', label: 'components.tshirt_splash.xsmall' },
  { value: 's', label: 'components.tshirt_splash.small' },
  { value: 'm', label: 'components.tshirt_splash.medium' },
  { value: 'l', label: 'components.tshirt_splash.large' },
  { value: 'xl', label: 'components.tshirt_splash.xlarge' },
];

export default class extends Component {
  @service confetti;
  @service intl;

  @tracked tshirt = null;
  @tracked sizes = null;
  @tracked isSaving = false;
  @tracked currentStep = 'surprise';
  @tracked showSurprise = true;
  @tracked showHooray = false;

  get user() {
    return this.args.user;
  }

  get product() {
    return this.args.product;
  }

  get isStepSurprise() {
    return this.currentStep === 'surprise';
  }

  get isStepHooray() {
    return this.currentStep === 'hooray';
  }

  get isStepForm() {
    return this.currentStep === 'form';
  }

  get isStepThanks() {
    return this.currentStep === 'thanks';
  }

  get notValid() {
    return get(this, 'tshirt.isNotValid');
  }

  get submitDisabled() {
    return this.isSaving || this.notValid;
  }

  constructor() {
    super(...arguments);
    const tshirt = get(this, `user.${this.product}`);
    this.tshirt = tshirt;
    this.sizes = sizes;
    this.i18nSizeLabels();
    this.preloadImage();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    const confetti = get(this, 'confetti');
    confetti.hide();
  }

  storeLocalDecision() {
    const key = `${this.product}State-u-${get(this, 'user.id')}`;
    localStorage.setItem(get(this, key), 'completed');
  }

  i18nSizeLabels() {
    this.sizes.forEach((size) => {
      size.label = this.intl.t(size.label);
    });
  }

  preloadImage() {
    let image = new Image();
    image.src = this.productImageUrl;
  }

  close() {
    if (this.isDestroyed) {
      return;
    }

    const tshirt = get(this, 'tshirt');
    set(tshirt, 'isVisible', false);
  }

  get i18nGift() {
    return this.intl.t(`gift.${this.product}`);
  }

  @action
  onModalClose() {
    const status = get(this.tshirt, 'status');
    if (status === 'pending') {
      set(this.tshirt, 'status', 'snoozed');
      this.tshirt.save();
    }

    this.close();
  }

  @action
  handleSizeChange(size) {
    set(this.tshirt, 'size', size.value);
  }

  @action
  handleSubmit() {
    set(this.tshirt, 'status', 'approved');
    this.isSaving = true;
    return this.tshirt.save().then(() => {
      set(this, 'currentStep', 'thanks');
      this.storeLocalDecision();
    });
  }

  @action
  saveStatus(status) {
    set(this.tshirt, 'status', status);
    return this.tshirt.save().then(() => {
      if (status === 'rejected') {
        this.storeLocalDecision();
      }

      this.close();
    });
  }

  @tracked modalBoxEl = null;

  @action onInsertModalBox(el) {
    this.modalBoxEl = el;
  }

  @action
  async animationComplete() {
    this.showSurprise = false;

    const rectBefore = this.modalBoxEl.getBoundingClientRect();
    this.currentStep = 'hooray';

    await afterRender();

    await animate.from(this.modalBoxEl, {
      height: rectBefore.height,
    });
    this.showHooray = true;
  }

  @action
  loopComplete() {
    setTimeout(() => {
      get(this, 'confetti').show({ duration: Infinity });
    }, 1000);
  }
}
