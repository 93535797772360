import ApplicationAdapter from 'teamtailor/adapters/application';

export default class HotelCareerEmployments extends ApplicationAdapter {
  pathForType() {
    return 'promotion_categories/hotel_career_employments';
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'promotion-categories/hotel-career-employment': HotelCareerEmployments;
  }
}
