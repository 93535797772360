/* import __COLOCATED_TEMPLATE__ from './row-actions.hbs'; */
import Component from '@glimmer/component';
import JobModel from 'teamtailor/models/job';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';

type JobsRowActionsArgs = {
  job: JobModel;
  onActionLoading: (isLoading: boolean) => void;
};

export default class JobsRowActions extends Component<JobsRowActionsArgs> {
  @service declare router: RouterService;
  @service declare store: Store;

  @tracked showInternalTitleModal = false;

  @action
  handleCopyJob(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.router.transitionTo('jobs.index.jobs-list.copy', this.args.job.id);
  }

  @action
  async openInternalTitleModal() {
    await this.args.job.jobDetail;
    this.showInternalTitleModal = true;
  }
}
