/* import __COLOCATED_TEMPLATE__ from './show-content.hbs'; */
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MeetingEventModel from 'teamtailor/models/meeting-event';

type ShowContext = {
  meetingEvent: MeetingEventModel;
};

interface ShowContentArgs {
  showContext: ShowContext;
}

export default class ShowContent extends Component<ShowContentArgs> {
  @tracked editingCopilotSummary = false;
}
