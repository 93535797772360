/* import __COLOCATED_TEMPLATE__ from './scorecard-results.hbs'; */
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';
import Component from '@glimmer/component';
import { InterviewModel, ScorecardScoreModel } from 'teamtailor/models';
import { TOPIC } from 'teamtailor/models/scorecard-criterium';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SCORE_VALUE_ADJUSTMENT } from 'teamtailor/constants/scorecard-scores';

interface Args {
  picks: ScorecardPickModel[];
  scores: ScorecardScoreModel[];
  showInterviewKitName: boolean;
  interviews: InterviewModel[];
}

export default class extends Component<Args> {
  @tracked showAll = false;

  get picks() {
    return this.args.picks.sortBy('position');
  }

  get criteriumWithScores() {
    return [
      ...new Set(
        this.args.scores
          .filter((score) => get(score, 'humanScore'))
          .map((score) =>
            get(get(score, 'scorecardPick'), 'scorecardCriteriumId')
          )
      ),
    ];
  }

  get picksWithScores() {
    return this.showAll
      ? this.picks.sort((a, b) => this.sortByCriterium(a, b))
      : this.picks.filter((pick) =>
          this.criteriumWithScores.includes(get(pick.scorecardCriterium, 'id'))
        );
  }

  get picksWithoutScores() {
    return this.picks.filter((pick) => {
      const scorecardCriteriumId = get(pick.scorecardCriterium, 'id');
      return !this.criteriumWithScores.includes(scorecardCriteriumId);
    });
  }

  get skillsPicks() {
    return this.picksWithScores.filter((pick) => pick.topic === TOPIC.SKILL);
  }

  get personalityPicks() {
    return this.picksWithScores.filter((pick) => pick.topic === TOPIC.TRAIT);
  }

  get showInterviewKitName() {
    return this.args.showInterviewKitName;
  }

  get interviews() {
    return this.args.interviews;
  }

  get totalWeight() {
    return this.picks
      .map((pick) => get(pick, 'weight'))
      .reduce((a, b) => a + b, 0);
  }

  @action
  weightForPick(pick: ScorecardPickModel) {
    return Math.floor((get(pick, 'weight') / this.totalWeight) * 100);
  }

  @action
  calculateScoreForPick(pick: ScorecardPickModel) {
    const scores = this.args.scores.filter(
      (score) =>
        get(get(score, 'scorecardPick'), 'scorecardCriteriumId') ===
          get(pick.scorecardCriterium, 'id') && get(score, 'humanScore')
    );
    const total = scores.reduce(
      (acc, score) => acc + (get(score, 'humanScore') || 0),
      0
    );
    return scores.length === 0
      ? false
      : {
          totalAverage: (total / scores.length).toFixed(1),
          totalRounded:
            Math.floor(total / scores.length) - SCORE_VALUE_ADJUSTMENT,
        };
  }

  get scoresByInterviews() {
    return this.interviews.map((interview) => {
      const scorecardScores = this.picksWithScores.map((pick) => {
        const scorecardCriteriumId = get(pick.scorecardCriterium, 'id');

        const score = interview.scores.find((score) => {
          return get(score, 'criteriumId') === scorecardCriteriumId;
        });

        return {
          topic: pick.topic,
          score,
        };
      });

      return {
        user: get(interview.userOrAuthorName, 'user'),
        author: interview.userOrAuthorName,
        partner: interview.partner,
        scorecardScores,
        ...(this.showInterviewKitName
          ? { interviewKitName: get(interview.interviewKit, 'name') }
          : {}),
      };
    });
  }

  sortByCriterium(a: ScorecardPickModel, b: ScorecardPickModel) {
    const criteriumIdA = get(a.scorecardCriterium, 'id');
    const criteriumIdB = get(b.scorecardCriterium, 'id');
    return this.criteriumWithScores.includes(criteriumIdA) &&
      this.criteriumWithScores.includes(criteriumIdB)
      ? a.position - b.position
      : this.criteriumWithScores.indexOf(criteriumIdB) -
          this.criteriumWithScores.indexOf(criteriumIdA);
  }
}
