/* import __COLOCATED_TEMPLATE__ from './trigger-note-form.hbs'; */
import { action } from '@ember/object';
import TriggerFormBaseComponent from 'teamtailor/components/trigger-form-base';

export default class TriggerNoteFormComponent extends TriggerFormBaseComponent {
  get upload() {
    return this.uploads.firstObject;
  }

  @action
  handleBodyChange(event) {
    this.args.model.body = event.target.value;
  }
}
