import Service, { inject as service } from '@ember/service';
import Current from './current';
import PusherService, { PusherChannel } from './pusher';
import { ActivityModel, JobModel } from 'teamtailor/models';
import Store from '@ember-data/store';
import ModelRegistry from 'ember-data/types/registries/model';

type NoteCallback = (record: ActivityModel) => void;

export default class PusherForJobs extends Service {
  @service declare pusher: PusherService;
  @service declare current: Current;
  @service declare store: Store;

  channels: Record<string, PusherChannel> = {};
  currentChannel: PusherChannel | null = null;
  newJobNoteCallbacks: NoteCallback[] = [];

  async setup(job: JobModel) {
    const cachedChannel = this.channels[job.id];

    if (cachedChannel?.subscribed) {
      this.currentChannel = cachedChannel;
    } else {
      const channel = await this.pusher.subscribe(job.pusherChannel);
      this.currentChannel = channel;
      this.channels[job.id] = channel;
    }

    return this;
  }

  bindNewJobNoteCallback(callback: NoteCallback) {
    this.newJobNoteCallbacks.push(callback);
    return this;
  }

  unbindNewJobNoteCallback(callback: NoteCallback) {
    this.newJobNoteCallbacks = this.newJobNoteCallbacks.filter(
      (c) => c !== callback
    );
    return this;
  }

  bindJobEvents() {
    this._bindJobEvents();
    return this;
  }

  bindStageEvents() {
    this._bindStageEvents();
    return this;
  }

  unbindJobEvents() {
    this._unbindJobEvents();
    return this;
  }

  unbindStageEvents() {
    this._unbindStageEvents();
    return this;
  }

  private _bindStageEvents() {
    if (!this.currentChannel) {
      return;
    }
    this.currentChannel.bind('new-activity', this._handleNewActivity);
  }

  private _unbindStageEvents() {
    if (!this.currentChannel) {
      return;
    }
    this.currentChannel.unbind('new-activity', this._handleNewActivity);
  }

  private _bindJobEvents() {
    if (!this.currentChannel) {
      return;
    }
    this.currentChannel.bind('new-reaction', this._handleNewReaction);
    this.currentChannel.bind(
      'destroyed-reaction',
      this._handleDestroyedReaction
    );

    this.currentChannel.bind(
      'requisition_updated',
      this._handleRequisitionUpdated
    );

    this.currentChannel.bind('new-job-note', this._handleNewJobNote);
  }

  private _unbindJobEvents = () => {
    if (!this.currentChannel) {
      return;
    }
    this.currentChannel.unbind('new-reaction', this._handleNewReaction);
    this.currentChannel.unbind(
      'destroyed-reaction',
      this._handleDestroyedReaction
    );

    this.currentChannel.unbind(
      'requisition_updated',
      this._handleRequisitionUpdated
    );

    this.currentChannel.unbind('new-job-note', this._handleNewJobNote);
  };

  private _handleNewReaction = (data: unknown) => {
    this.store.pushPayload(data as unknown as never);
  };

  private _handleDestroyedReaction = (id: string) => {
    const reaction = this.store.peekRecord('reaction', id);
    if (reaction) {
      this.store.unloadRecord(reaction);
    }
  };

  private _handleRequisitionUpdated = ({
    requisition_id: requisitionId,
  }: {
    requisition_id: string;
  }) => {
    if (requisitionId) {
      const requisition = this.store.peekRecord('requisition', requisitionId);

      if (requisition) {
        requisition.reload();
      }
    }
  };

  private _handleNewJobNote = async ({
    activity_id: activityId,
  }: {
    activity_id: string;
  }) => {
    if (this.newJobNoteCallbacks.length) {
      const record = await this.store.findRecord('activity', activityId);
      this.newJobNoteCallbacks.forEach((callback) => callback(record));
    }
  };

  private _handleNewActivity = (data: {
    model_name?: keyof ModelRegistry;
    model_id?: string;
  }) => {
    if (data.model_name && data.model_id) {
      this.store.findRecord(data.model_name, data.model_id);
    }
  };
}

declare module '@ember/service' {
  interface Registry {
    'pusher-for-jobs': PusherForJobs;
  }
}
