/* import __COLOCATED_TEMPLATE__ from './line-chart.hbs'; */
import Component from '@glimmer/component';
import colors from 'teamtailor/components/insights/charts/colors';
import moment from 'moment-timezone';
import fontOptions from './font-options';

export default class InsightsChartsLineChart extends Component {
  get chartData() {
    return this.args.series.map((series, index) => {
      return {
        name: series,
        data: this.args.data[index],
        color: colors[index % colors.length],
      };
    });
  }

  get chartType() {
    return this.args.smooth ? 'spline' : 'line';
  }

  get chartOptions() {
    const { columns, height, skipColFormat, showLabels } = this.args;
    let i = 0;
    return {
      chart: {
        type: this.chartType,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingTop: 15,
        spacingRight: 0,
        height: height || '200px',
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false,
      },

      yAxis: {
        title: {
          text: '',
        },

        allowDecimals: false,
        gridLineColor: '#eeeff2',

        labels: {
          enabled: true,
          padding: 0,
          x: 15,
          y: -5,
          style: fontOptions,
        },
      },

      xAxis: showLabels
        ? {
            labels: {
              enabled: true,
              style: fontOptions,
              formatter(ctx) {
                i++;
                if (ctx.isFirst) {
                  i = 0;
                }

                return columns[i] || '';
              },
            },

            tickInterval: 1,
            lineColor: '#eeeff2',
            lineWidth: 1,
          }
        : {
            labels: {
              enabled: false,
            },

            tickLength: 1,
            lineColor: '#eeeff2',
            lineWidth: 1,
          },

      tooltip: {
        formatter() {
          let colFormat = (value) => moment(value).format('DD MMM');
          if (skipColFormat) {
            colFormat = (value) => value;
          }

          return this.points
            .sort((a, b) => b.y - a.y)
            .slice(0, 20)
            .reduce(
              (s, point) =>
                `${s}<br /><span style="display: inline-block; margin-right: 4px; color: ${point.color}">\u25CF</span> ${point.series.name}: ${point.y}`,
              `<span>${colFormat(columns[this.x])}</span>`
            );
        },

        crosshairs: {
          color: '#eeeff2',
          dashStyle: 'solid',
        },

        shared: true,
      },

      plotOptions: {
        line: {
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },

        series: {
          label: {
            connectorAllowed: false,
          },

          marker: {
            enabled: false,
            symbol: 'circle',
          },
        },
      },
    };
  }
}
