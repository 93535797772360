/* import __COLOCATED_TEMPLATE__ from './truncated-text.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

type Args = {
  data: {
    value: string;
  };
};

export default class DataTableCandidateMessageComponent extends Component<Args> {
  @tracked isExpanded = false;

  get isContentTruncated(): boolean {
    return this.textContent.length > 140;
  }

  get textValue(): string {
    if (this.isExpanded) {
      return this.textContent;
    }

    return this.isContentTruncated ? this.truncatedText : this.textContent;
  }

  get truncatedText(): string {
    return `${this.textContent.substring(0, 140)}...`;
  }

  get textContent() {
    return this.args.data.value || '';
  }

  @action
  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
