import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class VideoMeetingToken extends ApplicationAdapter {
  namespace = 'app/api';

  buildUpdateRecordingUrl(meetingId) {
    return `${this.namespace}/video_meeting_tokens/${meetingId}/update_recording`;
  }
}
