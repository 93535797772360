/* import __COLOCATED_TEMPLATE__ from './share-data-request-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import {
  CannedResponseModel,
  DataRequestModel,
  DataRequestSettingModel,
} from 'teamtailor/models';

interface ShareDataRequestFormArgs {
  model: DataRequestModel;
  dataRequestSetting: DataRequestSettingModel;
}

export default class ShareDataRequestForm extends Component<ShareDataRequestFormArgs> {
  @service declare current: Current;

  @tracked messageTemplateId: string = '';

  @action
  toggleTable(table: string) {
    toggleInList(this.args.model.tables, table);
  }

  @action
  selectedCannedResponse(response: CannedResponseModel) {
    this.args.model.messageSubject = response.subject;
    this.args.model.messageBody = response.body;
    this.messageTemplateId = response.id;
  }
}
