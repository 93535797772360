/* import __COLOCATED_TEMPLATE__ from './answer-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import HireQualityResponseModel from 'teamtailor/models/hire-quality-response';
import IntlService from 'ember-intl/services/intl';
import { isBlank, isPresent } from '@ember/utils';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

type Args = {
  hireQualityResponse: HireQualityResponseModel;
  onClose: () => void;
};

export default class HireQualitySurveyAnswerModal extends Component<Args> {
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get hireQualityResponse(): HireQualityResponseModel {
    return this.args.hireQualityResponse;
  }

  get candidateName() {
    return get(this.hireQualityResponse.candidate, 'nameOrEmail');
  }

  get saveDisabled(): boolean {
    const { recommended } = this.hireQualityResponse;
    return isBlank(recommended);
  }

  get surveyResponse(): boolean | null {
    const { response } = this.router.currentRoute.queryParams;

    if (response) {
      return response === 'yes' ? true : false;
    } else {
      return null;
    }
  }

  get showScorecards(): boolean {
    return (
      this.current.company.hasFeature('interview_kit') &&
      this.hireQualityResponse.isCompetenceBased
    );
  }

  get modalSize(): string {
    return this.showScorecards ? 'medium' : 'small';
  }

  @action
  handleResponseChanged(option: boolean): void {
    this.hireQualityResponse.recommended =
      this.hireQualityResponse.recommended === option ? null : option;
  }

  @action
  async saveResponse(): Promise<void> {
    await this.hireQualityResponse.save();
    this.flashMessages.success(
      this.intl.t('candidates.hire_quality_survey.response_saved')
    );
    this.args.onClose();
  }

  @action
  onDidInsert() {
    if (isPresent(this.surveyResponse)) {
      this.hireQualityResponse.recommended = this.surveyResponse;
      this.hireQualityResponse.save();
    }
  }
}
