/* import __COLOCATED_TEMPLATE__ from './job-channel.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { JobModel, JobCommentStatusModel } from 'teamtailor/models';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import NotificationsService from 'teamtailor/services/notifications';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { later } from '@ember/runloop';
import RouterService from '@ember/routing/router-service';

type Args = {
  job: JobModel;
  currentJob: JobModel;
  jobCommentStatuses?: JobCommentStatusModel[];
};

export default class JobChannel extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare notifications: NotificationsService;
  @service declare router: RouterService;

  @tracked isTruncated = false;

  get jobId() {
    return this.args.job.id;
  }

  get jobCommentStatus() {
    return this.args.jobCommentStatuses?.find((jobComment) => {
      return jobComment.belongsTo('job').id() === this.jobId;
    });
  }

  get hasUnreadJobComment() {
    return this.jobCommentStatus?.unread;
  }

  get countJobNotifications() {
    return this.notifications.unreadMentions
      ? this.notifications.unreadMentions[this.jobId]?.length || 0
      : 0;
  }

  @action
  handleJobChannel(jobId: string, currentJobId: string) {
    if (this.hasUnreadJobComment) {
      this.updateJobCommentStatus();
    }

    if (this.countJobNotifications) {
      this.updateMentions();
    }

    this.navigateToJobComments(jobId, currentJobId);
  }

  @action
  navigateToJobComments(jobId: string, currentJobId: string) {
    const toolboxQuery = jobId
      ? `global_comments,${jobId}`
      : `global_comments,${currentJobId}`;
    this.router.transitionTo({ queryParams: { toolbox: toolboxQuery } });
  }

  @action
  updateMentions() {
    if (this.notifications.unreadMentions) {
      const unreadMentionIds =
        this.notifications.unreadMentions[this.jobId] || [];

      this.notifications.markAsRead({
        actionIds: unreadMentionIds,
      });
    }
  }

  @action
  updateJobCommentStatus() {
    later(async () => {
      if (!this.jobCommentStatus) {
        return;
      }

      await this.jobCommentStatus.save();
      await this.notifications.refreshUnread.perform();
    }, 2500);
  }

  checkOverflow = modifier((element: HTMLElement) => {
    this.isTruncated = element.scrollWidth > element.clientWidth;
  });

  // need to use this if the parent has overflow-auto
  popperOptions = Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: true,
      },
    },
  });
}
