/* import __COLOCATED_TEMPLATE__ from './on-job-show.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import moment from 'moment-timezone';

export default class HeaderingBarOnJobShow extends Component {
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showAddTeamMemberModal = false;
  @tracked showDetailsModal = false;
  @tracked showUnlistModal = false;
  @tracked showArchiveModal = false;
  @tracked showInternalTitleModal = false;

  get scheduledStatusText() {
    const { isAwaitingApproval, isAwaitingPublish } = this.args.job;
    if (isAwaitingApproval || isAwaitingPublish) {
      return this.intl.t('job.pending');
    } else {
      return this.intl.t('job.scheduled');
    }
  }

  get scheduledStatusDescription() {
    const { isAwaitingApproval, isAwaitingPublish, startDate } = this.args.job;

    if (isAwaitingApproval) {
      return this.intl.t('components.job_header.job_waiting_for_approval');
    } else if (isAwaitingPublish) {
      return this.intl.t('components.job_header.job_waiting_for_publisher');
    } else {
      return this.intl.t('components.job_header.job_scheduled_to_go_live', {
        date: moment(startDate).format('ll'),
      });
    }
  }

  @action
  publish() {
    if (this.args.job.status !== 'open') {
      this.args.job.status = 'open';

      // We need to populate picked questions rowOrderPosition before a save.
      const pickedQuestions = get(
        this.args.job,
        'jobDetail.pickedQuestions'
      ).toArray();
      pickedQuestions.forEach((item, index) => {
        item.rowOrderPosition = index;
      });

      this.args.job.save().then(() => {
        this.flashMessages.success(
          this.intl.t('components.job_header.job_republished')
        );
      });
    }
  }

  @action
  toggleUnlistModal() {
    if (this.args.job.status !== 'unlisted') {
      this.showUnlistModal = !this.showUnlistModal;
    }
  }

  @action
  unlist() {
    const promise = this.args.job.unlist();

    promise.then(() => {
      this.showUnlistModal = false;
      this.flashMessages.success(
        this.intl.t('components.job_header.job_unlisted')
      );
    });
    return promise;
  }

  @action
  archive(rejectOptions = {}) {
    const promise = this.args.job.archive(rejectOptions);
    promise.then(() => {
      this.showArchiveModal = false;
    });
    return promise;
  }

  @action
  toggleArchiveModal() {
    if (this.args.job.status !== 'archived') {
      this.showArchiveModal = !this.showArchiveModal;
    }

    if (this.showArchiveModal) {
      this.args.onOpenArchiveModal();
    }
  }

  @action
  closeHiringTeamModal() {
    this.showAddTeamMemberModal = false;
  }

  @action
  gotoJobEdit() {
    this.router.transitionTo('jobs.edit', this.args.job);
  }
}
