/* import __COLOCATED_TEMPLATE__ from './user-reject-reasons.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';

const DATA_QUERY = gql`
  query EmployeeRejectReasonsQuery(
    $dateRange: DateRangeAttributes!
    $userId: ID!
  ) {
    user: eventQuery(
      dateRange: $dateRange
      filters: {
        userId: { equals: $userId }
        rejectReasonRejectedByCompany: { equals: true }
      }
      eventTypes: [REJECTED]
    ) {
      rejectReasons: aggregated(
        groupBy: [REJECT_REASON_REASON, REJECT_REASON_REJECTED_BY_COMPANY]
      ) {
        name: rejectReasonReason
        numberOfRejects: count
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'name', headerKey: 'common.name' },
  {
    type: 'number',
    propertyName: 'numberOfRejects',
    headerKey: 'insights.common.count',
  },
];

export default class InsightsWidgetsUserRejectReasons extends Component {
  @service insights;
  @service insightsExport;
  @service store;

  get rejectReasons() {
    return this.fetchData.lastSuccessful?.value?.rejectReasons;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.common.reject_reasons',
      `employee-${this.args.user.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then((data) => data.user.rejectReasons);
  }

  @action
  queryForData(dateRange) {
    const { user } = this.args;
    const userId = get(user, 'id');

    return this.insights.query({
      query: DATA_QUERY,
      variables: {
        userId,
        dateRange: dateRange.asObject,
      },
    });
  }

  fetchData = dropTask(async () => {
    const { user: userResponse } = await this.queryForData(this.args.dateRange);
    const { rejectReasons } = userResponse;

    return {
      rejectReasons: rejectReasons.map((row) => ({
        ...row,
        rejectedByCompany: true,
      })),
    };
  });
}
