/* import __COLOCATED_TEMPLATE__ from './image-library.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export interface IConfigFieldImageOption {
  id: string;
  thumbUrl: string;
  alt?: string;
  previewUrl?: string;
  isPicked?: boolean;
}

export interface PartnerConfigFieldsImageLibraryArgs {
  field: {
    id: string;
    type: string;
    options: IConfigFieldImageOption[];
  };
  label: string;
  description: string;
  value: string;
  onChange: (value: string) => void;
}

export default class extends Component<PartnerConfigFieldsImageLibraryArgs> {
  @tracked isOpen = false;
  @tracked images = this.args.field.options.map((e) => {
    return { ...e, previewUrl: e.thumbUrl, isPicked: false };
  });

  get pickedImage() {
    return this.images.find((image) => image.id === this.args.value);
  }

  get overlayImageStyle() {
    return `background-image:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${this.pickedImage?.thumbUrl})`;
  }

  @action
  handleImagePick(img: IConfigFieldImageOption) {
    this.images = this.images.map((e) => {
      if (e.id === img.id) {
        return { ...e, isPicked: true };
      } else return { ...e, isPicked: false };
    });
    this.isOpen = false;
    this.args.onChange(img.id);
  }

  @action
  handleRemoveImage() {
    this.images.map((image) => ({ ...image, isPicked: false }));
    this.args.onChange('');
  }
}
