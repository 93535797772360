import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AboardService from 'teamtailor/services/aboard-service';
import Current from 'teamtailor/services/current';
import { Transition } from 'teamtailor/utils/type-utils';
import AboardLiteController from 'teamtailor/controllers/jobs/job/stages/index/candidate/aboard-lite';

export default class JobsJobStagesIndexCandidateAboardLiteRoute extends Route {
  @service declare store;
  @service declare aboardService: AboardService;
  @service declare current: Current;

  model(params: { onboarding_id: string }) {
    return { onboardingId: params.onboarding_id };
  }

  setupController(
    controller: AboardLiteController,
    model: null,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
  }
}
