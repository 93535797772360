/* import __COLOCATED_TEMPLATE__ from './conversion-rate.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class InsightWidgetsConversionRate extends Component {
  @service intl;

  series = [
    this.intl.t('insights.common.applications'),
    this.intl.t('insights.common.visits'),
  ];
}
