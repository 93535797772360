/* import __COLOCATED_TEMPLATE__ from './radio-button-color.hbs'; */
import { argDefault } from 'teamtailor/utils/arg-default';
import Component from '@glimmer/component';
import {
  ALLOWED_COLORS,
  randomizeColor,
} from 'teamtailor/constants/radio-button-color';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import {
  ColorOption,
  gradientBackgroundStyle,
} from 'teamtailor/utils/gradient-background';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  options: ColorOption[];
  selectedColor: string;
  onColorChange: (color: string) => void;
  size?: 'medium' | 'large';
}

export default class RadioButtonCircleComponent extends Component<Args> {
  @tracked showTooltip = true;
  @argDefault size = 'medium';

  backgroundClassName = (option: ColorOption): string => {
    verifyArg(option.color, ALLOWED_COLORS, 'RadioButtonColor @options');

    return `bg-decorative-${option.color}-weak`;
  };

  get colorOptions() {
    return this.args.options.filter((option) => option.color !== 'random');
  }

  get multipleColorStyle() {
    return gradientBackgroundStyle(this.colorOptions);
  }

  @action
  changeColor(color: string) {
    if (color === 'random') {
      this.showTooltip = false;
      color = randomizeColor(
        this.colorOptions.map((o) => o.color),
        this.args.selectedColor
      );
    }

    this.args.onColorChange(color);
  }
}
