/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

interface StatusArgs {
  hideDrafts: boolean | undefined;
  selected?: string;
}

type OptionType = {
  id: string;
  label: string;
  humanStatus?: string;
  coveredStatuses?: string[];
};

export default class extends Component<StatusArgs> {
  @service declare intl: IntlService;
  @service declare current: Current;

  get options(): OptionType[] {
    const activatedApprovals = get(this.current.company, 'activatedApprovals');

    const statuses = [
      !this.args.hideDrafts
        ? {
            id: 'draft',
            label: this.intl.t('jobs.index.jobs_list.status_options.draft'),
          }
        : null,
      {
        id: 'published',
        label: this.intl.t('jobs.index.jobs_list.status_options.published'),
      },
      {
        id: 'awaiting',
        humanStatus: 'pending', // use human status to make it consistent with job status dots in job list
        label: this.intl.t('jobs.index.jobs_list.status_options.awaiting'),
      },
      activatedApprovals
        ? {
            id: 'pending_approval',
            label: this.intl.t('jobs.index.jobs_list.pending_approval'),
          }
        : null,
      {
        id: 'scheduled',
        label: this.intl.t('jobs.index.jobs_list.status_options.scheduled'),
      },
      {
        id: 'unlisted',
        label: this.intl.t('jobs.index.jobs_list.status_options.unlisted'),
      },
      {
        id: 'expired',
        label: this.intl.t('jobs.index.jobs_list.status_options.expired'),
      },
      {
        id: 'archived',
        label: this.intl.t('common.archived'),
      },
    ];

    return [
      {
        id: '',
        label: this.intl.t('jobs.index.jobs_list.status_options.current'),
        coveredStatuses: ['scheduled', 'published', 'unlisted', 'pending'], // to display multi-colored status dot in dropdown option
      },
      {
        id: 'all',
        label: this.intl.t('jobs.index.jobs_list.status_options.all'),
        coveredStatuses: statuses
          .compact()
          .map((status) => status.humanStatus || status.id), // to display multi-colored status dot in dropdown option
      },
      ...statuses,
    ].compact();
  }
}
