/* import __COLOCATED_TEMPLATE__ from './nurture-campaign.hbs'; */
import { inject as service } from '@ember/service';
import Base from './base';

export default class NurtureCampaignComponent extends Base {
  @service current;

  get user() {
    return this.current.user;
  }
}
