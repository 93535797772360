/* import __COLOCATED_TEMPLATE__ from './job-date-pickers.hbs'; */
import JobModel from 'teamtailor/models/job';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';

enum DATE_TYPES {
  START = 'start',
  END = 'end',
}

type JobDatePickersArgs = {
  model: JobModel;
};

export default class JobDatePickers extends Component<JobDatePickersArgs> {
  get model(): JobModel {
    return this.args.model;
  }

  get minDate() {
    return this.model.startDate ? moment(this.model.startDate).toDate() : null;
  }

  fixedTimezoneOffsetDate(): Date {
    const dateString = moment.utc(moment()).format('YYYY-MM-DD');
    return moment.parseZone(dateString, 'YYYY MM DD ZZ').toDate();
  }

  get displayStartDate() {
    return this.model.startDate ? moment(this.model.startDate).toDate() : null;
  }

  get displayEndDate() {
    return this.model.endDate ? moment(this.model.endDate).toDate() : null;
  }

  get hasStartDate(): boolean {
    return !isEmpty(this.model.startDate);
  }

  set hasStartDate(value) {
    if (value) {
      this.model.startDate = this.fixedTimezoneOffsetDate();
    } else {
      this.model.startDate = null;
    }
  }

  get hasEndDate(): boolean {
    return !isEmpty(this.model.endDate);
  }

  set hasEndDate(value) {
    if (value) {
      this.model.endDate = this.fixedTimezoneOffsetDate();
    } else {
      this.model.endDate = null;
    }
  }

  @action
  setDate(type: DATE_TYPES, value: Date | null): void {
    const formattedDateString = moment.utc(value).format('YYYY-MM-DD');
    const newValue = value !== null ? formattedDateString : null;

    if (type === DATE_TYPES.END) {
      this.model.endDate = newValue;
    }

    if (type === DATE_TYPES.START) {
      this.model.startDate = newValue;
    }
  }
}
