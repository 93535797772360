/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import { action } from '@ember/object';

import FeedbackModel from 'teamtailor/models/copilot-feedback';

export type Args = {
  text: string;
  code: string;
  metadata: Record<string, string>;
  appearance?: 'primary' | 'secondary';
  icon?: string;
  positiveText: string;
  negativeText: string;
  positiveIcon: string;
  negativeIcon: string;
  commentLabel: string;
  placeholder: string;
  cancelText: string;
  sendFeedbackText: string;
  thankYouText: string;
  customClass?: string;
};

export default class Feedback extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;

  @tracked declare feedback: FeedbackModel | undefined;
  @tracked showBox = true;
  @tracked showThankYou = false;

  get showCommentForm(): boolean {
    return this.feedback?.good === false && !this.showThankYou;
  }

  get showPositive(): boolean {
    return this.feedback?.good === undefined || this.feedback.good;
  }

  get showNegative(): boolean {
    return this.feedback?.good === undefined || !this.feedback.good;
  }

  get feedbackKey(): string {
    const ignoredKeys = ['resumeSummary'];

    const values = Object.keys(this.args.metadata)
      .filter((key) => !ignoredKeys.includes(key))
      .map((key) => this.args.metadata[key])
      .filter((value): value is string => value !== undefined)
      .sort((a, b) => a.toString().localeCompare(b.toString()))
      .join('-');

    return `${this.args.code}-${values}`;
  }

  get hasAnswered(): boolean {
    return this.getAnsweredFeedbacks()[this.feedbackKey] || false;
  }

  getAnsweredFeedbacks() {
    return JSON.parse(
      window.localStorage.getItem('answeredCopilotFeedback') || '{}'
    ) as Record<string, boolean>;
  }

  markAsAnswered() {
    window.localStorage.setItem(
      'answeredCopilotFeedback',
      JSON.stringify({
        ...this.getAnsweredFeedbacks(),
        [this.feedbackKey]: true,
      })
    );
  }

  submitFeedbackTask = dropTask(async (good: boolean) => {
    const feedback = this.store.createRecord('copilot-feedback');
    feedback.good = good;
    feedback.code = this.args.code;
    feedback.metadata = this.args.metadata;
    this.feedback = await feedback.save();

    if (good) {
      this.toggleThankYou();
    }
  });

  updateFeedbackTask = dropTask(async () => {
    if (this.feedback) {
      await this.feedback.save();
      this.toggleThankYou();
    }
  });

  toggleThankYou() {
    this.showThankYou = true;
    later(() => {
      this.showBox = false;
      this.markAsAnswered();
    }, 3000);
  }

  @action
  disregard() {
    this.showBox = false;
    this.markAsAnswered();
  }

  @action
  cancel() {
    this.feedback?.destroyRecord();
    this.feedback = undefined;
  }
}
