/* import __COLOCATED_TEMPLATE__ from './multiline-input.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';

type Args = {
  value?: string;
  allowEnter?: boolean;
  maxLength?: number;
};

export default class CoreInputComponent extends Component<Args> {
  @argDefault allowEnter = false;

  @action
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.allowEnter) {
      event.preventDefault();
    }
  }
}
