/* import __COLOCATED_TEMPLATE__ from './smart-move-criteria.hbs'; */
import Component from '@glimmer/component';
import { set, action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  delayOptions,
  delayUnitOptions,
  applyOptions,
  DelayUnit,
} from 'teamtailor/constants/triggers';
import { tracked } from '@glimmer/tracking';
import { AnimateArray, animate, setStyles } from 'teamtailor/ember-smooth';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import MoveModel from 'teamtailor/models/move';
import QuestionModel from 'teamtailor/models/question';
import LocationModel from 'teamtailor/models/location';
import JobModel from 'teamtailor/models/job';
import StageModel from 'teamtailor/models/stage';
import { get } from 'teamtailor/utils/get';
import PickedQuestionModel from 'teamtailor/models/picked-question';
import PickedLocationModel from 'teamtailor/models/picked-location';
import { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';
import FlipperService from 'teamtailor/services/flipper';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { modifier } from 'ember-modifier';

type MoveComponentArgs = {
  move: MoveModel;
  allMoves: MoveModel[];
  job: JobModel;
  stage: StageModel;
  questions: AsyncHasMany<QuestionModel>;
  locations?: AsyncHasMany<LocationModel>;
  onSelect: (option: string) => void;
};

type Pickable = PickedQuestionModel | PickedLocationModel;

export default class SmartMoveCriteria extends Component<MoveComponentArgs> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare flipper: FlipperService;

  @tracked pickables: any;
  @tracked declare addButtonEl: HTMLButtonElement;
  @tracked showCustomDelay = !this.args.move.delayJobForSelectedOption;

  constructor(owner: unknown, args: MoveComponentArgs) {
    super(owner, args);

    if (this.move.hasCriteriaQuestion) {
      this.initAnimatedArray(this.move.pickedQuestions.slice());
    } else if (this.move.hasCriteriaLocation) {
      this.initAnimatedArray(this.move.pickedLocations.slice());
    }
  }

  initAnimatedArray(array: Pickable[]) {
    this.pickables = new AnimateArray(...array);
  }

  setAddButton = modifier((element: HTMLButtonElement) => {
    this.addButtonEl = element;
  });

  get options() {
    return [
      { label: this.intl.t('common.any'), value: 'any' },
      { label: this.intl.t('common.all'), value: 'all' },
    ];
  }

  get movesCount() {
    return this.args.allMoves.length;
  }

  get selectedDelayOption() {
    const selectedOption = this.move.delayJobForSelectedOption;
    return this.delayOptions.find((option) => {
      return this.showCustomDelay
        ? option.value === 'custom'
        : option.value === selectedOption?.value;
    });
  }

  get locationOptions() {
    const jobLocations = (this.args.locations || []).toArray();
    const pickedLocationIds = this.allPickedLocations
      .filter((p) => !p.isDeleted)
      .map((p) => get(get(p, 'location'), 'id'));

    return jobLocations.map((location: LocationModel) => {
      return {
        id: get(location, 'id'),
        nameOrCity: get(location, 'nameOrCity'),
        isDisabled: pickedLocationIds.includes(get(location, 'id')),
        location,
      };
    });
  }

  get questionOptions() {
    const { jobQuestions } = this;

    const jobQuestionIds = jobQuestions.map((q) => get(q, 'id'));
    const questionsToGroup = this.args.questions;
    const nonJobQuestions = questionsToGroup.filter((question) => {
      return !jobQuestionIds.includes(get(question, 'id'));
    });

    const jobQuestionsOptions = this.buildGroupQuestionOptions(
      jobQuestions.toArray()
    );

    const nonJobQuestionsOptions =
      this.buildGroupQuestionOptions(nonJobQuestions);

    return [
      ...(jobQuestionsOptions.length
        ? [
            {
              groupName: this.intl.t(
                'components.trigger_smart_move_form.job_questions'
              ),

              options: jobQuestionsOptions,
            },
          ]
        : []),
      ...(nonJobQuestionsOptions.length
        ? [
            {
              groupName: this.intl.t(
                'components.trigger_smart_move_form.all_questions'
              ),

              options: nonJobQuestionsOptions,
            },
          ]
        : []),
    ];
  }

  get delayOptions() {
    return delayOptions.map((delayOption) => ({
      ...delayOption,
      title: this.intl.t(
        delayOption.value === 0
          ? 'components.trigger_smart_move_form.move_immediately'
          : delayOption.value === 'custom'
            ? 'components.trigger_smart_move_form.delay_for_custom_time'
            : 'components.trigger_smart_move_form.delay_for',
        { time: this.intl.t(delayOption.title) }
      ),
    }));
  }

  get delayUnitOptions() {
    return delayUnitOptions.map((unit) => ({
      value: unit,
      title: this.intl.t(`constants.units.${unit}`),
    }));
  }

  get applyOptions() {
    return applyOptions.map((applyOption) => ({
      ...applyOption,
      title: this.intl.t(applyOption.title),
    }));
  }

  get move() {
    return this.args.move;
  }

  get moveType() {
    return this.args.move.criteria;
  }

  get stage() {
    return this.args.stage;
  }

  get job() {
    return this.args.job;
  }

  get jobQuestions() {
    return get(get(this.job, 'jobDetail'), 'questions');
  }

  @action
  handleDelayUnitSelect(option: (typeof this.delayUnitOptions)[number]) {
    this.move.delayJobForUnit = option.value;
  }

  @action
  changeDelayJobFor({ value }: (typeof delayOptions)[number]) {
    this.showCustomDelay = value === 'custom';
    this.move.delayJobFor = value === 'custom' ? 0 : value;
    this.move.delayJobForUnit = DelayUnit.Minutes;
  }

  @action
  handleRangeChange(
    pickedQuestion: PickedQuestionModel,
    value: [number, number] | number
  ) {
    if (pickedQuestion.rangeOperator === 'between' && Array.isArray(value)) {
      setProperties(pickedQuestion, {
        rangeMinAnswer: value[0],
        rangeMaxAnswer: value[1],
      });
    } else if (!Array.isArray(value)) {
      set(pickedQuestion, 'rangeSingleAnswer', value);
    }
  }

  @action
  changeApplyJobFor(apply?: (typeof applyOptions)[number]) {
    if (apply) {
      if (apply.value === 'ignoreSourced') {
        set(this.move, 'ignoreInternal', false);
        set(this.move, 'ignoreSourced', true);
      } else if (apply.value === 'ignoreInternal') {
        set(this.move, 'ignoreSourced', false);
        set(this.move, 'ignoreInternal', true);
      } else if (apply.value === 'ignoreSourcedAndInternal') {
        set(this.move, 'ignoreSourced', true);
        set(this.move, 'ignoreInternal', true);
      } else {
        set(this.move, 'ignoreSourced', false);
        set(this.move, 'ignoreInternal', false);
      }
    }
  }

  buildGroupQuestionOptions(questions: QuestionModel[]) {
    const pickedQuestionIds = this.allPickedQuestions
      .filter((p) => !p.isDeleted)
      .map((p) => get(get(p, 'question'), 'id'));

    return questions.map((question) => {
      return {
        id: get(question, 'id'),
        title: get(question, 'title'),
        toHuman: get(question, 'toHuman'),
        questionData: get(question, 'questionData'),
        isDisabled: pickedQuestionIds.includes(get(question, 'id')),
        badgeText: get(question, 'toHuman'),
        badgeColorVariant: 'medium',
        question,
      };
    });
  }

  get isCriteriaJobOffer() {
    return this.move.hasCriteriaJobOffer;
  }

  get isCriteriaQuestion() {
    return this.move.hasCriteriaQuestion;
  }

  get isCriteriaCvContent() {
    return this.move.hasCriteriaCvContent;
  }

  get isCriteriaLocation() {
    return this.move.hasCriteriaLocation;
  }

  get jobOfferOptions() {
    return ['job_offer_accepted', 'job_offer_declined', 'job_offer_sent'];
  }

  get stageOptions() {
    const currentStageId = get(this.stage, 'id');
    const options = get(this.job, 'stages').map((stage) => {
      const stageId = get(stage, 'id');
      const isDisabled = stageId ? stageId === currentStageId : true;
      return {
        id: stageId,
        name: get(stage, 'name'),
        isDisabled,
        stage,
      };
    });
    return options;
  }

  get allPickedQuestions() {
    return this.args.move.pickedQuestions;
  }

  get allPickedLocations() {
    return this.args.move.pickedLocations;
  }

  setCriteriaText(text: string) {
    set(this.move, 'criteriaText', text);
  }

  @action
  handleKeywordChange(keyword: string) {
    const keywords = get(this.move, 'keywords');
    toggleInList(keywords, keyword);
    this.setCriteriaText(keywords.join(','));
  }

  @action
  handleAddQuestionCriteria({ question }: { question: QuestionModel }) {
    const rowOrder = this.move.lastPickedQuestionRowOrder + 1;
    const props = applyDefaultPickedQuestionValues({ question, rowOrder });
    const item = this.move.pickedQuestions.createRecord(props);
    this.addPickableItem(item);
  }

  @action
  handleAddLocationCriteria({ location }: { location: LocationModel }) {
    const item = this.move.pickedLocations.createRecord({ location });
    this.addPickableItem(item);
  }

  @action
  handleSetProceedStage({ stage }: { stage: AsyncBelongsTo<StageModel> }) {
    set(this.move, 'proceedStage', stage);
  }

  @tracked isAnimating = false;
  @tracked isAnimatingRenderAsListEmpty = false;

  get listEmpty() {
    return this.isAnimating
      ? this.isAnimatingRenderAsListEmpty
      : this.itemCount === 0;
  }

  get itemCount() {
    return (this.pickables || []).length;
  }

  async animateItemRemoval(
    onAnimationStart: (() => Promise<void> | undefined) | null,
    onAnimationEnd: (() => Promise<void> | undefined) | null
  ) {
    this.isAnimating = true;

    if (onAnimationStart) {
      await onAnimationStart();
    }

    const isEmpty = this.itemCount === 0;
    if (isEmpty && document.startViewTransition) {
      const viewTransitionName = 'addButton';
      this.addButtonEl.style.viewTransitionName = viewTransitionName;

      const rectBefore = this.addButtonEl.getBoundingClientRect();
      const transition = document.startViewTransition(() => {
        this.isAnimatingRenderAsListEmpty = true;
      });

      await Promise.all([transition.ready, transition.updateCallbackDone]);

      const rect = this.addButtonEl.getBoundingClientRect();
      const duration = 200;
      const unsetDocStyles = setStyles(document.documentElement, {
        '--view-transition-duration': `${duration}ms`,
      });

      animate.to(
        document.documentElement,
        { opacity: 0 },
        {
          duration: 50,
          pseudoElement: `::view-transition-old(${viewTransitionName})`,
        }
      );

      animate(
        document.documentElement,
        [
          { width: rectBefore.width, height: rectBefore.height },
          { width: rect.width, height: rect.height },
        ],
        {
          pseudoElement: `::view-transition-old(${viewTransitionName})`,

          duration,
        }
      );

      await animate(
        document.documentElement,
        [
          { width: rectBefore.width, height: rectBefore.height },
          { width: rect.width, height: rect.height },
        ],
        {
          pseudoElement: `::view-transition-new(${viewTransitionName})`,
          duration,
        }
      );

      unsetDocStyles({ await: transition.finished });
      this.addButtonEl.style.viewTransitionName = '';
    }

    if (onAnimationEnd) {
      await onAnimationEnd();
    }

    this.isAnimatingRenderAsListEmpty = false;
    this.isAnimating = false;
  }

  addPickableItem(item: Pickable) {
    return this.pickables?.asyncPushObject(item);
  }

  removePickableItem(item: Pickable) {
    return this.pickables?.asyncRemoveObject(item);
  }

  @action
  handleRemoveCriteria(item: Pickable) {
    const onAnimationStart = () => {
      return this.removePickableItem(item);
    };

    const onAnimationEnd = () => {
      return new Promise<void>((resolve) => {
        item.unloadRecord();
        resolve();
      });
    };

    this.animateItemRemoval(onAnimationStart, onAnimationEnd);
  }

  @action
  toggleMultipleChoiceOption(
    pickedQuestion: PickedQuestionModel,
    alternativeId: string
  ) {
    toggleInList(get(pickedQuestion, 'multipleChoiceAnswers'), alternativeId);
  }
}
