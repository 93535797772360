/* import __COLOCATED_TEMPLATE__ from './nps-comments.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPromoterScore, isDetractorScore } from 'teamtailor/utils/nps';

export default class extends Component {
  @tracked onlyComments = false;

  @action
  scoreIconClass(score) {
    if (isPromoterScore(score)) {
      return 'scoreIcon--promoter';
    } else if (isDetractorScore(score)) {
      return 'scoreIcon--detractor';
    } else {
      return 'scoreIcon--passive';
    }
  }

  @action
  toggleComments() {
    this.onlyComments = !this.onlyComments;

    if (this.args.onCommentFilterChange) {
      this.args.onCommentFilterChange(this.onlyComments);
    }
  }

  get responses() {
    if (this.onlyComments) {
      return this.args.responses.filter((response) => response.comment);
    } else {
      return this.args.responses;
    }
  }
}
