/* import __COLOCATED_TEMPLATE__ from './counter.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type Args = {
  value: number;
  min: number;
  onValueChange: (value: number) => void;
};

export default class CounterComponent extends Component<Args> {
  @tracked value = this.args.value || 0;

  get min(): number {
    return this.args.min || 0;
  }

  @action
  onButtonsClick(amount: number) {
    const newValue = +this.value + amount;
    this.updateValue(newValue);
  }

  @action
  handleChange(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    this.updateValue(value);
  }

  updateValue(value: number): void {
    if (value >= this.min) {
      this.value = value;
      this.args.onValueChange(value);
    }
  }
}
