/* import __COLOCATED_TEMPLATE__ from './linkedin.hbs'; */
import ShareButton from 'teamtailor/components/share-button/share-button';
export default class extends ShareButton {
  shareURL = 'https://www.linkedin.com/shareArticle';

  buildUrl() {
    let url = this.shareURL;
    url += '?mini=true';
    url += `&url=${encodeURIComponent(this.args.url)}`;
    url += `&title=${this.args.title}`;
    url += `&summary=${this.args.text}`;
    return url;
  }
}
