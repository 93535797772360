/* import __COLOCATED_TEMPLATE__ from './ai-response.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { animate } from 'teamtailor/ember-smooth';
import { CoreDropdownMenuApi } from '../core/dropdown-menu';

export default class WysiwygEditorAiResponse extends Component {
  aiResponseAnchoredEl?: HTMLElement;
  backdropEl?: HTMLElement;

  @action onClickOutside(event: MouseEvent) {
    if (
      event.target instanceof HTMLElement &&
      (this.aiResponseAnchoredEl?.contains(event.target) ||
        event.target === this.backdropEl)
    ) {
      return true;
    }

    return false;
  }

  declare dropdownContainerEl: HTMLElement;

  @action onRegisterDropdown(api: CoreDropdownMenuApi) {
    this.dropdownContainerEl = api.containerElement!;
  }

  @action onBackdropInsert(el: HTMLElement) {
    this.backdropEl = el;
  }

  @action handleBackdropClick() {
    animate(this.dropdownContainerEl, 'headShake');
  }
}
