import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class ContentEditorBlockLibraryBlockLayoutRoute extends Route {
  @service store;

  async model({ layout_name }) {
    const page = this.modelFor('content.editor');

    const [careerSite, blockLayouts] = await Promise.all([
      get(page, 'careerSite.content'),
      this.store.findAll('block-layout'),
    ]);

    const blockLayout = blockLayouts.find(
      (blockLayout) => blockLayout.displayName === layout_name
    );

    let colorPreset, titleType;

    // From Rails section model, keep both in sync
    switch (blockLayout.name) {
      case 'social-links':
        titleType = 'none';
        break;
      case 'workplace':
        colorPreset = 'secondary';
        break;
      default:
        titleType = 'title';
        colorPreset = 'primary';
    }

    return {
      blockLayout,
      page,
      careerSite,
      mockSection: this.store.createRecord(
        this.blockLibraryController.getSectionType(blockLayout.blockName),
        {
          colorPreset,
          layout: blockLayout.name,
          titleType,
        }
      ),
    };
  }

  get blockLibraryController() {
    return this.controllerFor('content.editor.block-library');
  }
}
