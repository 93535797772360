/* import __COLOCATED_TEMPLATE__ from './create-job-modal.hbs'; */
import { action, get } from '@ember/object';
import AvatarModel from 'teamtailor/models/avatar';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import JobModel, { CopyJobArgs, CopyJobParams } from 'teamtailor/models/job';
import Current from 'teamtailor/services/current';

interface JobsCreateJobModalArgs {
  onModalClose(): void;
  requisitionId?: string;
  displayLoadState?: boolean;
  hideTemplatePicker?: boolean;
}

export type AvatarParams = {
  background_color: string;
  chosen_emoji: string;
  chosen_icon: string;
  avatar_type: string;
};

export default class JobsCreateJobModal extends Component<JobsCreateJobModalArgs> {
  @service declare current: Current;

  @tracked declare jobName: string;

  @tracked _inTransit = false;
  @tracked newTemplateInTransit = false;
  @tracked _selectedTemplate: JobModel | null = null;
  @tracked avatar: AvatarModel | null = null;

  get selectedTemplate() {
    return this._selectedTemplate
      ? this._selectedTemplate
      : this.defaultJobTemplate || null;
  }

  get templatesWithDefault() {
    return get(this.current.company, 'templates');
  }

  get defaultJobTemplate() {
    return this.templatesWithDefault.slice().find((job) => {
      return job.defaultTemplate;
    });
  }

  get jobTemplates() {
    return get(this.current.company, 'availableJobTemplates').slice();
  }

  get hasJobTemplates(): boolean {
    return this.jobTemplates.length > 0;
  }

  get inTransit() {
    return this.args.displayLoadState || this._inTransit;
  }

  get avatarParams() {
    return this.avatar ? this.avatar.toParams() : undefined;
  }

  @action
  createJob(template: JobModel, isTemplate = false): void {
    this._inTransit = true;
    let args: CopyJobArgs | undefined;

    if (isTemplate) {
      args = {
        params: {
          is_template: true,
        },
      };
    }

    if (this.args.requisitionId && !isTemplate) {
      args = {
        params: { requisition_id: this.args.requisitionId },
      };
    }

    // Ensure jobName is always a string. This hack prevents an issue where
    // FormDataAdapterMixin generates a malformed request body if params is
    // empty or all params are undefined.
    if (!this.jobName) {
      this.jobName = '';
    }

    const params: CopyJobParams = {
      job_name: this.jobName,
    };

    if (this.avatarParams) {
      params.avatar_params = this.avatarParams;
    }

    if (args?.params) {
      args.params = { ...args.params, ...params };
    } else {
      args = { params };
    }

    template.copyJob(args);
  }

  @action
  onSelectTemplate(template: JobModel): void {
    this._selectedTemplate = template;
  }

  @action
  createFromTemplate(template: JobModel): void {
    this.createJob(template);
  }

  @action
  onPickAvatar(avatar: AvatarModel): void {
    this.avatar = avatar;
  }
}
