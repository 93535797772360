/* import __COLOCATED_TEMPLATE__ from './average-rating.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import AverageRating from 'teamtailor/utils/average-rating';

type AverageRatingCellData = {
  value?: AverageRating | number;
};

type Args = {
  data: AverageRatingCellData;
};

export default class DataTableAverageRatingComponent extends Component<Args> {
  get value() {
    return this.args.data.value;
  }

  get averageRating() {
    return this.value instanceof AverageRating ? this.value.value : this.value;
  }

  get isLoading() {
    return this.value instanceof AverageRating
      ? this.value.fetchAverageRating.isRunning
      : false;
  }

  get isSuccessful() {
    return this.value instanceof AverageRating
      ? this.value.fetchAverageRating.lastSuccessful
      : true;
  }

  @action
  onEnter() {
    if (
      this.value instanceof AverageRating &&
      !this.value.fetchAverageRating.isRunning &&
      this.value.fetchAverageRating.performCount === 0
    ) {
      this.value.fetchAverageRating.perform();
    }
  }
}
