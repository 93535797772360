/* import __COLOCATED_TEMPLATE__ from './questions.hbs'; */
import Component from '@glimmer/component';
import {
  CareerSiteModel,
  ConnectSettingModel,
  DepartmentModel,
  PickedQuestionModel,
  RoleModel,
} from 'teamtailor/models';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { MappedTypes } from 'teamtailor/constants/question';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

interface ConnectSettingsBlocksQuestions {
  Args: {
    selectedDepartment?: DepartmentModel;
    selectedRole?: RoleModel;
    careerSite: CareerSiteModel;
    generalConnectSettings: ConnectSettingModel;
    connectSettings: ConnectSettingModel;
    currentDepartmentConnectSettings?: ConnectSettingModel;
  };
}

export default class ConnectSettingsBlocksQuestionsComponent extends Component<ConnectSettingsBlocksQuestions> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare current: Current;

  basicPickedQuestions: PickedQuestionModel[] = [];

  constructor(owner: unknown, args: ConnectSettingsBlocksQuestions['Args']) {
    super(owner, args);
    this.createBasicPickedQuestions();
  }

  get isDefaultCareerSite() {
    return get(this.args.careerSite, 'isDefault');
  }

  get generalPickedQuestions() {
    return this.isDefaultCareerSite
      ? this.args.generalConnectSettings.pickedQuestions
      : this.current.company.pickedQuestions;
  }

  get departmentPickedQuestions() {
    return this.isDefaultCareerSite
      ? this.args.currentDepartmentConnectSettings?.pickedQuestions
      : this.args.selectedDepartment?.pickedQuestions;
  }

  get pickedQuestions() {
    if (this.isDefaultCareerSite) {
      return this.args.connectSettings.pickedQuestions;
    } else {
      if (this.args.selectedRole) {
        return this.args.selectedRole.pickedQuestions;
      } else if (this.args.selectedDepartment) {
        return this.args.selectedDepartment.pickedQuestions;
      } else {
        return this.current.company.pickedQuestions;
      }
    }
  }

  get basicQuestions() {
    return [
      {
        title: this.intl.t('settings.connect.questions.any_basic_details'),
        type: MappedTypes.choice,
      },
      {
        title: this.intl.t(
          'settings.connect.questions.where_do_you_want_to_work'
        ),

        type: MappedTypes.boolean,
      },
      {
        title: this.intl.t('settings.connect.questions.upload_resume'),
        type: MappedTypes.file,
      },
      {
        title: this.intl.t('settings.connect.questions.pitch_yourself'),
        type: MappedTypes.text,
      },
    ];
  }

  get currentGroup() {
    if (this.args.selectedRole) {
      return this.args.selectedRole.name;
    } else if (this.args.selectedDepartment) {
      return this.args.selectedDepartment.name;
    }

    return this.intl.t('settings.connect.general');
  }

  get tooltip() {
    if (this.args.selectedRole) {
      return this.intl.t('settings.connect.questions.add_role_question');
    } else if (this.args.selectedDepartment) {
      return this.intl.t('settings.connect.questions.add_department_question');
    }

    return this.intl.t('settings.connect.questions.add_question');
  }

  createBasicPickedQuestions() {
    this.basicQuestions.forEach((q, i) => {
      let question = this.store.createRecord('question', {
        title: q.title,
        type: q.type,
      });

      let pickedQuestion = this.store.createRecord('picked-question', {
        question,
        rowOrderPosition: i,
      });

      this.basicPickedQuestions.push(pickedQuestion);
    });
  }

  willDestroy(): void {
    super.willDestroy();
    this.basicPickedQuestions.forEach(async (pickedQuestion) => {
      const question = await pickedQuestion.question;
      pickedQuestion.unloadRecord();
      question?.unloadRecord();
    });
  }
}
