/* import __COLOCATED_TEMPLATE__ from './expanded-view.hbs'; */
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import {
  ActivityModel,
  JobApplicationModel,
  MeetingEventModel,
  TodoModel,
} from 'teamtailor/models';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import CandidatePreviewService from 'teamtailor/services/candidate-preview';
import TimeFormatService from 'teamtailor/services/time-format';
import { get } from 'teamtailor/utils/get';

type StageExpandedViewArgs = {
  jobApplication: JobApplicationModel;
  candidateRoute?: string;
  anonymous?: boolean;
  stageId?: string;
};

type TodoDueDateDropdown = {
  actions?: {
    close?: () => void;
  };
};

type Content = 'meetings' | 'todos' | 'actions' | 'upcoming-triggers';

export default class StageExpandedViewComponent extends Component<StageExpandedViewArgs> {
  @service declare apollo: TeamtailorApolloService;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;
  @service declare candidatePreview: CandidatePreviewService;
  @tracked meetings: MeetingEventModel[] = [];
  @tracked activities: ActivityModel[] = [];
  @tracked todos: TodoModel[] = [];
  @tracked expandedContent: Content | null = null;
  todoDueDateDropdown?: TodoDueDateDropdown;

  get hasOnlyActivities() {
    return (
      [
        this.meetings,
        this.todos,
        this.args.jobApplication.jobApplicationTriggers,
      ].flat().length === 0
    );
  }

  get hasNoData() {
    return (
      !this.todos.length && !this.meetings.length && !this.activities.length
    );
  }

  fetchDataTask = restartableTask(async () => {
    const candidate = await get(this.args.jobApplication, 'candidate');

    if (!candidate?.id) {
      return;
    }

    const storeData = this.candidatePreview.stageCards.find(
      (c) => c.id === candidate.id && c.stageId === this.args.stageId
    );

    if (storeData) {
      const { meetings, activites, todos } = storeData;
      this.meetings = meetings;
      this.activities = activites;
      this.todos = todos;
    } else {
      const [meetings, activites, todos] = await Promise.all([
        this.store.query('meeting-event', {
          upcoming_and_ongoing: true,
          filter: {
            candidate_ids: [candidate.id],
          },
        }),

        this.store.query('activity', {
          candidate_id: candidate.id,
          per_page: 3,
          filter_automatic_emails: true,
        }),

        this.store.query('todo', {
          candidate_id: candidate.id,
          only_incomplete: true,
        }),
      ]);

      this.meetings = meetings;
      this.activities = activites;
      this.todos = todos;

      this.candidatePreview.stageCards = [
        ...this.candidatePreview.stageCards,
        {
          id: candidate.id,
          meetings: this.meetings,
          activites: this.activities,
          todos: this.todos,
          stageId: this.args.stageId || '',
        },
      ];
    }

    this.expandedContent = this.meetings.length
      ? 'meetings'
      : this.todos.length
        ? 'todos'
        : 'actions';
  });

  @action
  toggleExpanded(componentName: Content) {
    this.expandedContent =
      this.expandedContent === componentName ? null : componentName;
  }

  @action
  onToggleDueDateDropdown(dropdown: TodoDueDateDropdown) {
    this.todoDueDateDropdown = dropdown;
  }
}
