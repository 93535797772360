/* import __COLOCATED_TEMPLATE__ from './job-activity.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';

import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';

const ACTIVITY_QUERY = gql`
  query JobActivityQuery(
    $dateRange: DateRangeAttributes!
    $previousDateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    current: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: { userId: { exists: true } }
    ) {
      total: count
      byUser: aggregated(groupBy: [USER_ID]) {
        userId
        totalActivities: count

        notes: countOccurrences(filters: { eventType: { equals: NOTE } })
        sources: countOccurrences(filters: { eventType: { equals: SOURCED } })
        messages: countOccurrences(filters: { eventType: { equals: MESSAGE } })
        moves: countOccurrences(filters: { eventType: { equals: MOVED } })
        scores: countOccurrences(filters: { eventType: { equals: SCORE } })
        rejections: countOccurrences(
          filters: { eventType: { equals: REJECTED } }
        )
        hires: countOccurrences(filters: { eventType: { equals: HIRED } })
        interviews: countOccurrences(
          filters: { eventType: { equals: INTERVIEW_CREATED } }
        )
      }
    }
    previous: eventQuery(
      dateRange: $previousDateRange
      jobIds: $jobIds
      filters: { userId: { exists: true } }
    ) {
      total: count
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'nameOrEmail', headerKey: 'common.name' },
  {
    type: 'number',
    propertyName: 'notes',
    headerKey: 'insights.users.common.notes',
  },
  {
    type: 'number',
    propertyName: 'messages',
    headerKey: 'insights.users.common.messages',
  },
  {
    type: 'number',
    propertyName: 'moves',
    headerKey: 'insights.users.common.moves',
  },
  {
    type: 'number',
    propertyName: 'rejections',
    headerKey: 'insights.users.common.rejections',
  },
  {
    type: 'number',
    propertyName: 'sources',
    headerKey: 'insights.users.common.sources',
  },
  {
    type: 'number',
    propertyName: 'scores',
    headerKey: 'insights.users.common.scores',
  },
  {
    type: 'number',
    propertyName: 'hires',
    headerKey: 'insights.users.common.hires',
  },
  {
    type: 'number',
    propertyName: 'interviews',
    headerKey: 'insights.users.common.interviews',
  },
  {
    type: 'number',
    propertyName: 'totalActivities',
    headerKey: 'insights.common.activities',
  },
];

export default class InsightsWidgetsJobActivity extends Component {
  @service insights;
  @service insightsExport;
  @service store;

  get currentTotal() {
    return this.fetchData.lastSuccessful?.value.currentTotal;
  }

  get previousTotal() {
    return this.fetchData.lastSuccessful?.value.previousTotal;
  }

  get activitiesByUser() {
    return this.fetchData.lastSuccessful?.value.activitiesByUser;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.common.activities',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      {
        sortKey: 'totalActivities',
        createdAt: this.args.job.createdAt,
        size: 'large',
      }
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then(
      (result) => result.activitiesByUser
    );
  }

  @action
  async queryForData(dateRange) {
    const { job } = this.args;

    const { current, previous } = await this.insights.query({
      query: ACTIVITY_QUERY,
      variables: {
        jobIds: [job.id],
        dateRange: dateRange.asObject,
        previousDateRange: dateRange.previousDateRange.asObject,
      },

      context: { jobId: job.id },
    });
    let activitiesByUser = current?.byUser?.map((row) => ({
      ...row,
      id: row.userId,
    }));

    const users = await this.store.query('user', {
      ids: activitiesByUser.mapBy('id'),
    });
    return {
      id: job.id,
      previousTotal: previous?.total,
      currentTotal: current?.total,
      activitiesByUser: activitiesByUser.map((row) => {
        const user = users.find((user) => user.id === row.userId);
        return {
          ...row,
          nameOrEmail: (user && user.nameOrEmail) || 'Deleted user',
          initials: (user && user.initials) || 'du',
          picture: user && user.picture,
          user,
        };
      }),
    };
  }

  fetchData = dropTask(async () => {
    const activities = await this.queryForData(this.args.dateRange);

    return {
      ...activities,
      activitiesByUser: activities.activitiesByUser.filter((user) => {
        const totalCounted =
          user.notes + user.messages + user.moves + user.rejections;
        return totalCounted > 0;
      }),
    };
  });

  get topFiveUsers() {
    if (this.fetchData.lastSuccessful) {
      const { activitiesByUser } = this.fetchData.lastSuccessful.value;
      return activitiesByUser.sortBy('totalActivities').reverse().slice(0, 5);
    } else {
      return [];
    }
  }
}
