/* import __COLOCATED_TEMPLATE__ from './textarea.hbs'; */
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { argDefault } from 'teamtailor/utils/arg-default';

import {
  AllowedSizes,
  ALLOWED_SIZES,
  DEFAULT_SIZE,
} from 'teamtailor/constants/form';

type Args = {
  size?: AllowedSizes;
  maxLength?: number;
  value: string;
  autoresize?: boolean;
  onChange?: (event: Event) => void;
};

export default class CoreTextareaComponent extends Component<Args> {
  @argDefault autoresize = false;

  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Input @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get limitReached() {
    return this.args.value && this.args.value.length >= this.args.maxLength!;
  }
}
