/* import __COLOCATED_TEMPLATE__ from './action-bar.hbs'; */
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

interface ActionBarComponentArgs {
  show?: boolean;
}

export default class ActionBarComponent extends Component<ActionBarComponentArgs> {
  @argDefault show = true;
}
