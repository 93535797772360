/* import __COLOCATED_TEMPLATE__ from './qr-code-creator.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { lastValue, timeout, task } from 'ember-concurrency';
import imageUrl from 'teamtailor/utils/image-url';

const MIN_LOAD_TIME = 750;

export default class QrCodeCreatorComponent extends Component {
  @service current;

  @tracked accentColor = null;
  @tracked useLogo = false;
  @tracked isRound = true;
  @tracked useAccentColor = true;
  @tracked logo = null;

  design = null;

  @lastValue('initialize') loadComplete;

  triggerUpdatedCallback() {
    if (!this.args.onChange) {
      return;
    }

    const { accentColor, useLogo, isRound } = this;

    this.args.onChange({
      accentColor,
      useLogo,
      isRound,
      logo: useLogo ? this.logo : null,
    });
  }

  initialize = task(async () => {
    const startTime = Date.now();
    this.design = await get(this.current.company, 'design');
    this.handleUseAccentColorChange(true);

    if (this.args.logo) {
      this.logo = this.args.logo;
    } else {
      let logo = await get(this.design, 'logotype');
      if (!logo) {
        logo = await get(this.design, 'careersiteLogo');
      }

      if (logo) {
        this.logo = imageUrl(logo, 'original');
      }
    }

    this.useLogo = !!this.logo;

    this.handleIncludeLogoChange(this.useLogo);

    const loadTime = Date.now() - startTime;
    if (loadTime < MIN_LOAD_TIME) {
      await timeout(MIN_LOAD_TIME - loadTime);
    }

    return true;
  });

  @action
  handleIncludeLogoChange(value) {
    this.useLogo = value;
    this.triggerUpdatedCallback();
  }

  @action
  handleUseAccentColorChange(value) {
    this.useAccentColor = value;
    this.accentColor = this.useAccentColor ? this.design.color : false;
    this.triggerUpdatedCallback();
  }

  @action
  handleStyleChange(value) {
    this.isRound = value;
    this.triggerUpdatedCallback();
  }
}
