/* import __COLOCATED_TEMPLATE__ from './block-editor-item.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';
interface ConnectSettingsBlockEditorItem {
  Args: {
    blockType: string;
    isDisabled?: boolean;
    anchorValue?: string;
    onClick?: (blockType: string, anchorValue?: string) => void;
  };
}

export default class ConnectSettingsBlockEditorItemComponent extends Component<ConnectSettingsBlockEditorItem> {
  @argDefault isDisabled = false;

  get icon() {
    switch (this.args.blockType) {
      case 'cover':
        return 'book-sparkles';

      case 'welcome-message':
        return 'envelope';

      case 'posts':
        return 'feather-pointed';

      case 'noteworthy':
        return 'sparkle';

      default:
        return 'question-circle';
    }
  }

  @action
  onClick() {
    this.args.onClick?.(this.args.blockType, this.args.anchorValue);
  }
}
