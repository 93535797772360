/* import __COLOCATED_TEMPLATE__ from './song-embed.hbs'; */
import Component from '@glimmer/component';

interface Signature {
  Args: {
    url: string | null;
  };
}

export default class SongEmbed extends Component<Signature> {
  get url() {
    return this.args.url || '';
  }

  get spotifyUrl() {
    if (this.url.includes('spotify.com')) {
      return this.url.replace(
        'https://open.spotify.com',
        'https://open.spotify.com/embed'
      );
    }

    return null;
  }

  get appleMusicUrl() {
    if (this.url.includes('apple.com')) {
      return this.url.replace(
        'https://music.apple.com',
        'https://embed.music.apple.com'
      );
    }

    return null;
  }

  get tidalUrl() {
    const regex = /^https?:\/\/listen\.tidal\.com\/track\/(\d+)/;
    const match = this.url.match(regex);

    if (match?.[1]) {
      return `https://embed.tidal.com/tracks/${match[1]}?disableAnalytics=true`;
    }

    return null;
  }

  get deezerUrl() {
    const regex = /^https?:\/\/(?:www\.)?deezer\.com\/track\/(\d+)/;
    const match = this.url.match(regex);

    if (match?.[1]) {
      return `https://widget.deezer.com/widget/auto/track/${match[1]}?tracklist=false`;
    }

    return null;
  }
}
