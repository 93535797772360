/* import __COLOCATED_TEMPLATE__ from './edit-schedule.hbs'; */
import Component from '@ember/component';
import { computed, set, setProperties, action } from '@ember/object';
import { later } from '@ember/runloop';
import {
  equal,
  filterBy,
  gt,
  notEmpty,
  or,
  not,
  mapBy,
} from '@ember/object/computed';
import { reject } from 'rsvp';
import { inject as service } from '@ember/service';
import NurtureCampaignSettingValidations from 'teamtailor/validations/nurture-campaign-settings';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

export default Component.extend({
  intl: service(),
  user: service(),
  flashMessages: service(),

  isSendTestModalOpen: false,

  NurtureCampaignSettingValidations,

  nurtureCampaign: null,
  statusOptions: Object.freeze([]),
  canSendTestEmails: not('missingSavedMessageSteps'),

  hasStatusOptions: notEmpty('statusOptions'),
  testEmailUsers: null,
  testEmailUsersError: null,
  testEmailUserEmails: mapBy('testEmailUsers', 'email'),

  saveButtonTitle: computed({
    get() {
      return this.intl.t('components.nurture_campaigns.save_campaign');
    },

    set(key, value) {
      return value;
    },
  }),

  onAfterSave() {},
  doOptionSelect() {},
  classNames: ['c-nurture-campaigns-edit-schedule'],
  classNameBindings: ['nurtureCampaign.hasPotentialToSpam:pb-60'],

  unsavedSteps: filterBy(
    'nurtureCampaign.sortedNurtureCampaignSteps',
    'isNewOrHasDirtyChangeset',
    true
  ),

  hasUnsavedSteps: gt('unsavedSteps.length', 0),

  savedSteps: filterBy(
    'nurtureCampaign.sortedNurtureCampaignSteps',
    'isNew',
    false
  ),

  hasSavedStep: gt('savedSteps.length', 0),

  savedMessageSteps: filterBy('nurtureCampaign.emailSteps', 'isNew', false),
  missingSavedMessageSteps: equal('savedMessageSteps.length', 0),

  disableSaveButtons: or('hasUnsavedSteps', 'missingSavedMessageSteps'),

  validateAndSave(changeset, { promise, saveAsDraft }) {
    return changeset.validate().then(() => {
      if (get(changeset, 'isValid')) {
        return changeset
          .save()
          .then(promise)
          .then(() => {
            later(() => {
              this.onAfterSave(this.nurtureCampaign, saveAsDraft);
            }, 500);
          });
      } else {
        return reject();
      }
    });
  },

  toggleTestUsers: action(function (user) {
    const exists = get(this, 'testEmailUsers').findBy('id', get(user, 'id'));

    if (exists) {
      get(this, 'testEmailUsers').removeObject(user);
    } else {
      get(this, 'testEmailUsers').addObject(user);
    }
  }),

  save: action(function (changeset) {
    return this.validateAndSave(changeset, {
      promise: () => {
        if (get(changeset, 'data.isDraft') || get(changeset, 'data.isTemp')) {
          return get(changeset, 'data').activate();
        } else {
          return true;
        }
      },
    });
  }),

  saveAsDraft: action(function (changeset) {
    return this.validateAndSave(changeset, {
      saveAsDraft: true,
      promise: () => {
        if (get(changeset, 'data.isDraft')) {
          return true;
        } else {
          return get(changeset, 'data').saveAsDraft();
        }
      },
    });
  }),

  goBack: action(function () {
    window.history.back();
  }),

  openSendTestModal: action(function () {
    set(this, 'testEmailUsers', [get(this, 'user')]);
    set(this, 'isSendTestModalOpen', true);
  }),

  cancelTestCampaign: action(function () {
    setProperties(this, {
      testEmailUsers: null,
      isSendTestModalOpen: false,
    });
  }),

  sendTestCampaign: action(function () {
    let emails = get(this, 'testEmailUserEmails');
    const nurtureCampaign = get(this, 'nurtureCampaign');

    if (isEmpty(emails)) {
      set(this, 'testEmailUsersError', [
        {
          message: this.intl.t(
            'components.nurture_campaign_schedule.at_least_one_recipient'
          ),
        },
      ]);
    }

    return nurtureCampaign.sendTest(emails).then(() => {
      get(this, 'flashMessages').success(
        this.intl.t('components.nurture_campaign_schedule.test_emails_sent')
      );

      setProperties(this, {
        testEmailUsers: null,
        isSendTestModalOpen: false,
      });
    });
  }),
});
