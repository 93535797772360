/* import __COLOCATED_TEMPLATE__ from './hiring-team.hbs'; */
import ArrayProxy from '@ember/array/proxy';
import CurrentService from 'teamtailor/services/current';
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import PermissionsService from 'teamtailor/services/permissions';
import JobDetailModel from 'teamtailor/models/job-detail';
import JobModel from 'teamtailor/models/job';
import TeamModel from 'teamtailor/models/team';
import CompanyModel from 'teamtailor/models/company';
import UserModel from 'teamtailor/models/user';
import RecruitingFirmModel from 'teamtailor/models/recruiting-firm';
import StageModel from 'teamtailor/models/stage';
import UserStageRestrictionModel from 'teamtailor/models/user-stage-restriction';
import { tracked } from '@glimmer/tracking';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import uniqBy from 'teamtailor/utils/uniq-by';
import Store from '@ember-data/store';

type HiringTeamArgs = {
  jobDetail: JobDetailModel;
  showHiringTeamModal: boolean;
  closeHiringTeamModal: () => boolean;
  userStageRestriction: UserStageRestrictionModel;
};

export default class HiringTeamComponent extends Component<HiringTeamArgs> {
  @service declare current: CurrentService;
  @service declare permissions: PermissionsService;
  @service declare store: Store;

  @tracked recruitingFirmRecruiters: UserModel[] = [];
  @tracked activeTab = 'employees';

  constructor(owner: unknown, args: HiringTeamArgs) {
    super(owner, args);

    if (this.company.hasFeature('external_recruitment')) {
      this.updateRecruitingFirmRecruiters.perform();
    }
  }

  @action
  handleTabClick(tab: string) {
    this.activeTab = tab;
  }

  get company(): CompanyModel {
    return this.current.company;
  }

  get jobDetail(): JobDetailModel {
    return get(this.args, 'jobDetail');
  }

  get job(): JobModel {
    return get(this.jobDetail, 'job');
  }

  get canManageHiringTeam(): boolean {
    return !!this.permissions.has('job/update');
  }

  get teamMembers() {
    const hiringTeam = get(this.job, 'teamMembersAndRecruiter').sortBy(
      'nameOrEmail'
    );

    const recruiter = get(this.job, 'recruiter');

    return uniqBy(
      [recruiter, ...hiringTeam, ...this.recruitingFirmRecruiters].filter(
        Boolean
      ),
      'id'
    );
  }

  get additionalUsers() {
    const recruiter = get(this.job, 'recruiter');
    const hiringTeam = get(this.job, 'teamMembers')
      .filter(
        (user) => !user.externalRecruiter && user.id !== get(recruiter, 'id')
      )
      .toSorted((userA, userB) =>
        get(userA, 'nameOrEmail').localeCompare(get(userB, 'nameOrEmail'))
      );

    return [recruiter, ...hiringTeam.slice()];
  }

  get jobTeamUsers() {
    const users = get(this.job.team, 'users');
    const manager = get(this.job.team, 'user');

    return uniqBy([...users.slice(), manager].filter(Boolean), 'id');
  }

  get recruitingFirmsText() {
    if (!this.job.recruitingFirms.length) {
      return null;
    }

    const recruitingFirmNames = this.job.recruitingFirms.mapBy('name');
    return recruitingFirmNames.join(', ');
  }

  updateRecruitingFirmRecruiters = restartableTask(async () => {
    this.recruitingFirmRecruiters = [];

    const recruitingFirms: ArrayProxy<RecruitingFirmModel> =
      await this.job.recruitingFirms;

    recruitingFirms.forEach((recruitingFirm: RecruitingFirmModel) => {
      this.recruitingFirmRecruiters.addObjects(recruitingFirm.recruiters);
    });
  });

  handleRecruitingFirmChange = restartableTask(
    async (recruitingFirm: RecruitingFirmModel) => {
      const recruitingFirms = get(this.job, 'recruitingFirms');
      toggleInList(recruitingFirms, recruitingFirm);
      await this.job.save();
      await this.updateRecruitingFirmRecruiters.perform();
    }
  );

  @action
  onTeamMemberChange(user: UserModel) {
    const teamMembers = get(this.job, 'teamMembers');
    toggleInList(teamMembers, user);
    this.job.save();
  }

  onTeamChange = restartableTask(async (team: TeamModel | null) => {
    set(this.jobDetail, 'team' as keyof JobDetailModel, team);
    set(this.job, 'team' as keyof JobModel, team);

    await this.job.save();
  });

  @action
  async handleStageRestriction(user: UserModel, stage: StageModel) {
    const { userStageRestrictions } = user;
    const existingRestriction = userStageRestrictions.find(
      (restriction: UserStageRestrictionModel) =>
        get(restriction.stage, 'id') === stage.id
    );

    if (existingRestriction) {
      const restrictionRecord = await this.store.findRecord(
        'user-stage-restriction',
        existingRestriction.id,
        {
          backgroundReload: false,
        }
      );
      await restrictionRecord.destroyRecord();
    } else {
      const newRestriction = this.store.createRecord('user-stage-restriction', {
        user: user,
        stage: stage,
      });
      await newRestriction.save();
    }
  }
}
