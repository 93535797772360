/* import __COLOCATED_TEMPLATE__ from './two-factor-code-input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

interface TwoFactorCodeInputArgs {
  onCodeChange?: (
    code: string,
    resetCallback: (refocus?: boolean) => void
  ) => void;
  onHandleSuccess?: () => void;
  onHandleCloseModal?: () => void;
  inputSize?: 'small' | 'large';
}

export default class TwoFactorCodeInput extends Component<TwoFactorCodeInputArgs> {
  @tracked values = ['', '', '', '', '', ''];
  @tracked declare containerRef: HTMLElement | null;

  findAndFocus2fa = modifier((inputElement: HTMLElement) => {
    // Delay the visual cue
    setTimeout(() => {
      inputElement.focus();
    }, 500);
  });

  willDestroy(): void {
    super.willDestroy();
    this.values = ['', '', '', '', '', ''];
  }

  get inputs(): Array<HTMLInputElement> {
    return Array.from(this.containerRef?.getElementsByTagName('input') ?? []);
  }

  get inputSize(): typeof this.args.inputSize {
    return this.args.inputSize ?? 'small';
  }

  resetCallback = (refocus = false) => {
    this.values = ['', '', '', '', '', ''];
    this.inputs.slice(1).invoke('setAttribute', 'disabled', 'disabled');

    if (refocus) {
      this.inputs[0]?.focus();
    } else if (this.args.onHandleSuccess) {
      this.args.onHandleSuccess();
    }
  };

  private doCallback = () => {
    if (this.args.onCodeChange) {
      this.args.onCodeChange(this.values.join(''), this.resetCallback);
    }
  };

  @action
  storeContainerRef(element: HTMLElement) {
    this.containerRef = element;
  }

  // Only applies to the first render
  @action
  isDisabled(index: number) {
    return index !== 0 && this.values[index - 1] === '';
  }

  @action
  handleKeyUp(index: number, event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    if (event.key === 'Backspace') {
      const prevTarget = this.inputs.at(index - 1);
      if (prevTarget && prevTarget.value !== '') {
        this.values[index - 1] = '';
        prevTarget.value = '';
        prevTarget.focus();
      }
    }

    if (target.value !== '') {
      const { value } = target;
      this.values[index] = value.charAt(0);

      const nextInput = this.inputs.at(index + 1);
      if (nextInput) {
        nextInput.removeAttribute('disabled');
        nextInput.focus();
      }
    }

    this.doCallback();
  }

  @action
  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const paste = event.clipboardData?.getData('text');

    const slicedPaste = paste?.slice(0, 6);

    if (slicedPaste && /^[0-9]{1,6}$/.test(slicedPaste)) {
      this.values = slicedPaste.split('');
    }

    this.doCallback();
  }

  @action
  handleInput(event: Event) {
    const target = event.target as HTMLInputElement;

    // Probably an autofill from password manager
    // Autofill is done as soon as an input gets focus
    if (target.value.length === 6) {
      this.values = target.value.split('');
      this.doCallback();
    }

    if (!/^\d$/.test(target.value)) {
      target.value = '';
    }
  }
}
