/* import __COLOCATED_TEMPLATE__ from './html-button-or-link.hbs'; */
import Component from '@glimmer/component';
import Model from '@ember-data/model';
import { argDefault } from 'teamtailor/utils/arg-default';

type SharedArgs = {
  icon: string;
  disabled?: boolean;
  appearAsText?: boolean;
};

type LinkToArgs = {
  linkToRoute?: string;
  models?: Model[];
  query?: string;
  state?: string;
  active?: boolean;
};

type AnchorArgs = {
  href?: string;
};

type ButtonArgs = {
  typeAttr?: 'button' | 'submit';
};

export type Args = (LinkToArgs | AnchorArgs | ButtonArgs) & SharedArgs;

export default class HtmlButtonOrLinkComponent extends Component<Args> {
  @argDefault appearAsText = true;

  get typeAttr() {
    return ('typeAttr' in this.args && this.args.typeAttr) ?? 'button';
  }
}
