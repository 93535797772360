/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import ENV from 'teamtailor/config/environment';
import DropdownMenu, {
  CoreDropdownMenuApi,
} from 'teamtailor/components/core/dropdown-menu';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty, isNone } from '@ember/utils';
import { argDefault } from 'teamtailor/utils/arg-default';
import { restartableTask } from 'ember-concurrency';
import fuzzysort from 'fuzzysort';
import { inject as service } from '@ember/service';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { SUPPORTED_OPTION_TYPES } from 'teamtailor/constants/core/select';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';
import Model, { AsyncBelongsTo } from '@ember-data/model';
import { isObjectWithKey } from 'teamtailor/utils/type-utils';
import ObjectProxy from '@ember/object/proxy';
import { debounce } from '@ember/runloop';
import { justAssert } from 'teamtailor/utils/justAssert';
import { consume } from 'ember-provide-consume-context';
import { SelectScrollWrapperCtx } from './dropdown/select-scroll-wrapper';

type OptionTypes = 'user' | 'tag' | 'question' | 'candidate' | 'recipient';

type CoreSelectArgs = {
  closeOnSelect?: boolean;
  searchEnabled?: boolean;
  searchField?: string;
  onSearch?: (searchTerm: string | null) => Promise<void>;
  onSelect?: (
    option: Option,
    event?: MouseEvent
  ) => void | { closeOnSelect?: boolean };
  optionName?: string;
  optionType?: OptionTypes | { type: OptionTypes; field?: string };
  optionCompareField?: OptionTypes | { type: OptionTypes; field?: string };
  options: Option[];
  placeholder?: string;
  nullOption?: string;
  text?: string;
  searchOnOpen?: boolean;
  showSelected?: boolean;
  clearSearchOnSelect?: boolean;
  iconOnly?: boolean;
  isSubmenu?: boolean;
  wrapDescription?: boolean;
  dropdownWidth?: number;
  dropdownMinWidth?: number;
  virtualizeList?: boolean;
  pinSelected?: boolean;
  isLoading?: boolean;
  selectedIndexes?: number[];
  onClose?: () => void;
} & (
  | {
      isMultiple: true;
      selected?: Option[];
    }
  | {
      isMultiple?: false;
      selected?: Option;
    }
);

type OptionGroup = {
  groupName: 'hidden-group-name' | (string & Record<string, unknown>);
  options: Option[];
};

export type Option =
  | {
      value: string;
      label: string;
    }
  | OptionGroup
  | Model
  | AsyncBelongsTo<Model>
  | string
  | number;

type VerticalCollectionApi = { scrollToItem: (index: number) => void };

const findIndex = <ItemType>(
  array: ItemType[],
  callback: (item: ItemType) => boolean
) => {
  return Array.prototype.findIndex.call(array, callback);
};

export type CoreSelectApi = CoreDropdownMenuApi & {
  verticalCollectionApi?: VerticalCollectionApi;
};

export default class CoreSelect extends DropdownMenu<CoreSelectArgs> {
  @service declare intl: IntlService;

  declare verticalCollectionApi?: VerticalCollectionApi;

  @tracked searchTerm: string | null = null;
  @tracked showLoading = false;
  @tracked mayHaveWrongScrollPos = true;
  @tracked lastVisibleChangedCount = 0;
  @tracked nullOptionSelected = false;

  @argDefault searchOnOpen = false;

  @consume('select-scroll-wrapper')
  declare selectScrollWrapperCtx?: SelectScrollWrapperCtx;

  get isLoading() {
    if (!isNone(this.args.isLoading)) {
      return this.args.isLoading;
    }

    return !!(
      this.selectScrollWrapperCtx?.searchTask.isRunning ||
      this.selectScrollWrapperCtx?.fetchPageTask.isRunning
    );
  }

  get hideContainer() {
    return this.virtualizeList && this.mayHaveWrongScrollPos;
  }

  get api(): CoreSelectApi {
    const parentThis = this;
    return {
      ...super.api,
      get verticalCollectionApi() {
        return parentThis.verticalCollectionApi;
      },
    };
  }

  get nullOptionText() {
    return typeof this.args.nullOption === 'string'
      ? this.args.nullOption
      : this.intl.t('core.select.none');
  }

  get selectedIndexes() {
    if (this.args.selected === undefined) {
      return [];
    }

    return (
      this.args.isMultiple
        ? this.args.selected.map(this.indexFromOption)
        : [this.indexFromOption(this.args.selected)]
    ).filter((val) => !isNone(val)) as number[];
  }

  get containerDomItemsReady() {
    return !this.virtualizeList || this.lastVisibleChangedCount > 0;
  }

  get dropdownMinWidth() {
    return (this.args.dropdownMinWidth ?? this.virtualizeList)
      ? 250
      : undefined;
  }

  get wrapDescription() {
    return (
      this.args.wrapDescription ??
      (!this.args.dropdownWidth && !this.args.dropdownMinWidth)
    );
  }

  get virtualizeList() {
    return (
      this.args.virtualizeList ??
      (ENV.environment === 'test' ? false : this.filteredOptions.length > 15)
    );
  }

  get closeOnSelect() {
    return this.args.closeOnSelect ?? (this.args.isMultiple ? false : true);
  }

  get searchEnabled() {
    return !!(
      this.args.searchEnabled ??
      this.args.onSearch ??
      (this.searchField &&
        (this.flatOptionsWithoutGroup.length > 8 || !isEmpty(this.searchTerm)))
    );
  }

  get optionType() {
    const optionType =
      typeof this.args.optionType === 'string'
        ? this.args.optionType
        : this.args.optionType?.type;

    verifyArg(optionType, SUPPORTED_OPTION_TYPES, 'Select @optionType');

    return optionType;
  }

  get optionTypeField() {
    return typeof this.args.optionType === 'object'
      ? this.args.optionType.field
      : null;
  }

  get selectedUser() {
    if (
      this.hasSelectedValue &&
      (this.optionType === 'user' || this.optionType === 'recipient')
    ) {
      if (this.args.isMultiple) {
        return this.args.selected?.map(this.userFromOption);
      } else {
        return this.userFromOption(this.args.selected);
      }
    }

    return null;
  }

  get selectedCandidate() {
    if (this.hasSelectedValue && this.optionType === 'candidate') {
      if (this.args.isMultiple) {
        return this.args.selected?.map(this.candidateFromOption);
      } else {
        return this.candidateFromOption(this.args.selected);
      }
    }

    return null;
  }

  get placeholder() {
    return (
      this.args.placeholder ??
      (this.args.nullOption && typeof this.args.nullOption === 'string'
        ? this.args.nullOption
        : null) ??
      this.intl.t('core.select.default_placeholder')
    );
  }

  get hasSelectedValue() {
    return !isEmpty(this.args.selected);
    /**
     * This part will check if the options selected are in the list
     * of options. The previous code in this function was supposed to
     * do that, but was only checking if the options in selected existed
     * in selected array.
     *
     * The code below will check if the selected options are in the options
     * list, but at the time, too many places are failing because of
     * proxies and selected values not being in the options list.
     */
    /* const hasSelectedOption = this.args.options.some((option) =>
      this.optionIsSelected(option)
    );

    return hasSelectedOption; */
  }

  get buttonText() {
    if (!isNone(this.args.text)) {
      return this.args.text;
    }

    if (this.optionType === 'user') {
      // we send in @user to Button, let it handle text for user
      return null;
    }

    if (this.optionType === 'recipient') {
      // we send in @recipient to Button, let it handle text for recipient
      return null;
    }

    if (this.optionType === 'tag') {
      // we send in @tag to Button, let it handle text for tag
      return null;
    }

    if (this.optionType === 'candidate') {
      // we send in @candidate to Button, let it handle text for user
      return null;
    }

    if (this.args.isMultiple && this.args.showSelected) {
      return this.args.selected?.map((option) => this.textFromOption(option));
    } else if (this.args.isMultiple) {
      return this.intl.t('core.select.multiple_has_selected_text', {
        count: this.args.selected?.length || 0,
      });
    } else {
      return !isNone(this.args.selected)
        ? this.textFromOption(this.args.selected)
        : this.nullOptionText;
    }
  }

  get optionCompareField() {
    return (this.args.optionCompareField ??
      (this.optionType &&
        ['recipient', 'user', 'tag', 'question', 'candidate'].includes(
          this.optionType
        )))
      ? 'id'
      : undefined;
  }

  get flatOptionsWithoutGroup() {
    const optionsArray = this.args.options.slice();

    return optionsArray
      .map((val) => {
        return isObjectWithKey(val, 'options') &&
          isObjectWithKey(val, 'groupName')
          ? val.options
          : val;
      })
      .flat();
  }

  get filteredOptionsSelectedPinned() {
    if (this.selectedIndexes.length === 0) {
      return this.filteredOptionsSelectedNotPinned;
    }

    let selected = [] as Option[];

    if (!this.searchTerm) {
      selected = Array.isArray(this.args.selected)
        ? this.args.selected
        : [this.args.selected].filter(Boolean);
    }

    const notSelected = [] as Option[];
    this.filteredOptionsSelectedNotPinned.forEach((val, i) => {
      if (this.selectedIndexes.includes(i)) {
        if (this.searchTerm) {
          selected.push(val);
        }
      } else {
        notSelected.push(val);
      }
    });

    const out = [] as OptionGroup[];

    if (selected.length) {
      out.push({
        groupName: 'hidden-group-name',
        options: selected,
      });
    }

    out.push({
      groupName: 'hidden-group-name',
      options: notSelected,
    });

    return out;
  }

  get filteredOptions() {
    if (this.args.pinSelected !== undefined) {
      return this.args.pinSelected
        ? this.filteredOptionsSelectedPinned
        : this.filteredOptionsSelectedNotPinned;
    }

    return this.filteredOptionsSelectedNotPinned;
  }

  get filteredOptionsSelectedNotPinned() {
    if (!this.searchEnabled || this.args.onSearch || isEmpty(this.searchTerm)) {
      return this.args.options;
    } else {
      const fuzzyOptions: {
        threshold: number;
        keys?: string[];
      } = {
        threshold: -10000,
      };

      let flatResult: unknown[] = [];

      if (this.searchField) {
        flatResult = fuzzysort
          .go<Option>(this.searchTerm || '', this.flatOptionsWithoutGroup, {
            ...fuzzyOptions,
            keys: [this.searchField, 'groupName'],
          })
          .map((val) => get(val, 'obj' as keyof typeof val));
      } else {
        flatResult = fuzzysort
          .go(
            this.searchTerm || '',
            this.flatOptionsWithoutGroup as string[],
            fuzzyOptions
          )
          .map((val) => get(val, 'target' as keyof typeof val));
      }

      return this.args.options.reduce<Option[]>((acc, val) => {
        let out = val;
        if (
          isObjectWithKey(out, 'options') &&
          isObjectWithKey(out, 'groupName')
        ) {
          out = { ...out }; // TODO: refactor this to not spread objects in `@options`?
          out.options = out.options.filter((option) => {
            return flatResult.includes(option);
          });
          if (out.options.length) {
            acc.push(out);
          }
        } else if (flatResult.includes(out)) {
          acc.push(out);
        }

        return acc;
      }, []);
    }
  }

  get optionName() {
    if (this.args.optionName !== undefined) {
      return this.args.optionName;
    }

    if (this.optionType === 'user') {
      return 'nameOrEmail';
    }

    if (this.optionType === 'recipient') {
      return 'nameOrEmail';
    }

    if (this.optionType === 'candidate') {
      return 'nameOrEmail';
    }

    if (isObjectWithKey(this.args.options[0], 'label')) {
      return 'label';
    }
  }

  get searchField() {
    if (!isNone(this.args.searchField)) {
      return this.args.searchField;
    }

    if (this.optionTypeField) {
      return `${this.optionTypeField}.${this.optionName}`;
    }

    if (this.optionName) {
      return this.optionName;
    }
  }

  get text() {
    if (this.args.iconOnly) {
      return undefined;
    }

    return this.hasSelectedValue ? this.buttonText : this.placeholder;
  }

  onOpen() {
    if (this.searchOnOpen) {
      this.searchTask.perform();
    }

    super.onOpen();
  }

  @action
  async scrollToFirstSelected() {
    if (this.selectedIndexes[0] !== undefined && this.verticalCollectionApi) {
      justAssert(this.verticalCollectionApi);
      await this.verticalCollectionApi.scrollToItem(
        Math.max(this.selectedIndexes[0] - 2, 0)
      );
    }

    if (this.mayHaveWrongScrollPos) {
      this.mayHaveWrongScrollPos = false;
    }
  }

  @action
  textFromOption(option: Option) {
    return this.optionName && typeof option === 'object'
      ? get(option, this.optionName as keyof typeof option)
      : option;
  }

  @action
  indexFromOption(indexOption: Option) {
    let index = this.args.options.indexOf(indexOption);

    if (index === -1) {
      index = findIndex(this.args.options, (option) => {
        return (
          this.optionToCompare(option) === this.optionToCompare(indexOption)
        );
      });
    }

    return (index || 0) < 0 ? null : index;
  }

  @action
  firstVisibleChanged() {
    if (this.containerApi) {
      debounce(this.containerApi, 'updatePosition', 150);
    }
  }

  @action
  lastVisibleChanged() {
    this.lastVisibleChangedCount++;
    if (this.containerApi) {
      debounce(this.containerApi, 'updatePosition', 150);
    }
  }

  @action
  userFromOption(option?: Option) {
    if (this.optionType === 'user' || this.optionType === 'recipient') {
      return this.optionTypeModelFromOption(option);
    }

    return null;
  }

  @action
  candidateFromOption(option?: Option) {
    if (this.optionType === 'candidate') {
      return this.optionTypeModelFromOption(option);
    }

    return null;
  }

  @action
  optionTypeModelFromOption(option?: Option) {
    const optionUnwrapped = this.unwrapProxyIfNeeded(option);

    return this.optionTypeField
      ? get(
          optionUnwrapped,
          this.optionTypeField as keyof typeof optionUnwrapped
        )
      : optionUnwrapped instanceof Model
        ? optionUnwrapped
        : undefined;
  }

  @action
  unwrapProxyIfNeeded(option?: Option) {
    if (option instanceof ObjectProxy) {
      // @ts-expect-error this is needed for tracking on PromiseObject to work.
      // When we have migrated away from PromiseObject we can probably remove this
      option.foobar;
    }

    return option instanceof ObjectProxy ? get(option, 'content') : option;
  }

  @action
  optionToCompare(option?: Option) {
    const optionModel = this.optionTypeModelFromOption(option);

    const optiontoCompare =
      this.optionCompareField && optionModel
        ? (get(optionModel, this.optionCompareField) as Option | undefined)
        : isObjectWithKey(option, 'value')
          ? option.value
          : option;

    return this.unwrapProxyIfNeeded(optiontoCompare);
  }

  @action
  optionIsSelected(option?: Option) {
    const optionToCompare = this.optionToCompare(option);

    if (this.args.isMultiple) {
      const selectedToCompare = this.args.selected?.map(this.optionToCompare);
      return !!selectedToCompare?.includes(optionToCompare);
    } else {
      const selectedToCompare = this.optionToCompare(this.args.selected);

      return (
        !isNone(selectedToCompare) && selectedToCompare === optionToCompare
      );
    }
  }

  @action
  async handleSelect(option: Option, event: MouseEvent) {
    const onSelectReturn = await this.args.onSelect?.(option, event);

    const shouldClose = isObjectWithKey(onSelectReturn, 'closeOnSelect')
      ? onSelectReturn.closeOnSelect
      : this.closeOnSelect;

    if (shouldClose) {
      this.close();
    }

    if (this.args.isSubmenu) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.args.clearSearchOnSelect) {
      this.searchTerm = null;
    }
  }

  @action
  close() {
    super.close();
    this.searchTerm = null;

    this.lastVisibleChangedCount = 0;
    this.mayHaveWrongScrollPos = true;
  }

  @action
  handleIsLoadingChange(isLoading: boolean) {
    if (isLoading) {
      this.lastVisibleChangedCount = 0;
    }
  }

  @action
  handleSearch(event: KeyboardEvent) {
    const value =
      event.target instanceof HTMLInputElement ? event.target.value : null;
    this.searchTerm = value;

    if (this.args.onSearch) {
      this.searchTask.perform(value);
    }
  }

  searchTask = restartableTask(async (newSearchTerm = this.searchTerm) => {
    if (newSearchTerm !== this.searchTerm || this.args.options.length === 0) {
      this.showLoading = true;
    }

    this.searchTerm = newSearchTerm;

    if (this.args.onSearch) {
      await this.args.onSearch(this.searchTerm);
    }

    this.showLoading = false;
  });
}
