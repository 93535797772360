/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { argDefault } from 'teamtailor/utils/arg-default';
import { maxImageFileSize } from 'teamtailor/constants/uploads';
import DirectUploadService from 'teamtailor/services/direct-upload';
import IntlService from 'ember-intl/services/intl';
import FileQueue from 'ember-file-upload/services/file-queue';
import { UploadFile } from 'ember-file-upload';
import Store from '@ember-data/store';
import TagModel from 'teamtailor/models/tag';
import { task } from 'ember-concurrency';
import ImageModel from 'teamtailor/models/image';
import FlashMessageService from 'teamtailor/services/flash-message';
import { modifier } from 'ember-modifier';

interface Args {
  onImageUpload(image: ImageModel): void;
  tag?: TagModel;
  theme?: string;
}

type ImageDimensions = { width: number; height: number };

export default class MediaLibraryUploaderComponent extends Component<Args> {
  @service declare store: Store;
  @service declare fileQueue: FileQueue;
  @service declare directUpload: DirectUploadService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @argDefault theme = 'default';

  readonly queueName = 'mediaLibrary';
  declare fileUploadElement: HTMLLabelElement;

  registerWindowCallback = modifier((element: HTMLLabelElement) => {
    this.fileUploadElement = element;
    window.addEventListener('media-library-upload', this.openFileUpload);
  });

  willDestroy(): void {
    super.willDestroy();
    window.removeEventListener('media-library-upload', this.openFileUpload);
  }

  get queueProgress(): string | undefined {
    return this.fileQueue.find(this.queueName)?.progress.toString();
  }

  get queueLength(): number {
    return this.fileQueue.find(this.queueName)?.files.length ?? 0;
  }

  openFileUpload = () => {
    this.fileUploadElement.click();
  };

  startTask = task(async (upload: UploadFile) => {
    const dimensions = await this.resolveImageDimension.perform(upload);
    const url = await this.uploadToS3.perform(upload);

    const image = this.store.createRecord('image', {
      s3Image: url,
      filename: upload.name,
      width: dimensions.width,
      height: dimensions.height,
      createdAt: new Date(),
    });

    const { tag } = this.args;
    if (tag) {
      image.tags.addObject(tag);
    }

    await this.saveImage.perform(image).then((model: ImageModel) => {
      this.args.onImageUpload(model);
      this.flashMessages.success(
        this.intl.t('components.media_library.image_uploaded', {
          file: upload.name,
        })
      );
    });
  });

  resolveImageDimension = task(async (upload: UploadFile) => {
    const dataUrl = await upload.readAsDataURL();

    const dimensions = await new Promise<ImageDimensions>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };

      if (typeof dataUrl === 'string') {
        img.src = dataUrl;
      }
    });

    return dimensions;
  });

  uploadToS3 = task(async (blob: UploadFile) => {
    const url = await this.directUpload.s3(blob, {
      model: 'images',
      filename: `original.${blob.extension}`,
    });
    return url;
  });

  saveImage = task({ enqueue: true }, async (image: ImageModel) => {
    const record = await image.save();
    return record;
  });

  @action
  onFileAdded(upload: UploadFile): void {
    if (upload.size > maxImageFileSize) {
      this.flashMessages.error(
        this.intl.t('components.media_library.file_too_big', {
          file: upload.name,
        })
      );
      this.fileQueue.find(this.queueName)?.remove(upload);
    } else {
      this.startTask.perform(upload);
    }
  }

  @action
  handleFileDrop(files: UploadFile[]): UploadFile[] {
    return files.filter((file) => {
      return file.type.match(/(image)[^/]*/);
    });
  }
}
