/* import __COLOCATED_TEMPLATE__ from './local-participant.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { bind } from '@ember/runloop';
import {
  LocalParticipant,
  LocalAudioTrack,
  LocalVideoTrack,
} from 'twilio-video';
import { AudioVideoFacade } from 'amazon-chime-sdk-js';
import {
  AteendeeIdsToTilesMap,
  MeetingAttendee,
  bindChimeTileToVideoElement,
} from 'teamtailor/utils/video-meetings/utils';

interface VideoMeetingLocalParticipantArgs {
  audio: boolean;
  audioTrack: LocalAudioTrack | null;
  videoTrack: LocalVideoTrack | MediaStream | null;
  participant: LocalParticipant | MeetingAttendee;
  style: string;
  video: boolean;
  // Chime
  isNewProvider: boolean;
  audioVideoFacade?: AudioVideoFacade;
  tilesMap?: AteendeeIdsToTilesMap;
}

export default class VideoMeetingLocalParticipant extends Component<VideoMeetingLocalParticipantArgs> {
  get participant(): LocalParticipant | MeetingAttendee {
    return this.args.participant;
  }

  get name(): string {
    if (this.args.isNewProvider) {
      return (
        (<MeetingAttendee>this.participant).externalUserId.split('--')[0] || ''
      );
    } else {
      return (<LocalParticipant>this.participant).identity.split('--')[0] || '';
    }
  }

  onDimensionsChanged = (trackElement: HTMLElement, track: LocalVideoTrack) => {
    {
      const ratio =
        (track.dimensions.width || 1) / (track.dimensions.height || 1);

      if (ratio >= 1) {
        trackElement.classList.add('object-cover');
      } else {
        trackElement.classList.remove('object-cover');
      }

      trackElement.style.aspectRatio = `${track.dimensions.width} / ${track.dimensions.height}`;
    }
  };

  addVideoTrack = (element: HTMLElement): void => {
    const { videoTrack } = this.args;
    if (!videoTrack) return;

    if (this.args.isNewProvider) {
      if (this.args.audioVideoFacade && this.args.tilesMap) {
        this.args.audioVideoFacade.startLocalVideoTile();

        const participantTileId =
          this.args.tilesMap[(<MeetingAttendee>this.participant).attendeeId];

        if (participantTileId) {
          bindChimeTileToVideoElement(
            element,
            this.args.audioVideoFacade,
            (<MeetingAttendee>this.participant).attendeeId,
            participantTileId
          );
        }
      }
    } else {
      const trackElement = (<LocalVideoTrack>videoTrack).attach();
      trackElement.style.transform = 'scale(-1, 1)';
      trackElement.classList.add('w-full', 'h-full', 'object-cover');

      (<LocalVideoTrack>videoTrack).on(
        'dimensionsChanged',
        bind(this, (track: LocalVideoTrack) =>
          this.onDimensionsChanged(trackElement, track)
        )
      );
      this.onDimensionsChanged(trackElement, <LocalVideoTrack>videoTrack);

      element.appendChild(trackElement);
    }
  };

  @action
  handleVideoDimensionsChanged(event: Event) {
    if (this.args.isNewProvider) {
      const { videoWidth, videoHeight } = <HTMLVideoElement>event.target;
      if (videoHeight && videoWidth) {
        const ratio = videoWidth / videoHeight;

        if (ratio >= 1) {
          (<HTMLVideoElement>event.target).classList.add('object-cover');
        } else {
          (<HTMLVideoElement>event.target).classList.remove('object-cover');
        }

        (<HTMLVideoElement>event.target).style.aspectRatio =
          `${videoWidth} / ${videoHeight}`;
      }
    }
  }

  @action
  onAddVideoTrack(element: HTMLElement): void {
    this.addVideoTrack(element);
  }
}
