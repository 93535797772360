/* import __COLOCATED_TEMPLATE__ from './recipient-picker.hbs'; */
import Component from '@glimmer/component';
import { enqueueTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';
import { isEmpty } from '@ember/utils';
import Store from '@ember-data/store';
import { UserModel } from 'teamtailor/models';
import uniq from 'teamtailor/utils/uniq';

export type ExternalEmail = {
  id: string;
  nameOrEmail: string;
};

export type Recipient = UserModel | ExternalEmail;

type RecipientArray = Recipient[] & {
  meta?: {
    count: number;
    total_count: number;
    total_pages: number;
  };
};

type RecipientPickerArgs = {
  selected?: Recipient | Recipient[];
  isMultiple?: boolean;
  onSelect: (tag: Recipient) => void;
  isSubmenu?: boolean;
  onClose?: () => void;
  role?: string;
  showSelected?: boolean;
  selectedOnTop?: boolean;
  matchTriggerWidth?: boolean;
  minimumSelectionRequired?: boolean;
  searchParams?: Record<string, unknown>;
};

export default class CoreRecipientPickerComponent extends Component<RecipientPickerArgs> {
  @service declare store: Store;

  declare dropdownApi?: { close(): void };

  @tracked _selectedRecipients: Recipient[] = [];
  @tracked searchTerm: string | null = null;

  @argDefault showSelected = true;
  @argDefault selectedOnTop = true;
  @argDefault minimumSelectionRequired = false;

  get selectedRecipientIds() {
    return this.selectedRecipients.map((recipient) => get(recipient, 'id'));
  }

  get selectedUserIds() {
    return this.selectedRecipients
      .map((user) => (user instanceof UserModel ? get(user, 'id') : null))
      .compact();
  }

  get selectedRecipients(): Recipient[] {
    return this.args.selected
      ? Array.isArray(this.args.selected)
        ? this.args.selected
        : [this.args.selected].compact()
      : this._selectedRecipients;
  }

  set selectedRecipients(recipients) {
    this._selectedRecipients = Array.isArray(recipients)
      ? recipients
      : [recipients];
  }

  @action
  isDisabled(option: Recipient) {
    return (
      this.minimumSelectionRequired &&
      this.selectedRecipientIds.length === 1 &&
      this.selectedRecipientIds.includes(option.id)
    );
  }

  @action
  handleRegisterApi(api: { close(): void }): void {
    this.dropdownApi = api;
  }

  @action
  handleCreate(arg: string | MouseEvent) {
    let value: string | null;

    if (arg instanceof MouseEvent) {
      value = this.searchTerm;
    } else {
      value = arg;
    }

    if (value) {
      const recipient: ExternalEmail = {
        id: value,
        nameOrEmail: value,
      };

      this.args.onSelect(recipient);
      this.dropdownApi?.close();
    }
  }

  fetchUsersTask = enqueueTask(async ({ searchTerm: query, page }) => {
    let exclude_ids = [...(!this.showSelected ? this.selectedUserIds : [])];
    this.searchTerm = query;
    if (isEmpty(query) && this.showSelected && this.selectedOnTop) {
      exclude_ids = uniq([...exclude_ids, ...this.selectedUserIds]);
    }

    const params = {
      ...(this.args.searchParams || {}),
      page: page || 0,
      per_page: 10,
      exclude_ids,
      query,
      role: this.args.role,
    };

    const users = await this.store.query('user', params);
    let recipients: RecipientArray | undefined;

    if (isEmpty(query) && this.showSelected && this.selectedOnTop) {
      recipients = [
        ...this.selectedRecipients
          .filterBy('nameOrEmail')
          .sortBy('nameOrEmail'),
        ...users.toArray(),
      ];
    } else {
      recipients = [...users.toArray()] as RecipientArray;
    }

    return recipients;
  });
}
