/* import __COLOCATED_TEMPLATE__ from './custom-report-table.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import SessionService from 'ember-simple-auth/services/session';
import Store from '@ember-data/store';
import CustomReportRequest from 'teamtailor/utils/custom-report-request';

interface CustomReportTableArgs {
  rows: any[];
  rollups: any[];
  rowLimit?: number;
  request: CustomReportRequest;
  isLoading: boolean;
  onUpdatePropertyRollup: (property: string, value: string) => void;
}

export default class CustomReportTableComponent extends Component<CustomReportTableArgs> {
  @service declare session: SessionService;
  @service declare store: Store;

  declare initialDataProperties: { name: string }[];

  get request(): CustomReportRequest {
    return this.args.request;
  }

  constructor(owner: unknown, args: CustomReportTableArgs) {
    super(owner, args);

    this.initialDataProperties = [...this.args.request.properties];
  }

  get properties() {
    return this.request.properties;
  }
}
