/* import __COLOCATED_TEMPLATE__ from './yearly-summary.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, get, set, setProperties } from '@ember/object';
import { camelize } from '@ember/string';
import { task } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { animate } from 'teamtailor/ember-smooth';

export default class YearlySummaryWidget extends WidgetClass {
  @service store;
  @service current;
  @service pusher;
  @service intl;
  @service bowser;

  get company() {
    return this.current.company;
  }

  year = 2022;
  npsPromoterComments = [];
  colorPickerClass = 'yearlySummaryColorPicker';

  @tracked originalNpsPickedComments = [];
  @tracked npsPickedComments = [];
  @tracked isActivating = false;
  @tracked isDeactivating = false;
  @tracked isTogglingNpsScore = false;
  @tracked isTogglingNpsComments = false;
  @tracked isTogglingReferral = false;
  @tracked isTogglingCustomColor = false;
  @tracked isNpsCommentsModalOpen = false;
  @tracked showAdditionalSettings = false;
  @tracked selectedColor = null;
  @tracked isCustomColorEnabled = get(
    this.current.company,
    'yearlySummaryCustomColor'
  );

  constructor() {
    super(...arguments);
    this.pusher
      .channel(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        set(this, 'channel', channel);
        channel.bind('yearly-summary-update', ({ status, url }) => {
          this.isActivating = false;
          setProperties(this.current.company, {
            yearlySummary: status,
            yearlySummaryUrl: url,
          });

          if (this.shouldOpenYearlyReview && !this.bowser.isSafari) {
            this.shouldOpenYearlyReview = false;
            if (status === 'activated') {
              this.openYearlySummaryUrl();
            }
          }
        });
      });
  }

  get user() {
    return this.current.user;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    let channel = get(this, 'channel');

    if (channel) {
      channel.unbind('yearly-summary-update');
    }
  }

  get isPreparing() {
    return get(this.current.company, 'yearlySummary') === 'preparing';
  }

  get isPrepared() {
    return get(this.current.company, 'yearlySummary') === 'prepared';
  }

  get showAsActivated() {
    return (
      get(this.current.company, 'yearlySummary') === 'activated' ||
      get(this.current.company, 'yearlySummary') === null
    );
  }

  get isNpsScoreEnabled() {
    return get(this.current.company, 'yearlySummaryNpsScore');
  }

  get isNpsCommentsEnabled() {
    return get(this.current.company, 'yearlySummaryNpsComments');
  }

  get isReferralEnabled() {
    return get(this.current.company, 'yearlySummaryReferral');
  }

  get url() {
    return get(this.current.company, 'yearlySummaryUrl');
  }

  get isActivationToggleDisabled() {
    return this.isActivating || this.isDeactivating || !get(this, 'user.admin');
  }

  get isNpsScoreToggleDisabled() {
    return this.isTogglingNpsScore || !get(this, 'user.admin');
  }

  get isNpsCommentsToggleDisabled() {
    return this.isTogglingNpsComments || !get(this, 'user.admin');
  }

  get isNpsFeatureEnabled() {
    return this.current.company.hasFeature('nps');
  }

  get showToggles() {
    return get(this, 'user.admin');
  }

  get isReferralToggleDisabled() {
    return this.isTogglingReferral || !get(this, 'user.admin');
  }

  get isCustomColorToggleDisabled() {
    return this.isTogglingCustomColor || !get(this, 'user.admin');
  }

  get areCommentsUnchanged() {
    if (isPresent(this.originalNpsPickedComments)) {
      return this.originalNpsPickedComments.every(
        (val, index) => val === this.npsPickedComments[index]
      );
    } else {
      return true;
    }
  }

  get pickerComponents() {
    return {
      interaction: {
        clear: false,
      },
    };
  }

  fetchNpsData = task({ drop: true }, async () => {
    await this.current.company.yearlySummaryNpsData().then((data) => {
      this.npsPromoterComments = data.comments;
      this.npsPickedComments = data.picked_comments;
      this.originalNpsPickedComments = data.picked_comments;
    });
  });

  deactivate() {
    this.isDeactivating = true;
    this.current.company.deactivateYearlySummary().then(() => {
      set(this.current.company, 'yearlySummary', 'prepared');
      this.isDeactivating = false;
    });
  }

  toggleOption(option) {
    this[`isToggling${camelize(option)}`] = true;
    this.current.company
      .toggleYearlySummaryOption({ toggle_type: option })
      .then((response) => {
        this.current.company.toggleProperty(`yearlySummary${camelize(option)}`);
        this[`isToggling${camelize(option)}`] = false;
        if (option === 'customColor') {
          this.isCustomColorEnabled =
            response.company.yearly_summary_custom_color;
          this.pickrInstance.setColor(response.company.yearly_summary_color);
        }
      });
  }

  @action handleButtonClick(e) {
    e.preventDefault();

    if (this.url) {
      this.openYearlySummaryUrl();
    } else {
      this.activate();
    }
  }

  @action
  activate(openYearlyReview = true) {
    this.shouldOpenYearlyReview = openYearlyReview;

    this.isActivating = true;
    this.current.company.activateYearlySummary();

    if (this.shouldOpenYearlyReview && this.bowser.isSafari) {
      this.shouldOpenYearlyReview = false;
      const id = setInterval(() => {
        if (
          this.url &&
          get(this.current.company, 'yearlySummary') === 'activated'
        ) {
          clearInterval(id);
          this.openYearlySummaryUrl();
        }
      }, 400);
    }
  }

  @action
  prepare() {
    set(this.current.company, 'yearlySummary', 'preparing');
    this.current.company.prepareYearlySummary();
  }

  @action
  toggleActivation() {
    this.showAsActivated ? this.deactivate(false) : this.activate(false);
  }

  @action
  toggleNpsScore() {
    this.toggleOption('npsScore');
  }

  @action
  toggleNpsComments() {
    this.toggleOption('npsComments');
  }

  @action
  toggleReferral() {
    this.toggleOption('referral');
  }

  @action
  toggleCustomColor() {
    this.toggleOption('customColor');
  }

  @action
  toggleNpsCommentsModalDisplay() {
    this.isNpsCommentsModalOpen = !this.isNpsCommentsModalOpen;
    this.fetchNpsData.perform();
  }

  @action
  shuffleComment(index) {
    let commentsList = this.npsPromoterComments.filter((comment) => {
      return !this.npsPickedComments.includes(comment);
    });
    let pickedCommentsCopy = this.npsPickedComments.compact();
    pickedCommentsCopy[index] =
      commentsList[Math.floor(Math.random() * commentsList.length)];
    set(this, 'npsPickedComments', pickedCommentsCopy);
  }

  @action
  updateNpsComments() {
    this.current.company
      .updateYearlySummaryNpsComments({
        pickedComments: this.npsPickedComments,
      })
      .finally(() => {
        this.isNpsCommentsModalOpen = !this.isNpsCommentsModalOpen;
      });
  }

  @action
  toggleSettings() {
    this.showAdditionalSettings = !this.showAdditionalSettings;
  }

  @action
  onColorPickerSave(color, instance) {
    this.selectedColor = color.toHEXA().toString();
    this.current.company.updateYearlySummaryColor({
      selectedColor: this.selectedColor,
    });
    instance.hide();
  }

  @action
  onInitPickr(instance) {
    this.pickrInstance = instance;
  }

  Y;

  @action
  onInsertColorPicker() {
    document.querySelector(`.${this.colorPickerClass} .pcr-save`).value =
      this.intl.t('dashboard.widgets.yearly_summary.toggle.set_color');
  }

  @action
  openYearlySummaryUrl() {
    if (this.url) window.open(this.url, '_blank');
  }

  @action onInsert(el) {
    animate(el, 'tada', {
      duration: 2800,
      delay: 2000,
    });
  }
}
