/* import __COLOCATED_TEMPLATE__ from './video.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class VideoWidget extends WidgetClass {
  @service declare current: CurrentService;

  get displayTitle(): boolean {
    return (
      get(this.widget, 'title') && get(this.widget, 'options.displayTitle')
    );
  }

  get displayContent(): boolean {
    return (
      get(this.widget, 'body') && get(this.widget, 'options.displayContent')
    );
  }

  get displayUserInfo(): boolean {
    return (
      get(this.widget, 'options.displayUserInfo') && get(this.widget, 'user')
    );
  }

  get hasContent(): boolean {
    return this.displayTitle || this.displayContent || this.displayUserInfo;
  }

  get autoplay(): boolean {
    return get(this.widget, 'options.autoplay');
  }

  get externalVideoId(): string | null {
    return get(this.widget, 'options.externalVideoId');
  }

  get id(): string | null {
    return get(this.widget, 'id');
  }

  get videoProvider(): string | null {
    return get(this.widget, 'options.videoProvider');
  }

  get isZiggeo(): boolean {
    return this.videoProvider === 'ziggeo';
  }
}
