/* import __COLOCATED_TEMPLATE__ from './stories-list.hbs'; */
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import { later, cancel } from '@ember/runloop';
import StoryModel from 'teamtailor/models/story';
import { EmberRunTimer } from '@ember/runloop/types';

const IMAGE_STORY_DURATION = 5000;

interface Args {
  stories: StoryModel[];
}

export default class StoriesList extends Component<Args> {
  @tracked progressBar: HTMLDivElement | null = null;
  @tracked activeStoryIndex = 0;
  @tracked muted = true;
  @tracked playing = true;
  @tracked shouldHaveTogglePlay = true;
  @tracked storyElements: {
    [index: number]: HTMLDivElement;
  } = {};

  @tracked storyInfos: {
    [index: number]: HTMLDivElement;
  } = {};

  storyLastStartTime = 0; // The time (milliseconds) from a story's duration at which it last started playing
  storyLastStartTimestamp: number | null = null; // The date timestamp of when the story last started playing
  breakLoop = false;
  laterCallback: EmberRunTimer | null = null;
  storyDurations: {
    [index: number]: number;
  } = {};

  @action
  handleDidInsertContent(el: HTMLDivElement, index: number) {
    this.storyElements[index] = el;
  }

  @action
  handleDidInsertProgressBar(el: HTMLDivElement) {
    // There's only 1 story and it is an image
    if (this.args.stories.length === 1 && !this.activeStoryIsVideo) {
      this.shouldHaveTogglePlay = false;
      el.style.display = 'none';
      return;
    }

    this.progressBar = <HTMLDivElement>el.firstElementChild?.firstElementChild;
  }

  @action
  handleDidInsertInfo(el: HTMLDivElement, index: number) {
    this.storyInfos[index] = el;
  }

  @action
  handleLoadedVideoMetaData(index: number, e: Event) {
    const videoDuration = (<HTMLVideoElement>e.target).duration;
    this.storyDurations[index] = videoDuration * 1000;

    // Should start run loop here if the first story is a video (since we need metadata loaded)
    if (index === 0 && this.firstStoryIsVideo) {
      this.runStoriesLoop();
    }
  }

  @action
  handleWillDestroy() {
    this.breakLoop = true;
  }

  @action
  handleDidInsert() {
    // Should start run loop here if first story is an image and if there's multiple stories
    if (!this.firstStoryIsVideo && this.args.stories.length > 1) {
      this.runStoriesLoop();
    }
  }

  @action
  handleToggleVolume() {
    this.muted = !this.muted;

    if (this.activeStoryIsVideo) {
      const videoElement = <HTMLVideoElement>(
        this.storyElements[this.activeStoryIndex]?.firstElementChild
      );
      videoElement.muted = this.muted;
    }
  }

  @action
  handleTogglePlay() {
    this.playing = !this.playing;

    if (this.playing) {
      this.playActiveStory();
    } else {
      this.pauseActiveStory();
    }
  }

  get activeStoryIsVideo() {
    return this.args.stories.objectAt(this.activeStoryIndex)?.videoUrl;
  }

  get firstStoryIsVideo() {
    return this.args.stories.objectAt(0)?.videoUrl;
  }

  runStoriesLoop() {
    if (this.breakLoop) return; // Breaks when component unmounts
    this.playActiveStory();
  }

  playActiveStory() {
    // If story is video start playing it
    if (this.activeStoryIsVideo) {
      const videoElement = <HTMLVideoElement>(
        this.storyElements[this.activeStoryIndex]?.firstElementChild
      );
      videoElement.currentTime = this.storyLastStartTime / 1000;
      videoElement.muted = this.muted;
      videoElement.play();
    }

    this.storyLastStartTimestamp = Date.now();

    const storyDuration =
      this.storyDurations[this.activeStoryIndex] || IMAGE_STORY_DURATION;
    const storyDurationLeft = storyDuration - this.storyLastStartTime;

    this.updateProgressBar(storyDuration);
    this.changeStoryCallback(storyDurationLeft);
  }

  pauseActiveStory() {
    if (this.laterCallback) cancel(this.laterCallback);

    if (this.activeStoryIsVideo) {
      const videoElement = <HTMLVideoElement>(
        this.storyElements[this.activeStoryIndex]?.firstElementChild
      );

      videoElement.pause();
      this.storyLastStartTime = videoElement.currentTime * 1000; // Use actual video time for precision
    } else {
      this.storyLastStartTime += this.getStoryElapsedTimeSinceLastStart();
    }
  }

  changeStoryCallback(storyDuration: number) {
    this.laterCallback = later(
      this,
      () => {
        // If story is video stop it
        if (this.activeStoryIsVideo) {
          const videoElement =
            this.storyElements[this.activeStoryIndex]?.firstElementChild;
          (<HTMLVideoElement>videoElement).pause();
        }

        this.swapStories();
        this.runStoriesLoop();
      },
      storyDuration
    );
  }

  swapStories() {
    const nextStoryIndex =
      (this.activeStoryIndex + 1) % this.args.stories.length;

    this.activeStoryIndex = nextStoryIndex;
    this.storyLastStartTime = 0;
  }

  updateProgressBar(storyDuration: number) {
    if (!this.playing) {
      return;
    }

    const storyCurrentTime =
      this.storyLastStartTime + this.getStoryElapsedTimeSinceLastStart();

    if (storyCurrentTime <= storyDuration) {
      if (this.progressBar) {
        const progressBarWidth =
          this.progressBar.offsetWidth * (storyCurrentTime / storyDuration);
        this.progressBar.style.transform = `translateX(${progressBarWidth}px)`;
      }

      requestAnimationFrame(() => {
        this.updateProgressBar(storyDuration);
      });
    }
  }

  getStoryElapsedTimeSinceLastStart() {
    return this.storyLastStartTimestamp !== null
      ? Date.now() - this.storyLastStartTimestamp
      : 0;
  }
}
