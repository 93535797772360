/* import __COLOCATED_TEMPLATE__ from './login-layout.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class LoginLayoutComponent extends Component {
  @service intercom;
  @service cookieConsent;

  constructor() {
    super(...arguments);

    this.intercom.start();
  }

  @action
  handleManageCookiesClick() {
    this.cookieConsent.hideCookieConsent = false;
  }
}
