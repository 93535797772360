/* import __COLOCATED_TEMPLATE__ from './section-hero-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, setProperties } from '@ember/object';

export default class SectionHeroForm extends Component {
  @service store;
  @service filthyContent;

  get section() {
    return this.args.model;
  }

  get pickedImage() {
    return this.section.pickedImages.firstObject;
  }

  makeDirty() {
    if (
      !this.section.isDirty &&
      !this.filthyContent.isFilthy(this.section.id)
    ) {
      this.filthyContent.setFilthy(this.section.id);
    }
  }

  @action
  handlePickImage(image) {
    let pickedImage =
      this.section.pickedImages.toArray()[0] ||
      this.section.pickedImages.createRecord('picked-image', {
        section_id: this.section.id,
      });

    pickedImage.image = image;

    this.makeDirty();
  }

  @action
  handleRemoveImage() {
    this.section.pickedImages.toArray()[0].deleteRecord();
    this.section.pickedImages = [];
    this.makeDirty();
  }

  @action
  handleRemoveCtaButton() {
    setProperties(this.section, {
      hasHeroCtaButton: false,
      ctaWindowTargetBlank: false,
      ctaType: null,
      heroCtaButtonText: null,
      customUrl: null,
    });
  }
}
