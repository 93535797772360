/* import __COLOCATED_TEMPLATE__ from './cv-online.hbs'; */
import { action } from '@ember/object';
import PromotionBaseClass from './promotion-base';
import { tracked } from '@glimmer/tracking';

export default class extends PromotionBaseClass {
  @tracked checkoutData;

  get cvOnlineWorkTimes() {
    return this.store.peekAll('promotion-categories/cv-online-work-time');
  }

  get cvOnlineSearchCategories() {
    return this.store.peekAll('promotion-categories/cv-online-search-category');
  }

  get cvOnlineTowns() {
    return this.store.peekAll('promotion-categories/cv-online-town');
  }

  get salaryRatePerOptions() {
    return [
      { name: 'Month', value: 'MONTHLY' },
      { name: 'Hour', value: 'HOURLY' },
    ];
  }

  @action
  setSalaryRatePer(rateObj) {
    this.promotion.cvOnlineSalaryRatePer = rateObj.value;
  }
}
