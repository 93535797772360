/* import __COLOCATED_TEMPLATE__ from './promote-team-ad-box.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import JobModel from 'teamtailor/models/job';

type PromoteTeamAdBoxArgs = {
  job: JobModel;
};

export default class PromoteTeamAdBox extends Component<PromoteTeamAdBoxArgs> {
  @service declare intl: IntlService;
  @tracked isOpen = true;
  @tracked message = null;

  adVersion = Math.ceil(Math.random() * 2);

  get messageTitle() {
    return this.intl.t(
      `jobs.job.promote.promote_team_ad.${this.adVersion}.title`
    );
  }

  get messageContent() {
    return this.intl.t(
      `jobs.job.promote.promote_team_ad.${this.adVersion}.content`
    );
  }

  get chatMessage() {
    return `I would like to get tips on how I can improve my job ad, or learn more about using additional channels to reach & attract more candidates for ${this.args.job.title} (id: ${this.args.job.id})`;
  }
}
