/* import __COLOCATED_TEMPLATE__ from './nps-description.hbs'; */
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  classNames: ['py-16 px-8'],

  row: null,
  feedback: alias('row.feedback'),
});
