/* import __COLOCATED_TEMPLATE__ from './employees.hbs'; */
import BulkActionsBase from './base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import UserModel from 'teamtailor/models/user';
import { isEmpty } from '@ember/utils';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import { get } from 'teamtailor/utils/get';

type BulkData = {
  [key: string]: unknown;
};
export default class BulkActionsEmployeesComponent extends BulkActionsBase {
  @service declare store: Store;
  @service declare current: Current;

  get modals(): string[] {
    const modals = ['delete', 'department', 'location', 'role'];

    return modals;
  }

  get query(): string | null {
    const { query } = this.args.searchParams;
    return !isEmpty(query) ? query.toLowerCase() : null;
  }

  get departmentQuery(): string | null {
    const { department_id } = this.args.searchParams;

    return !isEmpty(department_id) ? department_id : null;
  }

  get isNoDepartmentQuery(): boolean {
    return this.departmentQuery === NO_DEPARTMENT_ID;
  }

  get locationQuery(): string | null {
    const { location_id } = this.args.searchParams;
    return !isEmpty(location_id) ? location_id : null;
  }

  get roleQuery(): string | null {
    const { role } = this.args.searchParams;
    return !isEmpty(role) ? role : null;
  }

  get hasQueries(): boolean {
    return (
      !!this.query ||
      !!this.departmentQuery ||
      !!this.locationQuery ||
      !!this.roleQuery
    );
  }

  get selectedEmployees() {
    if (this.args.selectAll) {
      return this.store
        .peekAll('user')
        .filter((user: UserModel) => this.isSelectedUser(user));
    } else {
      return this.args.selectedIds.map((id: string) => {
        return this.store.peekRecord('user', id);
      });
    }
  }

  get employeeBulkOptions() {
    const { deselectedIds, selectAll, selectedIds, searchParams } = this.args;
    if (selectAll) {
      return {
        query: searchParams,
        deselected_ids: deselectedIds,
      };
    } else {
      return {
        selected_ids: selectedIds,
      };
    }
  }

  isSelectedUser(user: UserModel): boolean {
    return (
      !this.args.deselectedIds.includes(user.id) &&
      (this.hasQueries ? this.isUserMatchingQueries(user) : true)
    );
  }

  isUserMatchingQueries(user: UserModel): boolean {
    return (
      this.userHasRole(user) &&
      this.userMatchesQuery(user) &&
      this.userHasDepartment(user) &&
      this.userHasLocation(user)
    );
  }

  userHasLocation(user: UserModel): boolean {
    return this.locationQuery
      ? get(user.location, 'id') === this.locationQuery
      : true;
  }

  userHasDepartment(user: UserModel): boolean {
    if (this.isNoDepartmentQuery) {
      return !user.departmentId;
    }

    return this.departmentQuery
      ? user.departmentId === this.departmentQuery
      : true;
  }

  userHasRole(user: UserModel): boolean {
    return this.roleQuery ? this.roleQuery === user.role : true;
  }

  userMatchesQuery(user: UserModel): boolean {
    return this.query
      ? user.nameOrEmail.toLowerCase().includes(this.query)
      : true;
  }

  @action
  handleBulkChange(bulkData: BulkData, action: string): void {
    const promise = this.postAction(
      action,
      {
        ...this.employeeBulkOptions,
        ...bulkData,
      },
      'users'
    );

    this.batchJob.batchJobStartedTask
      .perform(promise, this.args.selectedCount)
      .then(() => {
        this.args.refetchEmployees();
      });
  }

  @action
  closeModal(): void {
    this.currentModal = null;
  }
}
