/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';
import classnames from 'teamtailor/utils/classnames';

interface EmptyStateArgs {
  emoji?: string;
  icon?: string;
  color?: string;
  position?: 'top';
  layout?: 'horizontal' | 'vertical';
}

export default class EmptyStateComponent extends Component<EmptyStateArgs> {
  @argDefault layout = 'vertical';

  get isHorizontal() {
    return this.layout === 'horizontal';
  }

  get icon() {
    return this.args.icon ?? 'star';
  }

  get colorClasses() {
    if (this.args.color) {
      return classnames(
        `flex justify-center items-center rounded-full ${
          !this.isHorizontal ? 'mb-16' : ''
        } bg-decorative-${this.args.color}-weak`,
        { [`text-decorative-${this.args.color}-weak`]: this.args.icon }
      );
    } else {
      return '';
    }
  }

  get isPositionTop() {
    return this.args.position === 'top';
  }
}
