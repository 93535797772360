/* import __COLOCATED_TEMPLATE__ from './time-zone-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class TimezonePickerComponent extends Component {
  @service timezones;
  @service intl;

  @argDefault key = 'name';

  get defaultText() {
    return (
      this.args.defaultText || this.intl.t('search.timezone.select_timezone')
    );
  }

  get allTimezones() {
    if (this.args.defaultText) {
      return [
        {
          displayName: this.args.defaultText,
          name: null,
        },
        ...this.timezones.all,
      ];
    }

    return this.timezones.all;
  }

  get filteredTimezones() {
    const filterList = ['Osaka', 'Sapporo'];
    const filtered = (this.timezones.all || []).filter((tz) => {
      return !filterList.includes(tz.name);
    });
    return filtered;
  }

  @action
  registerApi(api) {
    this.args.onRegisterApi?.(api);
  }

  @action
  handleClose(api, event) {
    this.args.onClose?.(api, event);
  }

  @action
  handleOpen() {
    this.args.onOpen?.();
  }

  @action
  onSelect(timeZone) {
    this.args.onSelect?.(timeZone[this.key]);
  }
}
