/* import __COLOCATED_TEMPLATE__ from './meeting-event-self-schedule-picked-time.hbs'; */
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import Base from './base';
import { get } from 'teamtailor/utils/get';

export default class CalendarComponent extends Base {
  @service timeFormat;

  get tzid() {
    return get(this.activity, 'actionData.meeting_event_tzid');
  }

  get formattedDate() {
    if (!this.tzid) {
      // for older actions without tzid
      return moment
        .parseZone(get(this.activity, 'actionData.meeting_event_starts_at'))
        .format(`DD MMM YYYY ${this.timeFormat.format}`);
    }

    return moment(get(this.activity, 'actionData.meeting_event_starts_at'))
      .tz(this.tzid)
      .format(`DD MMM YYYY ${this.timeFormat.format} (z)`);
  }
}
