/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

const DATA_QUERY = gql`
  query EmployeeUserInfoQuery($dateRange: DateRangeAttributes!, $userId: ID!) {
    user: eventQuery(
      dateRange: $dateRange
      filters: { userId: { equals: $userId }, jobId: { exists: true } }
      eventTypes: [
        HIRED
        INTERVIEW_CREATED
        MOVED
        REJECTED
        SCORE
        MESSAGE
        NOTE
        REFERRAL
        REVIEW
        SOURCED
      ]
      orderBy: { field: DATE, desc: true }
    ) {
      activities: rows {
        date
        jobId
        eventType
      }
    }
  }
`;

export default class InsightsWidgetsUserInfo extends Component {
  @service insights;
  @service store;

  get lastActivity() {
    return this.fetchData.lastSuccessful?.value?.lastActivity;
  }

  get data() {
    return this.fetchData.lastSuccessful?.value;
  }

  get activities() {
    return this.fetchData.lastSuccessful?.value?.activities;
  }

  @action
  queryForData(dateRange) {
    const { user } = this.args;
    const userId = get(user, 'id');

    return this.insights.query({
      query: DATA_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        userId,
      },
    });
  }

  fetchData = dropTask(async () => {
    const { user: userResponse } = await this.queryForData(this.args.dateRange);
    const { activities } = userResponse;

    return {
      user: this.args.user,
      lastActivity: activities.firstObject,
      activities,
    };
  });
}
