/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class PermissionsPicker extends Component {
  @tracked searchTerm;

  get users() {
    const { users } = this.args;

    if (this.searchTerm) {
      return users.filter((user) => {
        return get(user, 'nameOrEmail')
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      });
    } else {
      return users;
    }
  }

  @action
  handleInputChange(event) {
    this.searchTerm = event.target.value;
  }
}
