import AvatarModel from 'teamtailor/models/avatar';

export const DEFAULT_SIZE = '32';
export const ALLOWED_SIZES = ['16', '20', '24', DEFAULT_SIZE, '36'] as const;

export type AllowedSizes = (typeof ALLOWED_SIZES)[number];

export const ICON_SIZE = {
  16: '10',
  20: '12',
  24: '14',
  32: '16',
  36: '20',
};

export const EMOJI_TEXT_CLASS = {
  16: 'body-text-xxs',
  20: 'body-text-xs',
  24: 'body-text-s',
  32: 'body-text-medium',
  36: 'body-text-l',
};

export const LETTER_TEXT_CLASS = {
  16: 'body-text-xxs',
  20: 'body-text-xs',
  24: 'body-text-medium',
  32: 'body-text-l',
  36: 'body-text-l',
};

export const ALLOWED_AVATAR_COLORS = [
  'zinc',
  'cerise',
  'indigo',
  'light-blue',
  'cyan',
  'lime',
  'amber',
  'rose',
];

export type TempAvatarData = Omit<AvatarModel, 'id' | 'job'>;

export const DEFAULT_AVATAR_ATTRIBUTES = {
  avatarType: 'letter',
  backgroundColor: 'zinc',
  chosenIcon: '',
  chosenIconPrefix: '',
  chosenEmoji: '',
} as TempAvatarData;
