/* import __COLOCATED_TEMPLATE__ from './trigger-hire-quality-survey-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import TriggerHireQualitySurveyModel from 'teamtailor/models/trigger/hire-quality-survey';

interface Args {
  model: TriggerHireQualitySurveyModel;
}

export default class TriggerHireQualitySurveyForm extends Component<Args> {
  @service declare intl: IntlService;

  @tracked evaluationPeriod = this.trigger.convertedEvaluationPeriod;
  @tracked evaluationPeriodUnit = this.trigger.convertedEvaluationPeriodUnit;

  get trigger(): TriggerHireQualitySurveyModel {
    return this.args.model;
  }

  @action
  changeEvaluationPeriod(): void {
    const value = this.evaluationPeriod;
    this.trigger.evaluationPeriod =
      this.evaluationPeriodUnit === 'weeks' ? value * 7 : value;
  }

  @action
  changeEvaluationPeriodUnit(option: string): void {
    this.evaluationPeriodUnit = option;

    if (this.trigger.evaluationPeriodUnit === option) {
      return;
    }

    const value = this.trigger.evaluationPeriod;
    this.trigger.evaluationPeriod = option === 'weeks' ? value * 7 : value / 7;
    this.trigger.evaluationPeriodUnit = option === 'weeks' ? 'days' : option;
  }
}
