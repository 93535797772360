/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import AvatarModel from 'teamtailor/models/avatar';
import {
  DEFAULT_AVATAR_ATTRIBUTES,
  TempAvatarData,
} from 'teamtailor/constants/job-avatar';
import { JobModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import copyModel from 'teamtailor/utils/copy-model';
import {
  AllowedSizes,
  ALLOWED_SIZES,
  DEFAULT_SIZE,
  EMOJI_TEXT_CLASS,
  LETTER_TEXT_CLASS,
  ICON_SIZE,
} from 'teamtailor/constants/job-avatar';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import BowserService from 'teamtailor/services/bowser';

interface AvatarArgs {
  canEdit: boolean;
  job?: JobModel;
  jobName?: string;
  size?: AllowedSizes;
  onPickAvatar?: (avatar: AvatarModel | TempAvatarData | undefined) => void;
}

export default class AvatarComponent extends Component<AvatarArgs> {
  @service declare bowser: BowserService;
  @service declare store: Store;

  @tracked declare draftAvatar: AvatarModel | undefined;
  @tracked showEditAvatarModal = false;

  @argDefault canEdit = false;

  get defaultAvatarData() {
    return DEFAULT_AVATAR_ATTRIBUTES;
  }

  get avatar(): AvatarModel | TempAvatarData {
    return (
      this.draftAvatar || get(this.job, 'avatar') || this.defaultAvatarData
    );
  }

  get job(): JobModel | undefined {
    return this.args.job;
  }

  get jobInitial() {
    const name = this.args.jobName || get(this.job, 'title');
    return name ? name.toUpperCase().slice(0, 1) : '_';
  }

  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Job avatar @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get emojiSizeBasedOnBrowser() {
    const sizeIndex = ALLOWED_SIZES.indexOf(this.size);
    return this.bowser.isSafari && sizeIndex > 0
      ? ALLOWED_SIZES[sizeIndex - 1]!
      : this.size;
  }

  get emojiTextClass() {
    return EMOJI_TEXT_CLASS[this.emojiSizeBasedOnBrowser];
  }

  get letterTextClass() {
    return LETTER_TEXT_CLASS[this.size];
  }

  get iconSize() {
    return ICON_SIZE[this.size];
  }

  makeDraft() {
    if (!this.draftAvatar) {
      if (this.job?.hasAvatar) {
        this.draftAvatar = copyModel({
          model: this.job.avatar,
          excludedRelationships: ['job'],
        });
      } else {
        this.draftAvatar = this.store.createRecord('avatar', {
          ...this.defaultAvatarData,
        });
      }
    }
  }

  @action
  openEditAvatarModal() {
    this.makeDraft();
    this.showEditAvatarModal = true;
  }

  @action
  closeEditAvatarModal(): void {
    this.draftAvatar?.rollbackAttributes();
    this.draftAvatar = undefined;
    this.showEditAvatarModal = false;
  }

  @action
  saveAvatar() {
    this.args.onPickAvatar?.(this.avatar);
    this.showEditAvatarModal = false;
  }
}
