/* import __COLOCATED_TEMPLATE__ from './add-to-job.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import JobModel from 'teamtailor/models/job';
import CandidateModel from 'teamtailor/models/candidate';
import { get } from 'teamtailor/utils/get';
import { JobApplicationModel } from 'teamtailor/models';

interface CandidateModalNewActionModalsAddToJobArgs {
  candidate: CandidateModel;
  onClose: () => void;
  handleJobApplicationChange?(jobApplication: JobApplicationModel): void;
}

export default class CandidateModalNewActionModalsAddToJob extends Component<CandidateModalNewActionModalsAddToJobArgs> {
  @tracked pickedJob?: JobModel;

  get excludeJobsIds() {
    return get(this.args.candidate, 'jobApplications').mapBy('job.id');
  }

  @action
  async assignCandidateToJob(job: JobModel) {
    const jobApplication = get(
      this.args.candidate,
      'jobApplications'
    ).createRecord({
      job,
    });

    try {
      await jobApplication.save();
      this.args.onClose();

      if (this.args.handleJobApplicationChange) {
        this.args.handleJobApplicationChange(jobApplication);
      }
    } catch {
      jobApplication.destroyRecord();
    }
  }
}
