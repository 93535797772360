/* import __COLOCATED_TEMPLATE__ from './user-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';
import { isEmpty } from '@ember/utils';
import uniqBy from 'teamtailor/utils/uniq-by';
import UserModel from 'teamtailor/models/user';
import Store from '@ember-data/store';
import { query } from 'ember-data-resources';

type CoreUserPickerComponentArgs = {
  searchParams?: Record<string, string>;
  currentUser?: UserModel;
  selectedUser?: UserModel;
  excludeUsers?: UserModel[];
  includeUsers?: UserModel[];
  showSelected?: boolean;
  selectedOnTop?: boolean;
  minimumSelectionRequired?: boolean;
  defaultOptionPlaceholder?: string;
  role?: string;
  onClose?: () => void;
} & (
  | {
      isMultiple: true;
      selected?: UserModel[];
    }
  | {
      isMultiple?: false;
      selected?: UserModel;
    }
);

export default class CoreUserPickerComponent extends Component<CoreUserPickerComponentArgs> {
  @service declare store: Store;

  @tracked query = '';

  @argDefault showSelected = true;
  @argDefault minimumSelectionRequired = false;
  @argDefault excludeUsers: UserModel[] = [];
  @argDefault includeUsers: UserModel[] = [];

  get selectedUserIds() {
    return this.selectedUsers.map((user) => user.id);
  }

  get excludeUserIds() {
    const exclude_ids = this.excludeUsers.map((user) => user.id);
    if (this.args.currentUser) {
      exclude_ids.push(this.args.currentUser.id);
    }

    if (!this.showSelected) {
      exclude_ids.push(...this.selectedUserIds);
    }

    return exclude_ids;
  }

  get includeUserIds() {
    return this.includeUsers.map((user) => user.id);
  }

  get selectedUsers() {
    return this.args.isMultiple
      ? (this.args.selected ?? [])
      : [this.args.selected].filter(Boolean);
  }

  get users() {
    let users = (this.userQuery.records || []) as unknown as UserModel[];
    if (isEmpty(this.query) && this.showSelected && this.selectedOnTop) {
      users = uniqBy(
        [
          ...this.selectedUsers
            .filter((user) => get(user, 'nameOrEmail'))
            .toSorted((a, b) =>
              get(a, 'nameOrEmail').localeCompare(get(b, 'nameOrEmail'))
            ),
          ...users,
        ],
        'id'
      );
    }

    if (this.args.currentUser) {
      users = uniqBy([this.args.currentUser, ...users], 'id');
    }

    if (this.args.defaultOptionPlaceholder) {
      users = [
        {
          id: '',
          nameOrEmail: this.args.defaultOptionPlaceholder,
        } as UserModel,
        ...users,
      ];
    }

    return users;
  }

  get selectedOnTop() {
    return this.showSelected && (this.args.selectedOnTop ?? true);
  }

  isDisabled = (option: UserModel) => {
    return (
      this.minimumSelectionRequired &&
      this.selectedUserIds.length === 1 &&
      this.selectedUserIds.includes(option.id)
    );
  };

  onClose = () => {
    if (this.query !== '') {
      this.query = '';
    }

    this.args.onClose?.();
  };

  userQuery = query(this, 'user', () => {
    return {
      ...(this.args.searchParams || {}),
      per_page: 10,
      exclude_ids: this.excludeUserIds,
      include_ids: this.includeUserIds,
      query: this.query,
      role: this.args.role,
    };
  });
}
