/* import __COLOCATED_TEMPLATE__ from './primitive.hbs'; */
import { action } from '@ember/object';

import Component from '@glimmer/component';

export default class PartnerConfigFieldsPrimitiveComponent extends Component {
  @action
  onChange(e) {
    this.args.onChange(e.target.value);
  }
}
