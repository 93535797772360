/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class FormFieldReadDate extends Component {
  @action
  pickDate(value) {
    const date = moment.utc(value).format('YYYY-MM-DD');
    return this.args.onChange(date);
  }
}
