/* import __COLOCATED_TEMPLATE__ from './move.hbs'; */
import BaseComponent from './base';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  CandidateModel,
  DepartmentModel,
  DivisionModel,
  RoleModel,
} from 'teamtailor/models';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import { NO_DIVISION_ID } from 'teamtailor/models/division';
import { get } from 'teamtailor/utils/get';

export default class MoveComponent extends BaseComponent {
  @service declare store: Store;

  @tracked division: DivisionModel | null = null;
  @tracked department: DepartmentModel | null = null;
  @tracked role: RoleModel | null = null;

  get departments() {
    let divisionId = this.division?.id ?? null;
    const departments = get(this.company, 'departments');

    if (!divisionId) {
      return departments;
    }

    if (divisionId === NO_DIVISION_ID) {
      divisionId = null;
    }

    return departments.filter((department: DepartmentModel) => {
      const division = get(department, 'division');
      return (
        get(division, 'id') === divisionId || department.id === NO_DEPARTMENT_ID
      );
    });
  }

  get roles() {
    return get(this.department, 'roles');
  }

  @action
  onChangeDivision(division: DivisionModel) {
    this.division = division;
    this.department = null;
    this.role = null;
  }

  @action
  onChangeDepartment(department: DepartmentModel) {
    this.department = department;
    this.role = null;
  }

  @action
  move() {
    let promise = this.postAction(
      'bulk_move',
      this.postData({
        division_id: get(this, 'division')?.id ?? null,
        department_id: get(this, 'department')?.id ?? null,
        role_id: get(this, 'role')?.id ?? null,
      })
    );

    promise.then(() => {
      this.store
        .peekAll('candidate')
        .forEach((candidate: CandidateModel) => candidate.reload());
      this.store
        .peekAll('division')
        .forEach((division: DivisionModel) => division.reload());
      this.store
        .peekAll('department')
        .forEach((department: DepartmentModel) => department.reload());
      this.store.peekAll('role').forEach((role: RoleModel) => role.reload());

      this.args.bulkCallbackAction();
    });

    this.args.onClose();

    this.batchJob.batchJobStartedTask.perform(promise, this.args.selectedCount);
  }
}
