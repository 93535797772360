/* import __COLOCATED_TEMPLATE__ from './section-gallery-layout.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { SectionModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    model: SectionModel;
  };
}

type StyleOption = {
  text: string;
  value: string;
};

export default class SectionGalleryLayout extends Component<Signature> {
  @service declare intl: IntlService;

  get section() {
    return this.args.model;
  }

  get layoutStyleOptions() {
    return [
      {
        text: this.intl.t('content.editor.section.gallery.collage'),
        value: 'collage',
      },
      {
        text: this.intl.t('content.editor.section.gallery.row'),
        value: 'row',
      },
      {
        text: this.intl.t('content.editor.section.gallery.carousel'),
        value: 'carousel',
      },
    ];
  }

  @action
  setLayout(option: StyleOption) {
    this.section.layout = option.value;
  }
}
