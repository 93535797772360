/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import CandidateModel from 'teamtailor/models/candidate';
import JobApplicationModel from 'teamtailor/models/job-application';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

interface Args {
  candidate: CandidateModel;
  jobApplication?: JobApplicationModel;
}

export default class CandidateModalNewMainTestsComponents extends Component<Args> {
  @service declare current: Current;

  get results() {
    if (this.args.candidate.isDestroying) {
      return [];
    }

    let results = this.args.candidate.partnerResults
      .slice()
      .toSorted(
        (result1, result2) =>
          Number(result2.createdAt) - Number(result1.createdAt)
      );

    if (this.args.jobApplication) {
      const { jobId } = this.args.jobApplication;

      if (typeof jobId === 'number') {
        results = results.filter(
          (r) => r.jobId === jobId.toString() || r.jobId === null
        );
      }
    } else {
      results = results.filter((r) => r.jobId === null);
    }

    return results;
  }

  get partnerActivations() {
    return this.current.company.partnerActivations.filter(
      (pa) => pa.capabilityCandidateResults
    );
  }
}
