/* import __COLOCATED_TEMPLATE__ from './form-builder.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import SurveyModel from 'teamtailor/models/survey';

type Args = {
  model: SurveyModel;
  onUpdateField: () => void;
  onUpdateFields: () => void;
};

export default class SurveyFormBuilderComponent extends Component<Args> {
  @tracked optionButtonsVisible = false;

  get isEdit(): boolean {
    return !this.args.model.isNew;
  }

  @action
  handleTitleEnter(): void {
    const textarea = document.getElementById('survey-intro-text');
    textarea?.focus();
  }

  @action
  toggleOptionButtons(event: Event): void {
    event.stopPropagation();
    this.optionButtonsVisible = !this.optionButtonsVisible;
  }

  @action
  didInsertTitleInput(input: HTMLElement) {
    if (!this.isEdit) {
      input.focus();
    }
  }
}
