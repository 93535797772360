/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import FormBaseComponent from './form-base';
import { action, setProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';
import placeholders from 'teamtailor/utils/placeholders';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';
import { RequisitionFlowStepModel } from 'teamtailor/models';
import { guidFor } from '@ember/object/internals';
import { data as currencyData } from 'currency-codes';
import { v4 as uuid } from 'ember-uuid';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class Form extends FormBaseComponent {
  @service store;
  @service current;
  @service intl;

  @tracked selectedTemplate;
  @tracked editingFlow = false;

  @tracked salaryTimeUnit = this.args.form?.options?.salaryTimeUnit;
  @tracked currency = this.args.form?.options?.currency;
  @tracked salary = this.args.form?.options?.salary;
  @tracked showWarning = false;
  @tracked requisitionShown = false;
  @tracked originalJobOfferApprovalFlowId = get(
    this.args.jobOffer,
    'jobOfferApprovalFlow.id'
  );

  @argDefault showMostUsedCurrenciesOnTop = true;

  @cached
  get currencyList() {
    if (this.showMostUsedCurrenciesOnTop) {
      const mostUsedCurrencyCodes = ['EUR', 'GBP', 'SEK', 'USD'];
      const others = [];
      const mostUsed = [];

      currencyData.forEach((currency) => {
        if (mostUsedCurrencyCodes.includes(currency.code)) {
          mostUsed.push(currency);
        } else {
          others.push(currency);
        }
      });

      return [
        {
          groupName: this.intl.t('requisitions.salary_selection.most_used'),
          options: mostUsed,
        },
        {
          groupName: this.intl.t('requisitions.salary_selection.others'),
          options: others,
        },
      ];
    }

    return currencyData;
  }

  get formId() {
    return `job-offer-form-${guidFor(this)}`;
  }

  get jobOffer() {
    return this.args.jobOffer;
  }

  get jobOfferTemplates() {
    return this.store.findAll('job-offer-template');
  }

  get defaultFields() {
    return this.fields.filter((field) =>
      get(this.args.form, 'unmodifiableFields')?.includes(field.label)
    );
  }

  get defaultsExcludedFields() {
    return this.fields.filter(
      (field) =>
        !get(this.args.form, 'unmodifiableFields')?.includes(field.label)
    );
  }

  get salaryValue() {
    return this.args.form?.options?.salary;
  }

  set salaryValue(value) {
    this.updateFormOptions({ salary: value });
  }

  get salaryTimeUnits() {
    return [
      { label: 'yearly' },
      { label: 'monthly' },
      { label: 'weekly' },
      { label: 'daily' },
      { label: 'hourly' },
    ];
  }

  get job() {
    return get(this.jobOffer, 'jobApplication.job');
  }

  get requisition() {
    return get(this.job, 'requisition');
  }

  @action
  toggleRequisition() {
    this.requisitionShown = !this.requisitionShown;
  }

  @action
  updateCurrency(currency) {
    this.currency = currency.code;
    this.updateFormOptions({ currency: currency.code });
  }

  @action
  updateSalaryTimeUnit(timeUnit) {
    this.salaryTimeUnit = timeUnit.label;
    this.updateFormOptions({ salaryTimeUnit: timeUnit.label });
  }

  updateFormOptions(options) {
    this.args.form.options = {
      ...this.args.form.options,
      ...options,
    };
    this.args.salary.textValue = this.updateSalaryValue(
      this.args.form.options.salary
    );
    this.args.onUpdateFields(this.fields);
  }

  updateSalaryValue(value) {
    if (this.currency && this.salaryTimeUnit) {
      return this.buildSalaryString();
    }

    return value;
  }

  jobOfferApprovalFlow = trackedFunction(this, async () => {
    return this.jobOffer.jobOfferApprovalFlow;
  });

  get approvalSteps() {
    const jobOfferApprovalFlow = this.jobOfferApprovalFlow.value;
    return isPresent(jobOfferApprovalFlow)
      ? get(jobOfferApprovalFlow, 'steps')
      : get(this.jobOffer, 'approvalSteps');
  }

  get candidate() {
    return get(this.jobOffer, 'candidate');
  }

  get placeholderParser() {
    const job = get(this.jobOffer, 'jobApplication.job');

    return placeholders(this.candidate, job);
  }

  get parsedIntroText() {
    return this.convertPlaceholders(this.args.form.introText, []);
  }

  get userOptions() {
    const currentUser = this.current.user;

    let users = get(this.candidate, 'restricted')
      ? get(this.jobOffer, 'jobApplication.job.unrestrictedTeamMembers')
      : get(this.jobOffer, 'jobApplication.job.teamMembersWithoutRecruiter');

    if (get(this.jobOffer, 'isNew')) {
      users = users?.filter((option) => option.id !== currentUser.id);
    }

    return users;
  }

  stepUsers(step) {
    if (step instanceof RequisitionFlowStepModel) {
      return step.users.map((user) => get(user, 'id'));
    } else {
      return step.requisitionStepVerdicts.map((verdict) =>
        get(verdict, 'userId')
      );
    }
  }

  needsYourApproval(currentStep) {
    if (!currentStep) {
      return false;
    }

    return this.stepUsers(currentStep).includes(get(this, 'current.user.id'));
  }

  buildSalaryString() {
    if (this.salaryValue !== undefined && this.currency) {
      return `${this.salaryValue} ${this.currency}/${this.intl.t(
        `requisitions.salary_selection.${this.salaryTimeUnit}`
      )}`;
    }

    return '';
  }

  applyNewFields(fields) {
    const salaryValue = this.buildSalaryString();

    fields.push({
      uuid: uuid(),
      label: 'salary',
      type: 'text',
      required: true,
      answers: [{ textValue: salaryValue }],
    });

    this.makeFieldsUnmodifiable(fields);
    this.fields = fields;
  }

  convertPlaceholders(introText, fields) {
    fields.forEach((field) => {
      if (
        field.label === 'acceptance_message' ||
        field.label === 'rejection_message'
      ) {
        field.answers[0].textValue = this.placeholderParser.parse(
          field.answers[0].textValue
        );
      }
    });
    return introText ? this.placeholderParser.parse(introText) : '';
  }

  applyTemplate(template) {
    const fields = questionsToFormFields(
      template.sortedFormQuestions.filter((q) => !q.isNew),
      false
    );
    const introText = this.convertPlaceholders(template.introText, fields);
    this.applyNewFields(fields);
    setProperties(this.args.form, {
      introText,
      uploads: template.uploads,
    });
  }

  clearData() {
    const fields = get(this.args, 'form.defaultFormFields');
    this.applyNewFields(fields);
    setProperties(this.args.form, {
      introText: '',
      uploads: [],
    });
  }

  resolveUsers = async (step) => {
    return get(step, 'users');
  };

  @action
  onUpdateFields(fields) {
    this.fields = [...this.defaultFields, ...fields];
    this.args.onUpdateFields(this.fields);
  }

  @action
  selectTemplate(template) {
    this.selectedTemplate = template;
    if (template) {
      this.applyTemplate(template);
    } else {
      this.clearData();
    }

    this.args.onUpdateFields(this.fields);
  }
}
