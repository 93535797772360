/* import __COLOCATED_TEMPLATE__ from './dropdown-submenu.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { KeyCodes } from 'teamtailor/constants/key-codes';
import { next } from '@ember/runloop';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class extends Component {
  @tracked _isOpen = false;

  get isOpen() {
    switch (this.showSubmenuOn) {
      case 'hover':
        return this._isOpen;
      case 'selected':
        return !!this.args.selected;
    }
  }

  set isOpen(value) {
    this._isOpen = value;
  }

  @argDefault closeOnTriggerClick = true;
  @argDefault showSubmenuOn = 'hover';
  @argDefault hasSubmenu = false;

  get showOnHover() {
    return this.showSubmenuOn === 'hover';
  }

  triggerId = `trigger-${guidFor(this)}`;
  listboxId = `listbox-${guidFor(this)}`;

  get destinationElement() {
    return (
      this.args.destinationElement ||
      document.getElementById('ember-teamtailor-dropdown-submenu')
    );
  }

  get api() {
    return {
      close: this.close,
      open: this.open,
    };
  }

  toggle(focusParentOnClose = false) {
    if (!this.isOpen) {
      this.open();
    } else {
      this.close({ focusParentOnClose });
    }
  }

  open = () => {
    if (!this.isOpen && !this.args.isDisabled) {
      this.isOpen = true;
    }
  };

  close = ({ focusParentOnClose = false, isClosedByKeyboard = false } = {}) => {
    if (this.container && this.isOpen) {
      this.isClosedByKeyboard = isClosedByKeyboard;

      this.isOpen = false;

      if (focusParentOnClose) {
        this.button.focus();
      }
    }
  };

  isClosedByKeyboard = false;

  @action
  handleContainerMouseenter() {
    this.open();
  }

  @action
  handleTriggerMouseleave(e) {
    if (
      !e.relatedTarget?.closest(`#${this.triggerId}`) &&
      !e.relatedTarget?.closest(`#${this.listboxId}`)
    ) {
      this.close();
    }
  }

  @action
  handleContainerMouseleave(e) {
    if (!e.relatedTarget?.closest(`#${this.triggerId}`) && !this.hasSubmenu) {
      this.close();
    }
  }

  @tracked isOpenedByKeyboard = false;

  @action
  handleClick(event) {
    if (event.detail === 0) {
      this.isOpenedByKeyboard = true;
    }

    event.preventDefault();
    event.stopPropagation();
    if (!this.isOpen || this.closeOnTriggerClick) {
      this.toggle(true);
    }
  }

  @action
  handleKeydown(event) {
    const { ENTER, ESC, ARROW_LEFT, ARROW_RIGHT } = KeyCodes;

    // This is to make it work in tests. When upgrading ember-test-helpers to 3+ we might be able to remove event.key fallback
    const code = event.code || event.key;

    if (ESC === code && this.isOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.close({ isClosedByKeyboard: true });
    }

    if (code === ENTER && this.isOpen) {
      event.preventDefault();
      event.stopPropagation();
    }

    if ([ARROW_LEFT, ARROW_RIGHT].includes(code) && this.isOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.close({ isClosedByKeyboard: true });
    }
  }

  @action
  onInsertContainer(element) {
    this.container = element;
    next(() => {
      this.container.focus();
    });

    this.args.onInsertContainer?.(element, { button: this.button });
  }

  @action onDestroyContainer(element) {
    this.args.onDestroyContainer?.(element, {
      button: this.button,
      isOpenedByKeyboard: this.isOpenedByKeyboard,
      isClosedByKeyboard: this.isClosedByKeyboard,
    });
  }

  @action
  onInsertButton(element) {
    this.button = element;
    if (this.args.onRegister) {
      this.args.onRegister(this.api);
    }
  }

  @tracked safePolygonEl;
  @tracked hoverTriangleState = new HoverTriangleState();

  @action onMouseEnterContainer() {
    this.hoverTriangleState.isVisible = false;
  }

  buttonRect;
  isInsideButtonRect({ x, y, cacheRect = true }) {
    if (!this.buttonRect || !cacheRect) {
      this.buttonRect = this.button.getBoundingClientRect();
    }

    const { left, top, width, height } = this.buttonRect;
    return x >= left && x <= left + width && y >= top && y <= top + height;
  }

  @tracked submenuSide = 'right';

  @action onTriggerMouseMove(event) {
    if (
      event.target === this.safePolygonEl &&
      !this.isInsideButtonRect({ x: event.clientX, y: event.clientY })
    ) {
      return;
    }

    this.hoverTriangleState.isVisible = true;

    this.hoverTriangleState.cursorX =
      event.clientX + (this.submenuSide === 'left' ? 1 : -1);
    this.hoverTriangleState.cursorY = event.clientY;
  }

  @action onUpdatePosition(positionState) {
    const {
      x,
      y,
      rects: {
        floating: { width, height },
      },
      placement,
    } = positionState;
    const side = placement.split('-')[0];
    this.submenuSide = side;

    const sideToXPosition = {
      right: x,
      left: x + width,
    };

    this.hoverTriangleState.containerNearestX = sideToXPosition[side];
    this.hoverTriangleState.containerTop = y + 5;
    this.hoverTriangleState.containerBottom = y + height - 5;
  }
}

class HoverTriangleState {
  @tracked isVisible = false;
  @tracked cursorX;
  @tracked cursorY;
  @tracked containerNearestX;
  @tracked containerTop;
  @tracked containerBottom;
}
