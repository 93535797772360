/* import __COLOCATED_TEMPLATE__ from './facebook.hbs'; */
import ShareButton from 'teamtailor/components/share-button/share-button';

export default class extends ShareButton {
  shareUrl = 'https://facebook.com/sharer.php';

  buildUrl() {
    const url = encodeURIComponent(this.args.url);
    return `${this.shareUrl}?display=popup&u=${url}&quote=${this.args.quote}`;
  }
}
