/* import __COLOCATED_TEMPLATE__ from './copy-input.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CopyInput extends Component {
  @service intl;
  @service flashMessages;

  @action
  copyToClipboard() {
    try {
      navigator.clipboard.writeText(this.args.value);
      this.flashMessages.success(this.intl.t('common.copied_to_clipboard'));
    } catch (error) {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
    }
  }
}
