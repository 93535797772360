/* import __COLOCATED_TEMPLATE__ from './restrict-candidate-confirmation.hbs'; */
import Component from '@glimmer/component';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class RestrictCandidarteConfirmationModal extends Component {
  @argDefault mode = 'stage';
  @computedLocalStorage(Boolean, 'hideRestrictionWarningKey', false)
  hideRestrictionWarning;

  get titleTranslationKey() {
    switch (this.mode) {
      case 'stage':
        return 'modals.restriction_warning.stage.title';
      case 'bulk-move':
        return 'modals.restriction_warning.bulk_move.title';
      case 'candidate':
      default:
        return 'modals.restriction_warning.candidate.title';
    }
  }

  get descriptionTranslationKey() {
    switch (this.mode) {
      case 'stage':
        return 'modals.restriction_warning.stage.description';
      case 'bulk-move':
        return 'modals.restriction_warning.bulk_move.description';
      case 'candidate':
      default:
        return 'modals.restriction_warning.candidate.description';
    }
  }

  get dontShowAgainTranslationKey() {
    switch (this.mode) {
      case 'stage':
        return 'modals.restriction_warning.stage.dont_show_again';
      default:
        return 'modals.restriction_warning.dont_show_again';
    }
  }
}
