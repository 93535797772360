/* import __COLOCATED_TEMPLATE__ from './date-range.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, setProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import moment from 'moment-timezone';

export default class InsightsDatePickerButtonDateRange extends Component {
  maxDate = new Date();
  defaultMinDate = moment('2016-06-01').toDate();

  @tracked selectedStartDate = null;
  @tracked selectedEndDate = null;

  @action
  changeDate([startDate, endDate]) {
    if (isPresent(startDate) && isPresent(endDate)) {
      this.args.onUpdate(startDate, endDate);
    }

    setProperties(this, {
      selectedStartDate: startDate,
      selectedEndDate: endDate,
    });
  }
}
