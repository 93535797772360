/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import Component from '@glimmer/component';
import { setProperties, action, get, set } from '@ember/object';
import DashboardWidgetModel from 'teamtailor/models/dashboard-widget';
import { extractVideoInfo } from 'teamtailor/utils/video-widget';

type DashboardWidgetsVideoFormArgs = {
  widget: DashboardWidgetModel;
  isRecord: boolean;
};

type Options = {
  displayTitle: boolean;
  displayContent: boolean;
  displayUserInfo: boolean;
  externalVideoId: string | null;
  videoProvider: string | null;
};

export default class DashboardWidgetsVideoFormComponent extends Component<DashboardWidgetsVideoFormArgs> {
  get displayVideoUrlInput(): boolean {
    return get(this.args.widget, 'isNew') && !this.args.isRecord;
  }

  @action
  onUpdateOptions(field: keyof Options) {
    const { widget } = this.args;
    set(widget.options, field, !widget.options[field]);
  }

  @action
  onEnterVideoUrl(event: Event) {
    const target = event.target as HTMLInputElement;
    const videoUrl = target.value;

    const { provider, id } = extractVideoInfo(videoUrl);

    setProperties(this.args.widget.options, {
      videoProvider: provider,
      externalVideoId: id,
    });
  }
}
