/* import __COLOCATED_TEMPLATE__ from './select-template-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

type Args = {
  isOpen: boolean;
  onClose: () => void;
  onCreateNewHistoricReport: (templateKey: string | null) => void;
  templates: any[];
};

export default class ReportSelectTemplateModal extends Component<Args> {
  @tracked selectedTemplateKey?: string | null;

  @action onClose() {
    this.selectedTemplateKey = undefined;
    this.args.onClose();
  }

  @action
  selectReportTemplate(templateId?: string) {
    this.selectedTemplateKey =
      this.selectedTemplateKey === templateId ? undefined : templateId;
  }

  @action
  next() {
    const { selectedTemplateKey } = this;
    if (selectedTemplateKey === undefined) return;

    this.selectedTemplateKey = undefined;
    this.args.onCreateNewHistoricReport(selectedTemplateKey);
  }
}
