/* import __COLOCATED_TEMPLATE__ from './data-table.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { argDefault } from 'teamtailor/utils/arg-default';
import { tracked } from '@glimmer/tracking';
import ColumnComponent, { ColumnData } from './data-table/column';
import { get } from 'teamtailor/utils/get';
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

const DEFAULT_PAGE_SIZE = 100;

export type DataTableColumn = ColumnData & {
  value: any;
  candidateRoute?: string;
  row: any;
};

export type DataTableRow = {
  [key: string]: DataTableColumn | string | undefined;
};

interface ComponentArgs {
  tableRowsBackgroundOnHover?: boolean;
  customExpandableComponent?: string;
  candidateRoute?: string;
  showFooter?: boolean;
  showToolbar?: boolean;
  showBorder?: boolean;
  sortProperty?: string;
  sortOrder?: 'asc' | 'desc';
  isEditing?: boolean;
  horizontalScroll?: boolean;
  extraPadding?: boolean;

  keepFirstColumn?: boolean;

  totalDataCount?: number;
  pageSize?: number;
  currentPage?: number;

  onPaginate?: (page: number) => void;

  onSort?: (column: string) => void;
  onExport?: () => void;

  data: DataTableRow[];

  rollups?: { [key: string]: string };
}

export default class DataTableComponent extends Component<ComponentArgs> {
  @argDefault tableRowsBackgroundOnHover = true;
  @argDefault showFooter = true;
  @argDefault showToolbar = true;
  @argDefault showBorder = true;
  @argDefault pageSize = DEFAULT_PAGE_SIZE;
  @argDefault isEditing = false;
  @argDefault horizontalScroll = false;
  @argDefault extraPadding = false;

  minRows = 5;

  @tracked columns: ColumnComponent[] = [];
  @tracked _currentPage = 1;
  @tracked _hoveringIndexColumn: number | undefined = undefined;
  @tracked hoveringEnabled = true;
  @tracked halfTableHeight = 0;

  addColumn(attributes: ColumnComponent) {
    this.columns = [...this.columns, attributes];
  }

  removeColumn(column: ColumnComponent) {
    this.columns = this.columns.filter((c) => c !== column);
  }

  exportTask = task(async () => {
    await this.onExport();
  });

  set hoveringIndexColumn(value: number | undefined) {
    this._hoveringIndexColumn = value;
  }

  get hoveringIndexColumn() {
    return this.columns.length === 1 && this.args.keepFirstColumn
      ? 0
      : this._hoveringIndexColumn;
  }

  get showAddOrEditInfo() {
    return (
      this.args.isEditing &&
      ((this.columns.length <= 1 && this.args.keepFirstColumn) ||
        this.columns.length === 0)
    );
  }

  get currentPage() {
    return this.args.currentPage ?? this._currentPage;
  }

  set currentPage(page: number) {
    this._currentPage = page;
  }

  get sortedData() {
    return this.args.data;
  }

  get totalPages() {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  get count() {
    return this.pageData.length;
  }

  get totalCount() {
    return this.args.totalDataCount ?? this.sortedData.length;
  }

  get hasRollups() {
    return Object.keys(this.args.rollups || {}).length > 0;
  }

  get pageData() {
    if (this.totalCount !== this.sortedData.length) {
      return this.columnData;
    }

    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = this.currentPage * this.pageSize;

    return this.columnData.slice(startIndex, endIndex);
  }

  get columnData() {
    let data = this.sortedData;
    if (this.showAddOrEditInfo) {
      data = new Array(10).fill(0).map(() => ({}));
    }

    return data.map((row) => {
      if (this.showAddOrEditInfo) {
        const columnData: DataTableColumn[] = this.columns.map((column) => {
          return {
            value: column.propertyName ? get(row, column.propertyName) : row,
            candidateRoute: this.args.candidateRoute,
            row,
            ...column.asObject,
          };
        });

        this.fakeColumns.forEach((column) => {
          columnData.push({
            value: 'test value',
            candidateRoute: undefined,
            row,
            ...column,
            blur: true,
            modelName: '',
            customExpandableComponent: '',
            expandable: false,
            sortable: false,
            span: undefined,
            digits: undefined,
            modelIdsPropertyName: undefined,
            options: undefined,
            preloaded: false,
            loadingForSort: false,
            sortPropertyName: '',
          });
        });

        return columnData;
      }

      return this.columns.map((column) => {
        return {
          value: column.propertyName ? row[column.propertyName] : row,
          candidateRoute: this.args.candidateRoute,
          row,
          ...column.asObject,
        };
      });
    });
  }

  get fakeColumns() {
    return new Array(9 - this.columns.length).fill(0).map((_v, index) => ({
      header: `Name${index + 1}`,
      type: 'text',
      colspan: 1,
      propertyName: 'name',
    }));
  }

  get filloutRows() {
    const neededRows = this.minRows - this.pageData.length;

    if (neededRows <= 0) {
      return [];
    }

    return new Array(neededRows).fill(0).map(() => {
      return this.columns.map((column) => {
        return {
          ...column.asObject,
        };
      });
    });
  }

  @action
  onSort(column: ColumnComponent) {
    if (this.args.onSort) {
      const propertyName = column.sortPropertyName
        ? column.sortPropertyName
        : column.propertyName;
      this.args.onSort(propertyName);
    }
  }

  @action
  async onExport() {
    await this.args.onExport?.();
  }

  @action
  goToPage(page: number) {
    this.currentPage = page;
    this.args.onPaginate?.(page);
  }

  @action
  mouseEnterColumn(index: number) {
    if (this.hoveringEnabled) {
      this.hoveringIndexColumn = index;
    }
  }

  @action
  mouseExitColumn(index: number) {
    if (this.hoveringEnabled && this.hoveringIndexColumn === index) {
      this.hoveringIndexColumn = undefined;
    }
  }

  @action
  enableHover() {
    this.hoveringEnabled = true;
  }

  @action
  disableHover() {
    this.hoveringEnabled = false;
  }

  scrollDataTableToTop = modifier((element, [isEditing]) => {
    if (isEditing && element.scrollTop !== 0) {
      element.scrollTop = 0;
    }
  });

  updateTableHeight = modifier((element, [_pageData, isEditing]) => {
    if (isEditing) {
      next(() => {
        this.halfTableHeight = Math.round(element.clientHeight / 2) - 32;
      });
    }
  });

  isLastIndex(array: unknown[], index: number) {
    return index === array.length - 1;
  }
}
