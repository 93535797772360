/* import __COLOCATED_TEMPLATE__ from './join.hbs'; */
import Component from '@glimmer/component';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import VideoMeetingTokenModel from 'teamtailor/models/video-meeting-token';
import moment from 'moment-timezone';
import { hexIsCloseToWhite } from 'teamtailor/utils/hex-close-to-white';

interface VideoMeetingJoinArgs {
  model: VideoMeetingTokenModel;
  isDesktop: boolean;
  canJoin: boolean;
  onJoin: (name?: string) => void;
  onInsert: () => void;
}

export default class VideoMeetingJoin extends Component<VideoMeetingJoinArgs> {
  @computedLocalStorage(null, 'videoMeetingName', null)
  declare name: string;

  get attendeesWithDetails() {
    return this.args.model.attendeesWithDetails.sort((a, b) =>
      a.is_organizer === b.is_organizer ? 0 : a.is_organizer ? -1 : 1
    );
  }

  get numberOfAttendees() {
    return this.attendeesWithDetails.length;
  }

  get shownAttendees() {
    if (this.numberOfAttendees <= 5) return this.attendeesWithDetails;
    return this.attendeesWithDetails.slice(0, 4);
  }

  get userImageSize() {
    switch (this.numberOfAttendees) {
      case 1:
        return '64';
      case 2:
        return '48';
      default:
        return '36';
    }
  }

  get numberOfNotShownAttendees() {
    return this.numberOfAttendees <= 5 ? 0 : this.numberOfAttendees - 4;
  }

  get eventHours(): string {
    const { bookedSlotStartsAt, bookedSlotEndsAt, tzid } = this.args.model;
    return `${moment(bookedSlotStartsAt).tz(tzid).format('HH:mm')} - ${moment(
      bookedSlotEndsAt
    )
      .tz(tzid)
      .format('HH:mm [(]zz[)]')}`;
  }

  get eventDay(): string {
    return moment(this.args.model.bookedSlotStartsAt).format('ddd[,] MMM Do');
  }

  get buttonStyle() {
    let backgroundColor = '';
    let textColor = '';

    if (!hexIsCloseToWhite(this.args.model.companyColor)) {
      backgroundColor = this.args.model.companyColor;
      textColor = this.args.model.companyButtonTextColor;
    } else {
      backgroundColor = this.args.model.companyButtonTextColor;
      textColor = this.args.model.companyColor;
    }

    return `background-color: ${backgroundColor}; color: ${textColor};`;
  }
}
