import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import { action } from '@ember/object';
import PaymentProcessorSettingModel from 'teamtailor/models/payment-processor-setting';

import { PAYMENT_SETUP_PUSHER_TOPIC } from 'teamtailor/constants/stripe';
import CurrentService from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';

export interface PaymentModalInterface {
  paymentProcessorSetting: PaymentProcessorSettingModel;
  isModalOpened: boolean;
  onCloseModal: () => void;
  onSubmit?: () => void;
}

export default class PaymentsInAppController extends Controller {
  @service declare pusher: PusherService;
  @service declare current: CurrentService;
  @service declare flipper: FlipperService;

  @tracked
  setupModalOpened = false;

  @tracked
  paymentMethodModalOpened = false;

  private declare pusherChannel?: PusherChannel;

  get isModelPersisted() {
    return !!this.model.id;
  }

  get hasPaymentMethods() {
    return this.model.stripePaymentMethods?.length > 0;
  }

  get hasDisplayablePaymentConfig() {
    return this.isModelPersisted && this.model.display === 'show';
  }

  @action
  handlePaymentMethodRemove() {
    this.model.status = 'initial';
    this.model.reload();
  }

  @action
  billingContactModalSubmit() {
    this.setupModalOpened = false;
    this.paymentMethodModalOpened = true;
  }

  @action
  getStarted() {
    if (this.hasDisplayablePaymentConfig) {
      this.paymentMethodModalOpened = true;
    } else {
      this.setupModalOpened = true;
    }
  }

  @action
  bindChannels() {
    this.pusher
      .subscribe(this.current.company.mainChannel)
      .then((pusherChannel) => {
        this.pusherChannel = pusherChannel;
        this.pusherChannel.bind(PAYMENT_SETUP_PUSHER_TOPIC, () => {
          this.model.status = 'saved';
          this.model.reload();
          this.unbindChannels();
        });
      });
  }

  @action
  unbindChannels() {
    if (this.pusherChannel) {
      this.pusherChannel.unbind(PAYMENT_SETUP_PUSHER_TOPIC);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.payments.in-app': PaymentsInAppController;
  }
}
