/* import __COLOCATED_TEMPLATE__ from './detail-diff.hbs'; */
import Component from '@glimmer/component';

export default class RequisitionDetailDiffComponent extends Component {
  get customLabel() {
    const { customForm, type } = this.args;

    if (customForm) {
      const formFieldObj = customForm.form.find((f) => {
        return [f.id, f.uuid].includes(type);
      });
      return formFieldObj.label;
    }
  }

  get fieldTranslationKey() {
    switch (this.args.type) {
      case 'job_title':
        return 'requisitions.key_details.job_title';
      case 'job_description':
        return 'requisitions.key_details.job_description';
      case 'country':
      case 'attachment':
      case 'location_id':
      case 'department_id':
      case 'role_id':
        return `common.${this.args.type.replace(/_id/, '')}`;
      case 'number_of_openings':
        return 'requisitions.key_details.expected_number_of_hires';
      case 'recruiter_id':
        return 'user.recruiter';
      case 'max_salary':
        return 'custom_report.properties.salary_max';
      case 'min_salary':
        return 'custom_report.properties.salary_min';
      case 'is_draft':
        return 'requisitions.key_details.is_draft';
      default:
        return this.args.type;
    }
  }
}
