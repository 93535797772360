/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import Component from '@glimmer/component';
import { action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SearchableNumberField extends Component {
  @service store;

  get customField() {
    return this.args.customField;
  }

  get options() {
    return this.customField.options.filter((option) => {
      return !option.isDeleted;
    });
  }

  @action
  handleNewOption() {
    this.customField.options.pushObject(
      this.store.createRecord('custom-field/option', {
        rowOrder: Number.MAX_SAFE_INTEGER,
      })
    );
  }

  @action
  handleDragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, draggedItem);

    targetList.forEach((item, index) => {
      setProperties(item, {
        rowOrderPosition: index,
        rowOrder: index,
      });
    });
  }

  @action
  handleRemoveSelectOption(option) {
    if (option.id) {
      option.deleteRecord();
    } else {
      this.customField.options.removeObject(option);
      option.unloadRecord();
    }
  }
}
