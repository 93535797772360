/* import __COLOCATED_TEMPLATE__ from './error.hbs'; */
import Component from '@glimmer/component';
import { underscore } from '@ember/string';
import { tracked } from '@glimmer/tracking';

interface VideoMeetingErrorArgs {
  error: any;
  onClear: () => void;
}

const MEDIA_ERRORS = [
  'NotAllowedError',
  'NotFoundError',
  'NotReadableError',
  'OverconstrainedError',
  'TypeError',
];

export default class VideoMeetingError extends Component<VideoMeetingErrorArgs> {
  @tracked showDetails = false;

  get name() {
    return this.args.error.name;
  }

  get errorTranslationKey() {
    if (MEDIA_ERRORS.includes(this.name)) {
      return underscore(this.name);
    } else {
      return 'generic';
    }
  }
}
