/* import __COLOCATED_TEMPLATE__ from './icon.hbs'; */
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';

interface Args {
  activity: ActivityModel;
}

export default class extends Component<Args> {
  get icon() {
    switch (this.args.activity.code) {
      case 'message':
      case 'unsubscribed':
      case 'consent_extend_request':
        return 'envelope';
      case 'reference_given':
        return 'comment-check';
      case 'note':
        return 'comment';
      case 'share':
        return 'arrow-up-from-bracket';
      case 'smart_moved':
        return 'lightbulb';
      case 'created':
        return 'hand-wave';
      case 'connected':
        return 'circle-nodes';
      case 'moved':
        return 'plug';
      case 'consent_requested':
      case 'consent_given':
      case 'consent_missing':
      case 'consent_extended':
      case 'consent_extend_future_jobs':
        return 'circle-info';
      case 'data_request':
      case 'data_request_dismissed':
      case 'data_request_handled':
        return 'database';
      case 'removal_request':
        return 'triangle-exclamation';
      case 'job_offer_accepted':
      case 'job_offer_declined':
      case 'job_offer_opened':
      case 'job_offer_flow_approved':
      case 'job_offer_flow_rejected':
      case 'job_offer_flow_verdict_approved':
      case 'job_offer_flow_verdict_rejected':
      case 'job_offer_flow_verdict_reminder':
      case 'job_offer_flow_verdict_requested':
        return 'handshake';
      case 'nps_response':
        return 'chart-area';
      case 'restricted':
        return 'lock';
      case 'candidate_merged':
        return 'merge';
      case 'todo_assigned':
        return 'square-check';
      case 'nurture_campaign_added':
      case 'nurture_campaign_recipient_paused':
      case 'nurture_campaign_exited':
        return 'circle-heart';
      case 'lead':
        return 'magnet';
      case 'copilot_resume_summary':
      case 'copilot_meeting_summary':
      case 'copilot_interview_kit_questions_feedback':
        return 'sparkles';
      case 'assessment':
        return 'circle-question';
      case 'upload':
        return 'file-arrow-up';
      case 'meeting_event_candidate_invited':
        return 'calendar-plus';
      case 'meeting_event_sent_self_schedule':
      case 'meeting_event_self_schedule_no_time':
      case 'meeting_event_candidate_status_changed':
      case 'meeting_event_self_schedule_picked_time':
        return 'calendar';
      case 'hire_quality_response':
        return 'star-user';
      case 'updated_profile':
        return 'pencil';
      case 'survey_sent':
      case 'survey':
        return 'ballot-check';
      case 'questionnaire':
        return 'messages-question';
      case 'new_hire':
        return 'party-horn';
      case 'resume_uploaded':
        return 'file-user';
      case 'sms_opt_out':
        return 'mobile';
      default:
        return 'circle-question';
    }
  }

  get iconBackground() {
    switch (this.args.activity.code) {
      case 'copilot_resume_summary':
      case 'copilot_meeting_summary':
      case 'copilot_interview_kit_questions_feedback':
        return 'bg-decorative-purple-weak';
      case 'new_hire':
        return 'bg-decorative-cerise-strong';
      default:
        return 'bg-neutral-medium';
    }
  }

  get iconColor() {
    switch (this.args.activity.code) {
      case 'copilot_resume_summary':
      case 'copilot_meeting_summary':
      case 'copilot_interview_kit_questions_feedback':
        return 'text-decorative-purple';
      case 'new_hire':
        return 'text-decorative-cerise-strong';
      default:
        return 'text-neutral';
    }
  }

  get iconTriggerBackground() {
    switch (this.args.activity.code) {
      case 'share':
        return 'bg-decorative-cyan-medium';
      case 'restrict':
      case 'restricted':
        return 'bg-decorative-blue-medium';
      case 'assessment':
      case 'nurture_campaign_added':
        return 'bg-decorative-indigo-medium';
      case 'meeting_event_sent_self_schedule':
        return 'bg-decorative-lime-medium';
      case 'todo_assigned':
        return 'bg-decorative-light-blue-medium';
      case 'note':
        return 'bg-decorative-rose-medium';
      case 'smart_moved':
        return 'bg-decorative-zinc-medium';
      case 'message':
        return 'bg-decorative-yellow-medium';
      case 'survey_sent':
        return 'bg-decorative-teal-medium';
      default:
        return 'bg-neutral-medium';
    }
  }

  get iconStyle() {
    switch (this.args.activity.code) {
      case 'hire_quality_response':
      case 'meeting_event_sent_self_schedule':
      case 'meeting_event_self_schedule_no_time':
      case 'meeting_event_candidate_status_changed':
      case 'meeting_event_self_schedule_picked_time':
        return 'kit';
      default:
        return 'regular';
    }
  }
}
