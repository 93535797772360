/* import __COLOCATED_TEMPLATE__ from './nurture-campaign-schedule.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import classnames from 'teamtailor/utils/classnames';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class NurtureCampaignStepComponent extends Component {
  @service dragSort;
  @service flashMessages;
  @service current;
  @service intl;

  @tracked isDraggingWaitItem = false;

  constructor() {
    super(...arguments);
    this.dragSort.on('start', this, this.handleDragStart);
    this.dragSort.on('end', this, this.handleDragEnd);
  }

  get nurtureCampaign() {
    return this.args.nurtureCampaign;
  }

  get canAddSteps() {
    return this.nurtureCampaign.isEditable;
  }

  get missingSavedMessageSteps() {
    return this.savedMessageSteps.length === 0;
  }

  get hasMultipleSavedSteps() {
    return this.savedSteps.length > 1;
  }

  get hasSavedStep() {
    return this.savedSteps.length > 0;
  }

  get savedMessageSteps() {
    return this.nurtureCampaign.emailSteps.filterBy('isNew', false);
  }

  get savedSteps() {
    return this.nurtureCampaign.sortedNurtureCampaignSteps.filterBy(
      'isNew',
      false
    );
  }

  get lastRowOrder() {
    return this.nurtureCampaign.sortedNurtureCampaignSteps.lastObject?.rowOrder;
  }

  get nextRowOrder() {
    return this.lastRowOrder === undefined
      ? 0
      : this.lastRowOrder +
          this.nurtureCampaign.sortedNurtureCampaignSteps.length || 1;
  }

  get canDragSteps() {
    return this.nurtureCampaign.isEditable && this.hasMultipleSavedSteps;
  }

  get containerClasses() {
    return classnames('c-nurture-campaign-step-drag-container', {
      'c-nurture-campaign-step-drag-container--dragging-right':
        this.isDraggingWaitItem,
    });
  }

  handleDragEnd() {
    this.isDraggingWaitItem = false;
  }

  handleDragStart({ draggedItem }) {
    this.isDraggingWaitItem = draggedItem.stepType === 'wait';
  }

  @action
  onHandleDragEnd({ sourceList, sourceIndex, targetList, targetIndex }) {
    if (sourceList === targetList && sourceIndex === targetIndex) return;

    const step = sourceList.objectAt(sourceIndex);

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, step);
    step.rowOrderPosition = targetIndex;
    step.save();
  }

  @action
  handleDeleteStep(step) {
    if (step.stepType === 'email' && step.isNew) {
      step.waitStep.then((waitStep) => waitStep?.destroyRecord());
    }

    step.destroyRecord();
  }

  @action
  async addTouchpoint() {
    let waitStep = null;
    if (this.nurtureCampaign.isNew) {
      await this.nurtureCampaign.save();
    }

    if (this.hasSavedStep) {
      waitStep = await this.nurtureCampaign.waitSteps
        .createRecord({
          nurtureCampaign: this.nurtureCampaign,
          rowOrder: this.nextRowOrder,
          rowOrderPosition: 'last',
        })
        .save();
    }

    this.nurtureCampaign.emailSteps.createRecord({
      nurtureCampaign: this.nurtureCampaign,
      rowOrder: this.nextRowOrder,
      rowOrderPosition: 'last',
      sendAt: '09:00',
      sender: this.current.user,
      waitStep,
    });
  }

  @action
  onItemClassNames(item) {
    return classnames(
      'c-form-expandable-box-drag-item c-form-expandable-box-drag-item--thick',
      {
        'c-form-expandable-box-drag-item--email': item.stepType === 'email',
        'c-form-expandable-box-drag-item--wait': item.stepType === 'wait',
      }
    );
  }

  @action
  onCloseModal(step) {
    if (get(step, 'isNew')) {
      this.handleDeleteStep(step);
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.dragSort.off('start', this, this.handleDragStart);
    this.dragSort.off('end', this, this.handleDragEnd);
  }
}
