/* import __COLOCATED_TEMPLATE__ from './dropdown-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class TriggersDropdownList extends Component {
  @service intl;
  @service current;
  @service flipper;

  get company() {
    return this.current.company;
  }

  get otherTriggers() {
    return get(this, 'args.stage.triggers');
  }

  get hasSmartMoveTrigger() {
    return this.hasAnyTriggerOfType(['smart-move']);
  }

  get hasSmartMoveOrPredictionTrigger() {
    return this.hasAnyTriggerOfType(['smart-move', 'prediction']);
  }

  get hasRestrictedCandidatesTrigger() {
    return this.hasAnyTriggerOfType(['restrict']);
  }

  get isSmartMoveTriggerDisabled() {
    return get(this, 'args.showRejected') || this.hasSmartMoveTrigger;
  }

  get isPredictionTriggerDisabled() {
    return (
      get(this, 'args.showRejected') || this.hasSmartMoveOrPredictionTrigger
    );
  }

  get criteriaOptions() {
    const options = ['question'];

    if (this.company.hasFeature('job_offers')) {
      options.push('job_offer');
    }

    options.push('cv_content', 'location');

    return options;
  }

  hasAnyTriggerOfType(types) {
    return (
      this.otherTriggers &&
      this.otherTriggers.some((trigger) => {
        return types.includes(get(trigger, 'type'));
      })
    );
  }
}
