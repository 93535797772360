import Model, { belongsTo, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class ChannelActivationModel extends Model {
  @service server;

  @belongsTo('company') company;
  @belongsTo('channel') channel;
  @belongsTo('authorization') authorization;
  @attr('string') channelId;
  @attr('boolean') existingAgreement;
  @attr('boolean') activated;
  @attr('date') createdAt;
  @attr('number') amsSenderId;
  @attr('string') apecAccountNumber;
  @attr('string') catererUsername;
  @attr('string') catererPassword;
  @attr('string') catererGlobalUsername;
  @attr('string') catererGlobalPassword;
  @attr('string') companyReference;
  @attr('string') cvLibraryPassword;
  @attr('string') cvLibraryUsername;
  @attr('string') cvOnlineEeApiKey;
  @attr('string') cvOnlineLtApiKey;
  @attr('string') cvOnlineLvApiKey;
  @attr('string') cwjobsUsername;
  @attr('string') cwjobsPassword;
  @attr('string') ejobsToken;
  @attr('string') finnClientId;
  @attr('string') indeedEmail;
  @attr('string') indeedPhone;
  @attr('boolean', { defaultValue: true }) indeedDirectApply;
  @attr('string') jobteaserCompanyId;
  @attr('string') jobsiteUsername;
  @attr('string') jobsitePassword;
  @attr('string') jobborseSupplierId;
  @attr('string') jobborseHiringOrgId;
  @attr('string') monsterUsername;
  @attr('string') monsterPassword;
  @attr('string') oikotieIdentification;
  @attr('string') reedPostingKey;
  @attr('string') reedUsername;
  @attr('string') stepstoneDeOrganisationId;
  @attr('string') stepstoneDeSenderId;
  @attr('string') stepstoneDeHotId;
  @attr('string') hainesattractCompanyIdentifier;
  @attr('string') hainesattractPassword;
  @attr('string') totaljobsUsername;
  @attr('string') totaljobsPassword;
  @attr('string') helloworkContactEmail;
  @attr('string') linkedinLimitedListingsCompanyId;
  @attr('raw') adapterAuthorizationToken;
  @attr('string') diceBatchUsername;
  @attr('number') duration;
  @attr('string') diceBatchPassword;
  @attr('string') diceGroupId;
  @attr('string') uptrailAuthorizationToken;

  async getWebhookActivationConfig() {
    return this.server.memberAction(this, {
      action: 'channel_webhook_activation_configs',
      method: 'get',
    });
  }

  channelActivationFields = async () => {
    const activationConfig = await this.getWebhookActivationConfig();

    const fields = activationConfig?.config?.fields || [];
    return this.getFieldsWithValues(fields);
  };

  getFieldsWithValues(fields = []) {
    if (this.adapterAuthorizationToken) {
      const values = this.adapterAuthorizationToken;
      return fields.map((field) => ({
        ...field,
        value: values[field.id] || field.value,
      }));
    }

    return fields;
  }
}
