/* import __COLOCATED_TEMPLATE__ from './interval-picker.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ScheduledReport from 'teamtailor/models/scheduled-report';
import { get } from 'teamtailor/utils/get';
import moment from 'moment-timezone';
import IntlService from 'ember-intl/services/intl';

type Args = {
  scheduledReport: ScheduledReport;
};

type TypeWithTranslationKey = {
  type: string;
  translationKey: string;
};

export default class InsightsScheduleReportModalIntervalPicker extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  intervalTypes: TypeWithTranslationKey[] = [
    { type: 'weekly', translationKey: 'common.week' },
    {
      type: 'monthly',
      translationKey: 'common.month',
    },
    {
      type: 'yearly',
      translationKey: 'common.year',
    },
  ];

  monthDates = [-1, ...Array(27).keys()].map((i) => {
    let displayName = '';
    switch (i) {
      case -1:
        displayName = this.intl.t(
          'insights.molecules.schedule_report_modal.last_day_of_month'
        );
        break;
      case 0:
        displayName = this.intl.t(
          'insights.molecules.schedule_report_modal.first_day_of_month'
        );
        break;
      default:
        displayName = moment(`2023-01-1`).day(i).format('Do');
        break;
    }

    return {
      displayName,
      id: i < 0 ? i : i + 1,
    };
  });

  get possibleStartingDates() {
    let nextDate = moment();

    if (this.isWeekly) {
      let dayIndex = 0;
      if (!this.intervalDays.length) {
        return [];
      }

      return [...Array(5).keys()].map((_i) => {
        if (nextDate.weekday() >= (this.intervalDays[dayIndex] || 0)) {
          dayIndex++;
        }

        if (dayIndex >= this.intervalDays.length) {
          dayIndex = 0;
          nextDate = nextDate.add(1, 'weeks');
        }

        nextDate = nextDate.weekday(this.intervalDays[dayIndex]!);

        return {
          date: nextDate.format('YYYY-MM-DD'),
          displayName: nextDate.format('Do MMM YYYY'),
        };
      });
    }

    if (this.isMonthly) {
      let dateIndex = 0;
      const dates = get(this.args.scheduledReport.settings.interval, 'dates');

      if (!dates.length) {
        return [];
      }

      return [...Array(5).keys()].map((_i) => {
        let date = dates[dateIndex];
        let lastDayOfMonth = date === -1;
        date = lastDayOfMonth ? moment(nextDate).endOf('month').date() : date;
        if (nextDate.date() >= (date || 0)) {
          dateIndex++;
        }

        if (dateIndex >= dates.length) {
          dateIndex = 0;
          nextDate = nextDate.add(1, 'months');
        }

        date = dates[dateIndex]!;
        lastDayOfMonth = date === -1;

        if (lastDayOfMonth) {
          nextDate = nextDate.endOf('month');
        } else {
          nextDate = nextDate.date(date);
        }

        return {
          date: nextDate.format('YYYY-MM-DD'),
          displayName: nextDate.format('Do MMM YYYY'),
        };
      });
    }

    if (this.isYearly) {
      let dateIndex = 0;
      let monthIndex = 0;
      const dates = get(this.args.scheduledReport.settings.interval, 'dates');
      const months = get(this.args.scheduledReport.settings.interval, 'months');

      if (!dates.length || !months.length) {
        return [];
      }

      return [...Array(5).keys()].map((_i) => {
        let month = months[monthIndex]! - 1;

        while (nextDate.month() > (month || 0)) {
          monthIndex++;
          if (monthIndex >= months.length) {
            monthIndex = 0;
            nextDate = nextDate.add(1, 'years').month(0);
          }

          month = months[monthIndex]! - 1;
        }

        nextDate = nextDate.month(month);

        let date = dates[dateIndex]!;
        let lastDayOfMonth = date === -1;
        date = lastDayOfMonth ? moment(nextDate).endOf('month').date() : date;
        if (nextDate.date() >= (date || 0)) {
          dateIndex++;
        }

        if (dateIndex >= dates.length) {
          dateIndex = 0;

          monthIndex++;
          if (monthIndex >= months.length) {
            monthIndex = 0;
            nextDate = nextDate.add(1, 'years');
          }

          month = months[monthIndex]! - 1;
          nextDate = nextDate.month(month);
        }

        date = dates[dateIndex]!;
        lastDayOfMonth = date === -1;

        if (lastDayOfMonth) {
          nextDate = nextDate.endOf('month');
        } else {
          nextDate = nextDate.date(date);
        }

        return {
          date: nextDate.format('YYYY-MM-DD'),
          displayName: nextDate.format('Do MMM YYYY'),
        };
      });
    }
  }

  get weekdays() {
    return [1, 2, 3, 4, 5, 6, 0].map((day) => ({
      value: day,
      displayName: moment().day(day).format('ddd'),
    }));
  }

  get months() {
    return [...Array(12).keys()].map((month) => ({
      value: month + 1,
      displayName: moment().month(month).format('MMM'),
    }));
  }

  get startingDate() {
    const nextSendAt = get(this.args.scheduledReport, 'nextSendAt');

    if (!nextSendAt) {
      return this.possibleStartingDates?.[0];
    }

    return this.possibleStartingDates?.find(
      (d) => d.date === moment(nextSendAt).format('YYYY-MM-DD')
    );
  }

  get intervalType() {
    return get(this.args.scheduledReport.settings.interval, 'type');
  }

  get intervalValue() {
    return get(this.args.scheduledReport.settings.interval, 'value');
  }

  set intervalValue(value: number) {
    set(this.args.scheduledReport.settings.interval, 'value', value);
  }

  get intervalDays() {
    return get(this.args.scheduledReport.settings.interval, 'days');
  }

  get intervalDates() {
    const dates = get(this.args.scheduledReport.settings.interval, 'dates');

    return this.monthDates.filter((d) => dates.includes(d.id));
  }

  get intervalMonths() {
    return get(this.args.scheduledReport.settings.interval, 'months');
  }

  get isWeekly() {
    return this.intervalType === 'weekly';
  }

  get isMonthly() {
    return this.intervalType === 'monthly';
  }

  get isYearly() {
    return this.intervalType === 'yearly';
  }

  get isHistoricReport() {
    return this.args.scheduledReport.insightsReport;
  }

  clearNextSendAt() {
    set(this.args.scheduledReport, 'nextSendAt', undefined);
  }

  @action
  updateIntervalValue(event: InputEvent) {
    if (event.target) {
      const { interval } = this.args.scheduledReport.settings;
      set(interval, 'value', Number((event.target as HTMLInputElement).value));
      this.clearNextSendAt();
    }
  }

  @action
  updateIntervalType(value: TypeWithTranslationKey) {
    const { interval } = this.args.scheduledReport.settings;
    set(interval, 'type', value.type);
    this.clearNextSendAt();
  }

  @action
  toggleWeekday(value: number) {
    const { interval } = this.args.scheduledReport.settings;
    let { days } = interval;

    const index = days.indexOf(value);
    if (index === -1) {
      days.push(value);
    } else {
      days.removeAt(index);
    }

    days = days.sort((a, b) => {
      if (a === 0) {
        return 1;
      } else if (b === 0) {
        return -1;
      }

      return a - b;
    });

    set(interval, 'days', [...days]);
    this.clearNextSendAt();
  }

  @action
  toggleMonth(value: number) {
    const { interval } = this.args.scheduledReport.settings;
    const { months } = interval;

    const index = months.indexOf(value);
    if (index === -1) {
      months.push(value);
    } else {
      months.removeAt(index);
    }

    set(interval, 'months', [...months]);
    this.clearNextSendAt();
  }

  @action
  setDate(datePick: { displayName: string; id: number }) {
    const { interval } = this.args.scheduledReport.settings;
    const { dates } = interval;
    const { id } = datePick;

    const index = dates.indexOf(id);
    if (index === -1) {
      dates.push(id);
    } else {
      dates.removeAt(index);
    }

    set(interval, 'dates', [...dates]);
    this.clearNextSendAt();
  }

  @action
  setStartingDate(datePick: { displayName: string; date: string }) {
    const { date } = datePick;

    set(this.args.scheduledReport, 'nextSendAt', moment(date).toDate());
  }
}
