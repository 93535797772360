/* import __COLOCATED_TEMPLATE__ from './candidate-recordings.hbs'; */
import Component from '@glimmer/component';
import MeetingEventModel from 'teamtailor/models/meeting-event';
import VideoRoomModel from 'teamtailor/models/video-room';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import FlashMessageService from 'teamtailor/services/flash-message';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { CompositionStatus } from 'teamtailor/constants/video-room';
import TimeFormatService from 'teamtailor/services/time-format';

interface Args {
  events: MeetingEventModel[];
}

export default class CandidateRecordings extends Component<Args> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare timeFormat: TimeFormatService;

  @tracked declare isDeleteClicked: boolean;

  compositionStatus = CompositionStatus;

  get eventsWithBookedSlot(): MeetingEventModel[] {
    return this.args.events.filter((event) => {
      return event.bookedSlotStartsAt;
    });
  }

  @action
  getFormattedDate(date: Date): string {
    const momentDate = moment(date);
    moment.locale(this.timeFormat.locale);
    const formattedDate = momentDate.format('HH:mm:ss . DD MMM YYYY');
    return formattedDate;
  }

  @action
  async deleteRecording(record: VideoRoomModel): Promise<void> {
    try {
      await record.destroyRecord();
      this.isDeleteClicked = false;
    } catch (message) {
      this.flashMessages.error(message as string);
    }
  }
}
