/* import __COLOCATED_TEMPLATE__ from './product-updates.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';

export default class ProductUpdateWidget extends WidgetClass {
  @service intl;
  @service puff;

  get linkHref() {
    return (
      this.puff.latestUpdate?.fields?.link || 'https://updates.teamtailor.com'
    );
  }

  get linkTitle() {
    if (this.puff.latestUpdate?.fields?.link) {
      return this.intl.t('dashboard.widgets.product_updates.read_all');
    } else {
      return this.intl.t('dashboard.widgets.product_updates.see_all');
    }
  }

  get hasLink() {
    if (this.puff.latestUpdate?.fields?.link) {
      return true;
    } else if (!this.puff.latestUpdate) {
      return true;
    }
  }
}
