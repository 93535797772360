/* import __COLOCATED_TEMPLATE__ from './trigger-nurture-form.hbs'; */
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';

export default class TriggerNurtureForm extends Component {
  @service intl;
  @service store;

  get selectedNurtureCampaign() {
    const nurtureCampaignId = get(this.args.model, 'nurtureCampaignId');
    return isPresent(nurtureCampaignId)
      ? this.store.findRecord('nurture-campaign', nurtureCampaignId)
      : null;
  }

  get headerText() {
    if (this.args.model.onReject) {
      return this.intl.t('mixins.trigger_form.header_text_on_reject', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    } else {
      return this.intl.t('mixins.trigger_form.header_text_on_move', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    }
  }

  @action
  handleChange(nurtureCampaign) {
    set(this.args.model, 'nurtureCampaignId', nurtureCampaign?.id);
  }
}
