/* import __COLOCATED_TEMPLATE__ from './video-meeting-icon.hbs'; */
import Component from '@glimmer/component';

interface Args {
  providerName: string;
}

export default class VideoMeetingIcon extends Component<Args> {
  get providerIcon(): string {
    switch (this.args.providerName) {
      case 'Google Meet':
        return 'google-meet';
      case 'ms_teams':
        return 'ms-teams';
      case 'zoom':
        return 'zoom';
      case 'go_to':
        return 'go-to';
      case 'teamtailor':
      default:
        return 'teamtailor';
    }
  }
}
