/* import __COLOCATED_TEMPLATE__ from './export-model-button.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import AuditLogModel from 'teamtailor/models/audit-log';
import { dropTask } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';

type Args = {
  model: Promise<AuditLogModel>;
};

export default class ExportModelButton extends Component<Args> {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare pusher: PusherService;

  @tracked disabledExport = false;

  private declare channel?: PusherChannel;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.pusher
      .channel(this.current.user.notificationsChannel)
      .then((channel) => {
        this.channel = channel;
        channel.bind('audit-log-export-update', ({ disabled_export }) => {
          this.disabledExport = disabled_export;
        });
      });
  }

  willDestroy(): void {
    super.willDestroy();
    const { channel } = this;

    if (channel) {
      channel.unbind('audit-log-export-update');
    }
  }

  get disabledExportAuditLog(): boolean {
    return this.disabledExport || this.current.user.cannotExportAuditLog;
  }

  get exportTooltip(): string {
    if (this.disabledExportAuditLog) {
      return this.intl.t('settings.audit_log.export_tooltip');
    }

    return '';
  }

  exportAuditLog = dropTask(async () => {
    const auditLog = await this.args.model;
    await auditLog.exportEvents();
    this.disabledExport = true;
    this.flashMessages.success(
      this.intl.t('settings.audit_log.export_confirmation')
    );
  });
}
