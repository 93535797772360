import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RecentVisitsService from 'teamtailor/services/recent-visits';
import RouterService from '@ember/routing/router-service';
import EditorRoute from '../editor';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import Store from '@ember-data/store';

export default class ContentEditorIndexRoute extends Route {
  @service declare recentVisits: RecentVisitsService;
  @service declare router: RouterService;
  @service declare store: Store;

  queryParams = {
    site: { refreshModel: false },
    returnedFromSectionId: {},
  };

  async model() {
    return this.modelFor('content.editor') as ModelFrom<EditorRoute>;
  }

  afterModel(page: Awaited<ReturnType<ContentEditorIndexRoute['model']>>) {
    this.recentVisits.log(page);

    const pageType = get(page, 'pageType');

    if (pageType === 'lead') {
      get(page, 'sections')?.then(() => {
        const sectionId = page.hasMany('sections').ids()[0];
        if (!sectionId) {
          return;
        }

        this.router.replaceWith(
          'content.editor.section.index',
          get(page, 'id'),
          sectionId
        );
      });
    }

    if (pageType === 'post') {
      this.router.replaceWith('content.posts.edit', get(page, 'id'));
    }
  }
}
