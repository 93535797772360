import { action } from '@ember/object';
import { CandidateSharingCounts } from 'teamtailor/utils/company-settings';
import Controller from '@ember/controller';
import Current from 'teamtailor/services/current';
import {
  type ExpireOption,
  getExpireOptions,
} from 'teamtailor/constants/share-links';
import FlashMessagesService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import PusherService, { type PusherChannel } from 'teamtailor/services/pusher';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { task, type Task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import {
  trackedTask,
  type TaskInstance,
} from 'ember-resources/util/ember-concurrency';
import TTAlert from 'teamtailor/services/tt-alert';

export default class SettingsShareLinksController extends Controller {
  @service declare current: Current;
  @service declare flashMessages: FlashMessagesService;
  @service declare intl: IntlService;
  @service declare pusher: PusherService;
  @service declare store: Store;
  @service declare ttAlert: TTAlert;

  @tracked declare selectedOption: ExpireOption | null;
  @tracked dataRevoked: boolean = false;

  userChannel: PusherChannel | null = null;

  get company() {
    return this.current.company;
  }

  get linkExpireOptions() {
    return getExpireOptions(this.intl);
  }

  get selectedExpireOption() {
    if (this.selectedOption) {
      return this.selectedOption;
    }

    const options = this.linkExpireOptions;
    const selectedOption = options.find(
      (option) => option.value === this.company.shareLinkExpireDays
    );

    if (!selectedOption) {
      return options.find((option) => option.value === 'custom');
    }

    return selectedOption;
  }

  get showCustomExpireDays() {
    return this.selectedExpireOption?.value === 'custom';
  }

  get isSaveDisabled() {
    const changedAttributes = this.company.changedAttributes();

    return !(
      get(this.company, 'hasDirtyAttributes') &&
      ('shareLinksEnabled' in changedAttributes ||
        'shareLinkExpireDays' in changedAttributes)
    );
  }

  get candidateSharingCounts(): CandidateSharingCounts | null {
    return this.fetchCandidateSharingCountsTask.value;
  }

  get showRevokeButton() {
    if (!this.company.shareLinksEnabled) {
      return false;
    }

    const counts = this.candidateSharingCounts;
    if (counts) {
      return counts.share_link_count > 0 || counts.trigger_count > 0;
    }
  }

  get isToggleDisabled() {
    return this.showRevokeButton && !this.dataRevoked;
  }

  @action
  onSelectExpireDays(option: ExpireOption) {
    this.selectedOption = option;

    if (option.value !== 'custom') {
      this.company.shareLinkExpireDays = option.value;
    }
  }

  @action
  save() {
    this.company.save();

    this.flashMessages.success(
      this.intl.t('settings.share_links.settings_updated')
    );
  }

  @action
  revokeSharingData() {
    const confirmationMessage = `${this.intl.t('settings.share_links.revoke_sharing_data_confirmation')} 
      ${this.intl.t('settings.share_links.revoke_sharing_data_description', {
        linkCount: this.candidateSharingCounts?.share_link_count,
        triggerCount: this.candidateSharingCounts?.trigger_count,
      })}`;

    this.ttAlert.confirm(confirmationMessage, async () => {
      this.pusher
        .subscribe(this.current.user.notificationsChannel)
        .then((channel) => {
          this.userChannel = channel;
          channel.bind('candidate-sharing-data-revoked', () => {
            this.dataRevoked = true;
          });
        });

      await this.company.revokeCandidateSharingData();
    });
  }

  fetchCandidateSharingCounts: Task<CandidateSharingCounts, unknown[]> = task(
    async () => {
      return await this.company.candidateSharingCounts();
    }
  );

  fetchCandidateSharingCountsTask: TaskInstance<CandidateSharingCounts> =
    trackedTask(this, this.fetchCandidateSharingCounts);
}

declare module '@ember/controller' {
  interface Registry {
    'settings.share-links': SettingsShareLinksController;
  }
}
