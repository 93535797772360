import ApplicationAdapter from './application';
import PaymentError from 'teamtailor/utils/errors/payment-error';
import classic from 'ember-classic-decorator';

@classic
export default class CreditTransaction extends ApplicationAdapter {
  handleResponse(status, headers, payload) {
    if (status === 400) {
      return new PaymentError(payload.errors);
    }

    return super.handleResponse(...arguments);
  }
}
