/* import __COLOCATED_TEMPLATE__ from './gif.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import { shuffle } from 'ember-composable-helpers/helpers/shuffle';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import preloadImages from 'teamtailor/utils/preload-images';
import { restartableTask, timeout } from 'ember-concurrency';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import UserModel from 'teamtailor/models/user';
import GiphyImageModel from 'teamtailor/models/giphy-image';
import ArrayProxy from '@ember/array/proxy';

const IMAGE_RATIO_LIMIT = 1;

export default class GifWidget extends WidgetClass {
  @service declare store: Store;
  @service declare current: Current;
  @service declare pusher: PusherService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked declare giphyImages: ArrayProxy<GiphyImageModel> | null;
  @tracked declare searchTerm: string;

  randomOrderedIndexes: number[] = [];
  channel: PusherChannel | null = null;

  get user(): UserModel {
    return this.current.user;
  }

  get gif(): GiphyImageModel | null {
    return this.fetchGif.lastSuccessful?.value || null;
  }

  constructor(owner: unknown, args: unknown) {
    super(owner, args);
    this.setupPusher();
    this.giphyImages = null;
    this.fetchGif.perform();
  }

  setupPusher(): void {
    this.pusher
      .subscribe(this.current.company.candidatesChannel)
      .then((channel) => {
        this.channel = channel;
        channel.bind('demo-data-updated', ({ status }) => {
          if (status === 'removed') {
            this.searchTerm = 'High five';
            this.args.settings.reload();
          }
        });
      });
  }

  willDestroy() {
    super.willDestroy();
    this.teardownPusher();
    this.store.unloadAll('giphy-image');
  }

  teardownPusher(): void {
    if (this.channel) {
      this.channel.unbind('demo-data-updated');
    }
  }

  fetchGif = restartableTask(
    async (
      refetch?: boolean,
      noQueryHits?: boolean,
      searchTerm?: string
    ): Promise<GiphyImageModel | null | undefined> => {
      if (this.giphyImages === null || refetch) {
        const currentSearchTerm =
          searchTerm ?? this.args.settings.gifSearchTerm;
        if (currentSearchTerm?.length && !noQueryHits) {
          await this.store
            .query('giphy-image', {
              q: currentSearchTerm,
              offset: 0,
            })
            .then((images) => {
              if (images.length) {
                this.giphyImages = images;
              } else {
                this.fetchGif.perform(true, true);
              }
            });
        } else {
          this.giphyImages = await this.store.findAll('giphy-image');
        }
      }

      if (this.randomOrderedIndexes.length === 0) {
        const indexes = Array.from(Array(this.giphyImages?.length).keys());
        this.randomOrderedIndexes = shuffle(indexes);
      }

      const image = this.giphyImages?.objectAt(
        this.randomOrderedIndexes.pop() || 0
      );

      if (image && image.ratio < IMAGE_RATIO_LIMIT) {
        return this.fetchGif.perform();
      }

      await preloadImages([image?.url]);
      await timeout(300);
      return image;
    }
  );

  @action
  copyUrlToClipboard() {
    navigator.clipboard.writeText(this.gif?.url || '');
    this.flashMessages.success(this.intl.t('common.copied_to_clipboard'));
  }
}
