import Helper from '@ember/component/helper';
import BreadCrumbsService, {
  BreadCrumb,
} from 'teamtailor/services/bread-crumbs';
import { inject as service } from '@ember/service';

export default class Crumb extends Helper {
  @service declare breadCrumbs: BreadCrumbsService;

  breadCrumb: BreadCrumb | null = null;

  compute(
    name: [title: string],
    additionalArgs: { route: string; icon: string; model: unknown }
  ) {
    this.breadCrumb = { name: name.join(''), ...additionalArgs };
    this.breadCrumbs.addCrumb(this.breadCrumb);
  }

  willDestroy() {
    super.willDestroy();
    if (this.breadCrumb) {
      this.breadCrumbs.removeCrumb(this.breadCrumb);
    }
  }
}
