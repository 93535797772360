/* import __COLOCATED_TEMPLATE__ from './form-textarea.hbs'; */
import { tracked } from '@glimmer/tracking';
import FormInput from 'teamtailor/components/form-input';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class FormTextAreaComponent extends FormInput {
  @argDefault autoresize = false;
  @argDefault rows = 1;

  @tracked hasFocusWithin = false;
}
