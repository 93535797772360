/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { CareerSiteModel } from 'teamtailor/models';
import LocaleModel from 'teamtailor/models/locale';

type Args = {
  isOpen: boolean;
  careerSite: CareerSiteModel;
  careerSites: CareerSiteModel[];
  locales: [];
  onCareerSiteUpdate: ({
    prop,
    value,
  }: {
    prop: 'languageCode' | 'name' | 'cloneFromId';
    value: string;
  }) => void;
  onClose: () => void;
  onSave: () => void;
};

export default class ModalComponent extends Component<Args> {
  get isValid() {
    const {
      careerSite: { emoji, languageCode },
    } = this.args;
    return emoji && languageCode;
  }

  @action
  updateLocale(locale: LocaleModel) {
    this.args.onCareerSiteUpdate({ prop: 'languageCode', value: locale.code });
    this.args.onCareerSiteUpdate({ prop: 'name', value: locale.name });
  }

  @action
  handleChangeClone(careerSite: CareerSiteModel) {
    const { id } = careerSite;
    this.args.onCareerSiteUpdate({ prop: 'cloneFromId', value: id });
  }
}
