/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import SectionItemComponent from 'teamtailor/components/content-block/section-item-component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const DEFAULT_COLORS = ['#E63416', '#F58F00', '#E8C500', '#6FCC00', '#2966FA'];

export default class InfographicItemBaseComponent extends SectionItemComponent {
  @tracked newDatasetItem = {};
  @tracked colorPickerOpen = false;

  get hasColor() {
    return ['area', 'line'].includes(this.args.sectionItem.itemType);
  }

  get showNewDataSetField() {
    const exisitingItems = this.args.sectionItem.dataset?.length || 0;
    const type = this.args.sectionItem.itemType;

    return !(type === 'number' && exisitingItems > 0);
  }

  get icon() {
    switch (this.args.sectionItem.itemType) {
      case 'number':
        return 'hashtag';
      case 'area':
        return 'chart-area';
      case 'bar':
        return 'chart-simple';
      case 'line':
        return 'chart-line';
      case 'dougnhut':
        return 'chart-pie';
      default:
        return 'chart-mixed';
    }
  }

  get color() {
    if (this.hasColor) {
      return this.args.sectionItem.color;
    } else if (this.args.sectionItem.itemType === 'number') {
      return this.args.sectionItem.dataset?.firstObject?.color;
    } else if (this.args.sectionItem.dataset) {
      return `linear-gradient(${this.args.sectionItem.dataset
        .map((item) => item.color)
        .join(', ')})`;
    }

    return null;
  }

  get nextDefaultColor() {
    const lastColor = this.args.sectionItem.dataset?.lastObject?.color;
    const lastColorIndex = DEFAULT_COLORS.indexOf(lastColor);

    if (lastColorIndex >= 0 && lastColorIndex < DEFAULT_COLORS.length - 1) {
      return DEFAULT_COLORS[lastColorIndex + 1];
    } else {
      return DEFAULT_COLORS[0];
    }
  }

  @action
  async handleNewDatasetItem() {
    if (!this.args.sectionItem.dataset) {
      this.args.sectionItem.dataset = [];
    }

    if (!this.hasColor) {
      this.newDatasetItem.color = this.nextDefaultColor;
    }

    this.args.sectionItem.dataset.push(this.newDatasetItem);
    this.newDatasetItem = {};
    await this.handleSaveItem();
  }

  @action
  handleDeleteDatasetItem(item) {
    this.args.sectionItem.dataset.removeObject(item);
    this.handleSaveItem();
  }
}
