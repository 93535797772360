/* import __COLOCATED_TEMPLATE__ from './selectable-item.hbs'; */
import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';
import { isNone } from '@ember/utils';

interface Args {
  selectAll?: boolean;
  valuePath?: string;
  isSelected?: boolean;
  onSelect?: (isChecked: boolean, item: unknown) => void;
  item: never;
  selectedIds?: string[];
  deselectedIds?: string[];
}

export default class SelectableItem extends Component<Args> {
  @argDefault valuePath = 'id';
  @argDefault selectedIds: string[] = [];
  @argDefault deselectedIds: string[] = [];

  get isSelected() {
    if (!isNone(this.args.isSelected)) {
      return this.args.isSelected;
    }

    const value: string = get(this.args.item, this.valuePath);

    return (
      (this.args.selectAll && !this.deselectedIds.includes(value)) ||
      get(this, 'selectedIds').includes(value)
    );
  }

  set isSelected(value: boolean) {
    let op: 'removeObject' | 'addObject';
    if (this.args.selectAll) {
      op = value ? 'removeObject' : 'addObject';
      this.deselectedIds[op](get(this.args.item, this.valuePath));
    } else {
      op = value ? 'addObject' : 'removeObject';

      this.selectedIds[op](get(this.args.item, this.valuePath));
    }
  }

  @action
  handleCheckBox(isChecked: boolean) {
    if (typeof this.args.onSelect === 'function') {
      return this.args.onSelect(isChecked, this.args.item);
    }

    this.isSelected = !this.isSelected;
  }
}
