/* import __COLOCATED_TEMPLATE__ from './pdf-viewer.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

const observerConfig = { attributes: false, childList: true, subtree: false };

const styleBaseBoxShadow =
  '0px 4px 12px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(0, 0, 0, 0.03), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)';

export default class PdfViewer extends Component {
  @tracked src;
  @service server;

  @tracked hidden = false;
  @tracked anonymous = this.args.anonymous;

  observer = new MutationObserver(this.onPdfPagesChange.bind(this));

  get canShowPdf() {
    const canShowAnonymousVersion = this.src && !this.args.anonymousFailed;
    const canShowOriginalVersion = this.src && !this.anonymous;
    const notInfected = !this.args.isInfected;

    return (
      this.hasUrl &&
      (canShowAnonymousVersion || canShowOriginalVersion) &&
      notInfected
    );
  }

  get url() {
    let { url } = this.args;

    if (this.anonymous) {
      url += '?anonymous=true';
    }

    return url;
  }

  get hasUrl() {
    return !!this.args.url;
  }

  onPdfPagesChange(mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        this.removeDefaultBorderFromPages(mutation.target);
      }
    }
  }

  removeDefaultBorderFromPages(viewer) {
    if (viewer) {
      const pages = viewer.getElementsByClassName('page');
      Array.from(pages).forEach(function (page) {
        page.style['border-image'] = 'none';
        page.style['box-shadow'] = styleBaseBoxShadow;
        page.style.border = 'none';
      });
    }
  }

  @action
  changedUserOrStage() {
    this.anonymous = this.args.anonymous;
    this.getPdf();
  }

  @action
  handleOnLoad(e) {
    if (this.args.theme === 'light') {
      if (e.path) {
        const iFrameDocument = e.path[0].contentDocument;
        const viewer = iFrameDocument.getElementById('viewer');
        const toolbar = iFrameDocument.getElementById('toolbarContainer');

        this.observer.observe(viewer, observerConfig);

        viewer.style['background-color'] = '#F8F8F9';
        toolbar.style['background-color'] = '#34353a';
        toolbar.style['background-image'] = 'none';
        toolbar.style['box-shadow'] = styleBaseBoxShadow;
      }
    }

    this.args.onLoad?.(e);
  }

  @action
  getPdf() {
    if (!this.hasUrl) {
      return;
    }

    this.server.request(this.url).then((data) => {
      const notYetGenerated = data.url.includes('missing');

      if (notYetGenerated) return;

      let src = `https://scripts.teamtailor-cdn.com/pdf.js/v8/web/viewer.html?file=${data.escapedUrl}`;
      this.src = src;

      // First layer of resume might "flash"
      // and reveal candidate details.
      // Delay until everything has been rendered:
      if (this.anonymous) {
        this.hidden = true;

        later(() => {
          this.hidden = false;
        }, 2000);
      }
    });
  }

  @action
  toggleAnonymous() {
    this.anonymous = !this.anonymous;
    this.getPdf();
  }

  @action
  lifelineDestroy() {
    this.observer.disconnect();
  }
}
