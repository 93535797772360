/* import __COLOCATED_TEMPLATE__ from './breadcrumb.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import BreadCrumbService from 'teamtailor/services/bread-crumbs';

export default class BreadcrumbComponent extends Component {
  @service declare router: RouterService;
  @service declare breadCrumbs: BreadCrumbService;

  get currentRoute() {
    return this.router.currentRouteName;
  }
}
