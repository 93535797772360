/* import __COLOCATED_TEMPLATE__ from './refresh.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import PusherService from 'teamtailor/services/pusher';
import FlashMessageService from 'teamtailor/services/flash-message';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import UserService from 'teamtailor/services/user';
import PromotionModel from 'teamtailor/models/promotion';
import PromotionRequestsService from 'teamtailor/services/promotion-requests';

export interface PromotionActionRefreshArgs {
  promotion: PromotionModel;
  hasAdapter: boolean;
}

export default class PromotionActionRefresh extends Component<PromotionActionRefreshArgs> {
  @service declare pusher: PusherService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare user: UserService;
  @service declare promotionRequests: PromotionRequestsService;

  @action
  async handleRefresh() {
    if (this.args.hasAdapter) {
      return this.promotionRequests.refreshPromotion(this.args.promotion);
    } else {
      return this.savePromotion();
    }
  }

  @action
  async savePromotion() {
    try {
      await this.args.promotion.save();
      this.flashSuccess();
    } catch (error) {
      this.flashFailMessage();
    }
  }

  @action
  async setupPusher() {
    const channel = await this.pusher.channel(
      get(this.user, 'notificationsChannel')
    );

    channel.bind('promotion-refresh-action', ({ payload, type }) => {
      if (type === 'fail') {
        this.flashFailMessage();
      } else {
        this.flashSuccess();
      }

      this.store.pushPayload(payload);
    });
  }

  @action
  async teardownPusher() {
    const channel = await this.pusher.channel(
      get(this.user, 'notificationsChannel')
    );

    channel.unbind('promotion-refresh-action');
  }

  @action flashFailMessage() {
    this.flashMessages.error(
      this.intl.t('jobs.job.promote.promotion_update_fail')
    );
  }

  @action flashSuccess() {
    this.flashMessages.success(
      this.intl.t('jobs.job.promote.promotion_update_success')
    );
  }
}
