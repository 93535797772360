/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import CandidateModel from 'teamtailor/models/candidate';
import { argDefault } from 'teamtailor/utils/arg-default';

interface Args {
  candidate: CandidateModel;
  greyBackground?: boolean;
}

export default class extends Component<Args> {
  @argDefault greyBackground = false;

  @tracked showUnableToSummarizeResumeAlert =
    this.hasDismissedUnableToSummarizeResumeAlert;

  @tracked
  isEditing = false;

  get hasDismissedUnableToSummarizeResumeAlert() {
    return (
      this.getUnableToSummarizeResumeAlertsDismissed()[
        this.args.candidate.id
      ] || false
    );
  }

  getUnableToSummarizeResumeAlertsDismissed() {
    return JSON.parse(
      window.localStorage.getItem('unableToSummarizeResumeAlertsDismissed') ||
        '{}'
    ) as Record<string, boolean>;
  }

  @action
  markUnableToSummarizeResumeAlertAsDismissed() {
    window.localStorage.setItem(
      'unableToSummarizeResumeAlertsDismissed',
      JSON.stringify({
        ...this.getUnableToSummarizeResumeAlertsDismissed(),
        [this.args.candidate.id]: true,
      })
    );
    this.showUnableToSummarizeResumeAlert = true;
  }

  @action
  onCancelClick() {
    this.args.candidate.rollbackAttributes();
    this.isEditing = false;
  }

  onSaveClick = dropTask(async () => {
    await this.args.candidate.save();
    this.isEditing = false;
  });

  @action onUpdate() {
    this.isEditing = false;
  }
}
