import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class InterviewSerializer extends ApplicationSerializer {
  attrs = {
    answers: {
      key: 'answers_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },

    scores: {
      key: 'scorecard_scores_attributes',
      serialize: 'records',
      deserialize: 'records',
    },
  };
}
