/* import __COLOCATED_TEMPLATE__ from './locations.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { JobBulkArgs } from 'teamtailor/constants/jobs';
import LocationModel from 'teamtailor/models/location';
import CurrentService from 'teamtailor/services/current';

export default class BulkActionsJobsLocationsComponent extends Component<JobBulkArgs> {
  @service declare current: CurrentService;

  @tracked locations: LocationModel[] = [];

  @action
  onClose(): void {
    this.args.onClose();
    this.locations = [];
  }

  @action
  handleBulkChange(): void {
    const data = {
      location_ids: this.locations.mapBy('id'),
    };
    this.args.handleBulkChange(data, 'bulk_location');
    this.onClose();
  }
}
