/* import __COLOCATED_TEMPLATE__ from './description.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { MeetingEventClass } from 'teamtailor/classes/meetings';
import PreparedMessageClass from 'teamtailor/classes/prepared-message';

interface Args {
  event: MeetingEventClass;
  preparedMessage: PreparedMessageClass;
}

export default class MeetingEventRowsDescription extends Component<Args> {
  @tracked declare descriptionElement: HTMLElement;
  @tracked showDescriptionInput = false;

  get event(): MeetingEventClass {
    return this.args.event;
  }

  @action
  registerDescriptionElement(element: HTMLElement): void {
    this.descriptionElement = element;
  }

  @action
  async focusDescriptionElement(): Promise<void> {
    this.showDescriptionInput = true;
    await timeout(300);
    this.descriptionElement.focus();
  }

  @action
  descriptionElementLostFocus(): void {
    this.showDescriptionInput = false;
  }
}
