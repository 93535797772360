/* import __COLOCATED_TEMPLATE__ from './percentage.hbs'; */
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

type Args = {
  data: {
    value: number;
  };
};

export default class DataTablePercentageComponent extends Component<Args> {
  get value() {
    return this.args.data.value;
  }

  get validValue() {
    if (isNaN(this.value) || this.value === Infinity) {
      return false;
    } else {
      return !isEmpty(this.value);
    }
  }

  get percentage() {
    return (this.value * 100).toFixed(2);
  }
}
