/* import __COLOCATED_TEMPLATE__ from './general-channel.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import { trackedFunction } from 'ember-resources/util/function';
import { action } from '@ember/object';
import { GlobalCollaborateMessageModel } from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import ScrollPosition from 'teamtailor/utils/scroll-position';
import { modifier } from 'ember-modifier';
import { isEmpty } from '@ember/utils';
import { IntlService } from 'ember-intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import { registerDestructor } from '@ember/destroyable';

type Args = {
  channel: string;
};
export default class GeneralChannelComponent extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare pusher: PusherService;

  @tracked messages: GlobalCollaborateMessageModel[] = [];
  declare scrollPosition: ScrollPosition;
  declare channel: PusherChannel;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.bindPusher();

    registerDestructor(this, () => {
      this.teardownPusher();
    });
  }

  resolveCompanyMessagesResponses = trackedFunction(this, async () => {
    const messages: GlobalCollaborateMessageModel[] = await this.store.query(
      'global-collaborate-message',
      {
        channel: this.args.channel,
      }
    );
    this.messages = messages.slice();
    return this.messages;
  });

  registerScrollbar = modifier((element: HTMLElement, [_companyMessages]) => {
    this.scrollPosition = new ScrollPosition(element);
    this.scrollToBottom();
  });

  get isLoading() {
    return (
      this.resolveCompanyMessagesResponses.isLoading ||
      this.resolveCompanyMessagesResponses.value === null
    );
  }

  get companyMessages() {
    if (!isEmpty(this.messages)) return this.messages;
    return this.resolveCompanyMessagesResponses.value ?? [];
  }

  @action
  addMessage(message: GlobalCollaborateMessageModel) {
    if (!this.messages.includes(message)) {
      this.messages = [...this.messages, message];
    }

    this.scrollToBottom();
  }

  @action
  async destroyMessage(message: GlobalCollaborateMessageModel) {
    message.deleteRecord();
    try {
      await message.destroyRecord();
      this.messages = this.messages.filter((m) => m !== message);
    } catch (e) {
      message.rollbackAttributes();
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  async bindPusher() {
    this.channel = await this.pusher.channel(
      this.current.user.notificationsChannel
    );

    this.channel.bind('new_general_message', async (data) => {
      const model = await this.store.findRecord(
        'global-collaborate-message',
        data.message_id
      );

      // Ignore this message if it was sent by the current user
      if (model.belongsTo('user').id() !== this.current.user.id) {
        this.addMessage(model);
      }
    });
  }

  teardownPusher() {
    this.channel.unbind('new_general_message');
  }

  scrollToBottom() {
    this.scrollPosition.scrollToBottom();
  }
}
