/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import RouterService from '@ember/routing/router-service';
import PermissionsService from 'teamtailor/services/permissions';
import ChannelModel from 'teamtailor/models/channel';
import IntlService from 'ember-intl/services/intl';
import JobModel from 'teamtailor/models/job';
import { get } from 'teamtailor/utils/get';
import PromotionRequestsService from 'teamtailor/services/promotion-requests';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';

export type Variants =
  | 'default'
  | 'ongoingPromotionDefault'
  | 'ongoingPromotionExpiresSoon'
  | 'ongoingPromotionUntilTakenDown'
  | 'alwaysIncluded'
  | 'mostPopular'
  | 'activated'
  | 'discounted'
  | 'bundle'
  | 'free'
  | 'error'
  | 'pending'
  | 'outOfService';

const APPEARANCES: {
  [key in Variants]: {
    cardBg: string;
    badgeText?: string;
    badgeColor?:
      | 'amber'
      | 'orange'
      | 'lime'
      | 'green'
      | 'emerald'
      | 'teal'
      | 'cyan'
      | 'light-blue'
      | 'blue'
      | 'indigo'
      | 'violet'
      | 'purple'
      | 'fuchsia'
      | 'yellow'
      | 'zinc'
      | 'cerise'
      | 'rose'
      | 'red'
      | 'success'
      | 'warning'
      | 'danger'
      | 'info';
    badgeTooltip?: string;
  };
} = {
  default: {
    cardBg: 'bg-neutral',
  },

  activated: {
    cardBg: 'bg-neutral',
    badgeText: 'components.promotion.card.badge.your_channel',
    badgeColor: 'zinc',
  },

  discounted: {
    cardBg: 'bg-decorative-indigo',
    badgeText: 'components.promotion.card.save_discount',
    badgeColor: 'indigo',
  },

  bundle: {
    cardBg: 'bg-decorative-violet',
    badgeText: 'components.promotion.card.badge.bundle',
    badgeColor: 'violet',
  },

  free: {
    cardBg: 'bg-neutral',
    badgeText: 'components.promotion.card.badge.free',
    badgeColor: 'green',
  },

  outOfService: {
    cardBg: 'bg-neutral-medium',
    badgeText: 'components.promotion.card.badge.out_of_service',
    badgeColor: 'amber',
    badgeTooltip: 'components.promotion.card.badge.out_of_service_tooltip',
  },

  ongoingPromotionDefault: {
    badgeText: 'components.promotion.card.badge.days_left',
    badgeColor: 'zinc',
    badgeTooltip: 'components.promotion.card.badge.promotion_created_at',
    cardBg: 'bg-neutral',
  },

  ongoingPromotionExpiresSoon: {
    badgeText: 'components.promotion.card.badge.days_left',
    badgeColor: 'amber',
    badgeTooltip: 'components.promotion.card.badge.promotion_created_at',
    cardBg: 'bg-decorative-amber',
  },

  ongoingPromotionUntilTakenDown: {
    badgeText: 'components.promotion.card.badge.until_taken_down',
    badgeColor: 'zinc',
    badgeTooltip: 'components.promotion.card.badge.promotion_created_at',
    cardBg: 'bg-neutral',
  },

  alwaysIncluded: {
    badgeText: 'components.promotion.card.badge.always_included',
    badgeColor: 'green',
    badgeTooltip: 'components.promotion.card.badge.always_included_tooltip',
    cardBg:
      'bg-[radial-gradient(ellipse_at_10%_100%,_var(--tw-gradient-stops))] from-yellow-50 via-pink-50/50 to-pink-50/50 dark:from-yellow-900/25 dark:via-pink-900/25 dark:to-pink-900/25 dark:bg-neutral from-10% via-60% to-100%',
  },

  mostPopular: {
    badgeText: 'components.promotion.card.badge.most_popular',
    badgeColor: 'cerise',
    badgeTooltip: 'components.promotion.card.badge.more_popular_tooltip',
    cardBg: 'bg-neutral',
  },

  error: {
    badgeText: 'components.promotion.card.badge.error',
    badgeColor: 'red',
    cardBg: 'bg-neutral',
  },

  pending: {
    badgeText: 'components.promotion.card.badge.pending',
    badgeColor: 'fuchsia',
    cardBg: 'bg-neutral',
  },
};

interface PromotionCardArgs {
  channel: ChannelModel;
  job: JobModel;
  showCountry?: boolean;
  isOngoing?: boolean;
  isRecommended?: boolean;
  isYourChannel?: boolean;
}

export default class PromotionCard extends Component<PromotionCardArgs> {
  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare permissions: PermissionsService;
  @service declare promotionRequests: PromotionRequestsService;

  @tracked hasEnteredViewport = false;

  get channelNameId() {
    return `channel-name-${guidFor(this)}`;
  }

  get promotions() {
    // always included doesn't have promotions
    if (get(this.args.channel, 'isAlwaysIncluded')) return [];
    return get(this.args.job, 'promotions').filterBy(
      'channel.id',
      get(this.channel, 'id')
    );
  }

  get channel() {
    return get(this.args, 'channel');
  }

  get appearance() {
    return APPEARANCES[this.variantName];
  }

  get badgeText() {
    if (!this.appearance.badgeText) return '';

    switch (this.variantName) {
      case 'ongoingPromotionExpiresSoon':
      case 'ongoingPromotionDefault':
        return `${this.intl.t(this.appearance.badgeText, {
          days: this.ongoingPromotion?.daysLeft,
        })}`;

      case 'discounted':
        return this.intl.t(this.appearance.badgeText, {
          percent: this.channel.discountInPercent,
        });
      default:
        return this.intl.t(this.appearance.badgeText);
    }
  }

  get badgeTooltip() {
    if (!this.appearance.badgeTooltip) return '';

    if (this.variantName.includes('ongoingPromotion')) {
      return this.intl.t(this.appearance.badgeTooltip, {
        date: moment(this.ongoingPromotion?.createdAt).format('YYYY-MM-DD'),
      });
    }

    return this.intl.t(this.appearance.badgeTooltip);
  }

  get ongoingPromotion() {
    return this.promotions.find(
      (promotion) => !promotion.isExpired && !promotion.deleteRequestSent
    );
  }

  get variantName(): Variants {
    if (this.channel.isOutOfService) return 'outOfService';
    if (this.channel.isBundle) {
      return 'bundle';
    }

    if (this.ongoingPromotion) {
      if (this.ongoingPromotion.status === 'pending') {
        return 'pending';
      }

      if (!this.ongoingPromotion.successfullyPublished) return 'error';
      if (!this.args.channel.duration) return 'ongoingPromotionUntilTakenDown';
      return this.ongoingPromotion.daysLeft > 2
        ? 'ongoingPromotionDefault'
        : 'ongoingPromotionExpiresSoon';
    }

    if (this.args.isRecommended) {
      return this.getRecommendedVariant();
    }

    if (this.channel.discountInPercent && !this.isExistingAgreement) {
      return 'discounted';
    }

    if (this.channel.isAlwaysIncluded) {
      return 'alwaysIncluded';
    }

    if (this.channel.isFree) {
      return 'free';
    }

    if (get(this.channel, 'isAlwaysIncluded')) return 'alwaysIncluded';

    return 'default';
  }

  get bgClass() {
    return this.appearance.cardBg;
  }

  get country() {
    if (!this.args.showCountry) return;
    return this.channel.countries.length > 1
      ? this.intl.t('components.promotion.card.multiple_countries')
      : this.channel.country;
  }

  get allPromotionsAreExpired() {
    return (
      this.promotions.isEvery('isExpired', true) &&
      this.promotions.isEvery('deleteRequestSent', false)
    );
  }

  get isPromotable() {
    return this.args.job.isPromotable || this.channel.name === 'Experis';
  }

  get showUnpublishButton() {
    return this.args.channel.isActivated && this.args.channel.isAlwaysIncluded;
  }

  get isNotActivated() {
    return !this.channel.channelActivation && this.isPromotable;
  }

  get isExistingAgreement() {
    return get(get(this.channel, 'channelActivation'), 'existingAgreement');
  }

  get isClickable() {
    if (this.channel.isOutOfService) return false;
    if (this.isSandboxOwner) return true;
    if (!get(this.current.company, 'currentLiveCustomer')) return false;
    if (
      !get(this.current.company, 'currentLiveCustomer') &&
      !get(this.current.company, 'sandbox')
    )
      return false;
    if (this.isNotActivated) return true;

    return (
      !this.args.isOngoing &&
      !this.channel.isAlwaysIncluded &&
      this.channel.isActivated &&
      this.isPromotable &&
      this.permissions.has('promote/manage')
    );
  }

  get isSandboxOwner() {
    if (!this.current.company.sandbox) return false;

    return this.current.company.sandboxActivations.findBy(
      'channel.id',
      this.channel.id
    );
  }

  get route() {
    if (!this.channel.isActivated) {
      if (this.channel.internalName === 'indeed_oauth') {
        return 'marketplace.channels.index.channel.indeed_employer_setup';
      }

      return 'jobs.job.promote.channel.activate';
    }

    if (this.channel.redirectToAdapterRoute) {
      return 'jobs.job.promote.channel.external';
    }

    return 'jobs.job.promote.pick';
  }

  get showActionButtons() {
    return this.args.isOngoing && !this.args.channel.isAlwaysIncluded;
  }

  get outOfServiceMessage() {
    return this.intl.t(
      `components.promotion.card.out_of_service.${this.args.channel.outOfServiceMessage}`
    );
  }

  @action getRecommendedVariant(): Variants {
    if (this.channel.discountInPercent && !this.isExistingAgreement) {
      return 'discounted';
    }

    if (this.channel.isAlwaysIncluded) {
      return 'alwaysIncluded';
    }

    if (this.channel.isFree) {
      return 'free';
    }

    return 'default';
  }

  @action
  onClick() {
    if (!this.isClickable) return;

    this.router.transitionTo(this.route, this.channel, {
      queryParams: { currency: this.channel.currency },
    });
  }

  @action
  onEnter() {
    this.hasEnteredViewport = true;
  }
}
