/* import __COLOCATED_TEMPLATE__ from './candidate-event-detail.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import {
  CandidateModel,
  InterviewModel,
  InterviewKitModel,
  JobModel,
  MeetingEventModel,
  MeetingEventAttendeeModel,
  VideoMeetingModel,
  VideoRoomModel,
  MeetingRecordingModel,
} from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import { action } from '@ember/object';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import TimeFormatService from 'teamtailor/services/time-format';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import Plyr from 'plyr';
import { next, scheduleOnce } from '@ember/runloop';
import RouterService from '@ember/routing/router-service';

interface Args {
  event: MeetingEventModel;
  candidate?: CandidateModel;
  job?: JobModel;
}

type EventConfigKey = 'upcoming' | 'pending' | 'ongoing' | 'concluded';

const EventConfigs: Record<
  EventConfigKey,
  {
    icon: string;
    tailwindClasses: { bg: string; text: string; textWeak: string };
    translationKey: string;
  }
> = {
  upcoming: {
    icon: 'circle-dashed',
    tailwindClasses: {
      bg: 'bg-decorative-cyan',
      text: 'text-decorative-cyan',
      textWeak: 'text-decorative-cyan-weak',
    },

    translationKey: 'components.meetings.candidate_events.upcoming',
  },

  pending: {
    icon: 'circle-ellipsis',
    tailwindClasses: {
      bg: 'bg-decorative-amber',
      text: 'text-decorative-amber',
      textWeak: 'text-decorative-amber-weak',
    },

    translationKey: 'components.meetings.candidate_events.pending',
  },

  ongoing: {
    icon: 'circle-dot',
    tailwindClasses: {
      bg: 'bg-decorative-green',
      text: 'text-decorative-green',
      textWeak: 'text-decorative-green-weak',
    },

    translationKey: 'components.meetings.candidate_events.ongoing',
  },

  concluded: {
    icon: 'circle-check',
    tailwindClasses: {
      bg: 'bg-neutral-weak',
      text: 'text-neutral-medium',
      textWeak: 'text-neutral',
    },

    translationKey: 'components.meetings.candidate_events.concluded',
  },
};

export default class CandidateEventDetail extends Component<Args> {
  @service declare timeFormat: TimeFormatService;
  @service declare current: Current;
  @service declare pusher: PusherService;
  @service declare store: Store;
  @service declare router: RouterService;

  @tracked declare selectedVideoRoom: VideoRoomModel | undefined;
  @tracked declare plyrInstance: Plyr | undefined;
  @tracked declare transcript: string;
  @tracked isLoadingTranscript = false;
  @tracked expanded = false;
  @tracked editingCopilotSummary = false;
  @tracked declare selectedMenuItem: string | undefined;
  @tracked menuItems: string[] = [];

  plyrStartTime: number | undefined;

  private declare pusherChannels?: PusherChannel[];

  get event(): MeetingEventModel {
    return this.args.event;
  }

  get location() {
    const meetingLocation = get(this.args.event, 'meetingEventLocation');
    const location = get(meetingLocation, 'location');
    const customLocation = get(meetingLocation, 'customLocation');

    return get(location, 'fullAddress')
      ? get(location, 'fullAddress')
      : get(customLocation, 'description');
  }

  get videoMeeting() {
    const meetingLocation = get(this.args.event, 'meetingEventLocation');
    return get(meetingLocation, 'videoMeeting');
  }

  get meetingRecording() {
    return get(this.args.event, 'meetingRecording');
  }

  get eventConfig() {
    let key: EventConfigKey;

    if (this.upcomingEvent) {
      key = 'upcoming';
    } else if (this.pendingEvent) {
      key = 'pending';
    } else if (this.ongoingEvent) {
      key = 'ongoing';
    } else {
      key = 'concluded';
    }

    return EventConfigs[key];
  }

  get eventIcon() {
    return this.eventConfig.icon;
  }

  get tailwindClasses() {
    return this.eventConfig.tailwindClasses;
  }

  get eventStatusKey() {
    return this.eventConfig.translationKey;
  }

  get ongoingEvent() {
    if (
      this.args.event.bookedSlotStartsAt &&
      this.args.event.bookedSlotEndsAt
    ) {
      const now = moment();

      return (
        moment(this.args.event.bookedSlotStartsAt) < now &&
        moment(this.args.event.bookedSlotEndsAt) > now
      );
    }

    return false;
  }

  get upcomingEvent() {
    return moment(this.args.event.bookedSlotStartsAt) > moment();
  }

  get pendingEvent() {
    return !this.eventDate;
  }

  get concludedEvent(): boolean {
    return moment(this.args.event.bookedSlotStartsAt) < moment();
  }

  get eventDate(): string | undefined {
    if (this.event.bookedSlotStartsAt) {
      const { tzid, bookedSlotStartsAt } = this.event;

      return `${moment(bookedSlotStartsAt)
        .tz(tzid)
        .locale(this.timeFormat.locale)
        .format(`ddd LL`)}`;
    }

    return undefined;
  }

  get eventTime(): string | undefined {
    if (this.args.event.bookedSlotEndsAt) {
      const { tzid, bookedSlotEndsAt, bookedSlotStartsAt } = this.args.event;

      return `${moment(bookedSlotStartsAt)
        .tz(tzid)
        .locale(this.timeFormat.locale)
        .format(this.timeFormat.format)} - ${moment(bookedSlotEndsAt)
        .tz(tzid)
        .locale(this.timeFormat.locale)
        .format(`${this.timeFormat.format} (z)`)}`;
    }

    return undefined;
  }

  get bookedDate(): string | undefined {
    const { bookedSlotStartsAt } = this.args.event;
    if (bookedSlotStartsAt) {
      return moment(bookedSlotStartsAt).toLocaleString();
    }

    return undefined;
  }

  get currentUserAttendeeId(): string {
    const attendee = this.event.userAttendees?.find(
      (u) => u.userId === this.current.user.id
    );
    if (attendee) {
      return attendee.id;
    }

    return '';
  }

  get shouldLeaveFeedback(): boolean {
    return (
      !!this.args.candidate &&
      !!this.event.interviewKitId &&
      !!this.args.job &&
      !!this.event.userAttendees &&
      this.currentUserHasInterview(this.event.userAttendees) &&
      this.jobHasKit(this.event.interviewKitId) &&
      this.userHasNotLeftFeedback(
        get(this.args.job, 'id'),
        this.event.interviewKitId
      )
    );
  }

  get candidateInterviews() {
    return this.args.candidate?.interviews;
  }

  get hasCopilotSummary() {
    return !!get(this.args.event, 'copilotSummary');
  }

  get hasChatHistory() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return get(this.videoMeeting, 'chatHistory')?.length;
  }

  get showMeetingDetails() {
    return (
      this.hasCopilotSummary ||
      this.hasVideos ||
      this.hasChatHistory ||
      this.hasMeetingRecording
    );
  }

  get hasVideos() {
    return !!this.videoRoomCount;
  }

  get hasMeetingRecording() {
    return !!get(this.meetingRecording, 'id');
  }

  get videoRoomCount() {
    return get(this.videoRooms, 'length') || 0;
  }

  get videoRooms() {
    return get(this.videoMeeting, 'videoRooms');
  }

  get hasMultipleVideos() {
    return this.videoRoomCount > 1;
  }

  get displayVideoRoomSelect() {
    return (
      ['recording', 'transcript'].includes(this.selectedMenuItem || '') &&
      this.hasMultipleVideos
    );
  }

  get transcriptModel() {
    return this.selectedVideoRoom || this.meetingRecording;
  }

  currentUserHasInterview(userAttendees: MeetingEventAttendeeModel[]): boolean {
    const userAttendee = userAttendees.find(
      (attendee) => get(attendee, 'userId') === this.current.user.id
    );
    return userAttendee?.hasInterview ?? false;
  }

  jobHasKit(interviewKitId: number): boolean {
    const id = interviewKitId.toString();
    const job = get(this.args, 'job');
    return !!get(job, 'interviewKits')
      ?.slice()
      .some(
        (interviewKit: InterviewKitModel) => get(interviewKit, 'id') === id
      );
  }

  userHasNotLeftFeedback(jobId: string, interviewKitId: number): boolean {
    const id = interviewKitId.toString();

    return !this.candidateInterviews
      ?.slice()
      .some((interview: InterviewModel) => {
        const interviewKit = get(interview, 'interviewKit');
        const interviewJob = get(interview, 'job');
        const interviewUser = get(interview, 'user');
        return (
          get(interviewKit, 'id') === id &&
          get(interviewJob, 'id') === jobId &&
          get(interviewUser, 'id') === this.current.user.id
        );
      });
  }

  updateRoom(data: { video_room: VideoRoomModel }) {
    this.store.pushPayload(data);

    this.event.meetingEventLocation.then((eventLocation) => {
      eventLocation?.videoMeeting.then((videoMeeting) => {
        const { video_room }: { video_room: VideoRoomModel } = data;
        const videoRoom = this.store.peekRecord('video-room', video_room.id);

        if (videoRoom) {
          videoMeeting?.videoRooms.pushObject(videoRoom);
        }
      });
    });
  }

  subscribeToRoom(room: VideoRoomModel) {
    this.pusher
      .subscribe(`video-room-${room.sid}`)
      .then((channel: PusherChannel) => {
        this.pusherChannels?.push(channel);
        channel.bind('room-updated', (data) => this.updateRoom(data));
        channel.bind('transcription-updated', (data) => this.updateRoom(data));
      });
  }

  subscribeToVideoMeeting(videoMeeting: VideoMeetingModel) {
    this.pusher
      .subscribe(`video-meeting-${videoMeeting.uuid}`)
      .then((channel: PusherChannel) => {
        this.pusherChannels?.push(channel);
        channel.bind('room-created', (data) => {
          const { video_room }: { video_room: VideoRoomModel } = data;
          this.subscribeToRoom(video_room);
          this.updateRoom(data);
        });
      });
  }

  setupView = async () => {
    const meetingRecording = await this.meetingRecording;
    const items: string[] = [];

    if (this.hasCopilotSummary) {
      items.push('summary');
    }

    if (this.hasVideos) {
      items.push('recording');
      items.push('transcript');
    }

    if (meetingRecording) {
      items.push('meeting_recording');
    }

    if (meetingRecording?.isTranscriptionAvailable) {
      items.push('transcript');
    }

    if (this.hasChatHistory) {
      items.push('chat_history');
    }

    this.menuItems = items;
    this.selectedMenuItem = items[0];

    const videoRooms = await get(this.videoMeeting, 'videoRooms');
    const videoRoom = get(videoRooms, '0');

    if (videoRoom) {
      this.selectedVideoRoom = videoRoom;
      await this.fetchTranscriptJson(videoRoom);
    } else if (meetingRecording) {
      await this.fetchTranscriptJson(meetingRecording);
    }
  };

  async fetchTranscriptJson(model: VideoRoomModel | MeetingRecordingModel) {
    const { isTranscriptionAvailable, transcriptionJsonUrl } = model;

    if (isTranscriptionAvailable && transcriptionJsonUrl) {
      const transcriptKey = `transcript-${model.id}`;
      const localTranscription = localStorage.getItem(transcriptKey);
      if (localTranscription) {
        this.transcript = JSON.parse(localTranscription) as string;
      } else {
        this.isLoadingTranscript = true;
        const url = new URL(transcriptionJsonUrl);
        const response = await fetch(url);
        const json = await response.json();

        this.transcript = json as string;
        localStorage.setItem(transcriptKey, JSON.stringify(json));
        this.isLoadingTranscript = false;
      }
    }
  }

  @action
  toggleMeetingDetails() {
    this.expanded = !this.expanded;

    if (this.expanded && this.menuItems.length === 0) {
      scheduleOnce('afterRender', this, 'setupView');
    }
  }

  @action
  handleSelectVideoRoom(videoRoom: VideoRoomModel | undefined) {
    this.selectedVideoRoom = videoRoom;
    if (videoRoom) {
      this.plyrInstance = undefined;
      this.fetchTranscriptJson(videoRoom);
    }
  }

  @action
  async handleInsert() {
    const meetingLocation = await get(this.event, 'meetingEventLocation');
    const videoMeeting = await get(meetingLocation, 'videoMeeting');
    if (videoMeeting) {
      this.subscribeToVideoMeeting(videoMeeting);

      videoMeeting.videoRooms.forEach((room: VideoRoomModel) => {
        if (!room.isCompositionAvailable) {
          this.subscribeToRoom(room);
        }
      });
    }
  }

  @action
  getPlyrInstance(plyr: Plyr) {
    this.plyrInstance = plyr;

    if (this.plyrStartTime) {
      this.plyrInstance.on('playing', () => {
        if (this.plyrInstance && this.plyrStartTime) {
          this.plyrInstance.currentTime = this.plyrStartTime;
          this.plyrStartTime = 0;
        }
      });
    }
  }

  @action
  handleVideoPlayerTime(
    seconds: number,
    model: VideoRoomModel | MeetingRecordingModel
  ) {
    this.plyrStartTime = seconds;

    if (model instanceof VideoRoomModel && this.selectedVideoRoom !== model) {
      this.selectedVideoRoom = model;
    }

    this.selectedMenuItem =
      model instanceof VideoRoomModel ? 'recording' : 'meeting_recording';

    next(() => {
      if (this.plyrInstance) {
        this.plyrInstance.currentTime = seconds;

        if (this.plyrInstance.paused || this.plyrInstance.stopped) {
          this.plyrInstance.play();
        }
      }
    });
  }

  willDestroy(): void {
    super.willDestroy();
    if (this.pusherChannels?.length) {
      this.pusherChannels.forEach((channel: PusherChannel) => {
        channel.unsubscribe();
      });
    }
  }
}
