/* import __COLOCATED_TEMPLATE__ from './carousel.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';

export default class InsightsWidgetsJobAudience extends Component {
  @tracked currentIndex = 0;
  @alias('args.numberOfWidgets') numberOfDots;
}
