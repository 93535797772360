/* import __COLOCATED_TEMPLATE__ from './summary-card.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class InsightsMoleculesSummaryCard extends Component {
  @action
  computeIcon(property) {
    switch (property) {
      case 'inboxCount':
        return 'envelope';
      case 'inProcessCount':
        return 'arrow-right';
      case 'rejectedCount':
        return 'ban';
      case 'hiredCount':
        return 'thumbs-up';
      case 'totalApplicants':
        return 'user';
      case 'totalJobs':
        return 'briefcase-blank';
      default:
        return 'circle';
    }
  }

  @action
  hasHires(stats) {
    return stats.hiredCount > 0;
  }
}
