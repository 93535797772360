/* import __COLOCATED_TEMPLATE__ from './user-switcher.hbs'; */
import Component from '@glimmer/component';
import { action, get, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const USER_ROLES = [
  'designer',
  'user',
  'hiring_manager',
  'recruiter',
  'recruitment_admin',
  'admin',
  'external_recruiter',
];

export default class UserSwitcher extends Component {
  @service current;
  @service store;
  @service session;
  @service bootData;

  @tracked show = false;

  userRoles = USER_ROLES;

  get user() {
    return this.current.user;
  }

  @action toggleShow() {
    this.show = !this.show;
  }

  @action
  async onChangeRole(role) {
    const user = await this.store.findRecord('user', get(this, 'user.id'));

    let recruitingFirm = null;

    if (role === 'external_recruiter') {
      const recruitingFirms = await this.store.findAll('recruitingFirm');
      recruitingFirm = get(recruitingFirms, 'firstObject');
    }

    setProperties(user, {
      role,
      recruitingFirm,
    });

    await user.save();
    this.args.onRefresh();
  }

  @action
  async onChangeUser(user) {
    await get(this, 'session').authenticate('authenticator:token', {
      user_id: get(user, 'id'),
    });

    window.location.reload();
  }
}
