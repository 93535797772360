/* import __COLOCATED_TEMPLATE__ from './toggle-publish.hbs'; */
import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import JobModel from 'teamtailor/models/job';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';

export interface PromotionActionTogglePublishArgs {
  job: JobModel;
  channelInternalName: string;
}

export default class PromotionActionTogglePublish extends Component<PromotionActionTogglePublishArgs> {
  @service declare intl: IntlService;
  @service declare store: Store;

  get isExcluded(): boolean {
    const excluded = this.args.job.excludeFromFeeds || [];
    return excluded.includes(this.args.channelInternalName);
  }

  @action
  async publishToggle() {
    const excludedList = this.args.job.excludeFromFeeds || [];
    const index = excludedList.indexOf(this.args.channelInternalName);
    const isPublished = index > -1;

    if (isPublished) {
      excludedList.splice(index, 1);
    } else {
      excludedList.push(this.args.channelInternalName);
    }

    this.args.job.excludeFromFeeds = excludedList;

    if (!this.args.job.jobDetail.isFulfilled) {
      // without jobdetail populated save will fail
      await this.store.query('job-detail', { ids: [get(this.args.job, 'id')] });
    }

    return this.args.job.save();
  }
}
