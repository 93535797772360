import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ActivitySerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    automaticEmail: { serialize: false },
    commentAuthor: { serialize: false },
    createdAt: { serialize: false },
    createdByTrigger: { serialize: false },
    date: { serialize: false },
    department: { serialize: false },
    feature: { serialize: false },
    interviewKitName: { serialize: false },
    jobOffer: { serialize: false },
    mentionedUserIds: { serialize: false },
    mentionedUsernames: { serialize: false },
    message: { serialize: false },
    newRecruiter: { serialize: false },
    npsResponse: { serialize: false },
    nurtureCampaign: { serialize: false },
    nurtureCampaignExitReason: { serialize: false },
    nurtureCampaignName: { serialize: false },
    nurtureCampaignStep: { serialize: false },
    partner: { serialize: false },
    privateNoteUsers: { serialize: 'ids' },
    questionnaire: { serialize: false },
    reference: { serialize: false },
    rejectReason: { serialize: false },
    requisition: { serialize: false },
    role: { serialize: false },
    todoAssigner: { serialize: false },
    todoValue: { serialize: false },
    trigger: { serialize: false },
    upload: { serialize: 'records' },
    user: { serialize: false },
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    activity: ActivitySerializer;
  }
}
