/* import __COLOCATED_TEMPLATE__ from './invoice-address-form.hbs'; */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import classnames from 'teamtailor/utils/classnames';

export default Component.extend({
  current: service(),

  promotion: null,
  theme: null,

  isDarkTheme: equal('theme', 'dark'),

  formLabelClasses: computed('isDarkTheme', function () {
    return classnames('tt-form-label-text', {
      'tt-dark-theme': get(this, 'isDarkTheme'),
    });
  }),

  textareaClasses: computed('isDarkTheme', function () {
    return get(this, 'isDarkTheme') ? 'tt-form-dark' : '';
  }),
});
