/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import { RoleModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import FlipperService from 'teamtailor/services/flipper';
import Current from 'teamtailor/services/current';

export default class RolesFormComponent extends TranslatableFormBaseComponent<RoleModel> {
  @service declare current: Current;
  @service declare flipper: FlipperService;

  translationsProps = ['name'];

  get role() {
    return get(this, 'translatableModel') as RoleModel;
  }

  get departments() {
    let departments = get(
      this.current.company,
      'departmentsWithoutNoDepartment'
    );

    return departments;
  }
}
