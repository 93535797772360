/* import __COLOCATED_TEMPLATE__ from './question-filter-number.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

type QuestionFilters = {
  number?: number;
};

interface QuestionFilterNumberArgs {
  updateFilter({ number }: { number: string }): void;
  filter: QuestionFilters;
}

export default class QuestionFilterNumber extends Component<QuestionFilterNumberArgs> {
  get value(): number {
    return this.args.filter.number ?? 0;
  }

  set value(val: number) {
    this.args.filter.number = val;
  }

  @action
  updateFilter(): void {
    this.args.updateFilter({ number: this.value.toString() });
  }
}
