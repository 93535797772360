/* import __COLOCATED_TEMPLATE__ from './tag.hbs'; */
import BaseComponent from './base';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class TagComponent extends BaseComponent {
  @tracked newTags = [];

  @action
  handleTagSelect(tag) {
    toggleInList(this.newTags, tag);
  }

  @action
  tagCandidates() {
    let promise = this.postAction(
      'bulk_tag',
      this.postData({ tags: this.newTags.mapBy('name') })
    );

    promise.then(() => {
      this.store.peekAll('candidate').forEach((candidate) => {
        candidate.reload();
      });
      this.newTags.clear();
    });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
