/* import __COLOCATED_TEMPLATE__ from './message-list.hbs'; */
import Component from '@glimmer/component';
import { VideoMeetingUser } from 'teamtailor/utils/video-meetings/utils';

type Args = {
  attendeesWithDetails: VideoMeetingUser[];
};

export default class VideoMeetingChatMessageList extends Component<Args> {
  getAttendee = (user_id: number | null) => {
    return this.args.attendeesWithDetails.find((a) => a.id === user_id);
  };
}
