/* import __COLOCATED_TEMPLATE__ from './custom-report-form.hbs'; */
import Component from '@glimmer/component';
import {
  CustomReportProperty,
  CustomReportPropertySchema,
} from 'teamtailor/utils/custom-report-schema';
import { inject as service } from '@ember/service';

import CustomReportRequest from 'teamtailor/utils/custom-report-request';
import { restartableTask } from 'ember-concurrency';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import FlipperService from 'teamtailor/services/flipper';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import { DataTableRow } from './data-table';
import { modifier } from 'ember-modifier';
import { ColumnData } from './data-table/column';

interface TranslatedCustomReportProperty extends CustomReportProperty {
  translatedName: string;
}

interface CustomReportFormArgs {
  schema: CustomReportPropertySchema;
  request: CustomReportRequest;
  onLoaded: (rows: any[]) => void;
  isFull: boolean;
}

export default class CustomReportForm extends Component<CustomReportFormArgs> {
  @service declare store: Store;
  @service declare flipper: FlipperService;
  @service declare intl: IntlService;

  @tracked reportIsGenerated = false;
  @tracked isLoading = false;

  @tracked lastAdded: string | null = null;
  @tracked lastRemovedIndex: number | null = null;

  get request(): CustomReportRequest {
    return this.args.request;
  }

  get translatedProperties(): TranslatedCustomReportProperty[] {
    return this.args.schema.properties.map((property) => {
      return {
        ...property,
        translatedName: this.translatedPropertyName(property),
      };
    });
  }

  get availableProperties() {
    return this.translatedProperties.filter(
      (property) =>
        this.request.properties.find((p) => p.name === property.name) ===
        undefined
    );
  }

  get selectedProperties() {
    return this.request.properties.map((property) =>
      this.translatedProperties.find((p) => p.name === property.name)
    );
  }

  get isFull() {
    return this.args.isFull;
  }

  get hasValue(): boolean {
    return !!this.fetchReport.last?.isSuccessful;
  }

  get rows() {
    if (this.isFull) {
      if (!this.hasValue) {
        return [];
      }

      return this.fetchReport.lastSuccessful?.value;
    } else {
      return this.fetchReport.lastSuccessful?.value?.slice(0, 5);
    }
  }

  get dataTableRows() {
    if (!this.rows?.length) {
      return [];
    }

    if (
      this.isLoading &&
      this.rows?.length > 0 &&
      this.request.properties.length !== this.rows[0].length
    ) {
      if (this.lastAdded) {
        let indexModifier = 0;
        this.rows?.map((row: string[]) => {
          return this.request.properties.reduce(
            (arr: DataTableRow, property: CustomReportProperty, index) => {
              if (property.name === this.lastAdded) {
                arr[property.name] = '';
                indexModifier++;
              } else {
                arr[property.name] = row[index - indexModifier];
              }

              return arr;
            },
            {}
          );
        });
      } else if (this.lastRemovedIndex) {
        let indexModifier = 0;
        this.rows?.map((row: string[]) => {
          return this.request.properties.reduce(
            (arr: DataTableRow, property: CustomReportProperty, index) => {
              if (index === this.lastRemovedIndex) {
                indexModifier++;
              }

              arr[property.name] = row[index + indexModifier];

              return arr;
            },
            {}
          );
        });
      }
    }

    return (
      this.rows?.map((row: string[]) => {
        return this.request.properties.reduce(
          (arr: DataTableRow, property: CustomReportProperty, index) => {
            arr[property.name] = row[index];
            return arr;
          },
          {}
        );
      }) || []
    );
  }

  translatedPropertyName(property: CustomReportProperty) {
    if (property.doNotTranslateName) {
      return property.name;
    }

    return this.intl.t(`custom_report.properties.${property.name}`);
  }

  fetchReport = restartableTask(async () => {
    let rows = [];
    this.reportIsGenerated = false;
    this.isLoading = true;

    if (this.request.properties.length === 0) {
      return [];
    }

    if (!this.isFull) {
      const response = (await this.request.load()) || [];

      rows = response?.reports;
    } else {
      const redirect_url =
        await this.request.generateCustomReport.perform(3000);
      this.reportIsGenerated = true;
      rows = (await window
        .fetch(redirect_url)
        .then((res) => res.json())) as any[];
    }

    this.args.onLoaded(rows);
    this.isLoading = false;
    this.lastAdded = null;
    this.lastRemovedIndex = null;

    return rows;
  });

  @action
  onAddBefore(column: ColumnData, property: TranslatedCustomReportProperty) {
    const newSelectedProperties = this.request.properties.toArray();
    let targetIndex = newSelectedProperties.findIndex(
      (p) => p.name === column.propertyName
    );

    targetIndex = targetIndex === -1 ? 0 : targetIndex;

    newSelectedProperties.insertAt(targetIndex, property);

    this.request.properties.setObjects(newSelectedProperties);
    this.lastAdded = property.name;
  }

  @action
  onAddAfter(column: ColumnData, property: TranslatedCustomReportProperty) {
    const newSelectedProperties = this.request.properties.toArray();
    const targetIndex =
      newSelectedProperties.findIndex((p) => p.name === column.propertyName) +
      1;
    newSelectedProperties.insertAt(targetIndex, property);

    this.request.properties.setObjects(newSelectedProperties);
    this.lastAdded = property.name;
  }

  @action
  onUpdate(column: ColumnData, property: TranslatedCustomReportProperty) {
    const newSelectedProperties = this.request.properties.toArray();
    const targetIndex = newSelectedProperties.findIndex(
      (p) => p.name === column.propertyName
    );
    newSelectedProperties[targetIndex] = property;

    this.request.properties.setObjects(newSelectedProperties);
  }

  @action
  onRemove(column: ColumnData) {
    let newSelectedProperties = this.request.properties.toArray();

    const lastRemovedIndex = newSelectedProperties.findIndex(
      (p) => p.name === column.propertyName
    );
    newSelectedProperties = newSelectedProperties.filter(
      (p) => p.name !== column.propertyName
    );

    this.request.properties.setObjects(newSelectedProperties);
    this.lastRemovedIndex = lastRemovedIndex;
  }

  previousSerializedRequest?: string;
  keepReportUpToDate = modifier(
    (_element, [_forceUpdate]: [{ [key: string]: any }]) => {
      const serializedRequest = JSON.stringify({
        _forceUpdate,
      });
      if (this.previousSerializedRequest !== serializedRequest) {
        this.fetchReport.perform();
        this.previousSerializedRequest = serializedRequest;
      }
    }
  );
}
