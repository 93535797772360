/* import __COLOCATED_TEMPLATE__ from './reject-reasons.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';

export interface RejectReasonRow {
  reason: string;
  numberOfRejects: number;
  rejectedByCompany: boolean;
  grouped: boolean;
}

type Args = {
  rows?: RejectReasonRow[];
  onlyShow?: string;
};

export default class RejectReasonsWidget extends Component<Args> {
  @service declare intl: IntlService;

  @tracked _currentIndex = 0;

  dataTypes = ['', 'byCompany', 'byCandidate'];

  get currentIndex() {
    if (this.args.onlyShow === 'company') {
      return 1;
    } else if (this.args.onlyShow === 'candidate') {
      return 2;
    }

    return this._currentIndex;
  }

  get dataType() {
    return this.dataTypes[this.currentIndex];
  }

  get translationKeys() {
    if (this.args.onlyShow) {
      return { heading: 'title' };
    }

    switch (this.dataType) {
      case 'byCompany':
        return { heading: 'by_company', compare: 'rejected_by_company_count' };
      case 'byCandidate':
        return {
          heading: 'by_candidate',
          compare: 'rejected_by_candidate_count',
        };
      default:
        return { heading: 'overview', compare: 'rejected_candidates_count' };
    }
  }

  get totalRejectedCandidates() {
    return this.reasons
      .flat()
      .map((r) => r.value)
      .reduce((acc, i) => acc + i, 0);
  }

  get totalRejectedCandidatesHeading() {
    if (!this.dataType) {
      return this.totalRejectedCandidates;
    } else {
      return this.ungroupedReasons
        .flat()
        .map((r) => r.value)
        .reduce((acc, i) => acc + i, 0);
    }
  }

  get ungroupedReasons() {
    return this.reasons.filter((reason) => !reason.grouped);
  }

  get groupedReasons() {
    return this.reasons.filterBy('grouped', true);
  }

  get reasons() {
    const rejectReasons = this.args.rows;

    if (!rejectReasons) {
      return [];
    }

    const mappedRejectReasons = rejectReasons
      .map((reason) => {
        return { value: reason.numberOfRejects, ...reason };
      })
      .sortBy('value')
      .reverse();

    const rejectedByCompany = {
      value: mappedRejectReasons
        .filterBy('rejectedByCompany')
        .reduce((acc, reason) => acc + reason.value, 0),

      name: this.intl.t(
        'insights.molecules.reject_reasons.rejected_by_company'
      ),

      grouped: true,
    };

    const rejectedByCandidate = {
      value: mappedRejectReasons
        .filterBy('rejectedByCompany', false)
        .reduce((acc, reason) => acc + reason.value, 0),

      name: this.intl.t(
        'insights.molecules.reject_reasons.rejected_by_candidate'
      ),

      grouped: true,
    };

    switch (this.dataType) {
      case 'byCompany':
        return [
          rejectedByCandidate,
          ...mappedRejectReasons.filterBy('rejectedByCompany'),
        ];

      case 'byCandidate':
        return [
          rejectedByCompany,
          ...mappedRejectReasons.filterBy('rejectedByCompany', false),
        ];

      default:
        return [rejectedByCompany, rejectedByCandidate];
    }
  }
}
