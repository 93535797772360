/* import __COLOCATED_TEMPLATE__ from './requisitions.hbs'; */
import BulkActionsBase from './base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import CurrentService from 'teamtailor/services/current';
import RequisitionModel from 'teamtailor/models/requisition';

type BulkData = {
  [key: string]: unknown;
};

export default class BulkActionsRequisitionsComponent extends BulkActionsBase {
  @service declare store: Store;
  @service declare current: CurrentService;

  get modals(): string[] {
    return ['archive'];
  }

  get requisitionBulkOptions() {
    const { deselectedIds, selectAll, selectedIds, searchParams } = this.args;
    if (selectAll) {
      return {
        query: searchParams,
        deselected_ids: deselectedIds,
      };
    } else {
      return {
        selected_ids: selectedIds,
      };
    }
  }

  get selectedRequisitions() {
    if (this.args.selectAll) {
      return this.store
        .peekAll('requisition')
        .filter(
          (requisition: RequisitionModel) =>
            !this.args.deselectedIds.includes(requisition.id)
        );
    } else {
      return this.args.selectedIds.map((id: string) => {
        return this.store.peekRecord('requisition', id);
      });
    }
  }

  @action
  handleBulkChange(bulkData: BulkData, action: string): void {
    const promise = this.postAction(
      action,
      { ...this.requisitionBulkOptions, ...bulkData },
      'requisitions'
    );

    this.batchJob.batchJobStartedTask
      .perform(promise, this.args.selectedCount)
      .then(() => {
        this.args.refetchRequisitions();
      });
  }

  @action
  closeModal(): void {
    this.currentModal = null;
  }
}
