/* import __COLOCATED_TEMPLATE__ from './icon.hbs'; */
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';

const DEFAULT_SIZE = 'small';
const ALLOWED_SIZES = ['medium', DEFAULT_SIZE] as const;

type AllowedSizes = (typeof ALLOWED_SIZES)[number];

const DEFAULT_COLOR = 'zinc';

export type Args = {
  size?: AllowedSizes;
  icon: string;
  color?: string;
};

export default class IconComponent extends Component<Args> {
  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Badge::Icon @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get color(): string {
    return this.args.color ?? DEFAULT_COLOR;
  }
}
