/* import __COLOCATED_TEMPLATE__ from './searchable-number-field.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SearchableNumberField extends Component {
  @tracked value = this.args.value;
  @tracked useRange = this.getUseRange();
  @tracked min;
  @tracked max;

  constructor() {
    super(...arguments);

    this.args.customField.getRangeValues().then((response) => {
      const [min, max] = response.range;
      this.min = min;
      this.max = max;
      this.value = min !== max ? [this.min, this.max] : this.min;
      this.useRange = this.getUseRange();
    });
  }

  getUseRange() {
    return Array.isArray(this.value);
  }

  getValue() {
    return this.args.value;
  }

  get firstValue() {
    return this.value[0];
  }

  get secondValue() {
    return this.value[1];
  }

  @action
  onRangeChange(value) {
    this.value = value;
    this.args.onChange(this.value);
  }

  @action
  toggleOnlyPossibleValue(_value, checked) {
    const value = checked ? _value : null;

    this.value = value;
    this.args.onChange([value]);
  }

  @action
  onInputChange(index, e) {
    if (this.useRange) {
      let { value } = this;
      value[index] = e.target.value;

      this.value = value;
    } else {
      this.value = e.target.value;
    }

    this.args.onChange(this.value);
  }
}
