/* import __COLOCATED_TEMPLATE__ from './qr-code-download.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';
import { UploadFile as File } from 'ember-file-upload';
import downloadFile from 'teamtailor/utils/download-file';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class QrCodeDownloadComponent extends Component {
  @service directUpload;
  @service pusher;
  @service current;
  @service store;

  @tracked isBusy = false;

  qrCode = null;
  notificationsChannel = null;

  get user() {
    return this.current.user;
  }

  get promotion() {
    return this.args.promotion;
  }

  get qrCodeSettings() {
    return this.args.qrCodeSettings || this.promotion.qrCodeSettings;
  }

  get fileName() {
    return (
      this.args.fileName || `${dasherize(this.promotion.shareLinkName)}-qr-code`
    );
  }

  get pdfCreatedEvent() {
    return `${this.promotion.id}-job-poster-created`;
  }

  async listenForPosterUrl() {
    this.notificationsChannel = await this.pusher.subscribe(
      get(this, 'user.notificationsChannel')
    );

    this.notificationsChannel.bind(this.pdfCreatedEvent, (payload) => {
      this.notificationsChannel.unbind(this.pdfCreatedEvent);
      this.notificationsChannel = null;
      this.onPusherPayloadReceived(payload);
    });
  }

  async onPusherPayloadReceived(payload) {
    this.store.pushPayload(payload);
    await this.downloadPdf();
    this.isBusy = false;
  }

  async createPdf() {
    const blob = await this.qrCode.getRawData('png');
    const file = File.fromBlob(blob, 'promotion');
    const uuid = get(this.promotion.job, 'uuid');
    const image = await this.directUpload.s3(file, {
      model: 'images',
      filename: `qr-${this.promotion.id}-${uuid}.png`,
    });

    await this.listenForPosterUrl();
    this.promotion.createQrCodePoster({ image });
  }

  async downloadPdf() {
    const response = await fetch(this.promotion.qrCodePosterUrl);
    const blob = await response.blob();
    downloadFile(URL.createObjectURL(blob), `${this.fileName}-poster.pdf`);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.notificationsChannel) {
      this.notificationsChannel.unbind(this.pdfCreatedEvent);
    }
  }

  delayCompletion() {
    setTimeout(() => {
      if (this.isBusy) {
        this.isBusy = false;
      }
    }, 600);
  }

  @action
  async handleDownload(extension) {
    if (this.qrCode && !this.isBusy) {
      this.isBusy = true;

      await this.qrCode.download({
        name: this.fileName,
        extension,
      });

      this.delayCompletion();
    }
  }

  @action
  async handleDownloadPoster() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;

    if (!isEmpty(this.promotion.qrCodePosterUrl)) {
      await this.downloadPdf();
      this.delayCompletion();
    } else {
      await this.createPdf();
    }
  }

  @action
  handleRegisteredQrCode(qrCode) {
    this.qrCode = qrCode;
  }
}
