/* import __COLOCATED_TEMPLATE__ from './trigger-survey-form.hbs'; */
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import TriggerFormBaseComponent from 'teamtailor/components/trigger-form-base';
import { get } from 'teamtailor/utils/get';

export default class TriggerSurveyFormComponent extends TriggerFormBaseComponent {
  @service current;

  get selectedId() {
    return this.model.formId;
  }

  get selectedSurvey() {
    return get(this.company, 'surveys').findBy('id', this.selectedId);
  }

  get company() {
    return this.current.company;
  }

  @action
  changeSurvey(survey) {
    set(this.model, 'formId', survey.id);
  }

  @action
  handleInsertElement() {
    get(this.company, 'surveys').reload();
  }
}
