/* import __COLOCATED_TEMPLATE__ from './multi-role-selector.hbs'; */
import Component from '@glimmer/component';

export default class EmployeesMultiRoleSelector extends Component {
  get roles() {
    const addOnRoles = ['analytics', 'designer'];

    return {
      no_access: [],
      user: addOnRoles,
      hiring_manager: addOnRoles,
      recruiter: addOnRoles,
      recruitment_admin: addOnRoles,
      admin: [],
    };
  }
}
