/* import __COLOCATED_TEMPLATE__ from './copy-job-modal.hbs'; */
import { action } from '@ember/object';
import AvatarModel from 'teamtailor/models/avatar';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import JobModel from 'teamtailor/models/job';
import { CopyJobParams } from 'teamtailor/models/job';

interface CopyJobModalArgs {
  job: JobModel;
  onClose: () => void;
}

export default class CopyJobModal extends Component<CopyJobModalArgs> {
  @tracked declare avatar: AvatarModel | undefined;
  @tracked inTransit = false;

  @tracked newJobName = this.job.title;

  get job() {
    return this.args.job;
  }

  get avatarParams() {
    return this.avatar ? this.avatar.toParams() : undefined;
  }

  @action
  copyJob(): void {
    this.inTransit = true;

    const params: CopyJobParams = {
      job_name: this.newJobName,
    };

    if (this.avatarParams) {
      params.avatar_params = this.avatarParams;
    }

    this.job.copyJob({ params });
  }

  @action
  onPickAvatar(avatar: AvatarModel): void {
    this.avatar = avatar;
  }
}
