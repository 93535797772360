/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import JobDetailModel from 'teamtailor/models/job-detail';
import JobModel from 'teamtailor/models/job';
import PermissionsService from 'teamtailor/services/permissions';

interface Args {
  job: JobModel;
  jobDetail: JobDetailModel;
}

export default class extends Component<Args> {
  @service declare permissions: PermissionsService;

  get showEnable() {
    const jobUpdatePermission = this.permissions.has('job/update');
    if (!this.args.job.suggestedStageEnabled && jobUpdatePermission) {
      return true;
    }
  }
}
