/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import FormBaseComponent from './base';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class HeaderAdjustmentsFormComponent extends FormBaseComponent {
  @service current;
  @service iframePreview;

  get pageHasCover() {
    const coverSection = get(this, 'args.page.sections').find(
      ({ name, enabled }) => name === 'cover' && enabled
    );

    return coverSection ? true : false;
  }

  get appearances() {
    return [
      {
        id: 'default',
        label: this.intl.t('content.editor.section.appearance.default'),
      },
      {
        id: 'custom',
        label: this.intl.t('content.editor.section.appearance.custom'),
      },
    ];
  }

  get logoPositions() {
    return [
      {
        id: 'center',
        label: this.intl.t('content.editor.section.logo_position.center'),
      },
      {
        id: 'left',
        label: this.intl.t('content.editor.section.logo_position.left'),
      },
      {
        id: 'right',
        label: this.intl.t('content.editor.section.logo_position.right'),
      },
    ];
  }

  get secondaryButtons() {
    const options = [
      {
        id: 'off',
        label: this.intl.t('content.editor.section.secondary_button.off'),
      },
      {
        id: 'share',
        label: this.intl.t('content.editor.section.secondary_button.share'),
      },
    ];

    if (get(this.model, 'careerSite.hasOtherCareerSites')) {
      options.push({
        id: 'language',
        label: this.intl.t('content.editor.section.secondary_button.language'),
      });
    }

    return options;
  }

  get backgroundColor() {
    return this.model.customBackgroundColor ?? '#0000004d';
  }

  get textColor() {
    return this.model.customTextColor ?? '#ffffff';
  }

  get navigationBackground() {
    return this.model.customNavigationBackground ?? '#000000';
  }

  get navigationColor() {
    return this.model.customNavigationColor ?? '#ffffff';
  }

  get navigationHighlightColor() {
    return (
      this.model.customNavigationHighlightColor ??
      get(this.current.company, 'draftDesign.linkColor')
    );
  }

  get selectedAppearance() {
    return this.model.appearance ?? 'default';
  }

  get selectedLogoPosition() {
    return this.model.logoPosition ?? 'center';
  }

  get selectedSecondaryButton() {
    return this.model.secondaryButton ?? 'share';
  }

  resetToDefault() {
    this.model.logoPosition = 'center';
    this.model.customBackgroundColor = null;
    this.model.customTextColor = null;
    this.model.customNavigationBackground = null;
    this.model.customNavigationColor = null;
    this.model.customNavigationHighlightColor = null;
    this.model.showMenuText = true;
    this.model.showShareButton = true;
  }

  reload() {
    this.iframePreview.reloadIframe();
  }

  @action
  async handleOnChangeAttribute(attribute, value) {
    if (attribute === 'appearance') {
      this.resetToDefault();
    } else {
      this.model.appearance = 'custom';
    }

    this.model[attribute] = value;
    await this.saveAndReload();
  }
}
