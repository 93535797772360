/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import {
  ALLOWED_SCHEDULE_OPTIONS,
  DEFAULT_SCHEDULE_OPTIONS,
  SCHEDULE_OPTIONS_WITH_ATTRIBUTES,
} from 'teamtailor/utils/due-date';
import { argDefault } from 'teamtailor/utils/arg-default';
import { assert } from '@ember/debug';
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

type Args = {
  options: string[];
  onSetDueDate: () => void;
  canClearDate?: boolean;
};

export default class QuickScheduleOptionsComponent extends Component<Args> {
  @argDefault canClearDate = true;

  verifyOptions(): void {
    const isNotValid = this.args.options.some((option: string) => {
      return !ALLOWED_SCHEDULE_OPTIONS.includes(option);
    });

    if (isNotValid) {
      assert(
        `Not supported schedule option value. Supported values are: \n${ALLOWED_SCHEDULE_OPTIONS}`
      );
    }
  }

  get scheduleOptions() {
    return SCHEDULE_OPTIONS_WITH_ATTRIBUTES;
  }

  get options(): string[] {
    if (isPresent(this.args.options)) {
      this.verifyOptions();
      return this.args.options;
    }

    return DEFAULT_SCHEDULE_OPTIONS;
  }
}
