/* import __COLOCATED_TEMPLATE__ from './unread-channel.hbs'; */
import Component from '@glimmer/component';
import JobCommentStatus from 'teamtailor/models/job-comment-status';

type Args = {
  jobCommentStatuses: JobCommentStatus[];
};

export default class UnreadChannelComponent extends Component<Args> {
  get channelsWithUnreadMessages() {
    return this.args.jobCommentStatuses.filter((entry) => entry.unread);
  }
}
