/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent, {
  BaseComponentArgs,
} from 'teamtailor/components/content-block/base-component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { DepartmentModel, LocationModel } from 'teamtailor/models';

export default class StoriesBaseComponent extends BaseComponent<BaseComponentArgs> {
  @service declare store: Store;

  get section() {
    return this.args.model;
  }

  @action
  async handleSetDepartment(department: DepartmentModel | null) {
    const section = await this.section;
    if (section) {
      set(section, 'pickedDepartmentId', department?.id);
    }
  }

  @action
  async handleSetLocation(location: LocationModel | null) {
    const section = await this.section;
    if (section) {
      set(section, 'pickedLocationId', location?.id);
    }
  }
}
