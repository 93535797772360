/* import __COLOCATED_TEMPLATE__ from './purchase-item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class PromotionHistoryItemComponent extends Component {
  @service router;
  @service promotionRequests;
  @service pusher;
  @service user;
  @service store;
  @service flashMessages;
  @service intl;
  @tracked isDisabled;

  transformErrors(errorMessages) {
    if (typeof errorMessages === 'string') {
      return `: ${errorMessages}`;
    }

    errorMessages = errorMessages.flatMap((message) => {
      if (!message) {
        return '';
      }

      if (typeof message === 'string') {
        return message;
      }

      if (typeof message === 'object' && 'errors' in message) {
        return this.transformErrors(message.errors);
      }

      return JSON.stringify(message);
    });

    return `: ${errorMessages.join(', ')}`;
  }

  updateRegularPromotion(promotion) {
    return promotion.save().catch(() => {
      this.router.transitionTo('jobs.job.promote.edit', promotion);
    });
  }

  get isPaused() {
    return this.args.promotion.paused || this.args.promotion.isPaused;
  }

  get published() {
    return this.args.promotion.successfullyPublished;
  }

  get isOk() {
    return this.published || this.isPending;
  }

  get isPending() {
    return this.args.promotion.status === 'pending';
  }

  get showWarning() {
    return this.published && this.args.promotion.lastResponseCode >= 400;
  }

  get promotionErrorMessage() {
    let { errorMessages } = this.args.promotion;

    return this.transformErrors(errorMessages);
  }

  get status() {
    if (this.isPending) {
      return this.intl.t('jobs.job.promote.purchase_history.pending');
    }

    if (get(this.args.promotion, 'deleteRequestSent'))
      return this.intl.t('jobs.job.promote.purchase_history.unpublished');
    if (!get(this.args.promotion, 'successfullyPublished'))
      return this.intl.t('jobs.job.promote.purchase_history.error');

    if (!get(this.args.promotion, 'channel.duration')) {
      return '';
    }

    if (this.args.promotion.isExpired)
      return this.intl.t('jobs.job.promote.purchase_history.expired');
    if (this.args.promotion.daysLeft)
      return this.intl.t('jobs.job.promote.purchase_history.expires_in', {
        days: this.args.promotion.daysLeft,
      });

    return '';
  }

  @action
  updatePromotion(promotion) {
    if (get(promotion, 'channel.hasAdapter')) {
      return this.promotionRequests.refreshPromotion(promotion);
    } else {
      return this.updateRegularPromotion(promotion);
    }
  }

  @action
  openEditPromotionModal(promotion) {
    if (get(promotion, 'channel.hasAdapter')) {
      this.router.transitionTo(
        'jobs.job.promote.promotion.edit-external',
        promotion
      );
    } else {
      this.router.transitionTo('jobs.job.promote.edit', promotion);
    }
  }

  @action
  openExternalUrl(promotion) {
    window.open(get(promotion, 'externalUrl'), '_blank');
  }

  @action
  async setupPusher() {
    const channel = await this.pusher.channel(
      get(this, 'user.notificationsChannel')
    );
    channel.bind('promotion-request-action', ({ payload, type }) => {
      if (type === 'fail') {
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      }

      this.isDisabled = false;
      this.store.pushPayload(payload);
    });
  }

  @action
  async teardownPusher() {
    const channel = await this.pusher.channel(
      get(this, 'user.notificationsChannel')
    );
    if (channel) {
      channel.unbind('promotion-request-action');
    }
  }
}
