/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
import BulkActionsBase from './base';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class BulkActionsTagsComponent extends BulkActionsBase {
  @service server;
  @service store;
  @service current;

  @tracked selectedTags = null;
  @tracked primaryTag = null;
  @tracked showDeleteModal = false;
  @tracked showMergeModal = false;
  @tracked taggingsCount = null;

  fetchTaggingsCount = dropTask(async (data) => {
    if (this.taggingsCount !== null) {
      this.taggingsCount = null;
    }

    const adapter = this.store.adapterFor('taggings');
    const url = `${adapter.buildURL('taggings')}/count`;

    const count = await this.server.request(url, 'GET', {
      data,
    });
    this.taggingsCount = count;
  });

  fetchSelectedTags = dropTask(async () => {
    if (this.selectedTags !== null) {
      this.selectedTags = null;
    }

    let tags = [];
    if (this.selectAll) {
      const deselectedIds = get(this, 'deselectedIds');

      tags = await this.store.query('tag', {
        query: this.args.query,
      });
      if (get(deselectedIds, 'length')) {
        tags = tags.filter((tag) => !deselectedIds.includes(tag.id));
      }
    } else {
      let selectedIds = get(this, 'selectedIds');
      const selectedTags = await Promise.all(
        selectedIds.map((id) => this.store.findRecord('tag', id))
      );
      tags = selectedTags.toSorted((a, b) => a.name.localeCompare(b.name));
    }

    if (tags.length) {
      this.selectedTags = tags;
    }
  });

  postAction(action, data) {
    return this.postTask.perform(action, data, 'tags');
  }

  bulkAction(promise, startCount, ids) {
    const modifiedIds = [...ids];
    promise.then(() => {
      this.args.removeTagsFromList(modifiedIds);
    });

    this.batchJob.batchJobStartedTask.perform(promise, startCount);

    if (this.args.onBulkActionConfirm) {
      this.args.onBulkActionConfirm();
    }
  }

  @action
  handleDeleteModalCancel() {
    this.showDeleteModal = false;
  }

  @action
  handleMergeModalCancel() {
    this.showMergeModal = false;
  }

  @action
  handleDelete() {
    const ids = get(this, 'bulkOptions.selected_ids') || [];
    const promise = this.postAction('bulk_delete', this.bulkData);
    this.bulkAction(promise, this.args.selectedCount, ids);
    this.showDeleteModal = false;
  }

  @action
  handleDeleteModalOpen() {
    this.fetchTaggingsCount.perform(this.bulkData);
    this.showDeleteModal = true;
  }

  get bulkData() {
    let data = get(this, 'bulkOptions');
    data = {
      ...data,
      color: this.args.color,
      tagged_type: this.args.taggedType,
    };

    return data;
  }

  @action
  handleMergeModalOpen() {
    this.primaryTag = null;
    this.fetchSelectedTags.perform();
    this.showMergeModal = true;
  }

  @action
  handleSelectPrimaryTag(tag) {
    this.primaryTag = tag;
  }

  @action
  handleMerge() {
    const primaryTagId = get(this, 'primaryTag.id');
    const selectedIds = get(this, 'selectedTags')
      .map((tag) => tag.id)
      .filter((id) => id !== primaryTagId);

    const promise = this.postAction(
      'bulk_merge',
      this.postData({
        primary_tag_id: primaryTagId,
        selected_ids: selectedIds,
      })
    );

    this.bulkAction(promise, selectedIds.length, selectedIds);
    this.showMergeModal = false;
  }
}
