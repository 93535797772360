/* import __COLOCATED_TEMPLATE__ from './reject-reason-row.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import TtAlertService from 'teamtailor/services/tt-alert';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { RejectReasonModel } from 'teamtailor/models';

interface RejectReasonRowComponentArgs {
  model: RejectReasonModel;
}

export default class RejectReasonRowComponent extends Component<RejectReasonRowComponentArgs> {
  @service declare ttAlert: TtAlertService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get model() {
    return this.args.model;
  }

  saveReason = task({ drop: true }, async () => {
    if (!get(this.model, 'hasDirtyAttributes')) {
      return;
    }

    try {
      await this.model.save();
    } catch (error) {
      const e = error as { errors: { detail: string }[] };
      this.displayError(
        !isEmpty(e.errors)
          ? e.errors.at(0)?.detail
          : this.intl.t('errors.something_went_wrong')
      );
    }
  });

  doFocusOut = task({ restartable: true }, async () => {
    await timeout(200);
    await waitForProperty(this.saveReason, 'isIdle', (v) => v);

    get(this, 'model').rollbackAttributes();
  });

  displayError(errorMessage = '') {
    this.flashMessages.error(errorMessage);
  }

  @action
  handleEditKeyUp(_: unknown, event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.doFocusOut.perform();
    }
  }

  @action
  deleteRejectReason() {
    this.ttAlert.customConfirm({
      title: this.intl.t(
        'components.settings.reject_reason_row.delete_reject_reason'
      ),

      text: this.intl.t('components.settings.reject_reason_row.are_you_sure', {
        reason: get(this.model, 'reason'),
      }),

      confirmButtonText: this.intl.t('common.delete'),
      confirmButtonClass: 'btn-danger',
      cancelButtonText: this.intl.t('common.no'),
      confirmCallback: async () => {
        try {
          await this.model.destroyRecord();
          this.flashMessages.success(
            this.intl.t(
              'components.settings.reject_reason_row.deleted_reject_reason'
            )
          );
        } catch {
          this.flashMessages.error(
            this.intl.t(
              'components.settings.reject_reason_row.cant_delete_if_used'
            )
          );
        }
      },

      cancelCallback: () => {},
    });
  }
}
