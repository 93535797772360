/* import __COLOCATED_TEMPLATE__ from './export-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { dasherize } from '@ember/string';
import DateRange from 'teamtailor/utils/date-range';
import { inject as service } from '@ember/service';
import InsightsExportService, {
  SortDirection,
} from 'teamtailor/services/insights-export';
import IntlService from 'ember-intl/services/intl';
import CsvFileExporter from 'teamtailor/utils/csv-file-exporter';

export default class InsightsExportModal extends Component {
  @service declare insightsExport: InsightsExportService;
  @service declare intl: IntlService;

  get data() {
    return this.insightsExport.fetchData.lastSuccessful?.value;
  }

  get sortProperty(): string | null {
    return this.insightsExport.sortProperty;
  }

  set sortProperty(value: string | null) {
    this.insightsExport.sortProperty = value;
  }

  get sortDirection(): SortDirection | null {
    return this.insightsExport.sortDirection;
  }

  set sortDirection(value: SortDirection | null) {
    this.insightsExport.sortDirection = value;
  }

  get sortedData() {
    const { sortDirection, sortProperty, data } = this;
    const sortedVisits = data.sortBy(sortProperty);
    return sortDirection === 'desc' ? sortedVisits.reverse() : sortedVisits;
  }

  @action
  handleClose() {
    this.insightsExport.closeModal();
  }

  @action
  handleSort(sortProperty: string | null) {
    if (this.sortProperty === sortProperty) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }

  @action
  handleUpdateTimeRange(startDate: Date, endDate: Date) {
    const momentStartDate = moment(startDate).format('YYYY-MM-DD');
    const momentEndDate = moment(endDate).format('YYYY-MM-DD');
    this.insightsExport.handleUpdateDateRange(
      new DateRange(momentStartDate, momentEndDate)
    );
  }

  get title() {
    const { titleKey } = this.insightsExport.modalOptions || {};
    return this.intl.t(titleKey || '');
  }

  get fileName() {
    const { filePrefix } = this.insightsExport.modalOptions || {};

    return `${filePrefix}-${dasherize(this.title)}`;
  }

  get columns() {
    return this.insightsExport.modalOptions?.columns;
  }

  get canExport() {
    if (!this.columns) {
      return false;
    }

    return this.columns.length > 0;
  }

  @action
  handleDownloadCSV() {
    if (!this.canExport) {
      return;
    }

    const { dateRange } = this.insightsExport;

    new CsvFileExporter(this.data, this.columns!, this.intl).downloadFile({
      baseFileName: 'nps-overview',
      startDate: dateRange.startDate as Date,
      endDate: dateRange.endDate as Date | undefined,
    });
  }
}
