/* import __COLOCATED_TEMPLATE__ from './form-field.hbs'; */
import { equal, or } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  classNameBindings: [
    ':form-field',
    'darkTheme:form-field--dark',
    'hasError:errors',
    'useToolipErrors:relative',
  ],

  theme: null,

  darkTheme: equal('theme', 'dark'),
  displayError: false,
  hasError: or('errors.length', 'displayError', 'errors.validation.length'),
  useToolipErrors: false,
});
