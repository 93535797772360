/* import __COLOCATED_TEMPLATE__ from './menu.hbs'; */
import CandidateModel from 'teamtailor/models/candidate';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { ReferenceModel } from 'teamtailor/models';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

interface Signature {
  Args: {
    recommendation: ReferenceModel;
    candidate: CandidateModel;
  };
}

export default class extends Component<Signature> {
  @service declare store: Store;
  @service declare current: Current;
  @tracked isOpen = false;

  get recommendation() {
    return this.args.recommendation;
  }

  get referenceTemplates() {
    return get(this.current.company, 'referenceTemplates');
  }

  get canRequestReference() {
    if (this.recommendation.feedbackRequestedAt) {
      return false;
    }

    if (this.recommendation.fullFeedbackAt) {
      return false;
    }

    const addedManually = !this.recommendation.feedbackRequestedAt;

    return (
      this.current.company.hasFeature('references') &&
      (!this.args.recommendation.answeredAt || addedManually)
    );
  }

  @action
  openModal() {
    this.isOpen = true;
  }

  @action
  onClose() {
    this.isOpen = false;
  }

  @action
  destroyReference() {
    this.recommendation.destroyRecord();
  }

  askForFeedback = task(async (templateId: string) => {
    const { candidate } = this.args;
    const payload = (await candidate.askForFeedback({
      reference_id: get(this.args.recommendation, 'id'),
      template_id: templateId,
    })) as ReferenceModel;
    this.store.pushPayload('reference', payload);
  });
}
