/* import __COLOCATED_TEMPLATE__ from './referral.hbs'; */
import Component from '@glimmer/component';
import { ActivityModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

interface Signature {
  Args: {
    activity: ActivityModel;
  };
}

export default class ActivityMessageComponent extends Component<Signature> {
  get activity() {
    return this.args.activity;
  }

  get jobApplication() {
    return get(this.activity, 'jobApplication');
  }

  get promotion() {
    return get(this.jobApplication, 'promotion');
  }

  get isShareLink() {
    return (
      get(get(this.activity, 'actionData'), 'channel_type') === 'share_link' ||
      get(this.promotion, 'isShareLink')
    );
  }

  get isQrCode() {
    return get(get(this.activity, 'actionData'), 'channel_type') === 'qr_code';
  }

  get shareLinkName() {
    return (
      get(get(this.activity, 'actionData'), 'promotion_name') ||
      get(this.promotion, 'shareLinkName')
    );
  }

  get channelName() {
    return (
      get(get(this.activity, 'actionData'), 'channel_name') ||
      get(get(this.promotion, 'channel'), 'name')
    );
  }

  get isFromPromotion() {
    return (
      !!get(get(this.activity, 'actionData'), 'channel_type') ||
      get(this.jobApplication, 'isFromPromotion')
    );
  }

  get isShareLinkOrQrCode() {
    return (
      this.isShareLink ||
      this.isQrCode ||
      get(this.promotion, 'isShareLinkOrQrCode')
    );
  }
}
