import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    stage: { serialize: 'ids' },
  },

  payloadKeyFromModelName() {
    return 'trigger';
  },

  extractCommonJson(json) {
    let {
      id,
      ignore_internal,
      ignore_sourced,
      job_id,
      stage_id,
      type,
      on_reject,
      delay_job_for,
      delay_job_for_unit,
      cancel_on_move,
      cancel_on_reject,
      ...data
    } = json;

    delete data.payload;

    if (data.share_options && data.share_options.interview_kit_toggles) {
      data.share_options.excluded_picked_interview_kit_ids =
        data.share_options.interview_kit_toggles
          .filter((item) => !item.checked)
          .map((item) => item.id);

      delete data.share_options.interview_kit_toggles;
    }

    return [
      {
        id,
        ignore_internal,
        ignore_sourced,
        job_id,
        stage_id,
        type,
        on_reject,
        delay_job_for,
        delay_job_for_unit,
        cancel_on_move,
        cancel_on_reject,
      },
      data,
    ];
  },
});
