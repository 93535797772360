/* import __COLOCATED_TEMPLATE__ from './list-item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class ReactionsListItemComponent extends Component {
  @service current;

  get group() {
    return this.args.group;
  }

  get items() {
    return this.group.items;
  }

  get names() {
    const usernames = this.items.map((reaction) => {
      const user = get(reaction, 'user');
      return get(user, 'usernameOrEmail');
    });

    return usernames.join(', ');
  }

  get firstThreeReactions() {
    if (this.items.length > 3) {
      return this.items.slice(0, 3);
    }

    return this.items;
  }

  get numberOfOtherReactions() {
    if (this.items.length > 3) {
      return this.items.length - 3;
    }

    return 0;
  }

  get reactionItem() {
    return {
      value: this.group.value,
      reaction: this.items.find(
        (reaction) => reaction.belongsTo('user').id() === this.current.user.id
      ),
    };
  }
}
