/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import formatDate from 'teamtailor/helpers/format-date';
import move from 'ember-animated/motions/move';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';
import { tracked } from '@glimmer/tracking';
import { today, tomorrow, isSameDay } from 'teamtailor/utils/due-date';
export default class TodoItem extends Component {
  @service intl;
  @service current;
  @service ttAlert;

  @tracked dropdownOpen = false;

  get dueIn() {
    return this.todo.relativeDueDays || this.todo.daysFromNow;
  }

  get todo() {
    return this.args.todo;
  }

  get hasDetails() {
    return (
      this.dueDate || this.dueIn || this.showCandidate || this.showAssignee
    );
  }

  get showAssignee() {
    return !this.args.hideAssignee && this.todoIsAssigned;
  }

  get showCandidate() {
    return (
      !this.args.hideCandidate &&
      this.todo.belongsTo &&
      !!this.todo.belongsTo('candidate').id()
    );
  }

  get todoIsAssigned() {
    return this.todo.belongsTo
      ? !!this.todo.belongsTo('assignee').id()
      : !!this.todo.assigneeId;
  }

  get dueDate() {
    let { due } = this.todo;
    const languageCode = this.current.locale;
    let formattedDate = formatDate(due, { intl: this.intl, languageCode });

    return formattedDate;
  }

  get dateColorClass() {
    const { due } = this.todo;
    if (due === null) {
      return 'text-neutral';
    } else if (new Date(due).getTime() < today.getTime()) {
      return 'text-red-600';
    } else if (isSameDay(due, today)) {
      return 'text-green-600';
    } else if (isSameDay(due, tomorrow)) {
      return 'text-teal-600';
    } else {
      return 'text-cyan-600';
    }
  }

  @action
  onToggleDueDateDropdown(dropdown) {
    this.args.onToggleDueDateDropdown?.(dropdown);
  }

  @action
  handleDeleteClick() {
    if (this.args.skipDeleteConfirm) {
      this.args.onDelete();
    } else {
      this.ttAlert.confirm(
        this.intl.t('components.todo_feed.delete_message'),
        () => {
          this.args.onDelete();
        }
      );
    }
  }

  // eslint-disable-next-line require-yield
  *showMoreTransition({ insertedSprites, keptSprites, removedSprites }) {
    for (let sprite of keptSprites) {
      sprite.applyStyles({ 'z-index': '1' });
      move(sprite);
    }

    for (let sprite of insertedSprites) {
      sprite.startAtPixel({
        y: sprite.absoluteFinalBounds.y,
        x: sprite.absoluteFinalBounds.right,
      });
      move(sprite);
      fadeIn(sprite);
    }

    for (let sprite of removedSprites) {
      sprite.endTranslatedBy({
        x: sprite.initialBounds.width,
        y: 0,
      });
      move(sprite);
      fadeOut(sprite);
    }
  }
}
