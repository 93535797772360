/* import __COLOCATED_TEMPLATE__ from './offer-details.hbs'; */
import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import {
  cloneFlowFromSteps,
  cloneStepsFromFlow,
} from 'teamtailor/utils/approval-flows';

export default class OfferDetails extends Component {
  @service current;
  @service store;

  editingFlow = false;

  get jobOffer() {
    return this.args.jobOffer;
  }

  get jobOfferApprovalFlow() {
    return get(this, 'jobOffer.jobOfferApprovalFlow.content');
  }

  get approvalSteps() {
    return this.jobOffer.approvalSteps.filter((step) => {
      return isPresent(get(step, 'id'));
    });
  }

  get responderName() {
    return (
      get(this, 'jobOffer.answeredByUser.nameOrEmail') ||
      get(this, 'jobOffer.candidate.nameOrEmail')
    );
  }

  get needsYourApproval() {
    if (get(this, 'jobOffer.isRejected')) {
      return false;
    }

    const currentStep = get(this, 'jobOffer.currentStep');
    if (!currentStep) {
      return false;
    }

    return currentStep.pendingVerdicts
      .mapBy('userId')
      .includes(get(this, 'current.user.id'));
  }

  get canEditFlow() {
    if (get(this, 'jobOffer.isApproved')) {
      return false;
    }

    if (get(this, 'current.user.admin')) {
      return true;
    }

    return (
      this.needsYourApproval && get(this, 'jobOffer.currentStep.isEditable')
    );
  }

  resolveUsers = async (step) => {
    return get(step, 'users');
  };

  @action
  async editFlow() {
    const steps = this.approvalSteps;
    const customFlow = cloneFlowFromSteps({
      store: this.store,
      steps,
      modelName: 'job-offer-approval-flow',
    });

    const clonedSteps = await get(customFlow, 'steps');

    for await (const step of clonedSteps) {
      const users = await this.resolveUsers(step);
      set(step, 'pickedUsers', users.toArray());
    }

    set(this, 'jobOffer.jobOfferApprovalFlow', customFlow);
    set(this, 'editingFlow', true);
  }

  @action
  cancelUpdatedFlow() {
    this.jobOffer.rollbackAttributes();
    set(this, 'editingFlow', false);
  }

  @action
  async saveUpdatedFlow() {
    const approvalSteps = cloneStepsFromFlow({
      store: this.store,
      flowSteps: get(this, 'jobOffer.jobOfferApprovalFlow.steps'),
    });

    const approvalStepBeforeSave = this.jobOffer.approvalSteps.toArray(); // i'm deeply sorry for this hack.

    set(this, 'jobOffer.approvalSteps', approvalSteps);
    await get(this, 'jobOffer').updateApprovalFlow();

    approvalStepBeforeSave.forEach((step) => {
      this.store.unloadRecord(step);
    });

    set(this, 'editingFlow', false);
  }
}
