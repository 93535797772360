import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ContentBlockTestimonialSerializer extends ApplicationSerializer {
  payloadKeyFromModelName() {
    return 'content_block_testimonial';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-testimonial': ContentBlockTestimonialSerializer;
  }
}
