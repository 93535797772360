/* import __COLOCATED_TEMPLATE__ from './image-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';

import imageUrl from 'teamtailor/utils/image-url';
import { argDefault } from 'teamtailor/utils/arg-default';
import { maxImageFileSize } from 'teamtailor/constants/uploads';

export default class ImagePickerComponent extends Component {
  @service directUpload;
  @service('file-queue') uploader;
  @service intl;
  @service flashMessages;

  @argDefault theme = 'default';
  @argDefault type = 'image';
  @argDefault queueName = 'image-picker';

  get accept() {
    if (this.type === 'image' || this.type === 'favicon') {
      return 'image/jpeg,image/gif,image/png,image/webp';
    } else {
      return 'video/mp4,video/webm,video/flv,video/mov,video/quicktime,video/ogv,video/3gp,video/3g2,video/wmv,video/mpeg,video/avi';
    }
  }

  get imageStyles() {
    let mediaPreviewUrl;
    if (isEmpty(this.args.media)) {
      return undefined;
    } else {
      mediaPreviewUrl = imageUrl(this.args.media, 'employee_picture');
    }

    return htmlSafe(`background-image: url('${mediaPreviewUrl}');`);
  }

  get overlayStyles() {
    const opacity = this.args.overlayOpacity / 100;
    const color = this.args.overlayColor;

    return htmlSafe(`background-color: ${color}; opacity: ${opacity}`);
  }

  @action
  clearFileQueue() {
    let queue = this.uploader.find('image-picker');
    queue.files.forEach((file) => (file.queue = null));
    queue.files.clear();
  }

  @action
  async uploadToS3(file) {
    if (file.size > maxImageFileSize) {
      const queue = this.uploader.find(this.queueName);
      queue.files.removeObject(file);
      this.flashMessages.error(
        this.intl.t('components.media_library.file_too_big', {
          file: file.name,
        })
      );

      return;
    }

    const url = await this.directUpload.s3(file, {
      model: 'images',
      filename: `original.${file.extension}`,
    });

    try {
      await this.args.onImageUploaded(url);
      this.flashMessages.success(
        this.intl.t(`components.image_picker.${this.type}_uploaded`)
      );
    } catch (err) {
      if (err.errors.length) {
        this.flashMessages.error(err.errors[0].detail);
      }
    }
  }

  @action
  removePicture() {
    this.args.onImageRemoved();
  }
}
