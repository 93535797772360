/* import __COLOCATED_TEMPLATE__ from './custom-select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class SelectComponent extends Component {
  get isMultiSelect() {
    return this.args.customField.type === 'CustomField::MultiSelect';
  }

  get selectedOptions() {
    const { options } = this.args.customField;

    if (Array.isArray(this.args.selected) || this.isMultiSelect) {
      return options.filter((v) => (this.args.selected || []).includes(v.id));
    }

    let [selected] = options.filter((v) => this.args.selected === v.id);
    return selected;
  }

  changeMultiSelect(option) {
    if (this.selectedOptions.includes(option)) {
      this.args.onChange([
        ...this.selectedOptions.filter((o) => o.id !== option.id),
      ]);
    } else {
      this.args.onChange([...this.selectedOptions, option]);
    }
  }

  @action
  handleSelect(option) {
    if (this.isMultiSelect) {
      this.changeMultiSelect(option);
    } else {
      this.args.onChange(option);
    }
  }
}
