/* import __COLOCATED_TEMPLATE__ from './role.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { ROLES } from 'teamtailor/constants/roles';
import IntlService from 'ember-intl/services/intl';

type SidebarFiltersRoleArgs = {
  excludedRoles?: string[];
};

export default class extends Component<SidebarFiltersRoleArgs> {
  @service declare intl: IntlService;

  get excludedRoles() {
    return this.args.excludedRoles || [];
  }

  get availableRoles() {
    return ROLES.filter((role) => !this.excludedRoles.includes(role.id)).map(
      (roleObj) => {
        const { label, id } = roleObj;
        return {
          label: this.intl.t(label),
          id,
        };
      }
    );
  }
}
