import { inject as service } from '@ember/service';
import ActiveModelAdapter from 'active-model-adapter';
import { set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { assert } from '@ember/debug';
import ENV from 'teamtailor/config/environment';
import AdapterError from '@ember-data/adapter/error';
import { isEmpty } from '@ember/utils';
import { next } from '@ember/runloop';
import { ServerError } from 'teamtailor/services/server';
import classic from 'ember-classic-decorator';

export const UnauthorizedError = AdapterError.extend({
  message: 'UnauthorizedError',
});

export const NoAccessError = AdapterError.extend({
  message: 'NoAccessError',
});

@classic
export default class Application extends ActiveModelAdapter {
  @service appRefresher;
  @service bootData;
  @service flashMessages;
  @service pusher;
  @service session;
  @service router;

  coalesceFindRequests = true;
  maxCoalesceSize = 25;
  useFetch = true;

  urlForQuery(params) {
    const modelUrl = super.urlForQuery(...arguments);

    if (params?.groupAnalytics) {
      return `${modelUrl}/group_analytics`;
    }

    return modelUrl;
  }

  get host() {
    if (ENV.environment !== 'test') {
      return `${ENV.httpOrHttps}://tt.${ENV.HTTP_HOST}`;
    }
  }

  get namespace() {
    const companyUuid = get(this, 'bootData.companyUuid');

    if (ENV.environment !== 'test') {
      // We should probably run this in test also. That requires changes in tests.
      assert('companyUuid has to be set', companyUuid);
    }

    return `app/companies/${companyUuid}/api`;
  }

  get headers() {
    let headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-Ember-Route': this.router.currentRouteName,
    };

    let pusherSocketId = get(this, 'pusher.socketId');
    if (pusherSocketId) {
      headers['X-PUSHER-SOCKET-ID'] = pusherSocketId;
    }

    return headers;
  }

  ajaxOptions() {
    return {
      ...super.ajaxOptions(...arguments),
      credentials: 'include',
    };
  }

  pathForType(modelName) {
    let path = super.pathForType(modelName);
    return path.replace('/', '_');
  }

  groupRecordsForFindMany() {
    function* chunks(arr, n) {
      for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
      }
    }

    let result = super.groupRecordsForFindMany(...arguments);
    let groups = [];
    result.forEach((group) => {
      groups = [...groups, ...chunks(group, this.maxCoalesceSize)];
    });
    return groups;
  }

  handleResponse(status, headers, body) {
    if (status === 401) {
      if (body && body.message && body.message !== 'Not authorized') {
        const { loginUrl } = body;
        get(this, 'session')
          .invalidate()
          .then(() => {
            if (loginUrl) {
              window.location = `${loginUrl}?return_to=${window.location}`;
            }
          });
        return new UnauthorizedError();
      }

      return new ServerError(body, status);
    }

    if (status === 403) {
      if (get(this, 'session.data.authenticated.companies.length') > 1) {
        window.location = `https://app.${ENV.HTTP_HOST}/companies`;
      }

      return new NoAccessError();
    }

    const emberVersion = headers['x-ember-version'];
    const flashMessages = headers['x-flash-messages'];

    if (!isEmpty(emberVersion)) {
      set(this, 'appRefresher.deployedAppVersion', emberVersion);
    }

    if (!isEmpty(flashMessages)) {
      const messages = JSON.parse(flashMessages);
      Object.keys(messages).forEach((key) => {
        next(() => {
          get(this, 'flashMessages')[key](messages[key]);
        });
      });
    }

    return super.handleResponse(...arguments);
  }
}
