/* import __COLOCATED_TEMPLATE__ from './email-item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NurtureCampaignStepEmailItem extends Component {
  @tracked showContentFade = false;

  @action
  handleContentFade(e) {
    const wrapperEl = e.querySelector('div');
    this.showContentFade = e.clientHeight < wrapperEl.clientHeight;
  }
}
