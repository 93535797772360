/* import __COLOCATED_TEMPLATE__ from './job-info-heading.hbs'; */
import Component from '@glimmer/component';
export default class InsightsJobInfoHeading extends Component {
  get text() {
    const { departmentName, locations } = this.args;

    const locNames = locations
      .map((location) => location.name || location.city)
      .join(', ');

    return [departmentName || '', locNames];
  }

  get localClassNames() {
    const classes = ['root', `root--${this.args.variant}`];

    if (this.args.light) {
      classes.push('root--light');
    }

    return classes;
  }
}
