/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
import { verifyArg } from 'teamtailor/utils/verify-arg';
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';
import {
  AllowedLayouts,
  ALLOWED_LAYOUTS,
  DEFAULT_LAYOUT,
  LayoutClass,
} from 'teamtailor/constants/table';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

type Alignment = 'left' | 'right';
type SortDirection = 'asc' | 'desc';

export interface TableColumn {
  label: string;
  selectable?: boolean;
  sortable?: boolean;
  sortColumn?: string;
  defaultSortOrder?: SortDirection;
  sortOrder?: number;
  width?: string;
  colspan?: number;
  alignment?: Alignment;
}

interface Args {
  columns: TableColumn[];
  isEmpty: boolean;
  isLoading: boolean;
  sortColumn?: string;
  sortDirection?: SortDirection;
  tableLayout?: AllowedLayouts;
  isStuck?: boolean;
  roundedTop?: boolean;
  roundedBottom?: boolean;
  shadow?: boolean;
}

export default class TableContainerComponent extends Component<Args> {
  @argDefault sortColumn = '';
  @argDefault sortDirection = '';
  @argDefault roundedBottom = true;
  @argDefault shadow = true;

  declare headerRowRect?: DOMRect;
  declare stickyHeaderEl?: HTMLElement;
  declare tableWrapperEl?: HTMLElement;
  @tracked declare headerIsStuck: boolean;

  get roundedTop() {
    return (
      this.args.roundedTop ??
      (!isEmpty(this.args.isStuck) ? !this.args.isStuck : true)
    );
  }

  @action handleScroll(e: Event) {
    if (this.stickyHeaderEl) {
      this.stickyHeaderEl.scrollLeft = (e.target as HTMLElement).scrollLeft;
    }
  }

  @action handleWheel(e: WheelEvent) {
    if (e.deltaX !== 0 && this.tableWrapperEl) {
      e.preventDefault();
      const maxScrollLeft =
        this.tableWrapperEl.scrollWidth - this.tableWrapperEl.clientWidth;
      const newScrollLeft = (this.tableWrapperEl.scrollLeft += e.deltaX);
      const newScrollLeftClamped = Math.max(
        Math.min(newScrollLeft, maxScrollLeft),
        0
      );
      this.tableWrapperEl.scrollTo({
        left: newScrollLeftClamped,
        behavior: 'smooth',
      });
    }
  }

  get sortedColumns() {
    return this.args.columns.sortBy('sortOrder');
  }

  get oppositeSortDirection(): SortDirection {
    return this.sortDirection === 'desc' ? 'asc' : 'desc';
  }

  get layout(): string {
    verifyArg(
      this.args.tableLayout,
      ALLOWED_LAYOUTS,
      'Container @tableLayout',
      DEFAULT_LAYOUT
    );

    const layout = this.args.tableLayout || DEFAULT_LAYOUT;
    return LayoutClass[layout];
  }
}
