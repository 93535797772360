/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import { action, set } from '@ember/object';
import ReviewModel from 'teamtailor/models/review';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import { Changeset as changeset } from 'ember-changeset';
import { BufferedChangeset } from 'ember-changeset/types';
import CandidateModel from 'teamtailor/models/candidate';
import JobApplicationModel from 'teamtailor/models/job-application';
import { get } from 'teamtailor/utils/get';
import { dropTask } from 'ember-concurrency';
import { scheduleOnce } from '@ember/runloop';

interface CandidateModalNewMainReviewsComponentArgs {
  candidate: CandidateModel;
  jobApplication?: JobApplicationModel;
  rating: number;
  onClose: () => void;
}

export default class CandidateModalNewMainReviewsComponent extends Component<CandidateModalNewMainReviewsComponentArgs> {
  @service declare current: Current;
  @service declare store: Store;

  @tracked changeset: BufferedChangeset | null = null;
  @tracked editReview = false;

  constructor(owner: unknown, args: CandidateModalNewMainReviewsComponentArgs) {
    super(owner, args);

    scheduleOnce('afterRender', this, this.createChangeSet);
  }

  get userHasNotReviewed() {
    const reviews = get(this.args.candidate, 'reviews');

    if (!get(reviews, 'isFulfilled')) {
      return false;
    }

    return !reviews.toArray().some((review: ReviewModel) => {
      const user = get(review, 'user');
      return get(user, 'id') === this.current.user.id;
    });
  }

  get isValid() {
    const rating = get(this.changeset, 'rating');
    return !!rating;
  }

  @action
  showActions(review: ReviewModel) {
    return (
      this.isOwnReview(review) || this.current.user.adminOrRecruitmentAdmin
    );
  }

  @action
  isOwnReview(review: ReviewModel) {
    return this.current.user.id === review.belongsTo('user').id();
  }

  @action
  createChangeSet() {
    const reviews = get(this.args.candidate, 'reviews');

    const userReview = reviews.find((review) => {
      const user = get(review, 'user');
      return get(user, 'id') === this.current.user.id;
    });

    if (userReview) {
      this.changeset = changeset(userReview);
      return;
    }

    this.changeset = changeset(
      this.store.createRecord('review', {
        candidate: this.args.candidate,
        jobApplication: this.args.jobApplication,
        rating: this.args.rating,
      })
    );
  }

  handleSubmit = dropTask(async () => {
    if (this.changeset === null) {
      return;
    }

    await this.changeset.save();
    this.editReview = false;
    this.changeset = null;
  });

  @action
  handleCommentChange({ target }: { target: HTMLTextAreaElement }) {
    if (this.changeset) {
      this.changeset.comment = target.value;
    }
  }

  @action
  async handleDeleteClick(review: ReviewModel) {
    await review.destroyRecord();

    const { candidate } = this.args;
    await candidate.fetchLastReviewsState();

    this.args.onClose();
  }

  @action
  handleEditClick(review: ReviewModel) {
    this.editReview = true;
    this.changeset = changeset(review);
  }

  @action
  handleCancelClick() {
    if (this.changeset?.isNew) {
      const underlyingRecord = this.changeset._content as ReviewModel;
      underlyingRecord.unloadRecord();
    }

    this.changeset = null;
    this.editReview = false;

    this.args.onClose();
  }

  @action
  handleOnRate(rating: number) {
    if (this.changeset) {
      set(this.changeset, 'rating', rating);
    }
  }

  @action
  handleCmdEnter() {
    if (this.isValid) {
      this.handleSubmit.perform();
    }
  }
}
