/* import __COLOCATED_TEMPLATE__ from './reminder-sent.hbs'; */
import { inject as service } from '@ember/service';
import Base from './base';

export default class ReminderSentComponent extends Base {
  @service current;

  get user() {
    return this.current.user;
  }
}
