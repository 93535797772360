/* import __COLOCATED_TEMPLATE__ from './people.hbs'; */
import AdjustmentsBaseComponent from './base';
import { action, get } from '@ember/object';

export default class PeopleAdjustmentsComponent extends AdjustmentsBaseComponent {
  @action
  async handleShapeChange(shape) {
    this.model.customEmployeesShape = shape;
    await this.args.onChange();
  }

  get employeesShapeOptions() {
    const defaultShape = get(this, 'design.employeesShape');

    return [
      {
        id: null,
        label: this.intl.t('content.editor.section.people.shapes.default', {
          shape: this.intl.t(
            `content.editor.section.people.shapes.${defaultShape}`
          ),
        }),
      },
      {
        id: 'circle',
        label: this.intl.t('content.editor.section.people.shapes.circle'),
      },
      {
        id: 'square',
        label: this.intl.t('content.editor.section.people.shapes.square'),
      },
    ];
  }
}
