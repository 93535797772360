/* import __COLOCATED_TEMPLATE__ from './recruiter.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { JobBulkArgs } from 'teamtailor/constants/jobs';
import UserModel from 'teamtailor/models/user';

export default class BulkActionsJobsRecruiterComponent extends Component<JobBulkArgs> {
  @tracked recruiter: UserModel | null = null;

  @action
  onClose(): void {
    this.args.onClose();
    this.recruiter = null;
  }

  @action
  handleBulkChange(): void {
    const data = {
      recruiter_id: this.recruiter?.id,
    };
    this.args.handleBulkChange(data, 'bulk_recruiter');
    this.onClose();
  }
}
