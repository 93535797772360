/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { later } from '@ember/runloop';
import { Changeset as changeset } from 'ember-changeset';
import { BufferedChangeset } from 'ember-changeset/types';

import Current from 'teamtailor/services/current';
import { CoreModalBaseApi } from 'teamtailor/components/core/modal/base';
import RouterService from '@ember/routing/router-service';

export default class EmployeeDashboardJobViewSettings extends Component {
  @service declare current: Current;
  @service declare router: RouterService;

  @tracked modalBaseApi?: CoreModalBaseApi;
  @tracked open = false;
  @tracked declare changeset?: BufferedChangeset;

  @action
  handleOpen() {
    this.open = true;
    this.changeset = changeset(this.current.company);
  }

  @action
  handleClose() {
    this.changeset = undefined;
    this.open = false;
  }

  @action
  registerModal(modalBaseApi: CoreModalBaseApi) {
    this.modalBaseApi = modalBaseApi;
  }

  save = dropTask(async () => {
    await this.changeset?.save();

    later(() => {
      this.modalBaseApi?.onClose();
      this.router.refresh('employee');
    }, 150);
  });
}
