/* import __COLOCATED_TEMPLATE__ from './button-item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
export default class ButtonItemComponent extends Component {
  get iconStyle() {
    return this.args.iconOptions?.style;
  }

  get iconSize() {
    return this.args.iconOptions?.size;
  }

  get active() {
    if (this.args.active !== undefined) {
      return this.args.active;
    }

    return this.args.value === this.args.currentValue;
  }

  @action
  handleClick() {
    if (this.args.onClick) {
      this.args.onClick();
    } else if (this.args.click) {
      this.args.click();
    } else if (this.args.onUpdate) {
      this.args.onUpdate(this.args.value);
    } else if (this.args.currentValue) {
      this.args.currentValue = this.args.value;
    }
  }
}
