/* import __COLOCATED_TEMPLATE__ from './two-factor-authentication.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service declare intl: IntlService;

  get options() {
    return [
      {
        id: 'true',
        label: this.intl.t(
          'search.two_factor_authentication.two_factor_authentication_activated'
        ),
      },
      {
        id: 'false',
        label: this.intl.t(
          'search.two_factor_authentication.two_factor_authentication_inactive'
        ),
      },
    ];
  }
}
