/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import HireQualityResponseModel from 'teamtailor/models/hire-quality-response';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { isPresent } from '@ember/utils';
import RouteHelper from 'teamtailor/services/route-helper';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';

type Args = {
  hireQualityResponse: HireQualityResponseModel;
};

export default class CandidateModalSectionJobOffersItem extends Component<Args> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare routeHelper: RouteHelper;

  @tracked cancelModalIsOpen = false;

  get hireQualityResponse(): HireQualityResponseModel {
    return this.args.hireQualityResponse;
  }

  get surveyEditRoute() {
    return this.routeHelper.getNestedRoute('hire-quality.edit');
  }

  get canEditSurvey(): boolean {
    if (this.hireQualityResponse.isSent) {
      return get(this.hireQualityResponse.user, 'id') === this.current.user.id;
    } else {
      return this.current.user.admin;
    }
  }

  get displayReviewPeriod(): boolean {
    return (
      get(this.hireQualityResponse.jobApplication, 'isHired') &&
      this.hireQualityResponse.reviewPeriod > 0
    );
  }

  get reviewPeriod(): string {
    const count = this.hireQualityResponse.reviewPeriod;
    const unit = this.hireQualityResponse.reviewPeriodUnit;
    return `${this.intl.t(`common.x_${unit}`, {
      count,
    })}`;
  }

  get oldJobMatch(): number {
    return get(this.hireQualityResponse.jobApplication, 'match');
  }

  get jobMatch(): string {
    return `${this.hireQualityResponse.match}%`;
  }

  get jobMatchDiff(): number {
    return this.hireQualityResponse.match - this.oldJobMatch;
  }

  get showJobMatchBreakdown(): boolean {
    return isPresent(this.hireQualityResponse.match);
  }

  get feedback(): string {
    const changedAttributes = this.hireQualityResponse.changedAttributes();
    const hasDirtyAttributes = get(
      this.hireQualityResponse,
      'hasDirtyAttributes'
    );

    if (hasDirtyAttributes && changedAttributes.feedback) {
      return changedAttributes.feedback[0];
    }

    return this.hireQualityResponse.feedback;
  }
}
