/* import __COLOCATED_TEMPLATE__ from './twitter.hbs'; */
import ShareButton from 'teamtailor/components/share-button/share-button';

export default class extends ShareButton {
  shareURL = 'https://twitter.com/intent/tweet';

  buildUrl() {
    let url = this.shareURL;
    url += `?text=${this.args.title}`;
    url += `&url=${encodeURIComponent(this.args.url)}`;
    url += `&hashtags=${this.args.hashtags}`;
    url += `&via=${this.args.via}`;
    return url;
  }
}
