/* import __COLOCATED_TEMPLATE__ from './custom-report-table-cell.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';

interface Args {
  property: { type: string; name: string };
  row?: string[];
  initialDataProperties?: { name: string }[];
  isHeader?: boolean;
  content?: string;
}

export default class CustomReportTableCell extends Component<Args> {
  @tracked declare el: HTMLElement | undefined;

  popperOptions = Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: true,
      },
    },
  });

  get columnData() {
    const propertyName = this.args.property.name;

    if (!this.args.initialDataProperties) return null;

    const index = this.args.initialDataProperties.findIndex(
      (dataProperty) => dataProperty.name === propertyName
    );

    return this.args.row?.[index];
  }

  get showTooltip() {
    return this.isContentOverflowing && isPresent(this.args.content);
  }

  get isContentOverflowing() {
    if (this.el) {
      return this.el.scrollWidth > this.el.offsetWidth;
    }

    return false;
  }

  @action
  onDidInsert(element: HTMLElement) {
    this.el = element;
  }
}
