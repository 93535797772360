/* import __COLOCATED_TEMPLATE__ from './stats.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class Stats extends Component {
  @service current;

  get orderedStageTypeNames() {
    if (!this.current.company) return [];

    return get(this.current.company, 'orderedStageTypes')?.map(
      (stageType) => stageType.category
    );
  }

  get orderedStageTypeNamesWithInProcess() {
    return [
      this.orderedStageTypeNames[0],
      'in_process',
      ...this.orderedStageTypeNames.slice(
        1,
        this.orderedStageTypeNames.length - 1
      ),
    ];
  }
}
