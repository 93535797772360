/* import __COLOCATED_TEMPLATE__ from './channel-name-divider.hbs'; */
import Component from '@glimmer/component';
import NotificationsService from 'teamtailor/services/notifications';
import { inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

type Args = {
  jobId: string;
  name: string;
  showButton?: boolean;
};

export default class ChannelNameDividerComponent extends Component<Args> {
  @service declare notifications: NotificationsService;

  @tracked actionIds: string[] = [];

  setActionIdsForJobCollaborations = modifier(() => {
    if (this.notifications.unreadMentions) {
      this.actionIds = this.notifications.unreadMentions[this.args.jobId] || [];
    }
  });

  @action
  async markChannelAsRead(event: MouseEvent) {
    this.notifications.markAsRead({
      actionIds: this.actionIds,
    });

    event.preventDefault();
    event.stopPropagation();
  }
}
