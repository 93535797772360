/* import __COLOCATED_TEMPLATE__ from './page.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { PageModel } from 'teamtailor/models';
import { enqueueTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';

export default class SmartLinkModalPage extends Component {
  @service declare store: Store;
  @service declare current: Current;

  @tracked selectedPage: PageModel | null = null;

  fetchPagesTask = enqueueTask(async ({ searchTerm: query }) => {
    const pages = await this.store.query('page', {
      query,
      status: 'published',
      page: 1,
      per_page: 12,
      no_templates: 'no',
    });

    return pages;
  });
}
