/* import __COLOCATED_TEMPLATE__ from './jobs.hbs'; */
import FormBaseComponent from './base';
import { get } from 'teamtailor/utils/get';

export default class JobsAdjustmentsFormComponent extends FormBaseComponent {
  reload() {
    if (get(this, 'model.page.isJobListPage')) {
      this.iframePreview.reloadIframe();
    } else {
      super.reload();
    }
  }
}
