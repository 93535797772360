/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';
import { set, action } from '@ember/object';
import { later } from '@ember/runloop';
import { argDefault } from 'teamtailor/utils/arg-default';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import TimeFormatService from 'teamtailor/services/time-format';
import IntlService from 'ember-intl/services/intl';
import ResizeObserverService from 'ember-resize-observer-service';
import CustomizeTableColumn from 'teamtailor/utils/candidate-table/column';

interface Args {
  isLoading: boolean;
  availableColumns: CustomizeTableColumn[];
  selectedColumns: CustomizeTableColumn[];
  defaultColumns: CustomizeTableColumn[];
  sortedColumns: CustomizeTableColumn[];
  sortDirection: 'asc' | 'desc';
  additionalCandidateIds: string[];
  additionalCandidateIdCount?: string;
}

export default class extends Component<Args> {
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;
  @service declare intl: IntlService;
  @service declare resizeObserver: ResizeObserverService;

  @argDefault additionalCandidateIdCount = 0;
  @tracked showNewCandidateAnimation = false;

  get skeletonColumns() {
    return this.sortedColumns.map((column) => {
      if (column.name === 'name_or_email') {
        return {
          width: '2xl',
          withAvatar: true,
          avatarClasses: 'rounded-full',
        };
      } else {
        return {
          width: 'm',
        };
      }
    });
  }

  selectColClasses = 'w-0 overflow-hidden !p-0';

  get sortedColumns() {
    return this.args.sortedColumns;
  }

  get oppositeSortDirection() {
    return this.args.sortDirection === 'desc' ? 'asc' : 'desc';
  }

  @action
  handleAdditionalCandidate() {
    if (
      this.args.additionalCandidateIds.length !==
      this.additionalCandidateIdCount
    ) {
      this.additionalCandidateIdCount = this.args.additionalCandidateIds.length;
      set(this, 'showNewCandidateAnimation', true);
      later(() => {
        set(this, 'showNewCandidateAnimation', false);
      }, 700);
    }
  }
}
