/* import __COLOCATED_TEMPLATE__ from './scale.hbs'; */
import Component from '@glimmer/component';

type Field = {
  maximum_value?: number;
  beginning_label?: string;
  end_label?: string;
};

type Args = {
  field: Field;
  selectedValue?: number;
};

export default class ScaleComponent extends Component<Args> {
  get values(): number[] {
    const value = this.args.field.maximum_value || 5;
    return Array.from({ length: value }, (_, i) => i + 1);
  }
}
