/* import __COLOCATED_TEMPLATE__ from './attendee-status-actions.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import IntlService from 'ember-intl/services/intl';

import UPDATE_ATTENDEE_STATUS from 'teamtailor/gql/update-meeting-event-attendee.graphql';
import MeetingEventAttendeeModel from 'teamtailor/models/meeting-event-attendee';

interface Args {
  attendee: MeetingEventAttendeeModel;
}

export default class AttendeeStatusActions extends Component<Args> {
  @service declare intl: IntlService;
  @service declare apollo: TeamtailorApolloService;

  get statusButtonText(): string {
    const currentStatus = this.args.attendee.status.toLowerCase();
    if (currentStatus === 'needs_action') {
      return this.intl.t('components.meetings.status.your_status');
    }

    return this.intl.t(`components.meetings.status.${currentStatus}`);
  }

  @action
  async updateStatus(status: string) {
    const variables = {
      attendeeId: this.args.attendee.id,
      status,
    };

    await this.apollo.mutate({
      mutation: UPDATE_ATTENDEE_STATUS,
      variables,
    });

    this.args.attendee.reload();
  }
}
