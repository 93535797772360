/* import __COLOCATED_TEMPLATE__ from './unsplash.hbs'; */
import Component from '@glimmer/component';
import config from 'teamtailor/config/environment';
import { action } from '@ember/object';

export default class MediaLibraryMasonryListImageToolsUnsplashComponent extends Component {
  willDestroy() {
    super.willDestroy();
    this.args.image.tasks.clear();
  }

  get linkToAuthor() {
    return `${this.args.image.authorLink}?utm_source=${config.unsplash.name}&utm_medium=referral`;
  }

  @action
  handleAddClick() {
    if (this.args.onPickImage) {
      this.args.onPickImage(this.args.image);
    }
  }
}
