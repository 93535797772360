/* import __COLOCATED_TEMPLATE__ from './user.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Store from '@ember-data/store';

type Args = {
  data: {
    value: string;
  };
};

export default class DataTableUserComponent extends Component<Args> {
  @service declare store: Store;

  get userId() {
    return this.args.data.value;
  }

  get userRecord() {
    return this.fetchUserRecordTask.lastSuccessful?.value;
  }

  fetchUserRecordTask = dropTask(async () => {
    try {
      return await this.store.findRecord('user', this.userId);
    } catch {
      return undefined;
    }
  });

  @action
  onEnter() {
    if (this.fetchUserRecordTask.performCount === 0) {
      this.fetchUserRecordTask.perform();
    }
  }
}
