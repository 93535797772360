/* import __COLOCATED_TEMPLATE__ from './sms.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import Current from 'teamtailor/services/current';
import { ComposerSignature } from 'teamtailor/components/candidate-messages/composer';
import { next } from '@ember/runloop';
import { calculateCredits, MAX_LENGTH } from 'teamtailor/utils/sms';
import { dropTask } from 'ember-concurrency';

export default class ComposerSms extends Component<ComposerSignature> {
  @service declare store: Store;
  @service declare current: Current;
  @tracked body = '';
  smsMaxLength = MAX_LENGTH;
  delayUntil: Date | string | null = null;

  declare textarea: HTMLTextAreaElement;

  get totalCreditsCost() {
    const smsMessageData = {
      message: this.body,
      optOutMessage: '',
      replyMessage: this.current.company.replyToInformation,
    };

    const { isNumberInShortcodeCountry } = this.args.candidate;
    const candidatesNumbers = {
      candidatesWithMessageNoSuffix: isNumberInShortcodeCountry ? 0 : 1,
      candidatesWithMessageReplySuffix: isNumberInShortcodeCountry ? 1 : 0,
      candidatesWithMessageOptOutSuffix: 0,
      candidatesWithMessageOptOutAndReplySuffix: 0,
    };
    return calculateCredits(smsMessageData, candidatesNumbers);
  }

  get isValid() {
    return !!this.body;
  }

  submitMessage = dropTask(async () => {
    const message = this.store.createRecord('message', {
      candidate: this.args.candidate,
      createdAt: new Date(),
      job: this.args.jobApplication?.job,
      jobApplicationId: this.args.jobApplication?.id,
      user: this.current.user,
      body: this.body,
      delayUntil: this.delayUntil,
      isSms: true,
    });

    await message.save();

    this.body = '';
    this.delayUntil = null;
    this.args.onMessageCreated(message);
  });

  @action
  handleCmdEnter() {
    if (this.isValid) {
      this.submitMessage.perform();
    }
  }

  @action
  submitDelayedMessage(delayUntil: Date | string) {
    this.delayUntil = delayUntil;
    this.submitMessage.perform();
  }

  @action
  handleBodyChange({ target }: { target: HTMLTextAreaElement }) {
    this.body = target.value;
  }

  @action
  registerTextarea(textarea: HTMLTextAreaElement) {
    this.textarea = textarea;
  }

  @action
  insertEmoji(emoji: string) {
    this.body = this.body + emoji;
    next(() => {
      this.textarea.focus();
    });
  }
}
