/* import __COLOCATED_TEMPLATE__ from './data-privacy.hbs'; */
import WidgetClass from './base/widget-class';
import { get } from 'teamtailor/utils/get';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class DataPrivacyWidgetComponent extends WidgetClass {
  @service current;
  @service store;
  @service flipper;

  constructor(...args) {
    super(...args);

    this.fetchRemovalRequested.perform();
    this.fetchDataRequested.perform();
    this.fetchPermissionMissing.perform();
    this.fetchExpiredConsent.perform();
    this.fetchWillBeDeleted.perform();
  }

  fetchRemovalRequested = task(async () => {
    return this.store.query('candidate', this.removalRequestedQuery);
  });

  fetchDataRequested = task(async () => {
    return this.store.query('candidate', this.dataRequestedQuery);
  });

  fetchPermissionMissing = task(async () => {
    return this.store.query('candidate', this.permissionMissingQuery);
  });

  fetchExpiredConsent = task(async () => {
    return this.store.query('candidate', this.expiredConsentQuery);
  });

  fetchWillBeDeleted = task(async () => {
    return this.store.query('candidate', this.willBeDeletedQuery);
  });

  get permissionMissingQuery() {
    return {
      root: [{ consent_missing_at: { exists: 'yes' } }],
      per_page: 1,
    };
  }

  get expiredConsentQuery() {
    return {
      root: [{ consent_expires_at: { relative_more_than: 0 } }],
      per_page: 1,
    };
  }

  get removalRequestedQuery() {
    return {
      root: [{ removal_requested_at: { exists: 'yes' } }],
      per_page: 1,
    };
  }

  get dataRequestedQuery() {
    return {
      root: [{ data_requested_at: { exists: 'yes' } }],
      per_page: 1,
    };
  }

  get willBeDeletedQuery() {
    return {
      root: [{ will_be_deleted_at: { exists: 'yes' } }],
      per_page: 1,
    };
  }

  get removalRequestedCount() {
    return get(this.fetchRemovalRequested, 'lastSuccessful.value.meta.total');
  }

  get dataRequestedCount() {
    return get(this.fetchDataRequested, 'lastSuccessful.value.meta.total');
  }

  get permissionMissingCount() {
    return get(this.fetchPermissionMissing, 'lastSuccessful.value.meta.total');
  }

  get fetchExpiredConsentCount() {
    return get(this.fetchExpiredConsent, 'lastSuccessful.value.meta.total');
  }

  get willBeDeletedCount() {
    return get(this.fetchWillBeDeleted, 'lastSuccessful.value.meta.total');
  }
}
