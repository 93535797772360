/* import __COLOCATED_TEMPLATE__ from './nurture-candidates-recipients-status.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface OptionsArgs {
  id: string | undefined;
  label: string | undefined;
}

export default class extends Component {
  @service declare intl: IntlService;

  get options(): OptionsArgs[] {
    return [
      {
        id: 'active',
        label: this.intl.t('nurture_campaign.status_options.active'),
      },
      {
        id: 'completed',
        label: this.intl.t('nurture_campaign.status_options.completed'),
      },
      {
        id: 'exited',
        label: this.intl.t('nurture_campaign.status_options.exited'),
      },
    ];
  }
}
