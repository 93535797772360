/* import __COLOCATED_TEMPLATE__ from './segment.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { query } from 'ember-data-resources';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import deepmerge from 'deepmerge';
import { DEFAULT_SEARCH_PARAMS } from 'teamtailor/controllers/candidates/segment';

export default class SegmentWidget extends WidgetClass {
  @service declare store: Store;
  @service declare current: Current;

  candidatesQuery = query(this, 'candidate', () => {
    const filters = get(this.segment, 'filters');

    const params = deepmerge(DEFAULT_SEARCH_PARAMS, filters);

    return Object.assign(
      {
        page: 1,
        per_page: 5,
        sort_column: 'created_at',
        sort_direction: 'desc',
      },
      params
    );
  });

  get emptyResult() {
    return !!this.candidates && this.candidates.length === 0;
  }

  get candidates() {
    return this.candidatesQuery.records;
  }

  get isLoading() {
    return !this.isSegmentLoaded || this.candidatesQuery.isLoading;
  }

  get isSegmentLoaded() {
    return !!get(this.segment, 'isFulfilled');
  }

  get segment() {
    return get(this.widget, 'segment');
  }

  get shouldDisplayFooter() {
    return !this.isLoading && !this.emptyResult;
  }
}
