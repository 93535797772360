/* import __COLOCATED_TEMPLATE__ from './todo.hbs'; */
import BaseComponent from './base';
import { action, setProperties } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class AssignComponent extends BaseComponent {
  @tracked todos = [];
  @tracked editingTodo = null;
  @tracked editingTodoIndex = null;

  get validTodos() {
    return this.todos.length > 0;
  }

  @action
  handleAddTodo(attributes) {
    if (isEmpty(attributes.value.trim())) {
      return;
    }

    this.todos.pushObject(this.store.createRecord('todo', attributes));

    return true;
  }

  @action
  handleDeleteTodo(index) {
    this.todos.removeAt(index);
  }

  @action
  editTodo(index, todo) {
    this.editingTodoIndex = index;
    this.editingTodo = todo;
  }

  @action
  saveTodo({ value, description, due, assignee, candidate }) {
    setProperties(this.editingTodo, {
      value,
      description,
      due,
      assignee,
      candidate,
    });

    this.editingTodoIndex = null;
    this.editingTodo = null;
  }

  @action
  createTodos() {
    const { bulkOptions } = this;

    const todos = this.todos.map((todo) => todo.serialize());

    const data = {
      selectedCandidateIds: this.args.selectedIds,
      todos,
      ...bulkOptions,
    };

    const promise = this.bulkApi.post('bulk_todo', data).then(() => {
      this.todos.map((todo) => todo.unloadRecord());
      this.todos = [];
      this.args.onClose();
    });
    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
