/* import __COLOCATED_TEMPLATE__ from './report-navigation.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import FlipperService from 'teamtailor/services/flipper';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import AnalyticsService from 'teamtailor/services/analytics';
import Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency';

type NavigationItem = {
  title: string;
  route?: string;
  items?: NavigationItem[];
  condition?: (self: ReportNavigation) => boolean;
};

const SECTIONS = [
  {
    title: 'reports.audience',
    items: [
      {
        title: 'reports.visitors.title',
        route: 'analytics.overview.visitors',
      },
      {
        title: 'reports.devices',
        route: 'analytics.overview.mobile',
      },
      {
        title: 'reports.sources',
        route: 'analytics.overview.sources',
      },
      {
        title: 'reports.promotions',
        route: 'analytics.overview.promotions',
      },
    ],
  },
  {
    title: 'reports.recruitment',
    items: [
      {
        title: 'reports.pipeline_overview.title',
        route: 'analytics.overview.pipeline-overview',
      },
      {
        title: 'reports.pipeline_speed',
        route: 'analytics.overview.pipeline-speed',
      },
      {
        title: 'reports.pipeline_conversion.title',
        route: 'analytics.overview.pipeline-conversions',
      },
      {
        title: 'reports.hires',
        route: 'analytics.overview.time-to-hire',
      },
      {
        title: 'reports.meetings.title',
        route: 'analytics.overview.meetings',
      },
      {
        title: 'reports.assessment_results',
        route: 'analytics.overview.partner-results',
        condition: (self: ReportNavigation) =>
          self.hasPartnerActivationsWithResults,
      },
      {
        title: 'reports.nps',
        route: 'analytics.overview.nps',
      },
      {
        title: 'reports.jobs_overview',
        route: 'analytics.overview.job-overview',
      },
      {
        title: 'reports.reject_reasons',
        route: 'analytics.overview.reject-reasons',
      },
    ],
  },
  {
    title: 'reports.team',
    condition: (self: ReportNavigation) =>
      get(self.flipper, 'disable_employee_analytics') === false,

    items: [
      {
        title: 'reports.activity',
        route: 'analytics.overview.team-activity',
      },
      {
        title: 'reports.referrals',
        route: 'analytics.overview.referrals',
      },
    ],
  },
];
export default class ReportNavigation extends Component {
  @service declare current: Current;
  @service declare flipper: FlipperService;
  @service declare router: RouterService;
  @service declare analytics: AnalyticsService;
  @service declare store: Store;

  @tracked stuck = false;
  previousScrollTop = 0;
  showHamburgerMenuReportNavigationKey =
    'showHamburgerReportNavigationKey-Report-Navigation';

  pinnedMenuListener?: () => void;

  @computedLocalStorage(Boolean, 'showHamburgerMenuReportNavigationKey', false)
  declare showHamburgerMenu: boolean;

  set pinned(value: boolean) {
    this.showHamburgerMenu = !value;
  }

  get pinned() {
    return !this.showHamburgerMenu;
  }

  get hasPartnerActivationsWithResults() {
    return (
      (this.fetchPartnerActivations.lastSuccessful?.value?.length || 0) > 0
    );
  }

  fetchPartnerActivations = dropTask(async () => {
    let partnerActivations = this.store.peekAll('partner-activation');
    if (partnerActivations.length) {
      return partnerActivations;
    }

    partnerActivations = await this.store.query('partner-activation', {
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    return partnerActivations;
  });

  get sections() {
    return SECTIONS.map((section: NavigationItem) => {
      if (!section.condition || section.condition(this)) {
        return {
          ...section,
          items: section.items?.filter(
            (item: NavigationItem) => !item.condition || item.condition(this)
          ),
        };
      }

      return null;
    }).compact();
  }

  @action
  initPinnedMenu(div: HTMLElement) {
    const box = div.getBoundingClientRect();

    const { body, documentElement: docEl } = document;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

    const clientTop = docEl.clientTop || body.clientTop || 0;

    const top = box.top + scrollTop - clientTop;
    const parentComputedStyle = getComputedStyle(div.parentElement || div);
    const computedStyle = getComputedStyle(div);
    const boxHeight =
      box.height +
      parseInt(parentComputedStyle.paddingTop.split('px')[0] || '0') +
      parseInt(parentComputedStyle.paddingBottom.split('px')[0] || '0');

    const startTop = computedStyle.top;
    const startY = Math.round(top);
    this.previousScrollTop = scrollTop;

    if (this.pinnedMenuListener) {
      window.removeEventListener('scroll', this.pinnedMenuListener);
    }

    this.pinnedMenuListener = () => {
      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      if (scrollTop > startY && !this.stuck) {
        this.stuck = true;
      } else if (scrollTop < startY && this.stuck) {
        this.stuck = false;
        div.style.top = startTop;
      }

      if (this.stuck) {
        const diffHeight = window.innerHeight - boxHeight;
        if (diffHeight < 0) {
          const scrollDiff = scrollTop - this.previousScrollTop;
          const currentTop = Number(div.style.top.split('px')[0] || 0);

          let y = currentTop - scrollDiff;

          if (y < diffHeight) {
            y = diffHeight;
          } else if (y > 0) {
            y = 0;
          }

          div.style.top = `${y}px`;
        }
      }

      this.previousScrollTop = scrollTop;
    };

    window.addEventListener('scroll', this.pinnedMenuListener);
  }

  @action
  removeScrollListener() {
    if (this.pinnedMenuListener) {
      window.removeEventListener('scroll', this.pinnedMenuListener);
    }
  }

  @action
  navigateToReport(route: string) {
    this.router.transitionTo(route);
  }

  @action
  togglePinned() {
    this.pinned = !this.pinned;
  }
}
