/* import __COLOCATED_TEMPLATE__ from './trial-period-banner.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

export interface TrialPeriodBannerArgs {
  daysToExpire?: number;
}

const HUBSPOT_FORM_SUBDOMAIN = 'https://resources.teamtailor.com';
const HUBSPOT_SUBDIRECTORY = 'product-experience';

const HUBSPOT_AVAILABLE_LANGUAGE_CODES = [
  'da',
  'de',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'it',
  'lt',
  'lv',
  'nl',
  'no',
  'pl',
  'pt',
  'sv',
];

export default class TrialPeriodBannerComponent extends Component<TrialPeriodBannerArgs> {
  @service declare current: Current;

  hubspotAvailableLanguageCodes = HUBSPOT_AVAILABLE_LANGUAGE_CODES;

  get isUrgent(): boolean {
    return this.args.daysToExpire !== undefined && this.args.daysToExpire <= 7;
  }

  get languageCode() {
    const languageCode = get(this.current.company, 'localeAts');

    return this.hubspotAvailableLanguageCodes.includes(languageCode)
      ? languageCode
      : 'en';
  }

  get iframeUrl() {
    return `${HUBSPOT_FORM_SUBDOMAIN}/${this.languageCode}/${HUBSPOT_SUBDIRECTORY}`;
  }
}
