/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action, get } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class MarketplaceActivationComponent extends Component {
  @tracked provider;
  @service store;
  @service flashMessages;
  @service intl;
  @service permissions;

  get isChannel() {
    return !!this.args.channelId;
  }

  get type() {
    if (this.isChannel) {
      return 'channel';
    }

    if (this.isPartner) {
      return 'partner';
    }

    return null;
  }

  get resourceName() {
    if (this.type === 'channel') {
      return 'channel-activation';
    }

    if (this.type === 'partner') {
      return 'partner-activation';
    }

    return null;
  }

  get isPartner() {
    return !!this.args.partnerId;
  }

  get canActivate() {
    if (this.permissions.has('settings/update_company')) return true;
    return (
      this.isChannel && this.permissions.has('settings/update_recruitment')
    );
  }

  handleOauthActivation = task(async (callback) => {
    await callback();
  });

  @action async fetchProvider() {
    if (this.isPartner) {
      const partner = await this.store.findRecord(
        this.type,
        this.args.partnerId
      );
      this.provider = partner;
    }

    if (this.isChannel) {
      const channel = await this.store.findRecord(
        this.type,
        this.args.channelId
      );
      this.provider = channel;
    }
  }

  @action async onOauthSuccess({ authorization: data }) {
    if (data?.pusherAuth) {
      const { type, id } = data;
      await this.store.findRecord(type, id);
      return this.flashMessages.success(
        this.intl.t('marketplace.partner_connected', {
          subject: get(this, 'partner.name'),
        })
      );
    }

    try {
      const authorization = await this.store.createRecord(
        'authorization',
        Object.assign(data)
      );

      await authorization.save();

      const activation = this.store.createRecord(this.resourceName, {
        [this.type]: this.provider,
        authorization,
      });

      if (this.isChannel) {
        activation.activated = true;
      }

      await activation.save();

      return this.flashMessages.success(
        this.intl.t('marketplace.partner_connected', {
          subject: get(this, 'partner.name'),
        })
      );
    } catch (err) {
      return get(this, 'flashMessages').error(
        this.intl.t('errors.something_went_wrong')
      );
    }
  }

  @action async onOauthFailure() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }
}
