/* import __COLOCATED_TEMPLATE__ from './restrict.hbs'; */
import BaseComponent from './base';
import { action } from '@ember/object';

export default class RestrictComponent extends BaseComponent {
  @action
  restrict() {
    return this.postAction('bulk_restrict', this.postData()).then(() => {
      this.args.onClose();
    });
  }
}
