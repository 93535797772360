/* import __COLOCATED_TEMPLATE__ from './manual.hbs'; */
import { action } from '@ember/object';
import PromotionBaseClass from './promotion-base';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class ManualPromotionClass extends PromotionBaseClass {
  @tracked checkoutData;

  get isPurchaseButtonDisabled() {
    if (this.customOptionSelected) {
      if (isPresent(this.args.purchase.customPrice)) {
        const availableAmounts = this.args.purchase.availableBudgets
          .map((budget) => budget.amount)
          .filter(Boolean);

        return (
          Number(this.args.purchase.customPrice) < Math.min(...availableAmounts)
        );
      } else {
        return true;
      }
    }

    return false;
  }

  get customOptionSelected() {
    return this.args.purchase.pickedBudget === 'custom';
  }

  get price() {
    return this.customOptionSelected
      ? Number(this.args.purchase.customPrice)
      : this.args.price;
  }

  get packageName() {
    return this.customOptionSelected
      ? 'custom'
      : this.args.purchase.pickedBudget?.name;
  }

  @action
  setPrice(value) {
    if (isPresent(value)) {
      const number = Number(value);

      this.args.purchase.customPrice = Number.isInteger(number)
        ? number
        : Math.floor(number);
    }
  }
}
