/* import __COLOCATED_TEMPLATE__ from './internal-jobs.hbs'; */
import WidgetClass from './base/widget-class';
import { get } from 'teamtailor/utils/get';
import { restartableTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class InternalJobs extends WidgetClass {
  @service store;

  constructor(...args) {
    super(...args);

    this.fetchJobs.perform();
  }

  get jobs() {
    return get(this.fetchJobs, 'lastSuccessful.value');
  }

  fetchJobs = restartableTask(async () => {
    return get(this, 'store').query('job', {
      sort: 'created_at-desc',
      per_page: 15,
      internal: true,
      all_published: true,
    });
  });
}
