/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { scheduleOnce } from '@ember/runloop';
import { action } from '@ember/object';

import CachedFeed, {
  CandidateFeedSignature,
} from 'teamtailor/components/cached-feed';
import { tracked } from '@glimmer/tracking';
import { JobApplicationTriggerModel } from 'teamtailor/models';
import { modifier } from 'ember-modifier';
export default class CandidateModalNewRightSidebarActivity extends CachedFeed<CandidateFeedSignature> {
  declare scrollPosition: number;
  declare element: HTMLElement;

  @tracked activityAction = '';

  constructor(owner: unknown, args: CandidateFeedSignature['Args']) {
    super(owner, args, 'activity', 25);
  }

  get scheduledTriggers() {
    if (this.args.globalFilter) {
      return this.args.candidate.scheduledJobApplicationTriggers;
    }

    return this.args.candidate.scheduledJobApplicationTriggers.filter(
      (jobApplicationTrigger: JobApplicationTriggerModel) => {
        return (
          this.args.jobApplication?.id ===
          jobApplicationTrigger.jobApplicationId
        );
      }
    );
  }

  scrollToLastPosition() {
    this.element.scrollTo(0, this.scrollPosition);
  }

  @action
  async loadMoreActivities() {
    this.scrollPosition = this.element.scrollTop;
    await this.feed.loadMore();
    scheduleOnce('afterRender', this, this.scrollToLastPosition);
  }

  @action
  changeActivityAction(action: string) {
    this.activityAction = action;
  }

  onInsert = modifier((element: HTMLElement) => {
    this.element = element;
  });
}
