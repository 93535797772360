/* import __COLOCATED_TEMPLATE__ from './rating-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import AnalyticsService from 'teamtailor/services/analytics';

interface RatingPickerArgs {
  onSelection: (value: unknown) => void;
  value: string | string[] | number[];
}

export default class RatingPickerComponent extends Component<RatingPickerArgs> {
  @service declare analytics: AnalyticsService;

  get starsArray(): number[] {
    return [1, 2, 3, 4, 5];
  }

  get values(): number[] {
    if (isEmpty(this.args.value)) {
      return [];
    }

    let values = this.args.value;

    if (!Array.isArray(values)) {
      values = [values];
    }

    return values.map((v: string | number) => Number(v));
  }

  @action setInputValues(value: number): void {
    const ratings = this.values;
    const existingValue = ratings.includes(value);

    if (existingValue) {
      ratings.removeObject(value);
      this.args.onSelection(ratings);
    } else {
      ratings.pushObject(value);
      this.args.onSelection(ratings);
    }
  }
}
