/* import __COLOCATED_TEMPLATE__ from './user-image.hbs'; */
import Component from '@glimmer/component';
import FlipperService from 'teamtailor/services/flipper';
import { getRobohashUrl } from 'teamtailor/utils/robohash';
import { argDefault } from 'teamtailor/utils/arg-default';
import UserModel from 'teamtailor/models/user';
import { AsyncBelongsTo } from '@ember-data/model';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { IUser } from 'teamtailor/components/meeting/types';
import { VideoMeetingUser } from 'teamtailor/utils/video-meetings/utils';
import { get } from 'teamtailor/utils/get';
import imageUrl from 'teamtailor/utils/image-url';
import { inject as service } from '@ember/service';

import {
  DEFAULT_SIZE,
  SIZE_CLASSES,
  ALLOWED_SIZES,
  AllowedSizes,
} from 'teamtailor/constants/avatar';

type User = AsyncBelongsTo<UserModel> | UserModel | IUser | VideoMeetingUser;
type UserArg = User | string;

interface UserImageArgs {
  user: UserArg;
  size?: AllowedSizes;
  tooltipSide?: string;
  showTitle?: boolean;
  status?: string;
  userFallbackIcon?: string;
  hideOnlineBeacon?: boolean;
}

export default class UserImage extends Component<UserImageArgs> {
  @service declare flipper: FlipperService;

  @argDefault tooltipSide = 'top';
  @argDefault showTitle = 'true';

  get user(): User | undefined {
    if (typeof this.args.user !== 'string') {
      return this.args.user;
    }
  }

  get sizeClasses() {
    return SIZE_CLASSES[this.size];
  }

  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'UserImage @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get title() {
    return get(this.user, 'nameOrEmail');
  }

  get seed() {
    return typeof this.args.user === 'string'
      ? this.args.user
      : get(this.user, 'id');
  }

  get imageUrl() {
    const { seed } = this;
    const nameOrEmail = get(this.user, 'nameOrEmail');

    const isExternalRecipient = seed === nameOrEmail;
    if (!seed || isExternalRecipient) {
      return null;
    }

    return this.userAvatarUrl || getRobohashUrl(seed);
  }

  get userAvatarUrl() {
    const avatarUrl = get(<UserModel | IUser>this.user, 'avatarUrl');
    const pictureUrl = get(<VideoMeetingUser>this.user, 'picture_url');

    return avatarUrl || imageUrl(pictureUrl, 'employee_picture');
  }

  get icon() {
    return this.args.userFallbackIcon ?? 'user';
  }

  get showOnlineBeacon(): boolean {
    return (
      !this.args.status &&
      get(this.flipper, 'user_online_status') &&
      get(<UserModel>this.user, 'isOnline') &&
      !get(<UserModel>this.user, 'hideOnlineStatus') &&
      !this.args.hideOnlineBeacon
    );
  }
}
