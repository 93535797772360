/* import __COLOCATED_TEMPLATE__ from './add-candidate-to-nurture-campaign.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AddCandidateToNurtureCampaignComponent extends Component {
  @service store;

  @tracked selectedNurtureCampaign = null;

  @action
  handleAdd() {
    this.args.onSelect(get(this, 'selectedNurtureCampaign.nurtureCampaign'));
  }

  @action
  handleChange(nurtureCampaign) {
    this.selectedNurtureCampaign = nurtureCampaign;
  }
}
