/* import __COLOCATED_TEMPLATE__ from './share-link.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { setProperties, set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import flatten from 'teamtailor/utils/flatten';
import { later } from '@ember/runloop';

export default class ShareLinkComponent extends Component {
  @service anonymals;
  @service store;
  @service current;
  @service intl;
  @service flashMessages;

  @tracked invalidAddresses = false;
  @tracked shareEmails = '';
  @tracked shareSubject;
  @tracked shareBody;
  @tracked shareLink = null;
  @tracked viewState = 'link';
  @tracked errors = null;
  @tracked messageTemplateId = null;
  @tracked shareAllCandidateDocuments = true;
  @tracked shareAllInternalDocuments = true;
  @tracked jobSpecific;
  sharedItems = null;

  get candidate() {
    return this.args.candidate;
  }

  get shareIsEmpty() {
    return !this.shareEmails || !this.shareBody || !this.shareSubject;
  }

  getShareSubject() {
    if (isEmpty(this.candidate.name) || !this.shareLink?.personalInformation) {
      return this.intl.t('components.share_link.help_review_candidate');
    } else {
      return this.intl.t('components.share_link.help_review_given_candidate', {
        candidateName: this.shareLink?.anonymous
          ? this.candidate.anonymousName
          : this.candidate.name,
      });
    }
  }

  get linkActive() {
    return this.viewState === 'link';
  }

  get emailActive() {
    return this.viewState === 'email';
  }

  get previousShareLinksExist() {
    return !this.candidate.shareLinks?.toArray()?.[0]?.hasDirtyAttributes;
  }

  get shareLinks() {
    return this.candidate.shareLinks.filterBy('isNew', false);
  }

  get sortedShareLinksAndQrCodes() {
    return this.shareLinks.sort((a, b) => {
      if (a.revokedAt < b.revokedAt) return -1;
      if (a.revokedAt > b.revokedAt) return 1;

      if (a.expiresAt > b.expiresAt) return -1;
      if (a.expiresAt < b.expiresAt) return 1;

      return 0;
    });
  }

  createShareLink = task(async () => {
    const candidate = await this.candidate;
    const jobApplications = await candidate.jobApplications;
    const answers = await get(this.candidate, 'answers');

    const accessibleJobs = (
      await Promise.all(
        jobApplications.map((ja) => {
          return get(ja, 'job').catch(() => {
            return null;
          });
        })
      )
    ).filter((job) => !!get(job, 'userHasAccess'));

    const accessibleJobApplications = jobApplications.filter((ja) =>
      get(ja, 'userHasAccessToJob')
    );

    await Promise.all([
      ...accessibleJobs.map((job) => {
        return get(job, 'jobDetail');
      }),
      ...answers.map((a) => get(a, 'questionnaire')),
      this.candidate.interviewKits,
    ]);

    this.jobSpecific = [];

    const shareLink = this.candidate.shareLinks.createRecord({
      name: this.intl.t(
        'components.promotion.cards.share_link.share_link_name',
        {
          name: this.current.user.nameOrEmail,
          date: moment().format('YYYY-MM-DD'),
        }
      ),

      anonymous: this.args.anonymous,
      anonymousColor: get(this, 'candidate.anonymal.colorHex'),
      anonymalIconUrl: get(this, 'candidate.anonymal.iconUrl'),
      anonymousName: get(this, 'candidate.anonymal.name'),
    });

    if (this.shareAllInternalDocuments !== true) {
      this.shareAllInternalDocuments = true;
    }

    if (this.shareAllCandidateDocuments !== true) {
      this.shareAllCandidateDocuments = true;
    }

    await Promise.all(
      flatten(
        accessibleJobs.map((job) => {
          return (get(job, 'jobDetail.pickedInterviewKits') || []).map(
            (pickedInterviewKit) => {
              return get(pickedInterviewKit, 'interviewKit');
            }
          );
        })
      )
    );

    accessibleJobApplications.forEach((jobApplication) => {
      const job = get(jobApplication, 'job');

      const interviewKits = (
        get(job, 'jobDetail.pickedInterviewKits') || []
      ).filter(({ interviewKit }) => {
        return !get(interviewKit, 'isHidden');
      });

      const jobInternalDocuments = this.candidate.uploads.filter((file) => {
        return file.internal && get(file, 'job.id') === get(job, 'id');
      });

      const jobCandidateDocuments = this.candidate.uploads.filter((file) => {
        return !file.internal && get(file, 'job.id') === get(job, 'id');
      });

      const jobAnswers = answers.filter(
        (answer) => answer.jobId === get(job, 'id')
      );

      this.jobSpecific = [
        ...this.jobSpecific,
        {
          enabled: true,
          job,
          shareLink,
          toggles: {
            answers: {
              checked: true,
              options: [...jobAnswers],
              selectedValues: [...jobAnswers],
              label: this.intl.t('components.share_link.answers'),
            },

            coverLetters: {
              checked: true,
              label: this.intl.t('components.share_link.cover_letters'),
            },

            recommendations: {
              checked: true,
              label: this.recommendationsLabel,
            },

            interviewKits: {
              checked: true,
              options: interviewKits,
              selectedValues: interviewKits,
              label: this.intl.t('components.share_link.interview_kit'),
            },

            candidateDocuments: {
              checked: true,
              options: jobCandidateDocuments,
              selectedValues: jobCandidateDocuments,
              label: this.intl.t('components.share_link.candidate_documents'),
            },

            internalDocuments: {
              checked: true,
              options: jobInternalDocuments,
              selectedValues: jobInternalDocuments,
              label: this.intl.t('components.share_link.internal_documents'),
            },
          },
        },
      ];
    });

    this.sharedItems = [];

    this.candidate.uploads.then((uploads) => {
      uploads.forEach((upload) => {
        if (!get(upload, 'job.id')) {
          shareLink.sharedItems.createRecord({
            upload,
          });
        }
      });
    });

    this.candidate.jobApplications.then((jobApplications) => {
      jobApplications
        .filter((jobApplication) => get(jobApplication, 'job.userHasAccess'))
        .forEach((jobApplication) => {
          shareLink.sharedItems.createRecord({
            jobApplication,
          });
        });
    });

    this.shareLink = shareLink;
    this.subjectManuallyChanged = false;
    this.shareSubject = this.getShareSubject();
    this.shareBody = this.intl.t('components.share_link.share_body');

    if (this.args.forCandidate) {
      this.setupFormForCandidate();
    }
  });

  setupFormForCandidate() {
    set(this.shareLink, 'forCandidate', true);
    this.viewState = 'email';
    this.shareEmails = this.candidate.email;
    this.shareSubject = this.intl.t(
      'components.share_link.subject_for_candidate'
    );
    this.shareBody = this.intl.t('components.share_link.body_for_candidate');
  }

  get recommendationsLabel() {
    if (this.current.company.hasFeature('references')) {
      return this.intl.t(
        'components.share_link.recommendations_and_references'
      );
    } else {
      return this.intl.t('components.share_link.recommendations');
    }
  }

  scrollIntoView(selector) {
    later(() => {
      document.querySelector(selector)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 250);
  }

  setErrors(message) {
    this.errors = [{ message }];
  }

  resetErrors() {
    this.errors = null;
  }

  isPasswordValid() {
    const isValid =
      !this.shareLink.restricted ||
      (this.shareLink.restricted && !isEmpty(this.shareLink.password));

    if (!isValid)
      this.setErrors(this.intl.t('components.share_link.password_invalid'));
    else this.resetErrors();

    return isValid;
  }

  @action
  togglePasswordProtection() {
    this.shareLink.restricted = !this.shareLink.restricted;
    if (!this.shareLink.restricted) {
      this.errors = null;
    }
  }

  @action
  handlePasswordChange(event) {
    this.shareLink.password = event.target.value;

    if (!isEmpty(this.shareLink.password)) {
      this.errors = null;
    }
  }

  @action
  onPersonalInformationToggle() {
    this.shareLink.personalInformation = !this.shareLink.personalInformation;
    this.updateShareLinkSubject();
  }

  @action
  onSubjectChange(value) {
    this.shareSubject = value;
    if (!this.subjectManuallyChanged) {
      this.subjectManuallyChanged = true;
    }
  }

  @action
  setPassword(password) {
    this.shareLink.password = password;
  }

  @action
  handleToggleChange(toggleKey, jobIndex) {
    const jobData = this.jobSpecific[jobIndex];
    const toggleData = jobData.toggles[toggleKey];
    const isChecked = toggleData.checked;
    set(toggleData, 'checked', !isChecked);
    set(jobData, 'toggleData', toggleData);
    set(this.jobSpecific, jobIndex, jobData);
  }

  @action
  handleArrayValuesChange(toggleKey, record, jobIndex) {
    const selectedJob = get(this.jobSpecific, jobIndex);
    let { selectedValues } = selectedJob.toggles[toggleKey];

    if (selectedValues.includes(record)) {
      const newSelectedValues = selectedValues.filter((pickedRecord) => {
        return pickedRecord.id !== record.id;
      });
      const pathMap = {
        answers: 'toggles.answers.selectedValues',
        interviewKits: 'toggles.interviewKits.selectedValues',
        candidateDocuments: 'toggles.candidateDocuments.selectedValues',
        internalDocuments: 'toggles.internalDocuments.selectedValues',
      };

      if (pathMap[toggleKey]) {
        set(selectedJob, pathMap[toggleKey], newSelectedValues);
      } else {
        throw new Error(`path for ${toggleKey} not found`);
      }
    } else {
      selectedValues = [...selectedValues, record];
    }
  }

  @action
  async saveShareLink() {
    if (!this.isPasswordValid()) throw new Error('Password not valid');

    await this.shareLink.save();

    const promises = this.jobSpecific.filterBy('enabled', true).map((state) => {
      const shareLinksJob = this.store.createRecord('share-links-job', {
        job: state.job,
        shareLink: state.shareLink,
        answers: state.toggles.answers.checked
          ? state.toggles.answers.selectedValues.map((answer) =>
              get(answer, 'id')
            )
          : [],

        interviewKits: state.toggles.interviewKits.checked
          ? state.toggles.interviewKits.selectedValues.map((intervewKit) =>
              get(intervewKit, 'id')
            )
          : [],

        internalDocuments: state.toggles.internalDocuments.checked
          ? state.toggles.internalDocuments.selectedValues.map((upload) =>
              get(upload, 'id')
            )
          : [],

        candidateDocuments: state.toggles.candidateDocuments.checked
          ? state.toggles.candidateDocuments.selectedValues.map((upload) =>
              get(upload, 'id')
            )
          : [],

        coverLetter: state.toggles.coverLetters.checked,
        recommendations: state.toggles.recommendations.checked,
      });

      return shareLinksJob.save();
    });

    await Promise.all(promises);

    this.scrollIntoView('.c-share-link');
    this.createShareLink.perform();
  }

  @action
  revokeShareLink(currentShareLink) {
    let promise = currentShareLink.revokeShareLink();

    promise.then(
      (res) => {
        this.store.pushPayload(res);
        this.flashMessages.success(
          this.intl.t('components.share_link.share_link_revoked')
        );
      },
      () => {
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      }
    );

    return promise;
  }

  @action
  async saveShareLinkEmail() {
    setProperties(this.shareLink, {
      emails: this.shareEmails.split('\n'),
      subject: this.shareSubject,
      message: this.shareBody,
    });

    try {
      await this.saveShareLink();
      setProperties(this, {
        shareEmails: '',
        invalidAddresses: false,
      });
    } catch (e) {
      set(this, 'invalidAddresses', true);
      this.scrollIntoView('.id-error-message');
    }
  }

  @action
  setViewState(state) {
    this.viewState = state;

    if (this.shareLink) {
      this.shareLink.emails = [];
    }
  }

  @action
  toggleCandidateDocs() {
    this.shareAllCandidateDocuments = !this.shareAllCandidateDocuments;
    const isChecked = this.shareAllCandidateDocuments;

    this.shareLink.candidateDocuments.forEach((file) => {
      file.visible = isChecked;
    });
  }

  @action
  toggleInternalDocs() {
    this.shareAllInternalDocuments = !this.shareAllInternalDocuments;
    const isChecked = this.shareAllInternalDocuments;

    this.shareLink.internalDocuments.forEach((file) => {
      file.visible = isChecked;
    });
  }

  @action
  updateSharedItem(sharedItem) {
    sharedItem.visible = !sharedItem.visible;
  }

  @action
  selectedCannedResponse(response) {
    setProperties(this, {
      shareSubject: response.subject,
      shareBody: response.body,
      messageTemplateId: response.id,
    });
  }

  @action
  toggleAnonymous() {
    this.shareLink.anonymous = !this.shareLink.anonymous;
    this.updateShareLinkSubject();
  }

  updateShareLinkSubject() {
    if (!this.subjectManuallyChanged) {
      this.shareSubject = this.getShareSubject();
    }
  }
}
