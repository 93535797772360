/* import __COLOCATED_TEMPLATE__ from './nps-score.hbs'; */
// cleanup_when_done: combined_nps_page, new_candidate_modal

import Component from '@ember/component';
import { equal } from '@ember/object/computed';
import {
  computedIsPromoterScore as isPromoter,
  computedIsPassiveScore as isPassive,
  computedIsDetractorScore as isDetractor,
} from 'teamtailor/utils/nps';

export default Component.extend({
  classNameBindings: [
    ':c-nps-score',
    'isPending:c-nps-score--pending',
    'isDetractor:c-nps-score--detractor',
    'isPromoter:c-nps-score--promoter',
    'isPassive:c-nps-score--passive',
  ],

  isPending: equal('score', null),
  isDetractor: isDetractor('score'),
  isPromoter: isPromoter('score'),
  isPassive: isPassive('score'),
});
