/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

import { ActivityModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface Args {
  activity: ActivityModel;
}

export default class extends Component<Args> {
  @service declare intl: IntlService;

  get translatedKeys() {
    return this.args.activity.actionDataKeys.map((key) =>
      this.intl.t(`candidates.candidate.edit.index.${key}`)
    );
  }

  get formattedActionData() {
    return this.translatedKeys.map((key) => {
      return `<span class="body-text-medium">${key}</span>`;
    });
  }
}
