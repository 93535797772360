/* import __COLOCATED_TEMPLATE__ from './text-field.hbs'; */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

// These fields are not necessary for the Twilio submission
// But we still need to send their keys alongside the document to the API
const REDUNDANT_FIELDS = ['address_sids'];

export default Component.extend({
  classNames: 'tt-form-group',

  didInsertElement() {
    this._super(...arguments);
    if (get(this, 'isRedundant')) {
      set(this, 'twilio.documents', {
        ...get(this, 'twilio.documents'),
        [this.docKey]: {
          [this.fieldKey]: null,
        },
      });
    }
  },

  isRedundant: computed('fieldKey', function () {
    return REDUNDANT_FIELDS.includes(get(this, 'fieldKey'));
  }),
});
