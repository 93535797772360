/* import __COLOCATED_TEMPLATE__ from './dismissable-message-box.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const DEFAULT_EMOJI = '🎉';

export default class DismissableMessageBox extends Component {
  @service dismissableStatus;
  @tracked isOpen = true;
  @tracked message = null;

  get messageContent() {
    return this.message?.content;
  }

  get messageLink() {
    return this.message?.link;
  }

  get hasMessage() {
    return !!this.message;
  }

  get shouldDisplayMessage() {
    if (this.args.group) {
      return this.isOpen && this.hasMessage;
    }

    return this.isOpen;
  }

  get messageTitle() {
    return this.args.title || this.message?.title;
  }

  get emoji() {
    return this.args.emoji || this.message?.emoji || DEFAULT_EMOJI;
  }

  @action
  async onDidInsert() {
    if (this.args.group) {
      this.message = await this.dismissableStatus.getMessage(this.args.group);
    }
  }

  @action
  handleClose() {
    if (this.onCloseRequest) {
      this.onCloseRequest();
    } else {
      this.isOpen = false;
    }
  }
}
