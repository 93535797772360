import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { later } from '@ember/runloop';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';

export default class SettingsTeamsNewController extends Controller {
  @service store;
  @service intl;
  @service router;
  @service flashMessages;

  successMessage = 'settings.teams.team_created';

  @tracked teamUsers = null;
  @tracked departmentsOrRoles = null;

  get team() {
    return this.model;
  }

  resetTeamRelationships() {
    set(this, 'team.roles', []);
    set(this, 'team.departments', []);
    set(this, 'team.divisions', []);
  }

  handlePickUser(user) {
    this.teamUsers.pushObject(user);
  }

  handleDropUser(user) {
    this.teamUsers.removeObject(user);
  }

  @action
  handleSelectLocation(location) {
    toggleInList(get(this, 'team.locations'), location);
  }

  @action
  toggleTeamMember(user) {
    if (this.teamUsers.findBy('id', user.id)) {
      this.handleDropUser(user);
    } else {
      this.handlePickUser(user);
    }
  }

  @action
  addDepartmentOrRole() {
    const dr = this.store.createRecord('department-or-role', {
      team: get(this, 'team'),
    });

    this.departmentsOrRoles.addObject(dr);
  }

  @action
  saveTeam() {
    this.resetTeamRelationships();
    set(this, 'team.users', this.teamUsers);

    this.departmentsOrRoles.forEach((dr) => {
      const roleId = get(dr, 'role.id');
      const departmentId = get(dr, 'department.id');
      const divisionId = get(dr, 'division.id');

      if (roleId) {
        get(this, 'team.roles').addObject(
          this.store.peekRecord('role', get(dr, 'role.id'))
        );
      } else if (
        departmentId &&
        (departmentId !== NO_DEPARTMENT_ID ||
          (departmentId === NO_DEPARTMENT_ID && !divisionId)) // not no-department or main division with no-department
      ) {
        get(this, 'team.departments').addObject(
          this.store.peekRecord('department', departmentId)
        );
      } else if (divisionId) {
        let teamDivisions = get(this, 'team.divisions') ?? [];
        teamDivisions = [
          ...teamDivisions.slice(),
          this.store.peekRecord('division', divisionId),
        ];
        set(this, 'team.divisions', teamDivisions);

        if (departmentId === NO_DEPARTMENT_ID) {
          let divisions = get(this, 'team.noDepartmentDivision') ?? [];
          divisions = [
            ...divisions.slice(),
            this.store.peekRecord('division', divisionId),
          ];
          set(this, 'team.noDepartmentDivision', divisions);
        }
      }
    });

    return this.team.save().then(() => {
      later(() => {
        this.flashMessages.success(this.intl.t(this.successMessage));
        this.router.transitionTo('settings.teams');
      }, 200);
    });
  }

  @action
  onDeleteDepartmentOrRole(departmentOrRoleToBeRemoved) {
    this.departmentsOrRoles = this.departmentsOrRoles.filter(
      (departmentOrRole) => departmentOrRole !== departmentOrRoleToBeRemoved
    );
    departmentOrRoleToBeRemoved.deleteRecord();
  }
}
