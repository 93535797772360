/* import __COLOCATED_TEMPLATE__ from './scheduler.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment, { Moment } from 'moment-timezone';

import PreparedMessageClass from 'teamtailor/classes/prepared-message';

import {
  IJob,
  IUser,
  IJobApplication,
  ICannedResponse,
} from 'teamtailor/components/meeting/types';
import { JobClass } from 'teamtailor/classes/job';
import {
  UserAttendeeClass,
  CandidateAttendeeClass,
  MeetingEventListClass,
  MeetingEventClass,
} from 'teamtailor/classes/meetings';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';
import IntlService from 'ember-intl/services/intl';

interface EventSchedulerArgs {
  currentUser: IUser;
  job?: JobClass;
  modalClosedAction(): void;
  meetingEventList: MeetingEventListClass;
  preparedMessage: PreparedMessageClass;
  save(): Promise<void>;
  isEditing: boolean;
  bookableMeetingRooms?: MeetingRoomAttendeeClass[];
  onChangeProblemValue(field: string): void;
  candidates: CandidateAttendeeClass[];
  jobApplications: IJobApplication[];
}

export default class EventScheduler extends Component<EventSchedulerArgs> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service declare apollo: any;
  @service declare intl: IntlService;

  @tracked preparedMessageOpen = false;
  @tracked showCandidateAvatarInTitle = false;
  @tracked selectedCannedResponse?: ICannedResponse;

  _candidates: CandidateAttendeeClass[] = [];
  _missingEmailCount = 0;

  constructor(owner: unknown, args: EventSchedulerArgs) {
    super(owner, args);

    if (this.meetingEvents[0]) {
      this._candidates =
        this.meetingEvents[0].meetingEventAttendees.candidateAttendees;
      this._missingEmailCount =
        this.meetingEvents[0].meetingEventAttendees.candidatesMissingEmailCount;
    }
  }

  get jobApplications() {
    return this.args.jobApplications;
  }

  /**
   * Check if the latest event meeting
   * start date have passed. Used for
   * disabling editing.
   */
  get readOnly(): boolean {
    if (this.candidates.length === 0) {
      return true;
    }

    const startDates: Moment[] = this.meetingEvents
      .filter((event) => event.originalBookedSlot)
      .map((event) => moment(event.originalBookedSlot?.startsAt));

    if (startDates.length > 0) {
      const lastEventStart = moment.max(startDates);
      return moment().isAfter(lastEventStart);
    }

    return false;
  }

  get isEditing(): boolean {
    return this.args.isEditing;
  }

  get currentUser(): IUser {
    return this.args.currentUser;
  }

  get candidates(): CandidateAttendeeClass[] {
    return this._candidates;
  }

  get preparedMessage(): PreparedMessageClass {
    return this.args.preparedMessage;
  }

  get meetingEvents(): MeetingEventClass[] {
    return this.args.meetingEventList.meetingEvents;
  }

  get selectedJob(): IJob | undefined {
    return this.args.job?.job;
  }

  get bookableMeetingRooms(): MeetingRoomAttendeeClass[] | undefined {
    return this.args.bookableMeetingRooms;
  }

  get missingEmailCount(): number {
    return this._missingEmailCount;
  }

  get lastMeetingIndex(): number {
    return this.meetingEvents.length - 1;
  }

  @action
  addEvent(): void {
    const organizer = UserAttendeeClass.from(this.args.currentUser);
    organizer.isOrganizer = true;

    const newEvent = MeetingEventClass.from({
      tzid: this.args.currentUser.tzid || this.args.currentUser.company.tzid,
      visibility: this.args.currentUser.company.meetingVisibility,
      meetingEventAttendees: [organizer, ...this.candidates],
      organizer,
    });

    this.args.meetingEventList.add(newEvent);
  }

  @action
  removeEvent(event: MeetingEventClass): void {
    this.args.meetingEventList.remove(event);
  }

  @action
  changeJob(jobApplication: IJobApplication | undefined): void {
    this.meetingEvents.forEach((meetingEvent) =>
      meetingEvent.clearInterviewKit()
    );

    if (jobApplication) {
      this.args.job!.job = jobApplication.job;
    } else {
      this.args.job!.job = undefined;
      this.meetingEvents.forEach((event) =>
        event.meetingEventAttendees.setAllHasInterview(false)
      );
    }
  }

  @action
  openPreparedMessage(): void {
    this.preparedMessageOpen = true;
  }

  @action
  closePreparedMessage(): void {
    this.preparedMessageOpen = false;
  }

  @action
  changeTemplate(template: ICannedResponse): void {
    this.selectedCannedResponse = template;
  }
}
