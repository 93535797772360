/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { dropTask } from 'ember-concurrency';

export default class extends Component {
  @service store;

  @tracked due = this.args.todo?.due ? new Date(this.args.todo?.due) : null;
  @tracked relativeDueDays =
    this.args.todo?.relativeDueDays || this.args.todo?.daysFromNow || null;

  @tracked candidate = this.args.todo?.candidate?.isTruthy
    ? this.args.todo?.candidate
    : null;

  @tracked value = this.args.todo?.value || '';
  @tracked description = this.args.todo?.description || '';
  @tracked assignee = this.args.todo?.assignee || null;
  @tracked inputElement = null;

  constructor() {
    super(...arguments);

    if (get(this.args, 'todo.candidate.id')) {
      this.store
        .findRecord('candidate', get(this.args, 'todo.candidate.id'))
        .then((candidate) => {
          this.candidate = candidate;
        });
    }

    if (get(this.args, 'todo.assignee.id')) {
      this.store
        .findRecord('user', get(this.args, 'todo.assignee.id'))
        .then((assignee) => {
          this.assignee = assignee;
        });
    }
  }

  get dropdownsAbove() {
    return this.args.dropdownsAbove || false;
  }

  get isValid() {
    return !!this.value && this.value.trim() !== '';
  }

  setFocusOnInput() {
    this.inputElement?.focus();
  }

  handleSubmit = dropTask(async () => {
    const hasDescription = !!this.redactorInstance.editor
      .getElement()
      .text()
      .trim().length;

    let editing = !!this.args.todo;
    let response = await this.args.onSubmit({
      value: this.value,
      description: hasDescription ? this.description : '',
      due: this.due,
      candidate: this.candidate,
      assignee: this.assignee,
      relativeDueDays: this.relativeDueDays,
    });

    let succeeded = response && !response.errors?.length;

    if (!editing && succeeded) {
      this.due = null;
      this.candidate = null;
      this.value = '';
      this.description = '';
      this.assignee = null;
      this.relativeDueDays = null;

      this.setFocusOnInput();
    } else if (editing && this.args.onClose) {
      this.args.onClose();
    }

    return succeeded;
  });

  @action
  handleInsertInput(inputElement) {
    this.inputElement = inputElement;

    if (!this.args.skipInputFocus) {
      next(() => {
        this.setFocusOnInput();
      });
    }
  }

  @action
  handleCandidate(candidate) {
    this.candidate = candidate;
  }

  @action
  handleAssignee(assignee) {
    if (this.assignee && this.assignee.id === assignee.id) {
      this.assignee = null;
    } else {
      this.assignee = assignee;
    }
  }

  @action
  handleDateFromNowChange(relativeDueDays) {
    this.relativeDueDays = relativeDueDays?.value || null;
  }

  @action
  handleDueDate(dueDate) {
    this.due = dueDate;
  }

  @action
  registerRedactorInstance(redactorInstance) {
    this.redactorInstance = redactorInstance;
  }

  @action
  handleCmdEnter() {
    if (this.isValid) {
      this.handleSubmit.perform();
    }
  }
}
