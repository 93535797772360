import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import MeetingEvent from 'teamtailor/models/meeting-event';

import { TranscriptionStatus } from 'teamtailor/constants/video-room';

export default class MeetingRecordingModel extends Model {
  @belongsTo('meeting-event')
  declare meetingEvent: AsyncBelongsTo<MeetingEvent>;

  @attr declare mediaUrl: string;
  @attr('string')
  declare transcriptionJsonUrl: string | null;

  @attr('string')
  declare transcriptionStatus: TranscriptionStatus;

  get isTranscriptionAvailable(): boolean {
    return this.transcriptionStatus === TranscriptionStatus.AVAILABLE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'meeting-recording': MeetingRecordingModel;
  }
}
