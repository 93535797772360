/* import __COLOCATED_TEMPLATE__ from './team-members.hbs'; */
import Component from '@glimmer/component';
import { TeamModel } from 'teamtailor/models';
import uniqBy from 'teamtailor/utils/uniq-by';

type Args = {
  team: TeamModel;
};

export default class TeamMembersComponent extends Component<Args> {
  get uniqueUsers() {
    const { team } = this.args;
    return uniqBy([...team.users.slice(), team.user], 'id');
  }
}
