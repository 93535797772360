/* import __COLOCATED_TEMPLATE__ from './apec.hbs'; */
import PromotionBaseClass from './promotion-base';
import { get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import {
  apecTravelAreas,
  apecExperienceLevels,
  apecJobStatuses,
  apecJobTypes,
  apecRemoteWorkTypes,
} from 'teamtailor/constants/promote';

export default class extends PromotionBaseClass {
  apecTravelAreas = apecTravelAreas;
  apecExperienceLevels = apecExperienceLevels;
  apecJobStatuses = apecJobStatuses;
  apecJobTypes = apecJobTypes;
  apecRemoteWorkTypes = apecRemoteWorkTypes;

  @tracked checkoutData;

  get apecDepartments() {
    return this.store.peekAll('promotion-categories/apec-department');
  }

  get apecIndustries() {
    return this.store.peekAll('promotion-categories/apec-industry');
  }

  get apecTravelArea() {
    return apecTravelAreas.findBy(
      'code',
      get(this, 'promotion.apecTravelArea')
    );
  }

  get apecRemoteWorkType() {
    return apecRemoteWorkTypes.findBy(
      'code',
      get(this, 'promotion.apecRemoteWorkType')
    );
  }

  get apecExperienceLevel() {
    return apecExperienceLevels.findBy(
      'code',
      get(this, 'promotion.apecExperienceLevel')
    );
  }

  get apecJobStatus() {
    return apecJobStatuses.findBy('code', get(this, 'promotion.apecJobStatus'));
  }

  get apecJobType() {
    return apecJobTypes.findBy('code', get(this, 'promotion.apecJobType'));
  }

  set apecJobType(value) {
    set(this, 'promotion.apecJobType', value);

    if (!get(this, 'promotion.apecNeedsDuration')) {
      set(this, 'promotion.apecDuration', undefined);
    }
  }
}
