/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import Component from '@glimmer/component';
import formatDate from 'teamtailor/helpers/format-date';
import { inject as service } from '@ember/service';

const DUE_IN_DAYS_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 10, 14, 21];

export default class TextComposerToolDatepickerComponent extends Component {
  @service intl;

  get dueInOptions() {
    return DUE_IN_DAYS_OPTIONS.map((i) => ({
      label: this.intl.t('common.x_days', { count: i }),
      value: i,
    }));
  }

  get triggerTooltip() {
    return this.args.due
      ? 'components.todo_input.date_selected'
      : 'components.todo_input.select_date';
  }

  get formattedDueDate() {
    return formatDate(this.args.due);
  }
}
