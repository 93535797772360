/* import __COLOCATED_TEMPLATE__ from './connects.hbs'; */
import WidgetClass from './base/widget-class';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

const CONNECTS_LIMIT = 4;

export default class ConnectsWidget extends WidgetClass {
  @service store;
  @service pusher;
  @service current;

  @tracked connectedCandidates;
  @tracked channel;

  get hasConnectedCandidates() {
    return isPresent(this.connectedCandidates);
  }

  get totalConnectedCandidates() {
    return this.connectedCandidates?.meta?.total;
  }

  get hasMoreConnectedCandidates() {
    return this.totalConnectedCandidates > CONNECTS_LIMIT;
  }

  fetchCandidates = restartableTask(async () => {
    this.connectedCandidates = await this.store.query('candidate', {
      connected: 'yes',
      applied: 'no',
      sourced: 'no',
      per_page: CONNECTS_LIMIT,
    });
  });

  @action
  setupPusher() {
    this.pusher
      .subscribe(this.current.company.candidatesChannel)
      .then((channel) => {
        this.channel = channel;
        channel.bind('demo-data-updated', () => {
          this.fetchCandidates.perform();
        });
      });
  }

  @action
  teardownPusher() {
    if (this.channel) {
      this.channel.unbind('demo-data-updated');
    }
  }
}
