/* import __COLOCATED_TEMPLATE__ from './billing-info.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import CompanyModel from 'teamtailor/models/company';
import { action } from '@ember/object';
import PaymentProcessorSettingModel from 'teamtailor/models/payment-processor-setting';
import { tracked } from '@glimmer/tracking';

interface BillingInfoInterface {
  paymentProcessorSetting: PaymentProcessorSettingModel;
}

export default class BillingInfoComponent extends Component<BillingInfoInterface> {
  @service declare current: Current;

  @tracked
  isEditModalOpened = false;

  get company(): CompanyModel {
    return this.current.company;
  }

  @action
  handleSubmit() {
    this.isEditModalOpened = false;
  }
}
