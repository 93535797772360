/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

interface EmployeeDashboardJobViewerListViewCardArgs {
  Args: {
    job: {
      url: string;
      internal: boolean;
      internalUrl?: string;
      image?: {
        standard: string;
      };
      department?: {
        name: string;
      };
      locations?: {
        nameOrCity: string;
      }[];
    };
  };
}

export default class EmployeeDashboardJobViewerFancyListCardComponent extends Component<EmployeeDashboardJobViewerListViewCardArgs> {
  @service declare current: Current;

  get showStageColumns() {
    return this.current.company.employeeDashboardRecruitmentProgress;
  }

  get image() {
    return this.args.job.image;
  }

  get hasImage() {
    return !!this.image?.standard;
  }

  get jobLink() {
    const { job } = this.args;
    if (job.internal) {
      return job.internalUrl;
    }

    return job.url;
  }

  get firstLocation() {
    return this.args.job.locations?.at(0);
  }

  get restOfLocations() {
    return this.args.job.locations?.slice(1);
  }
}
