/* import __COLOCATED_TEMPLATE__ from './group-date.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { ActivityModel } from 'teamtailor/models';

export interface GroupDateComponentSignature {
  Args: {
    item: ActivityModel;
    all: ActivityModel[];
  };
}

export default class GroupDateComponent extends Component<GroupDateComponentSignature> {
  get currentIndex() {
    return this.args.all.indexOf(this.args.item);
  }

  get currentDate() {
    return moment(this.args.item.updatedAt);
  }

  get previousDate() {
    const previousIndex = this.currentIndex - 1;
    if (previousIndex >= 0) {
      return moment(this.args.all.objectAt(previousIndex)?.updatedAt);
    }
  }

  get isNewDate() {
    return (
      !this.previousDate || !this.currentDate.isSame(this.previousDate, 'day')
    );
  }

  get showYear() {
    return !this.currentDate.isSame(new Date(), 'year');
  }

  get format() {
    return this.showYear ? 'dddd, MMM D YYYY' : 'dddd, MMM D';
  }
}
