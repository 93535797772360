/* import __COLOCATED_TEMPLATE__ from './question-creator.hbs'; */
/**
 * When working with ember changeset you
 * should use `.get()` and `.set()` when fetching
 * and setting data.
 */
/* eslint-disable ember/use-ember-get-and-set */
import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset as changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';
import { BufferedChangeset } from 'ember-changeset/types';
import { Alternative } from 'teamtailor/components/settings/questions/multi-choice';
import {
  MappedTypes,
  Question,
  QuestionType,
  QUESTION_TYPES,
} from 'teamtailor/constants/question';
import QuestionModel from 'teamtailor/models/question';
import TranslationQuestionModel from 'teamtailor/models/translation/question';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import TagModel from 'teamtailor/models/tag';
import { get } from 'teamtailor/utils/get';
import ScorecardCriterium from 'teamtailor/models/scorecard-criterium';
import CopilotService from 'teamtailor/services/copilot';
import { dropTask } from 'ember-concurrency';

type LanguageTab = {
  index: number;
  languageCode: string;
};

interface Args {
  model?: QuestionModel;
  onSavedCallback?(question: QuestionModel): void;
  onDeletedCallback?(question: QuestionModel): void;
  onCloseCallback?(): void;
  onClose(): void;
  hiddenQuestionTypes?: QuestionType[];
  scorecardCriterium?: ScorecardCriterium;
  isQualifying?: boolean;
  hideQuestionOptions?: boolean;
}

export default class QuestionCreator extends Component<Args> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare ttAlert: TtAlertService;
  @service declare flashMessages: FlashMessageService;
  @service declare copilot: CopilotService;

  @tracked activeIndex = 0;
  @tracked declare changeset?: BufferedChangeset;
  @tracked declare baseModel?: QuestionModel;
  @tracked declare translation?: TranslationQuestionModel;
  @tracked dismissedCopilotMessage = false;

  requiredProps = ['title'];

  readonly validators = {
    title: validatePresence(true),
  };

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (args.model) {
      this.changeset = changeset(
        args.model,
        lookupValidator(this.validators),
        this.validators
      );
      this.baseModel = args.model;
    }
  }

  get model(): QuestionModel | undefined {
    return this.args.model ?? this.baseModel ?? undefined;
  }

  reset() {
    this.baseModel = undefined;
    this.changeset = undefined;
    this.translation = undefined;
  }

  get pickedQuestionType(): Question | undefined {
    if (this.changeset) {
      const modelType = this.changeset.get('simpleType');
      return this.questionTypes.findBy('type', modelType);
    }
  }

  get isPickerDisabled(): boolean {
    if (this.args.hideQuestionOptions) {
      return true;
    }

    if (this.model) {
      return !get(this.model, 'isNew');
    }

    return false;
  }

  get translations(): TranslationQuestionModel[] {
    return this.changeset?.get('translations') ?? [];
  }

  get tags(): TagModel[] {
    return this.changeset?.get('tags') ?? [];
  }

  get isDefaultCareerSite(): boolean {
    return isEmpty(this.router.currentRoute.params.language_code);
  }

  get availableQuestionTypes(): Question[] {
    return Object.entries(QUESTION_TYPES)
      .map((question) => {
        return {
          type: question[0] as QuestionType,
          icon: question[1].icon,
          value: this.intl.t(question[1].translationKey),
        };
      })
      .sortBy('value');
  }

  get questionTypes() {
    return this.availableQuestionTypes.filter((item) => {
      return !this.args.hiddenQuestionTypes?.includes(item.type);
    });
  }

  get modalTitle(): string | undefined {
    return this.changeset?.id
      ? this.intl.t('settings.questions.edit_question')
      : this.intl.t('settings.questions.new_question');
  }

  private async handleSave(): Promise<QuestionModel> {
    const res = await this.changeset?.save().catch((err) => {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));

      if (this.baseModel) {
        get(this.baseModel, 'errors').forEach((err) => {
          this.changeset?.addError(err.attribute, {
            value: this.changeset.get(err.attribute) ?? '""',
            validation: err.message,
          });
        });
      }

      throw new Error(err);
    });

    return res;
  }

  private async saveTranslations(languageCode: string): Promise<void> {
    const promises: Promise<TranslationQuestionModel>[] = [];

    this.translations
      .filter((translation) => {
        return (
          get(translation, 'hasDirtyAttributes') &&
          translation.languageCode !== languageCode
        );
      })
      .map((translation) => {
        return translation.save();
      });

    await Promise.all(promises);
  }

  private async deleteQuestion(): Promise<QuestionModel> {
    if (!this.model) return Promise.reject(new Error());

    const model = await this.model.destroyRecord();

    if (this.args.onDeletedCallback) {
      this.args.onDeletedCallback(model);
    }

    return model;
  }

  private findOrCreateTranslation(
    languageCode: string
  ): TranslationQuestionModel {
    const translation = this.translations.findBy('languageCode', languageCode);

    if (translation) {
      this.changeset?.set('translations', [...this.translations.toArray()]);
      return translation;
    } else {
      const newTranslation = this.store.createRecord('translation/question', {
        languageCode,
        alternatives: [],
        questionData: { alternatives: [] },
        question: this.baseModel,
      });

      if (get(this.model, 'type') === MappedTypes.choice) {
        const alts = this.changeset?.get('formData') as Alternative[];
        const formData = alts.map((a) => {
          return { id: a.id, title: '' };
        });

        set(newTranslation, 'formData', formData);
      }

      // Ensures that the new relation is part of the base changeset
      // Unless `hasMany` relation is replaced, changes will not be reflected and rollbacks etc. wont work.
      // Note: Rollback does not work on already created relations
      // https://github.com/poteto/ember-changeset/pull/380
      this.changeset?.set('translations', [
        ...this.translations.toArray(),
        ...(this.translations
          .mapBy('languageCode')
          .includes(newTranslation.languageCode)
          ? []
          : [newTranslation]),
      ]);

      return newTranslation;
    }
  }

  @action
  handleTab(tab: LanguageTab) {
    this.activeIndex = tab.index;
    if (
      get(this.current.company.defaultCareerSite, 'languageCode') ===
      tab.languageCode
    ) {
      this.translation = undefined;
    } else {
      this.translation = this.findOrCreateTranslation(tab.languageCode);
      // Translate questions if not already translated
      if (
        !this.translation.title &&
        this.current.company.hasCopilotFeature('translate_questions')
      ) {
        this.translateTranslation(this.translation);
      }
    }
  }

  async translateTranslation(translation: TranslationQuestionModel) {
    const textToTranslate = {
      title: this.changeset?.get('title'),
      description: this.changeset?.get('description'),
      alternatives: Object.entries(this.changeset?.get('formData') || {}).map(
        ([_, v]: any) => v.title
      ),

      unit: this.changeset?.get('unit'),
    };
    translation.addedByCopilot = Object.fromEntries(
      Object.entries(textToTranslate).map(([k, v]) => [k, !!v?.length])
    );
    const translated = await this.translateTextWithCopilot.perform(
      textToTranslate,
      translation.languageCode
    );
    translation.setProperties(translated);
    translation.formData = translated.alternatives.map(
      (a: string, index: number) => {
        return { id: index + 1, title: a };
      }
    );
  }

  translateTextWithCopilot = dropTask(
    async (text, toLanguageCode: string): Promise<any> => {
      const response = await this.copilot.translateText(text, toLanguageCode);
      return response.text;
    }
  );

  @action
  async handleValidate(): Promise<QuestionModel> {
    await this.changeset?.validate();

    if (this.changeset?.get('isValid')) {
      const assetStructure = get(this.baseModel, 'assetStructure');
      const options = get(assetStructure, 'options');
      const resolved = await this.handleSave();

      if (assetStructure) {
        assetStructure.options = options;
        await assetStructure.save();
      }

      await this.saveTranslations(resolved.languageCode);

      if (this.args.onSavedCallback) {
        this.args.onSavedCallback(resolved);
        this.reset();
      }

      this.tags.filterBy('hasDirtyAttributes').invoke('rollbackAttributes');

      return resolved;
    } else {
      return Promise.reject(new Error());
    }
  }

  async getAlertText(): Promise<string> {
    let alertText = this.intl.t(
      'settings.questions.are_you_sure_deleting.part1'
    );

    if (this.model) {
      const questionDetail = await get(this.model, 'questionDetail');

      if (questionDetail) {
        const answersCount = get(questionDetail, 'answersCount');
        const triggerJobs = get(questionDetail, 'triggerJobs');

        if (answersCount && answersCount > 0) {
          alertText = alertText.concat(
            ' ',
            this.intl.t('settings.questions.are_you_sure_deleting.part2')
          );
        }

        if (triggerJobs.length) {
          alertText = alertText.concat(
            ' ',
            this.intl.t('settings.questions.are_you_sure_deleting.part3')
          );
        }
      }
    }

    return alertText;
  }

  @action
  async handleDelete(): Promise<void> {
    const alertText = await this.getAlertText();
    this.ttAlert.confirm(alertText, () => {
      this.deleteQuestion();
    });
  }

  @action
  handleClose(): void {
    this.args.onClose();
    // Rollback on hasMany currently not working in ember-changeset (3.15)
    // if the relation model is already persisted.
    this.translations
      .filterBy('hasDirtyAttributes')
      .invoke('rollbackAttributes');

    this.changeset?.rollback();

    if (this.args.onCloseCallback) {
      this.args.onCloseCallback();
    }

    this.reset();
  }

  @action
  handlePickQuestionType(questionType: Question): void {
    const model = this.store.createRecord('question', {
      type: MappedTypes[questionType.type],
      languageCode: get(
        get(this.current.company, 'defaultCareerSite'),
        'languageCode'
      ),

      // Change to default career site
      alternatives: [],
      questionData: { alternatives: [] },
      singleLine: true,
      multiple: false,
      title: this.changeset?.get('title'),
      description: this.changeset?.get('description'),
      scorecardCriterium: this.args.scorecardCriterium,
    });

    this.model?.destroy();

    this.changeset = changeset(
      model,
      lookupValidator(this.validators),
      this.validators
    );

    this.baseModel = model;

    const options = {
      department: null,
      role: null,
      region: null,
      location: null,
    };

    this.store.createRecord('asset-structure', {
      options,
      organizable: model,
    });
  }

  @action onDidInsert() {
    if (this.args.isQualifying) {
      const question: Question = {
        type: 'boolean',
        icon: QUESTION_TYPES.boolean.icon,
        value: QUESTION_TYPES.boolean.translationKey,
      };

      this.handlePickQuestionType(question);
    }
  }
}
