/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';
import Settings, {
  findProperty,
} from 'teamtailor/utils/insights/reports/settings';

interface ComponentArgs {
  settings: Settings;
  rowCount?: number;
  onUpdate: (settings: Settings) => void;
  onDone: () => void;
}

export default class ReportsSettingsComponent extends Component<ComponentArgs> {
  @service declare intl: IntlService;

  @tracked isAddMetricActive = false;
  @tracked isAddBreakdownActive = false;
  @tracked scrollContainerElement?: HTMLElement;

  get currentSettings() {
    return this.args.settings;
  }

  get basePropertySelectLabel() {
    let translationKey: string;
    if (this.isPieChart) {
      translationKey = 'breakdown';
    } else {
      translationKey = 'primary_axis';
    }

    return this.intl.t(
      `insights.reports.builder.chart_settings.${translationKey}`
    );
  }

  get additionalBasePropertySelectLabel() {
    return this.intl.t(`insights.reports.builder.chart_settings.breakdown`);
  }

  get propertySelectLabel() {
    let translationKey: string;
    if (this.isPieChart) {
      translationKey = 'metric';
    } else {
      translationKey = 'metric';
    }

    return this.intl.t(
      `insights.reports.builder.chart_settings.${translationKey}`
    );
  }

  get baseTypes() {
    return [{ type: 'regular' }, { type: 'cohort' }];
  }

  get currentBaseType() {
    return this.baseTypes[0];
  }

  get basePropertyOptions() {
    const chartType = this.args.settings.chartType?.name;

    if (!chartType) return [];

    return this.currentSettings.basePropertyOptions.filter((property) => {
      return property.allowAsBaseForChartTypes.includes(chartType);
    });
  }

  get additionalBasePropertyOptions() {
    return this.currentSettings.additionalBasePropertyOptions;
  }

  get baseProperty() {
    return this.currentSettings.baseProperty;
  }

  get additionalBaseProperty() {
    return this.currentSettings.additionalBaseProperty;
  }

  get selectedProperties() {
    return this.currentSettings.selectedProperties;
  }

  get availableProperties() {
    return this.currentSettings.availableProperties;
  }

  get groupedAvailableProperties() {
    return this.currentSettings.groupedAvailableProperties.map((group) => ({
      ...group,
      groupName: this.intl.t(
        `insights.reports.builder.property_groups.${group.groupName}`
      ),
    }));
  }

  get allProperties() {
    return this.currentSettings.properties;
  }

  get isBarChart() {
    return this.currentSettings.chartType?.name === 'bar';
  }

  get selectedFilters() {
    return this.currentSettings.filters;
  }

  get isPieChart() {
    return this.currentSettings.chartType?.name === 'pie';
  }

  get canAddAdditionalBaseProperty() {
    // Pie charts can only have one property on each axis
    if (this.isPieChart) {
      return false;
    }

    // No additional base property if there's already more than one metric property:
    return this.selectedProperties.length < 2;
  }

  get canAddMetricProperty() {
    // You can always add at least one metric property
    if (!this.selectedProperties.length) {
      return true;
    }

    // Pie charts can only have one property on each axis
    if (this.isPieChart) {
      return this.selectedProperties.length === 0;
    }

    // No secondary metric if there's already an additional base property:
    return !this.additionalBaseProperty;
  }

  @action
  handleUpdateName(name: string) {
    this.currentSettings.name = name;
    this.args.onUpdate(this.currentSettings);
  }

  @action
  updateBaseProperty(property: BaseProperty) {
    if (this.baseProperty?.serialize() === property.serialize()) {
      this.currentSettings.baseProperty = undefined;
    } else {
      this.currentSettings.baseProperty = property;
    }

    if (
      this.additionalBaseProperty &&
      !property.isCompatibleWithAdditionalBaseProperty(
        this.additionalBaseProperty
      )
    ) {
      this.currentSettings.additionalBaseProperty = undefined;
    }

    this.args.onUpdate(this.currentSettings);
  }

  @action
  updateAdditionalBaseProperty(property: BaseProperty | null) {
    if (
      !property ||
      this.additionalBaseProperty?.serialize() === property.serialize()
    ) {
      this.currentSettings.additionalBaseProperty = undefined;
    } else {
      this.currentSettings.additionalBaseProperty = property;
    }

    this.args.onUpdate(this.currentSettings);
  }

  @action
  handleSelectProperty(property: BaseProperty) {
    if (this.baseProperty?.serialize() === property.serialize()) {
      return;
    }

    if (findProperty(this.selectedProperties, property)) {
      this.currentSettings.selectedProperties = this.selectedProperties.filter(
        (item) => item !== property
      );
    } else {
      this.currentSettings.selectedProperties = [
        ...this.selectedProperties,
        property,
      ];
    }

    this.args.onUpdate(this.currentSettings);
  }

  @action
  handleUpdateProperty(
    oldProperty: BaseProperty,
    newProperty: BaseProperty | null
  ) {
    if (
      this.baseProperty &&
      this.baseProperty.serialize() === newProperty?.serialize()
    ) {
      return;
    }

    if (newProperty !== null) {
      const index = this.selectedProperties.findIndex(
        (item) => item.serialize() === oldProperty.serialize()
      );
      this.currentSettings.selectedProperties.splice(index, 0, newProperty);
    }

    this.currentSettings.selectedProperties = this.selectedProperties.filter(
      (item) => item !== oldProperty
    );

    this.args.onUpdate(this.currentSettings);
  }

  @action
  handleUpdateSortDirection(direction: any) {
    this.currentSettings.sortDirection = direction.name;
    this.args.onUpdate(this.currentSettings);
  }

  @action
  handleUpdateLimit(limit: any) {
    const stringLimit = limit?.value || limit;
    this.currentSettings.limit = stringLimit
      ? parseInt(stringLimit, 10)
      : undefined;
    this.args.onUpdate(this.currentSettings);
  }

  @action
  isInvalidProperty(property: BaseProperty) {
    if (
      this.baseProperty &&
      !property.isCompatibleWithBaseProperty(this.baseProperty)
    ) {
      return true;
    }

    if (this.additionalBaseProperty) {
      return !property.isCompatibleWithAdditionalBaseProperty(
        this.additionalBaseProperty
      );
    }

    return false;
  }

  @action
  incompatibleWithProperty(property: BaseProperty) {
    if (
      this.baseProperty &&
      !property.isCompatibleWithBaseProperty(this.baseProperty)
    ) {
      return this.baseProperty;
    }

    if (
      this.additionalBaseProperty &&
      !property.isCompatibleWithAdditionalBaseProperty(
        this.additionalBaseProperty
      )
    ) {
      return this.additionalBaseProperty;
    }

    return null;
  }

  @action
  combineGroupedAvailablePropertiesAnd(property: BaseProperty) {
    if (!property.category) {
      property.category = 'other';
    }

    const translatedCategory = this.intl.t(
      `insights.reports.builder.property_groups.${property.category}`
    );

    return this.groupedAvailableProperties.map((group) => {
      if (group.groupName === translatedCategory) {
        group.options = [...group.options, property];
      }

      return group;
    });
  }

  @action
  setScrollContainerElement(element: HTMLElement) {
    this.scrollContainerElement = element;
  }
}
