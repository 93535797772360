/* import __COLOCATED_TEMPLATE__ from './user-status-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import { modifier } from 'ember-modifier';
import moment, { Moment } from 'moment-timezone';
import { periodOptions } from 'teamtailor/constants/user-status';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import TimeFormatService from 'teamtailor/services/time-format';
import { tracked } from '@glimmer/tracking';
import UserStatusModel from 'teamtailor/models/user-status';

interface ComponentArgs {
  handleClose: () => void;
}

interface PeriodOption {
  id: string;
  label: string;
  isDefault?: boolean;
  add?: number;
  unit?: moment.unitOfTime.DurationConstructor;
  endOf?: moment.unitOfTime.StartOf;
  day?: number;
}

interface DateTimeOption {
  value: string;
  date: Moment;
}

export default class UserStatusModalComponent extends Component<ComponentArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare timeFormat: TimeFormatService;

  @tracked declare periodPlaceholder: string | null;
  @tracked declare selectedPeriodOption: PeriodOption | undefined;
  @tracked declare selectedCustomDate: Date;
  @tracked declare userStatus: UserStatusModel | undefined;

  setUserStatus = modifier(async () => {
    const { user } = this.current;

    const activeStatus = await get(user, 'activeStatus');

    if (activeStatus) {
      this.userStatus = activeStatus;
    } else {
      this.userStatus = this.store.createRecord('user-status', {
        user,
        isLastActive: true,
      });
    }

    this.setSelectedPeriodOption();
  });

  setSelectedPeriodOption() {
    const endOfToday = moment().endOf('day');
    const endOfThisWeek = moment().day(5).endOf('day');
    const endOfNextWeek = moment().add(1, 'weeks').day(5).endOf('day');
    const momentEndDate = moment(this.userStatus?.endDate);
    let optionId = '';

    if (momentEndDate.isSame(endOfToday)) {
      optionId = 'today';
    } else if (momentEndDate.isSame(endOfThisWeek)) {
      optionId = 'end_of_this_week';
    } else if (momentEndDate.isSame(endOfNextWeek)) {
      optionId = 'end_of_next_week';
    } else if (
      this.userStatus?.endDate &&
      (momentEndDate.minute() === 0 || momentEndDate.minute() === 30)
    ) {
      optionId = 'custom';
    }

    if (this.userStatus?.endDate) {
      this.selectedCustomDate = this.userStatus.endDate;

      if (optionId) {
        this.selectedPeriodOption = this.periodOptions.find(
          (option) => option.id === optionId
        );
      } else {
        this.periodPlaceholder = moment(this.userStatus.endDate).format(
          this.timeFormat.format
        );
      }
    }
  }

  get statusValue(): string {
    if (this.userStatus?.status) {
      return this.userStatus.status.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    }

    return '';
  }

  get periodOptions(): PeriodOption[] {
    const currentDay = moment().day();
    const isBeforeFriday = currentDay > 0 && currentDay < 5;
    const options: PeriodOption[] = [];

    periodOptions.forEach((option) => {
      if (option.id !== 'end_of_this_week' || isBeforeFriday) {
        options.push({
          ...option,
          label: this.intl.t(`components.user_status_modal.${option.id}`),
        } as PeriodOption);
      }
    });

    return options;
  }

  get defaultPeriodOption(): PeriodOption | undefined {
    return this.periodOptions.find((option) => option.isDefault);
  }

  get periodOption(): PeriodOption | undefined {
    return this.selectedPeriodOption || this.defaultPeriodOption;
  }

  get minDate(): Date {
    return new Date();
  }

  get customDateTimeOptions(): DateTimeOption[] {
    const options = [];
    const isToday = this.isToday(this.selectedCustomDate);
    const selectedDate = this.adjustCustomDate(
      this.selectedCustomDate,
      isToday
    );
    let startDate = moment(selectedDate);

    if (!isToday) {
      startDate = startDate.startOf('day');
    }

    const limitDate = moment(selectedDate).endOf('day');

    while (startDate <= limitDate) {
      const date = startDate.clone();
      options.push({ value: date.format(this.timeFormat.format), date });
      startDate.add(30, 'minutes');
    }

    return options;
  }

  get selectedCustomDateOption(): DateTimeOption | undefined {
    return this.customDateTimeOptions.find((option) => {
      return option.date.isSame(moment(this.selectedCustomDate));
    });
  }

  get saveButtonDisabled(): boolean {
    return !(get(this.userStatus, 'emoji') && get(this.userStatus, 'status'));
  }

  isToday(date: Date): boolean {
    return date.toDateString() === new Date().toDateString();
  }

  adjustTodayDate() {
    const now = moment();
    if (now.minute() > 30) {
      return now.add(1, 'hours').startOf('hour').toDate();
    } else {
      return now.minute(30).second(0).millisecond(0).toDate();
    }
  }

  adjustDateWithSameTime(date: Date) {
    const selectedMoment = moment(this.selectedCustomDate);
    return moment(date)
      .hour(selectedMoment.hour())
      .minute(selectedMoment.minute())
      .second(0)
      .toDate();
  }

  adjustCustomDate(date: Date, updateTime: boolean) {
    if (updateTime) {
      return this.adjustTodayDate();
    } else {
      return this.adjustDateWithSameTime(date);
    }
  }

  @action
  updateStatusValue(event: InputEvent) {
    if (event.target) {
      let status = (event.target as HTMLInputElement).value;
      if (this.userStatus) {
        this.userStatus.status = status
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');
      }
    }
  }

  @action
  onSelectPeriod(option: PeriodOption) {
    this.periodPlaceholder = null;
    this.selectedPeriodOption = option;

    if (option.id === 'dont_clear' && this.userStatus) {
      this.userStatus.endDate = null;
    } else if (option.id === 'custom') {
      this.selectedCustomDate = this.adjustCustomDate(this.minDate, true);
    } else {
      const validUntil = moment();
      if (option.add && option.unit) {
        validUntil.add(option.add, option.unit);
      }

      if (option.day) {
        validUntil.day(option.day);
      }

      if (option.endOf) {
        validUntil.endOf(option.endOf);
      }

      if (this.userStatus) {
        this.userStatus.endDate = validUntil.toDate();
      }
    }
  }

  @action
  onSelectCustomDate(date: Date) {
    const isToday = this.isToday(date);
    let updateTime = false;

    if (isToday) {
      const dateWithPreviousTime = this.adjustDateWithSameTime(date);
      updateTime = moment(dateWithPreviousTime).isBefore(this.minDate);
    }

    this.selectedCustomDate = this.adjustCustomDate(date, updateTime);
  }

  @action
  onSelectCustomTime(option: DateTimeOption) {
    this.selectedCustomDate = option.date.toDate();
  }

  @action
  saveStatus() {
    if (this.selectedPeriodOption?.id === 'custom' && this.userStatus) {
      this.userStatus.endDate = this.selectedCustomDate;
    }

    this.userStatus?.save();
    this.args.handleClose();
  }

  @action
  onClose() {
    if (this.userStatus?.isNew) {
      this.userStatus.deleteRecord();
    }

    this.args.handleClose();
  }
}
