/* import __COLOCATED_TEMPLATE__ from './blocket.hbs'; */
import PromotionBaseClass from './promotion-base';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

import { blocketEmploymentTypes } from 'teamtailor/constants/promote';

export default class extends PromotionBaseClass {
  blocketEmploymentTypes = blocketEmploymentTypes;

  @tracked checkoutData;

  get blocketCategories() {
    return this.store.peekAll('blocket-category');
  }

  get blocketRegions() {
    return this.store.peekAll('blocket-region');
  }

  get blocketEmployment() {
    return blocketEmploymentTypes.findBy(
      'id',
      get(this, 'promotion.blocketEmployment')
    );
  }
}
