import Service, { inject as service } from '@ember/service';

export default class DirectUploadService extends Service {
  @service server;
  @service current;

  // This array is duplicated in the backend Upload model
  DISALLOWED_EXTENSIONS = [
    'dmg',
    'pkg',
    'com',
    'exe',
    'bat',
    'cmd',
    'vbs',
    'vbe',
    'jse',
    'wsf',
    'wsh',
    'msc',
  ];

  s3_sign_upload_path(model) {
    return `/app/companies/${this.current.company.uuid}/api/${model}/sign_upload`;
  }

  async s3(
    file,
    { model = 'uploads', rootPath = '', filename, returnKey = false } = {}
  ) {
    const { url, credentials } = await this.server.request(
      this.s3_sign_upload_path(model),
      'GET',
      {
        data: { root_path: rootPath, file_name: file.name },
      }
    );

    let data = Object.assign(credentials, {
      'Content-Type': file.type,
    });

    if (filename) {
      filename = filename.replace('.svg+xml', '.svg');
      // eslint-disable-next-line tt/no-template-curly-in-string-fix
      data.key = credentials.key.replace('${filename}', filename);
    }

    const response = await file.upload(url, {
      data,
    });

    const text = await response.text();

    if (returnKey) {
      const [, key] = text.match(/<Key>(.*)<\/Key>/i);
      return key;
    }

    const [, location] = text.match(/<Location>(.*)<\/Location>/i);
    return location.replace(/%2F/g, '/');
  }
}
