/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { JobBulkArgs } from 'teamtailor/constants/jobs';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Server from 'teamtailor/services/server';

enum BulkTypes {
  DELETE = 'bulk_delete',
  STATUS = 'bulk_status',
}

const DEFAULT_PARAMS = {
  per_page: 10000,
  page: 1,
  sort: 'pinned-desc',
};

export default class BulkActionsJobsStatusComponent extends Component<JobBulkArgs> {
  @service declare store: Store;
  @service declare server: Server;

  @tracked status: string | null = null;
  @tracked isDeleteConfirmStep = false;
  @tracked confirmationNumber = '';
  @tracked onlyArchivedJobs = false;

  get isFirstDeleteStep(): boolean {
    return this.isDelete && !this.isDeleteConfirmStep;
  }

  get isDelete(): boolean {
    return this.status === 'delete';
  }

  get isStatusButtonDisabled(): boolean {
    if (this.isDelete && this.isDeleteConfirmStep) {
      return Number(this.confirmationNumber) !== this.args.selectedCount;
    }

    return this.status === null;
  }

  fetchIsAllArchived = dropTask(async () => {
    if (this.args.selectAll) {
      const adapter = this.store.adapterFor('application');
      const url = `${adapter.buildURL('job')}/bulk_select_all_archived`;

      const { only_archived_jobs } = (await this.server.request(url, 'GET', {
        data: {
          ...this.args.searchParams,
          ...DEFAULT_PARAMS,
          bulkData: {
            deselected_ids: this.args.deselectedIds,
          },
        },
      })) as { only_archived_jobs: boolean };

      this.onlyArchivedJobs = only_archived_jobs;
    } else {
      this.onlyArchivedJobs = this.args.selectedJobs.every(
        (job) => job.isArchived
      );
    }
  });

  @action
  onClose(): void {
    this.args.onClose();
    this.status = null;
    this.isDeleteConfirmStep = false;
    this.confirmationNumber = '';
  }

  @action
  handleBulkChange(): void {
    const data = {
      status: this.status,
    };

    const bulkType = this.isDelete ? BulkTypes.DELETE : BulkTypes.STATUS;
    this.args.handleBulkChange(data, bulkType);
    this.onClose();
  }
}
