/* import __COLOCATED_TEMPLATE__ from './two-factor-auth-enforce-banner.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

export default class TwoFactorAuthEnforceBanner extends Component {
  @service declare current: Current;

  get enforcementDate() {
    return this.current.company.twoFactorAuthenticationEnforcedAt;
  }
}
