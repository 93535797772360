/* import __COLOCATED_TEMPLATE__ from './nps.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CandidateModel, JobApplicationModel } from 'teamtailor/models';
import Store from '@ember-data/store';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel | undefined;
  onClose: () => void;
}

export default class extends Component<Args> {
  @tracked npsErrors = false;
  @tracked npsJobApplication: JobApplicationModel | undefined;
  @service declare store: Store;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.npsJobApplication = this.defaultNpsJobApplication;
  }

  get candidate() {
    return this.args.candidate;
  }

  get jobApplication() {
    return this.args.jobApplication;
  }

  get hasMoreThanOneApplication() {
    return this.candidate.jobApplications.length > 1;
  }

  get defaultNpsJobApplication() {
    if (this.jobApplication) {
      return this.jobApplication;
    }

    return this.candidate.jobApplications.toArray()[0];
  }

  @action
  sendNps() {
    this.npsErrors = false;
    const jobApplication = this.npsJobApplication;
    const npsResponse = this.store.createRecord('nps-response', {
      jobApplication,
    });

    const promise = npsResponse.save();

    promise.then(() => {
      this.args.onClose();
    });

    promise.catch(() => {
      this.npsErrors = true;
      npsResponse.destroyRecord();
    });

    return promise;
  }
}
