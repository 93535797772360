/* import __COLOCATED_TEMPLATE__ from './pwa-installed-message.hbs'; */
/* eslint-disable no-await-in-loop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { fadeIn } from 'ember-animated/motions/opacity';
import { BoxShadow } from 'ember-animated/box-shadow';
import boxShadow from 'ember-animated/motions/box-shadow';
import move from 'ember-animated/motions/move';
import resize from 'ember-animated/motions/resize';
import { timeout, dropTask } from 'ember-concurrency';
import { rAF, afterRender } from 'ember-animated';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import { animate } from 'teamtailor/ember-smooth';

export default class extends Component {
  @service bowser;
  @service notifications;
  @service pwaInstallation;
  @service router;

  @tracked
  isShowingPWAInstalled = false;

  showPwaInstalledMessage = modifier((element, [shouldShowMessage]) => {
    if (shouldShowMessage) {
      this.scheduleToShow.perform();
    } else {
      this.scheduleToShow.cancelAll();
      this.isShowingPWAInstalled = false;
    }
  });

  get isInPwa() {
    return window.matchMedia(
      '(display-mode: standalone), (display-mode: minimal-ui)'
    ).matches;
  }

  scheduleToShow = dropTask(async () => {
    setTimeout(() => {
      this.scheduleToShow.cancelAll();
      // If it hasnt gotten focus in 10 secs, just show it anyway
      if (this.isInPwa) {
        this.isShowingPWAInstalled = true;
      }
    }, 10000);

    while (true) {
      await timeout(100);
      if (this.isInPwa && document.hasFocus()) {
        await afterRender();
        await rAF();

        if (window.requestIdleCallback) {
          await new Promise((resolve) => {
            requestIdleCallback(resolve);
          });
        }

        this.isShowingPWAInstalled = true;
        break;
      }
    }
  });

  @action
  async in(element) {
    element.style.opacity = 0;
    await animate.from(element, { height: 0 }, 250);
    await animate.to(element, { opacity: 1 }, 250);
  }

  async out(element) {
    await animate.to(element, { height: 0 }, 250);
  }

  get googleUrl() {
    return `https://www.google.com/search?q=change+site+notification+permissions+${this.bowser.browser.name}&btnI`;
  }

  *transition({ insertedSprites, beacons }) {
    for (let sprite of insertedSprites) {
      sprite.applyStyles({ opacity: '0' });
      sprite.startAtSprite(
        beacons['pwa-installed-dismissable-message__beacon']
      );
      yield resize(sprite);

      if (sprite.finalComputedStyle['box-shadow']) {
        const from = BoxShadow.fromComputedStyle(
          sprite.finalComputedStyle['box-shadow']
        ).map((boxShadowInstance) => {
          ['x', 'y', 'spread', 'blur'].forEach((prop) => {
            if (boxShadowInstance[prop]) {
              boxShadowInstance[prop] = 0;
            }
          });
          return boxShadowInstance;
        });

        boxShadow(sprite, {
          from,
        });

        sprite.startTranslatedBy(0, 5);
        move(sprite);
        fadeIn(sprite, { from: 0 });
      }
    }
  }

  @action
  handlePwaInstalledEnableNotificationsClick() {
    this.notifications.enableDesktopNotifications();
  }
}
