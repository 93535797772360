/* import __COLOCATED_TEMPLATE__ from './stat-bar.hbs'; */
import Component from '@glimmer/component';

type PercentageOption = {
  isPercentage?: true;
  totalValue?: never;
};

type TotalValueOption = {
  totalValue?: string | number;
  isPercentage?: never;
};

type StatBarItemBase = {
  title: string;
  value: string | number;
  icon?: string;
  infoTitle?: string;
  infoText?: string;
};

export type StatBarItem = StatBarItemBase &
  (PercentageOption | TotalValueOption);

interface StatBarArgs {
  stats: StatBarItem[];
}

export default class StatBar extends Component<StatBarArgs> {}
