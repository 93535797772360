/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import { get } from 'teamtailor/utils/get';
import { NavigationItemModel } from 'teamtailor/models';

type HeaderItemArgs = {
  item: NavigationItemModel;
  reloadItems: () => Promise<void>;
};

export default class HeaderItemComponent extends Component<HeaderItemArgs> {
  @service declare store: Store;
  @service declare iframePreview: IframePreviewService;
  @service declare intl: IntlService;

  @tracked editingTitle = false;

  get item() {
    return this.args.item;
  }

  get subtitle() {
    if (this.item.staticType) {
      switch (this.item.staticType) {
        case 'home':
          return this.intl.t('content.editor.header.item_subtitle.home');
        case 'departments':
          return get(
            get(get(this.item, 'careerSiteHeader'), 'careerSite'),
            'departmentNamePlural'
          );
        case 'locations':
          return this.intl.t('content.editor.header.item_subtitle.locations');
        case 'people':
          return this.intl.t('content.editor.header.item_subtitle.people');
        case 'jobs':
          return this.intl.t('content.editor.header.item_subtitle.jobs');
        case 'blog':
          return this.intl.t('content.editor.header.item_subtitle.blog');
        case 'posts':
          return this.intl.t('content.editor.header.item_subtitle.posts');
        default:
          return null;
      }
    } else {
      return (
        get(this.item.pagePublication, 'name') ||
        get(this.item.pagePublication, 'navigationTitle')
      );
    }
  }

  @action
  async handleDestroy() {
    await this.item.destroyRecord();
    this.args.reloadItems();
    this.iframePreview.reloadIframe();
  }

  @action
  async toggleVisibility() {
    this.item.visible = !this.item.visible;
    await this.item.save();
    this.iframePreview.reloadIframe();
  }

  @action
  async onCustomTitleChange(customTitle: string, isEditingTitle: boolean) {
    this.item.customTitle = customTitle;

    await this.item.save();

    this.editingTitle = !isEditingTitle;
    this.iframePreview.reloadIframe();
  }

  @action
  focusAndSelectText(inputElement: HTMLInputElement) {
    inputElement.focus();
    inputElement.select();
  }
}
