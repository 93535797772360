/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import MessageService from 'teamtailor/services/message-service';

export default class extends Component {
  @service declare messageService: MessageService;

  @action
  setNewHire() {
    this.messageService.newHire = true;
  }
}
