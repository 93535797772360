/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { setProperties, get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { ROLES } from 'teamtailor/constants/roles';
import { isEmpty } from '@ember/utils';
import FlashMessageService from 'teamtailor/services/flash-message';
import RouterService from '@ember/routing/router-service';
import IntlService from 'ember-intl/services/intl';
import TodoTemplateModel from 'teamtailor/models/todo-template';
import TodoModel from 'teamtailor/models/todo';
import recordRemover from 'teamtailor/utils/record-remover';
import Store from '@ember-data/store';
import { next } from '@ember/runloop';

const ALL_ROLES = { id: 'all_roles', label: 'search.role.all_roles' };
const EXCLUDE_ROLES = ['no_access', 'external_recruiter'];

type TodoTemplateFormArgs = {
  todoTemplate: TodoTemplateModel;
};

type RoleOption = {
  name: string;
  value: string;
};

type TodoProperties = {
  value: string;
  description: string;
  relativeDueDays: number | null;
};

type DueDays = {
  value: number;
  label: string;
};

export default class extends Component<TodoTemplateFormArgs> {
  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked editingTodo: TodoModel | null = null;
  @tracked showDeleteModal = false;
  @tracked selectedRoles = this.roles;

  footerElement: HTMLElement | null = null;

  get todoTemplate() {
    return this.args.todoTemplate;
  }

  get roles() {
    return this.todoTemplate.roles;
  }

  get todos() {
    return this.todoTemplate.todos;
  }

  get roleOptions(): RoleOption[] {
    return [ALL_ROLES, ...ROLES]
      .filter((role) => !EXCLUDE_ROLES.includes(role.id))
      .map((roleObj) => {
        const { label, id } = roleObj;
        return {
          name: this.intl.t(label),
          value: id,
        };
      });
  }

  get selectedRoleObjects() {
    return this.roleOptions.filter((role) => {
      return this.selectedRoles.includes(role.value);
    });
  }

  @action
  handleNameChange({ target: { value } }: { target: HTMLInputElement }) {
    this.todoTemplate.name = value;
  }

  @action
  handleRoleChange(role: RoleOption) {
    const selectedRole = role.value;
    const roleHasBeenSelected = this.selectedRoles.includes(selectedRole);
    const isAllRoles = selectedRole === ALL_ROLES.id;

    if (isAllRoles) {
      this.selectedRoles = roleHasBeenSelected
        ? []
        : this.roleOptions.mapBy('value');
    } else if (roleHasBeenSelected) {
      this.selectedRoles.removeObject(selectedRole);
    } else {
      this.selectedRoles.addObject(selectedRole);
    }

    if (this.selectedRoles.length !== this.roleOptions.length) {
      this.selectedRoles.removeObject(ALL_ROLES.id);
    }

    this.todoTemplate.roles = this.selectedRoles.without(ALL_ROLES.id);
  }

  @action
  save() {
    return this.todoTemplate.save().then(() => {
      this.flashMessages.success(
        this.intl.t('settings.todo_templates.template_saved')
      );
      recordRemover(this.store, 'todo');
      this.router.transitionTo('settings.todo-templates');
    });
  }

  @action
  addTodo() {
    const todo = this.todos.createRecord();
    this.editingTodo = todo;

    next(() => {
      const scrollElement = document.getElementById('scrollable-container');
      if (scrollElement && this.footerElement) {
        const { top } = this.footerElement.getBoundingClientRect();
        scrollElement.scrollTo({
          left: 0,
          top,
          behavior: 'smooth',
        });
      }
    });
  }

  @action
  deleteTodo(todo: TodoModel) {
    return todo.destroyRecord();
  }

  @action
  editTodo(todo: TodoModel) {
    this.editingTodo = todo;
  }

  @action
  handleRelativeDateChange({ value }: DueDays) {
    if (!this.editingTodo) {
      return;
    }

    this.editingTodo.relativeDueDays = value;
  }

  @action
  handleTodoSubmit({ description, value, relativeDueDays }: TodoProperties) {
    setProperties(this.editingTodo, {
      description,
      value,
      relativeDueDays,
    });

    this.editingTodo = null;
  }

  @action
  handleCancel() {
    if (this.editingTodo === null) {
      return;
    }

    if (
      get(this.editingTodo, 'isNew') &&
      (isEmpty(this.editingTodo.value) ||
        isEmpty(this.editingTodo.relativeDueDays))
    ) {
      this.todos.removeObject(this.editingTodo);
    }

    this.editingTodo = null;
  }

  @action
  handleConfirmDelete() {
    return this.todoTemplate.destroyRecord().then(() => {
      this.showDeleteModal = false;
      this.flashMessages.success(
        this.intl.t('settings.todo_templates.template_deleted')
      );
      this.store.unloadRecord(this.todoTemplate);
      this.router.transitionTo('settings.todo-templates');
    });
  }

  @action
  handleCancelDelete() {
    this.showDeleteModal = false;
  }

  @action
  handleInsertFooterElement(element: HTMLElement) {
    this.footerElement = element;
  }
}
