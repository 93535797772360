/* import __COLOCATED_TEMPLATE__ from './referral-list.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import RSVP from 'rsvp';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { Referral } from 'teamtailor/classes/analytics/report-employee-referrals';
import AnalyticsService from 'teamtailor/services/analytics';

type Args = {
  referrals?: Referral[];
};

export default class InsightsWidgetsReferralList extends Component<Args> {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;

  get jobs() {
    return this.fetchData.lastSuccessful?.value?.jobs;
  }

  get users() {
    return this.fetchData.lastSuccessful?.value?.users;
  }

  get candidates() {
    return this.fetchData.lastSuccessful?.value?.candidates;
  }

  get numberOfReferrals() {
    return this.args.referrals?.reduce(
      (acc, referral) => acc + referral.numberOfReferrals,
      0
    );
  }

  get numberOfHired() {
    return get(this, 'candidates')?.reduce((acc, candidate) => {
      return acc + (candidate.hiredCount > 0 ? 1 : 0);
    }, 0);
  }

  fetchData = dropTask(async () => {
    const userIds = this.args.referrals?.map((referral) => referral.userId);

    const candidateIds = this.args.referrals
      ?.map((referral) => referral.candidateIds)
      .flat()
      .filter((id) => id);
    const jobIds = this.args.referrals
      ?.map((referral) => referral.jobId)
      .filter((id) => id);

    return RSVP.hash({
      users: this.store.query('user', {
        ids: userIds,
        groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
      }),

      jobs: this.store.query('job', {
        ids: jobIds,
        groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
      }),

      candidates: this.store.query('candidate', {
        ids: candidateIds,
        groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
      }),
    });
  });

  @action
  runFetchData() {
    if (this.args.referrals?.length) {
      this.fetchData.perform();
    }
  }
}
