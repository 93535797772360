/* import __COLOCATED_TEMPLATE__ from './status-dot.hbs'; */
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { STATUS_COLOR_MAP } from '../constants/status-dot';

const DEFAULT_STATUS = 'unknown';
const DEFAULT_SIZE = 'large';
const ALLOWED_SIZES = ['small', 'medium', DEFAULT_SIZE];

export default class StatusDotComponent extends Component {
  get mappedColor() {
    [this.args.status].flat().forEach((status) => {
      verifyArg(
        status,
        Object.keys(STATUS_COLOR_MAP),
        'StatusDot @status',
        DEFAULT_STATUS
      );
    });

    if (Array.isArray(this.args.status)) {
      return this.args.status.map((status) => STATUS_COLOR_MAP[status]);
    } else {
      return STATUS_COLOR_MAP[this.args.status];
    }
  }

  get size() {
    verifyArg(this.args.size, ALLOWED_SIZES, 'StatusDot @size', DEFAULT_SIZE);
    return this.args.size ?? DEFAULT_SIZE;
  }
}
