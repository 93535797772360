/* import __COLOCATED_TEMPLATE__ from './back-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BackButton extends Component {
  @action
  goBack() {
    window.history.back();
  }
}
