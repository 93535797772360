/* import __COLOCATED_TEMPLATE__ from './info-banner.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const HIDE_INFO_ALERT = 'new-candidate-card-info-alert';

export default class extends Component {
  @tracked isAlertHidden;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.isAlertHidden = localStorage.getItem(`${HIDE_INFO_ALERT}`) === 'true';
  }

  @action
  hideAlert() {
    localStorage.setItem(`${HIDE_INFO_ALERT}`, 'true');
    this.isAlertHidden = true;
  }
}
