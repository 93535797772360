/* import __COLOCATED_TEMPLATE__ from './outlet-target.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import VideoMeetingService from 'teamtailor/services/video-meeting';

interface OutletTargetArgs {
  id: string;
}

export default class OutletTarget extends Component<OutletTargetArgs> {
  @service videoMeeting?: VideoMeetingService;

  get isVideoMeetingOpenToSidebar(): boolean {
    return this.videoMeeting?.isActiveSideBar || false;
  }
}
