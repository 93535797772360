/* import __COLOCATED_TEMPLATE__ from './archive.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { RequisitionsBulkArgs } from 'teamtailor/constants/requisitions';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import { RequisitionArchiveReasonModel } from 'teamtailor/models';
import CompanyModel from 'teamtailor/models/company';
import Current from 'teamtailor/services/current';

export default class ArchiveRequisitionsComponent extends Component<RequisitionsBulkArgs> {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare current: Current;

  @tracked declare numberOfRequisitionsToDelete: number | null;
  @tracked requisitionArchiveReason: RequisitionArchiveReasonModel | null =
    null;

  get company(): CompanyModel {
    return this.current.company;
  }

  get requisitionArchiveReasons() {
    const reasons = this.company.sortedRequisitionArchiveReasons;
    return reasons.map((reason: RequisitionArchiveReasonModel) => {
      return {
        name: reason.reason,
        id: reason.id,
        companyId: this.current.company.id,
        reason,
      };
    });
  }

  @action
  archiveRequisitions(): void {
    const data = {
      requisition_archive_reason_id: this.requisitionArchiveReason?.id,
    };
    this.args.handleBulkChange(data, 'bulk_archive');
    this.onClose();
  }

  @action
  onClose() {
    this.numberOfRequisitionsToDelete = null;
    this.args.onClose();
  }
}
