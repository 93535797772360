/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { jsonToBase64 } from 'teamtailor/utils/base-64';
import { action } from '@ember/object';

export default class DropdownComponent extends Component {
  @service current;
  @service router;
  @service flipper;
  @service ttAlert;
  @service intl;

  get user() {
    return this.current.user;
  }

  get printUrl() {
    return this.router.urlFor('print', {
      queryParams: { printOptions: JSON.stringify(this.args.bulkOptions) },
    });
  }

  @action
  confirmMerge() {
    const { selectedCandidateIds } = this.args.bulkOptions;
    if (selectedCandidateIds.length > 3) {
      this.ttAlert.confirm(
        this.intl.t(
          'components.bulk_actions.candidates.dropdown.merging_many_candidates.description',
          { count: selectedCandidateIds.length }
        ),
        () => {
          this.args.onOpenModal('Merge');
        },
        () => {},
        {
          title: this.intl.t(
            'components.bulk_actions.candidates.dropdown.merging_many_candidates.title'
          ),

          cancelButtonText: this.intl.t('common.cancel'),

          confirmButtonText: this.intl.t(
            'components.bulk_actions.candidates.dropdown.merging_many_candidates.confirm_button'
          ),
        }
      );
    } else {
      this.args.onOpenModal('Merge');
    }
  }

  getNestedMeetingsRoute(meetingRoute) {
    if (this.router.currentRouteName.includes('jobs.job.stages.index')) {
      return `jobs.job.stages.index.${meetingRoute}`;
    }

    if (this.router.currentRouteName.includes('candidates.segment')) {
      return `candidates.segment.${meetingRoute}`;
    }

    return meetingRoute;
  }

  get newMeetingRoute() {
    return this.getNestedMeetingsRoute('meetings.new');
  }

  get bulkOptionsBase64() {
    const { query, selectedCandidateIds, deselectedCandidateIds } =
      this.args.bulkOptions;
    return jsonToBase64({
      ...query,
      selected_candidate_ids: selectedCandidateIds,
      deselected_candidate_ids: deselectedCandidateIds,
      per_page: 500,
    });
  }
}
