/* import __COLOCATED_TEMPLATE__ from './key-details-editable.hbs'; */
import Component from '@glimmer/component';
import { get, set, action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import currencyForCountry from 'teamtailor/utils/currency-for-country';

export default class KeyDetailsEditable extends Component {
  @service store;

  constructor(owner, args) {
    super(owner, args);

    if (
      !get(args, 'requisition.requisitionFlow.id') &&
      !get(args, 'hideFlowPreview')
    ) {
      get(this, 'findFlow').perform();
    }
  }

  get departments() {
    const divisionId = get(this.args, 'requisition.division.id') ?? null;
    const departments = get(this.args, 'departments') ?? [];

    return departments.filter(
      (department) => get(department, 'division.id') === divisionId
    );
  }

  findFlow = task(async () => {
    const store = get(this, 'store');
    const requisition = get(this.args, 'requisition');
    const country = get(requisition, 'country');
    const locationId = get(requisition, 'location.id');
    const departmentId = get(requisition, 'department.id');
    const roleId = get(requisition, 'role.id');
    const maxSalary = get(requisition, 'maxSalary') || 0;
    const minSalary = get(requisition, 'minSalary') || 0;
    const greaterSalary = maxSalary > minSalary ? maxSalary : minSalary;
    const currency = get(requisition, 'currency');
    const salaryTimeUnit = get(requisition, 'salaryTimeUnit');

    return store
      .query('requisition-flow', {
        country,
        location_id: locationId,
        department_id: departmentId,
        role_id: roleId,
        ...(greaterSalary
          ? {
              currency,
              max_salary: greaterSalary,
              salary_time_unit: salaryTimeUnit,
            }
          : {}),
      })
      .then((flows) => {
        set(
          this.args,
          'requisition.requisitionFlow',
          flows[0] || get(this.args, 'defaultFlow')
        );
      });
  });

  @action
  onCriteriaChange(criteriaType = null) {
    if (criteriaType === 'location') {
      const requisition = get(this.args, 'requisition');
      if (requisition.isDeleted) {
        return;
      }

      const currency = currencyForCountry(get(requisition, 'country'));
      if (currency && !get(this.args, 'requisition.currency')) {
        set(this.args, 'requisition.currency', currency);
      }
    }

    this.findFlow.perform();
  }

  @action
  addAttachment(temporaryFileUrl, filename) {
    set(this.args, 'requisition.attachment', temporaryFileUrl);
    set(this.args, 'requisition.attachmentFileName', filename);
    set(this.args, 'requisition.removeAttachment', false);
  }

  @action
  removeAttachment() {
    set(this.args, 'requisition.attachment', undefined);
    set(this.args, 'requisition.attachmentFileName', null);
    set(this.args, 'requisition.removeAttachment', true);
  }

  @action
  handleChangeRecruiter(recruiter) {
    this.args.requisition.recruiter = recruiter;
  }

  @action
  handleOnInputSalary() {
    debounce(this.findFlow.perform, 750);
  }
}
