/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import {
  AnswerModel,
  CandidateModel,
  InterviewKitModel,
  InterviewModel,
  JobApplicationModel,
  MeetingEventAttendeeModel,
  MeetingEventModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { NESTED_TYPES } from 'teamtailor/utils/competence/ordered-list-helpers';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

interface CandidateModalNewMainSectionInterviewsInterviewKitArgs {
  candidate: CandidateModel;
  interviewKit: InterviewKitModel;
  jobApplication: JobApplicationModel;
  interviews: InterviewModel[];
}

export default class CandidateModalNewMainSectionInterviewsInterviewKit extends Component<CandidateModalNewMainSectionInterviewsInterviewKitArgs> {
  @tracked expanded = false;
  @service declare current: Current;
  @service declare router: RouterService;

  competenceType = NESTED_TYPES.COMPETENCE;
  questionType = NESTED_TYPES.QUESTION;

  get displayInterviewFeedback() {
    if (this.meetingAttendedByUser) {
      return this.hasUserSubmittedFeedback || this.copilotSubmittedFeedback;
    }

    return true;
  }

  get hasUserSubmittedFeedback() {
    return this.interviews.some(
      (i) => get(i, 'userId') === get(this.current.user, 'id')
    );
  }

  get copilotSubmittedFeedback() {
    return this.interviews.some((i) => !i.userId);
  }

  get meetingAttendedByUser() {
    const interviewKitId = parseInt(this.interviewKitId);
    return get(this.args.candidate, 'meetingEvents').find(
      (meeting) =>
        meeting.interviewKitId &&
        meeting.interviewKitId === interviewKitId &&
        this.isUserExpectedToLeaveFeedback(meeting)
    );
  }

  get job() {
    return this.args.jobApplication.job;
  }

  get interviewsWithNotes() {
    return this.interviews.filter((interview) => !!interview.note);
  }

  get interviews() {
    return this.args.interviews.filterBy('interviewKitId', this.interviewKitId);
  }

  get interviewKitId() {
    return get(this.args.interviewKit, 'id');
  }

  get answersCount() {
    return this.interviews
      .map((interview: InterviewModel) => {
        const answers = get(interview, 'completedAnswers');
        const count = answers.length;
        return count;
      })
      .reduce((acc, count) => acc + count, 0);
  }

  get lastAnswerDate() {
    const dates = this.interviews
      .map((interview: InterviewModel) => {
        const answers = get(interview, 'completedAnswers');
        const lastAnswer = answers.sortBy('updatedAt').reverse()[0];
        return lastAnswer?.updatedAt;
      })
      .compact();

    return dates.length ? dates.sort().reverse()[0] : null;
  }

  get allAnswers() {
    return this.interviews.map((interview) => get(interview, 'answers'));
  }

  get nestedInterviewItemsWithAnswer() {
    return this.fetchNestedItemsTrackedTask.value;
  }

  nestedItemsWithAnswerTask = task(this, async () => {
    const nested = await get(this.args.interviewKit, 'nestedInterviewItems');

    const answersWithValue = (await Promise.all(this.allAnswers))
      .flat()
      .filter((answer) => !answer.emptyAnswer);

    const scores = await Promise.all(
      this.interviews.map((interview) => get(interview, 'scores'))
    );

    return nested.filter((item) => {
      if (item.type === this.competenceType) {
        const criteriaId = get(item.model, 'id');
        const hasScore = scores
          .flat()
          .filter((score) => score.score !== null)
          .some(
            (score) =>
              get(
                get(get(score, 'scorecardPick'), 'scorecardCriterium'),
                'id'
              ) === criteriaId
          );
        const questionIds = item.children?.map((question) =>
          get(question, 'id')
        );
        let hasAnswer = false;
        if (questionIds) {
          hasAnswer = answersWithValue.some((answer) => {
            const answerQuestionId = answer.belongsTo('question').id();
            return answerQuestionId && questionIds.includes(answerQuestionId);
          });
        }

        return hasScore || hasAnswer;
      }

      if (item.type === this.questionType) {
        return answersWithValue.some(
          (answer) =>
            get(item.model, 'id') === answer.belongsTo('question').id()
        );
      }
    });
  });

  fetchNestedItemsTrackedTask = trackedTask(
    this,
    this.nestedItemsWithAnswerTask,
    () => [this.allAnswers, this.interviews]
  );

  isUserExpectedToLeaveFeedback(meeting: MeetingEventModel) {
    return meeting.meetingEventAttendees
      ?.slice()
      .some((invite: MeetingEventAttendeeModel) => {
        return !!(
          invite.userId === get(this.current.user, 'id') && invite.hasInterview
        );
      });
  }

  @action
  leaveFeedback(interviewKit: InterviewKitModel) {
    const feedBackArgs = {
      interviewKitId: get(interviewKit, 'id'),
      jobId: get(this.job, 'id'),
    };

    this.router.transitionTo({
      queryParams: {
        tab: 'interview-feed',
        interviewFeedQuery: JSON.stringify(feedBackArgs),
      },
    });
  }

  @action
  handleDeleteAnswer(answer: AnswerModel) {
    answer.destroyRecord();
  }
}
