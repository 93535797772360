/* import __COLOCATED_TEMPLATE__ from './filtered-error.hbs'; */
import Component from '@glimmer/component';

import {
  findProblemByPath,
  IGraphQLErrorProblem,
} from 'teamtailor/utils/meetings/error-handling';

interface IMeetingArgs {
  warnings: IGraphQLErrorProblem[];
  allProblems: IGraphQLErrorProblem[];
  pathFilter: string;
}

export default class MeetingFilteredError extends Component<IMeetingArgs> {
  get problem(): IGraphQLErrorProblem | undefined {
    return findProblemByPath(this.args.allProblems, this.args.pathFilter);
  }

  get warning(): IGraphQLErrorProblem | undefined {
    return findProblemByPath(this.args.warnings, this.args.pathFilter);
  }

  get hasProblem(): boolean {
    return !!this.problem;
  }

  get hasWarning(): boolean {
    return !!this.warning;
  }

  get hasIssue(): boolean {
    return this.hasProblem || this.hasWarning;
  }

  get explanation(): string {
    return this.problem?.explanation || this.warning?.explanation || '';
  }
}
