import Service, { inject as service } from '@ember/service';
import Server from './server';
import IntlService from 'ember-intl/services/intl';
import { enqueueTask } from 'ember-concurrency';
import MomentService from 'ember-moment/service/moment';
import { macroCondition, isTesting, isDevelopingApp } from '@embroider/macros';
import ENV from 'teamtailor/config/environment';

type LocaleKeys =
  | 'ar'
  | 'az'
  | 'da'
  | 'de'
  | 'el'
  | 'en'
  | 'en-GB'
  | 'es'
  | 'et'
  | 'fi'
  | 'fr'
  | 'fr-CA'
  | 'he'
  | 'hu'
  | 'it'
  | 'lt'
  | 'lv'
  | 'nl'
  | 'no'
  | 'nn'
  | 'pl'
  | 'pt'
  | 'pt-BR'
  | 'ro'
  | 'sk'
  | 'sv'
  | 'cs'
  | 'zh-HK'
  | 'zh-TW';

export default class TranslationsService extends Service {
  @service declare server: Server;
  @service declare intl: IntlService;
  @service declare moment: MomentService;

  async setLocale(locale: LocaleKeys) {
    if (locale === this.intl.primaryLocale) {
      return;
    }

    return this.setLocaleTask.perform(locale);
  }

  setLocaleTask = enqueueTask(async (locale: LocaleKeys) => {
    let filePath;
    if (macroCondition(isTesting())) {
      filePath = `/translations/${locale.toLowerCase()}.json`;
    } else if (macroCondition(isDevelopingApp())) {
      filePath = `${ENV.EMBER_ASSETS_ROOT_PATH}/translations/${locale.toLowerCase()}.json`;
    } else {
      /*
        This allows webpack to discover the translation files and add fingerprints to them.
      */
      filePath = (await import(`/translations/${locale.toLowerCase()}.json`))
        .default;
    }

    const translations = await this.server
      .fetch(filePath, {
        credentials: 'omit',
      })
      .then((res) => res.json());

    const momentLocale = locale === 'no' ? 'nb' : locale;
    this.moment.setLocale(momentLocale);

    this.intl.locale = locale;
    this.intl.addTranslations(locale, translations);
  });
}

declare module '@ember/service' {
  interface Registry {
    translations: TranslationsService;
  }
}
