/* import __COLOCATED_TEMPLATE__ from './requisition-archive-reason.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { CompanyModel } from 'teamtailor/models';

export default class extends Component {
  @service declare current: Current;

  get company(): CompanyModel {
    return this.current.company;
  }

  get requisitionArchiveReasons() {
    return this.company.sortedRequisitionArchiveReasons;
  }
}
