/* import __COLOCATED_TEMPLATE__ from './activities.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import activityKeys from 'teamtailor/components/insights/charts/activity-keys';
import { get } from 'teamtailor/utils/get';
import colors, {
  activityTypeColors,
  bgActivityTypeColorClasses,
  bgColorClasses,
} from 'teamtailor/components/insights/charts/colors';

export default class InsightsMoleculesActivities extends Component {
  @service intl;

  get filteredActivityKeys() {
    if (this.args.hideReviews) {
      return activityKeys.filter(({ name }) => name !== 'reviews');
    }

    return activityKeys;
  }

  get colors() {
    return this.filteredActivityKeys.map(
      ({ name, colorIndex }) => activityTypeColors[name] || colors[colorIndex]
    );
  }

  get colorClasses() {
    return this.filteredActivityKeys.map(
      ({ name, colorIndex }) =>
        bgActivityTypeColorClasses[name] || bgColorClasses[colorIndex]
    );
  }

  get chartCategories() {
    return [...this.filteredActivityKeys].map(({ name }) =>
      this.intl.t(`insights.molecules.activities.${name}`)
    );
  }

  get chartData() {
    const topUserActivities =
      this.args.activitiesByUser?.slice().sort((a, b) => b.total - a.total) ??
      [];
    return topUserActivities
      .map((userActivities) => {
        return {
          name:
            get(userActivities, 'nameOrEmail') ||
            this.intl.t('common.deleted_user'),

          value: this.filteredActivityKeys.map(
            ({ name }) => userActivities[name]
          ),

          colors: this.colors,
        };
      })
      .slice(0, 5);
  }
}
