/* import __COLOCATED_TEMPLATE__ from './controls.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface VideoMeetingControlsArgs {
  interviewLayout: boolean;
  internal: boolean;
  visible: boolean;
}

export default class VideoMeetingControls extends Component<VideoMeetingControlsArgs> {
  settingsApi?: any;

  get destinationElement() {
    return document.getElementById('ember-teamtailor-dropdown');
  }

  @action
  registerSettingsMenu(api: any): void {
    this.settingsApi = api;
  }

  @action
  onVisibleChange(): void {
    if (!this.args.visible) {
      this.settingsApi?.close();
    }
  }
}
