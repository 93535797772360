/* import __COLOCATED_TEMPLATE__ from './meeting-row.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service('timeFormat') timeFormat;

  get meetingEvent() {
    return this.args.meetingEvent;
  }

  @action
  handleDidInsert(element) {
    this.rowElement = element;
  }
}
