/* import __COLOCATED_TEMPLATE__ from './location-form.hbs'; */
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import disableDefaultTemplate from 'teamtailor/utils/disable-default-template';
import Store from '@ember-data/store';
import LocationModel from 'teamtailor/models/location';
import { trackedFunction } from 'ember-resources/util/function';
import FlipperService from 'teamtailor/services/flipper';
import { modifier } from 'ember-modifier';
import Server from 'teamtailor/services/server';
import { task } from 'ember-concurrency';

const REQUIRED_PROPS = ['name'];
export default class LocationFormComponent extends TranslatableFormBaseComponent<LocationModel> {
  @service declare store: Store;
  @service declare flipper: FlipperService;
  @service declare server: Server;

  theme = 'default';
  translationsProps = REQUIRED_PROPS;

  @tracked showCityInput = false;
  @tracked locationsCount = 0;

  get location() {
    return this.translatableModel as unknown as LocationModel;
  }

  get model() {
    return this.location;
  }

  region = trackedFunction(this, async () => {
    const region = await get(this.location, 'region');
    return region;
  });

  // Disable the headquarters checkbox if company has no or one location
  get hasCompanyLessThanOneLocation(): boolean {
    return this.locationsCount < 2;
  }

  fetchLocationsCount = task(async () => {
    const response = (await this.server.collectionAction({
      modelName: 'location',
      action: 'count',
      method: 'GET',
    })) as {
      total: number;
    };

    this.locationsCount = response.total;
  });

  get isHqCheckboxDisabled(): boolean {
    return disableDefaultTemplate(this.model, 'headquarters');
  }

  get fullErrorMessages() {
    if (!this.showCityInput) {
      return get(this.location, 'fullErrorMessages').filter(
        (e: { attribute: string }) => !e.attribute.includes('city')
      );
    }

    return get(this.location, 'fullErrorMessages');
  }

  initHeadquartersCheckbox = modifier(async () => {
    const hasCompanyHQ = await get(this.company, 'headquarters');

    await this.fetchLocationsCount.perform();

    // If it exists for the company, one location has to be the headquarters as the default
    if (this.locationsCount && !hasCompanyHQ) {
      this.handleToggleHeadquartersCheckbox(true);
    }
  });

  @action
  handleToggleHeadquartersCheckbox(value?: boolean): void {
    set(this.location, 'headquarters', Boolean(value));
  }

  @action
  checkCityPresence(): void {
    if (!this.location.city) set(this, 'showCityInput', true);
  }

  @action
  createRegion(name: string) {
    const region = this.store.createRecord('region', {
      name,
    });
    get(this.company, 'regions').pushObject(region);
    set(this.location, 'region', region);
    region.save();
  }
}
