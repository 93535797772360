/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { v1 as uuid } from 'ember-uuid';
import { inject as service } from '@ember/service';
import { timeout, restartableTask } from 'ember-concurrency';
import { OTHER_OPTION, SCALE_LABEL_TYPES } from 'teamtailor/utils/form-builder';

export const TYPE_ICONS = {
  text: 'font',
  textarea: 'paragraph',
  number: 'hashtag',
  date: 'calendar',
  checkbox: 'square-check',
  radio: 'circle-dot',
  select: 'circle-chevron-down',
  scale: 'arrow-up-to-dotted-line',
  file: 'file-arrow-up',
};

const TYPE_VALUE_FIELDS = {
  text: 'textValue',
  textarea: 'textValue',
  number: 'numericValue',
  date: 'dateValue',
  checkbox: 'arrayValue',
  radio: 'textValue',
  file: 'fileValue',
};

export default class FormBuilderField extends Component {
  @service intl;
  @service ttAlert;

  typeIcons = TYPE_ICONS;

  @tracked
  required = this.args.field.required;

  @tracked
  options = this.args.field.options;

  @tracked
  allowMultipleAnswers = this.args.field.allow_multiple_answers;

  @tracked
  showAdditionalOptions = false;

  @tracked
  hasConditional = !!this.args.field.show_if;

  @tracked
  conditionalFieldUuid = this.args.field.show_if?.ref_uuid;

  @tracked
  conditionalFieldValue = this.args.field.show_if?.value;

  @tracked answer = this.fieldAnswer;

  @tracked _label;

  @tracked maximumValue = this.args.field.maximum_value;

  @tracked beginningLabel = this.args.field.beginning_label;

  @tracked endLabel = this.args.field.end_label;

  @tracked showDeleteModal = false;

  @tracked description = this.args.field.description;

  get label() {
    const { label } = this.args.field;
    if (this.args.field.unmodifiable || this.args.field.labelUnmodifiable) {
      return this.intl.t(`form_builder.${label}`);
    } else {
      return this._label ?? label;
    }
  }

  set label(value) {
    set(this, '_label', value);
  }

  get conditionalFieldOptions() {
    if (this.args.disableConditionalFields) return 0;

    const { allFields = [], field } = this.args;

    return allFields
      .filter((f) => !!f.options?.length)
      .filter((f) => f.uuid !== field.uuid && f.type !== 'checkbox');
  }

  get conditionalField() {
    if (!this.conditionalFieldUuid) return null;

    const field = this.args.allFields.find(
      (f) => f.uuid === this.conditionalFieldUuid
    );

    return field;
  }

  get conditionalFieldValueOptions() {
    return this.conditionalField?.options;
  }

  get field() {
    return {
      ...this.args.field,
      options: this.options,
      label: this.label || this.intl.t('common.untitled'),
      description: this.description,
      required: this.required,
    };
  }

  get isModifiable() {
    return !this.args.field.unmodifiable;
  }

  get showRequired() {
    return !this.args.disableRequired;
  }

  get fieldAnswer() {
    if (this.args.allowAnswers) {
      const answerObj = this.args.field?.answers?.[0];
      const valueField =
        this.args.field.type === 'date'
          ? 'formattedValue'
          : TYPE_VALUE_FIELDS[this.args.field.type];
      return answerObj?.[valueField];
    }

    return null;
  }

  get selectedConditionalFieldItem() {
    return this.conditionalFieldOptions?.find(
      (c) => c.uuid === this.conditionalFieldUuid
    );
  }

  get selectedConditionalFieldValueItem() {
    return this.conditionalFieldValueOptions?.find(
      (c) => c.id === this.conditionalFieldValue
    );
  }

  removeEmptyFieldOptions() {
    const { field, onUpdate } = this.args;

    if (!this.options) return;

    const optionsToKeep = this.options.filter((o) => o.label !== '');

    if (optionsToKeep.length !== this.options.length) {
      this.options = optionsToKeep;

      onUpdate({
        ...field,
        options: optionsToKeep,
      });
    }
  }

  @action
  focusInput(el) {
    setTimeout(() => el.focus(), 0);
  }

  @action
  onSelect() {
    this.checkForMissingReference();
    if (this.args.onSelect) {
      this.args.onSelect();
    }
  }

  @action
  handleDeleteModal() {
    this.showDeleteModal = !this.showDeleteModal;
  }

  @action
  updateLabel(e) {
    this.label = e.target.value;
    this.commitStateUpdateDebounced.perform();
  }

  @action
  updateDescription(e) {
    this.description = e.target.value;
    this.commitStateUpdateDebounced.perform();
  }

  @action
  updateValue(key, value) {
    this[key] = value.uuid || value.id || value;

    if (key === 'conditionalFieldId' || key === 'conditionalFieldUuid') {
      // Default option value needs to be set as well
      const values = this.conditionalFieldValueOptions;
      const containsObjects = values.some((f) => !!f.id);

      this.conditionalFieldValue = containsObjects ? values[0].id : values[0];
    }

    this.commitStateUpdateDebounced.perform();
  }

  @action
  onUpdateAnswer(value) {
    if (this.args.allowAnswers) {
      this.answer = value;
      const { field } = this.args;
      const valueField = TYPE_VALUE_FIELDS[field.type];
      if (field.answers && field.answers[0]) {
        field.answers[0][valueField] = value;
      } else {
        field.answers = [
          {
            [valueField]: value,
          },
        ];
      }

      this.args.onUpdate(field);
    }
  }

  @action onToggleRequired() {
    this.required = !this.required;
    this.commitStateUpdate();
  }

  @action onToggleAllowMultipleAnswers() {
    this.allowMultipleAnswers = !this.allowMultipleAnswers;
    this.commitStateUpdate();
  }

  @action
  onAddOption(value = '') {
    const { type } = this.args.field;

    switch (type) {
      case 'radio':
      case 'checkbox':
      case 'select': {
        const options = [
          ...this.options,
          {
            id: value === OTHER_OPTION ? OTHER_OPTION : uuid(),
            label:
              value === OTHER_OPTION
                ? this.intl.t('settings.requisitions.custom_form.other')
                : value,
          },
        ];

        this.options = [
          ...options.filter((o) => o.id !== OTHER_OPTION),
          ...(options.map((o) => o.id).includes(OTHER_OPTION)
            ? [
                {
                  id: OTHER_OPTION,
                  label: this.intl.t('settings.requisitions.custom_form.other'),
                },
              ]
            : []),
        ];

        break;
      }

      default:
        throw `Unhandled onAddOption type ${type}`;
    }

    this.commitStateUpdate();
  }

  @action
  onUpdateOption(optionIndex, label) {
    const { type } = this.args.field;

    switch (type) {
      case 'radio':
      case 'checkbox':
      case 'select': {
        let { options } = this;

        const optionId = options[optionIndex].id;

        options[optionIndex] = {
          id: optionId || uuid(),
          label,
        };

        this.options = options;
        break;
      }

      default:
        throw `Unhandled onUpdateOption type ${type}`;
    }

    this.commitStateUpdate();
  }

  @action
  onRemoveOption(optionIndex) {
    let { options } = this;
    const option = options[optionIndex];

    let refresh = false;
    if (this.args.allFields) {
      this.args.allFields.forEach((field) => {
        if (!!field.show_if && field.show_if.value === option.id) {
          delete field.show_if;
          refresh = true;
        }
      });
    }

    this.options = options.filter((option, index) => index !== optionIndex);
    this.commitStateUpdate(refresh);
  }

  @action
  onToggleOptions() {
    this.showAdditionalOptions = !this.showAdditionalOptions;
  }

  @action
  onScaleLabelChange(label, { target }) {
    const { value } = target;

    if (label === SCALE_LABEL_TYPES.START) {
      this.beginningLabel = value;
    } else if (label === SCALE_LABEL_TYPES.END) {
      this.endLabel = value;
    }

    this.commitStateUpdate();
  }

  @action
  onScaleChange({ value }) {
    this.maximumValue = value;
    this.commitStateUpdate(true);
  }

  @action
  toggleConditional() {
    this.hasConditional = !this.hasConditional;
    this.showAdditionalOptions = false;

    if (this.hasConditional) {
      const refFields = this.conditionalFieldOptions;
      const [defaultRefField] = refFields;

      if (!defaultRefField) return;

      this.conditionalFieldUuid = defaultRefField.uuid;
      const isSelect = ['select', 'radio'].includes(defaultRefField.type);

      const values = this.conditionalFieldValueOptions;
      this.conditionalFieldValue = isSelect ? values[0].id : values[0];

      this.required = false;
    }

    this.commitStateUpdate();
  }

  commitStateUpdateDebounced = restartableTask(async () => {
    await timeout(250);

    this.commitStateUpdate();
  });

  @action
  commitStateUpdate(refreshFields) {
    const nonRequiredKeys = [
      'allow_multiple_answers',
      'show_if',
      'options',
      'required',
      'maximum_value',
      'beginning_label',
      'end_label',
      'description',
    ];

    const orig = Object.keys(this.args.field)
      .filter((key) => !nonRequiredKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.args.field[key];
        return obj;
      }, {});

    let field = {
      ...orig,
      label: this.label,
      ...(this.description && { description: this.description }),
      ...(this.required !== undefined && { required: this.required }),
      ...(this.options && { options: this.options }),
      ...(this.allowMultipleAnswers !== undefined && {
        allow_multiple_answers: this.allowMultipleAnswers,
      }),

      ...(this.hasConditional && {
        show_if: {
          ref_uuid: this.conditionalFieldUuid,
          value: this.conditionalFieldValue,
        },
      }),

      ...(this.beginningLabel && { beginning_label: this.beginningLabel }),
      ...(this.endLabel && { end_label: this.endLabel }),
      ...(this.maximumValue && { maximum_value: this.maximumValue }),
    };

    this.args.onUpdate(field, refreshFields);
  }

  @action
  noop() {}

  @action
  onClickOutside(target) {
    this.removeEmptyFieldOptions();
    this.showDeleteModal = false;

    if (this.args.onClickOutside) {
      this.args.onClickOutside(target);
    }
  }

  @action
  checkForMissingReference() {
    if (!!this.args.field.show_if && !this.selectedConditionalFieldValueItem) {
      delete this.args.field.show_if;
      this.hasConditional = false;
      this.commitStateUpdate(true);
    }
  }
}
