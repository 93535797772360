/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { AllModels } from 'teamtailor/models';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import {
  AllowedSizes,
  ALLOWED_SIZES,
  DEFAULT_SIZE,
} from 'teamtailor/constants/form';

type Args = {
  model: AllModels;
  size?: AllowedSizes;
};

export default class FormComponent extends Component<Args> {
  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Form @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get model(): AllModels {
    return this.args.model;
  }

  @action
  defaultHandleSave() {
    this.model.save();
  }
}
