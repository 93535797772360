import Controller from '@ember/controller';
import { TableColumn } from 'teamtailor/components/table/container';

const TEAM_COLUMNS: TableColumn[] = [
  {
    label: 'settings.teams.name',
  },
  {
    label: 'settings.teams.candidate_access',
  },
  {
    label: 'settings.teams.members',
  },
];

export default class IndexController extends Controller {
  columns = TEAM_COLUMNS;
}

declare module '@ember/controller' {
  interface Registry {
    'settings.teams.index': IndexController;
  }
}
