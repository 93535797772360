/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import { argDefault } from 'teamtailor/utils/arg-default';
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import {
  today,
  tomorrow,
  yesterday,
  nextWeek,
  isSameDay,
} from 'teamtailor/utils/due-date';

export default class extends Component {
  @argDefault minDate = today;

  get dateFormat() {
    return this.args.displayYear ? 'D MMM YYYY' : 'D MMM';
  }

  get today() {
    return today;
  }

  get isDueToday() {
    return isSameDay(this.args.due, today);
  }

  get isDueTomorrow() {
    return isSameDay(this.args.due, tomorrow);
  }

  get isDueWithinAWeek() {
    return (
      this.args.due?.getTime() < nextWeek.getTime() &&
      this.args.due?.getTime() > tomorrow.getTime()
    );
  }

  get isDueYesterday() {
    return isSameDay(this.args.due, yesterday);
  }

  get dueWeekDay() {
    return moment.weekdays(this.args.due?.getDay());
  }
}
