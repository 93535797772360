/* import __COLOCATED_TEMPLATE__ from './checkout-summary.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { isEmpty } from '@ember/utils';
import ChannelModel from 'teamtailor/models/channel';
import IntlService from 'ember-intl/services/intl';
import FlipperService from 'teamtailor/services/flipper';
import { action } from '@ember/object';

interface CheckoutSummaryArgs {
  price?: number;
  vatPercentage: number;
  type?: 'sms' | 'promotion';
  channel?: ChannelModel;
  productName?: string;
  isDisabled?: boolean;
  onRegister?: (checkoutData: {
    isVatTypeEmpty: boolean;
    type: string;
    freeOrAgreedWithPartner: boolean;
    chargedByPartner: boolean;
    paymentButtonLabel: string | null;
    isDisabled?: boolean;
  }) => void;
}

export default class CheckoutSummary extends Component<CheckoutSummaryArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  type = this.args.type ?? 'sms';

  get paymentByCard() {
    return (
      get(this.flipper, 'purchase_promotion_by_credit_card') ||
      get(this.current.company, 'validCreditCardPayment')
    );
  }

  get vatAmount() {
    if (this.args.price) {
      return (this.args.price * this.args.vatPercentage) / 100;
    }

    return null;
  }

  get totalAmount() {
    if (this.args.price) {
      return this.vatAmount
        ? this.args.price + this.vatAmount
        : this.args.price;
    }

    return null;
  }

  get paymentProcessorSetting() {
    return get(this.current.company, 'paymentProcessorSetting');
  }

  get isVatTypeEmpty() {
    if (this.current.company.belongsTo('paymentProcessorSetting').id()) {
      return isEmpty(get(this.paymentProcessorSetting, 'vatType'));
    }

    return true;
  }

  get isFreeChannel() {
    if (this.args.channel) {
      return get(this.args.channel, 'isFree');
    }

    return false;
  }

  get isAgreedWithPartnerChannel() {
    if (this.args.channel) {
      return (
        get(this.args.channel, 'allowedPaymentTypes') === 'partner' ||
        (['partner_or_teamtailor', 'charged_by_partner'].includes(
          get(this.args.channel, 'allowedPaymentTypes')
        ) &&
          !!get(this.args.channel, 'channelActivation')?.existingAgreement)
      );
    }

    return false;
  }

  get freeOrAgreedWithPartner() {
    return this.isFreeChannel || this.isAgreedWithPartnerChannel;
  }

  get isNotFreeOrAgreedWithPartner() {
    return !this.freeOrAgreedWithPartner;
  }

  get chargedByPartner() {
    if (this.args.channel) {
      return get(this.args.channel, 'chargedByPartner');
    }

    return false;
  }

  get productName() {
    if (this.args.productName) {
      return this.args.productName;
    } else if (this.args.channel) {
      return get(this.args.channel, 'name');
    }

    return '';
  }

  get paymentButtonLabel() {
    if (this.freeOrAgreedWithPartner) {
      return this.intl.t('components.payment_summary.publish');
    } else {
      return null;
    }
  }

  @action
  onRegister() {
    const parentThis = this;
    this.args.onRegister?.({
      get isVatTypeEmpty() {
        return parentThis.isVatTypeEmpty;
      },

      get type() {
        return parentThis.type;
      },

      get freeOrAgreedWithPartner() {
        return parentThis.freeOrAgreedWithPartner;
      },

      get chargedByPartner() {
        return parentThis.chargedByPartner;
      },

      get paymentButtonLabel() {
        return parentThis.paymentButtonLabel;
      },

      get isDisabled() {
        return parentThis.args.isDisabled;
      },
    });
  }
}
