/* import __COLOCATED_TEMPLATE__ from './reactions-list.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import ActivityModel from 'teamtailor/models/activity';
import NoteModel from 'teamtailor/models/note';
import { action } from '@ember/object';
import toggleReaction from 'teamtailor/utils/toggle-reaction';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';

interface ReactionsListComponentArgs {
  model: NoteModel | ActivityModel;
}

export default class ReactionsListComponent extends Component<ReactionsListComponentArgs> {
  @service declare current: Current;

  get reactions() {
    return get(this.args.model, 'reactions');
  }

  get sortedReactions() {
    return this.reactions.sortBy('createdAt');
  }

  get user() {
    return this.current.user;
  }

  @action
  handleToggleReaction(emoji: string) {
    toggleReaction(this.args.model, this.user, emoji);
  }
}
