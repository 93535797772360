/* import __COLOCATED_TEMPLATE__ from './datetime-picker.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { arrow, computePosition, flip, offset } from '@floating-ui/dom';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class DatetimePicker extends Component {
  @service intl;
  @service timeFormat;

  flatpickrRef;
  today = moment()
    .startOf('day')
    .format(`YYYY-MM-DD ${get(this, 'timeFormat.format')}`);

  @argDefault defaultHour = '09';
  @argDefault defaultMinute = '00';
  @argDefault returnType = 'date';
  @argDefault returnFormat = `YYYY-MM-DD ${get(this, 'timeFormat.format')}`;

  @tracked hasError = false;

  get enableTime() {
    if (this.args.enableTime !== undefined) {
      return this.args.enableTime;
    } else {
      return true;
    }
  }

  get value() {
    const value = this.args.value || null;
    return this.args.range ? [value, value] : value;
  }

  get show24HsCalendar() {
    if (this.timeFormat.formatForEmberFlatpickr === 'H:i') {
      return true;
    }

    return false;
  }

  get format() {
    let format = this.args.dateFormat || 'Y-m-d';

    if (this.enableTime) {
      format += ` ${this.timeFormat.formatForEmberFlatpickr}`;
    }

    return format;
  }

  get minDate() {
    if (this.args.onlyFuture) {
      return this.today;
    } else {
      return this.args.minDate ? this.args.minDate : null;
    }
  }

  get classes() {
    let classes = this.args.inputClass;
    if (this.args.displayCalendarIcon) {
      classes += ' pl-36';
    }

    return classes;
  }

  get daysWithEvents() {
    if (this.args.daysWithEvents?.length > 0) {
      return this.args.daysWithEvents.map((dayStr) =>
        moment(dayStr).format('L').toString()
      );
    } else {
      return [];
    }
  }

  get userLocaleData() {
    return moment.localeData(this.timeFormat.locale);
  }

  get locale() {
    const localeObj = this.timeFormat.flatPickrLocaleData;

    if (this.args.displayShortWeekdays) {
      localeObj.weekdays.shorthand = this.userLocaleData
        .weekdaysMin()
        .map((d) => d[0]);
    }

    return localeObj;
  }

  get flatpickrButtonsContainer() {
    return document.querySelector('.flatpickr-time__buttons-container');
  }

  get destinationElement() {
    return typeof this.args.destinationElement === 'string'
      ? document.getElementById(this.args.destinationElement)
      : this.args.destinationElement;
  }

  @tracked appendBlock = undefined;
  @tracked hasEvaluateAppend = false;

  resizeSelectedMonthWidth(flatPickrInstance) {
    if (this.args.size === 'large') {
      const flatpickr = this.flatpickrRef || flatPickrInstance;
      if (flatpickr) {
        const monthLength =
          this.userLocaleData.months()[flatpickr.currentMonth].length;
        flatpickr.monthsDropdownContainer.style.minWidth = '64px';
        flatpickr.monthsDropdownContainer.style.width = `${monthLength * 16}px`;
      }
    }
  }

  @action onChange(value) {
    if (this.args.onChange) {
      if (this.args.useUTC) {
        if (Array.isArray(value)) {
          value = value.map((date) => moment(date).utc(true).toDate());
        } else {
          value = moment(value).utc(true).toDate();
        }
      }

      value = Array.isArray(value) && !this.args.range ? value[0] : value;
      if (this.returnType === 'moment') {
        value = Array.isArray(value)
          ? value.map((date) => moment(date).format(this.returnFormat))
          : moment(value).format(this.returnFormat);
      }

      this.args.onChange(value);
    }
  }

  @action
  onValueUpdate() {
    if (this.args.onlyFuture) {
      const selectedTime = this.flatpickrRef.selectedDates[0];
      const currentTime = new Date();

      this.hasError = selectedTime < currentTime;
    }
  }

  @action
  onDayCreate(dObj, dStr, fp, dayElem) {
    if (this.daysWithEvents.length > 0) {
      const day = moment(dayElem.ariaLabel).format('L').toString();
      if (
        this.daysWithEvents.includes(day) &&
        !dayElem.classList.contains('selected')
      ) {
        dayElem.innerHTML += "<span class='event'></span>";
      }
    }

    dayElem.innerHTML = `<span class="innerBox">${dayElem.innerHTML}</span>`;
  }

  @action
  onMonthChange() {
    this.resizeSelectedMonthWidth();
  }

  @action
  didInsertAppendBlock(element) {
    this.appendBlock = element;
  }

  @action
  async handlePosition(flatPickrInstance) {
    if (this.args.inline && !flatPickrInstance.config.positionElement) {
      return;
    }

    const referenceElement =
      flatPickrInstance.config.positionElement || flatPickrInstance.element;

    if (!this.arrowElement) {
      this.arrowElement = document.createElement('div');
      this.arrowElement.classList.add('datetime-picker__arrow');
      flatPickrInstance.calendarContainer.appendChild(this.arrowElement);
    }

    const {
      x,
      y,
      placement,
      middlewareData: {
        arrow: { x: arrowX, y: arrowY },
      },
    } = await computePosition(
      referenceElement,
      flatPickrInstance.calendarContainer,
      {
        strategy: 'fixed',
        middleware: [
          flip({
            fallbackPlacements: ['top', 'left'],
          }),
          offset(10),
          arrow({
            element: this.arrowElement,
          }),
        ],
      }
    );

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]];

    this.arrowElement.style.position = 'absolute';
    this.arrowElement.style.left = arrowX != null ? `${arrowX}px` : '';
    this.arrowElement.style.top = arrowY != null ? `${arrowY}px` : '';
    this.arrowElement.style.right = '';
    this.arrowElement.style.bottom = '';
    this.arrowElement.style[staticSide] = '-12px';

    flatPickrInstance.calendarContainer.dataset.floatingUiPlacement = placement;

    flatPickrInstance.calendarContainer.style.position = 'fixed';
    flatPickrInstance.calendarContainer.style.left = `${x}px`;
    flatPickrInstance.calendarContainer.style.top = `${y}px`;
  }

  @action
  onCustomDateClose(event) {
    event.stopPropagation();
    this.flatpickrRef.close();
    if (this.args.onClose) {
      this.args.onClose();
    }
  }

  @action
  onCustomDateSubmit(event) {
    event.stopPropagation();
    this.flatpickrRef.close();
    if (this.args.onConfirm) {
      this.args.onConfirm(
        this.args.range
          ? this.flatpickrRef.selectedDates
          : this.flatpickrRef.selectedDates[0]
      );
    }
  }

  @action
  didInsertDatetimePicker(date, str, flatPickrInstance) {
    this.flatpickrRef = flatPickrInstance;

    if (this.destinationElement) {
      const calendar = this.flatpickrRef.calendarContainer;
      this.destinationElement.append(calendar);
      this.handlePosition(flatPickrInstance);
    }

    if (flatPickrInstance.config.positionElement) {
      this.handlePosition(flatPickrInstance);
    }

    if (this.args.autoFocus) {
      flatPickrInstance.open();
    }

    if (this.appendBlock) {
      let flatpickrTime = document.querySelector('.flatpickr-calendar');
      flatpickrTime.appendChild(this.flatpickrButtonsContainer);
    }

    this.resizeSelectedMonthWidth(flatPickrInstance);
    this.args.onReady?.(flatPickrInstance);
  }

  @action
  didInsertLastElement() {
    this.hasEvaluateAppend = true;
  }

  @action
  openCalendar() {
    this.flatpickrRef.open();
  }

  @action
  onToggle(isOpen) {
    this.args.onToggle?.(isOpen);
    if (isOpen) {
      if (this.args.verticalPosition) {
        this.handlePosition(this.flatpickrRef);
      }

      this.args.onOpen?.();
    } else {
      this.args.onClose?.();
    }
  }
}
