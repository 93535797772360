/* import __COLOCATED_TEMPLATE__ from './save-report-modal.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import CustomReportModel from 'teamtailor/models/custom-report';
import { action, set } from '@ember/object';

interface CustomReportModalArgs {
  model: CustomReportModel;
  onSubmit: () => void;
}

export default class extends Component<CustomReportModalArgs> {
  @tracked isOpen = false;

  @tracked emoji = '⭐️';

  @action handleNameInput(event: Event) {
    const target = event.target as HTMLInputElement;
    set(this.args.model, 'name', target.value);
  }

  @action
  setStartingEmoji() {
    this.emoji = this.args.model.emoji || '⭐️';
  }

  handleSubmit = dropTask(async () => {
    this.args.model.emoji = this.emoji;
    await this.args.onSubmit();
    this.isOpen = false;
  });
}
