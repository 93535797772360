import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Modifier, { NamedArgs } from 'ember-modifier';
import {
  createFocusTrap,
  FocusTargetValue,
  FocusTrap,
  Options,
} from 'focus-trap';
import FocusTrapService from 'teamtailor/services/focus-trap';

interface FocusTrapModifierSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      focusTrapOptions?: Options;
      isActive?: boolean;
      isPaused?: boolean;
      shouldSelfFocus?: boolean;
      additionalElements?: HTMLElement[];
    };
  };
}

export default class FocusTrapModifier extends Modifier<FocusTrapModifierSignature> {
  @service declare focusTrap: FocusTrapService;

  @tracked declare el: HTMLElement;
  @tracked declare focusTrapInstance: FocusTrap;
  @tracked focusTrapOptions: Options = {};
  @tracked isActive = true;
  @tracked isPaused = false;

  async modify(
    element: HTMLElement,
    _: [],
    {
      focusTrapOptions,
      isActive,
      isPaused,
      shouldSelfFocus,
      additionalElements,
    }: NamedArgs<FocusTrapModifierSignature>
  ) {
    this.el = element;

    this.focusTrapOptions = focusTrapOptions ? { ...focusTrapOptions } : {};
    if (typeof isActive !== 'undefined') {
      this.isActive = isActive;
    }

    if (typeof isPaused !== 'undefined') {
      this.isPaused = isPaused;
    }

    if (
      typeof this.focusTrapOptions.initialFocus === 'undefined' &&
      shouldSelfFocus
    ) {
      this.focusTrapOptions.initialFocus = element;
    }

    if (this.focusTrapOptions.returnFocusOnDeactivate !== false) {
      this.focusTrapOptions.returnFocusOnDeactivate = true;
    }

    const returnFocusElement = focusTrapOptions?.setReturnFocus;

    this.focusTrapOptions.setReturnFocus = (previousActiveElement) => {
      // eslint-disable-next-line ember/use-ember-get-and-set
      const returnElement = this.focusTrap.focusTrapElements.get(
        this.focusTrapInstance
      );
      if (returnElement && (returnElement as HTMLElement).isConnected) {
        return returnElement;
      } else if (
        previousActiveElement.isConnected &&
        returnFocusElement !== false
      ) {
        return previousActiveElement;
      }

      return false;
    };

    this.focusTrapOptions.onPostDeactivate = () => {
      this.focusTrap.unregister(this.focusTrapInstance);
    };

    this.focusTrapInstance = createFocusTrap(
      typeof additionalElements !== 'undefined'
        ? [element, ...additionalElements]
        : element,
      { trapStack: this.focusTrap.stack, ...this.focusTrapOptions }
    );

    if (returnFocusElement) {
      this.focusTrap.register(
        this.focusTrapInstance,
        returnFocusElement as FocusTargetValue
      );
    }

    if (this.isActive) {
      this.focusTrapInstance.activate();
    }

    if (this.isPaused) {
      this.focusTrapInstance.pause();
    }

    registerDestructor(this, this.cleanup.bind(this));
  }

  updateReturnFocusIfRemovedInStack() {
    /* eslint-disable ember/use-ember-get-and-set */
    const lastTrap = this.focusTrap.stack.at(-1);
    if (
      this.focusTrap.stack.includes(this.focusTrapInstance) &&
      lastTrap !== this.focusTrapInstance
    ) {
      const currentTrapIndex = this.focusTrap.stack.indexOf(
        this.focusTrapInstance
      );
      const currentReturnFocusElement = this.focusTrap.focusTrapElements.get(
        this.focusTrapInstance
      );
      const nextTrap = this.focusTrap.stack.at(currentTrapIndex + 1);
      if (currentReturnFocusElement && nextTrap) {
        this.focusTrap.focusTrapElements.set(
          nextTrap,
          currentReturnFocusElement
        );
      }

      this.focusTrap.unregister(this.focusTrapInstance);
    }
    /* eslint-enable ember/use-ember-get-and-set */
  }

  cleanup() {
    this.updateReturnFocusIfRemovedInStack();
    this.focusTrapInstance.deactivate();
  }
}
