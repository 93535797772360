/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { maxAvailableImagesInWorkplaceCarousel } from 'teamtailor/constants/uploads';
import { action } from '@ember/object';
import { ContentBlockPictureModel, PickedImageModel } from 'teamtailor/models';
import { AsyncBelongsTo } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';

export type WorkplaceBaseArgs = {
  model: AsyncBelongsTo<ContentBlockPictureModel>;
};

export default class WorkplaceBaseComponent extends BaseComponent<WorkplaceBaseArgs> {
  get section() {
    return this.args.model;
  }

  get numberOfSelectedImages() {
    return get(this.section, 'pickedImages').rejectBy('isDeleted', true).length;
  }

  get canAddMoreImages() {
    return this.numberOfSelectedImages < maxAvailableImagesInWorkplaceCarousel;
  }

  get currentAvailableImages() {
    return maxAvailableImagesInWorkplaceCarousel - this.numberOfSelectedImages;
  }

  @action
  async handleDropImage(image: PickedImageModel, position: number) {
    image.rowOrderPosition = position;
    await image.save();
    this.reload();
  }

  @action
  handleRemoveImage(image: PickedImageModel) {
    this.ttAlert.confirm(
      this.intl.t('content.editor.section.items.delete_confirm'),
      () => {
        image.destroyRecord().then(() => {
          this.reload();
        });
      },
      () => {},
      {
        confirmButtonText: this.intl.t('common.delete'),
      }
    );
  }

  @action
  handlePickImage(image: PickedImageModel) {
    get(this.section, 'pickedImages').createRecord({ image });
  }

  @action
  deleteNewPickedImages() {
    get(this.section, 'pickedImages')
      .filterBy('isNew', true)
      .forEach((pickedImage) => pickedImage.unloadRecord());
  }
}
