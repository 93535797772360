/* import __COLOCATED_TEMPLATE__ from './candidate-info.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  ICandidate,
  IJob,
  IJobApplication,
} from 'teamtailor/components/meeting/types';
import { CandidateAttendeeClass } from 'teamtailor/classes/meetings';
import { assert } from '@ember/debug';
import IntlService from 'ember-intl/services/intl';
import TeamtailorApolloService from 'teamtailor/services/-private/apollo';
import PreparedMessageClass from 'teamtailor/classes/prepared-message';

interface Args {
  job?: IJob;
  candidates: CandidateAttendeeClass[];
  isDisabled: boolean;
  jobApplications: IJobApplication[];
  preparedMessage: PreparedMessageClass;
}

export default class CandidateInfoComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare apollo: TeamtailorApolloService;

  get jobApplication(): IJobApplication | undefined {
    return this.args.jobApplications.find(
      (ja) => ja.job?.id === (this.args.job ? this.args.job.id : '')
    );
  }

  get firstCandidate(): ICandidate | undefined {
    assert('this.args.candidates[0] must exist', this.args.candidates[0]);

    return this.args.candidates[0].candidate;
  }

  get jobApplicationStatus(): 'rejected' | 'hired' | 'inbox' | 'inprocess' {
    if (this.jobApplication?.rejectedAt) {
      return 'rejected';
    }

    if (this.jobApplication?.stage?.hired) {
      return 'hired';
    }

    if (this.jobApplication?.stage?.inbox) {
      return 'inbox';
    }

    return 'inprocess';
  }

  get hasMultipleCandidates(): boolean {
    return this.args.candidates.length > 1;
  }

  get shouldShowJobField(): boolean {
    return (
      !!this.args.job ||
      (!this.hasMultipleCandidates && this.args.jobApplications.length > 0)
    );
  }

  get anonymous(): boolean {
    assert('this.args.candidates[0] must exist', this.args.candidates[0]);

    return this.args.candidates[0].anonymousOnJob(this.args.job?.id);
  }

  get stageType(): string {
    return this.jobApplication?.stage?.stageType?.category || '';
  }

  get isMessageDisabled(): boolean {
    return this.args.isDisabled || !this.args.preparedMessage;
  }
}
