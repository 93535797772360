/* import __COLOCATED_TEMPLATE__ from './grid.hbs'; */
import Component from '@glimmer/component';
import CandidateModel from 'teamtailor/models/candidate';
import CustomFieldModel from 'teamtailor/models/custom-field';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import CustomFieldOptionModel from 'teamtailor/models/custom-field/option';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import PickedCustomFieldModel from 'teamtailor/models/picked-custom-field';
import { isArray } from '@ember/array';

interface CustomFieldsGridArgs {
  candidate: CandidateModel;
}

export default class CustomFieldsGrid extends Component<CustomFieldsGridArgs> {
  @service declare current: Current;
  @service declare store: Store;

  @tracked showAll = false;

  get allFields() {
    return get(this.current.company, 'visibleCandidateCustomFields');
  }

  get hiddenFields() {
    return this.allFields.filter(
      (f) => !(this.pickedCustomFieldFor(f)?.hasValue || f.isFeatured)
    );
  }

  get visibleFields() {
    return this.allFields.filter(
      (f) => this.pickedCustomFieldFor(f)?.hasValue || f.isFeatured
    );
  }

  get customFields() {
    return this.showAll || this.hiddenCount === 1
      ? [
          ...this.visibleFields.sortBy('rowOrder'),
          ...this.hiddenFields.sortBy('rowOrder'),
        ]
      : this.visibleFields.sortBy('rowOrder');
  }

  get hiddenCount() {
    return get(this, 'hiddenFields').length;
  }

  pickedCustomFieldFor(field: CustomFieldModel) {
    if (this.args.candidate.isDestroying) {
      return undefined;
    }
    return this.args.candidate.pickedCustomFields.findBy(
      'customFieldId',
      field.id
    );
  }

  valueFor(field: CustomFieldModel) {
    const pickedCustomField = this.pickedCustomFieldFor(field);
    switch (field.type) {
      case 'CustomField::Text':
      case 'CustomField::Number':
      case 'CustomField::Email':
      case 'CustomField::Phone':
      case 'CustomField::Url':
      case 'CustomField::Date':
      case 'CustomField::Checkbox':
        return pickedCustomField?.value;
      case 'CustomField::Select':
        return field.options.findBy(
          'id',
          (pickedCustomField?.value as string[])[0]
        );
      case 'CustomField::MultiSelect':
        return (pickedCustomField?.value as string[]).map((o) =>
          field.options.findBy('id', o)
        );
    }
  }

  toggleValue(value: string, field?: PickedCustomFieldModel) {
    if (field?.value && isArray(field.value)) {
      return field.value.includes(value)
        ? (field.value as Array<string>).filter((v) => v !== value)
        : [...(field.value as Array<string>), value];
    } else {
      return [value];
    }
  }

  @action
  value(field: CustomFieldModel) {
    const pickedCustomField = this.pickedCustomFieldFor(field);
    return pickedCustomField?.hasValue ? this.valueFor(field) : undefined;
  }

  @action
  clickableLink(field: CustomFieldModel) {
    const pickedCustomField = this.pickedCustomFieldFor(field);
    const link = pickedCustomField?.hasValue ? this.valueFor(field) : undefined;
    if (link) {
      return (link as string).startsWith('http://') ||
        (link as string).startsWith('https://')
        ? link
        : `http://${link}`;
    }
  }

  @action
  updateValue(
    field: CustomFieldModel,
    pick:
      | string
      | number
      | CustomFieldOptionModel
      | CustomFieldOptionModel[]
      | Date
  ) {
    const pickedCustomField = this.pickedCustomFieldFor(field);
    let value: string | number | string[] | null = null;
    if (pick) {
      switch (field.type) {
        case 'CustomField::Text':
        case 'CustomField::Number':
        case 'CustomField::Email':
        case 'CustomField::Phone':
        case 'CustomField::Url':
        case 'CustomField::Checkbox':
          value = pick as string | number;
          break;
        case 'CustomField::Select':
          value = [(pick as CustomFieldOptionModel).id];
          break;
        case 'CustomField::MultiSelect':
          value = this.toggleValue(
            (pick as CustomFieldOptionModel).id,
            pickedCustomField
          );
          break;
        case 'CustomField::Date':
          value = moment(pick as Date).format('YYYY-MM-DD');
          break;
      }
    }

    if (pickedCustomField) {
      pickedCustomField.value = value;
      pickedCustomField.save();
    } else {
      const newPickedCustomField = this.store.createRecord(
        'picked-custom-field',
        {
          value,
          customField: field,
          fieldType: field.type,
          ownerType: 'Candidate',
          ownerId: get(this.args.candidate, 'id'),
          rowOrder: field.rowOrder,
        }
      );

      newPickedCustomField
        .save()
        .then(() =>
          get(this.args.candidate, 'pickedCustomFields').pushObject(
            newPickedCustomField
          )
        );
    }
  }
}
