/* import __COLOCATED_TEMPLATE__ from './autocomplete.hbs'; */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import classnames from 'teamtailor/utils/classnames';
import { equal } from '@ember/object/computed';

export default Component.extend({
  showMap: null,
  location: null,
  onChange: null,
  theme: 'default',
  isDisabled: false,

  isDarkTheme: equal('theme', 'dark'),

  defaultInputValue: computed('location.{address,city}', function () {
    return [get(this, 'location.address'), get(this, 'location.city')]
      .filter((a) => a)
      .join(', ');
  }),

  inputClasses: computed('inputClass', 'isDarkTheme', 'theme', function () {
    return classnames('tt-form-control', get(this, 'inputClass'), {
      'tt-form-dark': get(this, 'isDarkTheme'),
    });
  }),
});
