/* import __COLOCATED_TEMPLATE__ from './hero-preview.hbs'; */
import Component from '@ember/component';
import { alias, bool, mapBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { get, computed, getProperties } from '@ember/object';
import { htmlSafe } from '@ember/template';
import hexToRgb from 'teamtailor/utils/hex-to-rgb';
import imageUrl from 'teamtailor/utils/image-url';

export default Component.extend({
  current: service(),

  tagName: '',
  job: null,
  design: null,
  applyButtonText: '',
  hasCover: false,

  imageWithSetting: alias('jobDetail.imageWithSetting'),
  replyTime: alias('jobDetail.replyTime'),
  jobDetail: alias('job.jobDetail'),
  departmentName: alias('job.department.name'),
  responseTimeLabel: alias('jobDetail.responseTimeOptions.label'),
  locationNames: mapBy('job.locations', 'nameOrCity'),
  hasImage: bool('imageWithSetting.pickedImage.image'),
  globalTextColor: alias('current.company.design.primaryTextColor'),
  previewUrl: computed(
    'hasCover',
    'imageWithSetting.pickedImage.image',
    function () {
      const transformation = get(this, 'hasCover')
        ? 'hero_picture_medium'
        : 'user_picture_800';

      return imageUrl(
        get(this, 'imageWithSetting.pickedImage.image'),
        transformation
      );
    }
  ),

  responseTimeText: computed(
    'jobDetail.responseTimeOptions',
    'replyTime',
    'responseTimeLabel',
    function () {
      const replyTime = get(this, 'replyTime');
      const { options } = get(this, 'jobDetail.responseTimeOptions');
      const responseTimeLabel = get(this, 'responseTimeLabel');
      if (!Object.keys(options).includes(replyTime)) {
        return null;
      }

      return responseTimeLabel.replace('%{time}', options[replyTime]);
    }
  ),

  previewStyles: computed(
    'design.{googleFontUrl,secondaryBackgroundColor}',
    'globalTextColor',
    'hasImage',
    'imageWithSetting.{coverLayoutColor,coverLayoutOpacity,coverLayoutTextColor}',
    'hasCover',
    'previewUrl',
    function () {
      if (!get(this, 'hasCover')) {
        return htmlSafe(`color: ${get(this, 'globalTextColor')};`);
      }

      const imageWithSetting = get(this, 'imageWithSetting');
      const previewUrl = get(this, 'previewUrl');
      let { coverLayoutColor, coverLayoutOpacity, coverLayoutTextColor } =
        getProperties(
          imageWithSetting,
          'coverLayoutColor',
          'coverLayoutOpacity',
          'coverLayoutTextColor'
        );

      let styles = 'background-image:';

      if (
        coverLayoutColor &&
        (coverLayoutOpacity || coverLayoutOpacity === 0)
      ) {
        let rgba = hexToRgb(coverLayoutColor, coverLayoutOpacity / 100);
        styles += `linear-gradient(${rgba},${rgba})`;
      }

      if (get(this, 'hasImage')) {
        styles += `, url(${previewUrl})`;
      }

      if (get(this, 'design.secondaryBackgroundColor')) {
        styles += `; background-color: ${get(
          this,
          'design.secondaryBackgroundColor'
        )}`;
      }

      return htmlSafe(`${styles}; color: ${coverLayoutTextColor};`.trim());
    }
  ),

  headerStyles: computed('design.{headerFont,headerFontWeight}', function () {
    let { headerFont, headerFontWeight } = getProperties(
      this.design,
      'headerFont',
      'headerFontWeight'
    );
    if (headerFont) {
      return htmlSafe(
        `font-family: ${headerFont};font-weight: ${headerFontWeight}`
      );
    }

    return undefined;
  }),

  headerFont: computed('design.headerFont', function () {
    // Without font weight, mainly for the department text which has its own weight
    let headerFont = get(this, 'design.headerFont');
    if (headerFont) {
      return htmlSafe(`font-family: ${headerFont};`);
    }

    return undefined;
  }),
});
