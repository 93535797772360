/* import __COLOCATED_TEMPLATE__ from './form-location.hbs'; */
import Component from '@glimmer/component';
import { action, set, setProperties } from '@ember/object';
import config from 'teamtailor/config/environment';

export default class FormLocationComponent extends Component {
  findAddressType(address_components, typeName) {
    const component = address_components.find((component) =>
      component.types.find((type) => type === typeName)
    );

    return component && component.long_name;
  }

  findByClassFromAdrAddress(html, className) {
    const matchingSpan = html.match(
      new RegExp(`<span class="${className}">(.*?)</span>`)
    );
    return matchingSpan && matchingSpan[1];
  }

  findAndSetZip(lat, lng) {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config['ember-google-maps'].key}`
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.results.length) {
          const { location } = this.args;
          set(
            location,
            'zip',
            this.findAddressType(
              response.results[0].address_components,
              'postal_code'
            )
          );
        }
      });
  }

  @action
  handleSearch(event) {
    const {
      name: address,
      address_components,
      adr_address,
      geometry: { location: googleLocation },
    } = event.autocompletes.getPlace();
    const { location } = this.args;
    const city =
      this.findAddressType(address_components, 'postal_town') ||
      this.findAddressType(address_components, 'locality') ||
      this.findAddressType(address_components, 'administrative_area_level_3') ||
      this.findByClassFromAdrAddress(adr_address, 'locality') ||
      this.findByClassFromAdrAddress(adr_address, 'region');

    setProperties(location, {
      zip: this.findAddressType(address_components, 'postal_code'),
      address: address === city ? null : address,
      country: this.findAddressType(address_components, 'country'),
      administrativeArea: this.findAddressType(
        address_components,
        'administrative_area_level_1'
      ),

      lat: googleLocation.lat(),
      long: googleLocation.lng(),
      city,
    });

    if (!location.zip) {
      this.findAndSetZip(googleLocation.lat(), googleLocation.lng());
    }

    if (this.args.onSelectPlace) {
      this.args.onSelectPlace(location);
    }
  }
}
