/* import __COLOCATED_TEMPLATE__ from './text.hbs'; */
/* eslint-disable ember/use-ember-get-and-set */
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    changeset: BufferedChangeset;
  };
}

type Option = {
  text: string;
  value: boolean;
};

export default class SettingsQuestionsText extends Component<Signature> {
  @service declare intl: IntlService;

  get singleLine() {
    const singleLine = this.args.changeset.get('singleLine');
    return JSON.parse(singleLine);
  }

  get options() {
    return [
      { text: this.intl.t('settings.questions.single_line'), value: true },
      { text: this.intl.t('settings.questions.multi_line'), value: false },
    ];
  }

  @action
  setSingleLine(option: Option) {
    this.args.changeset.set('singleLine', option.value);
  }
}
