/* import __COLOCATED_TEMPLATE__ from './stage.hbs'; */
import { action, set, get } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { recordIdentifierFor } from '@ember-data/store';
import { sortBy } from 'ember-composable-helpers/helpers/sort-by';
import { animate } from 'teamtailor/ember-smooth';

export default class JobsEditStageComponent extends Component {
  @service ttAlert;
  @service current;
  @service intl;
  @service flipper;

  @tracked showAnonymousModeSettings = false;
  @tracked showGuideTimeSettings = false;
  @tracked showDeadlineSettings = false;
  @tracked showMoveRejectedCandidates = false;
  @tracked showNewHireShareModeSettings = false;

  @action
  handleNameInput(e) {
    this.args.stage.name = e.target.textContent;
  }

  @tracked
  name;

  nameEl;

  @action
  handleMouseUp() {
    this.nameEl.blur();
  }

  @action
  handleNameMouseDownUp(e) {
    // to allow focusing contenteditable name element on draggable element
    e.stopPropagation();
  }

  lidFor(record) {
    return recordIdentifierFor(record).lid;
  }

  get triggers() {
    return sortBy([
      'delayJobFor:asc',
      'created_at:asc',
      get(this.args.stage, 'triggers'),
    ]).filter((stage) => get(stage, 'onReject') === this.args.isOnReject);
  }

  @action
  async actionIn(element) {
    await animate.from(element, {
      height: 0,
      transform: 'scale(0.9)',
      opacity: 0,
    });
  }

  @action
  async actionOut(element) {
    await animate.to(
      element,
      {
        height: 0,
        transform: 'scale(0.9)',
        opacity: 0,
      },
      100
    );
  }

  @action
  didInsertName(el) {
    this.nameEl = el;
    this.name = this.args.stage.displayName;

    if (!this.name) {
      this.nameEl.focus();
    }
  }

  @action
  handleRenameClick() {
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(this.nameEl.childNodes[0], this.nameEl.childNodes[0].length);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    this.nameEl.focus();
  }

  @action
  handleNameKeyDown(e) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  }

  @action
  guideTimeSelected(guideTime) {
    set(this, 'args.stage.guideTime', guideTime);
  }

  @action
  milestoneSelected(milestone) {
    set(this, 'args.stage.milestone', milestone);
  }

  @action
  handleRemoveClick() {
    this.args.stage.deleteRecord();
  }

  @action
  selectStageType(stageType) {
    this.args.stage.stageType = stageType;
  }

  @action
  handleTriggerSelect(isOnReject, triggerType, partnerActivation, subtype) {
    this.args.onAddTrigger(isOnReject, triggerType, partnerActivation, subtype);
  }
}
