/* import __COLOCATED_TEMPLATE__ from './key-details.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { IntlService } from 'ember-intl';
import Current from 'teamtailor/services/current';

export default class KeyDetails extends Component {
  @service declare intl: IntlService;
  @service declare current: Current;

  get locale() {
    return this.current.locale;
  }
}
