/* import __COLOCATED_TEMPLATE__ from './options-menu.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';
import { CoreDropdownMenuApi } from '../core/dropdown-menu';
import { ALLOWED_APPEARANCES } from 'teamtailor/constants/button';

function includesString(string: string, searchString: string) {
  return string.toLowerCase().includes(searchString.toLowerCase());
}

function filterOptions(
  options: any[],
  searchString: string,
  columnNameKey: string
) {
  return options.reduce((acc, option) => {
    if (option.groupName) {
      if (includesString(option.groupName, searchString)) {
        acc.push(option);
        return acc;
      }

      let groupOptions: any[] = option.options.filter((option: any) =>
        includesString(option[columnNameKey], searchString)
      );
      if (groupOptions.length > 0) {
        acc.push({
          groupName: option.groupName,
          options: groupOptions,
        });
      }
    } else {
      if (includesString(option[columnNameKey], searchString)) {
        acc.push(option);
      }
    }

    return acc;
  }, []);
}

type Args = {
  type: 'edit' | 'add' | 'first';
  size?: 'small' | 'medium';
  dropdownButtonAppearance?: (typeof ALLOWED_APPEARANCES)[number];
  availableColumns: any[];
  columnNameKey: string;
  closeOnChange?: boolean;
  onUpdate?: (option: any) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

export default class DataTableOptionsMenuComponent extends Component<Args> {
  @argDefault type: Args['type'] = 'add';
  @argDefault closeOnChange = true;

  @tracked searchString = '';

  get dropdownButtonAppearance() {
    return this.isFirstButton
      ? 'primary'
      : this.args.dropdownButtonAppearance || 'secondary';
  }

  get isFirstButton() {
    return this.args.type === 'first';
  }

  get isEditing() {
    return this.type === 'edit';
  }

  get isAdding() {
    return this.type === 'add';
  }

  get icon() {
    return this.isEditing ? 'pen' : 'plus';
  }

  get filteredOptions() {
    if (!this.searchString || this.searchString.length === 0) {
      return this.args.availableColumns;
    }

    return filterOptions(
      this.args.availableColumns,
      this.searchString,
      this.args.columnNameKey
    );
  }

  @action
  onSearch(event: KeyboardEvent) {
    const { value } = event.target as HTMLInputElement;
    this.searchString = value;
  }

  @action
  onSelect(menuApi: CoreDropdownMenuApi, option: any) {
    if (this.closeOnChange) {
      menuApi.close();
    }

    this.args.onUpdate?.(option);
  }

  @action
  onOpen() {
    this.args.onOpen?.();
  }

  @action
  onClose() {
    this.searchString = '';
    this.args.onClose?.();
  }
}
