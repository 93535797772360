/* import __COLOCATED_TEMPLATE__ from './warnings.hbs'; */
import Component from '@glimmer/component';
import CurrentService from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import JobModel from 'teamtailor/models/job';

interface WarningComponentArgs {
  job: JobModel;
}

export default class WarningComponent extends Component<WarningComponentArgs> {
  @service declare current: CurrentService;

  get isSandbox() {
    return this.current.company.sandbox;
  }

  get isCustomer() {
    return this.current.company.customer;
  }

  get isLive() {
    return this.current.company.currentLiveCustomer;
  }

  get isInternal() {
    return this.args.job.internal;
  }

  get notLiveDateWarning() {
    return !this.isLive && this.isCustomer;
  }

  get missingCreditCard() {
    return (
      !this.current.user.admin &&
      this.current.company.missingCreditCardForPromote
    );
  }

  get notPromotableWarning() {
    return this.isLive && !this.isInternal && !this.args.job.isPromotable;
  }
}
