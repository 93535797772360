/* import __COLOCATED_TEMPLATE__ from './monster.hbs'; */
import { action, set } from '@ember/object';
import PromotionBaseClass from './promotion-base';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class extends PromotionBaseClass {
  @tracked checkoutData;

  get monsterJobIndustries() {
    return this.store.peekAll('monster-job-industry');
  }

  get monsterJobCategories() {
    return this.store.peekAll('monster-job-category');
  }

  get internalName() {
    return get(this, 'promotion.channel.internalName');
  }

  get jobLevelOptions() {
    switch (this.internalName) {
      case 'monster':
        return jobLevelMonster;
      case 'monster_fi':
        return jobLevelMonsterFi;
      case 'monster_de':
        return jobLevelMonsterDe;
      case 'monster_fr':
        return jobLevelMonsterFr;
      case 'monster_nl':
        return jobLevelMonsterNl;
      default:
        return jobLevelMonsterEn;
    }
  }

  @action
  selectJobLevel(option) {
    set(this, 'promotion.monsterJobLevel', option.value);
  }

  get jobTypeOptions() {
    switch (this.internalName) {
      case 'monster':
        return jobTypeMonster;
      case 'monster_fi':
        return jobTypeMonsterFi;
      case 'monster_de':
        return jobTypeMonsterDe;
      case 'monster_fr':
        return jobTypeMonsterFr;
      case 'monster_nl':
        return jobTypeMonsterNl;
      default:
        return jobTypeMonsterEn;
    }
  }

  @action
  selectJobType(option) {
    set(this, 'promotion.monsterJobType', option.value);
  }

  get jobStatusOptions() {
    switch (this.internalName) {
      case 'monster':
        return jobStatusMonster;
      case 'monster_fi':
        return jobStatusMonsterFi;
      case 'monster_de':
        return jobStatusMonsterDe;
      case 'monster_fr':
        return jobStatusMonsterFr;
      case 'monster_nl':
        return jobStatusMonsterNl;
      default:
        return jobStatusMonsterEn;
    }
  }

  @action
  selectJobStatus(option) {
    set(this, 'promotion.monsterJobStatus', option.value);
  }
}

const jobLevelMonster = [
  { value: 16, name: 'Gymnasienivå' },
  { value: 10, name: 'Student' },
  { value: 11, name: 'Ingångsnivå' },
  { value: 12, name: 'Erfaren (mer än 2 års erfarenhet)' },
  { value: 13, name: 'Chef/Personalansvarig' },
  { value: 14, name: 'Högre chefsbefattning' },
  { value: 15, name: 'Företagsledning' },
];
const jobLevelMonsterFi = [
  { value: 16, name: 'Opiskelija (lukio, ammattikoulu)' },
  { value: 10, name: 'Opiskelija (korkeakoulu)' },
  { value: 11, name: 'Uran alkuvaihe (alle 2 vuotta työkokemusta)' },
  { value: 12, name: 'Uralla edennyt (ei esimiesvastuuta)' },
  { value: 13, name: 'Uralla edennyt (esimieskokemusta)' },
  { value: 14, name: 'Johtotehtävät' },
  { value: 15, name: 'Ylin johto (TJ, CEO, pääjohtaja)' },
];
const jobLevelMonsterDe = [
  { value: 16, name: 'Schüler' },
  { value: 10, name: 'Student' },
  { value: 11, name: 'Berufseinsteiger' },
  { value: 12, name: 'Berufserfahren' },
  { value: 13, name: 'Management (Manager/Abteilungsleiter)' },
  { value: 14, name: 'Mitglied der Geschäftsleitung oder Geschäftsführer' },
  { value: 15, name: 'Vorstandsmitglied oder Vorstandsvorsitzender' },
];
const jobLevelMonsterFr = [
  { value: 16, name: 'Etudiant' },
  { value: 10, name: 'Jeune Diplômé' },
  { value: 11, name: 'Junior' },
  { value: 12, name: 'Confirmé / Senior' },
  { value: 13, name: "Responsable d'équipe" },
  { value: 14, name: 'Responsable de Département' },
  { value: 15, name: 'Dirigeant/Entrepreneur' },
];
const jobLevelMonsterNl = [
  { value: 16, name: 'Scholier (Middelbare school)' },
  { value: 10, name: 'Student' },
  { value: 11, name: 'Startfunctie (weinig ervaring)' },
  { value: 12, name: 'Midcareer (ervaren)' },
  { value: 13, name: 'Management (Manager/Leidinggevende)' },
  { value: 14, name: 'Senior Management' },
  { value: 15, name: 'Directie' },
];
const jobLevelMonsterEn = [
  { value: 16, name: 'Student (High School)' },
  { value: 10, name: 'Student (Undergraduate/Graduate)' },
  { value: 11, name: 'Entry Level' },
  { value: 12, name: 'Experienced (Non-Manager)' },
  { value: 13, name: 'Manager (Manager/Supervisor of Staff)' },
  { value: 14, name: 'Executive (SVP, VP, Department Head, etc)' },
  { value: 15, name: 'Senior Executive (President, CFO, etc)' },
];

const jobTypeMonster = [
  { value: 1, name: 'Tillsvidare' },
  { value: 2, name: 'Visstid (vikariat/projekt/kontrakt)' },
  { value: 3, name: 'Examensjobb/praktik/trainee' },
  { value: 20, name: 'Säsongsarbete' },
];
const jobTypeMonsterFi = [
  { value: 1, name: 'Vakituinen' },
  { value: 2, name: 'Määräaikainen/Projekti' },
  { value: 3, name: 'Harjoittelija' },
  { value: 20, name: 'Kausiluontoinen' },
];
const jobTypeMonsterDe = [
  { value: 1, name: 'Festanstellung' },
  { value: 2, name: 'Freie Mitarbeit / Dienstvertrag' },
  { value: 3, name: 'Praktikum' },
  { value: 20, name: 'Befristet' },
];
const jobTypeMonsterFr = [
  { value: 1, name: 'CDI' },
  { value: 2, name: 'CDD/Mission/Intérim' },
  { value: 3, name: 'Stage/Apprentissage/Alternance' },
  { value: 20, name: 'Indépendant/Freelance/Saisonnier' },
];
const jobTypeMonsterNl = [
  { value: 1, name: 'Vast dienstverband' },
  { value: 2, name: 'Tijdelijk / Projectbasis' },
  { value: 3, name: 'Stage' },
  { value: 20, name: 'Seizoenswerk' },
  { value: 75, name: 'Volunteer' },
  { value: 86, name: 'CCL Sponsored' },
  { value: 87, name: 'Temporary' },
];
const jobTypeMonsterEn = [
  { value: 1, name: 'Employee' },
  { value: 2, name: 'Temporary / Contract' },
  { value: 3, name: 'Intern' },
  { value: 20, name: 'Seasonal' },
];

const jobStatusMonster = [
  { value: 4, name: 'Heltid' },
  { value: 5, name: 'Deltid' },
  { value: 26, name: 'Timanställd' },
];
const jobStatusMonsterFi = [
  { value: 4, name: 'Kokopäiväinen' },
  { value: 5, name: 'Osa-aikainen' },
  { value: 26, name: 'Tuntityö' },
];
const jobStatusMonsterDe = [
  { value: 4, name: 'Vollzeit' },
  { value: 5, name: 'Teilzeit' },
  { value: 26, name: 'Pro Tag' },
];
const jobStatusMonsterFr = [
  { value: 4, name: 'Temps plein' },
  { value: 5, name: 'Temps partiel' },
  { value: 26, name: 'Journalier' },
];
const jobStatusMonsterNl = [
  { value: 4, name: 'Fulltime' },
  { value: 5, name: 'Parttime' },
  { value: 26, name: 'Oproepbasis' },
];
const jobStatusMonsterEn = [
  { value: 4, name: 'Full Time' },
  { value: 5, name: 'Part Time' },
  { value: 26, name: 'Per Diem' },
];
