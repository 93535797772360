/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent, {
  BaseComponentArgs,
} from 'teamtailor/components/content-block/base-component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import Store from '@ember-data/store';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import DepartmentModel from 'teamtailor/models/department';
import LocationModel from 'teamtailor/models/location';

export default class PeopleBaseComponent extends BaseComponent<BaseComponentArgs> {
  @service declare store: Store;
  @service declare iframePreview: IframePreviewService;

  get isEmbedded() {
    return get(get(this.section, 'page'), 'pageType') !== 'employees_list';
  }

  get section() {
    return this.args.model;
  }

  get careerSite() {
    return get(get(get(this.section, 'page'), 'pagePublication'), 'careerSite');
  }

  @action
  async customReload() {
    const section = await this.section;
    if (this.isEmbedded && section) {
      this.iframePreview.reloadSection(section.id);
    } else {
      this.iframePreview.reloadIframe();
    }
  }

  @action
  async handleSetDepartment(department: DepartmentModel | null) {
    const section = await this.section;
    if (section) {
      set(section, 'pickedDepartmentId', department?.id);
    }
  }

  @action
  async handleSetLocation(location: LocationModel | null) {
    const section = await this.section;
    if (section) {
      set(section, 'pickedLocationId', location?.id);
    }
  }
}
