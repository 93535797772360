/* import __COLOCATED_TEMPLATE__ from './animate-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { AnimateArray } from 'teamtailor/ember-smooth/animate-array';
import { assert } from '@ember/debug';

export default class AnimateListComponent extends Component {
  constructor() {
    super(...arguments);

    assert(
      'You have to provide an AnimateArray',
      this.args.array instanceof AnimateArray
    );
  }

  get array() {
    return this.args.array;
  }

  @action onInsertChild(type, index, element) {
    this.array[index] = this.array[index] || {};

    this.array[index][type] = element;

    this.array[index].onInsert?.call?.(this.array, this.array[index], {
      type,
      index,
    });
  }

  @action onUpdateChild(type, index, element) {
    if (this.array[index]) {
      this.array[index][type] = element;
    }
  }
}
