/* import __COLOCATED_TEMPLATE__ from './scorecard-results.hbs'; */
import Component from '@ember/component';
import { filterBy, gt, sort } from '@ember/object/computed';
import EmberObject, { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import groupBy from 'teamtailor/utils/group-by';
import { SCORE_VALUE_ADJUSTMENT } from 'teamtailor/constants/scorecard-scores';

export default Component.extend({
  user: service(),
  intl: service(),

  scores: null,
  picks: null,
  interviews: null,
  noInterviews: false,
  showScoresFromDeletedUsers: false,
  showInterviewKitName: false,
  sortOnWeight: false,

  scoresUniqueByUsers: computed(
    'scoresByUser',
    'scoresIncludingDeletedUsers',
    'showScoresFromDeletedUsers',
    function () {
      return this.showScoresFromDeletedUsers
        ? this.scoresIncludingDeletedUsers
        : this.scoresByUser;
    }
  ),

  scoresByUser: computed('noInterviews', 'scores.@each.interview', function () {
    return this.noInterviews
      ? this.scores.uniqBy('user.id')
      : this.scores.uniqBy('interview.userOrAuthorName.id');
  }),

  groupedScoresWithoutUsers: groupBy('scoresWithoutUsers', 'criteriumId'),

  scoresWithoutUsers: computed('scores.[]', function () {
    return this.scores.filter((score) => {
      return !score.belongsTo('user').id();
    });
  }),

  deletedUsersScore: computed('groupedScoresWithoutUsers.[]', function () {
    const groupedScoresWithoutUsers = get(this, 'groupedScoresWithoutUsers');
    const deletedUsersScore = [];

    if (groupedScoresWithoutUsers.length > 0) {
      const maxPerCriteria = groupedScoresWithoutUsers.reduce(
        (max, current) => {
          return Math.max(max, current.items.length);
        },
        0
      );

      for (let i = 0; i < maxPerCriteria; i++) {
        let scores = groupedScoresWithoutUsers.map((groupedScores) => {
          return groupedScores.items[i];
        });

        deletedUsersScore.push(EmberObject.create({ scores, tempId: i + 1 }));
      }
    }

    return deletedUsersScore;
  }),

  scoresIncludingDeletedUsers: computed(
    'scoresByUser.[]',
    'deletedUsersScore.[]',
    function () {
      const scoresByUsers = get(this, 'scoresByUser').filter((score) => {
        return !!score.belongsTo('user').id();
      });

      const deletedUsersScore = get(this, 'deletedUsersScore');

      if (deletedUsersScore.length) {
        scoresByUsers.pushObjects(deletedUsersScore);
      }

      return scoresByUsers;
    }
  ),

  competencesList: computed(
    'hasPersonalityPicks',
    'hasSkillsPicks',
    'personalityScorecardPicks',
    'sortedPersonalityScorecardPicks',
    'personalityScorecardPicksWithTotal',
    'picks.[]',
    'sortedSkillsScorecardPicks',
    'skillsScorecardPicks',
    'skillsScorecardPicksWithTotal',
    function () {
      const items = [];

      if (this.hasSkillsPicks) {
        items.pushObject({ label: this.intl.t('common.skills') });
        items.pushObjects(get(this, 'skillsScorecardPicksWithTotal'));
      }

      if (this.hasPersonalityPicks) {
        items.pushObject({ label: this.intl.t('common.traits') });
        items.pushObjects(get(this, 'personalityScorecardPicksWithTotal'));
      }

      return items;
    }
  ),

  scoreDataByUsers: computed(
    'interviews',
    'picks.[]',
    'scores.[]',
    'scoresByInterviews',
    'scoresUniqueByUsers.[]',
    'showInterviewKitName',
    function () {
      if (this.interviews !== null) {
        return get(this, 'scoresByInterviews');
      } else {
        return this.scoresUniqueByUsers.map((userScore) => {
          const scorecardScores = this.picks.map((pick) => {
            const scorecardScores = this.scores.filterBy(
              'scorecardPick.id',
              get(pick, 'id')
            );

            let score = null;
            if (get(userScore, 'user.id')) {
              score = scorecardScores.findBy(
                'user.id',
                get(userScore, 'user.id')
              );
            } else if (get(userScore, 'interview.author')) {
              score = scorecardScores.findBy(
                'interview.author',
                get(userScore, 'interview.author')
              );
            } else if (get(userScore, 'tempId')) {
              score = userScore.scores.find(
                (innerScore) =>
                  innerScore &&
                  get(innerScore, 'scorecardPick.id') === get(pick, 'id')
              );
            }

            return {
              topic: get(pick, 'topic'),
              score,
            };
          });

          return {
            user:
              get(userScore, 'interview.userOrAuthorName.user') ||
              get(userScore, 'user'),

            author: get(userScore, 'interview.userOrAuthorName'),
            scorecardScores,
            ...(this.showInterviewKitName
              ? {
                  interviewKitName: get(
                    userScore,
                    'interview.interviewKit.name'
                  ),
                }
              : {}),
          };
        });
      }
    }
  ),

  scoresByInterviews: computed(
    'interviews.@each.scoresLoaded',
    'picks.[]',
    'scores.[]',
    'showInterviewKitName',
    function () {
      const interviews = get(this, 'interviews');
      if (!interviews) {
        return [];
      }

      return interviews
        .map((interview) => {
          let scoresGiven = false;

          const scorecardScores = this.picks.map((pick) => {
            const scorecardCriteriumId = get(pick, 'scorecardCriterium.id');
            const score = interview.scores.find((score) => {
              return get(score, 'criteriumId') === scorecardCriteriumId;
            });

            if (score) scoresGiven = true;

            return {
              topic: get(pick, 'topic'),
              score,
            };
          });

          // If no scores were given in this interview, discard this column
          if (!scoresGiven) return null;

          return {
            user: get(interview, 'userOrAuthorName.user'),
            author: get(interview, 'userOrAuthorName'),
            partner: get(interview, 'partner'),
            scorecardScores,
            ...(this.showInterviewKitName
              ? { interviewKitName: get(interview, 'interviewKit.name') }
              : {}),
          };
        })
        .compact();
    }
  ),

  personalityScorecardPicksWithTotal: computed(
    'personalityScorecardPicks',
    'scores',
    'sortOnWeight',
    'sortedPersonalityScorecardPicks',
    function () {
      const scorecardPicks = this.sortOnWeight
        ? this.sortedPersonalityScorecardPicks
        : this.personalityScorecardPicks;

      return scorecardPicks.map((pick) => {
        return this.getTotals(pick);
      });
    }
  ),

  skillsScorecardPicksWithTotal: computed(
    'scores',
    'skillsScorecardPicks',
    'sortOnWeight',
    'sortedSkillsScorecardPicks',
    function () {
      const scorecardPicks = this.sortOnWeight
        ? this.sortedSkillsScorecardPicks
        : this.skillsScorecardPicks;

      return scorecardPicks.map((pick) => {
        return this.getTotals(pick);
      });
    }
  ),

  totalWeight: computed('picks', function () {
    return this.sumArray(get(this, 'picks').mapBy('weight'));
  }),

  weightSorting: Object.freeze(['weight:desc']),
  skillsScorecardPicks: filterBy('picks', 'topic', 'skills'),
  sortedSkillsScorecardPicks: sort('skillsScorecardPicks', 'weightSorting'),

  personalityScorecardPicks: filterBy('picks', 'topic', 'personality'),
  sortedPersonalityScorecardPicks: sort(
    'personalityScorecardPicks',
    'weightSorting'
  ),

  hasSkillsPicks: gt('skillsScorecardPicks.length', 0),
  hasPersonalityPicks: gt('personalityScorecardPicks.length', 0),

  getTotals(pick) {
    let scorecardScores;
    if (this.interviews) {
      const scorecardCriteriumId = get(pick, 'scorecardCriterium.id');
      scorecardScores = this.scores.filter(
        (score) => get(score, 'criteriumId') === scorecardCriteriumId
      );
    } else {
      scorecardScores = this.scores.filterBy(
        'scorecardPick.id',
        get(pick, 'id')
      );
    }

    const scores = scorecardScores.mapBy('humanScore').compact();
    const total = scores.length
      ? (this.sumArray(scores) / scores.length).toFixed(1)
      : null;

    return {
      pick,
      weight: Math.floor(
        (get(pick, 'weight') / get(this, 'totalWeight')) * 100
      ),

      totalAverage: total,
      totalRounded: total ? Math.floor(total) - SCORE_VALUE_ADJUSTMENT : null,
    };
  },

  sumArray(numbers) {
    return numbers.reduce((a, b) => {
      return a + b;
    }, 0);
  },
});
