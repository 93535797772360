/* import __COLOCATED_TEMPLATE__ from './filter-option.hbs'; */
import Component from '@glimmer/component';
import { FilterOption } from 'teamtailor/components/fancy-filters';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface FilterOptionArgs {
  option: FilterOption;
}

export default class extends Component<FilterOptionArgs> {
  @tracked showTooltip = false;

  @action
  checkForOverflow(element: HTMLElement): void {
    if (element.offsetWidth < element.scrollWidth) {
      this.showTooltip = true;
      element.classList.add('text-ellipsis');
    }
  }
}
