/* import __COLOCATED_TEMPLATE__ from './partner-result.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import UserService from 'teamtailor/services/user';
import PartnerResult from 'teamtailor/models/partner-result';
import Store from '@ember-data/store';

interface Signature {
  Args: {
    partnerResult: PartnerResult;
  };
}

export default class PartnerPartnerResultComponent extends Component<Signature> {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare user: UserService;
  @service declare pusher: PusherService;
  @service declare store: Store;

  private declare channel?: PusherChannel;

  @tracked isLoading = false;
  @tracked showDetails = false;
  @tracked showDelete = false;

  get partnerResult() {
    return this.args.partnerResult;
  }

  get partner() {
    return this.partnerResult.partner;
  }

  get hasWebhookData() {
    const { webhookData } = this.partnerResult;
    return webhookData && Object.keys(webhookData).length;
  }

  get title() {
    const noun = get(this.partner, 'webhookNoun');
    const partnerName = get(this.partner, 'name');
    const verb = get(this.partner, 'webhookVerb');

    const name = [verb, partnerName, noun].filter(Boolean).join(' ');

    return capitalize(name);
  }

  get stateStyle() {
    switch (this.partnerResult.status) {
      case 'sending':
      case 'pending':
        return {
          bg: 'bg-amber-50',
          text: 'text-amber-800',
          icon: 'circle-ellipsis',
        };
      case 'failed':
        return {
          bg: 'bg-red-50',
          text: 'text-red-800',
          icon: 'circle-exclamation',
        };
      case 'sent':
      case 'completed':
        return {
          bg: 'bg-green-50',
          text: 'text-green-800',
          icon: 'circle-check',
        };
      default:
        return {
          bg: 'bg-gray-50',
          text: 'text-gray-800',
          icon: 'circle-ellipsis',
        };
    }
  }

  get partnerResultStatus() {
    const status = this.partnerResult.status || 'sent';
    return this.intl.t(`components.partner.partner_result.${status}`);
  }

  get hasScore() {
    return !isNone(this.partnerResult.assessmentScore);
  }

  get badgeColor() {
    if (this.partnerResult.isFailed) {
      return 'red';
    }

    if (
      this.partnerResult.status === 'sending' ||
      this.partnerResult.status === 'sent' ||
      this.partnerResult.status === 'pending'
    ) {
      return 'amber';
    }

    return 'green';
  }

  @action
  async resendWebhook() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    await this.partnerResult.save();
  }

  @action
  openModal() {
    this.showDelete = true;
  }

  @action
  onClose() {
    this.showDelete = false;
  }

  @action
  onCloseDetails() {
    this.showDetails = false;
  }

  @action
  handleDelete() {
    try {
      this.partnerResult.destroyRecord();
      this.flashMessages.success(
        this.intl.t('components.partner.partner_result.deleted')
      );
    } catch (error) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }
}
