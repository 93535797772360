/* import __COLOCATED_TEMPLATE__ from './job-pipeline-overview.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

const queryGenerator = (groupByWeek = false) => gql`
  query JobPipelineOverviewQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    stageSnapshotQuery(dateRange: $dateRange, jobIds: $jobIds) {
      aggregated(groupBy: [STAGE_NAME, ROW_ORDER, ${
        groupByWeek ? 'ISO_YEAR_ISO_WEEK' : 'DATE'
      }]) {
        stageName
        rowOrder
        ${groupByWeek ? 'isoYearIsoWeek' : 'date'}

        applications: sum(field: APPLICATIONS)
        ${groupByWeek ? 'duplications: distinctCount(field: DATE)' : ''}
      }
    }
  }
`;

const EXPORT_QUERY = gql`
  query JobPipelineOverviewExportQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: { stageFromName: { exists: true } }
    ) {
      aggregated(groupBy: [FROM_STAGE_NAME, FROM_STAGE_ID, FROM_STAGE_INDEX]) {
        name: stageFromName
        stageId: stageFromId
        index: stageFromIndex

        movedForward: countOccurrences(
          filters: { moveDirection: { equals: "right" } }
        )
        movedBack: countOccurrences(
          filters: { moveDirection: { equals: "left" } }
        )
        rejected: countOccurrences(filters: { eventType: { equals: REJECTED } })
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'name', headerKey: 'common.name' },
  {
    type: 'number',
    propertyName: 'movedBack',
    headerKey: 'candidate.moved_back',
  },
  {
    type: 'number',
    propertyName: 'movedForward',
    headerKey: 'candidate.moved_forward',
  },
  {
    type: 'number',
    propertyName: 'rejected',
    headerKey: 'candidate.rejected',
  },
];

export default class InsightsWidgetsJobPipelineOverview extends Component {
  @service insights;
  @service insightsExport;

  get dateSpan() {
    return this.args.dateRange.dateSpan;
  }

  get stages() {
    return this.fetchData?.lastSuccessful?.value?.stages;
  }

  get showWeeks() {
    return this.dateSpan.length > 91;
  }

  get dates() {
    if (this.showWeeks) {
      const firstDayOfWeekFilter = (value, index, array) => {
        const firstDayOfWeek = value.isoWeekday(1);
        const yearWeek = parseInt(
          `${firstDayOfWeek.year()}${firstDayOfWeek.isoWeek()}`
        );
        const firstItem = array.find((v) => {
          const comparisonYearWeek = parseInt(`${v.year()}${v.isoWeek()}`);
          return comparisonYearWeek === yearWeek;
        });

        const isFirstDateOfWeek = array.indexOf(firstItem) === index;
        return isFirstDateOfWeek;
      };

      return this.dateSpan.filter(firstDayOfWeekFilter);
    } else {
      return this.dateSpan;
    }
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.common.pipeline_overview',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      {
        createdAt: this.args.job.createdAt,
        sortDirection: 'asc',
        sortKey: 'index',
      }
    );
  }

  @action
  async retrieveExportData(dateRange) {
    const jobStages = this.args.job.stages;
    const {
      eventQuery: { aggregated: stages },
    } = await this.insights.query({
      query: EXPORT_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        jobIds: [this.args.job.id],
      },

      context: { jobId: this.args.job.id },
    });

    const missingStages = jobStages
      .rejectBy('hired')
      .filter((stage) => !stages.mapBy('stageId').includes(stage.id))
      .map((stage) => ({
        name: stage.name,
        stageId: stage.id,
        index: stage.rowOrder,
        movedForward: 0,
        movedBack: 0,
        rejected: 0,
      }));

    const presentStages = stages.map((stage) => ({
      ...stage,
      index: jobStages.findBy('id', stage.stageId)?.rowOrder || stage.index,
    }));

    return [...missingStages, ...presentStages]
      .sortBy('index')
      .map((row, index) => ({
        ...row,
        index,
      }));
  }

  @action
  queryForData(dateRange) {
    const { job } = this.args;
    return this.insights.query({
      query: queryGenerator(this.showWeeks),
      variables: {
        dateRange: dateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });
  }

  fetchData = dropTask(async () => {
    const { dateRange } = this.args;
    const {
      stageSnapshotQuery: { aggregated },
    } = await this.queryForData(dateRange);

    let pipelineOverview = aggregated.reduce((acc, row) => {
      const { stageName, rowOrder, date, isoYearIsoWeek, duplications } = row;
      let { applications } = row;
      let firstDateOfWeek;
      if (this.showWeeks) {
        const year = isoYearIsoWeek.toString().slice(0, 4);
        const week = isoYearIsoWeek.toString().slice(4);
        firstDateOfWeek = moment().year(year).isoWeek(week).isoWeekday(1);
        applications = Math.round(applications / duplications);
      }

      const existingRow = acc.find((r) => r.name === stageName);
      if (!existingRow) {
        acc.push({
          name: stageName,
          rowOrder,
          dateData: [
            {
              date: this.showWeeks ? firstDateOfWeek : date,
              isoYearIsoWeek,
              applications,
            },
          ],
        });
      } else {
        existingRow.dateData.push({
          date: this.showWeeks ? firstDateOfWeek : date,
          isoYearIsoWeek,
          applications,
        });
      }

      return acc;
    }, []);

    pipelineOverview = pipelineOverview.map((row) => {
      const { name, rowOrder, dateData } = row;
      const amendedDateData = this.dates.map((date) => {
        const existingDate = dateData.find((d) =>
          moment(d.date).isSame(date, 'day')
        );
        if (!existingDate) {
          return { date, applications: 0 };
        }

        return existingDate;
      });
      const data = amendedDateData
        .sort((a, b) => a.date.seconds - b.date.seconds)
        .map(({ applications }) => applications);
      return { name, rowOrder: Number(rowOrder), data };
    });
    return {
      stages: pipelineOverview.sortBy('rowOrder').rejectBy('name', 'Hired'),
    };
  });
}
