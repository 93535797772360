/* import __COLOCATED_TEMPLATE__ from './adjustments.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

import Section from 'teamtailor/models/section';
import BlockLayout from 'teamtailor/models/block-layout';

interface Args {
  blockLayout?: BlockLayout;
  mockSection: Section;
}

export default class BlockLibraryAdjustments extends Component<Args> {
  @service declare store: Store;

  get blockAdjustmentsComponent(): string | null {
    if (this.args.blockLayout) {
      return `editor/adjustments/blocks/${this.args.blockLayout.blockName}`;
    }

    return null;
  }

  @action
  onAdjustmentsChange() {
    const attributes = Object.fromEntries(
      Object.entries(this.args.mockSection.serialize()).filter(
        ([, value]) => !isEmpty(value)
      )
    );

    const iframe = document.querySelector<HTMLIFrameElement>(
      '#block-preview-iframe'
    );

    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify(attributes), '*');
    }
  }
}
