/* import __COLOCATED_TEMPLATE__ from './nps-score.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class InsightsWidgetsNpsScore extends Component {
  @service intl;

  @tracked hovering = false;

  get breakdown() {
    const promotersCount = get(this.args, 'data.promotersCount');
    const detractorsCount = get(this.args, 'data.detractorsCount');
    const passivesCount = get(this.args, 'data.passivesCount');

    const legendNameAndCount = (key, count) => {
      return {
        name: this.intl.t(`insights.widgets.nps_score.${key}`),
        value: count,
        label: key,
      };
    };

    return [
      legendNameAndCount('promoters', promotersCount),
      legendNameAndCount('detractors', detractorsCount),
      legendNameAndCount('passives', passivesCount),
    ];
  }
}
