/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import { get } from 'teamtailor/utils/get';
import Base from './base';

export default class ImageComponent extends Base {
  get exceptUserAvatarFor() {
    return (
      get(this.activity, 'createdByTrigger') ||
      get(this.activity, 'isShare') ||
      get(this.activity, 'isTriggerCancelled')
    );
  }

  get hasUser() {
    return get(this.activity, 'user.id');
  }

  get hasNoUser() {
    return !this.hasUser;
  }

  get noUserAvatarException() {
    return !this.exceptUserAvatarFor;
  }

  get showUserAvatar() {
    return this.hasUser && this.noUserAvatarException;
  }

  get displayDeletedUser() {
    return this.noUserAvatarException && this.hasNoUser;
  }

  get defaultIcon() {
    const iconKey = Object.keys(ICON_MAP).find((key) => {
      return get(this, key);
    });
    return iconKey && ICON_MAP[iconKey];
  }

  get triggerType() {
    return get(this, 'activity.trigger.type');
  }

  get isWebhookTrigger() {
    return this.triggerType === 'webhook';
  }

  get logotype() {
    return this.isWebhookTrigger
      ? get(this, 'activity.trigger.logotypeSquare')
      : null;
  }
}

const ICON_MAP = {
  'activity.isMoved': 'arrow-right',
  'activity.isReminderSent': 'bell',
  'activity.isNpsResponse': 'chart-area',
  'activity.isQuestionnaire': 'check',
  'activity.isDataRequest': 'clock',
  'activity.isDataRequestHandledBySystem': 'info',
  'activity.createdByTrigger': 'wand-magic-sparkles',
  'activity.isSms': 'mobile-button',
  'activity.isSmsOptOut': 'mobile-button',
  'activity.isWebhook': 'robot',
  'activity.isShare': 'share-nodes',
  'activity.isCandidateMerged': 'users',
  'activity.isRemovalRequest': 'triangle-exclamation',
  'activity.isNurtureCampaignUnsubscribed': 'triangle-exclamation',
  'activity.isConnected': 'user',
  'activity.isUpdatedProfile': 'user',
  'activity.isAssessment': 'user',
  'activity.isPartnerResult': 'user',
  'activity.isConsentMissing': 'info',
  'activity.isConsentExtended': 'info',
  'activity.isConsentRequested': 'info',
  'activity.isConsentGiven': 'info',
  'activity.isEmail': 'envelope',
  'activity.isMessage': 'envelope',
  'activity.isNurtureCampaign': 'envelope',
  'activity.isConsentExtendRequest': 'envelope',
  'activity.isReferenceGiven': 'comment',
  'activity.isJobOfferAccepted': 'party-horn',
  'activity.isJobOfferDeclined': 'face-disappointed',
  'activity.isJobOfferOpened': 'envelope',
};
