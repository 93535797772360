import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { set, get, setProperties } from '@ember/object';
import currencyForCountry from 'teamtailor/utils/currency-for-country';
import { buildFromInternalName as buildPromotion } from 'teamtailor/models/promotion';
import { inject as service } from '@ember/service';

export default class SnapchatRoute extends Route {
  @service current;
  @service store;

  model() {
    const page = this.modelFor('content.editor');
    return buildPromotion('snapchat', this.store).then((promotion) => {
      setProperties(promotion, {
        page,
        invoiceInfo: this.store.createRecord('invoice-info'),
      });
      return RSVP.hash({
        channel: promotion.channel,
        promotion,
        page,
      });
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const country = get(this.current.company, 'country');
    const currency = currencyForCountry(country);

    set(controller, 'currency', currency);
  }
}
