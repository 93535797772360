/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import TagModel from 'teamtailor/models/tag';

export interface Args {
  selected: string;
  onChange: (tag: TagModel | null) => void;
}

export default class extends Component<Args> {
  @service declare store: Store;

  selected = trackedFunction(this, async () => {
    if (!this.args.selected) {
      return null;
    }

    const tag = await this.store.findRecord('tag', this.args.selected);
    return tag;
  });

  @action
  onChange(tag: TagModel): void {
    if (tag.id === this.args.selected) {
      this.args.onChange(null);
    } else {
      this.args.onChange(tag);
    }
  }
}
