/* import __COLOCATED_TEMPLATE__ from './pipeline-speed.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { plotLineFontOptions } from 'teamtailor/components/insights/charts/font-options';

export default class InsightsMoleculesPipelineSpeed extends Component {
  @service intl;

  get chartCategories() {
    return [this.intl.t('insights.molecules.pipeline_speed.speed')];
  }

  get plotLines() {
    const { companyAverageDays, jobAverageDays } = this.args;

    const tooClose = Math.abs(jobAverageDays - companyAverageDays) < 4;

    return [
      {
        tooClose,
        text: this.intl.t(
          'insights.molecules.pipeline_speed.average_for_current_job'
        ),

        textColor: plotLineFontOptions.color,
        color: plotLineFontOptions.color,
        fontOptions: plotLineFontOptions,
        value: jobAverageDays,
      },

      {
        tooClose,
        text: this.intl.t(
          'insights.molecules.pipeline_speed.average_for_company'
        ),

        textColor: plotLineFontOptions.color,
        color: '#9013FE',
        fontOptions: plotLineFontOptions,
        value: companyAverageDays,
      },
    ];
  }

  get chartData() {
    return this.args.stagesAndTimes.sortBy('index').map((stageAndTime) => {
      return { name: stageAndTime.name, value: [Number(stageAndTime.value)] };
    });
  }
}
