/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

const REQUIRED_PROPS = [
  'connectText',
  'connectTextFutureJobs',
  'text',
  'textFutureJobs',
];
export default class ConsentFormComponent extends TranslatableFormBaseComponent {
  @service flipper;
  @service store;

  translationsProps = REQUIRED_PROPS;

  get termsOfServiceSetting() {
    return this.translatableModel;
  }

  get dataPrivacySetting() {
    return get(this.args, 'dataPrivacySetting');
  }

  @action
  handleOptInFutureJobsChange() {
    this.termsOfServiceSetting.toggleProperty('optInFutureJobs');
    if (!this.termsOfServiceSetting.optInFutureJobs) {
      this.termsOfServiceSetting.optInFutureJobsConnect = false;
    }
  }

  @action
  async handleSaveClick() {
    if (this.args.neverRemindAboutMissingConsents) {
      this.dataPrivacySetting.autoSendInitial = false;
    }

    this.dataPrivacySetting.save();
    this.handleSave();
  }

  @action
  updateTermSettingOption(settingKey, value) {
    this.termsOfServiceSetting[settingKey] = value;
  }
}
