/* import __COLOCATED_TEMPLATE__ from './number.hbs'; */
import Component from '@glimmer/component';

type NumberCellData = {
  value?: number;
  digits?: number;
};

type NumberCellArgs = {
  data: NumberCellData;
};

export default class DataTableNumberComponent extends Component<NumberCellArgs> {
  get value() {
    return this.args.data.value;
  }

  get zero() {
    return parseFloat(this.value?.toString() || '0') === 0;
  }

  get formattedValue() {
    const { digits } = this.args.data;

    if (digits !== undefined) {
      return this.value?.toFixed(digits);
    }

    return this.value;
  }
}
