/* import __COLOCATED_TEMPLATE__ from './manager.hbs'; */
import MediaLibraryManagerBaseComponent from 'teamtailor/components/media-library/manager-base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'teamtailor/config/environment';
import { UploadFile as File } from 'ember-file-upload';
import {
  restartableTask,
  task,
  TaskForTaskFunction,
  all,
} from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ImageModel from 'teamtailor/models/image';
import IntlService from 'ember-intl/services/intl';
import UnsplashPhotoModel from 'teamtailor/models/unsplash-photo';
import DirectUploadService from 'teamtailor/services/direct-upload';
import FlashMessageService from 'teamtailor/services/flash-message';

interface Args {
  onPickImage(image: ImageModel): void;
  multiple: boolean;
  displayUploadedMessages: boolean;
}

export default class UnsplashLibraryManagerComponent extends MediaLibraryManagerBaseComponent {
  declare args: Args;

  @service declare flashMessages: FlashMessageService;
  @service declare directUpload: DirectUploadService;
  @service declare intl: IntlService;

  // Actualy expecting `any` here...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @tracked declare lastestTask?: TaskForTaskFunction<any>;
  @tracked images: UnsplashPhotoModel[] = [];
  @tracked pickedImages: UnsplashPhotoModel[] = [];

  private perPage = 30;
  private orientation = 'landscape'; // Currently only supported for search requests

  get isRunning(): boolean {
    return this.lastestTask?.isRunning ?? false;
  }

  get unsplashLink(): string {
    return `https://unsplash.com/license/?utm_source=${config.unsplash.name}&utm_medium=referral`;
  }

  get hidePickedCounter(): boolean {
    return this.lastestTask?.isRunning ?? false;
  }

  fetchImages = restartableTask(async () => {
    const infinityModel = await this.infinity.model('unsplash-photo', {
      per_page: this.perPage,
      query: this.queryParams.query,
      orientation: this.orientation,
    });

    this.images = infinityModel;
  });

  createImage = task(async (photo: UnsplashPhotoModel) => {
    const unsplashLinkTask = this.fetchDownloadLink.perform(photo);
    photo.tasks.pushObject(unsplashLinkTask);
    const downloadLink = await unsplashLinkTask;

    const unsplashFetchTask = this.fetchPhoto.perform(downloadLink);
    photo.tasks.pushObject(unsplashFetchTask);
    const unsplashFile = await unsplashFetchTask;

    const s3Task = this.uploadPhoto.perform(unsplashFile);
    photo.tasks.pushObject(s3Task);
    const s3Url = await s3Task;

    const saveImageTask = this.saveImage.perform(photo, s3Url);
    photo.tasks.pushObject(saveImageTask);
    const newImage = await saveImageTask;

    return newImage;
  });

  fetchDownloadLink = task(async (photo: UnsplashPhotoModel) => {
    const url = await photo.download();
    return url;
  });

  fetchPhoto = task(async (downloadLink: string) => {
    const response: Response = await fetch(downloadLink);
    const blob: Blob = await response.blob();
    return File.fromBlob(blob);
  });

  uploadPhoto = task(async (file: File) => {
    const url = await this.directUpload.s3(file, {
      model: 'images',
      filename: `original.jpg`,
    });
    return url;
  });

  saveImage = task(async (photo: UnsplashPhotoModel, s3Url: string) => {
    const image = this.store.createRecord('image', {
      alt: photo.alt,
      s3Image: s3Url,
      filename: `${photo.id}.jpg`,
      width: photo.width,
      height: photo.height,
      createdAt: new Date(),
    });
    const result = await image.save();

    if (this.args.displayUploadedMessages) {
      this.flashMessages.success(
        this.intl.t('components.unsplash_library.manager.image_added')
      );
    }

    return result;
  });

  runMainTask = task(
    { maxConcurrency: 3, enqueue: true },
    async (photo: UnsplashPhotoModel) => {
      const mainTask = this.createImage;
      this.lastestTask = mainTask;
      const image = await mainTask.perform(photo);
      return image;
    }
  );

  doAll = task(async (photos: UnsplashPhotoModel[]) => {
    const picked = photos.map((image) => {
      return this.runMainTask.perform(image);
    });

    const images = await all(picked);

    images.forEach((image: ImageModel) => {
      this.args.onPickImage(image);
    });
  });

  @action
  async handlePickImage(photo: UnsplashPhotoModel): Promise<void> {
    if (this.multiple) {
      this.pickedImages.includes(photo)
        ? this.pickedImages.removeObject(photo)
        : this.pickedImages.pushObject(photo);
    } else {
      if (!this.isRunning) {
        const image = await this.runMainTask.perform(photo);
        this.args.onPickImage(image);
      }
    }
  }

  @action
  handleUsePickedImages(): void {
    this.doAll.perform(this.pickedImages);
  }
}
