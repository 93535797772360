/* import __COLOCATED_TEMPLATE__ from './candidate.hbs'; */
import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
  classNames: ['c-candidate-merger__candidate'],
  classNameBindings: ['selected:c-candidate-merger__candidate--selected'],

  selectingCandidates: null,
  selectedCandidates: Object.freeze([]),
  primaryCandidate: null,

  selected: computed(
    'candidate',
    'primaryCandidate',
    'selectedCandidates.[]',
    'selectingCandidates',
    function () {
      if (get(this, 'selectingCandidates')) {
        return get(this, 'selectedCandidates').includes(get(this, 'candidate'));
      } else {
        return get(this, 'candidate') === get(this, 'primaryCandidate');
      }
    }
  ),

  click(event) {
    if (!event.target.nodeName === 'A') {
      get(this, 'onClick')();
    }
  },
});
