/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface DisclaimerComponentArgs {
  createdByTeamtailor: boolean;
  name: string;
}

export default class DisclaimerComponent extends Component<DisclaimerComponentArgs> {
  @service declare intl: IntlService;
  @tracked showMore = false;
  // Define your component's properties and methods here
  @action toggleReadMore() {
    this.showMore = !this.showMore;
  }

  get disclaimerText() {
    const disclaimer = this.args.createdByTeamtailor
      ? this.integrationDisclaimer
      : `${this.intl.t('marketplace.integration_developed_by', {
          name: this.args.name || '',
        })} ${this.integrationDisclaimer}`;

    return this.showMore
      ? disclaimer
      : `${disclaimer.slice(0, disclaimer.indexOf(' ', 120))}...`;
  }

  get integrationDisclaimer() {
    return this.args.name === 'Aboard'
      ? this.intl.t('marketplace.aboard_disclaimer')
      : this.intl.t('marketplace.integration_disclaimer');
  }

  get buttonText() {
    return this.showMore
      ? this.intl.t('marketplace.disclaimer_read_less')
      : this.intl.t('marketplace.disclaimer_read_more');
  }
}
