/* import __COLOCATED_TEMPLATE__ from './job-details-row.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { JobModel } from 'teamtailor/models';

export default class extends Component {
  @service store;
  @service analytics;

  get jobId() {
    return this.args.id;
  }

  get job() {
    return this.args.model || this.fetchJobTask.lastSuccessful?.value;
  }

  get deletedJob() {
    return !this.job || !(this.job instanceof JobModel);
  }

  fetchJobTask = dropTask(async () => {
    if (this.args.model) {
      return undefined;
    }

    try {
      return await this.store.findRecord('job', this.jobId);
    } catch {
      return undefined;
    }
  });
}
