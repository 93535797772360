/* import __COLOCATED_TEMPLATE__ from './person-with-avatar.hbs'; */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: 'c-person-with-avatar',
  name: null,
  size: 'medium',
  avatarDescriptionNormalSize: false,
  truncate: false,
  user: null,
  candidate: null,
  linkName: false,

  imageSize: computed('size', function () {
    if (this.size === 'x-small') {
      return '24';
    } else if (this.size === 'medium') {
      return '48';
    } else {
      return '32';
    }
  }),
});
