/* import __COLOCATED_TEMPLATE__ from './bar-chart-settings.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Settings from 'teamtailor/utils/insights/reports/settings';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';

const RECOMMENDED_MAX_VERTICAL_BAR_CHART_ITEMS = 20;
const RECOMMENDED_MAX_HORIZONTAL_BAR_CHART_ITEMS = 10;

type Args = {
  settings: Settings;
  rowCount?: number;
  onUpdate: (settings: Settings) => void;
  scrollContainerElement?: HTMLElement;
};

export default class ReportPieChartSettings extends Component<Args> {
  @service declare intl: IntlService;

  get scrollContainerElement() {
    return this.args.scrollContainerElement;
  }

  get currentSettings() {
    return this.args.settings;
  }

  get baseProperty() {
    return this.currentSettings.baseProperty;
  }

  get additionalBaseProperty() {
    return this.currentSettings.additionalBaseProperty;
  }

  get isHorizontal() {
    return this.currentSettings.direction === 'horizontal';
  }

  get showLimitAlert() {
    if (this.currentSettings.limit) {
      return false;
    }

    if (this.currentSettings.direction === 'horizontal') {
      return (
        (this.args.rowCount || 0) > RECOMMENDED_MAX_HORIZONTAL_BAR_CHART_ITEMS
      );
    }

    return (this.args.rowCount || 0) > RECOMMENDED_MAX_VERTICAL_BAR_CHART_ITEMS;
  }

  get limitOptions() {
    const numberArray = [];
    for (let i = 0; i < 100; i++) {
      numberArray.push({ value: `${i}` });
    }

    return numberArray;
  }

  get currentLimit() {
    return String(this.args.settings.limit);
  }

  get sortableProperties() {
    return [
      ...this.currentSettings.selectedProperties,
      this.baseProperty,
      this.additionalBaseProperty,
    ].filter((property) => property?.isSortable);
  }

  get sortDirectionOptions() {
    return [
      {
        name: 'asc',
        translatedName: this.intl.t(
          'insights.reports.builder.chart_settings.asc'
        ),
      },
      {
        name: 'desc',
        translatedName: this.intl.t(
          'insights.reports.builder.chart_settings.desc'
        ),
      },
    ];
  }

  @action
  toggleDirection() {
    this.currentSettings.direction =
      this.currentSettings.direction === 'horizontal'
        ? 'vertical'
        : 'horizontal';
    this.args.onUpdate(this.currentSettings);
  }

  @action
  handleUpdateSortProperty(property: BaseProperty) {
    if (
      this.currentSettings.sortProperty?.serialize() === property.serialize()
    ) {
      this.currentSettings.sortProperty = undefined;
    } else {
      this.currentSettings.sortProperty = property;
    }

    this.args.onUpdate(this.currentSettings);
  }
}
