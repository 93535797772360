/* import __COLOCATED_TEMPLATE__ from './uptrail.hbs'; */
import PromotionBaseClass from './promotion-base';
import { get } from 'teamtailor/utils/get';

export default class extends PromotionBaseClass {
  get uptrailCategories() {
    let parents = this.store
      .peekAll('uptrail-category')
      .filterBy('parent', null);

    return parents.map((parent) => {
      return {
        groupName: get(parent, 'title'),
        options: get(parent, 'children').toArray(),
      };
    });
  }

  get uptrailRegions() {
    let parents = this.store.peekAll('uptrail-region').filterBy('parent', null);

    return parents.map((parent) => {
      return {
        groupName: get(parent, 'name'),
        options: get(parent, 'children').toArray(),
      };
    });
  }
}
