/* import __COLOCATED_TEMPLATE__ from './hainesattract.hbs'; */
import PromotionBaseClass from './promotion-base';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

import ENV from 'teamtailor/config/environment';

export default class extends PromotionBaseClass {
  @service current;

  get user() {
    return this.current.user;
  }

  get hainesattractPostUrl() {
    return ENV.hainesattract.postUrl;
  }

  get hainesattractSystemIdentifier() {
    return ENV.hainesattract.systemIdentifier;
  }

  get channelActivation() {
    return get(this.current.company, 'channelActivations').findBy(
      'channelId',
      get(this, 'promotion.channel.id')
    );
  }
}
