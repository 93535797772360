/* import __COLOCATED_TEMPLATE__ from './time.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import moment from 'moment-timezone';

export default class extends Component {
  @service tick;

  @computed('args.time', 'tick.currentTime')
  get timeFrom() {
    const time = moment(this.args.time).tz(moment.tz.guess());
    return time?.from(this.tick.currentTime, true);
  }
}
