/* import __COLOCATED_TEMPLATE__ from './hired-applications.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const VISIBLE_APPLICATIONS_NUMBER = 6;

export default class extends Component {
  @tracked currentIndex = 0;

  get showDots() {
    return this.visibleApplications.length > 1;
  }

  get numberOfDots() {
    return 1 + this.visibleApplications.length;
  }

  get visibleApplications() {
    const applications = this.args.applications || [];
    if (applications.length <= VISIBLE_APPLICATIONS_NUMBER + 1) {
      return applications;
    } else {
      return applications.slice(0, VISIBLE_APPLICATIONS_NUMBER);
    }
  }

  get numberOfMoreApplications() {
    return this.args.applications.slice(VISIBLE_APPLICATIONS_NUMBER).length;
  }

  get averageTimeToHireInDays() {
    const { applications } = this.args;

    const totalTimeToHire = applications
      .mapBy('timeToHireInDays')
      .reduce((acc, i) => acc + i);

    return Math.round(totalTimeToHire / applications.length);
  }

  get averageTimeToFillInDays() {
    const { applications } = this.args;

    const timeToFillArray = applications
      .mapBy('timeToFillInDays')
      .filter((val) => !!val);
    const totalTimeToFill = timeToFillArray.reduce((acc, i) => acc + i, 0);

    if (timeToFillArray.length === 0) {
      return null;
    }

    return Math.round(totalTimeToFill / timeToFillArray.length);
  }

  get currentApplication() {
    if (this.currentIndex === 0) {
      return null;
    } else {
      return this.visibleApplications[this.currentIndex - 1];
    }
  }

  get numJobs() {
    return this.args.applications
      ?.map((app) => app.jobId)
      .filter((jobId, index, array) => array.indexOf(jobId) === index).length;
  }

  @action
  updateCurrentIndex(index, event) {
    event.stopPropagation();
    this.currentIndex = index;
  }
}
