import Model, {
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
  type AsyncBelongsTo,
} from '@ember-data/model';
import { CompanyModel, UserModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import Raw from 'teamtailor/transforms/raw';

export default class BatchJobModel extends Model {
  @belongsTo('batch-job', { inverse: 'subBatchJobs' })
  declare parentBatchJob: AsyncBelongsTo<BatchJobModel>;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @hasMany('batch-job', { inverse: 'parentBatchJob' })
  declare subBatchJobs: AsyncHasMany<BatchJobModel>;

  @attr('boolean') declare isComplete: boolean;
  @attr('boolean', { defaultValue: false }) declare isHidden: boolean;
  @attr('number') declare totalCount: number;
  @attr('string') declare batchAction: string;
  @attr('string') declare batchType: string;
  @attr('string') declare bid: string;
  @attr('string') declare companyName: string;
  @attr('raw') declare data: Raw;

  get isBulkCalendar() {
    return this.batchType === 'Calendar';
  }

  get isBulkCandidates() {
    return this.batchType === 'BulkCandidate';
  }

  get isBulkCareerSite() {
    return this.batchType === 'CareerSite';
  }

  get isBulkEmployees() {
    return this.batchType === 'BulkEmployee';
  }

  get isBulkJobs() {
    return this.batchType === 'BulkJob';
  }

  get isBulkRequisitions() {
    return this.batchType === 'BulkRequisition';
  }

  get isBulkScorecardCriteria() {
    return this.batchType === 'BulkScorecardCriteria';
  }

  get isBulkTags() {
    return this.batchType === 'BulkTags';
  }

  get isBulkCandidateReject() {
    return this.isBulkCandidates && this.batchAction === 'reject';
  }

  get isSubJob() {
    return !!get(this.parentBatchJob, 'id');
  }

  get isBulkCandidateSharingData() {
    return this.batchType === 'BulkCandidateSharingData';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'batch-job': BatchJobModel;
  }
}
