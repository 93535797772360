/* import __COLOCATED_TEMPLATE__ from './args.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface UnicornArgsArgs {
  component: string;
  componentArgs: string;
}

export default class UnicornArgs extends Component<UnicornArgsArgs> {
  @tracked arguments: unknown;

  constructor(owner: unknown, args: UnicornArgsArgs) {
    super(owner, args);
    this.setupComponentArgs();
  }

  setupComponentArgs() {
    // import args for the component
  }
}
