/* import __COLOCATED_TEMPLATE__ from './multi-choice.hbs'; */
/* eslint-disable ember/use-ember-get-and-set */
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import TranslationQuestionModel from 'teamtailor/models/translation/question';
import CopilotService from 'teamtailor/services/copilot';
import Current from 'teamtailor/services/current';

interface Args {
  changeset: BufferedChangeset;
  workingSet: BufferedChangeset | TranslationQuestionModel;
  isTranslation: boolean;
}

export type Alternative = {
  id: number;
  title: string;
};

export default class SettingsQuestionsMultiChoice extends Component<Args> {
  @service declare current: Current;
  @service declare copilot: CopilotService;

  get workingSet(): BufferedChangeset | TranslationQuestionModel {
    return this.args.workingSet;
  }

  isDisabled = (index: number): boolean => {
    if (!this.args.isTranslation) {
      return false;
    }

    const alts = this.args.changeset.get('formData') as Alternative[];
    return alts[index]?.title?.length === 0;
  };

  @action
  findPlaceholder(index: number): string | undefined {
    const alts = this.args.changeset.get('formData') as Alternative[];
    return alts[index]?.title;
  }

  @action
  handleFocusOut(): void {
    this.args.changeset.validate('alternatives');

    const formData = this.args.workingSet.get('formData');
    if (!this.args.isTranslation) {
      this.args.changeset.set('formData', [...formData.toArray()]);
    } else {
      set(this.workingSet, 'formData', [...formData.toArray()]);
    }
  }

  @action
  removeAlternative(id: number): void {
    const alts = this.args.changeset.get('formData') as Alternative[];
    this.args.changeset.set('formData', alts.rejectBy('id', id).toArray());
    this.syncRemoveTranslations(id);
  }

  private syncRemoveTranslations(id: number): void {
    const translations = this.args.changeset.get(
      'translations'
    ) as TranslationQuestionModel[];

    translations.forEach((t) => {
      const alts = t.formData.rejectBy('id', id);
      set(t, 'formData', alts.toArray());
    });
  }

  @action
  addNewAlternative(): void {
    const formData = this.args.changeset.get('formData') as Alternative[];

    const lastId = formData.lastObject?.id ?? 0;
    const alt = { id: lastId + 1, title: '' };
    this.args.changeset.set('formData', [...formData.toArray(), alt]);
    this.syncAddTranslations(alt.id);
  }

  private syncAddTranslations(id: number): void {
    const translations = this.args.changeset.get(
      'translations'
    ) as TranslationQuestionModel[];

    translations.forEach((t) => t.formData.pushObject({ id, title: '' }));
  }
}
