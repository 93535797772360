/* import __COLOCATED_TEMPLATE__ from './referrals.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

const REFERRAL_LIMIT = 5;

export default class ReferralsWidget extends WidgetClass {
  @service current;
  @service candidate;

  @tracked page = 1;
  @tracked isInitialLoad = true;

  get user() {
    return this.current.user;
  }

  get companyColor() {
    return get(this.current.company, 'design.color');
  }

  get userReferrals() {
    return get(this.user, 'referrals');
  }

  get userReferralsCount() {
    return this.userReferrals.length;
  }

  get hiredReferrals() {
    return this.userReferrals.filter(
      (r) => get(r, 'jobApplicationStatus') === 'hired'
    );
  }

  get hiredReferralsCount() {
    return this.hiredReferrals.length;
  }

  get hasReferral() {
    return this.userReferralsCount > 0;
  }

  get hasHiredReferral() {
    return this.hiredReferrals > 0;
  }

  get startIndex() {
    return (this.page - 1) * REFERRAL_LIMIT;
  }

  get endIndex() {
    return this.startIndex + REFERRAL_LIMIT;
  }

  get pageCount() {
    return Math.ceil(this.userReferralsCount / REFERRAL_LIMIT);
  }

  @action
  handlePaginationChange(page) {
    this.page = page;
  }
}
