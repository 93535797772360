/* import __COLOCATED_TEMPLATE__ from './start-of-week-day-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { IntlService } from 'ember-intl';
import TimeFormatService from 'teamtailor/services/time-format';

type StartOfWeekDayPickerComponentArgs = {
  defaultText?: string;
  onSelect?: (weekDay: string) => void;
};

export default class StartOfWeekDayPickerComponent extends Component<StartOfWeekDayPickerComponentArgs> {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  get defaultText() {
    return (
      this.args.defaultText ||
      this.intl.t('common.weekdays.system_default_start_of_week_day', {
        weekday: this.timeFormat.defaultWeekday.name,
      })
    );
  }

  @action
  onSelect(weekDay: { id: number; value: string; name: string }) {
    this.args.onSelect?.(weekDay.value);
  }
}
