/* import __COLOCATED_TEMPLATE__ from './pipeline-conversion.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import {
  DataRow,
  ProcessedStageTypeRow,
  formatData,
  inflateStageTypeRows,
  removeEmptyInProcess,
} from 'teamtailor/utils/insights/pipeline-conversion';

type Args = {
  data?: ProcessedStageTypeRow[];
  loading?: boolean;
};

export default class PipelineConversionsWidget extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  get data(): DataRow[] {
    if (!this.args.data) return [];

    let rows = [...this.args.data];
    const stageTypes = this.store.peekAll('stage-type').toArray();

    rows = inflateStageTypeRows(rows, stageTypes);
    return removeEmptyInProcess(
      formatData(rows, (row) => this.intl.t(`job.stage_types.${row.stageType}`))
    );
  }
}
