/* import __COLOCATED_TEMPLATE__ from './language.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import CareerSiteModel from 'teamtailor/models/career-site';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

declare interface LanguageOption {
  id: string;
  label: string;
}

export default class JobFilterInputsLanguageComponent extends Component {
  @service declare current: Current;
  @service declare intl: IntlService;

  get publishedCareerSites(): LanguageOption[] {
    return get(this.current.company, 'publishedCareerSites').map(
      (careerSite: CareerSiteModel) => {
        return {
          id: careerSite.languageCode,
          label: careerSite.translatedLanguage,
        };
      }
    );
  }

  get options(): LanguageOption[] {
    return [...this.publishedCareerSites];
  }
}
