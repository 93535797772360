/* import __COLOCATED_TEMPLATE__ from './location.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlipperService from 'teamtailor/services/flipper';
import {
  CustomLocationClass,
  LocationType,
  MeetingEventClass,
  MeetingEventLocationLocationClass,
  VideoMeetingLocationClass,
} from 'teamtailor/classes/meetings';
import {
  ICompany,
  IConnectedConference,
  ILocation,
} from 'teamtailor/components/meeting/types';
import { LocationModel } from 'teamtailor/models';
import { trackedFunction } from 'ember-resources/util/function';
import { fetchLocations } from 'teamtailor/utils/locations-picker';
import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';

interface Args {
  event: MeetingEventClass;
  company: ICompany;
  connectedConferences: IConnectedConference[];
}

type RegionLocations = {
  [key: string]: LocationModel[];
};

export default class MeetingEventRowsLocation extends Component<Args> {
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;
  @service declare store: Store;

  @tracked declare customLocation?: CustomLocationClass;
  @tracked searchTerm = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.event.meetingEventLocation instanceof CustomLocationClass) {
      this.customLocation = this.args.event.meetingEventLocation;
    }
  }

  get event() {
    return this.args.event;
  }

  get triggerText() {
    if (this.event.meetingEventLocation) {
      return (
        (this.event.meetingEventLocation as MeetingEventLocationLocationClass)
          .location?.nameOrCity ||
        (this.event.meetingEventLocation as VideoMeetingLocationClass)
          .videoMeeting?.appName ||
        (this.event.meetingEventLocation as CustomLocationClass).customLocation
          .description
      );
    }

    return this.intl.t('components.meetings.event_scheduler.select');
  }

  get allLocations() {
    return this.fetchLocationsFunction.value || [];
  }

  groupLocationsByRegion(locations: LocationModel[]) {
    return locations.reduce((acc: RegionLocations, location) => {
      const regionName = get(location.region, 'name');
      if (acc[regionName]) {
        acc[regionName]?.push(location);
      } else {
        acc[regionName] = [location];
      }

      return acc;
    }, {});
  }

  get companyLocationOptions() {
    let options = [];
    const locationsWithRegion = this.allLocations.filter(
      (location) => !location.withoutRegion
    );

    if (locationsWithRegion.length) {
      const groupedLocations = this.groupLocationsByRegion(locationsWithRegion);

      Object.keys(groupedLocations)
        .sort((a, b) => a.localeCompare(b, 'sv'))
        .forEach((key) => {
          options.push({
            groupName: key,
            options: groupedLocations[key]?.map((l: LocationModel) =>
              this.convertLocation(l)
            ),
          });
        });
    }

    const locationsWithoutRegion = this.allLocations.filter(
      (location) => location.withoutRegion
    );
    if (locationsWithoutRegion.length) {
      options.push({
        groupName: this.intl.t('common.other_locations'),
        options: locationsWithoutRegion
          .sort((a, b) => a.nameOrCity.localeCompare(b.nameOrCity, 'sv'))
          .map((l: LocationModel) => this.convertLocation(l)),
      });
    }

    return options;
  }

  get remoteLocationOptions() {
    return this.args.connectedConferences.map((c) => {
      if (
        this.event.meetingEventLocation instanceof VideoMeetingLocationClass &&
        this.event.meetingEventLocation.videoMeeting?.providerName ===
          c.providerName
      ) {
        return this.event.meetingEventLocation;
      } else {
        return new VideoMeetingLocationClass({
          videoMeeting: {
            providerName: c.providerName,
            appName: c.appName,
            description:
              c.providerName === 'teamtailor'
                ? this.intl.t(
                    'components.meetings.event_scheduler.locations.teamtailor_meeting_description'
                  )
                : null,

            meetingDuration: '',
            meetingUrl: '',
          },
        });
      }
    });
  }

  get locations() {
    return this.filterLocations(this.searchTerm);
  }

  convertLocation(location: LocationModel) {
    if (
      this.event.meetingEventLocation instanceof
        MeetingEventLocationLocationClass &&
      this.event.meetingEventLocation.location?.id === location.id
    ) {
      return this.event.meetingEventLocation;
    } else {
      const locationClass = {
        id: location.id,
        nameOrCity: location.nameOrCity,
        fullAddress: location.fullAddress,
        long: location.long,
        lat: location.lat,
        regionId: location.belongsTo('region').id(),
      } as ILocation;

      return new MeetingEventLocationLocationClass({
        location: locationClass,
      });
    }
  }

  isMatchingTerm(term: string, name?: string) {
    return !!name?.toLowerCase().includes(term.toLowerCase());
  }

  filterLocations(term: string) {
    const remoteLocationOptions = this.remoteLocationOptions.filter(
      (l: VideoMeetingLocationClass) =>
        this.isMatchingTerm(term, l.videoMeeting?.providerName)
    );

    const companyLocationOptions = [];
    for (const locationGroup of this.companyLocationOptions) {
      const group = locationGroup;

      const filteredOptions = group.options.filter(
        (l: MeetingEventLocationLocationClass) =>
          this.isMatchingTerm(term, l.location?.nameOrCity)
      );
      if (filteredOptions.length) {
        companyLocationOptions.push({
          ...group,
          options: filteredOptions,
        });
      }
    }

    return { remoteLocationOptions, companyLocationOptions };
  }

  fetchLocationsFunction = trackedFunction(this, async () => {
    const query = { name_or_city: this.searchTerm, page: 1 };
    const locations = await fetchLocations(this.store, query);
    return locations;
  });

  @action
  clearLocation() {
    this.event.meetingEventLocation = undefined;
  }

  @action
  locationFilterCallback(event: InputEvent) {
    const term = (event.target as HTMLInputElement).value;
    this.searchTerm = term;
    if (term === '') {
      this.customLocation = undefined;
    } else {
      const customLocation = new CustomLocationClass({
        customLocation: { description: term },
      });
      this.customLocation = customLocation;
    }
  }

  @action
  selectLocation(location: LocationType, selected: boolean) {
    this.event.meetingEventLocation = selected ? undefined : location;
  }

  @action
  onCloseDropdown() {
    this.searchTerm = '';
  }
}
