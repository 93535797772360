import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import Store from '@ember-data/store';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import JobApplicationModel from 'teamtailor/models/job-application';
import JobDetailModel from 'teamtailor/models/job-detail';
import QuickRejectSettingModel from 'teamtailor/models/quick-reject-setting';
import StageTypeModel from 'teamtailor/models/stage-type';
import TriggerHireQualitySurveyModel from 'teamtailor/models/trigger/hire-quality-survey';
import TriggerMessageModel from 'teamtailor/models/trigger/message';
import TriggerNoteModel from 'teamtailor/models/trigger/note';
import TriggerNpsModel from 'teamtailor/models/trigger/nps';
import TriggerNurtureModel from 'teamtailor/models/trigger/nurture';
import TriggerPredictionModel from 'teamtailor/models/trigger/prediction';
import TriggerRestrictModel from 'teamtailor/models/trigger/restrict';
import TriggerShareModel from 'teamtailor/models/trigger/share';
import TriggerSmartMoveModel from 'teamtailor/models/trigger/smart-move';
import TriggerSmartScheduleModel from 'teamtailor/models/trigger/smart-schedule';
import TriggerSurveyModel from 'teamtailor/models/trigger/survey';
import TriggerTagModel from 'teamtailor/models/trigger/tag';
import TriggerTodoModel from 'teamtailor/models/trigger/todo';
import TriggerWebhookModel from 'teamtailor/models/trigger/webhook';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';
import TriggerAskForFeedbackModel from './trigger/ask-for-feedback';
import StageJobApplicationCountModel from './stage-job-application-count';
import UserStageRestrictionModel from 'teamtailor/models/user-stage-restriction';

type JobApplicationMeta = {
  total_pages: number;
  total: number;
};

export default class StageModel extends Model {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare server: Server;

  @belongsTo('job-detail') declare jobDetail: AsyncBelongsTo<JobDetailModel>;
  @belongsTo('quick-reject-setting')
  declare quickRejectSetting: AsyncBelongsTo<QuickRejectSettingModel>;

  @belongsTo('stage-type', { async: false })
  declare stageType: StageTypeModel | null;

  @belongsTo('stage-job-application-count')
  declare jobApplicationCount: AsyncBelongsTo<StageJobApplicationCountModel>;

  @hasMany('job-application', {
    inverse: 'activeStage',
    async: false,
  })
  declare activeJobApplications: SyncHasMany<JobApplicationModel>;

  @hasMany('job-application', {
    inverse: 'rejectedStage',
    async: false,
  })
  declare rejectedJobApplications: SyncHasMany<JobApplicationModel>;

  @hasMany('job-application', { async: false })
  declare jobApplications: SyncHasMany<JobApplicationModel>;

  @hasMany('trigger/restrict', { async: false })
  declare triggerRestricts: SyncHasMany<TriggerRestrictModel>;

  @hasMany('trigger/ask-for-feedback', { async: false })
  declare triggerAskForFeedback: SyncHasMany<TriggerAskForFeedbackModel>;

  @hasMany('trigger/hire-quality-survey', { async: false })
  declare triggerHireQualitySurveys: SyncHasMany<TriggerHireQualitySurveyModel>;

  @hasMany('trigger/message', { async: false })
  declare triggerMessages: SyncHasMany<TriggerMessageModel>;

  @hasMany('trigger/note', { async: false })
  declare triggerNotes: SyncHasMany<TriggerNoteModel>;

  @hasMany('trigger/nps', { async: false })
  declare triggerNps: SyncHasMany<TriggerNpsModel>;

  @hasMany('trigger/nurture', { async: false })
  declare triggerNurtures: SyncHasMany<TriggerNurtureModel>;

  @hasMany('trigger/share', { async: false })
  declare triggerShares: SyncHasMany<TriggerShareModel>;

  @hasMany('trigger/smart-move', { async: false })
  declare triggerSmartMoves: SyncHasMany<TriggerSmartMoveModel>;

  @hasMany('trigger/smart-schedule', { async: false })
  declare triggerSmartSchedules: SyncHasMany<TriggerSmartScheduleModel>;

  @hasMany('trigger/tag', { async: false })
  declare triggerTags: SyncHasMany<TriggerTagModel>;

  @hasMany('trigger/todo', { async: false })
  declare triggerTodos: SyncHasMany<TriggerTodoModel>;

  @hasMany('trigger/webhook', { async: false })
  declare triggerWebhooks: SyncHasMany<TriggerWebhookModel>;

  @hasMany('trigger/prediction', { async: false })
  declare triggerPredictions: SyncHasMany<TriggerPredictionModel>;

  @hasMany('trigger/survey', { async: false })
  declare triggerSurveys: SyncHasMany<TriggerSurveyModel>;

  @hasMany('trigger/smart-move', {
    inverse: 'proceedStage',
  })
  declare proceedStageTriggerSmartMoves: AsyncHasMany<TriggerSmartMoveModel>;

  @hasMany('trigger/prediction', {
    inverse: 'proceedStage',
  })
  declare proceedStageTriggerPredictions: AsyncHasMany<TriggerPredictionModel>;

  @hasMany('trigger/webhook', {
    inverse: 'proceedStage',
  })
  declare proceedStageTriggerWebhooks: AsyncHasMany<TriggerWebhookModel>;

  @hasMany('trigger/smart-schedule', {
    inverse: 'proceedStage',
  })
  declare proceedStageTriggerSmartSchedule: AsyncHasMany<TriggerSmartScheduleModel>;

  @hasMany('user-stage-restriction', { async: false })
  declare userStageRestrictions: SyncHasMany<UserStageRestrictionModel>;

  @attr('boolean') declare anonymous: boolean;
  @attr('boolean', { allowNull: true }) declare hirePageEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare hired: boolean;
  @attr('boolean', { defaultValue: false }) declare inbox: boolean;
  @attr('date') declare milestone: Date;
  @attr('number') declare guideTime: number;
  @attr('number') declare jobId: number;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('string') declare name: string;
  @attr('string') declare sortDirection: string;
  @attr('string') declare sortValue: string;

  @tracked _activeJobApplicationsCount: number | null = null;
  @tracked _rejectedJobApplicationsCount: number | null = null;
  @tracked allCandidatesSelected = false;

  get job() {
    return get(this.jobDetail, 'job');
  }

  get triggers() {
    return [
      ...this.triggerRestricts.toArray(),
      ...this.triggerAskForFeedback.toArray(),
      ...this.triggerHireQualitySurveys.toArray(),
      ...this.triggerMessages.toArray(),
      ...this.triggerNotes.toArray(),
      ...this.triggerNps.toArray(),
      ...this.triggerNurtures.toArray(),
      ...this.triggerShares.toArray(),
      ...this.triggerSmartMoves.toArray(),
      ...this.triggerSmartSchedules.toArray(),
      ...this.triggerTags.toArray(),
      ...this.triggerTodos.toArray(),
      ...this.triggerWebhooks.toArray(),
      ...this.triggerPredictions.toArray(),
      ...this.triggerSurveys.toArray(),
    ].compact();
  }

  get activeTriggers() {
    return this.triggers.filter((trigger) => !trigger.onReject);
  }

  get totalCount() {
    return this.activeJobApplicationsCount || this.rejectedJobApplicationsCount;
  }

  get rejectedJobApplicationsCount() {
    return this._rejectedJobApplicationsCount || 0;
  }

  get activeJobApplicationsCount() {
    return this._activeJobApplicationsCount || 0;
  }

  get classId() {
    return `stage-${get(this, 'id')}`;
  }

  get displayName() {
    if (this.inbox) {
      return this.intl.t('models.stage.inbox');
    } else if (this.hired) {
      return this.intl.t('models.stage.hired');
    } else {
      return this.name;
    }
  }

  get isEditable() {
    return !this.inbox && !this.hired;
  }

  get rejectedCount() {
    return get(this.jobApplicationCount, 'rejectedCount');
  }

  get activeCount() {
    return get(this.jobApplicationCount, 'activeCount');
  }

  get overdueGuideTimeCount() {
    return get(this.jobApplicationCount, 'overdueGuideTimeCount');
  }

  get hasRejectedJobApplications() {
    return !!this.rejectedCount;
  }

  get hasActiveJobApplications() {
    return !!this.activeCount;
  }

  get hasApplications() {
    return this.hasRejectedJobApplications || this.hasActiveJobApplications;
  }

  get partnerResults() {
    return get(this.job, 'partnerResults');
  }

  get activeCandidates() {
    const activeJobApplications = get(this, 'activeJobApplications').slice();
    return activeJobApplications.map((jobApplication) => {
      return get(jobApplication, 'candidate');
    });
  }

  async triggerApplicationsFetchForDeleteStatusCheck() {
    if (this.isNew) return;

    if (this._activeJobApplicationsCount) {
      const active = await this.fetchJobApplicationPage({
        page: 1,
        rejected: false,
        perPage: 1,
      });
      this._activeJobApplicationsCount = active.meta.total;
    }

    if (this._rejectedJobApplicationsCount === null) {
      const rejected = await this.fetchJobApplicationPage({
        page: 1,
        rejected: true,
        perPage: 1,
      });
      this._rejectedJobApplicationsCount = rejected.meta.total;
    }
  }

  async fetchJobApplicationPage({
    page,
    rejected,
    perPage = 15,
    filter,
  }: {
    page: number;
    rejected: boolean;
    perPage: number;
    filter?: Record<string, unknown>;
  }) {
    return this.store.query('job-application', {
      stage_id: this.id,
      rejected,
      page,
      per_page: perPage,
      filter,
    });
  }

  async quickMoveRejectedCandidates(data: JQueryAjaxSettings) {
    return this.server.memberAction(this, {
      action: 'quick_move_rejected_job_applications',
      options: { data },
    });
  }

  async fetchJobApplicationChunk({
    page,
    rejected,
    filter,
    loadAll,
  }: {
    page: number;
    rejected: boolean;
    filter: Record<string, unknown>;
    loadAll: boolean;
  }) {
    let result = await this.fetchJobApplicationPage({
      page,
      rejected,
      perPage: 15,
      filter,
    });

    if (page === 1) {
      this.jobApplications.setObjects(result.slice());
      set(this, this.getTotalCountProperty(rejected), result.meta?.total || 0);
    } else {
      this.jobApplications.pushObjects(result.slice());
    }

    if (loadAll) {
      if (page <= result.meta.total_pages) {
        result = await this.fetchJobApplicationChunk({
          page: page + 1,
          rejected,
          filter,
          loadAll,
        });
      }
    }

    return { ...result.meta, page };
  }

  _rejected?: boolean;
  _page = 0;
  _meta?: JobApplicationMeta | null;
  _filter = {};

  fetchJobApplicationsTask = restartableTask(
    async ({ rejected, reload = false, filter = {}, loadAll = false }) => {
      if (filter !== this._filter || reload || rejected !== this._rejected) {
        this._page = 0;
        this._meta = null;
      }

      this._rejected = rejected;
      this._page++;
      this._filter = filter;

      if (!this._meta || this._page <= this._meta.total_pages) {
        this._meta = await this.fetchJobApplicationChunk({
          page: this._page,
          rejected,
          filter,
          loadAll,
        });
      }

      return this._meta;
    }
  );

  getTotalCountProperty(rejected: boolean) {
    return rejected
      ? '_rejectedJobApplicationsCount'
      : '_activeJobApplicationsCount';
  }

  changeCount(rejected: boolean, amount = 1) {
    const property = this.getTotalCountProperty(rejected);
    const count = get(this, property) || 0;
    set(this, property, count + amount);
  }

  incrementCount(rejected: boolean) {
    set(this.jobApplicationCount, 'activeCount', this.activeCount + 1);
    this.changeCount(rejected);
  }

  decrementCount(rejected: boolean) {
    set(this.jobApplicationCount, 'activeCount', this.activeCount - 1);
    this.changeCount(rejected, -1);
  }

  refreshCounts() {
    this._activeJobApplicationsCount = null;
    this._rejectedJobApplicationsCount = null;
    this.store.findRecord('stage-job-application-count', this.id);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    stage: StageModel;
  }
}
