/* import __COLOCATED_TEMPLATE__ from './title.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { MeetingEventClass } from 'teamtailor/classes/meetings';
import PreparedMessageClass from 'teamtailor/classes/prepared-message';
import IntlService from 'ember-intl/services/intl';

interface Args {
  event: MeetingEventClass;
  preparedMessage: PreparedMessageClass;
}

export default class MeetingEventRowsTitle extends Component<Args> {
  @service declare intl: IntlService;

  @tracked declare titleElement: HTMLElement;
  @tracked showTitleInput = false;

  get event(): MeetingEventClass {
    return this.args.event;
  }

  get summaryPlaceholder(): string {
    const { subject } = this.args.preparedMessage;

    return subject.length > 0
      ? subject
      : this.intl.t('components.meetings.event_scheduler.add_title');
  }

  @action
  registerTitleElement(element: HTMLElement): void {
    this.titleElement = element;
  }

  @action
  async focusTitleElement(): Promise<void> {
    this.showTitleInput = true;
    await timeout(300);
    this.titleElement.focus();
  }

  @action
  titleElementLostFocus(): void {
    this.showTitleInput = false;
  }
}
