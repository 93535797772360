/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { NoteModel, ActivityModel } from 'teamtailor/models';
import toggleReaction from 'teamtailor/utils/toggle-reaction';
import CurrentService from 'teamtailor/services/current';
import ScrollPosition from 'teamtailor/utils/scroll-position';
import Store from '@ember-data/store';
import CachedFeed, {
  CandidateFeedSignature,
} from 'teamtailor/components/cached-feed';
import { modifier } from 'ember-modifier';
export default class CandidateModalNewRightSidebarCollaborate extends CachedFeed<CandidateFeedSignature> {
  @service declare current: CurrentService;
  @service declare store: Store;

  declare scrollPosition: ScrollPosition;

  constructor(owner: unknown, args: CandidateFeedSignature['Args']) {
    super(owner, args, 'note');
  }

  get notes() {
    return this.feed.records ? this.feed.records.slice().reverse() : [];
  }

  async onJobApplicationChange() {
    await super.onJobApplicationChange();
    this.scrollPosition.scrollToBottom();
  }

  @action
  async loadMore() {
    this.scrollPosition.setPosition();
    await this.feed.loadMore();
    this.scrollPosition.restorePosition();
  }

  @action
  addActivity(item: ActivityModel) {
    const note = this.store.peekRecord('note', item.id);
    if (note) {
      this.feed.addRecord(note);
    }

    this.scrollPosition.scrollToBottom();
  }

  @action
  addReaction(note: NoteModel, emoji: string) {
    toggleReaction(note, this.current.user, emoji);
  }

  @action
  async handleDestroyNote(note: NoteModel) {
    await note.destroyRecord();
    this.feed.removeRecord(note);
  }

  onInsert = modifier((element: HTMLElement) => {
    this.scrollPosition = new ScrollPosition(element);
  });
}
