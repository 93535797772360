/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { RenderedSection } from 'teamtailor/components/candidate-modal-new/main/observe-sections';
import { later } from '@ember/runloop';
interface Args {
  sections: RenderedSection[];
  focusedSection: string;
  sectionsInView: string[];
}

export default class extends Component<Args> {
  @service declare intl: IntlService;

  @action
  focused(section: RenderedSection) {
    return this.args.focusedSection === section.id;
  }

  @action
  inView(section: RenderedSection) {
    return (
      section.open &&
      this.args.sectionsInView.includes(section.id) &&
      !(this.args.focusedSection === section.id)
    );
  }

  @action
  outsideView(section: RenderedSection) {
    return (
      section.open &&
      !this.args.sectionsInView.includes(section.id) &&
      !(this.args.focusedSection === section.id)
    );
  }

  @action
  collapsed(section: RenderedSection) {
    return !section.open;
  }

  @action
  handleSectionClick(section: RenderedSection) {
    // Do this manually in order to place the clicked section in middle of the viewport
    history.replaceState(null, '', `#${section.id}`);
    window.dispatchEvent(new Event('hashchange'));

    // Give 'hashchange' listeners some time to update the view
    later(() => {
      const element = document.querySelector(
        `[data-type="section"][data-section-id="${section.id}"]`
      );

      if (element) {
        const anchor = element.querySelector('[data-section-scroll-anchor]');

        if (!anchor) {
          return;
        }

        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 200);
  }
}
