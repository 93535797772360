/* import __COLOCATED_TEMPLATE__ from './competence-scores.hbs'; */
import Component from '@glimmer/component';
import { SCORE_VALUE_ADJUSTMENT } from 'teamtailor/constants/scorecard-scores';
import InterviewModel from 'teamtailor/models/interview';
import ScorecardCriteriumModel from 'teamtailor/models/scorecard-criterium';
import flatten from 'teamtailor/utils/flatten';
import { get } from 'teamtailor/utils/get';

interface CandidateModalNewMainSectionInterviewsCompetenceScoresArgs {
  scorecardCriterium: ScorecardCriteriumModel;
  interviews: InterviewModel[];
}

export default class CandidateModalNewMainSectionInterviewsCompetenceScores extends Component<CandidateModalNewMainSectionInterviewsCompetenceScoresArgs> {
  scoreValueAdjustment = SCORE_VALUE_ADJUSTMENT;

  get allScores() {
    return flatten(this.args.interviews.mapBy('scores'));
  }

  get relevantScores() {
    return this.allScores.filterBy(
      'criteriumId',
      get(this.args.scorecardCriterium, 'id')
    );
  }
}
