/* import __COLOCATED_TEMPLATE__ from './date-time-picker.hbs'; */
import DropdownMenu from 'teamtailor/components/core/dropdown-menu';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';
import { isArray } from '@ember/array';

export default class CoreDateTimePicker extends DropdownMenu {
  @service timeFormat;

  get format() {
    let format = this.args.dateFormat || 'YYYY-MM-DD';

    if (this.enableTime) {
      format += ` ${this.timeFormat.format}`;
    }

    return format;
  }

  get enableTime() {
    return this.args.enableTime ?? true;
  }

  get hasSelectedValue() {
    return !isEmpty(this.args.selected);
  }

  get formattedValue() {
    if (this.args.triggerText) {
      return this.args.triggerText;
    }

    if (isEmpty(this.args.value)) {
      return null;
    }

    return moment(this.args.value)
      .locale(this.timeFormat.locale)
      .format(this.format);
  }

  @action
  setDate(dates) {
    if (this.args.closeOnSelect) {
      this.api.close();
    }

    if (isArray(this.args.value) || this.args.mode === 'range') {
      return dates;
    }

    return new Date(dates[0]);
  }
}
