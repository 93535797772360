import { action } from '@ember/object';
import Controller from '@ember/controller';
import FromRoute from 'teamtailor/routes/jobs/index/jobs-list/copy';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class JobsJobCopyController extends Controller {
  @service declare router: RouterService;

  declare model: ModelFrom<FromRoute>;

  @action
  modalClosedAction() {
    this.router.transitionTo('jobs.index');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.index.jobs-list.copy': JobsJobCopyController;
  }
}
