/* import __COLOCATED_TEMPLATE__ from './send-nps.hbs'; */
import BaseComponent from './base';
import { action, get } from '@ember/object';

export default class SendNpsComponent extends BaseComponent {
  @action
  sendNps() {
    return this.postAction(
      'bulk_nps',
      this.postData({
        job_id: get(this.args, 'job.id'),
      })
    ).then(() => {
      this.args.onClose();
    });
  }
}
