import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

export default class JobJobPromoteChannelActivateController extends Controller {
  @service flipper;
  @service current;
  @service router;
  @service intl;
  @service permissions;

  @tracked providerKeyData = {};

  @tracked selectedActivationType = this.model.channel.defaultActivationType;

  get hasPermission() {
    return this.permissions.has('promote/manage');
  }

  get supportMessage() {
    return `Hi, I would like to use my existing agreement with ${this.model.channel.name}.`;
  }

  get supportSubject() {
    return `Activate ${this.model.channel.name}`;
  }

  get stepstoneCredentialsPresent() {
    return (
      this.model.channelActivation.stepstoneDeOrganisationId &&
      this.model.channelActivation.stepstoneDeSenderId
    );
  }

  get hotelCareerCredentialsPresent() {
    return (
      this.channelActivation.stepstoneDeOrganisationId &&
      this.channelActivation.stepstoneDeSenderId &&
      this.channelActivation.stepstoneDeHotId
    );
  }

  get monsterCredentialsPresent() {
    return (
      this.model.channelActivation.monsterUsername &&
      this.model.channelActivation.monsterPassword
    );
  }

  get currency() {
    return this.model.currency;
  }

  get buttonIsDisabled() {
    if (!this.hasPermission) return true;
    if (!this.isExistingAgreement) return false;

    switch (this.model.channel.templateName) {
      case 'stepstone_de':
        return (
          this.selectedActivationType === 'existing_agreement' &&
          !this.stepstoneCredentialsPresent
        );
      case 'ams':
        return (
          isEmpty(this.model.channelActivation.amsSenderId) ||
          isEmpty(get(this.current.company, 'organizationNumber'))
        );
      case 'finn':
        return isEmpty(this.model.channelActivation.finnClientId);
      case 'blocket':
        return isEmpty(get(this.current.company, 'organizationNumber'));
      case 'monster':
        return (
          this.selectedActivationType === 'existing_agreement' &&
          !this.monsterCredentialsPresent
        );
      case 'hotel_career':
        return (
          this.selectedActivationType === 'existing_agreement' &&
          !this.hotelCareerCredentialsPresent
        );
      default:
        return false;
    }
  }

  get isExistingAgreement() {
    return this.selectedActivationType === 'existing_agreement';
  }

  @action
  async save() {
    await this.current.company.save();
    this.model.channelActivation.existingAgreement = this.isExistingAgreement;
    this.model.channelActivation.adapterAuthorizationToken =
      this.providerKeyData;
    await this.model.channelActivation.save();

    later(this.handleAfterSave, 500);
  }

  @action handleAfterSave() {
    if (this.model.channel.hasAdapter) {
      return this.router.transitionTo(
        'jobs.job.promote.channel.external',
        this.model.channel,
        {
          queryParams: { currency: this.currency },
        }
      );
    }

    if (this.model.channel.isAlwaysIncluded) {
      return this.router.transitionTo('jobs.job.promote');
    } else {
      return this.router.transitionTo(
        'jobs.job.promote.pick',
        this.model.channel,
        {
          queryParams: {
            currency: this.currency,
          },
        }
      );
    }
  }

  @action
  goBack() {
    return this.router.transitionTo('jobs.job.promote');
  }
}
