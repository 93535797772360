/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TabName } from 'teamtailor/utils/candidate-modal/tabs';
import JobApplicationModel from 'teamtailor/models/job-application';
import CandidateModel from 'teamtailor/models/candidate';
import PermissionsService from 'teamtailor/services/permissions';
import { CoreModalBaseApi } from 'teamtailor/components/core/modal/base';

interface Args {
  candidate: CandidateModel;
  activeTab: TabName;
  onTabChange: (tab: TabName) => void;
  onClose: () => void;
  jobApplication?: JobApplicationModel;
  isAnonymousStage?: boolean;
  jobApplicationId?: string;
}

export default class CandidateModalNew extends Component<Args> {
  @service declare permissions: PermissionsService;

  @tracked isOpen = true;

  keyboardPriority = 0;

  get userHasAccessToCandidate() {
    return (
      !this.args.candidate.restricted ||
      this.permissions.has('candidate/access_restricted')
    );
  }

  @action
  handleClose() {
    this.isOpen = false;
    this.args.onClose();
  }

  @action
  registerModal(api: CoreModalBaseApi) {
    this.keyboardPriority = api.keyboardPriority;
  }
}
