/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class UnicornTabs extends Component {
  @tracked activeTab = 'code';

  @action
  handleTabClick(tab: string) {
    this.activeTab = tab;
  }
}
