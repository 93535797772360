/* import __COLOCATED_TEMPLATE__ from './template-picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import CannedResponseModel from 'teamtailor/models/canned-response';

interface IArgs {
  templates: CannedResponseModel[];
  selected?: CannedResponseModel;
  languageCode: string;
  onChange?: (args: CannedResponseModel) => void;
}

export default class MessageTemplateComponent extends Component<IArgs> {
  @action
  selectMessageTemplate(cannedResponse: CannedResponseModel): void {
    this.args.onChange?.(cannedResponse);
  }
}
