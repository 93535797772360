/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import Component from '@ember/component';

export default Component.extend({
  classNames: ['c-form-expandable-box__footer'],

  onCancel: null,
  onDelete: null,
  onSave: null,

  showDeleteButton: true,
  showSaveButton: true,
});
