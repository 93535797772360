/* import __COLOCATED_TEMPLATE__ from './knot.hbs'; */
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';

const DEFAULT_SIZE = 'medium';
const ALLOWED_SIZES = ['small', DEFAULT_SIZE, 'large'] as const;
type AllowedSizes = (typeof ALLOWED_SIZES)[number];

export type Args = {
  size?: AllowedSizes;
};

export default class KnotComponent extends Component<Args> {
  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'knot @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }
}
