/* import __COLOCATED_TEMPLATE__ from './unsupported-browser.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const UNSUPPORTED_BROWSER = (function () {
  let jscriptVersion = new Function('/*@cc_on return @_jscript_version; @*/')();
  return jscriptVersion !== undefined;
})();
const IS_IE11 = !!window.MSInputMethodContext && !!document.documentMode;

export default class UnsupportedBrowserComponent extends Component {
  unsupportedBrowser = UNSUPPORTED_BROWSER;
  isIE11 = IS_IE11;
  supportUrl =
    'https://support.teamtailor.com/en/articles/1888992-system-requirements-and-supported-web-browsers';

  @tracked _dismissed = false;

  get hasNotDismissed() {
    if (this.args.hideDissmissButton) return true;
    return !this._dismissed;
  }

  @action
  dismiss() {
    this._dismissed = true;
  }
}
