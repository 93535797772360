/* import __COLOCATED_TEMPLATE__ from './name.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import CustomFieldModel from 'teamtailor/models/custom-field';
import PickedCustomFieldModel from 'teamtailor/models/picked-custom-field';
import { get } from 'teamtailor/utils/get';

export interface Args {
  field: CustomFieldModel | PickedCustomFieldModel;
  raw?: boolean;
}

export default class extends Component<Args> {
  @service declare current: CurrentService;
  @tracked showTooltip = false;

  get field() {
    if (this.args.field instanceof PickedCustomFieldModel) {
      return get(this.args.field, 'customField');
    }

    return this.args.field;
  }

  get translations() {
    return get(this.field, 'translations');
  }

  get translation() {
    return this.translations.findBy('languageCode', this.currentLanguage);
  }

  get currentLanguage() {
    return this.current.locale;
  }

  get i18n_name() {
    if (this.current.company.hasFeature('career_site_languages')) {
      return this.translation?.name || get(this.field, 'name');
    }

    return get(this.field, 'name');
  }

  @action
  checkForOverflow(element: HTMLElement): void {
    if (element.offsetWidth < element.scrollWidth) {
      this.showTooltip = true;
      element.classList.add('text-ellipsis');
    }
  }
}
