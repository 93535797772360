/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';
import JobModel from 'teamtailor/models/job';
import { LOADING_COLUMNS } from 'teamtailor/constants/jobs';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import FlipperService from 'teamtailor/services/flipper';

type JobsTableArgs = {
  jobs: JobModel[];
};

export default class JobsTableComponent extends Component<JobsTableArgs> {
  loadingColumns = LOADING_COLUMNS;
  @service declare current: Current;
  @service declare flipper: FlipperService;

  @tracked isActionLoading = false;

  get isUserExternalRecruiter(): boolean {
    return this.current.user.externalRecruiter;
  }

  get columns() {
    return [
      {
        label: 'common.title',
        width: 400,
        selectable: true,
      },
      ...(!this.isUserExternalRecruiter
        ? [
            {
              label: 'job.stages',
              width: 230,
              classes: 'hidden lg:table-cell',
              conditional: true,
            },
          ]
        : []),
      {
        label: 'common.created',
        width: this.isUserExternalRecruiter ? 200 : 80,
      },
      {
        label: 'common.location',
        width: 200,
      },
      ...(!this.isUserExternalRecruiter
        ? [
            {
              label: 'components.hiring_team.recruiter',
              width: 200,
            },
          ]
        : []),
    ];
  }

  @action
  handleActionLoading(isLoading: boolean) {
    this.isActionLoading = isLoading;
  }
}
