/* import __COLOCATED_TEMPLATE__ from './subscription-info.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import Server from 'teamtailor/services/server';
import Store from '@ember-data/store';

export default class SubscriptionInfoComponent extends Component {
  @service declare current: Current;
  @service declare pusher: PusherService;
  @service declare server: Server;
  @service declare store: Store;

  @tracked isLoaded = false;
  private declare channel?: PusherChannel;
  @tracked showContactFinance = false;
  @tracked error = null;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.bindPusher();
    this.updateStore();
  }

  async updateStore() {
    const response = await this.fetchSubscriptionContracts();
    if (response.status === 200) {
      const data = await response.json();
      this.store.pushPayload(data as any);
      this.isLoaded = true;
    }

    if (!response.ok) {
      const data: any = await response.json();
      if (data.error === 'No account on company') {
        this.showContactFinance = true;
      } else {
        this.error = data.error;
      }
    }
  }

  async fetchSubscriptionContracts() {
    const adapter = this.store.adapterFor('application');
    const url = adapter.buildURL('subscription-contract');
    return this.server.fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async bindPusher() {
    this.channel = await this.pusher.channel(
      this.current.user.notificationsChannel
    );
    this.channel.bind('subscription_contracts', (payload) => {
      if (payload.error) {
        this.error = payload.error;
        return;
      }

      this.store.pushPayload(payload);
      this.isLoaded = true;
    });
  }

  willDestroy() {
    super.willDestroy();
    this.channel?.unbind('subscription_contracts');
  }
}
