/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { CandidateModel, ReviewModel } from 'teamtailor/models';
import observeSections from './observe-sections';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { modifier } from 'ember-modifier';
import { get } from 'teamtailor/utils/get';
import FlipperService from 'teamtailor/services/flipper';
import { DEFAULT_SECTION_ORDER } from 'teamtailor/constants/candidate-modal-sections';

interface Args {
  candidate: CandidateModel;
  activeTab: string;
  onTabChange: (tab: string) => void;
}

interface ModalSection {
  id: string;
  position: number;
}

export default class extends Component<Args> {
  @service declare flipper: FlipperService;
  @service declare current: Current;
  @service declare router: RouterService;

  observeSections = observeSections;

  @tracked renderedSections: string[] = [];
  @tracked sectionsInView: string[] = [];
  @tracked declare focusedSection: string;
  @tracked displayReviews = false;
  @tracked jobContextVisible = true;
  @tracked expandedPicture = false;
  @tracked emailAlertHidden;
  @tracked starRatings = 0;
  @tracked mainSections: ModalSection[] = [];

  hideEmailAlertKey = `hide-email-alert-${this.candidate.id}`;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.emailAlertHidden =
      localStorage.getItem(this.hideEmailAlertKey) === 'true';

    const json = localStorage.getItem('candidate-modal-section-order');

    if (
      json &&
      (JSON.parse(json) as ModalSection[]).length ===
        DEFAULT_SECTION_ORDER.length
    ) {
      this.mainSections = JSON.parse(json) as ModalSection[];
    } else {
      this.mainSections = DEFAULT_SECTION_ORDER;
    }
  }

  get candidate() {
    return this.args.candidate;
  }

  get userHasNotReviewed() {
    if (this.args.candidate.isDestroying) {
      return false;
    }
    const reviews = get(this.args.candidate, 'reviews');

    if (!get(reviews, 'isFulfilled')) {
      return false;
    }

    return !reviews.toArray().some((review: ReviewModel) => {
      const user = get(review, 'user');
      return get(user, 'id') === this.current.user.id;
    });
  }

  get sections() {
    return this.mainSections.sort((a, b) => a.position - b.position);
  }

  @action
  componentPath(id: string) {
    const section = DEFAULT_SECTION_ORDER.find((section) => section.id === id);

    return section?.section;
  }

  @action
  reOrderSections({ draggedItem, targetList, targetIndex }: any) {
    const draggedSection = this.mainSections.find(
      (section) => section.id === draggedItem.id
    );

    if (!draggedSection) {
      return;
    }

    let updatedSections: ModalSection[] = [];

    if (targetIndex === targetList.length - 1) {
      updatedSections = this.mainSections.map((section) => {
        if (section.id === draggedItem.id) {
          return { ...section, position: 999 };
        }

        return section;
      });
    } else if (targetIndex === 0) {
      updatedSections = this.mainSections.map((section) => {
        if (section.id === draggedItem.id) {
          return { ...section, position: -1 };
        }

        return section;
      });
    } else {
      const dropTarget = targetList[targetIndex];
      const dropTargetPosition = this.mainSections.find(
        (section) => section.id === dropTarget.id
      )?.position;

      if (dropTargetPosition === undefined) {
        return;
      }

      updatedSections = this.mainSections.map((section) => {
        if (section.id === draggedItem.id) {
          return { ...section, position: dropTargetPosition };
        }

        if (
          draggedSection.position < dropTargetPosition &&
          section.position > draggedSection.position &&
          section.position <= dropTargetPosition
        ) {
          return { ...section, position: section.position - 1 };
        }

        if (
          draggedSection.position > dropTargetPosition &&
          section.position < draggedSection.position &&
          section.position >= dropTargetPosition
        ) {
          return { ...section, position: section.position + 1 };
        }

        return section;
      });
    }

    this.mainSections = updatedSections
      .sort((a, b) => a.position - b.position)
      .map((section, index) => {
        return { ...section, position: index };
      });

    localStorage.setItem(
      'candidate-modal-section-order',
      JSON.stringify(this.mainSections)
    );
  }

  @action
  handleImageUploaded(pictureUrl: string) {
    set(this.candidate, 'pictureCache', pictureUrl);

    return this.candidate.save();
  }

  @action
  toggleExpandedPicture() {
    this.expandedPicture = !this.expandedPicture;
  }

  @action
  updateVisibility(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const y = rect.top + window.scrollY - window.innerHeight;

    if (y < 0) {
      this.jobContextVisible = false;
    }
  }

  @action
  hideFromEmail(e: Event) {
    e.preventDefault();
    localStorage.setItem(this.hideEmailAlertKey, 'true');
    this.emailAlertHidden = true;
  }

  @action
  handleRating(number: number) {
    this.displayReviews = true;
    this.starRatings = number;
  }

  handleCandidateChange = modifier((_element, [_candidate]) => {
    this.starRatings = 0;
    this.displayReviews = false;
  });
}
