/* import __COLOCATED_TEMPLATE__ from './cookie-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import { CookieDetailModel } from 'teamtailor/models';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';

const UNIT_TYPES = ['seconds', 'minutes', 'hours', 'days'];

export interface CookieFormSignature {
  Args: {
    type: 'marketing' | 'analytics' | 'preferences';
    cookie: CookieDetailModel;
    onSave: (cookie: CookieDetailModel) => void;
    onClose: () => void;
  };
}

export default class CookieForm extends Component<CookieFormSignature> {
  @service declare intl: IntlService;
  @service declare ttAlert: TtAlertService;

  get isNew() {
    return get(this.args.cookie, 'isNew');
  }

  get unitTypes() {
    return UNIT_TYPES.map((type) => {
      const translationKey = `common.${type}`;

      return {
        type,
        value: this.intl.t(translationKey),
      };
    });
  }

  get header() {
    const action = this.isNew ? 'create' : 'update';
    const type = this.intl.t(
      `components.cookie_form.types.${this.args.type.toString()}`
    );
    return this.intl.t(`components.cookie_form.title.${action}`, { type });
  }

  get saveButtonTranslationKey() {
    return this.isNew ? 'common.create' : 'common.update';
  }

  @action
  onDurationChange(value: boolean) {
    set(this.args.cookie, 'sessionCookie', value);
  }

  @action
  updateUnit(unit: { type: string; value: string }) {
    set(this.args.cookie, 'unit', unit.type);
  }
}
