/* import __COLOCATED_TEMPLATE__ from './form-counter-inline.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class FormCounterInline extends Component {
  @action
  handleWheelScroll(event) {
    if (!event.target.disabled) {
      event.preventDefault();
      let newValue = parseInt(this.args.value, 10) || 0;
      if (event.deltaY < 0) {
        // scrolling up
        newValue += 1;
      } else {
        if (newValue > 0) {
          newValue -= 1;
        }
      }

      this.args.onValueChange(newValue);
    }
  }

  @action
  handleChange(event) {
    this.args.onValueChange(event.target.value);
  }
}
