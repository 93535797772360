/* import __COLOCATED_TEMPLATE__ from './pie-chart.hbs'; */
import Component from '@glimmer/component';

export default class PieChart extends Component {
  get chartData() {
    const { data } = this.args;

    return [
      {
        name: this.args.title,
        data: data.map((point) => {
          return {
            name: point.name,
            y: point.value,
          };
        }),
      },
    ];
  }

  get chartOptions() {
    const { colors } = this.args;

    return {
      colors,
      chart: {
        height: '380',
        type: 'pie',
      },

      title: {
        text: this.args.title,
        verticalAlign: 'bottom',
        y: -30,
      },

      legend: {
        enabled: false,
        align: 'right',
        itemMarginBottom: 8,
        verticalAlign: 'left',
        layout: 'vertical',
        x: -200,
        y: 50,
      },

      tooltip: {
        formatter() {
          const percentage = new Intl.NumberFormat('en-IN', {
            maximumSignificantDigits: 2,
          }).format(this.point.percentage);
          return `${this.point.name}: ${this.point.y} (${percentage}%)`;
        },
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
        },
      },
    };
  }
}
