/* import __COLOCATED_TEMPLATE__ from './table-instant-search.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

interface TableInstantSearchArgs {
  value: string;
  theme: 'dark' | 'light';
  onchange: (value: string) => void;
  allowClear?: boolean;
}

export default class TableInstantSearchComponent extends Component<TableInstantSearchArgs> {
  @tracked isFocus = false;
  @tracked inputElement: HTMLInputElement | null = null;

  get emptyValue() {
    return this.args.value === '';
  }

  get isDark() {
    return this.args.theme === 'dark';
  }

  get hasValue() {
    return this.args.value !== '';
  }

  get isActive() {
    return this.isFocus || this.hasValue;
  }

  @action
  valChange(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    this.args.onchange(target.value);
  }

  @action
  clearInput() {
    this.args.onchange('');
    this.inputElement?.focus();
  }

  @action
  handleOnFocusOut() {
    set(this, 'isFocus', false);
  }

  @action
  handleOnFocusIn() {
    set(this, 'isFocus', true);
  }

  @action
  handleInsertInput(element: HTMLInputElement) {
    this.inputElement = element;

    next(() => {
      this.inputElement?.focus();
    });
  }
}
