/* import __COLOCATED_TEMPLATE__ from './widget.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface WidgetArgs {
  title?: string;
  subTitle?: string;
  count?: number;
  empty?: boolean;
  emptyText?: string;
  emptyEmoji?: boolean;
  loading?: boolean;
  scrollable?: boolean;
  showMoreLink?: () => void;
  onShowExportModal?: () => void;
}

export default class extends Component<WidgetArgs> {
  @tracked hovering = false;
}
