import IntlService from 'ember-intl/services/intl';

export type ExpireOption = {
  value: number | 'custom';
  title: string;
};

export function getExpireOptions(intl: IntlService) {
  return [
    {
      value: 1,
      title: intl.t('settings.share_links.expire_options.one_day'),
    },
    {
      value: 7,
      title: intl.t('settings.share_links.expire_options.one_week'),
    },
    {
      value: 14,
      title: intl.t('settings.share_links.expire_options.two_weeks'),
    },
    {
      value: 30,
      title: intl.t('settings.share_links.expire_options.one_month'),
    },
    {
      value: 'custom',
      title: intl.t('settings.share_links.expire_options.custom'),
    },
  ] as const satisfies ReadonlyArray<ExpireOption>;
}
