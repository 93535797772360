/* import __COLOCATED_TEMPLATE__ from './remote-participant.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { bind } from '@ember/runloop';
import {
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  RemoteTrackPublication,
} from 'twilio-video';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import {
  AteendeeIdsToTilesMap,
  MeetingAttendee,
  RemoteParticipantScreenShare,
  VideoDimensions,
  bindChimeTileToVideoElement,
} from 'teamtailor/utils/video-meetings/utils';
import { AudioVideoFacade } from 'amazon-chime-sdk-js';

interface VideoMeetingRemoteParticipantArgs {
  participant: RemoteParticipant | MeetingAttendee;
  style: string;
  onDimensionsChanged?: (
    track?: RemoteVideoTrack | null,
    dimensions?: VideoDimensions | null
  ) => void;
  // Chime
  isNewProvider: boolean;
  audioVideoFacade: AudioVideoFacade;
  tilesMap: AteendeeIdsToTilesMap | Record<string, never>;
  remoteParticipantScreenShare?: RemoteParticipantScreenShare;
}

export default class VideoMeetingRemoteParticipant extends Component<VideoMeetingRemoteParticipantArgs> {
  @service declare intl: IntlService;

  declare tracksElement: HTMLElement;

  @tracked audio = true;
  @tracked screen = false;

  get participant(): RemoteParticipant | MeetingAttendee {
    return this.args.participant;
  }

  get name(): string {
    let name: string | undefined;

    if (this.args.isNewProvider) {
      name = (<MeetingAttendee>this.participant).externalUserId.split('--')[0];
    } else {
      name = (<RemoteParticipant>this.participant).identity.split('--')[0];
    }

    if (name === 'guest') {
      name = this.intl.t('components.video_meeting.guest');
    }

    return name || '';
  }

  get isParticipantSharingScreen() {
    if (this.args.isNewProvider && this.args.remoteParticipantScreenShare) {
      return (
        (<MeetingAttendee>this.participant).attendeeId ===
        this.args.remoteParticipantScreenShare.baseAttendeeId
      );
    }
  }

  onDimensionsChanged = (
    trackElement: HTMLElement,
    track: RemoteVideoTrack
  ): void => {
    const ratio =
      (track.dimensions.width || 1) / (track.dimensions.height || 1);

    if (ratio >= 1) {
      trackElement.style.aspectRatio = '';
      trackElement.classList.add('lg:object-cover');
    } else {
      trackElement.style.aspectRatio = `${track.dimensions.width} / ${track.dimensions.height}`;
      trackElement.classList.remove('lg:object-cover');
    }
  };

  trackSubscribed = (track: RemoteTrack | null) => {
    if (!track || track.kind === 'data') {
      return;
    }

    if (track.kind === 'video') {
      if (track.name === 'screen-share') {
        this.screen = true;
      } else {
        this.attachVideoElement(track);
      }
    }

    if (track.kind === 'audio') {
      this.audio = track.isEnabled;
    }
  };

  trackUnsubscribed = (track: RemoteTrack) => {
    if (track.kind === 'video' && track.name === 'screen-share') {
      this.screen = false;
    }
  };

  trackEnabled = (publication: RemoteTrackPublication) => {
    if (publication.kind === 'audio') {
      this.audio = true;
    }

    if (publication.kind === 'video') {
      this.tracksElement.classList.remove('hidden');
    }
  };

  trackDisabled = (publication: RemoteTrackPublication) => {
    if (publication.kind === 'audio') {
      this.audio = false;
    }

    if (publication.kind === 'video') {
      this.tracksElement.classList.add('hidden');
    }
  };

  attachVideoElement = (track: RemoteVideoTrack): void => {
    const trackElement = track.attach();
    trackElement.classList.add(
      'w-full',
      'h-full',
      'object-contain',
      'lg:object-cover'
    );
    track.on(
      'dimensionsChanged',
      bind(this, (track: RemoteVideoTrack) => {
        this.onDimensionsChanged(trackElement, track);
        if (this.args.onDimensionsChanged && this.args.style === 'speaker') {
          this.args.onDimensionsChanged(track);
        }
      })
    );

    this.onDimensionsChanged(trackElement, track);

    if (this.args.onDimensionsChanged && this.args.style === 'speaker') {
      this.args.onDimensionsChanged(track);
    }

    this.tracksElement.appendChild(trackElement);
    trackElement.classList.remove('opacity-0');
  };

  initializeParticipant() {
    const participant = <RemoteParticipant>this.participant;
    const { tracks } = participant;

    tracks.forEach((publication: RemoteTrackPublication) => {
      const { isSubscribed, track } = publication;
      if (isSubscribed) {
        this.trackSubscribed(track);
      }
    });

    participant.on('trackEnabled', bind(this, this.trackEnabled));
    participant.on('trackDisabled', bind(this, this.trackDisabled));
    participant.on('trackSubscribed', bind(this, this.trackSubscribed));
    participant.on('trackUnsubscribed', bind(this, this.trackUnsubscribed));
  }

  volumeIndicatorHandler = (
    _attendeeId: string,
    _volume: number | null,
    muted: boolean | null,
    _signalStrength: number | null,
    _externalUserId?: string
  ) => {
    if (muted === null) {
      return;
    }

    this.audio = !muted;
  };

  @action
  handleVideoDimensionsChanged(event: Event) {
    if (this.args.isNewProvider) {
      const videoElement = <HTMLVideoElement>event.target;
      const { videoWidth, videoHeight } = videoElement;
      if (videoHeight && videoWidth) {
        const ratio = videoWidth / videoHeight;

        if (ratio >= 1) {
          videoElement.style.aspectRatio = '';
          videoElement.classList.add('lg:object-cover');
        } else {
          videoElement.style.aspectRatio = `${videoWidth} / ${videoHeight}`;
          videoElement.classList.remove('lg:object-cover');
        }
      }

      if (this.args.onDimensionsChanged && this.args.style === 'speaker') {
        const dimensions = {
          height: videoHeight,
          width: videoWidth,
        };

        this.args.onDimensionsChanged(null, dimensions);
      }
    }
  }

  @action
  handleInsertTracks(element: HTMLElement) {
    if (this.args.isNewProvider) {
      this.args.audioVideoFacade.realtimeSubscribeToVolumeIndicator(
        (<MeetingAttendee>this.participant).attendeeId,
        this.volumeIndicatorHandler
      );
      if (Object.keys(this.args.tilesMap).length) {
        const participantTileId =
          this.args.tilesMap[(<MeetingAttendee>this.participant).attendeeId];

        if (participantTileId) {
          bindChimeTileToVideoElement(
            element,
            this.args.audioVideoFacade,
            (<MeetingAttendee>this.participant).attendeeId,
            participantTileId
          );
        }
      }
    } else {
      this.tracksElement = element;
      this.initializeParticipant();
    }
  }

  @action
  handleUpdateParticipant() {
    if (!this.args.isNewProvider) {
      this.tracksElement.replaceChildren();
      this.initializeParticipant();
    }
  }
}
