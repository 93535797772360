/* import __COLOCATED_TEMPLATE__ from './candidate-information.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import JobApplicationModel from 'teamtailor/models/job-application';
import { get } from 'teamtailor/utils/get';

const TRUNCATED_RESUME_SUMMARY_LENGTH = 100;

type CandidateInformationArgs = {
  jobApplications: JobApplicationModel[];
  hasResumeSummaryFeature: boolean;
  sortBy: string;
  pinnedCount: number;
  offset: number;
  sortIndicator: string;
  hiddenCandidateIds: number[];
  hoveredJobApplication: JobApplicationModel;
  columnsAreWide: boolean;
};

export default class CompareCandidatesCandidateInformationComponent extends Component<CandidateInformationArgs> {
  @tracked expandedResumes: JobApplicationModel[] = [];

  @action formattedResumeSummary(jobApplication: JobApplicationModel) {
    const resume = get(jobApplication.candidate, 'resumeSummary');
    if (!resume) {
      return '';
    }

    if (
      this.args.columnsAreWide ||
      this.resumeSummaryIsExpanded(jobApplication)
    ) {
      return resume;
    } else {
      return `${resume.slice(0, TRUNCATED_RESUME_SUMMARY_LENGTH)}...`;
    }
  }

  @action allowTruncateResumeSummary(jobApplication: JobApplicationModel) {
    if (this.args.columnsAreWide) {
      return false;
    }

    const resume = get(jobApplication.candidate, 'resumeSummary');
    return resume && resume.length > TRUNCATED_RESUME_SUMMARY_LENGTH;
  }

  @action toggleResumeSummaryIsExpanded(jobApplication: JobApplicationModel) {
    if (this.expandedResumes.includes(jobApplication)) {
      this.expandedResumes.removeObject(jobApplication);
    } else {
      this.expandedResumes.pushObject(jobApplication);
    }
  }

  @action resumeSummaryIsExpanded(jobApplication: JobApplicationModel) {
    return this.expandedResumes.includes(jobApplication);
  }
}
