/* import __COLOCATED_TEMPLATE__ from './referral-activities.hbs'; */
import WidgetClass from 'teamtailor/components/dashboard/widgets/base/widget-class';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ReferralLeaderboardWidget extends WidgetClass {
  @service declare store: any;

  @tracked activities = null;

  fetchActivities = dropTask(async () => {
    this.activities = await this.store.query('activity', {
      referral_activities: true,
    });
  });
}
