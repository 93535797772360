/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
import Component from '@glimmer/component';
import { DepartmentModel, RoleModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import IframePreviewService from 'teamtailor/services/iframe-preview';

type ContentBlockConnectSettings = {
  Args: {
    selectedDepartment?: DepartmentModel;
    selectedRole?: RoleModel;
    onBlockEditorItemClick: (
      blockType: string,
      iframeAnchor?: string,
      scrollToTopPage?: boolean
    ) => void;
    selectedBlock?: string;
    iframeAnchor: string;
    onConnectSelectionChange: (
      type: 'department' | 'role',
      selection: DepartmentModel | RoleModel | undefined
    ) => void;
  };
};

export default class ContentBlockConnectSettingsComponent extends Component<ContentBlockConnectSettings> {
  @service declare intl: IntlService;
  @service declare iframePreview: IframePreviewService;

  @action
  handleConnectIframeAnchor() {
    if (this.args.selectedBlock === 'posts') {
      this.iframePreview.isIframeLoaded = false;
      this.iframePreview.scrollToAnchor('posts_container');
    }
  }

  @action
  onConnectSelectionChange(
    type: 'department' | 'role',
    selection: DepartmentModel | RoleModel | undefined
  ) {
    this.args.onConnectSelectionChange(type, selection);
    this.handleConnectIframeAnchor();
  }

  get subheader() {
    const { selectedDepartment, selectedRole } = this.args;

    if (selectedDepartment && selectedRole) {
      return `${selectedDepartment.name} / ${selectedRole.name}`;
    }

    if (selectedDepartment && !selectedRole) {
      return `${selectedDepartment.name} / ${this.intl.t(
        'settings.connect.all_roles'
      )}`;
    }

    return `${this.intl.t('settings.connect.all_departments')} / ${this.intl.t(
      'settings.connect.all_roles'
    )}`;
  }

  @action
  onBlockEditorItemClick(
    blockType: string,
    iframeAnchor?: string,
    scrollToTopPage?: boolean
  ) {
    this.args.onBlockEditorItemClick(blockType);
    if (iframeAnchor) {
      this.iframePreview.scrollToAnchor(iframeAnchor);
    } else if (scrollToTopPage) {
      this.iframePreview.scrollToTop();
    }
  }
}
