import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import RouterService from '@ember/routing/router-service';
import AboardService from 'teamtailor/services/aboard-service';
import Current from 'teamtailor/services/current';
import RouteHelper from 'teamtailor/services/route-helper';
import ENV from 'teamtailor/config/environment';

export default class AboardLiteController extends Controller {
  @service declare aboardService: AboardService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare routeHelper: RouteHelper;

  aboardLiteUrl = trackedFunction(this, async (): Promise<string> => {
    const { onboardingId } = this.model;
    const token = await this.aboardService.token();

    const url = `${ENV.httpOrHttps}://app.${ENV.ABOARD_HOST}/companies/${this.current.company.aboardId}/teamtailor/onboardings/${onboardingId}?token=${token}&theme=${this.current.user.appearance}`;

    return url;
  });

  @action
  close() {
    const url = this.routeHelper.parentRoute;

    if (url) {
      this.router.transitionTo(url);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.job.stages.index.candidate.aboard-lite': AboardLiteController;
  }
}
