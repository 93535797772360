/* import __COLOCATED_TEMPLATE__ from './content-page-type-icon.hbs'; */
import Component from '@glimmer/component';
import {
  ALLOWED_TYPES,
  PAGE_TYPE_MAP,
  DEFAULT_PAGE_TYPE,
  PageIconType,
} from 'teamtailor/constants/pages';

interface ContentPageTypeIconArgs {
  type: string;
}

type AllowedTypes = (typeof ALLOWED_TYPES)[number];

export default class ContentPageTypeIcon extends Component<ContentPageTypeIconArgs> {
  get type(): AllowedTypes {
    return this.args.type;
  }

  get properties(): PageIconType {
    return PAGE_TYPE_MAP[this.type] || DEFAULT_PAGE_TYPE;
  }
}
