import ApplicationSerializer from './application';
import moment from 'moment-timezone';
import { get } from 'teamtailor/utils/get';
import classic from 'ember-classic-decorator';

@classic
export default class TodoSerializer extends ApplicationSerializer {
  serialize(snapshot) {
    let json = super.serialize(...arguments);
    delete json.due;

    if (get(snapshot.record, 'due')) {
      let due = moment(get(snapshot.record, 'due')).format('YYYY-MM-DD');
      return { ...json, due };
    } else {
      return { ...json, due: null };
    }
  }
}
