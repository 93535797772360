/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import colors from 'teamtailor/components/insights/charts/colors';

export default class extends Component {
  get colorStyles() {
    const color = this.args.color || colors[this.args.index % colors.length];
    if (this.args.checked) {
      return htmlSafe(`background-color: ${color}`);
    } else {
      return htmlSafe('background-color: white;');
    }
  }
}
