/* import __COLOCATED_TEMPLATE__ from './toolbar.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { ActivityModel } from 'teamtailor/models';
import toggleReaction from 'teamtailor/utils/toggle-reaction';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';

interface Args {
  activity: ActivityModel;
  changeActivityAction: (action: string) => void;
  activityAction: string;
}

export default class extends Component<Args> {
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @action
  async addReaction(model: ActivityModel, emoji: string) {
    try {
      await toggleReaction(model, this.current.user, emoji);
    } catch (e) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  @action
  toggleAction(action: string) {
    if (this.args.activityAction === action) {
      this.args.changeActivityAction('');
    } else {
      this.args.changeActivityAction(action);
    }
  }
}
