/* import __COLOCATED_TEMPLATE__ from './speaker-share-screen.hbs'; */
import Component from '@glimmer/component';
import { RemoteVideoTrack } from 'twilio-video';
import { action } from '@ember/object';
import {
  RemoteParticipantScreenShare,
  VideoDimensions,
  bindChimeTileToVideoElement,
} from 'teamtailor/utils/video-meetings/utils';
import { AudioVideoFacade } from 'amazon-chime-sdk-js';

interface VideoMeetingViewsSpeakerShareScreenArgs {
  track: RemoteVideoTrack;
  onDimensionsChanged: (
    track?: RemoteVideoTrack | null,
    dimensions?: VideoDimensions | null
  ) => void;
  // Chime
  remoteParticipantScreenShare?: RemoteParticipantScreenShare;
  isNewProvider: boolean;
  audioVideoFacade: AudioVideoFacade;
}

export default class VideoMeetingViewsSpeakerShareScreen extends Component<VideoMeetingViewsSpeakerShareScreenArgs> {
  @action
  onInsert(element: HTMLElement) {
    if (this.args.isNewProvider) {
      if (this.args.remoteParticipantScreenShare) {
        bindChimeTileToVideoElement(
          element,
          this.args.audioVideoFacade,
          this.args.remoteParticipantScreenShare.attendeeId,
          this.args.remoteParticipantScreenShare.tileId
        );
      }
    } else {
      const { track } = this.args;

      const trackElement = track.attach();
      trackElement.classList.add(
        'w-full',
        'h-full',
        'mx-auto',
        'object-contain',
        'shadow-video-meeting',
        'overflow-hidden',
        'rounded-10'
      );
      element.appendChild(trackElement);

      track.on('dimensionsChanged', (track: RemoteVideoTrack) =>
        this.args.onDimensionsChanged(track)
      );

      this.args.onDimensionsChanged(track);
    }
  }

  @action
  handleResize(event: Event) {
    if (this.args.isNewProvider) {
      const videoElement = <HTMLVideoElement>event.target;
      if (videoElement.videoHeight && videoElement.videoWidth) {
        const dimensions = {
          height: videoElement.videoHeight,
          width: videoElement.videoWidth,
        };
        this.args.onDimensionsChanged(null, dimensions);
      }
    }
  }
}
