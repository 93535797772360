/* import __COLOCATED_TEMPLATE__ from './sources.hbs'; */
import Component from '@glimmer/component';
import flatten from 'teamtailor/utils/flatten';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

export default class extends Component {
  @computed('args.data')
  get total() {
    return flatten(this.args.data.mapBy('value')).reduce(
      (acc, i) => acc + i,
      0
    );
  }
}
