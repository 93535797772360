/* import __COLOCATED_TEMPLATE__ from './period-picker.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ScheduledReport from 'teamtailor/models/scheduled-report';
import { get } from 'teamtailor/utils/get';

type Args = {
  scheduledReport: ScheduledReport;
};

type TypeWithTranslationKey = {
  type: string;
  translationKey: string;
};

export const intervalTypeAsPeriodType = (intervalType: string) => {
  switch (intervalType) {
    case 'weekly':
      return 'weeks';
    case 'monthly':
      return 'months';
    case 'yearly':
    default:
      return 'years';
  }
};

export default class InsightsScheduleReportModalPeriodPicker extends Component<Args> {
  @service declare store: Store;

  periodTypes: TypeWithTranslationKey[] = [
    {
      type: 'weeks',
      translationKey: 'common.week',
    },
    {
      type: 'months',
      translationKey: 'common.month',
    },
    {
      type: 'years',
      translationKey: 'common.year',
    },
  ];

  get intervalType() {
    return get(this.args.scheduledReport.settings.interval, 'type');
  }

  get intervalValue() {
    return get(this.args.scheduledReport.settings.interval, 'value');
  }

  get intervalTypeAsPeriodType() {
    return intervalTypeAsPeriodType(this.intervalType);
  }

  get periodType() {
    return (
      get(this.args.scheduledReport.settings.period, 'type') ||
      this.intervalTypeAsPeriodType
    );
  }

  get periodValue() {
    const value = get(this.args.scheduledReport.settings.period, 'value');

    if (!value && value !== 0) {
      return this.intervalValue;
    }

    return value;
  }

  set periodValue(value: number) {
    set(this.args.scheduledReport.settings.period, 'value', value);
  }

  get isWeekly() {
    return this.intervalType === 'weekly';
  }

  get isMonthly() {
    return this.intervalType === 'monthly';
  }

  get isYearly() {
    return this.intervalType === 'yearly';
  }

  @action
  updatePeriodType(value: TypeWithTranslationKey) {
    set(this.args.scheduledReport.settings.period, 'type', value.type);
  }

  @action
  updatePeriodValue(event: InputEvent) {
    if (event.target) {
      const { period } = this.args.scheduledReport.settings;
      set(period, 'value', Number((event.target as HTMLInputElement).value));
    }
  }
}
