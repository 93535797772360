/* import __COLOCATED_TEMPLATE__ from './new-hire-share-page-status.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { JobBulkArgs } from 'teamtailor/constants/jobs';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import HIDE_NEW_HIRE_PAGE_CARD from 'teamtailor/utils/new-hire-page';

export default class BulkActionsJobsNewHireSharePageStatusComponent extends Component<JobBulkArgs> {
  @service declare intl: IntlService;

  @tracked newHireSharePageStatus = false;

  @action
  onNewHireShareToggle(status: boolean) {
    set(this, 'newHireSharePageStatus', !status);

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(HIDE_NEW_HIRE_PAGE_CARD)) {
        localStorage.removeItem(key);
      }
    });
  }

  @action
  onClose(): void {
    this.args.onClose();
    this.newHireSharePageStatus = false;
  }

  @action
  handleBulkChange(): void {
    const data = {
      new_hire_share_page_status: this.newHireSharePageStatus,
    };
    this.args.handleBulkChange(data, 'bulk_new_hire_share_page_status');
    this.onClose();
  }
}
