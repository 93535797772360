import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ContentBlockTimeline extends ApplicationSerializer.extend({
  attrs: {
    pickedImages: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'picked_images_attributes',
    },

    company: { serialize: false },
    shadow: { serialize: false },
  },

  payloadKeyFromModelName() {
    return 'content_block_timeline';
  },
}) {}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-timeline': ContentBlockTimeline;
  }
}
