/* import __COLOCATED_TEMPLATE__ from './page-settings-takeover.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PageSettingsTakeoverComponent extends Component {
  @tracked isOpen = false;

  get isDepartmentOrLocation() {
    return this.args.page.isDepartmentPage || this.args.page.isLocationPage;
  }

  get generalPath() {
    return `${this.args.linkPathPrefix}.index`;
  }

  get sharingImagePath() {
    return `${this.args.linkPathPrefix}.sharing-image`;
  }

  get metaDataPath() {
    return `${this.args.linkPathPrefix}.seo-settings`;
  }

  get thumbnailSettingsPath() {
    return `${this.args.linkPathPrefix}.thumbnail-settings`;
  }

  get candidateChatPath() {
    return `${this.args.linkPathPrefix}.candidate-chat`;
  }

  get departmentSettingsPath() {
    return `${this.args.linkPathPrefix}.department-settings`;
  }

  get departmentPath() {
    return `${this.args.linkPathPrefix}.department`;
  }

  @action
  showSettingsModal() {
    this.isOpen = true;
  }

  @action
  onClose() {
    this.isOpen = false;
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}
