/* import __COLOCATED_TEMPLATE__ from './question-picker.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { query } from 'ember-data-resources';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import QuestionModel from 'teamtailor/models/question';
import { underscore } from '@ember/string';
import { isEmpty } from '@ember/utils';
import { isArray } from '@ember/array';
import {
  fetchGroupedAssetsByContext,
  AssetStructureContext,
} from 'teamtailor/utils/filter-assets';

const NUMBER_OF_QUESTIONS = 10;

type GroupedOptions = {
  groupName: string;
  options: QuestionModel[];
};

type QuestionPickerArgs = {
  onlySelect?: boolean;
  onlyIcon?: boolean;
  isMultiple?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
  text?: string;
  selected?: QuestionModel[] | QuestionModel;
  selectedIds?: string[];
  assetStructureContext?: AssetStructureContext;
  onCreate?: (name?: string) => void;
  filters?: any;
  hiddenQuestionTypes?: string[];
  languageCode?: string;
};

export default class CoreQuestionPickerComponent extends Component<QuestionPickerArgs> {
  @service declare intl: IntlService;
  @service declare current: Current;

  @tracked searchTerm: string | undefined;
  @tracked excludeIds: string[] = [];
  @tracked openQuestionCreator = false;

  questionsQuery = query(this, 'question', () => {
    const filters = this.args.filters || {};

    const underscoredFilters = Object.keys(filters).reduce(
      (result: any, key) => {
        result[underscore(key)] = filters[key];
        return result;
      },
      {}
    );

    return Object.assign(
      {
        page: 1,
        per_page: NUMBER_OF_QUESTIONS,
        sort_column: 'created_at',
        sort_direction: 'desc',
        query: this.searchTerm,
        exclude_ids: this.excludeIds,
        exclude_types: this.args.hiddenQuestionTypes,
      },
      underscoredFilters
    );
  });

  get text() {
    if (this.args.text) {
      return this.args.text;
    } else if (this.args.onlyIcon) {
      return undefined;
    } else if (this.args.onlySelect) {
      return this.intl.t('models.question_picker.add_question');
    } else {
      return this.intl.t('search.question.select_question');
    }
  }

  get placeholder() {
    if (this.args.placeholder) {
      return this.args.placeholder;
    } else if (this.args.onlyIcon) {
      return undefined;
    } else if (this.args.isMultiple) {
      return this.intl.t('models.question_picker.select_questions');
    } else {
      return this.intl.t('models.question_picker.select_question');
    }
  }

  get hasSelected(): boolean {
    return this.selectedExists(this.args.selected);
  }

  get selectedIds() {
    if (this.args.selectedIds) {
      return this.args.selectedIds;
    }

    const selected = isArray(this.args.selected)
      ? this.args.selected
      : [this.args.selected].compact();
    return selected.mapBy('id');
  }

  get careerSiteLanguageCodes() {
    return get(this.current.company, 'careerSites').mapBy('languageCode');
  }

  selectedExists(
    selected: QuestionModel[] | QuestionModel | undefined
  ): selected is QuestionModel[] | QuestionModel {
    if (isArray(selected)) {
      return selected.length > 0;
    }

    return !!selected;
  }

  get questions(): QuestionModel[] | GroupedOptions[] {
    let selected = this.args.selected || [];
    if (!isArray(selected)) {
      selected = [selected];
    }

    const queriedRecords = this.questionsQuery.records;
    const queriedQuestions = queriedRecords
      ? (queriedRecords.toArray() as QuestionModel[])
      : [];

    if (this.args.onlySelect) {
      const context = this.args.assetStructureContext;
      if (context) {
        return fetchGroupedAssetsByContext(
          queriedQuestions,
          context,
          this.intl
        ) as QuestionModel[] | GroupedOptions[];
      } else {
        return queriedQuestions;
      }
    } else if (this.args.isMultiple) {
      return this.getMultipleOptionsQuestions(queriedQuestions, selected);
    } else {
      return this.getSingleOptionQuestions(queriedQuestions, selected);
    }
  }

  getSingleOptionQuestions(
    questions: QuestionModel[],
    selected: QuestionModel[]
  ) {
    const selectedToPrepend = this.getSelectedToPrepend(questions, selected);
    return [...selectedToPrepend, ...questions];
  }

  getMultipleOptionsQuestions(
    queriedQuestions: QuestionModel[],
    selected: QuestionModel[]
  ) {
    const questions = isEmpty(this.searchTerm)
      ? queriedQuestions.filter((question: QuestionModel) => {
          return !this.selectedIds.includes(get(question, 'id'));
        })
      : queriedQuestions;

    if (isEmpty(this.searchTerm) && this.hasSelected) {
      if (questions.length === 0) {
        return selected;
      }

      return [
        {
          groupName: this.intl.t('common.selected'),
          options: this.getSelectedToPrepend(questions, selected),
        },
        {
          groupName: this.intl.t('common.questions'),
          options: questions,
        },
      ];
    }

    return questions;
  }

  getSelectedToPrepend(questions: QuestionModel[], selected: QuestionModel[]) {
    const questionIds = questions.mapBy('id');
    return selected.filter((question: QuestionModel) => {
      return !questionIds.includes(get(question, 'id'));
    });
  }

  title = (question: QuestionModel) => {
    if (this.args.languageCode) {
      const translation = get(question, 'translations').find(
        (translation) => translation.languageCode === this.args.languageCode
      );
      return translation ? translation.title : get(question, 'title');
    }

    return get(question, 'title');
  };

  @action
  updateExcludedIds(): void {
    this.excludeIds = this.selectedIds;
  }

  @action
  handleSearch(term: string): void {
    this.searchTerm = term;

    if (isEmpty(this.searchTerm)) {
      this.updateExcludedIds();
    }
  }

  @action
  openQuestionModal(): void {
    this.openQuestionCreator = true;
  }

  @action
  closeQuestionModal(): void {
    this.openQuestionCreator = false;
  }

  @action
  hasMissingTranslation(question: QuestionModel) {
    const questionTranslations = get(question, 'translations').mapBy(
      'languageCode'
    );

    if (this.args.languageCode) {
      return !questionTranslations.includes(this.args.languageCode);
    }

    return !this.careerSiteLanguageCodes.every((code) =>
      questionTranslations.includes(code)
    );
  }
}
