/* import __COLOCATED_TEMPLATE__ from './time-input.hbs'; */
import DropdownMenu from 'teamtailor/components/core/dropdown-menu';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { KeyCodes } from 'teamtailor/constants/key-codes';
import { inject as service } from '@ember/service';
import { CoreDropdownContainerApi } from '../core/dropdown/container';

type ScrollOptions = {
  behavior: string;
  inline: string;
  block: string;
};

type timeOption = {
  date: moment.Moment;
  duration?: string;
};

type MeetingTimeInputArgs = {
  date: moment.Moment;
  tzid: string;
  minDate?: moment.Moment;
  onChange?: (dateOrString: moment.Moment | string) => void;
  suggestions?: boolean;
  onKeydown?: (event: KeyboardEvent) => void;
  time?: string;
};

export default class MeetingTimeInput extends DropdownMenu<MeetingTimeInputArgs> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service declare timeFormat: any;
  @tracked invalidTime = false;

  @tracked declare containerApi: CoreDropdownContainerApi;

  _timeOptions: timeOption[] = [];

  get scrollOptions(): ScrollOptions {
    return {
      behavior: 'auto',
      inline: 'nearest',
      block: 'center',
    };
  }

  get time(): string {
    return (
      this.args.time ||
      moment(this.args.date).tz(this.args.tzid).format(this.timeFormat.format)
    );
  }

  get minDate(): string | undefined {
    return this.args.minDate
      ? moment(this.args.minDate)
          .tz(this.args.tzid)
          .format(this.timeFormat.format)
      : undefined;
  }

  get timeOptions(): timeOption[] | undefined {
    const options = [];
    const optionTime = this.minTime();
    const limitDate = this.minDate
      ? moment(this.minDate, this.timeFormat.format).add(12, 'hours')
      : moment().endOf('day');
    while (optionTime.isSameOrBefore(limitDate)) {
      options.push({
        date: moment(optionTime),
        duration: this.minDate
          ? this.duration(this.minTime(), moment(optionTime))
          : undefined,
      });
      optionTime.add(15, 'minutes');
    }

    if (
      this._timeOptions.length === 0 ||
      moment(this._timeOptions.firstObject?.date).format('HH:mm') !==
        moment(options.firstObject?.date).format('HH:mm')
    ) {
      this._timeOptions = options;
    }

    return this._timeOptions;
  }

  get scrollToOption(): number | undefined {
    return this.timeOptions?.findIndex((option) => {
      if (
        option.date.isSameOrAfter(moment(this.time, this.timeFormat.format))
      ) {
        return true;
      }

      return false;
    });
  }

  minTime(): moment.Moment {
    if (this.minDate) {
      return moment(this.minDate, this.timeFormat.format);
    }

    return moment().startOf('day');
  }

  duration(start: moment.Moment, end: moment.Moment): string {
    const h = end.diff(start, 'hours');
    const m = Math.round((end.diff(start, 'hours', true) - h) * 60);
    return h || m ? `${h ? `${h}h` : ''} ${m ? ` ${m}mins` : ''}` : '0mins';
  }

  @action
  setTime(event: InputEvent): void {
    const time = moment.tz(
      (event.target as HTMLInputElement).value,
      this.timeFormat.format,
      this.args.tzid
    );

    if (time.isValid()) {
      this.invalidTime = false;
      if (this.args.onChange) {
        this.args.onChange(time);
      }
    } else {
      this.invalidTime = true;
    }
  }

  @action
  setOption(option: timeOption): void {
    this.invalidTime = false;
    if (this.args.onChange) {
      this.args.onChange(option.date);
    }
  }

  @action
  registerApi(api: CoreDropdownContainerApi): void {
    this.containerApi = api;
  }

  onOpen() {
    next(() => {
      this.buttonElement.focus();
    });
  }

  @action
  handleClose(): void {
    return;
  }

  @action
  handleFocusLost(event: FocusEvent): void {
    if (this.isOpen && this.containerElement) {
      const loosingToContainer = this.containerElement.contains(
        event.relatedTarget as HTMLElement
      );
      if (!loosingToContainer) {
        this.api.close();
      }
    }
  }

  @action
  handleKeydown(event: KeyboardEvent) {
    const { code } = event;

    const { ARROW_UP, ARROW_DOWN, ENTER, ESC } = KeyCodes;

    if (code === ARROW_UP || code === ARROW_DOWN) {
      if (!this.isOpen) {
        this.api.open();
      }

      event.preventDefault();
      event.stopPropagation();
      const direction = code === ARROW_UP ? -1 : 1;

      if (typeof this.scrollToOption === 'number') {
        const nextIndex = this.scrollToOption + direction;

        this.args.onChange?.(
          this.timeOptions?.[
            nextIndex < 0
              ? this.timeOptions.length - 1
              : nextIndex === this.timeOptions.length
                ? 0
                : nextIndex
          ]?.date || this.time
        );

        if (this.args.suggestions !== false) {
          this.containerApi.activateItem(direction < 0 ? 'up' : 'down');
        }
      }
    }

    if (code === ENTER || code === ESC) {
      this.api.close();
    }

    if (this.args.onKeydown) {
      this.args.onKeydown(event);
    }
  }
}
