/* import __COLOCATED_TEMPLATE__ from './spectrum.hbs'; */
import Component from '@glimmer/component';
import { tokenColors } from '@teamtailor/design-tokens';

interface UnicornSpectrumArgs {
  color: keyof typeof tokenColors;
}

export default class UnicornSpectrum extends Component<UnicornSpectrumArgs> {
  get colors() {
    const weight = tokenColors[this.args.color];
    if (typeof weight === 'string') {
      return [{ hex: weight, weight: '500' }];
    }

    const colors = [];
    for (const w in weight) {
      colors.push({ hex: (weight as Record<string, string>)[w], weight: w });
    }

    return colors;
  }
}
