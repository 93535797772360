/* import __COLOCATED_TEMPLATE__ from './rsc-snippet.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class extends Component {
  @service current;

  get clientId() {
    return get(this.current.company, 'linkedinClientId');
  }
}
