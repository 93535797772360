/* import __COLOCATED_TEMPLATE__ from './add-job-tag.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class AddJobTagModalComponent extends Component {
  @tracked isAdding = false;

  get model() {
    return this.args.model;
  }

  get hasTags() {
    return !isEmpty(get(this.model, 'tags'));
  }

  get isTagged() {
    return get(this.model, 'jobDetail.isTagged') || this.hasTags;
  }

  @action
  changeTags(tag) {
    toggleInList(get(this.model, 'tags'), tag);
  }
}
