/* import __COLOCATED_TEMPLATE__ from './badge.hbs'; */
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';

import { themeColors } from '@teamtailor/design-tokens';
import { bgColor, textColor } from 'teamtailor/utils/theme-color';

const DEFAULT_SIZE = 'small';
const ALLOWED_SIZES = ['tiny', DEFAULT_SIZE, 'medium'] as const;

type AllowedSizes = (typeof ALLOWED_SIZES)[number];

const DEFAULT_COLOR = 'zinc';
const DECORATIVE_COLORS = Object.keys(themeColors.background.decorative);
const COPILOT_COLORS = ['copilot'];
const ALERT_COLORS = ['success', 'warning', 'danger', 'info'];

const ALLOWED_COLORS = [
  ...DECORATIVE_COLORS,
  ...COPILOT_COLORS,
  ...ALERT_COLORS,
];

type AllowedColors = (typeof ALLOWED_COLORS)[number];

export interface Args {
  text: string;
  tooltip?: string;
  size?: AllowedSizes;
  icon?: string;
  color?: AllowedColors;
  colorVariant?: 'weak' | 'medium' | 'strong';
}

export default class CoreBadgeComponent extends Component<Args> {
  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Badge @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get color(): AllowedColors {
    verifyArg(this.args.color, ALLOWED_COLORS, 'Badge @color', DEFAULT_COLOR);

    return this.args.color ?? DEFAULT_COLOR;
  }

  get bgColor(): string {
    return bgColor(this.color, this.colorVariant);
  }

  get textColor(): string {
    return textColor(this.color, this.colorVariant);
  }

  get colorVariant() {
    const COLOR_VARIANTS = [...DECORATIVE_COLORS, ...COPILOT_COLORS];
    if (COLOR_VARIANTS.includes(this.color)) {
      return this.args.colorVariant || 'weak';
    }
  }
}
