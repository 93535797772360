/* import __COLOCATED_TEMPLATE__ from './custom-date.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

const userTimezone = moment.tz.guess();
const formatDate = (date) => moment.tz(date, userTimezone).format('YYYY-MM-DD');
export default class DateComponent extends Component {
  @service intl;

  get label() {
    let values = this.selectedRange;

    if (values?.length > 0) {
      return values.map((v) => formatDate(v)).join(' - ');
    }

    return this.intl.t('common.all');
  }

  get selectedRange() {
    let values = this.args.selected;

    if (values) {
      let returnValues = [];
      if (values.date_query_start) {
        returnValues.push(values.date_query_start);
      }

      if (values.date_query_end) {
        returnValues.push(values.date_query_end);
      }

      return returnValues.map((val) => moment.tz(val, userTimezone).toDate());
    }

    return [];
  }

  @action
  handleClear() {
    this.args.onChange(null, null);
  }

  @action
  handleChange(dates) {
    if (dates.length < 2) {
      return;
    }

    const { 0: startDate, 1: endDate } = dates;

    this.args.onChange(formatDate(startDate), formatDate(endDate));
  }
}
