/* import __COLOCATED_TEMPLATE__ from './webhook-job-context-provider.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PartnerWebhookJobContextProviderComponent extends Component {
  @tracked pickedJobApplication;

  @action handlePickJobApplication(jobApplication) {
    this.pickedJobApplication = jobApplication;
    this.args.setJobApplicationContext(this.pickedJobApplication);
  }
}
