/* import __COLOCATED_TEMPLATE__ from './cover.hbs'; */
import { action, set } from '@ember/object';
import AdjustmentsBaseComponent from './base';

export default class CoverAdjustmentsComponent extends AdjustmentsBaseComponent {
  @action handleButtonsChange(newVal: number) {
    set(this.args.model, 'buttonCount', newVal);
    this.args.onChange();
  }

  get titleOptions() {
    return [
      {
        id: 'title',
        label: this.intl.t('content.editor.section.title_options.title'),
      },
      {
        id: 'title-subtitle',
        label: this.intl.t(
          'content.editor.section.title_options.title_subtitle'
        ),
      },
      {
        id: 'title-subtitle-uppernote',
        label: this.intl.t(
          'content.editor.section.title_options.title_subtitle_uppernote'
        ),
      },
    ];
  }

  get imageOrderOptions() {
    return [
      {
        id: 'default',
        label: this.intl.t('content.editor.section.reverse_order.default'),
      },
      {
        id: 'reversed',
        label: this.intl.t('content.editor.section.reverse_order.reversed'),
      },
    ];
  }

  @action async handleImageOrderChange(imageOrder: string) {
    this.model.reverseOrder = imageOrder === 'reversed';
    await this.args.onChange();
  }
}
