/* import __COLOCATED_TEMPLATE__ from './new-context.hbs'; */
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import PreparedMessageClass from 'teamtailor/classes/prepared-message';
import CREATE_MEETING_MUTATION from 'teamtailor/gql/meetings/create-meeting.graphql';

import {
  ICandidate,
  IJobApplication,
  IUser,
} from 'teamtailor/components/meeting/types';
import RouterService from '@ember/routing/router-service';
import { IGraphQLErrorResponse } from 'teamtailor/utils/meetings/error-handling';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import ErrorClass from 'teamtailor/classes/error-class';
import { JobClass } from 'teamtailor/classes/job';
import {
  CandidateAttendeeClass,
  IMeetingRecord,
  MeetingEventListClass,
} from 'teamtailor/classes/meetings';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { Response } from 'teamtailor/services/meeting-loader-new-graphql';
import CandidateModel from 'teamtailor/models/candidate';
import moment, { Moment } from 'moment-timezone';

interface Args {
  data: Response;
  close: (meetingId?: string, meetingEventId?: string) => void;
  loading: boolean;
  changeCandidate: (candidate: CandidateModel) => void;
  onSave?: () => void;
}

export default class NewContext extends Component<Args> {
  @service declare router: RouterService;
  @service declare apollo: TeamtailorApolloService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare store: Store;

  @tracked scrollDistance = 0;
  @tracked confirmDialogOpen = false;
  @tracked confirmLocationDialogOpen = false;

  @action changeCandidate(candidate: CandidateModel) {
    this.args.changeCandidate(candidate);
  }

  get loading(): boolean {
    return this.args.loading;
  }

  get currentUser(): IUser {
    return this.args.data.user;
  }

  get meetingRooms(): MeetingRoomAttendeeClass[] {
    return this.args.data.meetingRooms;
  }

  get candidates(): CandidateAttendeeClass[] | undefined {
    return this.args.data.meetingEvents.meetingEvents[0]?.meetingEventAttendees
      ?.candidateAttendees;
  }

  get errors(): ErrorClass {
    return this.args.data.errors;
  }

  get preparedMessage(): PreparedMessageClass {
    return this.args.data.preparedMessage;
  }

  get meetingEvents(): MeetingEventListClass {
    return this.args.data.meetingEvents;
  }

  get jobApplications(): IJobApplication[] {
    return this.args.data.jobApplications;
  }

  get job(): JobClass {
    return this.args.data.jobClass;
  }

  get hasSelfSchedule(): boolean {
    return this.meetingEvents.hasSelfSchedule();
  }

  get readOnly(): boolean {
    if (!this.candidates || this.candidates.length === 0) {
      return true;
    }

    const startDates: Moment[] = this.meetingEvents.meetingEvents
      .filter((event) => event.originalBookedSlot)
      .map((event) => moment(event.originalBookedSlot?.startsAt));

    if (startDates.length > 0) {
      const lastEventStart = moment.max(startDates);
      return moment().isAfter(lastEventStart);
    }

    return false;
  }

  get anyMeetingWithoutLocation() {
    return this.meetingEvents.meetingEvents.find(
      (event) => !event.meetingEventLocation
    );
  }

  get anyMeetingWithoutTitle() {
    return (
      this.meetingEvents.meetingEvents.find((event) => !event.summary) &&
      !this.preparedMessage.subject
    );
  }

  get anyMeetingWithoutDate() {
    return this.meetingEvents.meetingEvents.find(
      (event) => !event.bookedSlot && !event.selfSchedule
    );
  }

  meetingAttributes(): IMeetingRecord {
    const attrs = {
      meetingEventsAttributes: this.meetingEvents.toAttributes(),
      preparedMessageAttributes: this.preparedMessage.hasAnyData
        ? this.preparedMessage.toAttributes()
        : undefined,

      jobId: this.job.job ? this.job.job.id : '',
    };

    return attrs;
  }

  async reloadCandidateMeetingEvents(candidate: ICandidate) {
    const candidateModel = await this.store.findRecord(
      'candidate',
      candidate.id
    );
    candidateModel.meetingEvents.reload();
  }

  @action
  handleScroll({ target }: Event): void {
    if (target instanceof HTMLElement) {
      this.scrollDistance = target.scrollTop;
    }
  }

  @action
  sendCloseAction(): void {
    if (this.meetingEvents.hasChanges()) {
      this.confirmDialogOpen = true;
    } else {
      this.args.close();
    }
  }

  @action
  closeConfirmDialog(): void {
    this.confirmDialogOpen = false;
  }

  @action
  cancelAndClose(): void {
    this.confirmDialogOpen = false;
    this.args.close();
  }

  @action
  async save() {
    if (this.anyMeetingWithoutLocation && !this.readOnly) {
      this.confirmLocationDialogOpen = true;
    } else {
      return this.confirmSave();
    }
  }

  @action
  closeConfirmLocation() {
    this.confirmLocationDialogOpen = false;
  }

  @action
  async confirmSave(): Promise<void> {
    const variables = {
      meetingAttributes: this.meetingAttributes(),
    };

    return (
      this.apollo
        .mutate({
          mutation: CREATE_MEETING_MUTATION,
          variables,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response) {
            this.errors.reset();
            if (
              this.candidates?.length === 1 &&
              this.candidates.firstObject?.candidate
            ) {
              this.reloadCandidateMeetingEvents(
                this.candidates.firstObject.candidate
              );
            }

            this.args.close(
              response.createMeeting.meeting[0].id,
              response.createMeeting.meeting[0].meetingEvents[0].id
            );

            if (this.args.onSave) {
              this.args.onSave();
            }

            this.flashMessages.success(
              this.intl.t('components.meeting_created_successfully')
            );
          }
        })
        .catch(({ errors }: IGraphQLErrorResponse) => {
          if (errors) {
            this.errors.update(errors);
          }

          throw Error(this.intl.t('errors.something_went_wrong'));
        })
    );
  }
}
