import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ContentBlockFaq extends ApplicationSerializer {
  payloadKeyFromModelName() {
    return 'content_block_faq';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-faq': ContentBlockFaq;
  }
}
