import CustomizeTableColumn from 'teamtailor/utils/candidate-table/column';
import CandidateModel from 'teamtailor/models/candidate';
import CustomFieldOptionModel from 'teamtailor/models/custom-field/option';
import { set } from '@ember/object';
import moment from 'moment-timezone';
import { camelize } from '@ember/string';
import { get } from '../get';
import { DepartmentModel, RoleModel } from 'teamtailor/models';

export default function setColumnValues(
  columns: CustomizeTableColumn[],
  candidate: CandidateModel
) {
  return columns.map((columnArg: CustomizeTableColumn) => {
    const column = columnArg.newInstance(); // This is needed for templates to update with new values. Might be an ember bug 🤷‍♂️

    if (column.isCustomField) {
      setCustomFieldsValues(column, candidate);
    } else if (column.isRecruitmentColumn) {
      setRecruitmentValues(column, candidate);
    } else if (column.isQuestionColumn) {
      setQuestionValues(column, candidate);
    } else if (column.isPartnerColumn) {
      setPartnerResultsValues(column, candidate);
    } else if (
      column.isStringColumn ||
      column.isIntegerColumn ||
      column.isDateColumn
    ) {
      setDirectValues(column, candidate);
    } else {
      setRelationsValues(column, candidate);
    }

    return column;
  });
}

const setDirectValues = (
  column: CustomizeTableColumn,
  candidate: CandidateModel
) => {
  const value = candidate[camelize(column.name) as keyof CandidateModel];

  column.value = value;
};

const setRecruitmentValues = async (
  column: CustomizeTableColumn,
  candidate: CandidateModel
) => {
  let value: string | undefined | string[];

  if (column.name === 'sourced_external') {
    value = [
      column.intl.t(`common.${candidate.isExternallySourced ? 'yes' : 'no'}`),
    ];
  } else if (column.name === 'external_recruiter_id') {
    const externalRecruiter = await get(candidate, 'externalRecruiter');
    value = externalRecruiter?.name;
  } else if (column.name === 'recruiting_firm_id') {
    const recruitingFirm = await get(candidate, 'recruitingFirm');
    value = recruitingFirm?.name;
  }

  set(column, `${column.displayWithBadges ? 'values' : 'value'}`, value);
};

const setQuestionValues = async (
  column: CustomizeTableColumn,
  candidate: CandidateModel
) => {
  const values: { jobTitle: string; values: any }[] = [];
  const questionId = column.nested?.id;

  if (questionId) {
    const answers = await candidate.answers;
    const filteredAnswers = answers.filter((answer) => {
      return answer.questionId === questionId;
    });

    filteredAnswers.forEach((answer) => {
      values.push({
        jobTitle: answer.pickedQuestion?.job
          ? get(answer.pickedQuestion.job, 'title')
          : '',

        values: [answer],
      });
    });
  }

  set(column, 'values', values);
};

const setPartnerResultsValues = async (
  column: CustomizeTableColumn,
  candidate: CandidateModel
) => {
  const values: { jobTitle: string; values: any }[] = [];
  const partnerId = column.nested?.id;
  if (partnerId) {
    const partnerResults = await candidate.partnerResults;
    const filteredPartnerResults = partnerResults.filter(
      (result) => result.partnerId === partnerId
    );
    filteredPartnerResults.forEach((result) => {
      const resultValue =
        column.name === 'score'
          ? result.assessmentScore
          : column.intl.t(
              `candidates.candidate.candidate_table.${result.status}`
            );

      values.push({
        jobTitle: get(result.job, 'title'),
        values: [resultValue],
      });
    });
  }

  set(column, 'values', values);
};

const setRelationsValues = async (
  column: CustomizeTableColumn,
  candidate: CandidateModel
) => {
  let values: (string | undefined)[],
    department: DepartmentModel | null,
    role: RoleModel | null;

  switch (column.name) {
    case 'department_id':
      department = await get(candidate, 'department');
      values = [department?.name];
      break;
    case 'role_id':
      role = await get(candidate, 'role');
      values = [role?.name];
      break;
    case 'location_ids': {
      const pickedLocations = await candidate.pickedLocations;
      const locationNames = pickedLocations
        .uniqBy('location.id')
        .map(async (pickedLocation) => {
          const location = await pickedLocation.location;
          return location?.nameOrCity;
        });

      values = await Promise.all(locationNames);
      break;
    }

    case 'job_ids': {
      const jobApplications = await candidate.jobApplications;
      values = jobApplications.mapBy('jobTitle');
      break;
    }

    case 'language_code':
      values = [candidate.languageCode];
      break;
    case 'reviewed_by': {
      const reviews = await candidate.reviews;
      const reviewerNames = reviews.map(async (review) => {
        const user = await review.user;
        return get(user, 'nameOrEmail');
      });

      values = await Promise.all(reviewerNames);
      break;
    }

    default:
      values = [];
  }

  column.values = values;
};

const setCustomFieldsValues = async (
  column: CustomizeTableColumn,
  candidate: CandidateModel
) => {
  const pickedCustomFields = await candidate.pickedCustomFields;
  const pickedCustomField = pickedCustomFields.findBy(
    'customFieldId',
    column.id
  );

  let value: string | undefined | string[] | number;

  if (pickedCustomField) {
    if (column.type === 'polar') {
      value = [column.intl.t(`common.${pickedCustomField.value === 'true'}`)];
    } else if (column.type === '[Option]') {
      const customField = get(
        candidate.company,
        'visibleCandidateCustomFields'
      ).findBy('id', column.id);
      if (customField) {
        value = customField.options
          .filter((option: CustomFieldOptionModel) => {
            return (pickedCustomField.value as string[]).includes(option.id);
          })
          .mapBy('value');
      }
    } else if (column.type === 'date') {
      value = moment(pickedCustomField.value).format('YYYY-MM-DD');
    } else {
      value = pickedCustomField.value ?? undefined;
    }
  }

  set(column, `${column.displayWithBadges ? 'values' : 'value'}`, value);
};
