/* import __COLOCATED_TEMPLATE__ from './select-activation.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import ChannelModel from 'teamtailor/models/channel';
import CurrentService from 'teamtailor/services/current';

export interface PromotionSelectActivationArgs {
  selectedActivationType: 'teamtailor' | 'existing_agreement';
  channel: ChannelModel;
}

export default class PromotionSelectActivation extends Component<PromotionSelectActivationArgs> {
  @service declare current: CurrentService;

  get showForm() {
    return ['partner_or_teamtailor', 'charged_by_partner'].includes(
      this.args.channel.allowedPaymentTypes
    );
  }

  get creditCardForPromote() {
    return this.current.company.creditCardForPromote;
  }
}
