/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { helper } from '@ember/component/helper';
import { get } from 'teamtailor/utils/get';
import { DataTableColumn } from '../data-table';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

type Args = {
  data: DataTableColumn[];
};

export default class extends Component<Args> {
  @tracked expandedColumn: DataTableColumn | null = null;
  @tracked syncStarted = false;

  get modelIds() {
    return this.fetchTask.value || [];
  }

  get expandableColumns() {
    return this.args.data.filter(
      (d) => d.type === 'expandable' || d.expandable
    );
  }

  get totalSpan() {
    return this.args.data
      .map((a) => a.colspan || 1)
      .reduce((acc, a) => acc + a, 0);
  }

  expandedModels = dropTask(async () => {
    if (this.expandedColumn?.modelIdsPropertyName) {
      const result = await get(
        this.expandedColumn.row,
        this.expandedColumn.modelIdsPropertyName
      );
      return result;
    }
  });

  fetchTask = trackedTask(this, this.expandedModels, () => [
    this.expandedColumn,
  ]);

  @action
  handleShowExpandedColumn(value: DataTableColumn) {
    if (value.propertyName === this.expandedColumn?.propertyName) {
      this.expandedColumn = null;
    } else {
      this.expandedColumn = value;
    }
  }

  getColumnComponent = helper(([columnData]) => {
    let type = 'cell';
    if (columnData.preloaded) {
      type = 'preloaded-model';
    } else if (columnData.expandable && columnData.type !== 'expandable') {
      type = 'candidates';
    } else if (columnData.type) {
      type = columnData.type;
    }

    return `data-table/${type}`;
  });
}
