/* import __COLOCATED_TEMPLATE__ from './role-selector.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked category;

  get categoryWithDefault() {
    return this.category || this.getCategoryFromRole(this.args.groupValue);
  }

  categories = {
    no_access: ['no_access'],
    designer: ['designer'],
    hiring_team: ['user', 'hiring_manager', 'recruiter'],
    admin: ['recruitment_admin', 'admin'],
  };

  getCategoryFromRole(role) {
    return Object.keys(this.categories).find((category) => {
      const roles = this.categories[category];
      return roles.includes(role);
    });
  }

  @action onCategoryChange(category) {
    this.category = category;
    this.args.onChange(this.categories[category][0]);
  }

  @action onRoleChange(role) {
    this.args.onChange(role);

    const category = this.getCategoryFromRole(role);
    this.category = category;
  }
}
