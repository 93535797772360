/* import __COLOCATED_TEMPLATE__ from './prepared-message.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { IQuestion } from 'teamtailor/components/meeting/types';
import PreparedMessageClass, {
  PickedUploadFields,
} from 'teamtailor/classes/prepared-message';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import DirectUploadService from 'teamtailor/services/direct-upload';
import { UploadFile } from 'ember-file-upload';
import Current from 'teamtailor/services/current';

interface IPreparedMessageArgs {
  preparedMessage: PreparedMessageClass;
  readOnly?: boolean;
}

export default class PreparedMessageComponent extends Component<IPreparedMessageArgs> {
  @service declare current: Current;
  @service declare apollo: TeamtailorApolloService;
  @service declare directUpload: DirectUploadService;

  @tracked declare toolbarContainer: Element;

  @tracked isFocused = false;
  @tracked isHovered = false;

  get readOnly(): boolean {
    return this.args.readOnly ?? false;
  }

  get preparedMessage() {
    return this.args.preparedMessage;
  }

  get nonPickedQuestions() {
    return this.current.company.questions.filter((question) => {
      return !this.preparedMessage.questions.find((q) => q.id === question.id);
    });
  }

  @action
  selectQuestion(question: IQuestion): void {
    this.preparedMessage.questions = [
      ...this.preparedMessage.questions,
      question,
    ];
  }

  @action
  focusRedactor(): void {
    this.isFocused = true;
  }

  @action
  blurRedactor(): void {
    this.isFocused = false;
  }

  @action
  mouseEnterRedactor(): void {
    this.isHovered = true;
  }

  @action
  mouseOutRedactor(): void {
    this.isHovered = false;
  }

  @action
  async addAttachment(file: UploadFile): Promise<void> {
    file.name = file.name
      .replace(/[^a-z0-9.]/gi, '-')
      .replace(/[-]+/g, '-')
      .toLowerCase();

    const upload = {
      fileFileName: file.name,
      fileFileSize: file.size,
      fileContentType: file.type,
      isUploading: true,
      fromTemplate: false,
    };

    this.preparedMessage.uploads = [...this.preparedMessage.uploads, upload];

    const fileKey = await this.directUpload.s3(file, { returnKey: true });

    this.preparedMessage.uploads = this.preparedMessage.uploads.filter(
      (u) => u !== upload
    );

    this.preparedMessage.uploads = [
      ...this.preparedMessage.uploads,
      { ...upload, fileKey, isUploading: false },
    ];
  }

  @action
  registerToolbarContainer(element: Element): void {
    this.toolbarContainer = element;
  }

  @action
  removeQuestion(question: IQuestion): void {
    const questions = this.preparedMessage.questions.filter(
      (q) => q !== question
    );
    this.preparedMessage.questions = [...questions];
  }

  @action
  removeAttachment(upload: PickedUploadFields): void {
    const uploads = this.preparedMessage.uploads.filter((u) => u !== upload);
    this.preparedMessage.uploads = [...uploads];
  }
}
