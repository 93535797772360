/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import { parallel } from 'ember-animated';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';
import { easeOut, easeIn } from 'ember-animated/easings/cosine';

import { action } from '@ember/object';

const HEADER_TYPES = [
  'title',
  'candidate',
  'today',
  'tomorrow',
  'upcoming',
  'completed',
];
export default class ListComponent extends Component {
  headerTypes = HEADER_TYPES;

  noop = () => {};

  get doneTodayCount() {
    return this.args.totalTodayCount - this.args.dueTodayCount;
  }

  headerFor(dueGroup) {
    return {
      isHeader: true,
      id: dueGroup,
      count: this.getCount(dueGroup),
      totalCount: this.getTotalCount(dueGroup),
    };
  }

  get todosWithHeaders() {
    let todos = [...this.args.todos];
    let groups = [...this.headerTypes];

    return todos.reduce((acc, todo, index) => {
      if (this.args.selectedSortingOrder !== 'due') {
        if (index === 0 && todo.dueGroup !== 'completed') {
          acc.push(this.headerFor(this.args.selectedSortingOrder));
        } else if (groups.length > 0 && todo.dueGroup === 'completed') {
          groups = [];
          acc.push(this.headerFor(todo.dueGroup));
        }
      } else if (
        index === 0 ||
        (groups.length > 0 && todo.dueGroup !== groups[0])
      ) {
        while (groups.length > 0 && todo.dueGroup !== groups[0]) {
          groups.shift();
        }

        acc.push(this.headerFor(todo.dueGroup));
      }

      acc.push(todo);
      return acc;
    }, []);
  }

  getCount(type) {
    switch (type) {
      case 'today':
        return this.doneTodayCount;
      case 'tomorrow':
        return this.args.dueTomorrowCount;
      case 'upcoming':
        return this.args.dueLaterCount;
      case 'completed':
        return this.args.completedCount;
    }

    return null;
  }

  getTotalCount(type) {
    if (type === 'today') {
      return this.args.totalTodayCount;
    }

    return null;
  }

  @action
  scrollToElement(element) {
    if (!this.args.onScrollTo) {
      return;
    }

    let observer = new MutationObserver(() => {
      if (document.contains(element)) {
        observer.disconnect();

        this.args.onScrollTo(
          element.offsetTop,
          element.parentNode.scrollHeight
        );
      }
    });
    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });
  }

  @action
  // eslint-disable-next-line require-yield
  *transition({ insertedSprites, removedSprites }) {
    for (let sprite of insertedSprites) {
      if (this.headerTypes.includes(sprite.owner.id)) {
        fadeIn(sprite);
      } else {
        sprite.applyStyles({ 'z-index': '1' });
        sprite.startAtPixel({ x: -sprite.finalBounds.width * 2 });
        parallel(
          move(sprite, { easing: easeOut }),
          fadeIn(sprite, { from: '0' })
        );
      }
    }

    for (let sprite of removedSprites) {
      if (this.headerTypes.includes(sprite.owner.id)) {
        fadeOut(sprite);
      } else {
        sprite.applyStyles({ 'z-index': '1' });
        sprite.endAtPixel({ x: -sprite.initialBounds.width * 2 });
        parallel(move(sprite, { easing: easeIn }), fadeOut(sprite));
      }
    }
  }
}
