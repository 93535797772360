/* import __COLOCATED_TEMPLATE__ from './nps-breakdown.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service intl;

  get data() {
    return this.args.breakdown.map((row) => ({
      ...row,
      name: this.intl.t(`insights.molecules.nps_breakdown.${row.name}`),
    }));
  }
}
