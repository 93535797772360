/* import __COLOCATED_TEMPLATE__ from './segment-list-item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { timeout, restartableTask } from 'ember-concurrency';
import classnames from 'teamtailor/utils/classnames';
import { next } from '@ember/runloop';

export default class SegmentListItem extends Component {
  get isActiveOrFakeActive() {
    return this.args.isActive || this.fakeActive.isRunning;
  }

  get iconRotation() {
    return this.displayChildren ? 0 : 270;
  }

  get iconClasses() {
    return classnames('c-segment-list-item__icon', {
      'c-segment-list-item__icon--emoji': this.args.emoji,
      'c-segment-list-item__icon--children': this.args.hasChildren,
    });
  }

  get displayChildren() {
    if (!this.args.hasChildren) {
      return false;
    }

    return this.args.isOpen;
  }

  fakeActive = restartableTask(async () => {
    await timeout(500);
  });

  @action
  handleButtonClick() {
    this.fakeActive.perform();
    next(() => {
      this.args.handleClick?.();
    });
  }

  @action
  handleSettingsClick(event) {
    event.stopPropagation();
    this.args.onSettingsClick?.();
  }

  @action
  onIconClick(e) {
    if (!this.args.hasChildren) {
      return;
    }

    e.stopPropagation();
    this.args.handleToggleOpen?.(this.args.isOpen);
  }
}
