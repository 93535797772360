/* import __COLOCATED_TEMPLATE__ from './job-offer-flow.hbs'; */
import { get } from 'teamtailor/utils/get';
import Base from './base';
import { inject as service } from '@ember/service';

export default class ActivityItemJobOfferFlowComponent extends Base {
  @service router;

  get translationKey() {
    if (get(this.activity, 'isJobOfferFlowApproved')) {
      return 'components.activity_item.job_offer_approval.approved';
    } else if (get(this.activity, 'isJobOfferFlowVerdictReminder')) {
      return 'components.activity_item.job_offer_approval.reminded';
    } else if (get(this.activity, 'isJobOfferFlowVerdictRequested')) {
      return 'components.activity_item.job_offer_approval.requested';
    }

    return 'components.activity_item.job_offer_approval.the_job_offer';
  }

  get jobOfferUrl() {
    return this.router.urlFor(
      'jobs.job.stages.index.candidate.job-offer.show',
      get(this.activity, 'job'),
      get(this.activity, 'candidate'),
      get(this.activity, 'jobOffer')
    );
  }
}
