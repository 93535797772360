import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { getTimeSlot } from 'teamtailor/utils/time-slot';
import EmployeeController from 'teamtailor/controllers/employee';
import { Transition } from 'teamtailor/utils/type-utils';
import { inject as service } from '@ember/service';
import ApolloService from 'ember-apollo-client/services/apollo';
import { IGraphqlPageInfo, IJob } from 'teamtailor/components/meeting/types';
import JOBS_QUERY from 'teamtailor/gql/employee-dashboard-jobs.graphql';
import { dropTask } from 'ember-concurrency';
import Current from 'teamtailor/services/current';

export type JobsConnection = {
  jobsConnection: {
    totalCount: number;
    pageInfo: IGraphqlPageInfo;
  };
  jobs: IJob[];
};

export type QueryParams = {
  page: number;
  divisionId: number;
  departmentId: number;
  locationId: number;
  query: string;
};

export default class EmployeeRoute extends ScrollToTopRoute {
  @service declare apollo: ApolloService;
  @service declare current: Current;

  queryParams = {
    page: {
      refreshModel: true,
    },

    divisionId: {
      refreshModel: true,
    },

    departmentId: {
      refreshModel: true,
    },

    locationId: {
      refreshModel: true,
    },

    query: {
      refreshModel: true,
    },
  };

  fetchTask = dropTask(async (params: QueryParams) => {
    const { page, query, divisionId, departmentId, locationId } = params;

    return this.apollo.watchQuery<JobsConnection>({
      query: JOBS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: {
        perPage: 5,
        page,
        includeStageData:
          this.current.company.employeeDashboardRecruitmentProgress,

        filter: {
          specialStatus: 'published',
          locationId,
          departmentId,
          divisionId,
          query,
        },
      },
    });
  });

  model(params: QueryParams) {
    return this.fetchTask.perform(params);
  }

  setupController(
    controller: EmployeeController,
    model: JobsConnection,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    controller.totalAmountOfJobs = model.jobsConnection.totalCount;
    controller.useCardView = model.jobsConnection.totalCount <= 20;
    controller.timeSlot = getTimeSlot();
    controller.fetchTask = this.fetchTask;
  }
}
