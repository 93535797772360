/* import __COLOCATED_TEMPLATE__ from './candidate-stage-card.hbs'; */
import Component from '@glimmer/component';
import { set } from '@ember/object';
import ENV from 'teamtailor/config/environment';
import { inject as service } from '@ember/service';
import TextScramble from 'teamtailor/utils/text-scramble';
import { dropTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import isBetween from 'teamtailor/utils/is-between';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

const POPPER_OPTIONS = Object.freeze({
  modifiers: {
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
});

export default class CandidateStageCardComponent extends Component {
  @service stageListHelper;
  @service timeFormat;
  @service permissions;
  @service flipper;
  @service current;

  @tracked
  element = null;

  popperOptions = POPPER_OPTIONS;

  @tracked
  scrambledName = '';

  @tracked
  showRestrictedCandidateModal = false;

  get candidate() {
    return get(this.args.jobApplication, 'candidate');
  }

  get hasCandidate() {
    return get(this.candidate, 'isFulfilled');
  }

  get decayLevel() {
    return `candidate-stage-card--decay-${this.decayStage}`;
  }

  get notRejected() {
    return !this.args.rejected;
  }

  get hasReferrals() {
    return get(this.candidate, 'referralsCount') > 0;
  }

  get isSourced() {
    return get(this.args.jobApplication, 'sourcedAt');
  }

  get overdue() {
    return (
      get(this.args.jobApplication, 'isGuideTimeOverDue') && this.notRejected
    );
  }

  get hasPartnerResults() {
    return get(this.candidate, 'partnerResultsWithScores')?.length > 0;
  }

  get hasScoreObjects() {
    return get(this.candidate, 'scoreObjects')?.length > 0;
  }

  get hasFooter() {
    return (
      (this.shouldShowPrediction ||
        this.hasReferrals ||
        this.isSourced ||
        this.overdue ||
        this.hasScoreObjects ||
        get(this.args.jobApplication, 'hasScores') ||
        get(this.candidate, 'willBeDeletedAt') ||
        get(this.candidate, 'internal') ||
        get(this.candidate, 'restricted') ||
        this.args.competenceBased) &&
      get(this.args.jobApplication, 'userCanAccess')
    );
  }

  get isSelected() {
    return this.args.selectedJobApplications.includes(this.args.jobApplication);
  }

  set isSelected(value) {
    if (value) {
      this.selectJobApplication();
    } else {
      this.deselectJobApplication();
    }
  }

  get totalInStage() {
    return get(this.args.jobApplication, 'stage.jobApplications');
  }

  get selectedInStage() {
    return this.args.selectedJobApplications.filter((application) => {
      return this.totalInStage.includes(application);
    });
  }

  get isAnonymousStage() {
    return get(this.args.jobApplication, 'stage.anonymous');
  }

  get candidateDisplayName() {
    const nameOrEmail = get(this.candidate, 'nameOrEmail');
    return this.isAnonymousStage
      ? get(this.candidate, 'anonymousName')
      : nameOrEmail;
  }

  get decayStage() {
    const lastActivity = get(this.candidate, 'lastActivity');

    if (!this.hasCandidate || !lastActivity) {
      return 0;
    }

    const now = moment();
    const then = moment(lastActivity);

    const diff = now.diff(then, 'weeks');

    if (diff < 2) {
      return 0;
    } else if (isBetween(diff, 2, 4)) {
      return 1;
    } else {
      return 2;
    }
  }

  get scoreObjects() {
    return get(this.candidate, 'scoreObjects')?.filter((scoreObject) => {
      const jobId = `${get(this.args.jobApplication, 'jobId')}`;
      return get(scoreObject, 'jobId') === jobId;
    });
  }

  get prediction() {
    return get(this.args.jobApplication, 'predictions')?.filter(
      (p) => p.name === ENV.ML_DATASET
    )?.[0];
  }

  get hasPrediction() {
    return !!get(this, 'prediction')?.result;
  }

  get shouldShowPrediction() {
    return (
      this.hasPrediction && this.current.company.hasFeature('ai_suggestion')
    );
  }

  textScramble = dropTask(async (fromText, toText, nameWrapEl) => {
    const fx = new TextScramble();

    const scrambledNameEl = document.createElement('span');
    const originalNameEl = nameWrapEl.querySelector(
      '.candidate-stage-card__name'
    );

    originalNameEl.classList.add('hidden');

    nameWrapEl.appendChild(scrambledNameEl);

    await fx.setText(fromText, toText, (output) => {
      scrambledNameEl.innerText = output;
    });

    scrambledNameEl.remove();
    originalNameEl.classList.remove('hidden');
  });

  get textScrambleAnimationQueueItem() {
    return this.stageListHelper.getMatchingAnimationQueueItem({
      type: 'textScramble',
      data: {
        stageId: get(this.args.jobApplication, 'stage.id'),
        jobApplicationId: get(this.args.jobApplication, 'id'),
        targetIndex: this.args.collectionIndex,
      },
    });
  }

  animateNameScrambling = modifier((element, [animationQueueItem]) => {
    if (!animationQueueItem || !element.isConnected) {
      return;
    }

    const {
      data: { fromAnonymous, toAnonymous },
    } = animationQueueItem;
    const realName = get(this.candidate, 'nameOrEmail');
    const anonymousName = get(this.candidate, 'anonymousName');
    if (fromAnonymous === true && toAnonymous === false) {
      this.textScramble.perform(
        anonymousName,
        realName,
        element.querySelector('a')
      );
    } else if (fromAnonymous === false && toAnonymous === true) {
      this.textScramble.perform(
        realName,
        anonymousName,
        element.querySelector('a')
      );
    }

    this.stageListHelper.animationQueue.removeObject(animationQueueItem);
  });

  animateSuggestedStars = modifier(
    async (element, [lastSuggestedCandidateId]) => {
      if (
        lastSuggestedCandidateId &&
        lastSuggestedCandidateId === get(this.candidate, 'id')
      ) {
        const lottie = await import('lottie-web');

        const createAnimation = async (filePath, className) => {
          const animationElement = document.createElement('div');
          animationElement.classList.add(className);

          element.appendChild(animationElement);

          const animationData = await fetch(filePath).then((response) =>
            response.json()
          );

          const animation = lottie.loadAnimation({
            container: animationElement,
            renderer: 'svg',
            animationData,
            loop: false,
            autoplay: true,
          });

          animation.addEventListener('complete', () => {
            animation.destroy();
            animationElement.remove();
          });
        };

        next(() => {
          this.stageListHelper.lastSuggestedCandidateId = null;
        });

        createAnimation(
          '/assets/lottie-animations/copilot-twinkle-stars-top.json',
          'copilot-suggested-animation-top'
        );
        createAnimation(
          '/assets/lottie-animations/copilot-twinkle-stars-bottom.json',
          'copilot-suggested-animation-bottom'
        );
      }
    }
  );

  setAllSelectedState() {
    const selected = this.selectedInStage.length;
    const total = this.totalInStage.length;
    if (selected < total) {
      set(this.args.jobApplication, 'stage.allCandidatesSelected', false);
    } else {
      set(this.args.jobApplication, 'stage.allCandidatesSelected', true);
    }
  }

  get meetingStatus() {
    const statuses = get(this.candidate, 'meetingEventStatuses');
    const status = statuses
      ?.filter(
        (status) =>
          get(status, 'job.id') ===
            get(this.args.jobApplication, 'jobId').toString() &&
          (get(status, 'bookedSlotStartsAt') > new Date() ||
            !get(status, 'bookedSlotStartsAt'))
      )
      .sort((a, b) => {
        if (get(a, 'bookedSlotStartsAt') > get(b, 'bookedSlotStartsAt')) {
          return 1;
        } else {
          return -1;
        }
      })?.[0];

    return status?.status;
  }
}
