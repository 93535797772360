/* import __COLOCATED_TEMPLATE__ from './role-selector-input.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import FlipperService from 'teamtailor/services/flipper';
import IntlService from 'ember-intl/services/intl';

const ADDON_ICONS = {
  designer: {
    bgColor: 'bg-decorative-green-weak',
    icon: 'brush',
    iconColor: 'text-icon-decorative-green-weak',
  },

  analytics: {
    bgColor: 'bg-decorative-fuchsia-weak',
    icon: 'chart-simple',
    iconColor: 'text-icon-decorative-fuchsia-weak',
  },
};

interface RoleSelectorInputArgs {
  groupValue?: string;
  value?: string;
  checked?: boolean;
  selectedAddons?: string[];
  disabled?: boolean;
  onChange?: (mainRole: string, selectedAddons: string[]) => void;
}

export default class RoleSelectorInput extends Component<RoleSelectorInputArgs> {
  @service declare flipper: FlipperService;
  @service declare intl: IntlService;

  @tracked addonsSwitchEnabled = false;
  @tracked mainRole = '';
  @tracked selectedAddons: string[] = [];
  inputId = `role-selector-input-${guidFor(this)}`;

  get isChecked() {
    const group = this.args.groupValue ?? this.mainRole;
    return isPresent(group) && group === this.args.value;
  }

  get addonsSwitchEnabledComputed() {
    return this.addonsSwitchEnabled || isPresent(this.args.selectedAddons);
  }

  get selectedAddonsComputed() {
    return this.selectedAddons.length > 0
      ? this.selectedAddons
      : this.args.selectedAddons;
  }

  @action
  handleChange() {
    if (this.args.disabled) return;

    this.mainRole = this.args.value ?? '';
    this.selectedAddons = [];
    this.addonsSwitchEnabled = false;
    this.args.onChange?.(this.mainRole, this.selectedAddons);
  }

  @action
  onAddonChange(addonName: string) {
    this.mainRole = this.args.groupValue ?? this.mainRole;

    if (this.selectedAddons.length === 0) {
      this.selectedAddons = this.args.selectedAddons ?? [];
    }

    if (this.selectedAddons.includes(addonName)) {
      this.selectedAddons = this.selectedAddons.filter(
        (addon) => addon !== addonName
      );
    } else {
      this.selectedAddons = [...this.selectedAddons, addonName];
    }

    this.args.onChange?.(this.mainRole, this.selectedAddons);
  }

  @action
  handleAddonsSwitchChange(value: boolean): void {
    this.addonsSwitchEnabled = value;
    this.mainRole = this.args.value ?? '';
    this.selectedAddons = [];
    this.args.onChange?.(this.mainRole, this.selectedAddons);
  }

  @action
  addonIcon(addon: 'designer' | 'analytics') {
    return ADDON_ICONS[addon].icon;
  }

  @action
  addonIconStyle(addon: 'designer' | 'analytics') {
    return ADDON_ICONS[addon].iconColor;
  }

  @action
  addonIconBg(addon: 'designer' | 'analytics') {
    return ADDON_ICONS[addon].bgColor;
  }
}
