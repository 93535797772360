/* import __COLOCATED_TEMPLATE__ from './editable-flow-modal.hbs'; */
import { argDefault } from 'teamtailor/utils/arg-default';
import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import {
  JobOfferApprovalFlowModel,
  RequisitionFlowModel,
  RequisitionFlowStepModel,
} from 'teamtailor/models';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';

interface EditableFlowModalComponentArgs {
  model: RequisitionFlowModel | JobOfferApprovalFlowModel;
  partOfRequisition?: boolean;
  displayTitle?: boolean;
  onStopEditing?: () => void;
  onSave: () => Promise<void>;
  onDestroy?: () => Promise<void>;
}

type AttributeType = {
  stepIndex: number;
} & {
  requisitionFlow: RequisitionFlowModel | null;
  jobOfferApprovalFlow: RequisitionFlowModel | null;
};

export default class EditableFlowModalComponent extends Component<EditableFlowModalComponentArgs> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @tracked _steps: Array<{ id: string; userIds: string[] }> = [];

  @argDefault displayTitle = true;

  stepSorting = 'stepIndex';

  get showWarning() {
    return (
      this.args.partOfRequisition &&
      this.args.model instanceof RequisitionFlowModel
    );
  }

  get steps() {
    return get(this.args.model, 'steps');
  }

  get editableSteps() {
    return this.steps.rejectBy('isDeleted');
  }

  get sortedSteps() {
    return this.editableSteps.sortBy(this.stepSorting);
  }

  get firstStep() {
    return this.sortedSteps.firstObject;
  }

  get displayTitleInput() {
    return this.displayTitle && !this.args.model.isDefault;
  }

  get nextStepIndex() {
    const stepIndexes = this.editableSteps.mapBy('stepIndex');
    return Math.max(...stepIndexes) + 1;
  }

  get missingUsersError() {
    const errors = get(this.args.model, 'errors');
    return errors.has('requisition_flow_steps.users');
  }

  get missingTitleError() {
    const errors = get(this.args.model, 'errors');
    return errors.has('title');
  }

  get duplicateCustomFlowError() {
    const errors = get(this.args.model, 'errors');
    return errors.has('base');
  }

  @action
  addApprovalStep() {
    const model = get(this.args, 'model');

    const attributes = { stepIndex: this.nextStepIndex } as AttributeType;
    if (model instanceof RequisitionFlowModel) {
      attributes.requisitionFlow = model;
    } else if (model instanceof JobOfferApprovalFlowModel) {
      attributes.jobOfferApprovalFlow = model;
    }

    this.store.createRecord('requisition-flow-step', attributes);
  }

  @action
  handleDeleteStep(step: RequisitionFlowStepModel) {
    step.deleteRecord();
    this.steps
      .filterBy('isDeleted', false)
      .sortBy('stepIndex')
      .forEach((item, index) => set(item, 'stepIndex', index));
  }

  @action
  onSave() {
    if (!this.args.model.maxSalary) {
      set(this.args.model, 'currency', null);
      set(this.args.model, 'salaryTimeUnit', null);
    }

    return this.args.onSave();
  }
}
