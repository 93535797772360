/* import __COLOCATED_TEMPLATE__ from './nps-responses-table.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql } from '@apollo/client/core';
import { action } from '@ember/object';
import ApolloService from 'ember-apollo-client/services/apollo';
import { NpsRow } from 'teamtailor/classes/analytics/report-nps-responses';
import Current from 'teamtailor/services/current';
import AnalyticsService from 'teamtailor/services/analytics';
import CsvFileExporter from 'teamtailor/utils/csv-file-exporter';
import moment from 'moment-timezone';
import uniq from 'teamtailor/utils/uniq';

type Args = {
  data?: NpsRow[];
  loading?: boolean;
};

interface ApolloCandidate {
  nameOrEmail: string;
  id: string;
}

interface CandidateQueryResponse {
  candidates: ApolloCandidate[];
}

const COLUMN_CONFIGURATION_FOR_EXPORT = [
  {
    type: 'text',
    propertyName: 'npsResponseScore',
    headerKey: 'insights.widgets.nps_responses.export.score',
  },
  {
    type: 'text',
    propertyName: 'candidateName',
    headerKey: 'insights.widgets.nps_responses.export.candidate_name',
  },
  {
    type: 'text',
    propertyName: 'jobTitle',
    headerKey: 'insights.widgets.nps_responses.export.job_title',
  },
  {
    type: 'text',
    propertyName: 'stageNameWithStatus',
    headerKey: 'insights.widgets.nps_responses.export.stage_and_status',
  },
  {
    type: 'text',
    propertyName: 'date',
    headerKey: 'insights.widgets.nps_responses.export.date',
  },
  {
    type: 'text',
    propertyName: 'npsResponseFeedback',
    headerKey: 'insights.widgets.nps_responses.export.candidate_feedback',
  },
];

export default class NpsResponsesTable extends Component<Args> {
  @service declare analytics: AnalyticsService;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare apollo: ApolloService;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'timestamp';
  @tracked includeRejected = true;
  @tracked includeNonRejected = true;

  get hasDataToShow() {
    return this.responses.length > 0;
  }

  get shouldIncludeAll() {
    return this.includeRejected && this.includeNonRejected;
  }

  get shouldOnlyIncludeRejected() {
    return this.includeRejected && !this.includeNonRejected;
  }

  get shouldOnlyIncludeNonRejected() {
    return !this.includeRejected && this.includeNonRejected;
  }

  get responses() {
    if (!this.args.data) {
      return [];
    }

    switch (true) {
      case this.shouldIncludeAll:
        return this.args.data;
      case this.shouldOnlyIncludeRejected:
        return this.args.data.filterBy('stageIsReject', true);
      case this.shouldOnlyIncludeNonRejected:
        return this.args.data.filterBy('stageIsReject', false);
      default:
        return [];
    }
  }

  get sortedRows() {
    const { sortDirection, sortProperty, responses } = this;
    const sorted = responses.sortBy(sortProperty);
    return sortDirection === 'desc' ? sorted.reverse() : sorted;
  }

  get candidatesToEnrichForExport() {
    return uniq(this.responses.mapBy('candidate.id'));
  }

  async rowsToExport() {
    const { candidates } = await this.apollo.query<CandidateQueryResponse>({
      query: gql`
        query ($candidateIds: [ID!]!, $userId: ID!, $companyIds: [ID!]) {
          candidates(
            ids: $candidateIds
            userScope: { userId: $userId }
            groupCompanyIds: $companyIds
          ) {
            nameOrEmail
            id
          }
        }
      `,
      variables: {
        candidateIds: this.candidatesToEnrichForExport,
        userId: this.current.user.id,
        companyIds: this.analytics.availableCompanyIds,
      },
    });

    return this.responses.map((row) => ({
      jobTitle: row.jobTitle,
      date: moment(row.timestamp).format('YYYY-MM-DD'),
      npsResponseFeedback: row.npsResponseFeedback,
      npsResponseScore: row.npsResponseScore,
      stageNameWithStatus: row.displayStageName,
      candidateName:
        candidates.findBy('id', row.candidateId.toString())?.nameOrEmail ||
        this.intl.t('insights.common.deleted_candidate'),
    }));
  }

  @action
  async handleExport() {
    new CsvFileExporter(
      await this.rowsToExport(),
      COLUMN_CONFIGURATION_FOR_EXPORT,
      this.intl
    ).downloadFile({
      baseFileName: 'nps-overview',
      startDate: this.analytics.startDate,
      endDate: this.analytics.endDate,
    });
  }

  @action
  handleSort(sortProperty: string) {
    if (this.sortProperty === sortProperty) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }

  @action
  toggleIncludeRejected() {
    this.includeRejected = !this.includeRejected;
  }

  @action
  toggleIncludeNonRejected() {
    this.includeNonRejected = !this.includeNonRejected;
  }
}
