/* import __COLOCATED_TEMPLATE__ from './notification-settings.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked defaultNotificationConfigs = this.args.defaultNotificationConfigs;

  get allUsersSettings() {
    return this.defaultNotificationConfigs
      .filterBy('notificationConfigCategory', 'general')
      .sortBy('sortColumn');
  }

  get hiringTeamSettings() {
    return this.defaultNotificationConfigs
      .filterBy('notificationConfigCategory', 'hiring_team')
      .sortBy('sortColumn');
  }

  get recruiterSettings() {
    return this.defaultNotificationConfigs
      .filterBy('notificationConfigCategory', 'recruiter')
      .sortBy('sortColumn');
  }

  get requisitionSettings() {
    return this.defaultNotificationConfigs
      .filterBy('notificationConfigCategory', 'requisitions')
      .sortBy('sortColumn');
  }

  get gdprSettings() {
    return this.defaultNotificationConfigs
      .filterBy('notificationConfigCategory', 'gdpr')
      .sortBy('sortColumn');
  }

  get storySettings() {
    return this.defaultNotificationConfigs
      .filterBy('notificationConfigCategory', 'stories')
      .sortBy('sortColumn');
  }
}
