/* import __COLOCATED_TEMPLATE__ from './payment-methods.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import PaymentProcessorSettingModel, {
  PaymentMethodInterface,
} from 'teamtailor/models/payment-processor-setting';
import { CARD_BRAND_THEME } from 'teamtailor/constants/stripe';

interface PaymentMethodsInterface {
  paymentProcessorSetting: PaymentProcessorSettingModel;
  onPaymentMethodRemove: () => void;
}

export default class PaymentMethodsComponent extends Component<PaymentMethodsInterface> {
  cardBrandTheme: any = CARD_BRAND_THEME;

  get paymentMethods(): PaymentMethodInterface[] {
    const paymentMethods =
      this.args.paymentProcessorSetting.stripePaymentMethods || [];

    return paymentMethods.map((paymentMethod) => {
      paymentMethod.theme = this.cardBrandTheme[paymentMethod.brand];
      return paymentMethod;
    });
  }

  @action
  async handleDeletePaymentMethod(paymentMethodId: string) {
    await this.args.paymentProcessorSetting.removePaymentMethod(
      paymentMethodId
    );
    this.args.onPaymentMethodRemove();
  }
}
