/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { bgColor, textColor } from 'teamtailor/utils/theme-color';

export interface Args {
  text: string;
  selected: boolean;
  value?: string;
  onChange?: (value: unknown) => void;
  color?: string;
  colorVariant?: string;
}

export default class CoreToggleSwitchButtonComponent extends Component<Args> {
  get bgColor(): string {
    return bgColor(this.args.color ?? 'action', this.colorVariant);
  }

  get textColor(): string {
    return textColor(this.args.color ?? 'action', this.colorVariant);
  }

  get colorVariant(): string {
    return this.args.colorVariant || 'strong';
  }

  @action
  onChange(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target.checked) {
      this.args.onChange?.(this.args.value);
    }
  }
}
