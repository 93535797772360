/* import __COLOCATED_TEMPLATE__ from './users-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

const TABLE_COLUMNS = [
  { intl: 'insights.users.common.user_name', property: 'user.nameOrEmail' },
  {
    intl: 'insights.users.common.total_activities',
    property: 'totalActivities',
  },
  { intl: 'insights.users.common.moves', property: 'moves' },
  { intl: 'insights.users.common.hires', property: 'hires' },
  { intl: 'insights.users.common.rejections', property: 'rejections' },
  { intl: 'insights.users.common.sources', property: 'sources' },
  { intl: 'insights.users.common.comments', property: 'notes' },
  { intl: 'insights.users.common.messages', property: 'messages' },
  { intl: 'insights.users.common.reviews', property: 'reviews' },
];

export default class InsightsUsersList extends Component {
  @service current;

  tableColumns = TABLE_COLUMNS;
}
