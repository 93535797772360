/* import __COLOCATED_TEMPLATE__ from './noteworthy.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import ConnectSettingModel from 'teamtailor/models/connect-setting';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { ConnectMediaItemModel, VideoModel } from 'teamtailor/models';
import IframePreviewService from 'teamtailor/services/iframe-preview';

interface NoteworthyArgs {
  Args: {
    connectSettings: ConnectSettingModel;
  };
}

const ALLOWED_MEDIA_TYPES = ['video', 'gif', 'image'] as const;

type AllowedMediaTypes = (typeof ALLOWED_MEDIA_TYPES)[number];
type AllowedVideoTypes = 'file' | 'embed';

export default class NoteworthyComponent extends Component<NoteworthyArgs> {
  @service declare store: Store;
  @service declare iframePreview: IframePreviewService;

  @action
  async saveConnectSettings() {
    if (
      this.args.connectSettings.changedFields &&
      !this.args.connectSettings.changedFields.includes('noteworthy')
    ) {
      this.args.connectSettings.changedFields = [
        ...this.args.connectSettings.changedFields,
        'noteworthy',
      ];
    }

    await this.args.connectSettings.save();
    this.iframePreview.reloadIframe();
  }

  @action
  createMediaItem(mediaType: AllowedMediaTypes, videoType: AllowedVideoTypes) {
    if (!ALLOWED_MEDIA_TYPES.includes(mediaType)) {
      throw new Error(`Invalid media type: ${mediaType}`);
    }

    this.store.createRecord('connect-media-item', {
      mediaType,
      connectSetting: this.args.connectSettings,
      ...(mediaType === 'video' && { videoType }),
    });
  }

  @action
  async onSaveMediaItem(connectMediaItem: ConnectMediaItemModel) {
    await connectMediaItem.save();
    this.saveConnectSettings();
    this.iframePreview.reloadIframe();
  }

  @action
  async onUploadVideo(
    connectMediaItem: ConnectMediaItemModel,
    video: VideoModel
  ) {
    await this.onSaveMediaItem(connectMediaItem);
    const pickedVideo =
      connectMediaItem.pickedVideo ||
      this.store.createRecord('picked-video', {
        connectMediaItem,
      });

    pickedVideo.video = video;
    await pickedVideo.save();
    this.saveConnectSettings();
  }

  @action
  async handleDragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetIndex,
  }: {
    draggedItem: ConnectMediaItemModel;
    sourceList: ConnectMediaItemModel[];
    targetList: ConnectMediaItemModel[];
    sourceIndex: number;
    targetIndex: number;
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    const indexChange = targetIndex > sourceIndex ? 1 : -1;
    const targetItem = sourceList.objectAt(targetIndex);
    if (targetItem) {
      draggedItem.rowOrder = targetItem.rowOrder + indexChange;
    }

    draggedItem.rowOrderPosition = targetIndex;
    await draggedItem.save();
    this.iframePreview.reloadIframe();
    this.saveConnectSettings();
  }
}
