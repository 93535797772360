/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import Candidate from 'teamtailor/models/candidate';
import JobApplication from 'teamtailor/models/job-application';

interface JobOffersSignature {
  Args: {
    candidate: Candidate;
    jobApplication?: JobApplication;
  };
}

export default class CandidateModalNewMainSectionJobOffers extends Component<JobOffersSignature> {
  get jobOffers() {
    if (!this.args.jobApplication) {
      return null;
    }

    const jobApplicationId = this.args.jobApplication.id;
    return this.args.candidate.jobOffers.toArray().filter((jobOffer) => {
      return (
        jobOffer.belongsTo('jobApplication').id() === jobApplicationId &&
        !jobOffer.isNew
      );
    });
  }
}
