/* import __COLOCATED_TEMPLATE__ from './filter-list.hbs'; */
import Component from '@glimmer/component';
import {
  FilterCategory,
  FilterOption,
} from 'teamtailor/components/fancy-filters';
import ActiveFilter from 'teamtailor/components/fancy-filters/active-filter';
import ContainerFilter from 'teamtailor/utils/fancy-filters/container';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import fuzzysort from 'fuzzysort';
import { debounce, next } from '@ember/runloop';

interface FilterListArgs {
  options: FilterOption[];
  onSelect: (value: FilterOption) => void;
  activeFilter?: ActiveFilter;
  appearance?: string;
}

export default class extends Component<FilterListArgs> {
  @tracked selectedOption?: FilterOption = undefined;
  @tracked inputValue = '';
  @tracked page = 0;
  query = '';

  @action
  resetFilterInput(): void {
    this.inputValue = '';
  }

  @action
  handleSearchInput(e: Event) {
    this.inputValue = (e.target as HTMLInputElement).value;
  }

  @action
  setFocusOnInput(element: HTMLElement): void {
    next(() => element.focus());
  }

  @action
  reachedBottom() {
    if (
      this.isContainerFilter &&
      this.args.activeFilter?.filter?.loadSubfilters &&
      !this.args.activeFilter.filter.subFiltersAllLoaded
    ) {
      this.page = this.page + 1;
      this.args.activeFilter.filter.loadSubfilters(this.page, this.inputValue);
    }
  }

  get isSecondary(): boolean {
    return this.args.appearance === 'secondary';
  }

  get isContainerFilter(): boolean {
    return this.args.activeFilter?.filter instanceof ContainerFilter;
  }

  get options(): FilterOption[] {
    let { options } = this.args;

    if (this.isContainerFilter) {
      debounce(this, 'debouncedSearch', this.inputValue, 500);
    } else if (isPresent(this.inputValue)) {
      options = fuzzysort
        .go(this.inputValue, options, {
          key: 'translatedName',
          allowTypo: false,
          threshold: -10000,
        })
        .map((result) => result.obj);
    }

    return options;
  }

  debouncedSearch = (value: string) => {
    if (
      value !== this.query &&
      this.args.activeFilter?.filter?.loadSubfilters
    ) {
      this.query = value;
      this.page = 1;
      this.args.activeFilter.filter.loadSubfilters(this.page, value);
    }
  };

  get categories(): FilterCategory[] {
    return this.options.reduce(
      (categories: FilterCategory[], option: FilterOption) => {
        const category = categories.find((c) => c.name === option.category);

        if (category) {
          category.options.push(option);
        } else {
          categories.push({
            name: option.category,
            options: [option],
          });
        }

        return categories;
      },
      []
    );
  }
}
