/* import __COLOCATED_TEMPLATE__ from './color.hbs'; */
import Component from '@glimmer/component';
import { set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import BlockAdjustmentsService from 'teamtailor/services/block-adjustments';
import IntlService from 'ember-intl/services/intl';
import { SectionModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

type AdjustmentInputColorSignature = {
  Args: {
    model: SectionModel;
    displayValue?: string;
    attribute:
      | 'coverOverlayColor'
      | 'coverTextColor'
      | 'coverBackgroundColor'
      | 'customBackgroundColor'
      | 'customTitleColor'
      | 'customTextColor'
      | 'customLinkColor'
      | 'customHighlightColor';
    reloadIframeAfterChange?: boolean;
    onChange: (
      id: string,
      options?: { attribute?: string; reloadIframeAfterChange?: boolean }
    ) => void;
  };
};

export default class AdjustmentInputColorComponent extends Component<AdjustmentInputColorSignature> {
  @service declare blockAdjustments: BlockAdjustmentsService;
  @service declare intl: IntlService;

  @tracked
  colorPickerOpen = false;

  @tracked
  leftAlignedDropdown = false;

  @tracked
  selectedColor = this.args.displayValue;

  get value() {
    return get(this.args.model, this.args.attribute);
  }

  set value(color: string | undefined) {
    set(this.args.model, this.args.attribute, color?.toLowerCase());
  }

  get buttonValue() {
    if (this.args.displayValue === 'transparent') {
      return this.intl.t('content.editor.section.none');
    } else if (isEmpty(this.args.displayValue)) {
      return this.intl.t('content.editor.section.default');
    } else {
      return this.args.displayValue?.toLowerCase();
    }
  }

  @action
  initColor(element: Element) {
    const elementRect = element.getBoundingClientRect();
    const parentRect = element.parentElement?.getBoundingClientRect();
    if (!parentRect) return;

    this.leftAlignedDropdown =
      parentRect.right - elementRect.right > elementRect.width / 2;

    document.addEventListener(this.blockAdjustments.closeAllEvent, this.close);
  }

  @action
  destroyEvents() {
    document.removeEventListener(
      this.blockAdjustments.closeAllEvent,
      this.close
    );
  }

  @action
  close() {
    this.colorPickerOpen = false;
    this.blockAdjustments.editingAttribute = null;
  }

  @action
  toggleOpen() {
    this.colorPickerOpen = !this.colorPickerOpen;
    this.blockAdjustments.editingAttribute = this.colorPickerOpen
      ? this.args.attribute
      : undefined;
  }

  @action
  onColorChange(color: string) {
    this.value = color;
    this.args.onChange(color, {
      attribute: this.args.attribute,
      reloadIframeAfterChange: this.args.reloadIframeAfterChange,
    });
  }
}
