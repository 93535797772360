/* import __COLOCATED_TEMPLATE__ from './list-item.hbs'; */
import { assert } from '@ember/debug';
import { action, get } from '@ember/object';
import Component from '@glimmer/component';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import ScorecardCriteriumModel, {
  TOPIC,
} from 'teamtailor/models/scorecard-criterium';
import StageModel from 'teamtailor/models/stage';
import PickedInterviewKitModel from 'teamtailor/models/picked-interview-kit';
import uniqBy from 'teamtailor/utils/uniq-by';

export interface InterviewKitListItemArgs {
  interviewKit: InterviewKitModel;
  pickedInterviewKit: PickedInterviewKitModel;
  interviewKitStages?: StageModel[];
  jobId?: string;
  suggested?: boolean;
  highlightedScorecardCriteriums?: ScorecardCriteriumModel[];
  stages?: StageModel[];
  selectedStage?: StageModel;
  jobScorecardPicksIds?: string[];
  onAdd?: (interviewKitId: string) => void;
  onRemove?: (interviewKitId: string) => void;
  onStageSelected?: (
    pickedInterviewKit: PickedInterviewKitModel,
    stage: StageModel
  ) => void;
  isSelected?: boolean;
}

interface ScorecardCriteriumListItem {
  name: string;
  topic: string;
  faded?: boolean;
}

export default class InterviewKitListItem extends Component<InterviewKitListItemArgs> {
  get interviewKit(): InterviewKitModel {
    assert('interviewKit is required', this.args.interviewKit);
    return this.args.interviewKit;
  }

  get tags(): string[] {
    return get(this.interviewKit, 'tagNames');
  }

  get skillCriteriums(): ScorecardCriteriumModel[] {
    return uniqBy(
      get(this.interviewKit, 'skillPicks').mapBy('scorecardCriterium'),
      'id'
    );
  }

  get traitCriteriums(): ScorecardCriteriumModel[] {
    return uniqBy(
      get(this.interviewKit, 'personalityPicks').mapBy('scorecardCriterium'),
      'id'
    );
  }

  get highlightedScorecardCriteriums(): ScorecardCriteriumModel[] {
    return this.args.highlightedScorecardCriteriums || [];
  }

  get scorecardCriteriums(): ScorecardCriteriumListItem[] {
    if (this.highlightedScorecardCriteriums.length === 0) {
      return [...this.skillCriteriums, ...this.traitCriteriums].map(
        (criterium) => {
          return {
            name: get(criterium, 'name'),
            topic: get(criterium, 'topic'),
          };
        }
      );
    }

    const highlightedSkillPicks: ScorecardCriteriumModel[] = [];
    const highlightedPersonalityPicks: ScorecardCriteriumModel[] = [];
    const otherSkillPicks: ScorecardCriteriumModel[] = [];
    const otherPersonalityPicks: ScorecardCriteriumModel[] = [];

    [...this.skillCriteriums, ...this.traitCriteriums].forEach((criterium) => {
      if (
        this.highlightedScorecardCriteriums.findBy('id', get(criterium, 'id'))
      ) {
        if (get(criterium, 'topic') === TOPIC.SKILL) {
          highlightedSkillPicks.push(criterium);
        } else {
          highlightedPersonalityPicks.push(criterium);
        }
      } else {
        if (get(criterium, 'topic') === TOPIC.SKILL) {
          otherSkillPicks.push(criterium);
        } else {
          otherPersonalityPicks.push(criterium);
        }
      }
    });

    return [
      ...highlightedSkillPicks,
      ...highlightedPersonalityPicks,
      ...otherSkillPicks,
      ...otherPersonalityPicks,
    ].map((criterium) => {
      return {
        name: get(criterium, 'name'),
        topic: get(criterium, 'topic'),
        faded: !this.highlightedScorecardCriteriums.findBy(
          'id',
          get(criterium, 'id')
        ),
      };
    });
  }

  get shouldShowDeleteButton(): boolean {
    return !!this.args.onRemove;
  }

  get shouldShowAddButton(): boolean {
    return !!this.args.onAdd;
  }

  get shouldShowAttachToStageBar(): boolean {
    return !!this.args.stages && this.args.stages.length > 0;
  }

  get isHiddenInterviewKit(): boolean {
    return get(this.interviewKit, 'isHidden');
  }

  get editButtonIsDisabled(): boolean {
    if (this.shouldShowAddButton) {
      return true;
    }

    if (this.isHiddenInterviewKit) {
      return true;
    }

    const pickedInterviewKitIds = get(
      this.interviewKit,
      'pickedInterviewKitIds'
    );

    if (pickedInterviewKitIds.length > 1) {
      return true;
    }

    const isUsedByOtherJob =
      pickedInterviewKitIds.length === 1 &&
      pickedInterviewKitIds.some(
        (id) => id !== Number(this.args.pickedInterviewKit.id)
      );
    return isUsedByOtherJob;
  }

  get isSuggested(): boolean {
    const interviewKitScorecardCriteria = get(
      this.interviewKit,
      'scorecardCriteria'
    ).mapBy('id');
    return interviewKitScorecardCriteria.some((s: string) =>
      this.args.jobScorecardPicksIds?.includes(s)
    );
  }

  @action
  onStageSelected(stage: StageModel): void {
    if (this.args.onStageSelected) {
      return this.args.onStageSelected(this.args.pickedInterviewKit, stage);
    }
  }
}
