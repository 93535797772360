/* import __COLOCATED_TEMPLATE__ from './share-data-request.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import {
  CandidateModel,
  DataRequestModel,
  DataRequestSettingModel,
} from 'teamtailor/models';
import { task } from 'ember-concurrency';

interface ShareDataRequestArgs {
  candidate: CandidateModel;
  onShareData: () => void;
}

export default class ShareDataRequest extends Component<ShareDataRequestArgs> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked model: DataRequestModel | undefined;
  @tracked dataRequestSetting: DataRequestSettingModel | null = null;
  @tracked pendingDataRequest: DataRequestModel | undefined;

  constructor(owner: unknown, args: ShareDataRequestArgs) {
    super(owner, args);
    this.fetchDataRequest.perform();
  }

  get candidate() {
    return this.args.candidate;
  }

  fetchDataRequest = task(async () => {
    this.pendingDataRequest = await this.candidate.pendingDataRequest;
    this.dataRequestSetting = await this.current.company.dataRequestSetting;

    this.model =
      this.pendingDataRequest ||
      this.store.createRecord('data-request', {
        candidate: this.args.candidate,
      });

    this.model.tables = this.dataRequestSetting?.tables || [];
    this.model.messageSubject = this.dataRequestSetting?.messageSubject || '';
    this.model.messageBody = this.dataRequestSetting?.messageBody || '';
  });

  get hasPendingDataRequest() {
    return !!this.pendingDataRequest;
  }

  @action
  async share() {
    try {
      await this.model?.save();
      this.args.onShareData();
    } catch (error) {
      this.flashMessages.error(
        this.intl.t(
          'candidates.candidate.candidate_modal.consent_center.share_data_modal.error_message'
        )
      );
      return Promise.reject(error);
    }
  }
}
