/* import __COLOCATED_TEMPLATE__ from './custom-form.hbs'; */
import Component from '@glimmer/component';

import ChannelModel from 'teamtailor/models/channel';
import ChannelActivationModel from 'teamtailor/models/channel-activation';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import CurrentService from 'teamtailor/services/current';

export interface PromotionActivationCustomFormArgs {
  channel: ChannelModel;
  channelActivation: ChannelActivationModel;
  selectedActivationType: 'existing_agreement' | 'teamtailor';
  providerKeyData: Record<string, unknown>;
}

export default class PromotionActivationCustomFormComponent extends Component<PromotionActivationCustomFormArgs> {
  @service declare intl: IntlService;
  @service declare current: CurrentService;

  get isFreeChannel() {
    return (
      this.args.channel.priceForCountry === 0 ||
      this.args.channel.isFree ||
      this.args.channel.isAlwaysIncluded
    );
  }

  get isAgreedWithPartner() {
    return this.args.channel.priceForCountry === null;
  }

  get invoiceNotification() {
    if (this.args.channel.internalName === 'france_travail') {
      return this.intl.t('jobs.job.promote.teamtailor_yearly_fee_invoice');
    }

    if (this.isFreeChannel)
      return this.intl.t('jobs.job.promote.free_channel_notice');

    return this.args.selectedActivationType === 'existing_agreement'
      ? this.intl.t('jobs.job.promote.existing_agreement_notice', {
          channelName: this.args.channel.name,
        })
      : this.current.company.creditCardForPromote
        ? this.intl.t('jobs.job.promote.credit_card_will_be_used')
        : this.intl.t('jobs.job.promote.teamtailor_invoice');
  }

  get showActivationForm() {
    const shouldRenderForm =
      !this.args.channel.allowedPaymentTypes ||
      this.args.channel.allowedPaymentTypes === 'partner' ||
      this.args.selectedActivationType === 'existing_agreement';

    return this.args.channel.hasConfigurationForm && shouldRenderForm;
  }

  @action onValueChanged(key: string, value: string | number | boolean | null) {
    this.args.providerKeyData[key] = value;
  }
}
