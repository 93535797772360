/* import __COLOCATED_TEMPLATE__ from './queue-list-item.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { TaskInstance } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import { QueueItemClass } from './queue-item';

interface Args {
  queueItem: QueueItemClass;
  setEditingCandidate(queueItem: QueueItemClass): void;
  activeQueueItem: boolean;
  lastError: unknown;
  isPersisted: boolean;
  isProcessing: boolean;
  taskInstances: TaskInstance<void>[];
}

export default class QueueListItem extends Component<Args> {
  @service declare intl: IntlService;

  get queueItem(): QueueItemClass {
    return this.args.queueItem;
  }

  get displayName(): string {
    if (this.queueItem.parseInstances.isAny('isRunning', true)) {
      return this.intl.t(
        'candidates.segment.new_candidate_bulk.add_form.parsing_cv'
      );
    }

    if (this.queueItem.lastError) {
      return this.intl.t(
        'candidates.segment.new_candidate_bulk.add_form.review_data'
      );
    }

    if (this.queueItem.parseError) {
      return this.intl.t(
        'candidates.segment.new_candidate_bulk.add_form.parse_error'
      );
    }

    return (
      this.queueItem.nameOrEmail ??
      this.intl.t(
        'candidates.segment.new_candidate_bulk.add_form.new_candidate_placeholder'
      )
    );
  }
}
