/* import __COLOCATED_TEMPLATE__ from './data-privacy.hbs'; */
import BaseComponent from './base';
import { action, get, setProperties } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

export default class DataPrivacyComponent extends BaseComponent {
  @tracked messageTemplateToDisplay;
  @tracked consentMessageTemplateId;
  @tracked consentMessageSubject;
  @tracked consentMessageBody;

  @action
  async selectConsentMessageTemplate(value) {
    const responses = await get(this.company, 'dataPrivacyCannedResponses');
    const template = responses.findBy(value);

    setProperties(this, {
      messageTemplateToDisplay: value,
      consentMessageTemplateId: get(template, 'id'),
      consentMessageSubject: get(template, 'subject'),
      consentMessageBody: htmlSafe(get(template, 'body')),
    });
  }

  @action
  sendInitialConsentRequests() {
    let promise = this.postAction(
      'bulk_send_initial_consent_requests',
      this.bulkOptions
    );

    promise.then(() => {
      this.store
        .peekAll('candidate')
        .forEach((candidate) => candidate.reload());
      this.messageTemplateToDisplay = null;
    });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }

  @action
  sendExpiredConsentReminders() {
    let promise = this.postAction(
      'bulk_send_expired_consent_reminders',
      this.bulkOptions
    );

    promise.then(() => {
      this.store
        .peekAll('candidate')
        .forEach((candidate) => candidate.reload());
      this.messageTemplateToDisplay = null;
    });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
