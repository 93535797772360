/* import __COLOCATED_TEMPLATE__ from './progress-step.hbs'; */
import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
  classNames: ['c-progress-step'],
  classNameBindings: [
    'completed:c-progress-step--completed',
    'failed:c-progress-step--failed',
    'inProgress:c-progress-step--in-progress',
  ],

  completed: false,
  description: '',
  errorMessage: null,
  failed: false,
  inProgress: false,
  supportMessage: null,
  supportSubject: null,
  title: null,
  completedTitle: null,
  failedTitle: null,
  inProgressTitle: null,

  titleText: computed(
    'completed',
    'completedTitle',
    'failed',
    'failedTitle',
    'inProgress',
    'inProgressTitle',
    'title',
    function () {
      if (get(this, 'title')) {
        return get(this, 'title');
      } else {
        if (get(this, 'failed')) {
          return get(this, 'failedTitle');
        } else if (get(this, 'completed')) {
          return get(this, 'completedTitle');
        } else {
          return get(this, 'inProgressTitle');
        }
      }
    }
  ),

  icon: computed('failed', 'completed', 'inProgress', function () {
    if (get(this, 'failed')) {
      return 'ban';
    } else if (get(this, 'completed')) {
      return 'circle-check';
    } else {
      return 'spinner';
    }
  }),
});
