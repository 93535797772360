import Service, { inject as service } from '@ember/service';
import Current from './current';
import PusherService, {
  PusherChannel,
  ChannelMember,
  ChannelMembers,
} from './pusher';
import { tracked } from '@glimmer/tracking';

export default class OnlineCandidates extends Service {
  @service declare pusher: PusherService;
  @service declare current: Current;

  @tracked private _members: string[] = [];

  channel: PusherChannel | null = null;

  constructor(owner?: object) {
    super(owner);
    this.setupPusher();
  }

  get members() {
    return this._members;
  }

  async setupPusher() {
    this.channel = await this.pusher.subscribe(
      this.current.company.candidateChatsChannel
    );

    this.channel.bind('pusher:member_added', this._handleMemberAdded);
    this.channel.bind('pusher:member_removed', this._handleMemberRemoved);
    this.channel.bind('pusher:subscription_succeeded', this._handleConnect);
  }

  private _handleConnect = (members: ChannelMembers) => {
    const candidateMembers: string[] = [];
    members.each((member: ChannelMember) => {
      if (member.info?.candidate) {
        candidateMembers.push(member.id);
      }
    });

    if (candidateMembers.length) {
      this._members = candidateMembers;
    }
  };

  private _handleMemberRemoved = (member: ChannelMember) => {
    this._members = this._members.filter((id) => id !== member.id);
  };

  private _handleMemberAdded = (member: ChannelMember) => {
    if (!this._members.includes(member.id)) {
      this._members = [...this._members, member.id];
    }
  };
}

declare module '@ember/service' {
  interface Registry {
    'online-candidates': OnlineCandidates;
  }
}
