/* import __COLOCATED_TEMPLATE__ from './referrals-card.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';

export default class InsightsMoleculesReferralsCard extends Component {
  @service store;

  @alias('args.referrals.length') referralCount;
}
