/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TestimonialLayoutComponent extends Component {
  @service router;

  get section() {
    return this.args.section;
  }

  get sectionType() {
    return this.args.sectionType;
  }

  get isNewSection() {
    return this.args.isNewSection;
  }

  get backPath() {
    return this.args.backPath;
  }

  @action
  save() {
    return this.section.save().then(() => {
      if (this.isNewSection && this.sectionType === 'grid') {
        this.router.transitionTo(`content.editor.section.grid.new`);
      } else {
        this.router.transitionTo('content.editor.section');
      }
    });
  }
}
