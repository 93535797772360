import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { apecNeedsDurationForJobTypeCode } from 'teamtailor/constants/promote';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export function buildFromInternalName(internalName, store) {
  return store
    .findAll('channel')
    .then((channels) => channels.findBy('internalName', internalName))
    .then((channel) => store.createRecord('promotion', { channel }));
}

export default class PromotionModel extends Model {
  finnTagsLimit = 5;

  @service server;

  @belongsTo('job') job;
  @belongsTo('user') user;
  @belongsTo('page') page;
  @belongsTo('invoiceInfo') invoiceInfo;
  @belongsTo('channel') channel;
  @belongsTo('location') location;
  @belongsTo('monster-job-category', { async: false }) monsterJobCategory;
  @belongsTo('monster-job-occupation', { async: false }) monsterJobOccupation;
  @belongsTo('monster-job-industry', { async: false }) monsterJobIndustry;
  @belongsTo('blocket-category', { async: false }) blocketCategory;
  @belongsTo('blocket-subcategory', { async: false }) blocketSubcategory;
  @belongsTo('blocket-region', { async: false }) blocketRegion;
  @belongsTo('blocket-municipality', { async: false }) blocketMunicipality;
  @belongsTo('promotion-categories/cv-library-industry') cvLibraryIndustry;
  @belongsTo('promotion-categories/cv-library-job-type') cvLibraryJobType;
  @belongsTo('metro-category', { async: false }) metroCategory;
  @belongsTo('metro-region', { async: false }) metroRegion;
  @belongsTo('occupation-name', { async: false }) occupationName;
  @belongsTo('municipality-code', { async: false }) municipalityCode;
  @belongsTo('promotion-categories/ejobs-department') ejobsDepartment;
  @belongsTo('promotion-categories/cv-online-town') cvOnlineTown;
  @belongsTo('promotion-categories/ejobs-city') ejobsCity;
  @belongsTo('promotion-categories/jobnet-municipality') jobnetMunicipality;
  @belongsTo('promotion-categories/caterer-global-region') catererGlobalRegion;
  @belongsTo('reed/parent-sector') reedParentSector;
  @belongsTo('reed/sector') reedSector;
  @belongsTo('promotion-categories/jobnet-main-profession') jobnetMainCategory;
  @belongsTo('promotion-categories/totaljobs-region') totaljobsRegion;
  @belongsTo('promotion-categories/apec-industry') apecIndustry;
  @belongsTo('promotion-categories/apec-department') apecDepartment;
  @belongsTo('promotion-categories/apec-region') apecRegion;
  @belongsTo('promotion-categories/jobborse-country') jobborseCountry;
  @belongsTo('promotion-categories/jobborse-region') jobborseRegion;
  @belongsTo('promotion-categories/jobborse-occupation') jobborseOccupation;
  @belongsTo('uptrail-region', { async: false }) uptrailRegion;
  @belongsTo('promotion-categories/jobnet-secondary-category')
  jobnetSecondaryCategory;

  @belongsTo('promotion-categories/jobteaser-education-level')
  jobteaserEducationLevel;

  @belongsTo('promotion-categories/jobteaser-work-experience')
  jobteaserWorkExperience;

  @belongsTo('promotion-categories/jobteaser-contract-type')
  jobteaserContractType;

  @belongsTo('promotion-categories/jobteaser-contract-duration')
  jobteaserContractDuration;

  @belongsTo('promotion-categories/jobteaser-position-category')
  jobteaserPositionCategory;

  @belongsTo('promotion-categories/jobteaser-payment-type')
  jobteaserPaymentType;

  @belongsTo('promotion-categories/totaljobs-minmax-salary')
  totaljobsMinmaxSalaries;

  @belongsTo('promotion-categories/cv-library-salary-period')
  cvLibrarySalaryPeriod;

  @belongsTo('promotion-categories/hotel-career-employment')
  hotelCareerEmployment;

  @hasMany('picked-location')
  pickedLocations;

  @hasMany('promotion-request') promotionRequests;
  @hasMany('finn-occupation', { async: false }) finnOccupationGenerals;
  @hasMany('uptrail-category', { async: false }) uptrailCategories;
  @hasMany('oikotie-line-of-business') oikotieLineOfBusiness;
  @hasMany('finn-industry', { async: false }) finnIndustries;
  @hasMany('promotion-categories/cv-online-work-time')
  cvOnlineWorkTimes;

  @hasMany('promotion-categories/cv-online-search-category')
  cvOnlineSearchCategories;

  @hasMany('finn-occupation-specialization', { async: false })
  finnOccupationSpecializations;

  @attr('string') channelName;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('date') lastPublishedAt;
  @attr('number') applicationsCount;
  @attr('string') url;
  @attr('string') status;
  @attr('string') currency;
  @attr('number') price;
  @attr('string') invoiceAddress;
  @attr('date') publishedAt;
  @attr('date') endDate;
  @attr('number') duration;
  @attr('boolean') isExpired;
  @attr('string') plan;
  @attr('string') hainesattractChannelName;
  @attr('string') hainesattractChannelIdentity;
  @attr('raw') serviceErrors;
  @attr('string') externalUrl;
  @attr('boolean') paused;
  @attr('raw') errorMessages;
  @attr('boolean') withExistingAgreement;
  @attr('number') monsterJobLevel;
  @attr('number') monsterJobType;
  @attr('number') monsterJobStatus;
  @attr('number') blocketEmployment;
  @attr('number') cvLibraryMinSalary;
  @attr('number') cvLibraryMaxSalary;
  @attr('string') cvLibrarySalaryInfo;
  @attr('boolean', { defaultValue: true }) cvLibrarySalaryPresent;
  @attr('string') cvLibraryDisplayLocation;
  @attr('string') cvLibraryPreciseLocation;
  @attr('string') cvOnlineMinSalary;
  @attr('string') cvOnlineMaxSalary;
  @attr('string') cvOnlineSalaryRatePer;
  @attr('boolean', { defaultValue: false }) cvOnlineRemoteWork;
  @attr('boolean', { defaultValue: false }) employeeShareLink;
  @attr('string') metroJobTitle;
  @attr('number') metroEmploymentType;
  @attr('boolean') amsFullTime;
  @attr('number') amsDuration;
  @attr('string') amsDurationSummary;
  @attr('boolean') amsExperienceRequired;
  @attr('string') amsCountryCode;
  @attr('string') ejobsCareerLevelId;
  @attr('string') ejobsAdTypeId;
  @attr('string') facebookTargeting;
  @attr('string') finnJobType;
  @attr('string') finnManagerRole;
  @attr('string') finnJobTitle;
  @attr('string') finnDuration;
  @attr('number') finnNoOfPositions;
  @attr('string') finnSector;
  @attr('raw', {
    defaultValue() {
      return [];
    },
  })
  finnTags;

  @attr('string') finnUserReference;
  @attr('string') finnShortPitch;
  @attr('boolean') finnOverwriteImages;
  @attr('boolean') finnUseTwitter;
  @attr('boolean') finnUseFacebook;
  @attr('string') oikotieJobCategory;
  @attr('string') oikotieContractType;
  @attr('string') oikotieTemplateName;
  @attr('string') oikotieCustomInvoiceAddress;
  @attr('string') oikotieExperienceLevel;
  @attr('string') helloworkContractType;
  @attr('boolean') helloworkDisplaySalary;
  @attr('string') helloworkExperience;
  @attr('string') helloworkEducationLevel;
  @attr('string') stepstoneDeDescription;
  @attr('string') stepstoneDeOffer;
  @attr('string') stepstoneDeTasks;
  @attr('string') stepstoneDeProfile;
  @attr('boolean') stepstoneDeInternship;
  @attr('string') stepstoneDeIdent;
  @attr('string') jobborseSocialInsurance;
  @attr('number') jobborseMiniJob;
  @attr('string') apecCompanyDescription;
  @attr('string') apecDuration;
  @attr('string') apecJobStatus;
  @attr('string') apecJobType;
  @attr('string') apecJobDescription;
  @attr('string') apecMaxSalary;
  @attr('string') apecMinSalary;
  @attr('string') apecProfileDescription;
  @attr('string') apecRemoteWorkType;
  @attr('string') apecTravelArea;
  @attr('string') apecExperienceLevel;
  @attr('boolean') successfullyPublished;
  @attr('number') lastResponseCode;
  @attr('string') shareLinkName;
  @attr('string') indeedPhone;
  @attr('boolean') indeedActive;
  @attr('boolean') indeedPaused;
  @attr('string') indeedTimeToEnd;
  @attr('string') companyName;
  @attr('string') corporateNumber;
  @attr('string') vatNumber;
  @attr('string') invoicingAddress;
  @attr('string') invoicingEmail;
  @attr('string') costcenter;
  @attr('string') otherInformation;
  @attr('string') jobnetOccupationId;
  @attr('number', {
    defaultValue() {
      return 1;
    },
  })
  jobnetVacancyAvailable;

  @attr('number') jobnetMinWorkHours;
  @attr('number') jobnetMaxWorkHours;
  @attr('string', {
    defaultValue() {
      return 'Normal';
    },
  })
  jobnetJobType;

  @attr('date') jobnetEmploymentDate;
  @attr('boolean') jobnetEuresPromotion;
  @attr('boolean', {
    defaultValue() {
      return false;
    },
  })
  jobnetDayTimeWork;

  @attr('boolean', {
    defaultValue() {
      return false;
    },
  })
  jobnetEveningTimeWork;

  @attr('boolean', {
    defaultValue() {
      return false;
    },
  })
  jobnetNightTimeWork;

  @attr('boolean', {
    defaultValue() {
      return false;
    },
  })
  jobnetIsWorkInDenmark;

  @attr('boolean') jobnetIsDisabilityFriendly;
  @attr('boolean') jobnetNoPermanentWorkplace;
  @attr('string') jobnetWorkingTime;
  @attr('string') jobnetHiring;
  @attr('string', {
    defaultValue() {
      return 'Nothing';
    },
  })
  jobnetDrivingLicense;

  @attr('number') totaljobsMinSalary;
  @attr('number') totaljobsMaxSalary;
  @attr('string') totaljobsJobType;
  @attr('string') totaljobsSalaryDescription;
  @attr('string') qrCodePosterUrl;
  @attr('raw') qrCodeSettings;
  @attr('string') jobnetPNumber;
  @attr('string') jobnetCvrNumber;
  @attr('string') reedJobType;
  @attr('string') reedWorkingHour;
  @attr('boolean', { defaultValue: false }) reedIsPublic;
  @attr('boolean', { defaultValue: false }) reedIsGraduate;
  @attr('boolean', { defaultValue: false }) reedShowSalary;
  @attr('string', { defaultValue: '0' }) reedHiddenSalaryDescription;
  @attr('number') reedMinSalary;
  @attr('number') reedMaxSalary;
  @attr('raw', {
    defaultValue() {
      return [];
    },
  })
  reedSkills;

  @attr('boolean') deleteRequestSent;

  get locations() {
    return get(this, 'pickedLocations').mapBy('location');
  }

  get isUpdated() {
    return get(this, 'updatedAt') - get(this, 'createdAt') > 20000;
  }

  get daysLeft() {
    if (this.isExpired) return 0;

    const timeDifference =
      new Date(this.endDate).getTime() - new Date().getTime();

    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  }

  get humanName() {
    const channelName = get(this, 'channelName');
    const employeeShareLink = get(this, 'employeeShareLink');
    const internalName = get(this, 'channel.internalName');
    const shareLinkChannelName = get(this, 'channel.name');
    const userName = get(this, 'user.name');

    if (internalName === 'hainesattract') {
      return `${channelName}: ${this.hainesattractChannelName}`;
    } else if (employeeShareLink) {
      return `${shareLinkChannelName}: ${userName}`;
    } else {
      return channelName;
    }
  }

  get occupationField() {
    return (
      get(this, '_occupationField') ||
      get(this, 'occupationGroup.occupationField')
    );
  }

  set occupationField(value) {
    set(this, '_occupationField', value);
  }

  get occupationGroup() {
    return (
      get(this, '_occupationGroup') ||
      get(this, 'occupationName.occupationGroup')
    );
  }

  set occupationGroup(value) {
    set(this, '_occupationGroup', value);
  }

  get metroParentCategory() {
    return (
      get(this, '_metroParentCategory') ||
      get(this, 'metroCategory.metroCategory')
    );
  }

  set metroParentCategory(value) {
    set(this, '_metroParentCategory', value);
  }

  get metroFirstRegion() {
    return (
      get(this, '_metroFirstRegion') || get(this, 'metroRegion.metroRegion')
    );
  }

  set metroFirstRegion(value) {
    set(this, '_metroFirstRegion', value);
  }

  get isInvalidForMonster() {
    const values = [
      get(this, 'monsterJobLevel'),
      get(this, 'monsterJobType'),
      get(this, 'monsterJobStatus'),
      get(this, 'monsterJobCategory.id'),
      get(this, 'monsterJobOccupation.id'),
      get(this, 'monsterJobIndustry.id'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForBlocket() {
    const values = [
      get(this, 'blocketCategory.id'),
      get(this, 'blocketEmployment'),
      get(this, 'blocketRegion.id'),
      get(this, 'blocketMunicipality.id'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForJobnet() {
    const values = [
      get(this, 'jobnetSecondaryCategory.id'),
      get(this, 'jobnetWorkingTime'),
      get(this, 'jobnetHiring'),
      get(this, 'jobnetVacancyAvailable'),
      get(this, 'jobnetJobType'),
      get(this, 'jobnetEmploymentDate'),
      get(this, 'jobnetIsWorkInDenmark'),
      get(this, 'jobnetCvrNumber'),
      get(this, 'jobnetPNumber'),
    ];
    if (get(this, 'jobnetWorkingTime') === 'PartTime') {
      values.push(get(this, 'jobnetMinWorkHours'));
      values.push(get(this, 'jobnetMaxWorkHours'));
    }

    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isPaused() {
    if (get(this, 'channel.templateName') === 'indeed') {
      return get(this, 'indeedPaused');
    }

    return this.paused;
  }

  get isInvalidForReed() {
    const values = [get(this, 'reedJobType'), get(this, 'reedWorkingHour')];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForCvLibrary() {
    const values = [
      get(this, 'cvLibraryIndustry.id'),
      get(this, 'cvLibraryJobType.id'),
      get(this, 'cvLibrarySalaryPeriod.id'),
    ];

    if (get(this, 'cvLibrarySalaryPresent')) {
      values.push(get(this, 'cvLibraryMinSalary'));
      values.push(get(this, 'cvLibraryMaxSalary'));
    }

    return values.some((value) => {
      return isEmpty(value);
    });
  }

  get isInvalidForApec() {
    const values = [
      get(this, 'apecTravelArea'),
      get(this, 'apecDepartment.id'),
      get(this, 'apecRegion.id'),
      get(this, 'apecExperienceLevel'),
      get(this, 'apecMinSalary'),
      get(this, 'apecMaxSalary'),
      get(this, 'apecIndustry.id'),
      get(this, 'apecJobStatus'),
      get(this, 'apecJobType'),
    ];

    if (get(this, 'apecNeedsDuration')) {
      values.push(get(this, 'apecDuration'));
    }

    return values.some((value) => {
      return isEmpty(value);
    });
  }

  get isInvalidForMetro() {
    const values = [
      get(this, 'metroJobTitle'),
      get(this, 'metroEmploymentType'),
      get(this, 'metroCategory.id'),
      get(this, 'metroRegion.id'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForAms() {
    const values = [
      get(this, 'occupationName.id'),
      get(this, 'municipalityCode.id'),
      get(this, 'amsFullTime'),
      get(this, 'amsExperienceRequired'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForFinn() {
    const values = [
      get(this, 'finnOccupationSpecializations'),
      get(this, 'finnIndustries'),
      get(this, 'finnSector'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForUptrail() {
    const categories = get(this, 'uptrailCategories.length');
    if (categories > 5 || categories < 1) {
      return true;
    }

    if (isEmpty(get(this, 'uptrailRegion'))) {
      return true;
    }

    return false;
  }

  get isInvalidForEjobs() {
    const values = [
      get(this, 'ejobsDepartment.id'),
      get(this, 'ejobsCity.id'),
      get(this, 'ejobsCareerLevelId'),
      get(this, 'ejobsAdTypeId'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForTotaljobs() {
    return (
      isEmpty(get(this, 'totaljobsRegion.id')) &&
      isEmpty(get(this, 'catererGlobalRegion.id'))
    );
  }

  get isInvalidForHotelCareer() {
    const values = [get(this, 'hotelCareerEmployment.id')];

    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForJobborse() {
    const values = [
      get(this, 'jobborseRegion.id'),
      get(this, 'jobborseOccupation.id'),
      get(this, 'jobborseSocialInsurance'),
      get(this, 'jobborseMiniJob'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isIndForJobteaser() {
    const values = [
      get(this, 'jobteaserEducationLevel.id'),
      get(this, 'jobteaserWorkExperience.id'),
      get(this, 'jobteaserContractType.id'),
      get(this, 'jobteaserPositionCategory.id'),
      get(this, 'jobteaserPaymentType.id'),
    ];
    return values.some(function (value) {
      return isEmpty(value);
    });
  }

  get isInvalidForShareLink() {
    return isEmpty(this.shareLinkName);
  }

  get isValidForOikotie() {
    return (
      this.oikotieLineOfBusiness.length &&
      this.oikotieJobCategory &&
      this.oikotieContractType
    );
  }

  get isInvalidForOikotie() {
    return !this.isValidForOikotie;
  }

  get apecNeedsDuration() {
    return apecNeedsDurationForJobTypeCode.includes(get(this, 'apecJobType'));
  }

  get isJobnetWithoutMunicipality() {
    const withoutMunicipality = get(this, 'errors').find((error) =>
      get(error, 'message').includes('municipality')
    );
    const hasSelectedMunicipality = !isEmpty(
      get(this, 'jobnetMunicipality.content')
    );

    return withoutMunicipality || hasSelectedMunicipality;
  }

  get isShareLink() {
    return get(this, 'channel.internalName') === 'share_link';
  }

  get isQrCode() {
    return get(this, 'channel.internalName') === 'qr_code';
  }

  get isShareLinkOrQrCode() {
    return this.isShareLink || this.isQrCode;
  }

  get isNotShareLinkOrQrCode() {
    return !this.isShareLinkOrQrCode;
  }

  async createQrCodePoster(data) {
    return this.server.memberAction(this, {
      action: 'create_qr_poster',
      method: 'POST',
      options: { data },
    });
  }

  async unpublish() {
    return this.server.memberAction(this, {
      action: 'unpublish',
      method: 'POST',
      options: { data: { id: this.id } },
    });
  }

  async togglePause(paused) {
    const response = await this.server.memberAction(this, {
      action: 'toggle_pause',
      method: 'POST',
      options: {
        data: {
          paused,
        },
      },
    });
    this.store.pushPayload(response);
  }

  async refresh() {
    const response = await this.server.collectionAction({
      modelName: PromotionModel.modelName,
      action: 'refresh',
      method: 'post',
      options: { data: { id: this.id } },
    });
    this.store.pushPayload(response);
  }
}
