/* import __COLOCATED_TEMPLATE__ from './notification-settings-table.hbs'; */
import Component from '@glimmer/component';
import { getClosest } from 'teamtailor/utils/dom-helpers';
import { inject as service } from '@ember/service';
import { get, set, action } from '@ember/object';

export default class NotificationSettingsTableComponent extends Component {
  @service current;

  selectAllEnabled = false;
  emailAll = false;
  browserAll = false;
  mobileAll = false;
  keyToFeatureMappings = {
    candidate_answered_job_offer: 'job_offers',
    job_offer_reminders: 'job_offer_approvals',
    job_offer_approvals: 'job_offer_approvals',
    requisition_approvals: 'requisitions',
    requisition_reminders: 'requisitions',
    requisition_approved: 'requisitions',
  };

  notificationsEnabledForNoAccessUser = Object.freeze(['1']);

  constructor() {
    super(...arguments);
    const { collection } = this.args;
    set(this, 'emailAll', true);
    set(this, 'browserAll', true);
    set(this, 'mobileAll', true);
    collection.forEach((n) => {
      if (
        get(n, 'email') === false &&
        get(n, 'notificationConfig.availableForEmail')
      ) {
        set(this, 'emailAll', false);
      }

      if (
        get(n, 'browser') === false &&
        get(n, 'notificationConfig.availableForBrowser')
      ) {
        set(this, 'browserAll', false);
      }

      if (
        get(n, 'mobile') === false &&
        get(n, 'notificationConfig.availableForMobile')
      ) {
        set(this, 'mobileAll', false);
      }
    });
  }

  @action
  isEnabled(n) {
    if (this.keyToFeatureMappings[get(n, 'key')]) {
      return this.current.company.hasFeature(
        this.keyToFeatureMappings[get(n, 'key')]
      );
    }

    return true;
  }

  @action
  handleFocusIn(e) {
    getClosest(e.currentTarget, 'tr').classList.add('hover');
  }

  @action
  handleFocusOut(e) {
    getClosest(e.currentTarget, 'tr').classList.remove('hover');
  }

  @action
  handleSelectAll(category) {
    const { collection } = this.args;

    collection.forEach((n) => {
      if (
        category === 'email' &&
        get(n, 'notificationConfig.availableForEmail')
      ) {
        set(n, 'email', get(this, 'emailAll'));
      }

      if (
        category === 'browser' &&
        get(n, 'notificationConfig.availableForBrowser')
      ) {
        set(n, 'browser', get(this, 'browserAll'));
      }

      if (
        category === 'mobile' &&
        get(n, 'notificationConfig.availableForMobile')
      ) {
        set(n, 'mobile', get(this, 'mobileAll'));
      }
    });
  }

  @action
  isDisabled(n, availableFor) {
    if (get(n, 'user.noAccess')) {
      if (
        get(this, 'notificationsEnabledForNoAccessUser').includes(
          get(n, 'notificationConfig.id')
        )
      ) {
        return !get(n, `notificationConfig.${availableFor}`);
      }

      return true;
    }

    return !get(n, `notificationConfig.${availableFor}`);
  }
}
