/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { ActivityModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import htmlToStr from 'teamtailor/utils/html-to-text';

interface Args {
  activity: ActivityModel;
}

function br2nl(str: string) {
  return str.replace(/<\s*\/?br\s*[/]?>/gi, '\n');
}

function parseHTMLString(htmlString: string) {
  const parser = new DOMParser();
  return parser.parseFromString(htmlString, 'text/html');
}

export default class ActivityMessageComponent extends Component<Args> {
  get messageBody() {
    const message = get(this.args.activity, 'message');
    return message.body || null;
  }

  get strippedBody() {
    return this.messageBody ? htmlToStr(br2nl(this.messageBody)) : '';
  }

  get doc() {
    return this.messageBody ? parseHTMLString(this.messageBody) : null;
  }

  get includesSmartLink() {
    return this.doc ? !!this.doc.querySelector('.smart-link') : false;
  }

  get smartLinks() {
    return this.doc ? this.doc.querySelectorAll('.smart-link') : [];
  }

  get firstSmartLinkHref() {
    return this.smartLinks.length > 0
      ? this.smartLinks[0]?.getAttribute('href') || ''
      : '';
  }

  get firstSmartLinkText() {
    return this.smartLinks.length > 0
      ? this.smartLinks[0]?.textContent || ''
      : '';
  }

  get smartLinkHtml() {
    return `<a target="_blank" href="${this.firstSmartLinkHref}">${this.firstSmartLinkText}</a>`;
  }

  get isScheduled() {
    return (
      this.args.activity.message.isScheduled &&
      this.args.activity.message.sendAt &&
      this.args.activity.message.sendAt > new Date()
    );
  }
}
