/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class CandidateModalCustomFields extends Component {
  @service current;
  @service permissions;

  candidateTagsBefore = null;

  get candidate() {
    return get(this.args, 'candidate');
  }

  get headquarterCountryCode() {
    return get(
      get(this.current.company, 'headquarters'),
      'countryCode'
    )?.toLowerCase();
  }

  get headquarterCountryCodeOrDefault() {
    return this.headquarterCountryCode ?? 'se';
  }

  get userCanCreateTags() {
    return this.current.user.canCreateTags;
  }

  get sortedLocations() {
    return [
      ...new Set(
        this.candidate.pickedLocations
          .mapBy('location.nameOrCity')
          .sort((a, b) => {
            return a.localeCompare(b, this.locale);
          })
      ),
    ];
  }

  get locale() {
    return this.current.locale;
  }

  saveCandidate() {
    if (get(this.candidate, 'hasDirtyAttributes')) {
      this.candidate.save();
    }
  }

  createComparableTagString() {
    return this.candidate.hasMany('tags').ids().sort().join('.');
  }

  @action
  changeEmail(email) {
    this.candidate.email = email;
    this.saveCandidate();
  }

  @action
  changePhone(phone) {
    this.candidate.phone = phone;
    this.saveCandidate();
  }

  @action
  changeLinkedin(url) {
    this.candidate.linkedinUrl = url;
    this.saveCandidate();
  }

  @action
  onSwitchToMessage(value) {
    if (value === 'sendEmailMode') {
      value = this.candidate.emailFailedAt ? 'inputEmailMode' : value;
    }

    this.args.switchToMessage(value);
  }

  @action
  cacheExistingTags() {
    this.candidateTagsBefore = this.createComparableTagString();
  }

  @action
  saveTags() {
    const candidateTagsAfter = this.createComparableTagString();
    if (candidateTagsAfter !== this.candidateTagsBefore) {
      this.candidate.saveTags();
    }
  }

  @action
  handleChangeTags(tag) {
    toggleInList(this.candidate.tags, tag);
  }
}
