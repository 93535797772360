/* import __COLOCATED_TEMPLATE__ from './stage-trigger.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class StageTrigger extends Component {
  @service flipper;

  @tracked el;

  @action
  handleClick() {
    if (!this.args.isDisabled) {
      this.args.onClick?.(get(this, 'args.trigger_'), this.el);
    }
  }

  get label() {
    return get(this, 'args.trigger_.label');
  }

  get description() {
    return get(this, 'args.trigger_.description');
  }
}
