export const iconLight = [
  'globe',
  'square',
  'square-check',
  'lock',
  'thumbtack',
  'circle-user',
] as const;

export const iconDuoTone = [
  'bullseye-arrow',
  'link',
  'qrcode',
  'star-half-stroke',
  'file',
  'file-pdf',
  'file-lines',
  'file-image',
  'file-spreadsheet',
  'file-video',
] as const;

export const iconBrands = [
  'facebook-square',
  'github',
  'instagram',
  'linkedin-in',
  'linkedin',
  'pinterest',
  'square-instagram',
  'square-x-twitter',
  'tiktok',
  'unsplash',
  'vimeo',
  'x-twitter',
  'youtube',
  'whatsapp',
] as const;

export const iconSolid = [
  'address-book',
  'align-justify',
  'arrow-right',
  'ban',
  'bolt-lightning',
  'bolt',
  'block-question',
  'calendar-check',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'chart-pie',
  'chart-mixed',
  'check',
  'circle-arrow-up',
  'circle-check',
  'circle-dot',
  'circle-ellipsis',
  'circle-exclamation',
  'circle-info',
  'circle-question',
  'circle-small',
  'circle-stop',
  'circle-xmark',
  'circle',
  'clipboard-check',
  'clipboard-list-check',
  'clock',
  'comments',
  'eye',
  'gear',
  'gif',
  'house-person-return',
  'house-user',
  'infinity',
  'location-dot',
  'mask',
  'messages',
  'microphone',
  'money-bill',
  'note-sticky',
  'paper-plane',
  'pause',
  'play',
  'party-horn',
  'quote-right',
  'robot',
  'shield-exclamation',
  'sort-down',
  'square-check',
  'star',
  'stopwatch',
  'tag',
  'thumbs-up',
  'thumbtack',
  'trash-can',
  'triangle-exclamation',
  'turn-down-left',
  'user-secret',
  'user-slash',
  'user',
  'video',
  'volume-slash',
  'volume',
  'wand-magic-sparkles',
  'wifi',
  'xmark',
] as const;

export const iconRegular = [
  'address-book',
  'address-card',
  'alarm-clock',
  'align-justify',
  'anchor',
  'angle-down',
  'angle-up',
  'archive',
  'arrow-down-a-z',
  'arrow-down-from-dotted-line',
  'arrow-down-from-line',
  'arrow-down-left-and-arrow-up-right-to-center',
  'arrow-down-to-line',
  'arrow-down-z-a',
  'arrow-down',
  'arrow-left-from-line',
  'arrow-left-to-line',
  'arrow-left',
  'arrow-pointer',
  'arrow-right-from-line',
  'arrow-right-to-line',
  'arrow-right',
  'arrow-rotate-left',
  'arrow-turn-down-left',
  'arrow-up',
  'arrow-up-from-bracket',
  'arrow-up-from-line',
  'arrow-up-right-and-arrow-down-left-from-center',
  'arrow-up-right-from-square',
  'arrows-from-line',
  'arrows-retweet',
  'arrows-to-line',
  'at',
  'badge-check',
  'ballot-check',
  'ban',
  'bars-filter',
  'bars',
  'bell-concierge',
  'bell',
  'binoculars',
  'bolt-lightning',
  'bolt',
  'book-sparkles',
  'book-open',
  'block-question',
  'border-bottom',
  'border-top',
  'box-archive',
  'briefcase-blank',
  'brush',
  'building-user',
  'building',
  'bullhorn',
  'bullseye-arrow',
  'burger-cheese',
  'calendar-check',
  'calendar-days',
  'calendar',
  'camera-rotate',
  'camera',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'champagne-glasses',
  'chart-area',
  'chart-line',
  'chart-mixed',
  'chart-network',
  'chart-pie-simple',
  'chart-pie',
  'chart-simple',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-up',
  'circle-2',
  'circle-3',
  'circle-6',
  'circle-caret-down',
  'circle-check',
  'circle-chevron-down',
  'circle-chevron-right',
  'circle-dashed',
  'circle-dollar',
  'circle-dot',
  'circle-ellipsis',
  'circle-exclamation',
  'circle-half-stroke',
  'circle-heart',
  'circle-info',
  'circle-nodes',
  'circle-notch',
  'circle-pause',
  'circle-play',
  'circle-question',
  'circle-stop',
  'circle-user',
  'circle-xmark',
  'circle',
  'clipboard-check',
  'clipboard-list-check',
  'clipboard-list',
  'clipboard',
  'clock-desk',
  'clock',
  'clone',
  'cloud-arrow-down',
  'cloud-arrow-up',
  'code-merge',
  'code',
  'columns-3',
  'comment-lines',
  'comment-question',
  'comment-smile',
  'comment',
  'comment-check',
  'comments-question-check',
  'comments-question',
  'comments',
  'compress-wide',
  'command',
  'copy',
  'credit-card',
  'crosshairs',
  'crown',
  'database',
  'desktop',
  'diamond-turn-right',
  'display-slash',
  'display',
  'distribute-spacing-vertical',
  'door-open',
  'down-left-and-up-right-to-center',
  'download',
  'earth-americas',
  'earth-europe',
  'ellipsis-vertical',
  'ellipsis',
  'envelope',
  'envelope-open',
  'eye-slash',
  'eye',
  'face-disappointed',
  'face-frown',
  'face-meh',
  'face-smile-plus',
  'face-smile-wink',
  'face-smile',
  'feather-pointed',
  'file-export',
  'file-image',
  'file-lines',
  'file-magnifying-glass',
  'file-pdf',
  'file-user',
  'file-signature',
  'file',
  'file-arrow-up',
  'film-simple',
  'fire',
  'flag-checkered',
  'flag',
  'flask',
  'font-case',
  'font',
  'forward',
  'futbol',
  'gear',
  'gear-code',
  'gif',
  'gift',
  'globe',
  'grid',
  'grid-2',
  'grid-2-plus',
  'grip-lines-vertical',
  'grip-lines',
  'grip-vertical',
  'hand',
  'hand-wave',
  'handshake',
  'hashtag',
  'headset',
  'heart',
  'horizontal-rule',
  'house-heart',
  'house-person-return',
  'house-user',
  'house',
  'icons',
  'image-user',
  'image',
  'images',
  'inbox',
  'inbox-in',
  'inbox-out',
  'info',
  'key',
  'keyboard',
  'language',
  'laptop-mobile',
  'laptop',
  'layer-group',
  'leaf',
  'lightbulb',
  'link-simple',
  'link',
  'list-check',
  'list-ol',
  'list-ul',
  'list',
  'location-dot',
  'lock-open',
  'lock',
  'mailbox-flag-up',
  'magnifying-glass',
  'magnet',
  'map-marker',
  'mask',
  'message-pen',
  'message-xmark',
  'message',
  'message-question',
  'messages',
  'messages-question',
  'merge',
  'microphone-slash',
  'microphone',
  'minus',
  'mobile-button',
  'mobile',
  'money-bill',
  'moon',
  'music',
  'note-sticky',
  'note',
  'paint-brush',
  'paint-roller',
  'paper-plane',
  'paperclip',
  'paragraph',
  'party-horn',
  'pause',
  'pen',
  'pencil',
  'pen-circle',
  'people-arrows',
  'person-walking-arrow-right',
  'phone-flip',
  'phone-hangup',
  'play',
  'plug',
  'plus',
  'print',
  'puzzle-piece',
  'qrcode',
  'receipt',
  'rectangle-vertical-history',
  'rectangle-vertical',
  'rectangle-wide',
  'reply',
  'right-left',
  'robot',
  'rocket-launch',
  'rotate',
  'screwdriver-wrench',
  'share-nodes',
  'shield-check',
  'shield',
  'shirt',
  'shoe-prints',
  'sidebar',
  'signal-stream',
  'sitemap',
  'slash',
  'sliders-simple',
  'sliders',
  'sparkle',
  'sparkles',
  'spell-check',
  'spinner',
  'square-arrow-right',
  'square-check',
  'square-envelope',
  'square-poll-vertical',
  'square-t',
  'square-list',
  'square',
  'suitcase',
  'star',
  'stars',
  'stopwatch',
  'store',
  'sun-bright',
  'table',
  'tablet-button',
  'tag',
  'taxi',
  'text',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'timeline-arrow',
  'toolbox',
  'trash-can',
  'tree-palm',
  'triangle-exclamation',
  'trophy-alt',
  'trophy',
  'turn-down-left',
  'up-right-and-down-left-from-center',
  'up-right-from-square',
  'user-clock',
  'user-group',
  'user-pen',
  'user-plus',
  'user-secret',
  'user-slash',
  'user',
  'users',
  'rotate-left',
  'right-from-bracket',
  'video-slash',
  'video',
  'wand-magic-sparkles',
  'xmark',
] as const;
