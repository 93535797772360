/* import __COLOCATED_TEMPLATE__ from './viewable-flow-item.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';

export default class ViewableFlowItem extends Component {
  get hasResponse() {
    const verdicts = get(this, 'args.step.requisitionStepVerdicts');
    return (
      verdicts &&
      verdicts
        .slice()
        .some((verdict) => get(verdict, 'id') && !get(verdict, 'isPending'))
    );
  }

  get stepComments() {
    let comments = [];

    const verdicts = get(this, 'args.step.requisitionStepVerdicts');

    if (!verdicts) return comments;

    verdicts.forEach((verdict) => {
      const stepComments = get(verdict, 'requisitionStepVerdictComments');

      stepComments.forEach((stepComment) => {
        comments.push(stepComment);
      });
    });

    // Sort by date desc:
    return comments.sort((a, b) => {
      return get(b, 'createdAt') - get(a, 'createdAt');
    });
  }
}
