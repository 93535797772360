import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class QuestionSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    toHuman: { serialize: false },
    addedByCopilot: { serialize: false },
    createdByCopilot: { serialize: false },
    questionData: { key: 'data' },
    questionDetail: { serialize: false },
    tags: { serialize: 'records', key: 'tag_list' },
  };

  serialize() {
    const json = super.serialize(...arguments);
    if (json.tag_list.length) {
      json.tag_list = json.tag_list.map((tag) => tag.name);
    }

    return json;
  }
}
