/* import __COLOCATED_TEMPLATE__ from './question-answer.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import moment from 'moment-timezone';

export default class QuestionAnswerComponent extends Component {
  get answer() {
    return this.args.answer;
  }

  get choices() {
    return this.answer.choices
      .map((choice) => {
        let alternative = get(
          this.question,
          'alternativesArrayForChoices'
        ).find((alternative) => get(alternative, 'id') === choice);
        return alternative && alternative.title;
      })
      .filter(Boolean)
      .join(', ');
  }

  get data() {
    return this.answer.data;
  }

  get isText() {
    return (
      (get(this.question, 'isText') || this.answer.text) &&
      !get(this.question, 'isSong')
    );
  }

  get isNumber() {
    return get(this.question, 'isNumber') || this.answer.number;
  }

  get isDate() {
    return get(this.answer, 'date') || this.answer.date;
  }

  get question() {
    return this.answer.question;
  }

  get pickedQuestion() {
    return this.answer.pickedQuestion;
  }

  get unit() {
    return get(this.question, 'questionData')?.unit;
  }

  get formattedDate() {
    return moment(this.answer.date).format('YYYY-MM-DD');
  }
}
