/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';

export default class SurveysRow extends Component {
  get responseRate() {
    const { sends, responses } = this.args.survey;

    if (sends > 0) {
      return Math.round((100 * responses) / sends);
    }

    return 0;
  }
}
