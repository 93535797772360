import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class RequisitionStepVerdictSerializer extends ApplicationSerializer {
  attrs = {
    requisitionStepVerdictComments: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'requisition_step_verdict_comments_attributes',
    },
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'requisition-step-verdict': RequisitionStepVerdictSerializer;
  }
}
