/* import __COLOCATED_TEMPLATE__ from './company-menu.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import config from 'teamtailor/config/environment';
import { get } from 'teamtailor/utils/get';

export default class extends Component {
  @service router;
  @service permissions;
  @service current;

  adminUrl = `https://tt.${config.HTTP_HOST}/admin/`;
  salesAdminUrl = `https://tt.${config.HTTP_HOST}/tt_admin/`;

  get otherCompanies() {
    return get(this.current.user, 'multipleCompanies')
      ?.filter((company) => company.uuid !== this.current.company.uuid)
      .sortBy('name');
  }
}
