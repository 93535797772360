/* import __COLOCATED_TEMPLATE__ from './option-input.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

type FormBuilderDefaultFieldEditOptionInputArgs = {
  value: string;
  optionIndex: number;
  onChange: (index: number, value: string) => void;
};

export default class FormBuilderDefaultFieldEditOptionInputComponent extends Component<FormBuilderDefaultFieldEditOptionInputArgs> {
  @service declare intl: IntlService;
  @tracked inputValue = this.args.value;

  get isFirst(): boolean {
    return this.args.optionIndex === 0;
  }

  @action
  handleInputChange(e: KeyboardEvent) {
    const inputElement = e.target as HTMLInputElement;
    this.args.onChange(this.args.optionIndex, inputElement.value);
  }

  @action
  onFocusIn(): void {
    if (
      this.isFirst &&
      this.inputValue ===
        this.intl.t('settings.requisitions.custom_form.first_option')
    ) {
      this.inputValue = '';
    }
  }

  @action
  onFocusOut(): void {
    if (this.isFirst && this.inputValue === '') {
      this.args.onChange(
        0,
        this.intl.t('settings.requisitions.custom_form.first_option')
      );
    }
  }
}
