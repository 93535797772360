/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class PartnerConfigFieldsSelectComponent extends Component {
  get selectedValue() {
    return this.args.options?.find(
      (option) => option.id?.toString() === this.args.value?.toString()
    );
  }

  get selectedOptgroups() {
    return this.plainGroupOptions?.find(
      (option) => option.id?.toString() === this.args.value?.toString()
    );
  }

  get plainGroupOptions() {
    const { optgroups } = this.args;

    const reduceGroups = (result, group) => {
      return [
        ...result,
        ...group.options.map((option) => {
          return { id: `${option.id}`, label: option.label };
        }),
      ];
    };

    if (optgroups) {
      return optgroups.reduce(reduceGroups, []);
    } else {
      return null;
    }
  }

  get groupOptions() {
    const { optgroups } = this.args;

    if (optgroups) {
      return optgroups.map((optgroup) => {
        return {
          groupName: optgroup.label,
          options: optgroup.options.map((option) => {
            return { id: `${option.id}`, label: option.label };
          }),
        };
      });
    } else {
      return null;
    }
  }

  @action
  changeSelectValue(value) {
    const id = value ? value.id : null;
    this.args.onChange(id);
  }
}
