/* import __COLOCATED_TEMPLATE__ from './mobile-bar.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import BowserService from 'teamtailor/services/bowser';
import {
  ANDROID_APP_STORE_URL,
  IOS_APP_STORE_URL,
} from 'teamtailor/constants/mobile-app';

export default class LocationPickerComponent extends Component {
  @service session: any;
  @service declare bowser: BowserService;

  @tracked visible = true;

  get isMobile(): boolean {
    return (
      this.session.isAuthenticated &&
      (this.bowser.isAndroid || this.bowser.isIos)
    );
  }

  @action
  openApp(): void {
    window.location.replace(
      `teamtailor://auth?authtoken=${this.session.data.authenticated.token}`
    );
  }

  @action
  openAppStore(): void {
    if (this.bowser.isAndroid) {
      window.location.replace(ANDROID_APP_STORE_URL);
    } else if (this.bowser.isIos) {
      window.location.replace(IOS_APP_STORE_URL);
    }
  }

  @action
  closeBar(): void {
    this.visible = false;
  }
}
