/* import __COLOCATED_TEMPLATE__ from './message.hbs'; */
import BaseComponent from './base';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { calculateCredits } from 'teamtailor/utils/sms';
import { action, get, setProperties } from '@ember/object';
import { restartableTask } from 'ember-concurrency';

export default class MessageComponent extends BaseComponent {
  @service current;
  @service server;
  @service store;

  @tracked _body;
  @tracked _totalCreditsCost;

  @tracked pickedQuestions = [];

  @tracked messageUploads = [];
  @tracked messageTemplateId;
  @tracked messageBodySms;
  @tracked messageSubject;
  @tracked isSms = false;
  @tracked fallbackSmsToEmail = false;
  @tracked scheduleMessageSending;

  @tracked smsRecipientCount;

  get user() {
    return this.current.user;
  }

  get cannedResponses() {
    const departmentId = get(this.user, 'departmentId');

    const context = {
      department: departmentId,
      role: null,
      region: null,
      location: null,
    };

    return this.current.company.fetchGroupedCannedResponsesByContext(context);
  }

  get signature() {
    return `<br>${get(this, 'user.signature')}`;
  }

  get messageIsNotEmpty() {
    return (this.isSms &&
      this.messageBodySms &&
      !this.fallbackSmsToEmail &&
      !this.creditsLeftWarning) ||
      (this.isSms &&
        this.fallbackSmsToEmail &&
        this.messageSubject &&
        this.messageBodySms &&
        !this.creditsLeftWarning) ||
      (!this.isSms && this.messageBody && this.messageSubject)
      ? true
      : false;
  }

  get messageIsEmpty() {
    return !this.messageIsNotEmpty;
  }

  get messageBody() {
    if (get(this, 'activity.note')) {
      return get(this, 'activity.note');
    } else {
      if (this._body) {
        return this._body;
      }

      return this.signature;
    }
  }

  set messageBody(value) {
    this._body = value;
  }

  get bodyIsEmpty() {
    return !this.messageBody;
  }

  get showSMSWarning() {
    return get(this, 'smsRecipientCount.not_smsable_count');
  }

  get totalCreditsCost() {
    if (this._totalCreditsCost !== undefined) {
      return this._totalCreditsCost;
    }

    let smsMessageData = {
      message: this.messageBodySms,
      optOutMessage: get(this.current.company, 'optOutInformation'),
      replyMessage: get(this.current.company, 'replyToInformation'),
    };

    let candidatesNumbers = this.getCandidatesNumbers();

    return calculateCredits(smsMessageData, candidatesNumbers);
  }

  set totalCreditsCost(value) {
    this._totalCreditsCost = value;
  }

  get wallet() {
    return get(this.current.company, 'wallet');
  }

  get creditsLeftWarning() {
    return get(this, 'wallet.balance') < this.totalCreditsCost
      ? this.intl.t('components.bulk_actions.candidates.not_enough_credits')
      : '';
  }

  get languageCode() {
    return get(this.args, 'job.languageCode');
  }

  getCandidatesNumbers() {
    return {
      candidatesWithMessageNoSuffix: get(
        this,
        'smsRecipientCount.no_suffix_count'
      ),

      candidatesWithMessageReplySuffix: get(
        this,
        'smsRecipientCount.reply_to_suffix_count'
      ),

      candidatesWithMessageOptOutSuffix: get(
        this,
        'smsRecipientCount.opt_out_suffix_count'
      ),

      candidatesWithMessageOptOutAndReplySuffix: get(
        this,
        'smsRecipientCount.optout_and_reply_to_suffix_count'
      ),
    };
  }

  getSMSRecipientCountTask = restartableTask(async () => {
    const adapter = this.store.adapterFor('candidates');
    const url = `${adapter.buildURL('candidates')}/sms_recipient_count`;
    let count = await this.server.request(url, 'POST', {
      data: {
        ...this.args.searchParams,
        deselected_candidate_ids: this.args.deselectedIds,
        selected_candidate_ids: this.args.selectedIds,
      },
    });
    this.smsRecipientCount = count;
  });

  @action
  calculateRecipients() {
    this.getSMSRecipientCountTask.perform();
  }

  @action
  enableEmail() {
    this.isSms = false;
  }

  @action
  enableSms() {
    this.isSms = true;
  }

  @action
  pickQuestion(question) {
    this.pickedQuestions.pushObject(question);
  }

  @action
  removeQuestion(question) {
    this.pickedQuestions.removeObject(question);
  }

  @action
  removeUpload(upload) {
    this.messageUploads.removeObject(upload);
  }

  @action
  handleFileUploaded(temporaryFileUrl, fileFileName) {
    this.messageUploads.pushObject(
      this.store.createRecord('upload', {
        temporaryFileUrl,
        fileFileName,
      })
    );
  }

  @action
  selectedMessageTemplate(template) {
    setProperties(this, {
      messageTemplateId: get(template, 'id'),
      messageSubject: get(template, 'subject'),
      messageBody: get(template, 'body') + this.signature,
      messageUploads: get(template, 'uploads').toArray(),
      pickedQuestions: get(template, 'questions').toArray(),
    });
  }

  @action
  messageCandidates(delay_until) {
    const message = this.isSms ? this.messageBodySms : this.messageBody;

    let promise = this.postAction(
      'bulk_message',
      this.postData({
        canned_response_id: this.messageTemplateId,
        subject: this.messageSubject,
        job_id: get(this.args, 'job.id'),
        question_ids: this.pickedQuestions.mapBy('id'),
        upload_ids: this.messageUploads.mapBy('id').compact(),
        temporary_uploads: this.messageUploads
          .filterBy('isNew', true)
          .mapBy('temporaryFileUrl'),

        is_sms: this.isSms,
        is_sms_fallback: this.fallbackSmsToEmail,
        delay_until,
        message,
      })
    );

    promise.then(() => {
      setProperties(this, {
        messageBody: null,
        messageBodySms: null,
        fallbackSmsToEmail: false,
        messageSubject: '',
        pickedQuestions: [],
        messageTemplateId: null,
        messageUploads: [],
        scheduleMessageSending: false,
      });
    });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
