/* import __COLOCATED_TEMPLATE__ from './collapsible-header.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { argDefault } from 'teamtailor/utils/arg-default';
import ToggleService from 'teamtailor/services/toggle-service';
import JobApplicationModel from 'teamtailor/models/job-application';

type CollapsibleHeaderArgs = {
  identifier: string;
  emptyHeaderCells: boolean;
  hoveredJobApplication: JobApplicationModel;
  jobApplications?: JobApplicationModel[];
  pinnedCount?: number;
  offset?: string;
};

export default class CompareCandidatesCollapsibleHeaderComponent extends Component<CollapsibleHeaderArgs> {
  @service toggleService!: ToggleService;

  @argDefault emptyHeaderCells = true;

  toggle = () => {
    this.toggleService.toggle(this.args.identifier);
  };

  get isVisible(): boolean {
    return this.toggleService.isVisible(this.args.identifier);
  }
}
