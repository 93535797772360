/* import __COLOCATED_TEMPLATE__ from './remote-status.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { JobBulkArgs } from 'teamtailor/constants/jobs';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

type RemoteStatus = {
  id: string;
  label: string;
};

export default class BulkActionsJobsRemoteStatusComponent extends Component<JobBulkArgs> {
  @service declare intl: IntlService;

  @tracked remoteStatus: RemoteStatus | null = null;

  get remoteStatuses(): RemoteStatus[] {
    return ['none', 'temporary', 'hybrid', 'fully'].map((s) => {
      return {
        id: s,
        label: this.intl.t(`jobs.job.remote_status.options.${s}`),
      };
    });
  }

  @action
  onClose(): void {
    this.args.onClose();
    this.remoteStatus = null;
  }

  @action
  handleBulkChange(): void {
    const data = {
      remote_status: this.remoteStatus?.id,
    };
    this.args.handleBulkChange(data, 'bulk_remote_status');
    this.onClose();
  }
}
