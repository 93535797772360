/* import __COLOCATED_TEMPLATE__ from './questions.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { IQuestion } from 'teamtailor/components/meeting/types';

interface IPreparedMessageQuestionArgs {
  question: IQuestion;
  removeQuestion?(question: IQuestion): void;
}

export default class PreparedMessageQuestionComponent extends Component<IPreparedMessageQuestionArgs> {
  get canDestroy(): boolean {
    return 'removeQuestion' in this.args;
  }

  get question(): IQuestion {
    return this.args.question;
  }

  get questionIcon(): string {
    switch (this.question.type) {
      case 'Question::Boolean':
        return 'circle-check';
      case 'Question::Choice':
        return 'ballot-check';
      case 'Question::Range':
        return 'sliders';
      case 'Question::Text':
        return 'font';
      case 'Question::Video':
        return 'video';
      case 'Question::File':
        return 'file';
      default:
        return 'font';
    }
  }

  @action
  handleDestroy(): void {
    if (this.args.removeQuestion) {
      this.args.removeQuestion(this.args.question);
    }
  }
}
