/* import __COLOCATED_TEMPLATE__ from './stage-type-dropdown.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class StageTypeDropdown extends Component {
  @service current;

  get editableStageTypes() {
    return get(this.current.company, 'editableStageTypes');
  }
}
