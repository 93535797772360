/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { keyResponder, onKey } from 'ember-keyboard';
import { KeyCodes } from 'teamtailor/constants/key-codes';
import { argDefault } from 'teamtailor/utils/arg-default';

export type CoreModalComponentSize = 'small' | 'medium' | 'large';

export interface CoreModalComponentArgs {
  size?: CoreModalComponentSize;
  showCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
  closeOnEscape?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

@keyResponder
export default class CoreModalComponent extends Component<CoreModalComponentArgs> {
  @argDefault closeOnBackdropClick = true;
  @argDefault closeOnEscape = true;
  @argDefault isOpen = true;

  get modalElement() {
    return document.getElementById('ember-teamtailor-modal');
  }

  @action
  handleBackdropClick() {
    if (this.closeOnBackdropClick) {
      this.args.onClose?.();
    }
  }

  @onKey(KeyCodes.ESC)
  handleEscape() {
    if (this.closeOnEscape) {
      this.args.onClose?.();
    }
  }
}
