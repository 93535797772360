/* import __COLOCATED_TEMPLATE__ from './job-average-times.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';

const QUERY = gql`
  query JobAverageTimesQuery(
    $dateRange: DateRangeAttributes!
    $compareDateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    currentPeriod: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      distinctBy: {
        fields: [EVENT_TYPE, JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      aggregated(groupBy: [JOB_ID]) {
        timeToHire: average(field: TIME_TO_HIRE)
        timeInInbox: average(field: TIME_IN_INBOX)
        timeToReject: average(field: TIME_TO_REJECT)
      }
    }
    previousPeriod: eventQuery(
      dateRange: $compareDateRange
      jobIds: $jobIds
      distinctBy: {
        fields: [EVENT_TYPE, JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      aggregated(groupBy: [JOB_ID]) {
        timeToHire: average(field: TIME_TO_HIRE)
        timeInInbox: average(field: TIME_IN_INBOX)
        timeToReject: average(field: TIME_TO_REJECT)
      }
    }
  }
`;

export default class extends Component {
  @service insights;

  get currentPeriod() {
    return this.fetchData?.lastSuccessful?.value?.currentPeriod;
  }

  get previousPeriod() {
    return this.fetchData?.lastSuccessful?.value?.previousPeriod;
  }

  queryForData(dateRange) {
    const { job } = this.args;

    return this.insights.query({
      query: QUERY,
      variables: {
        dateRange: dateRange.asObject,
        compareDateRange: dateRange.previousDateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });
  }

  fetchData = dropTask(async () => {
    const { currentPeriod, previousPeriod } = await this.queryForData(
      this.args.dateRange
    );

    return {
      currentPeriod: currentPeriod?.aggregated?.firstObject,
      previousPeriod: previousPeriod?.aggregated?.firstObject,
    };
  });
}
