/* import __COLOCATED_TEMPLATE__ from './stats-item.hbs'; */
import Component from '@glimmer/component';
import { camelize } from '@ember/string';

interface CountObj {
  count: number;
  category: string;
}

interface Args {
  stageTypeName: string;
  stageTypesStats?: { [key: string]: CountObj };
}

export default class StatsItem extends Component<Args> {
  get value() {
    const { stageTypeName } = this.args;
    const stats = this.args.stageTypesStats;

    if (!stageTypeName) {
      return;
    }

    return stats?.[`${camelize(stageTypeName)}Count`];
  }
}
