/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import BaseComponent from 'teamtailor/components/content-block/base-component';

export default class GalleryComponent extends BaseComponent {
  @tracked showImageLibrary = false;

  @action
  async createSectionItem(image) {
    const sectionItem = await this.store.createRecord('section-item', {
      section: this.args.model,
    });

    await sectionItem.save();

    const pickedImage =
      sectionItem.pickedImage ||
      (await this.store.createRecord('picked-image', {
        sectionItem,
      }));

    pickedImage.image = image;
    await pickedImage.save();
    this.reload();
  }
}
