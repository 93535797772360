/* import __COLOCATED_TEMPLATE__ from './share-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ShareButtonComponent extends Component {
  getPopupPosition() {
    const dualScreenLeft = screen.availLeft;
    const dualScreenTop = screen.availTop;

    const windowWidth = screen.availWidth;
    const windowHeight = screen.availHeight;

    const left = windowWidth / 2 - 600 / 2 + dualScreenLeft;
    const top = windowHeight / 2 - 600 / 2 + dualScreenTop;

    return { left, top };
  }

  openSharePopup(url) {
    let popupPosition = this.getPopupPosition();
    let newWindow = window.open(
      url,
      'Facebook',
      `location=no,toolbar=no,menubar=no,scrollbars=no,status=no, width=600, height=600, top=${popupPosition.top}, left=${popupPosition.left}`
    );

    if (
      typeof newWindow !== 'undefined' &&
      newWindow !== null &&
      newWindow.focus
    ) {
      newWindow.focus();
    }
  }

  @action
  handleClick() {
    const url = this.buildUrl();
    this.openSharePopup(url);
  }
}
