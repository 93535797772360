/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { isEmpty, isPresent } from '@ember/utils';
export default class Modal extends Component {
  @service ttAlert;
  @service flashMessages;
  @service intl;
  @service store;
  @service current;

  get candidate() {
    return this.args.candidate;
  }

  get removalRequestedAt() {
    return get(this.candidate, 'removalRequestedAt');
  }

  get dataRequestedAt() {
    return get(this.candidate, 'dataRequestedAt');
  }

  get consentIsExpired() {
    return get(this.candidate, 'consentIsExpired');
  }

  get consentMissingAt() {
    return get(this.candidate, 'consentMissingAt');
  }

  get willBeDeletedAt() {
    return get(this.candidate, 'willBeDeletedAt');
  }

  get consentFutureJobsMissing() {
    return get(this.candidate, 'consentFutureJobsMissing');
  }

  get consentRequestedAt() {
    return get(this.candidate, 'consentRequestedAt');
  }

  get sentExtendGdprReminderAt() {
    return get(this.candidate, 'sentExtendGdprReminderAt');
  }

  get jobApplicationWillBeDeletedAt() {
    return get(this.candidate, 'jobApplicationWillBeDeletedAt');
  }

  get anyConsentRequestedAt() {
    return this.consentRequestedAt || this.sentExtendGdprReminderAt;
  }

  get _consentIsEmpty() {
    return isEmpty(get(this.candidate, 'consentExpiresAt'));
  }

  get consentNotMissingOrGiven() {
    return isPresent(this.consentMissingAt) || !this._consentIsEmpty;
  }

  get _consentEmptyOrExpired() {
    return this.consentIsExpired || this._consentIsEmpty;
  }

  get _didSendGdprReminder() {
    return isPresent(get(this, 'sentExtendGdprReminderAt'));
  }

  get _optInConsentIsPending() {
    return this._consentEmptyOrExpired && this._didSendGdprReminder;
  }

  get consentPending() {
    return (
      get(this.candidate, 'consentIsPending') || this._optInConsentIsPending
    );
  }

  @tracked showShare = false;

  @action
  extendConsent() {
    this.ttAlert.confirm(
      this.intl.t(
        'candidates.candidate.candidate_modal.proceed_extend_expiry_date'
      ),
      () => {
        this.candidate.extendConsent().then((res) => {
          this.store.pushPayload(res);
          this.flashMessages.success(
            this.intl.t(
              'candidates.candidate.candidate_modal.consent_center.consent_was_extended'
            )
          );
        });
      },
      () => {}
    );
  }

  @action
  requestConsent() {
    return new Promise((resolve, reject) => {
      this.ttAlert.confirm(
        this.intl.t(
          'candidates.candidate.candidate_modal.extend_data_retention_period_alert'
        ),
        () => {
          this.candidate.requestConsent().then(
            (res) => {
              this.store.pushPayload(res);
              this.flashMessages.success(
                this.intl.t(
                  'candidates.candidate.candidate_modal.consent_request_sent'
                )
              );
              return resolve();
            },
            () => {
              this.flashMessages.error(
                this.intl.t(
                  'candidates.candidate.candidate_modal.could_not_send_consent_request'
                )
              );
              return reject();
            }
          );
        },
        () => {
          return reject({ cancel: true });
        }
      );
    });
  }

  @action
  destroyCandidate() {
    this.ttAlert.confirm(
      this.intl.t(
        'candidates.candidate.candidate_modal.are_you_sure_deleting_candidate'
      ),
      () => {
        const jobApplications = get(this.candidate, 'jobApplications');
        this.candidate.deleteRecord();
        this.candidate.save().then(() => {
          jobApplications.invoke('deleteRecord');
          this.flashMessages.success(
            this.intl.t(
              'candidates.candidate.candidate_modal.candidate_deleted'
            )
          );
          this.args.onDestroyCandidate();
        });
      }
    );
  }

  @action
  dismissDataRequest() {
    this.ttAlert.confirm(
      this.intl.t(
        'candidates.candidate.candidate_modal.are_you_sure_dismiss_data_request'
      ),
      () => {
        this.candidate
          .handleDataRequest({ dismissed_or_handled: 'dismissed' })
          .then((res) => {
            this.store.pushPayload(res);
            this.flashMessages.success(
              this.intl.t(
                'candidates.candidate.candidate_modal.data_request_dismissed'
              )
            );
          });
      }
    );
  }

  @action
  onShareData() {
    this.showShare = false;
    this.flashMessages.success(
      this.intl.t(
        'candidates.candidate.candidate_modal.consent_center.data_shared'
      )
    );
  }
}
