/* import __COLOCATED_TEMPLATE__ from './apply-with-linkedin.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { bind } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @service pusher;
  @service user;
  @service current;
  @service intl;

  @tracked isLoading = true;

  get clientId() {
    return this.current.company.linkedinClientId;
  }

  get enabled() {
    return !!this.current.company.applyWithLinkedin;
  }

  get disabled() {
    return !this.enabled;
  }

  constructor() {
    super(...arguments);
    if (this.disabled || !this.clientId) {
      this.isLoading = false;
    }

    window.addEventListener(
      'message',
      bind(this, this.handleJavascriptEvents),
      false
    );

    this.pusher
      .channel(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        this.channel = channel;

        this.bindToLinkedinPartnerCreatorEvent();
        this.bindToLinkedinPartnerIntegrationEvent();
      });
  }

  bindToLinkedinPartnerCreatorEvent() {
    this.channel.bind('linkedinPartnerCreator', (data) => {
      if (data.created) {
        this.clientId = data.linkedin_client_id;
      } else {
        this.isLoading = false;
        this.error = this.intl.t('settings.apply_with_linkedin.error');
      }
    });
  }

  bindToLinkedinPartnerIntegrationEvent() {
    this.channel.bind('linkedinPartnerIntegration', (data) => {
      if (!data.integrated) {
        this.error = this.intl.t('settings.apply_with_linkedin.error');
      }
    });
  }

  handleJavascriptEvents(e) {
    if (e.data.for === 'applyWithLinkedinController') {
      switch (e.data.action) {
        case 'IFRAME_LOADED':
          this.isLoading = false;
          break;
        case 'INTEGRATIONS':
          this.handleCallbackIntegration(e.data.integrations);
          break;
      }
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    let { channel } = this;
    if (channel) {
      channel.unbind('linkedinPartnerCreator');
      channel.unbind('linkedinPartnerIntegration');
    }

    window.removeEventListener(
      'message',
      bind(this, this.handleJavascriptEvents),
      false
    );
  }

  handleCallbackIntegration(integration) {
    this.error = undefined;
    this.current.company.integrateLinkedinPartner({ integration });
  }

  @action
  createLinkedinPartner() {
    this.isLoading = true;
    this.error = undefined;
    this.current.company.createLinkedinPartner();
  }
}
