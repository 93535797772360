/* import __COLOCATED_TEMPLATE__ from './user-card.hbs'; */
import Component from '@glimmer/component';

export default class InsightsMoleculesUserCard extends Component {
  get topActivities() {
    const activityKeys = [
      'hires',
      'notes',
      'messages',
      'moves',
      'rejections',
      'reviews',
      'sources',
    ];
    const orderedActivities = activityKeys.map((key) => ({
      key,
      value: this.args.activities[key] || 0,
    }));

    if (this.args.criteria !== 'totalActivities') {
      return orderedActivities.filter(
        (activity) => activity.key === this.args.criteria
      );
    } else {
      return orderedActivities.sortBy('value').slice(-3).reverse();
    }
  }
}
