/* import __COLOCATED_TEMPLATE__ from './candidate-widget.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { modifier } from 'ember-modifier';

export default class LinkedinCandidateWidgetComponent extends Component {
  @tracked widgetWidth = 0;

  @service intl;
  @service current;

  registerWidget = modifier((widgetContainerElement) => {
    this.setWidgetWidth(widgetContainerElement);

    window.addEventListener('message', this.handleJavascriptEvents.bind(this));

    return () => {
      window.removeEventListener('message', this.handleJavascriptEvents);
    };
  });

  @action
  handleJavascriptEvents(e) {
    if (e.data.for === 'linkedinCandidateWidget') {
      const { member, action } = e.data;
      switch (action) {
        case 'LINK':
          this.link(member);
          break;
        case 'UNLINK':
          this.unlink();
          break;
      }
    }
  }

  @action
  link(memberId) {
    const { candidate } = this.args;
    set(candidate, 'linkedinId', memberId);
    candidate.save();
  }

  @action
  unlink() {
    const { candidate } = this.args;
    set(candidate, 'linkedinId', undefined);
    candidate.save();
  }

  @action
  setWidgetWidth(widgetContainerElement) {
    const [rect] = widgetContainerElement.getClientRects();
    const { width } = rect;
    this.widgetWidth = width;
  }
}
