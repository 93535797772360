import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class DashboardWidgetSerializer extends ApplicationSerializer {
  serialize() {
    let json = super.serialize(...arguments);
    json.content = json.body;
    delete json.body;

    return json;
  }
}
