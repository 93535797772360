/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import { action } from '@ember/object';
import BaseComponent, {
  BaseComponentArgs,
} from 'teamtailor/components/content-block/base-component';

export default class ListComponent extends BaseComponent<BaseComponentArgs> {
  @action
  async handleNewSectionItem(itemType: string) {
    this.addSectionItem(itemType, true);
  }
}
