/* import __COLOCATED_TEMPLATE__ from './partner-hub-form.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ENV from 'teamtailor/config/environment';
export default class PartnerHubForm extends Component {
  @tracked partnerCategories = [];
  @service current;
  @service server;
  @tracked countries = get(this, 'args.channel.countries');
  @tracked marketingTypes = [
    { id: 'premium', label: 'Agreed with partner' },
    { id: 'always_included', label: 'Always included XML' },
  ];

  integrationTypes = [
    { id: 'webhook', label: 'webhook' },
    { id: 'xml', label: 'xml' },
  ];

  @tracked integrationType = this.getInitialIntegrationType();

  get teamtailorAdapterUrl() {
    return `${ENV.TEAMTAILOR_ADAPTER_BASE_URL}/v2`;
  }

  constructor() {
    super(...arguments);
    this.getPartnerCategories.perform();
  }

  get url() {
    return `/app/companies/${get(
      this.current.company,
      'uuid'
    )}/api/partners/categories`;
  }

  getPartnerCategories = task(async () => {
    if (this.args.channel) return [];
    const categories = await this.server.request(this.url);
    this.partnerCategories = categories?.partners;
    return categories;
  });

  @action handleSelectMarketingType({ id }) {
    this.args.channel.marketingType = id;
  }

  @action handleSelectIntegrationType(type) {
    if (type.id === 'xml') {
      this.args.channel.adapterBaseUrl = this.teamtailorAdapterUrl;
    } else {
      this.args.channel.adapterBaseUrl = '';
    }

    this.integrationType = type;
  }

  @action onCountryChange(event) {
    if (event.target) {
      const values = event.target.value;

      this.args.channel.countries =
        values?.split(',').map((value) => value.trim()) || [];
    }
  }

  @action getInitialIntegrationType() {
    const channel = get(this, 'args.channel');
    if (channel.adapterBaseUrl === this.teamtailorAdapterUrl) {
      return this.integrationTypes[1];
    }

    return this.integrationTypes[0];
  }

  @action handleSelectCategory(category) {
    set(this, 'args.partner.content.category', category.id);
  }
}
