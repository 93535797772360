/* import __COLOCATED_TEMPLATE__ from './file.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  onChange: (value: unknown) => void;
  value: {
    url: string | null;
    file_name?: string | null;
    custom_file_id?: string | null;
  } | null;
  label: string;
  placeholder: string;
}

export default class FormFieldReadFile extends Component<Args> {
  @tracked filename: string | null = null;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.value?.file_name) {
      this.filename = this.args.value.file_name;
    }
  }

  @action
  fileUpload(temporaryFileUrl: string, filename: string) {
    this.filename = filename;

    return this.args.onChange({
      file: temporaryFileUrl,
    });
  }

  @action
  removeFile() {
    this.filename = null;

    return this.args.onChange({
      custom_file_id: this.args.value?.custom_file_id,
      file: null,
    });
  }
}
