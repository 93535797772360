/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import PromotionModel from 'teamtailor/models/promotion';
import JobModel from 'teamtailor/models/job';

export interface PromotionActionEditArgs {
  promotion: PromotionModel | undefined;
  job: JobModel;
  hasAdapter: boolean;
}

export default class PromotionActionEdit extends Component<PromotionActionEditArgs> {
  @service declare router: RouterService;

  @action
  handleEdit() {
    if (!this.args.promotion) return;

    if (this.args.hasAdapter) {
      this.router.transitionTo(
        'jobs.job.promote.promotion.edit-external',
        this.args.job,
        this.args.promotion
      );
    } else {
      this.router.transitionTo(
        'jobs.job.promote.edit',
        this.args.job,
        this.args.promotion
      );
    }
  }
}
