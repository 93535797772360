/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class PartnerConfigFieldsBaseComponent extends Component {
  type = this.args.field.type;
  required = this.args.field.required;
  refetchedField = false;

  @tracked dynamicFields;

  @tracked
  value;

  isRadio = this.type === 'radio';
  isFile = this.type === 'file';
  isDate = this.type === 'date';
  isCheckbox = this.type === 'checkbox';
  isTextarea = this.type === 'textarea';
  isHtmlTextarea = this.type === 'textarea-html';
  isInfoBox = this.type === 'infobox';
  isError = this.type === 'error';
  isHidden = this.type === 'hidden';
  isLink = this.type === 'link';
  isImageSelect = this.type === 'select-image';

  get hasIcon() {
    return ['info', 'error', 'warning', 'success', 'default'].includes(
      this.args.field.icon
    );
  }

  get show() {
    const { label } = this.args.field;
    if (!label && this.type === 'hidden') {
      return false;
    }

    return true;
  }

  get description() {
    const { description } = this.args.field;
    return this.htmlSafeOrUndefined(description);
  }

  get label() {
    let { label } = this.args.field;
    if (this.args.field.required) label += '*';
    return this.htmlSafeOrUndefined(label);
  }

  get htmlInfoBox() {
    const { content } = this.args.field;
    return this.htmlSafeOrUndefined(content);
  }

  get htmlError() {
    const { message } = this.args.field;
    return this.htmlSafeOrUndefined(message);
  }

  get isPrimitive() {
    return [
      'color',
      'datetime',
      'datetime-local',
      'email',
      'hidden',
      'month',
      'password',
      'range',
      'reset',
      'search',
      'tel',
      'text',
      'url',
      'week',
    ].includes(this.type);
  }

  @action async didInsert() {
    const { webhookData = {}, field } = this.args;

    if (field.useValue) {
      this.value =
        field.value === undefined ? webhookData[field.id] : field.value;
    } else {
      this.value =
        webhookData[field.id] === undefined
          ? field.value
          : webhookData[field.id];
    }

    this.args.field.isValid = this.isRequiredPresent(this.value);
    this.args.valueChanged(field.id, this.value);

    if (
      this.args.field.dynamicField &&
      this.args.promotionRequest &&
      this.args.shouldFetchDynamicFields
    ) {
      await this.fetchDynamicField.perform();
    }
  }

  @action
  isRequiredPresent(value) {
    if (this.type === 'error') {
      return false;
    }

    return this.required ? isPresent(value) : true;
  }

  fetchDynamicField = task(async () => {
    const { fields } = await this.args.promotionRequest.fetchDynamicField({
      field_url: this.args.field.dynamicField.url,
      webhook_data: { [this.args.field.id]: this.value },
      query: this.args.field.dynamicField?.query,
    });

    if (!fields) return null;

    this.dynamicFields = fields;

    return fields;
  });

  @action
  async onChange(value, { valid = true } = {}) {
    this.value = value;
    this.args.field.isValid = valid && this.isRequiredPresent(value);

    if (this.args.field.dynamicField && this.args.promotionRequest) {
      await this.fetchDynamicField.perform();
    }

    this.args.valueChanged(this.args.field.id, value);
  }

  @action
  htmlSafeOrUndefined(string) {
    if (!string) return '';

    return htmlSafe(string);
  }
}
