/* import __COLOCATED_TEMPLATE__ from './columns.hbs'; */
// @ts-nocheck
import Component from '@glimmer/component';
import { CustomReportProperty } from 'teamtailor/utils/custom-report-schema';
import { action } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed'; // eslint-disable-line ember/no-computed-properties-in-native-classes

interface CustomReportFormArgs {
  selectedProperties: CustomReportProperty[];
  properties: CustomReportProperty[];
}

export default class CustomReportFormColumns extends Component<CustomReportFormArgs> {
  @readOnly('args.properties')
  declare properties: CustomReportProperty[];

  @alias('args.selectedProperties')
  declare selectedProperties: CustomReportProperty[];

  get availableProperties() {
    return this.properties.filter(
      ({ name }) => !this.selectedPropertyNames.includes(name)
    );
  }

  get selectedPropertyNames() {
    return this.selectedProperties.mapBy('name');
  }

  @action
  handleDragEnd({ _draggedItem, sourceIndex, targetIndex }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    const newSelectedProperties = this.selectedProperties.toArray();

    const movedItem = newSelectedProperties[sourceIndex];
    newSelectedProperties.removeObject(movedItem);
    newSelectedProperties.insertAt(targetIndex, movedItem);
    this.selectedProperties.setObjects(newSelectedProperties);
  }

  @action
  addProperty(property: CustomReportProperty) {
    this.selectedProperties.pushObject(property);
  }

  @action
  removeProperty(property: CustomReportProperty) {
    this.selectedProperties.removeObject(property);
  }
}
