/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import { get } from 'teamtailor/utils/get';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Upload from 'teamtailor/models/upload';

interface JobsCollaborateItemArgs {
  model: ActivityModel;
}

export default class JobsCollaborateItemIndex extends Component<JobsCollaborateItemArgs> {
  @service declare timeFormat: TimeFormatService;
  @tracked upload: Upload | undefined | null;

  constructor(owner: unknown, args: JobsCollaborateItemArgs) {
    super(owner, args);

    this.fetchUpload.perform();
  }

  fetchUpload = task(async () => {
    this.upload = await get(this.args.model, 'upload');
  });
}
