/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import PerfectScrollbar from 'perfect-scrollbar';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';

export default class extends Component {
  @service current;
  @service store;
  @service intl;
  @service flashMessages;

  @tracked initialLoad = true;
  @tracked currentMonth = null;
  @tracked showPrevious = false;
  @tracked filterOnFeedback = false;

  perfectScrollbar = null;

  constructor(...args) {
    super(...args);

    this.meetingEventQuery.eventDatesCallback = this.updateDateCountContainers;
  }

  get selectedDate() {
    return this.meetingEventQuery.selectedDate;
  }

  get meetingEventQuery() {
    return this.args.meetingEventQuery;
  }

  get toolboxPath() {
    return [
      'meetings',
      this.args.selectedScopeFilter,
      this.args.selectedStatusFilter,
    ].join(',');
  }

  get meetingEvents() {
    return this.meetingEventQuery.meetingEvents;
  }

  get upcomingEvents() {
    if (this.hasSearched || this.showPrevious) {
      return [];
    }

    return this.currentEvent ? [this.currentEvent] : [];
  }

  get renderableEvents() {
    const withoutUpcoming = this.meetingEvents.filter(
      (me) => !this.upcomingEvents.includes(me)
    );

    return withoutUpcoming.map((meetingEvent, index) => {
      if (!meetingEvent.bookedSlotStartsAt) {
        return { meetingEvent, showDate: false };
      }

      const prevMeetingEvent = index > 0 ? withoutUpcoming[index - 1] : null;

      const showDate =
        !prevMeetingEvent ||
        !moment(meetingEvent.bookedSlotStartsAt).isSame(
          prevMeetingEvent.bookedSlotStartsAt,
          'day'
        );

      return {
        meetingEvent,
        showDate,
      };
    });
  }

  get searchString() {
    return moment(this.selectedDate).format('Do');
  }

  get hasSearched() {
    return !!this.selectedDate;
  }

  get showCurrentEvent() {
    return !(this.hasSearched || this.showPrevious);
  }

  get currentEvent() {
    const earliestEvent = this.meetingEvents.toArray().sort((a, b) => {
      if (moment(a.bookedSlotEndsAt).isBefore(moment(b.bookedSlotEndsAt))) {
        return -1;
      }

      if (moment(a.bookedSlotEndsAt).isAfter(moment(b.bookedSlotEndsAt))) {
        return 1;
      }

      return 0;
    }).firstObject;

    if (
      !earliestEvent?.bookedSlotStartsAt ||
      moment(earliestEvent?.bookedSlotStartsAt).isAfter(
        moment().add(8, 'hours')
      )
    ) {
      return null;
    }

    return earliestEvent;
  }

  get allMeetingEventDates() {
    return this.meetingEventQuery.allMeetingEventDates;
  }

  get cronofyReconnectRequired() {
    return get(
      this.current.user,
      'cronofyAuthorization.cronofyReconnectedRequired'
    );
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.meetingEventQuery.selectedDate = null;
    this.meetingEventQuery.monthToShow = null;
    if (this.perfectScrollbar) {
      this.perfectScrollbar.destroy();
      this.perfectScrollbar = null;
    }
  }

  @action
  reconnectProfiles() {
    return this.reconnectCronofyTask.perform();
  }

  reconnectCronofyTask = restartableTask(async () => {
    const auth = await get(this.current, 'user.cronofyAuthorization');

    if (auth) {
      await auth.fetchReconnectProfiles();
      this.store.unloadRecord(auth);
      const reconnectRequired = await get(
        this.current.user,
        'cronofyAuthorization.cronofyReconnectedRequired'
      );

      if (reconnectRequired) {
        this.flashMessages.error(
          this.intl.t('components.toolbox.meetings.sidebar.reconnect_failed')
        );
      } else {
        this.flashMessages.success(
          this.intl.t(
            'components.toolbox.meetings.sidebar.reconnect_successful'
          )
        );
      }
    }
  });

  @action
  registerDateCountContainers(dateCountContainers) {
    this.dateCountContainers = dateCountContainers;
    this.updateDateCountContainers();
  }

  @action
  updateDateCountContainers() {
    Object.entries(this.dateCountContainers).forEach(([d, container]) => {
      const meOnDay = this.allMeetingEventDates.filter((meD) => d === meD);
      const meCount = meOnDay.length;
      const dots = Math.min(meCount, 3);
      container.innerHTML = '.'.repeat(dots);
    });
  }

  @action
  async onOpenChanged() {
    if (this.args.isOpen) {
      await this.meetingEventQuery.refetchTask.perform();
      this.initialLoad = false;
    }
  }

  @action
  bindScrollElement(element) {
    this.meetingEventQuery.scrollContainerElement = element;
    this.perfectScrollbar = new PerfectScrollbar(element, {
      wheelPropagation: true,
      suppressScrollX: true,
    });
  }

  @action
  loadNextPage() {
    if (!this.meetingEventQuery.fetchMeetingEventsTask.isRunning) {
      this.meetingEventQuery.fetchMeetingEventsTask.perform();
    }
  }

  @action
  selectDate(value) {
    const newDate = Array.isArray(value) ? value[0] : value;

    if (moment(newDate).isSame(this.selectedDate, 'day')) {
      this.meetingEventQuery.selectedDate = null;
    } else {
      this.meetingEventQuery.selectedDate = newDate;
    }

    this.meetingEventQuery.refetchTask.perform();
  }

  @action
  showCurrentMonth(value) {
    this.currentMonth = value || new Date();

    if (this.showPrevious) {
      const newDate = Array.isArray(this.currentMonth)
        ? this.currentMonth[0]
        : this.currentMonth;
      this.meetingEventQuery.monthToShow = newDate;
    }

    this.meetingEventQuery.refetchTask.perform();
  }

  @action
  togglePreviousFilter() {
    if (this.showPrevious) {
      this.showPrevious = false;
      this.meetingEventQuery.monthToShow = null;
    } else {
      this.showPrevious = true;
      const newDate = Array.isArray(this.currentMonth)
        ? this.currentMonth[0]
        : this.currentMonth;
      this.meetingEventQuery.monthToShow = newDate || new Date();
    }

    this.meetingEventQuery.refetchTask.perform();
  }

  @action
  toggleFeedbackFilter() {
    this.filterOnFeedback = !this.filterOnFeedback;

    this.meetingEventQuery.filterOnFeedback = this.filterOnFeedback;
    this.meetingEventQuery.refetchTask.perform();
  }

  @action
  clearSearch() {
    this.meetingEventQuery.selectedDate = null;
  }
}
