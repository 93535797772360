/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import BlockAdjustmentsService from 'teamtailor/services/block-adjustments';

interface Args {
  blockName: string;
}

export default class BlockLibraryItem extends Component<Args> {
  @service declare blockAdjustments: BlockAdjustmentsService;

  get canPreviewLayout() {
    return this.blockAdjustments.canPreviewLayout();
  }
}
