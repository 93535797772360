/* import __COLOCATED_TEMPLATE__ from './availability-rules.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { helper } from '@ember/component/helper';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import Current from 'teamtailor/services/current';
import {
  AvailabilityPeriod,
  AvailabilityRules,
} from 'teamtailor/models/authorization';
import { argDefault } from 'teamtailor/utils/arg-default';

type CalendarAvailabilityRulesArgs = {
  availabilityRules?: AvailabilityRules;
  startTime?: string;
  endTime?: string;
  onChange: (rules: AvailabilityRules) => void;
};

export default class CalendarAvailabilityRulesComponent extends Component<CalendarAvailabilityRulesArgs> {
  @service declare timeFormat: TimeFormatService;
  @service declare current: Current;

  @argDefault startTime = '08:00';
  @argDefault endTime = '17:00';

  @tracked showTimesBefore = false;
  @tracked showTimesAfter = false;
  @tracked _availability?: AvailabilityPeriod[];

  get days() {
    return this.timeFormat.orderedDaysOfWeek;
  }

  get timeSlots() {
    const start = this.showTimesBefore ? '00:00' : this.startTime;
    const end = this.showTimesAfter ? '23:59' : this.endTime;
    return this.generateTimeSlotsBetween(start, end);
  }

  get availabilityTimeZone() {
    return this.args.availabilityRules?.tzid || 'UTC';
  }

  get availability() {
    return (
      this._availability || this.args.availabilityRules?.weekly_periods || []
    );
  }

  set availability(value) {
    this._availability = value;
  }

  toggleSlot = (day: string, slot: string) => {
    const dayPeriods = this.availability.filter((period) => period.day === day);
    const existingPeriod = dayPeriods.find(
      (period) =>
        moment(slot, 'HH:mm').isSameOrAfter(
          moment(period.start_time, 'HH:mm')
        ) && moment(slot, 'HH:mm').isBefore(moment(period.end_time, 'HH:mm'))
    );

    if (existingPeriod) {
      // Slot is within an existing period
      if (slot === existingPeriod.start_time) {
        // Toggling off the start time
        if (existingPeriod.end_time === this.oneHourAfter(slot)) {
          // Remove the period if it's only one hour long
          this.availability = this.availability.filter(
            (period) => period !== existingPeriod
          );
        } else {
          // Shorten the period from the start
          existingPeriod.start_time = this.oneHourAfter(slot);
        }
      } else if (slot === this.oneHourBefore(existingPeriod.end_time)) {
        // Toggling off the end time
        existingPeriod.end_time = slot;
      } else {
        // Split the period
        const newPeriod = {
          day: day,
          start_time: this.oneHourAfter(slot),
          end_time: existingPeriod.end_time,
        };
        existingPeriod.end_time = slot;
        this.availability.push(newPeriod);
      }
    } else {
      // Slot is not within any existing period
      const adjacentBefore = dayPeriods.find(
        (period) => period.end_time === slot
      );
      const adjacentAfter = dayPeriods.find(
        (period) => period.start_time === this.oneHourAfter(slot)
      );

      if (adjacentBefore && adjacentAfter) {
        adjacentBefore.end_time = adjacentAfter.end_time;
        this.availability = this.availability.filter(
          (period) => period !== adjacentAfter
        );
      } else if (adjacentBefore) {
        adjacentBefore.end_time = this.oneHourAfter(slot);
      } else if (adjacentAfter) {
        adjacentAfter.start_time = slot;
      } else {
        // Create a new period
        this.availability.push({
          day: day,
          start_time: slot,
          end_time: this.oneHourAfter(slot),
        });
      }
    }

    this.availability = [...this.availability];

    this.args.onChange({
      ...this.args.availabilityRules,
      weekly_periods: this.availability,
    } as AvailabilityRules);
  };

  slotToMoment = (slot: string) => {
    return moment(slot, 'HH:mm');
  };

  updateTimeZone = (tzid: string) => {
    return this.args.onChange({
      ...this.args.availabilityRules,
      tzid,
    } as AvailabilityRules);
  };

  oneHourAfter(slot: string) {
    const hourAfter = moment(slot, 'HH:mm').add(1, 'hour').format('HH:mm');
    return hourAfter === '00:00' ? '24:00' : hourAfter;
  }

  oneHourBefore(slot: string) {
    return moment(slot, 'HH:mm').subtract(1, 'hour').format('HH:mm');
  }

  generateTimeSlotsBetween(start: string, end: string) {
    const slots = [];
    let currentMoment = moment(start, 'HH:mm');
    const endMoment = moment(end, 'HH:mm');

    while (currentMoment.isBefore(endMoment)) {
      slots.push(currentMoment.format('HH:mm'));
      currentMoment.add(1, 'hour');
    }

    return slots;
  }

  slotSelected = helper(([day, slot]) => {
    const dayPeriods = this.availability.filter((period) => period.day === day);
    const isSelected = dayPeriods.some(
      (period) =>
        moment(slot, 'HH:mm').isSameOrAfter(
          moment(period.start_time, 'HH:mm')
        ) && moment(slot, 'HH:mm').isBefore(moment(period.end_time, 'HH:mm'))
    );

    return isSelected;
  });
}
