/* import __COLOCATED_TEMPLATE__ from './skills-traits.hbs'; */
import BulkActionsBase from './base';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import CurrentService from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BulkActionsJobsComponent extends BulkActionsBase {
  @service declare store: Store;
  @service declare current: CurrentService;

  @tracked showModal = false;

  get bulkData() {
    const { deselectedIds, selectAll, selectedIds, searchParams } = this.args;
    if (selectAll) {
      return {
        query: searchParams,
        deselected_ids: deselectedIds,
      };
    } else {
      return {
        selected_ids: selectedIds,
      };
    }
  }

  @action
  async handleBulkChange(): Promise<void> {
    const promise = this.postAction(
      'bulk_delete',
      this.bulkData,
      'scorecard-criteria'
    );

    this.showModal = false;
    await this.batchJob.batchJobStartedTask.perform(
      promise,
      this.args.selectedCount
    );
    this.args.onBulkActionDelete();
  }

  @action
  handleModal() {
    this.showModal = !this.showModal;
  }
}
