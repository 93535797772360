/* import __COLOCATED_TEMPLATE__ from './search.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { argDefault } from 'teamtailor/utils/arg-default';

export interface CoreDropdownSearchComponentArgs {
  listboxId?: string;
  placeholder?: string;
  onSearch?: (event: Event) => void;
  onCreate?: (name?: string) => void;
  activeItemEl?: HTMLElement;
  variant?: 'full-width' | 'floating';
  icon?: string;
  multiline?: boolean;
}

export default class CoreDropdownSearchComponent extends Component<CoreDropdownSearchComponentArgs> {
  @argDefault variant = 'full-width';

  get icon() {
    return this.args.icon;
  }

  @action
  handleKeyup(e: KeyboardEvent) {
    const inputElement = e.target as HTMLInputElement;

    if (e.key === 'Enter' && !this.args.activeItemEl && this.args.onCreate) {
      this.args.onCreate(inputElement.value);
      inputElement.value = '';
    }
  }

  guidFor(el?: HTMLElement) {
    if (!el) return '';

    if (!el.id) {
      el.id = guidFor(el);
    }

    return el.id;
  }
}
