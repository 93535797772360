/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import AppRefresher from 'teamtailor/services/app-refresher';
import FlipperService from 'teamtailor/services/flipper';
import Current from 'teamtailor/services/current';

interface EmployeeDashboardJobViewerListItemArgs {
  Args: {
    job: {
      locations: {
        nameOrCity: string;
      }[];
    };
  };
}

export default class EmployeeDashboardJobViewerListItemComponent extends Component<EmployeeDashboardJobViewerListItemArgs> {
  @service declare appRefresher: AppRefresher;
  @service declare flipper: FlipperService;
  @service declare current: Current;

  jobLinkParams = `?utm_source=${encodeURIComponent('Employee dashboard')}`;

  get otherLocations() {
    const allLocations = get(this.args.job, 'locations');
    if (allLocations.length <= 1) {
      return undefined;
    }

    return allLocations.map((l) => l.nameOrCity).splice(1);
  }

  get showStageColumns() {
    return this.current.company.employeeDashboardRecruitmentProgress;
  }
}
