/* import __COLOCATED_TEMPLATE__ from './role.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EmployeeBulkArgs } from 'teamtailor/constants/employee';
import AccessLevels from 'teamtailor/services/access-levels';
import { inject as service } from '@ember/service';
import FlipperService from 'teamtailor/services/flipper';

export default class BulkActionsEmployeesRoleComponent extends Component<EmployeeBulkArgs> {
  @service declare flipper: FlipperService;
  @tracked role = '';
  @tracked declare accessLevelIds: (string | undefined)[];
  @service declare accessLevels: AccessLevels;

  @action
  onClose(): void {
    this.args.onClose();
    this.role = '';
  }

  @action
  onChangeRole(value: string, addons: string[]): void {
    this.role = value;
    this.accessLevelIds = this.accessLevels.accessLevelIdsWithDefault(
      value,
      addons
    );
  }

  @action
  handleBulkChange(): void {
    const data = {
      role: this.role,
      accessLevelIds: this.accessLevelIds,
    };
    this.args.handleBulkChange(data, 'bulk_role');
    this.onClose();
  }
}
