/* import __COLOCATED_TEMPLATE__ from './block-editor-item-icon.hbs'; */
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import {
  sectionIcon,
  TYPE_ICON_MAPPING,
} from 'teamtailor/helpers/section-icon';

interface Args {
  type: keyof typeof TYPE_ICON_MAPPING;
}

export default class BlockEditorItemIconComponent extends Component<Args> {
  @service declare current: Current;

  get languageCode() {
    return this.current.locale;
  }

  get iconName() {
    return sectionIcon(this.type).name;
  }

  get iconStyle() {
    return sectionIcon(this.type).style;
  }

  get mirror() {
    return sectionIcon(this.type).mirror;
  }

  get type() {
    return this.args.type;
  }
}
