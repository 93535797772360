import EditController from 'teamtailor/controllers/employees/profile/edit';
import { set, get, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { modifier } from 'ember-modifier';

export default class MeetingsSettings extends EditController {
  @service current;
  @service user;
  @service store;
  @service flashMessages;

  @tracked elementToken = null;
  @tracked earliestStartTime = '08:00';
  @tracked latestEndTime = '18:00';
  @tracked hasError = false;
  @tracked cronofyRulesSavedCallback = null;
  @tracked availabilityRules = null;

  connectedConferences = null;

  get disableDailyLimit() {
    return !this.changeset.meetingsHasDailyLimit;
  }

  get disableWeeklyLimit() {
    return !this.changeset.meetingsHasWeeklyLimit;
  }

  get isSameUser() {
    return get(this.user, 'id') === this.employee.id;
  }

  @action
  loadConnectedConferences() {
    this.getConnectedConferencesList.perform();
  }

  fetchAvailabilityRulesTask = restartableTask(async () => {
    await get(this, 'user.authorizations');
    let cronofyAuthorization = get(this, 'user.cronofyAuthorization');
    this.hasError = false;

    if (cronofyAuthorization) {
      try {
        const availabilityRules =
          await cronofyAuthorization.fetchAvailabilitySettings();

        const { weekly_periods } = availabilityRules;

        const startTimeHours = weekly_periods.map((a) =>
          Number(a.start_time.split(':')[0])
        );
        const endTimeHours = weekly_periods.map((a) =>
          Number(a.end_time.split(':')[0])
        );

        const earliestStartHour = Math.min(...startTimeHours, 8);
        const latestEndHour = Math.max(...endTimeHours, 18);

        this.earliestStartTime = `${earliestStartHour}:00`;
        this.latestEndTime = `${latestEndHour}:00`;

        this.availabilityRules = availabilityRules;
      } catch {
        this.hasError = true;
      }
    }
  });

  getConnectedConferencesList = restartableTask(async () => {
    get(this, 'store').unloadAll('connected-conference');

    await get(this, 'store')
      .query('connected-conference', {
        only_connected: false,
      })
      .then((connectedConferencesResponse) => {
        set(this, 'connectedConferences', connectedConferencesResponse);
      });
  });

  @action
  onCalendarSuccess(data) {
    this.hasError = false;
    let authorization = this.store.createRecord(
      'authorization',
      Object.assign(data.authorization, { user: get(this, 'user.content') })
    );

    return authorization
      .save()
      .then(() => {
        this.flashMessages.success(
          this.intl.t('employees.edit.calendar_connected')
        );
        this.intercom.trackEvent('connected-calendar');

        authorization.upsertDefaultAvailabilityRule({
          time_zone: moment.tz.guess(),
        });
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      });
  }

  @action
  onCalendarFailure() {
    this.flashMessages.error(
      this.intl.t('employees.edit.something_went_wrong')
    );
  }

  @action
  onConnectedConferenceSuccess() {
    this.flashMessages.success(
      this.intl.t('employees.edit.conference_connected')
    );
    this.getConnectedConferencesList.perform();
  }

  @action
  onConnectedConferenceFailure() {
    this.flashMessages.error(
      this.intl.t('employees.edit.something_went_wrong')
    );
  }

  @action
  deleteAuthorization(auth) {
    this.ttAlert.confirm(
      this.intl.t('employees.edit.delete_auth_confirm'),
      () => {
        auth.destroyRecord().then(() => {
          this.store.unloadAll('connected-calendar');
          this.store.unloadAll('connected-conference');
          this.store.unloadAll('calendar-option');
          this.hasError = false;
        });
        this.elementToken = null;
      },
      () => {}
    );
  }

  fetchAvailabilityRules = modifier(() => {
    this.fetchAvailabilityRulesTask.perform();
  });

  @action
  handleConnectionError(hasError) {
    this.hasError = hasError;
  }

  @action
  toggleDailyMeetings(checked) {
    this.changeset.meetingsHasDailyLimit = checked;
  }

  @action
  toggleWeeklyMeetings(checked) {
    this.changeset.meetingsHasWeeklyLimit = checked;
  }

  @action
  handleSave() {
    const saveCronofy = get(
      this.user,
      'cronofyAuthorization'
    ).updateAvailabilityRule(this.availabilityRules);

    const saveEmployee = new Promise((resolve) => {
      this.saveUser();
      resolve();
    });

    const minTime = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 500);
    });

    return Promise.all([saveCronofy, saveEmployee, minTime]);
  }

  @action
  updateRules(rules) {
    this.availabilityRules = rules;
  }
}
