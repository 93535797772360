/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  BookedSlotClass,
  MeetingEventClass,
} from 'teamtailor/classes/meetings';
import { SelfScheduleClass } from 'teamtailor/classes/meetings/self-schedule';

interface Args {
  event: MeetingEventClass;
}

export default class MeetingEventRowsDate extends Component<Args> {
  get event(): MeetingEventClass | undefined {
    return this.args.event;
  }

  get isSelfSchedule(): boolean {
    return this.selfSchedule !== undefined;
  }

  get bookedSlot(): BookedSlotClass | undefined {
    return this.event?.bookedSlot;
  }

  get selfSchedule(): SelfScheduleClass | undefined {
    return this.event?.selfSchedule;
  }

  get organizerHasCronofy(): boolean | undefined {
    return this.event?.organizer?.hasConnectedCalendar;
  }

  get showSelfSchedule(): boolean | undefined {
    return (
      !!(this.selfSchedule || !this.bookedSlot) && this.organizerHasCronofy
    );
  }

  get showManualTime(): boolean {
    return !!(this.bookedSlot || (!this.selfSchedule && !this.bookedSlot));
  }

  @action
  resetTimeAndDate(): void {
    this.event!.selfSchedule = undefined;
    this.event!.bookedSlot = undefined;
  }
}
