/* import __COLOCATED_TEMPLATE__ from './job-info.hbs'; */
import Component from '@glimmer/component';
import JobApplicationModel from 'teamtailor/models/job-application';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';

interface Args {
  jobApplication: JobApplicationModel;
}

export default class CandidateModalNewMainJobInfoComponent extends Component<Args> {
  @service declare current: Current;
  @service declare intl: IntlService;

  get job() {
    return get(this.args.jobApplication, 'job');
  }

  get jobApplication() {
    return this.args.jobApplication;
  }

  get careerSite() {
    return get(this.job, 'careerSite');
  }

  get displayCareerSiteInfo() {
    return (
      this.current.company.hasFeature('career_site_languages') &&
      this.careerSite
    );
  }

  get displayReferrer() {
    const { jobApplication } = this.args;
    return (
      !!jobApplication.referringSite ||
      (jobApplication.isFromPromotion && get(this.job, 'userHasAccess'))
    );
  }

  get referrer() {
    const { jobApplication } = this.args;

    if (jobApplication.isFromLeadPage) {
      return this.intl.t('candidates.candidate.job_application.page');
    } else if (
      jobApplication.isFromPromotion &&
      get(this.job, 'userHasAccess')
    ) {
      const promotion = get(jobApplication, 'promotion');

      if (!get(promotion, 'id')) {
        return this.intl.t('components.activity_item.a_deleted_promotion');
      }

      if (get(promotion, 'isShareLinkOrQrCode')) {
        const key = get(promotion, 'isShareLink')
          ? 'candidates.candidate.job_application.trackable_share_link'
          : 'candidates.candidate.job_application.qr_code';
        return this.intl.t(key);
      }

      return get(get(promotion, 'channel'), 'name');
    } else {
      return jobApplication.referringSiteName;
    }
  }

  get appliedOrSourcedText() {
    const { jobApplication } = this.args;
    const sourced = !!jobApplication.sourcedAt;

    const translationArgs: {
      date: string;
      localizedSite?: string;
      referrer?: string;
      location?: string;
    } = {
      date: moment(jobApplication.createdAt).format('ll'),
    };

    if (sourced) {
      return this.intl.t('candidate.sourced_on', translationArgs);
    }

    const { fromHandheld } = jobApplication;

    const keyParts = ['applied'];

    if (fromHandheld) {
      keyParts.push('with_mobile');
    }

    if (this.displayCareerSiteInfo) {
      keyParts.push('through');
      translationArgs.localizedSite = `${this.careerSite?.emoji} ${get(
        this.careerSite,
        'translatedLanguage'
      )}`;
    }

    if (this.displayReferrer) {
      keyParts.push('via');
      translationArgs.referrer = this.referrer;
    }

    if (this.locationsToShow.length > 0) {
      keyParts.push('in');
      translationArgs.location = this.locationsToShow.join(', ');
    }

    return this.intl.t(`candidate.${keyParts.join('_')}`, translationArgs);
  }

  get allLocations() {
    return this.jobApplication.locations || [];
  }

  get locationsToShow() {
    return this.allLocations.map((location) => {
      return get(location, 'nameOrCity');
    });
  }
}
