/* import __COLOCATED_TEMPLATE__ from './survey-badge.hbs'; */
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import HireQualityResponseModel from 'teamtailor/models/hire-quality-response';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

type Args = {
  hireQualityResponse: HireQualityResponseModel;
};

export default class HireQualitySurveyBadgeComponent extends Component<Args> {
  @service declare current: Current;
  @service declare intl: IntlService;

  get locale(): string {
    return this.current.locale;
  }

  get response() {
    return this.args.hireQualityResponse.recommended;
  }

  get responseText(): string | undefined {
    if (this.response === true) {
      return this.intl.t('common.yes');
    } else if (this.response === false) {
      return this.intl.t('common.no');
    }
  }

  get responseBadgeColor(): string {
    if (this.response === true) {
      return 'green';
    } else if (this.response === false) {
      return 'red';
    } else {
      return 'amber';
    }
  }

  get timeLeft(): string {
    moment.locale(this.locale);
    const { scheduledAt } = this.args.hireQualityResponse;
    if (scheduledAt) {
      const value = moment(scheduledAt)
        .utc()
        .set('hour', 6) // eslint-disable-line ember/use-ember-get-and-set
        .set('minute', 0) // eslint-disable-line ember/use-ember-get-and-set
        .fromNow();
      return `${this.intl.t(
        'components.candidate_modal.hire_quality_surveys.will_be_sent'
      )} ${value}`;
    }

    return '';
  }
}
