/* import __COLOCATED_TEMPLATE__ from './deadline-modal.hbs'; */
import { action, get, set } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class StageListSettingsDeadlineModal extends Component {
  @service flashMessages;
  @service intl;
  @service intercom;

  @argDefault saveOnButtonClick = true;

  @tracked
  selectedDate = this.args.stage.milestone;

  @tracked
  dirty = false;

  @action
  milestoneSelected(date) {
    this.selectedDate = date;
    this.dirty = true;
  }

  @action
  async removeMilestoneClicked() {
    set(this.args, 'stage.milestone', null);

    if (this.saveOnButtonClick) {
      await this.save();
    }
  }

  @action
  async doneClicked() {
    set(this.args, 'stage.milestone', this.selectedDate);

    if (this.saveOnButtonClick) {
      await this.save();
    }
  }

  async save() {
    const changedAttributes = this.args.stage.changedAttributes();
    const addedNewMilestone = !changedAttributes.milestone[0];

    return this.args.stage
      .save()
      .then(() => {
        this.flashMessages.success(
          isEmpty(get(this.args, 'stage.milestone'))
            ? this.intl.t('components.stage_list.deadline_removed')
            : this.intl.t('components.stage_list.deadline_saved')
        );
        const overdue = get(this.args, 'stage.job.overdueJobApplication');
        if (overdue.isFulfilled) {
          overdue.reload();
        }

        if (addedNewMilestone) {
          this.intercom.trackEvent('added-stage-milestone');
        }
      })
      .catch(() => {
        this.flashMessages.error(
          this.intl.t('components.stage_list.couldnt_save_deadline')
        );
        this.args.stage.rollbackAttributes();
      });
  }
}
