/* import __COLOCATED_TEMPLATE__ from './activated-channels.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ChannelModel from 'teamtailor/models/channel';
import JobModel from 'teamtailor/models/job';
import {
  DEFAULT_COUNTRY,
  getAvailableCountries,
} from 'teamtailor/utils/channels';
import { get } from 'teamtailor/utils/get';

export interface ActivatedChannelsComponentProps {
  channels: ChannelModel[];
  job: JobModel;
  jobCountry: string | undefined;
}

export default class ActivatedChannelsComponent extends Component<ActivatedChannelsComponentProps> {
  @tracked _selectedCountry?: string /* = this.args.jobCountry*/;

  get selectedCountry() {
    return this._selectedCountry || this.args.jobCountry;
  }

  set selectedCountry(val) {
    this._selectedCountry = val;
  }

  @action
  onCountryChange(country: { id: string; label: string }) {
    this.selectedCountry = country.id;
  }

  get availableCountries() {
    return getAvailableCountries(this.args.channels, this.args.jobCountry);
  }

  get filteredChannelList() {
    return this.args.channels.filter((channel) => {
      if (!this.selectedCountry || this.selectedCountry === DEFAULT_COUNTRY)
        return true;
      ['Global', this.selectedCountry];

      return get(channel, 'countries').find((country) =>
        [this.selectedCountry, 'Global'].includes(country)
      );
    });
  }
}
