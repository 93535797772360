import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import PermissionsService from 'teamtailor/services/permissions';
import { get } from 'teamtailor/utils/get';

type Page = {
  route: string;
  name: string;
  show: boolean;
};

export type Section = {
  name: string;
  show: boolean;
  icon: string;
  pages: Page[];
};

export type SearchResult = {
  [name: string]: { icon: string; pages: Page[] };
};

export type CandidateSharingCounts = {
  trigger_count: number;
  share_link_count: number;
};

export function getCompanyPages(
  intl: IntlService,
  permissions: PermissionsService,
  current: Current
): Section {
  return {
    name: intl.t('settings.company'),
    show: permissions.has('settings/update_company'),
    icon: 'building-user',
    pages: [
      {
        route: 'settings.general',
        name: intl.t('settings.general.title'),
        show: true,
      },
      {
        route: 'settings.organization',
        name: intl.t('settings.organization.title'),
        show: true,
      },
      {
        route: 'settings.locations',
        name: intl.t('settings.locations.title'),
        show: true,
      },
      {
        route: 'settings.payments',
        name: intl.t('settings.payments.title'),
        show: true,
      },
      {
        route: 'settings.auto-join',
        name: intl.t('settings.auto_join.title'),
        show: true,
      },
      {
        route: 'settings.security',
        name: intl.t('settings.security.title'),
        show: true,
      },
      {
        route: 'settings.sms',
        name: intl.t('settings.sms.title'),
        show: current.company.customer,
      },
    ],
  };
}

export function getTemplatePages(
  intl: IntlService,
  permissions: PermissionsService,
  current: Current
): Section {
  return {
    name: intl.t('settings.templates'),
    show: true,
    icon: 'files',
    pages: [
      {
        route: 'settings.user-template',
        name: intl.t('settings.user_template.menu_title'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.canned_responses',
        name: intl.t('settings.canned_responses.menu_title'),
        show: permissions.has('settings/update_recruitment'),
      },
      {
        route: 'settings.templates',
        name: intl.t('settings.job_templates.menu_title'),
        show: permissions.has('settings/update_recruitment'),
      },
      {
        route: 'settings.todo-templates',
        name: intl.t('settings.todo_templates.menu_title'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.job-offer-templates',
        name: intl.t('settings.job_offer_templates.menu_title'),
        show: current.company.hasFeature('job_offers'),
      },

      {
        route: 'settings.reference-templates',
        name: intl.t('settings.reference_templates.menu_title'),
        show: current.company.hasFeature('references'),
      },
    ],
  };
}

export function getRecruitmentPages(
  intl: IntlService,
  permissions: PermissionsService,
  current: Current,
  flipper: FlipperService
): Section {
  return {
    name: intl.t('settings.recruitment'),
    show: permissions.has('settings/update_recruitment'),
    icon: 'user-magnifying-glass',
    pages: [
      {
        route: 'settings.teams',
        name: intl.t('settings.teams.title'),
        show: true,
      },
      {
        route: 'settings.requisitions',
        name: intl.t('settings.requisitions.title'),
        show: current.company.hasFeature('requisitions'),
      },
      {
        route: 'settings.requisition_archive_reasons',
        name: intl.t('settings.requisition_archive_reasons.title'),
        show: current.company.hasFeature('requisitions'),
      },
      {
        route: 'settings.recruiter-copilot',
        name: intl.t('settings.recruiter_copilot.title'),
        show: current.company.hasFeature('recruiter_copilot'),
      },
      {
        route: 'settings.connect',
        name: intl.t('settings.connect.title'),
        show:
          permissions.has('settings/update_company') &&
          !get(flipper, 'connect_content'),
      },
      {
        route: 'settings.questions',
        name: intl.t('settings.questions.title'),
        show: true,
      },
      {
        route: 'settings.ratings',
        name: intl.t('settings.ratings.title'),
        show: true,
      },
      {
        route: 'settings.custom-fields',
        name: intl.t('settings.custom_fields.title'),
        show: true,
      },
      {
        route: 'settings.tags',
        name: intl.t('settings.tag_manager.title'),
        show: true,
      },
      {
        route: 'settings.reject_reasons',
        name: intl.t('settings.reject_reasons.title'),
        show: true,
      },
      {
        route: 'settings.approval_settings',
        name: intl.t('settings.approval_settings.title'),
        show: current.company.hasApprovals && current.user.admin,
      },
      {
        route: 'settings.interview-kits',
        name: intl.t('settings.interview_kits.title'),
        show: current.company.hasFeature('interview_kit'),
      },
      {
        route: 'settings.skills-traits',
        name: intl.t('settings.skills_traits.title'),
        show: true,
      },
      {
        route: 'settings.meeting-rooms',
        name: intl.t('settings.meeting_rooms.title'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.enterprise-calendar',
        name: intl.t('settings.enterprise_calendar.title'),
        show:
          permissions.has('settings/update_company') &&
          current.company.enterpriseCalendarEnabled,
      },
      {
        route: 'settings.external-recruitment',
        name: intl.t('settings.external_recruitment.title'),
        show: current.company.hasFeature('external_recruitment'),
      },
      {
        route: 'settings.apply_with',
        name: intl.t('settings.apply_with.title'),
        show: true,
      },
      {
        route: 'settings.job-offer-approval-flows',
        name: intl.t('settings.job_offer_approval_flows.title'),
        show: current.company.hasFeature('job_offer_approvals'),
      },
    ],
  };
}

export function getContentCareerSitePages(
  intl: IntlService,
  permissions: PermissionsService,
  current: Current
): Section {
  return {
    name: intl.t('settings.content_career_site'),
    show: permissions.has('settings/update_company'),
    icon: 'folder-grid',
    pages: [
      {
        route: 'settings.domain',
        name: intl.t('settings.domain.title'),
        show: true,
      },
      {
        route: 'settings.language',
        name: intl.t('settings.language.title'),
        show: true,
      },
      {
        route: 'settings.chat',
        name: intl.t('settings.chat.candidate_interaction'),
        show: current.company.hasFeature('candidate_chat'),
      },
      {
        route: 'settings.media-library',
        name: intl.t('settings.image_library.title'),
        show: true,
      },
      {
        route: 'settings.group-site',
        name: intl.t('settings.group_site.title'),
        show: current.company.groupSite,
      },
    ],
  };
}

export function getDataAndPrivacyPages(
  intl: IntlService,
  permissions: PermissionsService,
  current: Current,
  flipper: FlipperService
): Section {
  return {
    name: intl.t('settings.data_and_privacy'),
    show: permissions.has('settings/update_company'),
    icon: 'shield-check',
    pages: [
      {
        route: 'settings.consent',
        name: intl.t('settings.consent.collect_permissions'),
        show: true,
      },
      {
        route: 'settings.data_privacy_settings',
        name: intl.t('settings.data_privacy.title'),
        show: true,
      },
      {
        route: 'settings.data-requests',
        name: intl.t('settings.data_requests.nav_title'),
        show: get(flipper, 'manual_data_requests'),
      },
      {
        route: 'settings.automatic-deletion',
        name: intl.t('settings.automatic_deletion.title'),
        show: true,
      },
      {
        route: 'settings.policy',
        name: intl.t('settings.privacy_policy.title'),
        show: true,
      },
      {
        route: 'settings.cookies',
        name: intl.t('settings.cookies.title'),
        show: true,
      },
      {
        route: 'settings.audit-log',
        name: intl.t('settings.audit_log.title'),
        show: current.company.hasFeature('audit_log'),
      },
      {
        route: 'settings.share-links',
        name: intl.t('settings.share_links.title'),
        show: !get(flipper, 'disable_candidate_sharing'),
      },
      {
        route: 'settings.eeo-reporting',
        name: intl.t('settings.eeo.title'),
        show: get(current.company.eeoReportSetting, 'enabled'),
      },
    ],
  };
}

export function getIntegrationsPages(
  intl: IntlService,
  permissions: PermissionsService,
  current: Current,
  flipper: FlipperService
): Section {
  return {
    name: intl.t('settings.integrations.title'),
    show: true,
    icon: 'gear-code',
    pages: [
      {
        route: 'settings.integrations.browser-extension',
        name: intl.t('settings.integrations.browser_extension.title'),
        show: true,
      },
      {
        route: 'settings.integrations.api_keys',
        name: intl.t('settings.integrations.api_keys.title'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.integrations.widgets',
        name: intl.t('settings.integrations.widget.title'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.facebook-pixel',
        name: intl.t('settings.facebook_pixel.title'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.google',
        name: intl.t('settings.google.title'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.integrations.slack_apps',
        name: intl.t('settings.integrations.slack.integration'),
        show: permissions.has('settings/update_company'),
      },
      {
        route: 'settings.linkedin_rsc',
        name: intl.t('settings.linkedin_rsc.title'),
        show:
          permissions.has('settings/update_recruitment') &&
          current.company.linkedinRscIntegration,
      },
      {
        route: 'settings.partner_hub',
        name: intl.t('settings.partner_hub.title'),
        show:
          permissions.has('settings/update_recruitment') &&
          current.company.sandbox,
      },
      {
        route: 'settings.webhooks',
        name: intl.t('settings.webhooks.title'),
        show:
          permissions.has('settings/update_recruitment') &&
          get(flipper, 'webhooks'),
      },
      {
        route: 'settings.integrations.marketplace-activations',
        name: intl.t('settings.integrations.marketplace_activations.title'),

        show: permissions.has('settings/update_recruitment'),
      },
    ],
  };
}
