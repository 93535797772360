/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

interface CoreModalFooterArgs {
  isLoading?: boolean;
  showOnLoad?: boolean;
}

export default class CoreModalFooter extends Component<CoreModalFooterArgs> {
  @argDefault showOnLoad = true;
}
