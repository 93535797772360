/* import __COLOCATED_TEMPLATE__ from './picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import hexToRgb from 'teamtailor/utils/hex-to-rgb';
import imageUrl from 'teamtailor/utils/image-url';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class MediaLibraryPickerComponent extends Component {
  @tracked isPicking = false;

  @argDefault isPreviewEnabled = true;

  get previewUrl() {
    return imageUrl(this.args.imageUrl, 'media_preview');
  }

  get fontStyle() {
    const styles = `color: ${this.args.previewTextColor};`;
    return htmlSafe(`${styles}`.trim());
  }

  get previewStyles() {
    let styles = [];

    if (
      this.previewUrl ||
      (this.args.overlayColor && this.args.overlayOpacity)
    ) {
      let backgroundImage = 'background-image:';

      if (this.args.overlayColor && this.args.overlayOpacity) {
        let rgba = hexToRgb(
          this.args.overlayColor,
          this.args.overlayOpacity / 100
        );
        backgroundImage += `linear-gradient(${rgba},${rgba}),`;
      }

      if (this.previewUrl) {
        backgroundImage += ` url(${this.previewUrl})`;

        if (this.args.previewBackgroundColor) {
          styles.push(`background-color: ${this.args.previewBackgroundColor}`);
        }
      }

      styles.push(backgroundImage);
    }

    styles.push(`background-size: ${this.args.backgroundSize || 'cover'}`);

    return htmlSafe(styles.join(';').trim());
  }

  @action
  handlePickImage(image) {
    this.args.onPickImage(image);
    this.isPicking = false;
  }

  @action
  handleRemoveImage(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.args.onRemoveImage();
  }

  @action
  handleCloseModal() {
    this.isPicking = false;
    if (this.args.onModalClose) {
      this.args.onModalClose();
    }
  }

  @action
  handleOpenPicker(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.isPicking = true;
    if (this.args.onOpenPicker) {
      this.args.onOpenPicker();
    }
  }
}
