/* import __COLOCATED_TEMPLATE__ from './item-row.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class InfographicItemRowComponent extends Component {
  @tracked colorPickerOpen = false;
  @tracked colorChanged = false;

  @action
  toggleColorPicker() {
    this.colorPickerOpen = !this.colorPickerOpen;

    if (!this.colorPickerOpen && this.colorChanged) {
      this.args.onChange();
      this.colorChanged = false;
    }
  }

  @action
  colorChange(color) {
    this.args.item.color = color;
    this.colorChanged = true;
  }

  @action
  async onEnter(event) {
    if (isEmpty(this.args.item.label) && isEmpty(this.args.item.value)) {
      return;
    }

    if (this.args.onEnter) {
      await this.args.onEnter();
    }

    event.target.parentElement.querySelector('#label')?.focus();
  }
}
