/* import __COLOCATED_TEMPLATE__ from './structure-selector.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { DepartmentModel, DivisionModel, RoleModel } from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import { NO_DIVISION_ID } from 'teamtailor/models/division';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import { action } from '@ember/object';

type OrganizationStructureSelectorArgs = {
  division: DivisionModel | null;
  department: DepartmentModel | null;
  role: RoleModel | null;
  setDivision: (division: DivisionModel | null) => void;
  setDepartment: (department: DepartmentModel | null) => void;
  setRole: (role: RoleModel | null) => void;
  hideNoDivision?: boolean;
  hideNoDepartment?: boolean;
};

export default class OrganizationStructureSelector extends Component<OrganizationStructureSelectorArgs> {
  @service declare current: Current;

  get hideNoDivision() {
    return !!this.args.hideNoDivision;
  }

  get hideNoDepartment() {
    return !!this.args.hideNoDepartment;
  }

  get divisions() {
    return this.hideNoDivision
      ? get(this.current.company, 'divisionsWithoutNoDivision')
      : get(this.current.company, 'divisions');
  }

  get departments() {
    let divisionId = get(this.args.division, 'id') ?? null;
    let departments = this.hideNoDepartment
      ? get(this.current.company, 'departmentsWithoutNoDepartment')
      : get(this.current.company, 'departments');

    if (this.divisions.length === 0 || !divisionId) {
      return departments;
    }

    if (divisionId === NO_DIVISION_ID) {
      divisionId = null;
    }

    return departments.filter(
      (department) =>
        get(get(department, 'division'), 'id') === divisionId ||
        department.id === NO_DEPARTMENT_ID
    );
  }

  get roles() {
    return get(this.args.department, 'roles');
  }

  @action
  handleDivisionChange(division: DivisionModel | null) {
    this.args.setDivision(division);
    this.args.setDepartment(null);
    this.args.setRole(null);
  }

  @action
  handleDepartmentChange(department: DepartmentModel | null) {
    this.args.setDepartment(department);
    this.args.setRole(null);
  }

  @action
  handleRoleChange(role: RoleModel | null) {
    this.args.setRole(role);
  }
}
