/* import __COLOCATED_TEMPLATE__ from './score-tag.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';

const DEFAULT_SIZE = 'small';
const ALLOWED_SIZES = [DEFAULT_SIZE, 'medium'] as const;

type ScorecardResultsScoreTagArgs = {
  size?: string;
  scorecardPick?: ScorecardPickModel;
};

export default class ScorecardResultsScoreTag extends Component<ScorecardResultsScoreTagArgs> {
  @tracked showTooltip = false;

  get size() {
    verifyArg(
      this.args.size,
      ALLOWED_SIZES,
      'ScorecardResultsScoreTag @size',
      DEFAULT_SIZE
    );

    return this.args.size;
  }

  @action
  handleEllipsis(e: HTMLSpanElement) {
    this.showTooltip = e.offsetWidth < e.scrollWidth;
  }
}
