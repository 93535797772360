/* import __COLOCATED_TEMPLATE__ from './block-editor-item-menu.hbs'; */
import Component from '@glimmer/component';
import { BlockLayoutModel, SectionModel } from 'teamtailor/models';

type BlockEditorItemMenuArgs = {
  allBlockLayouts: BlockLayoutModel[];
  section?: SectionModel;
};

export default class BlockEditorItemMenuComponent extends Component<BlockEditorItemMenuArgs> {
  get blockLayouts() {
    return this.args.section
      ? this.args.allBlockLayouts
          .filter((blockLayout) => {
            return blockLayout.blockName === this.args.section?.name;
          })
          .toSorted((a, b) => a.displayName.localeCompare(b.displayName))
      : [];
  }
}
