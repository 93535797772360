/* import __COLOCATED_TEMPLATE__ from './batch-job.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { singularize } from 'ember-inflector';
import { keepLatestTask, restartableTask, timeout } from 'ember-concurrency';
import { action, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import * as Sentry from '@sentry/ember';
import { tracked } from '@glimmer/tracking';

export default class BatchJobComponent extends Component {
  @service server;
  @service intl;
  @service current;
  @service batchJob;

  @tracked currentBatchJob = null;

  requestTimeout = 1000;
  showOnBegginingTimeout = 5000;
  batchJobBannerTimeout = 3000;
  _isOpened = false;

  get itemsCount() {
    return this.args.model.totalCount || this.currentBatchJob?.total;
  }

  get isOpened() {
    return this.args.model.isOpened || this._isOpened;
  }

  get batchJobIsHidden() {
    return this.args.model.isHidden;
  }

  @tracked sidekiqError = false;

  constructor(owner, args) {
    super(owner, args);

    this.requestTask.perform(true);
  }

  get isParentJob() {
    return get(this, 'args.model.isParentJob');
  }

  get pendingCount() {
    if (this.currentBatchJob?.total) {
      if (this.isParentJob) {
        return (
          get(this, 'args.model.totalCount') -
          get(this, 'currentBatchJob.processed')
        );
      } else {
        return get(this, 'currentBatchJob.pending');
      }
    }

    return undefined;
  }

  get requestUrl() {
    let url;

    if (this.isParentJob) {
      url = [];
      get(this, 'args.model.subBatchJobs').forEach((batchJob) => {
        if (get(batchJob, 'bid')) {
          url.push(`/batch_status/${get(batchJob, 'bid')}.json`);
        }
      });
    } else if (get(this, 'args.model.bid')) {
      url = `/batch_status/${get(this, 'args.model.bid')}.json`;
    }

    return url;
  }

  get statusPercentage() {
    const pending = this.pendingCount;
    const itemsCount = get(this, 'itemsCount');

    if (pending === undefined || !itemsCount) {
      return 0;
    }

    if (pending === 0 || get(this, 'currentBatchJob.isComplete')) {
      if (!get(this.args.model, 'isHidden')) this.hideAfterComplete();
      return 100;
    }

    let value = 100 - (pending / itemsCount) * 100;
    if (value > 99.0) {
      value = 99;
    }

    return Math.ceil(value);
  }

  get isCurrentUsersBatchJob() {
    return get(this, 'args.currentUser.id') === get(this, 'args.model.user.id');
  }

  get subjectText() {
    const total = get(this, 'itemsCount') || '';
    let subject = '';

    switch (get(this, 'args.model.batchType')) {
      case 'BulkTags':
        subject = this.intl.t('components.batch_job.tags');
        break;
      case 'BulkEmployee':
        subject = this.intl.t('components.batch_job.employees');
        break;
      case 'BulkJob':
        subject = this.intl.t('components.batch_job.jobs');
        break;
      case 'BulkRequisition':
        subject = this.intl.t('components.batch_job.requisitions');
        break;
      case 'BulkScorecardCriteria':
        subject = this.intl.t('components.batch_job.scorecard_criteria', {
          count: total,
        });
        break;
      case 'BulkCandidate':
        subject = this.intl.t('components.batch_job.candidates');
        break;
      default:
        subject = this.intl.t('components.batch_job.records');
    }

    if (total === 1) {
      subject = singularize(subject);
    }

    return subject;
  }

  get pendingText() {
    const total = get(this, 'itemsCount') || '';
    const subject = this.subjectText;
    const batchAction = get(this, 'args.model.batchAction');
    const company = get(this, 'args.model.companyName');

    const specialCases = {
      default_language:
        total === ''
          ? 'components.batch_job.default_language_change_started'
          : 'components.batch_job.default_language_changing',

      authorize_users: 'components.batch_job.authorizing_users',
    };

    if (specialCases[batchAction]) {
      return this.intl.t(specialCases[batchAction], {
        count: total,
        subject,
        company,
      });
    }

    const translations = {
      add_to_nurture_campaign:
        'components.batch_job.adding_to_nurture_campaign',

      delete: 'components.batch_job.deleting',
      tag: 'components.batch_job.tagging',
      assign: 'components.batch_job.assigning',
      merge: 'components.batch_job.merging',
      move: 'components.batch_job.moving',
      move_to_stage: 'components.batch_job.moving',
      reject: 'components.batch_job.rejecting',
      message: 'components.batch_job.messaging',
      share: 'components.batch_job.sharing',
      export: 'components.batch_job.exporting',
      initial_consent: 'components.batch_job.sending_data_retention_requests',
      expired_consent: 'components.batch_job.sending_data_retention_reminders',
      todo: 'components.batch_job.added_todos',
      webhook: 'components.batch_job.sending_webhook',
      smart_schedule_event: 'components.batch_job.sending_meeting_invites',
      multi_candidate_event: 'components.batch_job.scheduling_meeting',
      form_invite: 'components.batch_job.sending_survey_invite',
      restrict: 'components.batch_job.restricting',
      archive: 'components.batch_job.archiving_requisitions',
      location: 'components.batch_job.changing_location',
      role: 'components.batch_job.changing_role',
      department: 'components.batch_job.changing_department',
      status: 'components.batch_job.changing_status',
      recruiter: 'components.batch_job.changing_recruiter',
      remote_status: 'components.batch_job.changing_remote_status',
      department_role: 'components.batch_job.changing_department_and_role',
      new_hire_share_page_status:
        'components.batch_job.changing_new_hire_share_page_status',
    };

    return this.intl.t(translations[batchAction], { count: total, subject });
  }

  get doneText() {
    const total = get(this, 'itemsCount');
    const subject = this.subjectText;
    const batchAction = get(this, 'args.model.batchAction');
    const company = get(this, 'args.model.companyName');

    const specialCases = {
      authorize_users: 'components.batch_job.authorized_users',
    };

    if (specialCases[batchAction]) {
      return this.intl.t(specialCases[batchAction], {
        count: total,
        subject,
        company,
      });
    }

    const translations = {
      add_to_nurture_campaign: 'components.batch_job.added_to_nurture_campaign',
      delete: 'components.batch_job.deleted',
      tag: 'components.batch_job.tagged',
      assign: 'components.batch_job.assigned',
      merge: 'components.batch_job.merged',
      move: 'components.batch_job.moved',
      move_to_stage: 'components.batch_job.moved',
      reject: 'components.batch_job.rejected',
      message: 'components.batch_job.messaged',
      share: 'components.batch_job.shared',
      export: 'components.batch_job.exported',
      initial_consent: 'components.batch_job.sent_data_retention_requests',
      expired_consent: 'components.batch_job.sent_data_retention_reminders',
      todo: 'components.batch_job.added_todos_on',
      webhook: 'components.batch_job.sent_webhook',
      smart_schedule_event: 'components.batch_job.sent_meeting_invites',
      multi_candidate_event: 'components.batch_job.scheduled_meeting',
      form_invite: 'components.batch_job.sent_survey_invite',
      restrict: 'components.batch_job.restricted',
      default_language: 'components.batch_job.default_language_changed',
      department: 'components.batch_job.department',
      location: 'components.batch_job.location',
      role: 'components.batch_job.role',
      recruiter: 'components.batch_job.recruiter',
      remote_status: 'components.batch_job.remote_status',
      department_role: 'components.batch_job.changed_department_and_role',
      audience: 'components.batch_job.changed_audience',
      status: 'components.batch_job.changed_status',
      new_hire_share_page_status:
        'components.batch_job.changed_new_hire_share_page_status',

      archive: 'components.batch_job.archived_requisitions',
    };

    return this.intl.t(translations[batchAction], { count: total, subject });
  }

  requestTask = keepLatestTask(async (showOnBeggining) => {
    if (isEmpty(this.requestUrl)) {
      return;
    }

    if (showOnBeggining) {
      this.showOnBegginingTask.perform();
    }

    if (get(this, 'currentBatchJob')) {
      await timeout(get(this, 'requestTimeout'));
    }

    let response;
    if (get(this, 'args.model.isParentJob')) {
      const promises = this.requestUrl.map((url) => this.server.request(url));
      response = await Promise.all(promises).then((responses) => {
        return responses.reduce(
          (acc, response) => {
            acc.total += response.total;
            acc.processed += response.total - response.pending;
            if (response.is_complete === false) {
              acc.is_complete = false;
            }

            return acc;
          },
          { total: 0, processed: 0, is_complete: true }
        );
      });
    } else {
      response = await this.server.request(get(this, 'requestUrl'));
    }

    const { status, total, is_complete } = response;

    if (
      ((is_complete && total === 0) || !is_complete) &&
      status !== 404 &&
      status !== 401
    ) {
      this.requestTask.perform();
    } else if (status === 401) {
      Sentry.captureMessage('Got 401 status when getting batch job status');

      // 401 happens when batch job is stuck and never dissapears (hopefully it doesnt 401 in other cases..)
      this.sidekiqError = true;
    }

    this.currentBatchJob = response;

    return response;
  });

  showOnBegginingTask = restartableTask(async () => {
    set(this, '_isOpened', true);
    await timeout(get(this, 'showOnBegginingTimeout'));
    set(this, '_isOpened', false);
  });

  async hideAfterComplete() {
    await timeout(get(this, 'batchJobBannerTimeout'));
    this.hide();
  }

  @action
  destroyBatchJobRecord() {
    const batchJob = get(this, 'current.company.batchJobs').findBy(
      'bid',
      get(this, 'args.model.bid')
    );

    if (batchJob) {
      batchJob.destroyRecord();
    }
  }

  @action
  async hide() {
    set(this.args.model, 'isHidden', true);
    this.batchJob.hideBatchJobTriggered = true;
    await timeout(1000);
    this.batchJob.hideBatchJobTriggered = false;
  }
}
