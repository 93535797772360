/* import __COLOCATED_TEMPLATE__ from './candidate.hbs'; */
import Component from '@glimmer/component';

import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Current from 'teamtailor/services/current';

type CandidateCellData = {
  candidateRoute?: string;
  value?: any;
};

type CandidateCellArgs = {
  data: CandidateCellData;
};

export default class DataTableCandidateComponent extends Component<CandidateCellArgs> {
  @service declare store: Store;
  @service declare current: Current;

  get candidate() {
    return this.args.data.value;
  }

  get candidateId() {
    return get(this.candidate, 'id') || this.candidate;
  }

  get candidateRoute() {
    return this.args.data.candidateRoute;
  }

  get candidateRecord() {
    return (
      this.fetchCandidateRecordTask.lastSuccessful?.value || this.candidate
    );
  }

  get showCandidatePreview() {
    return (
      this.candidate?.company?.id === this.current.company.id &&
      !this.current.user.hasRole('analytics')
    );
  }

  fetchCandidateRecordTask = dropTask(async () => {
    try {
      await get(this, 'candidateId');
      return await this.store.findRecord('candidate', get(this, 'candidateId'));
    } catch {
      return undefined;
    }
  });

  @action
  onEnter() {
    if (
      this.fetchCandidateRecordTask.performCount === 0 &&
      this.showCandidatePreview
    ) {
      this.fetchCandidateRecordTask.perform();
    }
  }
}
