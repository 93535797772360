/* eslint-disable ember/use-ember-get-and-set */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { BufferedChangeset } from 'ember-changeset/types';
import moment from 'moment-timezone';
import FromRoute from 'teamtailor/routes/settings/security/email-password/2fa-setup';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsSecurityEmailPassword2faDeactivate extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;
  @service declare current: Current;

  @tracked declare changeset: BufferedChangeset;

  @action
  toggleTwoFactorAuthentication(value: boolean): void {
    this.changeset.set(
      'twoFactorAuthenticationEnforcedAt',
      !value ? this.model.twoFactorAuthenticationEnforcedAt : null
    );
  }

  get enforcedAtTranslationPath(): string {
    if (moment().isBefore(this.model.twoFactorAuthenticationEnforcedAt)) {
      return 'settings.security.will_come_into_effect';
    }

    return 'settings.security.came_into_effect';
  }

  get enforceAt(): Date | null {
    return this.changeset.get('twoFactorAuthenticationEnforcedAt');
  }

  @action
  async handleSave(): Promise<void> {
    try {
      await this.changeset.save();
      this.router.transitionTo('settings.security.email-password.2fa-setup');
      this.flashMessages.success(
        this.intl.t('settings.security.alerts.disabled')
      );
      return Promise.resolve();
    } catch (error) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject(error);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security.email-password.2fa-deactivate': SettingsSecurityEmailPassword2faDeactivate;
  }
}
