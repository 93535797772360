import { isEmpty } from '@ember/utils';
import ActiveModelAdapter from 'active-model-adapter';
import config from 'teamtailor/config/environment';
import classic from 'ember-classic-decorator';

@classic
export default class GiphyImage extends ActiveModelAdapter {
  host = 'https://api.giphy.com/v1/gifs';
  rating = 'g';

  urlForQuery(params) {
    return isEmpty(params.q)
      ? this.buildGiphyUrl('trending')
      : this.buildGiphyUrl('search');
  }

  urlForQueryRecord() {
    return this.buildGiphyUrl('random');
  }

  urlForFindAll() {
    return this.buildGiphyUrl('trending');
  }

  buildGiphyUrl(endpoint) {
    return `${this.host}/${endpoint}?api_key=${config.giphyApiKey}&rating=${this.rating}`;
  }

  handleResponse(status, headers, payload) {
    let response;

    if (payload?.pagination?.total_count !== undefined) {
      response = {
        meta: {
          count: Number(payload.pagination.total_count),
        },

        giphy_images: payload.data,
      };
    } else {
      response = {
        giphy_image: payload.data,
      };
    }

    return super.handleResponse(status, headers, response);
  }
}
