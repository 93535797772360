/* import __COLOCATED_TEMPLATE__ from './search.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked isSearchEnabled = false;

  element = null;

  get hideButton() {
    return this.isSearchEnabled && !this.args.isSearchQueryExist;
  }

  @action
  clearSearch() {
    this.args.onReset();
    if (this.isSearchEnabled) {
      this.isSearchEnabled = false;
    }

    this.element?.querySelector('input[type="search"]')?.focus();
  }

  @action
  showSearchBar() {
    this.isSearchEnabled = true;
  }

  @action
  hideSearchBar() {
    this.isSearchEnabled = false;
  }

  @action
  setSearchButtonElement(element) {
    this.searchButton = element;
  }

  @action
  updateSearchBarVisibility() {
    if (this.args.isSearchQueryExist) {
      this.isSearchEnabled = this.args.isSearchQueryExist;
    }
  }

  @action
  handleInsert(element) {
    this.element = element;

    if (this.args.isSearchQueryExist || this.args.isSearchEnabledDefault) {
      this.isSearchEnabled = true;
    }
  }
}
