/* import __COLOCATED_TEMPLATE__ from './wrapper.hbs'; */
import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import { easeOut } from 'ember-animated/easings/cosine';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';
import bezier from 'bezier-easing';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class FormWrapperComponent extends Component {
  @argDefault absolute = true;

  *transition({ insertedSprites, removedSprites }) {
    for (let sprite of insertedSprites) {
      sprite.startAtPixel({ y: window.innerHeight });
      move(sprite, {
        easing: bezier(0.1, 0.95, 0.45, 1.05),
      });
      yield fadeIn(sprite);
    }

    for (let sprite of removedSprites) {
      sprite.endAtPixel({ y: window.innerHeight });
      move(sprite, { easing: easeOut });
      yield fadeOut(sprite);
    }
  }
}
