import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { FocusTargetValue, FocusTrap } from 'focus-trap';
import { TrackedMap } from 'tracked-built-ins';

export default class FocusTrapService extends Service {
  @tracked stack: FocusTrap[] = [];
  focusTrapElements = new TrackedMap<FocusTrap, FocusTargetValue>();

  skipToMainContentButtons = [] as HTMLButtonElement[];

  handleSkipToMainContentButton = modifier((el: HTMLButtonElement) => {
    if (!this.skipToMainContentButtons.includes(el)) {
      this.skipToMainContentButtons.push(el);
    }

    return () => {
      this.skipToMainContentButtons = this.skipToMainContentButtons.filter(
        (button) => button !== el
      );
    };
  });

  register(trap: FocusTrap, trapElement: FocusTargetValue) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    this.focusTrapElements.set(trap, trapElement);
  }

  unregister(trap: FocusTrap) {
    this.focusTrapElements.delete(trap);
  }
}

declare module '@ember/service' {
  interface Registry {
    'focus-trap': FocusTrapService;
  }
}
