/* import __COLOCATED_TEMPLATE__ from './add-criterium.hbs'; */
import Component from '@glimmer/component';
import ScorecardCriterium, {
  TOPIC,
} from 'teamtailor/models/scorecard-criterium';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Intl from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import Store from '@ember-data/store';
import { argDefault } from 'teamtailor/utils/arg-default';

type CompetenceAddCriteriaArgs = {
  onAdd: (criterium: ScorecardCriterium) => void;
  buttonAppearance?: string;
};

export default class CompetenceAddCriteriaComponent extends Component<CompetenceAddCriteriaArgs> {
  @service declare intl: Intl;
  @service declare current: Current;
  @service declare store: Store;

  @argDefault buttonAppearance = 'secondary';

  @tracked isModalOpen = false;
  @tracked declare name: string | null;
  @tracked selectedTopic: TOPIC = TOPIC.SKILL;
  @tracked scorecardCriterium: ScorecardCriterium | null = null;

  get topicArray(): string[] {
    return Object.values(TOPIC);
  }

  get isValid(): boolean {
    return !!this.scorecardCriterium?.name.length;
  }

  @action
  buildScorecardCriterium(): void {
    this.scorecardCriterium = this.store.createRecord('scorecard-criterium', {
      name: '',
      topic: TOPIC.SKILL,
      company: this.current.company,
    });
  }

  @action
  openModal(): void {
    this.isModalOpen = true;
  }

  @action
  onModalClose(): void {
    this.isModalOpen = false;
    if (this.scorecardCriterium?.isNew) {
      this.scorecardCriterium.unloadRecord();
    }

    this.scorecardCriterium = null;
  }

  @action
  onTopicChange(value: TOPIC) {
    this.selectedTopic = value;
  }

  @action
  createCriterium() {
    const { scorecardCriterium } = this;
    if (scorecardCriterium) {
      scorecardCriterium.save().then(() => {
        this.args.onAdd(scorecardCriterium);
        this.onModalClose();
      });
    }
  }
}
