/* import __COLOCATED_TEMPLATE__ from './new-loader.hbs'; */
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import Component from '@glimmer/component';
import MeetingLoaderNewGraphql, {
  Response,
} from 'teamtailor/services/meeting-loader-new-graphql';
import { NewMeetingsQueryParams } from 'teamtailor/routes/meetings/new';
import CandidateModel from 'teamtailor/models/candidate';

interface Args {
  queryParamArg?: NewMeetingsQueryParams;
}

export default class NewLoader extends Component<Args> {
  @service declare meetingLoaderNewGraphql: MeetingLoaderNewGraphql;
  @tracked declare data?: Response;
  @tracked loading = true;
  @tracked candidateId?: string;

  @action
  async loadData() {
    this.loading = true;

    this.data = await this.meetingLoaderNewGraphql.loadData({
      ...this.args.queryParamArg,
      ...(this.candidateId ? { candidateId: this.candidateId } : {}),
    });

    this.loading = false;
  }

  @action
  changeCandidate(candidate: CandidateModel) {
    this.candidateId = candidate.id;
    this.loadData();
  }
}
