/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import classnames from 'teamtailor/utils/classnames';

export default class QuickNavigationItemComponent extends Component {
  @tracked selected;

  get item() {
    return this.args.item;
  }

  get title() {
    return this.item?.displayName || this.item?.title;
  }

  get isSettingsPage() {
    return this.item?.route === 'settings.index';
  }

  get searchResult() {
    const { item } = this;
    if (item && item.constructor.modelName === 'search-result') {
      return item;
    }

    return undefined;
  }

  get fakeCandidate() {
    return {
      picture: this.searchResult.candidateAvatarId,
      initials: this.searchResult.candidateInitials,
      color: this.searchResult.candidateColor,
    };
  }

  get pageIcon() {
    return this.isCreateAction ? 'plus' : this.isSettingsRoute ? 'cog' : 'list';
  }

  get isCreateAction() {
    const { createAction } = this.item;
    return !!createAction;
  }

  get pageIconStyle() {
    return this.pageIcon === 'plus' ? 'regular' : undefined;
  }

  get classes() {
    return classnames('quick-navigation-item', {
      'quick-navigation-item--selected': this.selected,
    });
  }

  get isSettingsRoute() {
    const { route } = this.item;
    return route && route.indexOf('settings') === 0;
  }

  get routePrefix() {
    if (this.item.type === 'page_publication') {
      return 'navbar.main_menu.content';
    } else if (
      this.isSettingsRoute &&
      !this.isCreateAction &&
      !this.isSettingsPage
    ) {
      return 'navbar.main_menu.settings';
    }

    return null;
  }

  @action
  handleSelectChange(element, args) {
    this.selected = args[0];

    if (this.selected) {
      this.args.onSelected(element);
    }
  }
}
