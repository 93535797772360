/* import __COLOCATED_TEMPLATE__ from './number.hbs'; */
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @service intl;
  @tracked errors;

  @action
  validateNumberField(value) {
    if (!isPresent(value) && this.args.field.required) {
      return false;
    }

    const { min, max, type, label } = this.args.field;

    const hasMinMax = isPresent(min ?? max);

    if (!type === 'number' || !hasMinMax || !value) {
      return true;
    }

    let isValidated = true;
    let errorType;
    if (min && !max) {
      isValidated = value >= min;
      errorType = 'invalid_number_over';
    }

    if (!isPresent(min) && isPresent(max)) {
      isValidated = value <= max;
      errorType = 'invalid_number_below';
    }

    if (isPresent(min) && isPresent(max)) {
      isValidated = value >= min && value <= max;
      errorType = 'invalid_number';
    }

    if (!isValidated) {
      this.errors = [
        {
          message: this.intl.t(`components.partner.config_field.${errorType}`, {
            label,
            min,
            max,
          }),
        },
      ];
    }

    return isValidated;
  }

  @action
  onChange(e) {
    this.errors = [];
    const { value } = e.target;
    this.args.onChange(value, { valid: this.validateNumberField(value) });
  }
}
