/* import __COLOCATED_TEMPLATE__ from './support-link-intl.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntercomService from 'teamtailor/services/intercom';
import { action } from '@ember/object';

interface SupportLinkIntlArgs {
  subject: string;
  message: string;
}

export default class SupportLinkIntl extends Component<SupportLinkIntlArgs> {
  @service declare intercom: IntercomService;

  @action didInsert(element: HTMLElement) {
    const linkEl = element.querySelector('a');

    if (linkEl) {
      linkEl.setAttribute(
        'href',
        `mailto:support@teamtailor.com?subject=${this.args.subject}`
      );
    }
  }

  @action onClick(event: MouseEvent) {
    event.preventDefault();

    if (event.target instanceof HTMLElement && event.target.tagName !== 'A') {
      return;
    }

    const message = this.args.message ? `${this.args.message}\n\n` : '';
    this.intercom.showNewMessage(message);
  }
}
