/* import __COLOCATED_TEMPLATE__ from './subscription-invoices.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import Store from '@ember-data/store';

import {
  TABLE_COLUMNS,
  DEFAULT_CURRENCY,
  TABLE_LOADING_COLUMNS,
} from 'teamtailor/constants/subscription-invoices';
import { tracked } from '@glimmer/tracking';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import SubscriptionInvoiceModel from 'teamtailor/models/subscription-invoice';

interface InvoiceResponse {
  subscription_invoices: SubscriptionInvoiceModel[];
  meta: {
    total_count: number;
    total_pages: number;
  };
}

export default class SubscriptionInvoiceComponent extends Component {
  columns = TABLE_COLUMNS;
  loadingColumns = TABLE_LOADING_COLUMNS;

  currency = DEFAULT_CURRENCY;
  @service declare server: Server;
  @service declare current: Current;
  @service declare store: Store;
  @service declare pusher: PusherService;

  @tracked isLoading = true;
  @tracked totalPages: number | undefined;
  @tracked page = 1;
  @tracked error = null;
  @tracked hide = false;

  limit = 5;
  @tracked invoices: SubscriptionInvoiceModel[] = [];
  private declare channel?: PusherChannel;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.bindPusher();
    this.updateStore();
  }

  @action
  async downloadInvoice(invoiceId: string) {
    const response = await this.server.fetch(
      `/app/companies/${this.current.company.uuid}/api/subscription_invoices/${invoiceId}/download`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `invoice-${invoiceId}.pdf`;
    link.click();
  }

  @action
  async setPage(page: number) {
    this.page = page;
    await this.updateStore();
  }

  async updateStore() {
    this.isLoading = true;
    const response = await this.fetchSubscriptionInvoices(this.page);
    if (response.status === 200) {
      const data = (await response.json()) as InvoiceResponse;
      this.store.pushPayload(data);
      this.invoices = this.current.company.subscriptionInvoices.slice(
        (this.page - 1) * this.limit,
        (this.page - 1) * this.limit + this.limit
      );
      this.totalPages = data.meta.total_pages;
      this.isLoading = false;
    }

    if (!response.ok) {
      const data: any = await response.json();
      if (data.error === 'No account on company') {
        this.hide = true;
      } else {
        this.error = data.error;
      }
    }
  }

  async fetchSubscriptionInvoices(page: number) {
    const adapter = this.store.adapterFor('application');
    const url = `${adapter.buildURL('subscription-invoice')}?page=${page}`;
    return this.server.fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async bindPusher() {
    this.channel = await this.pusher.channel(
      this.current.user.notificationsChannel
    );
    this.channel.bind('subscription_invoices', (payload) => {
      if (payload.error) {
        this.error = payload.error;
        return;
      }

      this.store.pushPayload(payload);
      this.invoices = this.current.company.subscriptionInvoices.slice(
        (this.page - 1) * this.limit,
        (this.page - 1) * this.limit + this.limit
      );
      this.totalPages = payload.meta.total_pages;
      this.isLoading = false;
    });
  }

  willDestroy() {
    super.willDestroy();
    this.channel?.unbind('subscription_invoices');
  }
}
