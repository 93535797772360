/* import __COLOCATED_TEMPLATE__ from './masonry-list-image.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import imageUrl from 'teamtailor/utils/image-url';
import { argDefault } from 'teamtailor/utils/arg-default';
import { htmlSafe } from '@ember/template';

export default class MediaLibraryMasonryListImageComponent extends Component {
  @service flashMessages;
  @service intl;

  @argDefault selectable = true;

  get imageUrl() {
    return (
      this.args.image.previewUrl || imageUrl(this.args.image, 'media_preview')
    );
  }

  get imageStyle() {
    const { width, height } = this.args.image;
    return htmlSafe(`aspect-ratio: ${width} / ${height};`);
  }

  @action
  removeImage() {
    this.args.onRemoveImage(this.args.image);
  }

  @action
  handleClick() {
    if (!this.selectable) {
      return;
    }

    if (!this.args.canPickImage && !this.args.picked) {
      this.flashMessages.error(
        this.intl.t(
          'components.media_library.list_image.maximum_images_exceeded'
        )
      );
    } else {
      this.args.onPickImage(this.args.image);
    }
  }
}
