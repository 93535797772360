/* import __COLOCATED_TEMPLATE__ from './user-nav-bar-profile.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import UserModel from 'teamtailor/models/user';
import UserOnlineStatus from 'teamtailor/services/user-online-status';

interface ComponentArgs {
  user: UserModel;
}

export default class UserNavBarProfileComponent extends Component<ComponentArgs> {
  @service declare userOnlineStatus: UserOnlineStatus;

  get user(): UserModel {
    return this.args.user;
  }

  get truncatedName(): string {
    const name = this.args.user.nameOrEmail;

    if (name.length > 24) {
      return `${name.substring(0, 21)}...`;
    }

    return name;
  }

  get showOnline(): boolean {
    return !this.user.hideOnlineStatus && !this.userOnlineStatus.impersonating;
  }
}
