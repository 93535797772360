/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import PermissionsService from 'teamtailor/services/permissions';
import { get } from 'teamtailor/utils/get';
import {
  CandidateModel,
  JobApplicationModel,
  MessageModel,
} from 'teamtailor/models';

export interface ComposerSignature {
  Args: {
    candidate: CandidateModel;
    jobApplication?: JobApplicationModel;
    replyingTo?: MessageModel;
    onMessageCreated: (message: MessageModel) => void;
  };
}

export enum MessageMode {
  EMAIL = 'email',
  SMS = 'sms',
}

export default class Composer extends Component<ComposerSignature> {
  @service declare current: Current;
  @service declare permissions: PermissionsService;

  @tracked messageMode = MessageMode.EMAIL;

  get displayWalletAlmostEmpty() {
    return (
      this.messageMode === MessageMode.SMS &&
      this.permissions.has('settings/update_company') &&
      this.companyWalletAlmostEmpty
    );
  }

  get companyWalletAlmostEmpty() {
    return get(this.current.company.wallet, 'balance') <= 20;
  }
}
