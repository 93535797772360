/* import __COLOCATED_TEMPLATE__ from './number-slider.hbs'; */
import Base from './base';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class extends Base {
  @service intl;

  @tracked
  selectedValue = isPresent(this.value) ? this.value : this.args.default;

  @argDefault unit = 'px';

  get displayValue() {
    if (this.value === this.args.default) {
      return this.intl.t(`content.editor.section.value_default`, {
        value: `${this.value}${this.unit}`,
      });
    } else if (isPresent(this.value)) {
      return `${this.value}${this.unit}`;
    } else if (isPresent(this.args.default)) {
      return this.intl.t(`content.editor.section.value_default`, {
        value: `${this.args.default}${this.unit}`,
      });
    } else {
      return '-';
    }
  }

  @action
  handleSliderChange(value) {
    this.selectedValue = value;
    this.saveChange();
  }

  @action
  saveChange() {
    if (this.selectedValue > this.args.max) {
      this.selectedValue = this.args.max;
    }

    if (this.selectedValue === this.args.default) {
      this.value = null;
    } else {
      this.value = this.selectedValue;
    }

    if (this.args.onChange) {
      this.args.onChange(this.selectedValue);
    }
  }
}
