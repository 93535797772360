/* import __COLOCATED_TEMPLATE__ from './if.hbs'; */
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
class SmoothIf extends Component {
  tagName = '';
  static positionalParams = ['predicate'];
}

export default SmoothIf;
