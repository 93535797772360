/* import __COLOCATED_TEMPLATE__ from './rows.hbs'; */
import { AsyncBelongsTo } from '@ember-data/model';
import Component from '@glimmer/component';
import { set } from '@ember/object';
import { compare } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import JobApplicationModel from 'teamtailor/models/job-application';
import PartnerModel from 'teamtailor/models/partner';
import PartnerResultModel from 'teamtailor/models/partner-result';

type PartnerResultsRowArgs = {
  jobApplications: JobApplicationModel[];
  sortBy: () => void;
  pinnedCount: number;
  hiddenCandidateIds: string[];
  hoveredJobApplication: JobApplicationModel;
};
type PartnerResultItem = {
  jobApplication?: JobApplicationModel;
  assessmentsResults: PartnerResultModel[];
};

type PartnerItem = {
  partnerId: string;
  label: string;
  results: PartnerResultItem[];
};
type Results = PartnerItem[];

export default class CompareCandidatesPartnerResultsRowsComponent extends Component<PartnerResultsRowArgs> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @tracked partners?: AsyncBelongsTo<PartnerModel>[];
  @tracked partnerResults?: PartnerResultModel[];
  @tracked showPartnerResultDetails = false;
  @tracked selectedPartnerResult?: PartnerResultModel;

  constructor(owner: unknown, args: PartnerResultsRowArgs) {
    super(owner, args);
    this.fetchPartners.perform();
  }

  get computedPartnerResults(): Results {
    const results: Results = [];

    this.partners?.forEach((partner) => {
      const partnerItem = this.partnerItem(partner);

      this.args.jobApplications.forEach((jobApplication) => {
        const partnerResultItem = this.partnerResultItem(jobApplication);
        const candidatePartnerResults =
          this.candidatePartnerResults(jobApplication, partner) || [];

        set(partnerResultItem, 'assessmentsResults', candidatePartnerResults);

        partnerItem.results.push(partnerResultItem);
      });

      results.push(partnerItem);
    });

    return results;
  }

  partnerItem(partner: AsyncBelongsTo<PartnerModel>): PartnerItem {
    return {
      partnerId: get(partner, 'id'),
      label: this.intl.t('jobs.job.compare_candidates.partner_score', {
        partnerName: get(partner, 'name'),
      }),

      results: [],
    };
  }

  partnerResultItem(jobApplication: JobApplicationModel): PartnerResultItem {
    return {
      jobApplication,
      assessmentsResults: [],
    };
  }

  candidatePartnerResults(
    jobApplication: JobApplicationModel,
    partner: AsyncBelongsTo<PartnerModel>
  ) {
    return this.partnerResults?.filter((partnerResult) => {
      return (
        get(partnerResult.candidate, 'id') ===
          jobApplication.candidateId.toString() &&
        get(partnerResult.partner, 'id') === get(partner, 'id')
      );
    });
  }

  assessmentScoreSort(partnerId: string) {
    function averageScore(jobApplication: JobApplicationModel): number {
      const partnerResults = jobApplication.partnerResults.filter((result) => {
        return (
          result.partnerId === partnerId &&
          parseInt(result.assessmentScore) >= 0
        );
      });
      const total = partnerResults.reduce(
        (sum, result) => (sum += parseInt(result.assessmentScore)),
        0
      );
      return total / partnerResults.length || 0;
    }

    return function (a: JobApplicationModel, b: JobApplicationModel) {
      return compare(averageScore(b), averageScore(a));
    };
  }

  fetchPartners = task(async () => {
    const partners: {
      [id: string]: AsyncBelongsTo<PartnerModel>;
    } = {};

    const job = await this.args.jobApplications[0]?.job;
    const candidateIds = this.args.jobApplications.map(
      (jobApplication) => jobApplication.candidateId
    );
    const partnerResults = await this.store.query('partner-result', {
      candidate_id: candidateIds,
    });

    const filteredPartnerResults = [];

    for (const partnerResult of partnerResults.toArray()) {
      if (
        partnerResult.assessmentScore &&
        partnerResult.jobId === job?.id.toString()
      ) {
        filteredPartnerResults.push(partnerResult);
        partners[get(partnerResult.partner, 'id')] = partnerResult.partner;
      }
    }

    this.partnerResults = filteredPartnerResults;
    this.partners = Object.values(partners);
  });
}
