/* import __COLOCATED_TEMPLATE__ from './location.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { trackedFunction } from 'ember-resources/util/function';
import { argDefault } from 'teamtailor/utils/arg-default';

type Args = {
  regionId?: string;
  noAllOption?: boolean;
  selected?: string;
};

export default class extends Component<Args> {
  @service declare store: Store;
  @argDefault selected = '';

  selectedLocationFunction = trackedFunction(this, async () => {
    if (this.selected) {
      const location = await this.store.findRecord('location', this.selected);
      return location;
    } else {
      return null;
    }
  });
}
