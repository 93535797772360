/* import __COLOCATED_TEMPLATE__ from './answers-table.hbs'; */
import Component from '@glimmer/component';
import { getStageTypeColor } from 'teamtailor/helpers/stage-type-color';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

type Answer = {
  label: string;
  stageBreakdown: {
    [key: string]: number;
  };
  value: {
    by_total: number;
  };
};

type Question = {
  title: string;
  answers: Answer[];
};

type Args = {
  questionIndex: number;
  question: Question;
};

type Column = {
  label: string;
  width: number;
  isBadge?: boolean;
  badgeColor?: string;
  classes?: string;
};

type StageTypeCount = {
  type: string;
  value: number;
};

type StageTypeAnswer = {
  label: string;
  stageBreakdown: {
    stageType: string;
    count: number;
    showValue: boolean;
  }[];
};

export default class SurveyAnswersTableComponent extends Component<Args> {
  @service declare intl: IntlService;

  get heading(): string {
    return `${this.args.questionIndex}. ${this.args.question.title}`;
  }

  get numberOfAnswers(): number {
    return this.args.question.answers.reduce(
      (acc: number, answer: Answer) => acc + answer.value.by_total,
      0
    );
  }

  get stageTypes(): string[] {
    return Object.keys(
      this.args.question.answers.firstObject?.stageBreakdown || {}
    );
  }

  get stageTypeCounts(): StageTypeCount[] {
    return this.stageTypes.map((stageType: string) => {
      const totalValue = this.args.question.answers.reduce(
        (acc: number, answer: Answer) => {
          const count = Number(answer.stageBreakdown[stageType]);
          return acc + count;
        },
        0
      );

      return {
        type: stageType,
        value: totalValue,
      };
    });
  }

  get stageTypeAnswers(): StageTypeAnswer[] {
    return this.args.question.answers.map((answer: Answer) => {
      const { label, stageBreakdown } = answer;

      return {
        label,
        stageBreakdown: Object.entries(stageBreakdown).map(
          (stage: [string, number]) => {
            const [stageType, count] = stage;
            const stageTypeCountValue =
              this.stageTypeCounts.find((stc) => stc.type === stageType)
                ?.value || 0;

            return {
              stageType,
              count: Number(count),
              showValue: stageTypeCountValue >= 5 || stageTypeCountValue === 0,
            };
          }
        ),
      };
    });
  }

  get columns(): Column[] {
    const stageColumns = this.stageTypes.map((stageType: string) => {
      return {
        label: this.intl.t(`job.stage_types.${stageType}`),
        width: 100,
        isBadge: true,
        badgeColor: getStageTypeColor(stageType),
        classes: 'text-center',
      };
    });

    return [
      {
        label: 'surveys.field',
        width: 400,
      },
      ...stageColumns,
    ];
  }
}
