import Model, { AsyncBelongsTo, belongsTo } from '@ember-data/model';
import { StageModel, UserModel } from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';

export default class UserStageRestrictionModel extends Model {
  @service declare current: Current;

  @belongsTo('stage') declare stage: AsyncBelongsTo<StageModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-stage-restriction': UserStageRestrictionModel;
  }
}
