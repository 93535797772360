/* import __COLOCATED_TEMPLATE__ from './error-messages.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

type Args = {
  errors: Record<string, string[]>;
  onClose?: (error: { [key: string]: string[] }) => void;
};

export default class ErrorMessagesComponent extends Component<Args> {
  @action
  closeErrorMessage(error: { [key: string]: string[] }) {
    this.args.onClose?.(error);
  }
}
