/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';
import { verifyArg } from 'teamtailor/utils/verify-arg';

const VALID_SIZES = ['32', '40'] as const;

interface ProgressIndicatorArgs {
  color: string;
  completionPercentage: number;
  colorScale?: number;
  size?: (typeof VALID_SIZES)[number];
}

export default class ProgressIndicatorComponent extends Component<ProgressIndicatorArgs> {
  @argDefault color = 'blue';
  @argDefault colorScale = 200;
  @argDefault size = '40';
  @argDefault completionPercentage = 0;

  get sizeClass(): string {
    verifyArg(this.size, VALID_SIZES, 'Progress indicator @size');
    return this.size === '40' ? 'size-48' : 'size-40';
  }

  get pixelSize(): number {
    verifyArg(this.size, VALID_SIZES, 'Progress indicator @size');
    return this.size === '40' ? 48 : 40;
  }

  get filledValue(): number {
    const radius = this.pixelSize * 0.43;
    return radius * 2 * Math.PI;
  }

  get circleClass(): string {
    return `text-${this.color}-${this.colorScale}`;
  }

  get percentageFillValue(): number {
    return this.filledValue - this.filledValue * this.completionPercentage;
  }
}
