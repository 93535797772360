/* import __COLOCATED_TEMPLATE__ from './theme.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class UnicornTheme extends Component {
  @tracked inDarkMode = false;

  colorSchemeMediaQueryList?: MediaQueryList;

  constructor(owner: unknown, args: never) {
    super(owner, args);

    if (
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !this.inDarkMode
    ) {
      next(this.toggleTheme.bind(this));
    }
  }

  toggleTheme(): void {
    const body = document.querySelector('body');
    if (this.inDarkMode) {
      body?.classList.remove('theme-dark', 'dark');
      body?.classList.add('theme-light');
    } else {
      body?.classList.remove('theme-light');
      body?.classList.add('theme-dark', 'dark');
    }

    this.inDarkMode = !this.inDarkMode;
  }

  @action
  onToggleTheme() {
    this.toggleTheme();
  }
}
