/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import { AsyncBelongsTo } from '@ember-data/model';
import Store from '@ember-data/store';
import { action, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import BaseComponent from 'teamtailor/components/content-block/base-component';
import ContentBlockPictureModel from 'teamtailor/models/content-block-picture';
import ImageModel from 'teamtailor/models/image';
import { get } from 'teamtailor/utils/get';

interface PictureBaseComponentArgs {
  model: AsyncBelongsTo<ContentBlockPictureModel>;
}

export default class PictureBaseComponent extends BaseComponent<PictureBaseComponentArgs> {
  @service declare store: Store;

  get pictureBlockSection() {
    return this.args.model;
  }

  get imageUrl() {
    const pickedImage = get(
      this.pictureBlockSection,
      'pickedImages'
    ).lastObject;
    return get(pickedImage, 'imageOrTemplateUrl');
  }

  @action
  async handlePickImage(image: ImageModel) {
    const pickedImage = get(
      this.pictureBlockSection,
      'pickedImages'
    ).lastObject;

    if (!pickedImage) {
      return;
    }

    set(pickedImage, 'image', image);
    setProperties(await this.pictureBlockSection, {
      altText: image.alt,
      url: image.s3Image,
    });
  }
}
