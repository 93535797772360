/* import __COLOCATED_TEMPLATE__ from './document-upload.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class SettingsSmsDocumentUploadComponent extends Component {
  @tracked selectedDocumentType;

  get document() {
    return this.args.document;
  }

  get hasSpecificDocumentType() {
    return this.document.accepted_documents.length;
  }

  get isMultipleChoice() {
    return this.document.accepted_documents.length > 1;
  }

  get keyName() {
    if (this.isMultipleChoice) {
      return this.selectedDocumentType?.type;
    } else if (this.hasSpecificDocumentType) {
      return this.document.accepted_documents[0]?.type;
    } else {
      return this.document.required_name;
    }
  }

  get fileName() {
    return this.args.fileNames[this.keyName];
  }

  get acceptedDocumentsText() {
    if (this.hasSpecificDocumentType && !this.isMultipleChoice) {
      const documentTypeNames = this.document.accepted_documents.map(
        (d) => d.name
      );

      return `Accepted documents: ${documentTypeNames.join(', ')}`;
    }

    return undefined;
  }

  // Set to empty object so that we can access nested attributes later:
  setEmptyDocInTwilioJson(docTypeKey) {
    set(this, 'args.twilio.documents', {
      ...get(this, 'args.twilio.documents'),
      [docTypeKey]: {},
    });
  }

  @action
  didInsert() {
    if (this.hasSpecificDocumentType) {
      const firstType = this.document.accepted_documents[0];
      this.selectedDocumentType = firstType;
      this.setEmptyDocInTwilioJson(firstType.type);
    }
  }

  @action
  setDocumentType(docType) {
    this.selectedDocumentType = docType;
    this.setEmptyDocInTwilioJson(docType.type);
  }
}
