/* import __COLOCATED_TEMPLATE__ from './trigger-message-form.hbs'; */
import { inject as service } from '@ember/service';
import { calculateCredits } from 'teamtailor/utils/sms';
import { action, set, setProperties } from '@ember/object';
import TriggerFormBaseComponent from 'teamtailor/components/trigger-form-base';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';

export default class TriggerMessageFormComponent extends TriggerFormBaseComponent {
  @service current;
  @service store;
  @service router;

  @tracked smsBody = null;
  @tracked emailBody = null;
  @tracked selectedResponse = null;

  get user() {
    return this.current.user;
  }

  get company() {
    return this.current.company;
  }

  get job() {
    return this.args.model.job;
  }

  get cannedResponses() {
    const company = get(this, 'company');
    const job = get(this, 'job');

    let context = null;
    if (job) {
      context = get(job, 'assetStructureContext');
    } else {
      const departmentId = get(this.user, 'departmentId');
      context = {
        department: departmentId,
        role: null,
        region: null,
        location: null,
      };
    }

    return company.fetchGroupedCannedResponsesByContext(context);
  }

  get pickedQuestions() {
    return get(this.model, 'sortedQuestions').filterBy('isDeleted', false);
  }

  get questions() {
    return this.pickedQuestions.mapBy('question');
  }

  get bodyWithSignature() {
    return get(this.model, 'body')
      ? get(this.model, 'body')
      : get(this.model, 'job.user.signature');
  }

  get totalCreditsCost() {
    let smsMessageData = {
      message: get(this.model, 'body'),
      optOutMessage: '',
      replyMessage: '',
    };

    let candidatesNumbers = {
      candidatesWithMessageNoSuffix: 1,
      candidatesWithMessageReplySuffix: 0,
      candidatesWithMessageOptOutSuffix: 0,
      candidatesWithMessageOptOutAndReplySuffix: 0,
    };
    return calculateCredits(smsMessageData, candidatesNumbers);
  }

  @action
  enableEmail() {
    set(this, 'model.isSms', false);
    set(this, 'smsBody', get(this, 'model.body'));
    set(this, 'model.body', get(this, 'emailBody'));
  }

  @action
  enableSms() {
    set(this, 'model.isSms', true);
    set(this, 'emailBody', get(this, 'model.body'));
    set(this, 'model.body', get(this, 'smsBody'));
  }

  @action
  addQuestion(question) {
    get(this, 'pickedQuestions').forEach((pickedQuestion, index) => {
      set(pickedQuestion, 'rowOrderPosition', index);
    });

    const rowOrderPosition = get(this, 'pickedQuestions.length');
    get(this, 'model.pickedQuestions').createRecord(
      applyDefaultPickedQuestionValues({
        question,
        rowOrderPosition,
      })
    );
  }

  @action
  removeQuestion(question) {
    const pickedQuestion = get(this, 'pickedQuestions').findBy(
      'question',
      question
    );
    pickedQuestion.deleteRecord();

    get(this, 'pickedQuestions').forEach((pickedQuestion, index) => {
      set(pickedQuestion, 'rowOrderPosition', index);
    });
  }

  @action
  didSelectCannedResponse(cannedResponse) {
    this.selectedResponse = cannedResponse;

    setProperties(get(this, 'model'), {
      subject: get(cannedResponse, 'subject'),
      body: get(cannedResponse, 'body'),
      uploads: get(cannedResponse, 'uploads').toArray(),
      pickedQuestions: [],
    });
    get(cannedResponse, 'questions')
      .toArray()
      .map((question) => {
        this.addQuestion(question);
      });
  }

  @action
  modalClosedAction() {
    this.router.transitionTo('jobs.job.stage-triggers');
  }
}
