import Service, { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import ENV from 'teamtailor/config/environment';
import { ActiveModelAdapter } from 'active-model-adapter';
import { AllModels, ModelKey } from 'teamtailor/models';
import AdapterRegistry from 'ember-data/types/registries/adapter';

interface URLObject {
  href?: string;
  protocol?: string;
  hostname?: string;
  port?: string;
  pathname?: string;
  search?: string;
  hash?: string;
}

type MemberActionArgs = ActionArgs & {
  urlType?: string;
};

type CollectionActionArgs = ActionArgs & {
  modelName: ModelKey;
};

type ActionArgs = {
  action: string;
  method?: HTTPVerb;
  options?: JQueryAjaxSettings | RequestInit;
  queryParams?: Record<string, string>;
};

type HTTPVerb =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'PATCH'
  | 'OPTIONS'
  | 'HEAD';

const completeUrlRegex = /^(http|https)/;

function shouldAddHeaders(url: RequestInfo | URL): boolean {
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  url = url.toString();
  const hostname = parseHostname(url);

  return !url.match(completeUrlRegex) || ENV.RAILS_HOST === hostname;
}

function parseHostname(str: string): string | undefined {
  const element = document.createElement('a');
  element.href = str;
  const fullObject: URLObject = element;

  return fullObject.hostname;
}

function addHostToUrl(url: string, host: string | undefined): string {
  if (!host || url.match(completeUrlRegex)) {
    return url;
  }

  if (!url.startsWith('/')) {
    url = '/' + url;
  }

  return `${ENV.httpOrHttps}://${ENV.RAILS_HOST}${url}`;
}

export class ServerError extends Error {
  payload: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  status: number;

  constructor(
    payload: unknown,
    status: number,
    message = 'Server operation failed'
  ) {
    super(message);

    this.payload = payload;
    this.status = status;
  }
}

export default class Server extends Service {
  @service declare store: Store;

  adapter: ActiveModelAdapter;

  constructor(properties?: object | undefined) {
    super(properties);
    this.adapter = this.store.adapterFor('application');
  }

  get headers(): Record<string, string> {
    return this.adapter.headers;
  }

  get host(): string | undefined {
    return this.adapter.host;
  }

  request(
    url: string,
    type = 'GET',
    options: JQueryAjaxSettings | RequestInit = {}
  ) {
    return this.adapter.ajax(addHostToUrl(url, this.host), type, options);
  }

  fetch(
    resource: RequestInfo | URL,
    options = {} as RequestInit
  ): Promise<Response> {
    if (shouldAddHeaders(resource)) {
      const headers = { ...this.headers, ...(options.headers || {}) };
      options = { credentials: 'include', ...options, headers };
    }

    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return fetch(addHostToUrl(resource.toString(), this.host), options);
  }

  memberEndpoint(record: AllModels, urlType?: string): string {
    return this.store
      .adapterFor(record.constructor.modelName as keyof AdapterRegistry)
      .buildURL(
        record.constructor.modelName,
        record.id,
        (
          record as unknown as { _createSnapshot: () => unknown[] }
        )._createSnapshot(), // Special case for UrlTemplates to work.
        urlType
      );
  }

  memberAction<T>(
    record: AllModels,
    {
      action,
      method = 'PUT',
      options = {},
      urlType,
      queryParams,
    }: MemberActionArgs
  ) {
    const memberEndpoint = this.memberEndpoint(record, urlType);

    return this.request(
      this.url({ memberEndpoint, action, queryParams }),
      method,
      options
    ) as Promise<T>;
  }

  memberActionUrl(
    record: AllModels,
    { action, urlType, queryParams }: MemberActionArgs
  ) {
    const memberEndpoint = this.memberEndpoint(record, urlType);

    return this.url({ memberEndpoint, action, queryParams });
  }

  collectionAction({
    modelName,
    action,
    method = 'PUT',
    options = {},
    queryParams,
  }: CollectionActionArgs) {
    const memberEndpoint = this.store
      .adapterFor(modelName as keyof AdapterRegistry)
      .buildURL(modelName);

    return this.request(
      this.url({ memberEndpoint, action, queryParams }),
      method,
      options
    );
  }

  url({
    memberEndpoint,
    action,
    queryParams,
  }: {
    memberEndpoint: string;
    action: string;
    queryParams: Record<string, string> | undefined;
  }) {
    const query = this.stringifyQueryParams(queryParams);
    return `${memberEndpoint}/${action}${query}`;
  }

  stringifyQueryParams(params: Record<string, string> | undefined): string {
    if (!params) return '';

    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value && ['number', 'string', 'boolean'].includes(typeof value))
        queryParams.append(key, value);
    });

    const query = queryParams.toString();

    return query.length ? `?${query}` : '';
  }
}

declare module '@ember/service' {
  interface Registry {
    server: Server;
  }
}
