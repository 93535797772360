import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { later } from '@ember/runloop';
import { formatNumber } from 'teamtailor/helpers/format-number';
import {
  countries,
  identityAcceptExtension,
} from 'teamtailor/constants/twilio';
import { get } from 'teamtailor/utils/get';

export default class SmsController extends Controller {
  @service store;
  @service intl;
  @service current;
  @service flashMessages;
  @service flipper;

  get wallet() {
    return this.current.company.wallet;
  }

  get smsSetting() {
    return this.current.company.smsSetting;
  }

  showMore = false;

  get twilio() {
    return this.store.findRecord('twilio', this.current.company.id);
  }

  get twilioAddress() {
    return this.twilio;
  }

  getTwilioFileObjectByName(keyName) {
    return `twilio.documents.${keyName}`;
  }

  get smsToBuyFormated() {
    return formatNumber(get(this, 'smsSetting.creditsToBuy'), 0, '', ',');
  }

  get selectedCountry() {
    const country = get(this.current.company, 'phoneNumber.country');
    return countries.findBy('code', country);
  }

  get validateRequiredDocuments() {
    let { requiredDocuments } = get(this, 'selectedCountry');
    if (!requiredDocuments) {
      return true;
    }

    // TODO: Fix this the next time the file is edited.

    this.isFilesMissing(requiredDocuments);
    return true;
  }

  get missingFiles() {
    return !get(this, 'missingFilesCount');
  }

  get formValidate() {
    return this.validateRequiredDocuments && this.missingFiles;
  }

  identityAcceptExtension = identityAcceptExtension;

  @action
  autoRenew(state) {
    this.smsSetting.hasAutoRenewal = state;
    this.smsSetting.save();
  }

  @action
  handleApprovalOnclick() {
    const adapter = this.store.adapterFor('smsSetting');
    const url = `${adapter.buildURL('smsSetting')}/compliance_form`;

    const twilio = get(this, 'twilio');
    const body = {
      business_name: get(twilio, 'businessName'),
      business_address: get(twilio, 'businessAddress'),
      business_registration_number: get(twilio, 'businessRegistrationNumber'),
      name_of_authorized_representative: get(
        twilio,
        'nameOfAuthorizedRepresentative'
      ),

      authorization_of_authorized_representative: get(
        twilio,
        'authorizationOfAuthorizedRepresentative'
      ),
    };

    adapter
      .ajax(url, 'POST', {
        data: body,
      })
      .then(
        () => {
          set(this, 'smsSetting.identityStatus', 'pending');
        },
        () => {
          this.flashMessages.error(
            this.intl.t('settings.sms.request_not_send')
          );
        }
      );
  }

  @action
  attach(type, url) {
    switch (type) {
      case 'BusinessName':
        set(this, 'twilio.businessName', url);
        break;
      case 'BusinessAddress':
        set(this, 'twilio.businessAddress', url);
        break;
      case 'BusinessRegistrationNumber':
        set(this, 'twilio.businessRegistrationNumber', url);
        break;
      case 'NameOfAuthorizedRepresentative':
        set(this, 'twilio.nameOfAuthorizedRepresentative', url);
        break;
    }
  }

  @action
  closeApprovedAlert() {
    const smsSetting = get(this, 'smsSetting.content');
    set(this, 'smsSetting.identityStatus', 'closed');
    smsSetting.save();
  }

  @action
  isFilesMissing(requiredDocuments) {
    let missing = 0;
    requiredDocuments.forEach((document) => {
      const twilioName = this.getTwilioFileObjectByName(document.key);
      if (!get(this, twilioName)) {
        missing++;
      }
    });
    set(this, 'missingFilesCount', missing);
  }

  @action
  resetTwilioCompilance() {
    set(this, 'smsSetting.identityStatus', null);
  }

  @action
  splitNumbers(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  @action
  save() {
    let promise = this.current.company.save();
    promise.then(
      () => {
        later(() => {
          get(this, 'flashMessages').success(
            this.intl.t('settings.sms.success_message')
          );
        }, 500);
      },
      () => {
        get(this, 'flashMessages').error(
          this.intl.t('settings.sms.error_message')
        );
      }
    );
    return promise;
  }
}
