import Controller from '@ember/controller';
import Store from '@ember-data/store';
import { action, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { IntlService } from 'ember-intl';
import { AllModels, RequisitionModel } from 'teamtailor/models';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import { NO_DIVISION_ID } from 'teamtailor/models/division';
import Router from 'teamtailor/router';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import ReportsService from 'teamtailor/services/reports';
import Server from 'teamtailor/services/server';
import UserService from 'teamtailor/services/user';
import { get } from 'teamtailor/utils/get';

export default class RequisitionsController extends Controller {
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare reports: ReportsService;
  @service declare router: Router;
  @service declare server: Server;
  @service declare store: Store;
  @service declare user: UserService;

  @tracked assignee = '';
  @tracked department_id = '';
  @tracked division_id = '';
  @tracked location_id = '';
  @tracked recruiter_id = '';
  @tracked requisition_archive_reason_id = '';
  @tracked split_approvers = false;
  @tracked status = '';
  @tracked user_id = '';

  @tracked deselectedRequisitionIds: string[] = [];
  @tracked exportModal = false;
  @tracked include_archived = false;
  @tracked page = 1;
  @tracked query = '';
  @tracked selectAll = false;
  @tracked selectedRequisitionIds: string[] = [];
  @tracked showBulk = false;

  queryParams = [
    'query',
    'status',
    'location_id',
    'division_id',
    'department_id',
    'user_id',
    'recruiter_id',
    'assignee',
    'requisition_archive_reason_id',
    'include_archived',
    'page',
  ];

  searchProxyDelay = 750;

  get showFilterSidebar() {
    return localStorage.getItem('Requisitions.showFilterSidebar') === 'true';
  }

  set showFilterSidebar(value: boolean) {
    localStorage.setItem('Requisitions.showFilterSidebar', String(value));
  }

  get totalPages() {
    return this.model.meta.total_pages;
  }

  get totalCount() {
    return this.model.meta.total_count;
  }

  get selectedCount() {
    if (this.selectAll) {
      return this.totalCount - this.deselectedRequisitionIds.length;
    } else {
      return this.selectedRequisitionIds.length;
    }
  }

  get departments() {
    const departments = get(this.current.company, 'sortedDepartments');

    if (this.division_id) {
      const divisionId =
        this.division_id === NO_DIVISION_ID ? null : this.division_id;

      return departments.filter((department) => {
        return (
          get(department.division, 'id') === divisionId ||
          department.id === NO_DEPARTMENT_ID
        );
      });
    }

    return departments;
  }

  get exportParams() {
    return {
      query: this.query,
      status: this.status,
      include_archived: this.include_archived,
      division_id: this.division_id,
      department_id: this.department_id,
      location_id: this.location_id,
      user_id: this.user_id,
      recruiter_id: this.recruiter_id,
      requisition_archive_reason_id: this.requisition_archive_reason_id,
      split_approvers: this.split_approvers,
    };
  }

  get hasStatusEmpty() {
    return this.status === '';
  }

  get hasLocationEmpty() {
    return this.location_id === '';
  }

  get hasDivisionEmpty() {
    return this.division_id === '';
  }

  get hasDepartmentEmpty() {
    return this.department_id === '';
  }

  get hasAssigneeEmpty() {
    return this.assignee === '';
  }

  get hasUserIdEmpty() {
    return this.user_id === '';
  }

  get hasRecruiterIdEmpty() {
    return this.recruiter_id === '';
  }

  get hasRequisitionArchiveReasonIdEmpty() {
    return this.requisition_archive_reason_id === '';
  }

  get hasLocation() {
    return !this.hasLocationEmpty;
  }

  get hasDivision() {
    return !this.hasDivisionEmpty;
  }

  get hasDepartment() {
    return !this.hasDepartmentEmpty;
  }

  get hasNotStatusEmpty() {
    return !this.hasStatusEmpty;
  }

  get hasNotAssigneeEmpty() {
    return !this.hasAssigneeEmpty;
  }

  get hasUserId() {
    return !this.hasUserIdEmpty;
  }

  get hasRecruiterId() {
    return !this.hasRecruiterIdEmpty;
  }

  get hasRequisitionArchiveReasonId() {
    return !this.hasRequisitionArchiveReasonIdEmpty;
  }

  get filterSelected() {
    return (
      this.hasLocation ||
      this.hasDivision ||
      this.hasDepartment ||
      this.hasUserId ||
      this.hasRecruiterId ||
      this.hasRequisitionArchiveReasonId ||
      this.hasNotAssigneeEmpty ||
      this.hasNotStatusEmpty ||
      this.include_archived
    );
  }

  get showPagination() {
    return this.totalPages > 1;
  }

  get visibleRequisitions() {
    return this.model.filter(
      (requisition: RequisitionModel) => !!requisition.jobTitle
    );
  }

  get sortedRequisitions() {
    return this.visibleRequisitions.sort(
      (a: RequisitionModel, b: RequisitionModel) => {
        return b.createdAt.getTime() - a.createdAt.getTime();
      }
    );
  }

  handleSearchInput = restartableTask(async (value: string | null) => {
    await timeout(this.searchProxyDelay);

    if (value?.length) {
      this.page = 1;
      this.query = value;
    } else {
      this.query = '';
    }
  });

  @action
  async handleExport() {
    const adapter = this.store.adapterFor('application');
    const url = `${adapter.buildURL('requisition')}/export`;

    await this.server.request(url, 'GET', { data: this.exportParams });

    this.flashMessages.success(
      this.intl.t('settings.requisition.export_confirmation')
    );

    this.exportModal = false;
  }

  @action
  clearFilters() {
    setProperties(this, {
      location_id: '',
      division_id: '',
      department_id: '',
      status: '',
      user_id: '',
      recruiter_id: '',
      assignee: '',
      requisition_archive_reason_id: '',
      include_archived: false,
    });
  }

  @action
  selectItem(
    attribute: keyof RequisitionsController,
    selected: AllModels | null
  ) {
    this.page = 1;

    set(this as RequisitionsController, attribute, get(selected, 'id') ?? '');

    if (attribute === 'division_id' && selected) {
      this.department_id = '';
    }
  }

  @action
  setPage(page: number) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action
  toggleShowBulk() {
    if (this.showBulk) {
      this.clearSelection();
    }

    this.showBulk = !this.showBulk;
  }

  @action
  toggleSelectAll() {
    setProperties(this, {
      selectAll: !this.selectAll,
      deselectedRequisitionIds: [],
      selectedRequisitionIds: [],
    });
  }

  @action
  clearSelection() {
    setProperties(this, {
      selectAll: false,
      deselectedRequisitionIds: [],
      selectedRequisitionIds: [],
    });
  }

  @action
  refetchRequisitions() {
    if (this.page !== 1) {
      this.page = 1;
      this.clearSelection();
    } else {
      this.router.refresh('jobs.requisitions');
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.requisitions': RequisitionsController;
  }
}
