/* import __COLOCATED_TEMPLATE__ from './activity-item.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { CODE_ACTIVITY_MAP } from 'teamtailor/constants/activity';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import TimeFormatService from 'teamtailor/services/time-format';
import ActivityModel from 'teamtailor/models/activity';

interface ActivityItemArgs {
  activity: ActivityModel;
}

export default class ActivityItemComponent extends Component<ActivityItemArgs> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;

  @tracked showShareBody = false;
  @tracked _showEmailBody: boolean | undefined;

  get user() {
    return this.current.user;
  }

  get activity(): ActivityModel {
    return this.args.activity;
  }

  get notAutomaticMessage(): boolean {
    return this.activity.isMessage && this.activity.notAutomaticEmail;
  }

  get hasToolbar(): boolean {
    return this.notAutomaticMessage || this.hasReactions;
  }

  get hasReactions(): boolean {
    return !this.activity.isMessage;
  }

  get hasReviewComment(): boolean {
    return !isEmpty(this.activity.actionData.comment);
  }

  get hideEmailBody(): boolean {
    return this.activity.automaticEmail || this.activity.createdByTrigger;
  }

  get showEmailBody(): boolean {
    return this._showEmailBody !== undefined
      ? this._showEmailBody
      : !this.hideEmailBody;
  }

  get typeClass() {
    return `c-activity-item--${this.activity.code}`;
  }

  get contentComponentName() {
    return CODE_ACTIVITY_MAP[this.activity.code];
  }

  @action
  toggleEmailBody() {
    this._showEmailBody = !this._showEmailBody;
  }

  @action
  toggleShareBody() {
    this.showShareBody = !this.showShareBody;
  }
}
