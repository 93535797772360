/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { getSortOptionLabelString } from 'teamtailor/utils/sort-option-label-string';
import { argDefault } from 'teamtailor/utils/arg-default';

import { UNEDITABLE_STAGE_TYPES } from 'teamtailor/models/stage-type';
import HIDE_NEW_HIRE_PAGE_CARD from 'teamtailor/utils/new-hire-page';

export default class StageListSettings extends Component {
  @service flashMessages;
  @service intl;
  @service intercom;
  @service ttAlert;
  @service current;
  @service flipper;
  @service router;

  @tracked showGuideTime = false;
  @tracked showDeadline = false;
  @tracked showMoveRejectedCandidates = false;
  @argDefault showWideCards = false;

  today = new Date();

  get canChangeStageType() {
    const { stage } = this.args;

    return (
      !UNEDITABLE_STAGE_TYPES.includes(stage.category) &&
      !stage.inbox &&
      !stage.hired
    );
  }

  get showTooltip() {
    return !(
      this.showGuideTime ||
      this.showDeadline ||
      this.showMoveRejectedCandidates
    );
  }

  get anonymous() {
    return this.args.stage.anonymous;
  }

  get isHired() {
    return this.args.stage.hired;
  }

  get hirePageEnabled() {
    return this.args.stage.hirePageEnabled;
  }

  get nameSortOptionValue() {
    return 'candidate.firstName';
  }

  get nameSortOptionAnonymousValue() {
    return 'candidate.anonymousName';
  }

  get nameSortOption() {
    let nameValue = this.nameSortOptionValue;
    if (this.anonymous) nameValue = this.nameSortOptionAnonymousValue;

    return {
      value: nameValue,
      label: this.intl.t(getSortOptionLabelString(nameValue)),
      icon: 'font-case',
      defaultSortDirection: 'asc',
    };
  }

  get lastNameSortOption() {
    const lastNameValue = 'candidate.lastName';
    return {
      value: lastNameValue,
      label: this.intl.t(getSortOptionLabelString(lastNameValue)),
      icon: 'font-case',
      defaultSortDirection: 'asc',
    };
  }

  get assessmentScoreOption() {
    const assessmentValue = 'highestPartnerResultScore';
    return {
      value: assessmentValue,
      label: this.intl.t(getSortOptionLabelString(assessmentValue)),
      icon: 'ballot-check',
      defaultSortDirection: 'desc',
    };
  }

  get sortOptions() {
    const manualValue = 'rowOrder';
    const lastActivityValue = 'candidate.timeSinceLastActivity';
    const ratingValue = 'candidate.averageRating';
    const createdAtValue = 'timeSinceCreatedAt';
    const matchValue = 'match';

    const sortOptionsArray = [
      {
        value: manualValue,
        label: this.intl.t(getSortOptionLabelString(manualValue)),
        icon: 'hand',
        defaultSortDirection: 'asc',
      },
      ...(this.args.stage.anonymous
        ? []
        : [this.nameSortOption, this.lastNameSortOption]),
      {
        value: createdAtValue,
        label: this.intl.t(getSortOptionLabelString(createdAtValue)),
        icon: 'calendar',
        defaultSortDirection: 'asc',
      },
      {
        value: lastActivityValue,
        label: this.intl.t(getSortOptionLabelString(lastActivityValue)),
        icon: 'bolt',
        defaultSortDirection: 'asc',
      },
      {
        value: ratingValue,
        label: this.intl.t(getSortOptionLabelString(ratingValue)),
        icon: 'star',
        defaultSortDirection: 'desc',
      },
      {
        value: matchValue,
        label: this.intl.t(getSortOptionLabelString(matchValue)),
        icon: 'thumbs-up',
        defaultSortDirection: 'desc',
      },
    ];

    if (this.args.stage.partnerResults.length > 0) {
      sortOptionsArray.push(this.assessmentScoreOption);
    }

    return sortOptionsArray;
  }

  @action
  toggleWideCards() {
    this.args.onShowWideCards(!this.showWideCards);
  }

  @action
  async selectStageType(stageType) {
    set(this.args, 'stage.stageType', stageType);

    try {
      await this.args.stage.save();
      this.flashMessages.success('Updated stage type!');
    } catch {
      this.flashMessages.error("Couldn't update stage type.");
      this.args.stage.rollbackAttributes();
    }
  }

  checkSortObject() {
    const sortOptionNameValues = [
      this.nameSortOptionValue,
      this.nameSortOptionAnonymousValue,
    ];

    if (sortOptionNameValues.includes(this.args.sortObject.value)) {
      this.args.updateSortObject(this.nameSortOption);
    }
  }

  @action
  onAnonymousToggle(state) {
    set(this.args, 'stage.anonymous', !state);

    this.checkSortObject();

    return this.args.stage
      .save()
      .then(() => {
        this.flashMessages.success(
          this.args.stage.anonymous
            ? this.intl.t('components.stage_list.settings.anonymous.turned_on')
            : this.intl.t('components.stage_list.settings.anonymous.turned_off')
        );
      })
      .catch(() => {
        this.flashMessages.error(
          this.intl.t('components.stage_list.settings.anonymous.failed')
        );
        this.args.stage.rollbackAttributes();
      });
  }

  @action
  async onNewHireShareToggle(state) {
    const stage = get(this, 'args.stage');
    set(stage, 'hirePageEnabled', !state);

    try {
      await stage.save();

      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(HIDE_NEW_HIRE_PAGE_CARD)) {
          localStorage.removeItem(key);
        }
      });
    } catch {
      stage.rollbackAttributes();
    }
  }

  @action
  delete() {
    this.ttAlert.confirm(
      this.intl.t('components.stage_list.do_you_want_to_delete'),
      () => {
        let { stage } = this.args;
        return stage.destroyRecord().catch(function () {
          stage.rollback();
        });
      }
    );
  }

  @action
  onClose() {
    this.args.stage.rollbackAttributes();
  }

  @action
  handleTriggerSelect(type, partnerActivation = null, subtype) {
    const stage = get(this.args, 'stage');
    this.router.transitionTo(
      'jobs.job.stages.stage-triggers.new',
      get(stage, 'job'),
      {
        queryParams: {
          stageId: get(stage, 'id'),
          type,
          subtype,
          partnerActivationId: partnerActivation?.id,
          onReject: this.args.showRejected,
        },
      }
    );
  }
}
