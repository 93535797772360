/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import { action } from '@ember/object';
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { inject as service } from '@ember/service';

export default class LeadBaseComponent extends BaseComponent {
  @service intl;
  @service store;
  @service filthyContent;

  maxValuesCount = 6;

  get section() {
    return this.args.model;
  }

  get supportingContentLength() {
    return this.args.itemsArray?.length || 0;
  }

  get respondTimeOptions() {
    return [
      {
        text: this.intl.t('components.content_block.lead.base.30_min_time'),

        option: '30_minutes',
      },
      {
        text: this.intl.t('components.content_block.lead.base.1_hour_time'),

        option: '1_hour',
      },
      {
        text: this.intl.t('components.content_block.lead.base.4_hours_time'),

        option: '4_hours',
      },
      {
        text: this.intl.t('components.content_block.lead.base.1_day_time'),

        option: '1_day',
      },
      {
        text: this.intl.t('components.content_block.lead.base.2_days_time'),

        option: '2_days',
      },
      {
        text: this.intl.t('components.content_block.lead.base.4_days_time'),

        option: '4_days',
      },
      {
        text: this.intl.t('components.content_block.lead.base.1_week_time'),

        option: '1_week',
      },
    ];
  }

  @action
  handleRemoveImage() {
    this.section.pickedImages.lastObject.deleteRecord;
    this.section.pickedImages = [];
    this.filthyContent.setFilthy(this.section.id);
  }

  @action
  handlePickImage(image) {
    const model = this.section;
    const isNew = this.section.pickedImages.length === 0;
    let pickedImage =
      this.section.pickedImages.firstObject ||
      this.store.createRecord('picked-image', {
        section_id: model.id,
      });

    pickedImage.image = image;

    if (isNew) {
      pickedImage.save().then((pi) => {
        model.pickedImages.push(pi);
        this.filthyContent.setFilthy(this.section.id);
      });
    } else {
      this.filthyContent.setFilthy(this.section.id);
    }
  }

  @action
  selectRespondTimeOption({ option }) {
    this.section.respondTimeText = option;
  }
}
