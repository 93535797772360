/* import __COLOCATED_TEMPLATE__ from './empty.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { later, schedule } from '@ember/runloop';
import { modifier } from 'ember-modifier';

import JobModel from 'teamtailor/models/job';

interface Args {
  job: JobModel;
}

export default class extends Component<Args> {
  @tracked visible = false;
  @tracked thinkingDotsIndex = 0;

  get thinkingDots() {
    return ['', '.', '..', '...'][this.thinkingDotsIndex];
  }

  visibilityDelay = modifier((_) => {
    later(() => {
      this.visible = true;
    }, 1000);
  });

  updateDotsInterval = modifier((_) => {
    schedule('afterRender', () => {
      const interval = setInterval(() => {
        this.updateDots();
      }, 500);

      return () => {
        clearInterval(interval);
      };
    });
  });

  updateDots() {
    this.thinkingDotsIndex = (this.thinkingDotsIndex + 1) % 4;
  }
}
