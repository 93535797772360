/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { bind, once } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import styles from './content.module.scss';
import { trackedFunction } from 'ember-resources/util/function';
import { handleActivities } from 'teamtailor/utils/candidate-modal/pusher';
import { modifier } from 'ember-modifier';

export default class ToolboxMessagesContentComponent extends Component {
  @service pusher;
  @service store;
  @service notifications;
  @service current;
  @service router;
  @service conversationQuery;
  @service candidateModalFeed;
  @service onlineCandidates;

  @tracked showJobTitleTooltip = false;
  @tracked showReassignModal = false;
  @tracked showAddUsersModal = false;

  @tracked candidatesOnline = [];
  channel = null;

  get jobApplication() {
    return this.resolveModels.value?.jobApplication;
  }

  get isAnonymous() {
    return get(this.conversation, 'isAnonymous');
  }

  get conversation() {
    return this.args.conversation;
  }

  get user() {
    return this.current.user;
  }

  get isAssignedUser() {
    return this.user.id === this.conversation.belongsTo('assignedUser').id();
  }

  get candidate() {
    return this.resolveModels.value?.candidate;
  }

  get isOnline() {
    if (!this.candidate) {
      return false;
    }

    return this.onlineCandidates.members.includes(this.candidate.uuid);
  }

  resolveModels = trackedFunction(this, async () => {
    const [candidate, jobApplication] = await Promise.all([
      get(this.conversation, 'candidate'),
      get(this.conversation, 'jobApplication'),
    ]);

    return { candidate, jobApplication };
  });

  async setupPusher() {
    if (this.channel === null || !this.channel.subscribed) {
      this.channel = await this.pusher.subscribe(
        this.current.company.candidatesChannel
      );
    }

    this.channel.bind('new-activity', this.handleActivityPush);
    this.channel.bind('updated-activity', this.handleActivityPush);
    this.channel.bind('updated-messages', this.handleUpdatedMessages);
  }

  handleUpdatedMessages = (data) => {
    data.messages = data.messages.filter((message) =>
      this.store.peekRecord('message', message.id)
    );

    if (data.messages.length > 0) {
      this.store.pushPayload(data);
    }
  };

  handleActivityPush = (data) => {
    handleActivities(this.candidate, this.store, data, this.candidateModalFeed);
  };

  teardownPusher() {
    if (this.channel) {
      this.channel.unbind('new-activity', this.handleActivityPush);
      this.channel.unbind('updated-activity', this.handleActivityPush);
      this.channel.unbind('updated-messages', this.handleUpdatedMessages);
    }
  }

  async markCurrentCandidateMessagesAsRead() {
    let notificationsChannel;
    try {
      notificationsChannel = await this.pusher.subscribe(
        this.user.notificationsChannel
      );
    } catch {
      return;
    }

    notificationsChannel.bind(
      'new-notification',
      bind(this, ({ unread_conversations }) => {
        if (
          unread_conversations.includes(
            parseInt(get(this.conversation, 'id'), 10)
          )
        ) {
          this.markAsRead();
        }
      })
    );
  }

  async markAsRead() {
    if (this.candidate) {
      const candidateId = get(this.candidate, 'id');
      await this.notifications.markAsRead({
        candidateId,
        exclude: 'actions',
      });
    }
  }

  closeConversation() {
    this.router.transitionTo({
      queryParams: { toolbox: this.args.query },
    });
  }

  handleInsert = modifier((element) => {
    this.containerElement = element;

    (async () => {
      await this.setupPusher();

      const candidate = await get(this.conversation, 'candidate');
      if (candidate && this.conversation) {
        this.markCurrentCandidateMessagesAsRead();
      }
      this.markAsRead();
    })();

    return () => {
      this.teardownPusher();
    };
  });

  @action
  handleDidInsert(element) {
    this.containerElement = element;
  }

  @action
  handleNewJobTitle() {
    const jobTitleElement = this.containerElement.querySelector(
      `.${styles.jobTitle}`
    );
    const textOverFlows =
      jobTitleElement.offsetWidth < jobTitleElement.scrollWidth;

    this.showJobTitleTooltip = textOverFlows;
  }

  @action
  handleConversationAction(conversation, memberAction) {
    this.conversationQuery
      .handleConversationAction(conversation, memberAction)
      .then(() => {
        if (
          ['leaveConversation', 'archive', 'unarchive'].includes(memberAction)
        ) {
          this.closeConversation();
        }
      });
  }

  @action
  handleConversationReassigned(conversation) {
    this.conversationQuery.handleConversationReassigned(conversation);
    this.closeConversation();
  }
}
