/* import __COLOCATED_TEMPLATE__ from './create-qr-code-for-page.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { debounce } from '@ember/runloop';

export default class ModalsCreateQrCodeForPageComponent extends Component {
  _utmSource = 'QR Code';
  @tracked utmSource = 'QR Code';
  @tracked qrCodeSettings = null;

  get url() {
    const query = isEmpty(this.utmSource)
      ? ''
      : `?utm_source=${encodeURIComponent(this.utmSource)}`;

    return this.args.page.publicUrl + query;
  }

  updateTrackedUtmSource() {
    this.utmSource = this._utmSource;
  }

  @action
  handleUtmSourceChange() {
    debounce(this, 'updateTrackedUtmSource', 500);
  }

  @action
  handleQrCodeSettinsChange(settings) {
    this.qrCodeSettings = settings;
  }
}
