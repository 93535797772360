/* import __COLOCATED_TEMPLATE__ from './location-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import LocationModel from 'teamtailor/models/location';
import { query } from 'ember-data-resources';
import { tracked } from '@glimmer/tracking';
import { formatLocationOptions } from 'teamtailor/utils/locations-picker';
import { IntlService } from 'ember-intl';

type LocationPickerArgs = {
  isMultiple?: boolean;
  selected?: LocationModel[] | LocationModel;
  isFullWidth?: boolean;
  regionId?: string;
  jobId?: string;
};

export default class LocationPickerComponent extends Component<LocationPickerArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;

  @tracked query = '';

  get locations() {
    return (this.locationsQuery.records || []) as unknown as LocationModel[];
  }

  get options() {
    return formatLocationOptions(this.locations, this.intl);
  }

  get isFullWidth(): boolean {
    return this.args.isFullWidth !== undefined ? this.args.isFullWidth : true;
  }

  onClose = () => {
    if (this.query !== '') {
      this.query = '';
    }
  };

  locationsQuery = query(this, 'location', () => {
    return {
      per_page: 25,
      name_or_city: this.query,
      sort_column: this.args.regionId ? null : 'region',
      with_fallback_sorting: true,
      region_id: this.args.regionId,
      job_id: this.args.jobId,
    };
  });
}
