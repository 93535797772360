/* import __COLOCATED_TEMPLATE__ from './careersite-form.hbs'; */
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import Component from '@glimmer/component';
import { Promise } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import {
  timeout,
  keepLatestTask,
  enqueueTask,
  restartableTask,
} from 'ember-concurrency';
import { argDefault } from 'teamtailor/utils/arg-default';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import FilthyContent from 'teamtailor/services/filthy-content';
import {
  BlogModel,
  CareerSiteModel,
  DesignModel,
  SectionModel,
} from 'teamtailor/models';
import { MaybeAsync } from 'teamtailor/utils/type-utils';

export const SECTION_NAMES_WITH_ADJUSTMENTS = [
  'text',
  'companies',
  'custom',
  'cover',
  'departments-list',
  'faq',
  'gallery',
  'grid',
  'group-jobs',
  'header',
  'infographic',
  'jobs',
  'jobs-list',
  'list',
  'locations-list',
  'locations-map',
  'numbers',
  'people',
  'picture',
  'social-links',
  'stories',
  'testimonial',
  'timeline',
  'video',
  'workplace',
];

type CareerSiteFormSignature = {
  Args: {
    design?: MaybeAsync<DesignModel>;
    section?: MaybeAsync<SectionModel>;
    blog: MaybeAsync<BlogModel>;
    careerSite: MaybeAsync<CareerSiteModel>;
    sectionItems?: MaybeAsync<SectionModel[]>;
    showSave?: boolean;
    saveButtonTitle?: string;
    saveCustomModel?: () => Promise<void>;
    forceIframeRefresh?: boolean;
    clearNestedAttributesAfterSave?: boolean;
    afterSave?: () => void;
    customReloadTask?: () => void;
    autoSaveDebounce?: number | false;
  };
};

type RelationshipModelNames =
  | 'design'
  | 'section'
  | 'blog'
  | 'careerSite'
  | 'sectionItems';

export default class CareersiteForm extends Component<CareerSiteFormSignature> {
  @service declare iframePreview: IframePreviewService;
  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare filthyContent: FilthyContent;

  @tracked showStyleOptions = false;

  @argDefault autoSaveDebounce = 50;

  blocksWithAdjustments = SECTION_NAMES_WITH_ADJUSTMENTS;

  get design() {
    return this.args.design;
  }

  get section() {
    return this.args.section;
  }

  get blog() {
    return this.args.blog;
  }

  get careerSite() {
    return this.args.careerSite;
  }

  get sectionItems() {
    return this.args.sectionItems ?? get(this.args.section, 'sectionItems');
  }

  get imageWithSetting() {
    return get(this.section, 'imageWithSetting');
  }

  get showSave() {
    return this.args.showSave || false;
  }

  get company() {
    return this.current.company;
  }

  get isInValid() {
    return this.sectionIsInvalid;
  }

  get isDirty() {
    return (
      this.sectionIsDirty ||
      get(this.company, 'hasDirtyAttributes') ||
      get(this.design, 'hasDirtyAttributes') ||
      get(this.careerSite, 'hasDirtyAttributes') ||
      get(this.blog, 'hasDirtyAttributes') ||
      get(this.imageWithSetting, 'hasDirtyAttributes') ||
      this.saveTask.isRunning ||
      this.delayButtonTask.isRunning ||
      this.showSave
    );
  }

  get hideSaveButton() {
    return (!this.isDirty || this.isInValid) && !this.showSave;
  }

  get sectionIsInvalid() {
    return (
      !get(this.section, 'isValid') ||
      get(this.section, 'hasInvalidAssociations')
    );
  }

  get sectionIsDirty() {
    if (this.filthyContent.isFilthy(get(this.section, 'id'))) {
      return true;
    }

    const hasDirtyAssociations = get(this.section, 'hasDirtyAssociations');
    const hasDirtyAttributes = get(this.section, 'hasDirtyAttributes');

    return hasDirtyAttributes || hasDirtyAssociations;
  }

  get alwaysShowWrapperClassInput() {
    const sectionType = get(this.section, 'name');
    const hasCustomClassName = get(this.section, 'hasCustomClassName');
    const hasNewBlockAdjustments = this.blocksWithAdjustments.includes(
      sectionType!
    );

    return hasCustomClassName && hasNewBlockAdjustments;
  }

  get showWrapperClassToggle() {
    const sectionType = get(this.section, 'name');
    return !this.blocksWithAdjustments.includes(sectionType!);
  }

  get saveButtonText() {
    if (this.args.saveButtonTitle) {
      return this.args.saveButtonTitle;
    } else if (get(this.section, 'globalSection')) {
      return this.intl.t('common.save_and_publish');
    } else {
      return this.intl.t('common.save');
    }
  }

  save() {
    return this.args.saveCustomModel
      ? this.args.saveCustomModel()
      : this.saveModels();
  }

  async saveModel(modelName: RelationshipModelNames) {
    if (this[modelName] as unknown) {
      return (await this[modelName])?.save();
    }
  }

  findAndSaveModels(...modelNames: RelationshipModelNames[]) {
    return Promise.all(
      modelNames.map((modelName) => {
        return this.saveModel(modelName);
      })
    );
  }

  saveModels() {
    return this.findAndSaveModels(
      'design',
      'section',
      'blog',
      'careerSite',
      'sectionItems'
    );
  }

  saveAndReload = restartableTask(async () => {
    if (this.autoSaveDebounce) {
      await timeout(this.autoSaveDebounce);
    }

    await (await this.args.section)?.save();

    this.reload();
  });

  @action async reload() {
    if (this.args.forceIframeRefresh) {
      this.iframePreview.reloadIframe();
    } else {
      const sectionId = get(this.section, 'id');
      this.iframePreview.reloadSection(sectionId);
    }

    if (this.design) {
      this.iframePreview.reloadStyleguideIframe();
    }
  }

  markPageUnpublished() {
    const page = get(this.section, 'page');
    if (
      this.section &&
      page &&
      !get(this.section, 'globalSection') &&
      !(get(page, 'isTemplatePage') && get(page, 'hasNoJobsUsing'))
    ) {
      set(page, 'unpublishedChanges', true);
    }
  }

  delayButtonTask = keepLatestTask(async () => {
    await timeout(2000);
  });

  saveTask = enqueueTask(async () => {
    await timeout(100); // Color pickers need to trigger their change event before we can save
    await this.save();
    this.delayButtonTask.perform();

    if (this.args.clearNestedAttributesAfterSave) {
      get(this.section, 'pickedImages')?.forEach((pickedImage) => {
        if (get(pickedImage, 'isNew')) {
          pickedImage.unloadRecord();
        }
      });
    }

    this.args.afterSave?.();

    this.markPageUnpublished();
    if (this.args.customReloadTask) {
      this.args.customReloadTask();
    } else {
      this.reload();
    }

    const id = get(this.section, 'id');
    if (id) {
      this.filthyContent.setClean(id);
    }
  });
}
