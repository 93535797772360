/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { FetchUserResult } from './event';
import { IUser } from 'teamtailor/components/meeting/types';
import Component from '@glimmer/component';
import {
  UserAttendeeClass,
  CandidateAttendeeClass,
  MeetingEventClass,
} from 'teamtailor/classes/meetings';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import { enqueueTask } from 'ember-concurrency';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import GET_USERS from 'teamtailor/gql/users-queries.graphql';

type TimeZone = {
  id: string;
  gmt: string;
};

type SearchQuery = {
  page: number;
  searchTerm: string;
};

interface Args {
  event?: MeetingEventClass;
  teamMembers: UserAttendeeClass[];
  bookableMeetingRooms: MeetingRoomAttendeeClass[];
  rawEvents: any[];
  onChange?: () => void;
  onSetBuffer: (arg: number) => void;
  onSetDuration: (arg: number) => void;
  onSetStartInterval: (arg: number) => void;
  onSetRequired: (arg: number | 'all') => void;
}

type OptionType = {
  value: number;
  format: string;
};

export default class EventScheduler extends Component<Args> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service declare timezones: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service timeFormat: any;
  @service declare intl: IntlService;
  @service declare apollo: TeamtailorApolloService;

  options: OptionType[] = [];
  bufferOptions: OptionType[] = [];
  intervalOptions: OptionType[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setDurationOptions();
    this.setBufferOptions();
    this.setIntervalOptions();
  }

  get organizer(): UserAttendeeClass | undefined {
    return this.args.event?.organizer;
  }

  get organizerHasCronofy(): boolean {
    return this.organizer?.hasConnectedCalendar ?? false;
  }

  get users(): UserAttendeeClass[] | undefined {
    return this.args.event?.meetingEventAttendees.userAttendees;
  }

  get usersWithCalendars(): UserAttendeeClass[] | undefined {
    return this.args.event?.meetingEventAttendees.userAttendeesWithCalendar;
  }

  get usersWithoutCalendars(): UserAttendeeClass[] | undefined {
    return this.args.event?.meetingEventAttendees.userAttendeesWithoutCalendar;
  }

  get candidates(): CandidateAttendeeClass[] {
    const { candidateAttendees } = this.args.event!.meetingEventAttendees;
    return candidateAttendees.length > 5 ? [] : candidateAttendees;
  }

  get candidateIds(): (string | undefined)[] {
    return this.candidates.map(
      (candidateAttendees) => candidateAttendees.candidate?.id
    );
  }

  get meetingRooms(): MeetingRoomAttendeeClass[] | undefined {
    return this.args.event?.meetingEventAttendees.meetingRoomAttendees;
  }

  get teamMembersWithCalendars(): UserAttendeeClass[] {
    return this.args.teamMembers.filter(
      (attendee) => attendee.hasConnectedCalendar
    );
  }

  get timezoneDisplayName(): string | undefined {
    const tz: TimeZone | undefined = this.timezones.all.findBy(
      'id',
      this.args.event?.tzid
    );
    return tz ? tz.gmt : this.args.event?.tzid;
  }

  get startDateLabel(): string {
    return moment(this.rawEvent.start)
      .tz(this.args.event!.tzid)
      .locale(this.timeFormat.locale)
      .format('dddd, MMM D');
  }

  get unselectedMeetingRooms(): MeetingRoomAttendeeClass[] {
    return this.args.bookableMeetingRooms.filter(
      (attendee) =>
        !this.meetingRooms?.some(
          (attendeeInList) =>
            attendee.meetingRoom?.id === attendeeInList.meetingRoom?.id
        )
    );
  }

  fetchUsersTask = enqueueTask(async (query: SearchQuery) => {
    const { usersConnection }: FetchUserResult = await this.apollo.query({
      query: GET_USERS,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          query: query.searchTerm,
          candidateIds: this.candidateIds,
        },
      },
    });

    if (usersConnection.nodes.length) {
      const convertedUsers: UserAttendeeClass[] = usersConnection.nodes.map(
        (user: IUser) => {
          return UserAttendeeClass.from(user);
        }
      );
      return convertedUsers.filter(
        (attendee: UserAttendeeClass) =>
          !this.users?.some(
            (attendeeInList) => attendee.user?.id === attendeeInList.user?.id
          )
      );
    }

    return [];
  });

  get rawEvent(): any {
    return this.args.rawEvents.firstObject;
  }

  setDurationOptions = (): void => {
    this.options = [
      {
        value: 15,
        format: this.intl.t('components.meetings.durations.duration1', {
          fifteen: 15,
        }),
      },
      {
        value: 30,
        format: this.intl.t('components.meetings.durations.duration2', {
          thirty: 30,
        }),
      },
      {
        value: 45,
        format: this.intl.t('components.meetings.durations.duration3', {
          fortyfive: 45,
        }),
      },
      {
        value: 60,
        format: this.intl.t('components.meetings.durations.duration4', {
          one: 1,
        }),
      },
      {
        value: 90,
        format: this.intl.t('components.meetings.durations.duration5', {
          one: 1,
          thirty: 30,
        }),
      },
      {
        value: 120,
        format: this.intl.t('components.meetings.durations.duration6', {
          two: 2,
        }),
      },
      {
        value: 150,
        format: this.intl.t('components.meetings.durations.duration7', {
          two: 2,
          thirty: 30,
        }),
      },
    ];
  };

  setBufferOptions = (): void => {
    this.bufferOptions = [
      {
        value: 0,
        format: this.intl.t('common.x_minutes', { count: 0 }),
      },
      {
        value: 5,
        format: this.intl.t('common.x_minutes', { count: 5 }),
      },
      {
        value: 10,
        format: this.intl.t('common.x_minutes', { count: 10 }),
      },
      {
        value: 15,
        format: this.intl.t('common.x_minutes', { count: 15 }),
      },
      {
        value: 20,
        format: this.intl.t('common.x_minutes', { count: 20 }),
      },
      {
        value: 30,
        format: this.intl.t('common.x_minutes', { count: 30 }),
      },
      {
        value: 45,
        format: this.intl.t('common.x_minutes', { count: 45 }),
      },
    ];
  };

  setIntervalOptions = (): void => {
    this.intervalOptions = [
      {
        value: 5,
        format: this.intl.t('common.x_minutes', { count: 5 }),
      },
      {
        value: 10,
        format: this.intl.t('common.x_minutes', { count: 10 }),
      },
      {
        value: 15,
        format: this.intl.t('common.x_minutes', { count: 15 }),
      },
      {
        value: 20,
        format: this.intl.t('common.x_minutes', { count: 20 }),
      },
      {
        value: 30,
        format: this.intl.t('common.x_minutes', { count: 30 }),
      },
      {
        value: 60,
        format: this.intl.t('common.x_minutes', { count: 60 }),
      },
    ];
  };

  get duration(): number {
    return this.args.event?.selfSchedule?.duration ?? 60;
  }

  get bufferBefore(): number {
    return this.args.event?.selfSchedule?.bufferBefore ?? 0;
  }

  get bufferAfter(): number {
    return this.args.event?.selfSchedule?.bufferAfter ?? 0;
  }

  get startInterval(): number {
    return this.args.event?.selfSchedule?.startInterval ?? 30;
  }

  get required(): number | 'all' {
    return this.args.event?.selfSchedule?.required ?? 'all';
  }

  @action
  setStartDate(time: moment.Moment): void {
    if (this.rawEvent) {
      const { start } = this.rawEvent;
      const momentStartsAt = moment(start).tz(this.args.event!.tzid);

      /* eslint-disable ember/use-ember-get-and-set */
      momentStartsAt.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
      });
      /* eslint-enable ember/use-ember-get-and-set */

      this.rawEvent.setStart(momentStartsAt.toISOString(), {
        maintainDuration: true,
      });
    }
  }

  @action
  setEndDate(time: moment.Moment): void {
    if (this.args.event?.bookedSlot) {
      this.rawEvent.setEnd(time.toISOString());
    }
  }

  @action
  handleDurationChange(arg: OptionType): void {
    this.args.onSetDuration(arg.value);
  }

  @action
  handleBufferChange(arg: OptionType): void {
    this.args.onSetBuffer(arg.value);
  }

  @action
  handleIntervalChange(arg: OptionType): void {
    this.args.onSetStartInterval(arg.value);
  }

  @action
  setRequired(index: number | 'all'): void {
    this.args.onSetRequired(index);
  }
}
