/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout, task, keepLatestTask } from 'ember-concurrency';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import FlashMessages from 'teamtailor/services/flash-message';
import Router from '@ember/routing/router-service';
import DashboardWidgetModel from 'teamtailor/models/dashboard-widget';
import ImageModel from 'teamtailor/models/image';
import imageUrl from 'teamtailor/utils/image-url';

type DashboardWidgetFormImageArgs = {
  widget: DashboardWidgetModel;
  route: string;
  onSave?: () => void;
  onClose?: () => void;
};

export default class DashboardWidgetFormImageComponent extends Component<DashboardWidgetFormImageArgs> {
  @service declare flashMessages: FlashMessages;
  @service declare router: Router;
  @service declare store: Store;
  @service declare current: Current;

  @tracked imageUrl = this.args.widget.options.imageUrl;

  get allFieldsNotPresent(): boolean {
    return !(this.args.widget.title && this.imageUrl);
  }

  delayedCloseTask = task(async () => {
    await timeout(800);
    this.handleCloseModal();
  });

  saveTask = keepLatestTask(async () => {
    this.args.widget.options = {
      ...this.args.widget.options,
      imageUrl: this.imageUrl,
    };

    await this.args.widget.save();

    if (this.args.onSave) {
      await this.args.onSave();
    }

    await this.delayedCloseTask.perform();
    return true;
  });

  destroyTask = keepLatestTask(async () => {
    const dashboardWidgetId = parseInt(this.args.widget.id, 10);
    await this.args.widget.destroyRecord();

    this.store
      .peekAll('picked-dashboard-widget')
      .forEach((pickedDashboardWidget) => {
        if (pickedDashboardWidget.dashboardWidgetId === dashboardWidgetId) {
          pickedDashboardWidget.unloadRecord();
        }
      });

    return true;
  });

  @action
  handleCloseModal(): void {
    if (this.args.onClose) {
      this.args.onClose();
    }

    if (this.args.route) {
      this.router.transitionTo(this.args.route);
    }
  }

  @action
  handlePickImage(image: ImageModel): void {
    this.imageUrl = imageUrl(image.s3Image, 'width_800') || undefined;
  }
}
