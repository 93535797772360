/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { keyResponder, onKey } from 'ember-keyboard';
import { action } from '@ember/object';
import isBetween from 'teamtailor/utils/is-between';

const COLUMNS = 4;
@keyResponder
export default class ImageMasonryComponent extends Component {
  element = null;
  focusedPoint = null;
  keyboardPriority = 100;

  get columns() {
    if (this.args.images.length === 0) {
      return [];
    }

    const columns = Array.from({ length: COLUMNS }).map(() => {
      return [];
    });
    const columnHeights = Array(COLUMNS).fill(0);

    this.args.images.forEach((image, index) => {
      let col =
        index < COLUMNS ? index % COLUMNS : this.getLowestColumn(columnHeights);

      columnHeights[col] += this.getImageHeight(image);
      columns[col].push(image);
    });

    return columns;
  }

  get lastColumnIndex() {
    return COLUMNS - 1;
  }

  getLowestColumn(columnHeights) {
    const min = Math.min(...columnHeights);
    return columnHeights.indexOf(min);
  }

  getImageHeight(image) {
    return Math.floor((image.height / image.width) * 100) || 0;
  }

  getNextX(switchY, x, direction) {
    if (switchY) {
      return direction < 0 ? this.lastColumnIndex : 0;
    }

    return x + direction;
  }

  getNextY(switchY, y, direction) {
    return switchY ? y + direction : y;
  }

  getNextPoint(shiftPressed) {
    const { x, y } = this.focusedPoint;
    const direction = shiftPressed ? -1 : 1;
    const switchY = !isBetween(x + direction, 0, this.lastColumnIndex);

    return {
      x: this.getNextX(switchY, x, direction),
      y: this.getNextY(switchY, y, direction),
    };
  }

  getNextFocusElement(shiftPressed) {
    const { x, y } = this.getNextPoint(shiftPressed);

    if (y < 0) {
      return null;
    }

    const column = this.element.children[x];
    const element = column.children[y];

    return element?.tabIndex === -1
      ? element.querySelector('[tabindex="0"]')
      : element;
  }

  isMasonryListImage(element) {
    return !!element.dataset.masonryListImage;
  }

  @onKey('Enter')
  selectImage({ target }) {
    if (this.isMasonryListImage(target)) {
      target.click();
    }
  }

  @onKey('Tab')
  handleTab(event) {
    if (!this.element.contains(event.target)) {
      return;
    }

    const nextElement = this.getNextFocusElement(event.shiftKey);

    if (nextElement) {
      event.preventDefault();
      nextElement.focus();
    }
  }

  @action
  handleInsertElement(element) {
    this.element = element;
  }

  @action
  handleFocus(point) {
    this.focusedPoint = point;
  }
}
