/* import __COLOCATED_TEMPLATE__ from './delete.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EmployeeBulkArgs } from 'teamtailor/constants/employee';
import UserModel from 'teamtailor/models/user';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { restartableTask } from 'ember-concurrency';

export default class BulkActionsEmployeesDeleteComponent extends Component<EmployeeBulkArgs> {
  @service declare current: Current;

  @tracked replacementUser: UserModel | null = null;
  @tracked hasSelectedReplacement = false;
  @tracked needsReplacement = false;
  @tracked confirmationNumber = '';

  get showReplacementStep(): boolean {
    return !this.hasSelectedReplacement && this.needsReplacement;
  }

  get excludedUserIds(): string[] {
    return this.args.selectedIds;
  }

  get includedUserIds(): string[] {
    return this.args.deselectedIds;
  }

  get hasCorrectConfirmationNumber(): boolean {
    return Number(this.confirmationNumber) === this.args.selectedCount;
  }

  get hasSelectedManager(): boolean {
    return this.args.selectedEmployees.some(
      (employee: UserModel) => employee.manager
    );
  }

  async isUserNeedingReplacement(user: UserModel): Promise<boolean> {
    const [jobs, nurtureCampaignSteps, requisitionOwner, requisitionRecruiter] =
      await Promise.all([
        user.jobs,
        user.nurtureCampaignSteps,
        user.requisitionOwner,
        user.requisitionRecruiter,
      ]);

    return (
      !!jobs.length ||
      !!nurtureCampaignSteps.length ||
      !!requisitionOwner.length ||
      !!requisitionRecruiter.length
    );
  }

  fetchUserResponsibilites = restartableTask(async () => {
    this.needsReplacement = false;

    for (const employee of this.args.selectedEmployees) {
      // eslint-disable-next-line no-await-in-loop
      const needReplacement = await this.isUserNeedingReplacement(employee);
      if (needReplacement) {
        this.needsReplacement = true;
        break;
      }
    }
  });

  @action
  onClose(): void {
    this.args.onClose();
    this.replacementUser = null;
    this.hasSelectedReplacement = false;
  }

  @action
  handleBulkChange(): void {
    const data = {
      replacement_user_id: this.replacementUser?.id,
    };
    this.args.handleBulkChange(data, 'bulk_delete');
    this.onClose();
  }
}
