/* import __COLOCATED_TEMPLATE__ from './report-filter.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import AnalyticsService from 'teamtailor/services/analytics';
import IntlService from 'ember-intl/services/intl';
import { FilterOption } from './fancy-filters';
import SerializableFilter from './fancy-filters/serializable-filter';
import { gql } from '@apollo/client/core';
import ApolloService from 'ember-apollo-client/services/apollo';
import { restartableTask } from 'ember-concurrency';
import Current from 'teamtailor/services/current';

const JOB_STATS_QUERY = gql`
  query TotalJobsCountQuery(
    $filter: JobsFilterInput
    $userId: ID!
    $companyIds: [ID!]
  ) {
    jobs: aggregatedJobs(
      filter: $filter
      userScope: { userId: $userId }
      groupCompanyIds: $companyIds
    ) {
      totalJobsCount
    }
  }
`;

type Args = {
  hideGroupCompanyAnalytics?: boolean;
};

export default class ReportFilterComponent extends Component<Args> {
  @service declare analytics: AnalyticsService;
  @service declare store: Store;
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare apollo: ApolloService;

  get jobCount() {
    const jobIds = this.analytics.selectedJobIds;
    if (Array.isArray(jobIds)) {
      return jobIds.length;
    }

    return this.fetchAllJobsCount.lastSuccessful?.value;
  }

  get isLoading() {
    return (
      this.analytics.fetchSchemaTask.isRunning ||
      this.analytics.fetchMatchingJobs.isRunning ||
      this.fetchAllJobsCount.isRunning
    );
  }

  fetchAllJobsCount = restartableTask(async () => {
    const companyIds = this.args.hideGroupCompanyAnalytics
      ? [this.current.company.id]
      : this.analytics.availableCompanyIds;
    const { jobs }: { jobs: { totalJobsCount: number } } =
      await this.apollo.query({
        query: JOB_STATS_QUERY,
        variables: {
          userId: this.current.user.id,
          companyIds,
          filter: {
            status: ['open', 'unlisted', 'archived'],
          },
        },
      });

    return jobs.totalJobsCount;
  });

  @action
  handleSelectFilter(filterOption: FilterOption) {
    this.analytics.addFilter(filterOption);
  }

  @action
  handleRemoveFilter(filter: SerializableFilter) {
    this.analytics.removeFilter(filter);
  }

  @action
  compareAndUpdateFiltersQueryParam(newQueryParam: string) {
    if (this.analytics.encodedFilters !== newQueryParam) {
      set(this.analytics, 'encodedFilters', newQueryParam);
    }
  }
}
