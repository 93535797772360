/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import JobApplicationModel from 'teamtailor/models/job-application';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { later } from '@ember/runloop';
import { modifier } from 'ember-modifier';
import { AsyncHasMany } from '@ember-data/model';

interface Args {
  jobApplication?: JobApplicationModel;
  jobApplications: AsyncHasMany<JobApplicationModel>;
}

export default class extends Component<Args> {
  @tracked showAll = false;
  @tracked loading = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    later(() => {
      this.loading = false;
    }, 300);
  }

  get restOfJobApplications() {
    if (this.args.jobApplications.isDestroying) {
      return [];
    }
    return this.args.jobApplications
      .filter((jobApplication) => {
        return jobApplication.id !== this.args.jobApplication?.id;
      })
      .sort((a, b) => {
        if (a.isRejected && !b.isRejected) {
          return 1;
        } else if (!a.isRejected && b.isRejected) {
          return -2;
        } else {
          return a.createdAt > b.createdAt ? -1 : 1;
        }
      });
  }

  get restOfJobApplicationsWithAccess() {
    return this.restOfJobApplications.filter((jobApplication) => {
      return get(jobApplication.job, 'userHasAccess');
    });
  }

  get activeApplicationsCount() {
    return this.restOfJobApplications.filter((jobApplication) => {
      return !jobApplication.isRejected;
    }).length;
  }

  handleCandidateChange = modifier((_element, [_candidate]) => {
    this.showAll = false;
    this.loading = true;

    later(() => {
      this.loading = false;
    }, 300);
  });
}
