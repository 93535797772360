/* import __COLOCATED_TEMPLATE__ from './sourced.hbs'; */
import Base from './base';
import { get } from 'teamtailor/utils/get';

export default class SourcedComponent extends Base {
  get isExternallySourced() {
    return (
      get(this.activity, 'candidate.isExternallySourced') &&
      get(this.activity, 'user.externalRecruiter')
    );
  }

  get isSourcedByLinkedin() {
    return get(this.activity, 'candidate.linkedinId');
  }
}
