/* import __COLOCATED_TEMPLATE__ from './reject-settings-form.hbs'; */
import { argDefault } from 'teamtailor/utils/arg-default';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import RejectSettings from 'teamtailor/services/reject-settings';
import IntlService from 'ember-intl/services/intl';
import Copilot from 'teamtailor/services/copilot';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';
import CannedResponse from 'teamtailor/models/canned-response';
import JobDetail from 'teamtailor/models/job-detail';
import Job from 'teamtailor/models/job';
import RejectReason from 'teamtailor/models/reject-reason';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';
import ArrayProxy from '@ember/array/proxy';
import { next } from '@ember/runloop';
import {
  GroupedOrganizable,
  fetchGroupedAssetsByContext,
} from 'teamtailor/utils/filter-assets';
import OrganizableModel from 'teamtailor/models/organizable';

const JOB_CUSTOM_EMAIL_NAMESPACE = 'custom_job_email';
const DAY_IN_SECONDS = 86400;

type RejectSettingsFormArgs = {
  rejectReason?: RejectReason;
  sendMessage: boolean;
  template?: CannedResponse;
  customSubject: string;
  customBody: string;
  delay: number | string;
  delayTimestamp?: string;
  includeCustomDelay?: boolean;
  job?: Job;
  jobDetail?: JobDetail;
  useTranslationFor?: string;
  noEmail?: boolean;
  jobApplicationId?: number;
  size?: string;
  isCustomDelayRelative: boolean;

  onChangeRejectReason: (rejectReason: RejectReason | null | undefined) => void;
  onChangeSendMessage: (sendMessage: boolean) => void;
  onChangeTemplate: (template: CannedResponse | undefined) => void;
  onChangeCustomSubject: (subject: string | null) => void;
  onChangeCustomBody: (subject: string | null) => void;
  onChangeDelay: (value: number | string | null) => void;
  onChangeDelayTimestamp?: (timestamp: string | null) => void;
};

enum TemplateField {
  Body = 'body',
  Subject = 'subject',
}

type RejectTemplate = {
  body: string;
  subject: string;
};

export default class RejectSettingsForm extends Component<RejectSettingsFormArgs> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare rejectSettings: RejectSettings;
  @service declare intl: IntlService;
  @service declare copilot: Copilot;

  @tracked usingCustomMessage = false;
  @tracked templates?: CannedResponse[];
  @tracked translationToUse?: RejectTemplate | null = null;
  @tracked rejectReasons?: ArrayProxy<RejectReason>;

  @argDefault size = 'large';
  @argDefault isCustomDelayRelative = false;

  get delayOptions() {
    if (this.args.includeCustomDelay) {
      const tomorrow = moment().add(1, 'day').hour(9).minutes(0).toISOString();

      return [
        ...this.rejectSettings.defaultDelayOptions,
        {
          value: tomorrow,
          text: this.intl.t('components.reject_settings_form.custom'),
        },
      ];
    } else {
      return this.rejectSettings.defaultDelayOptions;
    }
  }

  get clearDelayEnabled(): boolean {
    return !!this.args.delayTimestamp;
  }

  get delayIsCustom() {
    return this.args.delay && typeof this.args.delay === 'string';
  }

  get jobDetail(): JobDetail | undefined {
    return this.args.jobDetail;
  }

  get rejectReasonsWithoutHidden() {
    return this.rejectReasons?.filterBy('hidden', false);
  }

  get rejectedByCompanyReasons() {
    return this.rejectReasonsWithoutHidden?.filterBy('rejectedByCompany', true);
  }

  get rejectedByUserReasons() {
    return this.rejectReasonsWithoutHidden?.filterBy(
      'rejectedByCompany',
      false
    );
  }

  get sortedRejectedByCompanyReasons() {
    return this.rejectedByCompanyReasons?.sortBy('rowOrder');
  }

  get sortedRejectedByUserReasons() {
    return this.rejectedByUserReasons?.sortBy('rowOrder');
  }

  get groupedRejectReasons() {
    return [
      {
        groupName: this.intl.t(
          'components.reject_settings_form.rejected_by_us'
        ),

        options: this.sortedRejectedByCompanyReasons,
      },
      {
        groupName: this.intl.t(
          'components.reject_settings_form.rejected_by_candidate'
        ),

        options: this.sortedRejectedByUserReasons,
      },
    ];
  }

  get user() {
    return this.current.user;
  }

  get customJobEmailTemplate() {
    return this.templates?.findBy('id', JOB_CUSTOM_EMAIL_NAMESPACE);
  }

  get jobRejectTemplate() {
    const rejectMessage = this.jobDetail
      ? get(this.jobDetail, 'rejectMessage')
      : null;

    return rejectMessage
      ? this.templates?.findBy('id', get(rejectMessage, 'id'))
      : null;
  }

  get fallbackRejectTemplate() {
    return this.templates?.findBy('reject', true);
  }

  getTemplateValue(key: TemplateField): string | undefined | null {
    if (!this.args.template) {
      return null;
    }

    return get(this.args.template, key);
  }

  getCustomTemplateForJob() {
    const { jobDetail } = this;
    const hasCustomJobReject = jobDetail && !!get(jobDetail, 'rejectSubject');

    if (hasCustomJobReject) {
      this.store.createRecord('canned-response', {
        id: JOB_CUSTOM_EMAIL_NAMESPACE,
        name: this.intl.t(
          'components.reject_settings_form.custom_email_for_this_job'
        ),

        body: get(jobDetail, 'rejectBody'),
        subject: get(jobDetail, 'rejectSubject'),
      });
    }
  }

  removeCustomTemplateForJob() {
    const customJobEmail = this.store.peekRecord(
      'canned-response',
      JOB_CUSTOM_EMAIL_NAMESPACE
    );

    if (customJobEmail) {
      this.store.unloadRecord(customJobEmail);

      // References need to be removed too:
      if (this.templateId === JOB_CUSTOM_EMAIL_NAMESPACE) {
        this.args.onChangeTemplate(undefined);
      }
    }
  }

  updateCustomMessage(useCustomMessage: boolean) {
    const customSubject = useCustomMessage
      ? this.translationToUse?.subject ||
        this.getTemplateValue(TemplateField.Subject)
      : null;

    this.args.onChangeCustomSubject(customSubject || null);

    const defaultBody = `${
      this.translationToUse?.body ||
      this.getTemplateValue(TemplateField.Body) ||
      ''
    } <br> ${this.userSignature}`;

    const customBody = useCustomMessage ? defaultBody : '';
    this.args.onChangeCustomBody(customBody);
  }

  get customBody() {
    const body = this.args.customBody;

    const doc = new DOMParser().parseFromString(body, 'text/html');

    // See #format_body method in the CannedResponse model
    // The div-wrapper around the content breaks Redactors ability to insert list elements
    doc.body.childNodes.forEach((child) => {
      if (child.nodeName.toLowerCase() === 'div') {
        child.replaceWith(...child.childNodes);
      }
    });

    return doc.body.innerHTML;
  }

  get hasRejectReason(): boolean {
    return !!this.args.rejectReason && !!get(this.args.rejectReason, 'id');
  }

  get hasJobDetail(): boolean {
    return !!this.jobDetail && !!get(this.jobDetail, 'id');
  }

  get templateId() {
    return this.args.template ? get(this.args.template, 'id') : null;
  }

  get delay() {
    return this.args.delay || 0;
  }

  get delayTranslationKey() {
    const found = this.delayOptions.find((option) => {
      return option.value === this.delay;
    });

    const key = found
      ? found.text.toLowerCase().replace(' ', '_')
      : 'custom_days';
    return `components.reject_settings_form.${key}`;
  }

  get delayInDays() {
    return typeof this.delay === 'string' || Number.isNaN(this.delay)
      ? 1
      : Math.max(Math.floor(this.delay / DAY_IN_SECONDS), 1);
  }

  get userSignature() {
    const { useTranslationFor } = this.args;
    if (!useTranslationFor) {
      return this.user.signature;
    }

    const userTranslation = this.user.translations.findBy(
      'languageCode',
      useTranslationFor
    );

    return userTranslation && !isEmpty(userTranslation.signature)
      ? userTranslation.signature
      : this.user.signature;
  }

  get filteredTemplates(): OrganizableModel[] | GroupedOrganizable[] {
    const all = this.templates || [];
    if (this.args.job) {
      const context = get(this.args.job, 'assetStructureContext');
      return fetchGroupedAssetsByContext(all, context, this.intl);
    } else {
      return all;
    }
  }

  triggerDelayChange(delay: string | null): void {
    this.args.onChangeDelay(delay);
    if (this.args.onChangeDelayTimestamp) {
      this.args.onChangeDelayTimestamp(delay);
    }
  }

  fetchTemplates = dropTask(async () => {
    this.getCustomTemplateForJob();

    const allTemplates = await this.store.findAll('canned-response', {
      reload: true,
    });
    this.templates = allTemplates.slice();

    if (!this.templateId) {
      const defaultRejectTemplate =
        this.customJobEmailTemplate ||
        this.jobRejectTemplate ||
        this.fallbackRejectTemplate;
      this.setTemplate.perform(defaultRejectTemplate);
    }
  });

  setTemplate = dropTask(async (template: CannedResponse | undefined) => {
    const { useTranslationFor } = this.args;
    if (useTranslationFor && template) {
      const translations = await get(template, 'translations');

      this.translationToUse = translations.findBy(
        'languageCode',
        useTranslationFor
      );
    }

    this.args.onChangeTemplate(template);

    if (get(this, 'usingCustomMessage')) {
      this.updateCustomMessage(true);
    }
  });

  fetchRejectReasons = dropTask(async () => {
    this.rejectReasons = await this.store.findAll('reject-reason');
    if (!this.hasRejectReason || this.hasJobDetail) {
      this.args.onChangeRejectReason(
        this.sortedRejectedByCompanyReasons?.firstObject
      );
    }
  });

  preloadUserSignature = dropTask(async () => {
    if (!this.args.useTranslationFor) {
      return;
    }

    await get(this.user, 'translations');
  });

  @action
  changeDelay({ value }: { value: number | string }): void {
    this.args.onChangeDelay(value);

    if (this.args.onChangeDelayTimestamp) {
      const delayTimestamp =
        typeof value === 'string'
          ? value
          : value === 0
            ? null
            : moment().add(value, 'seconds').toISOString();

      this.args.onChangeDelayTimestamp(delayTimestamp);
    }
  }

  @action
  updateRelativeDays(days: number) {
    this.args.onChangeDelay(days * DAY_IN_SECONDS);
  }

  @action
  toggleCustomMessage(): void {
    this.updateCustomMessage(!get(this, 'usingCustomMessage'));
    this.usingCustomMessage = !this.usingCustomMessage;
  }

  draftCopilotRejectionEmail = dropTask(async () => {
    if (!this.args.jobApplicationId) return;

    // eslint-disable-next-line
    // @ts-ignore
    const rejectReason = this.args.rejectReason?.content?.reason || 'N/A';

    const data = {
      job_application_id: this.args.jobApplicationId,
      reject_reason: rejectReason,
      rejected_by_company: get(this.args.rejectReason, 'rejectedByCompany'),
    };

    const draftedRejectionMessage: string = await this.copilot.draftRejectEmail(
      this.args.onChangeCustomSubject,
      this.args.onChangeCustomBody,
      data
    );

    next(() => {
      this.args.onChangeCustomBody(
        `${draftedRejectionMessage} <br> ${this.userSignature}`
      );
    });
  });

  @action
  changeDate(value: number | string): void {
    if (isEmpty(value)) {
      this.triggerDelayChange(null);
      return;
    }

    this.triggerDelayChange(moment(value).toISOString());
  }

  @action
  changeReason(reason: RejectReason) {
    const previousReasonRejectedByCompany = this.args.rejectReason
      ? get(this.args.rejectReason, 'rejectedByCompany')
      : null;

    this.args.onChangeRejectReason(reason);

    if (previousReasonRejectedByCompany !== reason.rejectedByCompany) {
      this.args.onChangeSendMessage(reason.rejectedByCompany);
    }
  }

  @action
  onChangeSendMessage(value: boolean) {
    if (this.usingCustomMessage) {
      this.usingCustomMessage = false;
    }

    this.args.onChangeSendMessage(value);
  }

  @action
  handleTemplateChange(template: CannedResponse) {
    this.setTemplate.perform(template);
  }

  @action
  willDestroyElement() {
    this.removeCustomTemplateForJob();
    this.copilot.stop();
  }

  @action
  handleInsertElement() {
    this.usingCustomMessage = !!this.args.customSubject;
    this.preloadUserSignature.perform();
    this.fetchTemplates.perform();
    this.fetchRejectReasons.perform();
  }

  @action
  stopCopilot() {
    this.copilot.stop();
  }
}
