/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent, {
  BaseComponentArgs,
} from 'teamtailor/components/content-block/base-component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import ContentEditorService from 'teamtailor/services/content-editor';
import PickedVideoModel from 'teamtailor/models/picked-video';
import ImageModel from 'teamtailor/models/image';
import VideoModel from 'teamtailor/models/video';
import CookieDetail from 'teamtailor/models/cookie-detail';
import ArrayProxy from '@ember/array/proxy';
import FilthyContent from 'teamtailor/services/filthy-content';
import DirectUploadService from 'teamtailor/services/direct-upload';
import { get } from 'teamtailor/utils/get';
import { justAssert } from 'teamtailor/utils/justAssert';
import PagePublicationModel from 'teamtailor/models/page-publication';
import IntlService from 'ember-intl/services/intl';
import JobModel from 'teamtailor/models/job';
import { CoreSelectApi } from 'teamtailor/components/core/select';
import { afterRender } from 'teamtailor/ember-smooth';

type CoverBaseComponentArgs = BaseComponentArgs;

type ButtonItemIndices = 0 | 1;

export default class CoverBaseComponent extends BaseComponent<CoverBaseComponentArgs> {
  @service declare directUpload: DirectUploadService;
  @service declare store: Store;
  @service declare contentEditor: ContentEditorService;
  @service declare filthyContent: FilthyContent;
  @service declare intl: IntlService;

  @tracked declare cookieDetails: ArrayProxy<CookieDetail> | undefined;

  constructor(owner: unknown, args: CoverBaseComponentArgs) {
    super(owner, args);

    this._fetchCookieDetails();
  }

  @action newLineToSpace(text?: string) {
    return text?.replaceAll('\n', ' ');
  }

  async _fetchCookieDetails() {
    this.cookieDetails = await this.store.findAll('cookie-detail');
  }

  @action getButtonSectionItemByIndex(index: ButtonItemIndices) {
    return get(this.section, 'sectionItems')?.find((sectionItem) => {
      return sectionItem.itemType === this.getButtonItemType(index);
    });
  }

  @action async getOrCreateButtonSectionItemByIndex(index: ButtonItemIndices) {
    const itemType = this.getButtonItemType(index);
    const section = await this.section;

    justAssert(section?.sectionItems);

    let sectionItem = this.getButtonSectionItemByIndex(index);

    if (!sectionItem) {
      sectionItem = section.sectionItems.createRecord({
        itemType,
        rowOrder: index,
      });
    }

    return sectionItem;
  }

  get buttonSectionItems() {
    return [
      this.getButtonSectionItemByIndex(0),
      this.getButtonSectionItemByIndex(1),
    ];
  }

  getButtonItemType(index: ButtonItemIndices) {
    return `button_${index}` as const;
  }

  @action async handlePagePublicationSelect(
    index: ButtonItemIndices,
    pagePublicationModel: PagePublicationModel
  ) {
    const sectionItem = await this.getOrCreateButtonSectionItemByIndex(index);
    set(sectionItem, 'modelType', 'PagePublication');
    set(sectionItem, 'modelId', pagePublicationModel.id);
  }

  @action async handleButtonTextInput(
    index: ButtonItemIndices,
    event: KeyboardEvent
  ) {
    const sectionItem = await this.getOrCreateButtonSectionItemByIndex(index);
    if (event.target instanceof HTMLInputElement) {
      sectionItem.title = event.target.value;
    }
  }

  get section() {
    return this.args.model;
  }

  get pickedImage() {
    const pickedImages = get(this.section, 'pickedImages').filter(
      (pi) => pi.id && !pi.isDeleted
    );
    return pickedImages.firstObject;
  }

  get pickedVideo() {
    return this.getPickedVideo('cover');
  }

  get pickedMobileVideo() {
    return this.getPickedVideo('cover_mobile');
  }

  get imageHeight() {
    return this.contentEditor.isWide ? 250 : 125;
  }

  getPickedVideo(transcodeType: string): PickedVideoModel | undefined {
    const pickedVideos: PickedVideoModel[] | [] = get(
      this.section,
      'pickedVideos'
    ).filterBy('isDeleted', false);
    const video = pickedVideos.find((pickedVideo: PickedVideoModel) => {
      return pickedVideo.video.transcodeType === transcodeType;
    });

    return video;
  }

  @action
  handlePickImage(image: ImageModel) {
    get(this.section, 'pickedImages').forEach((image) => image.deleteRecord());

    get(this.section, 'pickedImages').createRecord({ image });
    this.filthyContent.setFilthy(get(this.section, 'id'));
  }

  @action
  handleRemoveImage() {
    this.pickedImage?.deleteRecord();
    this.filthyContent.setFilthy(get(this.section, 'id'));
  }

  @action
  handleVideoRemoved(transcodeType: string) {
    const video = this.getPickedVideo(transcodeType);
    video?.deleteRecord();
    this.filthyContent.setFilthy(get(this.section, 'id'));
  }

  @action
  handleVideoUploaded(video: VideoModel, transcodeType: string) {
    const pickedVideo = this.getPickedVideo(transcodeType);
    if (pickedVideo) {
      this.store
        .findRecord('picked-video', pickedVideo.id)
        .then(function (pickedVideo) {
          pickedVideo.video = video;
          pickedVideo.save();
        });
    } else {
      this.store
        .createRecord('picked-video', {
          video,
          section: this.section,
        })
        .save();
    }
  }

  get missingVideoCookie() {
    if (!this.cookieDetails) {
      return;
    }

    const videoProvider = get(this.section, 'video').match(/\D/)
      ? 'youtube'
      : 'vimeo';
    return !this.cookieDetails.filterBy('enabled').findBy('id', videoProvider);
  }

  get selectedJobHighlightsSectionItems() {
    return get(this.section, 'sectionItems')?.filter(
      (si) => si.itemType === 'job_highlight'
    );
  }

  get selectedJobHighlightsJobs() {
    return this.selectedJobHighlightsSectionItems
      ?.filter((si) => !get(si, 'isDeleted'))
      .map((si) => si.job);
  }

  @action handleJobPickerSelect(selectedJob: JobModel) {
    const selectedIndex = this.selectedJobHighlightsSectionItems?.findIndex(
      (si) => get(si.job, 'id') === get(selectedJob, 'id')
    );

    if (selectedIndex === -1) {
      get(this.section, 'sectionItems')?.createRecord({
        modelType: 'Job',
        modelId: get(selectedJob, 'id'),
        itemType: 'job_highlight',
      });
    } else if (selectedIndex !== undefined) {
      const sectionItem =
        this.selectedJobHighlightsSectionItems?.[selectedIndex];

      if (sectionItem?.isDeleted) {
        sectionItem.rollbackAttributes();
      } else {
        this.selectedJobHighlightsSectionItems?.[selectedIndex]?.deleteRecord();
      }
    }
  }

  jobPickerApi?: CoreSelectApi;

  get selectedJobHighlights() {
    return this.jobHighlightsOptions.find(
      (o) => o.value === get(this.section, 'jobHighlights')
    );
  }

  @action async handleJobHighlightsSelect(
    selectedOption: (typeof this.jobHighlightsOptions)[number] | null,
    event: MouseEvent
  ) {
    set(this.section, 'jobHighlights', selectedOption?.value);

    if (selectedOption?.value === 'specific_jobs') {
      event.stopPropagation();
      await afterRender();
      this.jobPickerApi?.open();
    }
  }

  get jobHighlightsOptions() {
    return [
      {
        value: '3_latest',
        label: this.intl.t('content.editor.section.x_latest', { count: 3 }),
      },
      {
        value: '4_latest',
        label: this.intl.t('content.editor.section.x_latest', { count: 4 }),
      },
      {
        value: '5_latest',
        label: this.intl.t('content.editor.section.x_latest', { count: 5 }),
      },
      {
        value: 'specific_jobs',
        label: this.intl.t('content.editor.section.specific_jobs'),
      },
    ];
  }
}
