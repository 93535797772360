/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class LocationsListComponent extends BaseComponent {
  @service iframePreview;
  @service intl;
  @service store;
  @service current;

  get visibleSectionItems() {
    return this.args.model.sectionItems.filterBy(
      'location.hideOnCareersite',
      false
    );
  }

  get locations() {
    let locations = get(this.current.company, 'sortedLocationsByRegions');

    if (!locations.findBy('id', null)) {
      locations.unshift({
        id: null,
        nameOrCity: this.intl.t('search.location.no_location'),
      });
    }

    return locations;
  }

  getLocationAtPlace = (place) => {
    if (this.args.model.sortedItems.length < place + 1) {
      return undefined;
    } else if (this.args.model.sortedItems[place].location) {
      return this.args.model.sortedItems[place].location;
    } else {
      return this.store.findRecord(
        'location',
        this.args.model.sortedItems[place].modelId
      );
    }
  };

  @action
  handleSelectLocation(index, location) {
    return this.setModelAsSectionItem(index, location, 'Location');
  }
}
