/* import __COLOCATED_TEMPLATE__ from './area-chart.hbs'; */
import Component from '@glimmer/component';
import fontOptions from './font-options';
import hexToRgb from 'teamtailor/utils/hex-to-rgb';

export default class InsightsChartsAreaChart extends Component {
  get chartColor() {
    return this.args.color || '#f43f85';
  }

  get chartData() {
    return this.args.series.map((series, index) => {
      return {
        name: series,
        data: this.args.data[index],
      };
    });
  }

  get chartType() {
    return this.args.smooth ? 'areaspline' : 'area';
  }

  get chartOptions() {
    const { columns, showLabels } = this.args;
    const { chartColor } = this;

    const startColor = hexToRgb(chartColor, 0.4);
    const stopColor = hexToRgb(chartColor, 0);
    let i = 0;
    const options = {
      chart: {
        type: this.chartType,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingTop: 0,
        spacingRight: 0,
        height: this.args.height || '200px',
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false,
      },

      yAxis: {
        title: {
          text: '',
        },

        gridLineColor: '#eeeff2',

        labels: {
          enabled: true,
          padding: 0,
          x: 15,
          y: -5,
          style: {
            fontSize: '11px',
            color: '#999',
          },
        },
      },

      xAxis: showLabels
        ? {
            labels: {
              enabled: true,
              style: fontOptions,
              formatter(ctx) {
                i++;
                if (ctx.isFirst) {
                  i = 0;
                }

                return columns[i] || '';
              },
            },

            tickInterval: 1,
            lineColor: '#eeeff2',
            lineWidth: 1,
          }
        : {
            labels: {
              enabled: false,
            },

            tickLength: 1,
            lineColor: '#eeeff2',
            lineWidth: 1,
          },

      tooltip: {
        formatter() {
          return this.points.reduce(
            (s, point) =>
              `${s}<br /><span style="display: inline-block; margin-right: 4px; color: ${chartColor}">\u25CF</span> ${point.series.name}: ${point.y}`,
            `<span>${columns[this.x]}</span>`
          );
        },

        padding: 8,

        crosshairs: {
          color: '#eeeff2',
          dashStyle: 'solid',
        },

        shared: true,
      },

      plotOptions: {
        line: {
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },

        series: {
          label: {
            connectorAllowed: false,
          },

          marker: {
            enabled: false,
          },
        },

        areaspline: {
          lineColor: chartColor,
          lineWidth: 2,

          fillColor: {
            linearGradient: [0, 0, 0, 200],
            stops: [
              [0, startColor],
              [1, stopColor],
            ],
          },
        },

        area: {
          lineColor: chartColor,
          lineWidth: 2,

          fillColor: {
            linearGradient: [0, 0, 0, 200],
            stops: [
              [0, startColor],
              [1, stopColor],
            ],
          },
        },
      },
    };

    return options;
  }
}
