/* import __COLOCATED_TEMPLATE__ from './message-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

type Args = {
  sendMessage(message: string): void;
};

export default class VideoMeetingChatMessageFormm extends Component<Args> {
  @tracked value = '';
  declare textarea: HTMLTextAreaElement;

  @action
  handleValueChange({ target }: { target: HTMLTextAreaElement }) {
    this.value = target.value;
  }

  @action
  sendMessage() {
    this.args.sendMessage(this.value);
    this.value = '';
  }

  @action
  handleCmdEnter() {
    if (this.value) {
      this.sendMessage();
    }
  }

  @action
  registerTextarea(textarea: HTMLTextAreaElement) {
    this.textarea = textarea;
  }

  @action
  insertEmoji(emoji: string) {
    this.value = this.value + emoji;
    next(() => {
      this.textarea.focus();
    });
  }
}
