/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import JobModel from 'teamtailor/models/job';
import ActivityModel from 'teamtailor/models/activity';
import { action } from '@ember/object';
import { AsyncHasMany } from '@ember-data/model';
import IntercomService from 'teamtailor/services/intercom';
import Server from 'teamtailor/services/server';
import NotificationsService from 'teamtailor/services/notifications';
import ScrollPosition from 'teamtailor/utils/scroll-position';
import { modifier } from 'ember-modifier';

interface JobsCollaborateArgs {
  job: JobModel;
  isOpen: boolean;
  activitiesClass?: AsyncHasMany<ActivityModel>;
  activities: ActivityModel[];
  addActivity: (activity: ActivityModel) => void;
  destroyActivity: (activity: ActivityModel) => Promise<void>;
}

export default class JobsCollaborate extends Component<JobsCollaborateArgs> {
  @service declare store: Store;
  @service declare intercom: IntercomService;
  @service declare server: Server;
  @service declare notifications: NotificationsService;

  declare scrollPosition: ScrollPosition;
  hasInitialized = false;

  constructor(owner: unknown, args: JobsCollaborateArgs) {
    super(owner, args);

    if (this.args.isOpen) {
      this.initialize();
    }
  }

  initialize() {
    this.hasInitialized = true;
    this.intercom.hideWidget();
    this.notifications.markAsRead({
      actionIds: this.args.activities.mapBy('id'),
    });
  }

  get sortedActivities(): ActivityModel[] {
    return this.args.activities.sortBy('createdAt');
  }

  get renderNoteForm() {
    return this.args.isOpen && !this.args.activitiesClass?.isRejected;
  }

  @action
  handleDidUpdate(): void {
    if (!this.hasInitialized && this.args.isOpen) {
      this.initialize();
      this.scrollPosition.scrollToBottom();
    }
  }

  @action
  addActivity(activity: ActivityModel) {
    this.args.addActivity(activity);
    this.scrollPosition.scrollToBottom();
  }

  registerScrollbar = modifier((element: HTMLElement, [_sortedActivities]) => {
    this.scrollPosition = new ScrollPosition(element);
    this.scrollPosition.scrollToBottom();
  });
}
