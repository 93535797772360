/* import __COLOCATED_TEMPLATE__ from './manager.hbs'; */
import MediaLibraryManagerBaseComponent from 'teamtailor/components/media-library/manager-base';
import { restartableTask } from 'ember-concurrency';

import InfinityModel from 'ember-infinity/lib/infinity-model';

const GiphyInfinityModel = InfinityModel.extend({
  buildParams() {
    let params = this._super(...arguments);
    const page = parseInt(params.page, 10) || 0;
    params.offset = page * params.limit;
    return params;
  },
});

export default class RedactorGiphyManagerComponent extends MediaLibraryManagerBaseComponent {
  perPage = 25;

  willDestroy() {
    super.willDestroy(...arguments);
    this.store.unloadAll('giphy-image');
  }

  fetchImages = restartableTask(async () => {
    const params = {
      perPage: this.perPage,
      perPageParam: 'limit',
      startingPage: 0,
    };

    if (this.isSearching) {
      params.q = this.queryParams.query;
    }

    const infinityModel = await this.infinity.model(
      'giphy-image',
      params,
      GiphyInfinityModel
    );

    this.images = infinityModel;
  });
}
