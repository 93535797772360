/* import __COLOCATED_TEMPLATE__ from './next-link.hbs'; */
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { JobsEditNavigationItem } from 'teamtailor/utils/jobs-edit-navigation-item';

interface JobsNextLinkComponentArgs {
  items: JobsEditNavigationItem[];
}

export default class JobsNextLinkComponent extends Component<JobsNextLinkComponentArgs> {
  @service declare router: RouterService;

  get nextItem(): JobsEditNavigationItem | undefined {
    const currentItemIndex = this.args.items.findIndex((item) => {
      const possibleRoutes = [
        `jobs.edit.${item.route}`,
        `jobs.edit.${item.route}.index`,
      ];

      return possibleRoutes.includes(this.router.currentRouteName);
    });

    return currentItemIndex > -1
      ? this.args.items[currentItemIndex + 1]
      : undefined;
  }
}
