/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class NurtureCampaignRecipientsRowComponent extends Component {
  @service intl;
  @argDefault recipient = null;

  get candidate() {
    return this.recipient.candidate;
  }

  get candidateId() {
    return get(this.candidate, 'id');
  }

  get statusTooltip() {
    if (this.recipient.status !== 'exited') return undefined;

    switch (this.recipient.exitReason) {
      case 'applied':
        return this.intl.t(
          'components.nurture_campaign_recipients.row.applied_to_job'
        );
      case 'removal_requested':
        return this.intl.t(
          'components.nurture_campaign_recipients.row.requested_data_removal'
        );
      case 'replied':
        return this.intl.t(
          'components.nurture_campaign_recipients.row.replied_to_message'
        );
      case 'unubscribed':
        return this.intl.t(
          'components.nurture_campaign_recipients.row.unubscribed'
        );
      case 'unsubscribed_from_other':
        return this.intl.t(
          'components.nurture_campaign_recipients.row.unsubscribed_from_other'
        );
      default:
        return capitalize(this.recipient.exitReason);
    }
  }

  get statusIndicator() {
    switch (this.recipient.status) {
      case 'active':
        return 'published';
      case 'paused':
        return 'pending';
      default:
        return 'archived';
    }
  }

  @action
  triggerStatusChange(status, e) {
    e.stopPropagation();
    return this.recipient.triggerStatusChange({ event: status });
  }
}
