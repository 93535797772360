/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
// @ts-nocheck
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import escapeStringRegexp from 'escape-string-regexp';
import { action, set } from '@ember/object';
import { gql } from '@apollo/client/core';
import ApolloService from 'ember-apollo-client/services/apollo';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { Notification } from 'teamtailor/models/notification';

import { stripHtml } from 'teamtailor/utils/string-sanitizer';
import styles from 'teamtailor/components/notifications/item.module.scss';

interface NotificationsItemArgs {
  notification: {
    toHuman: string;
    interest?: string;
  };
}

export default class NotificationsItem extends Component<NotificationsItemArgs> {
  @service declare apollo: ApolloService;
  @service declare current: Current;
  @service declare notifications: Notifications;

  get highlightedHtml() {
    if (isEmpty(this.args.notification.interest)) {
      return this.args.notification;
    }

    return htmlSafe(
      stripHtml(this.args.notification.toHuman).replace(
        this.escapedInterest,
        `<span class="${styles.interest}">$1</span>`
      )
    );
  }

  get escapedInterest(): RegExp {
    const interest = this.args.notification.interest || '';
    return new RegExp(`(${escapeStringRegexp(stripHtml(interest))})`);
  }

  @action
  async toggleUnread(notification: Notification, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.blur();

    await this.apollo.mutate({
      mutation: UPDATE_NOTIFICATION,
      variables: {
        userId: this.current.user.id,
        companyUuid: this.current.company.uuid,
        id: notification.id,
        checked: notification.isUnread,
      },
    });
    set(notification, 'isUnread', !notification.isUnread);
    await this.notifications.refreshUnread.perform();
  }
}

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($userId: ID!, $id: ID!, $checked: Boolean!) {
    updateNotification(
      input: {
        userScope: { userId: $userId }
        id: $id
        notificationAttributes: { checked: $checked }
      }
    ) {
      notification {
        id
        isUnread
      }
    }
  }
`;
