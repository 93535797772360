import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

const LIMIT_OF_ACTIVITIES = 6;

export default class IndexController extends Controller {
  @service router;
  @service current;

  get employee() {
    return get(this, 'model');
  }

  get recentEmployeeActivities() {
    return this.employee.activities.slice(0, LIMIT_OF_ACTIVITIES);
  }

  get shouldShowTeams() {
    return (
      this.current.user.admin ||
      (get(this.employee, 'allTeams').length &&
        this.current.user.id === get(this.employee, 'id'))
    );
  }

  @action
  goToCandidate(candidate, tab) {
    if (candidate) {
      get(this, 'router').transitionTo('candidates.candidate', candidate, {
        queryParams: { tab },
      });
    }
  }

  @action
  goToProfile(user) {
    get(this, 'router').transitionTo('employees.profile', user);
  }
}
