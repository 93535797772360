/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import ActivityModel from 'teamtailor/models/activity';
import PusherService from 'teamtailor/services/pusher';
import { registerDestructor } from '@ember/destroyable';
import { trackedFunction } from 'ember-resources/util/function';
import { JobModel } from 'teamtailor/models';
import { action } from '@ember/object';
import ScrollPosition from 'teamtailor/utils/scroll-position';
import { modifier } from 'ember-modifier';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { UnreadMessage } from 'teamtailor/components/jobs/collaborate/list';
import JobCommentStatusModel from 'teamtailor/models/job-comment-status';
import PusherForJobsService from 'teamtailor/services/pusher-for-jobs';

interface GlobalCommentsJobsCollaborateArgs {
  job: JobModel;
  addActivity: (activity: ActivityModel) => void;
  jobCommentStatuses: JobCommentStatusModel[];
}

export default class GlobalCommentsJobsCollaborate extends Component<GlobalCommentsJobsCollaborateArgs> {
  @service declare store: Store;
  @service declare pusher: PusherService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare pusherForJobs: PusherForJobsService;

  @tracked activities: ActivityModel[] = [];

  declare scrollPosition: ScrollPosition;

  constructor(owner: unknown, args: GlobalCommentsJobsCollaborateArgs) {
    super(owner, args);

    registerDestructor(this, () => {
      this.teardownPusher();
    });
  }

  get jobId() {
    return this.args.job.id;
  }

  resolveActivityResponses = trackedFunction(this, async () => {
    /*
      `store.query` returns a PromiseArray which doesn't quite work like a real one,
      so we need to convert it
    */
    const activities: ActivityModel[] = await this.store.query('activity', {
      job_id: this.jobId,
      collaborate: true,
    });
    this.activities = activities.slice();
    return this.activities;
  });

  get isLoading() {
    return (
      this.resolveActivityResponses.isLoading ||
      this.resolveActivityResponses.value === null
    );
  }

  async setupPusher(job: JobModel) {
    (await this.pusherForJobs.setup(job))
      .bindJobEvents()
      .bindNewJobNoteCallback(this.addActivity);
  }

  teardownPusher() {
    this.pusherForJobs
      .unbindJobEvents()
      .unbindNewJobNoteCallback(this.addActivity);
  }

  get sortedActivities(): ActivityModel[] {
    const allActivitiesForJob = [...this.activities].sort(
      (a: ActivityModel, b: ActivityModel) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    return allActivitiesForJob;
  }

  get unreadJobCommentStatus() {
    if (this.args.jobCommentStatuses.length === 0) return null;

    return this.args.jobCommentStatuses.find((jobComment) => {
      return jobComment.belongsTo('job').id() === this.jobId;
    });
  }

  get unreadMessages(): UnreadMessage[] {
    const unreadJobCommentReadDateAt = this.unreadJobCommentStatus
      ? this.unreadJobCommentStatus.latestReadCommentAt
      : null;

    if (unreadJobCommentReadDateAt === null) return [];

    const unreadComments = this.sortedActivities.filter((activity) => {
      return activity.createdAt >= unreadJobCommentReadDateAt;
    });

    return unreadComments.map((comment) => ({
      id: comment.id,
      createdAt: comment.createdAt,
    }));
  }

  scrollToBottom() {
    this.scrollPosition.scrollToBottom();
  }

  handleJobChange = modifier((_, [job]: [job: JobModel]) => {
    this.teardownPusher();
    this.setupPusher(job);
  });

  @action
  addActivity(activity: ActivityModel) {
    if (!this.activities.includes(activity)) {
      this.activities = [...this.activities, activity];
    }

    this.scrollToBottom();
  }

  @action
  async destroyActivity(activityToRemove: ActivityModel) {
    activityToRemove.deleteRecord();
    try {
      await activityToRemove.destroyRecord();
      this.activities = this.activities.filter(
        (activity) => activity !== activityToRemove
      );
    } catch (e) {
      activityToRemove.rollbackAttributes();
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  registerScrollbar = modifier((element: HTMLElement, [_sortedActivities]) => {
    this.scrollPosition = new ScrollPosition(element);
    this.scrollToBottom();
  });
}
