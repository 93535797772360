/* import __COLOCATED_TEMPLATE__ from './email.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import Current from 'teamtailor/services/current';
import {
  CandidateModel,
  QuestionModel,
  JobModel,
  PageModel,
  ImageModel,
  JobApplicationModel,
  CannedResponseModel,
  UploadModel,
  PickedQuestionModel,
} from 'teamtailor/models';
import { FileUploadStatus, FileUpload } from 'teamtailor/types/file-upload';
import {
  isSameSubject,
  replyPrefix,
} from 'teamtailor/components/candidate-messages/message';
import { ComposerSignature } from 'teamtailor/components/candidate-messages/composer';
import { get } from 'teamtailor/utils/get';
import { createSmartLink } from 'teamtailor/utils/smart-link';
import { dropTask, restartableTask } from 'ember-concurrency';
import htmlToStr from 'teamtailor/utils/html-to-text';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { next } from '@ember/runloop';
import {
  AssetStructureContext,
  fetchGroupedAssetsByContext,
} from 'teamtailor/utils/filter-assets';
import placeholders from 'teamtailor/utils/placeholders';
import templateBodyToRedactorHTML from 'teamtailor/utils/template-body-to-redactor-html';
import MessageService from 'teamtailor/services/message-service';

const NEW_HIRE_IMAGE =
  '<img class="smart-link__img" src="https://static.teamtailor-cdn.com/images/new-hire-party-horn.png"/>';

interface EmailComposerSignature {
  Args: {
    newHire?: string;
    anonymous: boolean;
  };
}

export default class ComposerEmail extends Component<
  ComposerSignature & EmailComposerSignature
> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare messageService: MessageService;

  declare redactorInstance: any;
  declare redactorContainer: HTMLDivElement;

  @tracked _body: string | null = null;
  @tracked userSignature: string | null = null;
  @tracked _subject: string | null = null;
  @tracked questions: QuestionModel[] = [];
  @tracked fileUploads: FileUpload[] = [];
  @tracked uploads: UploadModel[] = [];
  @tracked displaySmartLinkModalFor: string | null = null;

  private cannedResponseId: string | null = null;
  private delayUntil: Date | string | null = null;
  private _candidateId: string | null = null;
  private _jobApplicationId?: string;

  get replyingTo() {
    return this.args.replyingTo;
  }

  get replyTo() {
    return isSameSubject(this.replyingTo?.subject, this.subject)
      ? this.replyingTo
      : null;
  }

  get subject() {
    if (this.messageService.subject && this._subject === null) {
      return this.messageService.subject;
    }

    if (this._subject === null && this.replyingTo?.subject) {
      return this.replyingTo.subject.startsWith(replyPrefix)
        ? this.replyingTo.subject
        : `${replyPrefix}${this.replyingTo.subject}`;
    }

    return this._subject || '';
  }

  set subject(value: string) {
    this.messageService.subject = value;
    this._subject = value;
  }

  get body() {
    if (this.messageService.body && this._body === null) {
      return this.messageService.body;
    }

    return this._body || '';
  }

  set body(value: string) {
    this.messageService.body = value;
    this._body = value;
  }

  get isValid() {
    return (
      !!this.subject &&
      (!!this.trimmedBody || this.includesGif || this.questions.length) &&
      this.fileUploads.every(
        (fileUpload) => fileUpload.status === FileUploadStatus.DONE
      )
    );
  }

  get user() {
    return this.current.user;
  }

  get job() {
    return get(this.args.jobApplication, 'job');
  }

  get languageCode() {
    return get(this.job, 'languageCode') || this.args.candidate.languageCode;
  }

  get trimmedBody() {
    const textBody = htmlToStr(this.body).replace(/\s/g, '');
    const textSignature = htmlToStr(this.userSignature).replace(/\s/g, '');
    return textBody.replace(textSignature, '');
  }

  get includesGif() {
    return this.body.includes('<figure');
  }

  handleArgsChange = restartableTask(async () => {
    const { jobApplication, candidate } = this.args;

    if (this._candidateId !== candidate.id) {
      await this.onCandidateChange(candidate, jobApplication);
    } else if (
      this._candidateId === candidate.id &&
      this._jobApplicationId !== jobApplication?.id
    ) {
      await this.onJobApplicationChange(jobApplication);
    }

    return candidate;
  });

  _candidate = trackedTask(this, this.handleArgsChange, () => [
    this.args.jobApplication,
    this.args.newHire,
  ]);

  get candidate() {
    return this._candidate.value;
  }

  get company() {
    return this.current.company;
  }

  get signature() {
    return this.userSignature ? `<br>${this.userSignature}` : '';
  }

  get cannedResponses() {
    const job = get(this, 'job');

    let context: AssetStructureContext;
    if (job) {
      context = get(job, 'assetStructureContext');
    } else {
      const department = this.args.candidate.belongsTo('department').id();
      const role = this.args.candidate.belongsTo('role').id();

      context = {
        department,
        role,
        region: null,
        location: null,
      };
    }

    const cannedResponses = get(
      this.current.company,
      'sortedCannedResponses'
    ).filter((cannedResponse: CannedResponseModel) => {
      return (
        !cannedResponse.welcomeToConnect &&
        !cannedResponse.externalRecruiterInvite
      );
    });

    return fetchGroupedAssetsByContext(cannedResponses, context, this.intl);
  }

  insertContent(content: string) {
    this.redactorInstance.selection.restore();

    const signature = this.redactorContainer.querySelector('.signature');
    if (signature) {
      this.redactorInstance.caret.setBefore(signature);
    }

    this.redactorInstance.insertion.insertRaw(content);
  }

  async onCandidateChange(
    candidate: CandidateModel,
    jobApplication?: JobApplicationModel
  ) {
    if (this._candidateId !== null) {
      this.messageService.reset();
    }

    const { newHire } = this.messageService;
    this._candidateId = candidate.id;
    this._jobApplicationId = jobApplication?.id;

    this.userSignature = await this.getUserSignature();

    if (newHire && jobApplication) {
      await this.insertNewHireContent(jobApplication, true);
    } else {
      next(() => {
        this.resetForm();
      });
    }
  }

  resetForm() {
    if (this._body !== null) {
      this._body = null;
    }

    if (this._subject !== null) {
      this._subject = null;
    }

    if (this.questions.length) {
      this.questions = [];
    }

    if (this.fileUploads.length) {
      this.fileUploads = [];
    }

    if (this.uploads.length) {
      this.uploads = [];
    }

    this.delayUntil = null;
    this.cannedResponseId = null;
  }

  async onJobApplicationChange(jobApplication?: JobApplicationModel) {
    this._jobApplicationId = jobApplication?.id;
    this.userSignature = await this.getUserSignature();
    this.messageService.reset();
  }

  async getUserSignature() {
    if (this.languageCode) {
      const userTranslations = await get(this.user, 'translations');
      const userTranslation = userTranslations.findBy(
        'languageCode',
        this.languageCode
      );
      if (userTranslation?.signature) {
        return userTranslation.signature;
      }
    }

    return this.user.signature;
  }

  async insertSmartLink(title: string, url: string, image?: ImageModel | null) {
    const smartLinkHtml = await createSmartLink(this.current.company, {
      image,
      title,
      url,
    });
    this.insertContent(smartLinkHtml);
  }

  async insertNewHireContent(
    jobApplication: JobApplicationModel,
    includeText: boolean
  ) {
    const newHireSmartLink = await createSmartLink(this.current.company, {
      imgHtml: NEW_HIRE_IMAGE,
      title: this.intl.t('components.message_composer.new_job_page.title'),
      url: get(jobApplication, 'newHirePageUrl'),
    });

    const candidateName = this.args.candidate.firstName;

    if (this.subject === '') {
      this.subject = this.intl.t(
        'components.message_composer.new_job_page.subject',
        {
          candidateName,
        }
      );
    }

    let paragraph = '';

    if (includeText) {
      paragraph = this.intl.t(
        'components.message_composer.new_job_page.paragraph',
        {
          candidateName,
        }
      );
    }

    const bodyHTML = `${paragraph}${newHireSmartLink}`;
    this._body = `${bodyHTML}${this.signature}`;
  }

  submitMessage = dropTask(async () => {
    const uploads = [
      ...this.fileUploads.map((file) =>
        this.store.createRecord('upload', {
          temporaryFileUrl: file.temporaryUrl,
        })
      ),
      ...this.uploads,
    ];

    const message = this.store.createRecord('message', {
      candidate: this.args.candidate,
      createdAt: new Date(),
      job: this.args.jobApplication?.job,
      jobApplicationId: this.args.jobApplication?.id,
      cannedResponseId: this.cannedResponseId,
      user: this.current.user,
      body: this._body,
      questions: this.questions,
      uploads,
      subject: this.subject,
      replyTo: this.replyTo,
      delayUntil: this.delayUntil,
    });

    await message.save();

    uploads.forEach((upload) => {
      if (get(upload, 'isNew')) {
        upload.unloadRecord();
      }
    });
    this.messageService.reset();

    this.resetForm();

    this.args.onMessageCreated(message);
  });

  @action
  handleCmdEnter() {
    if (this.isValid) {
      this.submitMessage.perform();
    }
  }

  @action
  setNewHire() {
    if (this.args.jobApplication) {
      this.insertNewHireContent(this.args.jobApplication, false);
    }
  }

  @action
  pickQuestion(question: QuestionModel) {
    this.questions.pushObject(question);
  }

  @action
  removeQuestion(question: QuestionModel) {
    this.questions.removeObject(question);
  }

  @action
  onFileUploadStart(fileName: string) {
    this.fileUploads.pushObject({
      fileName,
      status: FileUploadStatus.UPLOADING,
    });
  }

  @action
  onFileUploaded(temporaryUrl: string, fileName: string) {
    this.fileUploads = this.fileUploads.map((file) => {
      if (file.fileName === fileName) {
        return {
          ...file,
          status: FileUploadStatus.DONE,
          temporaryUrl,
        };
      }

      return file;
    });
  }

  @action
  removeFile(fileUpload: FileUpload) {
    this.fileUploads.removeObject(fileUpload);
  }

  @action
  handleRemoveUpload(upload: UploadModel) {
    this.uploads.removeObject(upload);
  }

  @action
  openPlugin(plugin: string) {
    this.redactorInstance?.toolbar.getButton(plugin).click();
  }

  @action
  insertRawContent(content: string) {
    this.redactorInstance?.insertion.insertRaw(content);
  }

  @action
  setRedactorContainer(element: HTMLDivElement) {
    this.redactorContainer = element;
  }

  @action
  submitDelayedMessage(delayUntil: Date | string) {
    this.delayUntil = delayUntil;
    this.submitMessage.perform();
  }

  @action
  async insertJobSmartLink(job: JobModel) {
    const jobDetail = await get(job, 'jobDetail');
    const image = await get(jobDetail?.pickedImage, 'image');
    await this.insertSmartLink(job.externalTitle, job.url, image);
    this.displaySmartLinkModalFor = null;
  }

  @action
  async insertPageSmartLink(page: PageModel) {
    const sharingImage = await get(page, 'sharingImage');
    const image = await get(sharingImage?.pickedImage, 'image');
    await this.insertSmartLink(page.displayTitle, page.publicUrl, image);
    this.displaySmartLinkModalFor = null;
  }

  @action
  handleFocus() {
    if (!this.replyTo && !this._body) {
      this._body = this.signature;
    }
  }

  @action
  async handleTemplateSelected(template: CannedResponseModel) {
    const { anonymous, candidate } = this.args;
    const placeholdersParser = placeholders(candidate, this.job);

    const body = placeholdersParser.parse(
      templateBodyToRedactorHTML(template.body) + this.signature,
      anonymous
    );

    this.cannedResponseId = template.id;
    this._body = body;
    this.subject = placeholdersParser.parse(template.subject, anonymous);

    const uploadRelation = await get(template, 'uploads');
    let uploads = uploadRelation.toArray();

    if (this.current.company.hasFeature('career_site_languages')) {
      const languageCodes = [null, candidate.languageCode];
      uploads = uploads.filter((u) => languageCodes.includes(u.languageCode));
    }

    this.uploads = uploads;

    const questionPromises = get(template, 'pickedQuestions')
      .toSorted(
        (
          pickedQuestion1: PickedQuestionModel,
          pickedQuestion2: PickedQuestionModel
        ) => pickedQuestion1.rowOrder - pickedQuestion2.rowOrder
      )
      .map((pickedQuestion: PickedQuestionModel) => {
        return get(pickedQuestion, 'question');
      });

    const questions = await Promise.all(questionPromises);
    this.questions = questions.compact();
  }

  @action
  toggleTextDirection() {
    this.redactorInstance.selection.save();
    this.redactorInstance.selection.restore();
    this.redactorInstance.plugin.toggleDirection.call();
  }
}
