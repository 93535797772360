/* import __COLOCATED_TEMPLATE__ from './nav-bar.hbs'; */
import PermissionsService from 'teamtailor/services/permissions';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import config from 'teamtailor/config/environment';
import { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import RouterService from '@ember/routing/router-service';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import NotificationsService from 'teamtailor/services/notifications';
import CompanyController from 'teamtailor/controllers/company';
import { get } from 'teamtailor/utils/get';

export default class extends Component {
  @service declare router: RouterService;
  @service declare permissions: PermissionsService;
  @service declare current: Current;
  @service declare flipper: FlipperService;
  @service declare notifications: NotificationsService;

  ulGap = 8;

  moreRect?: DOMRectReadOnly;
  ulRect?: DOMRectReadOnly;
  nurtureLiRect?: DOMRectReadOnly;
  surveysLiRect?: DOMRectReadOnly;
  ulWrapperRect?: DOMRectReadOnly;
  promotionsLiRect?: DOMRectReadOnly;
  employeeLiRect?: DOMRectReadOnly;
  analyticsLiRect?: DOMRectReadOnly;

  get lowPriorityWidth() {
    const existingItems = [
      this.nurtureLiRect,
      this.surveysLiRect,
      this.promotionsLiRect,
    ].filter(Boolean);

    return (
      existingItems.reduce((acc, rect) => acc + Math.round(rect.width), 0) +
      this.ulGap * Math.max(existingItems.length - 1, 0)
    );
  }

  get lowerPriorityWidth() {
    const existingItems = [this.employeeLiRect, this.analyticsLiRect].filter(
      Boolean
    );

    return (
      existingItems.reduce((acc, rect) => acc + Math.round(rect.width), 0) +
      this.ulGap * Math.max(existingItems.length - 1, 0)
    );
  }

  @controller('company') declare companyController: CompanyController;

  @tracked moveSomeToMore = false;
  @tracked moveSomeMoreToMore = false;

  contentPagesNotTimetailorDisabled = config.timeTailorEnabled.contentPages;

  get showNurture() {
    return (
      this.current.company.hasFeature('nurture') &&
      (this.permissions.has('nurture_campaign/create') ||
        this.permissions.has('nurture_campaign/update'))
    );
  }

  get showSurveys() {
    return (
      this.current.company.hasFeature('surveys') &&
      (this.permissions.has('form/create') ||
        this.permissions.has('form/update') ||
        this.permissions.has('form/results'))
    );
  }

  get showPromotions() {
    return (
      get(this.flipper, 'promotions_overview') &&
      this.permissions.has('job/list') &&
      !this.user.externalRecruiter
    );
  }

  get user() {
    return this.current.user;
  }

  get dashboardRoute() {
    return this.permissions.has('job/list') ||
      get(this.user, 'externalRecruiter')
      ? 'dashboard'
      : 'employee';
  }

  get onCandidates() {
    return (
      get(this, 'router').isActive('department') ||
      get(this, 'router').isActive('candidates')
    );
  }

  get giftToClaim() {
    if (
      !get(this.user, 'hasMadeTshirtDecision') &&
      get(get(this.user, 'tshirt'), 'status') === 'snoozed'
    ) {
      return 'tshirt';
    }

    if (
      !get(this.user, 'hasMadeSweaterDecision') &&
      get(get(this.user, 'sweater'), 'status') === 'snoozed'
    ) {
      return 'sweater';
    }

    return null;
  }

  get sortedCareerSites() {
    const careerSites = [
      get(this.current.company, 'defaultCareerSite'),
      ...get(this.current.company, 'careerSites')
        .sortBy('translatedLanguage')
        .rejectBy('isDefault'),
    ];

    return careerSites;
  }

  @action handleResize() {
    if (!this.ulRect || !this.ulWrapperRect) return;

    const ulWidth = Math.round(this.ulRect.width);
    const ulWrapperWidth = Math.round(this.ulWrapperRect.width);
    const isOverflowing = ulWidth >= ulWrapperWidth;

    if (isOverflowing && this.moveSomeToMore) {
      this.moveSomeMoreToMore = true;
    } else if (isOverflowing) {
      this.moveSomeToMore = true;
    } else {
      let doUpdateSome = true;
      let doUpdateSomeMore = true;
      if (this.moreRect) {
        const staticWidth = ulWidth - this.ulGap + 20;

        if (ulWrapperWidth < staticWidth + this.lowPriorityWidth + 20) {
          doUpdateSome = false;
        }

        if (ulWrapperWidth < staticWidth + this.lowerPriorityWidth + 20) {
          doUpdateSomeMore = false;
        }
      }

      if (doUpdateSome && !this.moveSomeMoreToMore) {
        this.moveSomeToMore = false;
      }

      if (doUpdateSomeMore && this.moveSomeToMore) {
        this.moveSomeMoreToMore = false;
      }
    }
  }

  @action
  handleGiftReminderClick(product: 'tshirt' | 'sweater') {
    const gift = get(this.user, product)!;
    set(gift, `isVisible`, true);
  }

  observer?: IntersectionObserver;

  setupNavBarVisibleHeightUpdater = modifier((element: HTMLElement) => {
    if (this.observer) return;

    const oneThresholdPerPixel = [
      0,
      ...Array(element.offsetHeight)
        .fill(null)
        .map((_, i) => (i + 1) / element.offsetHeight),
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        if (!this.companyController.isDestroyed) {
          const lastEntry = entries.pop();
          set(
            this.companyController,
            'navBarVisibleHeight',
            lastEntry!.intersectionRect.height
          );
        }
      },
      {
        threshold: oneThresholdPerPixel,
      }
    );

    observer.observe(element);
    this.observer = observer;

    return () => {
      observer.disconnect();
    };
  });
}
