/* import __COLOCATED_TEMPLATE__ from './custom-number.hbs'; */
import Component from '@glimmer/component';
import { set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class CustomNumberComponent extends Component {
  @tracked defaultValue;
  @tracked _value;
  @tracked useRange = this.getUseRange();
  @tracked min;
  @service intl;
  @tracked max;

  constructor() {
    super(...arguments);

    this.args.customField.getRangeValues().then((response) => {
      const [min, max] = response.range;
      this.min = min;
      this.max = max;
      this.defaultValue = [this.min, this.max];
      this.useRange = this.getUseRange();
    });
  }

  get hasSelectedRange() {
    return Boolean(this.selectedRange?.length);
  }

  get value() {
    let values;
    if (this.selectedRange?.length > 0) {
      values = this.selectedRange;
    }

    return this._value || values || this.defaultValue;
  }

  set value(value) {
    this._value = value;
  }

  get label() {
    let values = this.selectedRange;

    if (values?.length > 0) {
      return values.join(' - ');
    }

    return this.intl.t('common.all');
  }

  get selectedRange() {
    const returnValues = [];
    const { numeric_query_start, numeric_query_end } = this.args.selected || {};

    if (numeric_query_start) {
      returnValues.push(numeric_query_start);
    }

    if (numeric_query_end) {
      returnValues.push(numeric_query_end);
    }

    return returnValues;
  }

  get firstValue() {
    return this.value[0];
  }

  get secondValue() {
    return this.value[1];
  }

  set firstValue(value) {
    this.value[0] = value;
  }

  set secondValue(value) {
    this.value[1] = value;
  }

  getUseRange() {
    return Array.isArray(this.value);
  }

  @action
  onInputChange(index, e) {
    if (this.useRange) {
      let { value } = this;
      value[index] = e.target.value;

      set(this, 'value', value);
    } else {
      set(this, 'value', e.target.value);
    }
  }

  @action
  onChangeNumberRange(values) {
    this._value = values;
  }

  @action
  apply() {
    const [start, end] = this.value;
    this.args.onChangeRange(start, end);
  }
}
