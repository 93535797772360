/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Store from '@ember-data/store';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import ActivityModel from 'teamtailor/models/activity';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import toggleReaction from 'teamtailor/utils/toggle-reaction';
import { GroupedModelsByDate } from 'teamtailor/helpers/grouped-models-by-date';

export interface UnreadMessage {
  id: string;
  createdAt: Date;
}

interface JobsCollaborateListArgs {
  models: ActivityModel[];
  setRef: (ref: HTMLOListElement) => void;
  destroyActivity: (model: ActivityModel) => Promise<void>;
  unreadMessages?: UnreadMessage[];
}

export default class JobsCollaborateList extends Component<JobsCollaborateListArgs> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare current: Current;
  @service declare intl: IntlService;

  @tracked editingItem: ActivityModel | null = null;

  @action
  toggleEditItem(model: ActivityModel) {
    this.editingItem = model === this.editingItem ? null : model;
  }

  @action
  async addReaction(model: ActivityModel, emoji: string) {
    try {
      await toggleReaction(model, this.current.user, emoji);
    } catch (e) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  @action
  async pinActivity(model: ActivityModel) {
    model.toggleProperty('pinned');

    try {
      await model.save();
    } catch (e) {
      model.toggleProperty('pinned');
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  @action
  finishedSaving() {
    this.editingItem = null;
  }

  @action
  isUnread(model: ActivityModel) {
    if (this.args.unreadMessages && this.args.unreadMessages.length > 0) {
      const firstUnreadMessage = this.args.unreadMessages.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )[0];

      return model.id === firstUnreadMessage?.id.toString();
    }

    return false;
  }

  firstModelOfGroup(group: GroupedModelsByDate) {
    return group.models[0];
  }
}
