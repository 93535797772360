import ApplicationSerializer from './application';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import classic from 'ember-classic-decorator';

@classic
export default class SegmentSerializer extends ApplicationSerializer {
  serialize(snapshot) {
    let json = super.serialize(snapshot);

    if (json.filters?.root) {
      const departmentFilters = json.filters?.root.filter(
        (o) => !!o.department_id || !!o?.not?.department_id
      );

      departmentFilters.forEach((departmentFilter) => {
        const departmentIds = departmentFilter.not
          ? departmentFilter.not.department_id
          : departmentFilter.department_id;

        departmentIds.any.forEach((value, index) => {
          if (value === null) {
            departmentIds.any[index] = NO_DEPARTMENT_ID;
          }
        });
      });
    }

    return json;
  }
}
