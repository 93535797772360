/* import __COLOCATED_TEMPLATE__ from './candidates.hbs'; */
import BulkActionsBase from './base';
import { inject as service } from '@ember/service';

import { setProperties, get, action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';
import { later } from '@ember/runloop';

const MAX_CANDIDATES_TO_COMPARE = 50;

export default class BulkActionsCandidatesComponent extends BulkActionsBase {
  @service flipper;
  @service store;
  @service current;
  @service ttAlert;
  @service confetti;
  @service router;
  @service intl;

  @argDefault job = null;
  @argDefault selectedJobApplications = [];

  get user() {
    return this.current.user;
  }

  get modals() {
    const modals = [
      { name: 'MoveToStage', hidden: !this.hasJob },
      { name: 'SendNps', hidden: !this.hasJob },
      { name: 'Move', hidden: this.hasJob },
      { name: 'Message', hidden: false },
      { name: 'Share', hidden: false },
      { name: 'Assign', hidden: false },
      { name: 'Merge', hidden: false },
      { name: 'Tag', hidden: false },
      { name: 'Export', hidden: false },
      { name: 'DataPrivacy', hidden: false },
      { name: 'Reject', hidden: !this.hasJob || this.showRejected },
      { name: 'Delete', hidden: this.hasJob },
      { name: 'Todo', hidden: false },
      { name: 'Restrict', hidden: false },
    ];

    return modals.filterBy('hidden', false).mapBy('name');
  }

  get hasJob() {
    return !!this.job;
  }

  get onRejected() {
    return this.showRejected;
  }

  get notOnRejected() {
    return !this.onRejected;
  }

  get anyOnboarding() {
    return this.selectedJobApplications.some((ja) => ja.isOnboarding);
  }

  get selectedCandidatesIdsWithoutDeselectedIds() {
    return this.selectedIds.filter(
      (candidateId) => !this.deselectedIds.includes(candidateId)
    );
  }

  get selectedCandidates() {
    if (this.selectAll) {
      return this.store.peekAll('candidate');
    } else {
      return this.selectedCandidatesIdsWithoutDeselectedIds.map((id) => {
        return this.store.peekRecord('candidate', id);
      });
    }
  }

  get bulkOptions() {
    if (this.selectAll) {
      return {
        query: this.searchParams,
        deselectedCandidateIds: this.deselectedIds,
      };
    } else {
      return {
        selectedCandidateIds: this.selectedIds,
      };
    }
  }

  get searchParamsJson() {
    return JSON.stringify(this.searchParams);
  }

  get selectedCandidateIdsJson() {
    return JSON.stringify(this.selectedIds);
  }

  get deselectedCandidateIdsJson() {
    return JSON.stringify(this.deselectedIds);
  }

  get isOnCompareCandidates() {
    return this.router.currentRouteName === 'jobs.job.compare-candidates.index';
  }

  get maxCandidatesToCompare() {
    return MAX_CANDIDATES_TO_COMPARE;
  }

  get tooManyToCompare() {
    return this.args.selectedCount > MAX_CANDIDATES_TO_COMPARE;
  }

  get tooManyToShare() {
    return this.args.selectedCount > 20;
  }

  get bulkShareToolTip() {
    if (this.tooManyToShare) {
      return this.intl.t('candidates.candidate.bulk_actions.too_many_to_share');
    } else {
      return this.intl.t('candidates.candidate.bulk_actions.share');
    }
  }

  @action
  print() {
    this.router.transitionTo('print', {
      queryParams: { printOptions: this.postData() },
    });
  }

  @action
  handleSendWebhooks(partnerActivation, webhookData) {
    const promise = this.postAction(
      'bulk_webhook',
      this.postData({
        job_id: this.job?.id,
        partner_activation_id: get(partnerActivation, 'id'),
        webhook_data: webhookData,
        webhook_config: get(partnerActivation, 'cachedConfig'),
      })
    );
    promise.then(() => {
      setProperties(this, {
        currentModal: null,
        pickedPartnerActivation: null,
      });
    });
  }

  @action
  handleSelectNurtureCampaign(selectedNurtureCampaign) {
    const promise = this.postAction(
      'bulk_add_to_nurture_campaign',
      this.postData({
        nurture_campaign_id: get(selectedNurtureCampaign, 'id'),
      })
    );
    promise.then(() => {
      setProperties(this, {
        currentModal: null,
      });
    });
  }

  @action
  handleSendSurvey(survey) {
    const promise = this.postAction(
      'bulk_form_invite',
      this.postData({
        form_id: survey.id,
        job_id: this.job?.id,
      })
    );
    promise.then(() => {
      setProperties(this, {
        currentModal: null,
      });
    });

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }

  @action
  closeModal() {
    later(() => {
      this.currentModal = null;
    }, 10);
  }
}
