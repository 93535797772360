/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class Header extends Component {
  static positionalParams = ['title', 'type', 'order'];

  @argDefault sortable = true;

  dropdownContainerApis = [];

  get isSortable() {
    return this.sortable && !this.args.isEditing && !this.args.blur;
  }

  @action
  onMouseExitColumn(event) {
    let isInside = false;
    this.dropdownContainerApis.forEach((api) => {
      if (api.containerElement?.contains(event.target)) {
        isInside = true;
      }
    });

    if (isInside) {
      this.args.onMouseExitColumn?.();
    }
  }

  @action
  onRegisterChildDropdowns(api) {
    this.dropdownContainerApis.push(api);
  }
}
