/* import __COLOCATED_TEMPLATE__ from './form-field.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ImageModel from 'teamtailor/models/image';

type MediaLibraryFormFieldArgs = {
  onPickImage: (image: ImageModel) => void;
};

export default class MediaLibraryFormFieldComponent extends Component<MediaLibraryFormFieldArgs> {
  @tracked isPickingImage = false;

  @action
  handleMediaLibrary(): void {
    this.isPickingImage = !this.isPickingImage;
  }

  @action
  handlePickImage(image: ImageModel): void {
    this.args.onPickImage(image);
    this.isPickingImage = false;
  }
}
