/* import __COLOCATED_TEMPLATE__ from './report-metric.hbs'; */
import { lt } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { warn } from '@ember/debug';

const validSizes = ['small', 'default', 'large'];

export default Component.extend({
  classNames: 'c-report-metric',
  classNameBindings: [
    'isNegative:c-report-metric--negative',
    'isReverse:c-report-metric--reverse',
    'colorNeutral:c-report-metric--neutral',
    'sizeClass',
  ],

  baseIndex: 0,
  compareMetric: null,
  colorNeutral: false,
  label: null,
  metric: null,
  isPercentage: false,

  isLarge: false,
  isReverse: false,
  isNegative: lt('compareMetric', 0),

  size: computed('_size', {
    get() {
      return get(this, '_size') || 'default';
    },

    set(key, value) {
      if (validSizes.includes(value)) {
        set(this, '_size', value);
        return value;
      } else {
        warn(`Must specify some of the valid sizes: ${validSizes}`, false, {
          id: 'Unknown size',
        });
      }
    },
  }),

  sizeClass: computed('size', function () {
    return `c-report-metric--${get(this, 'size')}`;
  }),
});
