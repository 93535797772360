/* import __COLOCATED_TEMPLATE__ from './tag-category.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import CurrentService from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';

interface TagCategoryArgs {
  hideDrafts: boolean | undefined;
}

export default class TagCategory extends Component<TagCategoryArgs> {
  @service declare intl: IntlService;
  @service declare current: CurrentService;

  get options(): { id: string | null; label: string }[] {
    const options = [
      {
        id: 'candidate',
        label: this.intl.t('settings.tag_manager.categories.candidates'),
      },
      {
        id: 'job',
        label: this.intl.t('settings.tag_manager.categories.jobs'),
      },
      {
        id: 'image',
        label: this.intl.t('settings.tag_manager.categories.images'),
      },
      {
        id: 'post',
        label: this.intl.t('settings.tag_manager.categories.posts'),
      },
      {
        id: 'page',
        label: this.intl.t('settings.tag_manager.categories.pages'),
      },
      {
        id: 'question',
        label: this.intl.t('settings.tag_manager.categories.questions'),
      },
      {
        id: 'cannedresponse',
        label: this.intl.t('settings.tag_manager.categories.cannedresponses'),
      },
    ];

    if (this.current.company.hasFeature('interview_kit')) {
      options.pushObject({
        id: 'interviewkit',
        label: this.intl.t('settings.tag_manager.categories.interviewkits'),
      });
    }

    return options;
  }
}
