/* import __COLOCATED_TEMPLATE__ from './department-role-select.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import {
  DepartmentModel,
  DepartmentOrRoleModel,
  RoleModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import DivisionModel, { NO_DIVISION_ID } from 'teamtailor/models/division';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';

type DepartmentRoleSelectArgs = {
  model: DepartmentOrRoleModel;
  setDivision: (division: DivisionModel | null) => void;
  setDepartment: (department: DepartmentModel | null) => void;
  setRole: (role: RoleModel | null) => void;
};

export default class DepartmentRoleSelect extends Component<DepartmentRoleSelectArgs> {
  @service declare current: Current;

  get fallbackDivision() {
    return get(this, 'divisions').find(
      (division) => get(division, 'id') == NO_DIVISION_ID
    );
  }

  get model() {
    return get(this.args, 'model');
  }

  get division() {
    return get(get(this.model, 'division'), 'id')
      ? get(this.model, 'division')
      : this.fallbackDivision;
  }

  get divisions() {
    return get(this.current.company, 'divisions');
  }

  get departments() {
    let divisionId = get(this.division, 'id') || null;
    let departments = get(this.current.company, 'departments');

    if (this.divisions.length === 0 || !divisionId) {
      return departments;
    }

    if (divisionId === NO_DIVISION_ID) {
      divisionId = null;
    }

    return departments.filter(
      (department) =>
        get(get(department, 'division'), 'id') === divisionId ||
        department.id === NO_DEPARTMENT_ID
    );
  }

  get roles() {
    return get(this.args.model.department, 'roles');
  }

  @action
  handleDivisionChange(division: DivisionModel | null) {
    this.args.setDivision(division);
    this.args.setDepartment(null);
    this.args.setRole(null);
  }

  @action
  handleDepartmentChange(department: DepartmentModel | null) {
    this.args.setDepartment(department);
    this.args.setRole(null);
  }

  @action
  handleRoleChange(role: RoleModel | null) {
    this.args.setRole(role);
  }
}
