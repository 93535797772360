/* import __COLOCATED_TEMPLATE__ from './file.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked uploadFilename;

  @action
  handleUpload(url, fileName) {
    this.uploadFilename = fileName;
    this.args.onChange(url);
  }
}
