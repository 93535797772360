/* import __COLOCATED_TEMPLATE__ from './user.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import { trackedFunction } from 'ember-resources/util/function';

type SidebarFiltersUserArgs = {
  selected?: string;
};

export default class SidebarFiltersUser extends Component<SidebarFiltersUserArgs> {
  @service('current') declare current: Current;
  @service declare store: Store;

  selectedUserFunction = trackedFunction(this, async () => {
    if (this.args.selected) {
      const user = await this.store.findRecord('user', this.args.selected);
      return user;
    } else {
      return null;
    }
  });
}
