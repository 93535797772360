/* import __COLOCATED_TEMPLATE__ from './wrapper.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { argDefault } from 'teamtailor/utils/arg-default';
import { get } from 'teamtailor/utils/get';

export default class WidgetWrapperComponent extends Component {
  @service current;

  @argDefault showTitle = true;

  get widget() {
    return get(this.args, 'pickedDashboardWidget.dashboardWidget');
  }

  get emoji() {
    return this.args.customEmoji || get(this.widget, 'emoji');
  }

  get showEmoji() {
    return get(this.widget, 'showEmoji');
  }

  get hasHeader() {
    return this.showTitle && this.title && !this.args.hideHeader;
  }

  get title() {
    return this.args.customTitle || get(this.widget, 'titleTranslated');
  }

  get isCentered() {
    return this.args.centered && !this.args.isEditing;
  }

  get isColored() {
    return get(this.widget, 'color') && !this.args.hideColor;
  }

  get widgetColor() {
    return this.args.customColor || get(this.widget, 'color');
  }

  get hasSettings() {
    return !!this.args.onSettingsClick;
  }

  get hasCardClickCallback() {
    return !!this.args.cardClick && !this.args.isEditing;
  }
}
