/* import __COLOCATED_TEMPLATE__ from './funnel-visualization.hbs'; */
import Component from '@glimmer/component';
import { isEqual } from '@ember/utils';
import {
  percentageOf,
  dropOffCount,
  dropOffPercentage,
} from 'teamtailor/utils/analytics';
import { funnelColors } from 'teamtailor/components/insights/charts/colors';
import {
  fontOptions,
  boldFontOptions,
} from 'teamtailor/components/insights/charts/font-options';
import { inject as service } from '@ember/service';
import hexToRgb from 'teamtailor/utils/hex-to-rgb';

export default class InsightsMoleculesFunnelVisualization extends Component {
  @service intl;

  get steps() {
    return [
      this.intl.t('insights.molecules.funnel_visualization.ad_visits'),
      this.intl.t('insights.molecules.funnel_visualization.form_visits'),
      this.intl.t('insights.molecules.funnel_visualization.applications'),
    ];
  }

  get plotLines() {
    const { data } = this.args;
    const { steps } = this;

    return (data || []).map((step, index) => {
      if (!isEqual(steps[index], steps.lastObject)) {
        return {
          label: {
            text: `${percentageOf(data[index + 1], step)}%`,
            align: 'center',
            rotation: 0,
            y: -5,
            style: boldFontOptions,
          },

          color: boldFontOptions.color,
          width: 1,
          dashStyle: 'solid',
          value: index + 1,
        };
      } else {
        return {};
      }
    });
  }

  get chartData() {
    const { data } = this.args;
    const { steps } = this;

    return (data || []).map((step, index) => {
      return {
        ...this.seriesConf(step, index),
        data: [
          {
            x: index,
            y: step,
            id: steps[index] + index,
          },
          {
            x: index + 1,
            y: isEqual(steps[index], steps.lastObject) ? step : data[index + 1],
            id: steps[index] + (index + 1),
          },
        ],
      };
    });
  }

  seriesConf(step, index) {
    return {
      lineColor: funnelColors[index],
      fillColor: {
        linearGradient: [0, 0, 0, 200],
        stops: [
          [0, hexToRgb(funnelColors[index], 0.3)],
          [1, hexToRgb(funnelColors[index], 0)],
        ],
      },

      tooltip: {
        headerFormat: `<span>${this.steps[index]}</span><br/>`,
        pointFormat: this.pointFormatter(step, index),
        style: fontOptions,
      },
    };
  }

  get chartOptions() {
    return {
      ...this.axes,
      ...this.plotOptions,

      chart: {
        type: 'area',
        height: '230px',
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 15,
      },

      title: {
        text: undefined,
      },

      legend: {
        enabled: false,
      },

      stickyTracking: false,

      tooltip: {
        enabled: true,
        followPointer: true,
      },
    };
  }

  get axes() {
    return {
      xAxis: {
        labels: { enabled: false },
        tickLength: 0,
        lineColor: '#D2DDDF',
        visible: true,

        plotLines: this.plotLines,
      },

      yAxis: {
        min: 0,
        allowDecimals: false,
        offset: 0,
        tickLength: 30,

        title: {
          text: undefined,
        },

        labels: {
          align: 'left',
          enabled: true,
          padding: 0,
          x: 0,
          y: -5,
          style: fontOptions,
        },
      },
    };
  }

  get plotOptions() {
    return {
      plotOptions: {
        line: {
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },

        series: {
          trackByArea: true,
          stickyTracking: false,
          pointPlacement: 'center',
          label: {
            connectorAllowed: false,
          },

          marker: {
            enabled: false,
          },

          threshold: null,
        },

        area: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },

          fillOpacity: 1,
          trackByArea: true,
          lineWidth: 2,
        },
      },
    };
  }

  pointFormatterDropOff(current, next) {
    return `<br/>${this.intl.t(
      'insights.common.drop_off'
    )}: ${dropOffPercentage(current, next)}% (${dropOffCount(current, next)})`;
  }

  pointFormatter(step, index) {
    const { data } = this.args;
    const { steps } = this;

    if (isEqual(steps[index], steps.lastObject)) {
      return `${this.intl.t('candidate.applied')}: ${step}`;
    } else {
      return `${this.intl.t(
        'insights.common.views'
      )}: ${step}${this.pointFormatterDropOff(step, data[index + 1])}`;
    }
  }
}
