/* import __COLOCATED_TEMPLATE__ from './audio-meter.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { RemoteAudioTrack } from 'twilio-video';

const AUDIO_THRESHOLD_PERCENT = 0.06;
const RING_MAX_SIZE = 15;

interface VideoMeetingAudioMeterArgs {
  track: RemoteAudioTrack | MediaStream | null;
  audio?: boolean;
  isNewProvider: boolean;
}

export default class VideoMeetingAudioMeter extends Component<VideoMeetingAudioMeterArgs> {
  declare audioContext?: AudioContext;
  declare processor?: ScriptProcessorNode;

  updateView(element: HTMLElement, inputData: Float32Array) {
    const value = RING_MAX_SIZE * this.percentFromInputData(inputData);

    element.style.setProperty(
      '--tw-ring-shadow',
      `var(--tw-ring-inset) 0 0 0 calc(${value}px + var(--tw-ring-offset-width)) var(--tw-ring-color)`
    );
  }

  percentFromInputData(inputData: Float32Array) {
    const total = inputData.reduce((total, num) => total + Math.abs(num), 0);

    const percent = Math.sqrt(total / inputData.length);

    return percent < AUDIO_THRESHOLD_PERCENT ? 0 : percent;
  }

  @action
  onUpdateTrack(element: HTMLElement) {
    const { track } = this.args;

    if (!track || !this.isAudioEnabled) {
      return;
    }

    if (this.processor) {
      this.processor.disconnect();
    }

    if (this.audioContext) {
      this.audioContext.close();
    }

    let mediaStreamTrack: MediaStreamTrack | undefined;

    if (this.args.isNewProvider) {
      mediaStreamTrack = (<MediaStream>track).getAudioTracks()[0];
    } else {
      mediaStreamTrack = (<RemoteAudioTrack>track).mediaStreamTrack;
    }

    if (!mediaStreamTrack) return;

    const stream = new MediaStream([mediaStreamTrack]);

    this.audioContext = new AudioContext();
    const mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
    this.processor = this.audioContext.createScriptProcessor(2048, 1, 1);
    mediaStreamSource.connect(this.processor);
    this.processor.connect(this.audioContext.destination);

    this.processor.onaudioprocess = (e) => {
      requestAnimationFrame(() => {
        const data = e.inputBuffer.getChannelData(0);
        this.updateView(element, data);
      });
    };
  }

  get isAudioEnabled() {
    return this.args.audio === undefined ? true : this.args.audio;
  }
}
