/* import __COLOCATED_TEMPLATE__ from './candidate-image.hbs'; */
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { ALLOWED_SIZES, SIZE_CLASSES } from 'teamtailor/constants/avatar';
import { verifyArg } from 'teamtailor/utils/verify-arg';

/** @deprecated Use sizes from Icon component instead */
const oldSizes = [
  'x-smaller',
  'x-small',
  'small',
  'default',
  'smaller-medium',
  'small-medium',
  'medium',
  'large',
  'extra-large',
  'larger-extra-large',
  'xx-large',
];

export default class AvatarImageComponent extends Component {
  @service() anonymals;

  get _tooltipSide() {
    return this.args.tooltipSide || 'top';
  }

  get size() {
    verifyArg(
      this.args.size,
      [...ALLOWED_SIZES, ...oldSizes],
      'AvatarImage @size',
      'default'
    );

    return this.args.size;
  }

  get noImage() {
    return !this.imageSrc;
  }

  get type() {
    let type = null;
    if (this.imageSrc) {
      type = 'picture';
    } else {
      type = 'initials';
    }

    return type;
  }

  get imageSrc() {
    if (this.args.anonymous) {
      return this.anonymal.iconUrl;
    }

    return this.args.src;
  }

  get sizeClass() {
    if (ALLOWED_SIZES.includes(this.args.size)) {
      return SIZE_CLASSES[this.size];
    }

    return `c-avatar-image--${this.args.size || 'default'}`;
  }

  get typeClass() {
    return `c-avatar-image--${this.type}`;
  }

  get style() {
    const isAnonymous = this.args.anonymous;
    if (isAnonymous) {
      const candidateColor = this.anonymal.colorHex;
      return htmlSafe(`background-color: ${candidateColor}`);
    }

    if (this.args.color) {
      return htmlSafe(`background-color: ${this.args.color};`);
    }
  }

  get anonymal() {
    return this.anonymals.fromSeed(this.args.candidateId);
  }
}
