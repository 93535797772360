/* import __COLOCATED_TEMPLATE__ from './webhooks-modal.hbs'; */
import Component from '@glimmer/component';

import { get, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked pickedPartnerActivation = null;
  @tracked validForm = true;
  @tracked webhookData = {};
  @tracked webhookConfig;
  @tracked cachedData = {};
  @tracked webhookConfigChunks = [];
  @tracked configSuccess = false;
  @tracked loadingConfig = false;
  @tracked partnerConfigError;
  @service current;
  @service router;
  @service intl;
  @service user;
  @service pusher;

  @tracked selectedJobApplication =
    this.hasSingleJobApplication && this.jobApplications.firstObject;

  get jobApplications() {
    return this.args.candidate?.jobApplications || [];
  }

  get hasSingleJobApplication() {
    return this.jobApplications.length === 1;
  }

  get routeIncludeJob() {
    return this.router.currentRouteName.includes('job');
  }

  get hasAccessToJobContext() {
    return this.routeIncludeJob || this.selectedJobApplication;
  }

  get disabledSubmit() {
    return !this.validForm || this.partnerConfigFieldsTask.isRunning;
  }

  get jobApplicationParams() {
    const job = this.router.currentRoute.find((routes) =>
      routes.paramNames.includes('job_id')
    );
    const jobId = job && get(job, 'params.job_id');

    if (!jobId) {
      return false;
    }

    const stage = this.jobApplications.find((jobApplication) => {
      return parseInt(jobId, 10) === get(jobApplication, 'jobId');
    });
    const stageId = stage && get(stage, 'id');

    return stageId && { job_id: jobId, stage_id: stageId };
  }

  get configParams() {
    return (
      this.jobApplicationParams || {
        job_id: get(this, 'selectedJobApplication.jobId'),
        stage_id: get(this, 'selectedJobApplication.stage.id'),
      }
    );
  }

  get partnerActivationsWithManualCapabilities() {
    return get(this.current.company, 'installedPartnerActivations')?.filter(
      (activation) =>
        get(activation, 'capabilityCandidateManual') || this.routeIncludeJob
    );
  }

  get showContextProviderModal() {
    return (
      this.args.candidate && !this.hasAccessToJobContext && this.jobApplications
    );
  }

  get partnerActivations() {
    return this.args.candidate && this.hasAccessToJobContext
      ? get(this.current.company, 'installedPartnerActivations')
      : this.partnerActivationsWithManualCapabilities;
  }

  get contextWarning() {
    if (!this.args.candidate) {
      return this.intl.t(
        'components.partner.webhooks_modal.webhook_context_alert'
      );
    }

    return (
      !this.hasAccessToJobContext &&
      this.intl.t('components.partner.webhooks_modal.candidate_context_alert')
    );
  }

  partnerConfigFieldsTask = task(async (key) => {
    const webhook_data_params = this.updateWebhookData(key);
    try {
      this.loadingConfig = true;
      const { config } = await this.pickedPartnerActivation.getConfig({
        ...this.configParams,
        webhook_data: Object.keys(webhook_data_params).length
          ? JSON.stringify(webhook_data_params)
          : '',
      });

      if (config?.fields) {
        this.setConfig(config.fields);
      }

      return null;
    } catch (error) {
      this.loadingConfig = false;
      this.validForm = false;
      this.partnerConfigError = `${error?.errors[0].status} ${error?.errors[0].detail}`;
      return error;
    }
  });

  @action
  async maybeRefetchConfig(key, value) {
    const refetchableField = this.webhookConfig.find(
      (field) => field.refetch && field.id === key
    );

    if (refetchableField && this.cachedData[key] !== value) {
      this.cachedData[key] = value;
      await this.partnerConfigFieldsTask.perform(key);
    }
  }

  @action
  updateWebhookData(key = null) {
    const config = this.webhookConfig;
    if (!Array.isArray(config)) return this.webhookData;
    const fields = config.reduce((acc, field) => {
      if (field.parentFieldId !== key) {
        return { ...acc, [field.id]: this.webhookData[field.id] };
      }

      return acc;
    }, {});
    return fields;
  }

  @action
  onValueChanged(key, value) {
    this.webhookData[key] = value;
    this.maybeRefetchConfig(key, value);
    this.validForm = this.webhookConfig.isEvery('isValid');
  }

  @action
  pickPartnerActivation(partnerActivation, event) {
    event.preventDefault();
    this.pickedPartnerActivation = partnerActivation;
    this.partnerConfigFieldsTask.perform();
  }

  @action
  setJobApplicationContext(jobApplication) {
    this.selectedJobApplication = jobApplication;
  }

  @action
  handleClose() {
    this.hasJobContext = this.routeIncludeJob;
    if (this.args?.onClose) {
      this.args.onClose();
    }
  }

  @action
  async setupPusher() {
    try {
      const channel = await this.pusher.channel(
        get(this, 'user.notificationsChannel')
      );

      channel.bind('partner-request-config-chunk', (data) => {
        const { chunk, final } = data;

        this.webhookConfigChunks.push(chunk);

        if (final) {
          try {
            const parsedConfig = JSON.parse(this.webhookConfigChunks.join(''));

            const fields = parsedConfig ? parsedConfig?.config?.fields : [];
            this.setConfig(fields);
          } catch (error) {
            this.partnerConfigError = this.intl.t(
              'components.partner.webhooks_modal.config_fetch_failed'
            );
            this.configSuccess = false;
            this.loadingConfig = false;
          }
        }
      });

      channel.bind('partner-request-config-fail', (e) => {
        this.configSuccess = false;
        this.loadingConfig = false;
        this.validForm = false;

        this.partnerConfigError = e;
      });
    } catch {
      return;
    }
  }

  @action
  setConfig(fields) {
    this.webhookConfig = fields;
    this.webhookConfigChunks = [];
    this.configSuccess = true;
    this.loadingConfig = false;
  }

  @action
  async teardownPusher() {
    const channel = await this.pusher.channel(
      get(this, 'user.notificationsChannel')
    );

    if (channel) {
      channel.unbind('partner-request-config-chunk');
      channel.unbind('partner-request-config-fail');
    }
  }
}
