/* import __COLOCATED_TEMPLATE__ from './pagination-pills.hbs'; */
import Component from '@glimmer/component';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class PaginationPillsComponent extends Component {
  @tracked _activePage = 1;

  @computed('args.dotCount')
  get dots() {
    return this.args.dotCount > 1
      ? [...Array(this.args.dotCount).keys()].map((i) => i + 1)
      : [];
  }

  @computed('_activePage', 'args.activePage')
  get activePage() {
    return this.args.activePage ? this.args.activePage : this._activePage;
  }

  handlePageUpdate = (_, [page]) => {
    this._activePage = page || 1;
  };

  @action
  handleClick(page) {
    this._activePage = page;
    if (this.args.onChange) {
      this.args.onChange(page);
    }
  }
}
