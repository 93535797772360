/* import __COLOCATED_TEMPLATE__ from './star-ratings.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';

import { argDefault } from 'teamtailor/utils/arg-default';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';

import { AllowedSizes } from 'teamtailor/constants/icon';
import FlipperService from 'teamtailor/services/flipper';

type Rating = number;

interface StarRatingsArgs {
  value?: Rating;
  size?: AllowedSizes;
  onRate?: (rating: Rating) => void;
}

export default class StarRatings extends Component<StarRatingsArgs> {
  maxRating = 5;

  @argDefault value = 0;
  @argDefault size = '24';

  @service declare current: Current;
  @service declare flipper: FlipperService;

  get stars() {
    return Array.from({ length: this.maxRating }).map((_, index) => {
      let style = 'duotone';
      let half = false;
      const rating = index + 1;

      half = this.value - index >= 0.5 && this.value - index < 1;

      const isColored = rating <= this.value || half;

      if (half) {
        style = 'duotone';
      } else if (isColored) {
        style = 'solid';
      } else {
        style = 'regular';
      }

      return {
        id: `star-${rating}-${guidFor(this)}`,
        rating,
        checked: this.value === rating,
        icon: half ? 'star-half-stroke' : 'star',
        style,
        description: this.current.company.ratingDescriptions[index],
        colored: isColored,
        half,
      };
    });
  }

  @action
  setRating(rating: Rating) {
    if (this.args.onRate) {
      this.args.onRate(rating);
    }
  }
}
