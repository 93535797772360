/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import {
  CandidateModel,
  JobApplicationModel,
  TodoModel,
  UserModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { action, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import { tracked } from '@glimmer/tracking';
import JobModel from 'teamtailor/models/job';
import { AsyncBelongsTo } from '@ember-data/model';

interface TodosSignature {
  Args: {
    candidate: CandidateModel;
    jobApplication?: JobApplicationModel;
    globalFilter: boolean;
  };
}

export default class CandidateModalNewRightSidebarTodos extends Component<TodosSignature> {
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @tracked editingTodo: TodoModel | null = null;

  get todos() {
    const todos = get(this.args.candidate, 'todos');

    if (this.args.globalFilter) {
      return todos;
    }

    const jobId = get(this.args.jobApplication, 'jobId')?.toString();
    return todos.filter((todo) => {
      const todoJobId = todo.belongsTo('job').id();
      return !todoJobId || todoJobId === jobId;
    });
  }

  get groupedTodos() {
    const grouped = this.todos.reduce(
      (acc, todo) => {
        const todoJob = get(todo, 'job');

        const todoJobId = get(todoJob, 'id') || 'global';

        if (!acc[todoJobId]) {
          acc[todoJobId] = {
            job: todo.belongsTo('job').id() ? todoJob : null,
            todos: [],
          };
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        acc[todoJobId]!.todos.push(todo);

        return acc;
      },
      {} as Record<
        string,
        { job: AsyncBelongsTo<JobModel> | null; todos: TodoModel[] }
      >
    );

    return Object.values(grouped).sort(
      (
        a: { job: AsyncBelongsTo<JobModel> | null; todos: TodoModel[] },
        b: { job: AsyncBelongsTo<JobModel> | null; todos: TodoModel[] }
      ) => {
        if (a.job === null) {
          return -1;
        }

        if (b.job === null) {
          return 1;
        }

        return get(a.job, 'title') < get(b.job, 'title') ? -1 : 1;
      }
    );
  }

  get newTodos() {
    return this.todos.filter((todo) => todo.createdNow);
  }

  get alreadyExistingTodos() {
    return this.todos.filter((todo) => !todo.createdNow);
  }

  get incompleteTodos() {
    return this.alreadyExistingTodos.filter((todo) => !todo.completed);
  }

  get completedTodos() {
    return this.alreadyExistingTodos.filter((todo) => todo.completed);
  }

  get incompleteTodosWithDueDate() {
    return this.incompleteTodos.filter((todo) => todo.hasDueDate);
  }

  get incompleteTodosWithoutDueDate() {
    return this.incompleteTodos.filter((todo) => !todo.hasDueDate);
  }

  get sortedIncompleteTodosWithDueDate() {
    return this.incompleteTodosWithDueDate.sortBy('due');
  }

  get sortedTodos() {
    return [
      ...this.newTodos.reverseObjects(),
      ...this.sortedIncompleteTodosWithDueDate,
      ...this.incompleteTodosWithoutDueDate,
      ...this.completedTodos,
    ];
  }

  onError() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  handleToggle(todo: TodoModel) {
    return todo.toggleCompletion();
  }

  @action
  handleCreateTodo({
    value,
    description,
    due,
    assignee,
  }: {
    value: string;
    description: string;
    due: string;
    assignee: UserModel;
  }) {
    return get(this.args.candidate, 'todos')
      .createRecord({
        createdNow: true,
        value,
        description,
        due,
        assignee,
        candidate: this.args.candidate,
        job: this.args.jobApplication?.job,
        jobApplication: this.args.jobApplication,
      })
      .save()
      .catch(() => this.onError());
  }

  @action
  deleteTodo(todo: TodoModel) {
    return todo.destroyRecord().catch((e) => {
      this.onError();
      return e;
    });
  }

  @action
  editTodo(todo: TodoModel) {
    this.editingTodo = todo;
  }

  @action
  saveTodo({
    value,
    description,
    due,
    assignee,
  }: {
    value: string;
    description: string;
    due: string;
    assignee: UserModel;
  }) {
    if (!this.editingTodo) {
      return;
    }

    setProperties(this.editingTodo, {
      value,
      description,
      due,
    });

    set(this.editingTodo, 'assignee', assignee);
    set(this.editingTodo, 'candidate', this.args.candidate);

    return this.editingTodo.save().catch((e) => {
      this.onError();
      return e;
    });
  }

  @action
  setDueDate(todo: TodoModel, dueDate: Date) {
    return todo.setDueDate(dueDate).catch((e) => {
      this.onError();
      return e;
    });
  }
}
