/* import __COLOCATED_TEMPLATE__ from './devices.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class InsightsWidgetsDevices extends Component {
  @service intl;

  get sortedData() {
    if (!this.args.data) {
      return [];
    }

    return this.args.data.sort((a, b) => b.sessions - a.sessions);
  }

  get barChartData() {
    return this.sortedData?.map((row) => ({
      ...row,
      label: row.name,
      value: row.sessions,
    }));
  }

  get total() {
    return this.barChartData?.reduce((acc, row) => acc + row.value, 0);
  }
}
