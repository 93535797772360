/* import __COLOCATED_TEMPLATE__ from './candidate-filters.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  FilterOption,
  FilterValue,
  NestedOptions,
} from 'teamtailor/components/fancy-filters.d';
import moment from 'moment-timezone';
import ContainerFilter from 'teamtailor/utils/fancy-filters/container';

import ActiveFilter from './active-filter';
import FancyFilterOption from 'teamtailor/utils/fancy-filter-option';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import UserModel from 'teamtailor/models/user';
import {
  CandidateFiltersJSON,
  JSONFilter,
  JSONOperator,
  JSONValue,
} from './candidate-filters-json';
import BaseFilter from 'teamtailor/utils/fancy-filters/base';
import { task, waitForProperty } from 'ember-concurrency';
import { assert } from '@ember/debug';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import { scrollToPromise } from 'teamtailor/utils/scroll-to-promise';

interface CandidateFiltersArgs {
  headingBarIsStuck: boolean;
  searchParams?: CandidateFiltersJSON;
  onUpdateQuery?: (query: CandidateFiltersJSON) => void;
  availableFilters?: FilterOption[];
}

function hasIsFulfilled(a: any): a is PromiseProxyMixin<UserModel> {
  return typeof a === 'object' && 'isFulfilled' in a;
}

export default class CandidateFilters extends Component<CandidateFiltersArgs> {
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked activeFilters: ActiveFilter[] = [];

  get availableFilters(): FilterOption[] {
    return this.args.availableFilters || [];
  }

  get visibleFilters(): FilterOption[] {
    return this.availableFilters.filter((filter: FilterOption) => {
      return !filter.parent;
    });
  }

  get validFilters(): ActiveFilter[] {
    return this.activeFilters.toArray().filter((filter) => filter.isValid);
  }

  get hasInvalidFilters(): boolean {
    return this.activeFilters.length !== this.validFilters.length;
  }

  prepareFancyFilter(filter: FilterOption | BaseFilter): FilterOption {
    if (filter instanceof FancyFilterOption) {
      return filter;
    }

    if (filter instanceof BaseFilter) {
      return Object.assign(filter, {
        intl: this.intl,
        translationPrefix: 'components.fancy_filters.filters',
      });
    }

    return FancyFilterOption.create(this, {
      translationPrefix: 'components.fancy_filters.filters',
      ...filter,
    });
  }

  async makeSureFilterValuesAreLoaded(filters: ActiveFilter[]): Promise<void> {
    const promises = filters.map((filter) => {
      return new Promise((resolve) => {
        let { value } = filter;
        if (!Array.isArray(value) && typeof value === 'object') {
          value = [value] as [any];
        }

        if (Array.isArray(value)) {
          const unfulfilledValues: FilterValue[] = (
            value as FilterValue[]
          ).filter((v) => hasIsFulfilled(v) && !v.isFulfilled);
          const innerPromises = unfulfilledValues.map((v: any, i: number) => {
            return new Promise((valueResolve) => {
              if (Object.keys(v).includes('isFulfilled')) {
                return (value as [any])[i].then((vv: FilterValue) => {
                  return valueResolve(vv);
                });
              }

              return valueResolve(value);
            });
          });
          return Promise.all(innerPromises).then(() => {
            resolve(filter);
          });
        }

        return resolve(filter);
      });
    });

    await Promise.all(promises.flat());
  }

  @action
  async handleRemoveFilter(filter: ActiveFilter) {
    if (this.args.headingBarIsStuck) {
      await scrollToPromise(0);
    }

    this.activeFilters.removeObject(filter);
  }

  @action
  async handleSelectFilter(
    filter: FilterOption | BaseFilter | ContainerFilter
  ) {
    if (this.args.headingBarIsStuck) {
      await scrollToPromise(0);
    }

    const fancyFilter = this.prepareFancyFilter(filter);

    this.activeFilters.forEach((filter: ActiveFilter) => {
      filter.isOpen = false;
    });

    const activefilter: ActiveFilter = new ActiveFilter(fancyFilter);
    activefilter.isOpen = true;
    this.setDefaultFilterValue(activefilter);
    this.activeFilters.pushObject(activefilter);
  }

  @action
  async handleFilterUpdate(): Promise<void> {
    if (this.args.headingBarIsStuck) {
      await scrollToPromise(0);
    }

    await this.makeSureFilterValuesAreLoaded(this.validFilters);

    const query: CandidateFiltersJSON = {
      root: this.validFilters.map((filter) => filter.asJSON()),
    };
    this.args.onUpdateQuery?.(query);
  }

  findFilter(
    key: string,
    object: JSONOperator | JSONFilter | JSONFilter[] | NestedOptions | JSONValue
  ): FilterOption | undefined {
    return this.availableFilters.find((f) => {
      if (!f.nested && f.name === key) {
        return true;
      }

      if (f.nested?.name !== key) {
        return false;
      }

      return (
        (f.nested.id === (object as NestedOptions)[`${f.nested.name}_id`] &&
          f.type !== 'container' &&
          (Object.keys(object).includes(f.name) ||
            (Object.keys(object).length === 1 && !f.name))) ||
        (!f.nested.id &&
          f.type !== 'container' &&
          f.groupedName &&
          Object.keys(object).includes(f.groupedName) &&
          f.name ===
            (
              (object as JSONFilter | undefined)?.[f.groupedName] as
                | JSONOperator
                | undefined
            )?.equals)
      );
    });
  }

  parseNode(json: JSONFilter, currentNode?: JSONFilter): ActiveFilter[] {
    const node = currentNode || json;

    if (Array.isArray(node)) {
      return (node as JSONFilter[]).map((n) => this.parseNode(json, n)).flat();
    }

    const filters: ActiveFilter[][] = Object.keys(node).map((key) => {
      const currentNode = node[key];
      assert('node[key] must exist', currentNode);

      const filter = this.findFilter(key, currentNode);

      if (filter) {
        const fancyFilter = this.prepareFancyFilter(filter);

        const activefilter: ActiveFilter = new ActiveFilter(fancyFilter);

        activefilter.parse(json);
        return [activefilter];
      }

      if (key === 'or' || key === 'not' || key === 'and') {
        return this.parseNode(json, node[key] as JSONFilter);
      }

      return [];
    });

    return filters.flat();
  }

  setDefaultFilterValue(filter: ActiveFilter): void {
    switch (filter.filterType) {
      case 'date':
        filter.value = moment().format('YYYY-MM-DD');
        break;

      case 'polar':
      case 'ReviewedBy':
        filter.value = filter.options.firstObject;
        break;
    }
  }

  doUpdateSearchParams = task(async (searchParams: CandidateFiltersJSON) => {
    const filters = searchParams.root
      ?.map((node: JSONFilter) => {
        return this.parseNode(node);
      })
      .flat();

    if (filters) {
      await this.makeSureFilterValuesAreLoaded(filters);
    }

    if (
      !filters ||
      this.newFiltersEqualsActiveFilters(filters, this.activeFilters)
    ) {
      return;
    }

    await waitForProperty(
      this.args,
      'availableFilters',
      (v: FilterOption[] | undefined) => !!v && v.length > 0
    );

    this.removeUnusedFilters(filters);
    if (
      !this.activeFilters.some((filter) => filter.name === 'average_rating')
    ) {
      this.addMissingFilters(filters);
    }
  });

  removeUnusedFilters(filters: ActiveFilter[]): void {
    const filtersToRemove = this.activeFilters.filter(
      (filter: ActiveFilter) => {
        return !filter.isOpen && !this.listContainsFilter(filter, filters);
      }
    );

    if (filtersToRemove.length > 0) {
      this.activeFilters.removeObjects(filtersToRemove);
    }
  }

  addMissingFilters(filters: ActiveFilter[]): void {
    const filtersToAdd = filters.filter((filter: ActiveFilter) => {
      return !this.listContainsFilter(filter, this.activeFilters);
    });

    if (filtersToAdd.length > 0) {
      this.activeFilters.pushObjects(filtersToAdd);
    }
  }

  listContainsFilter(filter: ActiveFilter, list: ActiveFilter[]): boolean {
    return !!list.find((listFilter: ActiveFilter) => listFilter.equals(filter));
  }

  newFiltersEqualsActiveFilters(
    filters: ActiveFilter[],
    activeFilters: ActiveFilter[]
  ): boolean {
    if (filters.length === activeFilters.length) {
      return filters.every((filter) => {
        return this.listContainsFilter(filter, activeFilters);
      });
    }

    return false;
  }

  @action
  handleSearchParamsUpdate(
    _element: HTMLDivElement,
    [searchParams]: CandidateFiltersJSON[]
  ): void {
    if (this.doUpdateSearchParams.isRunning) {
      return;
    }

    assert('searchParams must exist', searchParams);
    this.doUpdateSearchParams.perform(searchParams);
  }

  @action
  replaceFilter(
    activeFilter: ActiveFilter,
    fancyFilter: FancyFilterOption
  ): void {
    const indexOfActiveFilter = this.activeFilters.indexOf(activeFilter);

    const newActiveFilter: ActiveFilter = new ActiveFilter(fancyFilter);
    newActiveFilter.isOpen = true;
    const activeFilters = [...this.activeFilters];
    activeFilters.splice(indexOfActiveFilter, 1, newActiveFilter);
    this.activeFilters = activeFilters;
  }
}
