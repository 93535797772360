/* import __COLOCATED_TEMPLATE__ from './weigher.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import ScorecardPick from 'teamtailor/models/scorecard-pick';
import { inject as service } from '@ember/service';
import Intl from 'ember-intl/services/intl';
import ScorecardCriteriaSorter from 'teamtailor/utils/scorecard-criteria-sorter';
import { COMPETENCE_WEIGHTS } from 'teamtailor/constants/competence-weights';

interface CompetenceWeigherArgs {
  scorecardPicks?: ScorecardPick[];
}

export default class CompetenceWeigherComponent extends Component<CompetenceWeigherArgs> {
  @service declare intl: Intl;

  sorter: ScorecardCriteriaSorter;
  weights = COMPETENCE_WEIGHTS;

  constructor(owner: unknown, args: CompetenceWeigherArgs) {
    super(owner, args);
    this.sorter = new ScorecardCriteriaSorter(this.intl.primaryLocale);
  }

  get weightSum(): number | undefined {
    return this.args.scorecardPicks?.reduce(
      (sum: number, scorecardPick: ScorecardPick) => {
        return sum + (scorecardPick.weight || 0);
      },
      0
    );
  }

  get sortedScorecardPicks(): ScorecardPick[] {
    if (this.args.scorecardPicks) {
      return this.args.scorecardPicks.toArray().sort(this.sorter.sort);
    }

    return [];
  }

  @action
  calculateWidth(scorecardPick: ScorecardPick): number {
    if (!scorecardPick.weight || !this.weightSum || !this.args.scorecardPicks) {
      return 0;
    }

    const percentage =
      this.weightSum === 0
        ? 100 / this.args.scorecardPicks.length
        : (scorecardPick.weight / this.weightSum) * 100;
    return +percentage.toFixed(2);
  }

  @action
  calculatePercentage(scorecardPick: ScorecardPick): number {
    return Math.round(this.calculateWidth(scorecardPick));
  }
}
