/* import __COLOCATED_TEMPLATE__ from './form-radio-button.hbs'; */
import Component from '@ember/component';
import { get, set, computed, action } from '@ember/object';
import { equal } from '@ember/object/computed';

export default Component.extend({
  classNames: ['c-form-radio-button'],
  classNameBindings: [
    'isDarkTheme:c-form-radio-button--dark',
    'isCheckbox:c-form-radio-button--checkbox',
    'isSecondary:c-form-radio-button--secondary',
    'noMargin:no-margin',
  ],

  noMargin: false,

  isDarkTheme: equal('theme', 'dark'),

  isSecondary: equal('appearance', 'secondary'),

  checked: computed('groupValue', 'value', {
    get() {
      return get(this, 'groupValue') === get(this, 'value');
    },

    set(key, value) {
      return value;
    },
  }),

  inputId: computed('elementId', function () {
    return `${get(this, 'elementId')}-input`;
  }),

  handleChange: action(function (event) {
    set(this, 'groupValue', get(this, 'value'));
    event.target.checked = true;
    if (get(this, 'onChange')) {
      get(this, 'onChange')(get(this, 'value'));
    }
  }),
});
