/* import __COLOCATED_TEMPLATE__ from './user-delete-modal.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class UserDeleteModal extends Component {
  @service ttAlert;
  @service intl;

  @tracked pickedReplacement = null;

  get hasJobs() {
    return this.args.employee.jobs.length;
  }

  get hasNurtureCampaignSteps() {
    return !!this.args.employee.nurtureCampaignSteps.length;
  }

  get hasRequisitionOwner() {
    return !!this.args.employee.requisitionOwner.length;
  }

  get hasRequisitionRecruiter() {
    return !!this.args.employee.requisitionRecruiter.length;
  }

  get hasRequisitionApprover() {
    return !!this.args.employee.requisitionApproverCount;
  }

  get hasJobOfferApprover() {
    return !!this.args.employee.jobOfferApproverCount;
  }

  get needsReplacement() {
    return (
      this.hasJobs ||
      this.hasNurtureCampaignSteps ||
      this.hasRequisitionOwner ||
      this.hasRequisitionRecruiter ||
      this.hasRequisitionApprover ||
      this.hasJobOfferApprover
    );
  }

  get currentEmployeeAsArray() {
    return [this.args.employee];
  }

  get isDeleteButtonDisabled() {
    return this.needsReplacement && !this.args.employee.replacementUserId;
  }

  @action
  deleteUser() {
    if (!this.isDeleteButtonDisabled) {
      this.args.deleteUser();
    }
  }

  @action
  pickReplacement(user) {
    this.pickedReplacement = user;
    this.args.employee.replacementUserId = user ? user.id : undefined;
  }

  @action
  closeModal() {
    if (this.args.isOpen) {
      this.args.closeModal();
    }
  }
}
