/* import __COLOCATED_TEMPLATE__ from './stage-trigger-edit.hbs'; */
import Component from '@glimmer/component';
import { TRIGGER_COLORS } from 'teamtailor/constants/trigger-colors';

export default class StageTriggerEdit extends Component {
  get icon() {
    switch (this.args.trigger.type) {
      case 'message':
        return 'envelope';
      case 'note':
        return 'note-sticky';
      case 'nps':
        return 'chart-area';
      case 'tag':
        return 'tag';
      case 'share':
        return 'share-nodes';
      case 'webhook':
        return 'robot';
      case 'smart-move':
        return 'lightbulb';
      case 'smart-schedule':
        return 'calendar';
      case 'todo':
        return 'square-check';
      case 'prediction':
        return 'robot';
      case 'survey':
        return 'ballot-check';
      case 'ask-for-feedback':
        return 'message';
      case 'restrict':
        return 'user-secret';
      case 'nurture':
        return 'message-heart';
      default:
        return '';
    }
  }

  get iconColor() {
    return TRIGGER_COLORS[this.args.trigger.type];
  }

  get iconStyle() {
    switch (this.args.trigger.type) {
      case 'nurture':
        return 'kit';
      default:
        return null;
    }
  }
}
