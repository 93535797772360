/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import Component from '@glimmer/component';
import {
  FilterCategory,
  FilterOption,
} from 'teamtailor/components/fancy-filters';
import { tracked } from '@glimmer/tracking';

interface CustomizeTableListArgs {
  options: FilterOption[];
  onSelect: (value: FilterOption) => void;
  appearance?: string;
}

export default class extends Component<CustomizeTableListArgs> {
  @tracked activeOption?: FilterOption = undefined;
  declare el: HTMLDivElement;
  @tracked prevLockTheSize = false;

  get isSecondary(): boolean {
    return this.args.appearance === 'secondary';
  }

  get categories(): FilterCategory[] {
    return this.args.options.reduce(
      (categories: FilterCategory[], option: FilterOption) => {
        const category = categories.find((c) => c.name === option.category);

        if (category) {
          category.options.push(option);
        } else {
          categories.push({
            name: option.category,
            options: [option],
          });
        }

        return categories;
      },
      []
    );
  }
}
