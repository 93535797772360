/* import __COLOCATED_TEMPLATE__ from './panel.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { v1, v4 } from 'ember-uuid';

interface SettingsSecurityCollapsePanelArgs {
  title: string;
  open: boolean;
  route?: string;
}

export default class SettingsSecurityCollapsePanel extends Component<SettingsSecurityCollapsePanelArgs> {
  @tracked isOpen = this.args.open;

  get headingId() {
    return v1();
  }

  get contentId() {
    return v4();
  }

  get canToggle() {
    return !this.args.route;
  }

  @action
  toggleOpen() {
    if (this.canToggle) {
      this.isOpen = !this.isOpen;
    }
  }
}
