/* import __COLOCATED_TEMPLATE__ from './status-badge.hbs'; */
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

interface StatusBadgeArgs {
  size?: string;
  status?: string;
}

export default class StatusBadge extends Component<StatusBadgeArgs> {
  @argDefault size = '20';
  @argDefault status = 'pending';

  get statusColor() {
    switch (this.args.status) {
      case 'accepted':
        return 'text-green-600';
      case 'declined':
        return 'text-red-600';
      case 'tentative':
        return 'text-amber-600';
      case 'needsAction':
      default:
        return 'text-icon-neutral-weak';
    }
  }

  get statusIcon() {
    switch (this.args.status) {
      case 'accepted':
        return 'circle-check';
      case 'declined':
        return 'circle-xmark';
      case 'tentative':
        return 'circle-question';
      case 'needsAction':
      default:
        return 'circle-ellipsis';
    }
  }

  get statusSize() {
    switch (this.args.size) {
      case '20':
        return '12';
      case '24':
      case '28':
        return '14';
      case '32':
      case '36':
      case '48':
        return '16';
      case '64':
      case '80':
        return '20';
      case '96':
      case '160':
        return '24';
      default:
        return '14';
    }
  }

  get position() {
    switch (this.args.size) {
      case '20':
      case '24':
      case '28':
      case '32':
      case '36':
      case '48':
        return '-right-[4px] -bottom-[4px]';
      case '64':
      case '80':
        return '-right-[6px] -bottom-[6px]';
      case '96':
      case '160':
        return '-right-[7px] -bottom-[7px]';
      default:
        return '-right-[4px] -bottom-[4px]';
    }
  }
}
