/* import __COLOCATED_TEMPLATE__ from './range.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';

interface Signature {
  Args: {
    value?: number;
    min: number;
    max: number;
    onChange?: (arg: number) => void;
    onInput?: (arg: number) => void;
  };
}

export default class CoreRangeComponent extends Component<Signature> {
  @tracked sliderValue = (this.args.max - this.args.min) * 0.5 + this.args.min;

  get style() {
    return htmlSafe(
      `--min:${this.args.min}; --max:${this.args.max}; --val:${this.displayValue};`
    );
  }

  get displayValue() {
    return this.args.value === undefined
      ? this.sliderValue
      : parseInt(this.args.value, 10);
  }

  @action
  handleChange({ target }: { target: HTMLInputElement }) {
    if (this.args.onChange) {
      this.args.onChange(parseInt(target.value, 10));
    }
  }

  @action
  handleInput({ target }: { target: HTMLInputElement }) {
    this.sliderValue = parseInt(target.value, 10);

    if (this.args.onInput) {
      this.args.onInput(this.sliderValue);
    }
  }
}
