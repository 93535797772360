/* import __COLOCATED_TEMPLATE__ from './answer-form-field.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import moment from 'moment-timezone';

import { AnswerModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import toggleInList from 'teamtailor/utils/toggle-in-list';

type ExtendedAnswerModel = AnswerModel & { newUploadUrl?: string };

type AnswerFormFieldArgs = {
  answer: ExtendedAnswerModel;
  isDraft: boolean;
  save: (status: 'draft', autosave: boolean) => void;
};

type BooleanChoice = {
  title: string;
  id: number;
  value: boolean;
};

export default class AnswerFormField extends Component<AnswerFormFieldArgs> {
  @tracked newUploadFilename?: string;

  get answer() {
    return get(this.args, 'answer');
  }

  get formattedDate() {
    if (!this.answer.date || this.answer.date.length === 0) {
      return '';
    }

    return moment(this.answer.date).toDate();
  }

  handleSave() {
    if (this.args.isDraft) {
      this.args.save('draft', true);
    }
  }

  @action
  getRangeLabel(title?: string, unit?: string) {
    if (this.answer.range) {
      const rangeText = [this.answer.range.toString()];

      if (unit) {
        rangeText.push(unit);
      }

      return `${title ?? ''} (${rangeText.join(' ')})`;
    } else {
      return title;
    }
  }

  @action
  toggleCheckboxChoice(choiceId: number) {
    const choices = [...this.answer.choices];
    toggleInList(choices, choiceId);
    this.answer.choices = [...choices];
    this.handleSave();
  }

  @action
  handleFileUploaded(temporaryFileUrl: string, filename: string) {
    this.answer.newUploadUrl = temporaryFileUrl;
    this.newUploadFilename = filename;
    this.handleSave();
  }

  @action
  rollbackAnswerFile() {
    this.answer.newUploadUrl = undefined;
    this.newUploadFilename = undefined;
    this.handleSave();
  }

  @action
  setDate(value: Date | null) {
    this.answer.date = value ? moment(value).format('YYYY-MM-DD') : '';
    this.handleSave();
  }

  @action
  onChangeInput(event: InputEvent) {
    const question = get(this.answer, 'question');
    const { value } = event.target as HTMLInputElement;

    if (get(question, 'isText')) {
      this.answer.text = value;
    }

    if (get(question, 'isNumber')) {
      this.answer.number = parseInt(value, 10);
    }

    this.handleSave();
  }

  @action
  onChangeChoice(choice: BooleanChoice) {
    const question = get(this.answer, 'question');

    if (get(question, 'isChoice')) {
      this.answer.choices = [choice.id];
    }

    if (get(question, 'isBoolean')) {
      this.answer.boolean = choice.value;
    }

    this.handleSave();
  }
}
