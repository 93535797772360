/* import __COLOCATED_TEMPLATE__ from './job-card.hbs'; */
import Component from '@glimmer/component';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sum } from '@ember/object/computed';

export default class InsightsMoleculesJobCard extends Component {
  @sum('args.visits') totalVisits;
  @sum('args.applications') totalApplications;

  get showDetails() {
    return this.totalVisits + this.totalApplications > 0;
  }
}
