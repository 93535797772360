/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
import Component from '@glimmer/component';

export default class extends Component {
  get checked() {
    if (!this.args.value) return false;
    if (this.args.value === '0') return false;
    return true;
  }
}
