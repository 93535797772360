/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import { AnswerModel } from 'teamtailor/models';
import Candidate from 'teamtailor/models/candidate';
import JobApplication from 'teamtailor/models/job-application';
import { get } from 'teamtailor/utils/get';

interface Args {
  candidate: Candidate;
  jobApplication?: JobApplication;
  anonymous?: boolean;
}

export default class extends Component<Args> {
  answers = trackedFunction(this, async () => {
    const selectedJob = await this.args.jobApplication?.job;

    const answers = await this.args.candidate.answers;

    const jobQuestions: AnswerModel[] = [];
    const questionnaireQuestions: AnswerModel[] = [];
    const connectQuestions: AnswerModel[] = [];
    const apiQuestions: AnswerModel[] = [];

    answers.toArray().forEach((answer) => {
      const jobId = answer.pickedQuestion?.belongsTo('job').id();
      if (jobId === selectedJob?.id || (!selectedJob && !jobId)) {
        jobQuestions.push(answer);
      } else if (answer.pickedQuestion?.fromConnect) {
        connectQuestions.push(answer);
      } else if (
        !answer.pickedQuestion &&
        !answer.belongsTo('interview').id()
      ) {
        apiQuestions.push(answer);
      } else if (!jobId && !answer.belongsTo('interview').id()) {
        questionnaireQuestions.push(answer);
      }
    });

    jobQuestions.sort((a, b) => {
      const qualifyingQuestionA = get(a.pickedQuestion, 'isQualifying');
      const qualifyingQuestionB = get(b.pickedQuestion, 'isQualifying');
      if (qualifyingQuestionA && !qualifyingQuestionB) {
        return -1;
      } else if (!qualifyingQuestionA && qualifyingQuestionB) {
        return 1;
      } else if (a.pickedQuestion && b.pickedQuestion) {
        return (
          (a.pickedQuestion.rowOrder || 0) - (b.pickedQuestion.rowOrder || 0)
        );
      } else {
        return 0;
      }
    });

    connectQuestions.sort((a, b) => {
      if (a.pickedQuestion && b.pickedQuestion) {
        return (
          (a.pickedQuestion.rowOrder || 0) - (b.pickedQuestion.rowOrder || 0)
        );
      } else {
        return 0;
      }
    });

    return {
      jobQuestions,
      questionnaireQuestions,
      connectQuestions,
      apiQuestions,
    };
  });

  get questionnaireQuestions() {
    if (!this.answers.value?.questionnaireQuestions) {
      return [];
    }

    const groupedAnswers = this.answers.value.questionnaireQuestions.reduce(
      (acc: Record<string, AnswerModel[]>, answer) => {
        const ownerId = answer.pickedQuestion?.ownerId;
        if (!ownerId) {
          return acc;
        }

        if (!acc[ownerId]) {
          acc[ownerId] = [];
        }

        acc[ownerId]?.push(answer);

        return acc;
      },
      {}
    );

    return Object.values(groupedAnswers);
  }
}
