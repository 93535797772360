/* import __COLOCATED_TEMPLATE__ from './sso-config.hbs'; */
import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';
import SingleSignOnModel from 'teamtailor/models/single-sign-on';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface SettingsSecuritySsoConfigArgs {
  model: SingleSignOnModel;
}

type SsoModelKeys = {
  [key in keyof SingleSignOnModel]: unknown;
};

type SsoConfigKey = {
  label: string;
  mustBeSet?: boolean;
};

const SSO_CONFIG_KEYS_NEW = new Map<string, SsoConfigKey>([
  ['idpSsoServiceUrl', { label: 'Sign-on URL', mustBeSet: true }],

  [
    'serviceProviderCallbackUrl',
    { label: 'Assertion Consumer Service (ACS) URL', mustBeSet: true },
  ],

  ['serviceProviderMetadataUrl', { label: 'Entity ID', mustBeSet: true }],
  ['autoJoinDomains', { label: 'SSO auto join domains', mustBeSet: true }],
  ['defaultUserRole', { label: 'Default user role', mustBeSet: true }],
  ['createdAtFormatted', { label: 'Created at' }],
  ['updatedAtFormatted', { label: 'Updated at' }],
]);

export default class SettingsSecuritySsoConfig extends Component<SettingsSecuritySsoConfigArgs> {
  @service declare intl: IntlService;

  get config(): { key: string; value: unknown; hasProblem?: boolean }[] {
    return Array.from(SSO_CONFIG_KEYS_NEW.entries()).map(([key, value]) => {
      return {
        key: value.label,
        value:
          key === 'defaultUserRole'
            ? this.intl.t(`user.${this.args.model.defaultUserRole}`)
            : get(this.args.model, key as keyof SsoModelKeys),

        hasProblem:
          value.mustBeSet && !get(this.args.model, key as keyof SsoModelKeys),
      };
    });
  }
}
