/* import __COLOCATED_TEMPLATE__ from './question-filter.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class QuestionFilterComponent extends Component {
  get filter() {
    return this.args.filter;
  }

  get job() {
    return this.args.job;
  }

  get questions() {
    return this.args.questions;
  }

  get filters() {
    return this.args.filters;
  }

  get isChoice() {
    return get(this, 'shortName') === 'choice';
  }

  get isBoolean() {
    return get(this, 'shortName') === 'boolean';
  }

  get isRange() {
    return get(this, 'shortName') === 'range';
  }

  get question() {
    return get(this, 'questions').findBy('id', get(this, 'filter.question_id'));
  }

  get shortName() {
    if (get(this, 'question.type')) {
      return get(this, 'question.type').substring(10).toLowerCase();
    }
  }

  get componentName() {
    return `question-filter-${get(this, 'shortName')}`;
  }

  @action
  updateFilter(data) {
    get(this, 'filters').removeObject(get(this, 'filter'));
    get(this, 'filters').pushObject(
      Object.assign(get(this, 'filter'), { ...data })
    );
  }

  @action
  remove() {
    if (this.args.onRemove) {
      this.args.onRemove();
    } else {
      get(this, 'filters').removeObject(get(this, 'filter'));
    }
  }
}
