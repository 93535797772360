/* import __COLOCATED_TEMPLATE__ from './demo-datas.hbs'; */
import WidgetClass from './base/widget-class';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class DemoDatasWidget extends WidgetClass {
  @service current;
  @service pusher;
  @service session;
  @service server;

  @tracked isOpen = false;
  @tracked isDeletingDemoData = false;
  @tracked channel;

  demoDataEndpoint() {
    let companyUuid = this.current.company.uuid;
    return `/app/companies/${companyUuid}/api/demo_datas`;
  }

  async deleteDemoData() {
    return this.server.request(this.demoDataEndpoint(), 'DELETE');
  }

  get areDemoDataPreparing() {
    return this.current.company.demoDataStatus === 'preparing';
  }

  get isDeletionComplete() {
    return this.current.company.demoDataStatus === 'removed';
  }

  @action
  openModal(event) {
    event.preventDefault();
    if (!this.isDeletingDemoData) {
      this.isOpen = true;
    }
  }

  @action
  confirmDelete() {
    this.deleteDemoData();
    this.isDeletingDemoData = true;
    this.isOpen = false;
  }

  @action
  setupPusher() {
    this.pusher
      .subscribe(this.current.company.candidatesChannel)
      .then((channel) => {
        this.channel = channel;
        channel.bind('demo-data-updated', ({ status }) => {
          if (status === 'removed') {
            this.isDeletingDemoData = false;
          }

          this.current.company.demoDataStatus = status;
        });
      });
  }

  @action
  teardownPusher() {
    if (this.channel) {
      this.channel.unbind('demo-data-updated');
    }
  }
}
