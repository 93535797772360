import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class HireQualityResponseSerializer extends ApplicationSerializer {
  attrs = {
    scores: {
      key: 'scorecard_scores_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'hire-quality-response': HireQualityResponseSerializer;
  }
}
