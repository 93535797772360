/* import __COLOCATED_TEMPLATE__ from './jobteaser.hbs'; */
import PromotionBaseClass from './promotion-base';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class extends PromotionBaseClass {
  @service store;

  @tracked checkoutData;

  get jobteaserEducationLevels() {
    return this.store.peekAll('promotion-categories/jobteaser-education-level');
  }

  get jobteaserWorkExperiences() {
    return get(this, 'store').peekAll(
      'promotion-categories/jobteaser-work-experience'
    );
  }

  get jobteaserContractTypes() {
    return get(this, 'store').peekAll(
      'promotion-categories/jobteaser-contract-type'
    );
  }

  get jobteaserRemoteTypes() {
    return get(this, 'store').peekAll(
      'promotion-categories/jobteaser-remote-type'
    );
  }

  get jobteaserContractDurations() {
    return get(this, 'store').peekAll(
      'promotion-categories/jobteaser-contract-duration'
    );
  }

  get jobteaserPositionCategories() {
    return get(this, 'store').peekAll(
      'promotion-categories/jobteaser-position-category'
    );
  }

  get jobteaserPaymentTypes() {
    return get(this, 'store').peekAll(
      'promotion-categories/jobteaser-payment-type'
    );
  }

  @action
  resetJobteaserContractDuration(contractType) {
    if (get(contractType, 'id') === '1') {
      set(this, 'promotion.jobteaserContractDuration', null);
    }
  }
}
