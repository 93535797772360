/* import __COLOCATED_TEMPLATE__ from './question-cell.hbs'; */
import Component from '@glimmer/component';
import AnswerModel from 'teamtailor/models/answer';
import JobApplicationModel from 'teamtailor/models/job-application';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';

type CompareCandidatesQuestionCellComponentArgs = {
  answer: AnswerModel;
  isPinned: boolean;
  isHovered: boolean;
  jobApplication: JobApplicationModel;
  offset: string;
};

const TEXT_ANSWER_CHARACTER_LIMIT = 140;

export default class CompareCandidatesQuestionCellComponent extends Component<CompareCandidatesQuestionCellComponentArgs> {
  @service declare intl: IntlService;
  @tracked showFullText = false;

  get answer(): AnswerModel | undefined {
    return this.args.answer;
  }

  get isText() {
    return get(this.answer?.question, 'isText');
  }

  get isMultipleChoice() {
    return (
      get(this.answer?.question, 'isChoice') &&
      get(this.answer?.question, 'multiple')
    );
  }

  get isContentTruncated(): boolean {
    const text = this.answer?.text || '';
    return text.length > TEXT_ANSWER_CHARACTER_LIMIT;
  }

  get textContent() {
    return this.showFullText || !this.isContentTruncated
      ? this.answer?.text
      : `${this.answer?.text!.substring(
          0,
          TEXT_ANSWER_CHARACTER_LIMIT - 3
        )}...`;
  }

  get choices(): string[] {
    return this.answer!.choices.map((choice) => {
      const alternative = get(
        this.answer!.question,
        'alternativesArrayForChoices'
      ).findBy('id', choice);
      return alternative?.title;
    }).compact();
  }
}
