/* import __COLOCATED_TEMPLATE__ from './question-answers.hbs'; */
import Component from '@glimmer/component';

export default class QuestionAnswers extends Component {
  get numberOfAnswers() {
    const { stageType, question } = this.args;

    return question.answers.reduce(
      (acc, answer) => acc + answer.value[stageType],
      0
    );
  }

  get showAnswers() {
    return !this.args.isAnonymous || this.numberOfAnswers >= 10;
  }
}
