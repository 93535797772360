/* import __COLOCATED_TEMPLATE__ from './referral-leaderboard.hbs'; */
import WidgetClass from './base/widget-class';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import REFERRAL_LEADERBOARD_SPOTS_QUERY from 'teamtailor/gql/referral-leaderboard-spots-query';
import { get } from 'teamtailor/utils/get';

export default class ReferralLeaderboardWidget extends WidgetClass {
  @service apollo;
  @service current;

  @tracked leaderboardSpots = null;

  get user() {
    return this.current.user;
  }

  fetchLeaderboard = dropTask(async () => {
    const { referralLeaderboardSpots } = await this.apollo.query({
      query: REFERRAL_LEADERBOARD_SPOTS_QUERY,
      variables: {
        userId: this.userId,
      },
    });

    this.leaderboardSpots = referralLeaderboardSpots;
  });

  get userId() {
    return get(this.user, 'id');
  }

  get firstPlace() {
    return this.leaderboardSpots?.length ? this.leaderboardSpots[0] : null;
  }
}
