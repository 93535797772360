/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import SectionItemComponent from 'teamtailor/components/content-block/section-item-component';
import { action } from '@ember/object';

export default class TimelineItemBaseComponent extends SectionItemComponent {
  @action
  toggleOpenInNewTab() {
    this.sectionItem.openInNewTab = !this.sectionItem.openInNewTab;
  }
}
