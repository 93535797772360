/* import __COLOCATED_TEMPLATE__ from './action-button.hbs'; */
import Component from '@glimmer/component';
import Model from '@ember-data/model';

type SharedArgs = {
  icon: string;
  disabled?: boolean;
};

type LinkToArgs = {
  linkToRoute?: string;
  models?: Model[];
  query?: string;
  state?: string;
  active?: boolean;
};

type ButtonArgs = {
  typeAttr?: 'button' | 'submit';
};

export type Args = (LinkToArgs | ButtonArgs) & SharedArgs;

export default class BulkActionsActionButton extends Component<Args> {
  get typeAttr() {
    return ('typeAttr' in this.args && this.args.typeAttr) ?? 'button';
  }
}
