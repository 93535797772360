/* import __COLOCATED_TEMPLATE__ from './department-role-selector.hbs'; */
import Component from '@glimmer/component';
import ConnectSettingModel from 'teamtailor/models/connect-setting';
import { get } from 'teamtailor/utils/get';

export default class ConnectSettingsDepartmentRoleSelectorComponent extends Component {
  getConnectSetting(
    connectSettings: ConnectSettingModel[],
    departmentId: string,
    roleId: string
  ) {
    const allConnectSettings = connectSettings;
    const selectedConnectSetting = allConnectSettings.find(
      (connectSetting) =>
        get(connectSetting.department, 'id') === departmentId &&
        get(connectSetting.role, 'id') === roleId
    );

    return selectedConnectSetting;
  }
}
