/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import Component from '@glimmer/component';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

type PositionInGroup = 'leftMost' | 'middle' | 'rightMost';

export default class extends Component<{
  positionInGroup?: PositionInGroup;
  tooltipOutsideButton?: boolean;
}> {
  @tracked declare _positionInGroup: PositionInGroup;

  get positionInGroup() {
    if (!isNone(this.args.positionInGroup)) {
      return this.args.positionInGroup;
    }

    return this._positionInGroup;
  }

  @action handleInsert(el: HTMLElement) {
    if (isNone(this.args.positionInGroup)) {
      let groupEl: HTMLElement | null | undefined = el.parentElement;
      let childEl: HTMLElement | null | undefined = el;

      if (this.args.tooltipOutsideButton) {
        groupEl = el.parentElement?.parentElement;
        childEl = el.parentElement;
      }

      if (groupEl && childEl) {
        const children = Array.from(groupEl.children);
        const index = children.indexOf(childEl);

        this._positionInGroup =
          index === 0
            ? 'leftMost'
            : index + 1 === children.length
              ? 'rightMost'
              : 'middle';
      }
    }
  }
}
