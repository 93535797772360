/* import __COLOCATED_TEMPLATE__ from './job.hbs'; */
import Component from '@glimmer/component';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import DateRange from 'teamtailor/utils/date-range';
import isIe11 from 'teamtailor/utils/is-ie11';

const HAS_DATA_QUERY = gql`
  query JobHasDataQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    npsResponses: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      count
    }

    userActivities: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: { userId: { exists: true } }
    ) {
      count
    }

    audienceData: pageviewQuery(dateRange: $dateRange, jobIds: $jobIds) {
      count
    }
  }
`;

export default class extends Component {
  isIE11 = isIe11;

  @service insights;
  @service flipper;

  get anyNpsResponses() {
    return this.fetchData.lastSuccessful?.value?.anyNpsResponses;
  }

  get anyAudienceData() {
    return this.fetchData.lastSuccessful?.value?.anyAudienceData;
  }

  get anyUserActivity() {
    return this.fetchData.lastSuccessful?.value?.anyUserActivity;
  }

  get dateRange() {
    return this.fetchData.lastSuccessful?.value?.dateRange;
  }

  get hasNoData() {
    return !this.fetchData.lastSuccessful;
  }

  get isLoading() {
    return this.fetchData.isRunning || this.hasNoData;
  }

  fetchData = dropTask(async () => {
    const { job, startDate, endDate } = this.args;
    const dateRange = new DateRange(startDate, endDate);

    const { npsResponses, userActivities, audienceData } =
      await this.insights.query({
        query: HAS_DATA_QUERY,
        variables: {
          jobIds: [job.id],
          dateRange: dateRange.asObject,
        },

        context: {
          jobId: job.id,
        },
      });
    const anyAudienceData = audienceData.count > 0;
    const anyUserActivity = userActivities.count > 0;
    const anyNpsResponses = npsResponses.count > 0;

    return {
      job,
      dateRange,
      startDate,
      endDate,
      anyAudienceData,
      anyUserActivity,
      anyNpsResponses,
    };
  });
}
