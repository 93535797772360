/* import __COLOCATED_TEMPLATE__ from './gif-settings.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { trackedFunction } from 'ember-resources/util/function';
import FlashMessageService from 'teamtailor/services/flash-message';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import { DashboardSettingModel } from 'teamtailor/models';

const SUGGESTIONS = ['Office', 'Work', 'Good morning', 'High five', 'Happy'];
const GIPHY_QUERY_MAX_LENGTH = 50;

interface DashboardGifSettingsComponentSignature {
  Args: {
    settings: DashboardSettingModel;
  };
}

export default class DashboardGifSettingsComponent extends Component<DashboardGifSettingsComponentSignature> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get gifSearchTerm() {
    return this.args.settings.gifSearchTerm || '';
  }

  get gifSearchTerms() {
    return this.gifSearchTerm.split(',').filter((t) => t);
  }

  giphyImages = trackedFunction(this, async () => {
    if (this.hasValidSearchTerm) {
      const gifs = await this.store.query('giphy-image', {
        q: this.gifSearchTerm,
        offset: 0,
        limit: 4,
      });

      return gifs;
    }
  });

  get suggestions() {
    return SUGGESTIONS.filter(
      (suggestion) =>
        !this.gifSearchTerms.some(
          (searchTerm) => searchTerm.toLowerCase() === suggestion.toLowerCase()
        )
    );
  }

  get hasValidSearchTerm() {
    return GIPHY_QUERY_MAX_LENGTH >= this.gifSearchTerm.length;
  }

  get options() {
    return this.gifSearchTerms.map((title) => {
      return { title };
    });
  }

  @action
  saveGifSettings() {
    try {
      this.args.settings.save();
    } catch (e) {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      this.args.settings.rollbackAttributes();
    }
  }

  @action
  handleCreate(value: string) {
    this.args.settings.gifSearchTerm = [...this.gifSearchTerms, value].join(
      ','
    );
  }

  @action
  selectOption(option: { title: string }) {
    const searchTermArray = this.gifSearchTerms.slice();
    toggleInList(searchTermArray, option.title);
    this.args.settings.gifSearchTerm = searchTermArray.join(',');
  }
}
