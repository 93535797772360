/* import __COLOCATED_TEMPLATE__ from './preview-item.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';
import imageUrl from 'teamtailor/utils/image-url';
import { get } from 'teamtailor/utils/get';

export default class PreviewItemComponent extends Component {
  @service contentCareerSite;
  @service current;

  get user() {
    return this.current.user;
  }

  get styleBackground() {
    if (this.args.pickedImage) {
      return htmlSafe(
        `background-image: url('${get(
          this,
          'args.pickedImage.imageOrTemplateUrl'
        )}');`
      );
    } else if (this.args.screenshot) {
      return htmlSafe(
        `background-image: url('${imageUrl(
          this.args.screenshot,
          'page_screenshot'
        )}');`
      );
    }
  }

  get showDraftIcon() {
    if (this.args.isCareerSite) {
      return !this.args.model.isPublished;
    }

    return !this.args.model?.isPublished;
  }
}
