/* import __COLOCATED_TEMPLATE__ from './action.hbs'; */
import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import TimeFormatService from 'teamtailor/services/time-format';
import RouterService from '@ember/routing/router-service';
import ActivityModel from 'teamtailor/models/activity';
import CandidateModel from 'teamtailor/models/candidate';
import { CODE_ACTIVITY_MAP } from 'teamtailor/constants/activity';

interface Args {
  action: ActivityModel;
  candidate: CandidateModel;
  candidateRoute: string;
  anonymous: boolean;
}

export default class CandidatePreviewPopoverActionComponent extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;
  @service declare router: RouterService;

  get locale(): string {
    return this.current.locale;
  }

  get contentComponentName(): string | undefined {
    return CODE_ACTIVITY_MAP[this.args.action.code];
  }

  get isSameYear(): boolean {
    return moment(this.args.action.updatedAt).isSame(new Date(), 'year');
  }

  get date(): string {
    moment.locale(this.locale);
    if (this.isSameYear) {
      return moment(this.args.action.updatedAt).format('MMM D');
    }

    return moment(this.args.action.updatedAt).format('MMM D, YYYY');
  }

  @action
  openCandidateModal(tab: string): void {
    this.router.transitionTo(
      this.args.candidateRoute,
      get(this.args.candidate, 'id'),
      {
        queryParams: { tab },
      }
    );
  }
}
