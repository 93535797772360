/* import __COLOCATED_TEMPLATE__ from './chat-widget-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import MessageWidgetModel from 'teamtailor/models/message-widget';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import { trackedFunction } from 'ember-resources/util/function';
import { argDefault } from 'teamtailor/utils/arg-default';
import { AsyncBelongsTo } from '@ember-data/model';

export interface ChatWidgetFormSignature {
  Element: HTMLDivElement;
  Args: {
    messageWidget: MessageWidgetModel | AsyncBelongsTo<MessageWidgetModel>;
    hideRevertToGlobalLink?: boolean;
    showVideoPresenter?: boolean;
    createCustomMessageWidget: () => void;
    onToggle: () => void;
    createAndDisableMessageWidget: () => void;
    destroyMessageWidget: () => void;
  };
}

export default class ChatWidgetForm extends Component<ChatWidgetFormSignature> {
  @service declare current: Current;

  @argDefault showVideoPresenter = true;

  get company() {
    return this.current.company;
  }

  get hasMessageWidget() {
    return !!this.messageWidget;
  }

  get messageWidget() {
    return this.resolveMessageWidget.value;
  }

  get messageWidgetEnabled() {
    return !!this.messageWidget?.enabled;
  }

  get globalMessageWidget() {
    return this.resolveGlobalMessageWidget.value;
  }

  get globalMessageWidgetEnabled() {
    return !!this.globalMessageWidget?.enabled;
  }

  get useGlobalChatSettings() {
    return !this.hasMessageWidget && this.globalMessageWidgetEnabled;
  }

  get shouldDisplayForm() {
    return this.messageWidgetEnabled || this.globalMessageWidgetEnabled;
  }

  get isChatEnabled() {
    return this.messageWidget
      ? this.messageWidgetEnabled
      : this.globalMessageWidgetEnabled;
  }

  resolveGlobalMessageWidget = trackedFunction(this, async () => {
    const globalMessageWidget = await get(this.company, 'globalMessageWidget');
    return globalMessageWidget;
  });

  resolveMessageWidget = trackedFunction(this, async () => {
    const messageWidget = await get(this.args, 'messageWidget');
    return messageWidget;
  });

  launcherImage = trackedFunction(this, async () => {
    const messageWidget = this.messageWidget || this.globalMessageWidget;
    const user = await get(messageWidget, 'user');
    return user ? get(user, 'pictureUrl') : null;
  });

  get shouldDisplayRevertToGlobalLink() {
    return (
      !this.args.hideRevertToGlobalLink &&
      !!this.messageWidget &&
      this.globalMessageWidgetEnabled
    );
  }

  get isPickedVideoUploadCompleted() {
    const video = this.messageWidget?.pickedVideo?.video;
    return !!(video?.fileFileName && video.isTranscodeCompleted);
  }

  @action
  onToggleChat() {
    if (this.messageWidget) {
      this.args.onToggle();
    } else if (this.globalMessageWidgetEnabled) {
      this.args.createAndDisableMessageWidget();
    } else {
      this.args.createCustomMessageWidget();
    }
  }

  @action
  onGreetingChanged(event: InputEvent): void {
    if (this.messageWidget) {
      this.messageWidget.greeting = (event.target as HTMLTextAreaElement).value;
    }
  }

  @action
  didInsertVideo(videoElement: HTMLVideoElement) {
    videoElement.muted = true;
    videoElement.loop = true;
    videoElement.play();
  }
}
