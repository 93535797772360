import ApplicationAdapter from './application';
import { get } from 'teamtailor/utils/get';
import classic from 'ember-classic-decorator';

@classic
export default class Tag extends ApplicationAdapter {
  updateRecord(store, type, snapshot) {
    return super.updateRecord(...arguments).then((payload) => {
      return get(snapshot, 'adapterOptions.ignoreResponsePayload')
        ? null
        : payload;
    });
  }
}
