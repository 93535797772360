/* import __COLOCATED_TEMPLATE__ from './toolbar.hbs'; */
import { inject as service } from '@ember/service';
import Base from './base';
import { action, get } from '@ember/object';
import toggleReaction from 'teamtailor/utils/toggle-reaction';

export default class ToolbarComponent extends Base {
  @service store;
  @service current;

  get hasReactions() {
    return !get(this.activity, 'isMessage');
  }

  get user() {
    return this.current.user;
  }

  @action
  addReaction(emoji) {
    toggleReaction(get(this, 'activity'), this.user, emoji);
  }
}
