/* import __COLOCATED_TEMPLATE__ from './show-advanced.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ShowAdvancedComponentArgs {
  showAdvanced: boolean;
}

export default class ShowAdvancedComponent extends Component<ShowAdvancedComponentArgs> {
  @tracked showAdvanced = false;
}
