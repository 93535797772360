/* import __COLOCATED_TEMPLATE__ from './channels-list.hbs'; */
import Component from '@glimmer/component';
import JobModel from 'teamtailor/models/job';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { MetaJobsData } from './sidebar';
import { JobCommentStatusModel } from 'teamtailor/models';

type Args = {
  jobs: JobModel[];
  myJobs: JobModel[];
  currentJob: JobModel;
  isMyJobs?: boolean;
  currentChannel: string;
  loadMoreJobs: (isMyJobs?: boolean) => void;
  myJobsMeta: MetaJobsData;
  allJobsMeta: MetaJobsData;
  jobCommentStatuses?: JobCommentStatusModel[];
};

const SHOW_ALL_JOBS = 'All';
export const SHOW_MENTIONED_JOBS = 'Mentioned';
export const SHOW_UNREAD_JOBS = 'Unread';

export default class GlobalCommentsChannelsList extends Component<Args> {
  @tracked filterShowMyJobs: string = SHOW_ALL_JOBS;
  @tracked filterShowJobs: string = SHOW_ALL_JOBS;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadFiltersFromLocalStorage();
  }

  loadFiltersFromLocalStorage() {
    const savedShowMyJobsFilter: string =
      window.localStorage.getItem('filterShowMyJobs') || SHOW_ALL_JOBS;

    if (savedShowMyJobsFilter) {
      this.filterShowMyJobs = savedShowMyJobsFilter;
    }

    const savedShowJobsFilter: string =
      window.localStorage.getItem('filterShowJobs') || SHOW_ALL_JOBS;

    if (savedShowJobsFilter) {
      this.filterShowJobs = savedShowJobsFilter;
    }
  }

  saveMyJobsFilterToLocalStorage(filter: string) {
    window.localStorage.setItem('filterShowMyJobs', filter);
  }

  saveJobsFilterToLocalStorage(filter: string) {
    window.localStorage.setItem('filterShowJobs', filter);
  }

  @action
  setMyJobsFilter(filter: string) {
    this.filterShowMyJobs = filter;
    this.saveMyJobsFilterToLocalStorage(filter);
  }

  @action
  setJobsFilter(filter: string) {
    this.filterShowJobs = filter;
    this.saveJobsFilterToLocalStorage(filter);
  }
}
