/* import __COLOCATED_TEMPLATE__ from './send-survey.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
export default class SendSurveyComponent extends Component {
  @service current;

  @tracked selectedSurvey = null;
  @service flashMessages;
  @service intl;

  get surveys() {
    const companySurveys = get(this.current.company, 'surveys');

    if (this.args.openSurveys) {
      return companySurveys.filterBy('open', true);
    } else if (this.args.candidate) {
      const formInvites = get(this.args.candidate, 'formInvites');
      const existingSurveyIds = formInvites.map((formInvite) => {
        return formInvite.belongsTo('survey').id();
      });

      return get(formInvites, 'isFulfilled')
        ? companySurveys.filter(
            (survey) => !existingSurveyIds.includes(survey.id)
          )
        : [];
    }

    return companySurveys;
  }

  @action
  async sendSurvey(survey) {
    if (this.args.onSelect) {
      this.args.onSelect(survey);
    } else {
      const formInvite = this.args.candidate.formInvites.createRecord({
        survey,
      });

      try {
        await formInvite.save();
        this.flashMessages.success(this.intl.t('surveys.survey_sent'));

        this.args.onClose();
      } catch (e) {
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      }
    }
  }

  @action
  handleInsert() {
    if (this.selectedSurvey !== null) {
      this.selectedSurvey = null;
    }

    get(this.current.company, 'surveys').reload();
  }

  @action
  changeSurvey(survey) {
    this.selectedSurvey = survey;
  }
}
