/* import __COLOCATED_TEMPLATE__ from './figure-picker.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { trackedFunction } from 'ember-resources/util/function';
import {
  Icon,
  IconDefinition,
  IconName,
  icon,
} from '@fortawesome/fontawesome-svg-core';
import fuzzysort from 'fuzzysort';
import { PositionState } from './core/anchored-element';
import { setStyles } from 'teamtailor/ember-smooth';
import { debounce } from '@ember/runloop';
import { themeColors } from '@teamtailor/design-tokens';
import { modifier } from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import ScrollBooster from 'scrollbooster';
import { EkEvent } from 'ember-keyboard';
import { argDefault } from 'teamtailor/utils/arg-default';
import { STYLE_PREFIX_MAP } from './icon';
import { ALLOWED_STYLES } from 'teamtailor/constants/icon';
import EsmFaIconsService from 'teamtailor/services/esm-fa-icons';
import { inject as service } from '@ember/service';

const BG_DECORATIVE_COLORS = themeColors.background.decorative;

export type OnSelectColorArg = string;

export type OnSelectArg = Icon & { event?: Event };

export type SelectedColor = {
  name?: string;
  cssColor: string;
  cssVar?: string;
};

export type FigurePickerSignature = {
  Args: {
    closeOnSelect?: boolean;
    onSelect: (arg: OnSelectArg, event?: Event) => void;
    onSelectColor: (color: SelectedColor, event?: Event) => void;
    onRegister?: (api: FigurePickerApi) => void;
    onOpen?: () => void;
    onClose?: () => void;
    showColors?: boolean;
    style?: (typeof ALLOWED_STYLES)[number];
  };
};

export type FigurePickerApi = { isOpen: boolean };

export default class FigurePickerComponent extends Component<FigurePickerSignature> {
  @service declare esmFaIcons: EsmFaIconsService;

  @argDefault closeOnSelect = true;
  @argDefault showColors = false;
  @argDefault style: (typeof ALLOWED_STYLES)[number] = 'solid';

  @tracked _isOpen = false;
  @tracked scrollableEl?: HTMLElement;
  @tracked triggerEl?: HTMLElement;
  @tracked searchTerm = '';
  @tracked iconsWrapperEl?: HTMLElement;
  @tracked positionState?: PositionState;

  leftPickerWithMouseDown = false;
  scrollBoosterInstance?: ScrollBooster;
  anchoredElementEl?: HTMLElement;
  mouseIsDownInScroller = false;

  fuzzyOptions: {
    threshold: number;
    keys?: string[];
  } = {
    threshold: -10000,
  };

  rowHeight = 24;

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(value) {
    this._isOpen = value;

    if (!value) {
      this.searchTerm = '';
    }

    this.args.onClose?.();
  }

  @cached
  get computedDecorativeLightBgColors() {
    const lightElement = document.createElement('div');
    lightElement.className = 'theme-light';
    document.body.appendChild(lightElement);

    const lightComputedStyles = getComputedStyle(lightElement);

    const out = Object.keys(BG_DECORATIVE_COLORS).reduce<
      Record<string, string>
    >((acc, variantName) => {
      const fullName = `background-decorative-${variantName}-medium`;
      acc[fullName] = lightComputedStyles.getPropertyValue(
        `--color-${fullName}`
      );
      return acc;
    }, {});

    lightElement.remove();

    return out;
  }

  onOpen = modifier(() => {
    this.args.onOpen?.();
  });

  @action onEscape(_event: KeyboardEvent, ekEvent: EkEvent) {
    ekEvent.stopPropagation();
    this.isOpen = false;
  }

  @action handleTriggerClick() {
    this.isOpen = !this.isOpen;
  }

  @action onRegister() {
    this.args.onRegister?.(this.api);
  }

  get api(): FigurePickerApi {
    const parentThis = this;
    return {
      get isOpen() {
        return parentThis.isOpen;
      },
    };
  }

  @action handleClickOutside() {
    if (this.leftPickerWithMouseDown) {
      this.mouseIsDownInScroller = false;
      this.leftPickerWithMouseDown = false;
      return;
    }

    this.isOpen = false;
  }

  @action onAnchoredElMouseleave() {
    if (this.mouseIsDownInScroller) {
      this.leftPickerWithMouseDown = true;
    }
  }

  @action async onSelect(icon: Icon, event: MouseEvent) {
    await this.esmFaIcons.loadIcon(this.iconPrefix, icon.iconName);

    this.args.onSelect(icon, event);

    if (this.closeOnSelect) {
      this.isOpen = false;
    }
  }

  @action onSelectColor(color: SelectedColor, event: MouseEvent) {
    if (this.scrollBoosterInstance?.getState().isDragging) {
      return;
    }

    if (color.name && color.name in this.computedDecorativeLightBgColors) {
      color.cssColor = this.computedDecorativeLightBgColors[color.name]!;
    }

    this.args.onSelectColor(color, event);
  }

  get iconsFiltered() {
    if (!this.searchTerm) {
      return this.iconDefinitions.value || [];
    }

    return fuzzysort
      .go(this.searchTerm, this.iconDefinitions.value!, {
        ...this.fuzzyOptions,
        keys: ['iconName'],
      })
      .map((val) => val.obj);
  }

  get rowSize() {
    return 9;
  }

  get skeletonCount() {
    if (this.positionState?.availableHeight) {
      return (
        Math.round(this.positionState.availableHeight / this.rowHeight) * 9
      );
    }

    return 72;
  }

  get iconsGrouped() {
    const iconstFiltered = this.iconsFiltered;
    const chunk = this.rowSize;

    let out = [];
    for (let i = 0; i < iconstFiltered.length; i += chunk) {
      out.push(iconstFiltered.slice(i, i + chunk));
    }

    return out;
  }

  get iconPrefix() {
    return this.styleToPrefix(this.style);
  }

  setupColorWrapper = modifier(async (el) => {
    const ScrollBooster = (await import('scrollbooster')).default;
    this.scrollBoosterInstance = new ScrollBooster({
      viewport: el,
      content: el.children[0] as HTMLElement,
      scrollMode: 'transform',
      direction: 'horizontal',
      inputsFocus: false,
      emulateScroll: true,
    });

    const onWheel = (e: Event) => e.preventDefault();
    const onMousedown = () => (this.mouseIsDownInScroller = true);
    const onMouseup = () => (this.mouseIsDownInScroller = false);

    el.addEventListener('wheel', onWheel);
    el.addEventListener('mousedown', onMousedown);
    this.anchoredElementEl?.addEventListener('mouseup', onMouseup);

    registerDestructor(this, () => {
      el.removeEventListener('wheel', onWheel);
      el.addEventListener('mousedown', onMousedown);
      this.anchoredElementEl?.addEventListener('mouseup', onMouseup);
    });
  });

  colors = Object.entries(BG_DECORATIVE_COLORS).map(([name, variants]) => {
    const fullName = `background-decorative-${name}-medium`;

    return {
      name,
      fullName,
      cssColor:
        fullName in this.computedDecorativeLightBgColors
          ? this.computedDecorativeLightBgColors[fullName]
          : variants.medium,
    };
  });

  styleToPrefix(style: keyof typeof STYLE_PREFIX_MAP) {
    return STYLE_PREFIX_MAP[style];
  }

  async getIcons() {
    switch (this.style) {
      case 'solid':
        return import('@fortawesome/pro-solid-svg-icons');
      case 'regular':
        return import('@fortawesome/pro-regular-svg-icons');
      default:
        throw new Error('Invalid style');
    }
  }

  iconDefinitions = trackedFunction<Promise<Icon[]>>(this, async () => {
    const icons = await this.getIcons();
    const prefix = this.iconPrefix as keyof typeof icons;
    const existingIconNames: IconName[] = [];

    let iconDefinitions = Object.values(icons[prefix])
      .filter((iconDef) => {
        const isDuplicate = existingIconNames.includes(
          (iconDef as IconDefinition).iconName
        );
        if (!isDuplicate) {
          existingIconNames.push((iconDef as IconDefinition).iconName);
        }

        return !isDuplicate;
      })
      .map((iconDef) => icon(iconDef as IconDefinition));

    return iconDefinitions;
  });

  triggerInsertModifier = modifier((el: HTMLElement) => {
    this.handleInsertTrigger(el);
  });

  unsetLockedSizeStyles?: () => void;

  handleSearch = (e: KeyboardEvent) =>
    debounce(this, 'handleSearchDebounced', e, 150);

  @action handleSearchDebounced(e: KeyboardEvent) {
    const value = e.target instanceof HTMLInputElement ? e.target.value : null;

    if (!this.searchTerm && value) {
      // lock size when starting to search
      const rect = this.iconsWrapperEl!.getBoundingClientRect();
      this.unsetLockedSizeStyles = setStyles(this.iconsWrapperEl, {
        width: rect.width,
        height: rect.height,
      });
    } else if (this.searchTerm && !value) {
      // unlock size when stopping to search
      this.unsetLockedSizeStyles?.();
    }

    this.searchTerm = (e.target as HTMLInputElement).value;
  }

  @action onUpdatePosition(positionState: PositionState) {
    this.positionState = positionState;
  }

  @action handleInsertTrigger(el: HTMLElement) {
    this.triggerEl = el;
  }
}
