/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';

interface Args {
  createReason: 'applied' | 'sourced' | 'suggested';
  createdAt: string;
  lastActivityAt: string;
}

export default class extends Component<Args> {
  @service declare intl: IntlService;

  get lastActivityLabel() {
    return this.intl.t('candidates.candidate.stage_card.last_activity_at', {
      date: moment(this.args.lastActivityAt).format('D MMM YYYY'),
    });
  }

  get createdLabel() {
    return this.intl.t(
      `candidates.candidate.stage_card.${this.args.createReason}_at`,
      {
        date: moment(this.args.createdAt).format('D MMM YYYY.'),
      }
    );
  }
}
