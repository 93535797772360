/* import __COLOCATED_TEMPLATE__ from './wait.hbs'; */
import NurtureCampaignStepBase from './base';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import NurtureCampaignStepWaitValidations from 'teamtailor/validations/nurture-campaign-step/wait';

export default class NurtureCampaignStepWaitComponent extends NurtureCampaignStepBase {
  validator = NurtureCampaignStepWaitValidations;

  initValue;
  @tracked waitDays;

  constructor(owner, args) {
    super(owner, args);
    const value = this.changeset.waitMinutes / 60 / 24;
    this.initValue = value;
    this.waitDays = value;
  }

  @action
  onSave() {
    this.changeset.waitMinutes = this.waitDays * 60 * 24;
    return this.saveTask.perform();
  }
}
