/* import __COLOCATED_TEMPLATE__ from './expanded-row.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { AllModels } from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import AnalyticsService from 'teamtailor/services/analytics';

type Args = {
  expandedColumn: any;
  modelIds?: string[];
  groupAnalytics?: boolean;
};

export default class ExpandedRowComponent extends Component<Args> {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;

  @tracked page = 0;
  pageSize = 10;

  get models() {
    if (!this.modelName) {
      return this.ids;
    }

    const ids = this.ids.slice(0, (this.page + 1) * this.pageSize);
    let models = this.peekAllModels(ids);

    const newlyFetchedModels: AllModels[] =
      this.fetchModels.lastSuccessful?.value || [];
    newlyFetchedModels.forEach((model: AllModels) => {
      if (!models.find((c) => c.id === model.id)) {
        models.push(model);
      }
    });

    const unprocessedModels = ids.map((id: string) => ({ id }));
    models = models.reduce((acc, model) => {
      const existingCandidate = acc.find((c: AllModels) => c.id === model.id);
      const indexOfCandidate = acc.indexOf(existingCandidate);
      acc[indexOfCandidate] = model;
      return acc;
    }, unprocessedModels);

    return models;
  }

  get modelName() {
    return this.args.expandedColumn.modelName;
  }

  get ids() {
    return this.args.modelIds && this.args.modelIds.length > 0
      ? this.args.modelIds
      : this.args.expandedColumn.value || [];
  }

  get currentIds() {
    return this.ids.slice(
      this.page * this.pageSize,
      (this.page + 1) * this.pageSize
    );
  }

  peekAllModels(ids: string[]) {
    return this.store
      .peekAll(this.modelName)
      .toArray()
      .filter((model: AllModels) => ids.includes(model.id));
  }

  fetchModels = task(async () => {
    if (this.currentIds.length === 0) {
      return [];
    }

    if (!this.modelName) {
      return this.ids;
    }

    let ids: string[] = this.currentIds;
    const existingModels = this.peekAllModels(ids);
    ids = ids.filter((id) => !existingModels.find((c) => c.id === id));

    let models: AllModels[] = [];
    if (ids.length > 0) {
      models = await this.store.query(this.modelName, {
        ids,
        groupAnalytics: this.args.groupAnalytics || undefined,
      });
    }

    return [...models, ...existingModels];
  });

  @action
  lastReached() {
    if (
      !this.fetchModels.isRunning &&
      (!this.fetchModels.lastSuccessful?.value ||
        this.currentIds.length >= this.pageSize)
    ) {
      this.page++;
    }
  }

  @action
  resetPage() {
    this.page = 0;
    this.loadModels();
  }

  @action
  loadModels() {
    if (
      !this.fetchModels.isRunning &&
      (!this.fetchModels.lastSuccessful?.value || this.currentIds.length > 0)
    ) {
      next(() => {
        this.fetchModels.perform();
      });
    }
  }
}
