/* import __COLOCATED_TEMPLATE__ from './current-event.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { MeetingEventModel } from 'teamtailor/models';
import TimeFormatService from 'teamtailor/services/time-format';

type CurrentEventArgs = {
  currentEvent?: MeetingEventModel;
};

export default class CurrentEventComponent extends Component<CurrentEventArgs> {
  @service declare timeFormat: TimeFormatService;

  get isOngoing() {
    return (
      this.args.currentEvent &&
      moment(this.args.currentEvent.bookedSlotStartsAt).isBefore(moment())
    );
  }
}
