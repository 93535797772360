/* import __COLOCATED_TEMPLATE__ from './add-users.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class ToolboxMessagesModalsAddUsersComponent extends Component {
  @tracked users = [];

  get conversation() {
    return this.args.conversation;
  }

  @action
  save() {
    return this.conversation.addUsers(this.users).then(() => {
      this.conversation.hasMany('users').reload();

      later(() => {
        this.args.onClose();
      }, 500);
    });
  }

  @action
  handleUsersChange(user) {
    if (this.users.findBy('id', user.id)) {
      this.users.removeObject(user);
    } else {
      this.users.addObject(user);
    }
  }
}
