/* import __COLOCATED_TEMPLATE__ from './trigger-ask-for-feedback-form.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import ReferenceTemplateModel from 'teamtailor/models/reference-template';
import TriggerAskForFeedbackModel from 'teamtailor/models/trigger/ask-for-feedback';
import { get } from 'teamtailor/utils/get';

interface Args {
  model: TriggerAskForFeedbackModel;
}

export default class TriggerAskForFeedbackForm extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get referenceTemplates() {
    return this.store.findAll('reference-template');
  }

  get headerText() {
    return this.intl.t(
      this.args.model.onReject
        ? 'mixins.trigger_form.header_text_on_reject'
        : 'mixins.trigger_form.header_text_on_move',
      {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      }
    );
  }

  get referenceTemplatesURL() {
    return this.router.urlFor('settings.reference-templates');
  }

  @action
  setTemplate(template: ReferenceTemplateModel) {
    this.args.model.formId = template.id;
  }
}
