/* import __COLOCATED_TEMPLATE__ from './remote.hbs'; */
import Component from '@glimmer/component';

interface SidebarFiltersRemoteComponentArgs {
  extraStatuses?: string[];
}

export default class SidebarFiltersRemoteComponent extends Component<SidebarFiltersRemoteComponentArgs> {
  get options() {
    return [...(this.args.extraStatuses || [])];
  }
}
