/* import __COLOCATED_TEMPLATE__ from './chart-legend.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

export default class ChartLegend extends Component {
  get titlesWithColor() {
    const { colors, titles } = this.args;

    return titles.map((title, index) => {
      return {
        styles: this.buildStyles(colors[index]),
        title,
      };
    });
  }

  buildStyles(color) {
    return htmlSafe(`background-color: ${color}`);
  }
}
