/* import __COLOCATED_TEMPLATE__ from './salary-selection.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { data, CurrencyCodeRecord } from 'currency-codes';
import JobModel from 'teamtailor/models/job';
import RequisitionModel from 'teamtailor/models/requisition';

type SalarySelectionArgs = {
  model?: JobModel | RequisitionModel;
  onSelectCurrency: () => void;
  onSelectTimeUnit: () => void;
};

export default class SalarySelectionComponent extends Component<SalarySelectionArgs> {
  @tracked useRange = false;
  currencyList: CurrencyCodeRecord[] = data;

  constructor(owner: unknown, args: SalarySelectionArgs) {
    super(owner, args);

    if (this.args.model?.maxSalary) {
      this.useRange = true;
    }
  }

  get selectedCurrencyObj(): CurrencyCodeRecord | undefined {
    return this.currencyList.find((c) => c.code === this.args.model?.currency);
  }

  @action
  updateCurrency(currency: CurrencyCodeRecord): void {
    if (this.args.model) {
      this.args.model.currency = currency.code;
    }

    this.args.onSelectCurrency();
  }

  @action
  toggleSalaryRange(): void {
    this.useRange = !this.useRange;

    if (!this.useRange && this.args.model) {
      this.args.model.maxSalary = null;
    }
  }

  @action
  handleSalaryTimeUnitChange(timeUnit: string): void {
    if (this.args.model) {
      this.args.model.salaryTimeUnit = timeUnit;
    }

    this.args.onSelectTimeUnit();
  }
}
