/* import __COLOCATED_TEMPLATE__ from './summary-card.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class InsightsMoleculesStageTypesSummaryCard extends Component {
  @service flipper;

  get inProcessCount() {
    let stageTypesStats = get(this.args, 'stats.stageTypesStats');

    return stageTypesStats?.inProcessCount?.count || 0;
  }
}
