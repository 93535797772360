/* import __COLOCATED_TEMPLATE__ from './candidate-details-row.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class extends Component {
  @service store;
  @service current;
  @service intl;
  @service timeFormat;

  get candidateId() {
    return this.args.candidateId || this.args.id;
  }

  get candidate() {
    return this.args.model || this.fetchCandidateTask.lastSuccessful?.value;
  }

  get deletedCandidate() {
    return !this.candidate?.nameOrEmail;
  }

  get userHasAnalyticsRole() {
    return this.current.user.hasRole('analytics');
  }

  get activityTitle() {
    return this.intl.t('components.candidates_table.row.last_activity_at', {
      time: moment(get(this.candidate, 'lastActivity')).format(
        `YYYY-MM-DD ${get(this.timeFormat, 'format')}`
      ),
    });
  }

  get createdTitle() {
    const formattedTimestamp = moment(this.candidate.createdAt).format(
      `YYYY-MM-DD ${get(this.timeFormat, 'format')}`
    );

    return this.intl.t('components.candidates_table.row.created_at', {
      time: formattedTimestamp,
    });
  }

  fetchCandidateTask = dropTask(async () => {
    if (this.args.model) {
      return undefined;
    }

    try {
      return await this.store.findRecord('candidate', this.candidateId);
    } catch {
      return undefined;
    }
  });
}
