/* import __COLOCATED_TEMPLATE__ from './sort-direction.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { sortObject } from './types';

interface SortDirectionArgs {
  sortObject: sortObject;
  sortDirection: string;
}

export default class SortDirection extends Component<SortDirectionArgs> {
  @service declare intl: IntlService;

  get oppositeSortDirection(): string {
    return this.args.sortDirection === 'desc' ? 'asc' : 'desc';
  }

  get sortDirectionText(): string {
    return this.args.sortDirection === 'desc'
      ? this.intl.t('components.stage_list.sort_desc')
      : this.intl.t('components.stage_list.sort_asc');
  }

  get sortDirectionIcon(): string {
    return this.args.sortDirection === 'desc'
      ? 'arrow-down-z-a'
      : 'arrow-down-a-z';
  }
}
