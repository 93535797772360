/* import __COLOCATED_TEMPLATE__ from './teamtailor-redactor.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { schedule, next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import imageUrl from 'teamtailor/utils/image-url';
import { guidFor } from '@ember/object/internals';
import { keyResponder, onKey } from 'ember-keyboard';
import { consume } from 'ember-provide-consume-context';

import '@slightlyoff/lite-vimeo';
import '@justinribeiro/lite-youtube';
import { helper } from '@ember/component/helper';

const handleModalEvents = function (e) {
  if (e.which === 27) {
    e.stopPropagation();
  }
};

const formattingOptions = [
  'p',
  'blockquote',
  'code',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

const placeholderRegex = /\{([^{}]+)\}/g;

const replaceBodyTags = function (body) {
  body = body.replace(/<b>/g, '<strong>');
  body = body.replace(/<\/b>/g, '</strong>');
  body = body.replace(/<i>/g, '<em>');
  body = body.replace(/<\/i>/g, '</em>');
  body = body.replace(/style="[^"]*"/gi, '');
  body = body.replace(/class="[^"]*"/gi, '');
  body = body.replace(/<\/?(table|thead|tbody|tr|th|td)(.|\n)*?>/gi, '');
  body = body.replace(/\n/g, '<br>');
  body = body.replace(/(?:<br>\s*){2,}/g, '<br>');
  body = body.replace(/<span ><\/span>/g, '');
  body = body.replace(/<p><br><\/p>/g, '');
  body = body.replace(/<br><p>/g, '<p>');
  return body;
};

(function ($R) {
  $R.add('plugin', 'mediaLibrary', {
    init(app) {
      this.app = app;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.plugin = app.plugin;
    },

    start() {
      this.toolbar.addButton('mediaLibrary', {
        title: 'Image',
        api: 'plugin.mediaLibrary._showPicker',
        icon: '<i class="re-icon-image"></i>',
      });
    },

    _showPicker() {
      const { mediaLibrary } = this.plugin;
      mediaLibrary.showPicker();
    },
  });
})(window.Redactor);

(function ($R) {
  $R.add('plugin', 'giphy', {
    init(app) {
      this.app = app;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.plugin = app.plugin;
    },

    start() {
      this.toolbar.addButton('giphy', {
        title: '<span>GIF</span>',
        api: 'plugin.giphy._showPicker',
      });
    },

    _showPicker() {
      const { giphy } = this.plugin;
      giphy.showPicker();
    },
  });
})(window.Redactor);

(function ($R) {
  $R.add('plugin', 'toggleDirection', {
    init(app) {
      this.app = app;
      this.block = app.block;
      this.plugin = app.plugin;
      this.selection = app.selection;
    },

    call() {
      const block = this.selection.getBlock() || this.block;
      if (block.dir && block.dir.toLowerCase() === 'rtl') {
        this.plugin.textdirection.set('ltr'); // eslint-disable-line ember/use-ember-get-and-set
      } else {
        this.plugin.textdirection.set('rtl'); // eslint-disable-line ember/use-ember-get-and-set
      }
    },
  });
})(window.Redactor);

@keyResponder
export default class TeamtailorRedactorComponent extends Component {
  @consume('core/form/field') field;
  @tracked redactor = null;
  link = true;
  redactorIdDefault = `redactor-${guidFor(this)}`;

  @tracked bodyPlaceholders = null;
  @tracked inHtmlMode = false;
  @tracked isGifPicking = false;
  @tracked isPicking = false;
  @tracked keyboardActivated = false;
  @tracked bodyLength = null;

  @service ttAlert;
  @service current;

  constructor() {
    super(...arguments);

    schedule('afterRender', () => {
      this.setRedactor();
    });
  }

  get redactorId() {
    return this.field?.fieldElementId ?? this.redactorIdDefault;
  }

  get notAir() {
    return !this.args.air;
  }

  get keyboardPriority() {
    return this.args.keyboardPriority ?? 5;
  }

  get showTagHelp() {
    return this.inHtmlMode;
  }

  insertImageHtml(url, alt = '') {
    const imageHtml = `<p><img src="${url}" alt="${alt}"></p>`;

    next(() => {
      this.redactor.selection.restore();
      this.redactor.insertion.insertHtml(imageHtml);
    });
  }

  setRedactor() {
    const parentThis = this;
    const redactorOptions = {
      alignmentIncludeJustify: this.args.alignmentIncludeJustify ?? false,
      animation: false,
      dragUpload: this.dragFileUpload,
      toolbarFixed: false,
      imagePosition: true,
      imageResizable: true,
      air: this.args.air,
      placeholder: this.args.placeholder ?? '',
      plugins: [],
      buttons: this.args.buttons ?? ['bold', 'italic', 'lists'],
      pasteImages: false,
      linkValidation: false,
      autoparseVideo: false,
      linkSize: 100,

      callbacks: {
        started() {
          parentThis.args.onStarted?.(this);
        },

        source: {
          closed: () => {
            this.inHtmlMode = false;
            let html = this.redactor.source.getCode();
            const tableTagRegex = /<\/?(table|thead|tbody|tr|th|td)(.|\n)*?>/gi;
            html = html?.replace(tableTagRegex, '');
            this._body = null;
            this.args.onChange(html);
          },

          opened: (code) => {
            this.inHtmlMode = true;
            return code;
          },
        },

        pasting: (body) => {
          next(() => {
            return replaceBodyTags(body);
          });
        },

        changed: (body) => {
          next(() => {
            const isFirstChange = this._body === undefined;
            body = body.replace(/\/u200b/g, '');
            body = body.replace(/>\n</g, '><');

            /**
             * When saving value in backend with sanitize,
             * the nokogiri formatter will change list style to be readable.
             * But if we don't update the value with same formatting
             * the value will be registered as changed when in should not be.
             * Use this param when data is transformed in backend.
             */
            if (this.args.useNokogiriFormat) {
              body = body.replace(/<ul>/g, '<ul>\n');
              body = body.replace(/<ol>/g, '<ol>\n');
              body = body.replace(/<\/li>/g, '</li>\n');
              body = body.replace(/><ol>/g, '>\n<ol>');
            }

            body = body.replace(/&(?![a-z]{1,6};)/g, '&amp;');
            this._body = body;

            if (this.args.limit) {
              const text = this.redactor.editor.getElement().text();
              this.bodyLength =
                this.redactor.utils.removeInvisibleChars(text).length;
            }

            this.args.onChange(body, { isFirstChange });

            if (isFirstChange && this.args.onFirstChange) {
              this.args.onFirstChange();
            }
          });
        },

        focus: () => {
          next(() => {
            this.keyboardActivated = true;
            if (this.args.onFocus) {
              this.args.onFocus();
            }
          });
        },

        blur: () => {
          next(() => {
            this.keyboardActivated = false;
            if (this.args.onBlur) {
              this.args.onBlur();
            }
          });
        },

        modal: {
          opened: () =>
            next(() => {
              document.addEventListener('keydown', handleModalEvents);
            }),

          closed: () =>
            next(() => {
              document.removeEventListener('keydown', handleModalEvents);
            }),
        },
      },
    };

    if (this.args.format) {
      redactorOptions.formatting = formattingOptions;
      redactorOptions.buttons.unshift('format');
      redactorOptions.buttons.push('line');
    }

    if (this.link) {
      redactorOptions.buttons.push('link');
      redactorOptions.plugins.push('noopener-noreferrer');
    }

    if (this.args.source) {
      redactorOptions.buttons.unshift('html');
    }

    if (this.args.alignment) {
      redactorOptions.plugins.push('alignment');
    }

    if (this.args.limit) {
      redactorOptions.limiter = this.args.limit;
      redactorOptions.plugins.push('limiter');
    }

    if (get(this.current.company, 'hasRTLCareersite')) {
      redactorOptions.plugins.push('textdirection');
      redactorOptions.plugins.push('toggleDirection');
    }

    if (this.args.images) {
      redactorOptions.plugins.push('mediaLibrary');
    }

    if (this.args.giphy) {
      redactorOptions.plugins.push('giphy');
    }

    if (this.args.videos) {
      redactorOptions.plugins.push('video');
    }

    if (this.args.placeholders) {
      redactorOptions.emailPlaceholders = this.args.placeholders;
      redactorOptions.plugins.push('emailPlaceholders');
    }

    if (this.args.imageObserve === false) {
      redactorOptions.imageObserve = this.args.imageObserve;
    }

    if (this.args.minHeight) {
      redactorOptions.minHeight = `${this.args.minHeight}px`;
    }

    if (this.args.toolbarExternalElement) {
      redactorOptions.toolbarExternal = this.args.toolbarExternalElement;
    }

    const selector = `#${this.redactorId}`;

    this.redactor = window.Redactor(selector, redactorOptions);

    if (this.args.getRedactorInstance) {
      this.args.getRedactorInstance(this.redactor);
    }

    if (this.args.disabled) {
      this.redactor.enableReadOnly();
    }

    if (this.args.body) {
      this.redactor.source.setCode(this.args.body);
    }

    const { mediaLibrary, giphy } = this.redactor.plugin;

    if (giphy) {
      giphy.showPicker = () => {
        this.redactor.insertion.insertHtml('<span></span>');
        this.redactor.selection.save();
        this.isGifPicking = true;
      };
    }

    if (mediaLibrary) {
      mediaLibrary.showPicker = () => {
        this.redactor.selection.save();
        this.isPicking = true;
      };
    }
  }

  originalAriaLabelledby;
  setAriaLabelledBy = helper(([fieldContext, redactor]) => {
    const $editor = redactor?.editor.getElement();
    if (!fieldContext || !$editor) {
      return;
    }

    if (this.originalAriaLabelledby === undefined) {
      this.originalAriaLabelledby = $editor.attr('aria-labelledby') || '';
    }

    $editor.attr(
      'aria-labelledby',
      fieldContext.ariaLabelledby
        ? `${fieldContext.ariaLabelledby} ${this.originalAriaLabelledby}`
        : this.originalAriaLabelledby
    );
  });

  @action
  updateRedactorParams() {
    if (this.redactor) {
      if (this.args.showPlugin) {
        this.redactor.toolbar.getButton(this.args.showPlugin).click();
      }

      if (this.args.clearInput) {
        this.redactor.source.setCode('');
      }

      const editorEl = this.redactor.editor?.$editor?.nodes[0];

      if (editorEl && this.args.placeholder) {
        editorEl.setAttribute(
          'placeholder',
          this.redactor.cleaner?.getFlatText(this.args.placeholder) ?? ''
        );
      }
    }
  }

  @onKey('cmd+Enter')
  closeEdit(event) {
    if (this.keyboardActivated && this.args.onCmdEnter) {
      event.preventDefault();
      this.args.onCmdEnter();
    }
  }

  @action
  setRedactorParams() {
    if (this.redactor?.source?.setCode && this._body !== this.args.body) {
      this.redactor.source.setCode(this.args.body || '');
      this._body = this.args.body;

      // Set placeholder class, since it's not added automatically in this instance
      if (this.args.body === null || this.redactor.editor.isEmpty()) {
        this.redactor.editor.getElement().addClass('redactor-placeholder');
      }
    }

    let regexpValue = this.args.body;
    if (this.args.body && this.redactor) {
      regexpValue = this.redactor.cleaner.getFlatText(this.args.body);
    }

    if (
      this.args.body &&
      !this.args.placeholders &&
      regexpValue.match(placeholderRegex)
    ) {
      this.bodyPlaceholders = regexpValue.match(placeholderRegex);
    }
  }

  @action
  handlePickImage(image) {
    this.insertImageHtml(
      imageUrl(image, 'gallery_picture'),
      image.filename || ''
    );
    this.isPicking = false;
  }

  @action
  handlePickGiphy(image) {
    this.insertImageHtml(image.url);

    this.isGifPicking = false;
  }
}
