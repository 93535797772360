/* import __COLOCATED_TEMPLATE__ from './trigger-restrict-form.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service intl;

  get headerText() {
    if (this.args.model.onReject) {
      return this.intl.t('mixins.trigger_form.header_text_on_reject', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    } else {
      return this.intl.t('mixins.trigger_form.header_text_on_move', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    }
  }
}
