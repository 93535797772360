/* import __COLOCATED_TEMPLATE__ from './job-match.hbs'; */
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import JobDetail from 'teamtailor/models/job-detail';
import JobApplicationModel from 'teamtailor/models/job-application';

interface CompareCandidatesJobMatchArgs {
  jobApplications: JobApplicationModel[];
  pinnedCount: number;
  offset: string;
  sortBy: () => void;
  hoveredJobApplication: JobApplicationModel;
}

export default class JobMatch extends Component<CompareCandidatesJobMatchArgs> {
  @tracked sortBy = this.args.sortBy;
  @tracked pinnedCount = this.args.pinnedCount;
  @tracked offset = this.args.offset;

  get jobDetail(): JobDetail {
    return get(
      this.args.jobApplications[0]?.job,
      'jobDetail'
    ) as unknown as JobDetail;
  }
}
