/* import __COLOCATED_TEMPLATE__ from './youtube-iframe.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FeatureYoutubeIframe extends Component {
  @tracked shouldInsert = false;

  @action
  delayRender(): void {
    setTimeout(() => {
      this.shouldInsert = true;
    }, 250);
  }
}
