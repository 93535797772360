/* import __COLOCATED_TEMPLATE__ from './single-pie-chart.hbs'; */
import Component from '@glimmer/component';
import colors from 'teamtailor/components/insights/charts/colors';

export default class SinglePieChart extends Component {
  get colors() {
    return colors.slice(this.args.colorOffset ?? 0);
  }

  get titles() {
    return this.args.serie.data.map((data) => data.title);
  }

  get total() {
    return this.args.serie.data
      .flatMap((data) => data.value)
      .reduce((acc, i) => acc + i, 0);
  }
}
