/* import __COLOCATED_TEMPLATE__ from './pipeline-conversion-chart.hbs'; */
import Component from '@glimmer/component';

import { stageTypeColors } from 'teamtailor/components/insights/charts/colors';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import {
  ChartDataRow,
  DataRow,
  PointsDataRow,
} from 'teamtailor/utils/insights/pipeline-conversion';

const X_WIDTH = 5;
const MAX_Y = 100;

const scaleCoordinates = (
  [num1, num2]: [number, number],
  maxNum: number
): [number, number] => {
  const SCALE = MAX_Y - 10; // Adds top padding to prevent overflow
  return [(num1 * SCALE) / maxNum, (num2 * SCALE) / maxNum];
};

export const POSSIBLE_STAGE_TYPES = [
  'inbox',
  'in_process',
  'screening',
  'interview',
  'offer',
  'hired',
];

type Args = {
  data: DataRow[];
};

export default class PipelineConversionChart extends Component<Args> {
  @service declare store: Store;

  colors = stageTypeColors;

  get data() {
    return this.args.data;
  }

  get popperOptions(): unknown {
    return {
      modifiers: {
        preventOverflow: {
          escapeWithReference: false,
        },
      },
    };
  }

  get chartOptions(): unknown {
    const marks = Array(this.data.length - 1)
      .fill(null)
      .map((_null, index) => (index + 1) * X_WIDTH);

    return {
      chart: {
        height: 300,
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 24,
      },

      yAxis: {
        min: 0,
        max: MAX_Y,
        top: 24,
        height: 250,
        title: false,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },

      legend: {
        enabled: false,
      },

      xAxis: {
        labels: {
          enabled: false,
        },

        lineColor: 'transparent',
        tickLength: 0,
        plotLines: marks.map((mark) => ({
          color: '#E4E4E7',
          value: mark,
          width: 1,
        })),
      },
    };
  }

  get chartData(): ChartDataRow[] {
    const maxNum = this.pointsData[0]?.coordinates[1] || 100;

    const chartData = this.pointsData.map((data, index, arr) => {
      const xPosStart = index * X_WIDTH;
      const xPosEnd = (index + 1) * X_WIDTH;

      const coordinates = scaleCoordinates(data.coordinates, maxNum);

      const nextData = arr[index + 1] || data;
      const nextCoordinates = scaleCoordinates(nextData.coordinates, maxNum);

      return {
        name: data.displayName,
        color: stageTypeColors[data.stageType || 'in_process'],
        type: 'polygon',
        lineWidth: 1,
        lineColor: '#FFF',
        data: [
          [xPosStart, coordinates[0]],
          [xPosEnd, nextCoordinates[0]],
          [xPosEnd, nextCoordinates[1]],
          [xPosStart, coordinates[1]],
        ],
      } as ChartDataRow;
    });

    return chartData;
  }

  get pointsData(): PointsDataRow[] {
    return this.data.reduce<PointsDataRow[]>((acc, _curr, i) => {
      let coordinates: [number, number];

      if (i === 0) {
        coordinates = [0, _curr.numberOfApplicantsPassed];
      } else {
        const previous: PointsDataRow = acc[i - 1]!;
        const change =
          previous.numberOfApplicantsPassed - _curr.numberOfApplicantsPassed;

        const baseCoordinates: [number, number] = previous.coordinates as [
          number,
          number,
        ];

        coordinates = [
          baseCoordinates[0] + change / 2,
          baseCoordinates[1] - change / 2,
        ];
      }

      const curr: PointsDataRow = { ..._curr, coordinates };
      acc.push(curr);

      return acc;
    }, []);
  }
}
