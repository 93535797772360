/* import __COLOCATED_TEMPLATE__ from './value-input.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { FilterType, FilterValue } from 'teamtailor/components/fancy-filters';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { isPresent } from '@ember/utils';
import { dropTask, task } from 'ember-concurrency';
import { next } from '@ember/runloop';
import { get } from 'teamtailor/utils/get';
import TagModel from 'teamtailor/models/tag';
import {
  AllModels,
  CompanyModel,
  DepartmentModel,
  ModelKey,
  RequisitionModel,
  RoleModel,
} from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { modifier } from 'ember-modifier';
import IntlService from 'ember-intl/services/intl';

interface ValueInputArgs {
  onUpdateValue: (value: unknown) => void;
  onSubmit: () => void;
  onEsc: () => void;
  type: FilterType;
  value: FilterValue;
  options?: string[];
  appearance?: string;
  hideDraftStatus?: boolean;
  modelName?: ModelKey;
  modelKey?: string;
  groupAnalytics?: boolean;
  modelQueryParams?: Record<string, unknown>;
}

type ValueOption = {
  name: string;
};

export default class extends Component<ValueInputArgs> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare intl: IntlService;

  get destinationElement() {
    return document.getElementById('ember-teamtailor-dropdown-submenu');
  }

  fetchModels = dropTask(
    async ({
      searchTerm: query,
      page,
    }: {
      searchTerm: { query: string };
      page: number;
    }) => {
      if (!this.args.modelName) {
        throw new Error('No modelName provided');
      }

      const params = {
        query,
        page: page || 1,
        per_page: 20,
        groupAnalytics: this.args.groupAnalytics,
      };

      if (this.args.modelQueryParams) {
        Object.assign(params, this.args.modelQueryParams);
      }

      const models = await this.store.query(this.args.modelName, params);

      return models;
    }
  );

  fetchAllModels = dropTask(async () => {
    if (!this.args.modelName) {
      throw new Error('No modelName provided');
    }

    const models = await this.store.query(this.args.modelName, {
      groupAnalytics: this.args.groupAnalytics,
    });

    return models;
  });

  get allModels() {
    return this.allModelsTask.value ?? [];
  }

  get isSecondary(): boolean {
    return this.args.appearance === 'secondary';
  }

  get arrayValue(): FilterValue[] {
    return Array.isArray(this.args.value)
      ? this.args.value
      : isPresent(this.args.value)
        ? [this.args.value]
        : [];
  }

  get showDatePicker(): boolean {
    return this.args.type === 'date';
  }

  get showRelativeDate(): boolean {
    return this.args.type === 'relative_date';
  }

  get showRatingPicker(): boolean {
    return this.args.type === 'average_rating';
  }

  get showLocationsPicker(): boolean {
    return this.args.type === '[Location]';
  }

  get showDepartmentPicker(): boolean {
    return this.args.type === '[Department]';
  }

  get showRemoteStatusPicker(): boolean {
    return this.args.type === 'RemoteStatus';
  }

  get showBooleanPicker(): boolean {
    return this.args.type === 'bool';
  }

  get showJobTagsPicker(): boolean {
    return this.args.type === '[JobTag]';
  }

  get showCandidateTagsPicker(): boolean {
    return this.args.type === '[CandidateTag]';
  }

  get showExternalRecruiterPicker(): boolean {
    return this.args.type === '[ExternalRecruiter]';
  }

  get showRecruitingFirmPicker(): boolean {
    return this.args.type === '[RecruitingFirm]';
  }

  get showJobStatusPicker(): boolean {
    return this.args.type === 'JobStatus';
  }

  get showJobPicker(): boolean {
    return this.args.type === '[Job]';
  }

  get showRegionPicker(): boolean {
    return this.args.type === '[Region]';
  }

  get showRequisitionPicker(): boolean {
    return this.args.type === '[Requisition]';
  }

  get showUserPicker(): boolean {
    return this.args.type === 'User';
  }

  get showRolePicker(): boolean {
    return this.args.type === 'Role';
  }

  get showMultipleRolePicker(): boolean {
    return this.args.type === '[Role]';
  }

  get showLanguagePicker(): boolean {
    return this.args.type === '[Language]';
  }

  get showSelectPicker(): boolean {
    return this.args.type === 'Select';
  }

  get showMultiSelectPicker(): boolean {
    return this.args.type === '[Select]';
  }

  get showMultiOptionsPicker(): boolean {
    return this.args.type === '[Option]' || this.args.type === '[Enum]';
  }

  get showMultiAlternativePicker(): boolean {
    return this.args.type === '[Alternative]';
  }

  get showStageTypePicker(): boolean {
    return this.args.type === '[StageType]';
  }

  get showRadioButtons(): boolean {
    return ['ReviewedBy', 'polar', 'radio'].includes(this.args.type);
  }

  get showRangePicker(): boolean {
    return this.args.type === 'range';
  }

  get showMultiModelPicker(): boolean {
    return this.args.type === '[Model]';
  }

  get showMultiInfiniteModelPicker(): boolean {
    return this.args.type === '[InfiniteModel]';
  }

  get showCompanyPicker(): boolean {
    return this.args.type === '[Company]';
  }

  loadCompaniesTask = task(async () => {
    const companies = await this.store.query('company', {
      groupAnalytics: this.args.groupAnalytics,
    });

    return companies;
  });

  get companies() {
    return this.companiesTask.value ?? [];
  }

  loadDepartmentsTask = task(async () => {
    const departments = await this.store.query('department', {
      groupAnalytics: this.args.groupAnalytics,
    });
    return [...departments.sortBy('name')];
  });

  get departments() {
    return this.departmentsTask.value ?? [];
  }

  loadRolesTask = task(async () => {
    await this.loadDepartmentsTask.perform();
    return this.store.query('role', {
      groupAnalytics: this.args.groupAnalytics,
    });
  });

  get roles() {
    return this.rolesTask.value ?? [];
  }

  loadRequisitionsTask = task(async () => {
    const requisitions = await this.store.query('requisition', {
      groupAnalytics: this.args.groupAnalytics,
    });

    return requisitions;
  });

  get requisitions() {
    const requisitions = this.requisitionsTask.value ?? [];

    return requisitions
      .toArray()
      .filter((r) => get(r, 'status') === 'approved');
  }

  get jobStatusOptions() {
    if (this.args.hideDraftStatus) {
      return [{ name: 'open' }, { name: 'archived' }, { name: 'unlisted' }];
    } else {
      return [
        { name: 'open' },
        { name: 'draft' },
        { name: 'archived' },
        { name: 'unlisted' },
      ];
    }
  }

  get remoteStatusOptions(): ValueOption[] {
    return [
      { name: 'none' },
      { name: 'temporary' },
      { name: 'hybrid' },
      { name: 'fully' },
    ];
  }

  @action
  setValue(value: unknown): void {
    this.args.onUpdateValue(value);
  }

  @action
  toggleValue(value: FilterValue): void {
    const { arrayValue } = this;
    const existIndex = arrayValue.indexOf(value);

    if (existIndex >= 0) {
      arrayValue.removeAt(existIndex);
    } else {
      arrayValue.pushObject(value);
    }

    this.args.onUpdateValue([...arrayValue]);
  }

  @action
  setTagValue(tag: TagModel): void {
    const value = Array.isArray(this.arrayValue) ? [...this.arrayValue] : [];

    const existing = value.find((t) => {
      if (typeof t === 'string') {
        return t === tag.name;
      } else {
        return (t as TagModel).name === tag.name;
      }
    });

    if (existing) {
      value.removeObject(existing);
    } else {
      value.pushObject(tag);
    }

    this.args.onUpdateValue(value);
  }

  @action
  setValueFromName(option: ValueOption): void {
    this.args.onUpdateValue(option.name);
  }

  @action
  submitOnEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.args.onSubmit();
    }
  }

  @action
  closeOnEsc(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.args.onEsc();
    }
  }

  departmentsTask = trackedTask<DepartmentModel[]>(
    this,
    this.loadDepartmentsTask
  );

  rolesTask = trackedTask<RoleModel[]>(this, this.loadRolesTask);

  allModelsTask = trackedTask<AllModels[]>(this, this.fetchAllModels);

  companiesTask = trackedTask<CompanyModel[]>(this, this.loadCompaniesTask);

  requisitionsTask = trackedTask<RequisitionModel[]>(
    this,
    this.loadRequisitionsTask
  );

  setFocusOnInputModifier = modifier((element: HTMLElement) => {
    next(() => {
      element.focus();
    });
  });
}
