/* import __COLOCATED_TEMPLATE__ from './career-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'teamtailor/config/environment';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';

type Option = {
  text: string;
  value: string;
};

export default class WidgetSnippetsCareerButton extends Component {
  @service declare current: Current;
  @service declare intl: IntlService;

  @tracked declare locales: string[];
  @tracked declare careerButtonWidgetColor?: string;

  @tracked careerButtonWidgetPosition = 'bottom-right';
  @tracked careerButtonWidgetLocale: null | string = null;

  get settingsScript(): string {
    const settings = [
      `teamtailorSettings.widgetPosition = "${this.careerButtonWidgetPosition}"`,
      `teamtailorSettings.color = "${this.careerButtonWidgetColor}"`,
      `teamtailorSettings.locale = "${
        this.careerButtonWidgetLocale
          ? `?locale=${this.careerButtonWidgetLocale}`
          : ''
      }"`,
      `teamtailorSettings.company = "${get(
        this.current.company,
        'subdomain'
      )}.${config.HTTP_HOST}"`,
    ].join(';');

    return `<script>window.teamtailorSettings = window.teamtailorSettings || {}; ${settings}; </script>`;
  }

  get elementScript(): string {
    return `<script>(function() {var w=window;var d=document;function l() {var s=d.createElement('script');s.type='text/javascript'; s.async=true; s.src='//'+teamtailorSettings.company+'/widget'+teamtailorSettings.locale;var x=d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s,x);} if(w.attachEvent) { w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}})()</script>`;
  }

  get positionOptions() {
    return [
      {
        text: this.intl.t('settings.integrations.widget.bottom_left'),
        value: 'bottom-left',
      },
      {
        text: this.intl.t('settings.integrations.widget.bottom_right'),
        value: 'bottom-right',
      },
      {
        text: this.intl.t('settings.integrations.widget.top_left'),
        value: 'top-left',
      },
      {
        text: this.intl.t('settings.integrations.widget.top_right'),
        value: 'top-right',
      },
    ];
  }

  @action
  async didInsert(): Promise<void> {
    const design = await get(this.current.company, 'design');
    this.locales = await get(this.current.company, 'careerSites').mapBy(
      'languageCode'
    );
    this.careerButtonWidgetColor = get(design, 'color');
  }

  @action
  setPosition(option: Option) {
    this.careerButtonWidgetPosition = option.value;
  }
}
