/* import __COLOCATED_TEMPLATE__ from './delete.hbs'; */
import BaseComponent from './base';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class DeleteComponent extends BaseComponent {
  @service flashMessages;
  @service intl;

  @tracked numberOfCandidatesToDelete;
  @tracked isDeleteConfirmStep = false;

  @action
  deleteConfirmStep() {
    this.isDeleteConfirmStep = true;
  }

  @action
  onClose() {
    this.args.onClose();
    this.isDeleteConfirmStep = false;
    this.numberOfCandidatesToDelete = undefined;
  }

  @action
  deleteCandidates() {
    let promise = this.postAction(
      'bulk_delete',
      this.postData({ user_verification: this.numberOfCandidatesToDelete })
    );

    promise
      .then(() => {
        this.numberOfCandidatesToDelete = undefined;
        this.args.bulkCallbackAction();
        if (this.args.bulkDeleteCallbackAction) {
          this.args.bulkDeleteCallbackAction(this.args.selectedIds);
        }
      })
      .then(() => {
        if (this.hasJob) {
          if (!isEmpty(this.args.selectedJobApplications)) {
            this.args.selectedJobApplications.forEach((jobApplication) => {
              jobApplication.unloadRecord();
            });
            this.args.job.reload();
          }
        } else {
          this.args.selectedCandidates.forEach((candidate) => {
            candidate.unloadRecord();
          });
        }

        this.args.clearSelection();
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
