/* import __COLOCATED_TEMPLATE__ from './webhooks-form.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';

import WebhookSubscription from 'teamtailor/models/webhook-subscription';
import { tracked } from 'tracked-built-ins';

const addOrRemove = (item: string, _arr?: string[]) => {
  const arr = _arr || [];
  return arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];
};

export default class WebhooksFormComponent extends Component<{
  webhook: WebhookSubscription;
  onSave: () => void;
}> {
  @tracked pickedSubscriptions: string[] =
    this.args.webhook.subscriptions || [];

  @tracked subscriptions: string[] = [];

  fetchResources = task(async () => {
    const { resources } = await this.args.webhook.getResources();
    this.subscriptions = resources;
  });

  @action
  onChange(subscription: string) {
    this.args.webhook.subscriptions = addOrRemove(
      subscription,
      this.args.webhook.subscriptions
    );
  }
}
