/* import __COLOCATED_TEMPLATE__ from './publish-changes.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import CareerSite from 'teamtailor/models/career-site';
import SectionChangeModel from 'teamtailor/models/section-change';
import uniq from 'teamtailor/utils/uniq';

interface Args {
  unpublishedChangesCount: number;
  isOpen?: boolean;
  selectedGlobalDesignChange: boolean;
  selectedGlobalBlockChange: boolean;
  closePublishModal: () => void;
  confirmDialogText: string;
  confirmPublishModal: () => void;
  careerSite: CareerSite;
}

export default class ContentPublishChangesComponent extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;

  @tracked sectionChanges: SectionChangeModel[] = [];

  @tracked selectedPagePublicationIds: string[] = [];
  @tracked selectedGlobalDesignChange = true;
  @tracked selectedGlobalBlockChange = true;

  get pageChanges() {
    return uniq(this.sectionChanges.filterBy('pageId'));
  }

  get pageChangesCount() {
    return uniq(this.pageChanges.mapBy('pageId')).length;
  }

  get designChanges() {
    return this.sectionChanges.filterBy('designId');
  }

  get headerChanges() {
    return this.sectionChanges.filterBy('careerSiteHeaderId');
  }

  get pageSettingsChanges() {
    return this.sectionChanges.filterBy('hasPageSettingsChanges');
  }

  get postContentChanges() {
    return this.sectionChanges.filterBy('hasPostContentChanges');
  }

  get totalChangesCount() {
    return (
      this.pageChangesCount +
      this.designChanges.length +
      this.headerChanges.length
    );
  }

  get selectedChangesCount() {
    const designChangesCount = this.selectedGlobalDesignChange
      ? this.designChanges.length
      : 0;

    const globalBlockChangesCount = this.selectedGlobalBlockChange
      ? this.headerChanges.length
      : 0;

    return (
      this.selectedPagePublicationIds.length +
      designChangesCount +
      globalBlockChangesCount
    );
  }

  get hasOnlyPageSettingsChanges() {
    const hasPageChangesWithoutSectionChanges =
      this.args.unpublishedChangesCount !== this.totalChangesCount;

    const hasNoSectionChanges = this.totalChangesCount === 0;

    return hasPageChangesWithoutSectionChanges && hasNoSectionChanges;
  }

  get isSubmitPublishDisabled() {
    if (this.hasOnlyPageSettingsChanges) {
      return false;
    }

    return (
      this.fetchSectionChanges.isRunning || this.selectedChangesCount === 0
    );
  }

  fetchSectionChanges = restartableTask(async () => {
    this.sectionChanges = await this.store.query('section-change', {
      career_site_id: this.args.careerSite.id,
    });

    const filterChanges = this.sectionChanges.filter(
      (sectionChange) => sectionChange.pagePublicationId
    );

    const mappedPublicationIds = filterChanges.map((sectionChange) => {
      return sectionChange.pagePublicationId.toString();
    });

    this.selectedPagePublicationIds = [...new Set(mappedPublicationIds)];
  });
}
