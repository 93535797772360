/* import __COLOCATED_TEMPLATE__ from './interview-form.hbs'; */
import { action, set } from '@ember/object';
import Component from '@glimmer/component';
import { restartableTask, timeout } from 'ember-concurrency';
import { InterviewModel } from 'teamtailor/models';
import { OrderedCompetence } from 'teamtailor/utils/competence/ordered-list-helpers';
import { get } from 'teamtailor/utils/get';

type InterviewFormComponentArgs = {
  interview: InterviewModel;
  onSave: (status: 'draft' | 'published') => void;
};

export default class InterviewForm extends Component<InterviewFormComponentArgs> {
  get interviewKit() {
    return get(this.args.interview, 'interviewKit');
  }

  get job() {
    return get(this.args.interview, 'job');
  }

  get scores() {
    return get(this.args.interview, 'scores');
  }

  get nestedInterviewItems() {
    return get(this.interviewKit, 'nestedInterviewItems');
  }

  get questions() {
    return this.nestedInterviewItems.filterBy('type', 'question');
  }

  get answers() {
    return get(this.args.interview, 'answers').filter((answer) =>
      this.questions.some((q) => get(q, 'id') === get(answer, 'questionId'))
    );
  }

  get competences() {
    return this.nestedInterviewItems.filterBy('type', 'competence');
  }

  handleNoteInput = restartableTask(async (event) => {
    await timeout(800);
    set(this.args.interview, 'note', event.target.value);
    if (this.args.interview.isDraft) {
      this.args.onSave('draft');
    }
  });

  @action
  competenceAnswers(competence: OrderedCompetence) {
    const questionIds = competence.children?.mapBy('id') || [];
    return get(this.args.interview, 'answers').filter((answer) =>
      questionIds.includes(get(answer, 'questionId'))
    );
  }
}
