import TranslationSerializer from 'teamtailor/serializers/translation';
import classic from 'ember-classic-decorator';

@classic
export default class TranslationInterviewKitSerializer extends TranslationSerializer {}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'translation/interview-kit': TranslationInterviewKitSerializer;
  }
}
