/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';
import ChannelModel from 'teamtailor/models/channel';
import PartnerModel from 'teamtailor/models/partner';
import { get } from 'teamtailor/utils/get';

interface CoreModalMainArgs {
  channel?: ChannelModel;
  partner?: PartnerModel;
  text?: string;
  showOnLoad?: boolean;
}

export default class CoreModalHeader extends Component<CoreModalMainArgs> {
  get showOnLoad() {
    return this.args.showOnLoad ?? !!this.text;
  }

  get channelOrPartner() {
    return this.args.channel ?? this.args.partner;
  }

  get text() {
    return this.args.text ?? get(this.channelOrPartner, 'name');
  }
}
