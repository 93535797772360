/* import __COLOCATED_TEMPLATE__ from './quick-navigation.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import { keyResponder, onKey } from 'ember-keyboard';
import isMac from 'teamtailor/utils/is-mac';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

const REDACTOR_CLASS = 'redactor-styles';

@keyResponder
export default class QuickNavigationComponent extends Component {
  @service quickNavigation;
  @service recentVisits;
  isMac = isMac;

  @tracked boxWrapperEl;

  get query() {
    return get(this.quickNavigation, 'query');
  }

  set query(val) {
    set(this.quickNavigation, 'query', val);
  }

  get filterByType() {
    return get(this.quickNavigation, 'filterByType');
  }

  set filterByType(val) {
    set(this.quickNavigation, 'filterByType', val);
  }

  get result() {
    return get(this.quickNavigation, 'result');
  }

  get selectedItem() {
    return get(this.quickNavigation, 'selectedItem');
  }

  set selectedItem(val) {
    set(this.quickNavigation, 'selectedItem', val);
  }

  get isOpen() {
    return get(this.quickNavigation, 'isOpen');
  }

  get recentSearches() {
    return get(this.quickNavigation, 'history');
  }

  get defaultCreateActionItems() {
    return get(this.quickNavigation, 'defaultCreateActions');
  }

  get filterOptions() {
    return get(this.quickNavigation, 'filterOptions');
  }

  // @alias('quickNavigation.selectedFilter') selectedFilter;
  get selectedFilter() {
    return get(this.quickNavigation, 'selectedFilter');
  }

  set selectedFilter(val) {
    set(this.quickNavigation, 'selectedFilter', val);
  }

  get inputElement() {
    return get(this.quickNavigation, 'inputElement');
  }

  set inputElement(val) {
    set(this.quickNavigation, 'inputElement', val);
  }

  get showShortcuts() {
    return get(this.quickNavigation, 'showShortcuts');
  }

  set showShortcuts(val) {
    set(this.quickNavigation, 'showShortcuts', val);
  }

  get currentFilter() {
    return this.filterOptions.findBy('value', this.filterByType);
  }

  get placeholder() {
    return `components.quick_navigation.placeholder.${
      this.filterByType ? this.filterByType : 'default'
    }`;
  }

  @onKey('cmd+j')
  @onKey('cmd+k')
  handleShortcut(event) {
    const { key, target } = event;
    const isRedactorLinkShortcut =
      key === 'k' && target.className.includes(REDACTOR_CLASS);

    if (!isRedactorLinkShortcut) {
      event.preventDefault();
      if (this.isOpen) {
        this.focusSearchInput();
      } else {
        this.quickNavigation.open();
      }
    }
  }

  @action
  search(event) {
    this.quickNavigation.searchTask.perform(event.target.value);
  }

  @action
  focusElement(element) {
    this.inputElement = element;
    element.focus();
    element.select();
  }

  @action
  handleArrowDown(event) {
    event.preventDefault();
    this.quickNavigation.navigateDown();
  }

  @action
  handleArrowUp(event) {
    if (this.selectedItem || this.selectedFilter) {
      event.preventDefault();
      this.quickNavigation.navigateUp();
    }
  }

  @action
  handleArrowLeft(event) {
    event.preventDefault();
    this.quickNavigation.filterNavigateLeft();
  }

  @action
  handleTab(event) {
    if (event.shiftKey) {
      this.handleArrowUp(event);
    } else {
      this.handleArrowDown(event);
    }
  }

  @action
  handleArrowRight(event) {
    event.preventDefault();
    this.quickNavigation.filterNavigateRight();
  }

  @action
  handleEnter(event) {
    if (this.selectedFilter) {
      event.preventDefault();
      this.handleFilter(this.selectedFilter.value);
      this.selectedFilter = null;
    } else if (this.selectedItem) {
      event.preventDefault();
      this.quickNavigation.transitionToSelectedItem();
    }
  }

  @action
  handleCmdEnter(event) {
    event.preventDefault();
    this.quickNavigation.transitionToNewTabSelectedItem();
  }

  @action
  handleCmdShiftEnter(event) {
    event.preventDefault();
    this.quickNavigation.transitionToSelectedItem(true);
  }

  @action
  handleBackspace(event) {
    if (this.filterByType && !event.target.selectionStart) {
      set(this, 'filterByType', null);
      this.quickNavigation.searchTask.perform(this.query);
    }
  }

  @action
  transitionToItem(item) {
    this.quickNavigation.transitionToItem(item);
  }

  @action
  handleRecentQueryClick(query) {
    this.query = query;
    this.quickNavigation.searchTask.perform(query);
  }

  @action
  handleClearRecentQueriesClick() {
    this.quickNavigation.clearHistory();
  }

  @action
  toggleShortcuts() {
    this.showShortcuts = !this.showShortcuts;
  }

  @action
  handleFilter(filter) {
    this.inputElement.focus();
    this.filterByType = filter;
    this.quickNavigation.searchTask.perform(this.query);
  }

  @action
  handleFilterHover(filter) {
    this.selectedFilter = filter;
    if (this.selectedItem) {
      this.selectedItem = null;
    }
  }

  @action
  handleSelectedItemHover(item) {
    this.selectedItem = item;
    if (this.selectedFilter) {
      this.selectedFilter = null;
    }
  }
}
