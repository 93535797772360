/* import __COLOCATED_TEMPLATE__ from './stats.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';

export default class NurtureCampaignsStatsComponent extends Component {
  @service current;
  @service server;
  @service session;
  @service intl;
  @tracked fetchedStats;

  get statsEndpoint() {
    const companyUuid = this.current.company.uuid;
    return `/app/companies/${companyUuid}/api/nurture_campaign_stats`;
  }

  get params() {
    if (isEmpty(this.args.statusFilter) || this.args.statusFilter === 'all') {
      if (isEmpty(this.args.nurtureCampaignId)) {
        return {};
      } else {
        return { nurture_campaign_id: this.args.nurtureCampaignId };
      }
    } else {
      return { status: this.args.statusFilter };
    }
  }

  fetchStats = dropTask(async () => {
    this.fetchedStats = await this.server.request(this.statsEndpoint, 'GET', {
      data: this.params,
    });
  });

  get stats() {
    return [
      ...(!this.args.nurtureCampaignId
        ? [
            {
              icon: 'circle-user',
              title: this.intl.t('nurture_campaigns.stats.total_candidates'),
              value: this.fetchedStats?.total_candidates,
            },
          ]
        : []),
      {
        icon: 'envelope',
        title: this.intl.t('nurture_campaigns.stats.emails_sent'),
        value: this.fetchedStats?.emails_sent,
      },
      ...(this.args.nurtureCampaignId &&
      this.fetchedStats?.open_rate !== undefined
        ? [
            {
              icon: 'eye',
              title: this.intl.t('nurture_campaigns.stats.open_rate'),
              value: `${this.fetchedStats?.open_rate || 0}%`,
            },
          ]
        : []),
      {
        icon: 'door-open',
        title: this.intl.t('nurture_campaigns.stats.meetings_booked'),
        value: this.fetchedStats?.meetings_booked,
      },
      ...(this.current.company.hasFeature('job_offers')
        ? [
            {
              icon: 'circle-user',
              title: this.intl.t('nurture_campaigns.stats.job_offers_made'),
              value: this.fetchedStats?.job_offers_made,
            },
          ]
        : []),
      {
        icon: 'party-horn',
        title: this.intl.t('nurture_campaigns.stats.candidates_hired'),
        value: this.fetchedStats?.candidates_hired,
      },
    ];
  }
}
