/* import __COLOCATED_TEMPLATE__ from './reject-reason.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { next } from '@ember/runloop';

export default class ModalsRejectReason extends Component {
  @action async createRejectReason() {
    await this.args.rejectReason.save();
    next(() => {
      this.args.onClose();
    });
  }
}
