/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import CurrentService from 'teamtailor/services/current';
import CandidateModel from 'teamtailor/models/candidate';

interface Args {
  candidate: CandidateModel;
  anonymous?: boolean;
}

export default class extends Component<Args> {
  @service declare current: CurrentService;

  @computedLocalStorage(Boolean, 'expandedResumeKey', false)
  declare expandedResume: boolean;

  expandedResumeKey = 'CandidateModal-expandedResume';

  get resumeSummaryEnabled(): boolean {
    return this.current.company.hasCopilotFeature('generate_resume_summary');
  }

  get displaySummarizeButton(): boolean {
    return (
      this.resumeSummaryEnabled &&
      this.args.candidate.resumeSummaryStatus !== 'completed' &&
      !this.args.anonymous
    );
  }

  @action
  toggleExpandedResume() {
    this.expandedResume = !this.expandedResume;
  }
}
