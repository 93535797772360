/* import __COLOCATED_TEMPLATE__ from './hiring.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import config from 'teamtailor/config/environment';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

type Args = {
  [key: string]: unknown;
};

export default class WidgetSnippetsHiringComponent extends Component {
  @service declare current: Current;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked useCustomText = false;
  @tracked customText: string;
  @tracked backgroundColor?: string;
  @tracked textColor?: string;
  @tracked type!: string;
  @tracked bannerLinkUrl!: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.customText = this.intl.t(
      'settings.integrations.widget.hiring.default_custom_text'
    );

    this.type = 'badge';
  }

  apiKey = trackedFunction(this, async () => {
    const apiKey = await this.store.queryRecord('api-key', {
      widget: 'public',
      group_key: false,
    });

    return apiKey.key;
  });

  get isBanner(): boolean {
    return this.type === 'banner';
  }

  get isBadge(): boolean {
    return this.type === 'badge';
  }

  get spanTag(): string {
    return `<span class="tt-hiring"></span>`;
  }

  get scriptTag(): string {
    return `${this.inlineScript}<script src="${config.scripts_host}/hiring.js" async charset="utf-8"></script>`;
  }

  get hasCustomText(): boolean {
    return this.useCustomText || this.type === 'banner';
  }

  get inlineScript(): string {
    const settings = [
      `teamtailorSettings.apiKey="${get(this.apiKey, 'value')}"`,
      `teamtailorSettings.color="${this.textColor}"`,
      `teamtailorSettings.backgroundColor="${this.backgroundColor}"`,
      `teamtailorSettings.hiringWidgetType="${this.type}"`,
    ];

    if (this.hasCustomText || this.isBanner) {
      settings.push(`teamtailorSettings.hiringCustomText="${this.customText}"`);
    }

    if (this.isBanner) {
      settings.push(`teamtailorSettings.hiringLink="${this.bannerLinkUrl}"`);
    }

    return `<script>window.teamtailorSettings = window.teamtailorSettings || {}; ${settings.join(
      ';'
    )}; </script>`;
  }

  @action
  async didInsert(): Promise<void> {
    const design = await get(this.current.company, 'design');
    this.backgroundColor = get(design, 'buttonBackgroundColor');
    this.textColor = get(design, 'buttonTextColor');

    const url = get(
      await get(this.current.company, 'defaultCareerSite'),
      'url'
    );
    if (url) {
      this.bannerLinkUrl = `${url}jobs`;
    }
  }
}
