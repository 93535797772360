/* import __COLOCATED_TEMPLATE__ from './payment-history.hbs'; */
import Component from '@glimmer/component';
import ChargeModel from 'teamtailor/models/charge';

import {
  TABLE_COLUMNS,
  TABLE_LOADING_COLUMNS,
  DEFAULT_CURRENCY,
} from 'teamtailor/constants/payments';

interface PaymentHistoryInterface {
  charges: ChargeModel[];
}

export default class PaymentHistoryComponent extends Component<PaymentHistoryInterface> {
  columns = TABLE_COLUMNS;
  loadingColumns = TABLE_LOADING_COLUMNS;
  currency = DEFAULT_CURRENCY;
}
