/* import __COLOCATED_TEMPLATE__ from './candidate-online-beacon.hbs'; */
import Component from '@ember/component';
import { equal } from '@ember/object/computed';

export default Component.extend({
  classNameBindings: [
    ':c-online-beacon',
    'visible:c-online-beacon--visible',
    'isSmall:c-online-beacon--small',
  ],

  isSmall: equal('beaconSize', 'smaller-medium'),
});
