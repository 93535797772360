/* import __COLOCATED_TEMPLATE__ from './controls.hbs'; */
import Component from '@glimmer/component';

type MediaControlsArgs = {
  id: string;
  progress: number;
  volume: number;
  onUpdateRangeInputValue: () => void;
};

export default class MediaControlsComponent extends Component<MediaControlsArgs> {
  get progressBarWidth() {
    const value = this.args.progress;

    return `calc(${value}% + var(--plyr-range-thumb-height, ${
      13 * (value ? value / 100 : 0)
    }px))`;
  }

  get volumeBarWidth() {
    const value = this.args.volume;
    return `${70 * (value ? value : 0)}px`;
  }
}
