/* import __COLOCATED_TEMPLATE__ from './promotion-features.hbs'; */
import Component from '@glimmer/component';
import ChannelModel from 'teamtailor/models/channel';
import { get } from 'teamtailor/utils/get';
import { EmptyObj } from 'teamtailor/utils/type-utils';

interface PromotionFeaturesArgs {
  channel: ChannelModel;
}

export default class PromotionFeatures extends Component<PromotionFeaturesArgs> {
  get price(): number | null {
    const discountedPromotionPrice = get(
      this.args.channel,
      'discountedPromotionPrice'
    );
    const originalPromotionPrice = get(
      this.args.channel,
      'originalPromotionPrice'
    );
    return discountedPromotionPrice || originalPromotionPrice;
  }

  get unknownPrice(): boolean {
    return this.price === null;
  }

  get country(): 'Global' | (string & EmptyObj) | undefined {
    const countries = get(this.args.channel, 'countries');
    if (countries.length > 1) {
      return 'Global';
    }

    return countries[0];
  }

  get isFreeChannel(): boolean {
    return get(this.args.channel, 'isFree');
  }

  get isAgreedWithPartnerChannel(): boolean {
    return (
      get(this.args.channel, 'allowedPaymentTypes') === 'partner' ||
      (['partner_or_teamtailor', 'charged_by_partner'].includes(
        get(this.args.channel, 'allowedPaymentTypes')
      ) &&
        !!get(this.args.channel, 'channelActivation')?.existingAgreement)
    );
  }
}
