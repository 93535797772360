/* import __COLOCATED_TEMPLATE__ from './badges.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import UserModel from 'teamtailor/models/user';
import Current from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';

interface Args {
  user: UserModel;
}

export default class EmployeesCardComponent extends Component<Args> {
  @service declare current: Current;
  @service declare intl: IntlService;

  get showTwoFactorBadge(): boolean {
    return this.hasLogin && (this.twoFactorActive || this.twoFactorEnforced);
  }

  get hasLogin(): boolean {
    return this.args.user.hasLogin;
  }

  get twoFactorActive(): boolean {
    return get(get(this.args.user, 'login'), 'twoFactorActivated') || false;
  }

  get twoFactorEnforced(): boolean {
    return this.args.user.company.twoFactorAuthenticationEnforced;
  }

  get twoFactorAuthenticationTooltip() {
    if (this.twoFactorActive) {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_active.description'
      );
    } else {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_inactive.description'
      );
    }
  }

  get twoFactorAuthenticationText() {
    if (this.twoFactorActive) {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_active.title'
      );
    } else {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_inactive.title'
      );
    }
  }
}
