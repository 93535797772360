/* import __COLOCATED_TEMPLATE__ from './question.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';

import PermissionsService from 'teamtailor/services/permissions';
import { editQuestionProperties } from 'teamtailor/utils/jobs/edit/question';

export default class QuestionGroupsQuestion extends Component {
  @service declare intl: IntlService;
  @service declare permissions: PermissionsService;

  @tracked editing = false;

  editQuestionProperties = editQuestionProperties;
}
