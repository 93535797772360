import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class SharedReportAdapter extends ApplicationAdapter {
  namespace = 'app/api';
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'shared-report': SharedReportAdapter;
  }
}
