/* import __COLOCATED_TEMPLATE__ from './top-activities.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class InsightsMoleculesTopActivities extends Component {
  @action
  topActivities() {
    const { activitySummary } = this.args;
    const topActivityKeys = Object.keys(activitySummary)
      .sort((a, b) => activitySummary[a] - activitySummary[b])
      .filter((key) => !['__typename', 'userId'].includes(key))
      .reverse()
      .slice(0, 3);
    return topActivityKeys.map((key) => [key, activitySummary[key]]);
  }
}
