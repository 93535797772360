/* import __COLOCATED_TEMPLATE__ from './internal-title-modal.hbs'; */
import { action } from '@ember/object';
import AvatarModel from 'teamtailor/models/avatar';
import Component from '@glimmer/component';
import JobModel from 'teamtailor/models/job';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

interface JobsInternalTitleModalArgs {
  job: JobModel;
  onModalClose: () => void;
}

export default class JobsInternalTitleModal extends Component<JobsInternalTitleModalArgs> {
  @service declare store: Store;

  @tracked internalName = this.args.job.internalName;
  @tracked declare newAvatar: AvatarModel | null;

  @action
  onPickAvatar(avatar: AvatarModel) {
    this.newAvatar = avatar;
  }

  @action
  async saveJobAndAvatar() {
    const { job } = this.args;

    if (this.newAvatar) {
      if (job.avatar?.id) {
        job.avatar.copyProps(this.newAvatar);
      } else {
        job.avatar = this.newAvatar;
      }
    }

    job.internalName = this.internalName;
    await job.save();

    if (this.newAvatar) {
      this.newAvatar.rollbackAttributes();
    }

    this.args.onModalClose();
  }

  @action
  closeModal() {
    if (this.newAvatar) {
      this.newAvatar.rollbackAttributes();
    }

    this.args.onModalClose();
  }
}
