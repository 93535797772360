/* import __COLOCATED_TEMPLATE__ from './chat-video-upload.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import DirectUploadService from 'teamtailor/services/direct-upload';
import Store from '@ember-data/store';
import {
  MessageWidgetModel,
  PickedVideoModel,
  VideoModel,
} from 'teamtailor/models';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';

interface Args {
  messageWidget: MessageWidgetModel;
}

export default class ChatVideoUploadComponent extends Component<Args> {
  @service declare directUpload: DirectUploadService;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked validFileSize = true;
  @tracked messageWidget: MessageWidgetModel = this.args.messageWidget;
  @tracked uploadLabel: HTMLLabelElement | null = null;
  @tracked pickedVideo?: PickedVideoModel | null =
    this.messageWidget.pickedVideo;

  validateFileSize(file: File): boolean {
    const maxFileSizeInBytes = 209715200; // 200MB
    const fileSizeInBytes = file.size;
    return fileSizeInBytes <= maxFileSizeInBytes;
  }

  uploadToS3 = task(async (file: File) => {
    this.validFileSize = this.validateFileSize(file);

    if (!this.validFileSize) {
      return;
    }

    const fileUrl = await this.directUpload.s3(file, { model: 'videos' });
    const video = await this.store
      .createRecord('video', { file: fileUrl, transcodeType: 'video_block' })
      .save();

    await this.store
      .createRecord('picked-video', {
        video,
        messageWidget: this.messageWidget,
      })
      .save();
    this.pickedVideo = this.messageWidget.pickedVideo;

    this.updateUIOnTranscodeCompleted(video);
  });

  @action
  async handleDeleteVideo() {
    (await this.pickedVideo)?.destroyRecord();
  }

  get loadingText() {
    if (this.uploadToS3.isRunning) {
      return this.intl.t(
        'jobs.edit.chat_widget.form.video_presenter.uploading'
      );
      // eslint-disable-next-line
    } else if (this.pickedVideo?.get('video')?.isTranscodePending) {
      return this.intl.t(
        'jobs.edit.chat_widget.form.video_presenter.processing'
      );
    }
  }

  // Hack to update the UI when the video is transcoded
  updateUIOnTranscodeCompleted(video: VideoModel) {
    setTimeout(async () => {
      video = await this.store.findRecord('video', video.id);
      if (!video.isTranscodeCompleted) {
        this.updateUIOnTranscodeCompleted(video);
      }
    }, 2500);
  }
}
