/* import __COLOCATED_TEMPLATE__ from './trigger-advanced-options.hbs'; */
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { AllTriggerModels, DelayUnit } from 'teamtailor/models/trigger';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

interface TriggerAdvancedOptionsArgs {
  model: AllTriggerModels;
}

export default class TriggerAdvancedOptionsComponent extends Component<TriggerAdvancedOptionsArgs> {
  @service declare current: Current;

  @tracked isOpen = false;

  get hasInternalJobs() {
    return get(this.current.company, 'hasInternalJobs');
  }

  @action
  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  @action
  handleChangeUnit(newUnit: DelayUnit) {
    const unit = get(this.args.model, 'delayJobForUnit');

    if (unit === newUnit) {
      return;
    }

    const value = get(this.args.model, 'delayJobForCustomValue');

    if (newUnit === DelayUnit.days) {
      set(this.args.model, 'delayJobFor', value * 60 * 24);
    } else if (newUnit === DelayUnit.hours) {
      set(this.args.model, 'delayJobFor', value * 60);
    } else {
      set(this.args.model, 'delayJobFor', value);
    }

    set(this.args.model, 'delayJobForUnit', newUnit);
  }
}
