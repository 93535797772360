/* import __COLOCATED_TEMPLATE__ from './scorecard-form-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import ScorecardScoreModel from 'teamtailor/models/scorecard-score';

interface Args {
  scorecardScore: ScorecardScoreModel;
  onScore?: (score: ScorecardScoreModel) => void;
}

export default class ScorecardFormField extends Component<Args> {
  @action
  handleScore(scorecardScore: ScorecardScoreModel, score: number): void {
    if (scorecardScore.score === score) {
      scorecardScore.score = null;
    } else {
      scorecardScore.score = score;
    }

    if (this.args.onScore) {
      this.args.onScore(scorecardScore);
    }
  }
}
