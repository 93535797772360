/* import __COLOCATED_TEMPLATE__ from './question-type.hbs'; */
import Component from '@glimmer/component';
import { QuestionType, QUESTION_TYPES } from 'teamtailor/constants/question';
interface SettingsQuestionsQuestionTypeArgs {
  type: QuestionType;
  isPivate?: boolean;
}

export default class SettingsQuestionsQuestionType extends Component<SettingsQuestionsQuestionTypeArgs> {
  get text() {
    return QUESTION_TYPES[this.args.type].translationKey;
  }

  get icon() {
    return QUESTION_TYPES[this.args.type].icon;
  }
}
