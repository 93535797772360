/* import __COLOCATED_TEMPLATE__ from './custom-checkbox.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SelectComponent extends Component {
  @service intl;

  get options() {
    return [
      { id: 'yes', label: this.intl.t('common.yes') },
      { id: 'no', label: this.intl.t('common.no') },
    ];
  }

  get selectedOption() {
    return this.options.findBy('id', this.args.selected);
  }

  @action
  handleSelect(value) {
    this.args.onChange(value?.id);
  }
}
