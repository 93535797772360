/* import __COLOCATED_TEMPLATE__ from './location-picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import LocationModel from 'teamtailor/models/location';
import ConnectedConferenceModel from 'teamtailor/models/connected-conference';
import Store from '@ember-data/store';
import {
  fetchLocations,
  formatLocationOptions,
} from 'teamtailor/utils/locations-picker';
import { trackedFunction } from 'ember-resources/util/function';

interface Args {
  connectedConferences?: ConnectedConferenceModel[];
  selected?: LocationModel | ConnectedConferenceModel | string;
  placeholder?: string;
  onChange: (
    type: LocationType | undefined,
    location: LocationModel | ConnectedConferenceModel | string | undefined
  ) => void;
}

enum LocationType {
  Location = 'location',
  Video = 'video',
  Custom = 'custom',
}

export default class MeetingEventRowsLocation extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked searchTerm = '';
  @tracked customLocation = '';

  get triggerText(): string {
    if (this.args.selected) {
      return (
        (this.args.selected as LocationModel).nameOrCity ||
        (this.args.selected as ConnectedConferenceModel).appName ||
        (this.args.selected as string)
      );
    }

    return (
      this.args.placeholder ||
      this.intl.t('components.meetings.event_scheduler.select')
    );
  }

  get companyLocations() {
    return this.locationFunction.value || [];
  }

  get companyLocationOptions() {
    return formatLocationOptions(this.companyLocations, this.intl);
  }

  get remoteLocationOptions() {
    return this.args.connectedConferences || [];
  }

  get locations() {
    return this.filterLocations(this.searchTerm);
  }

  isMatchingTerm(term: string, name?: string): boolean {
    return !!name?.toLowerCase().includes(term.toLowerCase());
  }

  filterLocations(term: string) {
    const remoteLocationOptions = this.remoteLocationOptions.filter(
      (l: ConnectedConferenceModel) => this.isMatchingTerm(term, l.providerName)
    );

    return {
      remoteLocationOptions,
      companyLocationOptions: this.companyLocationOptions,
    };
  }

  locationFunction = trackedFunction(this, async () => {
    const query = { name_or_city: this.searchTerm, page: 1 };
    const locations = await fetchLocations(this.store, query);
    return locations;
  });

  @action
  clearLocation() {
    this.args.onChange(undefined, undefined);
  }

  @action
  locationFilterCallback(event: InputEvent) {
    const term = (event.target as HTMLInputElement).value;
    this.searchTerm = term;
    this.customLocation = term;
  }

  @action
  selectLocation(
    type: LocationType,
    location: LocationModel | ConnectedConferenceModel | string
  ) {
    this.args.onChange(type, location);
  }

  @action
  onCloseDropdown() {
    this.searchTerm = '';
  }
}
