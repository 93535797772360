/* import __COLOCATED_TEMPLATE__ from './column.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';
import DataTableComponent from '../data-table';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';

interface ComponentArgs {
  parent: DataTableComponent;

  customExpandableComponent: string;
  propertyName: string;
  modelName?: string;
  modelIdsPropertyName?: string;
  header: string;
  type: string;
  span?: string;
  colspan?: number;
  digits?: number;
  sortable?: boolean;
  expandable?: boolean;
  blur?: boolean;
  options?: BaseProperty[];
  preloaded?: boolean;
  loadingForSort?: boolean;
  sortPropertyName?: string;
}

export type ColumnData = {
  customExpandableComponent?: string;
  propertyName: string;
  modelName?: string;
  modelIdsPropertyName?: string;
  header: string;
  type: string;
  span?: string;
  colspan?: number;
  digits?: number;
  sortable: boolean;
  expandable: boolean;
  blur: boolean;
  options?: BaseProperty[];
  preloaded: boolean;
  loadingForSort: boolean;
  sortPropertyName?: string;
};

export default class ColumnComponent extends Component<ComponentArgs> {
  @argDefault parent!: DataTableComponent;
  @argDefault customExpandableComponent?: string;
  @argDefault propertyName!: string;
  @argDefault modelName?: string;
  @argDefault modelIdsPropertyName?: string;
  @argDefault header!: string;
  @argDefault type!: string;
  @argDefault span?: string;
  @argDefault colspan?: number;
  @argDefault digits?: number;
  @argDefault sortable = true;
  @argDefault expandable = false;
  @argDefault blur = false;
  @argDefault options?: BaseProperty[];
  @argDefault preloaded = false;
  @argDefault loadingForSort = false;
  @argDefault sortPropertyName?: string;

  get asObject(): ColumnData {
    return {
      customExpandableComponent: this.customExpandableComponent,
      propertyName: this.propertyName,
      modelName: this.modelName,
      modelIdsPropertyName: this.modelIdsPropertyName,
      header: this.header,
      type: this.type,
      span: this.span,
      colspan: this.colspan,
      digits: this.digits,
      sortable: this.sortable,
      expandable: this.expandable,
      blur: this.blur,
      options: this.options,
      preloaded: this.preloaded,
      loadingForSort: this.loadingForSort,
      sortPropertyName: this.sortPropertyName,
    };
  }

  @action
  didInsert() {
    this.parent.addColumn(this);
  }

  @action
  willRemove() {
    this.parent.removeColumn(this);
  }
}
