/* import __COLOCATED_TEMPLATE__ from './question-filter-choice.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class QuestionFilterChoiceComponent extends Component {
  @tracked selectedAlternatives = this.args.filter?.choices || [];

  get alternatives() {
    return get(this.args.question, 'questionData').alternatives;
  }

  @action
  updateChoiceSelection(isChecked, updatedChoice) {
    let newlySelectedChoices;
    if (isChecked) {
      newlySelectedChoices = this.selectedAlternatives.concat(updatedChoice.id);
    } else {
      newlySelectedChoices = this.selectedAlternatives.filter(
        (choice) => choice !== updatedChoice.id
      );
    }

    this.args.updateFilter({ choices: newlySelectedChoices });
    this.selectedAlternatives = newlySelectedChoices;
  }
}
