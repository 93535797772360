/* import __COLOCATED_TEMPLATE__ from './fieldset.hbs'; */
import Component from '@glimmer/component';

type Args = {
  columns?: 1 | 2;
};

export default class FormFieldsetComponent extends Component<Args> {
  get columns() {
    return parseInt(this.args.columns ?? 1);
  }
}
