/* import __COLOCATED_TEMPLATE__ from './text.hbs'; */
import Base from './base';
import { action } from '@ember/object';

const KEY_CODE_ENTER = 'Enter';
const KEY_CODE_ESCAPE = 'Escape';

export default class extends Base {
  @action
  handleKeyup(dropdown, event) {
    if ([KEY_CODE_ESCAPE, KEY_CODE_ENTER].includes(event.code)) {
      if (event.code === KEY_CODE_ENTER) {
        this.args.onChange();
      }

      if (event.code === KEY_CODE_ESCAPE) {
        this.args.model.rollbackAttributes();
      }

      dropdown.toggleOpen();
      event.preventDefault();
    }
  }
}
