/* import __COLOCATED_TEMPLATE__ from './add-reaction.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ReactionsAddReactionComponent extends Component {
  @tracked showEmojis = false;

  @action
  toggleShowEmojis() {
    this.showEmojis = !this.showEmojis;
  }

  @action
  handleReaction(emoji) {
    this.args.handleReaction(emoji);
    this.toggleShowEmojis();
  }

  @action
  onClickOutside() {
    this.showEmojis = false;
  }
}
