/* import __COLOCATED_TEMPLATE__ from './self-schedule.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  SelfScheduleClass,
  MeetingEventClass,
} from 'teamtailor/classes/meetings';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import IntlService from 'ember-intl/services/intl';
import TimeFormatService from 'teamtailor/services/time-format';

interface Args {
  value: SelfScheduleClass;
  event: MeetingEventClass;
  tzid: string;
  onChange?: () => void;
}

type Option = {
  value: number;
  format: string;
};

export default class MeetingField extends Component<Args> {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  get event(): MeetingEventClass {
    return this.args.event;
  }

  get durationOptions(): Option[] {
    return [
      {
        value: 15,
        format: this.intl.t('components.meetings.durations.duration1'),
      },
      {
        value: 30,
        format: this.intl.t('components.meetings.durations.duration2'),
      },
      {
        value: 45,
        format: this.intl.t('components.meetings.durations.duration3'),
      },
      {
        value: 60,
        format: this.intl.t('components.meetings.durations.duration4'),
      },
      {
        value: 90,
        format: this.intl.t('components.meetings.durations.duration5'),
      },
      {
        value: 120,
        format: this.intl.t('components.meetings.durations.duration6'),
      },
      {
        value: 150,
        format: this.intl.t('components.meetings.durations.duration7'),
      },
    ];
  }

  get formattedDuration(): string {
    return this.getTranslationFromOption(
      this.durationOptions,
      this.args.value.duration
    );
  }

  get minDate(): Date {
    return moment().add(1, 'days').startOf('day').toDate();
  }

  get rangeLabel(): string {
    if (this.args.value.slotRules?.dateRanges) {
      const from = this.args.value.slotRules.dateRanges.firstObject?.from;
      const to = this.args.value.slotRules.dateRanges.firstObject?.to;
      return this.intl.t('components.meetings.event_scheduler.number_of_days', {
        days: Math.round(moment(to).diff(from, 'days', true)),
      });
    }

    return '';
  }

  getTranslationFromOption(options: Option[], value?: number): string {
    const option = options.find((o) => o.value === value);
    return option ? option.format : 'Unknown';
  }

  @action
  setDuration(duration: Option): void {
    if (this.event.selfSchedule) {
      this.event.selfSchedule.duration = duration.value;
    }

    if (this.args.onChange) {
      this.args.onChange();
    }
  }
}
