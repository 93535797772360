/* import __COLOCATED_TEMPLATE__ from './video-meeting-join-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class VideoMeetingJoinButton extends Component {
  @service videoMeeting;
  @service flashMessages;
  @service intl;
  @service router;
  @service flipper;
  @service current;

  @action
  joinMeeting() {
    if (this.videoMeeting.isJoined) {
      this.flashMessages.error(
        this.intl.t(
          'components.calendar.video_meeting_join_button.in_another_meeting'
        )
      );
      return;
    }

    const { meetingUrl, isTeamtailor } = this.args;

    if (this.joinInterview && isTeamtailor) {
      this.goToVideoInterview();
    } else {
      this.openMeetingWindow(meetingUrl);
    }
  }

  openMeetingWindow(meetingUrl) {
    window.open(meetingUrl, 'null', 'fullscreen="yes&internal=true"');
  }

  get joinInterview() {
    return get(this.flipper, 'video_interview_layout') && this.candidateId;
  }

  get candidateId() {
    return this.args.candidate?.id || this.args.modelList[0]?.candidateId;
  }

  get candidate() {
    return this.args.candidate || this.args.modelList[0].candidate;
  }

  get currentUser() {
    return this.current.user;
  }

  async getInterview(interviewKitId, jobId) {
    const candidate = await this.candidate;

    return candidate.interviews.find((i) => {
      return (
        i.belongsTo('interviewKit').id() === interviewKitId &&
        get(i, 'jobId') === jobId &&
        get(i, 'userId') === this.currentUser.id
      );
    });
  }

  @action
  async goToVideoInterview() {
    const meetingJob = await get(this.args.meetingEvent, 'job');
    const meetingInterviewKit = await get(
      this.args.meetingEvent,
      'interviewKit'
    );
    const interview = meetingInterviewKit
      ? await this.getInterview(meetingInterviewKit.id, meetingJob.id)
      : null;
    const tabName = meetingInterviewKit ? 'interview-feed' : 'activity-feed';
    const interviewUrl = this.router.urlFor(
      'video-meeting.candidate',
      this.args.videoMeetingId,
      this.candidateId,
      {
        queryParams: {
          internal: true,
          tab: tabName,
          interviewFeedQuery: meetingInterviewKit
            ? JSON.stringify({
                jobId: interview ? undefined : get(meetingJob, 'id'),
                interviewKitId: get(meetingInterviewKit, 'id'),
                interviewId: interview?.id,
              })
            : undefined,

          toolbox: null,
        },
      }
    );

    window.open(interviewUrl, '_blank');
  }
}
