/* import __COLOCATED_TEMPLATE__ from './assign.hbs'; */
import BaseComponent from './base';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AssignComponent extends BaseComponent {
  @tracked assignToJob;

  @action
  assignCandidates() {
    let promise = this.postAction(
      'bulk_assign',
      this.postData({ job_id: get(this, 'assignToJob.id') })
    );

    promise.then(() => {
      this.store.peekAll('candidate').forEach((candidate) => {
        if (candidate.hasMany('activities').value() !== null) {
          get(candidate, 'jobApplications').reload();
        } else {
          candidate.reload();
        }
      });
    });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
