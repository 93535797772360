/* import __COLOCATED_TEMPLATE__ from './alert-message.hbs'; */
import Component from '@glimmer/component';

const ICONS = {
  info: 'circle-info',
  error: 'circle-exclamation',
  warning: 'triangle-exclamation',
  success: 'circle-check',
  default: 'circle-info',
};

type IconTypes = keyof typeof ICONS;

type AlertMessageSignature = {
  Args: {
    showIcon?: boolean;
    type?: IconTypes;
    noMargin?: boolean;
    icon?: string;
  };
};

export default class AlertMessageComponent extends Component<AlertMessageSignature> {
  get hideIcon() {
    return this.args.showIcon === false;
  }

  get type() {
    return this.args.type || 'info';
  }

  get alertClass() {
    return `alert-${this.type} `;
  }

  get nonModuleClasses() {
    return `${this.alertClass}${this.args.noMargin ? ' m-0' : ''}`;
  }

  get icon() {
    return this.args.icon || this.type in ICONS
      ? ICONS[this.type as IconTypes]
      : ICONS.default;
  }
}
