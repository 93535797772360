/* import __COLOCATED_TEMPLATE__ from './date-picker-button.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class InsightsDatePickerButton extends Component {
  @service intl;
  @service current;

  dropdownRef = null;

  @tracked showDateRange = false;

  get minDate() {
    const companyWentLiveAt = get(this.current.company, 'went_live_at');

    if (isPresent(companyWentLiveAt) && isPresent(this.args.createdDate)) {
      return moment
        .min(moment(companyWentLiveAt), moment(this.args.createdDate))
        .toDate();
    } else if (isPresent(companyWentLiveAt)) {
      return companyWentLiveAt;
    } else {
      return this.args.createdDate;
    }
  }

  get label() {
    const startDate = moment(this.args.startDate);
    const endDate = moment(this.args.endDate);
    const endedYesterday = moment().subtract(1, 'day').isSame(endDate, 'day');

    const difference = Math.abs(startDate.diff(endDate));
    const durationAsDays = Math.round(moment.duration(difference).asDays());

    if (endedYesterday && [6, 27].indexOf(durationAsDays) > -1) {
      return this.intl.t('insights.date_picker_button.last_x_days', {
        count: durationAsDays + 1,
      });
    } else {
      return `${startDate.format('YYYY-MM-DD')} - ${endDate.format(
        'YYYY-MM-DD'
      )}`;
    }
  }

  @action
  handleRelativeDates(numberOfDays) {
    const endDate = moment().subtract(1, 'day').toDate();
    const startDate = moment().subtract(numberOfDays, 'day').toDate();
    this.args.onUpdate(startDate, endDate);
    this.dropdownRef.close();
  }

  @action
  handleSinceDate(date) {
    const startDate = moment(date).toDate();
    const endDate = moment().subtract(1, 'day').toDate();
    this.args.onUpdate(startDate, endDate);
    this.dropdownRef.close();
  }

  @action
  setDropdownRef(ref) {
    this.dropdownRef = ref;
  }

  @action
  handleCustomDate(startDate, endDate) {
    this.dropdownRef.close();
    this.args.onUpdate(startDate, endDate);
  }
}
