/* import __COLOCATED_TEMPLATE__ from './offline-bar.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { rAF } from 'ember-animated';
import resize from 'ember-animated/motions/resize';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { bind } from '@ember/runloop';

function maybeConvertToDate(value) {
  return value ? new Date(value) : null;
}

export default class OfflineBarComponent extends Component {
  @tracked isOffline = !window.navigator.onLine;

  constructor() {
    super(...arguments);

    window.addEventListener('offline', bind(this, this.handleOffline));
    window.addEventListener('online', bind(this, this.handleOnline));
    this.transition = bind(this, this.transition);
  }

  handleOnline() {
    this.isOffline = false;
  }

  handleOffline() {
    this.wentOfflineAt = new Date().toJSON();
    this.isOffline = true;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  }

  @computedLocalStorage(maybeConvertToDate, 'wentOfflineAtKey')
  wentOfflineAt;

  *transition({ insertedSprites, removedSprites, beacons }) {
    for (let sprite of insertedSprites) {
      if (beacons) {
        sprite.startAtPixel({ x: 0 });
        resize(sprite);
      }
    }

    for (let sprite of removedSprites) {
      sprite.endAtPixel({ x: 0 });
      if (!sprite._finalCumulativeTransform) {
        // Workaround for https://github.com/ember-animation/ember-animated/issues/217
        sprite._finalCumulativeTransform = {};
      }

      yield resize(sprite);
      yield rAF;
      this.wentOfflineAt = null;
    }
  }
}
