/* import __COLOCATED_TEMPLATE__ from './time-format-picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'teamtailor/config/environment';

export default class TimeformatPickerComponent extends Component {
  @service intl;

  timeFormats = config.timeFormats;

  get options() {
    const timeFormats = this.timeFormats.map((timeFormat) => {
      return {
        ...timeFormat,
        name: this.intl.t(
          `settings.general.time_format_option.${timeFormat.namespace}`
        ),
      };
    });

    if (this.args.defaultTimeFormat) {
      return [
        {
          value: null,
          name: this.args.defaultText,
        },
        ...timeFormats,
      ];
    }

    return timeFormats;
  }

  @action
  onSelect(timeFormat) {
    this.args.onSelect?.(timeFormat.value);
  }
}
