/* import __COLOCATED_TEMPLATE__ from './gif.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import Store from '@ember-data/store';
import BaseMediaComponent from './base';

export default class GifMediaComponent extends BaseMediaComponent {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare ttAlert: TtAlertService;

  @action
  async handlePickGiphy(image: { url: string }) {
    this.args.mediaItem.mediaUrl = image.url;
    await this.args.mediaItem.save();
    await this.args.onSave();
    this.showImageLibrary = false;
  }
}
