/* import __COLOCATED_TEMPLATE__ from './group-date.hbs'; */
import Component from '@glimmer/component';

export default class EmployeeDashboardGroupDateComponent extends Component {
  get isNewDate() {
    const { item, all } = this.args;
    const previousIndex = all.indexOf(item) - 1;
    const previousObject = all.objectAt(previousIndex);

    return previousObject?.createdWhen !== item.createdWhen;
  }
}
