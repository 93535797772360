/* import __COLOCATED_TEMPLATE__ from './cv-library.hbs'; */
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import PromotionBaseClass from './promotion-base';

export default class extends PromotionBaseClass {
  @tracked checkoutData;

  @tracked preciseLocation =
    this.args.promotion.cvLibraryPreciseLocation || this.location?.zip;

  @tracked displayLocation =
    this.args.promotion.cvLibraryDisplayLocation || this.location?.fullAddress;

  get location() {
    return (
      get(this, 'args.promotion.location.content') ||
      get(this, 'args.promotion.job.locations.firstObject')
    );
  }

  get cvLibraryIndustries() {
    return this.store.peekAll('promotion-categories/cv-library-industry');
  }

  get cvLibraryJobTypes() {
    return this.store.peekAll('promotion-categories/cv-library-job-type');
  }

  get cvLibrarySalaryPeriods() {
    return this.store.peekAll('promotion-categories/cv-library-salary_period');
  }

  @action onDisplayLocationChange(value) {
    this.args.promotion.cvLibraryDisplayLocation = value;
  }

  @action onPreciseLocationChange(value) {
    this.args.promotion.cvLibraryPreciseLocation = value;
  }

  @action onLocationChange(location) {
    this.args.promotion.cvLibraryDisplayLocation = location.fullAddress;
    this.args.promotion.cvLibraryPreciseLocation = location.zip;
    this.displayLocation = location.fullAddress;
    this.preciseLocation = location.zip;
  }
}
