/* import __COLOCATED_TEMPLATE__ from './manager.hbs'; */
import MediaLibraryManagerBaseComponent from './manager-base';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class MediaLibraryManagerComponent extends MediaLibraryManagerBaseComponent {
  @tracked editingImage = null;

  get queryParams() {
    return {
      tag_id: this.args.selectedTag?.id,
      query: this.query.trim(),
    };
  }

  fetchImages = restartableTask(async () => {
    const images = await this.infinity.model('image', this.queryParams);
    this.images = images;
    this.pickedImages = [];
  });

  @action
  handleImageUpload(image) {
    this.images.unshiftObject(image);
  }

  @action
  handleRemoveImage(image) {
    this.ttAlert.confirm('', () => {
      image.destroyRecord().then(() => {
        this.images.removeObject(image);
        this.pickedImages.removeObject(image);
      });
    });
  }

  @action
  handleEditImage(image) {
    this.editingImage = image;
  }

  @action
  handleCloseEditImageModal() {
    if (this.editingImage) {
      this.editingImage = null;
    }
  }
}
