/* import __COLOCATED_TEMPLATE__ from './user-row.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class UserRow extends Component {
  @service intl;

  get isSelected() {
    if (this.args.isEveryoneRow) {
      return !this.args.isPrivate;
    }

    if (this.isMentioned) return true;

    const { user, selectedUserIds } = this.args;
    return selectedUserIds.includes(get(user, 'id'));
  }

  get inHiringTeam() {
    const { jobTeamMembers, user } = this.args;

    if (!jobTeamMembers) return false;
    return jobTeamMembers.includes(user);
  }

  get isMentioned() {
    if (this.args.isEveryoneRow) return false;

    const { user, mentionedUserIds } = this.args;
    return mentionedUserIds.includes(get(user, 'id'));
  }

  get isDisabled() {
    if (this.args.isEveryoneRow) return false;

    return this.isMentioned || get(this.args.user, 'admin');
  }

  get description() {
    if (this.args.isEveryoneRow) {
      if (this.args.candidate) {
        return this.intl.t(
          'components.permission_modal.anyone_with_candidate_access'
        );
      } else {
        return this.intl.t(
          'components.permission_modal.anyone_with_job_access'
        );
      }
    }

    if (this.isMentioned) {
      return this.intl.t('components.permission_modal.mentioned_in_note');
    }

    if (
      this.args.recruiterUser &&
      this.args.user &&
      get(this.args.recruiterUser, 'id') === get(this.args.user, 'id')
    ) {
      let out = this.intl.t('components.hiring_team.recruiter');

      if (get(this.args.user, 'admin')) {
        out += ` + ${this.intl.t('user.admin')}`;
      }

      return out;
    }

    if (get(this.args.user, 'admin')) {
      return this.intl.t('user.admin');
    }

    if (this.inHiringTeam) {
      return this.intl.t('user.category.hiring_team');
    }

    if (get(this.args.user, 'externalRecruiter')) {
      return this.intl.t('jobs.edit.external_recruiters.recruiter_label');
    }

    return null;
  }

  @action
  onClick() {
    const {
      user,
      isEveryoneRow,
      isPrivate,
      onMakePublic,
      onMakePrivate,
      onClick,
    } = this.args;

    if (this.isDisabled) return false;

    if (isEveryoneRow) {
      return !isPrivate ? onMakePrivate() : onMakePublic();
    }

    return onClick(get(user, 'id'));
  }
}
