/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';

export default class extends Component {
  @service blockAdjustments;

  @tracked
  dropdownOpen = false;

  @tracked
  leftAlignedDropdown = true;

  @tracked
  selectedColor = this.args.displayValue;

  get isDisabled() {
    return (
      this.blockAdjustments.editingAttribute &&
      this.blockAdjustments.editingAttribute !== this.args.attribute
    );
  }

  @action
  toggleOpen() {
    if (!this.isDisabled && this.dropdownOpen) {
      this.close();
    } else if (!this.isDisabled && !this.dropdownOpen) {
      this.open();
    }
  }

  @action
  close() {
    this.dropdownOpen = false;
    this.blockAdjustments.editingAttribute = null;
  }

  @action
  open() {
    this.dropdownOpen = true;
    this.blockAdjustments.editingAttribute = this.args.attribute;
  }

  @action
  initDropdown(element) {
    const elementRect = element.getBoundingClientRect();
    const parentRect = element.parentElement.getBoundingClientRect();

    this.leftAlignedDropdown =
      parentRect.right - elementRect.right > elementRect.width / 2;

    document.addEventListener(this.blockAdjustments.closeAllEvent, this.close);
  }

  @action
  destroyEvents() {
    document.removeEventListener(
      this.blockAdjustments.closeAllEvent,
      this.close
    );
  }

  @action
  handleOutsideClick() {
    if (!this.args.disableOutsideClick) {
      next(() => {
        this.close();
      });
    }
  }
}
