/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { CandidateModel, JobModel, ReferralModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

interface Args {
  candidate: CandidateModel;
  job?: JobModel;
}

export default class extends Component<Args> {
  @tracked revealAnonymousDetails = false;

  get referrals() {
    return this.args.candidate.referrals
      .toArray()
      .filter((referral: ReferralModel) => {
        const jobId = get(this.args.job, 'id');
        const referralJobId = get(referral.job, 'id');

        if (!jobId || !referralJobId) {
          return true;
        }

        return jobId === referralJobId;
      });
  }

  @action
  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
  }
}
