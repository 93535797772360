/* import __COLOCATED_TEMPLATE__ from './mentioned-activities.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import ActivityModel from 'teamtailor/models/activity';

type Args = {
  jobId: string;
  actionIds: string[];
  isLoading?: boolean;
};

export default class MentionedActivitiesComponent extends Component<Args> {
  @service declare store: Store;

  resolveMentionedActivityResponses = trackedFunction(this, async () => {
    const mentionedActivities: ActivityModel[] = await this.store.query(
      'activity',
      {
        job_id: this.args.jobId,
        action_ids: this.args.actionIds,
        collaborate: true,
      }
    );
    return mentionedActivities;
  });

  get isLoading() {
    return (
      this.args.isLoading ||
      this.resolveMentionedActivityResponses.value === null
    );
  }

  get mentionedActivities(): ActivityModel[] {
    return this.resolveMentionedActivityResponses.value || [];
  }

  get sortedMentionedActivities(): ActivityModel[] {
    return [...this.mentionedActivities].sort(
      (a: ActivityModel, b: ActivityModel) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
    );
  }
}
