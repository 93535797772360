/* import __COLOCATED_TEMPLATE__ from './user-online-beacon.hbs'; */
import Component from '@glimmer/component';
import {
  ALLOWED_SIZES,
  AllowedSizes,
  DEFAULT_SIZE,
  DEFAULT_POSITION,
  VALUES_BASED_ON_USER_IMAGE_SIZE,
} from 'teamtailor/constants/user-online-beacon';
import { AllowedSizes as UserImageSizes } from 'teamtailor/constants/avatar';
import { verifyArg } from 'teamtailor/utils/verify-arg';

interface Args {
  size?: AllowedSizes;
  userImageSize?: UserImageSizes;
  position: string;
}

export default class UserOnlineBeacon extends Component<Args> {
  get valuesBasedOnUserImage(): { size: string; position: string } {
    return VALUES_BASED_ON_USER_IMAGE_SIZE[this.args.userImageSize!];
  }

  get size(): AllowedSizes {
    if (this.args.size) {
      verifyArg(
        this.args.size,
        ALLOWED_SIZES,
        'UserOnlineBeacon @size',
        DEFAULT_SIZE
      );

      return this.args.size;
    }

    if (this.args.userImageSize) {
      return this.valuesBasedOnUserImage.size as AllowedSizes;
    }

    return DEFAULT_SIZE;
  }

  get position(): string {
    if (this.args.position) {
      return this.args.position;
    }

    if (this.args.userImageSize) {
      return this.valuesBasedOnUserImage.position;
    }

    return DEFAULT_POSITION;
  }
}
