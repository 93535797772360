/* import __COLOCATED_TEMPLATE__ from './candidates.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import PerfectScrollbar from 'perfect-scrollbar';

import JobModel from 'teamtailor/models/job';
import FlashMessageService from 'teamtailor/services/flash-message';
import StageListHelperService from 'teamtailor/services/stage-list-helper';
import CandidateSuggestion from 'teamtailor/models/candidate-suggestion';

interface Args {
  job: JobModel;
}

export default class extends Component<Args> {
  @service declare flashMessages: FlashMessageService;
  @service declare stageListHelper: StageListHelperService;

  @action
  async addToJob(candidateSuggestion: CandidateSuggestion) {
    this.stageListHelper.lastSuggestedCandidateId =
      candidateSuggestion.candidate.id;
    return candidateSuggestion.addToJob();
  }

  @action
  discard(candidateSuggestion: CandidateSuggestion) {
    return candidateSuggestion.discard();
  }

  setupScrollbar = modifier((element: HTMLElement) => {
    new PerfectScrollbar(element, {
      wheelPropagation: true,
      suppressScrollX: true,
      minScrollbarLength: 12,
    });
  });

  get anonymous() {
    return this.args.job.stages.find(
      (stage) => stage.name === 'Inbox' && stage.anonymous
    );
  }
}
