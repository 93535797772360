/* import __COLOCATED_TEMPLATE__ from './trigger-share-form.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { dropTask } from 'ember-concurrency';
export default class TriggerShareFormComponent extends Component {
  @service store;
  @service intl;
  @service current;

  @tracked selectedResponse;

  @action
  didInsert() {
    if (this.args.model.body === undefined) {
      this.args.model.subject = this.intl.t(
        'components.trigger_share_form.help_review_candidates'
      );
      this.args.model.body = this.intl.t(
        'components.trigger_share_form.share_body'
      );
    }

    if (this.args.model.shareOptions === undefined) {
      this.args.model.shareOptions = {
        personal_information: true,
        resume: true,
        pitch: true,
        answers: true,
        locations: true,
        linked_in: true,
        recommendations: true,
        cover_letter: true,
        interview_kits: true,
        all_candidate_documents: true,
        all_internal_documents: true,
        restricted: false,
        anonymous: false,
        password: '',
      };
    }
  }

  createInterviewKitToggles = dropTask(async () => {
    const { model } = this.args;
    const job = get(model, 'job');
    const excludedIds =
      get(model.shareOptions, 'excluded_picked_interview_kit_ids') || [];

    return Promise.all(
      this.pickedInterviewKits.map(async (pickedInterviewKit) => {
        const interviewKit = await pickedInterviewKit.interviewKit;
        return {
          id: pickedInterviewKit.id,
          label: interviewKit.name,
          checked: !excludedIds.includes(pickedInterviewKit.id),
        };
      })
    );
  });

  onAdditionalOptionsOpen = modifier(async () => {
    const { model } = this.args;
    const shareOptions = get(model, 'shareOptions');

    if (!shareOptions.interview_kits) {
      return;
    }

    if (shareOptions && !shareOptions.interview_kit_toggles) {
      const toggles = await this.createInterviewKitToggles.perform();
      this.updateShareOption('interview_kit_toggles', toggles);
    }
  });

  get cannedResponses() {
    const job = get(this.args.model, 'job');

    let context = null;
    if (job) {
      context = get(job, 'assetStructureContext');
    } else {
      const departmentId = get(this.current.user, 'departmentId');
      context = {
        department: departmentId,
        role: null,
        region: null,
        location: null,
      };
    }

    return this.current.company.fetchGroupedCannedResponsesByContext(context);
  }

  get model() {
    return this.args.model;
  }

  get pickedInterviewKits() {
    const job = get(this.model, 'job');
    const pickedKits = get(job, 'jobDetail.pickedInterviewKits') || [];

    return pickedKits.filter((pickedInterviewKit) => {
      return !get(pickedInterviewKit, 'isHidden');
    });
  }

  @action
  didSelectCannedResponse(response) {
    this.args.model.subject = response.subject;
    this.args.model.body = response.body;
    this.selectedResponse = response;
  }

  @action
  togglePasswordProtection() {
    this.updateShareOption(
      'restricted',
      !this.args.model.shareOptions.restricted
    );
  }

  @action
  setPassword(password) {
    this.updateShareOption('password', password);
  }

  @action
  onPasswordChange(event) {
    this.updateShareOption('password', event.target.value);
  }

  @action
  toggleAnonymous() {
    this.updateShareOption('anonymous', !this.model.shareOptions.anonymous);
  }

  @action
  updateShareOption(option, value) {
    this.args.model.shareOptions = {
      ...this.args.model.shareOptions,
      [option]: value,
    };
  }
}
