/* import __COLOCATED_TEMPLATE__ from './hellowork.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked checkoutData;
  helloworkContractTypes = [
    'CDI',
    'CDD',
    'Stage',
    'Travail temporaire',
    'Franchise',
    'Indépendant',
    'Alternance',
  ];

  helloworkExperiences = ['1 an', '1 à 7 ans', '+7 ans'];

  helloworkEducationLevels = [
    'BEP/CAP',
    'Agent de maîtrise/Bac +3/4',
    'Employé/Opérateur/Ouvrier Spe/Bac',
    'Technicien/Employé Bac +2',
    'Cadre dirigeant',
    'Ingénieur/Cadre/Bac +5',
  ];
}
