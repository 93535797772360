/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import TimeFormatService from 'teamtailor/services/time-format';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';

interface Arguments {
  minDate: Date;
  startDate?: Date;
  endDate?: Date;
  onUpdate?: (startDate?: Date, endDate?: Date) => void;
}

const ANALYTICS_DEMO_CUTOFF_DATE = '2024-10-11';

export default class DatePickerComponent extends Component<Arguments> {
  @service declare timeFormat: TimeFormatService;
  @service declare flipper: FlipperService;

  @tracked showDatePicker = false;
  @tracked flatpickrRef?: any;

  @tracked startDate?: Date;
  @tracked endDate?: Date;
  @tracked popoverStyles: any = {};

  get cutoffDate(): Date {
    if (get(this.flipper, 'analytics_demo_customer')) {
      return moment(ANALYTICS_DEMO_CUTOFF_DATE).toDate();
    }

    return moment().subtract(1, 'day').toDate();
  }

  get minDate(): Date {
    return moment(this.args.minDate).startOf('day').toDate();
  }

  @action
  calculatePosition(trigger: HTMLElement): void {
    const { top, left, width, height } = trigger.getBoundingClientRect();
    const DATE_PICKER_WIDTH = 740;

    const style = `
      left: ${left + width - DATE_PICKER_WIDTH}px;
      top: ${top + window.pageYOffset + height + 4}px;
      position: absolute;`;
    this.popoverStyles = style;
  }

  @action
  handleUpdate([startDate, endDate]: Date[]) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  handleSetSinceStart() {
    const startDate = moment(this.args.minDate).toDate();
    const endDate = moment(this.cutoffDate).format('YYYY-MM-DD');
    this.flatpickrRef.setDate([startDate, endDate], true, 'Y-m-d');
  }

  @action
  handleSetLastDays(length: number) {
    const endDate = moment(this.cutoffDate).format('YYYY-MM-DD');
    const startDate = moment(this.cutoffDate)
      .subtract(length - 1, 'days')
      .format('YYYY-MM-DD');

    this.flatpickrRef.setDate([startDate, endDate], true, 'Y-m-d');
  }

  @action
  setDefaultDates(_element: HTMLElement, [startDate, endDate]: Date[]): void {
    if (get(this.flipper, 'analytics_demo_customer')) {
      this.startDate = moment(this.cutoffDate).subtract(28, 'days').toDate();
      this.endDate = this.cutoffDate;
    } else {
      this.startDate = startDate;
      this.endDate = endDate;
    }
  }

  @action
  handleReady(_date: any, _str: any, instance: any) {
    this.flatpickrRef = instance;
  }

  @action
  handleDone() {
    const { startDate, endDate } = this;
    this.args.onUpdate?.(startDate, endDate);
  }

  @action
  toggleShowDatePicker() {
    if (this.args.onUpdate) {
      this.showDatePicker = !this.showDatePicker;
    }
  }
}
