/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import Component from '@glimmer/component';
import { DEFAULT_SIZE, SUPPORTED_SIZES } from 'teamtailor/constants/modal';
import { argDefault } from 'teamtailor/utils/arg-default';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { CoreModalBaseApi } from './modal/base';
import { tracked } from '@glimmer/tracking';

export type CoreModalComponentSize =
  | 'small'
  | 'medium'
  | 'large'
  | 'fullscreen';

export interface CoreModalComponentArgs {
  size?: CoreModalComponentSize;
  showCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
  closeOnEscape?: boolean;
  isOpen?: boolean;
  boxWrapperName?: string;
  fullHeight?: boolean;
  onOpen?: (el?: HTMLElement) => void;
  onClose?: () => Promise<Transition> | Transition | undefined;
  onMainScroll?: (e: Event) => void;
  onRegister?: (modalBaseApi: CoreModalBaseApi) => void;
  defaultTransitionIn?: boolean;
}

export default class CoreModalComponent extends Component<CoreModalComponentArgs> {
  @argDefault showCloseButton = true;
  @argDefault defaultTransitionIn = true;
  @argDefault fullHeight = false;

  @tracked modalBaseApi?: CoreModalBaseApi;

  get size() {
    return verifyArg(
      this.args.size,
      SUPPORTED_SIZES,
      'Core::Modal @size',
      DEFAULT_SIZE
    );
  }

  @action onOpen(el: HTMLElement) {
    this.args.onOpen?.(el);
  }

  @action onRegisterModalBase(modalBaseApi: CoreModalBaseApi) {
    this.modalBaseApi = modalBaseApi;

    this.args.onRegister?.(this.modalBaseApi);
  }

  @action
  handleMainScroll(ev: Event) {
    if (ev.target instanceof HTMLElement) {
      this.args.onMainScroll?.(ev);
    }
  }
}
