/* import __COLOCATED_TEMPLATE__ from './ai-block.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface WysiwygEditorAiBlockArgs {
  onAskAiToWriteBlock: (searchTerm: string | undefined) => void;
}

const draftAiPromptMap = {
  blog_post: 'Write a blog post about ',
  outline: 'Write an outline about ',
  paragraph: 'Write a paragraph about ',
  list: 'Write a list about ',
} as const;

export default class WysiwygEditorAiBlock extends Component<WysiwygEditorAiBlockArgs> {
  @service declare intl: IntlService;
  @tracked searchTerm?: string;
  @tracked searchInputEl?: HTMLInputElement;

  @action handleDraftClick(type: keyof typeof draftAiPromptMap) {
    this.searchTerm = draftAiPromptMap[type];
    this.searchInputEl?.focus();
  }

  @action handleSearchInput(event: KeyboardEvent) {
    this.searchTerm = (event.target as HTMLInputElement | null)?.value;
  }

  @action handleSearchButtonClick() {
    if (this.searchTerm) {
      this.args.onAskAiToWriteBlock(this.searchTerm);
    }
  }

  @action handleEnterKeydown(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.handleSearchButtonClick();

    return false;
  }
}
