/* import __COLOCATED_TEMPLATE__ from './connected-conference-list.hbs'; */
import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias, filterBy } from '@ember/object/computed';
import { v1 as uuid } from 'ember-uuid';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service current;
  @service flipper;

  @tracked authUrl;

  @alias('args.cronofyAuthorization') cronofyAuthorization;
  @alias('args.loadConnectedConferences') loadConnectedConferences;

  @filterBy('args.connectedConferences', 'isConnected', true)
  connectedConferences;

  @filterBy('args.connectedConferences', 'isConnected', false)
  disconnectedConferences;

  @filterBy('connectedConferences', 'isIncluded', true)
  includedConferences;

  @filterBy('connectedConferences', 'isIncluded', false)
  nonIncludedConferences;

  constructor() {
    super(...arguments);
    set(this, 'eventId', uuid());
    this.loadConnectedConferences();
  }

  get user() {
    return this.current.user;
  }

  @action async getAuthUrl(authPopup) {
    const response =
      await this.cronofyAuthorization.getConnectedConferenceRedirectUrl({
        userId: get(this, 'user.id'),
        eventId: authPopup.eventId,
      });
    this.authUrl = response.url;
  }

  @action
  getRedirectUrl(authPopup) {
    authPopup.openUrl(this.cronofyAuthorization);
  }
}
