/* import __COLOCATED_TEMPLATE__ from './stories.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import StoryModel from 'teamtailor/models/story';

export default class StoriesWidget extends WidgetClass {
  @service declare store: Store;
  @tracked stories: StoryModel[] = [];

  get hasStories() {
    return isPresent(this.stories);
  }

  getStories = task(async () => {
    this.stories = await this.store.query('story', { limit: 5 });
  });

  @action
  handleDidInsertWidget() {
    this.getStories.perform();
  }
}
