/* import __COLOCATED_TEMPLATE__ from './nps-breakdown.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import IntlService from 'ember-intl/services/intl';
import {
  NpsDistributionRow,
  fetch,
} from 'teamtailor/classes/analytics/report-nps-distribution';
import { get } from 'teamtailor/utils/get';
import InsightsExportService from 'teamtailor/services/insights-export';
import Current from 'teamtailor/services/current';
import DateRange from 'teamtailor/utils/date-range';
import AnalyticsService from 'teamtailor/services/analytics';
import { action } from '@ember/object';

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'number',
    propertyName: 'promotersCount',
    headerKey: 'insights.widgets.nps_breakdown.promoters',
  },
  {
    type: 'number',
    propertyName: 'passivesCount',
    headerKey: 'insights.widgets.nps_breakdown.passives',
  },
  {
    type: 'number',
    propertyName: 'detractorsCount',
    headerKey: 'insights.widgets.nps_breakdown.detractors',
  },
];

type Args = {
  data: NpsDistributionRow;
};

export default class InsightsWidgetsNpsScore extends Component<Args> {
  @service declare intl: IntlService;
  @service declare analytics: AnalyticsService;
  @service declare insightsExport: InsightsExportService;
  @service declare current: Current;

  get breakdown() {
    const promotersCount = get(this.args.data, 'promotersCount');
    const detractorsCount = get(this.args.data, 'detractorsCount');
    const passivesCount = get(this.args.data, 'passivesCount');

    const legendNameAndCount = (key: string, count: number) => {
      return {
        name: this.intl.t(`insights.widgets.nps_score.${key}`),
        value: count,
        label: key,
      };
    };

    return [
      legendNameAndCount('promoters', promotersCount),
      legendNameAndCount('detractors', detractorsCount),
      legendNameAndCount('passives', passivesCount),
    ];
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.widgets.nps_breakdown.title',
      `nps`,
      EXPORT_TABLE_COLUMNS,
      this.fetchExportData,
      new DateRange(this.analytics.startDate, this.analytics.endDate),
      {
        sortKey: 'date',
        sortDirection: 'desc',
        createdAt: get(this.current.company, 'went_live_at'),
      }
    );
  }

  @action
  async fetchExportData(dateRange: DateRange) {
    const container = getOwner(this);
    const { promotersCount, detractorsCount, passivesCount } = await fetch(
      container,
      { dateRange }
    );
    return [{ promotersCount, detractorsCount, passivesCount }];
  }
}
