/* import __COLOCATED_TEMPLATE__ from './move-to-stage.hbs'; */
import BaseComponent from './base';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { get } from 'teamtailor/utils/get';

export default class MoveToStageComponent extends BaseComponent {
  @service confetti;
  @service permissions;
  @tracked stage = null;
  @tracked isShowingRestrictCandidateConfirmation = false;

  @computedLocalStorage(Boolean, 'hideRestrictionWarningKey', false)
  hideRestrictionWarning;

  get hideRestrictionWarningKey() {
    return `hideRestrictionWarning-Bulk-Move`;
  }

  @action
  moveToStage() {
    let showWarningBeforeMove =
      !this.hideRestrictionWarning &&
      get(this.stage, 'triggerRestricts').length &&
      !this.permissions.has('candidate/access_restricted');

    if (showWarningBeforeMove) {
      this.isShowingRestrictCandidateConfirmation = true;
    } else {
      this.moveToStageConfirmed();
    }
  }

  @action
  moveToStageConfirmed() {
    let promise = this.postAction(
      'bulk_move_to_stage',
      this.postData({
        job_id: get(this.args, 'job.id'),
        stage_id: get(this, 'stage.id'),
      })
    );

    promise.then(() => {
      let fromStages = [];
      this.args.selectedJobApplications.forEach((jobApplication) => {
        fromStages.push(jobApplication.belongsTo('stage').id());
        jobApplication.reload().then((jobApplication) => {
          if (this.onRejected) {
            set(jobApplication, 'rejectedStage', this.stage);
          } else {
            set(jobApplication, 'activeStage', this.stage);
          }
        });
      });

      fromStages.forEach((stageId) => {
        if (stageId) {
          this.store
            .peekRecord('stage', stageId)
            .decrementCount(this.onRejected);
        }
      });

      this.stage.changeCount(
        this.onRejected,
        this.args.selectedJobApplications.length
      );

      [...new Set([...fromStages, this.stage.id])].forEach((stageId) => {
        this.store.findRecord('stage-job-application-count', stageId);
      });

      if (get(this, 'stage.hired')) {
        this.confetti.show();
      }
    });

    this.isShowingRestrictCandidateConfirmation = false;
    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
