import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import {
  CompanyModel,
  DefaultNotificationConfigModel,
} from 'teamtailor/models';

export default class UserTemplateModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @hasMany('default-notification-config')
  declare defaultNotificationConfigs: AsyncHasMany<DefaultNotificationConfigModel>;

  @attr('string') declare role: string;
  @attr('raw', { defaultValue: () => ['no_access'] })
  declare accessLevels: string[];

  @attr('boolean') declare visible: boolean;
  @attr('boolean') declare hideEmail: boolean;
  @attr('boolean') declare hidePhone: boolean;
  @attr('string') declare signature: string;
  @attr('string') declare locale: string;
  @attr('string') declare timeFormat: string;
  @attr('string') declare timeZone: string;
  @attr('string') declare startOfWeekDay: string;

  get showEmail() {
    return !this.hideEmail;
  }

  get showPhone() {
    return !this.hidePhone;
  }

  set showEmail(value) {
    this.hideEmail = !value;
  }

  set showPhone(value) {
    this.hidePhone = !value;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-template': UserTemplateModel;
  }
}
