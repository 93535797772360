/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import moment from 'moment-timezone';

export default class extends Component {
  @action
  pickDate(value) {
    const date = moment.utc(value).format('YYYY-MM-DD');
    this.args.onChange(date);
  }

  get minDate() {
    return new Date(this.args.field?.min);
  }

  get maxDate() {
    return new Date(this.args.field?.max);
  }

  get value() {
    return new Date(this.args.value);
  }
}
