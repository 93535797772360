/* import __COLOCATED_TEMPLATE__ from './external.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PromotionModalsExternal extends Component {
  @service router;

  @action
  goBack() {
    return this.router.transitionTo('jobs.job.promote');
  }
}
