/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import SectionItemComponent from 'teamtailor/components/content-block/section-item-component';
import SectionItemTestimonialValidations from 'teamtailor/validations/section-item/testimonials';

export default class TestimonialItemBaseComponent extends SectionItemComponent {
  validator = SectionItemTestimonialValidations;

  get forVideo() {
    const { itemType } = this.sectionItem;
    return itemType === 'video';
  }

  @action
  handleUploadButtonClick() {
    if (this.forVideo) {
      this.uploadLabel?.click();
    } else {
      this.showImageLibrary = true;
    }
  }
}
