/* import __COLOCATED_TEMPLATE__ from './searchable-custom-field.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { get } from 'teamtailor/utils/get';

const TEXT_TYPES = ['text', 'phone', 'url'];

export default class SearchableCustomField extends Component {
  @tracked value = this.getValue();

  getValue() {
    return this.customFieldsParams.findBy(
      'custom_field_id',
      this.customField.id
    );
  }

  get customField() {
    return this.args.customField;
  }

  get customFieldsParams() {
    return this.args.customFieldsParams;
  }

  get dateEndValue() {
    const value = this.getValue();
    return value && value.date_query_end;
  }

  get dateStartValue() {
    const value = this.getValue();
    return value && value.date_query_start;
  }

  get textValue() {
    const value = this.getValue();
    return value && value.string_query;
  }

  get boolValue() {
    const value = this.getValue();
    return (value && value.boolean_query) || null;
  }

  get numericValue() {
    const {
      numeric_query_start: start,
      numeric_query_end: end,
      numeric_query: point,
    } = this.value || {};

    if (start) {
      return [start, end];
    } else {
      return point;
    }
  }

  get selectedOptions() {
    const value = this.getValue();
    if (!value || !value.options_query) {
      return [];
    }

    return this.customField.options.filter((option) => {
      return value.options_query.includes(option.id);
    });
  }

  get selectedOption() {
    const value = this.getValue();
    if (!value || !value.options_query) {
      return null;
    }

    return this.customField.options.findBy('id', `${value.options_query}`);
  }

  get isTextBased() {
    return TEXT_TYPES.includes(this.customField.inputType);
  }

  get isMultiSelect() {
    return this.customField.type === 'CustomField::MultiSelect';
  }

  triggerFilterChange(customField) {
    let customFields = this.customFieldsParams.filter(
      (field) => field.custom_field_id !== this.customField.id
    );

    if (customField) {
      customFields.push(customField);
    }

    this.args.onFiltersChange(customFields);
  }

  @action
  onTextChange(e) {
    const { value: text } = e.target;

    const textField = text
      ? {
          custom_field_id: this.customField.id,
          string_query: text,
        }
      : null;

    this.triggerFilterChange(textField);
  }

  @action
  onCheckboxChange(boolValue) {
    const booleanField = boolValue
      ? {
          custom_field_id: this.customField.id,
          boolean_query: boolValue,
        }
      : null;

    this.triggerFilterChange(booleanField);
  }

  @action
  onNumberChange(number) {
    let numberField = null;

    if (number) {
      numberField = {
        custom_field_id: this.customField.id,
      };

      if (Array.isArray(number)) {
        let [numeric_query_start, numeric_query_end] = number;

        if (numeric_query_start) {
          numberField.numeric_query_start = numeric_query_start;
        }

        if (numeric_query_end) {
          numberField.numeric_query_end = numeric_query_end;
        }
      } else {
        numberField.numeric_query = number;
      }
    }

    this.triggerFilterChange(numberField);
  }

  @action
  onDateChange(key, date) {
    const rangeKeys = ['date_query_start', 'date_query_end'];

    const otherCustomFields = this.customFieldsParams.filter(
      (field) => field.custom_field_id !== this.customField.id
    );

    const field = this.customFieldsParams.findBy(
      'custom_field_id',
      this.customField.id
    );

    if (date) {
      this.args.onFiltersChange([
        ...otherCustomFields,
        {
          ...field,
          custom_field_id: this.customField.id,
          [key]: date && moment(date).format('YYYY-MM-DD'),
        },
      ]);
    } else {
      const isStartOrEnd = rangeKeys.includes(key);
      const otherKey = rangeKeys.find((k) => k !== key);
      const otherValue = field[otherKey];
      if (isStartOrEnd && otherValue) {
        // eslint-disable-next-line no-unused-vars
        const { [key]: _value, ...fieldWithoutKey } = field;

        this.args.onFiltersChange([...otherCustomFields, fieldWithoutKey]);
      } else {
        this.args.onFiltersChange(otherCustomFields);
      }
    }
  }

  @action
  onSelectOptions(option) {
    let options = [];
    if (this.selectedOptions.length) {
      options = this.selectedOptions;
      const existingOption = this.selectedOptions.findBy(
        'value',
        get(option, 'value')
      );
      if (existingOption) {
        options.removeObject(existingOption);
      } else {
        options.pushObject(option);
      }
    } else {
      options = [option];
    }

    const customFieldOptions = options.length
      ? {
          custom_field_id: this.customField.id,
          options_query: options.mapBy('id'),
        }
      : null;
    this.triggerFilterChange(customFieldOptions);
  }

  @action
  onSelectOption(option) {
    const customFieldOption = option
      ? {
          custom_field_id: this.customField.id,
          options_query: option.id,
        }
      : null;

    this.triggerFilterChange(customFieldOption);
  }
}
