/* import __COLOCATED_TEMPLATE__ from './visualization.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import AnalyticsService from 'teamtailor/services/analytics';
import Settings, {
  formatRows,
  sortRows,
  limitRows,
} from 'teamtailor/utils/insights/reports/settings';
import InsightsReport from 'teamtailor/models/insights-report';
import {
  CombinedQueryResponse,
  CombinedTypeResponse,
  EventQueryResponse,
  EventTypeResponse,
  PageviewQueryResponse,
  PageviewTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import { DocumentNode } from 'graphql';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import ReportAnalyticsRequest from 'teamtailor/classes/analytics/report-analytics-request';
import { getOwner } from '@ember/application';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';
import IntlService from 'ember-intl/services/intl';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import { dasherize } from '@ember/string';
import ModelProperty from 'teamtailor/utils/insights/reports/properties/model-property';
import generateQuery from 'teamtailor/utils/insights/reports/generate-query';
import FilterSettings from 'teamtailor/utils/insights/reports/filter-settings';
import CustomFormatProperty from 'teamtailor/utils/insights/reports/properties/custom-format-property';
import { later, next } from '@ember/runloop';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import uniq from 'teamtailor/utils/uniq';

function formatAndSortRows(
  results: (PageviewTypeResponse | EventTypeResponse | CombinedTypeResponse)[],
  settings: Settings,
  forCSV = false
) {
  const rows = formatRows(results, settings.allSelectedProperties, forCSV);

  return sortRows(rows, settings.sortProperty, settings.sortDirection);
}

function processResults(
  response: AnalyticsRequestResponse | null | undefined,
  settings: Settings,
  excludeSource: string | undefined
) {
  if (response?.combinedQuery?.aggregated.length) {
    return response.combinedQuery.aggregated.map((row) => ({ ...row }));
  }

  const pageviewResults = (response?.pageviewQuery?.aggregated || [])
    .map((pageviewResult: PageviewTypeResponse) => ({ ...pageviewResult }))
    .filter((row) => {
      return !excludeSource || row.source !== excludeSource;
    });

  const results: (PageviewTypeResponse | EventTypeResponse)[] = (
    response?.eventQuery?.aggregated || []
  ).map((eventResult: EventTypeResponse) => ({ ...eventResult }));

  pageviewResults.forEach((pageviewResult: PageviewTypeResponse) => {
    const existingRow = results.find(
      (row: PageviewTypeResponse | EventTypeResponse) => {
        return settings.currentGroupBys.every((groupBy) => {
          return (
            row[groupBy.columnName]?.toString().toLowerCase() ===
            pageviewResult[groupBy.columnName]?.toString().toLowerCase()
          );
        });
      }
    );

    if (existingRow) {
      settings.selectedProperties.forEach((property) => {
        if (property.queryTypes.includes('pageview')) {
          existingRow[property.columnName] =
            pageviewResult[property.columnName];
        }
      });
    } else {
      results.push(pageviewResult);
    }
  });

  return results;
}

interface AnalyticsRequestResponse {
  combinedQuery?: CombinedQueryResponse;
  pageviewQuery?: PageviewQueryResponse;
  eventQuery?: EventQueryResponse;
}

interface ComponentArgs {
  settings: Settings;
  filterSettings: FilterSettings;
  report: InsightsReport;
  properties: BaseProperty[];
  groupAnalytics: boolean;
  onUpdate?: (settings: Settings) => void;
  onRemove?: (settings: Settings) => void;
  handleMove?: (direction: 'up' | 'down') => void;
  sharedReport?: string;
  onLoaded?: () => void;
}

export default class VisualizationComponent extends Component<ComponentArgs> {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare current: Current;

  @tracked exportIsLoading = false;
  @tracked modelsLoaded = false;
  @tracked modelsLoading = false;
  @tracked showingDataTable = false;
  @tracked dataTableElement: HTMLElement | null = null;
  @tracked page = 1;
  pageSize = 20;
  currentQuery: DocumentNode | undefined;
  currentDateSpan: string | undefined;

  get name() {
    let name: string | undefined = this.currentSettings.getDisplayName(
      this.intl
    );

    if (!name) {
      return this.intl.t('insights.reports.builder.untitled_chart', {
        chartType: this.intl.t(
          `insights.reports.builder.add_chart.${this.chartType?.name}`
        ),
      });
    }

    if ((name.length || 0) > 100) {
      name = `${name.substring(0, 97)}...`;
    }

    return name;
  }

  get isLoading() {
    return (
      this.fetchQuery.isRunning || this.modelsLoading || this.exportIsLoading
    );
  }

  get chartType() {
    return this.currentSettings.chartType;
  }

  get isTable() {
    return this.chartType && this.chartType.name === 'table';
  }

  get filterSettings() {
    return this.args.filterSettings;
  }

  get currentMode() {
    return !this.args.sharedReport &&
      (this.currentSettings.editing || !this.currentSettings.valid)
      ? 'edit'
      : 'show';
  }

  get showDropdown() {
    return this.currentMode === 'show' && !this.args.sharedReport;
  }

  get report() {
    return this.args.report;
  }

  get properties() {
    return this.args.properties;
  }

  get currentSettings() {
    return this.args.settings;
  }

  get totalDataCount() {
    const combinedQueryCount =
      this.fetchQuery.lastSuccessful?.value?.combinedQuery
        ?.totalAggregatedCount || 0;
    const eventQueryCount =
      this.fetchQuery.lastSuccessful?.value?.eventQuery?.totalAggregatedCount ||
      0;
    const pageviewQueryCount =
      this.fetchQuery.lastSuccessful?.value?.pageviewQuery
        ?.totalAggregatedCount || 0;

    if (
      combinedQueryCount > eventQueryCount &&
      combinedQueryCount > pageviewQueryCount
    ) {
      return combinedQueryCount;
    } else if (eventQueryCount > pageviewQueryCount) {
      return eventQueryCount;
    }

    return pageviewQueryCount;
  }

  get rows() {
    return processResults(
      this.fetchQuery.lastSuccessful?.value,
      this.currentSettings,
      this.excludeSource
    );
  }

  get sortedRows() {
    const rows = formatAndSortRows(this.rows, this.currentSettings);
    return limitRows(rows, this.currentSettings.limit);
  }

  get sortedRowsForCSV() {
    return formatAndSortRows(this.rows, this.currentSettings, true);
  }

  get query(): DocumentNode | undefined {
    return generateQuery(
      this.currentSettings,
      this.filterSettings,
      this.isTable ? this.page - 1 : undefined,
      this.isTable ? this.pageSize : undefined
    );
  }

  get excludeSource() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.current.company) {
      const cname = get(get(this.current.company, 'domain'), 'cname');
      return cname
        ? cname
        : new URL(this.current.company.subdomainUrl).hostname;
    }
  }

  @action
  async updateModels() {
    if (this.modelsLoading || !this.fetchQuery.lastSuccessful?.value) {
      await get(this.current.company, 'domain');
      return;
    }

    this.eagerLoadRelatedModelsFor(this.rows);
  }

  async eagerLoadRelatedModelsFor(
    rows: (PageviewTypeResponse | EventTypeResponse | CombinedTypeResponse)[],
    shouldLoadAllChildModels = false
  ) {
    let promises = [];
    this.modelsLoading = true;

    const customFormatPreloads: {
      property: CustomFormatProperty;
      values: string[];
    }[] = [];

    this.currentSettings.allSelectedProperties
      .filter((property) => property instanceof CustomFormatProperty)
      .forEach((p) => {
        const property = p as CustomFormatProperty;

        if (!property.needsPreload) {
          return;
        }

        const values = uniq(
          rows.map((row) => row[property.columnName]).filter(Boolean)
        );

        if (values.length > 0) {
          customFormatPreloads.push({ property, values });
        }
      });
    promises = customFormatPreloads.map(({ property, values }) =>
      property.preload?.(uniq(values))
    );

    const modelsAndIds: { property: ModelProperty; ids: string[] }[] = [];

    this.currentSettings.allSelectedProperties
      .filter((property) => property instanceof ModelProperty)
      .forEach((p) => {
        const property = p as ModelProperty;
        const ids = uniq(
          rows
            .map((row) => row[property.columnName])
            .filter((id) => id && id !== '0')
        );

        if (ids.length > 0) {
          const existingObject = modelsAndIds.find(
            (md) => md.property.model === property.model
          );
          if (existingObject) {
            existingObject.ids = [...existingObject.ids, ...ids];
          } else {
            modelsAndIds.push({ property, ids });
          }
        }
      });

    if (shouldLoadAllChildModels) {
      this.currentSettings.allSelectedProperties
        .filter((property) => property.parentProperty instanceof ModelProperty)
        .forEach(({ columnName, parentProperty }) => {
          if (!parentProperty) {
            return;
          }

          const property = parentProperty as ModelProperty;

          const ids = uniq(
            rows
              .map((row) => row[columnName])
              .flat()
              .filter((id) => id && id !== '0')
          );

          if (ids.length > 0) {
            const existingObject = modelsAndIds.find(
              (md) => md.property.model === property.model
            );
            if (existingObject) {
              existingObject.ids = [...existingObject.ids, ...ids];
            } else {
              modelsAndIds.push({ property, ids });
            }
          }
        });
    }

    promises = [
      ...promises,
      ...modelsAndIds.map(({ property, ids }) => property.preload(uniq(ids))),
    ];

    await Promise.all(promises);

    this.modelsLoaded = true;
    this.modelsLoading = false;

    if (this.args.onLoaded) {
      later(() => {
        this.args.onLoaded?.();
      }, 500);
    }
  }

  @action
  async handleExport() {
    let rows: (
      | PageviewTypeResponse
      | EventTypeResponse
      | CombinedTypeResponse
    )[];

    if (this.isTable) {
      this.exportIsLoading = true;

      const query = generateQuery(this.currentSettings, this.filterSettings);
      if (!query) {
        throw new Error(
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          `No query generated. Current settings: ${this.currentSettings} Filter settings: ${this.filterSettings}`
        );
      }

      const container = getOwner(this);
      const context = this.args.sharedReport
        ? {
            insights_report_id: this.report.id,
          }
        : undefined;

      const response: AnalyticsRequestResponse =
        await new ReportAnalyticsRequest({
          container,
          query,
          callback: (result: AnalyticsRequestResponse) => result,
        }).fetch({
          context,
        });

      const rowsToExport = processResults(
        response,
        this.currentSettings,
        this.excludeSource
      );

      await this.eagerLoadRelatedModelsFor(rowsToExport, true);
      rows = formatAndSortRows(rowsToExport, this.currentSettings, true);

      this.exportIsLoading = false;
    } else {
      rows = this.sortedRowsForCSV;
    }

    if (rows.length === 0) {
      return;
    }

    jsonToCsvFile(
      dasherize(this.name || 'export'),
      this.analytics.startDate,
      this.analytics.endDate,
      rows,
      (row: any) => {
        return this.currentSettings.allSelectedProperties.reduce(
          (acc: { [key: string]: any }, property) => {
            if (property.translatedName) {
              acc[property.translatedName] =
                property.columnType === 'array'
                  ? row[property.columnName]?.join(', ')
                  : row[property.columnName];
            }

            return acc;
          },
          {}
        );
      }
    );
  }

  @action
  moveUp() {
    this.args.handleMove?.('up');
  }

  @action
  moveDown() {
    this.args.handleMove?.('down');
  }

  @action
  handleSort(column: string) {
    if (this.currentSettings.sortProperty?.columnName === column) {
      this.currentSettings.sortDirection =
        this.currentSettings.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSettings.sortProperty =
        this.currentSettings.allSelectedProperties.find(
          (prop) => prop.columnName === column
        );
    }
  }

  @action
  updateSettings() {
    if (!this.currentSettings.valid) {
      return;
    }

    const { query, currentQuery } = this;
    const dateSpan = `${this.analytics.startDateParam} - ${this.analytics.endDateParam}`;
    if (
      this.currentDateSpan !== dateSpan ||
      JSON.stringify(query) !== JSON.stringify(currentQuery || {})
    ) {
      this.currentQuery = query;
      this.currentDateSpan = dateSpan;
      this.fetchQuery.perform();
    }
  }

  fetchQuery = restartableTask(async () => {
    const { currentQuery } = this;

    if (!currentQuery) {
      return undefined;
    }

    const context = this.args.sharedReport
      ? {
          sharedReportToken: this.args.sharedReport,
        }
      : undefined;

    const container = getOwner(this);
    const result: AnalyticsRequestResponse = await new ReportAnalyticsRequest({
      container,
      query: currentQuery,
      callback: (result: AnalyticsRequestResponse) => result,
    }).fetch({
      context,
    });

    this.modelsLoaded = false;

    return result;
  });

  @action
  scrollToTable() {
    next(() => {
      if (this.showingDataTable && this.dataTableElement) {
        this.dataTableElement.scrollIntoView();
      }
    });
  }

  @action
  didInsertDataTable(element: HTMLElement) {
    this.dataTableElement = element;
  }

  @action
  willRemoveDataTable(_element: HTMLElement) {
    this.dataTableElement = null;
  }

  @action
  updateName(name: string) {
    this.currentSettings.name = name;
    this.args.onUpdate?.(this.currentSettings);
  }
}
