/* import __COLOCATED_TEMPLATE__ from './heading.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class InsightsChartsHeading extends Component {
  @tracked variant = 'regular';
  @tracked isEditingName = false;
  @tracked _text;

  get text() {
    return this._text === undefined ? this.args.text : this._text;
  }

  set text(value) {
    this._text = value;
  }

  get displayText() {
    return this.args.text || this.args.placeholder;
  }

  get maxLength() {
    return this.args.maxLength || 100;
  }

  get size() {
    return Math.min(
      this.text?.length || this.args.placeholder?.length || 20,
      70
    );
  }

  get isEditing() {
    return this.args.canEdit && this.isEditingName;
  }

  get localClassNames() {
    const classes = ['root', `root--${this.args.variant}`];

    if (this.args.light) {
      classes.push('root--light');
    }

    return classes;
  }

  @action
  nameUpdated(event) {
    this.text = event.target.value;
  }

  @action
  handleKeyUp(event) {
    if (event.code === 'Enter') {
      this.isEditingName = false;
      this.args.onChange?.(this.text);
    }
  }
}
