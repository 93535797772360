/* import __COLOCATED_TEMPLATE__ from './form-phone-input.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

interface FormPhoneInputArgs {
  number: string;
  onUpdate: (number: string) => void;
}

export default class FormPhoneInput extends Component<FormPhoneInputArgs> {
  @tracked
  phoneNumber = '';

  @action
  onDidInsertOrUpdate(
    element: HTMLElement,
    [number, isDisabled]: [string, boolean]
  ): void {
    const input: HTMLInputElement | null = element.querySelector('.iti input');
    if (input && isEmpty(number)) {
      input.value = '';
    }

    this.phoneNumber = number;

    const flagDropdown = element.querySelector('.iti__selected-flag');

    if (flagDropdown) {
      if (isDisabled) {
        flagDropdown.classList.add('disabled-flag-dropdown');
      } else {
        flagDropdown.classList.remove('disabled-flag-dropdown');
      }
    }
  }

  @action
  handleUpdate(value: string) {
    if (this.phoneNumber !== value) {
      this.args.onUpdate(value);
    }
  }
}
