/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset as changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import { BufferedChangeset } from 'ember-changeset/types';
import { BACKGROUND_COLORS } from 'teamtailor/constants/tag';
import TagModel from 'teamtailor/models/tag';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import TagValidations from 'teamtailor/validations/tag';
import { ValidationErr } from 'validated-changeset/dist/types';

type ColorType = {
  color: string;
  name: string;
};

interface Args {
  tag: TagModel;
  onModalClose(tag?: TagModel): void;
  hideTypeSelection?: boolean;
}

export default class SettingsTagForm extends Component<Args> {
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @tracked declare changeset: BufferedChangeset;

  backgroundColors = BACKGROUND_COLORS;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.changeset = changeset(
      args.tag,
      lookupValidator(TagValidations),
      TagValidations
    );
  }

  get bgColor() {
    return BACKGROUND_COLORS.findBy(
      'color',
      get(this.changeset, 'backgroundColor')
    );
  }

  @action
  changeBackgroundColor(value: ColorType) {
    set(this.changeset, 'backgroundColor', value.color);
  }

  @action
  handlePurposeChanged() {
    this.changeset.validate();
  }

  @action
  async handleValidate(): Promise<TagModel> {
    await this.changeset.validate();
    if (get(this.changeset, 'isValid')) {
      const newTag = await this.handleSave();
      this.flashMessages.success(this.intl.t('settings.tag_manager.tag_saved'));
      this.args.onModalClose(newTag);
      return newTag;
    } else {
      return Promise.reject(new Error('Validation failed'));
    }
  }

  @action
  async handleSave(): Promise<TagModel> {
    try {
      return await this.changeset.save();
    } catch (err) {
      const serverMessage: ValidationErr = buildMessage('name', {
        type: 'unique',
      });
      this.changeset.addError('name', serverMessage);

      throw err;
    }
  }
}
