/* import __COLOCATED_TEMPLATE__ from './mentioned-channel.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';

type Args = {
  jobIds: string[];
  isLoading?: boolean;
};

export default class MentionedChannelComponent extends Component<Args> {
  @service declare store: Store;

  resolveMentionedJobResponses = trackedFunction(this, async () => {
    if (this.args.jobIds.length === 0) return [];
    const sortedJobs = await this.store.query('job', {
      ids: this.args.jobIds,
    });
    return sortedJobs;
  });

  get isLoading() {
    return (
      this.args.isLoading || this.resolveMentionedJobResponses.value === null
    );
  }

  get mentionedJobs() {
    return this.resolveMentionedJobResponses.value ?? [];
  }
}
