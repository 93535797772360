/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import { ALLOWED_STATUSES } from 'teamtailor/constants/status-dot';
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import ENV from 'teamtailor/config/environment';
import { isNone } from '@ember/utils';
import { isArray } from '@ember/array';
import {
  DEFAULT_SIZE,
  ALLOWED_SIZES,
  DEFAULT_APPEARANCES,
  ALLOWED_APPEARANCES,
  ICON_SIZE_MAP,
  AVATAR_SIZE_MAP,
} from 'teamtailor/constants/button';
import { ALLOWED_STYLES } from 'teamtailor/constants/icon';
import UserModel from 'teamtailor/models/user';
import CompanyModel from 'teamtailor/models/company';
import CandidateModel from 'teamtailor/models/candidate';
import { ALLOWED_COLORS } from 'teamtailor/constants/core/color-dot';
import TagModel from 'teamtailor/models/tag';
import { STYLE_PREFIX_MAP } from './icon';
import { guidFor } from '@ember/object/internals';
import { consume } from 'ember-provide-consume-context';
import { FieldContext } from './core/form/field';

type ButtonArgs = {
  size?: (typeof ALLOWED_SIZES)[number];
  appearance?: (typeof ALLOWED_APPEARANCES)[number];
  emoji?: string;
  icon?: string;
  iconStyle?: typeof ALLOWED_STYLES;
  iconPrefix?: (typeof STYLE_PREFIX_MAP)[(typeof ALLOWED_STYLES)[number]];
  iconSpin?: boolean;
  iconClass?: string;
  iconRight?: boolean;
  secondaryIconRight?: string;
  iconTransform?: string;
  iconAnimate?: boolean;
  iconFlip?: string;
  iconStyleAttr?: string;
  isDisabled?: boolean;
  user?: UserModel | UserModel[];
  userGroup?: boolean;
  userGroupTooltip?: string;
  userFallbackIcon?: string;
  tags?: TagModel[] | TagModel;
  tagGroup?: boolean;
  tagLimit?: number;
  skipTagTruncate?: boolean;
  text?: string;
  textClass?: string;
  triggerFor?: 'select' | 'action';
  hideTriggerIcon?: boolean;
  fixedWidth?: boolean;
  typeAttr?: string;
  ariaLabel?: string;
  isFullWidth?: boolean;
  isDestructive?: boolean;
  isActive?: boolean;
  company?: CompanyModel;
  candidate?: CandidateModel;
  onClick?: (event: MouseEvent) => void;
  tooltip?: string;
  tooltipOutsideButton?: boolean;
  tooltipSide?: 'top' | 'bottom' | 'left' | 'right';
  status?: (typeof ALLOWED_STATUSES)[number];
  dot: (typeof ALLOWED_COLORS)[number];
  showDot?: boolean;
  wrapperElement?: string;
  id?: string;
} & (
  | {
      href?: string;
    }
  | {
      linkToRoute?: string;
      model?: unknown[];
      models?: unknown[];
    }
  | {
      query?: Record<string, unknown>;
    }
);

export default class Button extends Component<ButtonArgs> {
  @consume('core/form/field') fieldContext?: FieldContext;

  id = guidFor(this);

  get srTextId() {
    return this.srText ? `${this.id}-srTextId` : this.args.id;
  }

  get buttonId() {
    // if we have screen reader text use provided id (if provided) and the hidden element will get srTextId,
    // if no sr text, make the button element the sr text element, as a fallback (which may be the provided or generated id)
    return this.srText ? this.args.id : this.srTextId;
  }

  get ariaLabelledby() {
    return this.fieldContext?.ariaLabelledby
      ? `${this.fieldContext.ariaLabelledby} ${this.srTextId}`
      : this.srTextId;
  }

  get isListingTags() {
    return this.args.tagGroup && this.args.tags;
  }

  get srText() {
    const providedSrText = this.args.ariaLabel ?? this.args.text;

    if (providedSrText) {
      return providedSrText;
    }

    if (this.isListingTags && isArray(this.args.tags)) {
      return this.args.tags.map((tag) => tag.name).join(', ');
    }

    return this.args.tooltip;
  }

  get buttonSize() {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Button @size', DEFAULT_SIZE);
    return this.args.size ?? DEFAULT_SIZE;
  }

  get appearance() {
    verifyArg(
      this.args.appearance,
      ALLOWED_APPEARANCES,
      'Button @appearance',
      DEFAULT_APPEARANCES
    );
    return this.args.appearance ?? DEFAULT_APPEARANCES;
  }

  get icon() {
    if (!this.args.icon && !this.args.emoji) {
      return undefined;
    }

    return {
      emoji: this.args.emoji,
      icon: this.args.icon,
      style: this.args.iconStyle,
      prefix: this.args.iconPrefix,
      spin: this.args.iconSpin,
      class: this.args.iconClass,
    };
  }

  get iconSize() {
    return ICON_SIZE_MAP[this.buttonSize];
  }

  get avatarSize() {
    return AVATAR_SIZE_MAP[this.buttonSize];
  }

  get iconOnly() {
    return (
      !this.args.user &&
      !this.args.tagGroup &&
      !this.args.text &&
      !!(this.icon || !!this.args.triggerFor)
    );
  }

  get text() {
    if (!isNone(this.args.text)) {
      return this.args.text;
    }

    const modelWithNameOrEmail = this.args.user ?? this.args.candidate;

    return modelWithNameOrEmail && !Array.isArray(modelWithNameOrEmail)
      ? get(modelWithNameOrEmail, 'nameOrEmail')
      : null;
  }

  get fixedWidth() {
    return this.args.fixedWidth ?? this.iconOnly;
  }

  @action
  checkCollisionArgs(
    _: HTMLElement,
    [isDisabled, tooltip, tooltipOutsideButton]: [boolean, string, boolean]
  ) {
    if (
      isDisabled &&
      tooltip &&
      isNone(tooltipOutsideButton) &&
      ENV.environment !== 'production'
    ) {
      throw new Error(
        `tooltip doesnt work in disabled buttons, you can use @tooltipOutsideButton={{true}} or wrap Button instead. This is the tooltip: "${tooltip}"`
      );
    }
  }
}
