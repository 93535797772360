/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import {
  AvailableUserAttribute,
  AvailableUserAttributeValue,
} from 'teamtailor/models/single-sign-on';
import SsoUserAttributeMappingsModel from 'teamtailor/models/sso-user-attribute-mapping';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

interface SettingsSecuritySsoMappingsFormArgs {
  model: SsoUserAttributeMappingsModel;
}

export default class SettingsSecuritySsoMappingsForm extends Component<SettingsSecuritySsoMappingsFormArgs> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get availableUserAttributes() {
    const singleSignOn = get(this.current.company, 'singleSignOn');

    return get(singleSignOn, 'availableUserAttributes');
  }

  get selectedTargetAttribute(): AvailableUserAttribute | undefined {
    return this.availableUserAttributes.findBy(
      'key',
      this.args.model.targetKey
    );
  }

  get selectedTargetAttributeValue(): AvailableUserAttributeValue | undefined {
    return this.selectedTargetAttributeValues.findBy(
      'value',
      get(this.args.model, 'targetValue')
    );
  }

  get selectedTargetAttributeValues(): AvailableUserAttributeValue[] {
    return (
      this.availableUserAttributes.findBy('key', this.args.model.targetKey)
        ?.values ?? []
    );
  }

  @action
  selectTargetAttribute(targetAttribute: AvailableUserAttribute) {
    set(this.args.model, 'targetKey', targetAttribute.key);
  }

  @action
  handleSelectTargetAttributeValue(
    targetAttributeValue: AvailableUserAttributeValue
  ) {
    set(this.args.model, 'targetValue', String(targetAttributeValue.value));
  }
}
