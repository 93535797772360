/* import __COLOCATED_TEMPLATE__ from './store-requisition-jobs-modal.hbs'; */
import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class StageListStoreRequisitionJobsModal extends Component {
  @service store;

  get requisitionOpenJobs() {
    return get(this, 'requisition.jobs').filter((job) => job.status === 'open');
  }

  @action
  archiveRequisitionJobs() {
    let promises = [];

    this.requisitionOpenJobs.forEach((job) => {
      promises.push(
        this.store.findRecord('jobDetail', get(job, 'id')).then((jobDetail) => {
          set(jobDetail, 'job.status', 'archived');
          return get(jobDetail, 'job').save();
        })
      );
    });

    Promise.all(promises).then(() => {
      this.args.onComplete();
    });
  }
}
