/* import __COLOCATED_TEMPLATE__ from './channel-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import ChannelModel from 'teamtailor/models/channel';
import JobModel from 'teamtailor/models/job';
import {
  DEFAULT_COUNTRY,
  DEFAULT_CATEGORY,
  getAvailableCountries,
} from 'teamtailor/utils/channels';
import uniq from 'teamtailor/utils/uniq';

interface StatusArgs {
  channels: ChannelModel[];
  job: JobModel;
  jobCountry: string;
}

export default class extends Component<StatusArgs> {
  @service declare intl: IntlService;

  @tracked query = '';
  @tracked sort = this.defaultSort;
  @tracked _selectedCountry?: string /* = this.args.jobCountry*/;

  get selectedCountry() {
    return this._selectedCountry || this.args.jobCountry;
  }

  set selectedCountry(val) {
    this._selectedCountry = val;
  }

  @tracked selectedCategory = DEFAULT_CATEGORY;

  get channelCount() {
    return this.filteredChannelList.length;
  }

  get defaultSort() {
    return 'most-popular';
  }

  get availableCountries() {
    return getAvailableCountries(this.args.channels, this.args.jobCountry);
  }

  get isFilterActive() {
    return (
      this.selectedCategory !== DEFAULT_CATEGORY ||
      this.selectedCountry !== DEFAULT_COUNTRY
    );
  }

  get availableCategories() {
    const categories = this.args.channels.mapBy('category');

    const sortedCategories = uniq([
      DEFAULT_CATEGORY,
      'General',
      ...categories,
    ]) as string[];

    return sortedCategories.map((category) => ({
      id: category,
      label: this.intl.t(
        `jobs.job.promote.channel_categories.${category
          .toLowerCase()
          .replaceAll(' ', '_')}`
      ),
    }));
  }

  get sortingFunction() {
    switch (this.sort) {
      case 'most-popular':
      default:
        return (a: ChannelModel, b: ChannelModel) =>
          a.rowOrder > b.rowOrder ? 1 : -1;

      case 'title-asc':
        return (a: ChannelModel, b: ChannelModel) => (a.name > b.name ? 1 : -1);
      case 'title-desc':
        return (a: ChannelModel, b: ChannelModel) => (a.name < b.name ? 1 : -1);
    }
  }

  get sortedChannels() {
    return this.args.channels.sort(this.sortingFunction);
  }

  get filteredChannelList() {
    return this.sortedChannels
      .filter((channel) => {
        return (
          this.queryFilter(channel) &&
          this.countryFilter(channel) &&
          this.categoryFilter(channel)
        );
      })
      .sort(this.sortingFunction);
  }

  queryFilter(channel: ChannelModel) {
    if (!this.query) return true;

    const channelName = get(channel, 'name').toLowerCase();
    return channelName.includes(this.query.toLowerCase());
  }

  categoryFilter(channel: ChannelModel) {
    if (!this.selectedCategory || this.selectedCategory === 'All categories')
      return true;

    return this.selectedCategory === channel.category;
  }

  countryFilter(channel: ChannelModel) {
    if (!this.selectedCountry || this.selectedCountry === DEFAULT_COUNTRY)
      return true;

    return (
      channel.countries.includes(this.selectedCountry) ||
      channel.country === 'Global'
    );
  }

  get searchQueryExists() {
    return this.query;
  }

  get sortOptions() {
    return [
      {
        id: 'most-popular',
        label: this.intl.t('jobs.job.promote.sorting_options.most_popular'),
      },
      {
        id: 'title-asc',
        label: this.intl.t('jobs.job.promote.sorting_options.name_a_z'),
      },
      {
        id: 'title-desc',
        label: this.intl.t('jobs.job.promote.sorting_options.name_z_a'),
      },
    ];
  }

  @action
  handleSearchInput(query: string) {
    this.query = query;
  }

  @action onSortChange(sort: (typeof this.sortOptions)[number]) {
    this.sort = sort.id;
  }

  @action onCountryChange(country: { id: string; label: string }) {
    this.selectedCountry = country.id;
  }

  @action onCategoryChange(category: { id: string; label: string }) {
    this.selectedCategory = category.id;
  }
}
