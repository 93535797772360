import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ContentBlockLeadSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    pickedImages: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'picked_images_attributes',
    },

    imageWithSetting: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'image_with_setting_attributes',
    },

    company: { serialize: false },
  };

  payloadKeyFromModelName() {
    return 'content_block_lead';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-lead': ContentBlockLeadSerializer;
  }
}
