/* import __COLOCATED_TEMPLATE__ from './interview-kit.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  ICompany,
  IInterviewKit,
  IJob,
  IJobApplication,
} from 'teamtailor/components/meeting/types';
import {
  MeetingEventClass,
  UserAttendeeClass,
} from 'teamtailor/classes/meetings';
import IntlService from 'ember-intl/services/intl';

interface Args {
  event: MeetingEventClass;
  company: ICompany;
  job?: IJob;
  jobApplication: IJobApplication | undefined;
}

type PowerSelectGroup = {
  groupName: string;
  options: IInterviewKit[] | UserAttendeeClass[];
  description?: string;
};

export default class MeetingEventRowsInterviewKit extends Component<Args> {
  @service declare intl: IntlService;

  get event(): MeetingEventClass {
    return this.args.event;
  }

  get jobApplication(): IJobApplication | undefined {
    return this.args.jobApplication;
  }

  get userAttendees(): UserAttendeeClass[] {
    return this.event.meetingEventAttendees.userAttendees;
  }

  get leaveFeedbackOptions(): PowerSelectGroup[] {
    return [
      {
        groupName: this.intl.t(
          'components.meetings.event_scheduler.who_leave_feedback'
        ),

        options: this.userAttendees,
      },
    ];
  }

  get interviewKitUsers(): UserAttendeeClass[] {
    return this.userAttendees.filter((u) => u.hasInterview);
  }

  get companyInterviewKits(): IInterviewKit[] {
    return this.args.company.interviewKits.filter(
      (cik) =>
        !this.jobInterviewKits.some((jik) => jik.id === cik.id) && !cik.isHidden
    );
  }

  get jobInterviewKits(): IInterviewKit[] {
    return this.args.job?.interviewKits?.filter((ik) => !ik.isHidden) || [];
  }

  get interviewKits(): PowerSelectGroup[] {
    const interviewKitsGroups: PowerSelectGroup[] = [];

    if (this.jobInterviewKits.length) {
      interviewKitsGroups.push({
        groupName: this.intl.t(
          'components.meetings.event_scheduler.interview_kits.job_kits'
        ),

        options: this.jobInterviewKits,
      });
    }

    if (this.companyInterviewKits.length) {
      interviewKitsGroups.push({
        groupName: this.intl.t(
          'components.meetings.event_scheduler.interview_kits.company_kits'
        ),

        options: this.companyInterviewKits,
      });
    }

    return interviewKitsGroups;
  }

  get isInterviewKitsVisible(): boolean | undefined {
    return this.args.job && this.interviewKits.length > 0;
  }

  get interviewKit(): IInterviewKit | undefined {
    return this.event.interviewKit;
  }

  @action
  selectInterviewKit(interviewKit: IInterviewKit): void {
    if (this.interviewKit === interviewKit) {
      this.event.clearInterviewKit();
    } else {
      this.event.selectInterviewKit(interviewKit);
    }
  }

  @action
  toggleInterviewUsers(attendee: UserAttendeeClass): void {
    this.event.meetingEventAttendees.toggleHasInterview(attendee);
  }

  @action
  didInsertSelect() {
    if (this.args.jobApplication?.stage?.pickedInterviewKit?.interviewKit) {
      const interviewKit = this.jobInterviewKits.findBy(
        'id',
        this.jobApplication?.stage?.pickedInterviewKit?.interviewKit?.id
      );
      this.event.selectInterviewKit(interviewKit);
    }
  }
}
