import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class ArrayTransform extends Transform {
  deserialize(serialized: unknown[] | undefined) {
    return serialized || [];
  }

  serialize(deserialized: unknown[] | undefined) {
    return deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    array: ArrayTransform;
  }
}
