import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class Country extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  primaryKey = 'code';
}
