/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import CachedFeed, {
  CandidateFeedSignature,
} from 'teamtailor/components/cached-feed';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { MessageModel } from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import { next } from '@ember/runloop';

interface CandidateMessagesSignature extends CandidateFeedSignature {
  Args: CandidateFeedSignature['Args'] & {
    messageToHighlight: string;
  };
}

export default class CandidateMessages extends CachedFeed<CandidateMessagesSignature> {
  @service declare pusher: PusherService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare current: Current;

  declare presenceChannel: PusherChannel | null;

  @tracked displayRawMessage: MessageModel | null = null;

  constructor(owner: unknown, args: CandidateMessagesSignature['Args']) {
    super(owner, args, 'message');

    this.setupPusher();

    registerDestructor(this, () => {
      this.teardownPusher();
    });
  }

  async setupPusher() {
    if (this.presenceChannel) {
      return;
    }

    // just keeps track of who is currently viewing the message feed of the candidate
    // we use this atm just to silence browser notifications
    try {
      this.presenceChannel = await this.pusher.subscribe(
        this.args.candidate.pusherChatPresence
      );
    } catch {
      return;
    }
  }

  teardownPusher() {
    if (!this.presenceChannel) {
      return;
    }

    this.presenceChannel.unsubscribe();
    this.presenceChannel = null;
  }

  maybeMarkConversationsAsRead() {
    if (
      !this.feed.isCached &&
      this.feed.records &&
      this.feed.records.length > 0
    ) {
      this.current.user.markConversationsAsRead({
        candidate_id: this.args.candidate.id,
      });
    }
  }

  get highlightMessageIsLoaded() {
    const foundMessage = this.feed.records?.find((message) => {
      return message.id === this.args.messageToHighlight;
    });

    return !!foundMessage;
  }

  async loadUntilHighlightMessageIsFound() {
    if (this.feed.hasMore) {
      await this.feed.loadMore();

      if (!this.highlightMessageIsLoaded) {
        await this.loadUntilHighlightMessageIsFound();
      }
    }
  }

  async scrollHighlightedMessageIntoView() {
    await this.loadUntilHighlightMessageIsFound();

    next(() => {
      const messageElement = document.getElementById(
        `message-${this.args.messageToHighlight}`
      );

      if (messageElement) {
        messageElement.scrollIntoView({ block: 'end' });
      }
    });
  }

  async onJobApplicationChange() {
    this.teardownPusher();
    this.setupPusher();

    await super.onJobApplicationChange();

    if (this.args.messageToHighlight) {
      await this.scrollHighlightedMessageIntoView();
    }

    this.maybeMarkConversationsAsRead();
  }

  @action
  handleDeleteMessage(message: MessageModel) {
    this.ttAlert.confirm(
      this.intl.t('components.message_item.do_you_want_to_delete'),
      async () => {
        await message.destroyRecord();
        this.feed.removeRecord(message);
      }
    );
  }
}
