/* import __COLOCATED_TEMPLATE__ from './custom-form-data-value.hbs'; */
import Component from '@glimmer/component';
import Server from 'teamtailor/services/server';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { RequisitionModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';

interface Args {
  value: string | null;
  requisition: RequisitionModel;
}

interface CustomFileData {
  custom_file_id: string;
  file_name: string;
  delete?: boolean;
}

export default class DownloadComponent extends Component<Args> {
  @service declare server: Server;
  @service declare current: Current;

  downloadLink = trackedFunction(this, async () => {
    if (!this.args.value) {
      return null;
    }

    const data = JSON.parse(this.args.value) as CustomFileData;
    const requisition = await get(this.args, 'requisition');

    return this.server.memberActionUrl(requisition, {
      action: 'download_attachment',
      queryParams: {
        custom_form_answer_file_id: data.custom_file_id,
      },
    });
  });

  get fileName() {
    if (!this.args.value) {
      return null;
    }

    const data = JSON.parse(this.args.value) as CustomFileData;
    return data.file_name;
  }

  get fileDeleted(): boolean {
    if (!this.args.value) {
      return true;
    }

    const data = JSON.parse(this.args.value) as CustomFileData;
    return data.delete ?? !data.file_name;
  }

  get jsonResponse() {
    if (!this.args.value) {
      return false;
    }

    try {
      JSON.parse(this.args.value);
      return true;
    } catch {
      return false;
    }
  }
}
