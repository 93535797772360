/* import __COLOCATED_TEMPLATE__ from './alerts.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class StageAlerts extends Component {
  @service intl;
  get overdueToggleText() {
    const count = this.args.applicationsPastGuideTimeCount;
    return this.args.viewOverdue
      ? this.intl.t('components.stage_list.showing_overdue_count', { count })
      : this.intl.t('components.stage_list.overdue', { count });
  }
}
