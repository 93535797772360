/* import __COLOCATED_TEMPLATE__ from './profile-settings.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import RouterService from '@ember/routing/router-service';
import { IntlService } from 'ember-intl';
import UserService from 'teamtailor/services/user';
import TimeFormatService from 'teamtailor/services/time-format';
import { UserTemplateModel } from 'teamtailor/models';

type Args = {
  userTemplate: UserTemplateModel;
};

export default class extends Component<Args> {
  @service declare current: Current;
  @service declare user: UserService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  @tracked userTemplate = this.args.userTemplate;

  addonsList = ['analytics', 'designer'];

  get defaultStartOfWeekDay() {
    return this.timeFormat.weekdayForLocale(this.userTemplate.locale).name;
  }

  get roleAccessLevelName() {
    return this.userTemplate.accessLevels.find((accessLevel: string) => {
      return !this.addonsList.includes(accessLevel);
    });
  }

  get selectedAddons() {
    return this.userTemplate.accessLevels.filter((accessLevel: string) => {
      return this.addonsList.includes(accessLevel);
    });
  }

  get displayRoleExplanation() {
    return this.userTemplate.role !== 'no_access';
  }

  get roleExplanation() {
    const roleExplanationText = this.current.company.singleSignOnActive
      ? 'role_explanation_auto_join_with_sso'
      : 'role_explanation_auto_join';

    return this.intl.t(`settings.user_template.${roleExplanationText}`, {
      url: this.autoJoinSettingsUrl,
      htmlSafe: true,
    });
  }

  get autoJoinSettingsUrl() {
    return this.router.urlFor('settings.auto-join');
  }

  @action
  onChangeTemplateRole(role: string, addons: string[] | undefined) {
    this.userTemplate.role = role;

    const accessLevels = [role];

    if (addons) {
      accessLevels.push(...addons);
    }

    if (role === 'designer') {
      accessLevels.push('user');
    }

    this.userTemplate.accessLevels = accessLevels;
  }

  @action
  toggleUserTemplateVisibility(value: boolean) {
    this.userTemplate.visible = value;
  }

  @action
  toggleEmailVisibility(value: boolean) {
    this.userTemplate.showEmail = value;
  }

  @action
  togglePhoneVisibility(value: boolean) {
    this.userTemplate.showPhone = value;
  }
}
