/* import __COLOCATED_TEMPLATE__ from './candidate-picker.hbs'; */
import { set } from '@ember/object';
import Component from '@glimmer/component';
import { enqueueTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class TodoEditCandidatePicker extends Component {
  @service store;

  fetchCandidatesTask = enqueueTask(async ({ searchTerm: query, page }) => {
    const params = {
      page: page || 1,
      query,
    };

    let candidates = await this.store.query('candidate', params);

    if (page === 1 && this.args.defaultOptionPlaceholder) {
      let { meta } = candidates;
      candidates = [
        {
          id: '',
          nameOrEmail: this.args.defaultOptionPlaceholder,
        },
        ...candidates.toArray(),
      ];
      set(candidates, 'meta', meta);
    }

    return candidates;
  });
}
