/* import __COLOCATED_TEMPLATE__ from './schedule-report-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ScheduledReport, {
  defaultSettings,
} from 'teamtailor/models/scheduled-report';
import UserModel from 'teamtailor/models/user';
import CustomReportModel from 'teamtailor/models/custom-report';
import InsightsReport from 'teamtailor/models/insights-report';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { intervalTypeAsPeriodType } from './schedule-report-modal/period-picker';
import moment from 'moment-timezone';
import IntlService from 'ember-intl/services/intl';
import { task, timeout } from 'ember-concurrency';
import Current from 'teamtailor/services/current';
import {
  ExternalEmail,
  Recipient,
} from 'teamtailor/components/core/recipient-picker';

type Args = {
  customReport?: CustomReportModel;
  insightsReport?: InsightsReport;
  onClose: () => void;
};

function errorMessage(message: string) {
  return [
    {
      message,
    },
  ];
}

export default class InsightsScheduleReportModal extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare current: Current;

  @tracked scheduledReport?: ScheduledReport;
  @tracked isLoading = true;
  @tracked hasFailedToSave = false;

  get isSaving() {
    return this.onSave.isRunning && !this.hasFailedToSave;
  }

  get icon() {
    if (this.isSaving) {
      return 'spinner';
    } else if (this.hasFailedToSave) {
      return 'ban';
    }
  }

  get scheduleButtonText() {
    if (this.isScheduled) {
      return this.intl.t('common.confirm');
    }

    return this.intl.t('insights.molecules.schedule_report_modal.schedule');
  }

  get recipients(): Recipient[] {
    return [
      ...(this.userRecipients?.toArray() || []),
      ...(this.externalRecipients?.toArray() || [])
        .map(
          (email) =>
            ({
              id: email,
              nameOrEmail: email,
            }) as ExternalEmail
        )
        .compact(),
    ];
  }

  get userRecipients() {
    return get(this.scheduledReport, 'recipients');
  }

  get externalRecipients(): string[] | undefined {
    return get(this.scheduledReport, 'externalRecipients');
  }

  get errors() {
    const errors: { [key: string]: { message: string }[] } = {};

    if (this.hasInvalidRecipientEmails) {
      errors.recipients = errorMessage(
        this.intl.t('insights.molecules.schedule_report_modal.invalid_email', {
          email: this.invalidEmails.firstObject,
        })
      );
    } else if (!this.isValidRecipients) {
      errors.recipients = errorMessage(
        this.intl.t(
          'insights.molecules.schedule_report_modal.field_cant_be_blank'
        )
      );
    }

    if (!this.isValidSubject) {
      errors.subject = errorMessage(
        this.intl.t(
          'insights.molecules.schedule_report_modal.too_short_subject'
        )
      );
    }

    if (!this.isValidDaysOfWeek) {
      errors.days = errorMessage(
        this.intl.t(
          'insights.molecules.schedule_report_modal.select_at_least_one_day'
        )
      );
    }

    if (!this.isValidDatesOfMonth) {
      errors.dates = errorMessage(
        this.intl.t(
          'insights.molecules.schedule_report_modal.field_cant_be_blank'
        )
      );
    }

    if (!this.isValidMonthsOfYear) {
      errors.months = errorMessage(
        this.intl.t(
          'insights.molecules.schedule_report_modal.select_at_least_one_month'
        )
      );
    }

    if (!this.isValidIntervalValue) {
      errors.intervalValue = errorMessage(
        this.intl.t('insights.molecules.schedule_report_modal.invalid_value')
      );
    }

    if (!this.isValidPeriodValue) {
      errors.periodValue = errorMessage(
        this.intl.t('insights.molecules.schedule_report_modal.invalid_value')
      );
    }

    return errors;
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  get invalidEmails() {
    return (this.externalRecipients?.toArray() || []).filter(
      (email) => !this.isValidEmail(email)
    );
  }

  get hasInvalidRecipientEmails() {
    return this.invalidEmails.length > 0;
  }

  get isValidRecipients() {
    return !this.hasInvalidRecipientEmails && this.recipients.length > 0;
  }

  get isValidSubject() {
    return (this.subject?.length || 0) > 1;
  }

  get isValidInterval() {
    if (!this.scheduledReport) {
      return false;
    }

    if (!this.isValidIntervalValue) {
      return false;
    }

    const type = get(this.scheduledReport.settings.interval, 'type');

    switch (type) {
      case 'weekly':
        return this.isValidDaysOfWeek;
      case 'monthly':
        return this.isValidDatesOfMonth;
      case 'yearly':
        return this.isValidDatesOfMonth && this.isValidMonthsOfYear;
      default:
        return false;
    }
  }

  get isValidIntervalValue() {
    if (!this.scheduledReport) {
      return false;
    }

    const value = get(this.scheduledReport.settings.interval, 'value');
    return value > 0;
  }

  get isValidDaysOfWeek() {
    const days = get(this.scheduledReport?.settings.interval, 'days');

    return (days?.length || 0) > 0;
  }

  get isValidDatesOfMonth() {
    const dates = get(this.scheduledReport?.settings.interval, 'dates');

    return (dates?.length || 0) > 0;
  }

  get isValidMonthsOfYear() {
    const months = get(this.scheduledReport?.settings.interval, 'months');

    return (months?.length || 0) > 0;
  }

  get isValidPeriod() {
    return this.isValidPeriodValue;
  }

  get isValidPeriodValue() {
    if (!this.scheduledReport) {
      return false;
    }

    const periodValue = get(this.scheduledReport.settings.period, 'value');
    if (periodValue !== undefined && periodValue <= 0) {
      return false;
    }

    const intervalValue = get(this.scheduledReport.settings.interval, 'value');

    return (periodValue || intervalValue) > 0;
  }

  get isValidScheduledReport() {
    return (
      this.isValidRecipients &&
      this.isValidSubject &&
      this.isValidInterval &&
      this.isValidPeriod
    );
  }

  get report() {
    return this.args.customReport || this.args.insightsReport;
  }

  get subject() {
    if (this.scheduledReport?.subject === null) {
      return this.report?.name;
    }

    return this.scheduledReport?.subject;
  }

  set subject(value: string | undefined) {
    if (this.scheduledReport && value !== undefined) {
      this.scheduledReport.subject = value;
    }
  }

  get isScheduled() {
    return (
      this.scheduledReport?.id && this.scheduledReport.status !== 'disabled'
    );
  }

  get isHistoricReport() {
    return this.scheduledReport?.insightsReport;
  }

  get isLoadingNextDates() {
    if (!this.scheduledReport) {
      return false;
    }

    return this.scheduledReport.fetchNextSendDates.isRunning;
  }

  get nextDatesString() {
    if (this.scheduledReport) {
      return this.scheduledReport.nextSendDates
        .map((date) => moment(date).format('Do MMM YYYY'))
        .join(', ');
    }
  }

  @action
  async didInsert() {
    let scheduledReport = await get(this.report, 'scheduledReport');
    this.isLoading = false;

    if (scheduledReport === null) {
      scheduledReport = this.store.createRecord('scheduled-report', {
        subject: this.report?.name,
        customReport: this.args.customReport,
        insightsReport: this.args.insightsReport,
        settings: defaultSettings,
      });
    }

    this.scheduledReport = scheduledReport;
  }

  @action
  toggleRecipientValue(value: Recipient): void {
    if (value instanceof UserModel) {
      if (!this.userRecipients) return;

      const existIndex = this.userRecipients.indexOf(value);

      if (existIndex >= 0) {
        this.userRecipients.removeAt(existIndex);
      } else {
        this.userRecipients.pushObject(value);
      }
    } else {
      if (!this.externalRecipients) return;

      const existIndex = this.externalRecipients.indexOf(value.nameOrEmail);

      if (existIndex >= 0) {
        this.externalRecipients.removeAt(existIndex);
      } else {
        this.externalRecipients.pushObject(value.nameOrEmail);
      }
    }
  }

  onSave = task(async () => {
    if (this.scheduledReport) {
      if (
        !this.scheduledReport.status ||
        this.scheduledReport.status === 'disabled'
      ) {
        this.scheduledReport.status = 'pending';
      }

      if (this.isHistoricReport) {
        if (!this.scheduledReport.settings.period.type) {
          this.scheduledReport.settings.period.type = intervalTypeAsPeriodType(
            this.scheduledReport.settings.interval.type
          );
        }

        if (!this.scheduledReport.settings.period.value) {
          this.scheduledReport.settings.period.value =
            this.scheduledReport.settings.interval.value;
        }
      }

      try {
        await this.scheduledReport.save();
      } catch (e) {
        this.hasFailedToSave = true;
        await timeout(2000);
        this.hasFailedToSave = false;
        return;
      }

      this.args.onClose();
    }
  });

  @action
  unscheduleReport() {
    if (this.scheduledReport) {
      this.scheduledReport.status = 'disabled';
      this.scheduledReport.nextSendAt = undefined;
      this.scheduledReport.save().then(() => {
        this.args.onClose();
      });
    }
  }

  @action
  updateSubject(event: InputEvent) {
    if (this.scheduledReport && event.target) {
      this.scheduledReport.subject = (event.target as HTMLInputElement).value;
    }
  }

  @action
  updateNextSendDates() {
    if (this.isValidInterval && this.scheduledReport) {
      this.scheduledReport.fetchNextSendDates.perform();
    }
  }
}
