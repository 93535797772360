/* import __COLOCATED_TEMPLATE__ from './ejobs.hbs'; */
import PromotionBaseClass from './promotion-base';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

import { ejobsCareerLevels, ejobsAdTypes } from 'teamtailor/constants/promote';

export default class extends PromotionBaseClass {
  @tracked checkoutData;

  ejobsAdTypes = ejobsAdTypes;
  ejobsCareerLevels = ejobsCareerLevels;

  get ejobsCities() {
    return this.store.peekAll('promotion-categories/ejobs-city');
  }

  get ejobsDepartments() {
    return this.store.peekAll('promotion-categories/ejobs-department');
  }

  get ejobsAdTypeId() {
    return ejobsAdTypes.findBy('code', get(this, 'promotion.ejobsAdTypeId'));
  }

  get ejobsCareerLevelId() {
    return ejobsCareerLevels.findBy(
      'code',
      get(this, 'promotion.ejobsCareerLevelId')
    );
  }
}
