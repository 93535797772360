/* import __COLOCATED_TEMPLATE__ from './add-filter-button.hbs'; */
import Component from '@glimmer/component';
import { FilterOption } from 'teamtailor/components/fancy-filters.d';

interface AddFilterButtonArgs {
  options: FilterOption[];
  onSelect: (value: FilterOption) => void;
  appearance?: string;
}

export default class AddFilterButton extends Component<AddFilterButtonArgs> {}
