import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { reject } from 'rsvp';
import { TrackedObject } from 'tracked-built-ins';
import { ImageUrlVersions } from 'teamtailor/utils/image-url';
import Server from './server';
import Store from '@ember-data/store';
import FlipperService from './flipper';
import PermissionsService, { PermissionsMap } from './permissions';
import TtAdminService from './tt-admin';
const NO_ADMIN_SESSION_MESSAGE = 'Need TT admin session';

type Data = {
  esm_fa_icons_version: string;
  features: Record<string, boolean>;
  permissions: PermissionsMap;
  image_url_versions: Record<string, string>;
  image_url_global_version: string;
  access_levels: { id: string; name: string; is_addon: boolean }[];
};

@classic
export default class BootDataService extends Service {
  @service declare server: Server;
  @service declare store: Store;
  @service declare flipper: FlipperService;
  @service declare permissions: PermissionsService;
  @service declare TtAdmin: TtAdminService;

  esmFaIconVersion?: string;
  data?: Data;

  _blockingFetch = task(async () => {
    // @ts-expect-error boot-data is not a model or is the type wrong?
    let url = this.store.adapterFor('application').buildURL('boot-data');
    let authenticateTTAdmin: boolean | undefined = false;
    let responseError: Error | undefined;
    let data = (await this.server.request(url).catch((error) => {
      responseError = error;
      if (error.payload?.includes(NO_ADMIN_SESSION_MESSAGE)) {
        authenticateTTAdmin = true;
      }
    })) as Data | undefined;

    if (data) {
      this.esmFaIconVersion = data.esm_fa_icons_version;
      set(this.flipper, 'features', new TrackedObject(data.features));
      set(this.permissions, 'permissions', data.permissions);
      ImageUrlVersions.setTransformationVersions(
        data.image_url_versions,
        data.image_url_global_version
      );

      this.store.pushPayload({ 'access-levels': data.access_levels });

      return data;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (authenticateTTAdmin) {
      this.TtAdmin.authenticate.perform();
      await timeout(30000);
    } else {
      return reject(responseError);
    }

    return undefined;
  });

  async fetch() {
    const data = await this._blockingFetch.perform();
    if (!data) {
      throw new Error('Failed to fetch boot data');
    }

    set(this, 'data', data);
    return data;
  }
}

declare module '@ember/service' {
  interface Registry {
    'boot-data': BootDataService;
  }
}
