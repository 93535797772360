/* import __COLOCATED_TEMPLATE__ from './trigger-tag-form.hbs'; */
import TriggerFormBaseComponent from 'teamtailor/components/trigger-form-base';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { get } from 'teamtailor/utils/get';
import { action } from '@ember/object';

export default class TriggerTagFormComponent extends TriggerFormBaseComponent {
  @action
  changeTags(tag) {
    toggleInList(get(this.model, 'tags'), tag);
  }
}
