/* import __COLOCATED_TEMPLATE__ from './office-hours.hbs'; */
import DropdownMenu from 'teamtailor/components/core/dropdown-menu';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import { SelfScheduleClass } from 'teamtailor/classes/meetings/self-schedule';
import { HourAndMinute } from './types';
import { justAssert } from 'teamtailor/utils/justAssert';

export type WeekDay = {
  letter: string;
  weekday: number;
};

const MONDAY = 1;
const SUNDAY = 0;

type MeetingOfficeHoursArgs = {
  selfSchedule: SelfScheduleClass;
  onChange?: () => void;
};

export default class MeetingOfficeHours extends DropdownMenu<MeetingOfficeHoursArgs> {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  get weekDayOptions(): WeekDay[] {
    const localeData = moment.localeData(this.timeFormat.locale);
    const weekdays = localeData.weekdays().map((label, weekday) => ({
      letter: label[0]?.toUpperCase() ?? '',
      weekday,
    }));

    return weekdays
      .slice(this.timeFormat.firstDayOfWeek.id)
      .concat(weekdays.slice(0, this.timeFormat.firstDayOfWeek.id));
  }

  get dailyTimesFrom() {
    return this.args.selfSchedule.slotRules?.dailyTimes?.firstObject?.from;
  }

  get dailyTimesTo() {
    return this.args.selfSchedule.slotRules?.dailyTimes?.firstObject?.to;
  }

  get selectedDays(): string {
    const weekdays = this.args.selfSchedule.slotRules?.weekdays;
    if (weekdays?.length === 7) {
      return this.intl.t('components.meetings.event_scheduler.daily');
    }

    if (
      weekdays?.length === 5 &&
      [1, 2, 3, 4, 5].every((n) => weekdays.includes(n))
    ) {
      return this.intl.t('components.meetings.event_scheduler.weekdays');
    }

    if (weekdays?.length === 2 && [0, 6].every((n) => weekdays.includes(n))) {
      return this.intl.t('components.meetings.event_scheduler.weekends');
    }

    const firstDay = moment.localeData(this.timeFormat.locale).firstDayOfWeek();
    const sortedDays = weekdays?.sort();

    if (firstDay === MONDAY && sortedDays && sortedDays[0] === SUNDAY) {
      sortedDays.shift();
      sortedDays.push(SUNDAY);
    }

    const selectedDays = sortedDays?.map(
      (d: number) => this.weekDayOptions.find((w) => w.weekday === d)?.letter
    );

    const selectedString = selectedDays?.join(', ');

    return `${selectedString},`;
  }

  get label(): string {
    return `${this.selectedDays} ${moment(
      this.dailyTimesFrom,
      'HH:mm',
      'UTC'
    ).format(this.timeFormat.format.replace(' ', '\xA0'))}-${moment(
      this.dailyTimesTo,
      'HH:mm',
      'UTC'
    ).format(this.timeFormat.format.replace(' ', '\xA0'))}`;
  }

  get fromDate(): Date {
    return moment.tz(this.dailyTimesFrom!, 'HH:mm', 'UTC').toDate();
  }

  get toDate(): Date {
    return moment.tz(this.dailyTimesTo!, 'HH:mm', 'UTC').toDate();
  }

  @action
  updateStartTime(mTime: moment.Moment): void {
    const to = this.args.selfSchedule.slotRules?.dailyTimes?.firstObject?.to;

    justAssert(to);

    this.args.selfSchedule.slotRules = {
      ...this.args.selfSchedule.slotRules,
      dailyTimes: [
        {
          from: mTime.format('HH:mm') as HourAndMinute,
          to,
        },
      ],
    };
    this.args.onChange?.();
  }

  @action
  updateEndTime(mTime: moment.Moment): void {
    const from =
      this.args.selfSchedule.slotRules?.dailyTimes?.firstObject?.from;

    justAssert(from);

    this.args.selfSchedule.slotRules = {
      ...this.args.selfSchedule.slotRules,
      dailyTimes: [
        {
          to: mTime.format('HH:mm') as HourAndMinute,
          from,
        },
      ],
    };
    this.args.onChange?.();
  }

  @action
  toggleDay(day: WeekDay): void {
    if (
      this.args.selfSchedule.slotRules?.weekdays?.some(
        (d: number) => d === day.weekday
      )
    ) {
      this.args.selfSchedule.slotRules = {
        ...this.args.selfSchedule.slotRules,
        weekdays: this.args.selfSchedule.slotRules.weekdays.filter(
          (d: number) => d !== day.weekday
        ),
      };
    } else {
      this.args.selfSchedule.slotRules = {
        ...this.args.selfSchedule.slotRules,
        weekdays: [
          ...(this.args.selfSchedule.slotRules?.weekdays || []),
          day.weekday,
        ],
      };
    }

    this.args.onChange?.();
  }
}
