import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import BlockLayout from 'teamtailor/models/block-layout';
import IntlService from 'ember-intl/services/intl';
import { sectionIcon } from 'teamtailor/helpers/section-icon';
import ContentEditorController from 'teamtailor/controllers/content/editor';
import { inject as controller } from '@ember/controller';

interface Args {
  sectionTypesAvailable: string[];
}

export interface BlockLayoutCategory {
  name: string;
  translatedName: string;
  iconName?: string;
  iconStyle?: string;
  iconMirror?: boolean;
  blockLayouts: BlockLayout[];
}

export default class BlockLibraryModalBase<AdditionalArgs> extends Component<
  Args & AdditionalArgs
> {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  @controller('content.editor')
  declare editorController: ContentEditorController;

  get loading() {
    return this.editorController.allBlockLayouts !== null;
  }

  get blockLayouts() {
    return (
      this.editorController.allBlockLayouts?.filter((blockLayout) => {
        return this.args.sectionTypesAvailable.includes(blockLayout.blockName);
      }) || []
    );
  }

  get blockLayoutCategories(): BlockLayoutCategory[] {
    const categories: BlockLayoutCategory[] = [];

    this.blockLayouts.forEach((blockLayout) => {
      const category = categories.find(
        (category) => category.name === blockLayout.blockName
      );

      if (category) {
        category.blockLayouts.push(blockLayout);
      } else {
        const icon = sectionIcon(blockLayout.blockName);
        categories.push({
          name: blockLayout.blockName,
          translatedName: this.intl.t(`sections.${blockLayout.blockName}`),
          iconName: icon.name,
          iconStyle: icon.style,
          iconMirror: icon.mirror,
          blockLayouts: [blockLayout],
        });
      }
    });

    return categories.sort((a, b) =>
      a.translatedName.localeCompare(b.translatedName)
    );
  }

  get availableBlockLayoutCategories() {
    return this.blockLayoutCategories.filter((category) =>
      this.args.sectionTypesAvailable.includes(category.name)
    );
  }
}
