/* import __COLOCATED_TEMPLATE__ from './takeover.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { keyResponder, onKey } from 'ember-keyboard';
import { argDefault } from 'teamtailor/utils/arg-default';

@keyResponder
export default class ModalsTakeoverComponent extends Component {
  @argDefault isOpen = true;
  wrapperElement = null;
  keyboardPriority = 100;

  constructor() {
    super(...arguments);
    this.wrapperElement = document.getElementById('ember-teamtailor-modal');
  }

  @onKey('Escape')
  closeOnEscape(event, ekEvent) {
    if (this.isOpen) {
      ekEvent.stopImmediatePropagation();
      ekEvent.stopPropagation();
      this.close();
    }
  }

  @action
  close() {
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}
