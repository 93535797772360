/* import __COLOCATED_TEMPLATE__ from './pinned-messages.hbs'; */
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';
import { get } from 'teamtailor/utils/get';
import ArrayProxy from '@ember/array/proxy';

interface JobsPinnedMessagesArgs {
  activities: ArrayProxy<ActivityModel>;
}

export default class JobsPinnedMessages extends Component<JobsPinnedMessagesArgs> {
  get pinnedMessages(): ActivityModel[] {
    return get(this.args, 'activities')
      .filter((activity) => activity.pinned)
      .sortBy('createdAt')
      .reverse();
  }

  get messageCount(): number | undefined {
    return this.pinnedMessages.length;
  }
}
