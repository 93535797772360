/* import __COLOCATED_TEMPLATE__ from './profile-form.hbs'; */
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { LocationModel, UserModel } from 'teamtailor/models';
import FlipperService from 'teamtailor/services/flipper';
import { BufferedChangeset } from 'validated-changeset';
import { inject as service } from '@ember/service';
import FlashMessageService from 'teamtailor/services/flash-message';
import { IntlService } from 'ember-intl';
import TranslatableFormBaseComponent from '../translatable-form/base';

const REQUIRED_PROPS = ['title', 'description'];

export default class ProfileForm extends TranslatableFormBaseComponent {
  @service declare flipper: FlipperService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  translationsProps = REQUIRED_PROPS;

  get user() {
    return get(this, 'translatableModel') as UserModel;
  }

  @action
  handleSelectLocation(changeset: BufferedChangeset, location: LocationModel) {
    const value =
      get(changeset, 'location.id') === location.id ? null : location;

    set(changeset, 'location', value);
  }

  @action
  handleImageUploaded(pictureUrl: string): Promise<UserModel> {
    this.user.pictureCache = pictureUrl;
    return this.user.save();
  }

  @action
  async handleImageRemoved(): Promise<void> {
    this.user.picture = null;
    await this.user.save();
    this.flashMessages.success(this.intl.t('employees.profile.image_removed'));
  }
}
