/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { AsyncBelongsTo } from '@ember-data/model';
import { modifier } from 'ember-modifier';
import {
  CandidateModel,
  InterviewKitModel,
  InterviewModel,
  JobApplicationModel,
  JobModel,
  PickedQuestionModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import RouterService from '@ember/routing/router-service';
import PermissionsService from 'teamtailor/services/permissions';
import { next } from '@ember/runloop';
import Current from 'teamtailor/services/current';
import { relationsRecordRemover } from 'teamtailor/utils/record-remover';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { keepLatestTask, timeout } from 'ember-concurrency';

interface InterviewFeedSignature {
  Args: {
    candidate: CandidateModel;
    jobApplication?: JobApplicationModel;
    interviewFeedQuery?: InterviewQuery;
  };
}

interface InterviewQuery {
  interviewId?: string;
  interviewKitId?: string;
  jobId?: string;
}

export default class CandidateModalNewRightSidebarInterviews extends Component<InterviewFeedSignature> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare permissions: PermissionsService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked selectedInterview: InterviewModel | null = null;
  @tracked isEditing = false;

  get candidate() {
    return this.args.candidate;
  }

  get isLoading() {
    const interviewsFulfilled = get(this.candidate.interviews, 'isFulfilled');

    if (this.args.jobApplication) {
      const job = get(this.args.jobApplication, 'job') as
        | AsyncBelongsTo<JobModel>
        | undefined;

      const jobDetail = get(job, 'jobDetail');
      const pickedInterviewKits = get(jobDetail, 'pickedInterviewKits');
      const interviewKits = get(jobDetail, 'interviewKits');
      const interviewKitsFulfilled =
        interviewKits !== undefined &&
        pickedInterviewKits !== undefined &&
        interviewKits.length === pickedInterviewKits.length;

      return !interviewsFulfilled || !interviewKitsFulfilled;
    } else {
      return !interviewsFulfilled;
    }
  }

  get user() {
    return this.current.user;
  }

  get company() {
    return this.current.company;
  }

  get userInterviews() {
    return get(this.candidate, 'interviews').filter(
      (interview) => interview.belongsTo('user').id() === this.user.id
    );
  }

  get jobApplications() {
    const jobApplications = get(this.candidate, 'jobApplications');

    if (get(this, 'permissions').has('job/access', 'full')) {
      return jobApplications;
    }

    return jobApplications.filter(
      (jobApplication) =>
        get(get(jobApplication, 'job'), 'isFulfilled') &&
        get(get(jobApplication, 'job'), 'userHasAccess')
    );
  }

  get activeJobApplications() {
    return this.jobApplications.filter((ja) => ja.isActive || ja.isNew);
  }

  get inActiveJobApplications() {
    return this.jobApplications.filter((ja) => !ja.isActive && !ja.isNew);
  }

  willDestroy() {
    super.willDestroy();
    this.cancelForm();
  }

  async unloadInterview(interview: InterviewModel | null) {
    if (!interview) {
      return;
    }

    try {
      const answers = await get(interview, 'answers');
      answers.forEach((answer) => answer.rollbackAttributes());
      const scores = await get(interview, 'scores');
      scores.forEach((score) => score.rollbackAttributes());

      interview.rollbackAttributes();
    } catch (_err) {
      return;
    }
  }

  async saveDraft(interview: InterviewModel | null) {
    if (!interview || interview.isDestroying) {
      return;
    }

    if (get(interview, 'isNew')) {
      if (
        interview.note ||
        interview.completedAnswers.length > 0 ||
        interview.completedScores.length > 0
      ) {
        await this.handleSave('draft');
      } else {
        await this.unloadInterview(interview);
      }
    } else if (interview.isDraft && interview.hasChanges) {
      await this.handleSave('draft');
    }
  }

  async cancelForm(cancelPressed = false) {
    this.isEditing = false;

    if (cancelPressed) {
      await this.unloadInterview(this.selectedInterview);
    } else {
      if (this.selectedInterview?.isDraft) {
        await this.saveDraft(this.selectedInterview);
      }
    }

    this.selectedInterview = null;

    if (this.args.interviewFeedQuery) {
      this.router.transitionTo({
        queryParams: {
          interviewFeedQuery: undefined,
        },
      });
    }
  }

  async createAnswerForQuestion(pickedQuestion: PickedQuestionModel) {
    const question = await pickedQuestion.question;

    const range = question?.isRange ? Number(question.startWith) : null;

    return this.store.createRecord('answer', {
      pickedQuestion,
      range,
      question,
      candidate: this.candidate,
      user: this.user,
    });
  }

  @action
  handleLeaveFeedbackClickEdit(
    interviewKitId: string,
    interviewId?: string,
    jobId?: string
  ) {
    const interviewFeedQuery = {
      interviewKitId,
      ...(interviewId ? { interviewId } : { jobId }),
    };
    this.router.transitionTo({
      queryParams: {
        interviewFeedQuery: JSON.stringify(interviewFeedQuery),
      },
    });
  }

  @action
  async fillInterviewKit(job: JobModel, interviewKit: InterviewKitModel) {
    const answers = await Promise.all(
      get(interviewKit, 'pickedQuestions').map((pickedQuestion) =>
        this.createAnswerForQuestion(pickedQuestion)
      )
    );

    const scores = get(interviewKit, 'scorecardPicks').map((scorecardPick) =>
      this.store.createRecord('scorecard-score', {
        scorecardPick,
        user: this.user,
        candidate: this.candidate,
      })
    );

    this.selectedInterview = this.store.createRecord('interview', {
      user: this.user,
      candidate: this.candidate,
      interviewKit,
      job,
      status: 'draft',
      answers,
      scores,
    });

    this.isEditing = true;
  }

  handleInterviewFeedQuery = modifier(
    (_element: Element, [query]: [InterviewQuery | undefined]) => {
      if (query) {
        this.setInterview(query);
      }
    }
  );

  async setInterview(query: InterviewQuery) {
    const { interviewId, interviewKitId, jobId } = query;
    if (interviewKitId) {
      const interviewKit = await this.store.findRecord(
        'interview-kit',
        interviewKitId
      );

      let interview: InterviewModel | undefined;
      const candidateInterviews = await this.candidate.interviews;

      if (interviewId) {
        interview = candidateInterviews.find(
          (interview) => interview.id === interviewId
        );
      } else {
        interview = this.userInterviews.find(
          (interview) =>
            interview.belongsTo('interviewKit').id() === interviewKitId &&
            interview.belongsTo('job').id() === jobId
        );
      }

      if (interview) {
        await interview.reload();
        this.editInterview(interview, interviewKit);
      } else if (jobId) {
        const job = await this.store.findRecord('job', jobId);
        this.fillInterviewKit(job, interviewKit);
      }
    } else {
      next(() => this.cancelForm());
    }
  }

  @action
  async editInterview(
    interview: InterviewModel,
    interviewKit: InterviewKitModel
  ) {
    const answeredQuestionIds = (await get(interview, 'answers').reload()).map(
      (answer) => answer.belongsTo('question').id()
    );
    const newAnswers = await Promise.all(
      get(interviewKit, 'pickedQuestions')
        .filter((pickedQuestion) => {
          const questionId = pickedQuestion.belongsTo('question').id();
          return questionId && !answeredQuestionIds.includes(questionId);
        })
        .map((pickedQuestion) => this.createAnswerForQuestion(pickedQuestion))
    );

    const answeredScorecardIds = interview.scores.map((score) =>
      score.belongsTo('scorecardPick').id()
    );
    const newScorecards = get(interviewKit, 'scorecardPicks')
      .filter(
        (scorecardPick) => !answeredScorecardIds.includes(scorecardPick.id)
      )
      .map((scorecardPick) => {
        return this.store.createRecord('scorecard-score', {
          scorecardPick,
          user: this.user,
          candidate: this.candidate,
        });
      });

    interview.answers.pushObjects(newAnswers);
    interview.scores.pushObjects(newScorecards);

    this.selectedInterview = interview;
    this.isEditing = true;
  }

  @action
  async handleSave(status: string, autosave = false) {
    if (this.selectedInterview) {
      this.saveInterviewTask.perform(this.selectedInterview, status, autosave);
    }
  }

  @action
  handleCancel() {
    this.cancelForm(true);
  }

  @action
  goBack() {
    this.cancelForm();
  }

  @action
  findUserInterview(interviews: InterviewModel[]) {
    return this.userInterviews.find(
      (interview) => interviews.includes(interview) && !interview.isNew
    );
  }

  @action
  async deleteDraft() {
    this.isEditing = false;
    await this.selectedInterview?.destroyRecord();
    this.selectedInterview = null;
    this.cancelForm();
  }

  saveInterviewTask = keepLatestTask(
    async (
      interview: InterviewModel | null = this.selectedInterview,
      status?: string,
      debounce?: boolean
    ) => {
      if (!interview) {
        return;
      }

      if (debounce) {
        await timeout(1000);
      }

      if (status) {
        interview.status = status;
      }

      await interview.save();

      relationsRecordRemover(interview, 'answers', 'scores');

      if (debounce) {
        return;
      } else if (status === 'draft') {
        const interviewKit = await get(interview, 'interviewKit');
        if (interviewKit) {
          this.flashMessages.success(
            this.intl.t('components.interview_form.draft_saved')
          );
        } else {
          this.flashMessages.success(
            this.intl.t('common.something_went_wrong')
          );
        }
      } else {
        this.isEditing = false;
        this.router.transitionTo({
          queryParams: {
            interviewFeedQuery: undefined,
          },
        });
      }
    }
  );

  handleCandidateChange = modifier((_element, [_candidate]) => {
    this.selectedInterview = null;
    this.isEditing = false;
  });
}
