/* import __COLOCATED_TEMPLATE__ from './color.hbs'; */
import Component from '@glimmer/component';
import { BACKGROUND_COLORS } from 'teamtailor/constants/tag';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class extends Component {
  @service declare intl: IntlService;

  get options() {
    return BACKGROUND_COLORS.map(({ color, name }) => ({
      id: color,
      label: this.intl.t(`colors.${name}`),
    }));
  }
}
