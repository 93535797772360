/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import { AsyncBelongsTo } from '@ember-data/model';
import { action } from '@ember/object';
import { ImageModel } from 'teamtailor/models';
import BaseMediaComponent from './base';

export default class ImageMediaComponent extends BaseMediaComponent {
  @action
  handleUploadButtonClick() {
    this.showImageLibrary = true;
  }

  @action
  async handlePickImage(image: AsyncBelongsTo<ImageModel>) {
    await this.mediaItem.save();
    const pickedImage =
      this.mediaItem.pickedImage ||
      this.store.createRecord('picked-image', {
        connectMediaItem: this.mediaItem,
      });

    pickedImage.image = image;
    await pickedImage.save();

    this.showImageLibrary = false;
    this.args.onSave();
  }
}
