/* import __COLOCATED_TEMPLATE__ from './new-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import HireQualityResponseModel from 'teamtailor/models/hire-quality-response';
import { sixMonths, tomorrow } from 'teamtailor/utils/due-date';
import CandidateModel from 'teamtailor/models/candidate';
import JobApplicationModel from 'teamtailor/models/job-application';
import UserModel from 'teamtailor/models/user';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { isBlank } from '@ember/utils';
import moment from 'moment-timezone';

type Args = {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel;
  user: UserModel;
  onClose: () => void;
};

export default class HireQualitySurveyNewModal extends Component<Args> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked hireQualityResponse: HireQualityResponseModel | null = null;

  quickScheduleOptions = ['twoMonths', 'threeMonths', 'sixMonths', 'oneYear'];

  get minDate(): Date {
    return tomorrow;
  }

  get jobId(): number {
    return get(this.args.jobApplication, 'jobId');
  }

  get saveDisabled(): boolean {
    return (
      isBlank(this.hireQualityResponse?.user) ||
      isBlank(this.hireQualityResponse?.scheduledAt)
    );
  }

  get scheduledAt(): Date | null {
    const scheduledAt = this.hireQualityResponse?.scheduledAt;
    if (scheduledAt) {
      const utcOffset = moment(scheduledAt).utcOffset() * 60000;
      const adjustedDate = new Date(scheduledAt.getTime() - utcOffset);
      return adjustedDate;
    }

    return null;
  }

  get stage() {
    return get(this.args.jobApplication, 'stage');
  }

  get company() {
    return get(this.args.candidate, 'company');
  }

  get job() {
    return get(this.args.jobApplication, 'job');
  }

  get recruiter() {
    return get(this.job, 'recruiter');
  }

  @action
  createHireQualityResponse() {
    this.hireQualityResponse = this.store.createRecord(
      'hire-quality-response',
      {
        company: this.company,
        jobApplication: this.args.jobApplication,
        scheduledAt: sixMonths,
        stage: this.stage,
        user: this.recruiter,
      }
    );
  }

  @action
  onClose(): void {
    this.args.onClose();
    this.hireQualityResponse?.unloadRecord();
  }

  @action
  async onSave(): Promise<void> {
    if (this.hireQualityResponse) {
      await this.hireQualityResponse.save();
      this.flashMessages.success(
        this.intl.t('candidates.hire_quality_survey.survey_created')
      );
    }

    this.args.onClose();
  }
}
