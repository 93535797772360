/* import __COLOCATED_TEMPLATE__ from './requisitions.hbs'; */
import WidgetClass from './base/widget-class';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import CurrentService from 'teamtailor/services/current';
import { RequisitionModel } from 'teamtailor/models';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';

const REQUISITION_LIMIT = 5;

export default class RequisitionsWidget extends WidgetClass {
  @service declare store: Store;
  @service declare current: CurrentService;
  @service declare pusher: PusherService;

  @tracked createdByMeRequisitions: RequisitionModel[] = [];
  @tracked awaitingMyApprovalRequisitions: RequisitionModel[] = [];
  @tracked createdByMeMeta: { total_count: number } | null = null;
  @tracked awaitingMyApprovalRequisitionsMeta: { total_count: number } | null =
    null;

  @tracked toggle: 'created-by-me' | 'awaiting-my-approval' = 'created-by-me';

  private declare channel?: PusherChannel;

  constructor(owner: unknown, args: unknown) {
    super(owner, args);
    this.fetchCreatedRequisitions.perform();
    this.fetchRequestedRequisitions.perform();
    this.setupPusher();
  }

  willDestroy(): void {
    this.teardownPusher();
  }

  get requisitions() {
    return this.toggle === 'created-by-me'
      ? this.createdByMeRequisitions
      : this.awaitingMyApprovalRequisitions;
  }

  get meta() {
    return this.toggle === 'created-by-me'
      ? this.createdByMeMeta
      : this.awaitingMyApprovalRequisitionsMeta;
  }

  get user() {
    return this.current.user;
  }

  get company() {
    return this.current.company;
  }

  get hasRequisitions() {
    return this.requisitionsCount > 0;
  }

  get totalRequisitions() {
    return this.meta?.total_count || this.requisitionsCount || 0;
  }

  get hasMoreRequisitions() {
    return this.totalRequisitions > REQUISITION_LIMIT;
  }

  get requisitionsCount() {
    return this.requisitions.length;
  }

  fetchCreatedRequisitions = restartableTask(async () => {
    const response = await this.store.query('requisition', {
      per_page: REQUISITION_LIMIT,
      user_id: this.user.id,
    });

    this.createdByMeRequisitions = response.toArray();
    this.createdByMeMeta = response.meta;
  });

  fetchRequestedRequisitions = restartableTask(async () => {
    const response = await this.store.query('requisition', {
      per_page: REQUISITION_LIMIT,
      assignee: 'awaiting-my-approval',
      status: 'pending',
    });

    this.awaitingMyApprovalRequisitions = response.toArray();
    this.awaitingMyApprovalRequisitionsMeta = response.meta;
  });

  handleRequisitionUpdate = () => {
    this.fetchCreatedRequisitions.perform();
    this.fetchRequestedRequisitions.perform();
  };

  async setupPusher() {
    this.channel = await this.pusher.subscribe(
      this.current.company.mainChannel
    );
    this.channel.bind('requisition-created', this.handleRequisitionUpdate);
  }

  teardownPusher() {
    if (this.channel) {
      this.channel.unbind('requisition-created', this.handleRequisitionUpdate);
    }
  }

  @action
  toggleSwitch(value: 'created-by-me' | 'awaiting-my-approval') {
    this.toggle = value;
  }
}
