/* import __COLOCATED_TEMPLATE__ from './show-loader.hbs'; */
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import Component from '@glimmer/component';
import { ShowMeetingQueryParams } from 'teamtailor/routes/meetings/events/show';
import Store from '@ember-data/store';
import MeetingEventModel from 'teamtailor/models/meeting-event';

interface Args {
  queryParamArg: ShowMeetingQueryParams;
}

export default class ShowLoader extends Component<Args> {
  @service declare store: Store;
  @tracked declare data?: MeetingEventModel;
  @tracked loading = true;

  @action
  async loadData() {
    this.loading = true;

    this.data = await this.store.findRecord(
      'meeting-event',
      this.args.queryParamArg.meeting_event_id
    );

    this.loading = false;
  }
}
