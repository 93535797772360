/* import __COLOCATED_TEMPLATE__ from './results.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { PartnerResultModel } from 'teamtailor/models';
import PartnerModel from 'teamtailor/models/partner';

interface Signature {
  Args: {
    partner: PartnerModel;
    results: PartnerResultModel[] | undefined;
  };
}

export default class extends Component<Signature> {
  @tracked showAssessmentsModal = false;

  get partnerDomId() {
    return `partner_${this.partner.id}`;
  }

  get partnerResults() {
    if (this.args.results === undefined) {
      return [];
    }

    return this.args.results.filter((pr) => pr.partnerId === this.partner.id);
  }

  get partner() {
    return this.args.partner;
  }

  @action
  toggleAssessmentsModal() {
    this.showAssessmentsModal = !this.showAssessmentsModal;
  }
}
