/* import __COLOCATED_TEMPLATE__ from './tag-list.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';

export default class MediaLibraryTagListComponent extends Component {
  @service store;
  @service imageTags;
  @service current;
  @tracked modalOpen = false;
  @tracked tag = null;

  get user() {
    return this.current.user;
  }

  @action
  openNewTagModal() {
    this.tag = this.store.createRecord('tag', {
      availableOnImages: true,
    });

    this.modalOpen = true;
  }

  @action
  handleTagSaved() {
    this.imageTags.refresh();
  }

  @action
  handleModalClose() {
    if (this.tag?.isNew) {
      this.tag.unloadRecord();
    }

    next(() => {
      this.modalOpen = false;
    });
  }

  @action
  handleInsert() {
    if (this.imageTags.hasRun) {
      this.imageTags.refresh();
    }
  }
}
