/* import __COLOCATED_TEMPLATE__ from './ams.hbs'; */
import PromotionBaseClass from './promotion-base';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class extends PromotionBaseClass {
  @service current;
  @service intl;

  @tracked checkoutData;

  get amsOccupationFields() {
    return this.store.peekAll('occupation-field');
  }

  get amsMunicipalityCodes() {
    return this.store.peekAll('municipality-code');
  }

  get sortedCountriesList() {
    return this.store.peekAll('country').sortBy('name');
  }

  get municipalityCodeOutsideSweden() {
    return get(this, 'promotion.municipalityCode.id') === '9999';
  }

  get amsCountryCode() {
    return this.sortedCountriesList.findBy(
      'code',
      get(this, 'promotion.amsCountryCode')
    );
  }

  get locations() {
    return get(this.current.company, 'locations');
  }

  get companySwedishLocations() {
    return this.locations.filter(({ countryCode }) => countryCode == 'SE');
  }

  get experienceRequiredOptions() {
    return [
      {
        text: this.intl.t('components.job.promotion.ams.not_required'),
        value: false,
      },
      {
        text: this.intl.t('components.job.promotion.ams.required'),
        value: true,
      },
    ];
  }

  @action
  setExperienceRequired(option) {
    this.args.promotion.amsExperienceRequired = option.value;
  }

  get fullTimeOptions() {
    return [
      {
        text: this.intl.t('components.job.promotion.ams.full_time'),
        value: true,
      },
      {
        text: this.intl.t('components.job.promotion.ams.part_time'),
        value: false,
      },
    ];
  }

  @action
  setFullTime(option) {
    this.args.promotion.amsFullTime = option.value;
  }

  @action
  setPromotionLocation() {
    next(() => {
      if (!get(this, 'promotion.location.id')) {
        set(this, 'promotion.location', this.amsLocation);
      }
    });
  }

  get amsLocation() {
    if (get(this, 'promotion.location.id')) {
      return get(this, 'promotion.location');
    } else {
      let swedishJobLocations = get(this, 'promotion.job.locations').filterBy(
        'countryCode',
        'SE'
      );

      let location = this.companySwedishLocations.findBy(
        'id',
        get(swedishJobLocations, 'firstObject.id')
      );

      return location;
    }
  }

  get durationOptions() {
    return [
      { value: 1, name: this.intl.t('components.job.promotion.ams.regular') },
      {
        value: 2,
        name: this.intl.t('components.job.promotion.ams.temporary_6_months'),
      },
      {
        value: 3,
        name: this.intl.t('components.job.promotion.ams.temporary_3_6_months'),
      },
      {
        value: 7,
        name: this.intl.t(
          'components.job.promotion.ams.temporary_11_days_3_months'
        ),
      },
      {
        value: 8,
        name: this.intl.t('components.job.promotion.ams.temporary_max_10_days'),
      },
    ];
  }

  @action
  setAmsDuration(option) {
    set(this, 'promotion.amsDuration', option.value);
  }

  @action
  selectMunicipality(option) {
    set(this, 'promotion.municipalityCode', option);
  }

  @action
  localeSortByName(a, b) {
    return get(a, 'name').localeCompare(get(b, 'name'), 'sv');
  }
}
