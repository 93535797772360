/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
import Component from '@glimmer/component';
import { get, set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';

export default class NurtureCampaignSelectorComponent extends Component {
  @service store;

  @tracked selectedNurtureCampaign = this.args.selected;

  fetchNurtureCampaignsTask = dropTask(async ({ searchTerm: query, page }) => {
    let candidateNurtureCampaignIds = [];

    if (!isEmpty(this.args.candidate)) {
      const candidateNurtureCampaigns = await get(
        this.args.candidate,
        'nurtureCampaignRecipients'
      );
      candidateNurtureCampaignIds =
        candidateNurtureCampaigns.mapBy('nurtureCampaign.id');
    }

    const nurtureCampaigns = await this.store.query('nurture-campaign', {
      page: page || get(this, 'page'),
      per_page: 10,
      query,
      status: 'available',
      sort_direction: 'asc',
    });

    let result = nurtureCampaigns.map((nurtureCampaign) => {
      let { id, name, status } = nurtureCampaign;
      return {
        id,
        name: name || 'Draft',
        status,
        nurtureCampaign,
        disabled: candidateNurtureCampaignIds.indexOf(id) > -1,
      };
    });
    set(result, 'meta', nurtureCampaigns.meta);
    return result;
  });

  disableCandidateNurtureCampaigns(
    nurtureCampaigns,
    candidateNurtureCampaignIds
  ) {
    nurtureCampaigns.forEach((nurtureCampaign) => {
      nurtureCampaign.disabled =
        candidateNurtureCampaignIds.indexOf(nurtureCampaign.id) > -1;
    });
  }

  @action
  onChange(nurtureCampaign) {
    this.selectedNurtureCampaign = nurtureCampaign;
    this.args.handleChange(nurtureCampaign);
  }
}
