/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface UnicornSectionSignature {
  Args: object;
}

export default class UnicornNavigationComponent extends Component<UnicornSectionSignature> {
  @service declare router: RouterService;

  @tracked
  el: HTMLElement | undefined;

  get id(): string {
    if (this.el !== undefined) {
      let index = 0;
      this.el.parentNode?.querySelectorAll('section').forEach((el, i) => {
        if (el === this.el) {
          index = i;
        }
      });

      return `${this.router.currentRouteName}-${index}`;
    }

    return '';
  }

  @action
  mounted(element: HTMLElement) {
    this.el = element;
  }
}
