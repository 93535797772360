/* import __COLOCATED_TEMPLATE__ from './share.hbs'; */
import BaseComponent from './base';
import { tracked } from '@glimmer/tracking';
import { action, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import recordRemover from 'teamtailor/utils/record-remover';
import { get } from 'teamtailor/utils/get';

const DEFAULT_ALLOWED_SHARE_CANDIDATES_PARAMS = {
  personal_information: true,
  linked_in: true,
  resume: true,
  pitch: true,
  answers: true,
  locations: true,
  recommendations: true,
  allow_internal_documents: true,
  allow_candidate_documents: true,
  allow_cover_letters: true,
  allow_interview_kits: true,
  anonymous: false,
  restricted: false,
  password: '',
};

export default class ShareComponent extends BaseComponent {
  @service flashMessages;
  @service store;

  @tracked shareEmails;
  @tracked invalidAddresses;
  @tracked shareJobContext = 'onlyCurrent';
  @tracked messageTemplateId;
  @tracked interviewKitToggles;

  @tracked shareMessageSubject = this.intl.t(
    'components.bulk_actions.candidates.help_review_candidates'
  );

  @tracked shareMessageBody = this.intl.t(
    'components.bulk_actions.candidates.share_message_body'
  );

  @tracked allowedShareCandidatesParams = Object.assign(
    {},
    DEFAULT_ALLOWED_SHARE_CANDIDATES_PARAMS
  );

  @tracked errors;

  constructor(owner, args) {
    super(owner, args);

    this.createInterviewKitToggles();
  }

  get shareEmailsIsEmpty() {
    return !this.shareEmails;
  }

  get isJobContext() {
    return this.shareJobContext === 'onlyCurrent';
  }

  get pickedInterviewKits() {
    const { job } = this.args;
    const pickedKits = get(job, 'jobDetail.pickedInterviewKits') || [];

    return pickedKits.filter((pickedInterviewKit) => {
      return !get(pickedInterviewKit, 'isHidden');
    });
  }

  setErrors(message) {
    this.errors = [{ message }];
  }

  resetErrors() {
    this.errors = null;
  }

  isPasswordValid() {
    const isValid =
      !this.allowedShareCandidatesParams.restricted ||
      !isEmpty(this.allowedShareCandidatesParams.password);

    if (isValid) this.resetErrors();
    else this.setErrors(this.intl.t('components.share_link.password_invalid'));

    return isValid;
  }

  updateShareCandidateParam(option, value) {
    this.allowedShareCandidatesParams = {
      ...this.allowedShareCandidatesParams,
      [option]: value,
    };
  }

  async createInterviewKitToggles() {
    const toggles = await Promise.all(
      this.pickedInterviewKits.map(async (pickedInterviewKit) => {
        const interviewKit = await pickedInterviewKit.interviewKit;
        return {
          id: pickedInterviewKit.id,
          label: interviewKit.name,
          checked: true,
        };
      })
    );

    this.interviewKitToggles = toggles;
  }

  @action
  togglePasswordProtection() {
    this.updateShareCandidateParam(
      'restricted',
      !this.allowedShareCandidatesParams.restricted
    );
    if (!this.allowedShareCandidatesParams.restricted) this.resetErrors();
  }

  @action
  setPassword(password) {
    this.updateShareCandidateParam('password', password);
  }

  @action
  handlePasswordChange(event) {
    this.updateShareCandidateParam('password', event.target.value);

    if (!isEmpty(this.allowedShareCandidatesParams.password)) {
      this.resetErrors();
    }
  }

  @action
  async shareCandidates() {
    if (!this.isPasswordValid()) throw new Error('Password not valid');

    let { selectedCandidates } = this.args;
    let candidateAnonymals = [];

    if (this.allowedShareCandidatesParams.anonymous) {
      if (this.args.selectedJobApplications) {
        const promises = this.args.selectedJobApplications.map(
          (jobApplication) => {
            return get(jobApplication, 'candidate');
          }
        );

        selectedCandidates = await Promise.all(promises);
      }

      candidateAnonymals = selectedCandidates.map((candidate) => {
        return {
          candidate_id: get(candidate, 'id'),
          anonymous_name: get(candidate, 'anonymousName'),
          anonymal_icon_url: get(candidate, 'anonymalIconUrl'),
          anonymous_color: get(candidate, 'anonymousColor'),
        };
      });
    }

    let excludedPickedInterviewKitIds = [];
    if (
      this.allowedShareCandidatesParams.allow_interview_kits &&
      this.interviewKitToggles &&
      this.isJobContext
    ) {
      excludedPickedInterviewKitIds = this.interviewKitToggles
        .filter((item) => !item.checked)
        .map((item) => item.id);
    }

    const promise = this.postAction(
      'bulk_share',
      this.postData({
        emails: this.shareEmails,
        message: this.shareMessageBody,
        subject: this.shareMessageSubject,
        allowed_share_params: Object.assign(
          {},
          this.allowedShareCandidatesParams
        ),

        candidate_anonymals: candidateAnonymals,
        excluded_picked_interview_kit_ids: excludedPickedInterviewKitIds || [],

        ...(get(this.args, 'job.id') &&
          this.isJobContext && {
            job_id: get(this.args, 'job.id'),
          }),
      })
    );

    promise.then(
      () => {
        setProperties(this, {
          shareEmails: '',
          invalidAddresses: false,
          allowedShareCandidatesParams: DEFAULT_ALLOWED_SHARE_CANDIDATES_PARAMS,
        });

        this.flashMessages.success(
          this.intl.t(
            'components.bulk_actions.candidates.shared_candidates_count',
            { count: this.args.selectedCount }
          )
        );

        this.args.onClose();
      },
      () => {
        setProperties(this, {
          invalidAddresses: true,
        });
      }
    );

    return promise;
  }

  @action
  toggleAllowedShareParams(key) {
    set(
      this.allowedShareCandidatesParams,
      key,
      !get(this.allowedShareCandidatesParams, key)
    );
  }

  @action
  selectedCannedResponse(response) {
    this.shareMessageSubject = get(response, 'subject');
    this.shareMessageBody = get(response, 'body');
    this.messageTemplateId = get(response, 'id');
  }

  @action
  onClose() {
    setProperties(this, { invalidAddresses: false });
    recordRemover(this.store, 'share-link');
    this.args.onClose();
  }

  @action
  toggleAnonymous() {
    this.allowedShareCandidatesParams.anonymous =
      !this.allowedShareCandidatesParams.anonymous;
  }
}
