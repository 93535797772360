import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class PickedImage extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  serialize() {
    let json = super.serialize(...arguments);
    if (json.section_id) {
      json.owner_id = json.section_id;
      json.owner_type = 'ContentBlock::Section';
    } else if (json.section_item_id) {
      json.owner_id = json.section_item_id;
      json.owner_type = 'ContentBlock::SectionItem';
    } else if (json.job_detail_id) {
      json.owner_id = json.job_detail_id;
      json.owner_type = 'Job';
    } else if (json.location_id) {
      json.owner_id = json.location_id;
      json.owner_type = 'Location';
    } else if (json.page_id) {
      json.owner_id = json.page_id;
      json.owner_type = 'Page';
    } else if (json.connect_media_item_id) {
      json.owner_id = json.connect_media_item_id;
      json.owner_type = 'ConnectMediaItem';
    }

    delete json.image_with_setting_id;
    delete json.job_detail_id;
    delete json.section_id;
    delete json.section_item_id;
    delete json.location_id;
    delete json.page_id;
    delete json.connect_media_item_id;

    return json;
  }
}
