/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent, {
  BaseComponentArgs,
} from 'teamtailor/components/content-block/base-component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import Owner from '@ember/owner';
import { AsyncHasMany } from '@ember-data/model';
import SectionItemModel from 'teamtailor/models/section-item';
import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';

type NumberBaseComponentArgs = {
  sectionItems: AsyncHasMany<SectionItemModel>;
} & BaseComponentArgs;

export default class NumbersBaseComponent extends BaseComponent<NumberBaseComponentArgs> {
  @service declare store: Store;

  @tracked draggingEnabled = false;

  constructor(owner: Owner, args: NumberBaseComponentArgs) {
    super(owner, args);

    this.addEmptyItemsIfListEmpty();
  }

  @action async addEmptyItemsIfListEmpty() {
    await get(this.args.model, 'sectionItems');
    if (isEmpty(get(this.args.model, 'sectionItems'))) {
      this.createEmptyItem();
      this.createEmptyItem();
    }
  }

  @action
  async handleNewSectionItem() {
    await this.createEmptyItem();
    const inputs = document.querySelectorAll('#numbers-item-number-field-'); // No id yet
    if (inputs.length > 0) {
      (inputs[inputs.length - 1] as HTMLElement).focus();
    }
  }

  createEmptyItem() {
    return this.store.createRecord('section-item', {
      section: this.args.model,
    });
  }
}
