/* import __COLOCATED_TEMPLATE__ from './collapsible-section.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface CollapsibleSectionArgs {
  text: string;
  open: boolean | undefined;
}

export default class CollapsibleSectionComponent extends Component<CollapsibleSectionArgs> {
  @tracked open = false;

  constructor(owner: unknown, args: CollapsibleSectionArgs) {
    super(owner, args);
    this.open = this.args.open ?? false;
  }

  @action
  handleSubMenuClick() {
    this.open = !this.open;
  }
}
