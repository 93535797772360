/* import __COLOCATED_TEMPLATE__ from './audio-mix.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { AudioVideoFacade } from 'amazon-chime-sdk-js';

interface VideoMeetingAudioMixArgs {
  audioVideoFacade?: AudioVideoFacade;
}

export default class VideoMeetingAudioMix extends Component<VideoMeetingAudioMixArgs> {
  willDestroy(): void {
    super.willDestroy();
    this.args.audioVideoFacade?.unbindAudioElement();
  }

  @action
  onInsertMeetingAudioElement(element: HTMLAudioElement) {
    this.args.audioVideoFacade?.bindAudioElement(element);
  }
}
