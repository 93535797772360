/* import __COLOCATED_TEMPLATE__ from './posts.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { CareerSiteModel, DepartmentModel, PageModel } from 'teamtailor/models';
import ConnectSettingModel from 'teamtailor/models/connect-setting';
import { inject as service } from '@ember/service';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

interface ConnectSettingsBlocksPosts {
  Args: {
    connectSettings: ConnectSettingModel;
    onConnectSettingsChange: (changedField: string) => Promise<void>;
    careerSite: CareerSiteModel;
    selectedDepartment?: DepartmentModel;
  };
}

export default class ConnectSettingsBlocksPostsComponent extends Component<ConnectSettingsBlocksPosts> {
  anchorValue = 'posts_container';

  @service declare iframePreview: IframePreviewService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare current: Current;

  @action
  handleDragEnd({
    draggedItem,
    sourceIndex,
    targetIndex,
    targetList,
  }: {
    draggedItem: PageModel;
    sourceIndex: number;
    targetIndex: number;
    targetList: PageModel[];
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    let ordered_target_list = targetList.map((post) => post.id);
    ordered_target_list.removeAt(sourceIndex);
    ordered_target_list.insertAt(targetIndex, draggedItem.id);
    this.args.connectSettings.orderedPosts = ordered_target_list;

    this.args.onConnectSettingsChange('posts');
    this.iframePreview.scrollToAnchor(this.anchorValue);
  }

  @action
  async handleNewPost() {
    const page = this.store.createRecord('page', {
      careerSiteId: get(this.args.careerSite, 'id'),
      pageType: 'post',
      publishDate: new Date(),
      users: [this.current.user],
      department: this.args.selectedDepartment,
    });

    await page.save();

    return this.router.transitionTo('content.posts.edit', page);
  }
}
