/* import __COLOCATED_TEMPLATE__ from './boolean.hbs'; */
import Base from './base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { capitalize } from '@ember/string';

export default class extends Base {
  @service intl;

  get displayValue() {
    if (this.args.displayValue) {
      return this.args.displayValue;
    } else if (isPresent(this.value)) {
      return capitalize(this.intl.t(`common.${this.value ? 'on' : 'off'}`));
    } else if (isPresent(this.args.default)) {
      return capitalize(
        this.intl.t(`common.${this.args.default ? 'on' : 'off'}`)
      );
    } else {
      return '-';
    }
  }

  @action
  toggleValue() {
    if (this.args.onToggle) {
      this.args.onToggle();
    } else {
      // Set value to opposite of current value, but set to null if it's the default value
      this.value = isEmpty(this.value) ? !this.args.default : !this.value;
    }

    if (this.args.onChange) {
      this.args.onChange(this.value);
    }
  }
}
