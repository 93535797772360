import Service from '@ember/service';
import { next } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export type BreadCrumb = {
  name: String;
  icon: String;
  route: String;
};

export default class BreadCrumbsService extends Service {
  _crumbs: BreadCrumb[] | undefined = undefined;
  @tracked crumbsArray: BreadCrumb[] = [];

  get crumbs() {
    return [...this.crumbsArray.slice()];
  }

  addCrumb(crumb: BreadCrumb) {
    this.updateTracked();

    const exists = this._crumbs!.find((c) => c.route === crumb.route);
    if (exists) {
      this._crumbs!.splice(this._crumbs!.indexOf(exists), 1, crumb);
    } else {
      this._crumbs!.push(crumb);
    }
  }

  removeCrumb(crumb: BreadCrumb) {
    this.updateTracked();

    const exists = this._crumbs!.find((c) => c.route === crumb.route);
    if (exists) {
      this._crumbs!.splice(this._crumbs!.indexOf(exists), 1);
    }
  }

  updateTracked() {
    if (!this._crumbs) {
      this._crumbs = this.crumbsArray.slice();
    }

    next(() => (this.crumbsArray = [...this._crumbs!]));
  }
}

declare module '@ember/service' {
  interface Registry {
    'bread-crumbs': BreadCrumbsService;
  }
}
