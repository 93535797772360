/* import __COLOCATED_TEMPLATE__ from './jobs-section.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import NotificationsService from 'teamtailor/services/notifications';
import Current from 'teamtailor/services/current';
import { gql } from '@apollo/client/core';
import ApolloService from 'ember-apollo-client/services/apollo';
import { action } from '@ember/object';
import { MetaJobsData } from '../sidebar';
import { SHOW_MENTIONED_JOBS, SHOW_UNREAD_JOBS } from '../channels-list';
import { JobModel, JobCommentStatusModel } from 'teamtailor/models';
import FlipperService from 'teamtailor/services/flipper';

const MARK_ALL_JOBS_AS_READ = gql`
  mutation markAllNotificationsAsRead($userId: ID!) {
    updateFilteredNotifications(
      input: {
        userScope: { userId: $userId }
        filter: { scope: GLOBAL_COMMENTS_UNREAD_ALL_JOBS }
        notificationAttributes: { checked: true }
      }
    ) {
      success
    }
  }
`;

const MARK_MY_JOBS_AS_READ = gql`
  mutation markAllNotificationsAsRead($userId: ID!) {
    updateFilteredNotifications(
      input: {
        userScope: { userId: $userId }
        filter: { scope: GLOBAL_COMMENTS_UNREAD_MY_JOBS }
        notificationAttributes: { checked: true }
      }
    ) {
      success
    }
  }
`;

type Args = {
  jobs: JobModel[];
  currentJob: JobModel;
  sectionName: string;
  isMyJobs?: boolean;
  loadMoreJobs: (isMyJobs?: boolean) => void;
  meta: MetaJobsData;
  filter: string;
  setAllFilter: (filter: 'All') => void;
  setMentionedFilter: (filter: 'Mentioned') => void;
  setUnreadFilter?: (filter: 'Unread') => void;
  jobCommentStatuses?: JobCommentStatusModel[];
};

export default class GlobalCommentsJobSection extends Component<Args> {
  @service declare notifications: NotificationsService;
  @service declare current: Current;
  @service declare apollo: ApolloService;
  @service declare flipper: FlipperService;

  @tracked isDropdownOpen = false;

  get showMarkAllAsRead() {
    const { unreadMentionsTotal } = this.notifications;
    return unreadMentionsTotal > 0;
  }

  get mentionedJobsIds() {
    const mentionedJobs = this.notifications.unreadMentions;
    const mentionedJobsIds = mentionedJobs ? Object.keys(mentionedJobs) : [];
    const jobIds = this.args.jobs.map((job) => job.id);
    return jobIds.filter((id) => mentionedJobsIds.includes(id));
  }

  get unreadJobCommentIds() {
    if (
      this.args.jobCommentStatuses &&
      this.args.jobCommentStatuses.length > 0
    ) {
      return this.args.jobCommentStatuses.map((comment) => {
        return comment.belongsTo('job').id();
      });
    }
  }

  get unreadJobIds() {
    const jobIds = this.args.jobs.map((job) => job.id);
    return jobIds.filter((id) => this.unreadJobCommentIds?.includes(id));
  }

  get filteredJobs() {
    if (this.args.filter === SHOW_MENTIONED_JOBS) {
      return this.args.jobs.filter((job) =>
        this.mentionedJobsIds.includes(job.id)
      );
    } else if (this.args.filter === SHOW_UNREAD_JOBS) {
      return this.args.jobs.filter((job) => this.unreadJobIds.includes(job.id));
    } else {
      return this.args.jobs;
    }
  }

  markAllAsRead = dropTask(async () => {
    await this.apollo.mutate({
      mutation: this.args.isMyJobs
        ? MARK_MY_JOBS_AS_READ
        : MARK_ALL_JOBS_AS_READ,

      variables: {
        userId: this.current.user.id,
        companyUuid: this.current.company.uuid,
      },
    });

    await this.notifications.refreshUnread.perform();
  });

  @action
  openDropdown() {
    this.isDropdownOpen = true;
  }

  @action
  closeDropdown() {
    this.isDropdownOpen = false;
  }
}
