/* import __COLOCATED_TEMPLATE__ from './internal.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class extends Component {
  @service declare intl: IntlService;

  get options() {
    return [
      {
        id: 'true',
        label: this.intl.t('job.internal'),
      },
      {
        id: 'false',
        label: this.intl.t('job.public'),
      },
    ];
  }
}
