import Model, { attr, belongsTo, AsyncBelongsTo } from '@ember-data/model';
import JobModel from './job';
import CompanyModel from './company';
import { set, setProperties, getProperties } from '@ember/object';

type AvatarModelKey = keyof AvatarModel;

export default class AvatarModel extends Model {
  @belongsTo('job', { async: false }) declare job: JobModel;
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('string') declare avatarType: string;
  @attr('string', { defaultValue: '' }) declare chosenEmoji: string;
  @attr('string', { defaultValue: '' }) declare chosenIcon: string;
  @attr('string', { defaultValue: '' }) declare chosenIconPrefix: string;
  @attr('string') declare backgroundColor: string;

  get isTypeLetter() {
    return this.avatarType === 'letter';
  }

  get isTypeEmoji() {
    return this.avatarType === 'emoji';
  }

  get isTypeIcon() {
    return this.avatarType === 'icon';
  }

  toParams() {
    return {
      background_color: this.backgroundColor,
      chosen_emoji: this.chosenEmoji,
      chosen_icon: this.chosenIcon,
      chosen_icon_prefix: this.chosenIconPrefix,
      avatar_type: this.avatarType,
    };
  }

  copyProps(other: AvatarModel) {
    let props = [
      'avatarType',
      'chosenIcon',
      'chosenIconPrefix',
      'chosenEmoji',
      'backgroundColor',
    ] as AvatarModelKey[];
    setProperties(this as AvatarModel, getProperties(other, props));
  }

  resetProperties(properties: AvatarModelKey[]) {
    properties.forEach((property) => {
      set(this as AvatarModel, property, '');
    });
  }

  resetBasedOnType() {
    if (this.isTypeLetter) {
      this.resetProperties(['chosenIcon', 'chosenIconPrefix', 'chosenEmoji']);
    } else if (this.isTypeEmoji) {
      this.resetProperties(['chosenIcon', 'chosenIconPrefix']);
    } else if (this.isTypeIcon) {
      this.resetProperties(['chosenEmoji']);
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    avatar: AvatarModel;
  }
}
