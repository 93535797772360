/* import __COLOCATED_TEMPLATE__ from './cookie-consent.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'teamtailor/config/environment';
export default class extends Component {
  @service router;
  @service cookieConsent;

  constructor() {
    super(...arguments);
    this.cookieConsent.cookieGroups.forEach((cookieGroup) => {
      this[cookieGroup] = this.cookieConsent[cookieGroup];
      this.cookieConsent.applyCookieGroupSideEffect(
        cookieGroup,
        this[cookieGroup]
      );
    });
  }

  @tracked page = 'initial';

  // we have to list all cookieGroups tracked local state here
  // when we drop IE11 we might be able to use: https://github.com/pzuraq/tracked-built-ins
  @tracked functional;

  get isOpen() {
    if (ENV.environment === 'test') {
      return false;
    }

    return (
      !this.cookieConsent.hideCookieConsent &&
      this.router.currentRouteName !== 'cookie-policy' &&
      this.router.currentRouteName !== 'privacy-policy'
    );
  }

  get isSettingsPage() {
    return this.page === 'settings';
  }

  @action
  handleChange(cookieGroup) {
    this[cookieGroup] = !this[cookieGroup];
  }

  @action
  handleSettingsButtonClick() {
    this.cookieConsent.cookieGroups.forEach((cookieGroup) => {
      this.cookieConsent[cookieGroup] = this[cookieGroup];
    });

    this.cookieConsent.hideCookieConsent = true;
  }
}
