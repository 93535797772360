/* import __COLOCATED_TEMPLATE__ from './settings-form.hbs'; */
import { inject as service } from '@ember/service';
import { get, action } from '@ember/object';
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import { tracked } from '@glimmer/tracking';

export default class SettingsForm extends TranslatableFormBaseComponent {
  @service cookieConsent;
  @service current;
  @service accessLevels;
  @service flipper;
  @service timeFormat;
  @service intl;

  @tracked changeEmailModalOpen = false;

  translationsProps = ['signature'];

  get defaultStartOfWeekDay() {
    return this.timeFormat.weekdayForLocale(this.args.changeset.locale).name;
  }

  get company() {
    return this.current.company;
  }

  get user() {
    return this.current.user;
  }

  get userIsCurrentUser() {
    return this.user.id === get(this.args.employee, 'id');
  }

  get appearanceOptions() {
    return [
      {
        value: 'system',
        label: this.intl.t('employees.appearance.system'),
      },
      {
        value: 'dark',
        label: this.intl.t('employees.appearance.dark'),
      },
      {
        value: 'light',
        label: this.intl.t('employees.appearance.light'),
      },
    ];
  }

  get roleAccessLevelName() {
    if (this.accessLevels.roles) {
      return this.accessLevels.roles.find((accessLevel) => {
        return this.args.changeset.accessLevelIds
          .map((id) => id.toString())
          .includes(accessLevel.id);
      }).name;
    }

    return this.args.changeset.accessLevels.findBy('isAddon', false).name;
  }

  get selectedAddons() {
    if (this.accessLevels.addons) {
      return this.accessLevels.addons
        .filter((accessLevel) => {
          return this.args.changeset.accessLevelIds
            .map((id) => id.toString())
            .includes(accessLevel.id);
        })
        .mapBy('name');
    }

    return this.args.changeset.accessLevels
      .filterBy('isAddon', true)
      .mapBy('name');
  }

  @action
  handleManageCookiesClick() {
    this.cookieConsent.hideCookieConsent = false;
  }

  @action
  handleFirstRedactorChange() {
    let snapshot = this.args.changeset.snapshot();
    delete snapshot.changes.signature;
    this.args.changeset.restore(snapshot);
  }

  @action
  onChangeRole(value, addons) {
    this.args.changeset.role = value;
    this.args.changeset.accessLevelIds =
      this.accessLevels.accessLevelIdsWithDefault(value, addons);
  }

  @action
  onChangeAppearance(appearance) {
    this.args.changeset.appearance = appearance.value;
  }

  @action
  togglePublicProfile() {
    this.args.changeset.visible = !this.args.changeset.visible;
  }

  @action
  togglePublicProfileEmail() {
    this.args.changeset.showEmail = !this.args.changeset.showEmail;
  }

  @action
  togglePublicProfilePhone() {
    this.args.changeset.showPhone = !this.args.changeset.showPhone;
  }
}
