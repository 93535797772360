/* import __COLOCATED_TEMPLATE__ from './scorecard-scorer.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { setProperties, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class ScorecardScorerComponent extends Component {
  @service current;
  @service store;

  @tracked personalityScores;
  @tracked skillsScores;
  @tracked scorecardComment;

  get scorecardPicks() {
    return get(this, 'args.job.jobDetail.scorecardPicks');
  }

  get _scorecardScores() {
    return get(this, 'args.job.jobDetail.scorecardScores');
  }

  get scorecardScores() {
    return this._scorecardScores.filterBy('isNew', false);
  }

  getScorecardComment() {
    const existingComment = get(this, 'args.job.scorecardComments').find(
      (comment) => {
        return (
          get(comment, 'user.id') === this.current.user.id &&
          get(comment, 'candidate.id') === this.args.candidate.id
        );
      }
    );

    return (
      existingComment ||
      this.store.createRecord('scorecard-comment', {
        candidate: this.args.candidate,
        user: this.current.user,
        job: this.args.job,
      })
    );
  }

  getScores(picks) {
    return picks.map((pick) => {
      const score = pick.scorecardScores
        .filterBy('candidate.id', this.args.candidate.id)
        .findBy('user.id', this.current.user.id);
      return score || pick.scorecardScores.createRecord();
    });
  }

  @action
  setScores() {
    this.skillsScores = this.getScores(
      get(this, 'args.job.jobDetail.skillsPicks')
    );

    this.personalityScores = this.getScores(
      get(this, 'args.job.jobDetail.personalityPicks')
    );

    this.scorecardComment = this.getScorecardComment();
  }

  @action
  score(scorecardScore, score) {
    setProperties(scorecardScore, {
      score,
      candidate: this.args.candidate,
    });
    scorecardScore.save();
  }

  @action
  save() {
    const promise = this.scorecardComment.save();
    promise.then(() => {
      this.args.onScoreSaved();
    });
    return promise;
  }
}
