/* import __COLOCATED_TEMPLATE__ from './reactions-list-item.hbs'; */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: 'button',
  classNames: 'tt-reactions__reaction',
  classNameBindings: ['yourReaction:tt-reactions__reaction--used'],
  onToggleReaction: () => {},

  user: service(),
  intl: service(),

  reactionUsers: mapBy('reactions', 'user'),

  yourReaction: computed(
    'reactionUsers.@each.id',
    'reactions',
    'user.id',
    function () {
      return get(this, 'reactions').find((reaction) => {
        return get(reaction, 'user.id') === get(this, 'user.id');
      });
    }
  ),

  reactionText: computed(
    'reactionUsers.{@each.nameOrEmail,firstObject.nameOrEmail}',
    'reactions.length',
    function () {
      const numReactions = get(this, 'reactions.length');

      switch (numReactions) {
        case 1: {
          const name = get(this, 'reactionUsers.firstObject.nameOrEmail');
          return this.intl.t(
            'components.reactions_list_item.one_person_reacted_with_this',
            { userName: name }
          );
        }

        case 2: {
          const firstName = get(this, 'reactionUsers.firstObject.nameOrEmail');
          const secondName = get(
            this.reactions.objectAt(1),
            'user.nameOrEmail'
          );

          return this.intl.t(
            'components.reactions_list_item.other_reacted_with_this',
            { firstUserName: firstName, secondUserName: secondName }
          );
        }

        default: {
          const [firstName, ...otherNames] = get(this, 'reactionUsers').map(
            (user) => get(user, 'nameOrEmail')
          );

          return this.intl.t(
            'components.reactions_list_item.other_reacted_with_this',
            { firstUserName: otherNames.join(', '), secondUserName: firstName }
          );
        }
      }
    }
  ),

  click() {
    this.onToggleReaction(get(this, 'emoji'));
  },
});
