import Controller from '@ember/controller';
import { action, set, setProperties } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import SettingsOrganizationDivisionsEditRoute from 'teamtailor/routes/settings/organization/divisions/edit';
import FlashMessageService from 'teamtailor/services/flash-message';
import FlipperService from 'teamtailor/services/flipper';
import TtAlertService from 'teamtailor/services/tt-alert';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsOrganizationDivisionsEditController extends Controller {
  declare model: ModelFrom<SettingsOrganizationDivisionsEditRoute>;

  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  saveDivision = task(async () => {
    try {
      const model = await this.model.save();
      return model;
    } catch {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    }
  });

  @action
  updateDivision(
    modelField: keyof ModelFrom<SettingsOrganizationDivisionsEditRoute>,
    value: unknown
  ) {
    type ModelFieldValue =
      ModelFrom<SettingsOrganizationDivisionsEditRoute>[typeof modelField];
    set(this.model, modelField, value as ModelFieldValue);
  }

  @action
  removeLogo() {
    setProperties(this.model, {
      logo: null,
      logoCache: null,
    });
  }

  @action
  async handleSave() {
    try {
      await this.model.save();

      this.flashMessages.success(
        this.intl.t('settings.divisions.division_was_saved')
      );
      this.router.replaceWith('settings.organization.divisions.index');
      return Promise.resolve();
    } catch {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    }
  }

  @action
  handleDestroy() {
    get(this, 'ttAlert').confirm(
      this.intl.t('settings.divisions.confirm_deleting_division'),
      () => {
        this.model
          .destroyRecord()
          .then(() => {
            get(this, 'flashMessages').success(
              this.intl.t('settings.divisions.division_deleted')
            );
            this.router.transitionTo('settings.divisions');
          })
          .catch(() => {
            this.flashMessages.error(
              this.intl.t('common.something_went_wrong')
            );
          });
      }
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.organization.divisions.edit': SettingsOrganizationDivisionsEditController;
  }
}
