/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service blockAdjustments;

  get isDisabled() {
    return (
      this.blockAdjustments.editingAttribute &&
      this.blockAdjustments.editingAttribute !== this.args.attribute
    );
  }

  get emptyText() {
    return this.args.emptyLabel || '-';
  }

  @action
  onClick() {
    if (!this.isDisabled && this.args.onClick) {
      this.args.onClick();
    }
  }

  @action
  handleMouseEnterTitle(e) {
    const { target } = e;

    if (target.offsetWidth < target.scrollWidth) {
      target.title = target.dataset.title;
    }
  }
}
