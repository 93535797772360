/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import {
  AllowedSizes,
  ALLOWED_SIZES,
  DEFAULT_SIZE,
} from 'teamtailor/constants/form';
import { consume } from 'ember-provide-consume-context';
import { FieldContext } from './form/field';

type Args = {
  size?: AllowedSizes;
  onChange?: (event: Event) => void;
};

export default class CoreInputComponent extends Component<Args> {
  @consume('core/form/field') field?: FieldContext;

  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Input @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }
}
