/* import __COLOCATED_TEMPLATE__ from './mentioned-job.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import NotificationsService from 'teamtailor/services/notifications';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import { JobModel } from 'teamtailor/models';

type Args = {
  job: JobModel;
};

export default class MentionedJobComponent extends Component<Args> {
  @service declare notifications: NotificationsService;

  @tracked mentionUnreadActionIds: string[] = [];

  get job() {
    return this.args.job;
  }

  renderMentionUnreadActionIds = modifier(() => {
    if (this.notifications.unreadMentions) {
      this.mentionUnreadActionIds =
        this.notifications.unreadMentions[this.job.id] || [];
    }
  });
}
