/* import __COLOCATED_TEMPLATE__ from './choice-card.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { guidFor } from '@ember/object/internals';
import {
  DEFAULT_SIZE,
  ALLOWED_SIZES,
  DEFAULT_TYPE,
  ALLOWED_TYPES,
} from 'teamtailor/constants/choice-card';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class ChoiceCard extends Component {
  inputId = `choice-input-${guidFor(this)}`;
  radioId = `choice-radio-${guidFor(this)}`;

  @argDefault outerMargin = true;

  get size() {
    verifyArg(
      this.args.size,
      ALLOWED_SIZES,
      'Choice Card input @size',
      DEFAULT_SIZE
    );
    return this.args.size ?? DEFAULT_SIZE;
  }

  get type() {
    verifyArg(
      this.args.type,
      ALLOWED_TYPES,
      'Choice Card input @type',
      DEFAULT_TYPE
    );
    return this.args.type ?? DEFAULT_TYPE;
  }

  get isChecked() {
    return this.args.groupValue !== undefined
      ? this.args.groupValue === this.args.value
      : this.args.checked;
  }

  @action
  handleChange(evt) {
    if (this.args.disabled) {
      return false;
    }

    if (this.args.type === 'radio') {
      evt.target.checked = true;
    }

    if (this.args.onChange) {
      this.args.onChange(this.args.value);
    }
  }
}
