/* import __COLOCATED_TEMPLATE__ from './jobborse.hbs'; */
import PromotionBaseClass from './promotion-base';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';

export default class extends PromotionBaseClass {
  @service store;
  @service intl;

  @tracked checkoutData;

  get jobborseOccupations() {
    return this.store.peekAll('promotion-categories/jobborse-occupation');
  }

  get jobborseCountries() {
    return get(this, 'store').peekAll('promotion-categories/jobborse-country');
  }

  get minijobOptions() {
    return [
      {
        text: this.intl.t('common.yes'),
        value: 1,
      },
      {
        text: this.intl.t('common.no'),
        value: 0,
      },
    ];
  }

  @action
  selectMiniJob(option) {
    set(this, 'promotion.jobborseMiniJob', option.value);
  }
}
