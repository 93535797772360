/* import __COLOCATED_TEMPLATE__ from './job-match-breakdown.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';
import {
  HireQualityResponseModel,
  ScorecardPickModel,
  ScorecardScoreModel,
} from 'teamtailor/models';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { SCORE_VALUE_ADJUSTMENT } from 'teamtailor/constants/scorecard-scores';
import { tracked } from '@glimmer/tracking';
import { TOPIC } from 'teamtailor/models/scorecard-criterium';

type Args = {
  hireQualityResponse: HireQualityResponseModel;
};

type CompetenceItem = {
  scorecardPick: ScorecardPickModel;
  score: number | null | undefined;
  humanScore: number | null | undefined;
  average: number | null;
  averageRounded: number | null;
  diff: number | null;
};

type CompetenceOrLabel = CompetenceItem | { label: string };

export default class HireQualitySurveyJobMatchBreakdownComponent extends Component<Args> {
  @service declare intl: IntlService;

  @tracked interviewScores?: ScorecardScoreModel[];
  @tracked isExpanded = false;
  @tracked skillScorecardPicks: CompetenceItem[] = [];
  @tracked personalityScorecardPicks: CompetenceItem[] = [];

  get hireQualityResponse(): HireQualityResponseModel {
    return this.args.hireQualityResponse;
  }

  get surveyResponseScores(): ScorecardScoreModel[] {
    return this.hireQualityResponse.scores.toArray();
  }

  get competencesList(): CompetenceOrLabel[] {
    const items: CompetenceOrLabel[] = [];

    if (this.skillScorecardPicks.length) {
      items.push({ label: this.intl.t('common.skills') });
      items.push(...this.skillScorecardPicks);
    }

    if (this.personalityScorecardPicks.length) {
      items.push({ label: this.intl.t('common.traits') });
      items.push(...this.personalityScorecardPicks);
    }

    return items;
  }

  competenceItem(scorecardPick: ScorecardPickModel): CompetenceItem {
    const scorecardScore = this.surveyResponseScores.find((scorecardScore) => {
      return get(scorecardScore.scorecardPick, 'id') === scorecardPick.id;
    });
    const humanScore = scorecardScore?.humanScore;
    const average = this.calculateAverage(scorecardPick);

    return {
      scorecardPick,
      score: scorecardScore?.score,
      humanScore,
      average,
      averageRounded: average
        ? Math.floor(average) - SCORE_VALUE_ADJUSTMENT
        : null,

      diff: this.calculateDiff(humanScore, average),
    };
  }

  calculateTotal(scores: number[]): number {
    return scores.reduce((a, b) => {
      return a + b;
    }, 0);
  }

  calculateAverage(scorecardPick: ScorecardPickModel) {
    const scorecardCriteriumId = get(scorecardPick.scorecardCriterium, 'id');
    const scorecardScores = this.interviewScores?.filterBy(
      'criteriumId',
      scorecardCriteriumId
    );
    const scores = scorecardScores?.mapBy('humanScore').compact();
    return scores?.length
      ? parseFloat((this.calculateTotal(scores) / scores.length).toFixed(1))
      : null;
  }

  calculateDiff(
    score: number | null | undefined,
    average: number | null
  ): number | null {
    if (score) {
      return average ? parseFloat((score - average).toFixed(1)) : score;
    } else {
      return null;
    }
  }

  setPicksByTopics(scorecardPicks: ScorecardPickModel[] | undefined) {
    scorecardPicks?.forEach((scorecardPick) => {
      const item = this.competenceItem(scorecardPick);
      if (scorecardPick.topic === TOPIC.SKILL) {
        this.skillScorecardPicks.push(item);
      } else {
        this.personalityScorecardPicks.push(item);
      }
    });
  }

  fetchData = dropTask(async () => {
    const jobApplication = await this.hireQualityResponse.jobApplication;
    const job = await jobApplication?.job;
    const jobScorecardPicks = (await job?.jobDetail)?.scorecardPicks;
    const scorecardPicks: ScorecardPickModel[] | undefined =
      jobScorecardPicks?.filterBy('weight');

    const candidate = await this.hireQualityResponse.candidate;
    const interviews = await candidate?.interviews;

    this.interviewScores = interviews
      ?.rejectBy('isDraft')
      .filterBy('jobId', job?.id)
      .map((interview) => interview.scores.toArray())
      .flat();

    this.setPicksByTopics(scorecardPicks);
  });

  @action
  expandBreakdown(e: Event) {
    this.isExpanded = !this.isExpanded;
    e.stopPropagation();
    e.preventDefault();
  }
}
