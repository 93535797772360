/* import __COLOCATED_TEMPLATE__ from './role-row.hbs'; */
import { RoleModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import TranslatableFormBaseComponent from '../translatable-form/base';
import { action } from '@ember/object';

const REQUIRED_PROPS = ['name'];

export default class RoleRow extends TranslatableFormBaseComponent<RoleModel> {
  @service declare store: Store;
  translationsProps = REQUIRED_PROPS;

  get role() {
    return this.args.translatableModel;
  }

  @action
  handleRoleNameChange(event: Event) {
    if (this.translatableData) return;

    const target = event.target as HTMLInputElement;

    if (this.args.tabLanguage) {
      this.handleTranslation(this.args.tabLanguage, { name: target.value });
    }
  }
}
