/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { inject as service } from '@ember/service';

export default class BlogBaseComponent extends BaseComponent {
  @service current;

  get company() {
    return this.current.company;
  }
}
