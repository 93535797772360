import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class SettingsController extends Controller {
  @service router;

  get page() {
    return this.model;
  }

  queryParams = ['fromContent'];
  fromContent = false;

  @action
  handleCloseTakeover() {
    if (this.fromContent) {
      this.router.transitionTo('content.index');
    } else {
      const route = this.page.isPostPage
        ? 'content.posts.edit'
        : 'content.editor.index';

      this.router.transitionTo(route, get(this, 'page.id'));
    }
  }
}
