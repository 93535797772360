/* import __COLOCATED_TEMPLATE__ from './custom-fields-rows.hbs'; */
import Component from '@glimmer/component';
import { compare, isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';
import CustomFieldModel from 'teamtailor/models/custom-field';
import JobApplicationModel from 'teamtailor/models/job-application';
import PickedCustomFieldModel from 'teamtailor/models/picked-custom-field';

type CustomFieldsRowsArgs = {
  jobApplications: JobApplicationModel[];
  pinnedCount: number;
  hiddenCandidateIds: string[];
  hoveredJobApplication: JobApplicationModel;
};
type CandidateValueItem = {
  jobApplication: JobApplicationModel;
  pickedCustomField: PickedCustomFieldModel | undefined;
};
type CustomFieldItem = {
  customField: CustomFieldModel;
  results: CandidateValueItem[];
};
type Results = CustomFieldItem[];

export default class CompareCandidatesCustomFieldsRowsComponent extends Component<CustomFieldsRowsArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @tracked customFields?: CustomFieldModel[];

  constructor(owner: unknown, args: CustomFieldsRowsArgs) {
    super(owner, args);
    this.fetchCustomFields.perform();
  }

  get computedResults(): Results {
    const results: Results = [];

    this.customFields?.forEach((customField) => {
      const customFieldItem = this.customFieldValues(customField);

      this.args.jobApplications.forEach((jobApplication) => {
        const candidateResultItem = this.candidateResultItem(
          customField,
          jobApplication
        );
        customFieldItem.results.push(candidateResultItem);
      });

      results.push(customFieldItem);
    });

    return results;
  }

  customFieldValues(customField: CustomFieldModel): CustomFieldItem {
    return {
      customField,
      results: [],
    };
  }

  candidateResultItem(
    customField: CustomFieldModel,
    jobApplication: JobApplicationModel
  ): CandidateValueItem {
    const candidatePickedCustomField = get(
      jobApplication.candidate,
      'pickedCustomFields'
    ).findBy('customFieldId', get(customField, 'id'));

    return {
      jobApplication,
      pickedCustomField: candidatePickedCustomField,
    };
  }

  sort(customFieldItem: CustomFieldItem) {
    const pickedCustomField = (
      jobApplication: JobApplicationModel
    ): PickedCustomFieldModel | undefined => {
      return customFieldItem.results.find(
        (result) => result.jobApplication.id === jobApplication.id
      )?.pickedCustomField;
    };

    const optionValue = (
      pickedCustomField: PickedCustomFieldModel | undefined
    ): string => {
      return (
        customFieldItem.customField.options.findBy(
          'id',
          (pickedCustomField?.value as string[] | undefined)?.[0]
        )?.value || ''
      );
    };

    return (a: JobApplicationModel, b: JobApplicationModel) => {
      const { type } = customFieldItem.customField;
      const aPickedCustomField = pickedCustomField(a);
      const bPickedCustomField = pickedCustomField(b);
      let aValue = aPickedCustomField?.value;
      let bValue = bPickedCustomField?.value;

      if (type === 'CustomField::Checkbox') {
        aValue = isPresent(aValue) ? Number(aValue === 'true') : -1;
        bValue = isPresent(bValue) ? Number(bValue === 'true') : -1;
      }

      if (type === 'CustomField::Select') {
        aValue = optionValue(aPickedCustomField) || '';
        bValue = optionValue(bPickedCustomField) || '';
      }

      if (type === 'CustomField::MultiSelect') {
        return compare(
          (bValue as string[] | undefined)?.length,
          (aValue as string[] | undefined)?.length
        );
      } else if (
        type === 'CustomField::Number' ||
        type === 'CustomField::Date' ||
        type === 'CustomField::Checkbox'
      ) {
        return compare(bValue, aValue);
      } else {
        return aValue
          ? bValue
            ? (aValue as string).localeCompare(bValue as string)
            : -1
          : 1;
      }
    };
  }

  fetchCustomFields = task(async () => {
    const customFields: {
      [id: string]: CustomFieldModel | null;
    } = {};

    const { user } = this.current;
    const canUserViewPrivateFields =
      user.adminOrRecruitmentAdmin || user.recruiter;

    for (const jobApplication of this.args.jobApplications.toArray()) {
      // eslint-disable-next-line no-await-in-loop
      const candidate = await jobApplication.candidate;
      // eslint-disable-next-line no-await-in-loop
      const pickedCustomFields = await candidate?.pickedCustomFields;

      for (const pickedCustomField of pickedCustomFields?.toArray() || []) {
        const { customField } = pickedCustomField;
        if (
          pickedCustomField.value &&
          !get(customField, 'isHidden') &&
          (!get(customField, 'isPrivate') || canUserViewPrivateFields)
        ) {
          customFields[pickedCustomField.customFieldId] =
            // eslint-disable-next-line no-await-in-loop
            await pickedCustomField.customField;
        }
      }
    }

    this.customFields = Object.values(customFields).filter(Boolean);
  });
}
