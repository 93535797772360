/* import __COLOCATED_TEMPLATE__ from './filter-settings.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';
import SelectedFilter from 'teamtailor/utils/insights/reports/filters/selected-filter';
import FilterSettings from 'teamtailor/utils/insights/reports/filter-settings';
import { ReportFilterOption } from 'teamtailor/utils/insights/reports/filters';
import { FilterOption } from 'teamtailor/components/fancy-filters';

interface ComponentArgs {
  onUpdate: (chart: FilterSettings) => void;
  settings: FilterSettings;
  properties: BaseProperty[];
  filters: ReportFilterOption[];
}

export default class ReportsFilterSettingsComponent extends Component<ComponentArgs> {
  get settings() {
    return this.args.settings;
  }

  get activeFilters() {
    return this.args.settings.filters;
  }

  get hasInvalidFilters() {
    return false;
  }

  @action
  handleUpdateSelectedFilter(_filter: SelectedFilter) {
    this.args.onUpdate(this.settings);
  }

  @action
  handleSelectFilter(filterOption: FilterOption) {
    const selectedFilter = new SelectedFilter(filterOption);
    selectedFilter.isOpen = true;
    this.settings.filters = [...this.activeFilters, selectedFilter];

    this.args.onUpdate(this.settings);
  }

  @action
  handleRemoveFilter(filter: SelectedFilter) {
    this.settings.filters = this.activeFilters.filter(
      (item) => item !== filter
    );
    this.args.onUpdate(this.settings);
  }

  @action
  replaceFilter(_filter: SelectedFilter) {
    this.args.onUpdate(this.settings);
  }
}
