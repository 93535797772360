/* import __COLOCATED_TEMPLATE__ from './media.hbs'; */
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Base from './base';

export default class EditorAdjustmentsInputsMedia extends Base {
  @service filthyContent;

  @tracked mediaPickerOpen = false;

  get imageUrl() {
    return get(this, `args.model.${this.args.attribute}.s3Image`);
  }

  @action
  handlePickImage(image) {
    this.value = image;
    this.filthyContent.setFilthy(this.args.model.id);
  }

  @action
  handleRemoveImage() {
    this.value = null;
    this.filthyContent.setFilthy(this.args.model.id);
  }

  @action
  handleOnOpenPicker() {
    this.mediaPickerOpen = true;
  }

  @action
  handleModalClose() {
    this.mediaPickerOpen = false;
  }
}
