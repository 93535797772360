import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import SettingsShareLinksController from 'teamtailor/controllers/settings/share-links';

export default class ShareLinksRoute extends ScrollToTopRoute {
  @service declare current: Current;

  resetController(
    controller: SettingsShareLinksController,
    isExiting: boolean
  ) {
    if (isExiting) {
      const { company } = this.current;
      if (get(company, 'hasDirtyAttributes')) {
        company.rollbackAttributes();
      }

      if (controller.dataRevoked) {
        controller.dataRevoked = false;
      }

      if (controller.userChannel) {
        controller.userChannel.unbind('candidate-sharing-data-revoked');
      }
    }
  }
}
