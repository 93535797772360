/* import __COLOCATED_TEMPLATE__ from './trigger-modal.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { saveTrigger } from '../utils/triggers';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import TtAlertService from 'teamtailor/services/tt-alert';
import { AllTriggerModels } from 'teamtailor/models/trigger';
import JobModel from 'teamtailor/models/job';
import StageModel from 'teamtailor/models/stage';
import { isSmartMove } from 'teamtailor/models/trigger/smart-move';
import { get } from 'teamtailor/utils/get';

export type CloseReason = 'saved' | 'closed' | 'destroyed';

type TriggerModalArgs = {
  isOpen?: boolean;
  onClose?: (reason: CloseReason) => Promise<void>;
  onSave?: () => Promise<void>;
  onDestroy?: () => Promise<void>;
  trigger: AllTriggerModels;
  job?: JobModel;
  stage?: StageModel;
};

export default class TriggerModalComponent extends Component<TriggerModalArgs> {
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @tracked declare configValid: boolean;
  @tracked moveEnabled = false;

  get isOpen() {
    return this.args.isOpen ?? true;
  }

  set isOpen(_val) {
    // only here because old Modal component sets `this.isOpen` and without this setter it results in this error when closing modal (not using route change):
    // Cannot set property isOpen of #<TriggerModalComponent> which has only a getter
  }

  get job() {
    return this.args.job || get(this.args.trigger, 'job');
  }

  get stage() {
    return this.args.stage || get(this.args.trigger, 'stage');
  }

  get style() {
    return get(this.args.trigger, 'type') === 'nurture' ? 'kit' : 'regular';
  }

  get otherTriggers() {
    return get(this.stage, 'triggers');
  }

  get validTrigger() {
    const { trigger } = this.args;
    if (isSmartMove(trigger)) {
      const result = trigger.moves?.every((move) => {
        return move.valid;
      });

      const hasProceedStages = trigger.moves?.every((move) => {
        return move.hasProceedStage;
      });

      return result && hasProceedStages;
    }

    const { type, valid } = trigger;
    if (type === 'webhook') {
      return (
        valid &&
        this.configValid &&
        (!this.moveEnabled || get(trigger.proceedStage, 'id'))
      );
    }

    return valid;
  }

  get hasTrigger() {
    return !!this.args.trigger;
  }

  get componentName() {
    const triggerType = get(this.args.trigger, 'type');
    return `trigger-${triggerType}-form`;
  }

  get hasSmartMoveOrPredictionTrigger() {
    return this.otherTriggers.some(
      (trigger) =>
        get(trigger, 'type') === 'smart-move' ||
        get(trigger, 'type') === 'prediction'
    );
  }

  get hasRestrictedCandidatesTrigger() {
    return this.otherTriggers.some((trigger) => {
      return get(trigger, 'type') === 'restrict';
    });
  }

  @tracked declare modalMainEl: HTMLElement;

  isRestrictedToSingleTrigger = (type: AllTriggerModels['type']) => {
    return (
      (type === 'smart-move' && this.hasSmartMoveOrPredictionTrigger) ||
      (type === 'restrict' && this.hasRestrictedCandidatesTrigger)
    );
  };

  @action
  handleDidInsertModalMain(el: HTMLElement) {
    this.modalMainEl = el;
  }

  @action
  setConfigValid(isValid: boolean) {
    this.configValid = isValid;
  }

  @action
  setMoveEnabled(isEnabled: boolean) {
    this.moveEnabled = isEnabled;
  }

  @action
  async handleClose() {
    await this.args.onClose?.('closed');
  }

  @action
  async handleSave() {
    if (this.args.onSave) {
      await this.args.onSave();
    } else {
      await saveTrigger(this.args.trigger);
    }

    await this.args.onClose?.('saved');
  }

  @action
  async handleDestroy() {
    if (this.args.onDestroy) {
      await this.args.onDestroy();
    } else {
      this.args.trigger.destroyRecord();
    }

    await this.args.onClose?.('destroyed');
  }

  @action
  destroyTrigger() {
    if (this.args.trigger.type === 'smart-move') {
      this.ttAlert.customConfirm({
        title: this.intl.t('components.trigger_modal.delete_smart_move_title'),
        text: this.intl.t(
          'components.trigger_modal.cannot_be_undone_for_smart_move'
        ),

        cancelButtonText: this.intl.t('common.no'),
        confirmButtonClass: 'btn-danger',
        confirmButtonText: this.intl.t('common.yes'),
        confirmCallback: () => {
          this.handleDestroy();
        },
      });
    } else {
      this.ttAlert.confirm(
        this.intl.t('components.trigger_modal.cannot_be_undone'),
        () => {
          this.handleDestroy();
        }
      );
    }
  }
}
