/* import __COLOCATED_TEMPLATE__ from './interview-kit-form.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';

import TtAlertService from 'teamtailor/services/tt-alert';
import CurrentService from 'teamtailor/services/current';
import PermissionsService from 'teamtailor/services/permissions';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import ScorecardCriteriumModel from 'teamtailor/models/scorecard-criterium';
import QuestionModel from 'teamtailor/models/question';
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';
import TagModel from 'teamtailor/models/tag';
import JobModel from 'teamtailor/models/job';

import { OrderedCompetenceParent } from 'teamtailor/utils/competence/ordered-list-helpers';
import { get } from 'teamtailor/utils/get';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { tracked } from '@glimmer/tracking';
import TranslationInterviewKitModel from 'teamtailor/models/translation/interview-kit';
import { AsyncBelongsTo } from '@ember-data/model';
import QuestionTranslationsService from 'teamtailor/services/question-translations';

type LanguageTab = {
  index: number;
  languageCode: string;
};

type InterviewKitFormComponentArgs = {
  interviewKit: InterviewKitModel;
  job?: JobModel;
  noActions: boolean;
  onSave: () => Promise<void>;
};

export default class InterviewKitFormComponent extends Component<InterviewKitFormComponentArgs> {
  @service declare questionTranslations: QuestionTranslationsService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare current: CurrentService;
  @service declare permissions: PermissionsService;

  @tracked newQuestions: QuestionModel[] = [];
  @tracked questionGroupsElement?: HTMLElement;

  @tracked activeIndex = 0;
  @tracked declare translation?: TranslationInterviewKitModel;

  requiredProps = ['name'];

  get interviewKit() {
    return this.args.interviewKit;
  }

  get workingSet() {
    if (this.activeIndex === 0 || !this.translation) {
      return this.interviewKit;
    }

    return this.translation;
  }

  get instructionsPlaceholder() {
    if (this.interviewKit.instructions) {
      // if it starts with <p> we return the text until the first closing tag plus elipsis ...
      let placeholder = this.interviewKit.instructions.replace('<br>', ' ');
      if (this.interviewKit.instructions.startsWith('<p>')) {
        return `${placeholder.slice(3).split('</p>')[0]}...`;
      }
    }

    return '';
  }

  get company() {
    return this.current.company;
  }

  get careerSites() {
    return get(this.current.company, 'careerSites');
  }

  get careerSitesLanguages() {
    return this.careerSites.map((careerSite) => careerSite.languageCode);
  }

  get selectedQuestions(): (QuestionModel | AsyncBelongsTo<QuestionModel>)[] {
    const pickedQuestions = get(this.interviewKit, 'pickedQuestions')
      .filter((pickedQuestion) => !!pickedQuestion.id)
      .map((pickedQuestion) => get(pickedQuestion, 'question'));
    return [...pickedQuestions, ...this.newQuestions];
  }

  get interviewKitScorecardPicks() {
    return get(this.interviewKit, 'scorecardPicks').filter(
      (scorecardPick: ScorecardPickModel) => !!scorecardPick.name
    );
  }

  get dataLoaded() {
    return !get(this.interviewKit, 'questions').find(
      (question) => !get(question, 'title')
    );
  }

  get translations() {
    return get(this.interviewKit, 'translations');
  }

  get interviewKitTranslatedLanguages() {
    return this.translations.map((translation) => translation.languageCode);
  }

  get missingTranslationsForQuestions() {
    if (this.selectedQuestions.length === 0) return [];

    const missingTranslations = this.careerSitesLanguages.map((language) => {
      const anyQuestionMissingTranslation = this.selectedQuestions.find(
        (question) => {
          const translatedQuestions =
            this.questionTranslations.getQuestionTranslations(question);
          const questionTranslatedLanguages = translatedQuestions.map(
            (translation) => translation.languageCode
          );

          if (
            this.interviewKitTranslatedLanguages.includes(language) &&
            !questionTranslatedLanguages.includes(language)
          ) {
            return true;
          }

          return !translatedQuestions.find(
            (questionTranslation) =>
              questionTranslation.languageCode === language
          );
        }
      );

      return anyQuestionMissingTranslation ? language : null;
    });

    return missingTranslations.filter(
      (language) =>
        !!language &&
        language !== get(this.current.company.defaultCareerSite, 'languageCode')
    );
  }

  get hasMissingTranslationsForCurrentLanguage() {
    return this.missingTranslationsForQuestions.includes(
      this.workingSet.languageCode
    );
  }

  findOrCreateTranslation(languageCode: string): TranslationInterviewKitModel {
    const translation = this.translations.find((translation) => {
      return translation.languageCode === languageCode;
    });

    if (translation) {
      set(this.interviewKit, 'translations', [...this.translations.slice()]);
      return translation;
    } else {
      const newTranslation = this.store.createRecord(
        'translation/interview-kit',
        {
          languageCode,
          instructions: '',
          name: '',
          interviewKit: this.interviewKit,
        }
      );

      let newTranslations = this.translations.slice();
      const existingLanguageCodes = this.translations.map(
        (translation) => translation.languageCode
      );
      const newLanguageCode = newTranslation.languageCode;
      if (!existingLanguageCodes.includes(newLanguageCode)) {
        newTranslations.push(newTranslation);
      }

      set(this.interviewKit, 'translations', newTranslations);

      return newTranslation;
    }
  }

  @action
  handleSaveByEnter(event: KeyboardEvent) {
    const keyCode = 'Enter';
    const code = event.code || event.key;
    if (code === keyCode) {
      event.preventDefault();
      this.args.onSave();
    }
  }

  @action
  hideOrRemoveKit() {
    const removeInterviewKitAction = get(this.interviewKit, 'hasInterviews')
      ? 'archive'
      : 'delete';
    get(this, 'ttAlert').confirm(
      this.intl.t(
        `components.interview_kit_form.${removeInterviewKitAction}_kit_confirmation`
      ),
      () => {
        this.interviewKit.destroyRecord().then(() => {
          get(this, 'router').transitionTo('settings.interview-kits');
        });
      }
    );
  }

  @action
  handleSelectQuestionWithParent(
    question: QuestionModel,
    parentCompetence?: OrderedCompetenceParent
  ) {
    this.interviewKit.addPickedQuestion(question, parentCompetence);
    this.newQuestions.addObject(question);
  }

  @action
  handleSelectQuestion(question: QuestionModel) {
    this.interviewKit.addPickedQuestion(question);
    this.newQuestions.addObject(question);
  }

  @action
  async handleRemoveQuestion(question: QuestionModel) {
    const questionId = get(question, 'id');

    const pickedQuestion = this.interviewKit.pickedQuestions.find((pq) => {
      if (questionId) {
        return get(pq.question, 'id') === questionId;
      }

      return get(pq.question, 'title') === get(question, 'title');
    });

    if (pickedQuestion) {
      this.interviewKit.removePickedQuestion(pickedQuestion);
      const question = await get(pickedQuestion, 'question');
      pickedQuestion.unloadRecord();

      if (question && get(question, 'isNew')) {
        question.unloadRecord();
      }
    }

    this.newQuestions.removeObject(question);
  }

  @action
  handleChangeTags(tag: TagModel) {
    toggleInList(this.interviewKit.tags, tag);
  }

  @action
  handleQuestionGroupsInsert(element: HTMLElement) {
    this.questionGroupsElement = element;
  }

  @action
  handleInstructionsChange(newText: string) {
    if (this.activeIndex === 0 || !this.translation) {
      set(this.interviewKit, 'instructions', newText);
    } else {
      set(this.translation, 'instructions', newText);
    }
  }

  @action
  handleSelectCriteria(scorecardCriterium: ScorecardCriteriumModel): void {
    const id = get(scorecardCriterium, 'id');
    const name = get(scorecardCriterium, 'name');
    const existingPick = this.interviewKitScorecardPicks.find(
      (scorecardPick: ScorecardPickModel) => {
        const innerScorecardCriterium = get(
          scorecardPick,
          'scorecardCriterium'
        );

        if (id) {
          return get(innerScorecardCriterium, 'id') === id;
        } else {
          return get(innerScorecardCriterium, 'name') === name;
        }
      }
    );

    if (existingPick) {
      this.interviewKit.removeScorecardPick(existingPick);
    } else {
      this.interviewKit.addScorecardPick(scorecardCriterium);
    }
  }

  @action
  handleTab(tab: LanguageTab): void {
    this.activeIndex = tab.index;
    if (
      get(this.current.company.defaultCareerSite, 'languageCode') ===
      tab.languageCode
    ) {
      this.translation = undefined;
    } else {
      this.translation = this.findOrCreateTranslation(tab.languageCode);
    }
  }
}
