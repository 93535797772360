/* import __COLOCATED_TEMPLATE__ from './section-change.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ContentSectionChangeComponent extends Component {
  @service intl;
  @service timeFormat;

  get isDeleted() {
    return this.args.item.action === 'deleted';
  }

  get sectionChangeType() {
    if (this.args.item.hasPostContentChanges) {
      return 'posts';
    }

    if (this.args.item.hasPageSettingsChanges) {
      return 'page_settings';
    }

    if (this.args.item.isConnect) {
      return 'connect';
    }

    return this.args.item.sectionName || this.args.item.sectionCustomName;
  }

  get sectionName() {
    if (this.args.item.hasPostContentChanges) {
      return this.intl.t(`content.index.publish_all_changes.post`);
    }

    if (this.args.item.hasPageSettingsChanges) {
      return this.intl.t(`content.index.publish_all_changes.page_settings`);
    }

    if (!this.args.item.sectionCustomName && !this.args.item.sectionName) {
      return this.intl.t('content.index.publish_all_changes.a_block');
    }

    return (
      this.args.item.sectionCustomName ||
      this.intl.t(`sections.${this.args.item.sectionName}`)
    );
  }
}
