/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'ember-resources/util/function';
import {
  CandidateModel,
  JobApplicationModel,
  ScorecardCommentModel,
  ScorecardPickModel,
  ScorecardScoreModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { TOPIC } from 'teamtailor/models/scorecard-criterium';

interface ScorecardsSignature {
  Args: {
    candidate: CandidateModel;
    jobApplication?: JobApplicationModel;
  };
}

const ScorecardPickSorter = (a: ScorecardPickModel, b: ScorecardPickModel) => {
  return a.topic !== b.topic
    ? a.topic === TOPIC.SKILL
      ? -1
      : 1
    : a.rowOrder < b.rowOrder
      ? -1
      : 1;
};

export default class CandidateModalNewRightSidebarScorecards extends Component<ScorecardsSignature> {
  @service declare current: Current;
  @service declare store: Store;

  @tracked editScoreForId: string | null = null;

  resolveScores = trackedFunction(this, async () => {
    if (!this.args.jobApplication) {
      return [];
    }

    const { user } = this.current;
    const { candidate } = this.args;
    const job = await get(this.args.jobApplication, 'job');
    const jobDetail = await get(job, 'jobDetail');
    const candidateScorecards = await get(candidate, 'scorecardScores');

    if (jobDetail) {
      const scorecardPickIds = jobDetail.scorecardPicks.map((scorecardPick) => {
        return scorecardPick.id;
      });

      const scorecardsForJobAndUser = candidateScorecards.filter(
        (scorecardScore) => {
          const id = scorecardScore.belongsTo('scorecardPick').id();
          const userId = scorecardScore.belongsTo('user').id();
          return userId === user.id && id && scorecardPickIds.includes(id);
        }
      );

      return jobDetail.scorecardPicks
        .toArray()
        .sort(ScorecardPickSorter)
        .map((scorecardPick: ScorecardPickModel) => {
          const existingScoreCard = scorecardsForJobAndUser.find(
            (scorecardScore) =>
              scorecardScore.belongsTo('scorecardPick').id() ===
              scorecardPick.id
          );

          return existingScoreCard
            ? existingScoreCard
            : this.store.createRecord('scorecard-score', {
                scorecardPick,
                user,
                candidate,
              });
        });
    }

    return [];
  });

  resolveScorecardComment = trackedFunction(this, async () => {
    const job = await get(this.args.jobApplication, 'job');
    const { candidate } = this.args;
    const { user } = this.current;

    if (job) {
      const scorecardComments = await get(candidate, 'scorecardComments');

      const existingComment = scorecardComments.find((scorecardComment) => {
        return (
          scorecardComment.belongsTo('job').id() === job.id &&
          scorecardComment.belongsTo('user').id() === user.id
        );
      });

      return existingComment
        ? existingComment
        : this.store.createRecord('scorecard-comment', {
            job,
            candidate,
            user,
            comment: '',
          });
    }
  });

  get isLoading() {
    return !this.args.candidate.scorecardScores.isFulfilled;
  }

  get scores() {
    return this.resolveScores.value;
  }

  get scorecardComment(): ScorecardCommentModel | undefined | null {
    return this.resolveScorecardComment.value;
  }

  get editingScore() {
    return this.editScoreForId === this.args.jobApplication?.id;
  }

  handleSubmit = dropTask(async () => {
    await this.scorecardComment?.save();
    this.editScoreForId = null;
  });

  @action
  handleScore(score: ScorecardScoreModel) {
    score.save();
  }

  @action
  handleCommentChange({ target }: { target: HTMLTextAreaElement }) {
    if (this.scorecardComment) {
      this.scorecardComment.comment = target.value;
    }
  }

  @action
  handleCancelClick() {
    if (this.scorecardComment && !this.scorecardComment.isNew) {
      this.scorecardComment.rollbackAttributes();
    }

    this.editScoreForId = null;
  }

  @action
  handleCmdEnter() {
    this.handleSubmit.perform();
  }
}
