import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import IntlService from 'ember-intl/services/intl';
import PermissionsService from 'teamtailor/services/permissions';
import {
  getCompanyPages,
  getContentCareerSitePages,
  getDataAndPrivacyPages,
  getIntegrationsPages,
  getRecruitmentPages,
  getTemplatePages,
  SearchResult,
  Section,
} from 'teamtailor/utils/company-settings';

const PAGES_WITH_SIDEBAR = [
  'tags',
  'questions',
  'templates',
  'locations',
  'canned_responses',
  'todo-templates',
];

export default class SettingsController extends Controller {
  @service declare router: RouterService;
  @service declare current: Current;
  @service declare flipper: FlipperService;
  @service declare intl: IntlService;
  @service declare permissions: PermissionsService;

  @tracked query: string = '';
  @tracked queryResults: SearchResult = {};

  get hasSidebar() {
    return PAGES_WITH_SIDEBAR.includes(this.settingsPageName);
  }

  get company() {
    return this.current.company;
  }

  get settingsPageName() {
    return this.router.currentRouteName.split('.')[1] || '';
  }

  get showAllPages() {
    return Object.keys(this.queryResults).length === 0;
  }

  get showNoResults() {
    return this.query !== '' && this.showAllPages;
  }

  get currentSection() {
    const section = this.sections.find((section) =>
      section.pages.find((page) =>
        this.router.currentRouteName.includes(page.route)
      )
    );
    return section;
  }

  get currentSectionName() {
    return this.currentSection?.name;
  }

  get currentSectionIcon() {
    return this.currentSection?.icon;
  }

  // If you want to add sections or pages please add in ember_app/app/utils/company-settings.ts
  get sections(): Section[] {
    return [
      getCompanyPages(this.intl, this.permissions, this.current),
      getTemplatePages(this.intl, this.permissions, this.current),
      getRecruitmentPages(
        this.intl,
        this.permissions,
        this.current,
        this.flipper
      ),
      getContentCareerSitePages(this.intl, this.permissions, this.current),
      getDataAndPrivacyPages(
        this.intl,
        this.permissions,
        this.current,
        this.flipper
      ),
      getIntegrationsPages(
        this.intl,
        this.permissions,
        this.current,
        this.flipper
      ),
    ];
  }

  @action
  searchChanged(event: InputEvent) {
    const { value } = event.target as HTMLInputElement;
    this.query = value.toLowerCase();

    if (!this.query) {
      this.queryResults = {};
      return;
    }

    const results: SearchResult = this.sections.reduce<SearchResult>(
      (acc, section) => {
        const matchingPages = section.pages.filter(
          (page) => page.show && page.name.toLowerCase().includes(this.query)
        );

        if (matchingPages.length > 0) {
          acc[section.name] = { icon: section.icon, pages: matchingPages };
        }

        return acc;
      },
      {}
    );

    this.queryResults = results;
  }
}

declare module '@ember/controller' {
  interface Registry {
    settings: SettingsController;
  }
}
