/* import __COLOCATED_TEMPLATE__ from './tag-type.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { QuestionType, QUESTION_TYPES } from 'teamtailor/constants/question';

interface TagTypeArgs {
  hideDrafts: boolean | undefined;
}

export default class TagType extends Component<TagTypeArgs> {
  @service declare intl: IntlService;

  get options(): { id: string | null; label: string }[] {
    const options = [
      {
        id: null,
        label: this.intl.t('components.question_creator.default'),
      },
      ...Object.entries(QUESTION_TYPES).map((question) => {
        return {
          id: question[0] as QuestionType,
          label: this.intl.t(question[1].translationKey),
        };
      }),
    ];

    return options;
  }
}
