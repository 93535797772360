/* import __COLOCATED_TEMPLATE__ from './meeting.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import moment from 'moment-timezone';
import TimeFormatService from 'teamtailor/services/time-format';
import MeetingEventModel from 'teamtailor/models/meeting-event';
import MeetingEventAttendeeModel from 'teamtailor/models/meeting-event-attendee';

type MeetingArgs = {
  meeting: MeetingEventModel;
};

export default class CandidatePreviewPopoverMeetingComponent extends Component<MeetingArgs> {
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;

  get eventDate(): string | undefined {
    if (this.args.meeting.bookedSlotStartsAt) {
      const { tzid, bookedSlotStartsAt } = this.args.meeting;

      return `${moment(bookedSlotStartsAt)
        .tz(tzid)
        .locale(this.timeFormat.locale)
        .format(`ddd LL`)}`;
    }
  }

  get eventTime(): string | undefined {
    if (this.args.meeting.bookedSlotStartsAt) {
      const { tzid, bookedSlotStartsAt, bookedSlotEndsAt } = this.args.meeting;

      return `${moment(bookedSlotStartsAt)
        .tz(tzid)
        .locale(this.timeFormat.locale)
        .format(this.timeFormat.format)} - ${moment(bookedSlotEndsAt)
        .tz(tzid)
        .locale(this.timeFormat.locale)
        .format(`${this.timeFormat.format} (z)`)}`;
    }
  }

  get currentUserAttendeeId(): string {
    const attendee = this.args.meeting.userAttendees?.find(
      (u: MeetingEventAttendeeModel) =>
        u.user && get(u.user, 'id') === get(this.current.user, 'id')
    );

    return attendee ? attendee.id : '';
  }
}
