/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import { DeviceOption } from 'teamtailor/controllers/video-meetings';
import IntlService from 'ember-intl/services/intl';

import { DefaultDeviceController } from 'amazon-chime-sdk-js';

interface VideoMeetingPreviewArgs {
  videoTrack?: LocalVideoTrack | MediaStream;
  audioTrack?: LocalAudioTrack | MediaStream;
  isDesktop: boolean;
  audioInputDevices?: DeviceOption[];
  videoInputDevices?: DeviceOption[];
  currentVideoDevice?: DeviceOption;
  currentAudioInputDevice?: DeviceOption;
  onChangeVideoInputDevice: (deviceInfo?: MediaDeviceInfo) => void;
  onChangeAudioInputDevice: (deviceInfo?: MediaDeviceInfo) => void;
  // Chime
  isNewProvider: boolean;
  deviceController?: DefaultDeviceController;
}

export default class VideoMeetingPreview extends Component<VideoMeetingPreviewArgs> {
  @service declare intl: IntlService;
  @tracked testSound = false;

  declare videoTrackElement?: HTMLMediaElement;
  declare videoPreviewElement: HTMLVideoElement | null;
  declare videoWrapperElement: HTMLElement;

  updateTrackDimensions = (
    element: HTMLElement,
    track: LocalVideoTrack
  ): void => {
    element.style.aspectRatio = `${track.dimensions.width} / ${track.dimensions.height}`;
  };

  @action
  addVideoTrack(element: HTMLElement): void {
    if (this.args.videoTrack) {
      if (this.args.isNewProvider) {
        this.videoPreviewElement = element.querySelector('#video-preview');
        if (this.videoPreviewElement == null) return;

        if (this.args.deviceController) {
          const { deviceController } = this.args;
          deviceController.startVideoPreviewForVideoInput(
            this.videoPreviewElement
          );
        }
      } else {
        element.classList.remove('aspect-video');

        this.videoTrackElement = element.appendChild(
          (<LocalVideoTrack>this.args.videoTrack).attach()
        );
        this.videoTrackElement.style.transform = 'scale(-1, 1)';
        this.videoTrackElement.classList.add(
          'h-full',
          'object-cover',
          'rounded-t-10'
        );

        this.updateTrackDimensions(
          element,
          <LocalVideoTrack>this.args.videoTrack
        );

        (<LocalVideoTrack>this.args.videoTrack).on(
          'dimensionsChanged',
          (track: LocalVideoTrack) => this.updateTrackDimensions(element, track)
        );

        element.appendChild(this.videoTrackElement);
      }
    }
  }

  addAudioTrack(element: HTMLElement): void {
    if (this.args.audioTrack && !this.args.isNewProvider) {
      element.appendChild((<LocalAudioTrack>this.args.audioTrack).attach());
    }
  }

  @action
  handleVideoDimensionChanges(event: Event) {
    if (this.args.isNewProvider) {
      const { videoWidth, videoHeight } = <HTMLVideoElement>event.target;
      if (videoHeight && videoWidth) {
        this.videoWrapperElement.style.aspectRatio = `${videoWidth} / ${videoHeight}`;
      }
    }
  }

  @action
  onInsert(element: HTMLElement): void {
    this.videoWrapperElement = element;
    this.addAudioTrack(element);
    this.addVideoTrack(element);
  }

  @action
  onUpdateAudio(element: HTMLElement): void {
    this.addAudioTrack(element);
  }

  @action
  onUpdateVideo(element: HTMLElement): void {
    this.addVideoTrack(element);
  }

  willDestroy() {
    super.willDestroy();
    if (this.videoPreviewElement) {
      if (this.args.deviceController) {
        const { deviceController } = this.args;
        deviceController.stopVideoPreviewForVideoInput(
          this.videoPreviewElement
        );
      }
    }
  }
}
