/* import __COLOCATED_TEMPLATE__ from './form-builder.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { v1 as uuid } from 'ember-uuid';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { FormBuilderField } from 'teamtailor/utils/form-builder';

interface FormBuilderArgs {
  fields?: FormBuilderField[];
  onUpdateFields(fields: FormBuilderField[]): void;
  onUpdateField(fieldUuid: string, data: FormBuilderField): void;
}

export default class FormBuilder extends Component<FormBuilderArgs> {
  @service declare intl: IntlService;

  @tracked editingFieldUuid: string | null = null;

  @action
  onFieldSelect(fieldUuid: FormBuilderField['uuid']) {
    this.editingFieldUuid = fieldUuid;
  }

  @action
  onFieldAdd(type: FormBuilderField['type']) {
    const fieldUuid = uuid();

    let newField: FormBuilderField = {
      uuid: fieldUuid,
      type,
      label: '',
      sortIndex: this.nextSortIndex,
    };

    if (['select', 'radio', 'checkbox'].includes(type)) {
      newField = {
        ...newField,
        options: [
          {
            id: uuid(),
            label: this.intl.t(
              'settings.requisitions.custom_form.first_option'
            ),
          },
        ],
      };
    }

    this.onFieldSelect(fieldUuid);
    this.args.onUpdateFields([...(this.args.fields || []), newField]);
  }

  get nextSortIndex() {
    if (this.args.fields) {
      return Math.max(...this.args.fields.map((field) => field.sortIndex)) + 1;
    } else {
      return 0;
    }
  }

  @action
  onFieldDelete(fieldUuid: FormBuilderField['uuid']) {
    const fields =
      this.args.fields?.filter((field) => field.uuid !== fieldUuid) || [];

    fields.forEach((field) => {
      if (field.show_if?.ref_uuid === fieldUuid) {
        delete field.show_if;
      }
    });

    this.args.onUpdateFields(fields);
  }

  @action
  handleDragStart({
    event,
    element,
  }: {
    event: DragEvent;
    element: HTMLDivElement;
  }) {
    // Position to center:
    const { x, y } = element.getBoundingClientRect();
    event.dataTransfer?.setDragImage(
      element,
      event.clientX - x,
      event.clientY - y
    );
  }

  @action
  handleDragEnd({
    draggedItem,
    sourceIndex,
    targetIndex,
    sourceList,
    targetList,
  }: {
    draggedItem: FormBuilderField;
    sourceIndex: number;
    targetIndex: number;
    sourceList: FormBuilderField[];
    targetList: FormBuilderField[];
  }) {
    // NOTE: the `draggedItem` argument returned from `ember-drag-sort` will be out of sync if not saved before being dragged
    // The object reference must therefore be picked from the args.fields to make sure it has the latest changes.

    const field = this.args.fields?.find(
      (field) => field.uuid === draggedItem.uuid
    );

    if (!field) {
      return;
    }

    sourceList.slice(targetIndex, sourceList.length).forEach((item) => {
      if (item.unmodifiable) {
        targetIndex += 1;
      }
    });

    // NOTE: couldn't this be an issue when moving between two different lists?
    if (sourceIndex === targetIndex) {
      return;
    }

    sourceList.splice(sourceIndex, 1);
    targetList.splice(targetIndex, 0, field);

    targetList.forEach((item, index) => {
      item.sortIndex = index;
    });

    this.args.onUpdateFields(targetList);
  }
}
