/* import __COLOCATED_TEMPLATE__ from './edit-avatar-form.hbs'; */
import { action } from '@ember/object';
import { ALLOWED_AVATAR_COLORS } from 'teamtailor/constants/job-avatar';
import { BufferedChangeset } from 'ember-changeset/types';
import Component from '@glimmer/component';
import { gradientBackgroundStyle } from 'teamtailor/utils/gradient-background';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import randomItem from 'teamtailor/utils/random-item';

interface EditAvatarFormArgs {
  changeset: BufferedChangeset;
  jobInitial?: string;
}

const CHANGE_DELAY = 100;

export default class EditAvatarForm extends Component<EditAvatarFormArgs> {
  @service declare intl: IntlService;

  get colorOptions() {
    const options = ALLOWED_AVATAR_COLORS.map((color) => {
      return { color, variant: 'weak', isDecorative: true };
    });

    return [
      {
        color: 'random',
        icon: 'shuffle',
        tooltip: 'job.avatar.randomize_color',
      },
      ...options,
    ];
  }

  get multipleColorStyle() {
    const colorOptions = this.colorOptions.filter(
      (option) => option.color !== 'random'
    );
    return gradientBackgroundStyle(colorOptions);
  }

  get backgroundColorClassName() {
    return `bg-decorative-${this.color}-weak`;
  }

  get textColorClassName() {
    return `text-decorative-${this.color}-weak`;
  }

  get changeset() {
    return this.args.changeset;
  }

  get icon() {
    return this.changeset.chosenIcon;
  }

  get iconPrefix() {
    return this.changeset.chosenIconPrefix;
  }

  get emoji() {
    return this.changeset.chosenEmoji;
  }

  get color() {
    return this.changeset.backgroundColor;
  }

  get avatarType() {
    return this.changeset.avatarType;
  }

  get isTypeLetter() {
    return this.avatarType === 'letter';
  }

  get isTypeEmoji() {
    return this.avatarType === 'emoji';
  }

  get isTypeIcon() {
    return this.avatarType === 'icon';
  }

  get typeOptions() {
    return [
      { text: this.intl.t('job.avatar.type.first_letter'), value: 'letter' },
      { text: this.intl.t('job.avatar.type.emoji'), value: 'emoji' },
      { text: this.intl.t('job.avatar.type.icon'), value: 'icon' },
    ];
  }

  get jobInitial() {
    return this.args.jobInitial || '_';
  }

  @action
  onColorChange(color: string): void {
    this.changeset.backgroundColor = color;
  }

  @action
  changeType(choice: { text: string; value: string }): void {
    this.changeset.avatarType = choice.value;

    this.setDefaults(choice.value);
  }

  @action
  handleIconSelect(arg: { iconName: string; prefix: string }) {
    this.changeset.chosenIcon = '';
    this.changeset.chosenIconPrefix = '';
    later(
      this,
      () => {
        this.changeset.chosenIcon = arg.iconName;
        this.changeset.chosenIconPrefix = arg.prefix;
      },
      CHANGE_DELAY
    );
  }

  @action
  handleEmojiSelect(emoji: string) {
    this.changeset.chosenEmoji = '';
    later(
      this,
      () => {
        this.changeset.chosenEmoji = emoji;
      },
      CHANGE_DELAY
    );
  }

  setDefaults(type: string) {
    if (type === 'icon' && !this.changeset.chosenIcon) {
      this.handleIconSelect({ iconName: 'briefcase', prefix: 'far' });
    } else if (type === 'emoji' && !this.changeset.chosenEmoji) {
      const emoji = this.randomEmoji() || '';
      this.handleEmojiSelect(emoji);
    }
  }

  randomEmoji() {
    const emojis = ['🦄', '🐠', '🦋', '💼', '💖', '😻', '☀️', '💎', '🎉', '🎈'];
    return randomItem(emojis);
  }
}
