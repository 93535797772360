/* import __COLOCATED_TEMPLATE__ from './sources.hbs'; */
import Component from '@glimmer/component';
import flatten from 'teamtailor/utils/flatten';

export default class InsightsWidgetsSources extends Component {
  get total() {
    return flatten(this.args.data.mapBy('value')).reduce(
      (acc, i) => acc + i,
      0
    );
  }
}
