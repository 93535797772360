/* import __COLOCATED_TEMPLATE__ from './employees-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  TABLE_COLUMNS,
  TABLE_COLUMNS_SSO,
  TABLE_LOADING_COLUMNS,
  TABLE_LOADING_COLUMNS_SSO,
} from 'teamtailor/constants/employee';
import CurrentService from 'teamtailor/services/current';
import { TaskForAsyncTaskFunction } from 'ember-concurrency';

type EmployeesListComponentArgs = {
  page: number;
  setPage: (page: number) => void;
  view: string;
  fetchEmployees: TaskForAsyncTaskFunction<any, () => Promise<any>>;
  selectAll: boolean;
  toggleSelectAll: () => void;
  selectedIds: string[];
  deselectedIds: string[];
  showBulk: boolean;
};

export default class EmployeesListComponent extends Component<EmployeesListComponentArgs> {
  @service declare current: CurrentService;

  get columns() {
    let columns = TABLE_COLUMNS;
    if (this.current.company.singleSignOnActive) {
      columns = columns.concat(TABLE_COLUMNS_SSO);
    }

    return columns;
  }

  get loadingColumns() {
    let loading_columns = TABLE_LOADING_COLUMNS;
    if (this.current.company.singleSignOnActive) {
      loading_columns = loading_columns.concat(TABLE_LOADING_COLUMNS_SSO);
    }

    return loading_columns;
  }

  get employees() {
    return this.args.fetchEmployees.last?.value;
  }

  get totalPages() {
    return this.employees?.meta.total_pages;
  }

  get showPagination() {
    return this.totalPages > 1;
  }
}
