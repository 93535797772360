/* import __COLOCATED_TEMPLATE__ from './reject-modal.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import rejectSettingsFormValid from 'teamtailor/utils/reject-settings-form-valid';
import { dropTask } from 'ember-concurrency';

export default class RejectModal extends Component {
  @service intl;

  @rejectSettingsFormValid(
    'jobApplication.rejectReason.id',
    'willSendEmail',
    'jobApplication.rejectTemplate',
    'jobApplication.rejectSubject',
    'jobApplication.rejectBody'
  )
  formValid;

  get jobApplication() {
    return this.args.jobApplication;
  }

  get willSendEmail() {
    return (
      get(this.jobApplication, 'rejectSendEmail') &&
      get(this.jobApplication, 'candidate.email')
    );
  }

  rejectTask = dropTask(async () => {
    await this.jobApplication.reject();

    this.args.onClose();
  });
}
