import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { set, action } from '@ember/object';
import { task } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';
import { relationsRecordRemover } from 'teamtailor/utils/record-remover';
import { getMenuItem } from 'teamtailor/utils/jobs-edit-navigation-item';
import JobCreatedMessage from 'teamtailor/utils/job-created-message';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class EditController extends Controller {
  queryParams = ['template', 'showApprove'];

  @service copilot;
  @service current;
  @service dismissableStatus;
  @service flashMessages;
  @service flipper;
  @service intercom;
  @service intl;
  @service permissions;
  @service router;
  @service store;
  @service table;
  @service ttAlert;

  @tracked pickedApprovals = [];
  @tracked template = false;
  @tracked showStatusButtons = false;
  @tracked showDatePickers = false;
  @tracked showArchiveModal = false;
  @tracked showApprove = false;
  @tracked _inFlight = false;
  @tracked showDeleteJobModal = false;

  get inFlight() {
    return this.saveTask.isRunning || this._inFlight;
  }

  get statusButtonDisabled() {
    return this.inFlight;
  }

  get jobDetail() {
    return this.model.jobDetail;
  }

  get multipleApprovals() {
    return get(this.company, 'approvalSetting.multiple');
  }

  get showApproversPicker() {
    if (this.multipleApprovals) {
      return this.model.approvals.length === 0;
    } else {
      return true;
    }
  }

  get showInstructions() {
    return (
      this.template &&
      get(this.model, 'jobDetail.fromTemplate.jobDetail.templateInstructions')
    );
  }

  get message() {
    return get(this, 'model.approvals.firstObject.message');
  }

  get isCurrentUserApprover() {
    const userId = this.current.user.id;
    return get(this.model, 'approvals')
      .map((approval) => get(approval, 'approver.id'))
      .includes(userId);
  }

  get isApprovedByCurrentUser() {
    const userId = this.current.user.id;
    return get(this.model, 'approvals')
      .filter((approval) => get(approval, 'status') === 'pending')
      .map((approval) => get(approval, 'approver.id'))
      .includes(userId);
  }

  get canCurrentUserApprove() {
    return this.isApprovedByCurrentUser && this.isCurrentUserApprover;
  }

  get approvalsNotSaved() {
    return (
      !get(this, 'model.approvals').isAny('dirtyType', undefined) ||
      !get(this, 'model.approvals.length')
    );
  }

  get readOnlyApprovals() {
    return get(this, 'multipleApprovals') || !get(this, 'approvalsNotSaved');
  }

  get userApproval() {
    const userId = this.current.user.id;
    return get(this.model, 'approvals').find(
      (approval) => get(approval, 'approver.id') === userId
    );
  }

  get disablePublish() {
    if (!get(this, 'company').hasFeature('career_site_languages')) {
      return false;
    }

    return this.selectedCareerSite
      ? !this.selectedCareerSite.isPublished
      : false;
  }

  get company() {
    return this.current.company;
  }

  get missingAdTemplate() {
    return !get(this.model, 'adTemplate.id');
  }

  get selectedCareerSite() {
    const selectedLanguageCode = get(this, 'jobDetail.languageCode');
    const careerSite = get(this, 'company.careerSites').findBy(
      'languageCode',
      selectedLanguageCode
    );

    return careerSite;
  }

  get selectedCareerSiteName() {
    return this.selectedCareerSite
      ? this.selectedCareerSite.translatedLanguage
      : '';
  }

  get navigationItems() {
    const getRequisitionId = this.model.belongsTo('requisition').id();
    return [
      getMenuItem('requisition', Boolean(getRequisitionId)),
      getMenuItem('posting'),
      getMenuItem('application-form'),
      getMenuItem('chat-widget', this.company.hasFeature('candidate_chat')),
      getMenuItem('stages'),
      getMenuItem('evaluation', this.company.hasFeature('interview_kit')),
      getMenuItem('hiring-team'),
      getMenuItem(
        'external-recruiters',
        this.company.hasFeature('external_recruitment')
      ),
      getMenuItem('scorecard', !this.company.hasFeature('interview_kit')),
      getMenuItem('template', get(this.model, 'template')),
    ].compact();
  }

  getPossibleIntercomEvents() {
    let stages = get(this, 'model.stages');
    return stages.reduce((eventNames, stage) => {
      let changedAttributes = stage.changedAttributes();
      ['milestone', 'guideTime'].forEach((property) => {
        if (!changedAttributes[property] || changedAttributes[property][0]) {
          return;
        }

        let eventName = `added-stage-${property.toLowerCase()}`;
        if (eventNames.indexOf(eventName) < 0) {
          eventNames.push(eventName);
        }
      });
      return eventNames;
    }, []);
  }

  addCreatedStatusMessage() {
    const jobsCount = this.current.user.jobsCreated;
    const message = new JobCreatedMessage(this.intl, jobsCount).getMessage();
    get(this, 'dismissableStatus').addMessage(message, 'job-status-messages');
  }

  async saveModel() {
    set(this, 'showStatusButtons', false);
    let intercomEventsToTrack = this.getPossibleIntercomEvents();
    let model = get(this, 'model');

    get(model, 'jobDetail.pickedCustomFields').forEach((item) => {
      if (isEmpty(item.value)) {
        item.deleteRecord();
      }
    });

    const stages = get(model, 'jobDetail.stages');
    const editableStages = stages
      .filter((stage) => stage.isEditable)
      .toSorted(
        (stage1, stage2) => stage1.rowOrderPosition - stage2.rowOrderPosition
      );
    editableStages
      .filter((stage) => !stage.isDeleted)
      .forEach((item, index) => {
        set(item, 'rowOrderPosition', index + 1);
      });

    const hiredStage = stages.find((stage) => stage.hired);
    hiredStage.rowOrderPosition = stages.length;

    const inboxStage = stages.find((stage) => stage.inbox);
    inboxStage.rowOrderPosition = 0;

    set(model, 'jobDetail.stages', [inboxStage, ...stages, hiredStage]);

    await this.copilot.saveScorecardCriteria();
    await this.copilot.saveQuestions();

    try {
      await model.save();

      const relationships = [];
      (await get(model, 'jobDetail')).eachRelationship((name) =>
        relationships.push(name)
      );
      relationsRecordRemover(model.jobDetail, relationships);

      this.table.refreshJobsTable = true;

      const pickedQuestions = get(model, 'jobDetail.pickedQuestions');
      pickedQuestions.forEach((pickedQuestion, index) => {
        pickedQuestion.rowOrderPosition = index;
      });

      intercomEventsToTrack.forEach((eventName) => {
        get(this, 'intercom').trackEvent(eventName);
      });
    } catch (error) {
      get(this, 'model.jobDetail.pickedCustomFields')
        .filter((customField) => get(customField, 'isDeleted'))
        .forEach((field) => {
          field.rollbackAttributes();
          field.value = null;
        });

      const changes = model.changedAttributes();
      if (changes.status) {
        set(this, 'model.status', changes.status[0] || 'draft');
      }

      this.router.transitionTo('jobs.edit.posting');

      return Promise.reject(error);
    }
  }

  saveModelAndRedirect() {
    const promise = this.saveModel();
    promise.then(() => {
      later(() => {
        this.current.user.incrementProperty('jobsCreated');
        const job = get(this, 'model');
        if (get(job, 'internal')) {
          this.router.transitionTo('jobs.job.stages.index', job);
        } else {
          this.addCreatedStatusMessage();
          this.router.transitionTo('jobs.job.promote', job);
        }
      }, 500);
    });
    return promise;
  }

  checkApprovals() {
    const approvedCount = get(this, 'model.approvals').filterBy(
      'approved',
      true
    ).length;
    const neededApproversCount = get(
      this,
      'company.approvalSetting.users.length'
    );

    const saveAsDraft = () => {
      set(this, 'model.status', 'draft');
      return get(this, 'model').save().then();
    };

    if (get(this, 'multipleApprovals')) {
      if (approvedCount === neededApproversCount) saveAsDraft();
    } else {
      saveAsDraft();
    }
  }

  saveTask = task(
    {
      drop: true,
    },
    async (promise) => {
      let result = await promise.catch((error) => ({ isError: true, error }));

      if (result && result.isError) {
        return Promise.reject(result.error);
      }
    }
  );

  get deleteButton() {
    if (
      get(this, 'permissions').has('job/delete') &&
      (get(this, 'model.isDraft') ||
        get(this, 'model.isTemp') ||
        get(this, 'model.isArchived') ||
        get(this, 'model.isPendingApproval') ||
        get(this, 'model.isAwaitingPublish'))
    ) {
      return {
        class: 'delete',
        action: this.delete,
        text: 'common.delete',
      };
    }

    return undefined;
  }

  get archiveButton() {
    if (get(this, 'permissions').has('job/update')) {
      if (get(this, 'model.isOpen') || get(this, 'model.isUnlisted')) {
        return {
          class: 'archived',
          action: this.toggleArchiveModal,
          text: 'jobs.edit.archive',
        };
      }
    }

    return undefined;
  }

  get unlistButton() {
    if (get(this, 'permissions').has('job/update')) {
      if (
        get(this, 'model.isOpen') ||
        get(this, 'model.isDraft') ||
        get(this, 'model.isTemp') ||
        get(this, 'model.isAwaitingPublish')
      ) {
        return {
          class: 'unlisted',
          action: this.unlist,
          text: 'jobs.edit.unlist',
        };
      }
    }

    return undefined;
  }

  get republishButton() {
    if (get(this, 'permissions').has('job/publish')) {
      if (get(this, 'model.isUnlisted')) {
        return {
          action: this.republish,
          text: 'jobs.edit.publish',
        };
      }
    }

    return undefined;
  }

  get unarchiveButton() {
    if (get(this, 'permissions').has('job/update')) {
      if (get(this, 'model.isArchived')) {
        return {
          action: this.unarchive,
          text: 'jobs.edit.unarchive',
        };
      }
    }

    return undefined;
  }

  get rejectButton() {
    if (get(this, 'canCurrentUserApprove')) {
      if (get(this, 'userApproval.approved')) {
        return {
          action: () => this.reject,
          text: 'jobs.edit.dont_approve',
        };
      }
    }

    return undefined;
  }

  get approveButton() {
    if (get(this, 'canCurrentUserApprove')) {
      if (!get(this, 'userApproval.approved')) {
        return {
          action: () => this.approve,
          text: 'jobs.edit.approve',
        };
      }
    }

    return undefined;
  }

  get statusButtons() {
    const buttonNames = [
      'deleteButton',
      'archiveButton',
      'unlistButton',
      'republishButton',
      'unarchiveButton',
      'rejectButton',
      'approveButton',
    ];
    let buttonsData = [];

    buttonNames.forEach((name) => {
      if (get(this, name)) {
        buttonsData.push(get(this, name));
      }
    });

    return buttonsData;
  }

  get canShowPublishButton() {
    return (
      this.permissions.has('job/publish') &&
      (this.model.isDraft || this.model.isTemp || this.model.isAwaitingPublish)
    );
  }

  get canShowReadyToPublish() {
    return (
      !get(this, 'permissions').has('job/publish') &&
      (get(this, 'model.isDraft') || get(this, 'model.isTemp'))
    );
  }

  get primaryStatusButton() {
    let translationString = 'common.save';
    if (this.inFlight) {
      translationString = 'jobs.edit.saving';
    } else if (this.canShowPublishButton) {
      translationString = 'jobs.edit.publish';
    } else if (this.canShowReadyToPublish) {
      translationString = 'jobs.edit.ready_to_publish';
    }

    return translationString;
  }

  get hasStageWithEmptyName() {
    return !!get(this, 'model.jobDetail.stages')
      .toArray()
      .filter((stage) => {
        return !get(stage, 'name');
      }).length;
  }

  get disablePrimaryStatusButton() {
    return (
      this.disablePublish ||
      this.hasStageWithEmptyName ||
      this.missingAdTemplate
    );
  }

  addApprover(user) {
    const approval = get(this, 'store').createRecord('approval', {
      job: get(this, 'model'),
      approvedByUser: user,
      requester: get(this.model, 'user'),
      message: get(this, 'message'),
      status: 'pending',
    });
    get(this, 'pickedApprovals').pushObject(approval);
  }

  @action
  updateApprovalMessage(event) {
    const message = event.target.value;
    get(this.model, 'approvals').forEach((approval) =>
      set(approval, 'message', message)
    );
  }

  @action
  approveWithStatus(status) {
    if (get(this, 'inFlight')) {
      return;
    }

    set(this, '_inFlight', true);
    let currentUserApproval = get(this, 'model.approvals').find(
      (approval) => get(approval, 'approver.id') === this.current.user.id
    );

    const callback = () => {
      if (status === 'approved') {
        get(this, 'flashMessages').success(
          this.intl.t('jobs.edit.you_approved_the_job')
        );
        this.checkApprovals();
      } else {
        get(this, 'flashMessages').success(
          this.intl.t('jobs.edit.job_was_not_approved')
        );
        this.toggleButtons();
      }

      set(this, '_inFlight', false);
    };

    set(currentUserApproval, 'status', status);

    currentUserApproval.save().then(callback());
  }

  @action
  reject() {
    get(this, 'approveWithStatus')('rejected');
  }

  @action
  approve() {
    get(this, 'approveWithStatus')('approved');
  }

  @action
  approvePublishRequest() {
    return new Promise((resolve, reject) => {
      get(this, 'ttAlert').confirm(
        this.intl.t('jobs.edit.you_are_not_picked_approver'),
        () => {
          const user = get(this.model, 'user');
          let approval = get(this, 'model.approvals').createRecord({
            job: get(this, 'model'),
            approvedByUser: user,
            requester: user,
            message: get(this, 'message'),
            status: 'approved',
          });

          get(this, 'saveTask').perform(
            approval.save().then(() => {
              resolve();
              get(this, 'flashMessages').success(
                this.intl.t('jobs.edit.you_approved_the_job')
              );
              this.checkApprovals();
            })
          );
        },
        () => {
          return reject({ cancel: true });
        }
      );
    });
  }

  @action
  saveJobAndRequestApprovals() {
    if (get(this, 'inflight')) {
      return;
    }

    set(this, '_inFlight', true);

    const afterError = () => {
      if (get(this, 'multipleApprovals')) {
        get(this, 'store').unloadAll('approval');
      }

      get(this, 'flashMessages').error(
        this.intl.t('jobs.edit.approval_could_not_be_sent')
      );
    };

    const afterSave = () => {
      this.clearUnsavedModels();

      this.router.transitionTo('jobs.edit.posting', get(this, 'model'));
      set(this, 'template', false);
      get(this, 'flashMessages').success(
        this.intl.t('jobs.edit.sent_approval')
      );
    };

    set(this, 'model.status', 'awaiting');

    if (get(this, 'multipleApprovals')) {
      set(this, 'pickedApprovals', []);
      get(this, 'company.approvalSetting.users').forEach((user) => {
        this.addApprover(user);
      });
    }

    const saveApprovals = () =>
      Promise.all(get(this, 'pickedApprovals').map((p) => p.save()));

    get(this, 'model')
      .save()
      .then(saveApprovals)
      .then(afterSave)
      .catch(afterError)
      .finally(() => {
        set(this, '_inFlight', false);
      });
  }

  @action
  handleAddApprover(user) {
    this.addApprover(user);
  }

  @action
  removeApprover(user) {
    const approval = get(this, 'model.approvals').findBy(
      'approver.id',
      get(user, 'id')
    );
    get(this, 'pickedApprovals').removeObject(approval);
    approval.destroyRecord();
  }

  @action
  toggleApprover(user) {
    const approval = get(this, 'model.approvals').findBy(
      'approver.id',
      get(user, 'id')
    );
    if (approval) {
      get(this, 'pickedApprovals').removeObject(approval);
      approval.destroyRecord();
    } else {
      this.addApprover(user);
    }
  }

  @action
  toggleDatePickers(e) {
    e.stopPropagation();
    this.showDatePickers = !this.showDatePickers;
  }

  @action
  toggleButtons(e) {
    e.stopPropagation();
    this.showStatusButtons = !this.showStatusButtons;
  }

  @action
  toggleInternalJob() {
    get(this, 'model').toggleProperty('internal');
  }

  @action
  togglePinJob() {
    get(this, 'model').toggleProperty('pinned');
  }

  @action
  toggleTransparentRecruiting() {
    get(this, 'model').toggleProperty('transparentRecruiting');
  }

  @action
  unlist() {
    set(this, 'model.status', 'unlisted');
    get(this, 'saveTask').perform(this.saveModel());
  }

  @action
  toggleArchiveModal() {
    set(this, 'showStatusButtons', false);
    this.showArchiveModal = !this.showArchiveModal;
  }

  @action
  toggleShowApprove() {
    this.showApprove = !this.showApprove;
  }

  @action
  archive(rejectOptions = {}) {
    const promise = get(this, 'model').archive(rejectOptions);
    promise.then(() => {
      set(this, 'showArchiveModal', false);
    });
    get(this, 'saveTask').perform(promise);
  }

  @action
  unarchive() {
    set(this, 'model.status', 'draft');
    let promise = this.saveModel();

    get(this, 'saveTask').perform(promise);

    return promise;
  }

  @action
  republish() {
    if (get(this, 'inflight')) {
      return;
    }

    set(this, 'model.status', 'open');
    get(this, 'saveTask').perform(this.saveModelAndRedirect());
  }

  @action
  saveDraft(runTask) {
    set(this, 'model.status', 'draft');
    let promise = this.saveModel();
    if (runTask) {
      get(this, 'saveTask').perform(promise);
    }

    return promise;
  }

  @action
  delete() {
    this.ttAlert.confirm(
      this.intl.t('jobs.edit.delete_job_confirmation'),
      async () => {
        const isTemplate = !!this.model.template;

        try {
          await this.model.destroyRecord();
        } catch {
          if (get(this, 'model.errors').has('pages')) {
            this.showDeleteJobModal = true;
          }
        }

        if (this.model.isDeleted) {
          this.flashMessages.success(this.intl.t('jobs.edit.job_deleted'));
          this.router.transitionTo(
            isTemplate ? 'settings.templates.index' : 'jobs.index'
          );
        }
      },
      () => {},
      {
        confirmButtonText: this.intl.t('common.delete'),
      }
    );
  }

  @action
  async save() {
    if (get(this, 'inFlight')) {
      return;
    }

    let model = get(this, 'model');
    let saveTask = get(this, 'saveTask');

    if (get(this, 'canShowPublishButton')) {
      set(model, 'status', 'open');
      return saveTask.perform(this.saveModelAndRedirect());
    } else {
      if (get(this, 'canShowReadyToPublish')) {
        set(model, 'status', 'awaiting');
      }

      return saveTask.perform(this.saveModel());
    }
  }

  @action
  closeTemplateModal() {
    set(this, 'showTemplateModal', false);
  }

  @action
  toggleInstructions() {
    this.template = !this.template;
  }

  @action
  closeError(error) {
    get(this, 'model.errors').remove(error.attribute);
  }
}
