/* import __COLOCATED_TEMPLATE__ from './audience.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { JobBulkArgs } from 'teamtailor/constants/jobs';

export default class BulkActionsJobsAudienceComponent extends Component<JobBulkArgs> {
  @tracked audience = false;

  @action
  onClose(): void {
    this.args.onClose();
    this.audience = false;
  }

  @action
  handleBulkChange(): void {
    const data = {
      internal: this.audience,
    };
    this.args.handleBulkChange(data, 'bulk_audience');
    this.onClose();
  }
}
