/* import __COLOCATED_TEMPLATE__ from './locations.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import LocationModel from 'teamtailor/models/location';
import CurrentService from 'teamtailor/services/current';

type JobsLocationsArgs = {
  locations: LocationModel[];
  maxLocationsToShow: number;
};

export default class JobsLocationsComponent extends Component<JobsLocationsArgs> {
  @service declare current: CurrentService;

  get locations(): LocationModel[] | undefined {
    const { locations, maxLocationsToShow } = this.args;
    if (!locations.length) {
      return undefined;
    }

    const shownLocationsAmount =
      locations.length > maxLocationsToShow
        ? maxLocationsToShow
        : locations.length;

    return locations.slice(0, shownLocationsAmount);
  }

  get otherLocations(): LocationModel[] | undefined {
    const { locations, maxLocationsToShow } = this.args;
    if (
      !locations.length ||
      (locations.length && locations.length <= this.args.maxLocationsToShow)
    ) {
      return undefined;
    }

    return locations.slice(maxLocationsToShow, locations.length);
  }
}
