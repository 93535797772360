/* import __COLOCATED_TEMPLATE__ from './user-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';
import uniqBy from 'teamtailor/utils/uniq-by';

export default class TextComposerToolUserPickerComponent extends Component {
  @service current;
  @service intl;

  @tracked recentHiringTeam = [];

  resolvedUser = trackedFunction(this, async () => {
    const user = await get(this.args, 'user');
    return user;
  });

  get users() {
    return uniqBy(
      [
        ...this.selectedUserItem,
        ...this.selectedCurrentUserItem,
        ...this.recentHiringTeam,
      ],
      'id'
    );
  }

  get groupedUsers() {
    return [
      {
        groupName: this.intl.t('components.todo_input.suggested_employees', {
          count: this.users.length,
        }),

        options: this.users,
      },
    ];
  }

  get job() {
    return this.args.job;
  }

  get candidate() {
    return this.args.candidate;
  }

  get selectedCurrentUserItem() {
    return this.recentHiringTeam.length &&
      this.recentHiringTeam.includes(this.current.user)
      ? []
      : [this.current.user];
  }

  get selectedUserItem() {
    const user = this.resolvedUser.value;
    return user &&
      user.id !== this.current.user.id &&
      !this.recentHiringTeam.includes(user)
      ? [user]
      : [];
  }

  get searchParams() {
    return {
      allowed_to_login: true,
      with_access_to_candidate_id: this.args.candidate?.id,
    };
  }

  fetchSuggestedUsers = dropTask(async () => {
    if (this.job) {
      this.recentHiringTeam = await get(this.job, 'teamMembersAndRecruiter');
    } else if (this.candidate) {
      const candidate = await get(this.args, 'candidate');

      if (candidate) {
        const users = await candidate.fetchLastJobHiringTeam();
        this.recentHiringTeam = users;
      }
    }
  });
}
