/* import __COLOCATED_TEMPLATE__ from './add.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';

import { TYPE_ICONS } from './default-field';

export default class FormBuilderAddButton extends Component {
  typeIcons = TYPE_ICONS;

  @argDefault buttonStyle = 'primary';

  get types() {
    const { allowedTypes } = this.args;

    const allTypes = Object.keys(TYPE_ICONS);

    if (Array.isArray(allowedTypes)) {
      return allTypes.filter((type) => allowedTypes.includes(type));
    }

    if (this.hasSingleType) {
      return [];
    }

    return allTypes;
  }

  get hasSingleType() {
    const { allowedTypes } = this.args;
    return typeof allowedTypes === 'string';
  }

  @tracked isOpen = false;

  @action
  onClick() {
    if (this.hasSingleType) {
      const type = this.args.allowedTypes;
      this.args.onFieldAdd(type);
      return;
    }

    this.toggleOpen();
  }

  @action
  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  @action
  onClickOutside() {
    this.isOpen = false;
  }
}
