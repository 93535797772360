/* import __COLOCATED_TEMPLATE__ from './notes-list.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import JobApplicationModel from 'teamtailor/models/job-application';
import CandidateModel from 'teamtailor/models/candidate';

type NotesListArgs = {
  jobApplication: JobApplicationModel;
};

export default class CompareCandidatesNotesList extends Component<NotesListArgs> {
  @tracked showAllNotes = false;

  @action
  toggleShowAllNotes() {
    this.showAllNotes = !this.showAllNotes;
  }

  get candidate() {
    return this.args.jobApplication.candidate as unknown as CandidateModel;
  }

  get notes() {
    const notes = get(this.candidate, 'notes' as keyof CandidateModel);
    if (!notes) {
      return [];
    }

    return notes.toArray().reverse();
  }

  get firstNote() {
    return this.notes[0];
  }

  get showMoreButton() {
    return (
      this.notes.length > 1 ||
      (this.firstNote && this.firstNote.note.length > 200)
    );
  }
}
