/* import __COLOCATED_TEMPLATE__ from './created.hbs'; */
import Base from './base';
import { get } from 'teamtailor/utils/get';
export default class CreatedComponent extends Base {
  get jobApplication() {
    return get(this.activity, 'jobApplication');
  }

  get promotion() {
    return get(this.jobApplication, 'promotion');
  }

  get isShareLink() {
    return (
      get(this.activity, 'actionData.channel_type') === 'share_link' ||
      get(this.promotion, 'isShareLink')
    );
  }

  get isQrCode() {
    return get(this.activity, 'actionData.channel_type') === 'qr_code';
  }

  get shareLinkName() {
    return (
      get(this.activity, 'actionData.promotion_name') ||
      get(this.promotion, 'shareLinkName')
    );
  }

  get channelName() {
    return (
      get(this.activity, 'actionData.channel_name') ||
      get(this.promotion, 'channel.name')
    );
  }

  get isFromPromotion() {
    return (
      !!get(this.activity, 'actionData.channel_type') ||
      get(this.jobApplication, 'isFromPromotion')
    );
  }

  get isShareLinkOrQrCode() {
    return (
      this.isShareLink ||
      this.isQrCode ||
      get(this.promotion, 'isShareLinkOrQrCode')
    );
  }
}
