/* import __COLOCATED_TEMPLATE__ from './sort-button.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

type SortOption = {
  id: string;
  label: string;
};

export default class extends Component<{
  isActive: boolean;
  currentSort?: string;
  defaultSort?: string;
  sortOptions?: SortOption[];
}> {
  @service declare intl: IntlService;

  get SortOptions(): SortOption[] {
    if (this.args.sortOptions) return this.args.sortOptions;
    return [
      {
        id: 'pinned-desc',
        label: this.intl.t(
          'jobs.index.jobs_list.sorting_options.default_sorting'
        ),
      },
      {
        id: 'created_at-desc',
        label: this.intl.t('jobs.index.jobs_list.sorting_options.latest_first'),
      },
      {
        id: 'created_at-asc',
        label: this.intl.t('jobs.index.jobs_list.sorting_options.oldest_first'),
      },
      {
        id: 'title-asc',
        label: this.intl.t('jobs.index.jobs_list.sorting_options.title_a_z'),
      },
      {
        id: 'title-desc',
        label: this.intl.t('jobs.index.jobs_list.sorting_options.title_z_a'),
      },
    ];
  }

  get isActive(): boolean {
    return (
      this.args.isActive || !(this.args.currentSort === this.args.defaultSort)
    );
  }
}
