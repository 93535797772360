/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { scrollToPromise } from 'teamtailor/utils/scroll-to-promise';
import SelectedCandidateDisplayColumnModel from 'teamtailor/models/selected-candidate-display-column';
import CustomizeTableColumn, {
  findMatchingSelectedColumnRecord,
} from 'teamtailor/utils/candidate-table/column';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';

interface CustomizeTableArgs {
  headingBarIsStuck: boolean;
  availableColumns?: CustomizeTableColumn[];
  selectedColumns: CustomizeTableColumn[];
  defaultColumns: CustomizeTableColumn[];
}

export default class CandidateFilters extends Component<CustomizeTableArgs> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @tracked
  filterValue = '';

  get availableColumns() {
    return this.args.availableColumns || [];
  }

  get options() {
    const translationPrefix = 'components.fancy_filters.filters';
    return this.availableColumns.reduce<CustomizeTableColumn[]>(
      (acc, column) => {
        Object.assign(column, {
          translationPrefix,
        });

        if (column.parent) {
          Object.assign(column.parent, {
            translationPrefix,
          });
        }

        if (column.parent) {
          if (!acc.includes(column.parent)) {
            acc.push(column.parent);
          }
        } else {
          acc.push(column);
        }

        return acc;
      },
      []
    );
  }

  @action
  resetColumns() {
    const { user } = this.current;
    get(user, 'selectedCandidateDisplayColumns').invoke('destroyRecord');
  }

  @action
  getColumnInSelectedColumns(column: CustomizeTableColumn) {
    return this.args.selectedColumns.find((col) => {
      if (
        col.category === 'answers_questions' ||
        col.category === 'partner_results'
      ) {
        return col.name === column.name && col.nested?.id === column.nested?.id;
      } else {
        return col.name === column.name;
      }
    });
  }

  @action
  columnIsSelected(column: CustomizeTableColumn) {
    return !!this.getColumnInSelectedColumns(column);
  }

  @action
  async handleSelectColumn(selectedColumn: CustomizeTableColumn) {
    if (this.args.headingBarIsStuck) {
      await scrollToPromise(0);
    }

    const column = this.getColumnInSelectedColumns(selectedColumn);

    const selected = !column;

    if (selected) {
      this.args.selectedColumns.push(selectedColumn);
    } else {
      this.args.selectedColumns.removeObject(column);
    }

    this.saveSelectedColumns(selectedColumn, selected);
  }

  async createSelectedColumnRecord({
    column,
    saveColumn = true,
  }: {
    column: CustomizeTableColumn;
    saveColumn?: boolean;
  }): Promise<SelectedCandidateDisplayColumnModel> {
    const attrs: { [key: string]: any } = {
      user: this.current.user,
      candidateColumnName: column.name,
    };

    if (column.category === 'partner_results' && column.nested?.id) {
      attrs.partner = await this.store.findRecord('partner', column.nested.id);
    } else if (column.isCustomField && column.id) {
      attrs.customField = await this.store.findRecord(
        'custom-field',
        column.id
      );
    } else if (column.category === 'answers_questions' && column.nested?.id) {
      attrs.question = await this.store.findRecord(
        'question',
        column.nested.id
      );
    }

    const selectedColumn = this.store.createRecord(
      'selected-candidate-display-column',
      attrs
    );

    if (saveColumn) {
      return selectedColumn.save();
    } else {
      return selectedColumn;
    }
  }

  createSelectedColumnRecords({
    columns,
    saveColumns = true,
  }: {
    columns: CustomizeTableColumn[];
    saveColumns?: boolean;
  }): SelectedCandidateDisplayColumnModel[] {
    const records: SelectedCandidateDisplayColumnModel[] = [];

    columns.forEach(async (column) => {
      records.push(
        await this.createSelectedColumnRecord({
          column,
          saveColumn: saveColumns,
        })
      );
    });

    return records;
  }

  async saveSelectedColumns(
    selectedColumn: CustomizeTableColumn,
    selected: boolean
  ) {
    let selectedColumnsByUser = get(
      this.current.user,
      'selectedCandidateDisplayColumns'
    ).toArray();

    if (selectedColumnsByUser.length) {
      if (selected) {
        selectedColumnsByUser.addObject(
          await this.createSelectedColumnRecord({ column: selectedColumn })
        );
      } else {
        const selectedColumnRecord = findMatchingSelectedColumnRecord(
          selectedColumn,
          selectedColumnsByUser
        );
        selectedColumnRecord?.destroyRecord();
      }
    } else {
      selectedColumnsByUser = this.createSelectedColumnRecords({
        columns: this.args.selectedColumns,
      });
    }
  }

  @action
  onSearch(event: InputEvent) {
    const { value } = event.target as HTMLInputElement;

    this.filterValue = value;
  }
}
