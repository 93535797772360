/* import __COLOCATED_TEMPLATE__ from './subscription-checkbox.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export interface SubscriptionCheckboxArgs {
  onChange: (subscriptionCode: string) => void;
  subscriptionName: string;
  method: string;
  pickedSubscriptions: string[];
}

export default class SubsciptionCheckbox extends Component<SubscriptionCheckboxArgs> {
  get pickedSubscriptions() {
    return this.args.pickedSubscriptions;
  }

  @tracked checked = false;

  @action
  didInsert() {
    this.checked = this.pickedSubscriptions.includes(this.subscriptionCode);
  }

  get subscriptionCode() {
    return `${this.args.subscriptionName}.${this.args.method}`;
  }

  @action
  onChange() {
    this.args.onChange(this.subscriptionCode);
    this.checked = this.pickedSubscriptions.includes(this.subscriptionCode);
  }
}
