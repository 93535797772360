/* import __COLOCATED_TEMPLATE__ from './block-editor-item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as controller } from '@ember/controller';

export default class BlockEditorItemComponent extends Component {
  @service router;

  el;

  @controller('content.editor') contentEditorController;

  @action handleMouseEnter() {
    this.contentEditorController.hoveredSectionData = undefined;
  }

  get section() {
    return this.realSection?.isLinked
      ? this.realSection.parent
      : this.realSection;
  }

  get realSection() {
    return this.args.section;
  }

  get isButtonDisabled() {
    return (
      this.args.isLocked ||
      (this.section && !get(this, 'section.areSettingsEditable'))
    );
  }

  get isDisabled() {
    if (this.args.isEditingName) {
      return false;
    }

    if (this.args.isDisabled) {
      return true;
    }

    if (['footer', 'header'].includes(this.args.name)) {
      return false;
    }

    return !this.realSection.enabled;
  }

  get customName() {
    return get(this, 'section.customName') || get(this, 'section.prettyName');
  }

  set customName(value) {
    set(this, 'section.customName', value);
  }

  get previewIsHovered() {
    const hoveredSectionIdOrName =
      this.args.hoveredSectionData?.idOrName?.toString();
    const sectionId = get(this.section, 'id')?.toString();

    return (
      (hoveredSectionIdOrName === 'posts' &&
        get(this.args.section, 'name') === 'posts') ||
      (this.args.name === 'header' && hoveredSectionIdOrName === 'header') ||
      (this.args.name === 'footer' && hoveredSectionIdOrName === 'footer') ||
      (hoveredSectionIdOrName &&
        sectionId &&
        sectionId === hoveredSectionIdOrName)
    );
  }

  @action
  onCustomNameChange(name) {
    this.args.saveCustomName(name, this.section);
  }

  @action
  focusAndSelectText(inputElement) {
    inputElement.focus();
    inputElement.select();
  }
}
