/* import __COLOCATED_TEMPLATE__ from './basic-template.hbs'; */
import Component from '@glimmer/component';
import { get, set, action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import Purchase from 'teamtailor/lib/purchase';
import { isPresent } from '@ember/utils';

export default class extends Component {
  @service intl;
  @service flashMessages;
  @service current;

  @tracked channel = this.args.channel;
  @tracked promotion = this.args.promotion;

  constructor() {
    super(...arguments);
    this.purchase = new Purchase({
      channel: this.channel,
      promotion: this.promotion,
      currency: this.channel.currency,
      selectedBudget: null,
    });
  }

  get channelDescription() {
    return htmlSafe(get(this, 'channel.description'));
  }

  onSuccess() {
    later(
      this,
      () => {
        get(this, 'flashMessages').success(
          this.intl.t(
            'components.promotion.basic_template.promotion_will_be_published'
          )
        );
        set(this, 'purchase.selectedBudget', null);

        if (get(this, 'args.onPublishSuccess')) {
          get(this, 'args.onPublishSuccess')();
        }
      },
      500
    );
  }

  get isPurchaseButtonDisabled() {
    if (this.customOptionSelected) {
      if (isPresent(this.purchase.customPrice)) {
        const availableAmounts = this.purchase.availableBudgets
          .map((budget) => budget.amount)
          .filter(Boolean);

        return (
          Number(this.purchase.customPrice) < Math.min(...availableAmounts)
        );
      } else {
        return true;
      }
    }

    return false;
  }

  get customOptionSelected() {
    return this.purchase.pickedBudget === 'custom';
  }

  get price() {
    return this.customOptionSelected
      ? Number(this.purchase.customPrice)
      : this.purchase.pickedBudget.amount;
  }

  get paymentProcessorSetting() {
    return get(this.current.company, 'paymentProcessorSetting');
  }

  get vatPercentage() {
    return get(this.paymentProcessorSetting, 'vatPercentage');
  }

  get packageName() {
    return this.customOptionSelected
      ? 'custom'
      : this.purchase.pickedBudget.name;
  }

  @action
  pick() {
    this.purchase.pick().then(() => {
      this.onSuccess();
    });
  }

  @action
  setPrice(value) {
    if (isPresent(value)) {
      const number = Number(value);

      this.purchase.customPrice = Number.isInteger(number)
        ? number
        : Math.floor(number);
    }
  }
}
