/* import __COLOCATED_TEMPLATE__ from './summarize-button.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

import CandidateModel from 'teamtailor/models/candidate';
import CopilotService from 'teamtailor/services/copilot';

interface Args {
  candidate: CandidateModel;
}

export default class extends Component<Args> {
  @service declare copilot: CopilotService;

  generateCandidateResumeSummary = dropTask(async () => {
    this.args.candidate.resumeSummaryStatus = 'started'; // To show the spinner
    await this.copilot.generateCandidateResumeSummary(this.args.candidate.id);
  });

  get isGeneratingCandidateResumeSummary() {
    return this.args.candidate.resumeSummaryStatus === 'started';
  }
}
