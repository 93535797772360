/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class ImageWidget extends WidgetClass {
  @service declare current: CurrentService;

  get imageUrl(): string | null {
    return get(this.widget, 'options.imageUrl');
  }
}
