/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import classnames from 'teamtailor/utils/classnames';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import Store from '@ember-data/store';
import IntercomService from 'teamtailor/services/intercom';
import MeetingEventQueryService from 'teamtailor/services/meeting-event-query';
import VideoMeetingService from 'teamtailor/services/video-meeting';
import RouterService from '@ember/routing/router-service';
import Owner from '@ember/owner';
import ConversationModel from 'teamtailor/models/conversation';
import JobModel from 'teamtailor/models/job';
import { keyResponder } from 'ember-keyboard';
import ModalService from 'teamtailor/services/modal';
import { modifier } from 'ember-modifier';

const PAGES = {
  messages: 'candidate',
  global_comments: 'global_comments',
  collaborate: 'job',
  todos: 'todos',
  meetings: 'meetings',
};

const GLOBAL_COMMENTS_CHANNEL_IDS = [
  'unread',
  'mentioned',
  'pinned',
  'general',
];

export interface ToolboxContainerSignature {
  Args: {
    path?: string;
    onClose: (page?: string | null) => void;
  };
}

@keyResponder
export default class extends Component<ToolboxContainerSignature> {
  @service declare store: Store;
  @service declare intercom: IntercomService;
  @service declare meetingEventQuery: MeetingEventQueryService;
  @service declare modal: ModalService;

  @service declare videoMeeting: VideoMeetingService;
  @service declare router: RouterService;

  @tracked isOpen = false;
  @tracked modelData: null | ConversationModel | JobModel | string | undefined =
    null;

  @tracked hasContent: null | boolean = null;

  constructor(owner: Owner, args: ToolboxContainerSignature['Args']) {
    super(owner, args);
    this.fetchModelTask.perform();
  }

  get path() {
    return (this.args.path || '').split(',');
  }

  get page() {
    const [page] = this.path;
    return Object.keys(PAGES).includes(page!) ? page : null;
  }

  get scopeFilter() {
    const [, filter] = this.path;
    return filter;
  }

  get statusFilter() {
    const [, , status] = this.path;
    return status;
  }

  get sorting() {
    const [, , sorting] = this.path;
    return sorting;
  }

  get id() {
    if (this.page === 'messages') {
      const [, , , id] = this.path;
      return id;
    }

    if (this.page === 'meetings') {
      const [, , , , id] = this.path;
      return id;
    }

    if (this.page === 'global_comments') {
      const [, jobId] = this.path;
      return jobId;
    }

    return undefined;
  }

  get action() {
    if (this.page === 'meetings') {
      const [, , , action] = this.path;
      return action;
    }

    return undefined;
  }

  get showCompleted() {
    const [, , , showCompleted] = this.path;
    return showCompleted || '';
  }

  fetchModelTask = restartableTask(async () => {
    if (this.id || this.action) {
      this.hasContent = true;
    } else {
      if (this.hasContent !== null) {
        this.hasContent = false;
      }
    }

    if (this.page === 'messages') {
      this.modelData = this.id
        ? await this.store.findRecord('conversation', this.id)
        : null;
    }

    if (this.page === 'global_comments') {
      if (GLOBAL_COMMENTS_CHANNEL_IDS.includes(this.id!)) {
        this.modelData = this.id;
      } else {
        this.modelData = this.id
          ? await this.store.findRecord('job', this.id)
          : null;
      }
    }
  });

  get overlayClass() {
    return classnames('overlay', {
      'overlay--withVideoMeetingSidebar': this.videoMeeting.isActiveSideBar,
    });
  }

  get sidebarClass() {
    return classnames('sidebar', this.page ? 'show' : 'hide', {
      border: this.hasContent && !(this.page === 'global_comments'),
      shadow: !this.hasContent,
    });
  }

  get contentContainerClass() {
    return classnames('contentContainer', {
      show: this.hasContent === true,
      hide: this.hasContent === false && this.isOpen,
    });
  }

  get contentClass() {
    return classnames('content', {
      show: this.hasContent === true,
      hide: this.hasContent === false && this.isOpen,
    });
  }

  get closeButtonClass() {
    return classnames('closeButton', this.page ? 'show' : 'hide');
  }

  get returnQuery() {
    return [this.page, this.scopeFilter, this.statusFilter].join(',');
  }

  @action
  onEscPress() {
    if (
      this.hasContent &&
      (this.page === 'meetings' || this.page === 'messages')
    ) {
      this.router.transitionTo({
        queryParams: { toolbox: this.returnQuery },
      });
    } else {
      this.close();
    }
  }

  close(page?: string | null) {
    this.args.onClose(page);
    this.intercom.showWidget();
  }

  @action
  fetchModel() {
    this.fetchModelTask.perform();
  }

  handleModelRegistring = modifier((el: HTMLElement) => {
    return this.modal.registerOpenModal(el);
  });

  @action
  onInsert(element: HTMLElement) {
    this.intercom.hideWidget();

    element.addEventListener('animationend', (event) => {
      // Wait until blur is gone to consider closed
      if (
        event.target instanceof HTMLElement &&
        event.target.dataset.content === 'sidebar'
      ) {
        this.isOpen = this.page !== null;

        if (!this.isOpen) {
          this.hasContent = null;
          // Reset scrollbar
          document.body.style.paddingRight = '';
          document.body.classList.remove('overflow-hidden');
        }
      }
    });

    if (this.videoMeeting.isActiveSideBar) return;
    // Hide scrollbar
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.classList.add('overflow-hidden');
  }

  @action
  onClose() {
    this.close(this.id ? this.page : null);
  }

  @action
  onForceClose() {
    this.close(null);
  }

  @action
  toggleEventScheduler(candidateId: string, jobId: string) {
    this.router.transitionTo({
      queryParams: {
        modal: JSON.stringify({
          component: 'meeting/new-modal',
          arg: {
            candidateId,
            jobId,
            bulkOptions: null,
          },
        }),
      },
    });
  }
}
