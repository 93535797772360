/* import __COLOCATED_TEMPLATE__ from './conversation-row.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import Store from '@ember-data/store';
import TimeFormatService from 'teamtailor/services/time-format';
import Current from 'teamtailor/services/current';
import NotificationsService from 'teamtailor/services/notifications';
import { ConversationModel } from 'teamtailor/models';

interface ToolboxMessagesConversationRow {
  Args: {
    conversation: ConversationModel;
  };
}

export default class ToolboxMessagesConversationRowComponent extends Component<ToolboxMessagesConversationRow> {
  @service declare store: Store;
  @service declare notifications: NotificationsService;
  @service declare timeFormat: TimeFormatService;
  @service declare current: Current;

  @tracked isDropdownOpen = false;
  @tracked showReassignModal = false;
  @tracked showAddUsersModal = false;

  get conversation() {
    return this.args.conversation;
  }

  get jobTitle() {
    return get(this.conversation.jobApplication, 'jobTitle');
  }

  @action
  handleIsDropdownOpen() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
