/* import __COLOCATED_TEMPLATE__ from './recruiter-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import ExternalRecruiterValidations from 'teamtailor/validations/external-recruiter';
import ExternalRecruitingFirmValidations from 'teamtailor/validations/recruiting-firm';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { get } from 'teamtailor/utils/get';

export default class RecruiterFormComponent extends Component {
  ExternalRecruiterValidations = ExternalRecruiterValidations;
  ExternalRecruitingFirmValidations = ExternalRecruitingFirmValidations;

  @service intl;
  @service store;
  @service current;

  @tracked firmChangeset;

  get isNew() {
    return get(this.args.recruiter, 'isNew');
  }

  get header() {
    const action = this.isNew ? 'add_title' : 'edit_title';
    return this.intl.t(
      `settings.external_recruitment.recruiters.external_recruiter_form.${action}`
    );
  }

  get saveButtonTranslationKey() {
    return this.isNew ? 'common.add' : 'common.update';
  }

  get recruitingFirmOptions() {
    return get(this.current.company, 'recruitingFirms')
      .toArray()
      .filter((f) => !f.isNew);
  }

  @action
  handlePictureChange(changeset, picture) {
    set(changeset, 'picture', picture);
    set(changeset, 'pictureCache', picture);
  }

  @action
  handlePictureRemove(changeset) {
    set(changeset, 'picture', null);
    set(changeset, 'pictureCache', null);
  }

  @action
  handleRecruitingFirm(changeset, firm) {
    set(changeset, 'recruitingFirm', firm);
  }

  @action
  onCreateRecruitingFirm(changeset) {
    set(
      changeset,
      'recruitingFirm',
      this.store.createRecord('recruitingFirm', {
        company: this.current.company,
      })
    );
    this.firmChangeset = new Changeset(
      get(changeset, 'recruitingFirm'),
      lookupValidator(ExternalRecruitingFirmValidations),
      ExternalRecruitingFirmValidations
    );
  }

  @action
  onCancelNewRecruitingFirm(changeset) {
    get(changeset, 'recruitingFirm').deleteRecord();
    set(changeset, 'recruitingFirm', null);
    this.firmChangeset.destroy();
  }
}
