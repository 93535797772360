/* import __COLOCATED_TEMPLATE__ from './figure-picker.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Icon {
  iconName: string;
  prefix: string;
}

export default class UnicornFigurePicker extends Component {
  @tracked declare selectedIcon: string;

  @action
  onSelectIcon(icon: Icon) {
    this.selectedIcon = icon.iconName;
  }
}
