/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

interface UnicornLinkComponentSignature {
  Args: {
    text: string;
    open: boolean;
    route?: string;
  };
}

export default class UnicornLinkComponent extends Component<UnicornLinkComponentSignature> {
  @service declare router: RouterService;

  @tracked open = false;

  constructor(owner: unknown, args: UnicornLinkComponentSignature['Args']) {
    super(owner, args);
    this.open = this.args.open;
  }

  get id(): string {
    return guidFor(this);
  }

  @action
  handleSubMenuClick() {
    this.open = !this.open;

    if (this.args.route) {
      this.router.transitionTo(this.args.route);
    }
  }
}
