/* import __COLOCATED_TEMPLATE__ from './raw-body-modal.hbs'; */
import Component from '@glimmer/component';
import { dropTask, lastValue } from 'ember-concurrency';

export default class extends Component {
  constructor() {
    super(...arguments);
    this.fetchRawBody.perform();
  }

  @lastValue('fetchRawBody')
  rawBody;

  fetchRawBody = dropTask(async () => {
    return this.args.message.getRawBody().then((data) => {
      return data.raw_body;
    });
  });
}
