/* import __COLOCATED_TEMPLATE__ from './enable.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';

import JobModel from 'teamtailor/models/job';

interface Args {
  job: JobModel;
}

export default class extends Component<Args> {
  enableSuggestedCandidates = dropTask(async () => {
    this.args.job.suggestedStageEnabled = true;
    await this.args.job.save();
  });
}
