/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';
import classnames from 'teamtailor/utils/classnames';
import { action, get } from '@ember/object';

export default class extends Component {
  @service iframePreview;
  @service ttAlert;
  @service router;
  @service flipper;
  @service current;
  @service blockAdjustments;

  @tracked emptyStateEl;
  @tracked editorActionsEl;
  @tracked listWrapperEl;

  get isEditingBlockSetting() {
    return this.blockAdjustments.isEditing;
  }

  get isPublished() {
    return this.args.model?.isPublished;
  }

  get careerSite() {
    return (
      get(this, 'args.model.careerSite') ||
      get(this.current.company, 'defaultCareerSite')
    );
  }

  get hasUnpublishedChanges() {
    return (
      this.hasUnpublishedPageChanges ||
      this.hasUnpublishedDesignChanges ||
      this.hasUnpublishedHeaderChanges
    );
  }

  get hasUnpublishedPageChanges() {
    return this.args.model?.unpublishedChanges;
  }

  get hasUnpublishedDesignChanges() {
    return (
      this.args.displayDesignChanges &&
      get(this.current.company, 'draftDesign.unpublishedChanges')
    );
  }

  get hasUnpublishedHeaderChanges() {
    return (
      !this.args.displayDesignChanges &&
      get(this, 'careerSite.careerSiteHeader.unpublishedChanges')
    );
  }

  get isResponsive() {
    return this.args.preview === 'responsive';
  }

  get isDesktop() {
    return this.args.preview === 'desktop';
  }

  get isTablet() {
    return this.args.preview === 'tablet';
  }

  get isMobile() {
    return this.args.preview === 'mobile';
  }

  get isMobileOrTablet() {
    return this.isMobile || this.isTablet;
  }

  get isLandscape() {
    return this.args.orientation && this.args.orientation === 'landscape';
  }

  get isPortrait() {
    return !this.args.orientation || this.args.orientation === 'portrait';
  }

  get otherOrientation() {
    return this.isPortrait ? 'landscape' : 'portrait';
  }

  get iframeStyle() {
    let previewStyles;
    if (this.isResponsive) {
      previewStyles = 'width: 100%';
    } else if (this.isDesktop) {
      previewStyles = 'width: 100%; min-width: 1600px';
    } else if (this.isTablet) {
      const scale = this.isPortrait ? 0.9 : 1;
      const width = 768 * scale;
      const height = 1024 * scale;
      previewStyles = this.isPortrait
        ? `width: ${width}px; height: ${height}px`
        : `width: ${height}px; height: ${width}px`;
    } else if (this.isMobile) {
      const scale = this.isPortrait ? 0.85 : 1;
      const width = 393 * scale;
      const height = 852 * scale;
      previewStyles = this.isPortrait
        ? `width: ${width}px; height: ${height}px`
        : `width: ${height}px; height: ${width}px`;
    }

    return htmlSafe(previewStyles);
  }

  get iframeSrc() {
    if (this.args.model.isConnectPage) {
      const url = new URL(this.args.iframeSrc);
      const { selectedDepartment, selectedRole, refresh } = this.args;
      this.updateConnectIframeParams('department_id', url, selectedDepartment);
      this.updateConnectIframeParams('role_id', url, selectedRole);
      this.updateConnectIframeParams('refresh', url, refresh);
      return url.toString();
    }

    return this.args.overrideIframeSrc || this.args.iframeSrc;
  }

  get blocksSidebarClasses() {
    return classnames(
      'c-content-block-editor__sidebar tt-dark-theme',
      `c-content-block-editor__sidebar--${this.args.mode}`
    );
  }

  get overlayClasses() {
    return classnames(
      'c-content-block-editor__menu-overlay',
      'transition-all duration-200 ease-in-out'
    );
  }

  get blocksPreviewResponsiveClasses() {
    return classnames(
      'flex bg-neutral rounded-8 c-content-block-editor__preview__responsive-toolbar',
      'c-content-block-editor__toolbar',
      {
        'c-content-block-editor__preview__responsive-toolbar--hide':
          !this.args.responsive,
      }
    );
  }

  updateConnectIframeParams(param, url, value) {
    if (value) {
      url.searchParams.append(param, value.id || value);
    } else {
      url.searchParams.delete(param);
    }
  }

  @action
  handleTransition(routeName, sectionId) {
    if (sectionId) {
      this.router.transitionTo(routeName, sectionId);
    } else {
      this.router.transitionTo(routeName);
    }
  }

  @action
  handlePublishClick() {
    return this.hasUnpublishedDesignChanges
      ? this.args.onPublishDesign()
      : this.args.onPromoteChanges();
  }

  @action
  handleDiscardChangesClick() {
    return this.args.onDiscardChanges();
  }
}
