/* import __COLOCATED_TEMPLATE__ from './favourite-color.hbs'; */
import Component from '@glimmer/component';

const REGEX = /^#[0-9A-F]{6}$/i;

export default class QuestionAnswerFavouriteColor extends Component {
  get hasHexCode() {
    return REGEX.test(this.args.color);
  }
}
