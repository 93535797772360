/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import MessageModel from 'teamtailor/models/message';
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

export const replyPrefix = 'Re: ';

export function isSameSubject(first?: string, second?: string) {
  if (typeof first !== 'string' || typeof second !== 'string') {
    return null;
  }

  return first === second || first === second.replace(replyPrefix, '');
}

interface MessageArgs {
  message: MessageModel;
  messages: MessageModel[];
  index: number;
}

export default class Message extends Component<MessageArgs> {
  showMeta = trackedFunction(this, async () => {
    if (this.args.message.isScheduled) {
      return true;
    }

    const previousMessage = this.args.messages[this.args.index - 1];

    if (
      isSameSubject(this.args.message.subject, previousMessage?.subject) ===
      false
    ) {
      return true;
    }

    const currentMessageUser = await this.args.message.user;
    const previousMessageUser = await previousMessage?.user;

    if (currentMessageUser && previousMessageUser) {
      return currentMessageUser.id !== previousMessageUser.id;
    }

    return true;
  });

  get showUserImage() {
    return (
      !this.args.message.isInbound &&
      (this.showMeta.value || this.args.message.hasError)
    );
  }

  get showSubject() {
    return (
      !!this.args.message.subject &&
      !this.args.message.belongsTo('replyTo').id()
    );
  }

  get animationIndex() {
    return Math.floor(Math.random() * 7);
  }

  animateOutline = modifier((element: HTMLElement, [animate]) => {
    if (animate) {
      next(() => {
        element.classList.add('outline-transparent');
      });
    }
  });
}
