/* import __COLOCATED_TEMPLATE__ from './scorecard-scores.hbs'; */
import { set } from '@ember/object';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import HireQualityResponseModel from 'teamtailor/models/hire-quality-response';
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';
import ScorecardScoreModel from 'teamtailor/models/scorecard-score';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

type Args = {
  hireQualityResponse: HireQualityResponseModel;
};

export default class HireQualitySurveyScorecardScoresComponent extends Component<Args> {
  @service declare store: Store;

  @tracked scorecardScores?: ScorecardScoreModel[];

  get hireQualityResponse(): HireQualityResponseModel {
    return this.args.hireQualityResponse;
  }

  get responseScorecards() {
    return this.hireQualityResponse.scores;
  }

  findOrCreateScorecardScore(
    scorecardPick: ScorecardPickModel
  ): ScorecardScoreModel {
    const currentScorecardScores = this.responseScorecards;

    const scorecardScore = currentScorecardScores.find(
      (scorecardScore: ScorecardScoreModel) => {
        return get(scorecardScore.scorecardPick, 'id') === scorecardPick.id;
      }
    );

    return (
      scorecardScore ||
      this.store.createRecord('scorecard-score', {
        scorecardPick,
        user: this.hireQualityResponse.user,
        candidate: this.hireQualityResponse.candidate,
        score: null,
      })
    );
  }

  fetchPickedScorecards = dropTask(async () => {
    const jobApplication = await this.hireQualityResponse.jobApplication;
    const job = await jobApplication?.job;
    const jobScorecardPicks = (await job?.jobDetail)?.scorecardPicks;

    if (jobScorecardPicks) {
      this.scorecardScores = jobScorecardPicks.map(
        (scorecardPick: ScorecardPickModel) => {
          return this.findOrCreateScorecardScore(scorecardPick);
        }
      );

      set(this.hireQualityResponse, 'scores', this.scorecardScores);
    }
  });
}
