/* import __COLOCATED_TEMPLATE__ from './pipeline-overview-chart.hbs'; */
import Component from '@glimmer/component';
import DateRange from 'teamtailor/utils/date-range';
import { action } from '@ember/object';
import fontOptions from 'teamtailor/components/insights/charts/font-options';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import {
  stageTypeColors,
  bgStageTypeColorClasses,
} from 'teamtailor/components/insights/charts/colors';
import { Category } from 'teamtailor/models/stage-type';

const FIRST_STAGE_TYPES_IN_SNAPSHOT_DATE = '2022-04-12';

export type AccumulatedStageTypeData = {
  stageType: string;
  applications: number[];
  rejected: number[];
};

export type StageTypeData = {
  stageType: string;
  applications: number;
  rejected: number;
};

export type StageTypeRow = {
  date: string;
  isoYearIsoWeek?: number;
  stageTypes: StageTypeData[];
};

export type StageData = {
  dataId: string;
  name: string;
  stageType: Category;
  applications: number;
  rejected: number;
};

export type StageRow = {
  date: string;
  stages: StageData[];
  [key: string]: any;
};

type Args = {
  startDate: Date;
  endDate: Date;
  rows?: StageTypeRow[];
  stages?: StageRow[];
  stageTypes?: string[];
};

export default class InsightsWidgesPipelineOverview extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked missingDataTextWidth?: string;
  @tracked missingDataGridElement?: HTMLDivElement;
  @tracked showMissingDataTooltip = false;

  colors = stageTypeColors;

  get stageTypes() {
    return this.args.stageTypes || [];
  }

  sortByStageType(rows: StageData[]): StageData[] {
    const stageTypes = this.store.peekAll('stage-type');

    return rows.sort((a, b) => {
      const stageTypeA = stageTypes.find(
        (stageType) => stageType.category === a.stageType
      );
      const stageTypeB = stageTypes.find(
        (stageType) => stageType.category === b.stageType
      );

      return (
        (stageTypeA ? stageTypeA.orderIndex : 0.5) -
        (stageTypeB ? stageTypeB.orderIndex : 0.5)
      );
    });
  }

  get dateRange(): DateRange {
    const { startDate, endDate } = this.args;
    return new DateRange(startDate, endDate);
  }

  get dates(): string[] {
    return this.args.rows?.map(({ date }) => date) || [];
  }

  get allStages(): any[] {
    return this.sortByStageType(
      (this.args.stages || []).reduce((acc: any[], stageRow: StageRow) => {
        stageRow.stages.forEach((stage: StageData) => {
          const data = acc.find((it) => it.dataId === stage.dataId);
          if (!data) {
            acc.push({
              dataId: stage.dataId,
              name: stage.name,
              stageType: stage.stageType,
            });
          }
        });

        return acc;
      }, [])
    );
  }

  get datesBeforeData(): string[] {
    const dateComparison = this.dateRange.compare(
      new Date(FIRST_STAGE_TYPES_IN_SNAPSHOT_DATE)
    );
    if (dateComparison < 0) {
      return [];
    }

    if (dateComparison > 0) {
      return this.dates;
    }

    const datesBefore = this.dates.filter((date) =>
      moment(date).isSameOrBefore(FIRST_STAGE_TYPES_IN_SNAPSHOT_DATE)
    );
    if (!datesBefore.length) {
      return [];
    }

    const index = this.dates.indexOf(datesBefore[datesBefore.length - 1]!);

    return this.dates.slice(0, index);
  }

  get stageTypeRows(): AccumulatedStageTypeData[] {
    const emptyStageTypeRows: AccumulatedStageTypeData[] = this.stageTypes.map(
      (stageType) => ({
        stageType,
        applications: [],
        rejected: [],
      })
    );

    const rows = (this.args.rows || []).reduce(
      (acc: AccumulatedStageTypeData[], row: StageTypeRow) => {
        const emptyData = moment(row.date).isBefore(
          FIRST_STAGE_TYPES_IN_SNAPSHOT_DATE
        );
        this.stageTypes.forEach((stageType) => {
          const stageTypeData = row.stageTypes.find(
            (it: StageTypeData) => it.stageType === stageType
          );
          const item = acc.find((it) => it.stageType === stageType);
          if (item) {
            if (emptyData) {
              item.applications.push(0);
              item.rejected.push(0);
            } else {
              item.applications.push(stageTypeData?.applications || 0);
              item.rejected.push(stageTypeData?.rejected || 0);
            }
          }
        });

        return acc;
      },
      emptyStageTypeRows
    );

    return rows;
  }

  get stageTypeNames() {
    return this.stageTypes.map((stageType) => {
      return this.intl.t(`job.stage_types.${stageType}`);
    });
  }

  get legendColors() {
    return this.stageTypes.map((stageType) => {
      return this.colors[stageType];
    });
  }

  get legendColorClasses() {
    return this.stageTypes.map((stageType) => {
      return bgStageTypeColorClasses[stageType];
    });
  }

  get chartData(): any[] {
    const stageTypes = this.stageTypeRows;
    const data = this.stageTypes.map((stageType: string) => {
      const object = stageTypes.findBy('stageType', stageType);
      return object?.applications || [];
    });

    const finalData = this.stageTypes.map((series: string, index: number) => {
      const color: string | undefined = this.colors[series];
      return {
        name: this.intl.t(`job.stage_types.${series}`),
        data: data[index],
        color,
      };
    });

    return finalData;
  }

  get chartOptions(): any {
    return {
      chart: {
        type: 'column',
        height: '300px',
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 15,
      },

      title: {
        text: undefined,
      },

      xAxis: {
        labels: { enabled: false },
        categories: this.dates,
        lineColor: '#EEEFF2',
      },

      yAxis: {
        min: 0,
        allowDecimals: false,
        reversedStacks: false,
        offset: 30,
        tickLength: 30,
        gridLineColor: '#F8F8F9',

        title: {
          text: undefined,
        },

        labels: {
          enabled: true,
          padding: 0,
          x: 15,
          y: -5,
          style: fontOptions,
        },

        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color: '#57595f',
          },
        },
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        headerFormat: '<span>{point.x}</span><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },

      plotOptions: {
        column: {
          animation: {
            duration: 200,
          },

          stacking: 'normal',
          borderWidth: 0,
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
  }

  @action
  setMissingDataGridElement(element: HTMLDivElement): void {
    this.missingDataGridElement = element;
  }

  @action
  updateMissingDataWidth(element: HTMLDivElement): void {
    const width =
      (this.datesBeforeData.length *
        (this.missingDataGridElement?.offsetWidth || 0)) /
      this.dates.length;

    element.style.width = `${width}px`;
    this.missingDataTextWidth = `${width}px`;
  }

  @action
  updateMissingDataVisibility(element: HTMLDivElement): void {
    const { height, lineHeight } = getComputedStyle(element);
    const lineHeightNumber = parseInt(lineHeight.split('px')[0] || '0', 10);
    const heightNumber = parseInt(height.split('px')[0] || '0', 10);

    if (heightNumber > lineHeightNumber * 2) {
      this.showMissingDataTooltip = true;
    } else {
      this.showMissingDataTooltip = false;
    }
  }
}
