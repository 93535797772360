/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DepartmentsListComponent extends BaseComponent {
  @service intl;
  @service store;
  @service current;

  get departments() {
    let departments = get(
      this.current.company,
      'departmentsWithoutNoDepartment'
    );

    if (!departments.findBy('id', null)) {
      departments.unshift({
        id: null,
        name: this.intl.t('search.department.no_department'),
      });
    }

    return departments;
  }

  getDepartmentAtPlace = (place) => {
    if (this.args.model.sortedItems.length < place + 1) {
      return undefined;
    } else if (this.args.model.sortedItems[place].department) {
      return this.args.model.sortedItems[place].department;
    } else {
      return this.store.findRecord(
        'department',
        this.args.model.sortedItems[place].modelId
      );
    }
  };

  @action
  handleSelectDepartment(index, department) {
    return this.setModelAsSectionItem(index, department, 'Department');
  }
}
