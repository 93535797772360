/* import __COLOCATED_TEMPLATE__ from './chart.hbs'; */
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import AnalyticsService from 'teamtailor/services/analytics';
import {
  EventTypeResponse,
  PageviewTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import Settings, {
  limitRows,
  sortRows,
} from 'teamtailor/utils/insights/reports/settings';
import { cached } from '@glimmer/tracking';
import { maybeGroupSmallItems } from 'teamtailor/components/insights/charts/donut-chart-utils';
import ModelProperty from 'teamtailor/utils/insights/reports/properties/model-property';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';
import DateRange from 'teamtailor/utils/date-range';
import uniq from 'teamtailor/utils/uniq';

interface ComponentArgs {
  chartType: string;
  settings: Settings;
  rows: (PageviewTypeResponse & EventTypeResponse)[];
  isSharedReport?: boolean;
}

function replaceIdWithMissingString(
  intl: IntlService,
  property: BaseProperty,
  value: string
) {
  if (property.type === 'model') {
    return parseInt(value).toString() === value
      ? intl.t('insights.reports.builder.deleted_model', {
          model: (property as ModelProperty).translatedModelName,
        })
      : value;
  }

  return value;
}

export default class ReportsChartComponent extends Component<ComponentArgs> {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;

  get currentSettings() {
    return this.args.settings;
  }

  get showHorizontalBars() {
    return this.args.settings.direction === 'horizontal';
  }

  @cached
  get rows() {
    if (!this.currentSettings.baseProperty?.columnName) {
      return this.args.rows;
    }

    const sortProperty =
      this.currentSettings.sortProperty || this.currentSettings.baseProperty;
    const sortDirection = this.currentSettings.sortDirection || 'asc';
    const { limit } = this.currentSettings;

    return limitRows(
      sortRows(this.formattedRows, sortProperty, sortDirection),
      limit
    );
  }

  get formattedRows() {
    return this.args.rows.map((row) => {
      const newRow = { ...row };
      this.currentSettings.allSelectedProperties.forEach((property) => {
        if (
          property.columnName ===
            this.currentSettings.baseProperty?.columnName ||
          property.columnName ===
            this.currentSettings.additionalBaseProperty?.columnName
        ) {
          newRow[property.columnName] = property.formatBasePropertyValue(
            row[property.columnName]
          );
        } else {
          newRow[property.columnName] = property.format(
            row[property.columnName]
          );
        }
      });

      return newRow;
    });
  }

  @cached
  get basePropertyValues() {
    if (this.currentSettings.baseProperty?.columnName) {
      return uniq(
        this.rows.map(
          (row) => row[this.currentSettings.baseProperty!.columnName]
        )
      );
    }

    return [];
  }

  @cached
  get lineChartBasePropertyValues() {
    const { baseProperty } = this.currentSettings;
    if (!baseProperty) {
      return [];
    }

    let { basePropertyValues } = this;

    if (baseProperty.type === 'date') {
      let dates: (string | number)[] = [];
      if (this.currentSettings.hideEmpty) {
        dates = uniq(
          this.rows
            .sort((a, b) => baseProperty.sorter(a, b))
            .map((row) => row[baseProperty.columnName])
        );
      } else {
        const { startDate, endDate } = this.analytics;
        switch (baseProperty.id) {
          case 'date':
            dates = new DateRange(startDate, endDate).dateSpan.map((date) =>
              date.format('YYYY-MM-DD')
            );
            break;
          case 'week':
            dates = uniq(
              new DateRange(startDate, endDate).dateSpan.map((date) =>
                date.format(this.intl.t('insights.common.week_formatting'))
              )
            );
            break;
          case 'month':
            dates = uniq(
              new DateRange(startDate, endDate).dateSpan.map((date) =>
                date.format('MMMM YYYY')
              )
            );
            break;
          case 'year':
            dates = uniq(
              new DateRange(startDate, endDate).dateSpan.map((date) =>
                date.year()
              )
            );
            break;
        }
      }

      basePropertyValues = dates;
    }

    return basePropertyValues;
  }

  @cached
  get lineChartStartDate() {
    return this.lineChartBasePropertyValues.firstObject;
  }

  @cached
  get lineChartEndDate() {
    return this.lineChartBasePropertyValues.lastObject;
  }

  get legend() {
    switch (this.args.settings.chartType?.name) {
      case 'line':
      case 'bar':
        return this.series;

      case 'pie':
      default:
        return this.pieChartData;
    }
  }

  get hasOther() {
    return (
      this.args.settings.chartType?.name === 'pie' &&
      this.currentSettings.groupSmallValues &&
      this.currentSettings.groupPercentage &&
      this.pieChartData.length < this.rows.length
    );
  }

  get series() {
    const { additionalBaseProperty } = this.currentSettings;
    if (!additionalBaseProperty) {
      return this.currentSettings.selectedProperties.map(
        (prop) => prop.translatedName
      );
    }

    return uniq(this.rows.map((row) => row[additionalBaseProperty.columnName]))
      .map((value) =>
        replaceIdWithMissingString(this.intl, additionalBaseProperty, value)
      )
      .flat();
  }

  get pieChartData() {
    const { firstSelectedMetricProperty, baseProperty } = this.currentSettings;
    if (!firstSelectedMetricProperty || !baseProperty) {
      return [];
    }

    const items = this.basePropertyValues
      .map((basePropertyValue) => {
        const row = this.rows.find(
          (row) => row[baseProperty.columnName] === basePropertyValue
        );

        const value: number =
          row?.[firstSelectedMetricProperty.columnName] || 0;
        return {
          name: replaceIdWithMissingString(
            this.intl,
            baseProperty,
            basePropertyValue
          ),

          value,
        };
      })
      .sort((a, b) => b.value - a.value);

    return this.currentSettings.groupSmallValues &&
      this.currentSettings.groupPercentage
      ? maybeGroupSmallItems(
          items,
          this.currentSettings.groupPercentage,
          this.intl.t('insights.reports.other')
        )
      : items;
  }

  get lineChartData() {
    const {
      additionalBaseProperty,
      firstSelectedMetricProperty,
      baseProperty,
    } = this.currentSettings;
    if (!firstSelectedMetricProperty || !baseProperty) {
      return [];
    }

    let { rows }: { rows: { [key: string]: any }[] } = this;
    const { lineChartBasePropertyValues } = this;

    if (baseProperty.type === 'date') {
      rows = lineChartBasePropertyValues.map((date) => {
        const existing = rows.find(
          (val) => val[baseProperty.columnName] === date
        );
        if (existing) {
          return existing;
        }

        const emptyRow: { [key: string]: any } = {};
        this.currentSettings.allSelectedProperties.forEach((property) => {
          if (
            property.columnName ===
            this.currentSettings.baseProperty?.columnName
          ) {
            emptyRow[property.columnName] = date;
          } else {
            emptyRow[property.columnName] = 0;
          }
        });

        return emptyRow;
      });
    }

    let seriesNameValues: { columnName: string; value: any }[];
    if (additionalBaseProperty) {
      seriesNameValues = uniq(
        rows
          .map((row) => ({
            columnName: additionalBaseProperty.columnName,
            value: row[additionalBaseProperty.columnName],
          }))
          .reduce((arr: { columnName: string; value: any }[], item) => {
            if (!arr.find((i) => i.value === item.value)) {
              arr.push(item);
            }

            return arr;
          }, [])
          .flat()
      );

      return seriesNameValues.map(({ columnName, value }) => {
        return lineChartBasePropertyValues.map((basePropertyValue) => {
          const row = rows.find(
            (row) =>
              row[columnName] === value &&
              row[baseProperty.columnName] === basePropertyValue
          );

          if (!row) {
            return 0;
          }

          return row[firstSelectedMetricProperty.columnName];
        });
      });
    }

    return this.currentSettings.selectedProperties.map((property) => {
      return lineChartBasePropertyValues.map((basePropertyValue) => {
        const row = this.rows.find(
          (row) => row[baseProperty.columnName] === basePropertyValue
        );

        return row?.[property.columnName] || 0;
      });
    });
  }

  get barChartData() {
    const {
      additionalBaseProperty,
      firstSelectedMetricProperty,
      baseProperty,
    } = this.currentSettings;
    if (!firstSelectedMetricProperty || !baseProperty) {
      return {};
    }

    return this.basePropertyValues.map((basePropertyValue) => {
      let value = [];
      if (additionalBaseProperty) {
        const seriesNameValues = uniq(
          this.rows
            .map((row) => ({
              columnName: additionalBaseProperty.columnName,
              value: row[additionalBaseProperty.columnName],
            }))
            .reduce((arr: { columnName: string; value: any }[], item) => {
              if (!arr.find((i) => i.value === item.value)) {
                arr.push(item);
              }

              return arr;
            }, [])
            .flat()
        );

        value = seriesNameValues.map(({ columnName, value }) => {
          const row = this.rows.find(
            (row) =>
              row[columnName] === value &&
              row[baseProperty.columnName] === basePropertyValue
          );

          if (!row) {
            return 0;
          }

          return row[firstSelectedMetricProperty.columnName];
        });
      } else {
        value = this.currentSettings.selectedProperties.map((property) => {
          const row = this.rows.find(
            (row) => row[baseProperty.columnName] === basePropertyValue
          );

          return row?.[property.columnName] || 0;
        });
      }

      return {
        name: replaceIdWithMissingString(
          this.intl,
          baseProperty,
          basePropertyValue
        ),

        value,
      };
    });
  }
}
