/* import __COLOCATED_TEMPLATE__ from './table-search.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export interface TableSearchComponentSignature {
  Args: {
    value: string;
    placeholder: string;
    onChange?: (value: string) => void;
  };
}

export default class TableSearchComponent extends Component<TableSearchComponentSignature> {
  @action
  onSearchChanged(event: InputEvent) {
    const { value } = event.target as HTMLInputElement;
    this.args.onChange?.(value);
  }
}
