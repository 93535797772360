/* import __COLOCATED_TEMPLATE__ from './add-stage-modal.hbs'; */
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class StageListSettingsAddStageModalModal extends Component {
  @service current;

  @tracked selectedStageName;
  @tracked selectedStageType = this.stageTypes?.[0];
  @tracked createdStage;

  get stageTypes() {
    return get(this.current.company, 'editableStageTypes');
  }

  get options() {
    return [...this.args.presetStageNames, this.createdStage].compact();
  }

  @action
  changeStageName(value) {
    this.selectedStageName = value;

    this.selectedStageType = this.stageTypes.find(
      (stageType) => stageType.category === get(value, 'stageType.category')
    );
  }

  @action
  changeStageType(value) {
    this.selectedStageType = value;
  }

  @action
  changeOnCreateStageName(value) {
    this.selectedStageName = { id: value, stageType: this.selectedStageType };
    this.createdStage = this.selectedStageName;
  }

  @action
  saveStage() {
    this.args.onSave(this.selectedStageName, this.selectedStageType);
  }
}
