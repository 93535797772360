/* import __COLOCATED_TEMPLATE__ from './font.hbs'; */
import Base from './base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class extends Base {
  @service blockAdjustments;

  @tracked
  dropdownId = null;

  get isDisabled() {
    return (
      this.blockAdjustments.editingAttribute &&
      this.blockAdjustments.editingAttribute !== this.dropdownId
    );
  }

  @action
  setDropdownId(id) {
    this.dropdownId = id;
  }

  @action
  dropdownToggled(isOpen) {
    next(() => {
      this.blockAdjustments.editingAttribute = isOpen
        ? this.dropdownId
        : undefined;
    });
  }
}
