/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

export default class extends Component {
  @service current;
  @service timeFormat;

  @tracked flatpickerRef = null;
  @tracked flatpickerDate;

  dateCountContainers = {};

  constructor(...args) {
    super(...args);
  }

  get today() {
    return moment().startOf('day').toDate();
  }

  get userLocaleData() {
    const userLocale = this.current.company
      ? this.current.company.localeAts
      : 'en';
    return moment.localeData(userLocale);
  }

  get locale() {
    return {
      ...this.timeFormat.flatPickrLocaleData,
      ordinal: this.userLocaleData.ordinal,
      weekdays: {
        shorthand: this.userLocaleData.weekdaysMin().map((d) => d[0]),
        longhand: this.timeFormat.flatPickrLocaleData.weekdays.longhand,
      },
    };
  }

  get fromDate() {
    return this.selectedDate
      ? moment(this.selectedDate).format('YYYY-MM-DD')
      : null;
  }

  get toDate() {
    return this.fromDate;
  }

  @action
  flatpickerOnReady(_date, _str, instance) {
    this.flatpickerRef = instance;
  }

  @action
  onDayCreate(_dObj, _dStr, _fp, dayElem) {
    const createdDate = moment(dayElem.dateObj).format('YYYY-MM-DD');

    const innerBoxContainer = document.createElement('span');
    innerBoxContainer.className = `innerBox`;
    innerBoxContainer.innerHTML = dayElem.innerHTML;
    dayElem.innerHTML = '';
    dayElem.appendChild(innerBoxContainer);

    const dateCountContainer = document.createElement('span');
    dateCountContainer.className = `event event--${createdDate}`;
    innerBoxContainer.appendChild(dateCountContainer);

    this.dateCountContainers[createdDate] = dateCountContainer;
    this.args.registerDateCountContainers(this.dateCountContainers);
  }

  @action
  onMonthChange() {
    this.prevMonthDisabled =
      this.flatpickerRef?.currentMonth === this.today.getMonth() ||
      this.args.allowPreviousMonth;
    this.flatpickerDate = new Date();
    this.flatpickerDate.setFullYear(
      this.flatpickerRef?.currentYear,
      this.flatpickerRef?.currentMonth
    );

    this.args.onMonthChange?.(this.flatpickerDate);
  }

  @action
  nextMonth() {
    this.flatpickerRef?.changeMonth(1);
  }

  @action
  prevMonth() {
    this.flatpickerRef?.changeMonth(-1);
  }
}
