/* import __COLOCATED_TEMPLATE__ from './tag-group.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { argDefault } from 'teamtailor/utils/arg-default';
import TagModel from 'teamtailor/models/tag';
import { isArray } from '@ember/array';
import TimeFormatService from 'teamtailor/services/time-format';

interface TagGroupArgs {
  tags: TagModel[];
  limit?: number;
  tooltipSide?: string;
  compact?: boolean;
}

const DEFAULT_LIMIT = 2;

export default class UserGroup extends Component<TagGroupArgs> {
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;

  @argDefault compact = true;
  @argDefault limit = DEFAULT_LIMIT;

  get tags() {
    return isArray(this.args.tags) ? this.args.tags : [this.args.tags];
  }

  get visibleTags(): TagModel[] {
    if (this.limit) {
      return this.tags.slice(0, this.limit);
    } else {
      return this.tags;
    }
  }

  get hasMore(): boolean {
    return this.limit > 0 && this.tags.length > this.limit;
  }

  get visibleTagNames(): string {
    const oxfordCommaRegex = /,(?=[^,]+$)/;

    return new Intl.ListFormat(this.timeFormat.locale)
      .format(this.visibleTags.mapBy('name'))
      .replace(oxfordCommaRegex, '');
  }
}
