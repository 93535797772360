/* import __COLOCATED_TEMPLATE__ from './pagination-links.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class PaginationLinksComponent extends Component {
  @argDefault currentPage = 1;
  @argDefault lastPage = 1;

  width = 10;

  get prevDisabled() {
    return this.currentPage === 1;
  }

  get nextDisabled() {
    return this.currentPage === this.lastPage;
  }

  get firstDisabled() {
    return this.currentPage === 1;
  }

  get lastDisabled() {
    return !this.lastPage || this.currentPage === this.lastPage;
  }

  get listStartPage() {
    const { width } = this;
    const { lastPage } = this;

    if (lastPage - width <= 0) {
      return 1;
    }

    const { currentPage } = this;
    const halfWidthFromCurrent = currentPage - Math.ceil(width / 2);
    const fullWidthFromEnd = lastPage - width + 1;

    return Math.max(1, Math.min(halfWidthFromCurrent, fullWidthFromEnd));
  }

  get listEndPage() {
    const { listStartPage } = this;
    const { width } = this;
    const { lastPage } = this;

    return Math.min(listStartPage + width - 1, lastPage);
  }

  get pagesBeforeCurrent() {
    return this.range(this.listStartPage, this.currentPage - 1);
  }

  get pagesAfterCurrent() {
    return this.range(this.currentPage + 1, this.listEndPage);
  }

  setCurrentPage(page) {
    this.args.goToPage(page);
  }

  range(start, finish) {
    const range = [];
    for (let i = start; i <= finish; i++) {
      range.push(i);
    }

    return range;
  }

  @action
  goToPage(page) {
    this.setCurrentPage(page);
  }

  @action
  goToPrevPage() {
    const page = Math.max(this.currentPage - 1, 1);
    this.setCurrentPage(page);
  }

  @action
  goToNextPage() {
    const page = Math.min(this.currentPage + 1, this.lastPage);
    this.setCurrentPage(page);
  }
}
