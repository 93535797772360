/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import CandidateModel from 'teamtailor/models/candidate';
import Component from '@glimmer/component';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import JobApplicationModel from 'teamtailor/models/job-application';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel;
}

export default class CandidateModalNewMainHireQualitySurveysComponents extends Component<Args> {
  @service declare flipper: FlipperService;

  get hireQualityResponses() {
    if (this.args.candidate.isDestroying) {
      return [];
    }

    const jobApplicationId = get(this.args.jobApplication, 'id');
    return this.args.candidate.hireQualityResponses
      .toArray()
      .filter((hireQualityResponse) => {
        return (
          hireQualityResponse.jobApplicationId.toString() === jobApplicationId
        );
      });
  }
}
