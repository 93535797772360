/* import __COLOCATED_TEMPLATE__ from './answers.hbs'; */
import Component from '@glimmer/component';

type Question = {
  type: string;
};

type SurveyAnswersArgs = {
  question: Question;
  isAnonymous: boolean;
};

export default class SurveyAnswersComponent extends Component<SurveyAnswersArgs> {
  get isChoiceOrCheckboxQuestion(): boolean {
    return (
      this.args.question.type === 'FormQuestion::Choice' ||
      this.args.question.type === 'FormQuestion::Checkbox'
    );
  }

  get isTextQuestion(): boolean {
    return (
      this.args.question.type === 'FormQuestion::Text' ||
      this.args.question.type === 'FormQuestion::TextArea'
    );
  }

  get isScaleQuestion(): boolean {
    return this.args.question.type === 'FormQuestion::Scale';
  }
}
