/* import __COLOCATED_TEMPLATE__ from './tag-picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import TagModel from 'teamtailor/models/tag';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import { query } from 'ember-data-resources';
import { isArray } from '@ember/array';
import Store from '@ember-data/store';

type TagGroup = {
  groupName: string;
  options?: TagModel[];
};

type TagPickerArgs = {
  taggableType:
    | 'Job'
    | 'Candidate'
    | 'Page'
    | 'InterviewKit'
    | 'Question'
    | 'Image'
    | 'CannedResponse';
  searchPlaceholder?: string;
  options?: TagModel[];
  selected?: TagModel[];
  isMultiple?: boolean;
  disableCreate?: boolean;
  showCreateWhen?: boolean; // possible to supply value that overrides the default check if a user can create tags
  onSelect: (tag: TagModel) => void;
  isSubmenu?: boolean;
  onClose?: () => void;
  parentApi: {
    addClickOutsideElement: (
      arg: HTMLElement | null
    ) => (arg: HTMLElement | null) => void;
  };
};

const MAX_LIMIT = 8;

export default class CoreTagPickerComponent extends Component<TagPickerArgs> {
  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare store: Store;

  declare dropdownApi?: { close(): void };

  @tracked searchTerm: string | null = null;
  @tracked excludeIds: string[] = [];
  @tracked removeOutSideClickElement?: (arg: HTMLElement | null) => void;

  query = query(this, 'tag', () => {
    return {
      page: 1,
      per_page: MAX_LIMIT,
      taggable_type: this.taggableType,
      exclude_ids: this.excludeIds,
      query: this.searchTerm,
    };
  });

  get destinationElement() {
    return this.args.isSubmenu
      ? document.getElementById('ember-teamtailor-dropdown-submenu')
      : null;
  }

  get taggableType(): string {
    return this.args.taggableType;
  }

  get displayCreate() {
    if (this.args.showCreateWhen !== undefined) {
      return this.args.showCreateWhen;
    }

    return this.current.user.canCreateTags && !this.args.disableCreate;
  }

  get searchPlaceholder(): string {
    return this.args.searchPlaceholder
      ? this.args.searchPlaceholder
      : this.displayCreate
        ? this.intl.t('components.tag_picker.search_or_create')
        : this.intl.t('common.search');
  }

  get hasSelected(): boolean {
    return this.selectedAsArray.length > 0;
  }

  get selectedIds() {
    return this.selectedAsArray.mapBy('id');
  }

  get selected() {
    return this.args.selected;
  }

  get selectedAsArray() {
    let { selected } = this;
    if (selected && !isArray(selected)) {
      selected = [selected];
    }

    return selected || [];
  }

  get options(): TagGroup[] | TagModel[] | undefined {
    const queriedRecords = this.query.records || [];

    return this.isMultiple
      ? this.getMultipleOptions(queriedRecords as TagModel[])
      : this.getSingleOptions(queriedRecords as TagModel[]);
  }

  get isMultiple() {
    return this.args.isMultiple !== false;
  }

  getMultipleOptions(queriedTags: TagModel[]) {
    const tags = isEmpty(this.searchTerm)
      ? queriedTags.filter((tag: TagModel) => {
          return !this.selectedIds.includes(get(tag, 'id'));
        })
      : queriedTags;

    if (isEmpty(this.searchTerm) && this.hasSelected) {
      if (tags.length === 0) {
        return this.selected;
      }

      return [
        {
          groupName: this.intl.t('common.selected'),
          options: this.getSelectedToPrepend(tags),
        },
        {
          groupName: this.intl.t('common.tags'),
          options: tags,
        },
      ];
    }

    return tags;
  }

  getSingleOptions(tags: TagModel[]) {
    const selectedToPrepend = this.getSelectedToPrepend(tags);
    return [...selectedToPrepend, ...tags.toArray()];
  }

  getSelectedToPrepend(tags: TagModel[]) {
    const tagIds = tags.mapBy('id');
    return this.selectedAsArray.filter((tag: TagModel) => {
      return !tagIds.includes(get(tag, 'id'));
    });
  }

  get tagPurpose(): string {
    return `availableOn${this.taggableType}s`;
  }

  @action
  handleSearch(term: string): void {
    if (this.searchTerm !== term) {
      this.searchTerm = term;
    }

    if (this.searchTerm === '') {
      this.updateExcludedIds();
    }
  }

  @action
  handleRegisterApi(api: { close(): void }): void {
    this.dropdownApi = api;

    if (this.args.isSubmenu) {
      this.removeOutSideClickElement =
        this.args.parentApi.addClickOutsideElement(this.destinationElement);
    }
  }

  @action
  handleClose(): void {
    this.removeOutSideClickElement?.(this.destinationElement);
    this.args.onClose?.();
  }

  @action
  async handleCreate() {
    if (this.searchTerm) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tagParams: any = {
        name: this.searchTerm,
      };

      tagParams[this.tagPurpose] = true;

      const tag = this.store.createRecord('tag', tagParams);
      await tag.save();

      this.args.onSelect(tag);
      this.searchTerm = null;
      this.dropdownApi?.close();
    }
  }

  @action
  updateExcludedIds(): void {
    if (this.excludeIds.length !== this.selectedIds.length) {
      this.excludeIds = this.selectedIds;
    }
  }
}
