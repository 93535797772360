/* import __COLOCATED_TEMPLATE__ from './tt-admin-iframe.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import ENV from 'teamtailor/config/environment';

const URL = `${ENV.httpOrHttps}://${ENV.RAILS_HOST}/auth/tt-admin`;

export default class TtAdminIframeComponent extends Component {
  @service TtAdmin;

  url = URL;

  get showIframe() {
    return this.TtAdmin.showIframe;
  }
}
