/* import __COLOCATED_TEMPLATE__ from './breathing-animation.hbs'; */
/* eslint-disable no-await-in-loop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { animate } from 'teamtailor/ember-smooth';
import { wait } from 'teamtailor/ember-smooth';
import {
  restartableTask,
  timeout,
  task,
  waitForProperty,
  TaskForAsyncTaskFunction,
} from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import BowserService from 'teamtailor/services/bowser';
import { modifier } from 'ember-modifier';

const MAXIMUM_TIME_FOR_BREATHING = 60 * 1000;

interface BreathingAnimationArguments {
  onClose(arg?: string): void;
  onInsert(arg?: string): void;
}

export default class BreathingAnimation extends Component<BreathingAnimationArguments> {
  @service declare bowser: BowserService;
  @service declare intl: IntlService;

  @tracked animationCycleEnded = false;
  @tracked controlsVisible = true;
  @tracked elements: {
    firstPathElement?: HTMLElement;
    firstAnimateElement?: HTMLElement;
    restartBreathingElement?: HTMLElement;
    orbTextElement?: HTMLElement;
  } = {};

  @tracked declare orbText: string;
  @tracked showBye = false;
  @tracked showOrb = false;
  @tracked numberOfCompletedCycles = 0;

  delay = 2000;
  supportsCssPathFn = CSS.supports("d: path('')");

  constructor(owner: unknown, args: BreathingAnimationArguments) {
    super(owner, args);

    document.addEventListener('mousemove', this.showControls);
    this.hideControls.perform();
  }

  get bgAnimationEnabled() {
    return !['Firefox'].includes(this.bowser.browser.name!);
  }

  get delayInSeconds() {
    return `${this.delay / 1000}s`;
  }

  showControls = (): void => {
    this.controlsVisible = true;
    this.hideControls.perform();
  };

  hideControls = restartableTask(async () => {
    await timeout(1_500);
    this.controlsVisible = false;
  });

  prepareForBreathingExerciseModifier = modifier((element) => {
    if (!element.isConnected) {
      return;
    }

    this.args.onInsert();
    this.showOrb = true;
  });

  startBreathingExercise = restartableTask(async () => {
    this.showOrb = true;
    const [el, event] = this.supportsCssPathFn
      ? [this.elements.firstPathElement, 'animationiteration']
      : [this.elements.firstAnimateElement, 'repeatEvent'];

    if (el) {
      el.addEventListener(event, () => {
        this.animationCycleEnded = true;
      });
    }

    await wait(this.delay);

    let breathCycle = 0;
    const breatheText = [
      this.intl.t('breathing_animation.breathe_in'),
      this.intl.t('breathing_animation.breathe_out'),
    ] as const;
    const durationIn = 3000;
    const durationOut = 900;

    const breathInOutMaxCycles = Math.ceil(
      MAXIMUM_TIME_FOR_BREATHING / (durationIn + durationOut)
    );
    const isBreathOut = () => breathCycle % 2 === 1;

    while (
      breathCycle < breathInOutMaxCycles ||
      (breathInOutMaxCycles === breathCycle && isBreathOut())
    ) {
      const breatheTextI = (breathCycle % 2) as 0 | 1;

      this.animationCycleEnded = false;
      this.orbText = breatheText[breatheTextI];

      await animate(
        this.elements.orbTextElement,
        [{ opacity: 0 }, { opacity: 0.25 }],
        durationIn
      );
      await animate.to(
        this.elements.orbTextElement,
        { opacity: 0 },
        durationOut
      );

      if (this.supportsCssPathFn && isBreathOut()) {
        await waitForProperty(this, 'animationCycleEnded', true);
      }

      breathCycle = breathCycle + 1;
    }

    this.orbText = '';
    this.showBye = true;
    this.numberOfCompletedCycles += 1;
  });

  startBreathingExerciseModifier = modifier(
    (
      element: HTMLElement,
      [task]: [TaskForAsyncTaskFunction<unknown, () => Promise<void>>]
    ) => {
      if (!element.isConnected) {
        return;
      }

      this.elements.orbTextElement = element;
      task.perform();
    }
  );

  handleRestartBreathingExercise = task(async () => {
    this.showBye = false;
    this.showOrb = false;
    await animate.to(
      this.elements.restartBreathingElement,
      { opacity: 0 },
      500
    );

    await this.startBreathingExercise.perform();
  });

  willDestroy() {
    super.willDestroy();

    document.removeEventListener('mousemove', this.showControls);
  }

  onEndBreathingAnimation = task(async (byeTextElement: HTMLElement) => {
    await Promise.all([
      animate(
        this.elements.restartBreathingElement,
        [{ opacity: 0 }, { opacity: 0.5 }],
        3000
      ),
      animate(byeTextElement, [{ opacity: 0 }, { opacity: 0.4 }], 3000),
    ]);
  });
}
