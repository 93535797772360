/* import __COLOCATED_TEMPLATE__ from './select-location.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked promotion = this.args.promotion;

  get locations() {
    if (get(this, 'args.locations') && get(this, 'args.locations.length') > 0) {
      return get(this, 'args.locations');
    } else {
      return get(this, 'promotion.job.locations');
    }
  }

  get showLocationPicker() {
    if (get(this, 'args.isLocationPickerAlwaysVisible')) {
      return true;
    }

    return get(this, 'promotion.job.locations.length') > 1;
  }
}
