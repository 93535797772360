import { get } from 'teamtailor/utils/get';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import CompanyPageService from 'teamtailor/services/company-page';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import ContentEditorController from 'teamtailor/controllers/content/editor';
import { PageModel } from 'teamtailor/models';
import Transition from '@ember/routing/-private/transition';
import Current from 'teamtailor/services/current';
import IntercomService from 'teamtailor/services/intercom';

export default class EditorRoute extends Route {
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare companyPage: CompanyPageService;
  @service declare store: Store;
  @service declare current: Current;
  @service declare intercom: IntercomService;

  beforeModel() {
    this.companyPage.hideHeader = true;
  }

  async model({ page_id: pageId }: { page_id: string }) {
    const page = await this.store.findRecord('page', pageId);
    return page;
  }

  async setupController(
    controller: ContentEditorController,
    model: PageModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    this.companyPage.hideHeader = true;

    if (!controller.allBlockLayouts) {
      controller.allBlockLayouts = (
        await this.store.findAll('block-layout')
      ).slice();
    }

    if (model.isConnectPage) {
      this.intercom.hideWidget();
      const connectSettings = await this.store.query('connect-setting', {
        page_id: model.id,
      });
      if (connectSettings.length) {
        controller.connectSettings = connectSettings[0];
      } else {
        const page = model;
        const careerSite = await page.careerSite;
        controller.connectSettings = this.store.createRecord(
          'connect-setting',
          {
            page,
            company: this.current.company,
            welcomeMessage: careerSite?.connectWelcomeMessage,
            pickedQuestions: this.current.company.pickedQuestions,
          }
        );

        controller.connectSettings.save();
      }

      controller.generalConnectSettings = controller.connectSettings;
    }
  }

  resetController(controller: ContentEditorController, isExiting: boolean) {
    this.companyPage.hideHeader = false;
    controller.selectedDepartment = undefined;
    controller.selectedRole = undefined;
    controller.selectedConnectBlock = undefined;
    controller.cleanupConnectMediaItems();

    if (isExiting) {
      controller.overrideIframeSrc = undefined;
    }
  }

  @action
  error(error: { errors?: { status: string }[] }) {
    const { errors } = error;

    if (errors?.find((item) => get(item, 'status') === '401')) {
      this.ttAlert.error(this.intl.t('errors.dont_have_access_to_page'));
    }
  }
}
