/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class EditorAdjustmentsHeader extends Component {
  @service blockAdjustments;

  get isDisabled() {
    return this.blockAdjustments.isEditing;
  }
}
