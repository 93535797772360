/* import __COLOCATED_TEMPLATE__ from './actions-menu.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import isMac from 'teamtailor/utils/is-mac';
import config from 'teamtailor/config/environment';
import { get } from 'teamtailor/utils/get';
import NotificationsService from 'teamtailor/services/notifications';
import FlipperService from 'teamtailor/services/flipper';
import QuickNavigationService from 'teamtailor/services/quick-navigation';
import Current from 'teamtailor/services/current';
import TodoCountersService from 'teamtailor/services/todo-counters';

type Args = {
  toolboxParam: string;
};

export default class ActionsMenu extends Component<Args> {
  @service declare notifications: NotificationsService;
  @service declare quickNavigation: QuickNavigationService;
  @service declare todoCounters: TodoCountersService;
  @service declare flipper: FlipperService;
  @service declare current: Current;

  isMac = isMac;
  environment = config.environment;

  get openToolbox() {
    return (this.args.toolboxParam || '').split(',').firstObject;
  }

  get todosOpen() {
    return this.openToolbox === 'todos';
  }

  get messagesOpen() {
    return this.openToolbox === 'messages';
  }

  get globalCommentsOpen() {
    return this.openToolbox === 'global_comments';
  }

  get meetingsOpen() {
    return this.openToolbox === 'meetings';
  }

  get cronofyDisconnected() {
    const reconnectRequired = get(
      this.current,
      'user.cronofyAuthorization.cronofyReconnectedRequired'
    );
    return reconnectRequired;
  }

  get hasUnreadJobs() {
    return this.notifications.hasUnreadJobComments;
  }

  @action
  openQuickNav() {
    this.quickNavigation.open();
  }
}
