/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import Component from '@glimmer/component';
import CustomizeTableColumn from 'teamtailor/utils/candidate-table/column';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import fuzzysort from 'fuzzysort';
import { action } from '@ember/object';

interface CustomizeTableButtonArgs {
  disabled?: boolean;
  options: CustomizeTableColumn[];
}

export default class ButtonComponent extends Component<CustomizeTableButtonArgs> {
  @tracked inputValue = '';

  get disableButton() {
    return this.args.disabled ?? !this.args.options.length;
  }

  get options(): CustomizeTableColumn[] {
    let { options } = this.args;

    if (isPresent(this.inputValue)) {
      options = fuzzysort
        .go(this.inputValue, options, {
          key: 'translatedName',
          allowTypo: false,
          threshold: -10000,
        })
        .map((result) => result.obj);
    }

    return options;
  }

  @action
  onSearch(event: InputEvent) {
    const { value } = event.target as HTMLInputElement;

    this.inputValue = value;
  }

  @action
  resetSearch() {
    this.inputValue = '';
  }
}
