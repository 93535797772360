/* import __COLOCATED_TEMPLATE__ from './form-input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';

export default class FormInputComponent extends Component {
  get isDarkTheme() {
    return this.args.theme === 'dark';
  }

  get limitReached() {
    let maxlength;
    const { maxlengthLimit } = this.args;

    if (maxlengthLimit) {
      maxlength = maxlengthLimit;
    } else {
      maxlength = this.args.maxlength;
    }

    return this.args.value?.length >= maxlength;
  }

  get labelText() {
    let asterisk = this.args.required ? ' *' : '';
    return `${this.args.label}${asterisk}`;
  }

  get labelTextClasses() {
    return classnames('tt-form-label-text', this.args.labelClass, {
      'tt-dark-theme': this.isDarkTheme,
    });
  }

  get inputClasses() {
    return classnames('tt-form-control', this.args.inputClass, {
      'tt-form-dark': this.isDarkTheme,
      'tt-form-control--with-prefix': this.args.prefix,
    });
  }

  get helpTextClasses() {
    return classnames('tt-form-help-text', {
      'tt-dark-theme': this.isDarkTheme,
    });
  }

  get limitClasses() {
    return classnames('tt-form__characters-left-limit', {
      'tt-dark-theme': this.isDarkTheme,
    });
  }

  @action
  handleInsert(e) {
    if (this.args.autofocus) {
      e.querySelector('input').focus();
    }

    if (this.args.autoSelect) {
      e.querySelector('input').setSelectionRange(0, this.args.value.length);
    }
  }

  @action
  handleOnChange(event) {
    if (this.args.onChange) {
      this.args.onChange(event.target.value);
    }
  }
}
