/* import __COLOCATED_TEMPLATE__ from './payment-button.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import CompanyModel from 'teamtailor/models/company';
import UserModel from 'teamtailor/models/user';
import Current from 'teamtailor/services/current';
import { isEmpty } from '@ember/utils';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import PaymentError from 'teamtailor/utils/errors/payment-error';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';

const ALLOWED_FAILED_PAYMENT_CODES = [
  'card_declined',
  'expired_card',
  'payment_processor_error',
];

interface PaymentButtonArgs {
  action: () => Promise<void>;
  isSubscription: boolean;
  hideSuccessAlert: boolean;
  type: 'sms' | 'promotion';
  buttonLabel?: string;
  isVatTypeEmpty?: boolean;
  isDisabled?: boolean;
  noPayment: boolean;
}

interface PaymentErrorObject {
  errors: { code: string }[];
}

type iconOptions = 'credit-card' | 'receipt' | null;

export default class PaymentButtonComponent extends Component<PaymentButtonArgs> {
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  get company(): CompanyModel {
    return this.current.company;
  }

  get user(): UserModel {
    return this.current.user;
  }

  get paymentByCard(): boolean {
    if (this.args.type === 'sms') {
      return !get(this.company, 'paymentByInvoice');
    } else {
      return (
        get(this.company, 'validCreditCardPayment') ||
        get(this.flipper, 'purchase_promotion_by_credit_card')
      );
    }
  }

  get paymentProcessorSetting() {
    return get(this.company, 'paymentProcessorSetting');
  }

  get defaultPaymentMethod() {
    if (this.current.company.belongsTo('paymentProcessorSetting').id()) {
      const stripePaymentMethods = get(
        this.paymentProcessorSetting,
        'stripePaymentMethods'
      );

      return stripePaymentMethods?.at(0);
    }

    return null;
  }

  get buttonLabel() {
    if (this.args.buttonLabel) {
      return this.args.buttonLabel;
    }

    const mode = this.args.isSubscription ? 'subscription' : 'purchase';

    if (this.paymentByCard) {
      return this.intl.t(`components.payment_button.${mode}`);
    } else {
      return this.intl.t(`components.payment_button.${mode}_invoice`);
    }
  }

  get cardName() {
    return this.defaultPaymentMethod?.name;
  }

  get noActivePaymentMethod() {
    return this.paymentByCard && isEmpty(this.defaultPaymentMethod?.name);
  }

  get activePaymentMethod() {
    return !this.noActivePaymentMethod;
  }

  get icon(): iconOptions {
    if (this.args.buttonLabel) {
      return null;
    } else if (this.paymentByCard) {
      return 'credit-card';
    } else {
      return 'receipt';
    }
  }

  get isButtonDisabled(): boolean {
    if (this.args.noPayment) {
      return !!this.args.isDisabled;
    } else {
      return (
        this.noActivePaymentMethod ||
        (!!this.args.isVatTypeEmpty && this.paymentByCard) ||
        !!this.args.isDisabled
      );
    }
  }

  get showChargedByCardMessage() {
    return (
      this.activePaymentMethod && this.paymentByCard && this.requirePayment
    );
  }

  get requirePayment() {
    return !this.args.noPayment;
  }

  @action
  async processPayment() {
    try {
      const response = await this.args.action();

      if (!this.args.hideSuccessAlert) {
        this.flashMessages.success(
          this.intl.t('components.payment_button.messages.success')
        );
      }

      return response;
    } catch (e: unknown) {
      if (e instanceof PaymentError) {
        const paymentError = e as unknown as PaymentErrorObject;
        if (paymentError.errors.length) {
          const [error] = paymentError.errors;

          const errorCode = ALLOWED_FAILED_PAYMENT_CODES.includes(
            error?.code ?? ''
          )
            ? error?.code
            : 'generic_error';
          this.flashMessages.error(
            this.intl.t(`components.payment_button.messages.${errorCode}`)
          );
        }
      }

      throw e;
    }
  }
}
