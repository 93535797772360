/* import __COLOCATED_TEMPLATE__ from './time-picker.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ScheduledReport from 'teamtailor/models/scheduled-report';
import { get } from 'teamtailor/utils/get';
import moment, { Moment } from 'moment-timezone';

type Args = {
  scheduledReport: ScheduledReport;
};

export default class InsightsScheduleReportModalTimePicker extends Component<Args> {
  @service declare store: Store;

  get time() {
    const [hour, minute] = get(
      this.args.scheduledReport.settings,
      'time'
    ).split(':');
    return moment()
      .tz(this.tzid)
      .hour(parseInt(hour || '0'))
      .minute(parseInt(minute || '0'));
  }

  get tzid() {
    return get(this.args.scheduledReport.settings, 'tzid');
  }

  @action
  setTime(time: Moment) {
    set(this.args.scheduledReport.settings, 'time', time.format('HH:mm'));
  }

  @action
  selectTimeZone(tzid: string) {
    set(this.args.scheduledReport.settings, 'tzid', tzid);
  }
}
