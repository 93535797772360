/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type TabsButtonArgs = {
  icon?: string;
  iconStyle?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isGrowable?: boolean;
  onClick?: (event: MouseEvent) => void;
  text?: string;
};

export default class TabsButton extends Component<TabsButtonArgs> {
  @tracked isActive = false;
}
