/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class FormFieldReadSelect extends Component {
  get options() {
    const { optgroups, options } = this.args.field;

    if (!optgroups) return options;

    return optgroups.map((optgroup) => {
      return {
        groupName: optgroup.label,
        options: optgroup.options,
      };
    });
  }

  // Same but remove groups:
  get plainOptions() {
    const { optgroups, options } = this.args.field;

    if (!optgroups) return options;

    return optgroups.reduce((result, group) => {
      return [...result, ...group.options];
    }, []);
  }

  get selectedOptions() {
    if (this.args.value) {
      return this.plainOptions.filter((option) =>
        this.args.value.some((pick) => pick.id === option.id)
      );
    }
  }

  @action
  onChange(obj) {
    const { allow_multiple_answers } = this.args.field;

    if (allow_multiple_answers) {
      const value = (this.args.value || []).slice();
      toggleInList(value, obj);
      this.args.onChange(value);
    } else {
      this.args.onChange(obj ? obj.id : undefined);
    }
  }
}
