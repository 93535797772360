/* import __COLOCATED_TEMPLATE__ from './details-modal.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';

// Define interfaces for expected props and state
interface PartnerResult {
  partner: {
    name: string;
  };
  details: Record<string, any>;
}

interface PartnerDetailsModalComponentArgs {
  partnerResult: PartnerResult;
}

export default class PartnerDetailsModalComponent extends Component<PartnerDetailsModalComponentArgs> {
  get partnerName() {
    return get(this.args.partnerResult.partner, 'name');
  }

  get details(): Record<string, any> {
    const transformArrays = (
      acc: Record<string, any>,
      [key, value]: [string, any]
    ) => {
      if (Array.isArray(value)) {
        // Spread array into object with numeric keys
        return {
          ...acc,
          ...Object.fromEntries(value.map((v, i) => [`${key}_${i}`, v])),
        };
      }

      return { ...acc, [key]: value };
    };

    return Object.entries(this.args.partnerResult.details).reduce(
      transformArrays,
      {}
    );
  }
}
