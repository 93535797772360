/* import __COLOCATED_TEMPLATE__ from './sortable-image-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { PickedImageModel } from 'teamtailor/models';

interface SortableImageListArgs {
  onChange?: (draggedItem: PickedImageModel, targetIndex: number) => void;
  onRemove?: (pickedImage: PickedImageModel) => void;
}

export default class SortableImageList extends Component<SortableImageListArgs> {
  @action
  handleDragEnd({
    draggedItem,
    sourceIndex,
    targetIndex,
  }: {
    draggedItem: PickedImageModel;
    sourceIndex: number;
    targetIndex: number;
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    this.args.onChange?.(draggedItem, targetIndex);
  }

  @action
  handleRemoveImage(pickedImage: PickedImageModel) {
    this.args.onRemove?.(pickedImage);
  }
}
