/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import disableDefaultTemplate from 'teamtailor/utils/disable-default-template';
import { inject as service } from '@ember/service';
import TagModel from 'teamtailor/models/tag';
import { action } from '@ember/object';
import UserService from 'teamtailor/services/user';
import PermissionsService from 'teamtailor/services/permissions';
import Store from '@ember-data/store';
import CannedResponseModel from 'teamtailor/models/canned-response';
import IntlService from 'ember-intl/services/intl';
import Upload from 'teamtailor/models/upload';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { get } from 'teamtailor/utils/get';

const REQUIRED_PROPS = ['body', 'subject'];

export default class CannedResponseFormComponent extends TranslatableFormBaseComponent<CannedResponseModel> {
  @service declare user: UserService;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare permissions: PermissionsService;

  translationsProps = REQUIRED_PROPS;

  get userCanCreateTags() {
    return this.permissions.has('settings/update_recruitment', 'full');
  }

  get model() {
    return this.translatableModel as CannedResponseModel;
  }

  get disableReject(): boolean {
    return disableDefaultTemplate(this.model, 'reject');
  }

  get disableDefaultReply(): boolean {
    return disableDefaultTemplate(this.model, 'defaultReply');
  }

  get disableWelcomeToConnect(): boolean {
    return disableDefaultTemplate(this.model, 'welcomeToConnect');
  }

  get disableExternalRecruiterInvite(): boolean {
    return disableDefaultTemplate(this.model, 'externalRecruiterInvite');
  }

  get body() {
    const model = get(this, 'translatableData') as CannedResponseModel; // or TranslationCannedResponseModel
    const { body } = model;
    const doc = new DOMParser().parseFromString(body, 'text/html');

    // See #format_body method in the CannedResponse model
    // The div-wrapper around the content breaks Redactors ability to insert list elements
    doc.body.childNodes.forEach((child) => {
      if (child.nodeName.toLowerCase() === 'div') {
        child.replaceWith(...child.childNodes);
      }
    });

    return doc.body.innerHTML;
  }

  get uploads() {
    const { uploads } = this.model;

    if (!this.isTranslationFeatureEnabled || uploads.isDestroyed) {
      return uploads;
    }

    const languageCode = this.tabLanguage || this.defaultLanguageCode;

    return uploads.filter((upload) =>
      [languageCode, null].includes(upload.languageCode)
    );
  }

  get languageScopeOptions() {
    const currentCareerSiteTab = this.company.careerSites.findBy(
      'languageCode',
      this.currentLanguageTab
    );

    return [
      {
        label: this.intl.t(
          'settings.canned_responses.attached_to_all_languages'
        ),

        value: null,
      },
      {
        label: this.intl.t(
          'settings.canned_responses.attached_to_one_language_only',
          {
            language: currentCareerSiteTab?.translatedLanguage,
          }
        ),

        value: this.currentLanguageTab,
      },
    ];
  }

  @action
  handleChangeTags(tag: TagModel) {
    toggleInList(get(this.model, 'tags'), tag);
  }

  @action
  handleLanguageScopeSelect(upload: Upload, { value }: { value: string }) {
    upload.languageCode = value;
  }
}
