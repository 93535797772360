/* import __COLOCATED_TEMPLATE__ from './job-nps-comments.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import uniq from 'teamtailor/utils/uniq';

const QUERY = gql`
  query JobNpsCommentsQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    job: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      npsResponses: rows {
        jobId
        score: npsResponseScore
        candidateId
        stageId
        timestamp: keenTimestamp
        onReject: stageIsReject
        comment: npsResponseFeedback
      }
    }
  }
`;

const EXPORT_QUERY = gql`
  query JobNpsCommentsExportQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    job: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      npsResponses: rows {
        jobId
        score: npsResponseScore
        candidateId
        stageId
        timestamp: keenTimestamp
        responseAfterRejection: stageIsReject
        comment: npsResponseFeedback
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'candidateId',
    headerKey: 'insights.common.candidate_id',
  },
  {
    type: 'text',
    propertyName: 'candidateName',
    headerKey: 'insights.common.candidate',
  },
  {
    type: 'text',
    propertyName: 'stageId',
    headerKey: 'insights.common.stage_id',
  },
  { type: 'text', propertyName: 'date', headerKey: 'common.date' },
  {
    type: 'number',
    propertyName: 'score',
    headerKey: 'insights.common.nps_score',
  },
  {
    type: 'text',
    propertyName: 'comment',
    headerKey: 'insights.common.response',
  },
  {
    type: 'number',
    propertyName: 'responseAfterRejection',
    headerKey: 'insights.common.rejected',
  },
];

export default class extends Component {
  @service intl;
  @service insights;
  @service insightsExport;
  @service store;

  commentsOnly = false;

  get npsResponses() {
    return this.fetchData?.lastSuccessful?.value?.npsResponses;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.molecules.nps_comments.heading',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.loadReportData,
      this.args.dateRange,
      { createdAt: this.args.job.createdAt, size: 'large' }
    );
  }

  @action
  async loadReportData(dateRange) {
    let {
      job: { npsResponses },
    } = await this.insights.query({
      query: EXPORT_QUERY,
      variables: { dateRange: dateRange.asObject, jobIds: [this.args.job.id] },
      context: { jobId: this.args.job.id },
    });

    if (this.commentsOnly) {
      npsResponses = npsResponses.filter((npsResponse) => npsResponse.comment);
    }

    const candidates = await this.store.query('candidate', {
      ids: uniq(npsResponses.mapBy('candidateId').compact()),
    });

    const jobs = await this.store.query('job', {
      ids: uniq(npsResponses.mapBy('jobId').compact()),
    });

    return npsResponses.map((row) => {
      const candidate = candidates.findBy('id', String(row.candidateId));
      const job = jobs.findBy('id', String(row.jobId));

      return {
        candidateId: row.candidateId,
        jobId: row.jobId,
        stageId: row.stageId,
        date: moment(row.timestamp).format('YYYY-MM-DD'),
        score: row.score,
        comment: row.comment,
        responseAfterRejection: row.responseAfterRejection,
        jobTitle: job && job.title,
        candidateName: candidate && candidate.nameOrEmail,
      };
    });
  }

  @action
  onCommentFilterChange(value) {
    this.commentsOnly = value;
  }

  fetchData = dropTask(async () => {
    const {
      job: { npsResponses },
    } = await this.insights.query({
      query: QUERY,
      variables: {
        dateRange: this.args.dateRange.asObject,
        jobIds: [this.args.job.id],
      },

      context: { jobId: this.args.job.id },
    });

    const candidateIds = npsResponses.mapBy('candidateId');
    const candidates = await this.store.query('candidate', {
      ids: candidateIds,
    });

    const stageIds = npsResponses.mapBy('stageId').filter(Boolean);
    const stages =
      (await stageIds.length) > 0 &&
      this.store.query('stage', {
        ids: stageIds,
      });

    return {
      npsResponses: npsResponses.map((npsResponse) => {
        const candidate = candidates.findBy(
          'id',
          String(npsResponse.candidateId)
        );

        const stage = stages.findBy('id', String(npsResponse.stageId));

        return {
          ...npsResponse,
          timestamp: undefined,
          date: moment(npsResponse.timestamp).format('YYYY-MM-DD'),
          candidate,
          stage,
        };
      }),
    };
  });
}
