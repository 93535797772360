/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FormFieldRead extends Component {
  @service intl;

  get fieldLabel() {
    if (this.args.label) {
      return this.args.label;
    } else {
      const { label } = this.args.field;
      if (this.args.field.unmodifiable || this.args.field.labelUnmodifiable) {
        return this.intl.t(`form_builder.${label}`);
      } else {
        return label;
      }
    }
  }

  get hideLabel() {
    const { field, hideLabel } = this.args;

    return hideLabel || (field.type === 'checkbox' && !field.options?.length);
  }

  get shouldShow() {
    const { field, alwaysShow, refFieldValue } = this.args;
    const { show_if } = field;

    if (alwaysShow || !show_if) return true;

    if (Array.isArray(refFieldValue)) {
      const selectedUuids = refFieldValue.map((f) => f.id);
      return selectedUuids.includes(show_if.value);
    } else {
      return refFieldValue === show_if.value;
    }
  }

  @action
  onChange(value) {
    if (this.args.onChange) {
      this.args.onChange(value);
    }
  }
}
