/* import __COLOCATED_TEMPLATE__ from './reed.hbs'; */
import PromotionBaseClass from './promotion-base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { reedWorkingHours, reedJobTypes } from 'teamtailor/constants/promote';
import { tracked } from '@glimmer/tracking';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { get } from 'teamtailor/utils/get';

export default class extends PromotionBaseClass {
  @service intl;

  @tracked checkoutData;

  reedWorkingHours = reedWorkingHours;
  reedJobTypes = reedJobTypes;

  reedHiddenSalaryOptions = [
    {
      value: '0',
      label: this.intl.t('jobs.job.promote.channels.reed.negotiable'),
    },
    {
      value: '1',
      label: this.intl.t('jobs.job.promote.channels.reed.commision_only'),
    },
    {
      value: '2',
      label: this.intl.t('jobs.job.promote.channels.reed.competitive'),
    },
  ];

  get reedParentSectors() {
    return this.store.peekAll('reed/parent-sector');
  }

  get reedWorkingHour() {
    return reedWorkingHours.findBy(
      'code',
      get(this, 'promotion.reedWorkingHour')
    );
  }

  get reedJobType() {
    return reedJobTypes.findBy('code', get(this, 'promotion.reedJobType'));
  }

  get reedSkills() {
    return get(this, 'promotion.reedSkills').map((skill) => {
      return { skill };
    });
  }

  @action
  addSkill(skill) {
    toggleInList(get(this, 'promotion.reedSkills'), skill.skill || skill);
  }
}
