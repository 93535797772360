/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
import Component from '@glimmer/component';

interface CompareCandidatesCellArgs {
  index: number;
  pinnedCount: number;
  isHovered: boolean;
  jobApplicationId: string;
  offset: string;
  isContentTruncated?: boolean;
  showFullContent?: boolean;
  toggleContent?: () => void;
}

export default class JobsCompareRow extends Component<CompareCandidatesCellArgs> {
  get isPinned(): boolean {
    return this.args.index < this.args.pinnedCount;
  }

  get isLastPinned(): boolean {
    return this.args.index + 1 === this.args.pinnedCount;
  }
}
