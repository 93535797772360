/* import __COLOCATED_TEMPLATE__ from './jobs-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import { timeout, restartableTask } from 'ember-concurrency';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const JOBS_SUMMARY_QUERY = gql`
  query FetchJobsListQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    events: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [REJECTED, APPLIED, HIRED]
    ) {
      aggregated(groupBy: [JOB_ID]) {
        jobId
        applications: countOccurrences(
          filters: { eventType: { equals: APPLIED } }
        )
        rejects: countOccurrences(filters: { eventType: { equals: REJECTED } })
        hires: countOccurrences(filters: { eventType: { equals: HIRED } })
      }
    }

    pageviews: pageviewQuery(dateRange: $dateRange, jobIds: $jobIds) {
      aggregated(groupBy: JOB_ID) {
        jobId
        count: distinctCount(
          field: SESSION_ID
          filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
        )
      }
    }
  }
`;

export default class InsightsJobsList extends Component {
  @service store;
  @service insights;
  @service analytics;
  @service current;
  @service apollo;

  per_page = 20;

  @tracked
  lastJobIds;

  @tracked
  lastQuery = '';

  get isLoading() {
    return this.fetchData.isRunning || this.args.isLoading;
  }

  get jobs() {
    return this.fetchData.lastSuccessful?.value?.jobs;
  }

  get hasSearchQuery() {
    return this.args.query?.length > 0;
  }

  get meta() {
    return this.fetchData.lastSuccessful?.value?.meta;
  }

  get needsToLoadJobs() {
    if (!this.fetchJobs.lastSuccessful?.value) {
      return true;
    }

    const selectedJobIds = this.args.jobIds;

    if (
      this.lastJobIds?.length !== selectedJobIds?.length ||
      !this.lastJobIds?.every((job) => selectedJobIds?.includes(job.id)) ||
      this.args.query !== this.lastQuery
    ) {
      return true;
    }

    return false;
  }

  mergeData(jobIds, { pageviews = [], events = [] }) {
    const data = jobIds.map((id) => ({
      id,
      applications: 0,
      visits: 0,
      rejects: 0,
      hires: 0,
    }));

    events.forEach((event) => {
      const job = data.find((job) => job.id === event.jobId);
      if (job) {
        job.applications = event.applications;
        job.rejects = event.rejects;
        job.hires = event.hires;
      }
    });

    pageviews.forEach((pageview) => {
      const job = data.find((job) => job.id === pageview.jobId);
      if (job) {
        job.visits = pageview.count;
      }
    });

    return data;
  }

  fetchData = restartableTask(async () => {
    await timeout(500);
    if (this.args.jobIds?.length === 0) {
      return {
        meta: {},
        jobs: [],
      };
    }

    let jobs;

    if (this.needsToLoadJobs) {
      jobs = await this.fetchJobs.perform();
    } else {
      jobs = this.fetchJobs.lastSuccessful?.value;
    }

    this.lastQuery = this.args.query;
    this.lastJobIds = this.args.jobIds;

    const jobIds = jobs
      .mapBy('id')
      .slice(
        (this.args.page - 1) * this.per_page,
        this.args.page * this.per_page
      );

    const {
      events: { aggregated: events },
      pageviews: { aggregated: pageviews },
    } = await this.insights.query({
      query: JOBS_SUMMARY_QUERY,
      variables: {
        jobIds,
        dateRange: this.args.dateRange.asObject,
      },
    });

    const jobSummaries = this.mergeData(jobIds, {
      events,
      pageviews,
    });

    const jobsMeta = {
      page: this.args.page,
      total_pages: Math.ceil(jobs.length / this.per_page),
      total_count: jobs.length,
    };

    if (this.args.page > jobsMeta.total_pages) {
      set(this.analytics, 'page', jobsMeta.total_pages);
    }

    return {
      meta: jobsMeta,
      jobs: jobSummaries.map((row) => {
        const job = jobs.findBy('id', row.id);

        return {
          ...row,
          job,
          title: job.title,
          status: job.status,
          humanStatus: job.humanStatus,
        };
      }),
    };
  });

  fetchJobs = restartableTask(async () => {
    const { jobs } = await this.apollo.query({
      query: gql`
        query JobsListJobsQuery(
          $userId: ID!
          $filter: JobsFilterInput
          $allPublished: Boolean
        ) {
          jobs(
            filter: $filter
            userScope: { userId: $userId }
            allPublished: $allPublished
          ) {
            id
            humanStatus
            title
          }
        }
      `,
      variables: {
        userId: this.current.user.id,
        allPublished: this.current.user.hasRole('analytics'),
        filter: {
          ids: this.args.jobIds,
          query: this.args.query,
          status: ['open', 'archived', 'unlisted'],
        },
      },
    });

    return jobs;
  });

  paginate(jobIds, page_number, page_size) {
    return jobIds?.slice(
      (page_number - 1) * page_size,
      page_number * page_size
    );
  }
}
