/* import __COLOCATED_TEMPLATE__ from './info-popover.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

const POPPER_OPTIONS = {
  modifiers: {
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
};

export default class InfoPopoverComponent extends Component {
  popperOptions = POPPER_OPTIONS;
  canCloseOnClick = false;

  @tracked isOpen = false;

  get popperContainer() {
    return 'body';
  }

  @action
  handleOnShow() {
    next(() => {
      this.canCloseOnClick = true;
    });
  }

  @action
  handleClose() {
    this.isOpen = false;
  }

  @action
  handleOnHide() {
    this.canCloseOnClick = false;
  }

  @action
  handleToggle() {
    if (this.canCloseOnClick) {
      this.handleClose();
    }
  }
}
