/* import __COLOCATED_TEMPLATE__ from './segment.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { timeout, task, keepLatestTask, dropTask } from 'ember-concurrency';
import { DECORATIVE_COLORS } from 'teamtailor/constants/core/color-dot';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import FlashMessages from 'teamtailor/services/flash-message';
import Router from '@ember/routing/router-service';
import DashboardWidgetModel from 'teamtailor/models/dashboard-widget';
import SegmentModel from 'teamtailor/models/segment';

type DashboardWidgetFormArgs = {
  widget: DashboardWidgetModel;
  route: string;
  onSave?: () => void;
  onClose?: () => void;
};

type WidgetColor = {
  name: string;
  displayName: string;
};

export default class DashboardWidgetsFormSegmentComponent extends Component<DashboardWidgetFormArgs> {
  @service declare flashMessages: FlashMessages;
  @service declare router: Router;
  @service declare store: Store;
  @service declare current: Current;

  @tracked modalOpen = true;

  get widgetColors(): WidgetColor[] {
    return DECORATIVE_COLORS.map((color) => ({
      name: color,
      displayName: capitalize(color),
    }));
  }

  get widgetColor(): string {
    return this.args.widget.color;
  }

  get selectedWidgetColor(): WidgetColor | undefined {
    return this.widgetColors.findBy('name', this.widgetColor);
  }

  get allFieldsNotPresent(): boolean {
    // add this.selectedStyle when supported
    return !(this.args.widget.belongsTo('segment').id() && this.widgetColor);
  }

  get segments() {
    return this.store.findAll('segment');
  }

  delayedCloseTask = task(async () => {
    await timeout(800);
    this.handleModalClose();
  });

  saveTask = keepLatestTask(async () => {
    await this.args.widget.save();

    if (this.args.onSave) {
      await this.args.onSave();
    }

    await this.delayedCloseTask.perform();
    return true;
  });

  destroyTask = dropTask(async () => {
    const dashboardWidgetId = parseInt(this.args.widget.id, 10);
    await this.args.widget.destroyRecord();

    this.store
      .peekAll('picked-dashboard-widget')
      .forEach((pickedDashboardWidget) => {
        if (pickedDashboardWidget.dashboardWidgetId === dashboardWidgetId) {
          pickedDashboardWidget.unloadRecord();
        }
      });

    return true;
  });

  @action
  handleModalClose() {
    if (this.args.onClose) {
      this.args.onClose();
    }

    if (this.args.route) {
      this.router.transitionTo(this.args.route);
    }
  }

  @action
  handleSelectedSegment(segment: SegmentModel) {
    set(this.args.widget, 'segment', segment);
    this.args.widget.title = segment.name;
    this.args.widget.emoji = segment.emoji;
  }

  @action
  handleSelectedColor(colorObj: WidgetColor) {
    this.args.widget.color = colorObj.name;
  }
}
