/* import __COLOCATED_TEMPLATE__ from './show-context.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment, { Moment } from 'moment-timezone';
import RouterService from '@ember/routing/router-service';
import IntlService from 'ember-intl/services/intl';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import RouteHelper from 'teamtailor/services/route-helper';
import FlashMessageService from 'teamtailor/services/flash-message';
import MeetingModel from 'teamtailor/models/meeting';
import MeetingEventModel from 'teamtailor/models/meeting-event';
import placeholders from 'teamtailor/utils/placeholders';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import MeetingEventAttendeeModel from 'teamtailor/models/meeting-event-attendee';
import TimeFormatService from 'teamtailor/services/time-format';

interface Args {
  data: MeetingEventModel;
  close: () => void;
  openEdit: (() => void) | undefined;
  loading: boolean;
  onCancelEvent?: () => void;
}

export default class ShowContext extends Component<Args> {
  @service declare timeFormat: TimeFormatService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare apollo: TeamtailorApolloService;
  @service declare routeHelper: RouteHelper;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @tracked showConfirmDialog = false;

  @tracked _loading = false;

  get loading() {
    return this.args.loading || this._loading;
  }

  get meetingEvent(): MeetingEventModel {
    return this.args.data;
  }

  get meeting() {
    return get(this.meetingEvent, 'meeting');
  }

  get job() {
    return get(this.meetingEvent, 'job');
  }

  get readOnly() {
    return (this.startsAt && moment().isAfter(this.startsAt)) ?? false;
  }

  get meetingId() {
    return [get(this.meeting, 'id')];
  }

  get bookedMeetingRoom() {
    return this.meetingEvent.bookedMeetingRoom;
  }

  get questions() {
    return get(get(this.meeting, 'preparedMessage'), 'questions');
  }

  get uploads() {
    return get(get(this.meeting, 'preparedMessage'), 'uploads');
  }

  get interviewKit() {
    return this.meetingEvent.interviewKit;
  }

  get startsAt(): Moment | undefined {
    const { bookedSlotStartsAt, tzid } = this.meetingEvent;
    return moment(bookedSlotStartsAt).tz(tzid).locale(this.timeFormat.locale);
  }

  get endsAt(): Moment | undefined {
    const { bookedSlotEndsAt, tzid } = this.meetingEvent;
    return moment(bookedSlotEndsAt).tz(tzid).locale(this.timeFormat.locale);
  }

  get startsAtTime(): string | undefined {
    return this.startsAt?.format(this.timeFormat.format);
  }

  get endsAtTime(): string | undefined {
    return this.endsAt?.format(this.timeFormat.format);
  }

  get whenStartDate(): string | undefined {
    return this.startsAt?.format('ddd, MMM D');
  }

  get whenEndDate(): string | undefined {
    return this.endsAt?.format('ddd, MMM D');
  }

  get meetingDuration(): string {
    const { bookedSlotEndsAt, bookedSlotStartsAt } = this.meetingEvent;
    if (bookedSlotEndsAt) {
      const start = moment(bookedSlotStartsAt);
      const end = moment(bookedSlotEndsAt);
      const h = end.diff(start, 'hours');
      const m = Math.round((end.diff(start, 'hours', true) - h) * 60);
      return `${h}h${m ? ` ${m}mins` : ''}`;
    }

    return '';
  }

  get meetingEventLocation() {
    return this.meetingEvent.meetingEventLocation;
  }

  get location() {
    return get(this.meetingEventLocation, 'location');
  }

  get hasValidMapLocation(): boolean {
    return !!(!!get(this.location, 'lat') && !!get(this.location, 'long'));
  }

  get userAttendee(): MeetingEventAttendeeModel | undefined {
    const attendee = this.meetingEvent.userAttendees?.find(
      (u) => u.user && get(u.user, 'id') === this.current.user.id
    );

    return attendee;
  }

  get shouldLeaveFeedback(): boolean | undefined {
    const attendee = this.meetingEvent.userAttendees?.find(
      (a) => get(a, 'id') === this.userAttendee?.id
    );
    return attendee?.hasInterview;
  }

  get meetingEventId(): string {
    return this.meetingEvent.id || '';
  }

  get placeholderParser() {
    return this.firstCandidate
      ? placeholders(this.firstCandidate, this.job, this.current.company)
      : undefined;
  }

  get candidateAnonymousOnStage(): boolean {
    const jobId = get(this.job, 'id');
    if (this.firstCandidate && jobId) {
      const jobApplication = get(this.firstCandidate, 'jobApplications').filter(
        (ja) => ja.jobId === parseInt(jobId, 10)
      ).firstObject;

      return !!(jobApplication && get(jobApplication.stage, 'anonymous'));
    }

    return false;
  }

  get modalTitle(): string {
    return this.meetingEvent.summary
      ? this.placeholderParser?.parse(
          this.meetingEvent.summary,
          this.candidateAnonymousOnStage
        )
      : '';
  }

  get subject(): string {
    const subject = get(get(this.meeting, 'preparedMessage'), 'subject');
    return subject
      ? this.placeholderParser?.parse(subject, this.candidateAnonymousOnStage)
      : '';
  }

  get body(): string {
    const body = get(get(this.meeting, 'preparedMessage'), 'body');
    return body
      ? this.placeholderParser?.parse(body, this.candidateAnonymousOnStage)
      : '';
  }

  get currentUserIsOrganizer(): boolean {
    return this.meetingEvent.organizer?.id === this.userAttendee?.id;
  }

  get currentUserIsAttendee(): boolean {
    return !!this.meetingEvent.userAttendees?.some(
      (u) => u.id === this.userAttendee?.id
    );
  }

  get currentUserStatus(): string | undefined {
    return this.meetingEvent.userAttendees?.find(
      (u) => u.id === this.userAttendee?.id
    )?.status;
  }

  get editRoute() {
    return this.routeHelper.getNestedRoute('meetings.edit');
  }

  get showRoute() {
    return this.routeHelper.getNestedRoute('meetings.events/show');
  }

  get firstCandidate() {
    const candidates = get(this.meetingEvent, 'candidateAttendees');
    return candidates?.at(0)?.candidate;
  }

  @action
  openEdit() {
    if (this.args.openEdit) {
      return this.args.openEdit();
    }

    if (get(this.meeting, 'id')) {
      return this.router.transitionTo(this.editRoute, get(this.meeting, 'id'));
    }

    return false;
  }

  @action
  async leaveFeedback() {
    const meetingJob = await get(this.meetingEvent, 'job');
    const meetingInterviewKit = get(this.meetingEvent, 'interviewKit');
    const candidateId = this.meetingEvent.candidateIds?.at(0);

    if (meetingJob && candidateId) {
      this.router.transitionTo(
        this.routeHelper.parentRoute || 'candidates.candidate',
        candidateId,
        {
          queryParams: {
            tab: 'interview-feed',
            interviewFeedQuery: JSON.stringify({
              jobId: get(meetingJob, 'id'),
              interviewKitId: get(meetingInterviewKit, 'id'),
            }),
          },
        }
      );
    }
  }

  @action
  switchEvent(meetingEvent: MeetingModel): void {
    this.router.transitionTo(
      this.showRoute,
      get(this.meeting, 'id'),
      meetingEvent.id
    );
  }

  @action
  showCancelMeetingDialogue(): void {
    this.showConfirmDialog = true;
  }

  @action
  cancelConfirmDialog(): void {
    if (this.showConfirmDialog) {
      this.showConfirmDialog = false;
    }
  }

  @action
  close(): void {
    this.args.close();
  }

  @action
  async confirm(): Promise<void> {
    this._loading = true;
    return this.meetingEvent
      .destroyRecord()
      .then(() => {
        this.showConfirmDialog = false;
        this.close();
        if (this.args.onCancelEvent) {
          this.args.onCancelEvent();
        }

        this.flashMessages.success(
          this.intl.t('components.meeting_cancelled_successfully')
        );
      })
      .finally(() => {
        this._loading = false;
      });
  }
}
