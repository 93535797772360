/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ActivityModel from 'teamtailor/models/activity';

interface ActionsArgs {
  disableExpanded: boolean;
  actions: ActivityModel[];
  candidateRoute: string;
  isExpanded: boolean;
  toggleExpanded(): void;
  anonymous: boolean;
}

export default class CandidatePreviewPopoverActionsComponent extends Component<ActionsArgs> {
  @action
  toggleIsExpanded(): void {
    if (!this.args.disableExpanded) {
      this.args.toggleExpanded();
    }
  }
}
