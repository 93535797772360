import TriggerSerializer from 'teamtailor/serializers/trigger';
import classic from 'ember-classic-decorator';

@classic
export default class TriggerMessageSerializer extends TriggerSerializer {
  attrs = {
    stage: { serialize: 'ids' },
    uploads: {
      key: 'uploads_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },

    pickedQuestions: {
      key: 'picked_questions_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },
  };

  serialize(snapshot) {
    const json = super.serialize(snapshot);
    const [commonJson, extras] = this.extractCommonJson(json);
    const { picked_questions_attributes, uploads_attributes, ...data } = extras;

    return Object.assign(commonJson, {
      id: commonJson?.id ?? snapshot.record?.id,
      data,
      picked_questions_attributes,
      uploads_attributes,
    });
  }
}
