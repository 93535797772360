/* import __COLOCATED_TEMPLATE__ from './score-bar.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import {
  CandidateModel,
  InterviewKitModel,
  JobModel,
  JobApplicationModel,
} from 'teamtailor/models';
import { SafeString } from '@ember/template/-private/handlebars';
import { isEmpty } from '@ember/utils';
import { trackedFunction } from 'ember-resources/util/function';
import { AsyncBelongsTo } from '@ember-data/model';

interface ScorebarArgs {
  model: JobApplicationModel | CandidateModel;
  scope?:
    | JobModel
    | InterviewKitModel
    | AsyncBelongsTo<JobModel>
    | AsyncBelongsTo<InterviewKitModel>;
  jobId?: string;
}

export default class ScorebarComponent extends Component<ScorebarArgs> {
  modelIsJobApplication(
    model: JobApplicationModel | CandidateModel
  ): model is JobApplicationModel {
    return model.constructor.modelName === JobApplicationModel.modelName;
  }

  scopeIsJob(scope?: JobModel | InterviewKitModel | null): scope is JobModel {
    return scope?.constructor.modelName === JobModel.modelName;
  }

  scopeIsInterviewKit(
    scope?: JobModel | InterviewKitModel | null
  ): scope is InterviewKitModel {
    return scope?.constructor.modelName === InterviewKitModel.modelName;
  }

  getScorecardPickIdsFromInterviews() {
    const candidate = <CandidateModel>this.args.model;
    const scopeId = get(this.args.scope, 'id');

    const interviews = candidate.interviews.filter((interview) => {
      const interviewKitId = get(interview.interviewKit, 'id');
      const interviewJobId = get(interview, 'jobId');

      return (
        interviewKitId === scopeId &&
        (!interviewJobId || interviewJobId === this.args.jobId)
      );
    });

    const scoredScorePickIds = interviews.map((interview) =>
      interview.scores
        .filter((score) => {
          return score.score !== null;
        })
        .map((score) => get(score.scorecardPick, 'id'))
    );

    return scoredScorePickIds.flat();
  }

  resolvedScope = trackedFunction(this, async () => {
    const scope = await get(this.args, 'scope');
    return scope;
  });

  get scope() {
    return this.resolvedScope.value;
  }

  get shouldHide(): boolean {
    return !this.totalScoresLength;
  }

  get candidateScorecardScores() {
    return this.modelIsJobApplication(this.args.model) ||
      isEmpty(get(this.args.model, 'scorecardScores'))
      ? []
      : get(this.args.model, 'scorecardScores').filter(
          (scorecardScore) => !!get(scorecardScore, 'id')
        );
  }

  get scopeScorecardPickIds() {
    if (this.scopeIsJob(this.scope)) {
      return (
        this.scopeScorecardPicks?.map((scorecardPick) => scorecardPick.id) || []
      );
    } else if (this.scopeIsInterviewKit(this.scope)) {
      return this.getScorecardPickIdsFromInterviews();
    }

    return [];
  }

  get scopeScorecardPicks() {
    if (!this.args.scope) {
      return [];
    }

    if (this.scopeIsJob(this.scope)) {
      const scorecardPicksContainer = get(this.scope, 'jobDetail');
      return get(scorecardPicksContainer, 'scorecardPicks');
    }
  }

  get scorecardScores() {
    if (
      this.scopeScorecardPickIds.length ||
      this.scopeIsInterviewKit(this.scope)
    ) {
      return get(this, 'candidateScorecardScores').filter((scorecardScore) => {
        const scorecardPick = get(scorecardScore, 'scorecardPick');
        return this.scopeScorecardPickIds.includes(get(scorecardPick, 'id'));
      });
    } else {
      return get(this, 'candidateScorecardScores');
    }
  }

  get scores(): number[] {
    return this.scorecardScores.mapBy('score');
  }

  get disasterScores(): number {
    return this.scores.filter((score) => score === -2).length;
  }

  get negativeScores(): number {
    return this.scores.filter((score) => score === -1).length;
  }

  get neutralScores(): number {
    return this.scores.filter((score) => score === 0).length;
  }

  get positiveScores(): number {
    return this.scores.filter((score) => score === 1).length;
  }

  get superScores(): number {
    return this.scores.filter((score) => score === 2).length;
  }

  get disasterScoresLength(): number {
    return this.modelIsJobApplication(this.args.model)
      ? get(this.args.model, 'scoresDisasterCount')
      : this.disasterScores;
  }

  get negativeScoresLength(): number {
    return this.modelIsJobApplication(this.args.model)
      ? get(this.args.model, 'scoresNegativeCount')
      : this.negativeScores;
  }

  get neutralScoresLength(): number {
    return this.modelIsJobApplication(this.args.model)
      ? get(this.args.model, 'scoresBlankCount')
      : this.neutralScores;
  }

  get positiveScoresLength(): number {
    return this.modelIsJobApplication(this.args.model)
      ? get(this.args.model, 'scoresPositiveCount')
      : this.positiveScores;
  }

  get superScoresLength(): number {
    return this.modelIsJobApplication(this.args.model)
      ? get(this.args.model, 'scoresSuperCount')
      : this.superScores;
  }

  get totalScoresLength(): number {
    return (
      this.disasterScoresLength +
      this.negativeScoresLength +
      this.positiveScoresLength +
      this.superScoresLength +
      this.neutralScoresLength
    );
  }

  get disasterStyles(): SafeString {
    return htmlSafe(
      `width: ${(this.disasterScoresLength / this.totalScoresLength) * 100}%;`
    );
  }

  get negativeStyles(): SafeString {
    return htmlSafe(
      `width: ${(this.negativeScoresLength / this.totalScoresLength) * 100}%;`
    );
  }

  get neutralStyles(): SafeString {
    return htmlSafe(
      `width: ${(this.neutralScoresLength / this.totalScoresLength) * 100}%;`
    );
  }

  get positiveStyles(): SafeString {
    return htmlSafe(
      `width: ${(this.positiveScoresLength / this.totalScoresLength) * 100}%;`
    );
  }

  get superStyles(): SafeString {
    return htmlSafe(
      `width: ${(this.superScoresLength / this.totalScoresLength) * 100}%;`
    );
  }
}
