/* import __COLOCATED_TEMPLATE__ from './editable-flow-step.hbs'; */
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { RequisitionFlowStepModel, UserModel } from 'teamtailor/models';
import { ApprovalPolicy } from 'teamtailor/models/requisition-flow-step';
import { argDefault } from 'teamtailor/utils/arg-default';
import toggleInList from 'teamtailor/utils/toggle-in-list';

interface EditableFlowStepArgs {
  step: RequisitionFlowStepModel;
  firstStep: boolean;
}

export default class EditableFlowStep extends Component<EditableFlowStepArgs> {
  @service declare intl: IntlService;

  @argDefault firstStep = false;

  get approvalPolicies() {
    return [
      {
        value: 'any_user',
        name: this.intl.t('requisitions.editable_flow.any'),
      },
      {
        value: 'all_users',
        name: this.intl.t('requisitions.editable_flow.all'),
      },
    ];
  }

  @action
  onChangeApprovers(user: UserModel) {
    const users = get(this.args.step, 'pickedUsers');
    toggleInList(users, user);
  }

  @action
  onChangePolicy(policy: ApprovalPolicy) {
    set(this.args.step, 'approvalPolicy', policy.value);
  }
}
