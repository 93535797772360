/* import __COLOCATED_TEMPLATE__ from './speaker.hbs'; */
import { action } from '@ember/object';
import VideoMeetingViewsBase from 'teamtailor/components/video-meeting/views/base';
import { RemoteParticipant, RemoteVideoTrack } from 'twilio-video';
import { inject as service } from '@ember/service';
import ResizeObserverService from 'ember-resize-observer-service';
import {
  MeetingAttendee,
  VideoDimensions,
} from 'teamtailor/utils/video-meetings/utils';

export default class VideoMeetingViewsSpeaker extends VideoMeetingViewsBase {
  declare resizableElement?: HTMLElement;
  declare wrapperElement?: HTMLElement;
  declare track?: RemoteVideoTrack | null;
  declare intrinsicDimensions: VideoDimensions | null;
  @service declare resizeObserver: ResizeObserverService;

  get participants(): RemoteParticipant[] {
    if (this.speakerParticipant && !this.args.sharing) {
      if (this.args.isNewProvider) {
        return this.args.remoteParticipants.rejectBy(
          'attendeeId',
          (<MeetingAttendee>this.speakerParticipant).attendeeId
        );
      } else {
        return this.args.remoteParticipants.rejectBy(
          'identity',
          (<RemoteParticipant>this.speakerParticipant).identity
        );
      }
    } else {
      return this.args.remoteParticipants;
    }
  }

  resize(): void {
    const aspect =
      this.args.remoteScreenTrack || this.args.remoteParticipantScreenShare
        ? 'screen'
        : 'video';
    if (
      !this.wrapperElement ||
      !this.resizableElement ||
      this.isScreenShareOrParticipantTrackEmpty
    ) {
      return;
    }

    const { clientWidth, clientHeight } = this.wrapperElement;

    let dimensions: { width: number | null; height: number | null } = {
      height: null,
      width: null,
    };

    if (this.args.isNewProvider) {
      if (this.intrinsicDimensions) {
        dimensions = this.intrinsicDimensions;
      }
    } else {
      if (this.track) {
        dimensions = this.track.dimensions;
      }
    }

    if (!dimensions.width || !dimensions.height) {
      return;
    }

    const maxWidth = clientWidth;
    const maxHeight = clientHeight - 20;

    if (maxWidth < 1024) {
      this.resizableElement.style.width = '';
      this.resizableElement.style.height = '';
      return;
    }

    const aspectWidth = aspect === 'video' ? 16 : dimensions.width;
    const aspectHeight = aspect === 'video' ? 9 : dimensions.height;

    let height = (maxWidth / aspectWidth) * aspectHeight;
    let width = maxWidth;

    if (height > maxHeight) {
      height = maxHeight;
      width = (maxHeight / aspectHeight) * aspectWidth;
    }

    this.resizableElement.style.width = `${Math.floor(width)}px`;
    this.resizableElement.style.height = `${Math.floor(height)}px`;
  }

  get isScreenShareOrParticipantTrackEmpty() {
    return this.args.isNewProvider
      ? !this.args.remoteParticipantScreenShare && !this.args.remoteParticipants
      : !this.track;
  }

  @action
  onInsert(element: HTMLElement): void {
    this.wrapperElement = element;

    this.resizeObserver.observe(this.wrapperElement, () => this.resize());
  }

  @action
  registerResizableElement(element: HTMLElement): void {
    this.resizableElement = element;
  }

  @action
  handleDimensionsChange(
    track: RemoteVideoTrack | null,
    dimensions: VideoDimensions | null = null
  ): void {
    if (this.args.isNewProvider) {
      this.intrinsicDimensions = dimensions;
    } else {
      this.track = track;
    }

    this.resize();
  }
}
