/* import __COLOCATED_TEMPLATE__ from './copilot-summary.hbs'; */
import Component from '@glimmer/component';
import { MeetingEventModel } from 'teamtailor/models';
import { action } from '@ember/object';

interface MeetingCopilotSummaryArgs {
  meetingEvent: MeetingEventModel;
  onClose: () => void;
}

export default class MeetingCopilotSummary extends Component<MeetingCopilotSummaryArgs> {
  @action
  async handleCopilotSummarySave() {
    await this.args.meetingEvent.updateCopilotSummary();

    this.args.onClose();
  }

  @action
  async cancelEditCopilotSummary() {
    this.args.meetingEvent.rollbackAttributes();

    this.args.onClose();
  }
}
