import { action } from '@ember/object';
import BowserService from 'teamtailor/services/bowser';
import Current from 'teamtailor/services/current';
import { later } from '@ember/runloop';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import Service, { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

type OnlineUsers = {
  [id: string]: true;
};

export default class UserOnlineStatus extends Service {
  @service declare bowser: BowserService;
  @service declare current: Current;
  @service declare pusher: PusherService;
  @service declare store: Store;

  @tracked declare impersonating: boolean;
  @tracked declare onlineUserIds: OnlineUsers | undefined;
  @tracked declare pusherChannel: PusherChannel | null;

  get pusherChannelName() {
    return this.current.company.mainChannel;
  }

  get user() {
    return this.current.user;
  }

  isUserOnline(userId: string): boolean {
    return !!this.onlineUserIds?.[userId];
  }

  toggleUserStatus(id: string | number, isOnline: boolean) {
    const userId = id.toString();

    if (isOnline && !this.isUserOnline(userId)) {
      // set a new object to make components react to the change
      this.onlineUserIds = Object.assign(
        { [userId]: true },
        this.onlineUserIds
      );
    } else if (!isOnline && this.onlineUserIds) {
      delete this.onlineUserIds[userId];
      // set a new object to make components react to the change
      this.onlineUserIds = Object.assign({}, this.onlineUserIds);
    }
  }

  triggerVisibilityChanged(isVisible: boolean) {
    if (this.pusherChannel?.subscribed) {
      this.pusherChannel.trigger('client-visibility-changed', {
        id: this.user.id,
        isVisible,
      });
    }
  }

  @action
  handleVisibilityChange() {
    const isVisible = document.visibilityState === 'visible';

    // while switching between teamtailor tabs,
    // Firefox fires visibilitychange events of two tabs in opposite order
    // so wait before triggering the isVisible message to make it in correct order
    const shouldWait = isVisible && this.bowser.isFirefox;

    if (shouldWait) {
      later(() => {
        this.triggerVisibilityChanged(isVisible);
      }, 3000);
    } else {
      this.triggerVisibilityChanged(isVisible);
    }
  }

  @action
  handleHideOnlineStatusChange() {
    if (this.pusherChannel?.subscribed) {
      this.pusherChannel.trigger('client-hide-online-status-changed', {
        id: this.user.id,
        hideOnlineStatus: this.user.hideOnlineStatus,
      });
    }

    this.toggleUserStatus(this.user.id, !this.user.hideOnlineStatus);
  }

  setupSubscription() {
    this.pusherChannel?.bind('pusher:subscription_succeeded', (data) => {
      this.handleVisibilityChange();

      if (data?.members) {
        const ids: OnlineUsers = {};
        Object.keys(data.members).forEach((key) => {
          ids[key] = true;
        });
        this.onlineUserIds = ids;
      }
    });
  }

  setupSubscriptionChange() {
    this.pusherChannel?.bind('pusher:member_added', (member) => {
      this.toggleUserStatus(member.id, true);
    });

    this.pusherChannel?.bind('pusher:member_removed', (member) => {
      this.toggleUserStatus(member.id, false);
    });
  }

  setupHideOnlineStatusChange() {
    this.pusherChannel?.bind(
      'client-hide-online-status-changed',
      ({ id, hideOnlineStatus }) => {
        const user = this.store.peekRecord('user', id);
        if (user) {
          user.hideOnlineStatus = hideOnlineStatus;
        }

        this.toggleUserStatus(id, !hideOnlineStatus);
      }
    );
  }

  setupVisibilityChange() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.pusherChannel?.bind(
      'client-visibility-changed',
      ({ id, isVisible }) => {
        if (id !== this.user.id) {
          this.toggleUserStatus(id, isVisible);
        }
      }
    );
  }

  setup() {
    if (this.pusherChannel) {
      return;
    }

    this.onlineUserIds = {};
    this.pusher.subscribe(this.pusherChannelName).then((channel) => {
      this.pusherChannel = channel;

      this.setupSubscription();
      this.setupSubscriptionChange(); // when user open or close the app
      this.setupHideOnlineStatusChange(); // when user change hideOnlineStatus
      this.setupVisibilityChange(); // when user switch tabs
    });
  }

  teardown() {
    this.teardownPusher();
    this.removeEventListener();
  }

  teardownPusher() {
    if (this.pusherChannel) {
      this.pusherChannel.unsubscribe();
      this.pusherChannel = null;
    }
  }

  removeEventListener() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'user-online-status': UserOnlineStatus;
  }
}
