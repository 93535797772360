/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  BookedSlotClass,
  MeetingEventClass,
} from 'teamtailor/classes/meetings';
import moment, { Moment } from 'moment-timezone';
import { assert } from '@ember/debug';
import TimeFormatService from 'teamtailor/services/time-format';

interface MeetingFieldArgs {
  value: BookedSlotClass;
  event: MeetingEventClass;
  onTimeZoneChange: (arg: string) => void;
  tzid: string;
}

export default class MeetingField extends Component<MeetingFieldArgs> {
  @service declare timeFormat: TimeFormatService;

  get value(): BookedSlotClass {
    return this.args.value;
  }

  get dateLabel(): string {
    if (this.startEndDateDiffers) {
      return `${this.startDateLabel} - ${this.endDateLabel}`;
    }

    return this.startDateLabel;
  }

  get startDateLabel(): string {
    return moment(this.value.startsAt)
      .tz(this.args.tzid)
      .locale(this.timeFormat.locale)
      .format('dddd, MMM D');
  }

  get endDateLabel(): string {
    return moment(this.value.endsAt)
      .tz(this.args.tzid)
      .locale(this.timeFormat.locale)
      .format('dddd, MMM D');
  }

  get startsAtLabel(): string {
    return moment(this.value.startsAt).tz(this.args.tzid).format('HH:mm');
  }

  get endsAtLabel(): string {
    return moment(this.value.endsAt).tz(this.args.tzid).format('HH:mm');
  }

  get dateToday(): Date {
    return new Date();
  }

  get nowWithoutTz(): string {
    return moment().tz(this.args.tzid).format('YYYY-MM-DDTHH:mm:ss');
  }

  get startsAtWithoutTz(): string {
    return moment(this.value.startsAt)
      .tz(this.args.tzid)
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  get endsAtWithoutTz(): string {
    return moment(this.value.endsAt)
      .tz(this.args.tzid)
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  get duration(): string {
    const start = moment(this.value.startsAt);
    const end = moment(this.value.endsAt);
    const h = end.diff(start, 'hours');
    const m = Math.round((end.diff(start, 'hours', true) - h) * 60);
    return `${h}h${m ? ` ${m}mins` : ''}`;
  }

  get startEndDateDiffers(): boolean {
    const { startsAt, endsAt } = this.value;
    const momentStartsAt = moment(startsAt).tz(this.args.tzid);
    const momentEndsAt = moment(endsAt).tz(this.args.tzid);
    return (
      momentStartsAt.day() !== momentEndsAt.day() ||
      momentStartsAt.month() !== momentEndsAt.month()
    );
  }

  _setDate(newStartsDate: Date, newEndsDate: Date): void {
    const { startsAt, endsAt } = this.value;

    if (startsAt && endsAt) {
      const momentStartsAt = moment(startsAt).tz(this.args.tzid);
      const momentEndsAt = moment(endsAt).tz(this.args.tzid);

      /* eslint-disable ember/use-ember-get-and-set */
      momentStartsAt.set({
        year: newStartsDate.getFullYear(),
        month: newStartsDate.getMonth(),
        date: newStartsDate.getDate(),
      });
      momentEndsAt.set({
        year: newEndsDate.getFullYear(),
        month: newEndsDate.getMonth(),
        date: newEndsDate.getDate(),
      });
      /* eslint-enable ember/use-ember-get-and-set */

      this.args.event.bookedSlot = BookedSlotClass.from({
        startsAt: momentStartsAt.toDate(),
        endsAt: momentEndsAt.toDate(),
      });
    }
  }

  @action
  setTime(startOrEnd: string, date: Moment): void {
    /* eslint-disable ember/use-ember-get-and-set */
    const hour = date.get('hours');
    const minute = date.get('minutes');
    const { startsAt, endsAt } = this.value;
    const momentStartsAt = moment(startsAt).tz(this.args.tzid);
    let momentEndsAt = moment(endsAt).tz(this.args.tzid);

    if (startOrEnd === 'startTime') {
      const diff = moment.duration(momentEndsAt.diff(momentStartsAt));
      momentStartsAt.set({ hour, minute });
      if (diff.asMinutes() > 0) {
        momentEndsAt = momentStartsAt.clone().add(diff);
      }

      if (this.args.event.bookedSlot) {
        this.args.event.bookedSlot.startsAt = momentStartsAt.toDate();
        this.args.event.bookedSlot.endsAt = momentEndsAt.toDate();
      }
    } else {
      if (this.args.event.bookedSlot) {
        momentEndsAt.set({ hour, minute });
        this.args.event.bookedSlot.endsAt = momentEndsAt.toDate();
      }
    }
    /* eslint-enable ember/use-ember-get-and-set */
  }

  @action
  selectTimeZone(timezone: string): void {
    this.args.onTimeZoneChange(timezone);
  }

  @action
  setDate(dates: Date[]): void {
    const { 0: newStartsDate } = dates;
    let newEndsDate: Date | undefined;
    if (this.startEndDateDiffers) {
      if (dates.length === 1) {
        return;
      }

      newEndsDate = dates[1];
    } else {
      newEndsDate = dates[0];
    }

    assert(
      'newStartsDate && newEndsDate must exist',
      newStartsDate && newEndsDate
    );

    this._setDate(newStartsDate, newEndsDate);
  }
}
