/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import AppRefresher from 'teamtailor/services/app-refresher';
import simulateLinkClick from 'teamtailor/utils/simulate-link-click';
import { argDefault } from 'teamtailor/utils/arg-default';

interface Args {
  route?: string;
  id?: string;
  query?: { [key: string]: any };
  highlightOnHover?: boolean;
}

export default class TableRowComponent extends Component<Args> {
  @service declare router: RouterService;
  @service declare appRefresher: AppRefresher;

  @argDefault highlightOnHover = true;

  navigateToRoute(openInNewTab = false) {
    const { route, id, query } = this.args;
    const queryParams = query || {};

    if (route && id) {
      if (openInNewTab) {
        simulateLinkClick(
          this.router.urlFor(route, id, { queryParams }),
          new MouseEvent('click', {
            ctrlKey: true,
            metaKey: true,
          })
        );
      } else {
        this.appRefresher.transitionOrNavigateTo(route, id, {
          queryParams,
        });
      }
    }
  }

  @action
  handleRowClick(event: MouseEvent) {
    if (
      !event.defaultPrevented &&
      !(event.target as HTMLElement).closest('a, button')
    ) {
      this.navigateToRoute(event.ctrlKey || event.metaKey);
    }
  }

  @action
  handleRowKeyDown(event: KeyboardEvent) {
    const abort = event.defaultPrevented;
    if (event.key === 'Enter' && !abort) {
      event.preventDefault();
      this.navigateToRoute(event.ctrlKey || event.metaKey);
    }
  }
}
