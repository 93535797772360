/* import __COLOCATED_TEMPLATE__ from './form-label.hbs'; */
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';

interface FormLabelArgs {
  theme: 'default' | 'dark' | undefined;
  labelClass: string;
}

export default class FormLabelComponent extends Component<FormLabelArgs> {
  theme = this.args.theme ?? 'default';

  get isDarkTheme(): boolean {
    return this.theme === 'dark';
  }

  get labelTextClasses(): string {
    return classnames('tt-form-label-text', this.args.labelClass, {
      'tt-dark-theme': this.isDarkTheme,
    });
  }
}
