/* import __COLOCATED_TEMPLATE__ from './badge-cluster.hbs'; */
import Component from '@glimmer/component';

import { verifyArg } from 'teamtailor/utils/verify-arg';

const DEFAULT_SIZE = 'small';
const ALLOWED_SIZES = [DEFAULT_SIZE, 'medium'] as const;

type AllowedSizes = (typeof ALLOWED_SIZES)[number];

interface BadgeClusterArgs {
  gapSize?: AllowedSizes;
}

export default class BadgeCluster extends Component<BadgeClusterArgs> {
  get gapSize(): AllowedSizes {
    verifyArg(
      this.args.gapSize,
      ALLOWED_SIZES,
      'Badge cluster @gapSize',
      DEFAULT_SIZE
    );

    return this.args.gapSize ?? DEFAULT_SIZE;
  }
}
