/* import __COLOCATED_TEMPLATE__ from './job-picker.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { enqueueTask } from 'ember-concurrency';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class JobPickerComponent extends Component {
  @service store;
  @service intl;
  @service analytics;

  @tracked status = 'open';
  @tracked _selected = this.args.job;
  @tracked searchTerm = '';
  @argDefault theme = 'default';
  @argDefault allowClear = true;

  fetchJobsTask = enqueueTask(async ({ searchTerm: query, page }) => {
    this.searchTerm = query;

    let jobs = await this.store.query('job', {
      query,
      all_published: this.args.allPublished,
      internal: this.args.includeInternalJobs,
      status: this.args.status || this.status,
      department_id: this.args.department?.id,
      page: page || this.page,
      per_page: 20,
      language_code: this.args.languageCode,
      groupAnalytics: this.args.groupAnalytics,
    });

    if (this.args.excludeJobsIds) {
      jobs = jobs.filter((job) => !this.args.excludeJobsIds.includes(job.id));
    }

    jobs = [...new Set([...this.selectedJobs, ...jobs])];

    if (page === 1 && this.args.selectAllJobs) {
      let { meta } = jobs;
      jobs = [
        {
          jobId: '',
          title: this.intl.t('components.job_picker.all_jobs'),
          default: 'true',
        },
        ...jobs.toArray(),
      ];
      jobs.meta = meta;
    }

    return jobs;
  });

  get selectedJobs() {
    const searchTerm = this.searchTerm || '';
    if (this.args.isMultiple) {
      return this.args.selected.filter((job) =>
        get(job, 'title')?.includes(searchTerm)
      );
    } else if (this.args.selected) {
      const title = get(this.args.selected, 'title');
      if (title?.includes(searchTerm)) {
        return [this.args.selected];
      }
    }

    return [];
  }

  @action
  select(value) {
    this._selected = value;
    this.args.pickedJob(value);
  }

  @action
  changeStatus(select, status) {
    this.page = null;
    this.status = status;
    let query = select.searchText;
    select.actions.search(query, true);
  }

  @action
  didInsertJobPicker(sw) {
    if (this.args.initiallyOpened) {
      sw.fetchPageTask.perform();
    }
  }
}
