/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action, getProperties } from '@ember/object';
import Component from '@glimmer/component';
import { InsightsResponse } from 'teamtailor/controllers/promotions';
import ChannelModel from 'teamtailor/models/channel';
import JobModel from 'teamtailor/models/job';
import PromotionModel from 'teamtailor/models/promotion';
import { get } from 'teamtailor/utils/get';
import { tracked } from 'tracked-built-ins';

const LOADING_COLUMNS = [
  {
    width: '2xl',
  },
  {
    width: 'xl',
  },
  {
    width: 'm',
    contentType: 'core/badge',
  },
  {
    width: 'm',
  },
  {
    width: 'm',
  },
  {
    width: 'm',
  },
];

interface PromotionsTableArgs {
  job?: JobModel;
  channels: ChannelModel[];
  promotions: PromotionModel[];
  insights: InsightsResponse[];
  excludeExpired: boolean;
}

export default class PromotionsTable extends Component<PromotionsTableArgs> {
  loadingColumns = LOADING_COLUMNS;

  get columns() {
    return [
      {
        label: 'promotions.index.promotion',
      },
      {
        label: 'promotions.index.purchase_price',
      },
      {
        label: 'promotions.index.status',
      },
      {
        label: 'promotions.index.views',
      },
      {
        label: 'promotions.index.applications',
      },
      {
        width: 0,
      },
    ];
  }

  promotionLimit = 5;

  @tracked showingPromotionCount = this.promotionLimit;

  get savedPromotions() {
    return this.args.promotions.filter(
      (promotion) =>
        get(get(promotion, 'job'), 'id') === this.args.job?.id &&
        !promotion.isNew &&
        promotion.isNotShareLinkOrQrCode
    );
  }

  get total() {
    const data = this.savedPromotions.reduce<{
      views: number;
      applications: number;
      totalCost: { currency: string; price: number }[];
    }>(
      (acc, promotion) => {
        if (this.args.excludeExpired && promotion.isExpired) {
          return acc;
        }

        const insightsEntry = this.args.insights.find(
          (e) => e.promotionId === promotion.id
        );
        if (insightsEntry) {
          acc.views += insightsEntry.views;
        }

        const cost = acc.totalCost.find(
          (e) => e.currency === (promotion.currency || 'EUR')
        );

        if (cost) {
          cost.price += promotion.price;
        }

        acc.applications += promotion.applicationsCount || 0;

        return acc;
      },
      {
        views: 0,
        applications: 0,
        totalCost: [
          { currency: 'EUR', price: 0 },
          { currency: 'SEK', price: 0 },
          { currency: 'DKK', price: 0 },
          { currency: 'GBP', price: 0 },
          { currency: 'USD', price: 0 },
        ],
      }
    );
    const sortedCost = data.totalCost
      .sort((a, b) => b.price - a.price)
      .filter(({ price }) => price);
    const primaryCost = sortedCost[0] || {
      price: 0,
      currency: this.args.job?.currency || 'EUR',
    };
    const secondaryCosts = sortedCost.slice(1);
    const primaryCostText = `${primaryCost.price} ${primaryCost.currency}`;
    const secondaryCostsText = secondaryCosts
      .map(({ price, currency }) => `${price} ${currency}`)
      .join(', ');
    return {
      ...data,
      primaryCostText,
      secondaryCostsCount: secondaryCosts.length,
      secondaryCostsTooltip: secondaryCostsText,
    };
  }

  get availableChannels() {
    return this.args.channels
      .filter((channel) => {
        const { isInternal } = getProperties(channel, ['isInternal']);
        return !isInternal;
      })
      .sort((a, b) => get(a, 'rowOrder') - get(b, 'rowOrder'));
  }

  get sortedPromotions() {
    return this.savedPromotions.toArray();
  }

  shouldShowUnpublishButton = (channel: ChannelModel) => {
    return get(channel, 'isActivated') && get(channel, 'isAlwaysIncluded');
  };

  get ongoingPromotionData() {
    const promotionsWithChannel = this.sortedPromotions
      .map((promotion) => {
        const entry = this.args.insights.find(
          ({ promotionId }) => promotion.id === promotionId
        );

        return {
          channel: this.availableChannels.find(
            (channel) =>
              get(channel, 'id') === get(get(promotion, 'channel'), 'id')
          ),

          promotion,
          views: entry?.views || 0,
        };
      })
      .filter((data) => {
        if (!this.args.excludeExpired) return true;

        return !data.promotion.isExpired;
      });

    return promotionsWithChannel;
  }

  get showMoreButtonCount() {
    return this.ongoingPromotionData.length - this.showingPromotionCount;
  }

  @action handleShowMoreLessButtonClick() {
    if (this.showingPromotionCount === this.ongoingPromotionData.length) {
      this.showingPromotionCount = this.promotionLimit;
    } else {
      this.showingPromotionCount += this.showMoreButtonCount;
    }
  }
}
