/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask, didCancel } from 'ember-concurrency';
import { action, get, set } from '@ember/object';
import PerfectScrollbar from 'perfect-scrollbar';
import { tracked } from '@glimmer/tracking';

const SCOPE_FILTERS = ['my', 'assigned'];

export default class extends Component {
  @service flashMessages;
  @service store;
  @service intl;
  @service user;
  @service infinity;
  @service todoCounters;

  perfectScrollbar = null;
  scopeFilters = SCOPE_FILTERS;

  @tracked scrollToId = null;
  @tracked todos = null;
  @tracked editingTodo = null;
  @tracked isShowingAddTodo = false;

  element = null;
  perPage = 20;

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.perfectScrollbar) {
      this.perfectScrollbar.destroy();
      this.perfectScrollbar = null;
    }
  }

  orderBool(a, b, positive = true) {
    if (!!a === !!b) {
      return (positive && a) || (!positive && !a) ? null : 0;
    }

    if (a && !b) {
      return positive ? -1 : 1;
    }

    return positive ? 1 : -1;
  }

  orderById(a, b) {
    return a < b ? 1 : -1;
  }

  orderByCompleted(a, b) {
    let orderByCompleted = this.orderBool(a.completed, b.completed, false);
    if (orderByCompleted !== null) {
      if (a.completedRecently) {
        if (b.completed && !b.completedRecently) {
          return -1;
        }

        return null;
      } else if (b.completedRecently) {
        return a.completed ? 1 : null;
      }

      if (orderByCompleted === 0) {
        let aTime = new Date(a.completedAt).getTime();
        let bTime = new Date(b.completedAt).getTime();
        return aTime === bTime ? 0 : aTime < bTime ? 1 : -1;
      }

      return orderByCompleted;
    }

    return null;
  }

  get sortedTodos() {
    let todos = this.todos.toArray();

    todos = todos.filter((todo) => !todo.isDeleted);
    if (!this.args.showCompleted) {
      todos = todos.filter((todo) => !todo.completed || todo.completedRecently);
    }

    switch (this.args.selectedSortingOrder) {
      case 'due':
        todos.sort((a, b) => {
          let orderByCompleted = this.orderByCompleted(a, b);
          if (orderByCompleted !== null) {
            return orderByCompleted;
          }

          let noDueOrder = this.orderBool(a.due, b.due);
          if (noDueOrder !== null) {
            return noDueOrder;
          }

          let aTime = new Date(a.due).getTime();
          let bTime = new Date(b.due).getTime();
          return aTime === bTime
            ? this.orderById(a.id, b.id)
            : aTime > bTime
              ? 1
              : -1;
        });
        break;
      case 'title':
        todos.sort((a, b) => {
          let orderByCompleted = this.orderByCompleted(a, b);
          if (orderByCompleted !== null) {
            return orderByCompleted;
          }

          let aValue = a.value.toLowerCase();
          let bValue = b.value.toLowerCase();
          return aValue === bValue
            ? this.orderById(a.id, b.id)
            : aValue > bValue
              ? 1
              : -1;
        });
        break;
      case 'candidate':
        todos.sort((a, b) => {
          let orderByCompleted = this.orderByCompleted(a, b);
          if (orderByCompleted !== null) {
            return orderByCompleted;
          }

          let noCandidateOrder = this.orderBool(a.candidate, b.candidate);
          if (noCandidateOrder !== null) {
            return noCandidateOrder;
          }

          if (a.candidate.id === b.candidate.id) {
            return a.id > b.id ? 1 : -1;
          }

          let aName = a.candidate.nameOrEmail.toLowerCase();
          let bName = b.candidate.nameOrEmail.toLowerCase();
          if (aName === bName) {
            return this.orderById(a.id, b.id);
          }

          return aName > bName ? 1 : -1;
        });
        break;
    }

    return todos;
  }

  onError() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  bindScrollElement(element) {
    this.scrollContainerElement = element;
    const ps = new PerfectScrollbar(element, {
      wheelPropagation: true,
      suppressScrollX: true,
    });

    set(this, 'perfectScrollbar', ps);
  }

  @action
  scrollTo(offsetY, scrollHeight) {
    this.scrollToId = null;

    let { height } = this.scrollContainerElement.getBoundingClientRect();
    let maxHeight = scrollHeight;
    maxHeight -= offsetY;
    set(
      this,
      'perfectScrollbar.element.scrollTop',
      offsetY - Math.min(height / 3, maxHeight)
    );
  }

  insertTodo(todo) {
    this.infinity.pushObjects(this.todos, [todo]);
    this.todoCounters.modifyDueGroupCount(todo.dueGroup, 1);
    this.scrollToId = todo.id;
  }

  refetchTodos() {
    this.todos = null;
    this.fetchTodos.perform().catch((e) => {
      if (!didCancel(e)) {
        // re-throw the non-cancelation error
        throw e;
      }
    });
  }

  fetchTodos = restartableTask(async () => {
    if (this.todos !== null) {
      await this.infinity.infinityLoad(this.todos);
      return this.todos;
    }

    let todos = await this.infinity.model('todo', {
      per_page: this.perPage,
      sort: this.args.selectedSortingOrder,
      scope: this.args.selectedScopeFilter,
      include_completed:
        this.args.showCompleted === 'show-completed' ? true : undefined,
    });
    set(this, 'todos', todos);

    if (this.args.selectedScopeFilter === 'my') {
      this.todoCounters.setCountersFromMeta(get(todos, 'meta'));
    }

    return todos;
  });

  @action
  filterChanged() {
    this.refetchTodos();
  }

  @action
  showAddTodo() {
    this.isShowingAddTodo = true;
  }

  @action
  setTodo(todo) {
    this.editingTodo = todo;
  }

  @action
  handleToggle(todo) {
    let response = todo.toggleCompletion();
    return response;
  }

  @action
  addTodo({ value, description, due, assignee, candidate }) {
    return get(this, 'user.todos')
      .createRecord({
        createdNow: true,
        value,
        description,
        due,
        assignee,
        candidate,
      })
      .save()
      .then((todo) => {
        if (
          (this.args.selectedScopeFilter === 'my' &&
            (!assignee || get(todo, 'assignee.id') === get(this, 'user.id'))) ||
          (this.args.selectedScopeFilter === 'assigned' &&
            assignee &&
            get(todo, 'assignee.id') !== get(this, 'user.id'))
        ) {
          this.insertTodo(todo);
        }

        return todo;
      })
      .catch((e) => {
        this.onError();
        return e;
      });
  }

  @action
  saveTodo({ value, description, due, assignee, candidate }) {
    this.editingTodo.value = value;
    this.editingTodo.description = description;
    this.editingTodo.due = due;
    this.editingTodo.assignee = assignee;
    this.editingTodo.candidate = candidate;
    return this.editingTodo
      .save()
      .then(() => {
        this.todoCounters.refresh();
      })
      .catch((e) => {
        this.onError();
        return e;
      });
  }

  @action
  deleteTodo(todo) {
    return todo
      .destroyRecord()
      .then((response) => {
        let todos = this.todos.toArray();
        todos = todos.filter((todo) => todo.id !== response.id);
        this.infinity.replace(this.todos, todos);
        this.todoCounters.modifyDueGroupCount(todo.dueGroup, -1);

        return response;
      })
      .catch((e) => {
        this.onError();
        return e;
      });
  }

  @action
  setDueDate(todo, dueDate) {
    return todo.setDueDate(dueDate).catch((e) => {
      this.onError();
      return e;
    });
  }
}
