/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import { ChatMessage } from 'teamtailor/utils/video-meetings/utils';
import ScrollPosition from 'teamtailor/utils/scroll-position';
import { VideoMeetingTokenModel } from 'teamtailor/models';
import { registerDestructor } from '@ember/destroyable';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';

const NEW_CHAT_MESSAGE_EVENT = 'new-chat-message';

type VideoMeetingChatArgs = {
  isDesktop: boolean;
  isChatActive: boolean;
  localParticipantName: string;
  model: VideoMeetingTokenModel;
  updateNewChatMessagesAmount(): void;
};

export default class VideoMeetingChat extends Component<VideoMeetingChatArgs> {
  @service declare pusher: PusherService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @tracked chatMessages: ChatMessage[] = [];
  declare pusherChannel: PusherChannel | null;
  declare scrollPosition: ScrollPosition;

  constructor(owner: unknown, args: VideoMeetingChatArgs) {
    super(owner, args);

    this.setupPusher();

    registerDestructor(this, () => {
      this.teardownPusher();
    });
  }

  pusherChannelName() {
    return `presence-video-meeting-${this.args.model.id}`;
  }

  setupPusher() {
    this.pusher.subscribe(this.pusherChannelName()).then((channel) => {
      this.pusherChannel = channel;
      this.pusherChannel.bind(NEW_CHAT_MESSAGE_EVENT, (newChatMessage) => {
        this.chatMessages = [...this.chatMessages, newChatMessage];
        this.scrollPosition.scrollToBottom();
        this.args.updateNewChatMessagesAmount();
      });
    });
  }

  teardownPusher() {
    if (this.pusherChannel) {
      this.pusherChannel.unsubscribe();
      this.pusherChannel = null;
    }
  }

  sendMessage = (message: string) => {
    const newChatMessage: ChatMessage = {
      message,
      attendee_name: this.args.localParticipantName,
      timestamp: Date.now(),
    };

    this.args.model.updateChatHistory({ ...newChatMessage }).catch(() => {
      this.flashMessages.error(
        this.intl.t('components.video_meeting.chat.message_error')
      );
    });
  };

  @action
  registerScrollbar(element: HTMLDivElement) {
    this.scrollPosition = new ScrollPosition(element);
  }

  get classes() {
    let classes = 'flex-col bg-neutral-strong';

    if (this.args.isDesktop) {
      classes += ' h-[100vh] w-[340px] border-l border-zinc-700';
      classes += this.args.isChatActive ? ' flex' : ' hidden';
    } else {
      classes +=
        ' flex fixed bottom-0 z-[50] w-full rounded-t-10 transition-all duration-500 h-[500px]';
      classes += this.args.isChatActive ? '' : ' translate-y-[200%]';
    }

    return classes;
  }
}
