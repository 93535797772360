/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import ItemComponent from 'teamtailor/components/content-block/item-component';
import { action } from '@ember/object';

export default class LeadItemComponent extends ItemComponent {
  @action
  onTextChange(item, section, body) {
    if (item.text !== body) {
      this.filthyContent.setFilthy(section.id);
    }

    item.text = body;
  }
}
