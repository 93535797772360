/* import __COLOCATED_TEMPLATE__ from './job-picked-custom-field.hbs'; */
import Component from '@glimmer/component';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';

export default class JobPickedCustomField extends Component {
  @alias('args.field') field;
  @alias('field.customField.options') options;

  get selectValue() {
    if (this.field.value && this.options.length) {
      const values = this.field.value.map((pickedOption) => {
        return this.options.filter((option) => option.id === pickedOption)[0]
          .value;
      });
      return values.join(', ');
    }

    return '';
  }
}
