/* import __COLOCATED_TEMPLATE__ from './new-survey-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import templates from 'teamtailor/utils/survey-templates';

export default class NewSurveyModal extends Component {
  @service store;
  @service router;
  @service current;

  templates = templates;

  @action
  createFromTemplate(templateId) {
    const template = templates.find((t) => t.id === templateId);

    this.router.transitionTo('surveys.new', this.current.company.uuid, {
      queryParams: {
        template: template.id,
      },
    });
  }
}
