/* import __COLOCATED_TEMPLATE__ from './read-only-section.hbs'; */
import Component from '@glimmer/component';

export default class ReadOnlySectionComponent extends Component {
  // need to use this if the parent has overflow-auto
  popperOptions = Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: true,
      },
    },
  });
}
