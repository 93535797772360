/* import __COLOCATED_TEMPLATE__ from './account-menu.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import config from 'teamtailor/config/environment';
import Server from 'teamtailor/services/server';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import RouterService from '@ember/routing/router-service';
import PermissionsService from 'teamtailor/services/permissions';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import ApplicationController from 'teamtailor/controllers/application';
import SessionService from 'ember-simple-auth/services/session';
import { get } from 'teamtailor/utils/get';
import { CoreDropdownMenuApi } from '../core/dropdown-menu';
import { EkEvent } from 'ember-keyboard';
import Store from '@ember-data/store';

const HEADWAY_SCRIPT_SRC = 'https://cdn.headwayapp.co/widget.js';

export default class extends Component {
  @service declare server: Server;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare permissions: PermissionsService;
  @service declare current: Current;
  @service declare session: SessionService;
  @service declare flipper: FlipperService;

  @controller('application')
  declare applicationController: ApplicationController;

  @tracked numberOfNewUpdates = 0;
  @tracked showBadge = false;

  get user() {
    return this.current.user;
  }

  async updateLastCheckDate() {
    const response = (await this.server.request(
      '/users/current/tt_updates',
      'PATCH'
    )) as Record<string, unknown>;

    this.store.pushPayload(response);
  }

  setBadge(dates: Date[]) {
    const lastUpdateCheck = get(get(this.user, 'login'), 'lastTtUpdateCheck');
    const numNewUpdates = dates.filter((date) => date > lastUpdateCheck).length;

    this.numberOfNewUpdates = numNewUpdates;

    if (numNewUpdates) {
      this.showBadge = true;
    }
  }

  loadHeadwayScript() {
    const script = document.createElement('script');

    script.src = HEADWAY_SCRIPT_SRC;

    document.head.appendChild(script);
  }

  @action
  installPwa() {
    this.applicationController.deferredPrompt.prompt();
  }

  @action
  invalidateSession() {
    this.session.invalidate();
  }

  @action
  goToProfile() {
    this.router.transitionTo(
      'employees.profile.index',
      this.current.company.uuid,
      get(this, 'user')
    );
  }

  @tracked menuApi?: CoreDropdownMenuApi;

  @action async onEscapeKey(_event: KeyboardEvent, ekEvent: EkEvent) {
    if (this.headwayIsOpen) {
      ekEvent.stopPropagation();
      window.Headway?.hide();
    }
  }

  @tracked headwayIsOpen = false;

  @action
  onDidInsertHeadway(_el: HTMLButtonElement, [menuApi]: [CoreDropdownMenuApi]) {
    this.menuApi = menuApi;
    window.HW_config = {
      selector: '.headway-trigger-button',
      trigger: '.headway-trigger-button',
      account: config.headwayAccount,
      callbacks: {
        onWidgetReady: (widget: { getChangelogs(): { dateTime: Date }[] }) => {
          next(() => {
            const feedDates = widget.getChangelogs().map((c) => c.dateTime);
            this.setBadge(feedDates);
          });
        },

        onShowWidget: () => {
          next(() => {
            this.headwayIsOpen = true;
            this.updateLastCheckDate();
            this.showBadge = false;
            if (this.menuApi?.close) {
              this.menuApi.close();
            }
          });
        },

        onHideWidget: () => {
          next(() => {
            this.headwayIsOpen = false;
            this.numberOfNewUpdates = 0;
          });
        },
      },
    };

    this.loadHeadwayScript();
  }

  @action
  toggleHideOnlineStatus() {
    this.user.toggleHideOnlineStatus().catch(() => {
      this.user.hideOnlineStatus = !this.user.hideOnlineStatus;
    });
  }
}
