import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import Current from 'teamtailor/services/current';
import WrapUpController from 'teamtailor/controllers/wrap-up';
import Store from '@ember-data/store';
import {
  buildReport as buildAudienceVisitorsReport,
  AudienceVisitorsReport,
} from 'teamtailor/classes/analytics/report-audience-visitors';
import { getOwner } from '@ember/application';
import {
  HiredCandidate,
  HIRED_CANDIDATES_QUERY,
  ReturnModel,
  WrapUpRecord,
  WRAP_UP_QUERY,
  MESSAGE_COUNT_QUERY,
} from 'teamtailor/constants/wrap-up';
import FlipperService from 'teamtailor/services/flipper';
import * as Sentry from '@sentry/ember';
import DateRange from 'teamtailor/utils/date-range';
import InsightsService from 'teamtailor/services/insights';
import { EventQueryResponse } from 'teamtailor/utils/insights/graphql-response-types';

export default class WrapUp extends Route {
  @service declare apollo: TeamtailorApolloService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare flipper: FlipperService;
  @service declare insights: InsightsService;

  container = getOwner(this);

  beforeModel() {
    const hasAccess =
      this.current.user.admin && !!this.current.company.churnedAt;
    if (!hasAccess) {
      this.router.transitionTo('dashboard');
    }
  }

  async model(): Promise<ReturnModel> {
    const [
      wrapUpResult,
      audienceVisitors,
      messageCount,
      hiredCandidatesResult,
    ] = await Promise.all([
      this.fetchWrapUpResult(),
      this.fetchAudienceVisitors(),
      this.fetchMessageCount(),
      this.fetchHiredCandidates(),
    ]);

    return {
      ...wrapUpResult.wrapUp,
      messageCount,
      pageVisitsCount: audienceVisitors?.totalVisits || 0,
      hiredCandidates: hiredCandidatesResult.candidates,
      features: this.current.company.features.slice(),
    };
  }

  async fetchHiredCandidates() {
    const hiredCandidatesResult: Record<'candidates', HiredCandidate[]> =
      await this.apollo.query({
        query: HIRED_CANDIDATES_QUERY,
        variables: {
          filter: {
            hired: 'yes',
            perPage: 100,
          },
        },
      });

    return hiredCandidatesResult;
  }

  async fetchMessageCount() {
    const dateRange = new DateRange(
      this.current.company.went_live_at || '2015-01-01',
      this.current.company.churnedAt || new Date().toISOString()
    ).asObject;

    const messageCountResult: { events: EventQueryResponse } =
      await this.insights.query({
        query: MESSAGE_COUNT_QUERY,
        variables: {
          dateRange,
        },
      });

    return messageCountResult.events.aggregated[0]?.count || 0;
  }

  async fetchWrapUpResult() {
    const wrapUpResult: Record<'wrapUp', WrapUpRecord> =
      await this.apollo.query({
        query: WRAP_UP_QUERY,
      });
    return wrapUpResult;
  }

  async fetchAudienceVisitors() {
    let audienceVisitors: AudienceVisitorsReport | null = null;

    try {
      audienceVisitors = await buildAudienceVisitorsReport(
        this.container
      ).build.perform();
    } catch (error) {
      Sentry.captureException(error);
    }

    return audienceVisitors;
  }

  resetController(controller: WrapUpController) {
    controller.fadeOut = false;
  }
}
