/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import RouteHelper from 'teamtailor/services/route-helper';
import RouterService from '@ember/routing/router-service';
import { JobOfferModel } from 'teamtailor/models';
import uniqBy from 'teamtailor/utils/uniq-by';

interface CandidateModalSectionJobOffersItemArgs {
  jobOffer: JobOfferModel;
}

export default class CandidateModalSectionJobOffersItem extends Component<CandidateModalSectionJobOffersItemArgs> {
  @service declare router: RouterService;
  @service declare routeHelper: RouteHelper;
  @service declare current: Current;

  get userGroup() {
    const { jobOffer } = this;
    const users = [
      get(jobOffer, 'user'),
      get(jobOffer, 'recruiter'),
      ...get(jobOffer, 'users').toArray(),
    ];

    return uniqBy(users, 'id');
  }

  get jobOfferRoute() {
    return this.linkToJob
      ? 'jobs.job.stages.index.candidate.job-offer.show'
      : this.routeHelper.getNestedRoute('job-offer.show');
  }

  get linkToJob() {
    return this.router.currentRouteName.includes('analytics.overview');
  }

  get jobOffer() {
    return this.args.jobOffer;
  }

  get models() {
    if (this.linkToJob) {
      const jobApplication = get(this.jobOffer, 'jobApplication');
      return [
        this.current.company.uuid,
        get(jobApplication, 'jobId'),
        get(jobApplication, 'candidateId'),
        this.jobOffer,
      ];
    } else {
      return [this.jobOffer];
    }
  }
}
