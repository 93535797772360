/* import __COLOCATED_TEMPLATE__ from './section-hover-menu.hbs'; */
import Store from '@ember-data/store';
import ContentEditorController from 'teamtailor/controllers/content/editor';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import {
  Registry as ControllerRegistry,
  inject as controller,
} from '@ember/controller';
import { afterRender } from 'teamtailor/ember-smooth';
import { BlockLayoutModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import ContentEditorIndexController from 'teamtailor/controllers/content/editor/index';
import { getOwner } from '@ember/application';
import { ValueOf } from 'teamtailor/utils/type-utils';
import { tracked } from '@glimmer/tracking';
import { SubTypeClassNames } from 'teamtailor/serializers/section';
import { SECTION_NAMES_WITH_ADJUSTMENTS } from 'teamtailor/components/careersite-form';
import { CoreDropdownMenuApi } from '../core/dropdown-menu';

interface IframePreviewComponentArgs {
  iframeSrc?: string;
  iframeType: 'styleguide' | 'page';
  iframeStyle?: string;
  onInsert?: (element: HTMLElement) => void;
  allBlockLayouts?: BlockLayoutModel[];
}

type ControllersWithShowAdjustments = Extract<
  ValueOf<ControllerRegistry>,
  { showAdjustments: unknown }
>;

export default class IframePreviewComponent extends Component<IframePreviewComponentArgs> {
  @service declare iframePreview: IframePreviewService;
  @service declare router: RouterService;
  @service declare store: Store;

  constructor(owner: unknown, args: IframePreviewComponentArgs) {
    super(owner, args);
    window.addEventListener('message', this.handleIframeMessage);
  }

  get hasAdjustments() {
    return SECTION_NAMES_WITH_ADJUSTMENTS.includes(
      get(this.contentEditorController.hoveredSection, 'name')!
    );
  }

  @controller('content.editor')
  declare contentEditorController: ContentEditorController;

  @controller('content.editor.index')
  declare contentEditorIndexController: ContentEditorIndexController;

  @tracked adjustmentsController?: ControllersWithShowAdjustments;

  @action async handleAdjustmentsClick() {
    const owner = getOwner(this);
    await this.contentEditorIndexController.goToSectionEdit(
      this.contentEditorController.hoveredSection ||
        this.contentEditorController.hoveredSectionData?.idOrName
    );
    await afterRender();

    const sectionName = get(
      this.contentEditorController.hoveredSection,
      'name'
    );

    const controllerName =
      sectionName === 'header'
        ? 'content.editor.header'
        : `content.editor.section.${sectionName}`;
    let sectionController: ControllersWithShowAdjustments | undefined =
      owner.lookup(`controller:${controllerName}.index`);

    if (!sectionController) {
      sectionController = owner.lookup(
        `controller:${controllerName}`
      ) as ControllersWithShowAdjustments;
    }

    set(
      sectionController,
      'showAdjustments',
      !sectionController.showAdjustments
    );
  }

  declare el?: HTMLElement;

  @action onBlockLayoutUpdate() {
    if ((this.blockLayouts?.length || 0) > 1) {
      this.blockLayouts?.forEach((blockLayout) => {
        new Image().src = blockLayout.desktopPreview;
      });
    }
  }

  get blockLayouts() {
    return this.contentEditorController.hoveredSection
      ? this.args.allBlockLayouts
          ?.filter((blockLayout) => {
            return (
              blockLayout.blockName ===
              get(this.contentEditorController.hoveredSection, 'name')
            );
          })
          .toSorted((a, b) => a.displayName.localeCompare(b.displayName))
      : [];
  }

  @action onInsert(element: HTMLElement) {
    this.el = element;

    this.args.onInsert?.(element);
  }

  @action async handleEditClick() {
    const [routeName, routeModel] =
      (await this.contentEditorIndexController.getSectionEditRouteParams(
        this.contentEditorController.hoveredSectionData?.idOrName
      ))!;

    const newUrl = routeModel
      ? this.router.urlFor(routeName, routeModel)
      : this.router.urlFor(routeName);

    if (newUrl !== this.router.currentURL) {
      this.contentEditorIndexController.goToSectionEdit(
        this.contentEditorController.hoveredSection ||
          this.contentEditorController.hoveredSectionData?.idOrName
      );
    }
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('message', this.handleIframeMessage);
  }

  declare top: number;

  isScrolling = false;
  layoutDropdownApi?: CoreDropdownMenuApi;
  @action onLayoutDropdownDestroy() {
    this.layoutDropdownApi = undefined;
  }

  getExtraOffset(sectionName?: string) {
    return sectionName === 'cover' ? 100 : 0;
  }

  handleIframeMessage = (
    event: MessageEvent<
      {
        sectionId: string;
        sectionSubType: SubTypeClassNames | undefined;
        sectionName?: string;
        relatedTargetIsMain: boolean;
      } & (
        | {
            message: 'scroll';
            activeSectionRect?: DOMRect;
          }
        | {
            message: 'mouseenter-section';
            sectionRect?: DOMRect;
            scrollY: number;
          }
        | {
            message: 'mouseleave-section';
          }
      )
    >
  ) => {
    const stickyTop = 16;
    const offset = 8;
    const toplimit = stickyTop + offset;

    let topToSet: number | undefined;

    switch (event.data.message) {
      case 'scroll': {
        const { activeSectionRect, sectionId, sectionSubType, sectionName } =
          event.data;
        if (activeSectionRect) {
          this.isScrolling = true;

          const extraOffset = this.getExtraOffset(sectionName);
          const newTop = activeSectionRect.top + extraOffset;

          topToSet = newTop < toplimit ? stickyTop : newTop - offset;

          this.moveHoverMenu(topToSet);
        }

        this.contentEditorController.hoveredSectionData = {
          idOrName: sectionId,
          subTypeClassName: sectionSubType,
        };

        break;
      }

      case 'mouseenter-section': {
        if (this.layoutDropdownApi?.isOpen) {
          return;
        }

        const { sectionRect, sectionId, sectionSubType, sectionName } =
          event.data;

        if (!sectionRect) {
          return;
        }

        if (
          sectionId.toString() !==
            this.contentEditorController.hoveredSectionData?.idOrName?.toString() ||
          sectionSubType?.toString() !==
            this.contentEditorController.hoveredSectionData.subTypeClassName?.toString()
        ) {
          this.contentEditorController.hoveredSectionData = {
            idOrName: sectionId,
            subTypeClassName: sectionSubType,
          };
        }

        const extraOffset = this.getExtraOffset(sectionName);
        const newTop = sectionRect.top + extraOffset;

        topToSet = newTop < toplimit ? stickyTop : newTop - offset;

        if (!this.isScrolling) {
          this.moveHoverMenu(topToSet);
        }

        break;
      }

      case 'mouseleave-section': {
        if (this.layoutDropdownApi?.isOpen) {
          return;
        }

        const { relatedTargetIsMain } = event.data;
        if (relatedTargetIsMain) {
          this.contentEditorController.hoveredSectionData = undefined;
        }
      }
    }
  };

  @action moveHoverMenu(topToSet: number) {
    this.isScrolling = false;
    if (this.el) {
      this.el.style.top = `${topToSet}px`;
    }
  }
}
