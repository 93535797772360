/* import __COLOCATED_TEMPLATE__ from './cover.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ImageModel } from 'teamtailor/models';

interface ConnectSettingsBlocksCover {
  Args: {
    handlePickImage: (image: ImageModel) => Promise<void>;
    handleRemoveImage: () => Promise<void>;
  };
}

export default class ConnectSettingsBlocksCoverComponent extends Component<ConnectSettingsBlocksCover> {
  @tracked isPicking = false;
  @tracked isHandlingImage = false;

  @action
  async onPickImage(image: ImageModel) {
    this.isPicking = false;
    this.isHandlingImage = true;
    await this.args.handlePickImage(image);
    this.isHandlingImage = false;
  }

  @action
  async onRemoveImage() {
    this.isHandlingImage = true;
    await this.args.handleRemoveImage();
    this.isHandlingImage = false;
  }
}
