/* import __COLOCATED_TEMPLATE__ from './legend.hbs'; */
import Component from '@glimmer/component';
import colorful, {
  nps,
  grey,
  bgNpsClasses,
  bgColorClasses,
  bgGreyClasses,
} from './colors';
import { action } from '@ember/object';

type Args = {
  maxLength?: number;
  labels: string[];
  colors?: string[];
  colorscheme?: string;
  colorClasses?: string[];
  lastIsOther?: boolean;
  allowScroll?: boolean;
  vertical?: boolean;
  size?: 'small' | 'medium';
};

export default class InsightsChartsLegend extends Component<Args> {
  get size() {
    switch (this.args.size) {
      case 'medium':
        return 10;
      default:
        return 8;
    }
  }

  get gap() {
    return 8;
  }

  get detailsMargin() {
    return this.gap + this.size;
  }

  get labels() {
    if (this.args.maxLength) {
      return this.args.labels.slice(0, this.args.maxLength);
    } else {
      return this.args.labels;
    }
  }

  get colors() {
    if (this.args.colors) {
      return this.args.colors;
    }

    switch (this.args.colorscheme) {
      case 'colorful':
        return colorful;

      case 'grey':
        return grey;

      case 'nps':
        return nps;

      default:
        throw new Error(`invalid @colorscheme: ${this.args.colorscheme}`);
    }
  }

  get colorClasses() {
    if (this.args.colorClasses) {
      return this.args.colorClasses;
    }

    switch (this.args.colorscheme) {
      case 'colorful':
        return bgColorClasses;

      case 'grey':
        return bgGreyClasses;

      case 'nps':
        return bgNpsClasses;

      default:
        throw new Error(`invalid @colorscheme: ${this.args.colorscheme}`);
    }
  }

  @action
  colorCode(colorIndex: number) {
    let color = this.colorClasses[colorIndex % this.colors.length];

    if (this.args.lastIsOther && this.labels.length - 1 === colorIndex) {
      color = 'bg-zinc-150';
    }

    return color;
  }
}
