/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import CandidateModel from 'teamtailor/models/candidate';
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import PermissionsService from 'teamtailor/services/permissions';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { JobApplicationModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import FlipperService from 'teamtailor/services/flipper';
import RouterService from '@ember/routing/router-service';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel | null;
}

export default class extends Component<Args> {
  @service declare current: Current;
  @service declare permissions: PermissionsService;
  @service declare flipper: FlipperService;
  @service declare router: RouterService;

  @tracked isShowingRestrictCandidateConfirmation = false;
  @tracked showShareModal = false;

  @computedLocalStorage(Boolean, 'hideRestrictionWarningKey', false)
  declare hideRestrictionWarning: boolean;

  get printOptions() {
    return JSON.stringify({ selectedCandidateIds: [this.candidate.id] });
  }

  get candidate() {
    return this.args.candidate;
  }

  get candidateUrl() {
    const path = this.router.urlFor(
      'candidates.segment.candidate',
      'all',
      this.candidate
    );

    return `${window.location.origin}${path}`;
  }

  get isAnonymousStage() {
    if (this.jobApplication) {
      return get(this.jobApplication.stage, 'anonymous');
    }

    return false;
  }

  get anonymalColor() {
    return this.candidate.anonymousColor;
  }

  get anonymalIconUrl() {
    return this.candidate.anonymalIconUrl;
  }

  get jobApplication() {
    return this.args.jobApplication;
  }

  @action
  onToggleRestrictedAccess() {
    const { restricted } = this.candidate;
    const showWarningBeforeRestrict =
      !restricted &&
      !this.hideRestrictionWarning &&
      !this.permissions.has('candidate/access_restricted');

    if (showWarningBeforeRestrict) {
      this.isShowingRestrictCandidateConfirmation = true;
    } else {
      this.confirmCandidateRestriction();
    }
  }

  @action
  confirmCandidateRestriction() {
    this.isShowingRestrictCandidateConfirmation = false;
    const { restricted } = this.candidate;
    this.candidate.restricted = !restricted;

    return this.candidate.save();
  }
}
