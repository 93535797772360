/* import __COLOCATED_TEMPLATE__ from './nps-gauge.hbs'; */
import Component from '@glimmer/component';

type Args = {
  data: number;
};

export default class InsightsChartsNpsGauge extends Component<Args> {
  get chartOptions() {
    const options = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '60%',
      },

      title: {
        text: '',
      },

      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%',
      },

      legend: {
        enabled: false,
      },

      yAxis: {
        title: {
          text: '',
        },

        min: -100,
        max: 100,

        gridLineColor: '#eeeff2',

        tickPixelInterval: 100,
        tickPosition: 'inside',
        tickColor: '#FFFFFF',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,

        labels: {
          distance: 20,
          style: {
            fontSize: '14px',
          },
        },

        lineWidth: 0,
        plotBands: [
          {
            from: -100,
            to: 0,
            color: '#DF5353', // red
            thickness: 20,
          },
          {
            from: 0,
            to: 25,
            color: '#DDDF0D', // yellow
            thickness: 20,
          },
          {
            from: 25,
            to: 100,
            color: '#55BF3B', // green
            thickness: 20,
          },
        ],
      },

      series: [
        {
          name: 'NPS',
          data: [Math.round(100 * this.args.data) / 100.0],

          dataLabels: {
            format: '{y}',
            borderWidth: 0,
            color: '#34353A',

            style: {
              fontSize: '28px',
            },
          },

          dial: {
            radius: '80%',
            backgroundColor: 'gray',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%',
          },

          pivot: {
            backgroundColor: 'gray',
            radius: 6,
          },
        },
      ],
    };

    return options;
  }
}
