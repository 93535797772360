/* import __COLOCATED_TEMPLATE__ from './average-times.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class extends Component {
  get hasTimeInInboxComparison() {
    return (
      this.args.currentPeriod?.timeInInbox &&
      this.args.previousPeriod?.timeInInbox
    );
  }

  get hasTimeToRejectComparison() {
    return (
      this.args.currentPeriod?.timeToReject &&
      this.args.previousPeriod?.timeToReject
    );
  }

  get hasTimeToHireComparison() {
    return (
      this.args.currentPeriod?.timeToHire &&
      this.args.previousPeriod?.timeToHire
    );
  }

  get hasThreeColumns() {
    return (
      this.hasTimeToHireComparison ||
      this.hasTimeToRejectComparison ||
      this.hasTimeInInboxComparison
    );
  }

  @action
  secondsAsDays(seconds) {
    if (seconds !== null) {
      return moment.duration(seconds, 'seconds').as('days').toFixed(1);
    } else {
      return 0;
    }
  }
}
