/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import { ActivityModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action } from '@ember/object';

type Args = {
  activities: Record<string, ActivityModel[]> | null;
};

export default class GlobalCommentsPinned extends Component<Args> {
  @service declare store: Store;

  resolveChannelsResponse = trackedFunction(this, async () => {
    if (this.channelsIds.length === 0) return [];
    const sortedJobs = await this.store.query('job', {
      ids: this.channelsIds,
    });
    return sortedJobs;
  });

  get isLoading() {
    return this.args.activities === null;
  }

  get channelsIds() {
    if (this.args.activities) {
      return Object.keys(this.args.activities);
    } else {
      return [];
    }
  }

  get channels() {
    return this.resolveChannelsResponse.value || [];
  }

  @action
  channelActivities(id: string) {
    if (this.args.activities) {
      return this.args.activities[id];
    }

    return [];
  }
}
