/* import __COLOCATED_TEMPLATE__ from './organizer.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  MeetingEventClass,
  UserAttendeeClass,
} from 'teamtailor/classes/meetings';

interface Args {
  event: MeetingEventClass;
  refreshAvailability: () => void;
}

export default class MeetingEventRowsOrganizer extends Component<Args> {
  get event(): MeetingEventClass {
    return this.args.event;
  }

  get userAttendees(): UserAttendeeClass[] {
    return this.event.meetingEventAttendees.userAttendees;
  }

  @action
  toggleOrganizerAvailability(): void {
    this.userAttendees.forEach((userAttendee) => {
      if (
        this.event.organizer &&
        userAttendee.user?.id === this.event.organizer.user?.id
      ) {
        this.event.organizer.excludeAvailability =
          !this.event.organizer.excludeAvailability;
        userAttendee.excludeAvailability =
          this.event.organizer.excludeAvailability;
      }
    });

    this.args.refreshAvailability();
  }
}
