/* import __COLOCATED_TEMPLATE__ from './step-indicator.hbs'; */
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';

export default class StepIndicatorComponent extends Component {
  get classes() {
    return classnames(
      'step-indicator',
      `step-indicator--${this.args.currentStep}`
    );
  }
}
