/* import __COLOCATED_TEMPLATE__ from './hero-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class JobsHeroFormComponent extends Component {
  @service current;
  @service flipper;

  @tracked isModalOpen = false;

  get job() {
    return this.args.job;
  }

  get jobDetail() {
    return this.args.job.jobDetail;
  }

  get isDefaultLayout() {
    return this.jobDetail.imageWithSetting.coverLayoutType === 'default';
  }

  get selectedImage() {
    return get(this.jobDetail, 'imageWithSetting.pickedImage.image');
  }

  @action
  handlePickImage(image) {
    this.args.onPickImage(image);
    this.isModalOpen = false;
  }

  @action
  handleRemoveImage() {
    this.args.onRemoveImage();
  }
}
