/* import __COLOCATED_TEMPLATE__ from './step-indicator-step.hbs'; */
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';

export default class StepIndicatorStepComponent extends Component {
  get classes() {
    return classnames('step-indicator__step', {
      'step-indicator__step--active': this.args.step <= this.args.currentStep,
    });
  }
}
