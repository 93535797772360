/* import __COLOCATED_TEMPLATE__ from './skip-to-main-content-button.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import FocusTrapService from 'teamtailor/services/focus-trap';
import ModalService from 'teamtailor/services/modal';

type SkipToMainContentButtonSignature = {
  Args: {};
};

export default class SkipToMainContentButton extends Component<SkipToMainContentButtonSignature> {
  @service declare focusTrap: FocusTrapService;
  @service declare modal: ModalService;

  @action
  onClick(event: MouseEvent) {
    const target = event.currentTarget as HTMLButtonElement;
    const href = target.getAttribute('href');
    if (!href) return;

    let mainContentEl = document.querySelector(href) as HTMLElement | null;

    let topModalEl = this.modal.openModals.at(-1);
    if (topModalEl) {
      mainContentEl = topModalEl;
    }

    const mainElInMainContentEl = Array.from(
      mainContentEl?.querySelectorAll('main') || []
    ).at(-1);

    const elToFocus = mainElInMainContentEl || mainContentEl;

    if (elToFocus) {
      elToFocus.setAttribute('tabindex', '-1');
      event.preventDefault();
      elToFocus.focus();

      const onElFocusout = () => {
        elToFocus.removeAttribute('tabindex');
        elToFocus.removeEventListener('focusout', onElFocusout);
      };

      elToFocus.addEventListener('focusout', onElFocusout);
    }
  }
}
