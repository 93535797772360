/* import __COLOCATED_TEMPLATE__ from './requisition.hbs'; */
import { get } from 'teamtailor/utils/get';
import Base from './base';

export default class RequisitionComponent extends Base {
  get addedRequisitionUsers() {
    return get(this.activity, 'actionData.approval_flow_diff.added_users');
  }

  get removedRequisitionUsers() {
    return get(this.activity, 'actionData.approval_flow_diff.removed_users');
  }

  get localHideRequisitionName() {
    return (
      this.args.hideRequisitionName ||
      get(this.activity, 'code') === 'requisition_changed_recruiter'
    );
  }
}
