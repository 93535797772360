/* import __COLOCATED_TEMPLATE__ from './date-column.hbs'; */
import Component from '@glimmer/component';

type CandidatesDateColumnArgs = {
  date: Date | string;
  icon?: string;
};

export default class CandidatesDateColumnComponent extends Component<CandidatesDateColumnArgs> {
  get createdThisYear(): boolean {
    return this.args.date instanceof Date
      ? this.args.date.getFullYear() === new Date().getFullYear()
      : false;
  }
}
