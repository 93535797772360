import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    externalTitle: 'title',
    recruiter: { serialize: false },
    approvals: { serialize: false },
    inboxCount: { serialize: false },
    inProcessCount: { serialize: false },
    hiredCount: { serialize: false },
    rejectedCount: { serialize: false },
    humanStatus: { serialize: false },
    permalink: { serialize: false },
    pusherChannel: { serialize: false },
    templateCompany: { serialize: false },
    jobDetail: { serialize: 'records', deserialize: false },
    unreadApplications: { serialize: false },
    tags: { serialize: 'records', key: 'tag_list' },
    url: { serialize: false },
    userHasAccess: { serialize: false },
    defaultTemplate: { serialize: false },
    overdueJobApplication: { serialize: false },
    locations: { serialize: 'ids' },
    avatar: {
      serialize: 'records',
      key: 'avatar_attributes',
    },
  },

  serialize() {
    let json = { ...this._super(...arguments) };
    if (json.tag_list.length) {
      json.tag_list = json.tag_list.map((tag) => tag.name);
    }

    return json;
  },
});
