/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { filteredTabs } from 'teamtailor/utils/candidate-modal/tabs';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

interface Args {
  onTabChange: (tab: string) => void;
  currentTab: string;
}

export default class extends Component<Args> {
  @service declare current: Current;
  declare tabs;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.tabs = filteredTabs(this.current.company);
  }
}
