import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { setProperties } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import InfinityService from 'ember-infinity/addon/services/infinity';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';
import ContentIndexController from 'teamtailor/controllers/content';
import CareerSite from 'teamtailor/models/career-site';

export default class ContentIndexRoute extends Route {
  @service declare current: Current;
  @service declare infinity: InfinityService;
  @service declare router: RouterService;
  @service declare store: Store;

  queryParams = {
    view: { refreshModel: true },
    site: { refreshModel: true },
    query: { refreshModel: true },
  };

  async beforeModel({
    to: {
      queryParams: { site },
    },
  }: Transition) {
    if (this.current.company.hasFeature('career_site_languages') && !site) {
      await this._loadCareerSites();
      // @ts-expect-error ember docs says that the first arg has to be a string, does this work?
      this.router.replaceWith({
        queryParams: {
          site: get(get(this.current.company, 'defaultCareerSite'), 'id'),
        },
      });
    }
  }

  async model({
    site,
    view,
    query,
  }: {
    site: string;
    view: string;
    query: string;
  }) {
    const careerSite = await this.getCareerSite(site);

    const pages = this.infinity.model('page', {
      startingPage: 1,
      perPage: 25,
      career_site_id: site,
      view,
      query,
      no_connect: true,
    });

    return {
      careerSite,
      pages,
    };
  }

  setupController(...args: [ContentIndexController, any, Transition]) {
    super.setupController(...args);
    const { careerSite } = args[1];
    if (careerSite) {
      careerSite.belongsTo('pageCount').reload();
    }
  }

  resetController(controller: ContentIndexController, isExiting: boolean) {
    if (isExiting) {
      setProperties(controller, {
        view: null,
        query: null,
        site: null,
        showPublishChangesModal: false,
      });
    }
  }

  async getCareerSite(careerSiteId: string) {
    let careerSite: CareerSite | undefined | null;
    if (careerSiteId) {
      careerSite = this.store.peekRecord('career-site', careerSiteId);
      if (!careerSite) {
        careerSite = await this.store.findRecord('career-site', careerSiteId);
      }

      return careerSite;
    }

    await this._loadCareerSites();
    return this.current.company.defaultCareerSite;
  }

  async _loadCareerSites() {
    await this.current.company.careerSites;
  }
}
