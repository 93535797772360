/* import __COLOCATED_TEMPLATE__ from './support-link.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntercomService from 'teamtailor/services/intercom';
import { action } from '@ember/object';

export interface SupportLinkComponentSignature {
  Args: {
    subject?: string;
    message?: string;
  };
}

export default class SupportLinkComponent extends Component<SupportLinkComponentSignature> {
  @service
  declare intercom: IntercomService;

  get mailHref() {
    return `mailto:support@teamtailor.com?subject=${this.args.subject}`;
  }

  @action
  click(event: MouseEvent) {
    const message = this.args.message ? `${this.args.message}\n\n` : null;
    this.intercom.showNewMessage(message);
    event.preventDefault();
  }
}
