import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { ModelKey } from 'teamtailor/models/index';

export default class ResolveModelHelper extends Helper {
  @service declare store: Store;

  compute([modelName, modelId]: [string, string]) {
    if (!modelName || !modelId) {
      return;
    }

    const peekedRecord = this.store.peekRecord(modelName as ModelKey, modelId);

    return (
      peekedRecord || this.store.findRecord(modelName as ModelKey, modelId)
    );
  }
}
