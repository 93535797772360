/* import __COLOCATED_TEMPLATE__ from './todos.hbs'; */
import WidgetClass from './base/widget-class';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import fade from 'ember-animated/transitions/fade';

export default class TodosWidget extends WidgetClass {
  @service flashMessages;
  @service intl;
  @service confetti;
  @service router;
  @service store;
  @service flipper;
  @service todoCounters;
  @service pusher;
  @service current;

  @tracked initialLoad = true;
  @tracked todos = [];
  @tracked totalPages = null;
  @tracked shouldShowDailyProgress = false;
  @tracked editingTodo = null;

  fade = fade;

  perPage = 5;
  page = 0;

  lastFetchTodoTask = null;

  get hasTodos() {
    return this.todos?.length > 0;
  }

  get hasMoreTodos() {
    return this.todoCounters.totalTodos > this.todos.length;
  }

  fetchTodos = restartableTask(async () => {
    let page = 1;
    let todos = await this.store.query('todo', {
      per_page: this.perPage,
      page,
    });

    todos.toArray().forEach((todo) => {
      if (this.todos.filter((t) => t.id === todo.id).length === 0) {
        this.todos.pushObject(todo);
      }
    });

    this.totalPages = todos.meta.total_pages;
    this.todoCounters.setCountersFromMeta(todos.meta);

    if (this.initialLoad) {
      this.shouldShowDailyProgress = this.todoCounters._dueTodayCount > 0;
    }

    this.initialLoad = false;

    return todos;
  });

  get sortedTodos() {
    const todos = this.todos.filter(
      (todo) => !todo.completed || todo.completedRecently
    );
    if (
      todos.length <= Math.ceil(this.perPage / 2) &&
      todos.length < this.todoCounters.totalDue &&
      this.totalPages > 1
    ) {
      this.lastFetchTodoTask = this.fetchTodos.perform();
    }

    const sortedTodosWithDueDates = todos.rejectBy('due', null).sortBy('due');
    const todosWithoutDueDates = todos.filterBy('due', null);

    return [...sortedTodosWithDueDates, ...todosWithoutDueDates];
  }

  willDestroy() {
    super.willDestroy();
    this.teardownPusher();
    if (this.lastFetchTodoTask?.isRunning) {
      this.lastFetchTodoTask.cancel();
    }
  }

  @action handleClick(task) {
    const candidateId = task.candidate.id;

    return this.router.transitionTo('candidates.candidate', candidateId, {
      queryParams: { tab: 'todo-feed' },
    });
  }

  @action handleToggle(todo) {
    todo.toggleCompletion();
    if (todo.dueGroup === 'today' && this.dueTodayCount === 0) {
      this.confetti.show();
      later(() => {
        this.shouldShowDailyProgress = false;
      }, 4500);
    }
  }

  @action
  deleteTodo(todo) {
    return todo
      .destroyRecord()
      .then((deletedTodo) => {
        this.todos = this.todos.filter((todo) => todo.id !== deletedTodo.id);
      })
      .catch((e) => {
        this.onError();
        return e;
      });
  }

  @action editTodo(todo) {
    this.editingTodo = todo;
  }

  @action saveTodo({ value, description, due, assignee, candidate }) {
    this.editingTodo.value = value;
    this.editingTodo.description = description;
    this.editingTodo.due = due;
    this.editingTodo.assignee = assignee;
    this.editingTodo.candidate = candidate;
    return this.editingTodo
      .save()
      .then(() => {
        this.todoCounters.refresh();
      })
      .catch((e) => {
        this.onError();
        return e;
      });
  }

  @action
  setDueDate(todo, dueDate) {
    return todo.setDueDate(dueDate).catch((e) => {
      this.onError();
      return e;
    });
  }

  onError() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  setupPusher() {
    this.pusher
      .subscribe(this.current.company.candidatesChannel)
      .then((channel) => {
        this.channel = channel;
        channel.bind('demo-data-updated', () => {
          this.lastFetchTodoTask = this.fetchTodos.perform();
        });
      });
  }

  @action
  teardownPusher() {
    if (this.channel) {
      this.channel.unbind('demo-data-updated');
    }
  }

  @action
  handleInsert() {
    this.setupPusher();
    this.lastFetchTodoTask = this.fetchTodos.perform();
  }
}
