/* import __COLOCATED_TEMPLATE__ from './styleguide.hbs'; */
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service iframePreview;

  get containerClass() {
    return classnames('container', {
      show: this.args.isOpen === true,
      hide: this.args.isOpen === false,
    });
  }
}
