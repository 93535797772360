/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { animate, beforeAfter } from 'teamtailor/ember-smooth';
import { argDefault } from 'teamtailor/utils/arg-default';

interface CoreModalMainArgs {
  isScrollable?: boolean;
  isLoading?: boolean;
  padding?: boolean;
}

export default class CoreModalMain extends Component<CoreModalMainArgs> {
  @argDefault isScrollable = true;
  @argDefault padding = true;

  @tracked _isLoading? = this.args.isLoading;

  @action async handleIsLoadingChange(el: HTMLElement) {
    if (!!this.args.isLoading !== !!this._isLoading) {
      const { before, after } = await beforeAfter(
        el,
        'height',
        () => (this._isLoading = !!this.args.isLoading)
      );

      await animate(el, [{ flexBasis: before }, { flexBasis: after }], {
        setStylesAfter: false,
      });
    }
  }
}
