/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import CandidateModel from 'teamtailor/models/candidate';
import { isPresent } from '@ember/utils';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { ReferenceModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

interface Args {
  recommendation: ReferenceModel;
  candidate: CandidateModel;
}

export default class extends Component<Args> {
  @service declare intl: IntlService;
  @service declare current: Current;

  get badgeColor() {
    if (this.recommendation.endorsed !== null) {
      return this.recommendation.endorsed ? 'green' : 'red';
    }

    if (this.recommendation.feedbackRequestedAt) {
      return this.waitingFeedback ? 'amber' : 'light-blue';
    }

    return 'zinc';
  }

  get badgeIcon() {
    if (this.recommendation.endorsed !== null) {
      return this.recommendation.endorsed ? 'thumbs-up' : 'thumbs-down';
    }

    if (this.recommendation.feedbackRequestedAt) {
      return this.waitingFeedback ? 'ellipsis' : 'check';
    }

    return 'address-card';
  }

  get waitingFeedback() {
    return (
      this.recommendation.feedbackRequestedAt &&
      !isPresent(this.recommendation.fullFeedbackAt)
    );
  }

  get referenceForm() {
    return get(this.recommendation, 'referenceForm');
  }

  get sortedFormAnswers() {
    return get(this.referenceForm, 'formAnswers');
  }

  get formAnswers() {
    return get(this.referenceForm, 'formAnswers');
  }

  get recommendation() {
    return this.args.recommendation;
  }
}
