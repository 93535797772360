/* import __COLOCATED_TEMPLATE__ from './delete.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { dropTask } from 'ember-concurrency';

export default class extends Component {
  @tracked showMoveRejectedCandidates = false;
  @tracked isMarkedAsProceedStageIn = [];

  get canBeDeleted() {
    if (this.stage.isNew) {
      return true;
    }

    if (!this.checkStageRemovability.lastSuccessful) {
      this.checkStageRemovability.perform();
      return false;
    }

    return (
      this.isMarkedAsProceedStageIn.length === 0 &&
      !this.stage.hasActiveJobApplications
    );
  }

  get onlyRejectedCandidatesInStage() {
    return (
      this.stage.hasRejectedJobApplications &&
      !this.stage.hasActiveJobApplications
    );
  }

  get stage() {
    return this.args.stage;
  }

  isModelUsingThisAsProceedStage(modelWithProceedStage) {
    const proceedStageId = modelWithProceedStage.belongsTo('proceedStage').id();
    return proceedStageId === this.stage.id;
  }

  isAnyMoveUsingThisAsProceedStage(trigger) {
    return get(trigger, 'moves').some((move) =>
      this.isModelUsingThisAsProceedStage(move)
    );
  }

  checkIfProceedStageInTrigger(trigger) {
    return get(trigger, 'type') === 'smart-move'
      ? this.isAnyMoveUsingThisAsProceedStage(trigger)
      : this.isModelUsingThisAsProceedStage(trigger);
  }

  checkIsMarkedAsProceedStage() {
    const triggers = get(this.stage, 'job.stages')
      .map((stage) => {
        const triggers = [
          ...get(stage, 'triggerPredictions').toArray(),
          ...get(stage, 'triggerSmartMoves').toArray(),
          ...get(stage, 'triggerSmartSchedules').toArray(),
          ...get(stage, 'triggerWebhooks').toArray(),
        ];

        return triggers;
      })
      .flat();

    this.isMarkedAsProceedStageIn = triggers.filter((trigger) =>
      this.checkIfProceedStageInTrigger(trigger)
    );
  }

  checkStageRemovability = dropTask(async () => {
    this.checkIsMarkedAsProceedStage();
    await this.stage.triggerApplicationsFetchForDeleteStatusCheck();
  });
}
