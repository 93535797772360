/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FilterButtonsList extends Component {
  @tracked showLeftFade = false;

  scrollElement = null;
  scrollLeftMax = null;

  handleScroll = () => {
    if (this.scrollElement.scrollLeft && !this.showLeftFade) {
      this.showLeftFade = true;
    } else if (!this.scrollElement.scrollLeft && this.showLeftFade) {
      this.showLeftFade = false;
    }
  };

  @action
  handleInsertScrollElement(element) {
    this.scrollElement = element;
    this.scrollLeftMax = element.scrollWidth - element.offsetWidth;
    element.addEventListener('scroll', this.handleScroll);
  }

  @action
  handleFilter(e, [isSelected]) {
    if (isSelected) {
      e.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }
}
