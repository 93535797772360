/* import __COLOCATED_TEMPLATE__ from './inner-wrapper.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { animate } from 'teamtailor/ember-smooth';

export default class SidebarInnerWrapperComponent extends Component {
  @action
  async in(element) {
    if (this.args.doFadeInOut) {
      await animate.from(
        element,
        {
          transform: 'translateY(5px)',
          opacity: 0,
        },
        400
      );
    }
  }
}
