/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';
import {
  TABLE_COLUMNS,
  LOADING_COLUMNS,
} from 'teamtailor/constants/nurture-campaign-recipients';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class NurtureCampaignReccipientsTableComponent extends Component {
  @argDefault sortDirection = null;
  columns = TABLE_COLUMNS;
  loadingColumns = LOADING_COLUMNS;
  candidateRoute = 'nurture-campaigns.nurture-campaign.recipients.candidate';

  get oppositeSortDirection() {
    return this.sortDirection === 'desc' ? 'asc' : 'desc';
  }
}
