/* import __COLOCATED_TEMPLATE__ from './layouts.hbs'; */
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Section from 'teamtailor/models/section';
import IntlService from 'ember-intl/services/intl';
import { next } from '@ember/runloop';

import Base, { BlockLayoutCategory } from './base';

interface Args {
  onClose(): void;
  newSection(params: {
    blockName: string;
    layoutName?: string;
    parentBlockId?: string;
    position?: string;
  }): Promise<Section>;
  isOpen?: boolean;
  sectionTypesAvailable: string[];
}

const featuredBlockLayouts: string[] = [
  'VI03',
  'LI03',
  'TM04',
  'GL01',
  'NB01',
  'FA01',
  'TM02',
  'GR01',
  'TL04',
];

export default class BlockLibraryModalLayouts extends Base<Args> {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  @tracked globalBlocks: Section[] = [];
  @tracked declare selectedCategory: string;
  @tracked searchInput = '';
  @tracked searchTerm = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.getGlobalBlocks();
  }

  async getGlobalBlocks(): Promise<void> {
    this.globalBlocks = (
      await this.store.query('section', { global: true })
    ).toArray();
  }

  get featuredBlockLayouts(): BlockLayoutCategory {
    const blockLayouts = this.blockLayouts
      .filter((blockLayout) =>
        featuredBlockLayouts.includes(blockLayout.displayName)
      )
      .sort(
        (a, b) =>
          featuredBlockLayouts.indexOf(a.displayName) -
          featuredBlockLayouts.indexOf(b.displayName)
      );

    return {
      name: 'featured',
      translatedName: this.intl.t(
        'components.content_block.editor.block_library.featured'
      ),

      blockLayouts,
    };
  }

  get filteredFeaturedBlockLayouts(): BlockLayoutCategory {
    if (this.searchTerm) {
      return {
        ...this.featuredBlockLayouts,
        blockLayouts: this.featuredBlockLayouts.blockLayouts.filter(
          ({ blockName }) =>
            blockName.toLowerCase().includes(this.searchTerm.toLowerCase())
        ),
      };
    }

    return this.featuredBlockLayouts;
  }

  get filteredGlobalBlocks(): Section[] {
    if (this.searchTerm) {
      return this.globalBlocks.filter((globalBlock) =>
        globalBlock.prettyName
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
    }

    return this.globalBlocks;
  }

  get filteredBlockLayoutCategories(): BlockLayoutCategory[] {
    if (this.searchTerm) {
      const searchTerm = this.searchTerm.toLowerCase();

      const matchingBlockLayouts = this.blockLayouts.filter(({ displayName }) =>
        displayName.toLowerCase().includes(searchTerm)
      );

      const matchingBlockLayoutCategories = this.blockLayoutCategories
        .filter(({ translatedName }) =>
          translatedName.toLowerCase().includes(searchTerm)
        )
        .map(({ name }) => name);

      return this.blockLayoutCategories.filter((category) => {
        const categoryMatch = matchingBlockLayoutCategories.includes(
          category.name
        );
        const blockLayoutMatch = matchingBlockLayouts.some((blockLayout) =>
          category.blockLayouts.includes(blockLayout)
        );

        if (blockLayoutMatch) {
          category.blockLayouts = category.blockLayouts.filter(
            ({ displayName }) => displayName.toLowerCase().includes(searchTerm)
          );
          return true;
        }

        return categoryMatch;
      });
    }

    return this.blockLayoutCategories;
  }

  get showEmptyState(): boolean {
    return (
      !this.filteredFeaturedBlockLayouts.blockLayouts.length &&
      !this.filteredBlockLayoutCategories.length &&
      !this.filteredGlobalBlocks.length
    );
  }

  resetAndClose() {
    this.selectedCategory = '';
    this.args.onClose();
  }

  @action
  previewLayout(layoutName: string) {
    this.router.transitionTo(
      'content.editor.block-library.block-layout',
      layoutName,
      {
        queryParams: {
          position: this.router.currentRoute.queryParams.position,
        },
      }
    );
  }

  @action
  async addNewBlock(blockName: string, layoutName: string): Promise<void> {
    await this.args.newSection({
      blockName,
      layoutName,
      position: this.router.currentRoute.queryParams.position,
    });
    this.selectedCategory = '';
  }

  @action
  async addNewGlobalBlock(globalBlock: Section): Promise<void> {
    await this.args.newSection({
      blockName: 'linked',
      parentBlockId: globalBlock.id,
      position: this.router.currentRoute.queryParams.position,
    });
    this.selectedCategory = '';
  }

  @action
  handleSearch(searchInput: string) {
    next(() => {
      this.searchTerm = searchInput;
    });
  }

  @action
  handleSearchOnKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab' && this.searchTerm) {
      const firstResult = document.querySelector<HTMLButtonElement>(
        '#block-layouts button'
      );
      if (firstResult) {
        firstResult.focus();
        event.preventDefault();
      }
    }
  }

  @action
  handleClose() {
    this.resetAndClose();
  }

  @action
  onOpen() {
    this.getGlobalBlocks();
  }
}
