/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
import Component from '@glimmer/component';
import { set, get, setProperties, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { later } from '@ember/runloop';

export default class StageContent extends Component {
  @service confetti;
  @service dragSort;
  @service store;
  @service intl;
  @service stageListHelper;
  @service permissions;
  @service flipper;
  @service stageDrag;

  @tracked showStoreRequisitionJobsModal = false;
  @tracked _displayLoadingState = false;

  @tracked isShowingRestrictCandidateConfirmation = false;
  @tracked restrictCallback = null;
  @tracked projectedItemsCount = 3;

  @tracked stageDropListEl = null;
  @tracked ps;
  @tracked allLoaded = false;

  candidateIdsCache = null;

  virtualCollection = null;

  @computedLocalStorage(Boolean, 'hideRestrictionWarningKey', false)
  hideRestrictionWarning;

  constructor() {
    super(...arguments);

    if (this.args.registerInstance) {
      this.unregisterInstance = this.args.registerInstance(this);

      registerDestructor(this, () => {
        if (this.unregisterInstance) this.unregisterInstance();
      });
    }
  }

  get hideRestrictionWarningKey() {
    return `hideRestrictionWarning-Job-${this.args.stage.jobId}`;
  }

  get showAlerts() {
    return (
      !this.args.showRejected &&
      (this.args.hasOverdueCandidates || this.args.isPastMilestone)
    );
  }

  get requisition() {
    return get(this.args, 'stage.job.requisition');
  }

  get isCompetenceBased() {
    return get(this.args, 'stage.job.jobDetail.competenceBased');
  }

  get filteredJobApplications() {
    const nonDeleted = this.args.filteredJobApplications
      .filter((jobApplication) => {
        return get(jobApplication, 'isDeleted') === false;
      })
      .filter((jobApplication) => {
        return get(jobApplication, 'aboardOnboardingId') == null;
      });

    const filterProperty = this.args.showRejected ? 'rejected' : 'active';
    const countProperty = this.args.filteredCandidateIds
      ? 'JobApplicationsCount'
      : 'Count';

    const aboardOffset = this.args.filteredJobApplications.filter(
      (jobApplication) => {
        return get(jobApplication, 'aboardOnboardingId') != null;
      }
    ).length;

    let totalCount = get(this.args.stage, filterProperty + countProperty) || 0;

    totalCount -= aboardOffset;

    return nonDeleted.length > 0
      ? Array(parseInt(totalCount, 10))
          .fill(null)
          .map((_, i) => nonDeleted[i] ?? `placeholder_${i}`)
      : [];
  }

  get displayLoadingState() {
    return (
      this._displayLoadingState ||
      (this.args.stage.fetchJobApplicationsTask.isRunning &&
        this.args.filteredJobApplications.length === 0)
    );
  }

  set displayLoadingState(value) {
    this._displayLoadingState = value;
  }

  updateJobApplicationAnonymity(
    jobApplication,
    targetIndex,
    fromAnonymous,
    toStage
  ) {
    const toAnonymous = get(toStage, 'anonymous');
    if (fromAnonymous !== toAnonymous) {
      if (fromAnonymous) {
        this.stageListHelper.lastMovedJobApplicationId = get(
          jobApplication,
          'id'
        );
      }

      this.stageListHelper.onNextStageLastVisibleChanged(
        get(toStage, 'id'),
        () => {
          const data = {
            jobApplicationId: get(jobApplication, 'id'),
            stageId: get(toStage, 'id'),
            targetIndex,
            fromAnonymous,
            toAnonymous,
          };

          this.stageListHelper.animationQueue.addObject({
            data,
            type: 'avatar',
          });
          this.stageListHelper.animationQueue.addObject({
            data,
            type: 'textScramble',
          });
        }
      );
    }
  }

  async updateJobApplication(
    jobApplication,
    toStage,
    sameStage,
    sourceList,
    sourceIndex,
    rowOrder,
    rowOrderPosition,
    stageType
  ) {
    let stageRelationName = this.args.showRejected
      ? 'rejectedStage'
      : 'activeStage';

    const jobApplicationProperties = {
      stage: toStage,
      rowOrder,
      rowOrderPosition,
    };
    jobApplicationProperties[stageRelationName] = toStage;

    setProperties(jobApplication, jobApplicationProperties);

    const toStageContentInstance =
      this.args.stageListInstances[get(toStage, 'id')];

    if (sameStage) {
      sourceList.removeAt(sourceIndex);
    } else {
      if (toStage.sortValue !== 'rowOrder') {
        toStageContentInstance.setProjectedItemsCount();
        toStageContentInstance.displayLoadingState = true;
      }

      this.args.stage.decrementCount(this.args.showRejected);
      toStage.incrementCount(this.args.showRejected);
      set(jobApplication, 'lastMovedAt', new Date());
    }

    await jobApplication.save();

    if (!sameStage) {
      const overdue = get(this.args, 'stage.job.overdueJobApplication');
      if (overdue.isFulfilled) {
        overdue.reload();
      }

      this.store.findRecord('stage-job-application-count', this.args.stage.id);
      this.store.findRecord('stage-job-application-count', toStage.id);

      if (toStage.sortValue !== 'rowOrder') {
        if (toStageContentInstance.projectedItemsCount > 1) {
          toStage.fetchJobApplicationsTask.perform({
            rejected: this.args.showRejected,
            reload: true,
          });
        }

        toStageContentInstance.displayLoadingState = false;
      }

      if (get(toStage, 'hired') && stageType !== 'aboard') {
        await this.requisition?.reload();
        if (
          get(this, 'requisition.hires.length') >=
            get(this, 'requisition.numberOfOpenings') &&
          get(this, 'requisitionOpenJobs.length') > 0
        ) {
          set(this, 'showStoreRequisitionJobsModal', true);
        }

        this.confetti.show();
      }
    }
  }

  setProjectedItemsCount() {
    this.projectedItemsCount = this.virtualCollection.items.length;
  }

  candidateIdsChanged(a, b) {
    if (a === null && b === null) {
      return false;
    }

    if (a === null || b === null) {
      return true;
    }

    return !(a.length === b.length && a.every((e, i) => e === b[i]));
  }

  @action
  updateScroller() {
    if (this.ps) {
      this.ps.update();
    }

    later(() => {
      if (this.ps) {
        this.ps.update();
      }
    }, 500);
  }

  @action
  handleRegisterVerticalCollection(virtualCollection) {
    this.virtualCollection = virtualCollection;
    this.stageDropListEl = virtualCollection.element;
  }

  @action
  handleLastVisibleChanged(...args) {
    this.stageListHelper.triggerStageLastVisibleChanged(
      get(args[0], 'stage.id'),
      args
    );
  }

  @action
  handleDragEnd({
    draggedItem: jobApplication,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex: rowOrderPosition,
  }) {
    let toStage = get(this, 'dragSort.targetListModel');
    let targetElement = get(this, 'dragSort.targetListElement');

    const stageType =
      targetElement.parentElement.getAttribute('data-stage-type');

    let sameStage = sourceList === targetList;
    let upwards = rowOrderPosition < sourceIndex;
    let rowOrder = 0;
    let jobApplicationSibling = targetList[rowOrderPosition];

    // fromAnonymous has to get fetched before jobApplication is updated
    const fromAnonymous = get(jobApplication, 'activeStage.anonymous');

    if (stageType === 'aboard') {
      if (!(get(jobApplication.stage, 'stageType').category === 'hired')) {
        return;
      }

      this.stageDrag.jobApplication = jobApplication;
      this.stageDrag.aboardModalOpen = true;
    }

    if (sameStage) {
      if (this.args.sortObject.value !== 'rowOrder') return;
      if (jobApplicationSibling) {
        rowOrder = jobApplicationSibling.rowOrder + (upwards ? -1 : 1);
      }
    } else {
      if (jobApplicationSibling) {
        rowOrder = jobApplicationSibling.rowOrder - 1;
      } else if (
        rowOrderPosition === targetList.length &&
        targetList.length > 0
      ) {
        rowOrder = get(targetList[targetList.length - 1], 'rowOrder') + 1;
      }
    }

    let showWarningBeforeSave =
      !this.hideRestrictionWarning &&
      toStage.triggerRestricts.length &&
      !get(jobApplication, 'candidate.restricted') &&
      !sameStage &&
      !this.permissions.has('candidate/access_restricted');

    let updateCandidate = () => {
      targetList.insertAt(rowOrderPosition, jobApplication);

      if (rowOrderPosition === 0) {
        this.stageListHelper.onNextStageLastVisibleChanged(
          get(toStage, 'id'),
          () => {
            let element = targetElement;
            if (element) element.scrollTop = 0;
          }
        );
      }

      this.updateJobApplication(
        jobApplication,
        toStage,
        sameStage,
        sourceList,
        sourceIndex,
        rowOrder,
        rowOrderPosition,
        stageType
      );

      this.updateJobApplicationAnonymity(
        jobApplication,
        rowOrderPosition,
        fromAnonymous,
        toStage
      );
    };

    if (showWarningBeforeSave) {
      this.isShowingRestrictCandidateConfirmation = true;
      this.restrictCallback = updateCandidate;
    } else {
      updateCandidate();
    }
  }

  @action
  confirmCandidateRestriction() {
    this.restrictCallback();
    this.restrictCallback = null;
    this.isShowingRestrictCandidateConfirmation = false;
  }

  @action
  handleRegisterPerfectScrollbar(ps) {
    this.ps = ps;
  }

  @action
  maybeFetchMoreJobApplications() {
    if (
      this.candidateIdsChanged(
        this.candidateIdsCache,
        this.args.filteredCandidateIds
      )
    ) {
      this.candidateIdsCache = this.args.filteredCandidateIds;
      this.fetchMoreApplications();
    }
  }

  @action
  fetchMoreApplications() {
    this.args.stage.fetchJobApplicationsTask.perform({
      rejected: this.args.showRejected,
      reload: false,
      filter: this.args.params,
    });
  }

  @action
  maybeLoadApplications() {
    if (this.args.stage.fetchJobApplicationsTask.performCount === 0) {
      this.fetchMoreApplications();
    }
  }

  @action
  handleLastReached() {
    if (
      this.args.stage.fetchJobApplicationsTask.performCount > 0 &&
      !this.args.stage.fetchJobApplicationsTask.isRunning
    ) {
      this.fetchMoreApplications();
    }
  }
}
