/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { run } from '@ember/runloop';

export default class extends Component {
  @tracked
  selectedColor = this.args.displayValue;

  @action
  onPickerChange(color) {
    run(() => {
      const hexa = color.toHEXA().toString().toLowerCase();
      if (hexa !== this.selectedColor) {
        this.selectedColor = hexa;
      }
    });
  }

  @action
  applySelectedColor() {
    run(() => {
      this.args.onChange(this.selectedColor);
    });
  }

  @action
  setTransparent() {
    this.selectedColor = 'transparent';
    this.applySelectedColor();
  }
}
