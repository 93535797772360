/* import __COLOCATED_TEMPLATE__ from './scheduled-message-dropdown-options.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';

export default class ScheduledMessageDropdownOptionsComponent extends Component {
  @tracked customDate = null;

  get threeHoursFromNow() {
    return moment().add(3, 'hour').format('dddd Do, Ha');
  }

  get timeTomorrow() {
    return moment().add(1, 'day').hour(9).format('dddd Do, Ha');
  }

  get nextWeek() {
    return moment().add(1, 'week').day(1).hour(9).format('dddd Do, Ha');
  }

  get momentNow() {
    return moment();
  }

  get minDate() {
    return this.momentNow.startOf('day');
  }

  get defaultHour() {
    return this.momentNow.hour();
  }

  get defaultMinute() {
    return this.momentNow.minute();
  }

  @action
  onCustomDateSubmit() {
    this.args.handleAction(this.customDate);
  }
}
