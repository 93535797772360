/* import __COLOCATED_TEMPLATE__ from './reassign.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { later } from '@ember/runloop';

export default class ToolboxMessagesModalsReassignComponent extends Component {
  get conversation() {
    return this.args.conversation;
  }

  @action
  save() {
    return this.conversation.save().then(() => {
      later(() => {
        this.args.onConversationReassigned(this.conversation);
        this.args.onClose();
      }, 500);
    });
  }
}
