/* import __COLOCATED_TEMPLATE__ from './copilot-feature-setting-card.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import PusherService from 'teamtailor/services/pusher';
import CurrentService from 'teamtailor/services/current';

interface CopilotFeatureSettingCardSignature {
  Args: {
    releaseDate?: string;
    icon: string;
    title: string;
    description: string;
    checked: boolean;
    handleChange: () => void;
    feature?: string;
  };
}
export default class CopilotFeatureSettingCard extends Component<CopilotFeatureSettingCardSignature> {
  @service declare pusher: PusherService;
  @service declare current: CurrentService;

  handleChange() {
    // This function does nothing since we want to ignore the <LabelSwitch> behaviour
  }

  get showNewBadge() {
    if (this.args.releaseDate) {
      const releaseDate = moment(this.args.releaseDate);
      const threeMonthsAgo = moment().subtract(3, 'months');
      return releaseDate.isAfter(threeMonthsAgo);
    }
  }
}
