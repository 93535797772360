/* import __COLOCATED_TEMPLATE__ from './requisition-archive-reason.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import TtAlertService from 'teamtailor/services/tt-alert';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { RequisitionArchiveReasonModel } from 'teamtailor/models';

interface RequisitionArchiveReasonComponentArgs {
  model: RequisitionArchiveReasonModel;
}

export default class RequisitionArchiveReasonComponent extends Component<RequisitionArchiveReasonComponentArgs> {
  @service declare ttAlert: TtAlertService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get model() {
    return this.args.model;
  }

  @action
  deleteArchiveReason() {
    this.ttAlert.customConfirm({
      title: this.intl.t(
        'components.settings.requisition_archive_reason.delete_requisition_archive_reason'
      ),

      text: this.intl.t(
        'components.settings.requisition_archive_reason.are_you_sure',
        {
          reason: get(this.model, 'reason'),
        }
      ),

      confirmButtonText: this.intl.t('common.delete'),
      confirmButtonClass: 'btn-danger',
      cancelButtonText: this.intl.t('common.no'),
      confirmCallback: async () => {
        try {
          await this.model.destroyRecord();
          this.flashMessages.success(
            this.intl.t(
              'components.settings.requisition_archive_reason.deleted_requisition_archive_reason'
            )
          );
        } catch {
          this.flashMessages.error(
            this.intl.t(
              'components.settings.requisition_archive_reason.cant_delete_if_used'
            )
          );
        }
      },

      cancelCallback: () => {},
    });
  }
}
