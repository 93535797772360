/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const ITEM_TYPES = [
  'facebook',
  'github',
  'instagram',
  'linkedin',
  'pinterest',
  'tiktok',
  'twitter',
  'vimeo',
  'youtube',
];

const ICON_MAP = {
  facebook: 'facebook-square',
  link: 'link-simple',
  twitter: 'x-twitter',
};

export default class SocialLinksBaseComponent extends BaseComponent {
  @service store;

  @tracked draggingEnabled = false;

  get section() {
    return this.args.model;
  }

  get availableTypes() {
    return ITEM_TYPES.filter(
      (type) => !this.selectedLinkTypes.includes(type)
    ).concat(['link']);
  }

  get selectedLinkTypes() {
    return get(this, 'section.sectionItems').mapBy('itemType');
  }

  async addSectionItem(itemType) {
    await this.store.createRecord('section-item', {
      section: this.args.model,
      itemType,
    });
  }

  icon(type) {
    return ICON_MAP[type] || type;
  }

  iconStyle(type) {
    return type === 'link' ? 'regular' : 'brands';
  }

  @action
  handleNewSectionItem(itemType) {
    this.addSectionItem(itemType);
  }
}
