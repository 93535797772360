/* import __COLOCATED_TEMPLATE__ from './nps-responses-row.hbs'; */
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { NpsRow } from 'teamtailor/classes/analytics/report-nps-overview';
import { scoreTextColorClass } from 'teamtailor/utils/nps';

type Args = {
  data: NpsRow;
};

export default class NpsResponsesRow extends Component<Args> {
  @service declare store: Store;
  get score() {
    return this.args.data.npsResponseScore;
  }

  get scoreTextColorClass() {
    return scoreTextColorClass(this.score);
  }

  get candidate() {
    return this.args.data.candidate;
  }
}
