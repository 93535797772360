/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

const SORT_OPTIONS = [
  {
    name: 'due',
    translation: 'by_due_date',
  },
  {
    name: 'title',
    translation: 'by_title',
  },
  {
    name: 'candidate',
    translation: 'by_candidate',
  },
];

export default class extends Component {
  @service router;
  sortOptions = SORT_OPTIONS;

  @tracked isMoreDropdownOpened = false;

  get currentSorting() {
    return this.args.selectedSortingOrder || 'due';
  }

  get currentSortingOption() {
    return this.sortOptions.find((v) => v.name === this.currentSorting);
  }

  @action
  toggleCompleted() {
    this.router.transitionTo(this.router.currentRouteName, {
      queryParams: {
        toolbox: [
          'todos',
          this.args.selectedScopeFilter,
          this.currentSorting,
          this.args.showCompleted === 'show-completed' ? '' : 'show-completed',
        ],
      },
    });
  }

  @action
  dropdownOpened(dropdown) {
    this[dropdown] = true;
  }

  @action
  dropdownClosed(dropdown) {
    this[dropdown] = false;
  }
}
