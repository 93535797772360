/* import __COLOCATED_TEMPLATE__ from './custom-form-data-row.hbs'; */
import Component from '@glimmer/component';

export default class CustomFormDataRow extends Component {
  get shouldShow() {
    const field = this.args.customField;
    if (field.show_if) {
      return this.extractAnswerValues.includes(field.show_if.value);
    } else {
      return true;
    }
  }

  get extractAnswerValues() {
    const answers = this.args.customFieldAnswers;

    return Object.values(answers).flatMap((value) => {
      if (Array.isArray(value)) {
        return value.map((item) => {
          if (item && typeof item === 'object' && 'id' in item) {
            return item.id;
          }

          return item;
        });
      }

      return [value];
    });
  }
}
