/* import __COLOCATED_TEMPLATE__ from './toolbar.hbs'; */
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { GlobalCollaborateMessageModel } from 'teamtailor/models';

interface JobsCollaborateItemArgs {
  model: ActivityModel;
  destroyActivity: (
    model: ActivityModel | GlobalCollaborateMessageModel
  ) => void;
  addReaction: (model: ActivityModel) => void;
  pinActivity: (model: ActivityModel) => void;
  toggleEdit: (model: ActivityModel) => void;
  isEditing: boolean;
  channel?: string;
}

export default class JobsCollaborateItemToolbar extends Component<JobsCollaborateItemArgs> {
  @service declare current: Current;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  get isSameUser() {
    return this.current.user.id === get(this.args.model.user, 'id');
  }

  get canEditComment() {
    return this.isSameUser && !this.args.channel;
  }

  @action
  handleDeleteClick() {
    this.ttAlert.confirm(
      this.intl.t('components.note_feed_item.confirm_delete'),
      () => {
        this.args.destroyActivity(this.args.model);
      }
    );
  }
}
