/* import __COLOCATED_TEMPLATE__ from './job-item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

interface Args {
  value: { title: string };
}

export default class MeetingFieldsJobItem extends Component<Args> {
  @service declare current: Current;

  get titleFirstLetter() {
    return this.args.value.title ? this.args.value.title.substring(0, 1) : '';
  }

  get companyColor() {
    const design = get(this.current.company, 'design');
    return get(design, 'color');
  }
}
