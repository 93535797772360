/* import __COLOCATED_TEMPLATE__ from './export.hbs'; */
import BaseComponent from './base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { findMatchingSelectedColumnRecord } from 'teamtailor/utils/candidate-table/column';

export default class ExportComponent extends BaseComponent {
  @service flashMessages;
  @service current;

  get user() {
    return this.current.user;
  }

  @action
  exportCandidates() {
    return this.postAction(
      'bulk_export',
      this.postData(
        this.args.sortedColumns
          ? {
              selectedColumnIds: this.args.sortedColumns
                .map((column) => {
                  let selectedColumnRecords = get(
                    this.user,
                    'selectedCandidateDisplayColumns'
                  ).toArray();

                  const selectedColumnRecordMatchingName =
                    findMatchingSelectedColumnRecord(
                      column,
                      selectedColumnRecords
                    );

                  return get(selectedColumnRecordMatchingName, 'id');
                })
                .filter(Boolean),
            }
          : {}
      )
    ).then(() => {
      this.args.onClose();
      this.flashMessages.success(
        this.intl.t(
          'components.bulk_actions.candidates.exported_candidates_count',
          { count: this.args.selectedCount }
        )
      );
    });
  }
}
