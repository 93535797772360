import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

export default class JobsIndexJobsListCopyRoute extends Route {
  @service declare store: Store;

  async model(params: { job_id: string }) {
    let job = this.store.peekRecord('job', params.job_id);

    if (!job) {
      job = await this.store.findRecord('job', params.job_id);
    }

    return job;
  }
}
