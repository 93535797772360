/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { cancel, scheduleOnce } from '@ember/runloop';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';

interface Args {
  title: string;
  id: string;
}

export default class extends Component<Args> {
  @tracked declare open: boolean;
  @tracked shouldRender = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const toggleRender = () => {
      // Render all sections outside viewport when everything else finished rendering
      this.shouldRender = true;
    };

    const runScheduleOnce = scheduleOnce('afterRender', null, toggleRender);

    registerDestructor(this, () => {
      cancel(runScheduleOnce);
    });

    const currentlyFocused = window.location.hash.slice(1) === this.args.id;
    const previouslyOpened = !localStorage.getItem(this.localStorageKey);

    this.open = currentlyFocused || previouslyOpened;
  }

  get buttonId(): string {
    return guidFor(this);
  }

  get localStorageKey() {
    return `section-${this.args.id}-closed`;
  }

  @action
  handleHashChange(hash: string) {
    if (hash === this.args.id) {
      this.open = true;
    }
  }

  @action
  handleToggle() {
    this.open = !this.open;

    if (this.open) {
      localStorage.removeItem(this.localStorageKey);
    } else {
      localStorage.setItem(this.localStorageKey, 'true');
      removeLocationHash();
    }
  }
}

function removeLocationHash() {
  const noHashURL = window.location.href.replace(/#.*$/, '');
  window.history.replaceState('', document.title, noHashURL);
}
