/* import __COLOCATED_TEMPLATE__ from './department.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import CompanyModel from 'teamtailor/models/company';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import DepartmentModel from 'teamtailor/models/department';
import { EmployeeBulkArgs } from 'teamtailor/constants/employee';

export default class BulkActionsEmployeesDepartmentComponent extends Component<EmployeeBulkArgs> {
  @service declare current: Current;

  @tracked department: DepartmentModel | null = null;

  get company(): CompanyModel {
    return this.current.company;
  }

  @action
  onClose(): void {
    this.args.onClose();
    this.department = null;
  }

  @action
  handleBulkChange(): void {
    const data = {
      department_id: this.department?.id,
    };
    this.args.handleBulkChange(data, 'bulk_department');
    this.onClose();
  }
}
