/* import __COLOCATED_TEMPLATE__ from './high-charts.hbs'; */
import Component from '@glimmer/component';
import { Options } from 'highcharts';

interface HighChartsArgs {
  chartOptions: Options;
  content: Record<string, unknown>;
  hideAnimations?: boolean;
}

export default class HighCharts extends Component<HighChartsArgs> {
  get chart() {
    return {
      series: this.args.content,
      ...this.args.chartOptions,

      plotOptions: {
        ...this.args.chartOptions.plotOptions,

        series: {
          ...(this.args.chartOptions.plotOptions?.series || {}),
          animation: !this.args.hideAnimations,
        },
      },
    };
  }
}
