/* import __COLOCATED_TEMPLATE__ from './trigger-cancelled.hbs'; */
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';
import { triggerLabel as label } from 'teamtailor/utils/trigger-text';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';
import { AllTriggerModels } from 'teamtailor/models/trigger';
import TriggerWebhookModel from 'teamtailor/models/trigger/webhook';

interface TriggerCancelledArgs {
  activity: ActivityModel;
}

function isWebhookTrigger(
  trigger: AllTriggerModels
): trigger is TriggerWebhookModel {
  return get(trigger, 'type') === 'webhook';
}

export default class ActivityItemTriggerCancelledComponent extends Component<TriggerCancelledArgs> {
  @service declare intl: IntlService;

  get label(): string {
    if (isWebhookTrigger(this.args.activity.trigger)) {
      return label(this.intl.t.bind(this.intl), this.triggerType, {
        webhookVerb: get(
          get(this.args.activity.trigger, 'partner'),
          'webhookVerb'
        ),

        webhookNoun: get(
          get(this.args.activity.trigger, 'partner'),
          'webhookNoun'
        ),
      });
    } else {
      return label(this.intl.t.bind(this.intl), this.triggerType, {
        webhookVerb: null,
        webhookNoun: null,
      });
    }
  }

  get triggerType(): string {
    return get(this.args.activity.trigger, 'type');
  }

  get isWebhookTrigger(): boolean {
    return this.triggerType === 'webhook';
  }
}
