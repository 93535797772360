/* import __COLOCATED_TEMPLATE__ from './assignees.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface StatusArgs {
  hideDrafts: boolean | undefined;
}

interface OptionsArgs {
  id: string | undefined;
  label: string | undefined;
}

export default class extends Component<StatusArgs> {
  @service declare intl: IntlService;

  get options(): OptionsArgs[] {
    const statuses = ['involving-me', 'awaiting-my-approval'];

    return statuses.map((status) => ({
      id: status,
      label: this.intl.t(`job.requisitions_selects.${status}`),
    }));
  }
}
