import Service, { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { bind } from '@ember/runloop';
import { keepLatestTask } from 'ember-concurrency';
import PusherService, { PusherChannel } from './pusher';
import Server from './server';
import PageTitleListService from './page-title-list';
import DesktopNotificationsService from './desktop-notifications';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import Current from './current';
import Store from '@ember-data/store';
import FlipperService from 'teamtailor/services/flipper';

type MarkAsReadParams = {
  actionIds?: string[];
  candidateId?: string;
  exclude?: string;
  userId?: string;
};

type UnreadMentions = Record<string, string[]>;

interface PollResponse {
  unread: number;
  unread_conversations: number;
  unread_mentions: UnreadMentions;
  has_unread_job_comments: boolean;
}

export default class NotificationsService extends Service {
  @service declare pusher: PusherService;
  @service declare current: Current;
  @service declare server: Server;
  @service declare store: Store;
  @service declare pageTitleList: PageTitleListService;
  @service declare desktopNotifications: DesktopNotificationsService;
  @service declare flipper: FlipperService;

  @tracked declare channel: PusherChannel;

  @tracked unread = 0;
  @tracked declare unreadConversations: number;

  @tracked unreadMentionsTotal = 0;
  @tracked declare unreadMentions: UnreadMentions | undefined;
  @tracked declare hasUnreadJobComments?: boolean;

  constructor(owner?: object) {
    super(owner);

    this.refreshUnread.perform();
    this.setupPusher();
  }

  async setupPusher() {
    let channel: PusherChannel | undefined;
    try {
      await get(this.current, 'user');
      channel = await get(this, 'pusher').subscribe(
        get(this.current.user, 'notificationsChannel')
      );
    } catch {
      return;
    }

    this.channel = channel;
    channel.bind('new-notification', bind(this, this.handleNewNotification));
    if (get(this.flipper, 'global_comments')) {
      channel.bind('global-comments-unread', async (data) => {
        await this.reloadJobCommentStatus(data.job_id);
        this.refreshUnread.perform();
      });
    }
  }

  isChanging(unreadNotificationsCount?: number) {
    return get(this, 'unread') !== unreadNotificationsCount;
  }

  updateUnreadCounts(
    unreadNotificationsCount?: number,
    unreadConversationsCount?: number,
    unreadMentions?: UnreadMentions,
    hasUnreadJobComments?: boolean
  ) {
    const isChanging = this.isChanging(unreadNotificationsCount);

    if (unreadNotificationsCount !== undefined) {
      set(this, 'unread', unreadNotificationsCount);
      set(this.pageTitleList, 'unreadNotifications', unreadNotificationsCount);
    }

    if (unreadConversationsCount !== undefined) {
      set(this, 'unreadConversations', unreadConversationsCount);
    }

    if (unreadMentions !== undefined) {
      this.unreadMentions = unreadMentions;
      const total = Object.values(unreadMentions).reduce(
        (total, actionIds) => total + actionIds.length,
        0
      );
      this.unreadMentionsTotal = total;
    }

    if (this.hasUnreadJobComments !== hasUnreadJobComments) {
      this.hasUnreadJobComments = hasUnreadJobComments;
    }

    if (isChanging) {
      this.triggerTitleUpdate();
    }
  }

  triggerTitleUpdate() {
    this.pageTitleList.scheduleTitleUpdate();
  }

  handleNewNotification(notification: {
    unread_notifications_count: number;
    unread_conversations: number;
    unread_mentions: UnreadMentions;
    has_unread_job_comments: boolean;
    body: string;
    link: string;
    silent: boolean;
  }) {
    const {
      unread_notifications_count,
      unread_conversations,
      unread_mentions,
      has_unread_job_comments,
      body,
      link,
      silent,
    } = notification;

    this.updateUnreadCounts(
      unread_notifications_count,
      unread_conversations,
      unread_mentions,
      has_unread_job_comments
    );

    if (body || link) {
      this.desktopNotifications.showNotification(silent, body, link);
    }
  }

  refreshUnread = keepLatestTask(async () => {
    const response = (await this.server.request(
      `/app/companies/${get(
        this.current.company,
        'uuid'
      )}/api/notifications/poll`
    )) as PollResponse;

    this.updateUnreadCounts(
      response.unread,
      response.unread_conversations,
      response.unread_mentions,
      response.has_unread_job_comments
    );
  });

  reloadJobCommentStatus = async (jobId: string) => {
    await this.store.query('job-comment-status', {
      job_ids: [jobId],
    });
  };

  async markAsRead(params: MarkAsReadParams) {
    if (this.unread === 0 && this.unreadMentionsTotal === 0) {
      return;
    }

    const data = this.buildUnderscoreParams(params);

    if (!data) {
      return;
    }

    const adapter = this.store.adapterFor('application');
    const url = `${adapter.buildURL('activity')}/mark_as_read`;
    const { count } = (await this.server.request(url, 'POST', {
      data,
    })) as { count: number };

    if (count) {
      this.refreshUnread.perform();
    }
  }

  buildUnderscoreParams(params: MarkAsReadParams) {
    const data: Record<string, string | string[]> = {};

    if (params.actionIds?.length) {
      data.action_ids = params.actionIds;
    }

    if (params.candidateId) {
      data.candidate_id = params.candidateId;
    }

    if (params.exclude) {
      data.exclude = params.exclude;
    }

    if (params.userId) {
      data.user_id = params.userId;
    }

    return Object.keys(data).length ? data : null;
  }
}

declare module '@ember/service' {
  interface Registry {
    notifications: NotificationsService;
  }
}
