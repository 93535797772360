/* import __COLOCATED_TEMPLATE__ from './job-picked-custom-field.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { get } from 'teamtailor/utils/get';

export default class JobPickedCustomField extends Component {
  @service store;
  @service current;

  get field() {
    return this.args.field;
  }

  get pickedField() {
    return get(this.job, 'pickedCustomFields').findBy(
      'customFieldId',
      this.field.id
    );
  }

  get job() {
    return this.args.job;
  }

  get translations() {
    return get(this.field, 'translations');
  }

  get translation() {
    return this.translations?.findBy('languageCode', this.currentLanguage);
  }

  get currentLanguage() {
    return this.current.user.locale || this.current.company.localeAts;
  }

  get isDateType() {
    return this.field.type === 'CustomField::Date';
  }

  get i18n_name() {
    if (this.current.company.hasFeature('career_site_languages')) {
      return this.translation?.name || this.field.name;
    }

    return this.field.name;
  }

  get errors() {
    if (this.args.errors && get(this.args.errors, `${this.field.name}`)) {
      return get(this.args.errors, `${this.field.name}`);
    }

    return null;
  }

  get hasError() {
    return !!this.errors;
  }

  get isRequired() {
    return get(this.field, 'isRequired');
  }

  get label() {
    return this.isRequired
      ? `${get(this, 'i18n_name')} *`
      : get(this, 'i18n_name');
  }

  get multiSelected() {
    return get(this.field, 'options').filter((o) =>
      this.pickedField?.value?.includes(o.id)
    );
  }

  getValue(value) {
    return this.isDateType && value
      ? moment(value).format('YYYY-MM-DD')
      : value;
  }

  @action
  createPickedCustomField(value) {
    const pickedField = this.store.createRecord('picked-custom-field', {
      value,
      customField: this.field,
      customFieldId: this.field.id,
      fieldType: this.field.type,
      isHidden: this.field.isHidden,
      isExternal: this.field.isExternal,
      ownerType: 'Job',
      type: this.field.type,
      ownerId: get(this.job, 'id'),
      name: this.field.name,
    });

    get(this.job, 'pickedCustomFields').pushObject(pickedField);
  }

  @action
  createOrUpdatePickedCustomField(value) {
    get(this.job, 'errors').remove(this.field.name);
    if (this.pickedField) {
      this.pickedField.value = this.getValue(value);
    } else {
      this.createPickedCustomField(this.getValue(value));
    }
  }

  @action
  updateField(eventOrValue) {
    // eventOrValue can be null
    const value = eventOrValue?.target?.value ?? eventOrValue;
    this.createOrUpdatePickedCustomField(value);
  }

  @action
  clearPickedCustomField() {
    if (this.pickedField) {
      this.pickedField.value = null;
    }
  }

  @action
  updateSelect(value) {
    const selectedValue = value ? [value.id] : [];
    this.createOrUpdatePickedCustomField(selectedValue);
  }

  @action
  updateMultiSelect(value) {
    const values = (this.pickedField?.value || []).slice();
    toggleInList(values, value.id);
    this.createOrUpdatePickedCustomField(values);
  }
}
