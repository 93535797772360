/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import BaseComponent from 'teamtailor/components/content-block/base-component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SocialBaseComponent extends BaseComponent {
  @service iframePreview;
  @service store;
  @service ttAlert;
  @service intl;
  @service flashMessages;
  @service flipper;
  @service current;

  @action
  onInstagramConnect({ authorization: data }) {
    let authorization = this.store.createRecord(
      'authorization',
      Object.assign(data, { company: this.current.company })
    );

    return authorization
      .save()
      .then(() => {
        return this.current.company.reload();
      })
      .then(() => {
        this.iframePreview.reloadIframe();
      });
  }

  @action
  onInstagramFailure() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  removeInstagram() {
    this.ttAlert.confirm(
      this.intl.t('content.editor.social.instagram_remove_confirm'),
      () => {
        this.current.company.removeInstagram().then((data) => {
          this.store.pushPayload(data);
          this.iframePreview.reloadIframe();
        });
      },
      () => {}
    );
  }

  @action
  save() {
    return this.current.company.saveSocialMedia();
  }
}
