/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import FeatureModel, { FeatureCategoryType } from 'teamtailor/models/feature';

interface Args {
  feature: FeatureModel;
  category: FeatureCategoryType;
}

export default class FeatureCard extends Component<Args> {
  @service declare router: RouterService;

  get feature(): FeatureModel {
    return this.args.feature;
  }

  get category(): FeatureCategoryType {
    return this.args.category;
  }
}
