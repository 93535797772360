/* import __COLOCATED_TEMPLATE__ from './careersite.hbs'; */
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { dropTask } from 'ember-concurrency';
import { gql } from '@apollo/client/core';

const INSIGHTS_QUERY = gql`
  query DashboardCareersiteQuery(
    $dateRange: DateRangeAttributes!
    $compareDateRange: DateRangeAttributes!
  ) {
    currentEvents: eventQuery(
      dateRange: $dateRange
      eventTypes: [APPLIED, CONNECTED]
    ) {
      aggregated {
        applications: countOccurrences(
          filters: { eventType: { equals: APPLIED } }
        )
        connects: countOccurrences(
          filters: { eventType: { equals: CONNECTED } }
        )
      }
    }
    currentPageviews: pageviewQuery(dateRange: $dateRange) {
      visitors: distinctCount(field: SESSION_ID)
    }

    compareEvents: eventQuery(
      dateRange: $compareDateRange
      eventTypes: [APPLIED, CONNECTED]
    ) {
      aggregated {
        applications: countOccurrences(
          filters: { eventType: { equals: APPLIED } }
        )
        connects: countOccurrences(
          filters: { eventType: { equals: CONNECTED } }
        )
      }
    }
    comparePageviews: pageviewQuery(dateRange: $compareDateRange) {
      visitors: distinctCount(field: SESSION_ID)
    }
  }
`;

export default class CareerSiteWidgetComponent extends WidgetClass {
  @service insights;
  @service flipper;
  @service current;

  get hasAnyData() {
    if (this.currentPeriod) {
      const { visitors, applications, connects } = this.currentPeriod;
      return visitors + applications + connects > 0;
    } else {
      return false;
    }
  }

  get currentPeriod() {
    return this.fetchData.lastSuccessful?.value?.current;
  }

  get comparePeriod() {
    return this.fetchData.lastSuccessful?.value?.compare;
  }

  get dateRange() {
    return {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    };
  }

  get compareDateRange() {
    return {
      startDate: moment().subtract(14, 'days').format('YYYY-MM-DD'),
      endDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    };
  }

  fetchData = dropTask(async () => {
    const { dateRange, compareDateRange } = this;
    const {
      currentEvents: { aggregated: currentEvents },
      currentPageviews,
      compareEvents: { aggregated: compareEvents },
      comparePageviews,
    } = await this.insights.query({
      query: INSIGHTS_QUERY,
      variables: {
        dateRange,
        compareDateRange,
      },
    });

    return {
      current: {
        applications: currentEvents.firstObject?.applications,
        connects: currentEvents.firstObject?.connects,
        visitors: currentPageviews.visitors,
      },

      compare: {
        applications: compareEvents.firstObject?.applications,
        connects: compareEvents.firstObject?.connects,
        visitors: comparePageviews.visitors,
      },
    };
  });
}
