/* import __COLOCATED_TEMPLATE__ from './new-hire-page.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import HIDE_NEW_HIRE_PAGE_CARD from 'teamtailor/utils/new-hire-page';
import MessageService from 'teamtailor/services/message-service';
import { inject as service } from '@ember/service';
interface Args {
  candidateId: string;
}

export default class extends Component<Args> {
  @tracked isCardHidden = true;
  @service declare messageService: MessageService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.initializeCardVisibility();
  }

  initializeCardVisibility() {
    const { candidateId } = this.args;

    this.isCardHidden =
      localStorage.getItem(`${HIDE_NEW_HIRE_PAGE_CARD}-${candidateId}`) ===
      'true';
  }

  @action
  setNewHire() {
    this.messageService.newHire = true;
  }

  @action
  hideCard() {
    const { candidateId } = this.args;

    localStorage.setItem(`${HIDE_NEW_HIRE_PAGE_CARD}-${candidateId}`, 'true');
    this.isCardHidden = true;
  }
}
