/* import __COLOCATED_TEMPLATE__ from './publish-button.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import { isEmpty } from '@ember/utils';

type Args = {
  onClick: () => void;
  isPublished: boolean;
  hasChanges: boolean;
  publishedAt?: Date;
  error?: string;
};

export default class PostsPublishButtonComponent extends Component<Args> {
  @service declare timeFormat: TimeFormatService;

  get isEnabled(): boolean {
    return (
      isEmpty(this.args.error) &&
      (!this.args.isPublished || this.args.hasChanges)
    );
  }

  get icon(): string {
    if (!isEmpty(this.args.error)) {
      return 'ban';
    } else if (this.args.isPublished && !this.args.hasChanges) {
      return 'check';
    } else {
      return 'arrow-up-from-line';
    }
  }

  get status(): string {
    if (!isEmpty(this.args.error)) {
      return 'error';
    } else if (!this.args.isPublished) {
      return 'draft';
    } else if (this.args.hasChanges) {
      return 'changed';
    } else {
      return 'published';
    }
  }
}
