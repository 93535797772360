/* import __COLOCATED_TEMPLATE__ from './pipeline-speed.hbs'; */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

import IntlService from 'ember-intl/services/intl';
import round from 'teamtailor/utils/round';
import {
  plotLineFontOptions,
  fontOptions,
} from 'teamtailor/components/insights/charts/font-options';

type Args = {
  showMoreLink?: string;
  data?: any[];
  loading?: boolean;
  timeToHire?: number;
  timeToReject?: number;
  previousTimeToHire?: number;
  previousTimeToReject?: number;
  companyAverage?: number;
};

export default class InsightsWidgetsPipelineSpeed extends Component<Args> {
  @service declare intl: IntlService;

  get chartData() {
    return [
      {
        name: '',
        data: this.args.data?.map((row) => {
          return {
            name: this.intl.t(`job.stage_types.${row.stageType}`),
            y: round(moment.duration(row.averageDuration, 'seconds').asDays()),

            color: row.color,
          };
        }),
      },
    ];
  }

  get chartOptions() {
    return {
      chart: {
        type: 'bar',
        height: '300px',
        marginTop: 35,
      },

      xAxis: {
        categories: this.args.data?.mapBy('humanStageType'),
      },

      yAxis: {
        title: {
          enabled: false,
        },

        plotLines: this.plotLines,

        labels: {
          style: fontOptions,
        },
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        pointFormat: '<b>{point.y} days</b>',
      },

      plotOptions: {
        bar: {
          pointWidth: 16,
        },
      },
    };
  }

  get plotLines() {
    const text = this.intl.t(
      'insights.molecules.pipeline_speed.average_for_company'
    );
    return [
      {
        label: {
          text,
          rotation: 0,
          align: 'center',
          y: -25,
          style: {
            width: 55,
            ...plotLineFontOptions,
          },
        },

        color: '#9013FE',
        width: 1,
        dashStyle: 'dash',
        value: this.args.companyAverage,
        zIndex: 3,
      },
    ];
  }
}
