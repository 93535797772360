/* import __COLOCATED_TEMPLATE__ from './player.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { run } from '@ember/runloop';
import { extractVideoInfo } from 'teamtailor/utils/video-widget';
import {
  ALLOWED_ROUNDED_TYPES,
  DEFAULT_ROUNDED_TYPE,
  getTranslations,
} from 'teamtailor/constants/video-player';
import { inject as service } from '@ember/service';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import IntlService from 'ember-intl/services/intl';
import 'plyr/dist/plyr.css';
import { modifier } from 'ember-modifier';

type MediaPlayerArgs = {
  autoplay?: boolean;
  id: string;
  provider: string;
  rounded?: AllowedRoundedTypes;
  sourceType?: string;
  src?: string;
  videoUrl?: string;
  useCustomControls?: boolean;
  handlePlyrInstance?: (player: Plyr) => void;
  type?: 'audio' | 'video';
};

type AllowedRoundedTypes = (typeof ALLOWED_ROUNDED_TYPES)[number];

export default class MediaPlayerComponent extends Component<MediaPlayerArgs> {
  @service declare intl: IntlService;

  player?: Plyr;
  declare provider: string | null;
  declare src: string | undefined;
  declare id: string | null;

  @tracked progress = 0;
  @tracked volume = 0.65;
  @tracked defaultSourceType = 'video/mp4';

  controlsId = guidFor(this);
  playerId = guidFor(this);

  constructor(owner: unknown, args: MediaPlayerArgs) {
    super(owner, args);

    const { videoUrl } = this.args;

    if (videoUrl) {
      const { provider, id } = extractVideoInfo(videoUrl);
      this.id = id;
      this.provider = provider;
      this.src = videoUrl;
    } else {
      const { id, provider, src } = this.args;
      this.id = id;
      this.provider = provider;
      this.src = src;
    }
  }

  get rounded(): AllowedRoundedTypes {
    verifyArg(
      this.args.rounded,
      ALLOWED_ROUNDED_TYPES,
      'Media::Player @rounded',
      DEFAULT_ROUNDED_TYPE
    );

    return this.args.rounded ?? DEFAULT_ROUNDED_TYPE;
  }

  get controls(): Element | undefined {
    const controlsObject = document.getElementById(this.controlsId);
    return controlsObject?.firstElementChild || undefined;
  }

  get sourceType(): string {
    return this.args.sourceType || this.defaultSourceType;
  }

  @action
  updateSource() {
    if (this.args.src && this.player) {
      this.player.source = {
        type: this.args.type ?? 'video',
        sources: [{ src: this.args.src, type: this.sourceType }],
      };
    }
  }

  willDestroy(): void {
    super.willDestroy();
    this.player?.destroy();
  }

  @action
  onUpdateRangeInputValue(inputType: string, event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = target.value ? parseFloat(target.value) : 0;

    if (inputType === 'progress') {
      this.progress = value;
    } else if (inputType === 'volume') {
      this.volume = value;
    }
  }

  onInsert = modifier(async (element: Element) => {
    if (!this.args.useCustomControls) {
      (element as HTMLElement).style.setProperty(
        '--plyr-color-main',
        '#f43f85'
      );
    }

    const module = await import('plyr');
    const Plyr = module.default;

    const player = new Plyr(element as HTMLElement, {
      hideControls: this.args.useCustomControls,
      autoplay: this.args.autoplay,
      muted: this.args.autoplay,
      speed: {
        selected: 1,
        options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      },

      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: true,
      },

      youtube: {
        autoplay: this.args.autoplay,
        noCookie: true,
        rel: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        controls: 0,
      },

      vimeo: {
        autoplay: this.args.autoplay,
      },

      ...(this.args.useCustomControls && { controls: this.controls }),
      ...(!this.args.useCustomControls && {
        i18n: getTranslations(this.intl),
      }),
    });

    player.on('timeupdate', (_event) => {
      run(this, () => {
        if (player.currentTime && player.duration) {
          this.progress = (player.currentTime / player.duration) * 100;
        }
      });
    });

    player.on('volumechange', (_event) => {
      run(this, () => {
        const volume = player.muted ? 0 : player.volume;
        this.volume = volume;
      });
    });

    this.player = player;

    if (this.args.handlePlyrInstance) {
      this.args.handlePlyrInstance(player);
    }
  });
}
