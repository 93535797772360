/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import CandidateModel from 'teamtailor/models/candidate';
import JobApplicationModel from 'teamtailor/models/job-application';
import UploadModel from 'teamtailor/models/upload';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import FileUploadData from 'teamtailor/services/file-upload-data';
import { UploadFile } from 'ember-file-upload';

interface CandidateModalNewMainDocumentsComponentArgs {
  candidate: CandidateModel;
  jobApplication?: JobApplicationModel;
}

export default class CandidateModalNewMainDocumentComponents extends Component<CandidateModalNewMainDocumentsComponentArgs> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare fileUploadData: FileUploadData;

  get documents() {
    if (this.args.candidate.isDestroying) {
      return [];
    }

    const jobId = this.args.jobApplication
      ? this.args.jobApplication.belongsTo('job').id()
      : null;

    return this.args.candidate.uploads
      .slice()
      .filter((upload) => {
        const uploadJobId = upload.belongsTo('job').id();
        return !upload.isNew && (uploadJobId === null || uploadJobId === jobId);
      })
      .sortBy('createdAt')
      .reverse();
  }

  @action
  handleUploadStart(_fileName: string, file: UploadFile) {
    this.fileUploadData.add(file.id, {
      candidate: this.args.candidate,
      job: this.args.jobApplication?.job,
    });
  }

  @action
  handleFileUploaded(
    temporaryFileUrl: string,
    fileFileName: string,
    file: UploadFile
  ) {
    const fileUploadData = this.fileUploadData.data[file.id];
    const { candidate, job } = fileUploadData ?? {};
    const upload = this.store.createRecord('upload', {
      temporaryFileUrl,
      fileFileName,
      candidate: candidate ?? this.args.candidate,
      job: job ?? this.args.jobApplication?.job,
      fileContentType: file.type,
    });
    this.fileUploadData.remove(file.id);

    return upload
      .save()
      .then(() => {
        this.flashMessages.success(
          this.intl.t(
            'candidates.candidate.candidate_modal.document_upload_success'
          )
        );
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      });
  }

  @action
  deleteUpload(upload: UploadModel) {
    upload.destroyRecord();
  }
}
