/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
// @ts-nocheck
import Component from '@glimmer/component';
import { CustomReportFilter } from 'teamtailor/utils/custom-report-schema';
import { CustomReportRequestFilter } from 'teamtailor/utils/custom-report-request';
import { alias, readOnly } from '@ember/object/computed'; // eslint-disable-line ember/no-computed-properties-in-native-classes
import { action } from '@ember/object';
import { FilterOption } from 'teamtailor/components/fancy-filters.d';

interface CustomReportFormFiltersArgs {
  filters: CustomReportRequestFilter[];
  availableFilters: CustomReportFilter[];
}

export default class CustomReportFormFilters extends Component<CustomReportFormFiltersArgs> {
  @alias('args.filters')
  declare filters: CustomReportRequestFilter[];

  @readOnly('args.availableFilters')
  declare availableFilters: CustomReportFilter[];

  @action
  handleSelectFilter(filter: FilterOption) {
    const customReportFilter = new CustomReportRequestFilter(filter);
    customReportFilter.isOpen = true;
    this.filters.pushObject(customReportFilter);
  }

  @action
  handleRemoveFilter(filter: CustomReportRequestFilter) {
    this.filters.removeObject(filter);
  }

  @action
  removeFilter(filter: CustomReportRequestFilter) {
    this.filters.removeObject(filter);
  }
}
