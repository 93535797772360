/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';
import UserModel from 'teamtailor/models/user';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

interface Args {
  user: UserModel;
}

export default class EmployeesCardComponent extends Component<Args> {
  @service declare current: Current;
}
