/* import __COLOCATED_TEMPLATE__ from './nps-gauge.hbs'; */
import Component from '@glimmer/component';

type Args = {
  score: number;
  previousScore?: number;
};

export default class InsightsWidgetsNpsGauge extends Component<Args> {
  get roundedScore() {
    return Math.round(this.args.score);
  }

  get roundedPreviousScore() {
    if (!this.args.previousScore) return 0;

    return Math.round(this.args.previousScore);
  }
}
