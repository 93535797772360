import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';

export default class EditRoute extends ScrollToTopRoute {
  @service store;
  @service current;

  async model(params) {
    const team = await this.store.findRecord('team', params.team_id);
    const roles = await get(team, 'roles');
    const departments = await get(team, 'departments');
    await get(team, 'divisions');

    await Promise.all(
      team.users
        .map((user) => {
          return get(user, 'id');
        })
        .concat(
          roles
            .map((role) => {
              return get(role, 'department');
            })
            .concat(
              departments.map((department) => {
                return get(department, 'division');
              })
            )
        )
    );

    await Promise.all(
      roles.map((role) => {
        return get(role, 'department.division');
      })
    );

    return team;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const noDepartmentDivision = get(model, 'noDepartmentDivision');
    const roles = get(model, 'roles').map((role) => {
      return this.store.createRecord('department-or-role', {
        team: model,
        role,
        department: get(role, 'department'),
        division: get(role, 'department.division'),
      });
    });

    const departments = get(model, 'departments').map((department) => {
      return this.store.createRecord('department-or-role', {
        team: model,
        department,
        division: get(department, 'division'),
      });
    });

    const divisions = get(model, 'divisions').map((division) => {
      return this.store.createRecord('department-or-role', {
        team: model,
        division,
        department: noDepartmentDivision.find(
          (noDepartmentDivision) =>
            get(noDepartmentDivision, 'id') === get(division, 'id')
        )
          ? this.noDepartmentDepartment
          : undefined,
      });
    });

    const departmentsOrRoles = [...divisions, ...departments, ...roles];

    controller.teamUsers = get(model, 'users')?.slice();
    controller.departmentsOrRoles = departmentsOrRoles;
  }

  get noDepartmentDepartment() {
    return get(this.current.company, 'departments').find((department) => {
      return get(department, 'id') === NO_DEPARTMENT_ID;
    });
  }
}
