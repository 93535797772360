import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    divisions: {
      serialize: 'ids',
      deserialize: 'ids',
    },

    departments: {
      serialize: 'ids',
      deserialize: 'ids',
    },

    noDepartmentDivision: {
      serialize: 'ids',
      deserialize: 'ids',
    },

    roles: {
      serialize: 'ids',
      deserialize: 'ids',
    },

    users: {
      serialize: 'ids',
      deserialize: 'ids',
    },

    locations: {
      serialize: 'ids',
      deserialize: 'ids',
    },
  },
});
